<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox notes-warnings-modal">
            <!-- <div class="modal-header">
                <div class="modal-drag-div">
                    <i class="fa fa-arrows"></i>
                </div>
            </div> -->
            <div class="modal-body">
                <ul id="note-warnings-ul" class="nav nav-tabs">
                    <li v-if="notesArray.length > 0"
                        class="nav-item">
                        <a id="test-bind" 
                            class="nav-link" 
                            data-toggle="tab" 
                            href="#notes" 
                            :title="getTranslationText('265')"
                            data-bind="text: getTranslationText('265'), attr: { title: getTranslationText('265') }">{{getTranslationText('265')}}</a>
                    </li>
                    <li v-if="warningsArray.length > 0"
                        class="nav-item">
                        <a class="nav-link" 
                            data-toggle="tab" 
                            href="#warnings" 
                            :title="getTranslationText('560')"
                            data-bind="text: getTranslationText('560'), attr: { title: getTranslationText('560') }">{{getTranslationText('560')}}</a>
                    </li>
                    <div class="modal-drag-container">
                        <ModalDragIcon></ModalDragIcon>
                        <!-- <div class="modal-drag-div">
                            <i class="fa fa-arrows"></i>
                        </div> -->
                    </div>
                </ul>
                <div class="tab-content">
                    <div id="notes" class="tab-pane array-container">
                        <div class="notes-title">
                            <span data-bind="text: getTranslationText('4328')">{{getTranslationText('4328')}}</span>                    
                        </div>
                        <div class="notes-ul-container">
                            <ul>
                                <!-- <li>
                                    <span data-bind="text: getTranslationText('4329')">{{getTranslationText('4329')}}</span>
                                </li> -->
                                <template v-for="note in notesArray" :key="note">
                                    <li v-if="checkNoteIsForThisSection(note, config.NOTES_HEADINGS.MANUFACTURER_RATINGS)" 
                                        :key="note"
                                        :class="{ 'not-viewed': note.getIsNew() === true }">
                                        <span >{{note.getStringVal()}}</span>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="notes-title">
                            <span data-bind="text: getTranslationText('4310')">{{getTranslationText('4310')}}</span>
                        </div>
                        <div class="notes-ul-container">
                            <!-- <template v-for="legislation in legislationsArray" :key="legislation">
                                <ul v-if="checkLegislationDescription(legislation) === true">
                                    <li>
                                        <span data-bind="text: description">{{legislation.description}}</span>
                                        <template v-if="legislation.roadNames !== ''">
                                            <span> (</span><span data-bind="text: roadNames">{{legislation.roadNames}}</span><span>)</span>
                                        </template>
                                    </li>
                                </ul>
                                <ul v-if="checkLegislationGetIsNewFunction(legislation) === true">
                                    <li :class="{ 'not-viewed': legislation.getIsNew() === true }"
                                        data-bind="css: { 'not-viewed': getIsNew() === true }">
                                        <span data-bind="text: getStringVal()">{{legislation.getStringVal()}}</span>
                                    </li>
                                </ul>
                            </template> -->
                            <ul>
                                <template v-for="note in notesArray" :key="note">
                                    <li v-if="checkNoteIsForThisSection(note, config.NOTES_HEADINGS.REGULATIONS_APPLIED)" 
                                        :key="note"
                                        :class="{ 'not-viewed': note.getIsNew() === true }">
                                        <span >{{note.getStringVal()}}</span>
                                        <ul>
                                            <template v-for="dDRNote in getLegislationDDRArray(note.getId())" :key="dDRNote">
                                                <li 
                                                    
                                                    :class="{ 'not-viewed': dDRNote.getIsNew() === true }">
                                                    <span >{{dDRNote.getStringVal()}}</span>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="notes-title">
                            <span data-bind="text: getTranslationText('4311')">{{getTranslationText('4311')}}</span>
                        </div>
                        <div class="notes-ul-container">
                            <ul>
                                <template v-for="note in notesArray" :key="note">
                                <li v-if="checkNoteIsForThisSection(note, config.NOTES_HEADINGS.GENERAL)" 
                                    :key="note"
                                    :class="{ 'not-viewed': note.getIsNew() === true }">
                                    <span >{{note.getStringVal()}}</span>
                                </li>
                                </template>
                                
                            </ul>
                        </div>
                    </div>
                    <div id="warnings" class="tab-pane array-container">
                        <div class="warnings-container" data-bind="foreach: warningsArray">
                            <template v-for="warningParent in warningsArray" :key="warningParent">
                                <div class="warnings-legislation-description">
                                    <span data-bind="text: description">{{warningParent.description}}</span>
                                </div>
                                <div class="warnings-list">
                                    <ul data-bind="foreach: warnings">
                                        <li v-for="warning in warningParent.warnings" 
                                            :key="warning"
                                            :class="{ 'not-viewed': warning.getIsNew() === true }">
                                            <span data-bind="text: getStringVal()">{{warning.getStringVal()}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="menu-flex-end">
                    <button class="btn btn-default btn-truckscience btn-primary" 
                        @click="ok"
                        data-bind="click: ok, touchstart: ok">
                        <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                    </button>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import { ref, toRef, shallowRef, onMounted, nextTick } from 'vue';

import config from '@/services/config';
import globals from '@/services/globals';

import PopupModal from '../PopupModal.vue';
import ModalDragIcon from '../ModalDragIcon.vue'

var $ = window.$;

export default {
    name: 'NotesAndWarningsModal',
    components: { PopupModal, ModalDragIcon },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const notes = opts.value.modalObject.notes;
        const notesArray = shallowRef([]);
        const warnings = opts.value.modalObject.warnings;
        const warningsArray = shallowRef([]);
        const dDRNotes = opts.value.modalObject.dDRNotes;
        
        // const legislationsArray = shallowRef([]);
        var sortedLegislations = opts.value.modalObject.sortedLegislations;
        
        let resolvePromise;

        onMounted(() => {
            popup.value.open();
            activate();
            nextTick(()=> {
                compositionComplete();
            });
            
        });

        function activate() {
            notesArray.value = notes.getStringTrackerObjects();
            warningsArray.value = createWarningsArray(warnings, sortedLegislations);
            // var legislationsArr = [];
            // globals.getDistinctLegislationStubsArray(sortedLegislations, globals.user.getLegislationStubObjectUsingId).forEach(function (item) {
            //     legislationsArr.push(item);
            // });
            // notes.getStringTrackerObjects(globals.returnApplicationItemsOnly).forEach(function (item) {
            //     legislationsArr.push(item);
            // });
            // legislationsArray.value = legislationsArr;
        }

        function compositionComplete() {
            function handleTabChange(e) {
                if (e.relatedTarget.hash.includes('notes')) {
                    // mark all notes as viewes
                    notes.markAllAsViewed();
                    globals.clearShallowRefArrayAndAddItems(notesArray, notes.getStringTrackerObjects());
                    // var legislationsArr = [];
                    // globals.getDistinctLegislationStubsArray(sortedLegislations, globals.user.getLegislationStubObjectUsingId).forEach(function (item) {
                    //     legislationsArr.push(item);
                    // });
                    // notes.getStringTrackerObjects(globals.returnApplicationItemsOnly).forEach(function (item) {
                    //     legislationsArr.push(item);
                    // });
                    // globals.clearShallowRefArrayAndAddItems(legislationsArray, legislationsArray);
                }
                if (e.relatedTarget.hash.includes('warnings')) {
                    // mark all warnings as viewes
                    Object.keys(warnings).forEach(function (key) {
                        warnings[key].markAllAsViewed();
                    });
                    globals.clearShallowRefArrayAndAddItems(warningsArray, createWarningsArray(warnings, sortedLegislations));
                }
                setupTabToggle();
            }

            function setupTabToggle() {
                $('#note-warnings-ul a[data-toggle="tab"]').unbind('show.bs.tab');
                $('#note-warnings-ul a[data-toggle="tab"]').on('show.bs.tab', handleTabChange);
            }

            // setup the tabs
            $('#note-warnings-ul a:first').tab('show');
            setupTabToggle();
        }

        function createWarningsArray(warningsObj, legislationsArray) {
            var returnArray = [];

            if (warningsObj[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].getStringTrackerObjects().length > 0) {
                let warningsObject = {};
                warningsObject.description = config.getTranslationText('4637'); 
                warningsObject.warnings = [];

                warningsObj[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].getStringTrackerObjects().forEach(function (trackerObj) {
                    warningsObject.warnings.push(trackerObj);
                });
                returnArray.push(warningsObject);
            }
            legislationsArray.forEach(function (leg) {
                if (warningsObj[leg.id].getStringTrackerObjects().length > 0) {
                    var warningsObject = {};
                    warningsObject.description = globals.user.getLegislationDescriptionUsingId(parseInt(leg.id));
                    warningsObject.warnings = [];

                    warningsObj[leg.id].getStringTrackerObjects().forEach(function (trackerObj) {
                        warningsObject.warnings.push(trackerObj);
                    });
                    returnArray.push(warningsObject);
                }
            });
            if (warningsObj[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].getStringTrackerObjects().length > 0) {
                let warningsObject = {};
                warningsObject.description = config.getTranslationText('4321'); 
                warningsObject.warnings = [];

                warningsObj[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].getStringTrackerObjects().forEach(function (trackerObj) {
                    warningsObject.warnings.push(trackerObj);
                });
                returnArray.push(warningsObject);
            }

            return returnArray;
        }

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }
        
        // function checkLegislationDescription(legislation) {
        //     return typeof legislation.description !== 'undefined';
        // }

        // function checkLegislationGetIsNewFunction(legislation) {
        //     return typeof legislation.getIsNew === 'function';
        // }

        function checkNoteIsForThisSection(note, heading) {
            return note.getState(config.TRACKER_STATE_KEY.SECTION) === heading;
        }

        function getLegislationDDRArray(legislationNoteId) {
            let encodeLegislationId = legislationNoteId.split('_')[1];
            let legislationdDRNotes = dDRNotes[encodeLegislationId];
            return legislationdDRNotes ? legislationdDRNotes.getStringTrackerObjects() : [];
        }

        function close() {
            popup.value.close();
            resolvePromise();
        }

        function ok() {
            $('#note-warnings-ul a[data-toggle="tab"]').unbind('show.bs.tab');
            close();
        }

        return {
            popup,
            show,
            notesArray,
            warningsArray,
            // legislationsArray,
            // checkLegislationDescription,
            // checkLegislationGetIsNewFunction,
            // Buttons
            ok,
            // Text
            getTranslationText: config.getTranslationText,
            config,
            checkNoteIsForThisSection,
            getLegislationDDRArray
        }
    }
}
</script>

<style lang="scss" scoped>

</style>