
var AxleLetter = function (xOffset, yOffset, letter, axlePosition, highlight) {

    var self = this;
    //constructor
    self.centre = { x: xOffset, y: yOffset };
    self.letter = letter;
    self.axlePosition = axlePosition;
    self.highlight = highlight || false;

    self.drawingGroupName = 'NOT_SET';
    self.setDrawingGroupName = function (newValue) {
        self.drawingGroupName = newValue;
    }

    self.setShowAsWarning = function (showAsWarning) {
        self.showAsWarning = showAsWarning;
    }

    self.setColour = function (colour) {
        self.colour = colour;
    }
};



export default AxleLetter;

