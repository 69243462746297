<template>
    <tr class="menu-li menu-li-value menu-li-text-with-save" :class="textValue.customCss"
        data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-text-with-save' }">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{ textValue.label }}</span>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div"></div>
                <input class="form-control login-input menu-input menu-input-number" type="text"
                    v-model.lazy="textValue.value"
                    @click="selectAllText"
                    @keyup.enter="handleEnterKey"
                    :focus="selectAllText"
                    :maxlength="textValue.maxLength"
                    :readonly="returnReadOnly()"
                    :class="{'override-false': isReadOnly()}"
                    data-bind="value: value, click: isMac === true ? selectAllText : null, event: { focus: isMac === false ? selectAllText : null }, attr: {id: id, maxlength: maxLength}, disable: permissionObv().readOnly, css: {'override-false': permissionObv().readOnly === true}" />
                <div class="increment-div decrement-div"></div>
                <div class="increment-div"></div>
            </div>
        </td>
    </tr>
    <!-- <div>
        'template-menu-item'
    </div> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.VALUE 
     ko if: inputType === $root.config.INPUT_TYPE.TEXT_WITH_SAVE 
    <tr class="menu-li menu-li-value menu-li-text-with-save" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-text-with-save' }"></tr>
     /ko 
     /ko 
</script> -->

    <!-- <script type="text/html" id="template-menu-text-with-save">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
        </div>
    </td>
    <td class="menu-value-td">
        <div class="flex-center menu-flex-end">
            <div class="increment-div info-tooltip-div"></div>
            <input class="form-control login-input menu-input menu-input-number" type="text" data-bind="value: value, click: isMac === true ? selectAllText : null, event: { focus: isMac === false ? selectAllText : null }, attr: {id: id, maxlength: maxLength}, disable: permissionObv().readOnly, css: {'override-false': permissionObv().readOnly === true}" />
            <div class="increment-div decrement-div"></div>
            <div class="increment-div"></div>
        </div>
    </td>
</script> -->
</template>

<script>
import { toRef, nextTick, isRef } from 'vue';

const $ = window.$;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const textValue = ref(props.valueFromParent);
        const textValue = toRef(props, "valueFromParent");

        function selectAllText() {
            if(textValue.value.isMac === true) {
                textValue.value.selectAllText
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + textValue.value.id).val(textValue.value.value.value);
            });
        }

        function isReadOnly() {
            if(isRef(textValue.value.permissionObv)) {
                return textValue.value.permissionObv.value.readOnly === true;
            } else {
                return textValue.value.permissionObv.readOnly === true;
            }
        }

        function returnReadOnly() {
            if(isRef(textValue.value.permissionObv)) {
                return textValue.value.permissionObv.value.readOnly;
            } else {
                return textValue.value.permissionObv.readOnly;
            }
        }

        return {
            textValue,
            selectAllText,
            handleEnterKey,
            isReadOnly,
            returnReadOnly
        }
    }
}
</script>

<style lang="scss" scoped>
</style>