import { isRef, nextTick, shallowRef, watch } from "vue";
import config from "@/services/config";
import globals from "@/services/globals";
import utils from "@/services/utils";
import dataManager from "@/services/dataManager";
import bus from "@/main";
import CustomMessageBox from '@/services/CustomMessageBox';

const $ = window.$;
let TScMessenger = window.TScMessenger;
let WebuiPopovers = window.WebuiPopovers;
let filterDfd;

function DatagridColumn(initDatagridColumn) {
  var self = this;
  self.sortType = shallowRef();
  self.activeSort = shallowRef();

  construct(initDatagridColumn);

  self.toggleSort = function () {
    if (self.sortable) {
      if (self.sortType.value === config.SORT_OPTIONS.ASC) {
        self.sortType.value = config.SORT_OPTIONS.DESC;
      } else {
        self.sortType.value = config.SORT_OPTIONS.ASC;
      }
    }
    self.activeSort.value = true;
  };

  self.cleanUp = function () {
    self.name = null;
    self.nameDisplayed = null;
    self.sortable = null;
    self.sortType.value = null;
    self.sortType = null;
    self.itemToSort = null;
    self.headerClass = null;
    // if (self.activeSort !== undefined) {
    //     try {
    //         utils.clearSubscriptions(self.activeSort);
    //         self.activeSort(null);

    //     } catch (ex) {
    //         var breakHere = 0;
    //     }
    //     self.activeSort = null;
    // }
    // if (ko.isObservable(self.isChecked)) {
    //     self.isChecked(null);
    //     self.isChecked = null;
    // }
    initDatagridColumn.cleanUp();
    self = null;
  };

  function construct(datagridColumn) {
    self.name = datagridColumn.name;
    self.nameDisplayed = datagridColumn.nameDisplayed;
    self.sortable = datagridColumn.sortable;
    self.sortType.value = datagridColumn.sortType;
    self.itemToSort = datagridColumn.itemToSort;
    self.headerClass = datagridColumn.headerClass;
    self.headerThClass =
      typeof datagridColumn.headerThClass === "string"
        ? datagridColumn.headerThClass
        : "";
    self.activeSort.value = datagridColumn.activeSort;

    if (datagridColumn.includeSelectAllCheckbox === true) {
      self.includeSelectAllCheckbox = true;
      self.isChecked = shallowRef(datagridColumn.isChecked);
    } else {
      self.includeSelectAllCheckbox = false;
    }
  }
}

function SelectionWizardStepTab(selectionWizardStepTab) {
  var self = this;
  self.isActive = shallowRef(false);

  self.activateTab = function () {
    self.isActive.value = true;
  };

  self.deactivateTab = function () {
    self.isActive.value = false;
  };

  construct(selectionWizardStepTab);

  function construct(tab) {
    self.displayName = tab.displayName;
    self.iconClass = tab.iconClass;
    self.filterObject = tab.filterObject;
    self.tooltipText =
      typeof tab.tooltipText === "string" ? tab.tooltipText : "";
    self.clickFunction =
      typeof tab.clickFunction === "function" ? tab.clickFunction : null;
  }

  self.cleanUp = function () {
    self.displayName = null;
    self.iconClass = null;
    self.filterObject = null;
    self.isActive.value = null;
    self.isActive = null;
    selectionWizardStepTab.cleanUp();
    self = null;
  };
}

function SelectionWizardStep(
  id,
  initSelectionWizardStep,
  containerId,
  getSelectionWizard
) {
  var self = this;
  self.renderAs = config.RENDERED_AS_OPTIONS.SELECTION_WIZARD;
  self.displayName = shallowRef();
  self.displayInBreadcrumb = shallowRef();
  self.filtersArray = [];
  self.activeStepBeforeSearch = false;

  construct(id, initSelectionWizardStep, containerId, getSelectionWizard);

  self.activateWizardStep = function (activeStepBeforeSearch) {
    self.isActive = true;
    self.displayName.value = self.originalDisplayName;
    // update activeStepBeforeSearch
    if (activeStepBeforeSearch) {
      self.activeStepBeforeSearch = activeStepBeforeSearch;
      self.displayInBreadcrumb.value = true;
    }
    if (self.displayQuickSearch === true) {
      $("#" + self.getQuickSearchInputId()).focus();
    }
    if (self.displayMultiSelect === true) {
      self.getParentSelectionWizard().initializeMultiSelects();
    }
    if(self.activateWizardStepCallback) {
      self.activateWizardStepCallback();
    }
  };
  self.deactivateWizardStep = function () {
    self.isActive = false;
    self.activeStepBeforeSearch = false;
    self.displayInBreadcrumb.value = false;
    self.displayName.value = self.originalDisplayName;
    self.filtersArray = [];
    if (self.displayTabs) {
      self.tabs[0].activateTab();
    }
    if(self.deactivateWizardStepCallback) {
      self.deactivateWizardStepCallback();
    }
  };

  self.updateSort = function (data) {
    data.toggleSort();
  };

  self.sortArray = function (arrayToSort) {
    var sortableColumns = [];
    // get active column first
    self.columns.forEach(function (column) {
      if (column.sortable && column.activeSort.value) {
        sortableColumns.push(column);
      }
    });
    // get the other columns in order
    self.columns.forEach(function (column) {
      if (column.sortable && !column.activeSort.value) {
        sortableColumns.push(column);
      }
    });
    return self.sortFunction(arrayToSort, sortableColumns);
  };

  self.setActiveSortForAllColumnsToFalse = function () {
    self.columns.forEach(function (column) {
      if (column.sortable) {
        column.activeSort.value = false;
      }
    });
  };

  self.checkRow = function (row, value, showAll, multiSelectFilters) {
    return self.filterFunction(
      row,
      value,
      self.filtersArray,
      showAll,
      multiSelectFilters
    );
  };

  self.clicked = function (itemData, stepIndex) {
    self.clickFunction(itemData, stepIndex, self.filtersArray);
  };

  self.shareItem = function (itemData) {
    self.shareFunction(itemData);
  };

  self.deleteItem = function (itemData) {
    self.deleteFunction(itemData);
  };

  self.downloadFile = function (itemData) {
    self.downloadFileFunction(itemData);
  };

  self.moveToNextStep = function (itemData) {
    var filterObject = self.clickFunction(itemData);
    self.displayName.value =
      filterObject.displayValue !== undefined
        ? filterObject.displayValue
        : filterObject.value;

    return filterObject;
  };

  /**
   * Get the active sort column for step if it exists
   * Returns the relevant DatagridColumn or null if it doesn't exist
   * @returns {Object|null}
   */
  self.getActiveSort = function () {
    var returnObj = null;
    self.columns.forEach(function (column) {
      if (column.activeSort.value === true) {
        returnObj = column;
      }
    });
    return returnObj;
  };

  self.setActiveSortUsingObject = function (sortObject) {
    self.columns.forEach(function (column) {
      var columnItemToSortString =
        typeof column.itemToSort === "function"
          ? column.itemToSort()
          : column.itemToSort;
      if (column.itemToSort === sortObject.itemToSort) {
        column.sortType(sortObject.sortType);
        column.activeSort(true);
      }
    });
  };

  self.updateSortUsingArray = function (arrayOfObjects) {
    self.columns.forEach(function (column) {
      var columnItemToSortString =
        typeof column.itemToSort === "function"
          ? column.itemToSort.value
          : column.itemToSort;
      var sortObject = arrayOfObjects.find(function (sortObjectCol) {
        return column.itemToSort === sortObjectCol.itemToSort;
      });
      if (sortObject !== undefined) {
        column.sortType.value = sortObject.sortType;
        column.activeSort.value = sortObject.activeSort;
      }
    });
  };

  self.getQuickSearchInputId = function () {
    if (self.displayQuickSearch) {
      var idToUse =
        typeof self.containerId === "string" && self.containerId !== ""
          ? self.containerId + "-" + self.id
          : self.id;
      return "selection-wizard-step-" + idToUse + "-quick-search-input";
    }
    return null;
  };

  self.getSelectAllColumn = function () {
    return self.columns.find(function (col) {
      return col.includeSelectAllCheckbox === true;
    });
  };

  self.toggleSelectAllCheckbox = function () {
    var selectAllColumn = self.getSelectAllColumn();
    if (
      selectAllColumn.isChecked.value ===
        config.SELECT_ALL_CHECKBOX_OPTIONS.CHECKED ||
      selectAllColumn.isChecked.value ===
        config.SELECT_ALL_CHECKBOX_OPTIONS.MINUS
    ) {
      selectAllColumn.isChecked.value =
        config.SELECT_ALL_CHECKBOX_OPTIONS.NOT_CHECKED;
    } else {
      selectAllColumn.isChecked.value =
        config.SELECT_ALL_CHECKBOX_OPTIONS.CHECKED;
    }
    // Update all rows
    var itemsArray = self.getParentSelectionWizard().getItemsToDisplayArray();
    itemsArray.forEach(function (row) {
      if (
        selectAllColumn.isChecked.value ===
        config.SELECT_ALL_CHECKBOX_OPTIONS.CHECKED
      ) {
        row.isSelected.value = true;
      } else {
        row.isSelected.value = false;
      }
    });
    self.getParentSelectionWizard().updateToolbar();
  };

  self.toggleCheckbox = function (rowClicked, el) {
    // toggle is selected
    if (rowClicked.isSelected === true) {
      rowClicked.isSelected = false;
    } else {
      rowClicked.isSelected = true;
    }

    // Update select all
    var itemsArray = self.getParentSelectionWizard().getItemsToDisplayArray();
    var allRowsSelected = true,
      atLeastOneRowSelected = false;
    itemsArray.forEach(function (row) {
      if (row.isSelected.value === true) {
        atLeastOneRowSelected = true;
      } else if (row.isSelected.value === false) {
        allRowsSelected = false;
      }
    });
    var selectAllType = config.SELECT_ALL_CHECKBOX_OPTIONS.NOT_CHECKED;
    if (allRowsSelected === true) {
      selectAllType = config.SELECT_ALL_CHECKBOX_OPTIONS.CHECKED;
    } else if (atLeastOneRowSelected === true) {
      selectAllType = config.SELECT_ALL_CHECKBOX_OPTIONS.MINUS;
    }
    var selectAllColumn = self.getSelectAllColumn();
    selectAllColumn.isChecked.value = selectAllType;
    self.getParentSelectionWizard().updateToolbar();
  };

  function construct(id, selectionWizardStep, containerId, getSelectionWizard) {
    var includeSelectAllCheckbox = false;
    self.id = id;
    self.containerId = containerId;
    self.displayName.value = selectionWizardStep.displayName;
    self.originalDisplayName = selectionWizardStep.displayName;
    self.displayInBreadcrumb.value = selectionWizardStep.displayInBreadcrumb;
    self.sortFunction =
      typeof selectionWizardStep.sortFunction === "function"
        ? selectionWizardStep.sortFunction
        : function (arrayToSort) {
            return arrayToSort.sort(function (a, b) {
              return 0;
            });
          };
    self.filterFunction =
      typeof selectionWizardStep.filterFunction === "function"
        ? selectionWizardStep.filterFunction
        : function () {
            return true;
          };
    self.clickFunction = selectionWizardStep.clickFunction;
    self.shareFunction = selectionWizardStep.shareFunction;
    self.deleteFunction = selectionWizardStep.deleteFunction;
    self.downloadFileFunction = selectionWizardStep.downloadFileFunction;
    self.renderAs = selectionWizardStep.renderAs;
    self.uniqueField = selectionWizardStep.uniqueField;
    self.renderImageCardAs = selectionWizardStep.renderImageCardAs;
    self.displayQuickSearch = selectionWizardStep.displayQuickSearch;
    self.displayMultiSelect = selectionWizardStep.displayMultiSelect;
    self.stayOnStepWhenClearAllFiltersClicked =
      typeof selectionWizardStep.stayOnStepWhenClearAllFiltersClicked ===
      "boolean"
        ? selectionWizardStep.stayOnStepWhenClearAllFiltersClicked
        : false;
    if (selectionWizardStep.limitResults !== undefined) {
      self.limitResults = selectionWizardStep.limitResults;
    }

    if (selectionWizardStep.displayQuickSearch) {
      // #unfinished - Look for way to update UI without affecting rateLimit
      // self.searchTerms = ref("").extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
      self.searchTerms = shallowRef("");
    }
    self.alwaysDisplayStep =
      selectionWizardStep.alwaysDisplayStep !== undefined
        ? selectionWizardStep.alwaysDisplayStep
        : false;
    self.performCheckOnClickOnThisStep =
      selectionWizardStep.performCheckOnClickOnThisStep !== undefined
        ? selectionWizardStep.performCheckOnClickOnThisStep
        : false;

    self.isActive = false;
    self.columns = [];
    if (selectionWizardStep.columns !== undefined) {
      selectionWizardStep.columns.forEach(function (column) {
        var datagridColumn = new DatagridColumn(column);
        self.columns.push(datagridColumn);
        if (datagridColumn.includeSelectAllCheckbox === true) {
          includeSelectAllCheckbox = true;
        }
      });
    }

    self.bypassAutoItemSelect =
      selectionWizardStep.bypassAutoItemSelectWhenOnlyOne;
    self.tabs = [];
    selectionWizardStep.tabs.forEach(function (tab) {
      self.tabs.push(new SelectionWizardStepTab(tab));
    });
    self.displayTabs = selectionWizardStep.displayTabs;
    self.requestItemFunction = selectionWizardStep.requestItemFunction;
    self.displayRequestItemSection =
      selectionWizardStep.displayRequestItemSection;
    // Added to set title on wizard
    self.setWizardTitleFunction = selectionWizardStep.setWizardTitleFunction;
    self.displayClearButton =
      typeof selectionWizardStep.displayClearButton === "boolean"
        ? selectionWizardStep.displayClearButton
        : false;
    self.displayDownloadFileOption =
      typeof selectionWizardStep.displayDownloadFileOption === "boolean"
        ? selectionWizardStep.displayDownloadFileOption
        : false;
    self.displayIconButton =
      typeof selectionWizardStep.displayIconButton === "boolean"
        ? selectionWizardStep.displayIconButton
        : false;
    self.iconButtonCallback =
      typeof selectionWizardStep.iconButtonCallback === "function"
        ? selectionWizardStep.iconButtonCallback
        : null;
    self.iconButtonText =
      typeof selectionWizardStep.iconButtonText === "string"
        ? selectionWizardStep.iconButtonText
        : "";
    self.iconButtonClass =
      typeof selectionWizardStep.iconButtonClass === "string"
        ? selectionWizardStep.iconButtonClass
        : "";
    self.rowTemplateName =
      typeof selectionWizardStep.rowTemplateName === "function"
        ? selectionWizardStep.rowTemplateName
        : null;
    self.onlineSearchCallback =
      typeof selectionWizardStep.onlineSearchCallback === "function"
        ? selectionWizardStep.onlineSearchCallback
        : null;
    self.clickOnItemCallback =
      typeof selectionWizardStep.clickOnItemCallback === "function"
        ? selectionWizardStep.clickOnItemCallback
        : null;
    //self.getItemsToDisplayArray = getItemsToDisplayArray;
    self.getParentSelectionWizard = getSelectionWizard;
    self.activateWizardStepCallback = typeof selectionWizardStep.activateWizardStepCallback === "function" ? selectionWizardStep.activateWizardStepCallback : null;
    self.deactivateWizardStepCallback = typeof selectionWizardStep.deactivateWizardStepCallback === "function" ? selectionWizardStep.deactivateWizardStepCallback : null;
  }

  self.cleanUp = function () {
    self.id = null;
    self.displayName.value = null;
    self.displayName = null;
    self.originalDisplayName = null;
    self.displayInBreadcrumb.value = null;
    self.displayInBreadcrumb = null;
    self.sortFunction = null;
    self.filterFunction = null;
    self.clickFunction = null;
    self.shareFunction = null;
    self.deleteFunction = null;
    self.downloadFileFunction = null;
    self.renderAs = null;
    self.uniqueField = null;
    //self.renderImageCardAs = null;
    self.displayQuickSearch = null;

    self.limitResults = null;

    if (self.searchTerms !== undefined) {
      self.searchTerms.value = null;
      self.searchTerms = null;
    }

    self.alwaysDisplayStep = null;

    self.isActive = null;
    self.columns.forEach(function (column) {
      column.cleanUp();
    });
    self.columns = null;

    self.bypassAutoItemSelect = null;
    //self.tabs = selectionWizardStep.tabs;
    self.tabs.forEach(function (tab) {
      tab.cleanUp();
    });
    self.tabs = null;
    self.displayTabs = null;
    self.requestItemFunction = null;
    self.displayRequestItemSection = null;
    // Added to set title on wizard
    self.setWizardTitleFunction = null;
    self.displayClearButton = null;
    self.displayDownloadFileOption = null;
    initSelectionWizardStep.cleanUp();
    //self = null;
  };
}

function SelectionWizard(
  initSelectionWizard,
  partialMenuId,
  activateStepCallback
) {
  var self = this;
  var previouslyEnteredSearchTerms = "",
    getMenuCallback = null;

  self.activeWizardStep = shallowRef();
  self.trigger = shallowRef();
  self.title = shallowRef();
  self.itemsToDisplayArray = shallowRef([]);
  self.renderSelectionWizardAs = shallowRef();
  self.displayQuickSearch = shallowRef(false);
  self.displayClearButton = shallowRef(false);
  self.displayToolbar = shallowRef(false);
  self.displayCustomBreadcrumbs = shallowRef(false);
  self.displayBackButtonOnScreen = shallowRef(false);
  self.displayTabs = shallowRef(false);
  self.displayRequestItemSection = shallowRef(false);
  self.requestItemObject = shallowRef(null);
  self.tabs = shallowRef([]);
  self.displayMultiSelect = shallowRef(false);
  self.currentUpperIndex;
  self.partialMenuId = partialMenuId;
  self.activateStepCallback = activateStepCallback;
  self.checkRules = initSelectionWizard.checkRules;
  self.multiSelectFilters = [];
  self.allowMultiSelectOnChangeToChangeActiveStep = true;
  // self.searchTerms = ref("").extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
  self.searchTerms = shallowRef("");
  self.preventAutoFilterRemoval = Array.isArray(
    initSelectionWizard.preventAutoFilterRemoval
  )
    ? initSelectionWizard.preventAutoFilterRemoval
    : [];
  self.preventNotificationOfSearchTermsSubscribers = false;
  self.selectTemplateOptionFunction = initSelectionWizard.selectTemplateOptionFunction;
  //   self.searchTermsSubscriptionRef = self.searchTerms.subscribe(function (
  //     value
  //   ) {
  //     if (self.preventNotificationOfSearchTermsSubscribers === false) {
  //       if (self.activeWizardStep.value.onlineSearchCallback) {
  //         if (value === "") {
  //           // go back to step with activeStepBeforeSearch
  //           goBackToStepUsingActiveStepBeforeSearchCall(value);
  //         } else {
  //           self.activeWizardStep.value.onlineSearchCallback(value);
  //         }
  //       } else {
  //         activateBottomStep(value);
  //         if (value === "") {
  //           // go back to step with activeStepBeforeSearch
  //           goBackToStepUsingActiveStepBeforeSearchCall(value);
  //         }
  //       }
  //     }
  //   });
  self.allowLastStepToComplete = true;
  self.displaySearchResultsBreadcrumb = shallowRef(false);

  watch(
    self.searchTerms,
    (newValue, oldValue) => {
      if(self !== null) {
        if (self.activeWizardStep.value.onlineSearchCallback) {
          if (newValue === "") {
            // go back to step with activeStepBeforeSearch
            goBackToStepUsingActiveStepBeforeSearchCall(newValue);
          } else {
            self.activeWizardStep.value.onlineSearchCallback(newValue);
          }
        } else {
          activateBottomStep(newValue);
          if (newValue === "") {
            // go back to step with activeStepBeforeSearch
            goBackToStepUsingActiveStepBeforeSearchCall(newValue);
          }
        }
      }
    }
      
  );

  function activateBottomStep(searchTerms) {
    var bottomStep = self.steps[self.steps.length - 1];
    if (self.activeWizardStep.value.id !== bottomStep.id) {
      activateWizardStepAndDeactivateOthers(bottomStep);
    }
    self.itemsToDisplayArray.value = [];
    self.itemsToDisplayArray.value = self.filterItemsToDisplay(searchTerms);
    if (self.searchTerms.value === "" && self.multiSelectFilters.length === 0) {
      self.displayBreadcrumbs.value = self.displayBreadcrumbsParam;
    } else {
      self.displayBreadcrumbs.value = false;
      if (!self.alwaysHideSearchResultsBreadcrumb) {
        self.displaySearchResultsBreadcrumb.value = true;
      }
    }
    // Check if the multiselect has a callback function
    if (self.allowUpdateRequestItemText === true && typeof self.updateRequestItemTextFunction === "function") {
      self.updateRequestItemTextFunction();
    }
  }

  function goBackToStepUsingActiveStepBeforeSearchCall(
    searchTerms,
    goBackToStart
  ) {
    if (self.customBreadcrumbsObject !== null) {
      self.displaySearchResultsBreadcrumb.value = false;
      //self.customBreadcrumbsObject.resetBreadcrumbsToStateBeforeSearchResults();
      self.customBreadcrumbsObject.clickFunction(
        self.customBreadcrumbsObject.breadcrumbs.value[
          self.customBreadcrumbsObject.breadcrumbs.value.length - 1
        ]
      );
    } else {
      goBackToStepUsingActiveStepBeforeSearch(searchTerms, goBackToStart);
    }
  }

  function goBackToStepUsingActiveStepBeforeSearch(searchTerms, goBackToStart) {
    var goToStart = typeof goBackToStart === "boolean" ? goBackToStart : false;
    // go back to step with activeStepBeforeSearch
    var activeStepBeforeSearchList;
    if (goToStart) {
      activeStepBeforeSearchList = self.steps[0];
    } else {
      activeStepBeforeSearchList = self.steps.filter(function (step) {
        return step.activeStepBeforeSearch === true;
      });
    }

    var lastActiveStepBeforeSearch = Array.isArray(activeStepBeforeSearchList)
      ? activeStepBeforeSearchList[activeStepBeforeSearchList.length - 1]
      : activeStepBeforeSearchList;
    if (
      self.activeWizardStep.value.id !== lastActiveStepBeforeSearch.id &&
      searchTerms === ""
    ) {
      activateWizardStepAndDeactivateOthers(lastActiveStepBeforeSearch);
    }
    var temp = self.filterItemsToDisplay(searchTerms);
    self.itemsToDisplayArray.value = [];
    self.itemsToDisplayArray.value = temp;
    if (self.searchTerms.value === "" && self.multiSelectFilters.length === 0) {
      self.displayBreadcrumbs.value = self.displayBreadcrumbsParam;
      self.displaySearchResultsBreadcrumb.value = false;
    } else if (
      self.activeWizardStep.value.filtersArray.length > 0 &&
      self.displayBreadcrumbsParam === true
    ) {
      self.displayBreadcrumbs.value = self.displayBreadcrumbsParam;
      self.displaySearchResultsBreadcrumb.value = false;
    } else if (
      self.multiSelectFilters.length === 1 &&
      (self.multiSelectFilters[0].property === "Status" ||
        self.multiSelectFilters[0].property === "getStatus" ||
        self.multiSelectFilters[0].property === "getStatusForMultiSelect")
    ) {
      self.displayBreadcrumbs.value = self.displayBreadcrumbsParam;
      self.displaySearchResultsBreadcrumb.value = false;
    } else {
      self.displayBreadcrumbs.value = false;
    }
    if (searchTerms === "") {
      globals.user.updateVehicleSelectionSearchHistorySearchTerms(searchTerms);
    }
  }

  function getPreviouslyEnteredSearchTerms() {
    return previouslyEnteredSearchTerms;
  }

  function setPreviouslyEnteredSearchTerms(newValue) {
    previouslyEnteredSearchTerms = newValue;
  }

  self.registerGetMenuCallback = function (callback) {
    getMenuCallback = callback;
  };

  function filterWithPromise() {
    filterDfd = $.Deferred();

    var searchTermsArray =
      self.trigger.value !== undefined ? self.trigger.value.split(" ") : [];
    if (self.activeWizardStep.value !== undefined) {
      var filteredArray = self.rows.filter(function (row) {
        return self.activeWizardStep.value.checkRow(row, searchTermsArray);
      });
      self.activeWizardStep.value.sortArray(filteredArray);
      filterDfd.resolve(filteredArray);
    }

    return filterDfd.promise();
  }

  self.filterItemsToDisplay = function (
    searchTerms,
    showAll,
    bypass,
    bypassFilter,
    preventMoreRowsAdded
  ) {
    var searchTermsArray,
      filteredArray = [],
      returnArray = [],
      sortedArray = [],
      sendToIntercom = false;

    //if (searchTerms !== undefined) {
    if (typeof searchTerms === "string") {
      searchTermsArray = searchTerms.split(" ");
    } else {
      searchTermsArray =
        self.trigger.value !== undefined ? self.trigger.value.split(" ") : [];
    }

    if (self.activeWizardStep.value !== undefined) {
      filteredArray =
        bypassFilter === true && searchTermsArray.length === 0
          ? self.rows
          : self.rows.filter(function (row) {
              return self.activeWizardStep.value.checkRow(
                row,
                searchTermsArray,
                showAll,
                self.multiSelectFilters
              );
            });
      if (self.activeWizardStep.value.uniqueField !== undefined) {
        filteredArray = globals.getUniqueObjectsFromArrayUsingProperty(
          filteredArray,
          self.activeWizardStep.value.uniqueField
        );
      }
      sortedArray = self.activeWizardStep.value.sortArray(filteredArray);

      if (
        sortedArray.length > self.currentUpperIndex &&
        self.activeWizardStep.value.limitResults !== undefined &&
        bypass !== true
      ) {
        returnArray = self.loadMoreResults(
          sortedArray,
          undefined,
          preventMoreRowsAdded
        );
      } else {
        returnArray = sortedArray;
      }
    }
    globals.numSearchResults = returnArray.length;
    if (searchTermsArray.length > 0 && searchTermsArray[0] !== "") {
      if (
        searchTerms !== undefined &&
        searchTerms !== getPreviouslyEnteredSearchTerms()
      ) {
        sendToIntercom = true;
        setPreviouslyEnteredSearchTerms(searchTerms);
      }

      var equipmentType = "";

      if (self.activeWizardStep.value.requestItemFunction !== undefined) {
        equipmentType = self.activeWizardStep.value.requestItemFunction(
          self.rows,
          self.activeWizardStep.value.filtersArray
        ).requestItemType;
      }

      globals.quickSearchWithSearchTermsCompleted.value = {
        searchTerms: searchTerms,
        searchTermsChanged: true,
        sendToIntercom: sendToIntercom,
        searchTermsChangeCallbackFunction:
          self.searchTermsChangeCallbackFunction,
        searchTermsIntercomEvent: self.searchTermsIntercomEvent,
        equipmentType: equipmentType,
      };
    }
    //return filteredArray;
    return returnArray;
  };

  self.loadMoreResults = function (newArray, upperIndex, preventMoreRowsAdded) {
    var resultBlockSize = self.activeWizardStep.value.limitResults;
    var returnArray = [];
    if (newArray === undefined) {
      newArray = self.filterItemsToDisplay(
        self.searchTerms.value,
        undefined,
        true
      );
    }
    if (upperIndex === undefined) {
      upperIndex = self.currentUpperIndex;
    }
    if (newArray.length > upperIndex) {
      if (!preventMoreRowsAdded) {
        upperIndex += resultBlockSize;
      }
      self.currentUpperIndex = upperIndex;
      for (var i = 0; i <= upperIndex; i++) {
        if (newArray[i] !== undefined) {
          returnArray.push(newArray[i]);
        } else {
          break;
        }
      }
    } else {
      returnArray = newArray;
    }
    return returnArray;
  };

  self.updateSort = function (columnData) {
    // Set all columns active sort to false
    self.activeWizardStep.value.setActiveSortForAllColumnsToFalse();
    // Toggle sort and set column to active
    columnData.toggleSort();
    if (typeof self.toggleSortOrderCallback === "function") {
      self.toggleSortOrderCallback(columnData);
    }
    updateItemsToDisplayArray(undefined, true, true);
  };
  self.activateMenuItem = function (searchTerms) {
    updateItemsToDisplayArray(searchTerms);
    sortItemsToDisplayArray();
    self.isActive.value = true;
    self.displayQuickSearch.value = self.getDisplayQuickSearch();
    self.displayClearButton.value = self.getDisplayClearButton();
  };

  function sortItemsToDisplayArray() {
    var sortedArray = [];
    sortedArray = self.activeWizardStep.value.sortArray(
      self.itemsToDisplayArray.value
    );
  }

  function sortRowsArray() {
    var sortedArray = [];
    sortedArray = self.activeWizardStep.value.sortArray(self.rows);
    self.rows = sortedArray;
  }

  self.deactivateMenuItem = function () {
    self.isActive.value = false;
    self.itemsToDisplayArray.value = [];
    self.activeWizardStep.value = self.steps[0];
  };
  self.clickOnItem = function (itemData, skipRuleCheck) {
    if (
      itemData.getAccessoryType !== undefined &&
      itemData.getAccessoryType() !== null
    ) {
      if (
        self.activeWizardStep.value.alwaysDisplayStep === false ||
        self.activeWizardStep.value.performCheckOnClickOnThisStep === true
      ) {
        self.checkRules(
          self.path,
          itemData.getAccessoryType(),
          itemData.getType !== undefined ? itemData.getType() : undefined,
          callback,
          itemData,
          skipRuleCheck,
          self.activeWizardStep.value.performCheckOnClickOnThisStep
        );
      } else {
        doClickOnItem(itemData);
      }
    } else {
      doClickOnItem(itemData);
    }

    function callback() {
      doClickOnItem(itemData);
    }

    function doClickOnItem(itemData) {
      if (self.activeStepIndex + 1 === self.steps.length) {
        if (self.allowLastStepToComplete) {
          self.activeWizardStep.value.clicked(itemData, self.activeStepIndex);
        }
      } else {
        fadeResults(clickOnItemFunction, itemData);
      }
    }
  };

  self.shareItem = function (itemData) {
    self.activeWizardStep.value.shareItem(itemData);
  };

  self.deleteItem = function (itemData) {
    self.activeWizardStep.value.deleteItem(itemData);
  };

  self.downloadFile = function (itemData) {
    self.activeWizardStep.value.downloadFile(itemData);
  };

  self.searchClick = function () {
    var temp = self.filterItemsToDisplay(self.searchTerms.value, true);
    self.itemsToDisplayArray.value = temp;
  };
  self.getRenderAs = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.renderAs;
    }
  };
  self.getDisplayQuickSearch = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.displayQuickSearch;
    }
  };
  self.getDisplayClearButton = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.displayClearButton;
    }
  };
  self.getDisplayTabs = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.displayTabs;
    }
  };
  self.getStepTabs = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.tabs;
    }
  };
  self.getDisplayRequestItemSection = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.displayRequestItemSection;
    }
  };
  self.getRequestItemFunction = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.requestItemFunction;
    }
  };
  self.getSetWizardTitleFunction = function () {
    if (self.activeWizardStep.value !== undefined) {
      return self.activeWizardStep.value.setWizardTitleFunction;
    }
  };
  self.initializeMultiSelects = function () {
    if (Array.isArray(self.multiSelectsArray)) {
      self.multiSelectsArray.forEach(function (ms) {
        ms.initializeMultiSelect();
        self.multiSelectFilters.forEach(function (filter) {
          if (filter.property === ms.valueColumn) {
            filter.values.forEach(function (value) {
              ms.selectItemUsingValue(value, false);
            });
          }
        });
      });
    }
  };
  self.getMultiSelectFiltersValuesUsingProperty = function (property) {
    if(Array.isArray(self.multiSelectFilters)) {
      var filter = self.multiSelectFilters.find(function (filter) {
        return filter.property === property;
      });
      return filter !== undefined ? filter.values : [];
    }
  };
  self.activateWizardStep = function (step) {
    self.itemsToDisplayArray.value = [];
    self.activeWizardStep.value = step;
    self.renderSelectionWizardAs.value = self.getRenderAs();
    self.displayQuickSearch.value = self.getDisplayQuickSearch();
    self.displayClearButton.value = self.getDisplayClearButton();
    self.displayTabs.value = self.getDisplayTabs();
    self.displayRequestItemSection.value = self.getDisplayRequestItemSection();
    if (self.displayRequestItemSection.value) {
      createRequestItemObject();
    } else {
      clearRequestItemObject();
      clearClickListeners();
    }

    if (self.displayTabs.value) {
      self.tabs.value = self.getStepTabs();
      self.activateTab(self.tabs.value[0]);
    } else {
      self.tabs.value = [];
    }
    if (
      self.getSetWizardTitleFunction() !== undefined &&
      self.rows.length > 0
    ) {
      self.title.value = self.getSetWizardTitleFunction()(
        self.itemsToDisplayArray.value
      );
    } else {
      self.title.value = self.originalTitle;
    }
    if (self.displayQuickSearch.value === true) {
      $("#" + self.activeWizardStep.value.getQuickSearchInputId()).focus();
    }
    if (self.activeWizardStep.value.displayMultiSelect === true) {
      self.initializeMultiSelects();
    }
  };

  self.handleDataGridScroll = function (eventData) {
    var element = eventData.currentTarget;
    //if (parseInt(element.scrollTop) + parseInt(element.clientHeight) === parseInt(element.scrollHeight)) {
    if (detectScrollToBottom(element)) {
      if (self.rows.length > self.currentUpperIndex) {
        self.itemsToDisplayArray.value = self.loadMoreResults();
      }
    }
  };

  self.afterRenderFunction = function () {
    addClickListeners();
  };

  function addClickListeners() {
    $(".menu-message-link.select-import").unbind("click touchstart");
    $(".menu-message-link.select-import").on(
      "click touchstart",
      function (event) {
        self.searchBarButtonCallback();
      }
    );

    $(".menu-message-link.select-template").unbind("click touchstart");
    $(".menu-message-link.select-template").on(
      "click touchstart",
      function (event) {
        // self.selectTemplateOption();
        if (typeof self.templateLinkClickListenerCallback === "function") {
          self.templateLinkClickListenerCallback();
        } else {
          self.selectTemplateOption();
        }
      }
    );

    $(".menu-message-link.select-change-status").unbind("click touchstart");
    $(".menu-message-link.select-change-status").on(
      "click touchstart",
      function (event) {
        if (typeof self.changeStatusLinkClickListenerCallback === "function") {
          self.changeStatusLinkClickListenerCallback();
        } else {
          self.changeStatusOption();
        }
      }
    );

    $(".menu-message-link.open-dialog").unbind("click touchstart");
    $(".menu-message-link.open-dialog").on(
      "click touchstart",
      function (event) {
        var onlyRunCallback = false;
        if (typeof self.openDialogClickListenerCallback === "function") {
          onlyRunCallback = self.openDialogClickListenerCallback();
        }
        if (onlyRunCallback !== true) {
          if (!globals.user.isTrialUser()) {
            if (globals.user.isUserAllowedFullAccessToRequestData() === true) {
              self.openDialog();
            } else {
              var messageCode = "4433",
                sourceForIntercom = null;
  
              if (self.path === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                messageCode = "4506";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_VEHICLE;
              } else if (self.path === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                messageCode = "4507";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_TRAILER1;
              } else if (self.path === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                messageCode = "4508";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_TRAILER2;
              } else if (
                self.path
                  .toLowerCase()
                  .includes(config.OBJECT_TYPES.BODY.toLowerCase())
              ) {
                messageCode = "4509";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_BODY;
              } else if (
                self.path
                  .toLowerCase()
                  .includes(config.OBJECT_TYPES.ACCESSORY.toLowerCase())
              ) {
                messageCode = "4510";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_ACCESSORY;
              } else if (
                self.path
                  .toLowerCase()
                  .includes(config.OBJECT_TYPES.PAYLOAD.toLowerCase())
              ) {
                messageCode = "4511";
                sourceForIntercom =
                  config.ESSENTIALS_CHECK_LOCATION
                    .CONFIGURATION_DATA_REQUEST_PAYLOAD;
              }
  
              if (sourceForIntercom !== null) {
                var metaDataObj = {};
                metaDataObj[config.INTERCOM_METADATA_ITEM.SOURCE] =
                  sourceForIntercom;
                dataManager.sendInfoToIntercom(
                  config.OPERATION.SendIntercomEventData,
                  globals.getIntercomObject(
                    config.INTERCOM_EVENT.UPGRADE_CONSIDERED,
                    metaDataObj
                  )
                );
              }
  
              globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference(messageCode)))
                  .addCallback(function (dialogResult) {
                    nextTick(function(){
                      bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: sourceForIntercom });
                    });
                  });
            }
          } else {
            // Runs on 'ask us to add it.' link text to open request a vehicle modal.
            globals.showDialog(new CustomMessageBox(config.getMessageText('4433', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('54'), config.getTranslationText('65')], config.getMessageReference('4433')))
                .addCallback(function (clickedButtonText) {
                    if (clickedButtonText === config.getTranslationText('54')) {
                        nextTick(function(){
                          bus.emit('openBuyNowModal');
                        });
                    }
                });
            return false;
          }
        }
        
      }
    );
  }

  function clearClickListeners() {
    $(".menu-message-link.select-template").unbind("click touchstart");
    $(".menu-message-link.select-change-status").unbind("click touchstart");
    $(".menu-message-link.open-dialog").unbind("click touchstart");
  }

  function createRequestItemObject() {
    var rio = {};

    if (self.searchBarButtonCallback !== null) {
      //rio.requestItemText = globals.user.hasFullSupportPlan() === true ? config.getTranslationText('1348') : config.getTranslationText('1349');
      rio.requestItemText = shallowRef(config.getTranslationText("1348"));
    } else {
      //rio.requestItemText = globals.user.hasFullSupportPlan() === true ? config.getTranslationText('1025') : config.getTranslationText('1026');
      rio.requestItemText = shallowRef(self.requestItemText !== null ? self.requestItemText : config.getTranslationText("1025"));
    }
    rio.openWizardOption = function () {
      self.searchBarButtonCallback();
    };
    rio.selectTemplateOption = function () {
      self.selectTemplateOption();
    };
    rio.openDialog = function () {
      self.openDialog();
    };
    self.requestItemObject.value = rio;
  }

  function clearRequestItemObject() {
    if(self.requestItemObject.value !== null) {
      if(isRef(self.requestItemObject.value.requestItemText)) {
        self.requestItemObject.value.requestItemText.value = null;
      }
    }
    
    self.requestItemObject.value = null;
  }

  function detectScrollToBottom(element) {
    if (
      parseInt(element.scrollTop) + parseInt(element.clientHeight) >
      parseInt(element.scrollHeight) - 5
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 
   */
  self.updateRequestItemText = function (text, tooltipText) {
    if (self.allowUpdateRequestItemText === true && typeof self.updateRequestItemTextFunction === "function" && self.requestItemObject.value !== null) {
      // var temp = self.requestItemObject.value;
      // temp.requestItemText = self.updateRequestItemTextFunction();
      // self.requestItemObject.value = temp;
      self.requestItemObject.value.requestItemText.value = text;
    }
  }

  self.resetSelectionWizard = function () {
    activateWizardStepAndDeactivateOthers(self.steps[0]);
  };
  self.activateWizardStepFromBreadcrumb = function (step) {
    self.searchTerms.value = "";
    fadeResults(activateWizardStepFromBreadcrumbFunction, step);
  };
  self.backButtonClick = function () {
    self.activateWizardStepFromBreadcrumb(self.steps[self.activeStepIndex - 1]);
  };
  self.goBackToStart = function () {
    self.activateWizardStepFromBreadcrumb(self.steps[0]);
  };
  self.removeRowBasedOnId = function (
    id,
    updateCounter,
    updateItemsToDisplayArray
  ) {
    var temp = self.rows.filter(function (item) {
      return item.Id !== id && item.UpdateCounter === updateCounter;
    });
    self.rows = temp;
    if (updateItemsToDisplayArray) {
      updateItemsToDisplayArray();
    }
  };
  /**
   * Replace the array of row object with new row objects
   * @param {object[]} newRowsArray - Array of new row objects
   */
  self.updateRowsArray = function (newRowsArray) {
    // Run clean up code on rows Array if it exists
    self.rows.forEach(function (row) {
      if (typeof row.cleanUp === "function") {
        row.cleanUp();
      }
    });
    // Update the array
    self.rows = newRowsArray;
    // Update itemsToDisplayArray to change results on screen
    if (updateItemsToDisplayArray) {
      updateItemsToDisplayArray();
    }
  };
  self.activateTab = function (clickedTab) {
    if (typeof clickedTab.clickFunction === "function") {
      clickedTab.clickFunction(self);
    } else {
      self.tabs.value.forEach(function (tab) {
        tab.deactivateTab();
        // remove from filtersArray
        self.activeWizardStep.value.filtersArray =
          self.activeWizardStep.value.filtersArray.filter(function (
            filterItem
          ) {
            if (typeof tab.clickFunction === "function") {
              return true;
            } else {
              return (
                filterItem.property !== tab.filterObject.property &&
                filterItem.value !== tab.filterObject.value
              );
            }
          });
      });
      clickedTab.activateTab();
      self.activeWizardStep.value.filtersArray.push(clickedTab.filterObject);
      updateItemsToDisplayArray();
    }
  };

  self.selectTemplateOption = function () {
    var templateTab = self.tabs.value.filter(function (tab) {
      if (tab.filterObject === undefined) {
        return false;
      }
      return tab.filterObject.value === config.ACCESSORY_SOURCE_TYPES.TEMPLATE;
    });
    self.searchTerms.value = "";
    self.activateTab(templateTab[0]);
  };

  self.changeStatusOption = function () {
    var statusMultiselect = self.getMultiSelectByName('MULTISELECT-MANUFACTURER-STATUS');
    if (statusMultiselect !== null) {
      var currentStatusMultiSelectOptionsChecked = [],
        statusMultiselectFiltersChecked = self.getMultiSelectFiltersValuesUsingProperty('Status');

      statusMultiselectFiltersChecked.forEach(function (option) {
        currentStatusMultiSelectOptionsChecked.push(option);
      });
      statusMultiselect.deselectAllOptions();
      // Set all statuses to be selected
      var availableManufacturerStatuses = globals.user.getAvailableManufacturerStatusesArray();
      availableManufacturerStatuses.forEach(function (status) {
        statusMultiselect.selectItemUsingValue(status.value, true);
      });
      // Refresh the multiselect
      statusMultiselect.refreshMultiSelect();
      // Update the items to display array
      // updateItemsToDisplayArray();
      // Update the text 
      statusMultiselect.popoverTooltipText.value = config.getTranslationText(globals.getManufacturerRequestItemTooltipTextCode(availableManufacturerStatuses, currentStatusMultiSelectOptionsChecked));
      
      nextTick(function(){
        // Show tooltip
        var options = {
          trigger: "manual",
          closeable: true,
          animation: "pop",
          style: "status-multiselect-tooltip",
          // style: "inverse",
        };
        $('#' + statusMultiselect.selectId + '-multiselect-div').webuiPopover(options);
        $('#' + statusMultiselect.selectId + '-multiselect-div').webuiPopover("show");

        setTimeout(function () {
          $('.webui-popover-status-multiselect-tooltip').addClass('remove-styling');
        }, 2000);
        setTimeout(function () {
          $('#' + statusMultiselect.selectId + '-multiselect-div').webuiPopover("hide");
        }, 5000);
      });
    }
  };

  self.openDialog = function () {
    //requestItemModal.show(self.activeWizardStep.value.requestItemFunction(self.rows, self.activeWizardStep.value.filtersArray)).then(function (response) {});
    var requestItemModalObject = self.activeWizardStep.value.requestItemFunction(self.rows, self.activeWizardStep.value.filtersArray);
    globals.showDialog({ 
        type: 'RequestItemModal', 
        callback: function (dialogResult) {
            if (dialogResult !== undefined) {
                TScMessenger.writeDebugMessage('');
            }
        },
        requestItemModalObject: requestItemModalObject,
        displayMode: config.MODAL_DISPLAY_MODE.OVERLAY
    });
  };

  function updateItemsToDisplayArray(
    searchTerms,
    bypassFilter,
    preventMoreRowsAdded
  ) {
    var searchTermsToUse =
      searchTerms !== undefined ? searchTerms : self.searchTerms.value;
    self.itemsToDisplayArray.value = [];
    var temp = self.filterItemsToDisplay(
      searchTermsToUse,
      undefined,
      undefined,
      bypassFilter,
      preventMoreRowsAdded
    );
    self.itemsToDisplayArray.value = temp;
  }
  self.updateItemsToDisplayArray = updateItemsToDisplayArray;

  function clickOnItemFunction(itemData) {
    // deactivate current step
    var filterObject = self.activeWizardStep.value.moveToNextStep(itemData);
    // update self.activeMenuIndex
    var tempFiltersArray = [];
    self.activeWizardStep.value.filtersArray.forEach(function (filter) {
      tempFiltersArray.push(filter);
    });
    tempFiltersArray.push(filterObject);
    self.activeStepIndex += 1;
    self.steps[self.activeStepIndex].filtersArray = tempFiltersArray;
    self.steps[self.activeStepIndex].activateWizardStep(true);
    if (
      typeof self.steps[self.activeStepIndex].clickOnItemCallback === "function"
    ) {
      self.steps[self.activeStepIndex].clickOnItemCallback(self, filterObject);
    }
    // activate next step
    self.activateWizardStep(self.steps[self.activeStepIndex]);
    // update self.activeWizardStep
    updateItemsToDisplayArray();
    setupBackButton();
    updateMultiSelectsUsingNewFilterObject(filterObject);
    if (self.partialMenuId !== undefined) {
      if (getMenuCallback !== null) {
        if (getMenuCallback(self.partialMenuId).menuDocked.value) {
          globals.repositionMenu(self.partialMenuId);
        }
      }
    }
    return true;
  }
  function updateMultiSelectsUsingFiltersArray() {
    if (self.multiSelectsArray.length > 0) {
      // Check if value has been set alreadt
    }
  }
  function updateMultiSelectsUsingNewFilterObject(newFilterObject) {
    if (self.multiSelectsArray !== null && self.multiSelectsArray.length > 0) {
      self.allowMultiSelectOnChangeToChangeActiveStep = false;
      var ms;
      for (var i = 0; i < self.multiSelectsArray.length; i++) {
        ms = self.multiSelectsArray[i];
        if (ms.valueColumn === newFilterObject.property) {
          ms.selectItemUsingValue(newFilterObject.value, true);
          break;
        }
      }
      self.allowMultiSelectOnChangeToChangeActiveStep = true;
    }
  }
  function activateWizardStepFromBreadcrumbFunction(step) {
    activateWizardStepAndDeactivateOthers(step, true);
    updateItemsToDisplayArray();
    if (self.partialMenuId !== undefined) {
      globals.repositionMenu(self.partialMenuId);
    }
    self.currentUpperIndex = 0;
    return true;
  }
  function activateWizardStepAndDeactivateOthers(
    step,
    allowMultiSelectsToUpdate
  ) {
    var tempId = step.id + 1;
    // deactivate other steps using array/id
    for (var i = tempId; i < self.steps.length; i++) {
      self.steps[i].deactivateWizardStep();
    }
    // activate step
    self.activeStepIndex = step.id;
    step.activateWizardStep();
    if (allowMultiSelectsToUpdate === true) {
      updateMultiSelects(step);
    }
    // update observable
    self.activateWizardStep(step);
    // update self.activeWizardStep
    var temp = self.filterItemsToDisplay(undefined);
    self.itemsToDisplayArray.value = [];
    self.itemsToDisplayArray.value = temp;
    setupBackButton();

    function updateMultiSelects(stepToUse) {
      var filtersToRemoveArray = [];
      // get items to remove from multiSelectFilters
      var count = 0,
        allow = true,
        filter;
      for (var j = self.multiSelectFilters.length - 1; j >= 0; j--) {
        filter = self.multiSelectFilters[j];
        for (var i = 0; i < stepToUse.filtersArray.length; i++) {
          if (stepToUse.filtersArray[i].property === filter.property) {
            count += 1;
          }
        }
        if (count === 0) {
          self.preventAutoFilterRemoval.forEach(function (newFilter) {
            if (newFilter.filterPropertyName === filter.property) {
              allow = false;
            }
          });
          if (allow) {
            filtersToRemoveArray.push(filter);
          }
        }
      }

      filtersToRemoveArray.forEach(function (filter) {
        removeFromMultiSelectFiltersArray(filter.property);
        self.clearMultiSelectUsingProperty(filter.property);
      });
    }
  }
  function setupBackButton() {
    if (self.displayBackButton) {
      if (self.activeWizardStep.value.id === 0) {
        self.displayBackButtonOnScreen.value = false;
      } else {
        self.displayBackButtonOnScreen.value = true;
      }
    }
  }
  self.setupWizardUsingPath = function (path) {
    var pathIndex = path.activeStepIndex,
      pathFiltersArray = path.filtersArray;

    if (
      pathIndex === self.steps.length - 1 &&
      self.displayBreadcrumbs.value === false
    ) {
      activateBottomStep();
    } else {
      for (var i = 0; i < pathIndex; i++) {
        if (pathFiltersArray[i] !== undefined) {
          clickOnItemFunction(pathFiltersArray[i]);
        } else {
          if (pathIndex === self.steps.length - 1) {
            activateBottomStep();
          }
          break;
        }
      }
    }
  };
  /**
   * Set the active sort on a DatagridColumn using object as parameter
   * @param {Object} sortObject - Expected object to contain keys itemToSort/sortType
   */
  self.setSortUsingObject = function (sortObject) {
    self.activeWizardStep.value.setActiveSortForAllColumnsToFalse();
    self.activeWizardStep.value.setActiveSortUsingObject(sortObject);
  };

  /**
   *
   * @param {object[]} sortObjectArray
   */
  self.setSortUsingArray = function (sortObjectArray) {
    self.activeWizardStep.value.updateSortUsingArray(sortObjectArray);
  };
  function fadeResults(callback, data) {
    var temp =
      self.containerId !== undefined
        ? "#" + self.containerId
        : ".datagrid-container-div";
    $(temp).fadeOut(300, function () {
      completionCallback(callback, data, temp);
    });
  }
  function completionCallback(callback, data, id) {
    var fadeInResults = callback(data);
    if (fadeInResults) {
      $(id).fadeIn(300, function () {
        if (typeof self.activateStepCallback === "function") {
          self.activateStepCallback();
        }
      });
    } else {
      $(id).hide();
    }
  }
  function getSelectionWizard() {
    return self;
  }
  self.getItemsToDisplayArray = function () {
    return self.itemsToDisplayArray.value;
  };
  self.updateToolbar = function () {
    if (self.toolbar !== null) {
      self.toolbar.updateToolbarButtons(self.getItemsToDisplayArray());
    }
  };
  self.getSelectedRows = function () {
    return self.rows.filter(function (row) {
      return row.isSelected.value;
    });
  };
  self.deselectAllRows = function () {
    // deselect all rows
    self.rows.forEach(function (row) {
      row.isSelected.value = false;
    });
    // Update the select all checkbox
    var selectAllColumn = self.activeWizardStep.value.getSelectAllColumn();
    if (selectAllColumn !== undefined) {
      selectAllColumn.isChecked.value =
        config.SELECT_ALL_CHECKBOX_OPTIONS.NOT_CHECKED;
    }
  };

  construct(initSelectionWizard);

  function construct(selectionWizard) {
    self.placeholderText = selectionWizard.placeholderText;
    self.isActive = shallowRef(false);
    self.steps = [];
    self.displayBreadcrumbsParam =
      typeof selectionWizard.displayBreadcrumbs === "boolean"
        ? selectionWizard.displayBreadcrumbs
        : false;
    self.alwaysHideSearchResultsBreadcrumb =
      typeof selectionWizard.alwaysHideSearchResultsBreadcrumb === "boolean"
        ? selectionWizard.alwaysHideSearchResultsBreadcrumb
        : false;
    self.displayBreadcrumbs = shallowRef(self.displayBreadcrumbsParam);
    self.containerId = selectionWizard.containerId;
    // self.displayBreadcrumbs.subscribe(function (newValue) {
    //   // Update the user if breadcrumbs are displayed
    //   globals.user.updateVehicleSelectionSearchHistoryDisplayBreadcrumbs(
    //     newValue
    //   );
    // });
    if (selectionWizard.steps !== undefined) {
      var index = 0;
      selectionWizard.steps.forEach(function (step) {
        self.steps.push(
          new SelectionWizardStep(
            index,
            step,
            self.containerId,
            getSelectionWizard
          )
        );
        index += 1;
      });
    }
    self.rows = selectionWizard.rows;
    self.multiSelectsArray =
      selectionWizard.multiSelectsArray !== undefined
        ? selectionWizard.multiSelectsArray
        : null;
    if (self.multiSelectsArray !== null) {
      // Register multiselectOnChangeFunction on multiselect
      self.multiSelectsArray.forEach(function (ms) {
        ms.registerOnChangeCallbackFunction(multiselectOnChangeFunction);
        ms.registerOnDeselectAllCallbackFunction(
          multiselectOnDeselectAllFunction
        );
      });
    }
    if (selectionWizard.displayMultiSelect) {
      self.displayMultiSelect.value = selectionWizard.displayMultiSelect;
    }

    self.activateWizardStep(self.steps[0]);
    self.steps[0].activeStepBeforeSearch = true;

    self.activeStepIndex = 0;
    self.rowType = selectionWizard.rowType;
    self.title.value = selectionWizard.title;
    self.originalTitle = selectionWizard.title;
    self.displayBackButton = selectionWizard.displayBackButton;
    self.displayBackButtonOnScreen.value = false;
    self.currentUpperIndex = 0;
    self.path = selectionWizard.path;
    self.isTrailerSelectionWizard =
      selectionWizard.isTrailerSelectionWizard !== undefined
        ? selectionWizard.isTrailerSelectionWizard
        : false;
    self.searchTermsChangeCallbackFunction =
      selectionWizard.searchTermsChangeCallbackFunction;
    self.searchTermsIntercomEvent = selectionWizard.searchTermsIntercomEvent;
    self.customCss =
      selectionWizard.customCss !== undefined ? selectionWizard.customCss : "";
    self.getMenuCallback =
      selectionWizard.getMenuCallback !== undefined
        ? selectionWizard.getMenuCallback
        : null;
    self.toggleSortOrderCallback =
      selectionWizard.toggleSortOrderCallback !== undefined
        ? selectionWizard.toggleSortOrderCallback
        : null;

    self.filterOption =
      selectionWizard.filterOption !== undefined
        ? selectionWizard.filterOption
        : config.WIZARD_FILTER_OPTIONS.DEFAULT;

    //self.displaySearchBarButton = typeof selectionWizard.displaySearchBarButton === 'boolean' ? selectionWizard.displaySearchBarButton : false;
    self.displaySearchBarButton = false;
    self.searchBarButtonCallback =
      typeof selectionWizard.searchBarButtonCallback === "function"
        ? selectionWizard.searchBarButtonCallback
        : null;
    self.searchBarButtonText =
      typeof selectionWizard.searchBarButtonText === "string"
        ? selectionWizard.searchBarButtonText
        : "";
    self.searchBarButtonClass =
      typeof selectionWizard.searchBarButtonClass === "string"
        ? selectionWizard.searchBarButtonClass
        : "";

    self.deleteIconTooltipText =
      typeof selectionWizard.deleteIconTooltipText === "string"
        ? selectionWizard.deleteIconTooltipText
        : "";
    self.noEditIconTooltipText =
      typeof selectionWizard.noEditIconTooltipText === "string"
        ? selectionWizard.noEditIconTooltipText
        : "";
    self.shareIconTooltipText =
      typeof selectionWizard.shareIconTooltipText === "string"
        ? selectionWizard.shareIconTooltipText
        : "";
    self.clearButtonTooltip =
      typeof selectionWizard.clearButtonTooltip === "string"
        ? selectionWizard.clearButtonTooltip
        : "";
    self.displayResetButtonOnSameRowAsSearch =
      typeof selectionWizard.displayResetButtonOnSameRowAsSearch === "boolean"
        ? selectionWizard.displayResetButtonOnSameRowAsSearch
        : false;
    self.downloadFileTooltipText =
      typeof selectionWizard.downloadFileTooltipText === "string"
        ? selectionWizard.downloadFileTooltipText
        : "";
    self.openDialogClickListenerCallback =
      typeof selectionWizard.openDialogClickListenerCallback === "function"
        ? selectionWizard.openDialogClickListenerCallback
        : null;
    self.templateLinkClickListenerCallback = 
      typeof selectionWizard.templateLinkClickListenerCallback === "function"
        ? selectionWizard.templateLinkClickListenerCallback
        : null;
    self.changeStatusLinkClickListenerCallback =
      typeof selectionWizard.changeStatusLinkClickListenerCallback === "function"
        ? selectionWizard.changeStatusLinkClickListenerCallback
        : null;
    self.requestItemText = typeof selectionWizard.requestItemText === "string" ? selectionWizard.requestItemText : null;
    self.allowUpdateRequestItemText = typeof selectionWizard.allowUpdateRequestItemText === "boolean" ? selectionWizard.allowUpdateRequestItemText : false;
    self.updateRequestItemTextFunction = typeof selectionWizard.updateRequestItemTextFunction === "function" ? selectionWizard.updateRequestItemTextFunction : null;
    if (selectionWizard.displayToolbar === true) {
      self.displayToolbar.value = true;
      self.toolbar = new WizardToolbar(selectionWizard.toolbar);
      self.updateToolbar();
    } else {
      self.toolbar = null;
    }
    if (selectionWizard.displayCustomBreadcrumbs === true) {
      self.displayCustomBreadcrumbs.value = true;
      self.customBreadcrumbsObject = new WizardCustomBreadcrumbsObject(
        selectionWizard.customBreadcrumbsObject
      );
    } else {
      self.customBreadcrumbsObject = null;
    }

    self.dialogDisplayMode = typeof selectionWizard.dialogDisplayMode === "string" ? selectionWizard.dialogDisplayMode : config.MODAL_DISPLAY_MODE.QUEUE;

    // TODO: This is test code and should be deleted
    self.itemsToDisplayArray.value = self.rows;
  }

  self.cleanUp = function () {
    if (self.searchTermsSubscriptionRef) {
      self.searchTermsSubscriptionRef.dispose();
      //utils.clearSubscriptions(self.searchTermsSubscriptionRef._target);
      self.searchTermsSubscriptionRef = null;
    }
    //utils.clearSubscriptions(self.activeWizardStep);
    //self.activeWizardStep(null);
    //self.activeWizardStep = null;
    self.trigger.value = null;
    self.trigger = null;
    // utils.clearSubscriptions(self.itemsToDisplayArray);
    // self.itemsToDisplayArray.removeAll();
    // self.itemsToDisplayArray.value = null;
    // self.itemsToDisplayArray = null;
    // utils.clearSubscriptions(self.renderSelectionWizardAs);
    // self.renderSelectionWizardAs.value = null;
    // self.renderSelectionWizardAs = null;
    // utils.clearSubscriptions(self.displayQuickSearch);
    if (self.displayQuickSearch) {
      self.displayQuickSearch.value = null;
      self.displayQuickSearch = null;
    }
    if (self.displayClearButton) {
      try {
        utils.clearSubscriptions(self.displayClearButton);
        self.displayClearButton.value = null;
      } catch (buttonNullEx) {
        var breakHere = 0;
      }
      self.displayClearButton = null;
    }

    utils.clearSubscriptions(self.displayToolbar);
    self.displayToolbar.value = null;
    self.displayToolbar = null;

    //self.displayBackButtonOnScreen(null);
    self.displayBackButtonOnScreen = null;
    self.activateStepCallback = null;
    self.checkRules = null;
    if (self.searchTerms) {
      self.searchTerms.value = null;
      self.searchTerms = null;
    }

    self.activateWizardStep = null;
    self.activeStepIndex = null;
    self.rowType = null;
    self.containerId = null;
    self.title.value = null;
    self.title = null;
    self.originalTitle = null;
    self.displayBackButton = null;
    //self.displayBackButtonOnScreen(null);
    //self.displayBackButtonOnScreen = null;
    self.currentUpperIndex = null;
    self.path = null;
    self.isTrailerSelectionWizard = null;
    self.searchTermsChangeCallbackFunction = null;
    self.searchTermsIntercomEvent = null;
    self.customCss = null;
    self.getMenuCallback = null;
    self.toggleSortOrderCallback = null;

    if (self.displayMultiSelect) {
      self.displayMultiSelect.value = null;
      self.displayMultiSelect = null;
    }
    self.filterOption = null;

    self.placeholderText = null;
    self.isActive = null;
    if (self.steps !== undefined) {
      self.steps.forEach(function (step) {
        step.cleanUp();
      });
      self.steps = null;
    }
    self.displayBreadcrumbs = null;
    self.rows.forEach(function (row) {
      if (row.cabToAxlesFormatted) {
        if (row.cabToAxlesFormatted.dispose) {
          row.cabToAxlesFormatted.dispose();
        }
        row.cabToAxlesFormatted = null;
      }

      var breakHere = 0;
      //row.gvwFormatted.dispose();
      //row.gvwFormatted = null;

      //row.payloadFormatted.dispose();
      //row.payloadFormatted = null;

      //row.gcwFormatted.dispose();
      //row.gcwFormatted = null;

      //row.ShowAsUnavailable.dispose();
      //row.ShowAsUnavailable = null;

      //row.wheelbaseFormatted.dispose();
      //row.wheelbaseFormatted = null;

      row = null;
    });
    self.rows = null;

    if (
      self.multiSelectsArray !== undefined &&
      self.multiSelectsArray !== null
    ) {
      self.multiSelectsArray.forEach(function (ms) {
        ms.cleanUp();
        ms = null;
      });
      self.multiSelectsArray = null;
    }
    if (self.multiSelectFilters) {
      self.multiSelectFilters = null;
    }
    if (self.preventAutoFilterRemoval) {
      self.preventAutoFilterRemoval = null;
    }
    initSelectionWizard.cleanUp();
    self = null;
  };

  self.getMultiSelectByName = function (name) {
    var returnObject = null;
    if (Array.isArray(self.multiSelectsArray)) {
      returnObject = self.multiSelectsArray.find(function (el) {
        return el.multiselectName === name;
      });
    }
    return returnObject !== undefined ? returnObject : null;
  };

  //self.initializeMultiSelects = function () {
  //    self.multiSelectsArray.forEach(function (ms) {
  //        ms.initializeMultiSelect();
  //        self.multiSelectFilters.forEach(function (filter) {
  //            if (filter.property === ms.valueColumn) {
  //                filter.values.forEach(function (value) {
  //                    ms.selectItemUsingValue(value, false);
  //                });
  //            }
  //        });
  //    });
  //};

  self.getMultiSelectByPartialName = function (partialName) {
    var returnObject = null;
    if (Array.isArray(self.multiSelectsArray)) {
      returnObject = self.multiSelectsArray.find(function (el) {
        return el.multiselectName.includes(partialName) === true;
      });
    }
    return returnObject !== undefined ? returnObject : null;
  };

  function addToMultiSelectFiltersArray(propertyName, value) {
    var idx = self.multiSelectFilters.findIndex(function (filter) {
      return filter.property === propertyName;
    });
    if (idx >= 0) {
      if (!self.multiSelectFilters[idx].values.includes(value)) {
        self.multiSelectFilters[idx].values.push(value);
      }
    } else {
      self.multiSelectFilters.push({
        property: propertyName,
        values: [value],
      });
    }
  }

  function removeFromMultiSelectFiltersArray(propertyName, value) {
    // remove from multiSelectFilters
    // Get index
    var indexToRemove = self.multiSelectFilters.findIndex(function (element) {
      return element.property === propertyName;
    });
    if (self.multiSelectFilters[indexToRemove].values.length > 1) {
      // More than 1 value so only remove that value
      var valueIndexToRemove = self.multiSelectFilters[
        indexToRemove
      ].values.findIndex(function (existingValue) {
        return existingValue === value;
      });
      self.multiSelectFilters[indexToRemove].values.splice(
        valueIndexToRemove,
        1
      );
    } else {
      // Only 1 value attached so remove property from array
      self.multiSelectFilters.splice(indexToRemove, 1);
    }

    // Also remove from self.activeWizardStep().filtersArray if it exists
    indexToRemove = self.activeWizardStep.value.filtersArray.findIndex(
      function (element) {
        return element.property === propertyName;
      }
    );

    if (indexToRemove >= 0) {
      self.activeWizardStep.value.filtersArray.splice(indexToRemove, 1);
    }
  }

  /**
   * Funcion that is called when user clicks on an option in a multiselect control
   * @param {Element} option - The option that was changed
   * @param {boolean} checked - Option has been checked/unchecked (true/false)
   * @param {Object} ms - Multiselect that was triggered
   */
  function multiselectOnChangeFunction(option, checked, ms) {
    TScMessenger.writeDebugMessage(
      "in selection wizard multiselectOnChangeFunction"
    );

    if (checked) {
      // add to multiSelectFilters
      //self.multiSelectFilters.push({
      //    property: ms.valueColumn,
      //    value: option[0].value
      //});
      addToMultiSelectFiltersArray(ms.valueColumn, option[0].value);
    } else {
      //// remove from multiSelectFilters
      //// Get index
      //var indexToRemove = self.multiSelectFilters.findIndex(function (element) {
      //    return element.property === ms.valueColumn && element.value === option[0].value;
      //});
      //// Remove item from array
      //self.multiSelectFilters.splice(indexToRemove, 1);
      removeFromMultiSelectFiltersArray(ms.valueColumn, option[0].value);
    }

    globals.user.updateVehicleSelectionSearchHistoryMultiSelectFilters(
      self.multiSelectFilters
    );

    // Check if the multiselect has a callback function
    if (self.allowUpdateRequestItemText === true && typeof self.updateRequestItemTextFunction === "function") {
      self.updateRequestItemTextFunction();
    }

    if (
      self.multiSelectFilters.length > 0 &&
      self.allowMultiSelectOnChangeToChangeActiveStep
    ) {
      if (
        ms.multiselectName.includes("STATUS") ||
        ms.multiselectName.includes("COUNTRY")
      ) {
        self.itemsToDisplayArray.value = self.filterItemsToDisplay(
          self.searchTerms.value
        );
      } else {
        var bottomStep = self.steps[self.steps.length - 1];
        if (self.activeWizardStep.value.id !== bottomStep.id) {
          activateWizardStepAndDeactivateOthers(bottomStep);
        }
        self.itemsToDisplayArray.value = self.filterItemsToDisplay(
          self.searchTerms.value
        );
        self.displayBreadcrumbs.value = false;
      }
    } else {
      goBackToStepUsingActiveStepBeforeSearchCall(self.searchTerms.value);
    }
  }

  /**
   * Funcion that is called when user clicks on Clear button in multiselect
   * @param {Object} ms - Multiselect that was triggered
   */
  function multiselectOnDeselectAllFunction(ms, updateResults) {
    var changeResults =
      typeof updateResults === "boolean" ? updateResults : true;
    if (self.multiSelectFilters.length > 0) {
      // Clear
      var filtersToClear = [];
      self.multiSelectFilters.forEach(function (element) {
        if (element.property === ms.valueColumn) {
          filtersToClear.push(element);
        }
      });

      filtersToClear.forEach(function (elementToRemove) {
        // Get index
        var indexToRemove = self.multiSelectFilters.findIndex(function (
          element
        ) {
          if (elementToRemove.valueColumn) {
            return (
              element.property === elementToRemove.valueColumn &&
              element.value === elementToRemove.value
            );
          } else {
            return (
              element.property === elementToRemove.property &&
              element.values === elementToRemove.values
            );
          }
        });
        // Remove item from array
        self.multiSelectFilters.splice(indexToRemove, 1);

        // Also remove from self.activeWizardStep().filtersArray if it exists
        indexToRemove = self.activeWizardStep.value.filtersArray.findIndex(
          function (element) {
            return element.property === elementToRemove.property;
          }
        );

        if (indexToRemove >= 0) {
          self.activeWizardStep.value.filtersArray.splice(indexToRemove, 1);
        }
      });
      globals.user.updateVehicleSelectionSearchHistoryMultiSelectFilters(
        self.multiSelectFilters
      );

      if (typeof ms.onDeselectAllExtraCallback === "function") {
        ms.onDeselectAllExtraCallback();
      }

      if (changeResults) {
        // Have selections been made in other multiselects
        if (self.multiSelectFilters.length === 0) {
          // Go back to the previously active step
          goBackToStepUsingActiveStepBeforeSearchCall(self.searchTerms.value);
        } else {
          // Leave it and update results
          self.itemsToDisplayArray.value = self.filterItemsToDisplay();
        }
      }
    }
  }

  self.setMultiSelectFilters = function (newMultiSelectFilters) {
    self.multiSelectFilters = newMultiSelectFilters;
    if (self.multiSelectFilters.length > 0) {
      var filter, ms;
      for (var i = 0; i < self.multiSelectFilters.length; i++) {
        filter = self.multiSelectFilters[i];
        if (self.multiSelectsArray !== null) {
          for (var j = 0; j < self.multiSelectsArray.length; j++) {
            ms = self.multiSelectsArray[j];
            if (ms.valueColumn === filter.property) {
              // Update the multiselect without triggering the onChange function
              if (filter.value !== undefined) {
                ms.selectItemUsingValue(filter.value, false);
              }
              if (filter.values !== undefined && Array.isArray(filter.values)) {
                filter.values.forEach(function (value) {
                  ms.selectItemUsingValue(value, false);
                });
              }
            }
          }
        }
      }
    }
  };

  self.clearMultiSelectUsingProperty = function (propertyName) {
    var idx = self.multiSelectsArray.findIndex(function (ms) {
      return ms.valueColumn === propertyName;
    });
    if (idx > -1) {
      //self.multiSelectsArray[idx].onDeselectAllCallback(self.multiSelectsArray[idx], false);
      if (
        typeof self.multiSelectsArray[idx].onDeselectAllCallback === "function"
      ) {
        self.multiSelectsArray[idx].onDeselectAllCallback(
          self.multiSelectsArray[idx],
          false
        );
      }
      if (
        typeof self.multiSelectsArray[idx].onDeselectAllExtraCallback ===
        "function"
      ) {
        self.multiSelectsArray[idx].onDeselectAllExtraCallback();
      }
      $("#" + self.multiSelectsArray[idx].selectId).multiselect(
        "deselectAll",
        false
      );
      $("#" + self.multiSelectsArray[idx].selectId).multiselect(
        "updateButtonText"
      );
    }
  };

  self.clearAllFilters = function (
    preventNotificationOfSearchTermsSubscribers,
    preventClearingOfSearchTerms
  ) {
    var multiSelectToUse = null;
    // #unfinished - Look for way to update UI without affecting rateLimit
    if (
      typeof preventNotificationOfSearchTermsSubscribers === "boolean" &&
      preventNotificationOfSearchTermsSubscribers === true &&
      self.customBreadcrumbsObject !== null
    ) {
      self.preventNotificationOfSearchTermsSubscribers = true;
      setTimeout(function () {
        self.preventNotificationOfSearchTermsSubscribers = false;
      }, 1500);
    }
    if (preventClearingOfSearchTerms !== true) {
      self.searchTerms.value = "";
    }
    while (self.multiSelectFilters.length > 0) {
      self.multiSelectFilters.forEach(function (filter) {
        var idx = self.multiSelectsArray.findIndex(function (ms) {
          return ms.valueColumn === filter.property;
        });
        if (idx > -1) {
          if (
            typeof self.multiSelectsArray[idx].onDeselectAllCallback ===
            "function"
          ) {
            self.multiSelectsArray[idx].onDeselectAllCallback(
              self.multiSelectsArray[idx],
              false
            );
          }
          $("#" + self.multiSelectsArray[idx].selectId).multiselect(
            "deselectAll",
            false
          );
          $("#" + self.multiSelectsArray[idx].selectId).multiselect(
            "updateButtonText"
          );
        }
      });
    }
    multiSelectToUse = self.getMultiSelectByPartialName("STATUS");
    if (multiSelectToUse !== null) {
      if (
        multiSelectToUse.multiselectName === "MULTISELECT-MANUFACTURER-STATUS"
      ) {
        globals.user.setDefaultMultiselectFilters();
        self.setMultiSelectFilters(
          globals.user.getVehicleSelectionSearchHistoryMultiSelectFilters()
        );
      } else {
        if (multiSelectToUse.multiselectName.includes("VEHICLE")) {
          self.setMultiSelectFilters(
            globals.getDefaultManufacturerSelectionWizardMultiSelectFilterObject()
          );
        } else {
          self.setMultiSelectFilters([
            {
              property: "getStatusForMultiSelect",
              // values: [config.getTranslationText('4930')],
              values: [config.VEHICLE_STATUS_TYPES.CURRENT]
            },
          ]);
        }
      }
      // multiSelectToUse.selectItemUsingValue(config.getTranslationText('4930'), false);
      multiSelectToUse.selectItemUsingValue(config.VEHICLE_STATUS_TYPES.CURRENT, false);
    }
    if (
      !self.preventNotificationOfSearchTermsSubscribers &&
      self.activeWizardStep.value.stayOnStepWhenClearAllFiltersClicked === false
    ) {
      goBackToStepUsingActiveStepBeforeSearchCall(self.searchTerms.value, true);
    }
    self.itemsToDisplayArray.value = self.filterItemsToDisplay();
  };

  self.clickOnSearchBarButton = function () {
    if (typeof self.searchBarButtonCallback === "function") {
      self.searchBarButtonCallback();
    }
  };

  self.updateCustomBreadcrumbsArray = function (newCustomBreadcrumbsArray) {
    if (self.customBreadcrumbsObject !== null) {
      self.customBreadcrumbsObject.updateBreadcrumbs(newCustomBreadcrumbsArray);
    }
  };
}

function WizardToolbar(initWizardToolbar) {
  var self = this;
  self.buttons = [];
  construct(initWizardToolbar);

  function construct(init) {
    init.toolbarButtons.forEach(function (button) {
      self.buttons.push(new WizardToolbarButton(button));
    });
  }

  self.updateToolbarButtons = function (rows) {
    self.buttons.forEach(function (button) {
      button.updateIsEnabled(rows);
    });
  };
}

function WizardToolbarButton(initWizardTooltipButton) {
  var self = this;
  construct(initWizardTooltipButton);

  function construct(init) {
    self.type = init.type;
    if (self.type === config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON) {
      self.text = init.text;
      self.icon = init.icon;
      self.clickCallback = init.clickFunction;
      self.isEnabledCallback = init.isEnabledCallback;
      //self.isEnabled = ref(self.isEnabledCallback());
      self.isEnabled = shallowRef(false);
    }
  }

  self.clickFunction = function () {
    if (self.isEnabled.value) {
      TScMessenger.writeDebugMessage("WizardToolbarButton clickFunction");
      self.clickCallback();
    }
  };

  self.updateIsEnabled = function (rows) {
    if (self.type === config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON) {
      self.isEnabled.value = self.isEnabledCallback(rows);
    }
  };

  self.cleanUp = function () {
    if (self.type === config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON) {
      self.isEnabled.value = null;
      self.isEnabled = null;
    }
  };
}

function WizardCustomBreadcrumbsObject(initWizardCustomBreadcrumbsObject) {
  var self = this;
  self.breadcrumbs = shallowRef([]);
  self.breadcrumbsBeforeSearch = [];
  construct(initWizardCustomBreadcrumbsObject);

  function construct(init) {
    var bcs = [];
    init.breadcrumbs.forEach(function (button) {
      bcs.push(new WizardCustomBreadcrumb(button));
    });
    self.breadcrumbs.value = bcs;
    self.clickCallback = init.clickCallback;
  }

  self.clickFunction = function (clickedBreadcrumb) {
    self.clickCallback(clickedBreadcrumb);
  };

  self.getPreviousBreadcrumb = function () {
    return self.breadcrumbs.value[self.breadcrumbs.value.length - 2];
  };

  self.cleanUp = function () {
    //self.breadcrumbs([]);
  };

  /**
   * Update the custom breadcrumbs
   * @param {object[]} newBreadcrumbs - New breadcrumbs to be displayed on the wizard
   */
  self.updateBreadcrumbs = function (newBreadcrumbs) {
    var bcs = [];
    newBreadcrumbs.forEach(function (bc) {
      bcs.push(new WizardCustomBreadcrumb(bc));
    });
    self.breadcrumbs.value = bcs;
  };

  self.popLastBreadcrumb = function () {
    var bcs = self.breadcrumbs.value;
    bcs.pop();

    self.breadcrumbs.value = bcs;
  };

  self.addBreadcrumb = function (newBreadcrumb) {
    self.breadcrumbs.value.push(newBreadcrumb);
    // self.breadcrumbs.value = self.breadcrumbs.value;
    // self.breadcrumbs.valueHasMutated();
  };

  self.replaceBreadcrumbsWithSearchResults = function () {
    var searchResultBreadcrumb = [
      new WizardCustomBreadcrumb({
        id: 0,
        text: config.getTranslationText("2952"),
        allowClick: false,
      }),
    ];
    if (self.breadcrumbs.value[0].text !== config.getTranslationText("2952")) {
      self.breadcrumbsBeforeSearch = self.breadcrumbs.value;
    }

    self.breadcrumbs.value = searchResultBreadcrumb;
  };

  self.resetBreadcrumbsToStateBeforeSearchResults = function () {
    self.breadcrumbs.value = self.breadcrumbsBeforeSearch;
  };
}

function WizardCustomBreadcrumb(initWizardCustomBreadcrumb) {
  var self = this;
  construct(initWizardCustomBreadcrumb);

  function construct(init) {
    self.id = init.id;
    self.text = init.text;
    self.optionalData = init.optionalData;
    self.allowClick =
      typeof init.allowClick === "boolean" ? init.allowClick : true;
  }
}

/**
 *
 * @param {object} initConfigurationWizardStep
 */
function ConfigurationWizardStep(initConfigurationWizardStep) {
  var self = this;

  construct(initConfigurationWizardStep);

  //#region Private Functions
  function construct(initStep) {
    self.stepType = initStep.stepType;
    self.title = initStep.title;
    self.description = initStep.description;
    self.wizardLayoutType = initStep.wizardLayoutType;
    self.displayBackButtonWhenStepIsActive =
      typeof initStep.displayBackButtonWhenStepIsActive === "boolean"
        ? initStep.displayBackButtonWhenStepIsActive
        : true;
    self.displayNextButtonWhenStepIsActive =
      typeof initStep.displayNextButtonWhenStepIsActive === "boolean"
        ? initStep.displayNextButtonWhenStepIsActive
        : true;
    self.displayDoneButtonWhenStepIsActive =
      typeof initStep.displayDoneButtonWhenStepIsActive === "boolean"
        ? initStep.displayDoneButtonWhenStepIsActive
        : true;
    self.isBackButtonActiveWhenStepIsActive =
      typeof initStep.isBackButtonActiveWhenStepIsActive === "boolean"
        ? initStep.isBackButtonActiveWhenStepIsActive
        : true;
    self.isNextButtonActiveWhenStepIsActive =
      typeof initStep.isNextButtonActiveWhenStepIsActive === "boolean"
        ? initStep.isNextButtonActiveWhenStepIsActive
        : false;
    self.isDoneButtonActiveWhenStepIsActive =
      typeof initStep.isDoneButtonActiveWhenStepIsActive === "boolean"
        ? initStep.isDoneButtonActiveWhenStepIsActive
        : false;
    self.isActive = shallowRef(
      typeof initStep.isActive === "boolean" ? initStep.isActive : false
    );
    self.isComplete = shallowRef(
      typeof initStep.isComplete === "boolean" ? initStep.isComplete : false
    );
    self.customCss =
      typeof initStep.customCss === "string" ? initStep.customCss : "";
    self.customContainerCss = shallowRef(
      typeof initStep.customContainerCss === "string"
        ? initStep.customContainerCss
        : ""
    );
    self.stepIncludesRadioGroupInput =
      typeof initStep.stepIncludesRadioGroupInput === "boolean"
        ? initStep.stepIncludesRadioGroupInput
        : false;
    self.radioGroupInput =
      self.stepIncludesRadioGroupInput === true
        ? initStep.radioGroupInput
        : null;

    self.stepIncludesFileInputForm =
      typeof initStep.stepIncludesFileInputForm === "boolean"
        ? initStep.stepIncludesFileInputForm
        : false;
    self.fileInputForm =
      self.stepIncludesFileInputForm === true ? initStep.fileInputForm : null;

    self.stepIncludesInputContainerSection =
      typeof initStep.stepIncludesInputContainerSection === "boolean"
        ? initStep.stepIncludesInputContainerSection
        : false;
    self.inputsToIncludeInContainer =
      self.inputsToIncludeInContainer === true
        ? initStep.inputsToIncludeInContainer
        : null;

    self.stepIncludesMenuSection =
      typeof initStep.stepIncludesMenuSection === "boolean"
        ? initStep.stepIncludesMenuSection
        : false;
    self.menuToIncludeOnStep =
      self.stepIncludesMenuSection === true
        ? initStep.menuToIncludeOnStep
        : null;

    self.activateWizardStepCallback =
      typeof initStep.activateWizardStepCallback === "function"
        ? initStep.activateWizardStepCallback
        : null;
    self.clickOnNextButtonCallbackForStep =
      typeof initStep.clickOnNextButtonCallbackForStep === "function"
        ? initStep.clickOnNextButtonCallbackForStep
        : null;

    self.stepIncludesResultsTableSection =
      typeof initStep.stepIncludesResultsTableSection === "boolean"
        ? initStep.stepIncludesResultsTableSection
        : false;
    self.resultsTableToIncludeOnStep =
      self.stepIncludesResultsTableSection === true
        ? initStep.resultsTableToIncludeOnStep
        : null;

    self.preNextValidation = initStep.preNextValidationCallback;
  }
  //#endregion Private Functions

  //#region Public Functions
  /**
   * Activate wizard step if it is not already active
   */
  self.activateWizardStep = function () {
    if (!self.isActive.value) {
      self.isActive.value = true;
    }
    if (self.activateWizardStepCallback !== null) {
      self.activateWizardStepCallback();
    }
  };

  /**
   * Deactivate wizard step if it is not already deactivated
   */
  self.deactivateWizardStep = function () {
    if (self.isActive.value) {
      self.isActive.value = false;
    }
  };

  /**
   * Add a menu to the step and update the flag to include it in the design
   * @param {object} menu - new Menu() object
   */
  self.addMenuToStep = function (menu) {
    if (typeof menu !== "object") {
      throw "menu param in ConfigurationWizardStep addMenuToStep function is not an object";
    }
    self.stepIncludesMenuSection = true;
    self.menuToIncludeOnStep = menu;
  };

  /**
   * Set the value for isNextButtonActiveWhenStepIsActive on the configuration wizard step
   * @param {boolean} newValue - new value for isNextButtonActiveWhenStepIsActive
   */
  self.setIsNextButtonActiveWhenStepIsActive = function (newValue) {
    if (typeof newValue === "boolean") {
      self.isNextButtonActiveWhenStepIsActive = newValue;
    }
  };

  /**
   * Clean up observables and functions
   */
  self.cleanUp = function () {
    if (self.menuToIncludeOnStep !== null) {
      self.menuToIncludeOnStep.cleanUp();
    }
  };
  //#endregion Public Functions
}

/**
 * Wizard with back, next and done buttons in footer
 * @param {object} initConfigurationWizard
 */
function ConfigurationWizard(initConfigurationWizard) {
  var self = this;
  self.activeWizardStep = shallowRef(null);
  self.displayCancelButton = shallowRef(true);
  self.displayBackButton = shallowRef(true);
  self.displayNextButton = shallowRef(true);
  self.displayDoneButton = shallowRef(true);
  self.isCancelButtonActive = shallowRef(true);
  self.isBackButtonActive = shallowRef(true);
  self.isNextButtonActive = shallowRef(true);
  self.isDoneButtonActive = shallowRef(true);
  self.activeWizardStep = shallowRef(null);

  construct(initConfigurationWizard);

  //#region Private Functions
  function construct(initWizardObject) {
    self.wizardSteps = [];
    initWizardObject.wizardSteps.forEach(function (step) {
      self.wizardSteps.push(new ConfigurationWizardStep(step));
    });

    self.cancelButtonText =
      typeof initWizardObject.cancelButtonText === "string"
        ? initWizardObject.cancelButtonText
        : config.getTranslationText("65");
    self.backButtonText =
      typeof initWizardObject.backButtonText === "string"
        ? initWizardObject.backButtonText
        : config.getTranslationText("2632");
    self.nextButtonText =
      typeof initWizardObject.nextButtonText === "string"
        ? initWizardObject.nextButtonText
        : config.getTranslationText("2631");
    self.doneButtonText =
      typeof initWizardObject.doneButtonText === "string"
        ? initWizardObject.doneButtonText
        : config.getTranslationText("2633");

    self.displayFooterCheckbox =
      typeof initWizardObject.displayFooterCheckbox === "boolean"
        ? initWizardObject.displayFooterCheckbox
        : false;
    self.isFooterCheckboxChecked =
      typeof initWizardObject.isFooterCheckboxChecked === "boolean"
        ? shallowRef(initWizardObject.isFooterCheckboxChecked)
        : null;
    self.footerCheckboxText =
      typeof initWizardObject.footerCheckboxText === "string"
        ? initWizardObject.footerCheckboxText
        : null;

    self.clickOnCancelButtonCallback =
      typeof initWizardObject.clickOnCancelButtonCallback === "function"
        ? initWizardObject.clickOnCancelButtonCallback
        : null;
    self.clickOnBackButtonCallback =
      typeof initWizardObject.clickOnBackButtonCallback === "function"
        ? initWizardObject.clickOnBackButtonCallback
        : null;
    self.clickOnNextButtonCallback =
      typeof initWizardObject.clickOnNextButtonCallback === "function"
        ? initWizardObject.clickOnNextButtonCallback
        : null;
    self.clickOnDoneButtonCallback =
      typeof initWizardObject.clickOnDoneButtonCallback === "function"
        ? initWizardObject.clickOnDoneButtonCallback
        : null;

    updateActiveWizardStep(getActiveWizardStep());
  }

  /**
   * Update the activeWizardStep observable and update the footer buttons
   * @param {ConfigurationWizardStep} step - Step to be activated
   */
  function updateActiveWizardStep(step) {
    WebuiPopovers.hideAll();
    self.displayBackButton.value = step.displayBackButtonWhenStepIsActive;
    self.displayNextButton.value = step.displayNextButtonWhenStepIsActive;
    self.displayDoneButton.value = step.displayDoneButtonWhenStepIsActive;
    self.isBackButtonActive.value = step.isBackButtonActiveWhenStepIsActive;
    self.isNextButtonActive.value = step.isNextButtonActiveWhenStepIsActive;
    self.isDoneButtonActive.value = step.isDoneButtonActiveWhenStepIsActive;
    self.activeWizardStep.value = step;
    step.activateWizardStep();
  }

  /**
   * Get the active wizard step or the first step if none are active
   */
  function getActiveWizardStep() {
    return (
      self.wizardSteps.find(function (wizardStep) {
        return wizardStep.isActive.value;
      }) || self.wizardSteps[0]
    );
  }

  /**
   * Activate wizard step passed in as a parameter
   * @param {ConfigurationWizard} wizardStepToActivate - wizard step to activate
   */
  function activateWizardStep(wizardStepToActivate) {
    wizardStepToActivate.activateWizardStep();
  }

  /**
   * Deactivate all the steps in the wizard
   */
  function deactivateAllWizardSteps() {
    self.wizardSteps.forEach(function (wizardStep) {
      wizardStep.isActive.value = false;
    });
  }
  //#endregion Private Functions

  //#region Public Functions

  /**
   * Toggle the footer checkbox value
   */
  self.toggleFooterCheckbox = function () {
    if (self.isFooterCheckboxChecked.value === true) {
      self.isFooterCheckboxChecked.value = false;
    } else {
      self.isFooterCheckboxChecked.value = true;
    }
  };

  /**
   * React to user click on step in header
   * @param {ConfigurationWizardStep} stepClickedOn - The wizard step that the user clicked on
   */
  self.clickOnWizardStep = function (stepClickedOn) {
    deactivateAllWizardSteps();
    updateActiveWizardStep(stepClickedOn);
  };

  /**
   * React to click on Back button in footer
   */
  self.clickOnBackButton = function () {
    var currentActiveWizardStep = getActiveWizardStep(),
      currentIndex = self.wizardSteps.findIndex(function (step) {
        return step.stepType === currentActiveWizardStep.stepType;
      }),
      indexToUse = currentIndex - 1;

    currentActiveWizardStep.isComplete.value = false;
    if (indexToUse >= 0) {
      deactivateAllWizardSteps();
      updateActiveWizardStep(self.wizardSteps[indexToUse]);
      if (self.clickOnBackButtonCallback !== null) {
        self.clickOnBackButtonCallback();
      }
    }
  };

  /**
   * React to click on Next button in footer
   */
  self.clickOnNextButton = function () {
    if (
      (self.activeWizardStep.value.preNextValidation &&
        self.activeWizardStep.value.preNextValidation()) ||
      !self.activeWizardStep.value.preNextValidation
    ) {
      if (
        self.activeWizardStep.value.clickOnNextButtonCallbackForStep !== null
      ) {
        $.when(
          self.activeWizardStep.value.clickOnNextButtonCallbackForStep()
        ).then(function () {
          commonSteps();
        });
      } else {
        commonSteps();
      }
    }

    function commonSteps() {
      var currentActiveWizardStep = getActiveWizardStep(),
        currentIndex = self.wizardSteps.findIndex(function (step) {
          return step.stepType === currentActiveWizardStep.stepType;
        }),
        indexToUse = currentIndex + 1;

      currentActiveWizardStep.isComplete.value = true;
      if (indexToUse < self.wizardSteps.length) {
        deactivateAllWizardSteps();
        updateActiveWizardStep(self.wizardSteps[indexToUse]);
        if (self.clickOnNextButtonCallback !== null) {
          self.clickOnNextButtonCallback();
        }
      }
    }
  };

  /**
   * React to click on Done button in footer
   */
  self.clickOnDoneButton = function () {
    if (
      (self.activeWizardStep.value.preNextValidation &&
        self.activeWizardStep.value.preNextValidation()) ||
      !self.activeWizardStep.value.preNextValidation
    ) {
      if (self.clickOnDoneButtonCallback !== null) {
        self.clickOnDoneButtonCallback();
      }
    }
  };

  /**
   * React to click on Cancel button in footer
   */
  self.clickOnCancelButton = function () {
    if (self.clickOnCancelButtonCallback !== null) {
      self.clickOnCancelButtonCallback();
    }
  };

  self.activateNextButton = function () {};

  self.getWizardStepUsingStepType = function (stepType) {
    return (
      self.wizardSteps.find(function (wizardStep) {
        return wizardStep.stepType === stepType;
      }) || null
    );
  };

  /**
   * Clean up observables and functions
   */
  self.cleanUp = function () {
    self.wizardSteps.forEach(function (wizardStep) {
      wizardStep.cleanUp();
    });
    //initConfigurationWizardStep.cleanUp();
  };
  //#endregion Public Functions
}

function createSelectionWizard(
  selectionWizardObject,
  partialMenuId,
  activateStepCallback
) {
  return new SelectionWizard(
    selectionWizardObject,
    partialMenuId,
    activateStepCallback
  );
}

function createConfigurationWizard(configurationWizardObject) {
  return new ConfigurationWizard(configurationWizardObject);
}

const WizardHelper = {
  createSelectionWizard: createSelectionWizard,
  createConfigurationWizard: createConfigurationWizard,
};

export default WizardHelper;
