import PriceInterval from './model.priceinterval';
// import OnRoadCost from './model.onroadcost';
import config from '../../../services/config';
import globals from '../../../services/globals';

// import { config, globals } from '../../../services';
import { shallowRef } from 'vue';



var Costing = function (noOfUnits, noOfUnitsMin, noOfUnitsMax, noOfUnitsIncrement, priceIntervalsArray, depositRate, depositRateMin, depositRateMax, depositRateIncrement,
    fuelConsumption, fuelConsumptionMin, fuelConsumptionMax, fuelConsumptionIncrement, fuelCost, fuelCostMin, fuelCostMax, fuelCostIncrement,
    interestRate, interestRateMin, interestRateMax, interestRateIncrement, maintenanceRate, maintenanceRateMin, maintenanceRateMax, maintenanceRateIncrement,
    monthlyDistance, monthlyDistanceMin, monthlyDistanceMax, monthlyDistanceIncrement, repaymentTerm, repaymentTermMin, repaymentTermMax, repaymentTermIncrement,
    residualRate, residualRateMin, residualRateMax, residualRateIncrement, optionPriceMin, optionPriceMax, optionPriceIncrement,
    bodyAndEquipmentPriceMin, bodyAndEquipmentPriceMax, bodyAndEquipmentPriceIncrement, vehiclePriceMin, vehiclePriceMax, vehiclePriceIncrement,
    originalVehiclePrice, originalBodyAndEquipmentPrice, originalOptionPrice, onRoadCostsCosts, onRoadCostsDiscounts, decimalsHighPricedItems,
    decimalsMediumPricedItems, decimalsLowPricedItems, driverRemuneration,
    driverRemunerationMin, driverRemunerationMax, driverRemunerationIncrement, insuranceRate, insuranceRateMin, insuranceRateMax, insuranceRateIncrement,
    overheadRate, overheadRateMin, overheadRateMax, overheadRateIncrement, tyreRate, tyreRateMin, tyreRateMax, tyreRateIncrement, licenceFeesMin, licenceFeesMax,
    licenceFeesIncrement, tollFeesMin, tollFeesMax, tollFeesIncrement, totalRevenueMin, totalRevenueMax, totalRevenueIncrement, tyrePriceMin, tyrePriceMax,
    tyrePriceIncrement, averageTyreLifeMin, averageTyreLifeMax, averageTyreLifeIncrement, licenceFeesOverride, vehicleLicenceFees, vehicleLicenceFeesOverride,
    vehicleLicenceFeesRegionId, vehiclePriceOverride, vehicleDiscount, vehiclePeriod, vehicleInterestRate, vehicleResidualRate, vehicleResidualRateType,
    financeTotalPriceOverride, vehicleCommonGroupFinancePeriod, countryInterestRate, vehiclePriceEffectiveDate, monthlyTrips, monthlyTripsMin, monthlyTripsMax,
    monthlyTripsIncrement, tollFeesOverride, tollFeesPerTrip, tollFeesPerTripOverride, permitCostPerTrip, vehicleClassId, vehicleClassIdOverride,
    fuelAndAdblueCostPerMonthMin, fuelAndAdblueCostPerMonthMax, fuelAndAdblueCostPerMonthIncrement, fuelAndAdblueCostPerMonth, fuelAndAdblueCostPerMonthOverride, 
    vehicleFuelAndAdblueTotalCostPerMonth, vehicleFuelAndAdblueTotalCostPerMonthOverride, vehicleFuelAndAdblueFuelConsumption, vehicleFuelAndAdblueFuelConsumptionOverride, 
    vehicleFuelAndAdblueFuelCostPerVolume, vehicleFuelAndAdblueFuelCostPerVolumeOverride, vehicleFuelAndAdblueFuelCostPerVolumeMin, vehicleFuelAndAdblueFuelCostPerVolumeMax,
    vehicleFuelAndAdblueAdblueConsumption, vehicleFuelAndAdblueAdblueConsumptionOverride,    
    vehicleFuelAndAdblueAdblueConsumptionMin, vehicleFuelAndAdblueAdblueConsumptionMax, vehicleFuelAndAdblueAdblueCostPerVolume, vehicleFuelAndAdblueAdblueCostPerVolumeOverride, 
    vehicleFuelAndAdblueAdblueCostPerVolumeMin, vehicleFuelAndAdblueAdblueCostPerVolumeMax, vehicleFuelAndAdblueEquipmentFuelConsumption,     
    vehicleFuelAndAdblueEquipmentFuelConsumptionOverride, vehicleFuelAndAdblueEquipmentFuelCostPerVolume, vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride, 
    vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin, vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax, vehicleFuelAndAdblueEquipmentUtilisation, 
    trailerFuelAndAdblueEquipmentFuelCostPerVolume, trailerFuelAndAdblueEquipmentFuelCostPerVolumeMin, trailerFuelAndAdblueEquipmentFuelCostPerVolumeMax) {

    var self = this;

    //#region private members
    var _noOfUnits = noOfUnits,
        _noOfUnitsMin = noOfUnitsMin,
        _noOfUnitsMax = noOfUnitsMax,
        _noOfUnitsIncrement = noOfUnitsIncrement,
        _depositRate = depositRate,
        _depositRateMin = depositRateMin,
        _depositRateMax = depositRateMax,
        _depositRateIncrement = depositRateIncrement,
        _fuelConsumption = fuelConsumption,
        _fuelConsumptionMin = fuelConsumptionMin,
        _fuelConsumptionMax = fuelConsumptionMax,
        _fuelConsumptionIncrement = fuelConsumptionIncrement,
        _fuelConsumptionOverride = false,
        _fuelCost = fuelCost,
        _fuelCostMin = fuelCostMin,
        _fuelCostMax = fuelCostMax,
        _fuelCostIncrement = fuelCostIncrement,
        _interestRate = interestRate,
        _interestRateMin = interestRateMin,
        _interestRateMax = interestRateMax,
        _interestRateIncrement = interestRateIncrement,
        _maintenanceRate = maintenanceRate,
        _maintenanceRateMin = maintenanceRateMin,
        _maintenanceRateMax = maintenanceRateMax,
        _maintenanceRateIncrement = maintenanceRateIncrement,
        _monthlyDistance = monthlyDistance,
        _monthlyDistanceMin = monthlyDistanceMin,
        _monthlyDistanceMax = monthlyDistanceMax,
        _monthlyDistanceIncrement = monthlyDistanceIncrement,
        _repaymentTerm = repaymentTerm,
        _repaymentTermMin = repaymentTermMin,
        _repaymentTermMax = repaymentTermMax,
        _repaymentTermIncrement = repaymentTermIncrement,
        _residualRate = residualRate,
        _residualRateMin = residualRateMin,
        _residualRateMax = residualRateMax,
        _residualRateIncrement = residualRateIncrement,
        _optionPrice = 0,
        _optionPriceMin = optionPriceMin,
        _optionPriceMax = optionPriceMax,
        _optionPriceIncrement = optionPriceIncrement,
        _bodyAndEquipmentPrice,
        _bodyAndEquipmentPriceMin = bodyAndEquipmentPriceMin,
        _bodyAndEquipmentPriceMax = bodyAndEquipmentPriceMax,
        _bodyAndEquipmentPriceMinActual,
        _bodyAndEquipmentPriceMaxActual,
        _bodyAndEquipmentPriceIncrement = bodyAndEquipmentPriceIncrement,
        _vehiclePrice,
        _vehiclePriceMinActual,
        _vehiclePriceMaxActual,
        _vehiclePriceMin = vehiclePriceMin,
        _vehiclePriceMax = vehiclePriceMax,
        _vehiclePriceIncrement = vehiclePriceIncrement,
        _originalVehiclePrice = originalVehiclePrice,
        _originalBodyAndEquipmentPrice = originalBodyAndEquipmentPrice,
        _originalOptionPrice = originalOptionPrice,
        _registrationFeePercent = 0,
        _registrationFee = 0,
        _numberPlatesCost = 0,
        _insuranceFeeCost = 0,
        _vehicleCertificationCost = 0,
        _registrationServiceCost = 0,
        _roadTaxCost = 0,
        _summaryDiscount = 0,
        _fuelConsumptionOutbound = 0,
        _fuelConsumptionReturn = 0,
        _onRoadCostsCosts = [],
        _onRoadCostsDiscounts = [],
        _priceIntervals = [],
        _decimalsHighPricedItems = decimalsHighPricedItems,
        _decimalsMediumPricedItems = decimalsMediumPricedItems,
        _decimalsLowPricedItems = decimalsLowPricedItems,
        _driverRemuneration = driverRemuneration,
        _driverRemunerationMin = driverRemunerationMin,
        _driverRemunerationMax = driverRemunerationMax,
        _driverRemunerationIncrement = driverRemunerationIncrement,
        _insuranceRate = insuranceRate,
        _insuranceRateMin = insuranceRateMin,
        _insuranceRateMax = insuranceRateMax,
        _insuranceRateIncrement = insuranceRateIncrement,
        _overheadRate = overheadRate,
        _overheadRateMin = overheadRateMin,
        _overheadRateMax = overheadRateMax,
        _overheadRateIncrement = overheadRateIncrement,
        _totalRevenue = 13,
        _totalRevenueMin = totalRevenueMin,
        _totalRevenueMax = totalRevenueMax,
        _totalRevenueIncrement = totalRevenueIncrement,
        _totalRevenueSetUsingMeasurementSystemId = null, 
        _totalRevenueAsCurrencyPerKm,
        _tyreRate = tyreRate,
        _tyreRateMin = tyreRateMin,
        _tyreRateMax = tyreRateMax,
        _tyreRateIncrement = tyreRateIncrement,
        _tyreRateSetUsingMeasurementSystemId = null,
        _tyreRateAsCentsPerKm,
        _licenceFees = 0,
        _licenceFeesMin = licenceFeesMin,
        _licenceFeesMax = licenceFeesMax,
        _licenceFeesIncrement = licenceFeesIncrement,
        _tollFees = 0,
        _tollFeesMin = tollFeesMin,
        _tollFeesMax = tollFeesMax,
        _tollFeesIncrement = tollFeesIncrement,
        _tyrePriceMin = tyrePriceMin,
        _tyrePriceMax = tyrePriceMax,
        _tyrePriceIncrement = tyrePriceIncrement,
        _averageTyreLifeMin = averageTyreLifeMin,
        _averageTyreLifeMax = averageTyreLifeMax,
        _averageTyreLifeIncrement = averageTyreLifeIncrement,
        _simulatedFuelConsumption = 0,
        _tyreRateOverride = false,
        _licenceCategories = [],
        _licenceFeesOverride = licenceFeesOverride || false,
        _vehicleLicenceFees = vehicleLicenceFees,
        _vehicleLicenceFeesOverride = vehicleLicenceFeesOverride || false,
        _vehicleLicenceFeesRegionId = vehicleLicenceFeesRegionId,
        _vehiclePriceOverride = vehiclePriceOverride || false,
        _vehicleDiscount = vehicleDiscount || 0,
        _vehiclePeriod = vehiclePeriod || 0,
        _vehicleInterestRate = vehicleInterestRate || 0,
        _vehicleResidualRate = vehicleResidualRate || 0,
        _vehicleResidualRateType = vehicleResidualRateType || config.RESIDUAL_RATE_TYPES.NET_PRICE,
        _financeTotalPrice,
        _financeTotalPriceOverride = financeTotalPriceOverride || false,
        _financeDetailCostPerMonth,
        _financeCostPerMonth,
        _vehicleCommonGroupFinancePeriod = vehicleCommonGroupFinancePeriod,
        _countryInterestRate = countryInterestRate,
        _vehiclePriceOnDatabase,
        _vehiclePriceEffectiveDate = vehiclePriceEffectiveDate,
        _fuelConsumptionOnDatabase = fuelConsumption,
        _monthlyTrips = monthlyTrips,
        _monthlyTripsMin = monthlyTripsMin,
        _monthlyTripsMax = monthlyTripsMax,
        _monthlyTripsIncrement = monthlyTripsIncrement,
        _tollFeesOverride = tollFeesOverride || false,
        _tollFeesPerTrip = tollFeesPerTrip || 0,
        _tollFeesPerTripOverride = tollFeesPerTripOverride || false,
        _permitCostPerTrip = permitCostPerTrip || 0,
        _vehicleClassId = vehicleClassId || 1,
        _vehicleClassIdOverride = vehicleClassIdOverride || false,
        _fuelAndAdblueCostPerMonthMin = fuelAndAdblueCostPerMonthMin || 0,
        _fuelAndAdblueCostPerMonthMax = fuelAndAdblueCostPerMonthMax || 1000,
        _fuelAndAdblueCostPerMonthIncrement = fuelAndAdblueCostPerMonthIncrement || 1,
        _fuelAndAdblueCostPerMonth = fuelAndAdblueCostPerMonth || 0,
        _fuelAndAdblueCostPerMonthOverride = fuelAndAdblueCostPerMonthOverride || false, 
        _vehicleFuelAndAdblueTotalCostPerMonth = vehicleFuelAndAdblueTotalCostPerMonth || 0,
        _vehicleFuelAndAdblueTotalCostPerMonthOverride = vehicleFuelAndAdblueTotalCostPerMonthOverride || false,
        // _vehicleFuelAndAdblueFuelConsumption = vehicleFuelAndAdblueFuelConsumption || 50.25,
        _vehicleFuelAndAdblueFuelConsumption = vehicleFuelAndAdblueFuelConsumption || fuelConsumption,
        _vehicleFuelAndAdblueFuelConsumptionOverride = vehicleFuelAndAdblueFuelConsumptionOverride || false,
        _vehicleFuelAndAdblueFuelConsumptionOnDatabase = vehicleFuelAndAdblueFuelConsumption || fuelConsumption,
        // _vehicleFuelAndAdblueFuelCostPerVolume = vehicleFuelAndAdblueFuelCostPerVolume || 25,
        _vehicleFuelAndAdblueFuelCostPerVolume = vehicleFuelAndAdblueFuelCostPerVolume || fuelCost,
        _vehicleFuelAndAdblueFuelCostPerVolumeOverride = vehicleFuelAndAdblueFuelCostPerVolumeOverride || false,
        _vehicleFuelAndAdblueFuelCostPerVolumeOnDatabase = vehicleFuelAndAdblueFuelCostPerVolume || fuelCost,
        _vehicleFuelAndAdblueFuelCostPerVolumeMin = vehicleFuelAndAdblueFuelCostPerVolumeMin, 
        _vehicleFuelAndAdblueFuelCostPerVolumeMax = vehicleFuelAndAdblueFuelCostPerVolumeMax,
        // _vehicleFuelAndAdblueAdblueConsumption = vehicleFuelAndAdblueAdblueConsumption || 4.5,
        _vehicleFuelAndAdblueAdblueConsumption = vehicleFuelAndAdblueAdblueConsumption || 0,
        _vehicleFuelAndAdblueAdblueConsumptionOverride = vehicleFuelAndAdblueAdblueConsumptionOverride || false,
        _vehicleFuelAndAdblueAdblueConsumptionOnDatabase = vehicleFuelAndAdblueAdblueConsumption || 0,
        _vehicleFuelAndAdblueAdblueConsumptionMin = vehicleFuelAndAdblueAdblueConsumptionMin || 0, 
        _vehicleFuelAndAdblueAdblueConsumptionMax = vehicleFuelAndAdblueAdblueConsumptionMax || 0,
        // _vehicleFuelAndAdblueAdblueCostPerVolume = vehicleFuelAndAdblueAdblueCostPerVolume || 17,
        _vehicleFuelAndAdblueAdblueCostPerVolume = vehicleFuelAndAdblueAdblueCostPerVolume || 0,
        _vehicleFuelAndAdblueAdblueCostPerVolumeOverride = vehicleFuelAndAdblueAdblueCostPerVolumeOverride || false,
        _vehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase = vehicleFuelAndAdblueAdblueCostPerVolume || 0,
        _vehicleFuelAndAdblueAdblueCostPerVolumeMin = vehicleFuelAndAdblueAdblueCostPerVolumeMin || 0,
        _vehicleFuelAndAdblueAdblueCostPerVolumeMax = vehicleFuelAndAdblueAdblueCostPerVolumeMax || 0,
        _vehicleFuelAndAdblueEquipmentFuelConsumption = vehicleFuelAndAdblueEquipmentFuelConsumption || 0,
        _vehicleFuelAndAdblueEquipmentFuelConsumptionOverride = vehicleFuelAndAdblueEquipmentFuelConsumptionOverride || false,
        // _vehicleFuelAndAdblueEquipmentFuelCostPerVolume = vehicleFuelAndAdblueEquipmentFuelCostPerVolume || 24,
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolume = vehicleFuelAndAdblueEquipmentFuelCostPerVolume || 0,
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride = vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride || false,
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase = vehicleFuelAndAdblueEquipmentFuelCostPerVolume || 0,
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin = vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin || 0, 
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax = vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax || 0,
        _vehicleFuelAndAdblueEquipmentUtilisation = vehicleFuelAndAdblueEquipmentUtilisation || 0,
        _trailerFuelAndAdblueEquipmentFuelCostPerVolume = trailerFuelAndAdblueEquipmentFuelCostPerVolume || 0,
        _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMin = trailerFuelAndAdblueEquipmentFuelCostPerVolumeMin || 0,
        _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMax = trailerFuelAndAdblueEquipmentFuelCostPerVolumeMax || 0;
    //#endregion private members

    //#region getters and setters
    function getNoOfUnits() {
        return _noOfUnits;
    }

    function setNoOfUnits(newValue) {
        _noOfUnits = newValue;
    }

    function getNoOfUnitsMin() {
        return _noOfUnitsMin;
    }

    function setNoOfUnitsMin(newValue) {
        _noOfUnitsMin = newValue;
    }

    function getNoOfUnitsMax() {
        return _noOfUnitsMax;
    }

    function setNoOfUnitsMax(newValue) {
        _noOfUnitsMax = newValue;
    }

    function getNoOfUnitsIncrement() {
        return _noOfUnitsIncrement;
    }

    function setNoOfUnitsIncrement(newValue) {
        _noOfUnitsIncrement = newValue;
    }

    function getDepositRate() {
        return _depositRate;
    }

    function setDepositRate(newValue) {
        _depositRate = newValue;
    }

    function getDepositRateMin() {
        return _depositRateMin;
    }

    function setDepositRateMin(newValue) {
        _depositRateMin = newValue;
    }

    function getDepositRateMax() {
        return _depositRateMax;
    }

    function setDepositRateMax(newValue) {
        _depositRateMax = newValue;
    }

    function getDepositRateIncrement() {
        return _depositRateIncrement;
    }

    function setDepositRateIncrement(newValue) {
        _depositRateIncrement = newValue;
    }

    function getFuelConsumption() {
        if (_fuelConsumptionOverride === false) {
            if (_simulatedFuelConsumption > 0) {
                return _simulatedFuelConsumption;
            } else {
                return _fuelConsumption;
            }
        } else {
            return _fuelConsumption;
        }

    }

    function setFuelConsumption(newValue) {
        _fuelConsumption = newValue;
    }

    function getFuelConsumptionMin() {
        return _fuelConsumptionMin;
    }

    function setFuelConsumptionMin(newValue) {
        _fuelConsumptionMin = newValue;
    }

    function getFuelConsumptionMax() {
        return _fuelConsumptionMax;
    }

    function setFuelConsumptionMax(newValue) {
        _fuelConsumptionMax = newValue;
    }

    function getFuelConsumptionIncrement() {
        return _fuelConsumptionIncrement;
    }

    function setFuelConsumptionIncrement(newValue) {
        _fuelConsumptionIncrement = newValue;
    }

    function getFuelCost() {
        return _fuelCost;
    }

    function setFuelCost(newValue) {
        _fuelCost = newValue;
    }

    function getFuelCostMin() {
        return _fuelCostMin;
    }

    function setFuelCostMin(newValue) {
        _fuelCostMin = newValue;
    }

    function getFuelCostMax() {
        return _fuelCostMax;
    }

    function setFuelCostMax(newValue) {
        _fuelCostMax = newValue;
    }

    function getFuelCostIncrement() {
        return _fuelCostIncrement;
    }

    function setFuelCostIncrement(newValue) {
        _fuelCostIncrement = newValue;
    }

    function getInterestRate() {
        return _interestRate;
    }

    function setInterestRate(newValue) {
        _interestRate = newValue;
    }

    function getInterestRateMin() {
        return _interestRateMin;
    }

    function setInterestRateMin(newValue) {
        _interestRateMin = newValue;
    }

    function getInterestRateMax() {
        return _interestRateMax;
    }

    function setInterestRateMax(newValue) {
        _interestRateMax = newValue;
    }

    function getInterestRateIncrement() {
        return _interestRateIncrement;
    }

    function setInterestRateIncrement(newValue) {
        _interestRateIncrement = newValue;
    }

    function getMaintenanceRate() {
        return _maintenanceRate;
    }

    function setMaintenanceRate(newValue) {
        _maintenanceRate = newValue;
    }

    function getMaintenanceRateMin() {
        return _maintenanceRateMin;
    }

    function setMaintenanceRateMin(newValue) {
        _maintenanceRateMin = newValue;
    }

    function getMaintenanceRateMax() {
        return _maintenanceRateMax;
    }

    function setMaintenanceRateMax(newValue) {
        _maintenanceRateMax = newValue;
    }

    function getMaintenanceRateIncrement() {
        return _maintenanceRateIncrement;
    }

    function setMaintenanceRateIncrement(newValue) {
        _maintenanceRateIncrement = newValue;
    }

    function getMonthlyDistance() {
        return _monthlyDistance;
    }

    function setMonthlyDistance(newValue) {
        _monthlyDistance = newValue;
    }

    function getMonthlyDistanceMin() {
        return _monthlyDistanceMin;
    }

    function setMonthlyDistanceMin(newValue) {
        _monthlyDistanceMin = newValue;
    }

    function getMonthlyDistanceMax() {
        return _monthlyDistanceMax;
    }

    function setMonthlyDistanceMax(newValue) {
        _monthlyDistanceMax = newValue;
    }

    function getMonthlyDistanceIncrement() {
        return _monthlyDistanceIncrement;
    }

    function setMonthlyDistanceIncrement(newValue) {
        _monthlyDistanceIncrement = newValue;
    }

    function getRepaymentTerm() {
        return _repaymentTerm;
    }

    function setRepaymentTerm(newValue) {
        _repaymentTerm = newValue;
    }

    function getRepaymentTermMin() {
        return _repaymentTermMin;
    }

    function setRepaymentTermMin(newValue) {
        _repaymentTermMin = newValue;
    }

    function getRepaymentTermMax() {
        return _repaymentTermMax;
    }

    function setRepaymentTermMax(newValue) {
        _repaymentTermMax = newValue;
    }

    function getRepaymentTermIncrement() {
        return _repaymentTermIncrement;
    }

    function setRepaymentTermIncrement(newValue) {
        _repaymentTermIncrement = newValue;
    }

    function getResidualRate() {
        return _residualRate;
    }

    function setResidualRate(newValue) {
        _residualRate = newValue;
    }

    function getResidualRateMin() {
        return _residualRateMin;
    }

    function setResidualRateMin(newValue) {
        _residualRateMin = newValue;
    }

    function getResidualRateMax() {
        return _residualRateMax;
    }

    function setResidualRateMax(newValue) {
        _residualRateMax = newValue;
    }

    function getResidualRateIncrement() {
        return _residualRateIncrement;
    }

    function setResidualRateIncrement(newValue) {
        _residualRateIncrement = newValue;
    }

    function getOptionPrice() {
        return _optionPrice;
    }

    function setOptionPrice(newValue) {
        _optionPrice = newValue;
    }

    function getOptionPriceMin() {
        return _optionPriceMin;
    }

    function setOptionPriceMin(newValue) {
        _optionPriceMin = newValue;
    }

    function getOptionPriceMax() {
        return _optionPriceMax;
    }

    function setOptionPriceMax(newValue) {
        _optionPriceMax = newValue;
    }

    function getOptionPriceIncrement() {
        return _optionPriceIncrement;
    }

    function setOptionPriceIncrement(newValue) {
        _optionPriceIncrement = newValue;
    }

    function getBodyAndEquipmentPrice() {
        return _bodyAndEquipmentPrice;
    }

    function setBodyAndEquipmentPrice(newValue) {
        _bodyAndEquipmentPrice = newValue;
    }

    function getBodyAndEquipmentPriceMin() {
        return _bodyAndEquipmentPriceMin;
    }

    function setBodyAndEquipmentPriceMin(newValue) {
        _bodyAndEquipmentPriceMin = newValue;
    }

    function getBodyAndEquipmentPriceMinActual() {
        return _bodyAndEquipmentPriceMinActual;
    }

    function setBodyAndEquipmentPriceMinActual(newValue) {
        _bodyAndEquipmentPriceMinActual = newValue;
    }

    function getBodyAndEquipmentPriceMax() {
        return _bodyAndEquipmentPriceMax;
    }

    function setBodyAndEquipmentPriceMax(newValue) {
        _bodyAndEquipmentPriceMax = newValue;
    }

    function getBodyAndEquipmentPriceMaxActual() {
        return _bodyAndEquipmentPriceMaxActual;
    }

    function setBodyAndEquipmentPriceMaxActual(newValue) {
        _bodyAndEquipmentPriceMaxActual = newValue;
    }

    function getBodyAndEquipmentPriceIncrement() {
        return _bodyAndEquipmentPriceIncrement;
    }

    function setBodyAndEquipmentPriceIncrement(newValue) {
        _bodyAndEquipmentPriceIncrement = newValue;
    }

    function getVehiclePrice() {
        return _vehiclePrice;
    }

    function setVehiclePrice(newValue) {
        _vehiclePrice = newValue;
    }

    function getVehiclePriceMin() {
        return _vehiclePriceMin;
    }

    function setVehiclePriceMin(newValue) {
        _vehiclePriceMin = newValue;
    }

    function getVehiclePriceMinActual() {
        return _vehiclePriceMinActual;
    }

    function setVehiclePriceMinActual(newValue) {
        _vehiclePriceMinActual = newValue;
    }

    function getVehiclePriceMax() {
        return _vehiclePriceMax;
    }

    function setVehiclePriceMax(newValue) {
        _vehiclePriceMax = newValue;
    }

    function getVehiclePriceMaxActual() {
        return _vehiclePriceMaxActual;
    }

    function setVehiclePriceMaxActual(newValue) {
        _vehiclePriceMaxActual = newValue;
    }

    function getVehiclePriceIncrement() {
        return _vehiclePriceIncrement;
    }

    function setVehiclePriceIncrement(newValue) {
        _vehiclePriceIncrement = newValue;
    }

    function getOriginalVehiclePrice() {
        return _originalVehiclePrice;
    }

    function setOriginalVehiclePrice(newValue) {
        _originalVehiclePrice = newValue;
    }

    function getOriginalBodyAndEquipmentPrice() {
        return _originalBodyAndEquipmentPrice;
    }

    function setOriginalBodyAndEquipmentPrice(newValue) {
        _originalBodyAndEquipmentPrice = newValue;
    }

    function getOriginalOptionPrice() {
        return _originalOptionPrice;
    }

    function setOriginalOptionPrice(newValue) {
        _originalOptionPrice = newValue;
    }

    function getRegistrationFeePercent() {
        return _registrationFeePercent;
    }

    function setRegistrationFeePercent(newValue) {
        _registrationFeePercent = newValue;
    }

    function getRegistrationFee() {
        return _registrationFee;
    }

    function setRegistrationFee(newValue) {
        _registrationFee = newValue;
    }

    function getNumberPlatesCost() {
        return _numberPlatesCost;
    }

    function setNumberPlatesCost(newValue) {
        _numberPlatesCost = newValue;
    }

    function getInsuranceFeeCost() {
        return _insuranceFeeCost;
    }

    function setInsuranceFeeCost(newValue) {
        _insuranceFeeCost = newValue;
    }

    function getVehicleCertificationCost() {
        return _vehicleCertificationCost;
    }

    function setVehicleCertificationCost(newValue) {
        _vehicleCertificationCost = newValue;
    }

    function getRegistrationServiceCost() {
        return _registrationServiceCost;
    }

    function setRegistrationServiceCost(newValue) {
        _registrationServiceCost = newValue;
    }

    function getRoadTaxCost() {
        return _roadTaxCost;
    }

    function setRoadTaxCost(newValue) {
        _roadTaxCost = newValue;
    }

    function getSummaryDiscount() {
        return _summaryDiscount;
    }

    function setSummaryDiscount(newValue) {
        _summaryDiscount = newValue;
    }

    function getFuelConsumptionOutbound() {
        return _fuelConsumptionOutbound;
    }

    function setFuelConsumptionOutbound(newValue) {
        _fuelConsumptionOutbound = newValue;
    }

    function getFuelConsumptionReturn() {
        return _fuelConsumptionReturn;
    }

    function setFuelConsumptionReturn(newValue) {
        _fuelConsumptionReturn = newValue;
    }

    function getOnRoadCostsCosts() {
        return _onRoadCostsCosts;
    }

    function setOnRoadCostsCosts(newValue) {
        _onRoadCostsCosts = newValue;
    }

    function getOnRoadCostsDiscounts() {
        return _onRoadCostsDiscounts;
    }

    function setOnRoadCostsDiscounts(newValue) {
        _onRoadCostsDiscounts = newValue;
    }

    function getPriceIntervals() {
        return _priceIntervals;
    }

    function setPriceIntervals(newValue) {
        _priceIntervals = newValue;
    }

    function getDecimalsHighPricedItems() {
        return _decimalsHighPricedItems;
    }

    function setDecimalsHighPricedItems(newValue) {
        _decimalsHighPricedItems = newValue;
    }

    function getDecimalsMediumPricedItems() {
        return _decimalsMediumPricedItems;
    }

    function setDecimalsMediumPricedItems(newValue) {
        _decimalsMediumPricedItems = newValue;
    }

    function getDecimalsLowPricedItems() {
        return _decimalsLowPricedItems;
    }

    function setDecimalsLowPricedItems(newValue) {
        _decimalsLowPricedItems = newValue;
    }

    function getDriverRemuneration() {
        return _driverRemuneration;
    }

    function setDriverRemuneration(newValue) {
        _driverRemuneration = newValue;
    }

    function getDriverRemunerationMin() {
        return _driverRemunerationMin;
    }

    function setDriverRemunerationMin(newValue) {
        _driverRemunerationMin = newValue;
    }

    function getDriverRemunerationMax() {
        return _driverRemunerationMax;
    }

    function setDriverRemunerationMax(newValue) {
        _driverRemunerationMax = newValue;
    }

    function getDriverRemunerationIncrement() {
        return _driverRemunerationIncrement;
    }

    function setDriverRemunerationIncrement(newValue) {
        _driverRemunerationIncrement = newValue;
    }

    function getInsuranceRate() {
        return _insuranceRate;
    }

    function setInsuranceRate(newValue) {
        _insuranceRate = newValue;
    }

    function getInsuranceRateMin() {
        return _insuranceRateMin;
    }

    function setInsuranceRateMin(newValue) {
        _insuranceRateMin = newValue;
    }

    function getInsuranceRateMax() {
        return _insuranceRateMax;
    }

    function setInsuranceRateMax(newValue) {
        _insuranceRateMax = newValue;
    }

    function getInsuranceRateIncrement() {
        return _insuranceRateIncrement;
    }

    function setInsuranceRateIncrement(newValue) {
        _insuranceRateIncrement = newValue;
    }

    function getOverheadRate() {
        return _overheadRate;
    }

    function setOverheadRate(newValue) {
        _overheadRate = newValue;
    }

    function getOverheadRateMin() {
        return _overheadRateMin;
    }

    function setOverheadRateMin(newValue) {
        _overheadRateMin = newValue;
    }

    function getOverheadRateMax() {
        return _overheadRateMax;
    }

    function setOverheadRateMax(newValue) {
        _overheadRateMax = newValue;
    }

    function getOverheadRateIncrement() {
        return _overheadRateIncrement;
    }

    function setOverheadRateIncrement(newValue) {
        _overheadRateIncrement = newValue;
    }

    function getCrewCostPerMonth() {
        return _driverRemuneration;
    }

    function setCrewCostPerMonth(newValue) {
        _driverRemuneration = newValue;
    }

    function getTotalRevenue() {
        return _totalRevenue;
    }

    function setTotalRevenue(newValue) {
        _totalRevenue = newValue;
    }

    function getTotalRevenueMin() {
        return _totalRevenueMin;
    }

    function getTotalRevenueMax() {
        return _totalRevenueMax;
    }

    function getTotalRevenueIncrement() {
        return _totalRevenueIncrement;
    }
    function getTotalRevenueSetUsingMeasurementSystemId() {
        return _totalRevenueSetUsingMeasurementSystemId;
    }
    function setTotalRevenueSetUsingMeasurementSystemId(newValue) {
        _totalRevenueSetUsingMeasurementSystemId = newValue;
    }
    function getTotalRevenueAsCurrencyPerKm() {
        return _totalRevenueAsCurrencyPerKm;
    }
    function setTotalRevenueAsCurrencyPerKm(newValue) {
        _totalRevenueAsCurrencyPerKm = newValue;
    }
    function getTyreRate() {
        return _tyreRate;
    }
    function setTyreRate(newValue) {
        _tyreRate = newValue;
    }
    function getTyreRateMin() {
        return _tyreRateMin;
    }
    function setTyreRateMin(newValue) {
        _tyreRateMin = newValue;
    }
    function getTyreRateMax() {
        return _tyreRateMax;
    }
    function setTyreRateMax(newValue) {
        _tyreRateMax = newValue;
    }
    function getTyreRateIncrement() {
        return _tyreRateIncrement;
    }
    function setTyreRateIncrement(newValue) {
        _tyreRateIncrement = newValue;
    }
    function getTyreRateSetUsingMeasurementSystemId() {
        return _tyreRateSetUsingMeasurementSystemId;
    }
    function setTyreRateSetUsingMeasurementSystemId(newValue) {
        _tyreRateSetUsingMeasurementSystemId = newValue;
    }
    function getTyreRateAsCentsPerKm() {
        return _tyreRateAsCentsPerKm;
    }
    function setTyreRateAsCentsPerKm(newValue) {
        _tyreRateAsCentsPerKm = newValue;
    }
    function getLicenceFees() {
        return _licenceFees;
    }
    function setLicenceFees(newValue) {
        _licenceFees = newValue;
    }
    function getLicenceFeesMin() {
        return _licenceFeesMin;
    }
    function setLicenceFeesMin(newValue) {
        _licenceFeesMin = newValue;
    }
    function getLicenceFeesMax() {
        return _licenceFeesMax;
    }
    function setLicenceFeesMax(newValue) {
        _licenceFeesMax = newValue;
    }
    function getLicenceFeesIncrement() {
        return _licenceFeesIncrement;
    }
    function setLicenceFeesIncrement(newValue) {
        _licenceFeesIncrement = newValue;
    }
    function getTollFees() {
        return _tollFees;
    }
    function setTollFees(newValue) {
        _tollFees = newValue;
    }
    function getTollFeesMin() {
        return _tollFeesMin;
    }
    function setTollFeesMin(newValue) {
        _tollFeesMin = newValue;
    }
    function getTollFeesMax() {
        return _tollFeesMax;
    }
    function setTollFeesMax(newValue) {
        _tollFeesMax = newValue;
    }
    function getTollFeesIncrement() {
        return _tollFeesIncrement;
    }
    function setTollFeesIncrement(newValue) {
        _tollFeesIncrement = newValue;
    }
    function getFuelConsumptionOverride() {
        return _fuelConsumptionOverride;
    }
    function setFuelConsumptionOverride(newValue) {
        _fuelConsumptionOverride = newValue;
    }
    function getSimulatedFuelConsumption() {
        return _simulatedFuelConsumption;
    }
    function setSimulatedFuelConsumption(newValue) {
        _simulatedFuelConsumption = newValue;
    }
    function getTyrePriceMin() {
        return _tyrePriceMin;
    }
    function setTyrePriceMin(newValue) {
        _tyrePriceMin = newValue;
    }
    function getTyrePriceMax() {
        return _tyrePriceMax;
    }
    function setTyrePriceMax(newValue) {
        _tyrePriceMax = newValue;
    }
    function getTyrePriceIncrement() {
        return _tyrePriceIncrement;
    }
    function setTyrePriceIncrement(newValue) {
        _tyrePriceIncrement = newValue;
    }
    function getAverageTyreLifeMin() {
        return _averageTyreLifeMin;
    }
    function setAverageTyreLifeMin(newValue) {
        _averageTyreLifeMin = newValue;
    }
    function getAverageTyreLifeMax() {
        return _averageTyreLifeMax;
    }
    function setAverageTyreLifeMax(newValue) {
        _averageTyreLifeMax = newValue;
    }
    function getAverageTyreLifeIncrement() {
        return _averageTyreLifeIncrement;
    }
    function setAverageTyreLifeIncrement(newValue) {
        _averageTyreLifeIncrement = newValue;
    }
    function getTyreRateOverride() {
        return _tyreRateOverride;
    }
    function setTyreRateOverride(newValue) {
        _tyreRateOverride = newValue;
    }
    function getLicenceCategories() {
        return _licenceCategories;
    }
    function setLicenceCategories(newValue) {
        _licenceCategories = newValue;
    }
    function getLicenceFeesOverride() {
        return _licenceFeesOverride;
    }
    function setLicenceFeesOverride(newValue) {
        _licenceFeesOverride = newValue;
    }
    function getVehicleLicenceFees() {
        return _vehicleLicenceFees;
    }
    function setVehicleLicenceFees(newValue) {
        _vehicleLicenceFees = newValue;
    }
    function getVehicleLicenceFeesOverride() {
        return _vehicleLicenceFeesOverride;
    }
    function setVehicleLicenceFeesOverride(newValue) {
        _vehicleLicenceFeesOverride = newValue;
    }
    function getVehicleLicenceFeesRegionId() {
        return _vehicleLicenceFeesRegionId;
    }
    function setVehicleLicenceFeesRegionId(newValue) {
        _vehicleLicenceFeesRegionId = newValue;
    }
    function getVehiclePriceOverride() {
        return _vehiclePriceOverride;
    }
    function setVehiclePriceOverride(newValue) {
        _vehiclePriceOverride = newValue;
    }
    function getVehicleDiscount() {
        return _vehicleDiscount;
    }
    function setVehicleDiscount(newValue) {
        _vehicleDiscount = newValue;
    }
    function getVehiclePeriod() {
        return _vehiclePeriod;
    }
    function setVehiclePeriod(newValue) {
        _vehiclePeriod = newValue;
    }
    function getVehicleInterestRate() {
        return _vehicleInterestRate;
    }
    function setVehicleInterestRate(newValue) {
        _vehicleInterestRate = newValue;
    }
    function getVehicleResidualRate() {
        return _vehicleResidualRate;
    }
    function setVehicleResidualRate(newValue) {
        _vehicleResidualRate = newValue;
    }
    function getVehicleResidualRateType() {
        return _vehicleResidualRateType;
    }
    function setVehicleResidualRateType(newValue) {
        _vehicleResidualRateType = newValue;
    }
    function getFinanceTotalPrice() {
        return _financeTotalPrice;
    }
    function setFinanceTotalPrice(newValue) {
        _financeTotalPrice = newValue;
    }
    function getFinanceTotalPriceOverride() {
        return _financeTotalPriceOverride;
    }
    function setFinanceTotalPriceOverride(newValue) {
        _financeTotalPriceOverride = newValue;
    }
    function getFinanceDetailCostPerMonth() {
        return _financeDetailCostPerMonth;
    }
    function setFinanceDetailCostPerMonth(newValue) {
        _financeDetailCostPerMonth = newValue;
    }
    function getFinanceCostPerMonth() {
        return _financeCostPerMonth;
    }
    function setFinanceCostPerMonth(newValue) {
        _financeCostPerMonth = newValue;
    }
    function getVehicleCommonGroupFinancePeriod() {
        return _vehicleCommonGroupFinancePeriod;
    }
    function setVehicleCommonGroupFinancePeriod(newValue) {
        _vehicleCommonGroupFinancePeriod = newValue;
    }
    function getCountryInterestRate() {
        return _countryInterestRate;
    }
    function setCountryInterestRate(newValue) {
        _countryInterestRate = newValue;
    }
    function getVehiclePriceOnDatabase() {
        return _vehiclePriceOnDatabase;
    }
    function setVehiclePriceOnDatabase(newValue) {
        _vehiclePriceOnDatabase = newValue;
    }
    function getVehiclePriceEffectiveDate() {
        return _vehiclePriceEffectiveDate;
    }
    function setVehiclePriceEffectiveDate(newValue) {
        _vehiclePriceEffectiveDate = newValue;
    }
    function getFuelConsumptionOnDatabase() {
        return _fuelConsumptionOnDatabase;
    }
    function getMonthlyTrips() {
        return _monthlyTrips;
    }
    function setMonthlyTrips(newValue) {
        _monthlyTrips = newValue
    }
    function getMonthlyTripsMin() {
        return _monthlyTripsMin;
    }
    function setMonthlyTripsMin(newValue) {
        _monthlyTripsMin = newValue;
    }
    function getMonthlyTripsMax() {
        return _monthlyTripsMax;
    }
    function setMonthlyTripsMax(newValue) {
    _monthlyTripsMax = newValue;
    }
    function getMonthlyTripsIncrement() {
        return _monthlyTripsIncrement;
    }
    function setMonthlyTripsIncrement(newValue) {
        _monthlyTripsIncrement = newValue;
    }
    function getTollFeesOverride() {
        return _tollFeesOverride;
    }
    function setTollFeesOverride(newValue) {
        _tollFeesOverride = newValue;
    }
    function getTollFeesPerTrip() {
        return _tollFeesPerTrip;
    }
    function setTollFeesPerTrip(newValue) {
        _tollFeesPerTrip = newValue;
    }
    function getTollFeesPerTripOverride() {
        return _tollFeesPerTripOverride;
    }
    function setTollFeesPerTripOverride(newValue) {
        _tollFeesPerTripOverride = newValue;
    }
    function getPermitCostPerTrip() {
        return _permitCostPerTrip;
    }
    function setPermitCostPerTrip(newValue) {
        _permitCostPerTrip = newValue;
    }
    function getVehicleClassId() {
        return _vehicleClassId;
    }
    function setVehicleClassId(newValue) {
        _vehicleClassId = newValue;
    }
    function getVehicleClassIdOverride() {
        return _vehicleClassIdOverride;
    }
    function setVehicleClassIdOverride(newValue) {
        _vehicleClassIdOverride = newValue;
    }
    function getFuelAndAdblueCostPerMonthMin() {
        return _fuelAndAdblueCostPerMonthMin;
    }
    function setFuelAndAdblueCostPerMonthMin(newValue) {
        _fuelAndAdblueCostPerMonthMin = newValue;
    }
    function getFuelAndAdblueCostPerMonthMax() {
        return _fuelAndAdblueCostPerMonthMax;
    }
    function setFuelAndAdblueCostPerMonthMax(newValue) {
        _fuelAndAdblueCostPerMonthMax = newValue;
    }
    function getFuelAndAdblueCostPerMonthIncrement() {
        return _fuelAndAdblueCostPerMonthIncrement;
    }
    function setFuelAndAdblueCostPerMonthIncrement(newValue) {
        _fuelAndAdblueCostPerMonthIncrement = newValue;
    }
    function getFuelAndAdblueCostPerMonth() {
        return _fuelAndAdblueCostPerMonth;
    }
    function setFuelAndAdblueCostPerMonth(newValue) {
        _fuelAndAdblueCostPerMonth = newValue;
    }
    function getFuelAndAdblueCostPerMonthOverride() {
        return _fuelAndAdblueCostPerMonthOverride;
    }
    function setFuelAndAdblueCostPerMonthOverride(newValue) {
        _fuelAndAdblueCostPerMonthOverride = newValue;
    }
    function getVehicleFuelAndAdblueTotalCostPerMonth() {
        return _vehicleFuelAndAdblueTotalCostPerMonth;
    }
    function setVehicleFuelAndAdblueTotalCostPerMonth(newValue) {
        _vehicleFuelAndAdblueTotalCostPerMonth = newValue;
    }
    function getVehicleFuelAndAdblueTotalCostPerMonthOverride() {
        return _vehicleFuelAndAdblueTotalCostPerMonthOverride;
    }
    function setVehicleFuelAndAdblueTotalCostPerMonthOverride(newValue) {
        _vehicleFuelAndAdblueTotalCostPerMonthOverride = newValue;
    }
    function getVehicleFuelAndAdblueFuelConsumption() {
        return _vehicleFuelAndAdblueFuelConsumption;
    }
    function setVehicleFuelAndAdblueFuelConsumption(newValue) {
        _vehicleFuelAndAdblueFuelConsumption = newValue;
    }
    function getVehicleFuelAndAdblueFuelConsumptionOverride() {
        return _vehicleFuelAndAdblueFuelConsumptionOverride;
    }
    function setVehicleFuelAndAdblueFuelConsumptionOverride(newValue) {
        _vehicleFuelAndAdblueFuelConsumptionOverride = newValue;
    }
    function getVehicleFuelAndAdblueFuelConsumptionOnDatabase() {
        return _vehicleFuelAndAdblueFuelConsumptionOnDatabase;
    }
    function getVehicleFuelAndAdblueFuelCostPerVolume() {
        return _vehicleFuelAndAdblueFuelCostPerVolume;
    }
    function setVehicleFuelAndAdblueFuelCostPerVolume(newValue) {
        _vehicleFuelAndAdblueFuelCostPerVolume = newValue;
    }
    function getVehicleFuelAndAdblueFuelCostPerVolumeOverride() {
        return _vehicleFuelAndAdblueFuelCostPerVolumeOverride;
    }
    function setVehicleFuelAndAdblueFuelCostPerVolumeOverride(newValue) {
        _vehicleFuelAndAdblueFuelCostPerVolumeOverride = newValue;
    }
    function getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase() {
        return _vehicleFuelAndAdblueFuelCostPerVolumeOnDatabase;
    }
    function getVehicleFuelAndAdblueFuelCostPerVolumeMin() {
        return _vehicleFuelAndAdblueFuelCostPerVolumeMin;
    }
    function setVehicleFuelAndAdblueFuelCostPerVolumeMin(newValue) {
        _vehicleFuelAndAdblueFuelCostPerVolumeMin = newValue;
    }
    function getVehicleFuelAndAdblueFuelCostPerVolumeMax() {
        return _vehicleFuelAndAdblueFuelCostPerVolumeMax;
    }
    function setVehicleFuelAndAdblueFuelCostPerVolumeMax(newValue) {
        _vehicleFuelAndAdblueFuelCostPerVolumeMax = newValue;
    }
    function getVehicleFuelAndAdblueAdblueConsumption() {
        return _vehicleFuelAndAdblueAdblueConsumption;
    }
    function setVehicleFuelAndAdblueAdblueConsumption(newValue) {
        _vehicleFuelAndAdblueAdblueConsumption = newValue;
    }
    function getVehicleFuelAndAdblueAdblueConsumptionOverride() {
        return _vehicleFuelAndAdblueAdblueConsumptionOverride;
    }
    function setVehicleFuelAndAdblueAdblueConsumptionOverride(newValue) {
        _vehicleFuelAndAdblueAdblueConsumptionOverride = newValue;
    }    
    function getVehicleFuelAndAdblueAdblueConsumptionOnDatabase() {
        return _vehicleFuelAndAdblueAdblueConsumptionOnDatabase;
    }
    function getVehicleFuelAndAdblueAdblueCostPerVolume() {
        return _vehicleFuelAndAdblueAdblueCostPerVolume;
    }
    function setVehicleFuelAndAdblueAdblueCostPerVolume(newValue) {
        _vehicleFuelAndAdblueAdblueCostPerVolume = newValue;
    }
    function getVehicleFuelAndAdblueAdblueCostPerVolumeOverride() {
        return _vehicleFuelAndAdblueAdblueCostPerVolumeOverride;
    }
    function setVehicleFuelAndAdblueAdblueCostPerVolumeOverride(newValue) {
        _vehicleFuelAndAdblueAdblueCostPerVolumeOverride = newValue;
    }
    function getVehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase() {
        return _vehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase;
    }
    function getVehicleFuelAndAdblueEquipmentFuelConsumption() {
        return _vehicleFuelAndAdblueEquipmentFuelConsumption;
    }
    function setVehicleFuelAndAdblueEquipmentFuelConsumption(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelConsumption = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelConsumptionOverride() {
        return _vehicleFuelAndAdblueEquipmentFuelConsumptionOverride;
    }
    function setVehicleFuelAndAdblueEquipmentFuelConsumptionOverride(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelConsumptionOverride = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelCostPerVolume() {
        return _vehicleFuelAndAdblueEquipmentFuelCostPerVolume;
    }
    function setVehicleFuelAndAdblueEquipmentFuelCostPerVolume(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolume = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride() {
        return _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride;
    }
    function setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase() {
        return _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase;
    }
    function getVehicleFuelAndAdblueEquipmentUtilisation() {
        return _vehicleFuelAndAdblueEquipmentUtilisation;
    }
    function setVehicleFuelAndAdblueEquipmentUtilisation(newValue) {
        _vehicleFuelAndAdblueEquipmentUtilisation = newValue;
    }
    function getTrailerFuelAndAdblueEquipmentFuelCostPerVolume() {
        return _trailerFuelAndAdblueEquipmentFuelCostPerVolume;
    }
    function setTrailerFuelAndAdblueEquipmentFuelCostPerVolume(newValue) {
        _trailerFuelAndAdblueEquipmentFuelCostPerVolume = newValue;
    }
    function getVehicleFuelAndAdblueAdblueConsumptionMin() {
        return _vehicleFuelAndAdblueAdblueConsumptionMin;
    }
    function setVehicleFuelAndAdblueAdblueConsumptionMin(newValue) {
        _vehicleFuelAndAdblueAdblueConsumptionMin = newValue;
    }
    function getVehicleFuelAndAdblueAdblueConsumptionMax() {
        return _vehicleFuelAndAdblueAdblueConsumptionMax;
    }
    function setVehicleFuelAndAdblueAdblueConsumptionMax(newValue) {
        _vehicleFuelAndAdblueAdblueConsumptionMax = newValue;
    }
    function getVehicleFuelAndAdblueAdblueCostPerVolumeMin() {
        return _vehicleFuelAndAdblueAdblueCostPerVolumeMin;
    }
    function setVehicleFuelAndAdblueAdblueCostPerVolumeMin(newValue) {
        _vehicleFuelAndAdblueAdblueCostPerVolumeMin = newValue;
    }
    function getVehicleFuelAndAdblueAdblueCostPerVolumeMax() {
        return _vehicleFuelAndAdblueAdblueCostPerVolumeMax;
    }
    function setVehicleFuelAndAdblueAdblueCostPerVolumeMax(newValue) {
        _vehicleFuelAndAdblueAdblueCostPerVolumeMax = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin() {
        return _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    }
    function setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin = newValue;
    }
    function getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax() {
        return _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    }
    function setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax(newValue) {
        _vehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax = newValue;
    }
    function getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin() {
        return _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    }
    function setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin(newValue) {
        _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMin = newValue;
    }
    function getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax() {
        return _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    }
    function setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax(newValue) {
        _trailerFuelAndAdblueEquipmentFuelCostPerVolumeMax = newValue;
    }
    //#endregion getters and setters

    //#region Functions
    function updateValues(noOfUnits, noOfUnitsMin, noOfUnitsMax, noOfUnitsIncrement, priceIntervalsArray, depositRate, depositRateMin, depositRateMax, depositRateIncrement,
                        fuelConsumption, fuelConsumptionMin, fuelConsumptionMax, fuelConsumptionIncrement, fuelCost, fuelCostMin, fuelCostMax, fuelCostIncrement,
                        interestRate, interestRateMin, interestRateMax, interestRateIncrement, maintenanceRate, maintenanceRateMin, maintenanceRateMax, maintenanceRateIncrement,
                        monthlyDistance, monthlyDistanceMin, monthlyDistanceMax, monthlyDistanceIncrement, repaymentTerm, repaymentTermMin, repaymentTermMax, repaymentTermIncrement,
                        residualRate, residualRateMin, residualRateMax, residualRateIncrement, optionPriceMin, optionPriceMax, optionPriceIncrement,
                        bodyAndEquipmentPriceMin, bodyAndEquipmentPriceMax, bodyAndEquipmentPriceIncrement, vehiclePriceMin, vehiclePriceMax, vehiclePriceIncrement,
                        originalVehiclePrice, originalBodyAndEquipmentPrice, originalOptionPrice, onRoadCostsCosts, onRoadCostsDiscounts, decimalsHighPricedItems,
                        decimalsMediumPricedItems, decimalsLowPricedItems, driverRemuneration,
                        driverRemunerationMin, driverRemunerationMax, driverRemunerationIncrement, insuranceRate, insuranceRateMin, insuranceRateMax, insuranceRateIncrement,
                        overheadRate, overheadRateMin, overheadRateMax, overheadRateIncrement, tyreRate, tyreRateMin, tyreRateMax, tyreRateIncrement, licenceFeesMin, licenceFeesMax,
        licenceFeesIncrement, tollFeesMin, tollFeesMax, tollFeesIncrement, totalRevenueMin, totalRevenueMax, totalRevenueIncrement, tyrePriceMin, tyrePriceMax, tyrePriceIncrement,
        averageTyreLifeMin, averageTyreLifeMax, averageTyreLifeIncrement) {


        _noOfUnits = noOfUnits;
        _noOfUnitsMin = noOfUnitsMin;
        _noOfUnitsMax = noOfUnitsMax;
        _noOfUnitsIncrement = noOfUnitsIncrement;
        _depositRate = depositRate;
        _depositRateMin = depositRateMin;
        _depositRateMax = depositRateMax;
        _depositRateIncrement = depositRateIncrement;
        _fuelConsumption = fuelConsumption;
        _fuelConsumptionMin = fuelConsumptionMin;
        _fuelConsumptionMax = fuelConsumptionMax;
        _fuelConsumptionIncrement = fuelConsumptionIncrement;
        _fuelConsumptionOverride = false;
        _fuelCost = fuelCost;
        _fuelCostMin = fuelCostMin;
        _fuelCostMax = fuelCostMax;
        _fuelCostIncrement = fuelCostIncrement;
        _interestRate = interestRate;
        _interestRateMin = interestRateMin;
        _interestRateMax = interestRateMax;
        _interestRateIncrement = interestRateIncrement;
        _maintenanceRate = maintenanceRate;
        _maintenanceRateMin = maintenanceRateMin;
        _maintenanceRateMax = maintenanceRateMax;
        _maintenanceRateIncrement = maintenanceRateIncrement;
        _monthlyDistance = monthlyDistance;
        _monthlyDistanceMin = monthlyDistanceMin;
        _monthlyDistanceMax = monthlyDistanceMax;
        _monthlyDistanceIncrement = monthlyDistanceIncrement;
        _repaymentTerm = repaymentTerm;
        _repaymentTermMin = repaymentTermMin;
        _repaymentTermMax = repaymentTermMax;
        _repaymentTermIncrement = repaymentTermIncrement;
        _residualRate = residualRate;
        _residualRateMin = residualRateMin;
        _residualRateMax = residualRateMax;
        _residualRateIncrement = residualRateIncrement;
        _optionPrice = 0;
        _optionPriceMin = optionPriceMin;
        _optionPriceMax = optionPriceMax;
        _optionPriceIncrement = optionPriceIncrement;
        _bodyAndEquipmentPrice;
        _bodyAndEquipmentPriceMin = bodyAndEquipmentPriceMin;
        _bodyAndEquipmentPriceMax = bodyAndEquipmentPriceMax;
        _bodyAndEquipmentPriceMinActual;
        _bodyAndEquipmentPriceMaxActual;
        _bodyAndEquipmentPriceIncrement = bodyAndEquipmentPriceIncrement;
        _vehiclePrice;
        _vehiclePriceMinActual;
        _vehiclePriceMaxActual;
        _vehiclePriceMin = vehiclePriceMin;
        _vehiclePriceMax = vehiclePriceMax;
        _vehiclePriceIncrement = vehiclePriceIncrement;
        _originalVehiclePrice = originalVehiclePrice;
        _originalBodyAndEquipmentPrice = originalBodyAndEquipmentPrice;
        _originalOptionPrice = originalOptionPrice;
        _registrationFeePercent = 0;
        _registrationFee = 0;
        _numberPlatesCost = 0;
        _insuranceFeeCost = 0;
        _vehicleCertificationCost = 0;
        _registrationServiceCost = 0;
        _roadTaxCost = 0;
        _summaryDiscount = 0;
        _fuelConsumptionOutbound = 0;
        _fuelConsumptionReturn = 0;
        _onRoadCostsCosts = [];
        _onRoadCostsDiscounts = [];
        _priceIntervals = [];
        _decimalsHighPricedItems = decimalsHighPricedItems;
        _decimalsMediumPricedItems = decimalsMediumPricedItems;
        _decimalsLowPricedItems = decimalsLowPricedItems;
        _driverRemuneration = driverRemuneration;
        _driverRemunerationMin = driverRemunerationMin;
        _driverRemunerationMax = driverRemunerationMax;
        _driverRemunerationIncrement = driverRemunerationIncrement;
        _insuranceRate = insuranceRate;
        _insuranceRateMin = insuranceRateMin;
        _insuranceRateMax = insuranceRateMax;
        _insuranceRateIncrement = insuranceRateIncrement;
        _overheadRate = overheadRate;
        _overheadRateMin = overheadRateMin;
        _overheadRateMax = overheadRateMax;
        _overheadRateIncrement = overheadRateIncrement;
        _totalRevenue = 13;
        _totalRevenueMin = totalRevenueMin;
        _totalRevenueMax = totalRevenueMax;
        _totalRevenueIncrement = totalRevenueIncrement;
        _tyreRate = tyreRate;
        _tyreRateMin = tyreRateMin;
        _tyreRateMax = tyreRateMax;
        _tyreRateIncrement = tyreRateIncrement;
        _tyreRateSetUsingMeasurementSystemId;
        _licenceFees = 0;
        _licenceFeesMin = licenceFeesMin;
        _licenceFeesMax = licenceFeesMax;
        _licenceFeesIncrement = licenceFeesIncrement;
        _tollFees = 0;
        _tollFeesMin = tollFeesMin;
        _tollFeesMax = tollFeesMax;
        _tollFeesIncrement = tollFeesIncrement;
        _simulatedFuelConsumption = 0;
        _tyrePriceMin = tyrePriceMin;
        _tyrePriceMax = tyrePriceMax;
        _tyrePriceIncrement = tyrePriceIncrement;
        _averageTyreLifeMin = averageTyreLifeMin;
        _averageTyreLifeMax = averageTyreLifeMax;
        _averageTyreLifeIncrement = averageTyreLifeIncrement;

        completeCostingSetup(onRoadCostsCosts, onRoadCostsDiscounts, priceIntervalsArray);
    }


    //function getFinanceCostPerMonth() {
    //    var financeCostPerMonth = 0;

    //    if (getInterestRate() > 0) {
    //        financeCostPerMonth = (((getVehiclePrice() - (getVehiclePrice() * getResidualRate() / 100)) *
    //                                (getInterestRate() / 12 / 100) / (1 - Math.pow((1 + (getInterestRate() / 12 / 100)), (getRepaymentTerm() * -1))) +
    //                                (getVehiclePrice() * getResidualRate() / 100) * (getInterestRate() / 12 / 100)));
    //    }

    //    if (getInterestRate() === 0) {
    //        financeCostPerMonth = ((getVehiclePrice() - (getVehiclePrice() * getResidualRate() / 100)) / getRepaymentTerm());
    //    }

    //    if (getRepaymentTerm() === 0) {
    //        financeCostPerMonth = ((getVehiclePrice() - getVehiclePrice() * getResidualRate() / 100));
    //    }
        

    //    return financeCostPerMonth;
    //}

    /*
        var residualInterestRateCalc = totalCapitalCosts * residualRate / 100;
            var depositAmount = (totalCapitalCosts / 100) * depositRate;
            var interestRateCalc = interestRate / 12 / 100;
            return (totalCapitalCosts - depositAmount - residualInterestRateCalc) * interestRateCalc / (1 - Math.pow(1 + (interestRateCalc), (-1 * repaymentTerm))) + residualInterestRateCalc * interestRateCalc;
    */

    function getFinanceCostPerKM(measurementSystemId) {
        //return getFinanceCostPerMonth() / getMonthlyDistance();
        return getCalculatedFinanceCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getCrewCostPerKM(measurementSystemId) {
        //return getCrewCostPerMonth() / getMonthlyDistance();
        return getCrewCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getInsuranceCostPerMonth() {
        //return getVehiclePrice() * getInsuranceRate() / 100 / 12;
        return getFinanceTotalPrice() * getInsuranceRate() / 100 / 12;
    }

    function getInsuranceCostPerKM(measurementSystemId) {
        //return getInsuranceCostPerMonth() / getMonthlyDistance();
        return getInsuranceCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getLicenceFeesCostPerMonth() {
        return getLicenceFees() / 12;
    }

    function getLicenceFeesCostPerKM(measurementSystemId) {
        //return getLicenceFeesCostPerMonth() / getMonthlyDistance();
        return getLicenceFeesCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getFuelAndAdblueCostPerKM(measurementSystemId) {
        return getFuelAndAdblueCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getOverheadsCostPerMonth() {
        //return (getFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth()) / (1 - getOverheadRate()) / 10;
        //return (getFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth()) / (1 - (getOverheadRate() / 100)) / 10;
        //return (getCalculatedFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth()) / (1 - (getOverheadRate() / 100)) / 10;
        return (getCalculatedFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth()) / (1 - (getOverheadRate() / 100)) / (1 / (getOverheadRate() / 100));
    }

    function getOverheadsCostPerKM(measurementSystemId) {
        //return getOverheadsCostPerMonth() / getMonthlyDistance();
        return getOverheadsCostPerMonth() / getUtilisation(measurementSystemId);
    }

    function getFixedCostsTotalCostPerMonth() {
        //return getFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth() + getOverheadsCostPerMonth();
        return getCalculatedFinanceCostPerMonth() + getCrewCostPerMonth() + getInsuranceCostPerMonth() + getLicenceFeesCostPerMonth() + getOverheadsCostPerMonth();
    }

    function getFixedCostsTotalCostPerKM(measurementSystemId) {
        return getFinanceCostPerKM(measurementSystemId) + getCrewCostPerKM(measurementSystemId) + getInsuranceCostPerKM(measurementSystemId) + getLicenceFeesCostPerKM(measurementSystemId) + getOverheadsCostPerKM(measurementSystemId);
    }

    function getFuelCostPerMonth(measurementSystemId) {
        if (measurementSystemId === config.MEASUREMENT_SYSTEM.METRIC) {
            return getMonthlyDistance() / 100 * getFuelConsumption() * getFuelCost();
        } else {
            var measurementSystem = globals.getMeasurementSystemUsingType(measurementSystemId),
                monthlyDistance = getMonthlyDistance() * measurementSystem.distanceConversion,
                fuelConsumption = 100 / getFuelConsumption() * measurementSystem.distanceConversion / measurementSystem.volumeConversion,
                fuelCost = getFuelCost() / measurementSystem.volumeConversion;

            return monthlyDistance / fuelConsumption * fuelCost;
            //return getMonthlyDistance() / getFuelConsumption() * getFuelCost();
        }
    }

    function getFuelCostPerKM(measurementSystemId) {
        //return getFuelCostPerMonth(measurementSystemId) / getMonthlyDistance();
        return getFuelCostPerMonth(measurementSystemId) / getUtilisation(measurementSystemId);
    }

    function getTyreCostPerMonth(measurementSystemId) {
        //return getMonthlyDistance() * (getTyreRate() / 100);
        return getUtilisation(measurementSystemId) * (getTyreRate() / 100);
    }

    function getTyreRateCostPerDistance(measurementSystemId) {
        return getTyreRate() / 100;
    }

    function getTyreCostPerKM() {
        return getTyreRate()/100;
    }

    function getMaintenanceCostPerMonth(measurementSystemId) {
        //return getMonthlyDistance() * (getMaintenanceRate()/100);
        return getUtilisation(measurementSystemId) * (getMaintenanceRate() / 100);
    }

    function getMaintenanceCostPerKM() {
        return getMaintenanceRate()/100;
    }

    function getTollFeesCostPerMonth() {
        return getTollFees();
    }

    function getTollFeesCostPerKM(measurementSystemId) {
        //return getTollFees() / getMonthlyDistance();
        return getTollFees() / getUtilisation(measurementSystemId);
    }

    function getVariableCostsTotalCostPerMonth(measurementSystemId) {
        // return getFuelCostPerMonth(measurementSystemId) + getTyreCostPerMonth(measurementSystemId) + getMaintenanceCostPerMonth(measurementSystemId) + getTollFeesCostPerMonth();
        return getFuelAndAdblueCostPerMonth(measurementSystemId) + getTyreCostPerMonth(measurementSystemId) + getMaintenanceCostPerMonth(measurementSystemId) + getTollFeesCostPerMonth();
    }

    function getVariableCostsTotalCostPerKM(measurementSystemId) {
        // return getFuelCostPerKM(measurementSystemId) + getTyreCostPerKM() + getMaintenanceCostPerKM() + getTollFeesCostPerKM(measurementSystemId);
        return getFuelAndAdblueCostPerKM(measurementSystemId) + getTyreCostPerKM() + getMaintenanceCostPerKM() + getTollFeesCostPerKM(measurementSystemId);
    }

    function getTotalCostsPerMonth(measurementSystemId) {
        return getFixedCostsTotalCostPerMonth() + getVariableCostsTotalCostPerMonth(measurementSystemId);
    }

    function getTotalCostsPerKM(measurementSystemId) {
        return getFixedCostsTotalCostPerKM(measurementSystemId) + getVariableCostsTotalCostPerKM(measurementSystemId);
    }

    function getTotalRevenuePerMonth(measurementSystemId) {
        //return getTotalRevenue() * getMonthlyDistance();
        return getTotalRevenue() * getUtilisation(measurementSystemId);
    }

    function getTotalRevenuePerKM() {
        return getTotalRevenue();
    }

    function getTotalProfitPerMonth(measurementSystemId) {
        return getTotalRevenuePerMonth(measurementSystemId) - getTotalCostsPerMonth(measurementSystemId);
    }

    function getTotalProfitPerKM(measurementSystemId) {
        return getTotalRevenuePerKM() - getTotalCostsPerKM(measurementSystemId);
    }

    function getFinanceCostPercentageOfTotal(measurementSystemId) {
        //return getFinanceCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
        return getCalculatedFinanceCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getCrewCostPercentageOfTotal(measurementSystemId) {
        return getCrewCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getInsuranceCostPercentageOfTotal(measurementSystemId) {
        return getInsuranceCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getLicenceFeesCostPercentageOfTotal(measurementSystemId) {
        return getLicenceFeesCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getOverheadsCostPercentageOfTotal(measurementSystemId) {
        return getOverheadsCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getFuelCostPercentageOfTotal(measurementSystemId) {
        // return getFuelCostPerMonth(measurementSystemId) / getTotalCostsPerMonth(measurementSystemId) * 100;
        return getFuelAndAdblueCostPerMonth(measurementSystemId) / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getTyreCostPercentageOfTotal(measurementSystemId) {
        return getTyreCostPerMonth(measurementSystemId) / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getMaintenanceCostPercentageOfTotal(measurementSystemId) {
        return getMaintenanceCostPerMonth(measurementSystemId) / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getTollFeesCostPercentageOfTotal(measurementSystemId) {
        return getTollFeesCostPerMonth() / getTotalCostsPerMonth(measurementSystemId) * 100;
    }

    function getTotalFinanceCost() {
        //return getFinanceCostPerMonth() * getRepaymentTerm();
        return getCalculatedFinanceCostPerMonth() * getRepaymentTerm();
    }

    function getCalculatedFinanceCostPerMonth() {
        var returnValue = getFinanceCostPerMonth();
        if (!getFinanceTotalPriceOverride()) {
            if (typeof getFinanceDetailCostPerMonth() === 'number') {
                returnValue = getFinanceDetailCostPerMonth();
            }
        } else {
            returnValue = globals.calculateFinanceCostPerMonth(getFinanceTotalPrice(), getResidualRate(), getInterestRate(), getRepaymentTerm());
        }

        return returnValue;
    }

    function saveOnRoadCostsCosts() {
        var tempArray = [], tempObject = {};

        getOnRoadCostsCosts().forEach(function (onRoadCost) {
            tempObject = {
                "Name": onRoadCost.getName(),
                "Type": onRoadCost.getType(),
                "ValueType": onRoadCost.getValueType(),
                "Value": onRoadCost.getValue(),
                "PercentageOf": onRoadCost.getPercentageOf(),
                "Order": onRoadCost.getOrder()
            };
            tempArray.push(tempObject);
        });

        return tempArray;
    }

    function saveOnRoadCostsDiscounts() {
        var tempArray = [], tempObject = {};

        getOnRoadCostsDiscounts().forEach(function (onRoadCost) {
            tempObject = {
                "Name": onRoadCost.getName(),
                "Type": onRoadCost.getType(),
                "ValueType": onRoadCost.getValueType(),
                "Value": onRoadCost.getValue(),
                "PercentageOf": onRoadCost.getPercentageOf(),
                "Order": onRoadCost.getOrder()
            };
            tempArray.push(tempObject);
        });
        return tempArray;
    }

    function getUtilisation(measurementSystemId) {
        if (measurementSystemId === undefined || measurementSystemId === config.MEASUREMENT_SYSTEM.METRIC) {
            return getMonthlyDistance();
        } else {
            var measurementSystem = globals.getMeasurementSystemUsingType(measurementSystemId),
                monthlyDistance = getMonthlyDistance() * measurementSystem.distanceConversion;
            return monthlyDistance;
        }
    }
    //#endregion Functions

    //#region public members
    self.getNoOfUnits = getNoOfUnits;
    self.setNoOfUnits = setNoOfUnits;
    self.getNoOfUnitsMin = getNoOfUnitsMin;
    self.setNoOfUnitsMin = setNoOfUnitsMin;
    self.getNoOfUnitsMax = getNoOfUnitsMax;
    self.setNoOfUnitsMax = setNoOfUnitsMax;
    self.getNoOfUnitsIncrement = getNoOfUnitsIncrement;
    self.setNoOfUnitsIncrement = setNoOfUnitsIncrement;
    self.getDepositRate = getDepositRate;
    self.setDepositRate = setDepositRate;
    self.getDepositRateMin = getDepositRateMin;
    self.setDepositRateMin = setDepositRateMin;
    self.getDepositRateMax = getDepositRateMax;
    self.setDepositRateMax = setDepositRateMax;
    self.getDepositRateIncrement = getDepositRateIncrement;
    self.setDepositRateIncrement = setDepositRateIncrement;
    self.getFuelConsumption = getFuelConsumption;
    self.setFuelConsumption = setFuelConsumption;
    self.getFuelConsumptionMin = getFuelConsumptionMin;
    self.setFuelConsumptionMin = setFuelConsumptionMin;
    self.getFuelConsumptionMax = getFuelConsumptionMax;
    self.setFuelConsumptionMax = setFuelConsumptionMax;
    self.getFuelConsumptionIncrement = getFuelConsumptionIncrement;
    self.setFuelConsumptionIncrement = setFuelConsumptionIncrement;
    self.getFuelCost = getFuelCost;
    self.setFuelCost = setFuelCost;
    self.getFuelCostMin = getFuelCostMin;
    self.setFuelCostMin = setFuelCostMin;
    self.getFuelCostMax = getFuelCostMax;
    self.setFuelCostMax = setFuelCostMax;
    self.getFuelCostIncrement = getFuelCostIncrement;
    self.setFuelCostIncrement = setFuelCostIncrement;
    self.getInterestRate = getInterestRate;
    self.setInterestRate = setInterestRate;
    self.getInterestRateMin = getInterestRateMin;
    self.setInterestRateMin = setInterestRateMin;
    self.getInterestRateMax = getInterestRateMax;
    self.setInterestRateMax = setInterestRateMax;
    self.getInterestRateIncrement = getInterestRateIncrement;
    self.setInterestRateIncrement = setInterestRateIncrement;
    self.getMaintenanceRate = getMaintenanceRate;
    self.setMaintenanceRate = setMaintenanceRate;
    self.getMaintenanceRateMin = getMaintenanceRateMin;
    self.setMaintenanceRateMin = setMaintenanceRateMin;
    self.getMaintenanceRateMax = getMaintenanceRateMax;
    self.setMaintenanceRateMax = setMaintenanceRateMax;
    self.getMaintenanceRateIncrement = getMaintenanceRateIncrement;
    self.setMaintenanceRateIncrement = setMaintenanceRateIncrement;
    self.getMonthlyDistance = getMonthlyDistance;
    self.setMonthlyDistance = setMonthlyDistance;
    self.getMonthlyDistanceMin = getMonthlyDistanceMin;
    self.setMonthlyDistanceMin = setMonthlyDistanceMin;
    self.getMonthlyDistanceMax = getMonthlyDistanceMax;
    self.setMonthlyDistanceMax = setMonthlyDistanceMax;
    self.getMonthlyDistanceIncrement = getMonthlyDistanceIncrement;
    self.setMonthlyDistanceIncrement = setMonthlyDistanceIncrement;
    self.getRepaymentTerm = getRepaymentTerm;
    self.setRepaymentTerm = setRepaymentTerm;
    self.getRepaymentTermMin = getRepaymentTermMin;
    self.setRepaymentTermMin = setRepaymentTermMin;
    self.getRepaymentTermMax = getRepaymentTermMax;
    self.setRepaymentTermMax = setRepaymentTermMax;
    self.getRepaymentTermIncrement = getRepaymentTermIncrement;
    self.setRepaymentTermIncrement = setRepaymentTermIncrement;
    self.getResidualRate = getResidualRate;
    self.setResidualRate = setResidualRate;
    self.getResidualRateMin = getResidualRateMin;
    self.setResidualRateMin = setResidualRateMin;
    self.getResidualRateMax = getResidualRateMax;
    self.setResidualRateMax = setResidualRateMax;
    self.getResidualRateIncrement = getResidualRateIncrement;
    self.setResidualRateIncrement = setResidualRateIncrement;
    self.getOptionPrice = getOptionPrice;
    self.setOptionPrice = setOptionPrice;
    self.getOptionPriceMin = getOptionPriceMin;
    self.setOptionPriceMin = setOptionPriceMin;
    self.getOptionPriceMax = getOptionPriceMax;
    self.setOptionPriceMax = setOptionPriceMax;
    self.getOptionPriceIncrement = getOptionPriceIncrement;
    self.setOptionPriceIncrement = setOptionPriceIncrement;
    self.getBodyAndEquipmentPrice = getBodyAndEquipmentPrice;
    self.setBodyAndEquipmentPrice = setBodyAndEquipmentPrice;
    self.getBodyAndEquipmentPriceMin = getBodyAndEquipmentPriceMin;
    self.setBodyAndEquipmentPriceMin = setBodyAndEquipmentPriceMin;
    self.getBodyAndEquipmentPriceMinActual = getBodyAndEquipmentPriceMinActual;
    self.setBodyAndEquipmentPriceMinActual = setBodyAndEquipmentPriceMinActual;
    self.getBodyAndEquipmentPriceMax = getBodyAndEquipmentPriceMax;
    self.setBodyAndEquipmentPriceMaxActual = setBodyAndEquipmentPriceMaxActual;
    self.getBodyAndEquipmentPriceMaxActual = getBodyAndEquipmentPriceMaxActual;
    self.setBodyAndEquipmentPriceMax = setBodyAndEquipmentPriceMax;
    self.getBodyAndEquipmentPriceIncrement = getBodyAndEquipmentPriceIncrement;
    self.setBodyAndEquipmentPriceIncrement = setBodyAndEquipmentPriceIncrement;
    self.getVehiclePrice = getVehiclePrice;
    self.setVehiclePrice = setVehiclePrice;
    self.getVehiclePriceMin = getVehiclePriceMin;
    self.setVehiclePriceMin = setVehiclePriceMin;
    self.getVehiclePriceMinActual = getVehiclePriceMinActual;
    self.setVehiclePriceMinActual = setVehiclePriceMinActual;
    self.getVehiclePriceMax = getVehiclePriceMax;
    self.setVehiclePriceMax = setVehiclePriceMax;
    self.getVehiclePriceMaxActual = getVehiclePriceMaxActual;
    self.setVehiclePriceMaxActual = setVehiclePriceMaxActual;
    self.getVehiclePriceIncrement = getVehiclePriceIncrement;
    self.setVehiclePriceIncrement = setVehiclePriceIncrement;
    self.getOriginalVehiclePrice = getOriginalVehiclePrice;
    self.setOriginalVehiclePrice = setOriginalVehiclePrice;
    self.getOriginalBodyAndEquipmentPrice = getOriginalBodyAndEquipmentPrice;
    self.setOriginalBodyAndEquipmentPrice = setOriginalBodyAndEquipmentPrice;
    self.getOriginalOptionPrice = getOriginalOptionPrice;
    self.setOriginalOptionPrice = setOriginalOptionPrice;
    self.getRegistrationFeePercent = getRegistrationFeePercent;
    self.setRegistrationFeePercent = setRegistrationFeePercent;
    self.getRegistrationFee = getRegistrationFee;
    self.setRegistrationFee = setRegistrationFee;
    self.getNumberPlatesCost = getNumberPlatesCost;
    self.setNumberPlatesCost = setNumberPlatesCost;
    self.getInsuranceFeeCost = getInsuranceFeeCost;
    self.setInsuranceFeeCost = setInsuranceFeeCost;
    self.getVehicleCertificationCost = getVehicleCertificationCost;
    self.setVehicleCertificationCost = setVehicleCertificationCost;
    self.getRegistrationServiceCost = getRegistrationServiceCost;
    self.setRegistrationServiceCost = setRegistrationServiceCost;
    self.getRoadTaxCost = getRoadTaxCost;
    self.setRoadTaxCost = setRoadTaxCost;
    self.getSummaryDiscount = getSummaryDiscount;
    self.setSummaryDiscount = setSummaryDiscount;
    self.getFuelConsumptionOutbound = getFuelConsumptionOutbound;
    self.setFuelConsumptionOutbound = setFuelConsumptionOutbound;
    self.getFuelConsumptionReturn = getFuelConsumptionReturn;
    self.setFuelConsumptionReturn = setFuelConsumptionReturn;
    self.getOnRoadCostsCosts = getOnRoadCostsCosts;
    self.setOnRoadCostsCosts = setOnRoadCostsCosts;
    self.getOnRoadCostsDiscounts = getOnRoadCostsDiscounts;
    self.setOnRoadCostsDiscounts = setOnRoadCostsDiscounts;
    // self.setOnRoadCostsConstantValueForPercentage = setOnRoadCostsConstantValueForPercentage;
    self.saveOnRoadCostsCosts = saveOnRoadCostsCosts;
    self.saveOnRoadCostsDiscounts = saveOnRoadCostsDiscounts;
    self.getPriceIntervals = getPriceIntervals;
    self.getDecimalsHighPricedItems = getDecimalsHighPricedItems;
    self.getDecimalsMediumPricedItems = getDecimalsMediumPricedItems;
    self.getDecimalsLowPricedItems = getDecimalsLowPricedItems;
    self.getDriverRemuneration = getDriverRemuneration;
    self.setDriverRemuneration = setDriverRemuneration;
    self.getDriverRemunerationMin = getDriverRemunerationMin;
    self.setDriverRemunerationMin = setDriverRemunerationMin;
    self.getDriverRemunerationMax = getDriverRemunerationMax;
    self.setDriverRemunerationMax = setDriverRemunerationMax;
    self.getDriverRemunerationIncrement = getDriverRemunerationIncrement;
    self.setDriverRemunerationIncrement = setDriverRemunerationIncrement;
    self.getInsuranceRate = getInsuranceRate;
    self.setInsuranceRate = setInsuranceRate;
    self.getInsuranceRateMin = getInsuranceRateMin;
    self.setInsuranceRateMin = setInsuranceRateMin;
    self.getInsuranceRateMax = getInsuranceRateMax;
    self.setInsuranceRateMax = setInsuranceRateMax;
    self.getInsuranceRateIncrement = getInsuranceRateIncrement;
    self.setInsuranceRateIncrement = setInsuranceRateIncrement;
    self.getOverheadRate = getOverheadRate;
    self.setOverheadRate = setOverheadRate;
    self.getOverheadRateMin = getOverheadRateMin;
    self.setOverheadRateMin = setOverheadRateMin;
    self.getOverheadRateMax = getOverheadRateMax;
    self.setOverheadRateMax = setOverheadRateMax;
    self.getOverheadRateIncrement = getOverheadRateIncrement;
    self.setOverheadRateIncrement = setOverheadRateIncrement;
    self.getCrewCostPerMonth = getCrewCostPerMonth;
    self.setCrewCostPerMonth = setCrewCostPerMonth;
    self.getTotalRevenue = getTotalRevenue;
    self.getTotalRevenueMin = getTotalRevenueMin;
    self.getTotalRevenueMax = getTotalRevenueMax;
    self.getTotalRevenueIncrement = getTotalRevenueIncrement;
    self.setTotalRevenue = setTotalRevenue;    
    self.getTotalRevenueSetUsingMeasurementSystemId = getTotalRevenueSetUsingMeasurementSystemId;
    self.setTotalRevenueSetUsingMeasurementSystemId = setTotalRevenueSetUsingMeasurementSystemId;
    self.getTotalRevenueAsCurrencyPerKm = getTotalRevenueAsCurrencyPerKm;
    self.setTotalRevenueAsCurrencyPerKm = setTotalRevenueAsCurrencyPerKm;
    self.getTyreRate = getTyreRate;
    self.setTyreRate = setTyreRate;
    self.getTyreRateMin = getTyreRateMin;
    self.setTyreRateMin = setTyreRateMin;
    self.getTyreRateMax = getTyreRateMax;
    self.setTyreRateMax = setTyreRateMax;
    self.getTyreRateIncrement = getTyreRateIncrement;
    self.setTyreRateIncrement = setTyreRateIncrement;
    self.getTyreRateSetUsingMeasurementSystemId = getTyreRateSetUsingMeasurementSystemId;
    self.setTyreRateSetUsingMeasurementSystemId = setTyreRateSetUsingMeasurementSystemId;
    self.getTyreRateAsCentsPerKm = getTyreRateAsCentsPerKm;
    self.setTyreRateAsCentsPerKm = setTyreRateAsCentsPerKm;
    self.getLicenceFees = getLicenceFees;
    self.setLicenceFees = setLicenceFees;
    self.getLicenceFeesMin = getLicenceFeesMin;
    self.setLicenceFeesMin = setLicenceFeesMin;
    self.getLicenceFeesMax = getLicenceFeesMax;
    self.setLicenceFeesMax = setLicenceFeesMax;
    self.getLicenceFeesIncrement = getLicenceFeesIncrement;
    self.setLicenceFeesIncrement = setLicenceFeesIncrement;
    self.getTollFees = getTollFees;
    self.setTollFees = setTollFees;
    self.getTollFeesMin = getTollFeesMin;
    self.setTollFeesMin = setTollFeesMin;
    self.getTollFeesMax = getTollFeesMax;
    self.setTollFeesMax = setTollFeesMax;
    self.getTollFeesIncrement = getTollFeesIncrement;
    self.setTollFeesIncrement = setTollFeesIncrement;
    self.getFinanceCostPerMonth = getFinanceCostPerMonth;
    self.getFinanceCostPerKM = getFinanceCostPerKM;
    self.getCrewCostPerKM = getCrewCostPerKM;
    self.getInsuranceCostPerMonth = getInsuranceCostPerMonth;
    self.getInsuranceCostPerKM = getInsuranceCostPerKM;
    self.getLicenceFeesCostPerMonth = getLicenceFeesCostPerMonth;
    self.getLicenceFeesCostPerKM = getLicenceFeesCostPerKM;
    self.getOverheadsCostPerMonth = getOverheadsCostPerMonth;
    self.getOverheadsCostPerKM = getOverheadsCostPerKM;
    self.getFixedCostsTotalCostPerMonth = getFixedCostsTotalCostPerMonth;
    self.getFixedCostsTotalCostPerKM = getFixedCostsTotalCostPerKM;
    self.getFuelConsumptionOverride = getFuelConsumptionOverride;
    self.setFuelConsumptionOverride = setFuelConsumptionOverride;
    self.getSimulatedFuelConsumption = getSimulatedFuelConsumption;
    self.setSimulatedFuelConsumption = setSimulatedFuelConsumption;
    self.getFuelCostPerMonth = getFuelCostPerMonth;
    self.getFuelCostPerKM = getFuelCostPerKM;
    self.getTyreCostPerMonth = getTyreCostPerMonth;
    self.getTyreCostPerKM = getTyreCostPerKM;
    self.getMaintenanceCostPerMonth = getMaintenanceCostPerMonth;
    self.getMaintenanceCostPerKM = getMaintenanceCostPerKM;
    self.getTollFeesCostPerMonth = getTollFeesCostPerMonth;
    self.getTollFeesCostPerKM = getTollFeesCostPerKM;
    self.getVariableCostsTotalCostPerMonth = getVariableCostsTotalCostPerMonth;
    self.getVariableCostsTotalCostPerKM = getVariableCostsTotalCostPerKM;
    self.getTotalCostsPerMonth = getTotalCostsPerMonth;
    self.getTotalCostsPerKM = getTotalCostsPerKM;
    self.getTotalRevenuePerMonth = getTotalRevenuePerMonth;
    self.getTotalRevenuePerKM = getTotalRevenuePerKM;
    self.getTotalProfitPerMonth = getTotalProfitPerMonth;
    self.getTotalProfitPerKM = getTotalProfitPerKM;
    self.getFinanceCostPercentageOfTotal = getFinanceCostPercentageOfTotal;
    self.getCrewCostPercentageOfTotal = getCrewCostPercentageOfTotal;
    self.getInsuranceCostPercentageOfTotal = getInsuranceCostPercentageOfTotal;
    self.getLicenceFeesCostPercentageOfTotal = getLicenceFeesCostPercentageOfTotal;
    self.getOverheadsCostPercentageOfTotal = getOverheadsCostPercentageOfTotal;
    self.getFuelCostPercentageOfTotal = getFuelCostPercentageOfTotal;
    self.getTyreCostPercentageOfTotal = getTyreCostPercentageOfTotal;
    self.getMaintenanceCostPercentageOfTotal = getMaintenanceCostPercentageOfTotal;
    self.getTollFeesCostPercentageOfTotal = getTollFeesCostPercentageOfTotal;
    self.getTotalFinanceCost = getTotalFinanceCost;
    self.getTyrePriceMin = getTyrePriceMin;
    self.getTyrePriceMax = getTyrePriceMax;
    self.getTyrePriceIncrement = getTyrePriceIncrement;
    self.getAverageTyreLifeMin = getAverageTyreLifeMin;
    self.getAverageTyreLifeMax = getAverageTyreLifeMax;
    self.getAverageTyreLifeIncrement = getAverageTyreLifeIncrement;
    self.getTyreRateOverride = getTyreRateOverride;
    self.setTyreRateOverride = setTyreRateOverride;
    self.getLicenceCategories = getLicenceCategories;
    self.setLicenceCategories = setLicenceCategories;
    self.getLicenceFeesOverride = getLicenceFeesOverride;
    self.setLicenceFeesOverride = setLicenceFeesOverride;
    self.getVehicleLicenceFees = getVehicleLicenceFees;
    self.setVehicleLicenceFees = setVehicleLicenceFees;
    self.getVehicleLicenceFeesOverride = getVehicleLicenceFeesOverride;
    self.setVehicleLicenceFeesOverride = setVehicleLicenceFeesOverride;
    self.getVehicleLicenceFeesRegionId = getVehicleLicenceFeesRegionId;
    self.setVehicleLicenceFeesRegionId = setVehicleLicenceFeesRegionId;
    self.getVehiclePriceOverride = getVehiclePriceOverride;
    self.setVehiclePriceOverride = setVehiclePriceOverride;
    self.getVehicleDiscount = getVehicleDiscount;
    self.setVehicleDiscount = setVehicleDiscount;
    self.getVehiclePeriod = getVehiclePeriod;
    self.setVehiclePeriod = setVehiclePeriod;
    self.getVehicleInterestRate = getVehicleInterestRate;
    self.setVehicleInterestRate = setVehicleInterestRate;
    self.getVehicleResidualRate = getVehicleResidualRate;
    self.setVehicleResidualRate = setVehicleResidualRate;
    self.getVehicleResidualRateType = getVehicleResidualRateType;
    self.setVehicleResidualRateType = setVehicleResidualRateType;
    self.getFinanceTotalPrice = getFinanceTotalPrice;
    self.setFinanceTotalPrice = setFinanceTotalPrice;
    self.getFinanceTotalPriceOverride = getFinanceTotalPriceOverride;
    self.setFinanceTotalPriceOverride = setFinanceTotalPriceOverride;
    self.getFinanceDetailCostPerMonth = getFinanceDetailCostPerMonth;
    self.setFinanceDetailCostPerMonth = setFinanceDetailCostPerMonth;
    self.getFinanceCostPerMonth = getFinanceCostPerMonth;
    self.setFinanceCostPerMonth = setFinanceCostPerMonth;
    self.getVehicleCommonGroupFinancePeriod = getVehicleCommonGroupFinancePeriod;
    self.setVehicleCommonGroupFinancePeriod = setVehicleCommonGroupFinancePeriod;
    self.getCountryInterestRate = getCountryInterestRate;
    self.setCountryInterestRate = setCountryInterestRate;
    self.getVehiclePriceOnDatabase = getVehiclePriceOnDatabase;
    self.getVehiclePriceEffectiveDate = getVehiclePriceEffectiveDate;
    self.setVehiclePriceEffectiveDate = setVehiclePriceEffectiveDate;
    self.getFuelConsumptionOnDatabase = getFuelConsumptionOnDatabase;
    self.getCalculatedFinanceCostPerMonth = getCalculatedFinanceCostPerMonth;

    self.getMonthlyTrips = getMonthlyTrips;
    self.setMonthlyTrips = setMonthlyTrips;
    self.getMonthlyTripsMin = getMonthlyTripsMin;
    self.setMonthlyTripsMin = setMonthlyTripsMin;
    self.getMonthlyTripsMax = getMonthlyTripsMax;
    self.setMonthlyTripsMax = setMonthlyTripsMax;
    self.getMonthlyTripsIncrement = getMonthlyTripsIncrement;
    self.setMonthlyTripsIncrement = setMonthlyTripsIncrement;
    self.getTollFeesOverride = getTollFeesOverride;
    self.setTollFeesOverride = setTollFeesOverride;
    self.getTollFeesPerTrip = getTollFeesPerTrip;
    self.setTollFeesPerTrip = setTollFeesPerTrip;
    self.getTollFeesPerTripOverride = getTollFeesPerTripOverride;
    self.setTollFeesPerTripOverride = setTollFeesPerTripOverride;
    self.getPermitCostPerTrip = getPermitCostPerTrip;
    self.setPermitCostPerTrip = setPermitCostPerTrip;
    self.getVehicleClassId = getVehicleClassId;
    self.setVehicleClassId = setVehicleClassId;
    self.getVehicleClassIdOverride = getVehicleClassIdOverride;
    self.setVehicleClassIdOverride = setVehicleClassIdOverride;
    self.getFuelAndAdblueCostPerMonthMin = getFuelAndAdblueCostPerMonthMin;
    self.setFuelAndAdblueCostPerMonthMin = setFuelAndAdblueCostPerMonthMin;
    self.getFuelAndAdblueCostPerMonthMax = getFuelAndAdblueCostPerMonthMax;
    self.setFuelAndAdblueCostPerMonthMax = setFuelAndAdblueCostPerMonthMax;
    self.getFuelAndAdblueCostPerMonthIncrement = getFuelAndAdblueCostPerMonthIncrement;
    self.setFuelAndAdblueCostPerMonthIncrement = setFuelAndAdblueCostPerMonthIncrement;
    self.getFuelAndAdblueCostPerMonth = getFuelAndAdblueCostPerMonth;
    self.setFuelAndAdblueCostPerMonth = setFuelAndAdblueCostPerMonth;
    self.getFuelAndAdblueCostPerMonthOverride = getFuelAndAdblueCostPerMonthOverride;
    self.setFuelAndAdblueCostPerMonthOverride = setFuelAndAdblueCostPerMonthOverride;
    self.getFuelAndAdblueCostPerKM = getFuelAndAdblueCostPerKM;
    self.getVehicleFuelAndAdblueTotalCostPerMonth = getVehicleFuelAndAdblueTotalCostPerMonth;
    self.setVehicleFuelAndAdblueTotalCostPerMonth = setVehicleFuelAndAdblueTotalCostPerMonth;
    self.getVehicleFuelAndAdblueTotalCostPerMonthOverride = getVehicleFuelAndAdblueTotalCostPerMonthOverride;
    self.setVehicleFuelAndAdblueTotalCostPerMonthOverride = setVehicleFuelAndAdblueTotalCostPerMonthOverride;
    self.getVehicleFuelAndAdblueFuelConsumption = getVehicleFuelAndAdblueFuelConsumption;
    self.setVehicleFuelAndAdblueFuelConsumption = setVehicleFuelAndAdblueFuelConsumption;
    self.getVehicleFuelAndAdblueFuelConsumptionOverride = getVehicleFuelAndAdblueFuelConsumptionOverride;
    self.setVehicleFuelAndAdblueFuelConsumptionOverride = setVehicleFuelAndAdblueFuelConsumptionOverride;
    self.getVehicleFuelAndAdblueFuelCostPerVolume = getVehicleFuelAndAdblueFuelCostPerVolume;
    self.setVehicleFuelAndAdblueFuelCostPerVolume = setVehicleFuelAndAdblueFuelCostPerVolume;
    self.getVehicleFuelAndAdblueFuelCostPerVolumeOverride = getVehicleFuelAndAdblueFuelCostPerVolumeOverride;
    self.setVehicleFuelAndAdblueFuelCostPerVolumeOverride = setVehicleFuelAndAdblueFuelCostPerVolumeOverride;
    self.getVehicleFuelAndAdblueFuelCostPerVolumeMin = getVehicleFuelAndAdblueFuelCostPerVolumeMin;
    self.setVehicleFuelAndAdblueFuelCostPerVolumeMin = setVehicleFuelAndAdblueFuelCostPerVolumeMin;
    self.getVehicleFuelAndAdblueFuelCostPerVolumeMax = getVehicleFuelAndAdblueFuelCostPerVolumeMax;
    self.setVehicleFuelAndAdblueFuelCostPerVolumeMax = setVehicleFuelAndAdblueFuelCostPerVolumeMax;
    self.getVehicleFuelAndAdblueAdblueConsumption = getVehicleFuelAndAdblueAdblueConsumption;
    self.setVehicleFuelAndAdblueAdblueConsumption = setVehicleFuelAndAdblueAdblueConsumption;
    self.getVehicleFuelAndAdblueAdblueConsumptionOverride = getVehicleFuelAndAdblueAdblueConsumptionOverride;
    self.setVehicleFuelAndAdblueAdblueConsumptionOverride = setVehicleFuelAndAdblueAdblueConsumptionOverride;
    self.getVehicleFuelAndAdblueAdblueCostPerVolume = getVehicleFuelAndAdblueAdblueCostPerVolume;
    self.setVehicleFuelAndAdblueAdblueCostPerVolume = setVehicleFuelAndAdblueAdblueCostPerVolume;
    self.getVehicleFuelAndAdblueAdblueCostPerVolumeOverride = getVehicleFuelAndAdblueAdblueCostPerVolumeOverride;
    self.setVehicleFuelAndAdblueAdblueCostPerVolumeOverride = setVehicleFuelAndAdblueAdblueCostPerVolumeOverride;
    self.getVehicleFuelAndAdblueEquipmentFuelConsumption = getVehicleFuelAndAdblueEquipmentFuelConsumption;
    self.setVehicleFuelAndAdblueEquipmentFuelConsumption = setVehicleFuelAndAdblueEquipmentFuelConsumption;
    self.getVehicleFuelAndAdblueEquipmentFuelConsumptionOverride = getVehicleFuelAndAdblueEquipmentFuelConsumptionOverride;
    self.setVehicleFuelAndAdblueEquipmentFuelConsumptionOverride = setVehicleFuelAndAdblueEquipmentFuelConsumptionOverride;
    self.getVehicleFuelAndAdblueEquipmentFuelCostPerVolume = getVehicleFuelAndAdblueEquipmentFuelCostPerVolume;
    self.setVehicleFuelAndAdblueEquipmentFuelCostPerVolume = setVehicleFuelAndAdblueEquipmentFuelCostPerVolume;
    self.getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride = getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride;
    self.setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride = setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride;
    self.getVehicleFuelAndAdblueEquipmentUtilisation = getVehicleFuelAndAdblueEquipmentUtilisation;
    self.setVehicleFuelAndAdblueEquipmentUtilisation = setVehicleFuelAndAdblueEquipmentUtilisation;
    self.getTrailerFuelAndAdblueEquipmentFuelCostPerVolume = getTrailerFuelAndAdblueEquipmentFuelCostPerVolume;
    self.setTrailerFuelAndAdblueEquipmentFuelCostPerVolume = setTrailerFuelAndAdblueEquipmentFuelCostPerVolume;
    
    self.getVehicleFuelAndAdblueAdblueConsumptionMin = getVehicleFuelAndAdblueAdblueConsumptionMin;
    self.setVehicleFuelAndAdblueAdblueConsumptionMin = setVehicleFuelAndAdblueAdblueConsumptionMin;
    self.getVehicleFuelAndAdblueAdblueConsumptionMax = getVehicleFuelAndAdblueAdblueConsumptionMax;
    self.setVehicleFuelAndAdblueAdblueConsumptionMax = setVehicleFuelAndAdblueAdblueConsumptionMax;
    self.getVehicleFuelAndAdblueAdblueCostPerVolumeMin = getVehicleFuelAndAdblueAdblueCostPerVolumeMin;
    self.setVehicleFuelAndAdblueAdblueCostPerVolumeMin = setVehicleFuelAndAdblueAdblueCostPerVolumeMin;
    self.getVehicleFuelAndAdblueAdblueCostPerVolumeMax = getVehicleFuelAndAdblueAdblueCostPerVolumeMax;
    self.setVehicleFuelAndAdblueAdblueCostPerVolumeMax = setVehicleFuelAndAdblueAdblueCostPerVolumeMax;
    self.getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin = getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    self.setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin = setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    self.getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax = getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    self.setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax = setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    self.getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin = getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    self.setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin = setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin;
    self.getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax = getTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    self.setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax = setTrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax;
    self.getVehicleFuelAndAdblueFuelConsumptionOnDatabase = getVehicleFuelAndAdblueFuelConsumptionOnDatabase;
    self.getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase = getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase;
    self.getVehicleFuelAndAdblueAdblueConsumptionOnDatabase = getVehicleFuelAndAdblueAdblueConsumptionOnDatabase;
    self.getVehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase = getVehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase;
    self.getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase = getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase;

    self.updateValues = updateValues;
    //#endregion public members

    //properties
    self.vehiclePriceTextInput = shallowRef(false);
    self.bodyAndEquipmentPriceTextInput = shallowRef(false);
    
    completeCostingSetup(onRoadCostsCosts, onRoadCostsDiscounts, priceIntervalsArray);

    function completeCostingSetup(onRoadCostsCosts, onRoadCostsDiscounts, priceIntervalsArray) {
        // var tempOnRoadCostsCosts = [];
        // onRoadCostsCosts.forEach(function (currentItem, index) {
        //     tempOnRoadCostsCosts.push(new OnRoadCost(currentItem.Name, currentItem.Type, currentItem.ValueType, currentItem.Value, currentItem.Order, currentItem.PercentageOf))
        // });
        // setOnRoadCostsCosts(tempOnRoadCostsCosts);

        // var tempOnRoadCostsDiscounts = [];
        // onRoadCostsDiscounts.forEach(function (currentItem, index) {
        //     tempOnRoadCostsDiscounts.push(new OnRoadCost(currentItem.Name, currentItem.Type, currentItem.ValueType, currentItem.Value, currentItem.Order, currentItem.PercentageOf));
        // });
        // setOnRoadCostsDiscounts(tempOnRoadCostsDiscounts);

        //self.priceIntervals = ko.observableArray();

        var tempPriceIntervalsArray = [];
        for (var i = 0; i < priceIntervalsArray.length; i++) {
            tempPriceIntervalsArray.push(new PriceInterval(priceIntervalsArray[i].NoOfUnits, priceIntervalsArray[i].BodyAndEquipmentPriceMin, priceIntervalsArray[i].BodyAndEquipmentPriceMax,
                                                            priceIntervalsArray[i].VehiclePriceMin, priceIntervalsArray[i].VehiclePriceMax));
        }
        setPriceIntervals(tempPriceIntervalsArray);

        if (getPriceIntervals().length > 0) {
            setVehiclePriceMinActual(getPriceIntervals()[0].getVehiclePriceMin());
            setVehiclePriceMaxActual(getPriceIntervals()[0].getVehiclePriceMax());
            //setVehiclePrice(getPriceIntervals()[0].getVehiclePriceMax());
            setVehiclePriceOverride(false);
            setVehiclePrice(getPriceIntervals()[0].getVehiclePriceMax());
            setFinanceTotalPrice(getPriceIntervals()[0].getVehiclePriceMax());
            setBodyAndEquipmentPriceMinActual(getPriceIntervals()[0].getBodyAndEquipmentPriceMin());
            setBodyAndEquipmentPriceMaxActual(getPriceIntervals()[0].getBodyAndEquipmentPriceMax());
            setBodyAndEquipmentPrice(getPriceIntervals()[0].getBodyAndEquipmentPriceMax());
        } else {
            setVehiclePriceMinActual(0);
            setVehiclePriceMaxActual(0);
            //setVehiclePrice(0);
            setVehiclePriceOverride(false);
            setVehiclePrice(vehiclePriceMax);
            setBodyAndEquipmentPriceMinActual(0);
            setBodyAndEquipmentPriceMaxActual(0);
            setBodyAndEquipmentPrice(0);
        }
        setVehiclePriceOnDatabase(getVehiclePrice());
    }

    //#region Functions
    function setOnRoadCostsConstantValueForPercentage() {
        var temp;

        getOnRoadCostsCosts().forEach(function (onRoadCost) {
            if (onRoadCost.valueType === config.onRoadCostValueTypePercentage) {
                temp = (self[onRoadCost.percentageOf] * onRoadCost.value) / 100;
                //onRoadCost.constantValue(temp);
                onRoadCost.constantValue = temp;
            }
        });

        getOnRoadCostsDiscounts().forEach(function (onRoadCost) {
            if (onRoadCost.valueType === config.onRoadCostValueTypePercentage) {
                temp = (self[onRoadCost.percentageOf] * onRoadCost.value) / 100;
                //onRoadCost.constantValue(temp);
                onRoadCost.constantValue = temp;
            }
        });
    }
    //#endregion Functions
};

export default Costing;
