<template>
    <tr class="template-user-settings-image-upload">
        <td class="user-settings-modal-td-desc">
            <div>
                <span data-bind="text: label">{{imageUpload.label}}</span>
            </div>
            <div class="image-upload-message-container">
                {{imageUpload.messageText}}
            </div>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="imageUpload.displayTooltip === true"
                            :id="imageUpload.tooltipId"
                            :data-content="imageUpload.tooltipMessage"
                            @click="imageUpload.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                    <div v-if="imageUpload.displayUpgradeTooltip === true"
                        class="increment-div info-tooltip-div info-upgrade-div">
                        <a :id="imageUpload.upgradeTooltipMessageId"
                            :data-content="imageUpload.upgradeTooltipMessage"
                            @click="imageUpload.showUpgradePopover"
                            data-placement="top"
                            data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                </div>
                <div class="image-upload-section-container">
                    <div class="image-upload-container">
                        <div class="image-upload-image-container">
                            <img :src="imageUpload.obv.value"
                                alt=""
                                data-bind="attr: { src: obv } " />
                        </div>
                    </div>
                    <div class="image-upload-button-container">
                        <div class="image-upload-button-links-container">
                            <a @click="imageUpload.updateClick"
                                class="image-upload-button-update" 
                                data-bind="click: updateClick, touchstart: updateClick, text: $parents[1].getTranslationText('4247')">
                                {{getTranslationText('4247')}}
                            </a>
                            <a @click="imageUpload.resetClick"
                                class="image-upload-button-reset" 
                                data-bind="click: resetClick, touchstart: resetClick, text: $parents[1].getTranslationText('4248')">
                                {{getTranslationText('4248')}}
                            </a>
                        </div>
                        <input :id="imageUpload.fileInputId"
                            @change="imageUpload.fileUploaded"
                            style="display:none;" 
                            type="file" 
                            accept=".jpg,.png" 
                            data-bind="attr: { id: fileInputId }, event: { change: fileUploaded }" />
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

export default {
    name: 'InputTypeImageUpload',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const imageUpload = toRef(props, "inputFromParent")

        return {
            imageUpload,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>