<template>
    <td class="tyre-detail-column-axle">
        <span data-bind="text: label">{{row.label}}</span>
    </td>
    <td class="tyre-detail-column-num-tyres" data-bind="with: noOfTyresSelectMenu">
        <div>
            <div class="override-updownbox-checkbox"
                :class="{'override-false': row.noOfTyresSelectMenu.override.value === false }"
                @click="toggleOverride"
                data-toggle="tooltip"
                data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }">
                <i class="far fa-lg" 
                    :class="{ 'fa-square': row.noOfTyresSelectMenu.override.value === false, 'fa-check-square': row.noOfTyresSelectMenu.override.value === true }"
                    data-bind="css: { 'fa-square': override() === false, 'fa-check-square': override() === true }">
                </i>
            </div>
            <select :id="row.noOfTyresSelectMenu.id" 
                v-model="row.noOfTyresSelectMenu.valueForSelect.value"
                data-toggle="tooltip">
                <option v-for="option in row.noOfTyresSelectMenu.optionsArray" :key="option" :value="option.id">
                    {{ option.description }}</option>
            </select>
        </div>
    </td>
    <td class="tyre-detail-column-tyre-cost" 
        data-bind="template: { name: 'template-row-tyre-detail-slider', data: averageCostOfTyresSlider }">
        <RowTyreDetailSlider :sliderFromParent="row.averageCostOfTyresSlider"></RowTyreDetailSlider>
    </td>
    <td class="tyre-detail-column-tyre-life" 
        data-bind="template: { name: 'template-row-tyre-detail-slider', data: averageLifeOfTyresSlider }">
        <RowTyreDetailSlider :sliderFromParent="row.averageLifeOfTyresSlider"></RowTyreDetailSlider>
    </td>
    <td class="tyre-detail-column-cost-per-km">
        <div>
            <span data-bind="text: costPerKmFormatted">{{row.costPerKmFormatted.value}}</span>
        </div>
    </td>

    <!-- <script type="text/html" id="template-row-tyre-detail">
        <td class="tyre-detail-column-axle">
            <span data-bind="text: label"></span>
        </td>
        <td class="tyre-detail-column-num-tyres" data-bind="with: noOfTyresSelectMenu">
            <div>
                <div class="override-updownbox-checkbox" data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }" data-toggle="tooltip">
                    <i class="far fa-square fa-lg" data-bind="css: { 'fa-square': override() === false, 'fa-check-square': override() === true }"></i>
                </div>
                <select data-bind="options: optionsArray, optionsText: 'description', optionsValue: 'id', value: valueForSelect, attr: { id: id }"></select>
            </div>
        </td>
        <td class="tyre-detail-column-tyre-cost" data-bind="template: { name: 'template-row-tyre-detail-slider', data: averageCostOfTyresSlider }"></td>
        <td class="tyre-detail-column-tyre-life" data-bind="template: { name: 'template-row-tyre-detail-slider', data: averageLifeOfTyresSlider }"></td>
        <td class="tyre-detail-column-cost-per-km">
            <div>
                <span data-bind="text: costPerKmFormatted"></span>
            </div>
        </td>
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import RowTyreDetailSlider from './RowTyreDetailSlider.vue';

export default {
    name: 'RowTyreDetail',
    props: {
        rowFromParent: Object
    },
    components: { RowTyreDetailSlider },
    setup (props) {
        const row = toRef(props, "rowFromParent");

        function toggleOverride() {
            row.value.noOfTyresSelectMenu.toggleOverride();
        }

        return {
            row,
            toggleOverride
        }
    }
}
</script>

<style lang="scss" scoped>

</style>