
var BodyUprightHolder = function () {

    var self = this;

    

    //#region Private members
    var _bodyUprights = [];
    
    //#endregion Private members

    //#region Getters and setters
    function getBodyUprights() {
        return _bodyUprights;
    }
    function setBodyUprights(newValue) {
        _bodyUprights = newValue;
    }
    //#endregion Getters and setters

    function add(bodyUpright) {
        if (bodyUpright.getNumber() === undefined) {
            bodyUpright.setNumber(_bodyUprights.length + 1);
        }
        _bodyUprights.push(bodyUpright);
    }

    function remove(bodyUpright) {
        

        var indexOfElementToRemove = _bodyUprights.map(function (item) { return item.getNumber(); }).indexOf(bodyUpright.getNumber());

        _bodyUprights.splice(indexOfElementToRemove, 1);
        var i = 1;
        _bodyUprights.forEach(function (item) {
            item.setNumber(i);
            i++;
        });

    }

    function removeUprightById(bodyUprightId) {

        var upright = getUprightById(bodyUprightId);
        if (upright !== null) {
            remove(upright);
        }

    }

    function getUprightById(id) {
        var foundUpright = null;
        _bodyUprights.forEach(function (upright) {
            if (upright.getId() === id) {
                foundUpright = upright;
            }
        });
        return foundUpright;
    }

    function getBodyUprightsArrayForSave() {
        var temp = [];
        _bodyUprights.forEach(function (upright) {
            temp.push({
                Number: upright.getNumber(),
                InsideFrontToUpright: upright.getInsideFrontToUpright(),
                Width: upright.getWidth(),
                Height: upright.getHeight()
            })
        });
        

        return temp;
    }

    self.getBodyUprights = getBodyUprights;
    self.add = add;
    self.remove = remove;
    self.removeUprightById = removeUprightById;
    self.getUprightById = getUprightById;
    self.getBodyUprightsArrayForSave = getBodyUprightsArrayForSave;
}

export default BodyUprightHolder;
