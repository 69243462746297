import SpecificationAttribute from './model.specificationAttribute';

 

var SpecificationVehicle = function (manufacturerLogo, vehicleChassisImage, manufacturerReportLogo, vehicleChassisReportImage, vehicleModel, vehicleId, attributes,
                                        pdfName1, pdfUrl1, pdfName2, pdfUrl2, pdfName3, pdfUrl3, pdfName4, pdfUrl4, pdfName5, pdfUrl5) {

    var self = this;

    var _manufacturerLogo,
        _vehicleChassisImage,
        _manufacturerReportLogo,
        _vehicleChassisReportImage,
        _vehicleModel,
        _vehicleId,
        _attributes,
        _advantages,
        _pdfName1,
        _pdfUrl1,
        _pdfName2,
        _pdfUrl2,
        _pdfName3,
        _pdfUrl3,
        _pdfName4,
        _pdfUrl4,
        _pdfName5,
        _pdfUrl5;

    //#region Getters and setters
    function getManufacturerLogo() {
        return _manufacturerLogo;
    }
    function setManufacturerLogo(newValue) {
        _manufacturerLogo = newValue;
    }
    function getVehicleChassisImage() {
        return _vehicleChassisImage;
    }
    function setVehicleChassisImage(newValue) {
        _vehicleChassisImage = newValue;
    }
    function getManufacturerReportLogo() {
        return _manufacturerReportLogo;
    }
    function setManufacturerReportLogo(newValue) {
        _manufacturerReportLogo = newValue;
    }
    function getVehicleChassisReportImage() {
        return _vehicleChassisReportImage;
    }
    function setVehicleChassisReportImage(newValue) {
        _vehicleChassisReportImage = newValue;
    }
    function getVehicleModel() {
        return _vehicleModel;
    }
    function setVehicleModel(newValue) {
        _vehicleModel = newValue;
    }
    function getVehicleId() {
        return _vehicleId;
    }
    function setVehicleId(newValue) {
        _vehicleId = newValue;
    }
    function getAttributes() {
        return _attributes;
    }
    function setAttributes(newValue) {
        _attributes = newValue;
    }
    function getAdvantages() {
        return _advantages;
    }
    function setAdvantages(newValue) {
        _advantages = newValue;
    }
    function getPdfName1() {
        return _pdfName1;
    }
    function setPdfName1(newValue) {
        _pdfName1 = newValue;
    }
    function getPdfUrl1() {
        return _pdfUrl1;
    }
    function setPdfUrl1(newValue) {
        _pdfUrl1 = newValue;
    }
    function getPdfName2() {
        return _pdfName2;
    }
    function setPdfName2(newValue) {
        _pdfName2 = newValue;
    }
    function getPdfUrl2() {
        return _pdfUrl2;
    }
    function setPdfUrl2(newValue) {
        _pdfUrl2 = newValue;
    }
    function getPdfName3() {
        return _pdfName3;
    }
    function setPdfName3(newValue) {
        _pdfName3 = newValue;
    }
    function getPdfUrl3() {
        return _pdfUrl3;
    }
    function setPdfUrl3(newValue) {
        _pdfUrl3 = newValue;
    }
    function getPdfName4() {
        return _pdfName4;
    }
    function setPdfName4(newValue) {
        _pdfName4 = newValue;
    }
    function getPdfUrl4() {
        return _pdfUrl4;
    }
    function setPdfUrl4(newValue) {
        _pdfUrl4 = newValue;
    }
    function getPdfName5() {
        return _pdfName5;
    }
    function setPdfName5(newValue) {
        _pdfName5 = newValue;
    }
    function getPdfUrl5() {
        return _pdfUrl5;
    }
    function setPdfUrl5(newValue) {
        _pdfUrl5 = newValue;
    }

    function getAttributeUsingCode(code, unit, measurementSystemId) {
        var attributeValue, 
            attribute = getAttributes().filter(function (attribute) {
                return attribute.getHeadingCode() === code;
            })[0] || null;
        attributeValue = attribute !== null ? attribute.getValueFormatted(unit, measurementSystemId) : '';

        return attributeValue;
    }
    //#endregion Getters and setters

    //#region Initialize
    setManufacturerLogo(manufacturerLogo);
    setVehicleChassisImage(vehicleChassisImage);
    setManufacturerReportLogo(manufacturerReportLogo);
    setVehicleChassisReportImage(vehicleChassisReportImage);
    setVehicleModel(vehicleModel);
    setVehicleId(vehicleId);

    setPdfName1(pdfName1);
    setPdfUrl1(pdfUrl1);
    setPdfName2(pdfName2);
    setPdfUrl2(pdfUrl2);
    setPdfName3(pdfName3);
    setPdfUrl3(pdfUrl3);
    setPdfName4(pdfName4);
    setPdfUrl4(pdfUrl4);
    setPdfName5(pdfName5);
    setPdfUrl5(pdfUrl5);

    var tempAttributes = [];
    var i;
    for (i = 0; i < attributes.length; i++) {
        tempAttributes.push(new SpecificationAttribute(attributes[i].HeadingCode, attributes[i].Value));
    }
    setAttributes(tempAttributes);
    //#endregion Initialize

    function displayDownloadButtonOnSpecification() {
        return getPdfName1() !== '' || getPdfUrl1() !== '' || getPdfName2() !== '' || getPdfUrl2() !== '' || getPdfName3() !== '' || getPdfUrl3() !== '' ||
            getPdfName4() !== '' || getPdfUrl4() !== '' || getPdfName5() !== '' || getPdfUrl5() !== '';
    }

    function displayDownloadButtonWithoutDropUp() {
        var countOfLinks = 0;

        if (getPdfName1() !== '' && getPdfUrl1() !== '') {
            countOfLinks++;
        }
        if (getPdfName2() !== '' && getPdfUrl2() !== '') {
            countOfLinks++;
        }
        if (getPdfName3() !== '' && getPdfUrl3() !== '') {
            countOfLinks++;
        }
        if (getPdfName4() !== '' && getPdfUrl4() !== '') {
            countOfLinks++;
        }
        if (getPdfName5() !== '' && getPdfUrl5() !== '') {
            countOfLinks++;
        }
        return countOfLinks === 1;
    }

    function getPdfNameForDownloadButtonWithoutDropUp() {
        if (getPdfName1() !== '' && getPdfUrl1() !== '') {
            return {
                name: getPdfName1(),
                url: getPdfUrl1()
            };
        }
        if (getPdfName2() !== '' && getPdfUrl2() !== '') {
            return {
                name: getPdfName2(),
                url: getPdfUrl2()
            };
        }
        if (getPdfName3() !== '' && getPdfUrl3() !== '') {
            return {
                name: getPdfName3(),
                url: getPdfUrl3()
            };
        }
        if (getPdfName4() !== '' && getPdfUrl4() !== '') {
            return {
                name: getPdfName4(),
                url: getPdfUrl4()
            };
        }
        if (getPdfName5() !== '' && getPdfUrl5() !== '') {
            return {
                name: getPdfName5(),
                url: getPdfUrl5()
            };
        }
    }
    function getPdfObjectUsingId(id) {
        switch (id) {
            case 1:
                return {
                    name: getPdfName1(),
                    url: getPdfUrl1()
                };
            case 2:
                return {
                    name: getPdfName2(),
                    url: getPdfUrl2()
                };

            case 3:
                return {
                    name: getPdfName3(),
                    url: getPdfUrl3()
                };

            case 4:
                return {
                    name: getPdfName4(),
                    url: getPdfUrl4()
                };

            case 5:
                return {
                    name: getPdfName5(),
                    url: getPdfUrl5()
                };
            default:
                return null;
        }
    }
    function displayPdfLinkUsingId(id) {
        switch (id) {
            case 1:
                return getPdfName1() !== '' && getPdfUrl1() !== '';
            case 2:
                return getPdfName2() !== '' && getPdfUrl2() !== '';
            case 3:
                return getPdfName3() !== '' && getPdfUrl3() !== '';
            case 4:
                return getPdfName4() !== '' && getPdfUrl4() !== '';
            case 5:
                return getPdfName5() !== '' && getPdfUrl5() !== '';
            default:
                return false;
        }
    }
    //#region Public members
    self.getManufacturerLogo = getManufacturerLogo;
    self.setManufacturerLogo = setManufacturerLogo;
    self.getVehicleChassisImage = getVehicleChassisImage;
    self.setVehicleChassisImage = setVehicleChassisImage;
    self.getManufacturerReportLogo = getManufacturerReportLogo;
    self.setManufacturerReportLogo = setManufacturerReportLogo;
    self.getVehicleChassisReportImage = getVehicleChassisReportImage;
    self.setVehicleChassisReportImage = setVehicleChassisReportImage;
    self.getVehicleModel = getVehicleModel;
    self.setVehicleModel = setVehicleModel;
    self.getVehicleId = getVehicleId;
    self.setVehicleId = setVehicleId;
    self.getAdvantages = getAdvantages;
    self.setAdvantages = setAdvantages;
    self.getAttributeUsingCode = getAttributeUsingCode;
    self.getPdfName1 = getPdfName1;
    self.setPdfName1 = setPdfName1;
    self.getPdfUrl1 = getPdfUrl1;
    self.setPdfUrl1 = setPdfUrl1;
    self.getPdfName2 = getPdfName2;
    self.setPdfName2 = setPdfName2;
    self.getPdfUrl2 = getPdfUrl2;
    self.setPdfUrl2 = setPdfUrl2;
    self.getPdfName3 = getPdfName3;
    self.setPdfName3 = setPdfName3;
    self.getPdfUrl3 = getPdfUrl3;
    self.setPdfUrl3 = setPdfUrl3;
    self.getPdfName4 = getPdfName4;
    self.setPdfName4 = setPdfName4;
    self.getPdfUrl4 = getPdfUrl4;
    self.setPdfUrl4 = setPdfUrl4;
    self.getPdfName5 = getPdfName5;
    self.setPdfName5 = setPdfName5;
    self.getPdfUrl5 = getPdfUrl5;
    self.setPdfUrl5 = setPdfUrl5;
    self.displayDownloadButtonOnSpecification = displayDownloadButtonOnSpecification;
    self.displayDownloadButtonWithoutDropUp = displayDownloadButtonWithoutDropUp;
    self.getPdfNameForDownloadButtonWithoutDropUp = getPdfNameForDownloadButtonWithoutDropUp;
    self.getPdfObjectUsingId = getPdfObjectUsingId;
    self.displayPdfLinkUsingId = displayPdfLinkUsingId;
    //#endregion Public members

    //NOTE: The code below may have to be removed
    self.manufacturerLogo = manufacturerLogo;
    self.vehicleChassisImage = vehicleChassisImage;
    self.manufacturerReportLogo = manufacturerReportLogo;
    self.vehicleChassisReportImage = vehicleChassisReportImage;
    self.vehicleModel = vehicleModel;
    self.vehicleId = vehicleId;

    //properties
    self.attributes = [];

    for (i = 0; i < attributes.length; i++) {
        self.attributes.push(new SpecificationAttribute(attributes[i].HeadingCode, attributes[i].Value));
    }

    //self.advantages = ko.observableArray();
    self.advantages = [];
};

export default SpecificationVehicle;

