import config from '../../services/config';



var ShareUser = function (shareUser) {
	this._id = shareUser.UserId;
	this._firstName = shareUser.FirstName;
	this._lastName = shareUser.LastName;
	this._email = shareUser.EmailAddress;
	if (shareUser.ShareStatus) {
		this._shareStatus = shareUser.ShareStatus;
		this._shareUserType = config.SHARE_USER_TYPES.SHAREE;
	} else {
		this._shareUserType = config.SHARE_USER_TYPES.SHARER;
	}
	this._sharePermission = shareUser.SharePermission;
}

ShareUser.prototype.isSharer = function () {
	return this._shareUserType === config.SHARE_USER_TYPES.SHARER;
}

ShareUser.prototype.getId = function () {
	return this._id;
}

ShareUser.prototype.getFirstName = function () {
	return this._firstName;
}

ShareUser.prototype.getLastName = function () {
	return this._lastName;
}

ShareUser.prototype.getEmail = function () {
	return this._email;
}

ShareUser.prototype.getShareStatus = function () {
	return this._shareStatus;
}
ShareUser.prototype.getSharePermission = function () {
	return this._sharePermission;
}
ShareUser.prototype.setSharePermission = function (permission) {
	this._sharePermission = permission;
}
export default ShareUser

