<template>
    <tr data-bind="template: { name: 'template-user-settings-input-group' }">
        <td colspan="2">
            <table data-bind="template: { name: 'template-user-settings-input-types-tbody', data: inputGroupItems }"></table>
            <button class="btn btn-default btn-truckscience btn-primary" data-bind="click: clickButtonFunction, touchstart: clickButtonFunction"><span data-bind="text: buttonText"></span></button>
        </td>
    </tr>
    
    <!-- ko if: type === $root.config.INPUT_TYPE.INPUT_GROUP
    <tr data-bind="template: { name: 'template-user-settings-input-group' }"></tr>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-input-group">
        <td colspan="2">
            <table data-bind="template: { name: 'template-user-settings-input-types-tbody', data: inputGroupItems }"></table>
            <button class="btn btn-default btn-truckscience btn-primary" data-bind="click: clickButtonFunction, touchstart: clickButtonFunction"><span data-bind="text: buttonText"></span></button>
        </td>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeInputGroup',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const inputGroup = toRef(props, "inputFromParent")

        return {
            inputGroup
        }
    }
}
</script>

<style lang="scss" scoped>

</style>