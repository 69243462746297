// define([], function () {

/**
 * @class UiInput
 * @constructor
 * @param {object} initInput - Initial object to create input
 */
function Input(initInput) {
    this._id = initInput.id;
    this._label = initInput.label;
    this._customCss = typeof initInput.customCss === 'string' ? initInput.customCss : '';

    return this;
}

/**
 * Get the ID
 * @memberof UiInput
 * @returns {string} - Return the ID
 */
Input.prototype.getId = function () {
    return this._id;
};

/**
 * Get the label
 * @memberof UiInput
 * @returns {string} - Return the label text
 */
Input.prototype.getLabel = function () {
    return this._label;
};

/**
 * Change the label text
 * @memberof UiInput
 * @param {string} newValue - New text 
 */
Input.prototype.setLabel = function (newValue) {
    if (typeof newValue === 'string') {
        this._label = newValue;
    }
};

/**
 * Get the CSS classes to be added to element
 * @memberof UiInput
 * @returns {string} - Return the CSS classes
 */
Input.prototype.getCustomCss = function () {
    return this._customCss;
};

export default Input;

//     return Input;
// });
