<template>
    <div class="image-card-container">
        <!-- <div class="image-card" v-for="item in wizard.itemsToDisplayArray" :key="item" @click="wizard.clickOnItem"> -->
        <div class="image-card" v-for="item in wizard.itemsToDisplayArray.value" :key="item" @click="wizard.clickOnItem(item)">
            <span>{{ item.VehicleRange }}</span>
        </div>
    </div>
</template>

<script>
import { toRef } from 'vue';

export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        let wizard = toRef(props, "wizardFromParent");

        // watch(() => props.wizardFromParent,
        //     (newValue, oldValue) => {
        //         wizard.value = newValue;
        //     });

        return {
            wizard
        }
    }
}
</script>

<style lang="scss" scoped>
</style>