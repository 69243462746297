<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox submit-pending-vehicle-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: modalTitle">{{getTranslationText('2710')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div>
                    <span data-bind="text: postValidationVehicleStatusPromptText">{{getTranslationText('1120')}}</span>
                </div>
                <div>
                    <!-- <select data-bind="options: optionsArray, optionsText: 'description', optionsValue: 'id', value: selectedOption, attr: { id: 'post-validated-vehicle-status-select' }" data-toggle="tooltip"></select> -->
                    <select id="post-validated-vehicle-status-select" 
                        :value="selectedOption"
                        data-toggle="tooltip">
                        <option v-for="option in optionsArray" :key="option" :value="option.id">
                            {{ option.description }}</option>
                    </select>
                </div>
                <div class="request-vehicle-input-container textarea">
                    <textarea class="request-vehicle-textarea"
                        :placeholder="getTranslationText('1155')" 
                        v-model="comments"
                        data-bind="value: comments, attr: {placeholder: commentsPlaceholder}"></textarea>
                </div>
            </div>
            
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" 
                    @click="ok"
                    data-bind="click: function () { close(getTranslationText('1113'))}, touchstart: function () { close(getTranslationText('1113'))}">
                    <span data-bind="text: getTranslationText('1113')">{{getTranslationText('1113')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience" 
                    @click="cancel"
                    data-bind="click: function () { close(getTranslationText('65'))}, touchstart: function () { close(getTranslationText('65'))}">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import offerManager from '@/services/offerManager';
import PopupModal from './PopupModal.vue';
import { ref, toRef, onMounted, shallowRef } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';

export default {
    name: 'SubmitPendingVehicleModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        // const selectedItems = opts.value.modalObject.selectedItems;
        const comments = shallowRef('');
        const optionsArray = offerManager.getPostValidatedVehicleStatusOptions();
        const selectedOption = shallowRef(optionsArray[0].id);

        const $ = window.$;

        let resolvePromise;

        function show() {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function compositionComplete() {
            $('#post-validated-vehicle-status-select').selectmenu({
            width: 150,
            change: function (event, data) {
                selectedOption.value = data.item.value;
            }
        });
        }

        onMounted(() => {
            setTimeout(compositionComplete, 10);
        })
        
        function close(selectedButtonText) {
            popup.value.close();
            var details = { 
                selectedButtonText: selectedButtonText, 
                postValidatedVehicleStatus: selectedOption.value,
                comments: comments.value
            };
            resolvePromise(details);
        }

        function ok() {
            close(config.getTranslationText('1113'));
        }

        function cancel() {
            close(config.getTranslationText('65'));
        }

        return {
            show,
            popup,
            comments,
            // Functions
            cancel,
            ok,
            // Values
            optionsArray,
            selectedOption,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>