<template>
    <tr class="menu-li menu-li-value"
        v-visible="isVisible()">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{ telValue.label }}</span>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div">
                    <a data-placement="top" v-if="telValue.tooltipMessage !== undefined" :id="telValue.tooltipId"
                        :data-content="tooltipMessageText()"
                        @click="telValue.showPopover">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                <input v-if="telValue.overrideDefaultWidth === true"
                    class="form-control login-input menu-input menu-input-number override-width-tel" type="tel"
                    v-model.lazy="telValue.value.value"
                    @click="selectAllText"
                    @keyup.enter="handleEnterKey"
                    :readonly="isReadOnly()"
                    :focus="selectAllText"
                    @blur="telValue.revertErrorValueOnBlur"
                    :id="telValue.id"
                    :title="telValue.errorMessageObv.value"
                    :data-original-title="telValue.errorMessageObv.value" :maxlength="telValue.maxLength" />
                <input v-else
                    class="form-control login-input menu-input menu-input-number menu-input-50 default-width-tel"
                    type="tel" v-model.lazy="telValue.value.value" 
                    @click="selectAllText"
                    @keyup.enter="handleEnterKey"
                    :readonly="isReadOnly()"
                    :focus="selectAllText"
                    @blur="telValue.revertErrorValueOnBlur"
                    :id="telValue.id"
                    :title="telValue.errorMessageObv.value"
                    :data-original-title="telValue.errorMessageObv.value" :maxlength="telValue.maxLength" />
                <div class="increment-div decrement-div"></div>
                <div class="increment-div"></div>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef, isRef, onUpdated, nextTick } from 'vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const telValue = ref(props.valueFromParent);
        const telValue = toRef(props, "valueFromParent");

        function tooltipMessageText() {
            $('a').webuiPopover('destroy');
            if(isRef(telValue.value.tooltipMessage)) {
                return telValue.value.tooltipMessage.value;
            } else {
                return telValue.value.tooltipMessage;
            }
        }

        onUpdated(() => {
            $('#' + telValue.value.id).val(telValue.value.value.value);
        })

        function selectAllText() {
            if(telValue.value.isMac === false) {
                telValue.value.selectAllText();
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + telValue.value.id).val(telValue.value.value.value);
            });
        }

        function isVisible() {
            if(isRef(telValue.value.permissionObv)) {
                return telValue.value.permissionObv.value.visible;
            } else {
                return telValue.value.permissionObv.visible;
            }
        }

        function isReadOnly() {
            if(isRef(telValue.value.permissionObv)) {
                return telValue.value.permissionObv.value.readOnly === true;
            } else {
                return telValue.value.permissionObv.readOnly === true;
            }
        }

        return {
            telValue,
            tooltipMessageText,
            selectAllText,
            handleEnterKey,
            isVisible,
            isReadOnly
        }
    }
}
</script>

<style lang="scss" scoped>
</style>