<template>
    <template v-if="input.inputType === INPUT_TYPE.SELECT">
        <MenuContentItemTableCellInputSelect :valueFromParent="input"></MenuContentItemTableCellInputSelect>
    </template>
    <template v-if="input.inputType === INPUT_TYPE.UPDOWNBOX">
        <MenuContentItemTableCellInputUpDownBox :valueFromParent="input"></MenuContentItemTableCellInputUpDownBox>
    </template>
    <template v-if="input.inputType === INPUT_TYPE.INPUT_GROUP">
        <template v-if="input.inputGroupType === INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU">
            <MenuContentItemTableCellInputInputGroupUpDownBoxSelect :valueFromParent="input"></MenuContentItemTableCellInputInputGroupUpDownBoxSelect>
        </template>
    </template>

    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: inputType === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }"></div>
        /ko
        ko if: inputType === $root.config.INPUT_TYPE.UPDOWNBOX
        <div class="override-updownbox-div" data-bind="template: { name: 'template-menu-upDownBox-element' }, css: customCss"></div>
        /ko
        ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP
        ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU
        <div class="override-updownbox-div override-menuInputGroup-upDownBoxAndSelect-div" data-bind="template: { name: 'template-menu-menuInputGroup-upDownBoxAndSelect-element' }, css: customCss"></div>
        /ko
        /ko
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

import MenuContentItemTableCellInputSelect from './MenuContentItemTableCellInputSelect.vue';
import MenuContentItemTableCellInputUpDownBox from './MenuContentItemTableCellInputUpDownBox.vue';
import MenuContentItemTableCellInputInputGroupUpDownBoxSelect from './MenuContentItemTableCellInputInputGroupUpDownBoxSelect.vue';

export default {
    props: {
        valueFromParent: Object
    },
    components: { MenuContentItemTableCellInputSelect, MenuContentItemTableCellInputUpDownBox, MenuContentItemTableCellInputInputGroupUpDownBoxSelect },
    setup(props) {
        const input = toRef(props, "valueFromParent");

        return {
            input,
            INPUT_TYPE: config.INPUT_TYPE,
            INPUT_GROUP_TYPES: config.INPUT_GROUP_TYPES
        }
    }
}
</script>

<style lang="scss" scoped>

</style>