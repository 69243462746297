import DashboardDataPoint from './dashboardDataPoint';
import InternalStandard from './internalStandard';
import DashboardLegislation from './dashboardLegislation'



/**
 * Dashbnoard configuration object used to save user's choices for configuration drtawing
 * @param {object} init - Initial Dashboard Configuration object
 * @returns {object} - Returns Dashboard object that contains arrays for data points, internal standards and legislations
 */
var DashboardConfiguration = function(init) {
    var dashboardConfiguration = this;
    var _id,
        _dashboardDataPoints = [],
        _internalStandards = [],
        _dashboardLegislations = [];

    construct(init);

    // #region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getDashboardDataPoints() {
        return _dashboardDataPoints;
    }
    function setDashboardDataPoints(newValue) {
        _dashboardDataPoints = newValue;
    }
    function getInternalStandards() {
        return _internalStandards;
    }
    function hasActiveInternalStandards() {
        var hasActiveInternalStandards = false;

        _internalStandards.forEach(function (internalStandard) {
            if (internalStandard.getIsActive()) {
                hasActiveInternalStandards = true;
            }
        });

        return hasActiveInternalStandards;
    }
    function setInternalStandards(newValue) {
        _internalStandards = newValue;
    }
    function getDashboardLegislations() {
        return _dashboardLegislations;
    }
    function setDashboardLegislations(newValue) {
        _dashboardLegislations = newValue;
    }
    // #endregion Getters and setters

    function construct(initDashboardConfiguration) {
        _id = initDashboardConfiguration.Id;
        if (Array.isArray(initDashboardConfiguration.DashboardDataPoints)) {
            initDashboardConfiguration.DashboardDataPoints.forEach(function (item) {
                _dashboardDataPoints.push(new DashboardDataPoint(item));
            });
        }
        if (Array.isArray(initDashboardConfiguration.InternalStandards)) {
            initDashboardConfiguration.InternalStandards.forEach(function (item) {
                _internalStandards.push(new InternalStandard(item));
            });
        }
        if (Array.isArray(initDashboardConfiguration.DashboardLegislations)) {
            initDashboardConfiguration.DashboardLegislations.forEach(function (item) {
                _dashboardLegislations.push(new DashboardLegislation(item));
            });
        }
    }

    function getObjectToBeSaved() {
        var objectToBeSaved = {},
            dashboardDataPointsToBeSaved = [],
            internalStandardsToBeSaved = [],
            dashboardLegislationsToBeSaved = [];

        _dashboardDataPoints.forEach(function (item) {
            dashboardDataPointsToBeSaved.push(item.getObjectToBeSaved());
        });

        _internalStandards.forEach(function (item) {
            internalStandardsToBeSaved.push(item.getObjectToBeSaved());
        });

        _dashboardLegislations.forEach(function (item) {
            dashboardLegislationsToBeSaved.push(item.getObjectToBeSaved());
        });

        objectToBeSaved.Id = getId();
        objectToBeSaved.DashboardDataPoints = dashboardDataPointsToBeSaved;
        objectToBeSaved.InternalStandards = internalStandardsToBeSaved;
        objectToBeSaved.DashboardLegislations = dashboardLegislationsToBeSaved;

        return objectToBeSaved;
    }

    /**
     * Get the data point ids from selected dashboard data points
     * @returns {Number[]} - Array of ids 
     * */
    function getDashboardDataPointIds() {
        var returnArray = [];

        getDashboardDataPoints().forEach(function (ddp) {
            returnArray.push(ddp.getDataPointId());
        });

        return returnArray;
    }

    /**
     * Get the legislation ids from selected dashboard legislations
     * @returns {Number[]} - Array of ids
     * */
    function getDashboardLegislationIds() {
        var returnArray = [];

        getDashboardLegislations().forEach(function (ddp) {
            returnArray.push(ddp.getIdForMultiSelect());
        });

        return returnArray;
    }

    /**
     * Get the intenral standard objects from selected dashboard internal standards
     * @returns {Number[]} - Array of ids
     * */
    function getInternalStandardObjects() {
        var returnArray = [];

        _internalStandards.forEach(function (item) {
            returnArray.push(item.getObjectToBeSaved());
        });

        return returnArray;
    }

    /**
     * Get rid of existing data points and create new ones from the array
     * @param {number[]|object[]} newArrayOfIds
     */
    function replaceDashboardDataPointsUsingArrayOfIds(newArrayOfIds) {
        if (Array.isArray(newArrayOfIds)) {
            // Get rid of existing objects
            for (var i = 0; i < _dashboardDataPoints.length; i++) {
                _dashboardDataPoints[i] = null;
            }
            _dashboardDataPoints = [];

            newArrayOfIds.forEach(function (newId) {
                var idToUse;
                if (typeof newId === 'number') {
                    idToUse = {
                        DataPointId: newId
                    };
                } else {
                    idToUse = newId;
                }
                if (typeof idToUse.DataPointId !== undefined) {
                    _dashboardDataPoints.push(new DashboardDataPoint(idToUse));
                }
            });
            
        }
    }

    /**
     * Get rid of existing internal standards and create new ones from the array
     * @param {object[]} newArray
     */
    function replaceInternalStandardsUsingArray(newArray) {
        if (Array.isArray(newArray)) {
            // Get rid of existing objects
            for (var i = 0; i < _internalStandards.length; i++) {
                _internalStandards[i] = null;
            }
            _internalStandards = [];

            newArray.forEach(function (newIs) {
                if (typeof newIs.DataPointId !== undefined) {
                    _internalStandards.push(new InternalStandard(newIs));
                }
            });

        }
    }

    /**
     * Get rid of existing data points and create new ones from the array
     * @param {object[]} newArrayOfIds
     */
    function replaceDashboardLegislationsUsingArray(newArray) {
        if (Array.isArray(newArray)) {
            // Get rid of existing objects
            for (var i = 0; i < _dashboardLegislations.length; i++) {
                _dashboardLegislations[i] = null;
            }
            _dashboardLegislations = [];

            newArray.forEach(function (newLegislation) {
                if (typeof newLegislation.LegislationId !== undefined && typeof newLegislation.CountryId !== undefined) {
                    _dashboardLegislations.push(new DashboardLegislation(newLegislation));
                }
            });
        }
    }

    /**
     * Checks if all the selected legislations are available to the user
     * @param {object[]} availableLegislations - list of legislations that can be selected by the user
     * @returns {boolean} - True if all are available for use, False if not
     */
    function areAllLegislationsInAvailableLegislations(availableLegislations) {
        if (Array.isArray(availableLegislations)) {
            var count = 0,
                numberOfSelectedLegislations = _dashboardLegislations.length;
            _dashboardLegislations.forEach(function (dl) {
                var availableLegislation = availableLegislations.find(function (al) {
                    return al.legislationId === dl.getLegislationId() && al.countryId === dl.getCountryId();
                });
                if (availableLegislation !== undefined) {
                    count++;
                }
            });

            //return count > 0;
            return count === numberOfSelectedLegislations;
        }
        return false;
    }

    /**
     * Removes selected legislations from dashboard if not available to the user
     * @param {object[]} availableLegislations - list of legislations that can be selected by the user
     */
    function updateDashboardLegislationsWithDefaultsFromAvailableLegislations(availableLegislations) {
        if (Array.isArray(availableLegislations)) {
            var arr = [];

            _dashboardLegislations.forEach(function (dl) {
                var availableLegislation = availableLegislations.find(function (al) {
                    return al.legislationId === dl.getLegislationId() && al.countryId === dl.getCountryId();
                });
                if (availableLegislation !== undefined) {
                    arr.push(dl.getObjectToBeSaved());
                }
            });

            availableLegislations.forEach(function (al) {
                if (al.isDefault === true) {
                    arr.push({
                        LegislationId: al.legislationId,
                        CountryId: al.countryId
                    });
                }
            })

            if (arr.length > 0) {
                replaceDashboardLegislationsUsingArray(arr);
            }
        }
    }

    function getDashboardLegislationsToBeSaved() {
        var dashboardLegislationsToBeSaved = [];

        _dashboardLegislations.forEach(function (item) {
            dashboardLegislationsToBeSaved.push(item.getObjectToBeSaved());
        });

        return dashboardLegislationsToBeSaved;
    }

    dashboardConfiguration.getId = getId;
    dashboardConfiguration.setId = setId;
    dashboardConfiguration.getDashboardDataPoints = getDashboardDataPoints;
    dashboardConfiguration.setDashboardDataPoints = setDashboardDataPoints;
    dashboardConfiguration.getInternalStandards = getInternalStandards;
    dashboardConfiguration.setInternalStandards = setInternalStandards;
    dashboardConfiguration.getDashboardLegislations = getDashboardLegislations;
    dashboardConfiguration.setDashboardLegislations = setDashboardLegislations;
    dashboardConfiguration.getObjectToBeSaved = getObjectToBeSaved;
    dashboardConfiguration.getDashboardDataPointIds = getDashboardDataPointIds;
    dashboardConfiguration.getDashboardLegislationIds = getDashboardLegislationIds;
    dashboardConfiguration.getInternalStandardObjects = getInternalStandardObjects;
    dashboardConfiguration.replaceDashboardDataPointsUsingArrayOfIds = replaceDashboardDataPointsUsingArrayOfIds;
    dashboardConfiguration.replaceInternalStandardsUsingArray = replaceInternalStandardsUsingArray;
    dashboardConfiguration.replaceDashboardLegislationsUsingArray = replaceDashboardLegislationsUsingArray;
    dashboardConfiguration.getDashboardLegislationsToBeSaved = getDashboardLegislationsToBeSaved;
    dashboardConfiguration.areAllLegislationsInAvailableLegislations = areAllLegislationsInAvailableLegislations;
    dashboardConfiguration.updateDashboardLegislationsWithDefaultsFromAvailableLegislations = updateDashboardLegislationsWithDefaultsFromAvailableLegislations;
    dashboardConfiguration.hasActiveInternalStandards = hasActiveInternalStandards;
}

export default DashboardConfiguration;