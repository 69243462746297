<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('2942')">{{getTranslationText('2942')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('2941')">{{getTranslationText('2941')}}</span>
                    </div>
                    <div>
                        <input v-model="description"
                            class="form-control summary-modal-input" 
                            maxlength="50" 
                            required 
                            data-bind="value: description" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="ok"
                    class="btn btn-default btn-truckscience btn-primary" 
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button @click="closeClick"
                    class="btn btn-default btn-truckscience" 
                    data-bind="click: cancel, touchstart: cancel">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import dataManager from '@/services/dataManager';
import globals from '@/services/globals';
import PopupModal from './PopupModal.vue';
import { ref, toRef } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import bus from '@/main';

const $ = window.$;

export default {
    name: 'CreateFolderModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const description = ref('');
        const existingFolderNames = opts.value.modalObject.existingFolderNames;
        const parentFolderId = opts.value.modalObject.parentFolderId;
        
        let resolvePromise;
        
        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function okCheckFunction() {
            var check = {
                passed: true,
                messageCode: null
            };

            if (description.value === '') {
                check.passed = false;
                check.messageCode = '2944';

                return check;
            }

            var folderNameMatches = existingFolderNames.filter(function (folderName) {
                return folderName.toUpperCase() === description.value.toUpperCase();
            });
            if (folderNameMatches.length > 0) {
                check.passed = false;
                check.messageCode = '2945';

                return check;
            }

            return check;
        }

        function ok() {
            var self = this;
            globals.showSpinner();

            $.when(okCheckFunction()).done(function (checkObject) {
                if (checkObject.passed) {
                    dataManager.createFolder(description.value, parentFolderId)
                        .then(function (data) {
                            if (data.ReturnCode === 1) {
                                var metaDataObj = {};
                                metaDataObj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = description.value;
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CREATED_FOLDER, metaDataObj));
                                bus.emit('displayToast', {
                                    method: 'showToast',
                                    params: [config.getMessageText('2948', globals.getActiveCultureCodeForTranslations()).replace('$S1', description.value), config.TOASTR_TYPES.SUCCESS] 
                                });
                                
                                close({
                                    folderName: description.value,
                                    data: data
                                });
                            } else if (data.ReturnCode === -2) {
                                globals.hideSpinner();
                                globals.showDialog(new CustomMessageBox(config.getMessageText('2945', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference('2945')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            } else {
                                globals.hideSpinner();
                                dataManager.log("createFolderModal.ok, dataManager.createFolder, ReturnCode=" + data.ReturnCode + ",  1571", data.Message, 'CreateFolderModal', config.LOG_MESSAGE_TYPE.ERROR);
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            }
                        })
                        .fail(function (error) {
                            globals.hideSpinner();
                            dataManager.log("createFolderModal.ok, dataManager.createFolder, fail", error, 'CreateFolderModal', config.LOG_MESSAGE_TYPE.ERROR);
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        });
                    
                } else {
                    globals.hideSpinner();
                    globals.showDialog(new CustomMessageBox(config.getMessageText(checkObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference(checkObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                }
            });
        }

        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        function closeClick() {
            close(false);
        }

        return {
            popup,
            show,
            description,
            ok,
            closeClick,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>