import config from './config';
import * as PIXI from '../assets/scripts/pixi.min';
import $ from '../assets/scripts/jquery-3.6.0.min';

var CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;

var scalingFactor, mainHeight, topOffsetRequiredForAxisFlip;
//var lineWidth = 0.75, dimensionLineWidth = 0.5, tableCellLineWidth = 0.5, lineColour = 0x000000, lineAlpha = 1, wordWrapWidth;
var lineWidth = 0.8, dimensionLineWidth = 0.8, tableCellLineWidth = 0.65, lineColour = config.DRAWING_COLOURS.BLACK, lineAlpha = 1, wordWrapWidth, circleWidth = lineWidth, altCircleWidth = 1.5;
var graphicsObject  = new PIXI.Graphics();
var curAlternateGraphicsObject = null;
var watermarkSprite = null;
var _resources;

if (CustomTruckScienceRequirements.getBrowser() === config.BROWSER_NAME.CHROMEONANDROID) {
    circleWidth = altCircleWidth;
} 

function getGraphicsObject(getAsSprite) {
    
    //if (graphicsObject === null || graphicsObject === undefined || graphicsObject.children === null) {
    //    graphicsObject = new PIXI.Graphics();
    //}

    if(getAsSprite) {
        var drawingTexture = graphicsObject.generateTexture();
        var drawingSprite = new PIXI.Sprite(drawingTexture);
        return drawingSprite;
    } else {
        if (graphicsObject === undefined || graphicsObject === null) {
            graphicsObject = new PIXI.Graphics();
        }
        return graphicsObject;
    }
    
}
function destroyGraphicsObject() {
    graphicsObject = null;
}
function setMainHeight(mH) {
    mainHeight = mH;
}
function setAlternateGraphicsObject(dimensionGraphicsObject) {
    curAlternateGraphicsObject = dimensionGraphicsObject;
}
//function setTopOffsetRequiredForAxisFlip(topOffset) {
//    topOffsetRequiredForAxisFlip = topOffset;
//}

function setScalingFactor(factor) {
    scalingFactor = factor;
}

function getPoint(point) {
    return new PIXI.Point(point.x, point.y);
}

function getNewGraphicsObject() {
    return new PIXI.Graphics();
}
//function drawText(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour) {

//    var textGraphicsObject = new PIXI.Text(text, { font: font || '10px Arial', fill: optionalFontColour || 0x000000, align: textAligment || 'center', wordWrap: true, wordWrapWidth: wordWrapWidth });

//    var anchor = anchorPoint || { x: 0, y: 0 };

//    textGraphicsObject.anchor = new PIXI.Point(anchor.x, anchor.y);
    
//    if (addToGraphics) {
//        //textGraphicsObject.position = new PIXI.Point(Math.round(position.x * scalingFactor), mainHeight - (Math.round(position.y * scalingFactor)));
//        textGraphicsObject.position = new PIXI.Point((position.x * scalingFactor), mainHeight - ((position.y * scalingFactor)));
//        graphicsObject.addChild(textGraphicsObject);
//    } else {
//        textGraphicsObject.position = new PIXI.Point(position.x, position.y);
//    }
//    return textGraphicsObject;
//}

function drawText(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour) {
    var styleObject = { font: font || '10px Arial', fill: optionalFontColour || config.DRAWING_COLOURS.BLACK, align: textAligment || 'center', wordWrap: true, wordWrapWidth: wordWrapWidth };
    if (font.includes('FontAwesome5ProTruckScience')) {
        styleObject.fontWeight = 900;
    }
    //var textGraphicsObject = new PIXI.Text(text, { font: font || '10px Arial', fill: optionalFontColour || config.DRAWING_COLOURS.BLACK, align: textAligment || 'center', wordWrap: true, wordWrapWidth: wordWrapWidth });
    var textGraphicsObject = new PIXI.Text(text, styleObject);

    var anchor = anchorPoint || { x: 0, y: 0 };

    textGraphicsObject.anchor = new PIXI.Point(anchor.x, anchor.y);
    textGraphicsObject.position = new PIXI.Point(position.x, position.y);

    if (addToGraphics) {
        graphicsObject.addChild(textGraphicsObject);
    } 

    return textGraphicsObject;
}

function drawInteractiveTextIcon(callbacks, cellHeight, text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour) {

    var textGraphicsObject = drawText(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour);
    var containerPosition;
    var translateIconX = false;
    if (anchorPoint !== undefined) {
        containerPosition = {};
        if (anchorPoint.x === 1) {
            translateIconX = true;
            containerPosition.x = position.x - textGraphicsObject.width;
            containerPosition.y = position.y;
        } else {
            containerPosition = position;
        }
    } else {
        containerPosition = position;
    }
    var iconContainer = getDrawingContainer(containerPosition, textGraphicsObject.width, textGraphicsObject.height);
    if (translateIconX === true) {
        textGraphicsObject.position.x = textGraphicsObject.width;
    } else {
        textGraphicsObject.position.x = 0;
    }
    
    textGraphicsObject.position.y = (cellHeight - textGraphicsObject.height)/2;

    if (callbacks !== undefined) {
        iconContainer.interactive = true;
        iconContainer.buttonMode = true;
        iconContainer.hitArea = new PIXI.Rectangle(0,0, iconContainer.width, iconContainer.height);
        if (callbacks.mouseover !== undefined && typeof callbacks.mouseover === 'function') {
            iconContainer.mouseover = callbacks.mouseover;
        }
        if (callbacks.click !== undefined && typeof callbacks.click === 'function') {
            iconContainer.click = callbacks.click;
            iconContainer.tap = callbacks.click;
        }
        if (callbacks.mouseout !== undefined && typeof callbacks.mouseout === 'function') {
            iconContainer.mouseout = callbacks.mouseout;
        }
        if (callbacks.mousemove !== undefined && typeof callbacks.mousemove === 'function') {
            iconContainer.mousemove = callbacks.mousemove;
        }
    }
    iconContainer.addChild(textGraphicsObject);
    return iconContainer;
    //return textGraphicsObject;
}


function setWordWrapWidth(www) {
    wordWrapWidth = www;
}

//function drawLine(startPoint, endPoint, addToGraphics, width, colour, alpha) {
//    var graphicsObjectToUse;

//    if (addToGraphics && addToGraphics === true) {
//        graphicsObjectToUse = getGraphicsObject();
//    } else if (curAlternateGraphicsObject !== null) {
//        graphicsObjectToUse = curAlternateGraphicsObject;
//    } else {
//        graphicsObjectToUse = new PIXI.Graphics();
//    }

//    graphicsObjectToUse.lineStyle(width || lineWidth, colour || lineColour, alpha || lineAlpha);
//    graphicsObjectToUse.moveTo(startPoint.x, startPoint.y);
//    graphicsObjectToUse.lineTo(endPoint.x, endPoint.y);

//    if (addToGraphics === undefined || addToGraphics === false) {
//        return graphicsObjectToUse;
//    } 
//}
//function drawLine(graphicsObjectForLine, startPoint, endPoint, width, colour, alpha) {
//    graphicsObjectForLine.lineStyle(width || lineWidth, colour || lineColour, alpha || lineAlpha);
//    graphicsObjectForLine.moveTo(startPoint.x, startPoint.y);
//    graphicsObjectForLine.lineTo(endPoint.x, endPoint.y);
//}

function drawLine(graphicsObjectToUse, startPoint, endPoint, addToGraphics, width, colour, alpha) {
    //var graphicsObjectToUse;

    //if (addToGraphics && addToGraphics === true) {
    //    graphicsObjectToUse = getGraphicsObject();
    //} else if (curAlternateGraphicsObject !== null) {
    //    graphicsObjectToUse = curAlternateGraphicsObject;
    //} else {
    //    graphicsObjectToUse = new PIXI.Graphics();
    //}

    graphicsObjectToUse.lineStyle(width || lineWidth, colour || lineColour, alpha || lineAlpha);
    graphicsObjectToUse.moveTo(startPoint.x, startPoint.y);
    graphicsObjectToUse.lineTo(endPoint.x, endPoint.y);

    if (addToGraphics === undefined || addToGraphics === false) {
        return graphicsObjectToUse;
    }
}

function drawDashedLine(graphicsObjectToUse, startPoint, endPoint, dashArray, width, colour, alpha)
{
    
    var dashCount = dashArray.length;
    graphicsObjectToUse.lineStyle(width || lineWidth, colour || lineColour, alpha || lineAlpha);
    graphicsObjectToUse.moveTo(startPoint.x, startPoint.y);
    var downLine = false;
    var dx, dy;
    dx = (endPoint.x - startPoint.x);
    if (endPoint.x === startPoint.x && endPoint.y < startPoint.y) {
        downLine = true;
        dy = (startPoint.y - endPoint.y);
    }else {
        dy = (endPoint.y - startPoint.y);
    }

    var slope = dy / dx;
    //if (dx === 0) {
    //    slope = 1; //if vertival line(slope will be infinity) then need to set slope to 1 so so that rest of code works
    //}
    var distRemaining = Math.sqrt(dx * dx + dy * dy);
    var dashIndex = 0;
    var draw = true;

    while (distRemaining >= 0.1)
    {
        var dashLength = dashArray[dashIndex++ % dashCount];
        if (dashLength > distRemaining) dashLength = distRemaining;
        var slopeDivideFactor;
        if (isFinite(slope)) {
            slopeDivideFactor = 1 + slope * slope;
        } else {
            slopeDivideFactor = 1;
        }
        var xStep = Math.sqrt(dashLength * dashLength / (slopeDivideFactor));

        if (dx < 0) {
            xStep = -xStep;
        }

        if (dx !== 0) {
            startPoint.x += xStep
        }
        
        var yStep;
        if (isFinite(slope)) {
            yStep = slope * xStep;
        } else {
            if (downLine === true) {
                yStep = -xStep;
            } else {
                yStep = xStep;
            }
            
        }
        startPoint.y += yStep;

        if (draw) {
            graphicsObjectToUse.lineTo(startPoint.x, startPoint.y);
        } else {
            graphicsObjectToUse.moveTo(startPoint.x, startPoint.y);
        }

        distRemaining -= dashLength;
        draw = !draw;
    }

}

function drawArc(centrePoint, startPoint, radius, startAngle, endAngle, graphicsObjectToUse, width, colour, antiClockwise) {
    graphicsObjectToUse.lineStyle(width || lineWidth, colour || lineColour);

    //var startPoint;

    //startPoint = { x: centrePoint.x + radius * Math.cos(startAngle), y: centrePoint.y + radius * Math.sin(startAngle) };

    //graphicsObject.moveTo(Math.floor(startPoint.x * scalingFactor), mainHeight - (Math.floor(startPoint.y * scalingFactor)));
    //graphicsObject.arc(Math.floor(centrePoint.x * scalingFactor), mainHeight - (Math.floor(centrePoint.y * scalingFactor)), Math.floor(radius * scalingFactor), startAngle, endAngle, antiClockwise || false);
    graphicsObjectToUse.moveTo(startPoint.x, startPoint.y);
    graphicsObjectToUse.arc(centrePoint.x, centrePoint.y, radius, startAngle, endAngle, antiClockwise || false);
}

//function drawArcNoScaling(centrePoint, startPoint, radius, startAngle, endAngle, colour, antiClockwise) {
//    graphicsObject.lineStyle(lineWidth, colour || lineColour);

//    //var startPoint;

//    //startPoint = { x: centrePoint.x + radius * Math.cos(startAngle), y: centrePoint.y + radius * Math.sin(startAngle) };

//    //graphicsObject.moveTo(Math.floor(startPoint.x * scalingFactor), mainHeight - (Math.floor(startPoint.y * scalingFactor)));
//    //graphicsObject.arc(Math.floor(centrePoint.x * scalingFactor), mainHeight - (Math.floor(centrePoint.y * scalingFactor)), Math.floor(radius * scalingFactor), startAngle, endAngle, antiClockwise || false);
//    graphicsObject.moveTo((startPoint.x), mainHeight - ((startPoint.y)));
//    graphicsObject.arc((centrePoint.x), mainHeight - ((centrePoint.y)), (radius), startAngle, endAngle, antiClockwise || false);
//}

function fillArc(fillColour, centrePoint, startPoint, endPoint, radius, startAngle, endAngle, graphicsObjectToUse, width, colour, antiClockwise) {
    graphicsObjectToUse.beginFill(fillColour, 1);

    
    var points = [centrePoint, startPoint, endPoint, centrePoint];
    //drawLine(centrePoint, { x: centrePoint.x - radius, y: centrePoint.y }, true);
    //drawLine(centrePoint, { x: centrePoint.x, y: centrePoint.y - radius }, true);
    //drawLine({ x: centrePoint.x - radius, y: centrePoint.y }, { x: centrePoint.x, y: centrePoint.y - radius }, true);
    
    drawArc(centrePoint, startPoint, radius, startAngle, endAngle, graphicsObjectToUse, width, colour, antiClockwise);
    graphicsObjectToUse.endFill();
    drawPolygon(graphicsObjectToUse, points, colour || lineColour, fillColour);
}

//function fillArcNoScaling(fillColour, centrePoint, startPoint, endPoint, radius, startAngle, endAngle, colour, antiClockwise) {
//    graphicsObject.beginFill(fillColour, 1);


//    var points = [centrePoint, startPoint, endPoint, centrePoint];
//    //drawLine(centrePoint, { x: centrePoint.x - radius, y: centrePoint.y }, true);
//    //drawLine(centrePoint, { x: centrePoint.x, y: centrePoint.y - radius }, true);
//    //drawLine({ x: centrePoint.x - radius, y: centrePoint.y }, { x: centrePoint.x, y: centrePoint.y - radius }, true);

//    drawArcNoScaling(centrePoint, startPoint, radius, startAngle, endAngle, colour, antiClockwise);
//    graphicsObject.endFill();
//    drawPolygonNoScaling(points, lineColour, fillColour);
//}

function drawDashedArc(centrePoint, startPoint, radius, startAngle, endAngle, graphicsObjectToUse, width, colour, antiClockwise) {
    //#note: it's debatable as to whether this function should be in here as pixi doesn't have a concept of dashed arcs so this should be in the graphicsLayer and also to staisfy the condistion that all data manipulation i.e. flippling axis or scaling takes place in the graphicsLayer
    //Other future libraries more than likely will have the concept of a dashed arc in whihc case it would be appropriate to have this method in this layer however all of the logic will be handled internally by that library
    //In the meantime, this is the only place where flipping of y axis is allowed inside the graphicsAdapter layer as the arc section dashes need to be calculated on the raw un-altered data and then drawArc is called internally which does not flip the y axis so it must be done here
    var sweep = endAngle > startAngle ? endAngle - startAngle : startAngle - endAngle,
        theta = sweep/40;

    
    for (var i = 0; i < 40; i++) {
        if (i % 2 === 0) {
            var sectorStartAngle = (startAngle + (theta * (i)));
            var sectorEndAngle = sectorStartAngle + theta;
            startPoint = getArcStartPoint(startAngle + (theta * i), radius, centrePoint);
            drawArc({ x: centrePoint.x, y: mainHeight - centrePoint.y }, {x:startPoint.x, y: mainHeight - startPoint.y}, radius, sectorStartAngle, sectorEndAngle, graphicsObjectToUse, width, colour, antiClockwise);
        }
    }


    function getArcStartPoint(startAngle, radius, centrePoint) {
        var adjustedStartAngle;
        var startX, startY;

                

        if (startAngle >= 0 && startAngle < getRadians(90)) {
            //bottom right
            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(90) && startAngle < getRadians(180)) {
            //bottom left
            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(180) && startAngle < getRadians(270)) {
            // top left
            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(270) && startAngle <= getRadians(360)) {
            //top right
            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
        }
                
        return { x: startX, y: startY };
        function getRadians(angleDegrees) {
            return (Math.PI / 180) * angleDegrees;
            //Return Math.PI * angleInDegrees / 180.0
        }
    }


}

//function drawDashedArcNoScaling(centrePoint, startPoint, radius, startAngle, endAngle, colour, antiClockwise) {

//    var sweep = endAngle > startAngle ? endAngle - startAngle : startAngle - endAngle,
//        theta = sweep / 40;


//    for (var i = 0; i < 40; i++) {
//        if (i % 2 === 0) {
//            var sectorStartAngle = (startAngle + (theta * (i)));
//            var sectorEndAngle = sectorStartAngle + theta;
//            drawArcNoScaling(centrePoint, getArcStartPoint(startAngle + (theta * i), radius, centrePoint), radius, sectorStartAngle, sectorEndAngle, colour, antiClockwise);
//        }
//    }


//    function getArcStartPoint(startAngle, radius, centrePoint) {
//        var adjustedStartAngle;
//        var startX, startY;



//        if (startAngle >= 0 && startAngle < getRadians(90)) {
//            //bottom right
//            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
//            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
//        } else if (startAngle >= getRadians(90) && startAngle < getRadians(180)) {
//            //bottom left
//            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
//            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
//        } else if (startAngle >= getRadians(180) && startAngle < getRadians(270)) {
//            // top left
//            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
//            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
//        } else if (startAngle >= getRadians(270) && startAngle <= getRadians(360)) {
//            //top right
//            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
//            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
//        }

//        return { x: startX, y: startY };
//        function getRadians(angleDegrees) {
//            return (Math.PI / 180) * angleDegrees;
//            //Return Math.PI * angleInDegrees / 180.0
//        }
//    }


//}

//function drawRect(topLeft, topRight, bottomRight, bottomLeft, addToGraphics, optionalColour, optionalFillColour, optionalAlpha) {
//    var rectGraphicsObject;
//    if (addToGraphics === true) {
//        rectGraphicsObject = getGraphicsObject();
//    } else if (curAlternateGraphicsObject !== null){
//        rectGraphicsObject = curAlternateGraphicsObject;
//    } else {
//        rectGraphicsObject = new PIXI.Graphics();
//    }
//    rectGraphicsObject.lineStyle(tableCellLineWidth, optionalColour || lineColour, optionalAlpha || lineAlpha);

//    if (optionalFillColour !== undefined) {
//        rectGraphicsObject.beginFill(optionalFillColour, optionalAlpha || 1);
//    }

//    //if (addToGraphics === true) {
//    //    rectGraphicsObject.moveTo(position.x, position.y);
//    //    rectGraphicsObject.lineTo(position.x + width, position.y);
//    //    rectGraphicsObject.lineTo(position.x + width, position.y + height);
//    //    rectGraphicsObject.lineTo(position.x, position.y + height);
//    //    rectGraphicsObject.lineTo(position.x, position.y);
//    //} else {
//    //    rectGraphicsObject.moveTo(position.x, position.y);
//    //    rectGraphicsObject.lineTo(position.x + width, position.y);
//    //    rectGraphicsObject.lineTo(position.x + width, position.y + height);
//    //    rectGraphicsObject.lineTo(position.x, position.y + height);
//    //    rectGraphicsObject.lineTo(position.x, position.y);
//    //}
//    rectGraphicsObject.moveTo(topLeft.x, topLeft.y);
//    rectGraphicsObject.lineTo(topRight.x, topRight.y);
//    rectGraphicsObject.lineTo(bottomRight.x, bottomRight.y);
//    rectGraphicsObject.lineTo(bottomLeft.x, bottomLeft.y);
//    rectGraphicsObject.lineTo(topLeft.x, topLeft.y);

//    if (optionalFillColour) {
//        rectGraphicsObject.endFill();
//    }

//    if (addToGraphics === false) {
//        return rectGraphicsObject;
//    } 
    
//}

function drawRect(graphicsObjectToUse, topLeft, topRight, bottomRight, bottomLeft, addToGraphics, optionalColour, optionalFillColour, optionalAlpha) {
    
    graphicsObjectToUse.lineStyle(tableCellLineWidth, optionalColour || lineColour, optionalAlpha || lineAlpha);

    if (optionalFillColour !== undefined) {
        graphicsObjectToUse.beginFill(optionalFillColour, optionalAlpha || 1);
    }

    //if (addToGraphics === true) {
    //    rectGraphicsObject.moveTo(position.x, position.y);
    //    rectGraphicsObject.lineTo(position.x + width, position.y);
    //    rectGraphicsObject.lineTo(position.x + width, position.y + height);
    //    rectGraphicsObject.lineTo(position.x, position.y + height);
    //    rectGraphicsObject.lineTo(position.x, position.y);
    //} else {
    //    rectGraphicsObject.moveTo(position.x, position.y);
    //    rectGraphicsObject.lineTo(position.x + width, position.y);
    //    rectGraphicsObject.lineTo(position.x + width, position.y + height);
    //    rectGraphicsObject.lineTo(position.x, position.y + height);
    //    rectGraphicsObject.lineTo(position.x, position.y);
    //}
    graphicsObjectToUse.moveTo(topLeft.x, topLeft.y);
    graphicsObjectToUse.lineTo(topRight.x, topRight.y);
    graphicsObjectToUse.lineTo(bottomRight.x, bottomRight.y);
    graphicsObjectToUse.lineTo(bottomLeft.x, bottomLeft.y);
    graphicsObjectToUse.lineTo(topLeft.x, topLeft.y);

    if (optionalFillColour) {
        graphicsObjectToUse.endFill();
    }

    if (addToGraphics === false) {
        return graphicsObjectToUse;
    }

}

//function drawInteractiveRect(callbacks, position, width, height, optionalColour, optionalFillColour, optionalAlpha) {
//    var rect = drawRect(position, width, height, false, optionalColour, optionalFillColour, optionalAlpha);
//    if (callbacks !== undefined) {
//        rect.interactive = true;
//        rect.buttonMode = true;
//        rect.hitArea = new PIXI.Rectangle(position.x, position.y, width, height);
//        if (callbacks.mouseover !== undefined && typeof callbacks.mouseover === 'function') {
//            rect.mouseover = callbacks.mouseover;
//        }
//        if (callbacks.click !== undefined && typeof callbacks.click === 'function') {
//            rect.click = callbacks.click;
//            rect.touchstart = callbacks.click;
//        }
//        if (callbacks.mouseout !== undefined && typeof callbacks.mouseout === 'function') {
//            rect.mouseout = callbacks.mouseout;
//        }
//    }
    
    

//    return rect;
//}

function makeRectInteractive(rect, hitAreaX, hitAreaY, callbacks) {
    
    if (callbacks !== undefined) {
        rect.interactive = true;
        rect.buttonMode = true;
        if (rect.hitArea) {
            rect.hitArea = null;
        }
        rect.hitArea = new PIXI.Rectangle(hitAreaX, hitAreaY, rect.width, rect.height);
        
        if (callbacks.mouseover !== undefined && typeof callbacks.mouseover === 'function') {
            rect.mouseover = callbacks.mouseover;
        }
        if (callbacks.click !== undefined && typeof callbacks.click === 'function') {
            rect.click = callbacks.click;
            rect.touchstart = callbacks.click;
        }
        if (callbacks.mouseout !== undefined && typeof callbacks.mouseout === 'function') {
            rect.mouseout = callbacks.mouseout;
        }
        if (callbacks.mousemove !== undefined && typeof callbacks.mousemove === 'function') {
            rect.mousemove = callbacks.mousemove;
        }
    }



    return rect;
}

function drawRectWithFixedBuffer(position, innerWidth, innerHeight, buffer, optionalColour, optionalFillColour, optionalAlpha) {
    var rectGraphicsObject;
    //if (addToGraphics === true) {
        rectGraphicsObject = getGraphicsObject();
    //} else {
    //    rectGraphicsObject = new PIXI.Graphics();
    //}
    rectGraphicsObject.lineStyle(tableCellLineWidth, optionalColour || lineColour, optionalAlpha || lineAlpha);

    if (optionalFillColour !== undefined) {
        rectGraphicsObject.beginFill(optionalFillColour, optionalAlpha || 1);
    }
    var x = Math.round((position.x + buffer) * scalingFactor) - buffer;
    var y = Math.round((position.y + buffer) * scalingFactor) - buffer;
    var width = Math.round(innerWidth * scalingFactor) + (buffer * 2);
    var height = Math.round(innerHeight * scalingFactor) + (buffer * 2);
    //if (addToGraphics === true) {
        rectGraphicsObject.moveTo(x, mainHeight - y );
        rectGraphicsObject.lineTo(x + width, mainHeight - y);
        rectGraphicsObject.lineTo(x + width, mainHeight - (y + height));
        rectGraphicsObject.lineTo(x, mainHeight - (y + height));
        rectGraphicsObject.lineTo(x, mainHeight - y);
    //} else {
        //rectGraphicsObject.moveTo(position.x, position.y);
        //rectGraphicsObject.lineTo(position.x + width, position.y);
        //rectGraphicsObject.lineTo(position.x + width, position.y + height);
        //rectGraphicsObject.lineTo(position.x, position.y + height);
        //rectGraphicsObject.lineTo(position.x, position.y);
    //}


    if (optionalFillColour) {
        rectGraphicsObject.endFill();
    }

    //if (addToGraphics === false) {
    //    return rectGraphicsObject;
    //}

}

function drawPolygon(graphicsObjectToUse, points, colour, fillColour, width, alpha) {
    //var polygonGraphicsObject = new PIXI.Graphics();
    if (fillColour !== undefined) {
        var alphaVal = alpha || 1;
        graphicsObjectToUse.beginFill(fillColour, alphaVal);
    } else {
        graphicsObjectToUse.beginFill(config.DRAWING_COLOURS.WHITE, 0);
    }
    graphicsObjectToUse.lineStyle(width || lineWidth, colour);
    
    for (var i = 0; i < points.length; i++) {
        if (i == 0) {
            //graphicsObject.moveTo(Math.round(points[i].x * scalingFactor), mainHeight - (Math.round(points[i].y * scalingFactor)));
            graphicsObjectToUse.moveTo(points[i].x, points[i].y);
        } else {
            //graphicsObject.lineTo(Math.round(points[i].x * scalingFactor), mainHeight - (Math.round(points[i].y * scalingFactor)));
            graphicsObjectToUse.lineTo(points[i].x, points[i].y);
        }
    }
    //if (fillColour !== undefined) {
        graphicsObjectToUse.endFill();
    //}
    //return polygonGraphicsObject;
}

//function drawPolygonNoScaling(points, colour, fillColour) {
//    //var polygonGraphicsObject = new PIXI.Graphics();
//    if (fillColour !== undefined) {
//        graphicsObject.beginFill(fillColour, 1);
//    }
//    graphicsObject.lineStyle(lineWidth, colour);

//    for (var i = 0; i < points.length; i++) {
//        if (i == 0) {
//            //graphicsObject.moveTo(Math.round(points[i].x * scalingFactor), mainHeight - (Math.round(points[i].y * scalingFactor)));
//            graphicsObject.moveTo((points[i].x), mainHeight - ((points[i].y)));
//        } else {
//            //graphicsObject.lineTo(Math.round(points[i].x * scalingFactor), mainHeight - (Math.round(points[i].y * scalingFactor)));
//            graphicsObject.lineTo((points[i].x), mainHeight - ((points[i].y)));
//        }
//    }
//    if (fillColour !== undefined) {
//        graphicsObject.endFill();
//    }
    //return polygonGraphicsObject;
//}

function drawCircle(graphicsObjectToUse, centrePoint, radius, colour, fillColour, width) {
    //var circleGraphicsObject = new PIXI.Graphics();


    if (fillColour !== undefined) {
        graphicsObjectToUse.beginFill(fillColour, 1);
    }
    //else {
    graphicsObjectToUse.lineStyle(width || circleWidth, colour);
    //}

    //graphicsObject.drawCircle(Math.round(centrePoint.x * scalingFactor), mainHeight - (Math.round(centrePoint.y * scalingFactor)), Math.round(radius * scalingFactor));
    graphicsObjectToUse.drawCircle(centrePoint.x, centrePoint.y, radius);

    if (fillColour !== undefined) {
        graphicsObjectToUse.endFill();
    }
    //return circleGraphicsObject;
}

//function drawCircleNoScaling(centrePoint, radius, colour, fillColour) {
//    //var circleGraphicsObject = new PIXI.Graphics();


//    if (fillColour !== undefined) {
//        graphicsObject.beginFill(fillColour, 1);
//    }
//    //else {
//    graphicsObject.lineStyle(circleWidth, colour);
//    //}

//    //graphicsObject.drawCircle(Math.round(centrePoint.x * scalingFactor), mainHeight - (Math.round(centrePoint.y * scalingFactor)), Math.round(radius * scalingFactor));
//    graphicsObject.drawCircle((centrePoint.x), mainHeight - ((centrePoint.y)), (radius));

//    if (fillColour !== undefined) {
//        graphicsObject.endFill();
//    }
//    //return circleGraphicsObject;
//}


function drawDashedCircle(graphicsObjectToUse, centrePoint, radius, dash, width, colour, alpha) {
    var pointArray = calcPointsCirc(centrePoint.x, centrePoint.y, radius, dash);
    graphicsObjectToUse.lineStyle(width || lineWidth, colour || lineColour, alpha || lineAlpha);
    for (var p = 0; p < pointArray.length; p++) {
        graphicsObjectToUse.moveTo(pointArray[p].x, pointArray[p].y);
        graphicsObjectToUse.lineTo(pointArray[p].ex, pointArray[p].ey);
    }
}


function calcPointsCirc(cx, cy, rad, dashLength) {
    var n = rad / dashLength,
        alpha = Math.PI * 2 / n,
        pointObj = {},
        points = [],
        i = -1;

    while (i < n) {
        var theta = alpha * i,
            theta2 = alpha * (i + 1);

        points.push({ x: (Math.cos(theta) * rad) + cx, y: (Math.sin(theta) * rad) + cy, ex: (Math.cos(theta2) * rad) + cx, ey: (Math.sin(theta2) * rad) + cy });
        i += 2;
    }
    return points;
}

function drawEllipse(graphicsObjectToUse, centrePoint, halfWidth, halfHeight, colour, fillColour, width) {
    //var circleGraphicsObject = new PIXI.Graphics();


    if (fillColour !== undefined) {
        graphicsObjectToUse.beginFill(fillColour, 1);
    }
    //else {
    graphicsObjectToUse.lineStyle(width || circleWidth, colour);
    //}

    //graphicsObject.drawCircle(Math.round(centrePoint.x * scalingFactor), mainHeight - (Math.round(centrePoint.y * scalingFactor)), Math.round(radius * scalingFactor));
    graphicsObjectToUse.drawEllipse(centrePoint.x, centrePoint.y, halfWidth, halfHeight);

    if (fillColour !== undefined) {
        graphicsObjectToUse.endFill();
    }
    //return circleGraphicsObject;
}

function drawDashedEllipse() {

}

//function drawDimension(dimension, addToGraphicsObject) {

//    var graphicsObj;

//    if (addToGraphicsObject !== undefined) {
//        graphicsObj = getGraphicsObject();
//    } else {
//        graphicsObj = new PIXI.Graphics();
//    }

//    if (dimension.type.indexOf('cog') !== -1) {

//        var flipYAxisVal = mainHeight;// + dimension.totalTopLayerThickness;

//        graphicsObj.moveTo(dimension.ah1.tip.x, flipYAxisVal - dimension.ah1.tip.y);
//        graphicsObj.lineTo(dimension.ah1.sideAEnd.x, flipYAxisVal - dimension.ah1.sideAEnd.y);
//        graphicsObj.moveTo(dimension.ah1.tip.x, flipYAxisVal - dimension.ah1.tip.y);
//        graphicsObj.lineTo(dimension.ah1.sideBEnd.x, flipYAxisVal - dimension.ah1.sideBEnd.y);

//        graphicsObj.moveTo(dimension.ah2.tip.x, flipYAxisVal - dimension.ah2.tip.y);
//        graphicsObj.lineTo(dimension.ah2.sideAEnd.x, flipYAxisVal - dimension.ah2.sideAEnd.y);
//        graphicsObj.moveTo(dimension.ah2.tip.x, flipYAxisVal - dimension.ah2.tip.y);
//        graphicsObj.lineTo(dimension.ah2.sideBEnd.x, flipYAxisVal - dimension.ah2.sideBEnd.y);

//        graphicsObj.moveTo(dimension.lineStop1Start.x, flipYAxisVal - dimension.lineStop1Start.y);
//        graphicsObj.lineTo(dimension.lineStop1End.x, flipYAxisVal - dimension.lineStop1End.y);

//        graphicsObj.moveTo(dimension.lineStop2Start.x, flipYAxisVal - dimension.lineStop2Start.y);
//        graphicsObj.lineTo(dimension.lineStop2End.x, flipYAxisVal - dimension.lineStop2End.y);

//        var horizontalStartPoint = dimension.horizontalStartPoint;
//        var horizontalEndPoint = dimension.horizontalEndPoint;
//        var verticalStartPoint = dimension.verticalStartPoint;
//        var verticalEndPoint = dimension.verticalEndPoint;
//        var cogPoint = dimension.cogPoint;

//        //draw horizontal dimension portion
//        graphicsObj.moveTo(horizontalStartPoint.x, flipYAxisVal - horizontalStartPoint.y);
//        graphicsObj.lineTo(horizontalEndPoint.x, flipYAxisVal - horizontalEndPoint.y);
//        //draw vertical dimension portion
//        graphicsObj.moveTo(verticalStartPoint.x, flipYAxisVal - verticalStartPoint.y);
//        graphicsObj.lineTo(verticalEndPoint.x, flipYAxisVal - verticalEndPoint.y);
//        //draw vertical portion to COG marker connecting line
//        //graphicsObj.moveTo(verticalStartPoint.x, verticalStartPoint.y);
//        graphicsObj.lineTo(cogPoint.x, flipYAxisVal - cogPoint.y);
//        //draw cog marker to horizontal portion connecting line
//        graphicsObj.lineTo(horizontalEndPoint.x, flipYAxisVal - horizontalEndPoint.y);
        

//        var verticalText = new PIXI.Text("" + dimension.displayLengthVertical, { font: dimension.font, fill: dimension.fontColour, align: 'center', fontWeight: fontWeight });

//        //if (dimension.interaction !== undefined) {
//        //    if (dimension.interaction.callbacks !== undefined) {
//        //        text.interactive = true;
//        //        text.buttonMode = true;
//        //        if (dimension.interaction.callbacks.mouseover !== undefined && typeof dimension.interaction.callbacks.mouseover === 'function') {
//        //            text.mouseover = dimension.interaction.callbacks.mouseover;
//        //        }
//        //        if (dimension.interaction.callbacks.click !== undefined && typeof dimension.interaction.callbacks.click === 'function') {
//        //            text.click = dimension.interaction.callbacks.click;
//        //            text.touchstart = dimension.interaction.callbacks.click;
//        //        }
//        //        if (dimension.interaction.callbacks.mouseout !== undefined && typeof dimension.interaction.callbacks.mouseout === 'function') {
//        //            text.mouseout = dimension.interaction.callbacks.mouseout;
//        //        }
//        //    }
//        //}

//        verticalText.anchor = new PIXI.Point(0.5, 0.5);

//        var verticalTextBackground = new PIXI.Graphics();

//        verticalTextBackground.x = dimension.verticalTextCentre.x;
//        verticalTextBackground.y = mainHeight - dimension.verticalTextCentre.y;
//        verticalText.x = dimension.textBackgroundWidth / 2;
//        verticalText.y = dimension.textBackgroundHeight / 2;
        
//        verticalText.rotation = dimension.rotateText;
        

//        verticalTextBackground.beginFill(config.DRAWING_COLOURS.WHITE, 1);
//        verticalTextBackground.drawRect(0, 0, dimension.textBackgroundWidth, dimension.textBackgroundHeight);
//        verticalTextBackground.endFill();
//        verticalTextBackground.addChild(verticalText);
//        graphicsObj.addChild(verticalTextBackground);


//        var horizontalText = new PIXI.Text("" + dimension.displayLengthHorizontal, { font: dimension.font, fill: dimension.fontColour, align: 'center', fontWeight: fontWeight });

//        //if (dimension.interaction !== undefined) {
//        //    if (dimension.interaction.callbacks !== undefined) {
//        //        text.interactive = true;
//        //        text.buttonMode = true;
//        //        if (dimension.interaction.callbacks.mouseover !== undefined && typeof dimension.interaction.callbacks.mouseover === 'function') {
//        //            text.mouseover = dimension.interaction.callbacks.mouseover;
//        //        }
//        //        if (dimension.interaction.callbacks.click !== undefined && typeof dimension.interaction.callbacks.click === 'function') {
//        //            text.click = dimension.interaction.callbacks.click;
//        //            text.touchstart = dimension.interaction.callbacks.click;
//        //        }
//        //        if (dimension.interaction.callbacks.mouseout !== undefined && typeof dimension.interaction.callbacks.mouseout === 'function') {
//        //            text.mouseout = dimension.interaction.callbacks.mouseout;
//        //        }
//        //    }
//        //}

//        horizontalText.anchor = new PIXI.Point(0.5, 0.5);

//        var horizontalTextBackground = new PIXI.Graphics();

//        horizontalTextBackground.x = dimension.horizontalTextCentre.x;
//        horizontalTextBackground.y = mainHeight - dimension.horizontalTextCentre.y;
//        horizontalText.x = dimension.textBackgroundHeight / 2;
//        horizontalText.y = dimension.textBackgroundWidth / 2;

//        //verticalText.rotation = dimension.rotateText;


//        horizontalTextBackground.beginFill(config.DRAWING_COLOURS.WHITE, 1);
//        horizontalTextBackground.drawRect(0, 0, dimension.textBackgroundHeight, dimension.textBackgroundWidth);
//        horizontalTextBackground.endFill();
//        horizontalTextBackground.addChild(horizontalText);
//        graphicsObj.addChild(horizontalTextBackground);

//    } else {
//        if (dimension.style !== config.DIMENSION_STYLE.COG) {
//            graphicsObj.lineStyle(dimensionLineWidth, config.DRAWING_COLOURS.BLACK, 1);
//        } else {
//            graphicsObj.lineStyle(dimensionLineWidth, config.DRAWING_COLOURS.BLUE, 1);
//        }

//        if (dimension.style !== config.DIMENSION_STYLE.POP_LAYER) {
//            //if (dimension.jumpingLeft === undefined && dimension.jumpingDown === undefined) {
//            if (dimension.style !== config.DIMENSION_STYLE.COG) {
//                //arrow head 1
//                graphicsObj.moveTo(dimension.ah1.tip.x, dimension.ah1.tip.y);
//                graphicsObj.lineTo(dimension.ah1.sideAEnd.x, dimension.ah1.sideAEnd.y);
//                graphicsObj.moveTo(dimension.ah1.tip.x, dimension.ah1.tip.y);
//                graphicsObj.lineTo(dimension.ah1.sideBEnd.x, dimension.ah1.sideBEnd.y);
//            }
//            // line stop 1
//            graphicsObj.moveTo(dimension.lineStop1Start.x, dimension.lineStop1Start.y);
//            graphicsObj.lineTo(dimension.lineStop1End.x, dimension.lineStop1End.y);
//            //}

//            //main line 1
//            graphicsObj.moveTo(dimension.lineStart.x, dimension.lineStart.y);
//            graphicsObj.lineTo(dimension.lineEnd.x, dimension.lineEnd.y);

//            if (dimension.style !== config.DIMENSION_STYLE.COG) {
//                //main line 2
//                graphicsObj.moveTo(dimension.lineStart2.x, dimension.lineStart2.y);
//                graphicsObj.lineTo(dimension.lineEnd2.x, dimension.lineEnd2.y);


//                //if (dimension.jumpingRight === undefined && dimension.jumpingUp === undefined) {
//                // arrow head 2
//                graphicsObj.moveTo(dimension.ah2.tip.x, dimension.ah2.tip.y);
//                graphicsObj.lineTo(dimension.ah2.sideAEnd.x, dimension.ah2.sideAEnd.y);
//                graphicsObj.moveTo(dimension.ah2.tip.x, dimension.ah2.tip.y);
//                graphicsObj.lineTo(dimension.ah2.sideBEnd.x, dimension.ah2.sideBEnd.y);

//                //line stop 2
//                graphicsObj.moveTo(dimension.lineStop2Start.x, dimension.lineStop2Start.y);
//                graphicsObj.lineTo(dimension.lineStop2End.x, dimension.lineStop2End.y);
//                //}
//            }

//        }
//        var fontWeight;
//        if (dimension.style === config.DIMENSION_STYLE.COG) {
//            fontWeight = 'bold';
//        }
//        var text = new PIXI.Text("" + dimension.displayLength, { font: dimension.font, fill: dimension.fontColour, align: 'center', fontWeight: fontWeight });

//        if (dimension.interaction !== undefined) {
//            if (dimension.interaction.callbacks !== undefined) {
//                text.interactive = true;
//                text.buttonMode = true;
//                if (dimension.interaction.callbacks.mouseover !== undefined && typeof dimension.interaction.callbacks.mouseover === 'function') {
//                    text.mouseover = dimension.interaction.callbacks.mouseover;
//                }
//                if (dimension.interaction.callbacks.click !== undefined && typeof dimension.interaction.callbacks.click === 'function') {
//                    text.click = dimension.interaction.callbacks.click;
//                    text.touchstart = dimension.interaction.callbacks.click;
//                }
//                if (dimension.interaction.callbacks.mouseout !== undefined && typeof dimension.interaction.callbacks.mouseout === 'function') {
//                    text.mouseout = dimension.interaction.callbacks.mouseout;
//                }
//            }
//        }

//        text.anchor = new PIXI.Point(0.5, 0.5);

//        var textBackground = new PIXI.Graphics();

//        textBackground.x = dimension.textCentre.x;
//        textBackground.y = dimension.textCentre.y;
//        text.x = dimension.textBackgroundWidth / 2;
//        text.y = dimension.textBackgroundHeight / 2;
//        if (dimension.rotateText) {
//            //text.pivot = new PIXI.Point(0.5, 0.5);
//            text.rotation = dimension.rotateText;
//            //text.x = 2;//(dimension.length - 2) / 2;
//            //text.y = 4;//dimension.lineStart.y;

//        }
//        //else {
//        //    text.x = 4;//(dimension.length - 2) / 2;
//        //    text.y = 2;//dimension.lineStart.y;

//        //}

//        textBackground.beginFill(config.DRAWING_COLOURS.WHITE, 1);
//        textBackground.drawRect(0, 0, dimension.textBackgroundWidth, dimension.textBackgroundHeight);
//        textBackground.endFill();
//        textBackground.addChild(text);
//        graphicsObj.addChild(textBackground);
//    }


//    if (addToGraphicsObject === undefined) {
//        return graphicsObj;
//    }
    
//}

function getTrialWatermarkSprite(imageSource) {
    return watermarkSprite;
}

function loadImageResources() {
    var dfd = $.Deferred();
    if (watermarkSprite === null) {
        //if (_resources !== undefined && _resources['watermark'] !== undefined) {
        //    watermarkSprite = new PIXI.Sprite(_resources.watermark.texture);
        //    watermarkSprite.alpha = 0.5;
        //    dfd.resolve();
        //} else {
        PIXI.loader.reset();
            var loader = PIXI.loader.add('watermark', "img/app/Logo_TrialStamp.png").load(function (loader, resources) {
                _resources = resources;
                watermarkSprite = new PIXI.Sprite(resources.watermark.texture);
                watermarkSprite.alpha = 0.2;
                dfd.resolve();
            })
        //}
        
    } else {
        dfd.resolve();
    }
    

    return dfd.promise();
}

function getDrawingContainer(position, width, height, optioanlBGFillColour, optioanlBorderColor) {
    var drawingArea = new PIXI.Container();
    drawingArea.x = position.x;
    drawingArea.y = position.y;
    //topDrawingArea.width = topWidth;
    //drawingArea.height = height;
    addBackground(drawingArea, width, height, optioanlBGFillColour, optioanlBorderColor );
    return drawingArea;
}

function getInteractiveContainer(callbacks, position, width, height, optioanlBGFillColour) {
    var interactiveContainer = getDrawingContainer(position, width, height, optioanlBGFillColour);

    if (callbacks !== undefined) {
        interactiveContainer.interactive = true;
        interactiveContainer.hitArea = new PIXI.Rectangle(0, 0, width, height);
        if (callbacks.mouseover !== undefined && typeof callbacks.mouseover === 'function') {
            interactiveContainer.mouseover = callbacks.mouseover;
        }
        if (callbacks.click !== undefined && typeof callbacks.click === 'function') {
            interactiveContainer.click = callbacks.click;
        }
        if (callbacks.mousemove !== undefined && typeof callbacks.mousemove === 'function') {
            interactiveContainer.mousemove = callbacks.mousemove;
        }
        if (callbacks.mouseout !== undefined && typeof callbacks.mouseout === 'function') {
            interactiveContainer.mouseout = callbacks.mouseout;
        }
    }
    
    return interactiveContainer;
}

function addBackground(container, w, h, bgColour, borderColor) {
    var bg = new PIXI.Graphics();
    if (bgColour !== undefined && bgColour !== null) {
        bg.beginFill(bgColour);
    }
    if (borderColor !== undefined && borderColor !== null) {
        bg.lineStyle(1, borderColor);
        bg.lineColour = borderColor;
    }
    bg.drawRect(0, 0, w, h);
    if (bgColour !== undefined && bgColour !== null) {
        bg.endFill();
    }

    container.addChild(bg);
}

function getRenderer(width, height, options) {
    return PIXI.autoDetectRenderer(width, height, options, true);
    // return new PIXI.Application(width, height, { antialias: true });
}

function setWaterMarkSpriteToNUll() {
    watermarkSprite = null;
}

function getPolygon(points) {
    return new PIXI.Polygon(points);
}

function getRect(x, y, width, height) {
    return new PIXI.Rectangle(x, y, width, height);
}

var pixiAdapter = {
    drawText: drawText,
    drawLine: drawLine,
    drawDashedLine:drawDashedLine,
    drawArc: drawArc,
    drawPolygon: drawPolygon,
    drawCircle: drawCircle,
    drawDashedCircle:drawDashedCircle,
    getRenderer: getRenderer,
    setScalingFactor: setScalingFactor,
    //drawDimension: drawDimension,
    drawRect: drawRect,
    getDrawingContainer: getDrawingContainer,
    getGraphicsObject: getGraphicsObject,
    setMainHeight: setMainHeight,
    setWordWrapWidth: setWordWrapWidth,
    fillArc: fillArc,
    drawRectWithFixedBuffer: drawRectWithFixedBuffer,
    drawDashedArc: drawDashedArc,
    getTrialWatermarkSprite: getTrialWatermarkSprite,
    loadImageResources: loadImageResources,
    makeRectInteractive: makeRectInteractive,
    drawInteractiveTextIcon: drawInteractiveTextIcon,
    getInteractiveContainer: getInteractiveContainer,
    //drawArcNoScaling: drawArcNoScaling,
    //fillArcNoScaling: fillArcNoScaling,
    //drawDashedArcNoScaling: drawDashedArcNoScaling,
    //drawCircleNoScaling: drawCircleNoScaling,
    getPoint: getPoint,
    getNewGraphicsObject: getNewGraphicsObject,
    setAlternateGraphicsObject: setAlternateGraphicsObject,
    destroyGraphicsObject: destroyGraphicsObject,
    setWaterMarkSpriteToNUll: setWaterMarkSpriteToNUll,
    getPolygon: getPolygon,
    getRect: getRect,
    drawEllipse: drawEllipse,
    drawDashedEllipse: drawDashedEllipse
    //setTopOffsetRequiredForAxisFlip: setTopOffsetRequiredForAxisFlip

}
export default pixiAdapter;


