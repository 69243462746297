<template>
    <div class="row-container">
        <div class="tyre-slider-div" 
            :id="slider.id"
            data-bind="attr: { id: id }"></div>
        <div class="input-container-div" 
            :id="slider.inputContainerId"
            :data-original-title="slider.errorMessage.value"
            data-bind="attr: { id: inputContainerId, 'data-original-title': errorMessage }">
            <input type="text"
                :id="slider.inputId"
                @focus="selectAllText"
                @blur="blurFunction"
                data-bind="attr: { id: inputId }, event: { focus: selectAllText, blur: blurFunction }" />
        </div>
    </div>

    <!-- <script type="text/html" id="template-row-tyre-detail-slider">
        <div class="row-container">
            <div class="tyre-slider-div" data-bind="attr: { id: id }"></div>
            <div class="input-container-div" data-bind="attr: { id: inputContainerId, 'data-original-title': errorMessage }">
                <input type="text" data-bind="attr: { id: inputId }, event: { focus: selectAllText, blur: blurFunction }" />
            </div>
        </div>
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    name: 'RowTyreDetailSlider',
    props: {
        sliderFromParent: Object
    },
    setup (props) {
        const slider = toRef(props, "sliderFromParent");

        function selectAllText() {
            slider.value.selectAllText();
        }

        function blurFunction() {
            slider.value.blurFunction();
        }

        return {
            slider,
            selectAllText,
            blurFunction
        }
    }
}
</script>

<style lang="scss" scoped>

</style>