<template>
    <div class="multiselect-div" :id="multiselect.selectId + '-multiselect-div'" :data-content="multiselect.popoverTooltipText.value" data-placement="right">
        <select class="multiselect-template-class" v-if="multiselect.selectedOptionType === SELECT_OPTION_TYPE.MULTIPLE"
            :id="multiselect.selectId" multiple data-toggle="tooltip">
            <option v-for="option in multiselect.options.value" :key="option" :value="multiselect.getValueForOption(option)">
                {{ multiselect.getDescriptionForOption(option) }}</option>
        </select>
        <select class="multiselect-template-class" v-if="multiselect.selectedOptionType === SELECT_OPTION_TYPE.SINGLE"
            :id="multiselect.selectId" data-toggle="tooltip">
            <option v-for="option in multiselect.options.value" :key="option" :value="multiselect.getValueForOption(option)">
                {{ multiselect.getDescriptionForOption(option) }}</option>
        </select>
    </div>
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

export default {
    props: {
        multiSelectFromParent: Object
    },
    setup(props) {
        const multiselect = toRef(props, "multiSelectFromParent");

        return {
            multiselect,
            SELECT_OPTION_TYPE: config.SELECT_OPTION_TYPE
        }
    }
}
</script>

<style lang="scss" scoped>
</style>