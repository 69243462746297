import config from '../..//services/config';



var COGDimension = function (type, name, horizontalLayerNumber, verticalLayerNumber, style,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, zOrder,
                            optionalLabelText, markerRadius, optionalLabelStyle, isPdfDrawing, verticalAxisOrigin) {

    var self = this;

    self.textBuffer = 4;
    self.type = type;
    self.name = name;
    self.verticalLayerNumber = verticalLayerNumber;
    self.horizontalLayerNumber = horizontalLayerNumber;
    self.style = style || config.DIMENSION_STYLE.STANDARD;
    self.zOrder = zOrder;
    self.markerRadius = markerRadius || 8;
    self.layerIncrement = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
    self.verticalAxisOrigin = verticalAxisOrigin || 0;
    
    if((hCOGOffset + hCOG) > horizontalReferenceX) {
        self.startHorizontal = horizontalReferenceX;
        self.lengthHorizontal = (hCOGOffset + hCOG) - horizontalReferenceX;
    } else {
        self.startHorizontal = hCOGOffset + hCOG;
        self.lengthHorizontal = horizontalReferenceX - (hCOGOffset + hCOG);
    }

    self.startVertical = self.verticalAxisOrigin;
    self.lengthVertical = vCOGOffset + vCOG;
    self.horizontalReferenceX = horizontalReferenceX;
    self.vehicleLengthInc = vehicleLengthInc;
    self.vehicleHeightInc = vehicleHeightInc;

    self.displayLengthVertical = displayLengthVertical;
    self.displayLengthHorizontal = displayLengthHorizontal;
    
    self.setTotalFrontLayerThickness = function(newValue) {
        self.totalFrontLayerThickness = newValue;
    }

    self.setTotalBottomLayerThickness = function (newValue) {
        self.totalBottomLayerThickness = newValue;
    }

    self.setTotalTopLayerThickness = function (newValue) {
        self.totalTopLayerThickness = newValue;
    }

    self.setMainHeight = function (newValue) {
        self.mainHeight = newValue;
    }
    
    self.setupPoints = function () {
        
        if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || self.type === config.DIMENSION_TYPE.COG_TOP_LEFT) {
            //self.verticalStartPoint = { x: self.totalFrontLayerThickness - (self.verticalLayerNumber * config.configurationDimensionLayerIncrement), y: self.totalBottomLayerThickness + self.startVertical };
            self.verticalStartPoint = { x: self.totalFrontLayerThickness - (self.verticalLayerNumber * self.layerIncrement), y: self.totalBottomLayerThickness + self.startVertical };
        } else if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT || self.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
            //self.verticalStartPoint = { x: self.totalFrontLayerThickness + self.vehicleLengthInc + (self.verticalLayerNumber * config.configurationDimensionLayerIncrement), y: self.totalBottomLayerThickness + self.startVertical };
            self.verticalStartPoint = { x: self.totalFrontLayerThickness + self.vehicleLengthInc + (self.verticalLayerNumber * self.layerIncrement), y: self.totalBottomLayerThickness + self.startVertical };
        }

        if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || self.type === config.DIMENSION_TYPE.COG_TOP_LEFT) {
            //self.verticalEndPoint = { x: self.totalFrontLayerThickness - (self.verticalLayerNumber * config.configurationDimensionLayerIncrement), y: self.totalBottomLayerThickness + self.lengthVertical };
            self.verticalEndPoint = { x: self.totalFrontLayerThickness - (self.verticalLayerNumber * self.layerIncrement), y: self.totalBottomLayerThickness + self.verticalAxisOrigin + self.lengthVertical };
        } else if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT || self.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
            //self.verticalEndPoint = { x: self.totalFrontLayerThickness + self.vehicleLengthInc + (self.verticalLayerNumber * config.configurationDimensionLayerIncrement), y: self.totalBottomLayerThickness + self.lengthVertical };
            self.verticalEndPoint = { x: self.totalFrontLayerThickness + self.vehicleLengthInc + (self.verticalLayerNumber * self.layerIncrement), y: self.totalBottomLayerThickness + self.verticalAxisOrigin + self.lengthVertical };
        }

        if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || self.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT) {
            //self.horizontalStartPoint = { x: self.totalFrontLayerThickness + self.startHorizontal, y: self.totalBottomLayerThickness - (self.horizontalLayerNumber * config.configurationDimensionLayerIncrement) };
            self.horizontalStartPoint = { x: self.totalFrontLayerThickness + self.startHorizontal, y: self.totalBottomLayerThickness - (self.horizontalLayerNumber * self.layerIncrement) };
        } else if (self.type === config.DIMENSION_TYPE.COG_TOP_LEFT || self.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
            //self.horizontalStartPoint = { x: self.totalFrontLayerThickness + self.startHorizontal, y: self.totalBottomLayerThickness + self.vehicleHeightInc + (self.horizontalLayerNumber * config.configurationDimensionLayerIncrement) };
            self.horizontalStartPoint = { x: self.totalFrontLayerThickness + self.startHorizontal, y: self.totalBottomLayerThickness + self.vehicleHeightInc + (self.horizontalLayerNumber * self.layerIncrement) };
        }

        if (self.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || self.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT) {
            //self.horizontalEndPoint = { x: self.totalFrontLayerThickness + self.startHorizontal + self.lengthHorizontal, y: self.totalBottomLayerThickness - (self.horizontalLayerNumber * config.configurationDimensionLayerIncrement) };
            self.horizontalEndPoint = { x: self.totalFrontLayerThickness + self.startHorizontal + self.lengthHorizontal, y: self.totalBottomLayerThickness - (self.horizontalLayerNumber * self.layerIncrement) };
        } else if (self.type === config.DIMENSION_TYPE.COG_TOP_LEFT || self.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
            //self.horizontalEndPoint = { x: self.totalFrontLayerThickness + self.startHorizontal + self.lengthHorizontal, y: self.totalBottomLayerThickness + self.vehicleHeightInc + (self.horizontalLayerNumber * config.configurationDimensionLayerIncrement) };
            self.horizontalEndPoint = { x: self.totalFrontLayerThickness + self.startHorizontal + self.lengthHorizontal, y: self.totalBottomLayerThickness + self.vehicleHeightInc + (self.horizontalLayerNumber * self.layerIncrement) };
        }

        if (self.startHorizontal < self.horizontalReferenceX) {
            self.cogPoint = { x: self.totalFrontLayerThickness + self.startHorizontal, y: self.totalBottomLayerThickness + self.lengthVertical + self.verticalAxisOrigin };
        } else {
            self.cogPoint = { x: self.totalFrontLayerThickness + self.startHorizontal + self.lengthHorizontal, y: self.totalBottomLayerThickness + self.lengthVertical + self.verticalAxisOrigin };
        }
    }
    
    //arrow props
    self.ah1 = {
        tip: {},
        sideAEnd : {},
        sideBEnd : {}
    };
    
    self.ah2 = {
        tip: {},
        sideAEnd : {},
        sideBEnd : {}
    };
    
    //line stops a.k.a delimeters
    self.lineStop1Start = {};
    self.lineStop1End = {};
    self.lineStop2Start = {};
    self.lineStop2End = {};

    self.labelText = optionalLabelText || null;
    self.labelStyle = optionalLabelStyle || config.COG_ELEMENT_STYLE.DRAW_LEFT;
    self.setLabelStyle = function (newLabelStyle) {
        self.labelStyle = newLabelStyle;
    }
    //dimension text/value props
    self.rotateText = false;
    function addInteraction(interactionObj) {
        self.interaction = interactionObj;
        self.customFontColour = config.DRAWING_COLOURS.BLUE;
        return self;
    }

    self.drawingGroupName = 'NOT_SET';
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
    }

    self.setDrawingGroupName = setDrawingGroupName;
    self.addInteraction = addInteraction;
};

export default COGDimension;


