

function DimensionConfig() {
    var self = this;

    var _id, _start = 0, _length, _attrContext = undefined, _standardDisplayTextFunc, _customDisplayTextFunc, _startPoint, _endPoint, _lineEndStyle, _trialText, _objectId, _drawBG;


    function setId(id) {
        _attrContext = undefined;
        _id = id;
        return self;
    }
    function getId() {
        return _id;
    }

    function setStart(start) {
        _start = start;
        return self;
    }
    function getStart() {
        if (typeof _start === 'function') {
            return _start(_attrContext, _objectId);
        } else {
            return _start;
        }
    }
    function setLength(length) {
        _length = length;
        return self;
    }
    function getLength() {
        if (typeof _length === 'function') {
            return Math.abs(_length(_attrContext, _objectId));
        } else {
            return Math.abs(_length);
        }
    }
    function getDisplayText(optionalLengthVal) {
        if (_trialText === undefined) {
            if (_customDisplayTextFunc === undefined) {
                return _standardDisplayTextFunc(optionalLengthVal === undefined ? getLength() : optionalLengthVal);
            } else {
                return _customDisplayTextFunc(_attrContext, optionalLengthVal || getLength());
            }
        } else {
            return _trialText;
        }
        

    }
    function setTrialText(trialText) {
        _trialText = trialText;
    }
    function addCustomDisplayTextFunction(customDisplayTextFunc) {
        _customDisplayTextFunc = customDisplayTextFunc;
        return self;
    }
    function addStandardDisplayTextFunction(standardDisplayTextFunc) {
        _standardDisplayTextFunc = standardDisplayTextFunc;
        return self;
    }
    function setContext(attrContext) {
        _attrContext = attrContext;
        return self;
    }
    function getContext(attrContext) {
        return _attrContext;
    }

    function setStartPoint(startPointX, startPointY) {
        _startPoint = { x: startPointX, y: startPointY };
        return self;
    }

    function getStartPoint() {
        return _startPoint;
    }

    function setEndPoint(endPointX, endPointY) {
        _endPoint = { x: endPointX, y: endPointY };
        return self;
    }

    function getEndPoint() {
        return _endPoint;
    }

    function setLineEndStyle(lineEndStyle) {
        _lineEndStyle = lineEndStyle;
        return self;
    }

    function getLineEndStyle() {
        return _lineEndStyle;
    }

    function setObjectId(objectId) {
        _objectId = objectId;
        return self;
    }
    function getObjectId() {
        return _objectId;
    }

    function setDrawBG(drawBG) {
        _drawBG = drawBG;
        return self;
    }

    function getDrawBG() {
        return _drawBG;
    }

    self.setId = setId;
    self.setStart = setStart;
    self.setLength = setLength;
    self.getId = getId;
    self.getStart = getStart;
    self.getLength = getLength;
    self.getDisplayText = getDisplayText;
    self.setContext = setContext;
    self.addCustomDisplayTextFunction = addCustomDisplayTextFunction;
    self.addStandardDisplayTextFunction = addStandardDisplayTextFunction;
    self.setStartPoint = setStartPoint;
    self.setEndPoint = setEndPoint;
    self.getStartPoint = getStartPoint;
    self.getEndPoint = getEndPoint;
    self.setLineEndStyle = setLineEndStyle;
    self.getLineEndStyle = getLineEndStyle;
    self.setTrialText = setTrialText;
    self.getContext = getContext;
    self.getObjectId = getObjectId;
    self.setObjectId = setObjectId;
    self.getDrawBG = getDrawBG;
    self.setDrawBG = setDrawBG;

}

export default DimensionConfig;
