import config from "@/services/config";
import utils from "@/services/utils";

function DatagridColumnBuilder() {
  let self = this;
  let newElement = {
    name: "",
    nameDisplayed: false,
    sortable: false,
    cleanUp: cleanUp,
    includeSelectAllCheckbox: false,
  };

  let permissionObject = JSON.parse(
    JSON.stringify(config.defaultMenuPermissionObject)
  );
  // name, nameDisplayed, sortable, sortType, itemToSort, headerClass

  function name(n) {
    newElement.name = n;
    return self;
  }

  function nameDisplayed(nd) {
    newElement.nameDisplayed = nd;
    return self;
  }

  function isNameDisplayed() {
    newElement.nameDisplayed = true;
    return self;
  }

  function sortable(s) {
    newElement.sortable = s;
    return self;
  }

  function isSortable() {
    newElement.sortable = true;
    newElement.activeSort = false;
    return self;
  }

  function isActiveSort() {
    newElement.activeSort = true;
    return self;
  }

  function sortType(st) {
    newElement.sortType = st;
    return self;
  }

  function itemToSort(its) {
    newElement.itemToSort = its;
    return self;
  }

  function headerClass(hc) {
    newElement.headerClass = hc;
    return self;
  }

  function sortFunction(sf) {
    newElement.sortFunction = sf;
    return self;
  }

  function includeSelectAllCheckbox(isChecked) {
    newElement.includeSelectAllCheckbox = true;
    if (
      isChecked === config.SELECT_ALL_CHECKBOX_OPTIONS.CHECKED ||
      isChecked === config.SELECT_ALL_CHECKBOX_OPTIONS.MINUS
    ) {
      newElement.isChecked = isChecked;
    } else {
      newElement.isChecked = config.SELECT_ALL_CHECKBOX_OPTIONS.NOT_CHECKED;
    }

    return self;
  }

  function headerThClass(htc) {
    newElement.headerThClass = htc;
    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      let breakHere = 0;
      delete newElement[member];
    }
  }

  this.name = name;
  this.nameDisplayed = nameDisplayed;
  this.sortable = sortable;
  this.sortType = sortType;
  this.itemToSort = itemToSort;
  this.headerClass = headerClass;
  this.isActiveSort = isActiveSort;
  this.sortFunction = sortFunction;
  this.headerThClass = headerThClass;
  this.build = build;
  this.includeSelectAllCheckbox = includeSelectAllCheckbox;
  this.cleanUp = cleanUp;
}

/**
 *
 * @param {object[]|object} toolbarButtons - Buttons generated using ToolbarButtonBuilder
 */
function ToolbarBuilder(toolbarButtons) {
  let self = this;
  let newElement = {
    generatedBy: "ToolbarBuilder",
    toolbarButtons: [],
  };

  if (Array.isArray(toolbarButtons)) {
    toolbarButtons.forEach(function (tbButton) {
      if (tbButton.generatedBy === "ToolbarButtonBuilder") {
        newElement.toolbarButtons.push(tbButton);
      } else {
        throw "toolbarButtons.type is not ToolbarButtonBuilder";
      }
    });
  } else {
    if (toolbarButtons.generatedBy === "ToolbarButtonBuilder") {
      newElement.toolbarButtons.push(toolbarButtons);
    } else {
      throw "toolbarButtons.type is not ToolbarButtonBuilder";
    }
  }

  function build() {
    return newElement;
  }

  this.build = build;
}

/**
 *
 * @param {string} text - Text to be displayed beside the icon
 * @param {string} icon - Font Awesome icon class
 * @param {any} clickCallback - Function to be called when the user clicks on the button
 */
function ToolbarButtonBuilder(text, icon, clickCallback) {
  //if (typeof text !== 'string') {
  //    throw 'text param in ToolbarButtonBuilder is not a string';
  //}
  //if (typeof icon !== 'string') {
  //    throw 'icon param in ToolbarButtonBuilder is not a string';
  //}
  //if (typeof clickCallback !== 'function') {
  //    throw 'clickCallback param in ToolbarButtonBuilder is not a function';
  //}
  let self = this;
  let newElement = {
    generatedBy: "ToolbarButtonBuilder",
    isEnabledCallback: function () {
      return true;
    },
  };

  function asButton(text, icon, clickCallback) {
    if (typeof text !== "string") {
      throw "text param in ToolbarButtonBuilder is not a string";
    }
    if (typeof icon !== "string") {
      throw "icon param in ToolbarButtonBuilder is not a string";
    }
    if (typeof clickCallback !== "function") {
      throw "clickCallback param in ToolbarButtonBuilder is not a function";
    }

    newElement.text = text;
    newElement.icon = icon;
    newElement.clickFunction = clickCallback;
    newElement.type = config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON;

    return self;
  }

  function asSeparator() {
    newElement.type = config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.SEPARATOR;

    return self;
  }

  function addIsEnabledCallback(iec) {
    if (typeof iec !== "function") {
      throw "iec param in addIsEnabledCallback is not a function";
    }
    newElement.isEnabledCallback = iec;
    return self;
  }

  function build() {
    return newElement;
  }

  this.asButton = asButton;
  this.asSeparator = asSeparator;
  this.addIsEnabledCallback = addIsEnabledCallback;
  this.build = build;
}

/**
 *
 * @param {object[]|object} breadcrumbs - Buttons generated using ToolbarButtonBuilder
 */
function CustomBreadcrumbObjectBuilder(breadcrumbs, clickCallback) {
  let self = this;
  let newElement = {
    generatedBy: "CustomBreadcrumbObjectBuilder",
    breadcrumbs: [],
  };

  if (Array.isArray(breadcrumbs)) {
    breadcrumbs.forEach(function (bc) {
      if (bc.generatedBy === "CustomBreadcrumbBuilder") {
        newElement.breadcrumbs.push(bc);
      } else {
        throw "breadcrumbs.generatedBy is not CustomBreadcrumbBuilder";
      }
    });
  } else {
    if (breadcrumbs.generatedBy === "CustomBreadcrumbBuilder") {
      newElement.breadcrumbs.push(breadcrumbs);
    } else {
      throw "breadcrumbs.generatedBy is not CustomBreadcrumbBuilder";
    }
  }

  if (typeof clickCallback === "function") {
    newElement.clickCallback = clickCallback;
  } else {
    throw "clickCallback param in CustomBreadcrumbsBuilder is not a function";
  }

  function build() {
    return newElement;
  }

  this.build = build;
}

/**
 *
 * @param {number} id - ID of breadcrumb
 * @param {string} text - Text to be displayed in breadcrumb
 * @param {object?} optionalData - Object containing other data that can be stored on breadcrumb
 */
function CustomBreadcrumbBuilder(id, text, optionalData) {
  if (typeof id !== "number") {
    throw "is param in CustomBreadcrumbBuilder is not a number";
  }
  if (typeof text !== "string") {
    throw "text param in CustomBreadcrumbBuilder is not a string";
  }
  let self = this;
  let newElement = {
    id: id,
    text: text,
    optionalData: optionalData !== undefined ? optionalData : null,
    generatedBy: "CustomBreadcrumbBuilder",
  };

  function build() {
    return newElement;
  }

  this.build = build;
}

function TabFilterObjectBuilder() {
  let self = this;
  let newElement = {
    cleanUp: cleanUp,
  };

  function value(v) {
    newElement.value = v;
    return self;
  }

  function property(p) {
    newElement.property = p;
    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      delete newElement[member];
    }
  }

  this.value = value;
  this.property = property;
  this.build = build;
  this.cleanUp = cleanUp;
}

function StepTabBuilder() {
  let self = this;
  let newElement = {
    cleanUp: cleanUp,
  };

  function displayName(dn) {
    newElement.displayName = dn;
    return self;
  }

  function iconClass(ic) {
    newElement.iconClass = ic;
    return self;
  }

  function filterObject(fo) {
    newElement.filterObject = fo;
    return self;
  }

  /**
   *
   * @param {string} tt
   */
  function tooltipText(tt) {
    newElement.tooltipText = typeof tt === "string" ? tt : "";
    return self;
  }

  function clickFunction(cf) {
    newElement.clickFunction = cf;
    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    utils.deleteObjectMembers(newElement);
  }

  this.displayName = displayName;
  this.iconClass = iconClass;
  this.filterObject = filterObject;
  this.tooltipText = tooltipText;
  this.clickFunction = clickFunction;
  this.build = build;
  this.cleanUp = cleanUp;
}

function SelectionWizardStepBuilder() {
  let self = this;
  let newElement = {
    displayQuickSearch: false,
    displayMultiSelect: false,
    stayOnStepWhenClearAllFiltersClicked: false,
    displayTabs: false,
    displayRequestItemSection: false,
    tabs: [],
    cleanUp: cleanUp,
  };

  function displayName(dn) {
    newElement.displayName = dn;
    return self;
  }

  function displayInBreadcrumb(din) {
    newElement.displayInBreadcrumb = din;
    return self;
  }

  function sortFunction(sf) {
    newElement.sortFunction = sf;
    return self;
  }

  function filterFunction(ff) {
    newElement.filterFunction = ff;
    return self;
  }

  function clickFunction(cf) {
    newElement.clickFunction = cf;
    return self;
  }

  function shareFunction(sf) {
    newElement.shareFunction = sf;
    return self;
  }

  function deleteFunction(df) {
    newElement.deleteFunction = df;
    return self;
  }

  function downloadFileFunction(dff) {
    newElement.downloadFileFunction = dff;
    return self;
  }

  function renderAs(ra) {
    newElement.renderAs = ra;
    return self;
  }

  function uniqueField(uf) {
    newElement.uniqueField = uf;
    return self;
  }

  function renderImageCardAs(rica) {
    newElement.renderImageCardAs = rica;
    return self;
  }

  function displayQuickSearch(dqs) {
    newElement.displayQuickSearch = dqs;
    return self;
  }

  function columns(cols) {
    newElement.columns = cols;
    return self;
  }

  function limitResults(limit) {
    newElement.limitResults = limit || config.defaultSearchResultsLimit;
    return self;
  }

  function alwaysDisplayStep() {
    newElement.alwaysDisplayStep = true;
    return self;
  }

  function bypassAutoItemSelectWhenOnlyOne() {
    newElement.bypassAutoItemSelectWhenOnlyOne = true;
    return self;
  }

  function tabs(t) {
    newElement.tabs = t;
    newElement.displayTabs = true;
    return self;
  }

  function requestItemFunction(rif) {
    newElement.requestItemFunction = rif;
    newElement.displayRequestItemSection = true;
    return self;
  }

  // Added to set title on wizard
  function setWizardTitleFunction(swtf) {
    newElement.setWizardTitleFunction = swtf;
    return self;
  }

  /**
   * Display the clear button to the user
   */
  function displayClearButton() {
    newElement.displayClearButton = true;
    return self;
  }

  /**
   *
   * @param {function|boolean} ddfo
   */
  function displayDownloadFileOption(ddfo) {
    newElement.displayDownloadFileOption =
      typeof ddfo === "function"
        ? ddfo()
        : typeof ddfo === "boolean"
        ? ddfo
        : false;
    return self;
  }

  /**
   * Add button beside the searchg field
   * @param {function} dib - Callback function when button is clicked
   * @param {string} text - Text to be displayed on button
   * @param {string} cssClass - Font Awesome classes to be displayed beside the text
   */
  function displayIconButton(dib, text, cssClass) {
    if (typeof dib === "function") {
      newElement.displayIconButton = true;
      newElement.iconButtonCallback = dib;
      newElement.iconButtonText =
        typeof text === "string" ? text : config.getTranslationText('4954'); // User Story 17565 Translations 4954
      newElement.iconButtonClass = typeof cssClass === "string" ? cssClass : "";
    } else {
      newElement.displayIconButton = false;
    }

    return self;
  }

  /**
   * Set the name of the template to be used on the step
   * @param {string=null} rtn - Name of the template to be used in the step
   */
  function rowTemplateName(rtn) {
    newElement.rowTemplateName = typeof rtn === "function" ? rtn : null;
    return self;
  }

  function onlineSearchCallback(callback) {
    newElement.onlineSearchCallback = callback;
    return self;
  }

  function displayMultiSelect(dms) {
    newElement.displayMultiSelect = dms;
    return self;
  }

  function stayOnStepWhenClearAllFiltersClicked() {
    newElement.stayOnStepWhenClearAllFiltersClicked = true;
    return self;
  }

  function clickOnItemCallback(cb) {
    newElement.clickOnItemCallback = typeof cb === "function" ? cb : null;
    return self;
  }

  /**
   * Added to ensure that the self.checkRules is fired even when alwaysDisplayStep === false in clickOnItem function in wizardHelper.
   */
  function performCheckOnClickOnThisStep() {
    newElement.performCheckOnClickOnThisStep = true;
    return self;
  }

  function activateWizardStepCallback(cb) {
    newElement.activateWizardStepCallback = typeof cb === "function" ? cb : null;
    return self
  }

  function deactivateWizardStepCallback(cb) {
    newElement.deactivateWizardStepCallback = typeof cb === "function" ? cb : null;
    return self
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      let breakHere = 0;
      delete newElement[member];
    }
  }

  this.displayName = displayName;
  this.displayInBreadcrumb = displayInBreadcrumb;
  this.sortFunction = sortFunction;
  this.filterFunction = filterFunction;
  this.clickFunction = clickFunction;
  this.shareFunction = shareFunction;
  this.deleteFunction = deleteFunction;
  this.downloadFileFunction = downloadFileFunction;
  this.displayDownloadFileOption = displayDownloadFileOption;
  this.renderAs = renderAs;
  this.uniqueField = uniqueField;
  this.renderImageCardAs = renderImageCardAs;
  this.displayQuickSearch = displayQuickSearch;
  this.columns = columns;
  this.limitResults = limitResults;
  this.alwaysDisplayStep = alwaysDisplayStep;
  this.bypassAutoItemSelectWhenOnlyOne = bypassAutoItemSelectWhenOnlyOne;
  this.tabs = tabs;
  this.requestItemFunction = requestItemFunction;
  this.setWizardTitleFunction = setWizardTitleFunction;
  this.displayClearButton = displayClearButton;
  this.displayIconButton = displayIconButton;
  this.rowTemplateName = rowTemplateName;
  this.onlineSearchCallback = onlineSearchCallback;
  this.displayMultiSelect = displayMultiSelect;
  this.stayOnStepWhenClearAllFiltersClicked =
    stayOnStepWhenClearAllFiltersClicked;
  this.clickOnItemCallback = clickOnItemCallback;
  this.performCheckOnClickOnThisStep = performCheckOnClickOnThisStep;
  this.activateWizardStepCallback = activateWizardStepCallback;
  this.deactivateWizardStepCallback = deactivateWizardStepCallback;
  this.build = build;
  this.cleanUp = cleanUp;
}

function SelectionWizardBuilder() {
  let self = this;
  let newElement = {
    cleanUp: cleanUp,
  };

  function placeholderText(pt) {
    newElement.placeholderText = pt;
    return self;
  }

  function displayBreadcrumbs(db) {
    newElement.displayBreadcrumbs = db;
    return self;
  }

  function alwaysHideBreadcrumbs() {
    newElement.displayBreadcrumbs = false;
    return self;
  }

  /**
   * Do not show search results breadcrumb to the user
   * */
  function alwaysHideSearchResultsBreadcrumb() {
    newElement.alwaysHideSearchResultsBreadcrumb = true;
    return self;
  }

  function displayBackButton(title) {
    newElement.displayBreadcrumbs = false;
    newElement.displayBackButton = true;
    newElement.title = title;
    return self;
  }

  function steps(st) {
    newElement.steps = st;
    return self;
  }

  function rows(rs) {
    newElement.rows = rs;
    return self;
  }

  function rowType(rt) {
    newElement.rowType = rt;
    return self;
  }

  function containerId(ci) {
    newElement.containerId = ci;
    return self;
  }

  function addRulesEngine(rulesEngine) {
    newElement.checkRules = rulesEngine;
    return self;
  }

  function path(path) {
    newElement.path = path;
    return self;
  }

  function isTrailerSelectionWizard() {
    newElement.isTrailerSelectionWizard = true;
    return self;
  }

  function searchTermsChangeCallbackFunction(stccf, stie) {
    newElement.searchTermsChangeCallbackFunction = stccf;
    newElement.searchTermsIntercomEvent = stie;
    return self;
  }

  function addTitleButDoNotDisplay(title) {
    newElement.displayBreadcrumbs = false;
    newElement.displayBackButton = false;
    newElement.title = title;
    return self;
  }

  function customCss(cc) {
    newElement.customCss = cc;
    return self;
  }

  function getMenuCallback(gmc) {
    newElement.getMenuCallback = gmc;
    return self;
  }

  function toggleSortOrderCallback(tsoc) {
    newElement.toggleSortOrderCallback = tsoc;
    return self;
  }

  function addMultiSelects(msa) {
    newElement.multiSelectsArray = msa;
    newElement.displayMultiSelect = true;
    return self;
  }

  function filterOption(fo) {
    newElement.filterOption = fo;
    return self;
  }

  function preventAutoFilterRemoval(pafr) {
    newElement.preventAutoFilterRemoval = Array.isArray(pafr) ? pafr : [];
    return self;
  }

  /**
   * Add button beside the searchg field
   * @param {function} dib - Callback function when button is clicked
   * @param {string} text - Text to be displayed on button
   * @param {string} cssClass - Font Awesome classes to be displayed beside the text
   */
  function addSearchBarButton(dib, text, cssClass) {
    if (typeof dib === "function") {
      newElement.displaySearchBarButton = true;
      newElement.searchBarButtonCallback = dib;
      newElement.searchBarButtonText =
        typeof text === "string" ? text : config.getTranslationText('4955'); // User Story 17565 Translations 4955
      newElement.searchBarButtonClass =
        typeof cssClass === "string" ? cssClass : "";
    } else {
      newElement.displaySearchBarButton = false;
    }

    return self;
  }

  /**
   *
   * @param {string} tt - Text to be displayed
   */
  function deleteIconTooltipText(tt) {
    newElement.deleteIconTooltipText = typeof tt === "string" ? tt : "";
    return self;
  }

  /**
   * Add tooltip text to clear button
   * @param {string} [tt=''] - Tooltip text to be displayed on hover
   */
  function clearButtonTooltipText(tt) {
    newElement.clearButtonTooltip = typeof tt === "string" ? tt : "";
    return self;
  }

  /**
   * Add tooltip text to download button in datagrid
   * @param {string} [tt=''] - Tooltip text to be displayed on hover
   */
  function downloadFileTooltipText(tt) {
    newElement.downloadFileTooltipText = typeof tt === "string" ? tt : "";
    return self;
  }

  /**
   * Add tooltip text to share button in datagrid
   * @param {string} [tt=''] - Tooltip text to be displayed on hover
   */
  function shareIconTooltipText(tt) {
    newElement.shareIconTooltipText = typeof tt === "string" ? tt : "";
    return self;
  }

  /**
   * Add tooltip text to padlock/no-edit icon in datagrid
   * @param {string} [tt=''] - Tooltip text to be displayed on hover
   */
  function noEditIconTooltipText(tt) {
    newElement.noEditIconTooltipText = typeof tt === "string" ? tt : "";
    return self;
  }

  function openDialogClickListenerCallback(cb) {
    newElement.openDialogClickListenerCallback =
      typeof cb === "function" ? cb : null;
    return self;
  }

  /**
   * Add Toolbar - Example in selection my saved items
   * @param {object} tb - Toolbar object generated using ToolbarBuilder
   * @returns {object} SelectionWizardObject
   */
  function addToolbar(tb) {
    if (tb.generatedBy !== "ToolbarBuilder") {
      throw "tb param not generated using ToolbarBuilder";
    }
    newElement.displayToolbar = true;
    newElement.toolbar = tb;
    return self;
  }

  /**
   * Add Custom Breadcrumb to selection wizard - Example in selection my saved items
   * @param {object} cbc - Custom breadcrumb object generated using CustomBreadcrumbObjectBuilder
   * @returns {object} SelectionWizardObject
   */
  function addCustomBreadcrumbs(cbc) {
    if (cbc.generatedBy !== "CustomBreadcrumbObjectBuilder") {
      throw "cbc param not generated using CustomBreadcrumbObjectBuilder";
    }
    newElement.displayCustomBreadcrumbs = true;
    newElement.customBreadcrumbsObject = cbc;
    return self;
  }

  function displayResetButtonOnSameRowAsSearch() {
    newElement.displayResetButtonOnSameRowAsSearch = true;
    return self;
  }

  function displayDialogAsOverlay() {
    newElement.dialogDisplayMode = config.MODAL_DISPLAY_MODE.OVERLAY;
    return self;
  }

  function templateLinkClickListenerCallback(cb) {
    newElement.templateLinkClickListenerCallback =
      typeof cb === "function" ? cb : null;
    return self;
  }

  function changeStatusLinkClickListenerCallback(cb) {
    newElement.changeStatusLinkClickListenerCallback =
      typeof cb === "function" ? cb : null;
    return self;
  }

  function addRequestItemText(rit) {
    newElement.requestItemText = typeof rit === "string" ? rit : null;
    return self;
  }

  function addUpdateRequestItemTextFunction(cb) {
    newElement.allowUpdateRequestItemText = true;
    newElement.updateRequestItemTextFunction =
      typeof cb === "function" ? cb : null;
    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      let breakHere = 0;
      delete newElement[member];
    }
    newElement = null;
    self = null;
  }

  this.placeholderText = placeholderText;
  this.displayBreadcrumbs = displayBreadcrumbs;
  this.alwaysHideBreadcrumbs = alwaysHideBreadcrumbs;
  this.alwaysHideSearchResultsBreadcrumb = alwaysHideSearchResultsBreadcrumb;
  this.displayBackButton = displayBackButton;
  this.steps = steps;
  this.rows = rows;
  this.rowType = rowType;
  this.containerId = containerId;
  this.addRulesEngine = addRulesEngine;
  this.path = path;
  this.isTrailerSelectionWizard = isTrailerSelectionWizard;
  this.searchTermsChangeCallbackFunction = searchTermsChangeCallbackFunction;
  this.addTitleButDoNotDisplay = addTitleButDoNotDisplay;
  this.customCss = customCss;
  this.getMenuCallback = getMenuCallback;
  this.toggleSortOrderCallback = toggleSortOrderCallback;
  this.addMultiSelects = addMultiSelects;
  this.filterOption = filterOption;
  this.preventAutoFilterRemoval = preventAutoFilterRemoval;
  this.addSearchBarButton = addSearchBarButton;
  this.deleteIconTooltipText = deleteIconTooltipText;
  this.clearButtonTooltipText = clearButtonTooltipText;
  this.downloadFileTooltipText = downloadFileTooltipText;
  this.shareIconTooltipText = shareIconTooltipText;
  this.noEditIconTooltipText = noEditIconTooltipText;
  this.openDialogClickListenerCallback = openDialogClickListenerCallback;
  this.addToolbar = addToolbar;
  this.addCustomBreadcrumbs = addCustomBreadcrumbs;
  this.displayResetButtonOnSameRowAsSearch =
    displayResetButtonOnSameRowAsSearch;
  this.displayDialogAsOverlay = displayDialogAsOverlay;
  this.templateLinkClickListenerCallback = templateLinkClickListenerCallback;
  this.changeStatusLinkClickListenerCallback = changeStatusLinkClickListenerCallback;
  this.addRequestItemText = addRequestItemText;
  this.addUpdateRequestItemTextFunction = addUpdateRequestItemTextFunction;
  this.build = build;
  this.cleanUp = cleanUp;
}

/**
 * Used to create step objects to be used in ConfigurationWizard
 * @param {string} stepType - Step type should be on config.WIZARD_STEP_TYPES enum
 * @param {string} title - Title to be displayed to user
 * @param {string} wizardLayoutType - Layout to be used in the body which should be on WIZARD_LAYOUT_TYPES enum
 */
function ConfigurationWizardStepBuilder(stepType, title, wizardLayoutType) {
  if (typeof stepType !== "string") {
    throw "stepType is not provided";
  }
  if (typeof title !== "string") {
    throw "title is not provided";
  }
  if (typeof wizardLayoutType !== "string") {
    throw "wizardLayoutType is not provided";
  }
  let self = this;
  let newElement = {
    stepType: stepType,
    title: title,
    description: null,
    wizardLayoutType: wizardLayoutType,
    displayBackButtonWhenStepIsActive: true,
    displayNextButtonWhenStepIsActive: true,
    displayDoneButtonWhenStepIsActive: true,
    isBackButtonActiveWhenStepIsActive: true,
    isNextButtonActiveWhenStepIsActive: false,
    isDoneButtonActiveWhenStepIsActive: false,
    isActive: false,
    stepIncludesRadioGroupInput: false,
    radioGroupInput: null,
    cleanUp: cleanUp,
  };

  /**
   * Hide back button when step is active
   */
  function hideBackButton() {
    newElement.displayBackButtonWhenStepIsActive = false;
    return self;
  }

  /**
   * Hide next button when step is active
   */
  function hideNextButton() {
    newElement.displayNextButtonWhenStepIsActive = false;
    return self;
  }

  /**
   * Hide done button when step is active
   */
  function hideDoneButton() {
    newElement.displayDoneButtonWhenStepIsActive = false;
    return self;
  }

  /**
   * Back button can be clicked by user by default
   */
  function backButtonIsActive() {
    newElement.isBackButtonActiveWhenStepIsActive = true;
    return self;
  }

  /**
   * Next button can be clicked by user by default
   */
  function nextButtonIsActive() {
    newElement.isNextButtonActiveWhenStepIsActive = true;
    return self;
  }

  /**
   * Done button can be clicked by user by default
   */
  function doneButtonIsActive() {
    newElement.isDoneButtonActiveWhenStepIsActive = true;
    return self;
  }

  /**
   * Add description that may be displayed to the user in the wizard when the step is active
   * @param {string} d - Add description to step
   */
  function addDescription(d) {
    if (typeof d !== "string") {
      throw "Parameter passed into addDescription is not a string";
    }
    newElement.description = d;
    return self;
  }

  /**
   * Sets the step to active
   */
  function isActive() {
    newElement.isActive = true;
    return self;
  }

  /**
   * Add a CSS class to be added to the container HTML element. To add multiple classes, separate them with a space. Example: 'class1 class2'
   * @param {string} c - name of class to be added to container HTML element. Defaults to '' if value passed in is not a string
   */
  function addCustomContainerCssClass(c) {
    newElement.customContainerCss = typeof c === "string" ? c : "";
    return self;
  }

  /**
   * Add a CSS class to be added to the HTML element. To add multiple classes, separate them with a space. Example: 'class1 class2'
   * @param {string} c - name of class to be added to HTML element. Defaults to '' if value passed in is not a string
   */
  function addCustomCssClass(c) {
    newElement.customCss = typeof c === "string" ? c : "";
    return self;
  }

  /**
   *
   * @param {object} rgi
   */
  function addRadioGroupInput(rgi) {
    newElement.stepIncludesRadioGroupInput = true;
    newElement.radioGroupInput = rgi;
    return self;
  }

  /**
   *
   * @param {object} fif
   */
  function addFileInputForm(fif) {
    newElement.stepIncludesFileInputForm = true;
    newElement.fileInputForm = fif;
    return self;
  }

  /**
   *
   * @param {object[]} ic - Array of items to be displayed in the input container section
   */
  function addInputContainer(ic) {
    if (!Array.isArray(ic)) {
      throw "ic param in addInputContainer should be an array";
    }
    newElement.stepIncludesInputContainerSection = true;
    newElement.inputsToIncludeInContainer = ic;
    return self;
  }

  /**
   *
   * @param {object} m
   */
  function addMenu(m) {
    if (typeof m !== "object" && m !== null) {
      throw "Param m in addMenu function is not a valid object";
    }
    newElement.stepIncludesMenuSection = true;
    newElement.menuToIncludeOnStep = m;
    return self;
  }

  /**
   * Add function to be called when wizard step is activated
   * @param {function} cb - Function to be called
   */
  function activateWizardStepCallback(cb) {
    newElement.activateWizardStepCallback =
      typeof cb === "function" ? cb : null;
    return self;
  }

  /**
   * Function to call when user clicks on the Next button
   * @param {function} cb - Callback function
   */
  function clickOnNextButtonCallbackForStep(cb) {
    if (typeof cb !== "function") {
      throw "clickOnNextButtonCallbackForStep is not a function";
    }
    newElement.clickOnNextButtonCallbackForStep = cb;
    return self;
  }

  /**
   *
   * @param {object} rt
   */
  function addResultsTable(rt) {
    if (typeof rt !== "object" && rt !== null) {
      throw "Param m in addMenu function is not a valid object";
    }
    newElement.stepIncludesResultsTableSection = true;
    newElement.resultsTableToIncludeOnStep = rt;
    return self;
  }

  /**
   * Add validation function to be called prior to moving to next step
   * @param {function} preNextValidation - Function to be called
   */
  function preNextValidationCallback(preNextValidation) {
    newElement.preNextValidationCallback =
      typeof preNextValidation === "function" ? preNextValidation : null;
    return self;
  }

  /**
   * Return ConfigurationWizardStep built object
   * @returns {object}
   */
  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      let breakHere = 0;
      delete newElement[member];
    }
  }

  this.hideBackButton = hideBackButton;
  this.hideNextButton = hideNextButton;
  this.hideDoneButton = hideDoneButton;
  this.backButtonIsActive = backButtonIsActive;
  this.nextButtonIsActive = nextButtonIsActive;
  this.doneButtonIsActive = doneButtonIsActive;
  this.addDescription = addDescription;
  this.isActive = isActive;
  this.addCustomContainerCssClass = addCustomContainerCssClass;
  this.addCustomCssClass = addCustomCssClass;
  this.addRadioGroupInput = addRadioGroupInput;
  this.addFileInputForm = addFileInputForm;
  this.addInputContainer = addInputContainer;
  this.addMenu = addMenu;
  this.activateWizardStepCallback = activateWizardStepCallback;
  this.clickOnNextButtonCallbackForStep = clickOnNextButtonCallbackForStep;
  this.addResultsTable = addResultsTable;
  this.preNextValidationCallback = preNextValidationCallback;
  this.build = build;
  this.cleanUp = cleanUp;
}

/**
 * Used to create object to create ConfigurationWizard
 * @param {[ConfigurationWizardStep]} - Array of ConfigurationWizardSteps
 */
function ConfigurationWizardBuilder(wizardSteps) {
  if (!Array.isArray(wizardSteps)) {
    throw "wizardSteps is not an array";
  }
  let self = this;
  let newElement = {
    wizardSteps: wizardSteps,
    cleanUp: cleanUp,
  };

  /**
   * Adds add checkbox and text to footer beside buttons
   * @param {boolean} [fc=true] - Is checkbox checked by default
   * @param {string} [fct='Text Not Set'] - Text to be displayed beside the checkbox
   */
  function addFooterCheckbox(fc, fct, ft) {
    newElement.displayFooterCheckbox = true;
    newElement.isFooterCheckboxChecked = typeof ft === "boolean" ? ft : true;
    newElement.footerCheckboxText =
      typeof fct === "string" ? fct : config.getTranslationText('4956'); // User Story 17565 Translations 4956
    return self;
  }

  /**
   * Change the text to be displayed on Back button in wozard
   * @param {string} [bbt=config.getTranslationText('2632')] - Text to be displayed on Back button. Defaults to Back
   */
  function updateBackButtonText(bbt) {
    newElement.backButtonText =
      typeof bbt === "string" ? bbt : config.getTranslationText("2632");
    return self;
  }

  /**
   * Change the text to be displayed on Back button in wozard
   * @param {string} [nbt=config.getTranslationText('2631')] - Text to be displayed on Back button. Defaults to Back
   */
  function updateNextButtonText(nbt) {
    newElement.nextButtonText =
      typeof nbt === "string" ? nbt : config.getTranslationText("2631");
    return self;
  }

  /**
   * Change the text to be displayed on Back button in wozard
   * @param {string} [dbt=config.getTranslationText('2633')] - Text to be displayed on Back button. Defaults to Back
   */
  function updateDoneButtonText(dbt) {
    newElement.doneButtonText =
      typeof dbt === "string" ? dbt : config.getTranslationText("2633");
    return self;
  }

  /**
   * Function to call when user clicks on the Back button
   * @param {function} cb - Callback function
   */
  function clickOnBackButtonCallback(cb) {
    if (typeof cb !== "function") {
      throw "clickOnBackButtonCallback is not a function";
    }
    newElement.clickOnBackButtonCallback = cb;
    return self;
  }

  /**
   * Function to call when user clicks on the Next button
   * @param {function} cb - Callback function
   */
  function clickOnNextButtonCallback(cb) {
    if (typeof cb !== "function") {
      throw "clickOnNextButtonCallback is not a function";
    }
    newElement.clickOnNextButtonCallback = cb;
    return self;
  }

  /**
   * Function to call when user clicks on the Done button
   * @param {function} cb - Callback function
   */
  function clickOnDoneButtonCallback(cb) {
    if (typeof cb !== "function") {
      throw "clickOnDoneButtonCallback is not a function";
    }
    newElement.clickOnDoneButtonCallback = cb;
    return self;
  }

  /**
   * Function to call when user clicks on the Cancel button
   * @param {function} cb - Callback function
   */
  function clickOnCancelButtonCallback(cb) {
    if (typeof cb !== "function") {
      throw "clickOnCancelButtonCallback is not a function";
    }
    newElement.clickOnCancelButtonCallback = cb;
    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    for (let member in newElement) {
      let breakHere = 0;
      delete newElement[member];
    }
  }

  this.addFooterCheckbox = addFooterCheckbox;
  this.updateBackButtonText = updateBackButtonText;
  this.updateNextButtonText = updateNextButtonText;
  this.updateDoneButtonText = updateDoneButtonText;
  this.clickOnBackButtonCallback = clickOnBackButtonCallback;
  this.clickOnNextButtonCallback = clickOnNextButtonCallback;
  this.clickOnDoneButtonCallback = clickOnDoneButtonCallback;
  this.clickOnCancelButtonCallback = clickOnCancelButtonCallback;
  this.build = build;
  this.cleanUp = cleanUp;
}

const WizardBuilderHelper = {
  DatagridColumnBuilder: DatagridColumnBuilder,
  ToolbarBuilder: ToolbarBuilder,
  ToolbarButtonBuilder: ToolbarButtonBuilder,
  CustomBreadcrumbObjectBuilder: CustomBreadcrumbObjectBuilder,
  CustomBreadcrumbBuilder: CustomBreadcrumbBuilder,
  TabFilterObjectBuilder: TabFilterObjectBuilder,
  StepTabBuilder: StepTabBuilder,
  SelectionWizardStepBuilder: SelectionWizardStepBuilder,
  SelectionWizardBuilder: SelectionWizardBuilder,
  ConfigurationWizardStepBuilder: ConfigurationWizardStepBuilder,
  ConfigurationWizardBuilder: ConfigurationWizardBuilder,
};

export default WizardBuilderHelper;
