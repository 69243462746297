<template>
    <div class="override-updownbox-div override-menuInputGroup-upDownBoxAndSelect-div"
        :class="inputGroup.customCss"
        data-bind="template: { name: 'template-menu-menuInputGroup-upDownBoxAndSelect-element' }, css: customCss">
        <div v-if="displayOverride()" 
            class="override-updownbox-checkbox"
            @click.stop="inputGroup.upDownBox.toggleOverride"
            :id="inputGroup.upDownBox.id"
            :title="inputGroup.upDownBox.override.value === false ? inputGroup.upDownBox.overrideTooltipMessage: ''"
            :class="{'override-false': inputGroup.upDownBox.override.value === false }"
            data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: {id: id, title: override() === false ? overrideTooltipMessage: '' }"
            data-toggle="tooltip">
            <i class="far fa-lg"
                :class="{ 'fa-check-square': inputGroup.upDownBox.override.value === true, 'fa-square': inputGroup.upDownBox.override.value === false }"
                data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
        </div>
        <div @click="upDownBoxDivClick"
            data-bind="click: function() { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false ) { toggleOverride()}  }, touchstart: function () { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false) {  toggleOverride() } }">
            <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel"
                v-model.lazy="inputGroup.upDownBox.value.value"
                @click="upDownBoxClick"
                @blur="inputGroup.upDownBox.revertErrorValueOnBlur"
                @focus="upDownBoxFocus"
                @keyup.enter="handleEnterKey"
                :id="inputGroup.upDownBox.id"
                :title="inputGroup.upDownBox.override.value === false ? inputGroup.upDownBox.overrideTooltipMessage : inputGroup.upDownBox.errorMessageObv.value"
                :data-original-title="inputGroup.upDownBox.errorMessageObv.value"
                :class="{'override-false': addOverrideFalseClass(), 'override-cursor': addOverrideCursorClass() }"
                :readonly="isInputDisabled()"
                data-bind="value: value, click: isMac === true ? selectAllText : null,disable: override() === false || permissionObv().readOnly === true, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, title: override() === false ? overrideTooltipMessage : errorMessageObv, 'data-original-title': errorMessageObv}, css: {'override-false': override() === false || permissionObv().readOnly === true, 'override-cursor' : override() === false && permissionObv().readOnly === false}"
                data-toggle="tooltip" />
        </div>
        <div class="menu-select-div" data-bind="with: select">
            <select :id="inputGroup.select.id" :title="inputGroup.select.errorMessageObv.value"
                :data-original-title="inputGroup.select.errorMessageObv.value"
                v-model="inputGroup.select.valueObv.value"
                data-toggle="tooltip">
                <option v-for="option in inputGroup.select.displayArray.value" :key="option" :value="option.id">
                    {{ option.description }}</option>
            </select>
        </div>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: inputType === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }"></div>
        /ko
        ko if: inputType === $root.config.INPUT_TYPE.UPDOWNBOX
        <div class="override-updownbox-div" data-bind="template: { name: 'template-menu-upDownBox-element' }, css: customCss"></div>
        /ko
        ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP
        ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU
        <div class="override-updownbox-div override-menuInputGroup-upDownBoxAndSelect-div" data-bind="template: { name: 'template-menu-menuInputGroup-upDownBoxAndSelect-element' }, css: customCss"></div>
        /ko
        /ko
    </script> -->

    <!-- <script type="text/html" id="template-menu-menuInputGroup-upDownBoxAndSelect-element">
            ko with: upDownBox
            ko if: permissionObv().readOnly === false && permissionObv().override === true
            <div class="override-updownbox-checkbox"
            data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: {id: id, title: override() === false ? overrideTooltipMessage: '' }"
            data-toggle="tooltip">
                <i class="far fa-square fa-lg"
                data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
            </div>
            /ko
            <div data-bind="click: function() { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false ) { toggleOverride()}  }, touchstart: function () { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false) {  toggleOverride() } }">
                <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel"
                    data-bind="value: value, click: isMac === true ? selectAllText : null,disable: override() === false || permissionObv().readOnly === true, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, title: override() === false ? overrideTooltipMessage : errorMessageObv, 'data-original-title': errorMessageObv}, css: {'override-false': override() === false || permissionObv().readOnly === true, 'override-cursor' : override() === false && permissionObv().readOnly === false}"
                    data-toggle="tooltip" />
            </div>
            /ko
            <div class="menu-select-div" data-bind="with: select">
                <select data-bind="options: displayArray, optionsText: 'description', optionsValue: 'id', value: valueObv, attr: {id: id, title: errorMessageObv, 'data-original-title': errorMessageObv}"
                    data-toggle="tooltip"></select>
            </div>
    </script> -->
</template>

<script>
import { toRef, nextTick, isRef } from 'vue';

const $ = window.$;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const inputGroup = toRef(props, "valueFromParent");

        function upDownBoxDivClick() {
            if(inputGroup.value.permissionObv.value.readOnly === false && inputGroup.value.permissionObv.value.override === true && inputGroup.value.override.value === false ) { 
                inputGroup.value.toggleOverride()
            }
        }

        function upDownBoxClick() {
            if(inputGroup.value.upDownBox.isMac === true) {
                inputGroup.value.upDownBox.selectAllText();
            }
        }

        function upDownBoxFocus() {
            if(inputGroup.value.upDownBox.isMac === false) {
                inputGroup.value.upDownBox.selectAllText();
            }
        }

        function isInputDisabled() {
            return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.value.readOnly === true;
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + inputGroup.value.upDownBox.id).val(inputGroup.value.upDownBox.value.value);
            });
        }

        function displayOverride(){
            if(isRef(inputGroup.value.upDownBox.permissionObv)) {
                return inputGroup.value.upDownBox.permissionObv.value.readOnly === false && inputGroup.value.upDownBox.permissionObv.value.override === true;
            } else {
                return inputGroup.value.upDownBox.permissionObv.readOnly === false && inputGroup.value.upDownBox.permissionObv.override === true;
            }
        }

        function addOverrideFalseClass() {
            if(isRef(inputGroup.value.upDownBox.permissionObv)) {
                return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.value.readOnly === true;
            } else {
                return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.readOnly === true;
            }
        }

        function addOverrideCursorClass() {
            if(isRef(inputGroup.value.upDownBox.permissionObv)) {
                return inputGroup.value.upDownBox.override.value === false && inputGroup.value.upDownBox.permissionObv.value.readOnly === false;
            } else {
                return inputGroup.value.upDownBox.override.value === false && inputGroup.value.upDownBox.permissionObv.readOnly === false;
            }
        }

        return {
            inputGroup,
            upDownBoxDivClick,
            upDownBoxClick,
            upDownBoxFocus,
            isInputDisabled,
            handleEnterKey,
            displayOverride,
            addOverrideFalseClass,
            addOverrideCursorClass
        }
    }
}
</script>

<style lang="scss" scoped>

</style>