import config from '../../services/config';
import OpEngine from '../undo/opEngine';
// import router from '@/router';

function UndoHandler(offerManager, undoTracker, redoTracker) {

    var _opStack = [];
    var opEngine = new OpEngine(offerManager);
    var preventOpCreation = false;
    var blockOpCreationFlag = false;
    var opLockPath = null;
    var undoStackDfd = null, undoStackDfdLockPath = null;

    function addOp(newOp) {
        // newOp.addModuleTitle(router.activeItem().title);
        newOp.addInitialMeasurementSystemId(offerManager.activeMeasurementSystem.value.id);
        clearRedoStack();
        _opStack.push(newOp);
    }
    function removeOp(opToRemove) {
        var indexOfOpToRemove = _opStack.map(function (op) { return op.getId(); }).indexOf(opToRemove.getId());
        _opStack.splice(indexOfOpToRemove, 1);
    }

    function undo() {
        
        var curUndoOp = getCurUndoOp();
        if (curUndoOp !== null) {
            var opExecutionSuccessful = opEngine.executeOp(curUndoOp, 'UNDO');
            if (opExecutionSuccessful) {
                // undoTracker(undoTracker() - 1);
                // redoTracker(redoTracker() + 1);
                undoTracker.value = undoTracker.value - 1;
                redoTracker.value = redoTracker.value + 1;
                curUndoOp.setUndone(true);
            }
        }
    }

    function getCurUndoOp() {

        var curUndoOp = null;
        for (var i = _opStack.length - 1; i >= 0; i--) {
            if (_opStack[i].getUndone() === false) {
                curUndoOp = _opStack[i];
                break;
            }
        }
        return curUndoOp;
    }

    function redo() {
        
        var curRedoOp = getCurRedoOp();
        if (curRedoOp !== null) {
            
            var opExecutionSuccessful = opEngine.executeOp(curRedoOp, 'REDO');
            if (opExecutionSuccessful) {
                // redoTracker(redoTracker() - 1);
                redoTracker.value = redoTracker.value - 1;
                // undoTracker(undoTracker() + 1);
                undoTracker.value = undoTracker.value + 1;
                curRedoOp.setUndone(false);
            }
            
        }
    }

    function getCurRedoOp() {

        var curRedoOp = null;
        for (var i = 0; i < _opStack.length; i++) {
            if (_opStack[i].getUndone()) {
                curRedoOp = _opStack[i];
                break;
            }
        }
        return curRedoOp;
    }

    function clearRedoStack() {
        // redoTracker(0);
        redoTracker.value = 0;
        var firstRedoOpIndex = -1;

        for (var i = 0; i < _opStack.length; i++) {
            if (_opStack[i].getUndone()) {
                firstRedoOpIndex = i;
                break;
            }
        }

        if (firstRedoOpIndex !== -1) {
            _opStack.splice(firstRedoOpIndex, _opStack.length - firstRedoOpIndex + 1);
        }

    }

    function clearUndoStack() {
        _opStack = [];
        // undoTracker(0);
        // redoTracker(0);
        undoTracker.value = 0;
        redoTracker.value = 0;
    }

    function setUndoStackDfd(dfd, path) {
        undoStackDfd = dfd;
        undoStackDfdLockPath = path;
    }

    function resolveUndoStackDfd(path) {
        if (undoStackDfdLockPath && undoStackDfdLockPath === path && undoStackDfd !== null) {
            undoStackDfd.resolve();
            //undoStackDfd = null;
            //undoStackDfdLockPath = null;
        }
    }

    function getUndoStackForObject(path) {
        var undoStackForObject = [];
        var rigOps = offerManager.getRigOps();
        for(var i = _opStack.length-1; i >= 0; i--) {

            var curOp = _opStack[i];
            if (curOp.getPath() === path && (curOp.getType() === config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT || curOp.getType() === config.CALCULATION_OP_TYPES.ADD_WAYPOINT || curOp.getType() === config.CALCULATION_OP_TYPES.ADD_AXLE)) {
                break;
            }else {
                if (curOp.getPath().indexOf(path) !== -1) {
                    undoStackForObject.unshift(curOp);
                } else if (rigOps.getAccessoryTypeFromPath(path) === config.ACCESSORY_TYPES.BODY && rigOps.getChassisTypeUsingPath(path) === rigOps.getChassisTypeUsingPath(curOp.getPath()) &&
                    (rigOps.getAccessoryTypeFromPath(curOp.getPath()) === config.ACCESSORY_TYPES.TAILLIFT || rigOps.getAccessoryTypeFromPath(curOp.getPath()) === config.ACCESSORY_TYPES.FRIDGE ||
                    rigOps.getAccessoryTypeFromPath(curOp.getPath()) === config.ACCESSORY_TYPES.PAYLOAD)) {
                    undoStackForObject.unshift(curOp);
                } else if (rigOps.getChassisTypeUsingPath(path) === config.CHASSIS_OBJECT_TYPES.TRAILER && rigOps.getChassisTypeUsingPath(curOp.getPath()) === config.CHASSIS_OBJECT_TYPES.TRAILER) {
                    undoStackForObject.unshift(curOp);
                }
            }
            
        }
        return undoStackForObject;
    }

    function getLinkedOpMainOp(linkedPath) {
        var ops = _opStack.filter(function (op) {
            return op.getPath() === linkedPath;
        });
        if (ops.length > 0) {
            return ops[ops.length - 1];
        }
        return null;
    }

    function updateOpDefunctObjectInternalId(curId, newId, originatingOpPath) {
        _opStack.forEach(function (op) {
            if (op.getType() === config.CALCULATION_OP_TYPES.CHANGE_VALUE && op.getPath().indexOf(originatingOpPath) !== -1) {
                op.addObjectInternalId(newId);
                if (op.getPath().indexOf('.' + curId + '.') !== -1) {
                    op.setPath(op.getPath().replace('.' + curId + '.', '.' + newId + '.'))
                } else if (op.getPath().indexOf('.' + curId) !== -1) {
                    op.setPath(op.getPath().replace('.' + curId, '.' + newId))
                }

                if (op.getLinkedOp()) {
                    op.getLinkedOp().addObjectInternalId(newId);
                    if (op.getLinkedOp().getPath().indexOf('.' + curId + '.') !== -1) {
                        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId + '.', '.' + newId + '.'))
                    } else if (op.getLinkedOp().getPath().indexOf('.' + curId) !== -1) {
                        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId, '.' + newId))
                    }
                }
            }else if (op.getObjectInternalId() === curId) {
                op.addObjectInternalId(newId);
                if (op.getPath().indexOf('.' + curId + '.') !== -1) {
                    op.setPath(op.getPath().replace('.' + curId + '.', '.' + newId + '.'))
                } else if (op.getPath().indexOf('.' + curId) !== -1) {
                    op.setPath(op.getPath().replace('.' + curId, '.' + newId))
                }
                if (op.getLinkedOp()) {
                    op.getLinkedOp().addObjectInternalId(newId);
                    if (op.getLinkedOp().getPath().indexOf('.' + curId + '.') !== -1) {
                        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId + '.', '.' + newId + '.'))
                    } else if (op.getLinkedOp().getPath().indexOf('.' + curId) !== -1) {
                        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId, '.' + newId))
                    }
                }
            } else if (op.getType() === config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT && (op.getPath().indexOf(originatingOpPath) !== -1 && op.getObjectId() === curId)) {
                op.addObjectId(newId);
            } else if (op.getType() === config.CALCULATION_OP_TYPES.CHANGE_CHECKBOX && op.getPath().indexOf(originatingOpPath + '.' + curId) !== -1) {
                //op.addObjectInternalId(newId);
                if (op.getPath().indexOf('.' + curId + '.') !== -1) {
                    op.setPath(op.getPath().replace('.' + curId + '.', '.' + newId + '.'))
                } else if (op.getPath().indexOf('.' + curId) !== -1) {
                    op.setPath(op.getPath().replace('.' + curId, '.' + newId))
                }

                //if (op.getLinkedOp()) {
                //    op.getLinkedOp().addObjectInternalId(newId);
                //    if (op.getLinkedOp().getPath().indexOf('.' + curId + '.') !== -1) {
                //        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId + '.', '.' + newId + '.'))
                //    } else if (op.getLinkedOp().getPath().indexOf('.' + curId) !== -1) {
                //        op.getLinkedOp().setPath(op.getLinkedOp().getPath().replace('.' + curId, '.' + newId))
                //    }
                //}
            }
        });
    }

    function Op(opType, path) {

        var self = this;

        self._undone = false;
        self.type = opType;
        self.path = path;
        
        self.addUndoValue = function (undoValue) {
            self.undoValue = undoValue;
            return self;
        }

        self.addRedoValue = function (redoValue) {
            self.redoValue = redoValue;
            return self;
        }

        self.addObjectId = function (objectId) {
            self.objectId = objectId;
            return self;
        }

        self.addObjectSource = function (objectSource) {
            self.objectSource = objectSource;
            return self;
        }

        self.addObjectAccessoryType = function (accessoryType) {
            self.objectAccessoryType = accessoryType;
            return self;
        }

        self.addObjectAccessLevel = function (accessLevel) {
            self.objectAccessLevel = accessLevel;
            return self;
        }

        self.addObjectUndoStack = function (objectUndoStack) {
            self.objectUndoStack = objectUndoStack;
            return self;
        }

        self.addLinkedPath = function (linkedPath) {
            self.linkedPath = linkedPath;
            return self;
        }

        self.addLinkedOp = function (linkedOp) {
            self.linkedOp = linkedOp;
            return self;
        }

        self.addObjectInternalId = function (internalId) {
            self.objectInternalId = internalId;
            return self;
        }

        self.addAxleType = function (axleType) {
            self.axleType = axleType;
            return self;
        }

        self.addAxleNumber = function (axleNumber) {
            self.axleNumber = axleNumber;
            return self;
        }

        self.addCheckboxType = function (checkboxType) {
            self.checkboxType = checkboxType;
            return self;
        }

        self.addWaypointDescription = function (description) {
            self.waypointDescription = description;
            return self;
        }

        self.addWaypointLocation = function (location) {
            self.waypointLocation = location;
            return self;
        }

        self.addWaypointMenuHrefValue = function (hrefValue) {
            self.waypointMenuHrefValue = hrefValue;
            return self;
        }

        self.addCreateMethodCallback = function (createFunc) {
            self.createFunc = createFunc;
            return self;
        }

        self.addWaypointDirectionOfMovement = function (directionOfMovement) {
            self.waypointDirectionOfMovement = directionOfMovement;
            return self;
        }

        self.addValueLinkedSliderId = function (linkedSliderId) {
            self.valueLinkedSliderId = linkedSliderId;
            return self;
        }

        self.addUndoValuesObject = function (undoValuesObject) {
            self.undoValuesObject = undoValuesObject;
            return self;
        }

        self.addRedoValuesObject = function (redoValuesObject) {
            self.redoValuesObject = redoValuesObject;
            return self;
        }

        self.addModuleTitle = function (moduleTitle) {
            self.moduleTitle = moduleTitle;
            return self;
        }

        self.addUnitType = function (unitType) {
            self.unitType = unitType;
            return self;
        }

        self.addCustomUnitType = function (unitType) {
            self.customUnitType = unitType;
            return self;
        }

        self.addCustomFormattingRuleFunc = function (customFormattingRuleFunc) {
            self.customFormattingRuleFunc = customFormattingRuleFunc;
            return self;
        }

        self.addIsFromDraggingFlag = function () {
            self.isFromDragging = true;
            return self;
        }

        self.addInitialMeasurementSystemId = function (measurementSystemId) {
            self.initialMeasurementSystemId = measurementSystemId;
            return self;
        }

        self.getType = function() {
            return self.type;
        }

        self.getPath = function () {
            return self.path;
        }

        self.setPath = function (path) {
            self.path = path;
        }

        self.getUndoValue = function() {
            return self.undoValue;
        }

        self.getRedoValue = function() {
            return self.redoValue;
        }

        self.getUndone = function() {
            return self._undone;
        }

        self.setUndone = function(newValue) {
            self._undone = newValue;
        }

        self.getObjectId = function () {
            return self.objectId;
        }

        self.getObjectSource = function () {
            return self.objectSource;
        }

        self.getObjectAccessoryType = function () {
            return self.objectAccessoryType;
        }

        self.getObjectAccessLevel = function () {
            return self.objectAccessLevel;
        }

        self.getObjectUndoStack = function () {
            return self.objectUndoStack;
        }

        self.getLinkedOp = function () {
            return self.linkedOp;
        }

        self.getObjectInternalId = function () {
            return self.objectInternalId;
        }

        self.getAxleType = function () {
            return self.axleType;
        }

        self.getAxleNumber = function () {
            return self.axleNumber;
        }

        self.getCheckboxType = function () {
            return self.checkboxType;
        }

        self.getWaypointDescription = function () {
            return self.waypointDescription;
        }

        self.getWaypointLocation = function () {
            return self.waypointLocation;
        }

        self.getWaypointMenuHrefValue = function () {
            return self.waypointMenuHrefValue;
        }

        self.getCreateMethod = function() {
            return self.createFunc;
        }

        self.getWaypointDirectionOfMovement = function () {
            return self.waypointDirectionOfMovement;
        }

        self.getValueLinkedSliderId = function () {
            return self.valueLinkedSliderId;
        }

        self.getUndoValuesObject = function () {
            return self.undoValuesObject;
        }

        self.getRedoValuesObject = function () {
            return self.redoValuesObject;
        }

        self.getModuleTitle = function () {
            return self.moduleTitle;
        }

        self.getUnitType = function () {
            return self.unitType;
        }

        self.getCustomUnitType = function () {
            return self.customUnitType;
        }

        self.getCustomFormattingRuleFunc = function () {
            return self.customFormattingRuleFunc;
        }

        self.getIsFromDragging = function () {
            return self.isFromDragging;
        }

        self.getInitialMeasurementSystemId = function () {
            return self.initialMeasurementSystemId;
        }
    }

    

    function newChangeValueOp(path, oldValue, newValue, unitType) {
        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            var unitTypeToUse = unitType !== undefined ? unitType : config.INCREMENT_TYPE.LENGTH;
            var newOp = new Op(config.CALCULATION_OP_TYPES.CHANGE_VALUE, path).addUndoValue(oldValue).addRedoValue(newValue).addUnitType(unitTypeToUse);
            addOp(newOp);
            return newOp;
        }
        return null;
    }

    function newCompoundChangeValueOp(path, undoValuesObject, redoValuesObject) {
        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            var newOp = new Op(config.CALCULATION_OP_TYPES.COMPOUND_CHANGE_VALUE, path).addUndoValuesObject(undoValuesObject).addRedoValuesObject(redoValuesObject);
            addOp(newOp);
            return newOp;
        }
        return null;
    }

    function newChangeSelectOp(path, oldValue, newValue) {
        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            addOp(new Op(config.CALCULATION_OP_TYPES.CHANGE_SELECT, path).addUndoValue(oldValue).addRedoValue(newValue));
        }
    }

    function newChangeCheckboxOp(path, oldValue, newValue, checkboxType) {
        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            addOp(new Op(config.CALCULATION_OP_TYPES.CHANGE_CHECKBOX, path).addUndoValue(oldValue).addRedoValue(newValue).addCheckboxType(checkboxType));
        }
    }

    function newLinkedChangeValueOp(linkedPath, path, oldValue, newValue, unitType) {
        if (preventOpCreation === false) {
            var unitTypeToUse = unitType !== undefined ? unitType : config.INCREMENT_TYPE.LENGTH;
            var mainOp = getLinkedOpMainOp(linkedPath);
            // var linkedOp = new Op(config.CALCULATION_OP_TYPES.CHANGE_VALUE, path).addUndoValue(oldValue).addRedoValue(newValue).addModuleTitle(router.activeItem().title).addUnitType(unitTypeToUse);
            var linkedOp = new Op(config.CALCULATION_OP_TYPES.CHANGE_VALUE, path).addUndoValue(oldValue).addRedoValue(newValue).addUnitType(unitTypeToUse);
            linkedOp.addInitialMeasurementSystemId(offerManager.activeMeasurementSystem.value.id);
            mainOp.addLinkedOp(linkedOp);
            return linkedOp;
        }
        return null;
    }

    function newAddRigObjectOp(path, addedObject) {
        if (preventOpCreation === false) {
            var objectSourceDatabaseId = addedObject.getSourceDatabaseId();
            var objectSource = addedObject.getSource();
            var accessoryType = addedObject.getAccessoryType();
            var accessLevel = addedObject.getAccessLevel();
            var objectInternalId = addedObject.getId();
        
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            addOp(new Op(config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT, path).addObjectId(objectSourceDatabaseId).addObjectSource(objectSource).addObjectAccessoryType(accessoryType).addObjectAccessLevel(accessLevel).addObjectInternalId(objectInternalId));
        }
    }

    function newRemoveRigObjectOp(path, addedObject) {
        if (preventOpCreation === false) {
            var objectSourceDatabaseId = addedObject.getSourceDatabaseId();
            var objectSource = addedObject.getSource();
            var accessoryType = addedObject.getAccessoryType();
            var accessLevel = addedObject.getAccessLevel();
            var objectInternalId = addedObject.getId();
        
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;

            var newOp = new Op(config.CALCULATION_OP_TYPES.REMOVE_RIG_OBJECT, path).addObjectId(objectSourceDatabaseId).addObjectSource(objectSource).addObjectAccessoryType(accessoryType).addObjectAccessLevel(accessLevel).addObjectInternalId(objectInternalId);
            var undoStackForObject = getUndoStackForObject(path);
            newOp.addObjectUndoStack(undoStackForObject);
            addOp(newOp);
        }
    }

    function newAddAxleOp(path, addedAxle) {

        if (preventOpCreation === false) {
            var axleType = addedAxle.getType();
            var objectInternalId = addedAxle.getId();
            var axleNumber = addedAxle.getNumber();

            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            addOp(new Op(config.CALCULATION_OP_TYPES.ADD_AXLE, path).addAxleType(axleType).addAxleNumber(axleNumber).addObjectInternalId(objectInternalId));
        }
    }

    function newRemoveAxleOp(path, addedAxle) {

        if (preventOpCreation === false) {
            var axleType = addedAxle.getType();
            var objectInternalId = addedAxle.getId();
            var axleNumber = addedAxle.getNumber();

            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;

            var newOp = new Op(config.CALCULATION_OP_TYPES.REMOVE_AXLE, path).addAxleType(axleType).addAxleNumber(axleNumber).addObjectInternalId(objectInternalId);
            var undoStackForObject = getUndoStackForObject(path);
            newOp.addObjectUndoStack(undoStackForObject);
            addOp(newOp);

        }
    }

    function newAddWaypointOp(path, addedWaypoint, menuHrefValue, createFunc) {
        var location = {
            latitude: addedWaypoint.getLatitude(),
            longitude: addedWaypoint.getLongitude()
        }
        var locationName = addedWaypoint.getDescription();
        var objectInternalId = addedWaypoint.getInternalId();

        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;
            addOp(new Op(config.CALCULATION_OP_TYPES.ADD_WAYPOINT, path).addWaypointDescription(locationName).addWaypointLocation(location).addObjectInternalId(objectInternalId).addWaypointMenuHrefValue(menuHrefValue).addCreateMethodCallback(createFunc));
        }
    }

    function newRemoveWaypointOp(path, addedWaypoint, menuHrefValue, createFunc) {
        var location = {
            latitude: addedWaypoint.getLatitude(),
            longitude: addedWaypoint.getLongitude()
        }
        var locationName = addedWaypoint.getDescription();
        var objectInternalId = addedWaypoint.getInternalId();

        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;

            var newOp = new Op(config.CALCULATION_OP_TYPES.REMOVE_WAYPOINT, path).addWaypointDescription(locationName).addWaypointLocation(location).addObjectInternalId(objectInternalId).addWaypointMenuHrefValue(menuHrefValue).addCreateMethodCallback(createFunc);
            var undoStackForObject = getUndoStackForObject(path);
            newOp.addObjectUndoStack(undoStackForObject);
            addOp(newOp);
        }
    }

    function newChangeWaypointOrderOp(path, waypointInternalId, menuHrefValue, dirOfMovement) {
        
        if (preventOpCreation === false) {
            // undoTracker(undoTracker() + 1);
            undoTracker.value = undoTracker.value + 1;

            addOp(new Op(config.CALCULATION_OP_TYPES.CHANGE_WAYPOINT_ORDER, path).addObjectInternalId(waypointInternalId).addWaypointMenuHrefValue(menuHrefValue).addWaypointDirectionOfMovement(dirOfMovement));
        }
    }

    function disableUndoOpCreation() {
        if (blockOpCreationFlag === false) {
            preventOpCreation = true;
        }
    }

    function enableUndoOpCreation() {
        if (blockOpCreationFlag === false) {
            preventOpCreation = false;
        }
    }

    function blockOpCreationFlagChange(lockPath) {
        if (lockPath) {
            opLockPath = lockPath;
        }
        blockOpCreationFlag = true;
    }

    function unblockOpCreationFlagChange(lockPath) {
        if (lockPath && opLockPath && lockPath === opLockPath) {
            blockOpCreationFlag = false;
            opLockPath = null;
        } else if (!opLockPath) {
            blockOpCreationFlag = false;
        }
        
    }

    this.newAddRigObjectOp = newAddRigObjectOp;
    this.newChangeValueOp = newChangeValueOp;
    this.newCompoundChangeValueOp = newCompoundChangeValueOp;
    this.newChangeSelectOp = newChangeSelectOp;
    this.newChangeCheckboxOp = newChangeCheckboxOp;
    this.newRemoveRigObjectOp = newRemoveRigObjectOp;
    this.newLinkedChangeValueOp = newLinkedChangeValueOp;
    this.newAddAxleOp = newAddAxleOp;
    this.newRemoveAxleOp = newRemoveAxleOp;
    this.newAddWaypointOp = newAddWaypointOp;
    this.newRemoveWaypointOp = newRemoveWaypointOp;
    this.newChangeWaypointOrderOp = newChangeWaypointOrderOp;
    
    this.addOp = addOp;
    this.undo = undo;
    this.redo = redo;
    this.disableUndoOpCreation = disableUndoOpCreation;
    this.enableUndoOpCreation = enableUndoOpCreation;
    this.blockOpCreationFlagChange = blockOpCreationFlagChange;
    this.unblockOpCreationFlagChange = unblockOpCreationFlagChange;
    this.updateOpDefunctObjectInternalId = updateOpDefunctObjectInternalId;
    this.clearUndoStack = clearUndoStack;
    this.setUndoStackDfd = setUndoStackDfd;
    this.resolveUndoStackDfd = resolveUndoStackDfd;

}

export default UndoHandler;

