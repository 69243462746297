import config from './config'
import toastrManager from './toastrManager'
import moment from '../assets/scripts/moment.min';
/**
 * Logger class
 * @class Logger
 */
var logger = {
    log: log
};

export default logger;

function log(message, data, source, showToast, toastOptions, messageType) {
    var msgType = messageType || config.LOG_MESSAGE_TYPE.INFO;
    logIt(message, data, source, showToast, toastOptions, msgType);
}

/**
 * Actual code for logging message
 * @method LogIt
 * @param {type} message
 * @param {type} data
 * @param {type} source
 * @param {type} showToast
 * @param {type} toastType
 */
function logIt(message, data, source, showToast, toastOptions, toastType) {
    source = source ? '[' + source + '] ' : '';

    if (toastType === config.LOG_MESSAGE_TYPE.ERROR) {
        error(message);
        if (showToast) {
            toastrManager.showToastError(message, toastOptions);
        }
    } else {
        if (data) {
            log(source, message, data);
        } else {
            log(source, message);
        }

        if (showToast) {
            toastrManager.showToastInfo(message, toastOptions);
        }
    }

    function log(source, message, data) {
        // console.log(`Log ${format(Date.now(), displayTimeFormat)}: ${source} : ${message}`);
        console.log(`Log ${new Date().toString()}: ${source} : ${message}`);
        // moment(data.Offer.OfferDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');
        if (data) {
        console.log(JSON.stringify(data, null, 2));
        }
    }

    function error(message) {
        console.log(`Error ${new Date().toString()}: ${message}`);
    }
}
