import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, globals, utils } from '@/services';
import BodyUprightHolder from './model.bodyUprightHolder';
import Accessory from './model.accessory';
// import { BodyUprightHolder, Accessory } from '@/model/offer';



var Body = function (id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, type, subframeHeight, substructureHeight, headboardHeight, tailboardHeight,
                    heightType, height, lengthType, length, hingePoint, width, widthType, mass, massOverride, cabGapOverride, massType, headboardThickness, tailboardThickness,
                    roofThickness, sidewallRight, sidewallLeft, material, floorType, cogType, horizontalCog, horizontalCogOverride, payloadCogType, payloadHorizontalCog, tarpCoverMassType,
                    tarpCoverMass, verticalCOG, verticalCogOverride, payloadVerticalCOG, rampLength, rampAngle, rampPlatesProtrusion, rampPlatesHeight, rampPlatesMass, rampPlatesCOGType,
                    rampPlatesHorizontalCOG, rampPlatesHorizontalCOGOverride, rampPlatesVerticalCOG, rampPlatesVerticalCOGOverride, subframeLength, payloadVerticalCOGOverride,
                    lateralCOG, lateralCOGOverride, payloadLateralCOG, payloadLateralCOGOverride, rampPlatesLateralCOG, rampPlatesLateralCOGOverride,
                    bottomOfBodyToTopOfChassis, leftCompartmentWidth, rightCompartmentWidth,
                    verticalCOGType, lateralCOGType, payloadVerticalCOGType, payloadLateralCOGType, rampPlatesVerticalCOGType, rampPlatesLateralCOGType,
                    verticalCOGConfirmed, rampPlatesVerticalCOGConfirmed, payloadVerticalCOGConfirmed, lateralCOGConfirmed, payloadLateralCOGConfirmed, rampPlatesLateralCOGConfirmed,
                    updateCounter, cached, sideDoors, sideDoorType, sideDoorFrontToDoor, sideDoorWidthPerDoor, sideDoorHeight, availableBodyMasses,
    costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, aboveBelowChassis, aboveBelowChassisOverride, subType, fromAxle1Front, fromAxle1FrontOverride) {

    



    //var accessoryType = config.ACCESSORY_TYPES.BODY;
    //#region Private members

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    //this._appDataDatabaseId = appDataDatabaseId;
    this._type = type;
    this._subframeHeight = subframeHeight;
    this._substructureHeight = substructureHeight;
    this._headboardHeight = headboardHeight;
    this._tailboardHeight = tailboardHeight;
    this._heightType = heightType;
    this._height = height;
    this._lengthType = lengthType;
    this._length = length;
    this._hingePoint = hingePoint;
    this._width = width;
    this._widthType = widthType;
    this._mass = mass;
    this._massType = massType;
    this._headboardThickness = headboardThickness;
    this._tailboardThickness = tailboardThickness;
    this._roofThickness = roofThickness;
    this._sidewallRight = sidewallRight;
    this._sidewallLeft = sidewallLeft;
    this._material = material;
    this._floorType = floorType;
    this._cogType = cogType;
    this._horizontalCog = horizontalCog;
    
    this._payloadCogType = payloadCogType;
    this._payloadHorizontalCog = payloadHorizontalCog;
    this._tarpCoverMassType = tarpCoverMassType;
    this._tarpCoverMass = tarpCoverMass;
    this._verticalCOG = verticalCOG;
    
    this._payloadVerticalCOG = payloadVerticalCOG;
    this._sideDoors = sideDoors || false;
    this._sideDoorType = sideDoorType;
    this._sideDoorFrontToDoor = sideDoorFrontToDoor || 0;
    this._sideDoorWidthPerDoor = sideDoorWidthPerDoor || 0;
    this._sideDoorHeight = sideDoorHeight || 0;
    this._bodyUprightHolder = new BodyUprightHolder();
    this._rampLength = rampLength;
    this._rampAngle = rampAngle;
    this._rampPlatesProtrusion = rampPlatesProtrusion;
    this._rampPlatesHeight = rampPlatesHeight;
    this._rampPlatesMass = rampPlatesMass;
    this._rampPlatesCOGType = rampPlatesCOGType || config.COG_TYPES.PERCENTAGE;
    this._rampPlatesHorizontalCOG = rampPlatesHorizontalCOG;
    
    this._rampPlatesVerticalCOG = rampPlatesVerticalCOG;
    
    this._subframeLength = subframeLength;
    this._payloadVerticalCOGOverride = payloadVerticalCOGOverride;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._rampPlatesVerticalCOGConfirmed = rampPlatesVerticalCOGConfirmed;
    this._payloadVerticalCOGConfirmed = payloadVerticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed; 
    this._payloadLateralCOGConfirmed = payloadLateralCOGConfirmed; 
    this._rampPlatesLateralCOGConfirmed = rampPlatesLateralCOGConfirmed;
    
    this._lateralCOG = lateralCOG;
    this._payloadLateralCOG = payloadLateralCOG;
    this._rampPlatesLateralCOG = rampPlatesLateralCOG;

    this._cabGapOverride = cabGapOverride;

    this._bottomOfBodyToTopOfChassis = bottomOfBodyToTopOfChassis;
    this._leftCompartmentWidth = leftCompartmentWidth;
    this._rightCompartmentWidth = rightCompartmentWidth;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    this._payloadVerticalCOGType = payloadVerticalCOGType;
    this._payloadLateralCOGType = payloadLateralCOGType;
    this._rampPlatesVerticalCOGType = rampPlatesVerticalCOGType;
    this._rampPlatesLateralCOGType = rampPlatesLateralCOGType;
    this._availableBodyMasses = Array.isArray(availableBodyMasses) ? availableBodyMasses : [];
    this._dropsideHeight = 0;
    this._aboveBelowChassis = aboveBelowChassis;
    this._aboveBelowChassisOverride = aboveBelowChassisOverride;

    this._subType = subType;

    this._fromAxle1Front = fromAxle1Front || 0;
    this._fromAxle1FrontOverride = fromAxle1FrontOverride || false;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCogOverride = true;
        this._verticalCOGOverride = true;
        this._payloadVerticalCOGOverride = true;
        this._rampPlatesHorizontalCOGOverride = true;
        this._rampPlatesVerticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
        this._payloadLateralCOGOverride = true;
        this._rampPlatesLateralCOGOverride = true;
        
    } else {
        this._horizontalCogOverride = horizontalCogOverride || false;
        this._verticalCOGOverride = verticalCogOverride || false;
        this._rampPlatesHorizontalCOGOverride = rampPlatesHorizontalCOGOverride;
        this._rampPlatesVerticalCOGOverride = rampPlatesVerticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
        this._payloadLateralCOGOverride = payloadLateralCOGOverride;
        this._rampPlatesLateralCOGOverride = rampPlatesLateralCOGOverride;
        
    }

    return this;
    
}
    //#endregion Private members
    
//#region Getters and setters
//Body.prototype.getBodyId = function() {
//    return this._bodyId;
//}
//Body.prototype.setBodyId = function(newValue) {
//    this._bodyId = newValue;
//}

Body.prototype.getType = function() {
    return this._type;
}
Body.prototype.setType = function(newValue) {
    this._type = newValue;
}
Body.prototype.getSubframeHeight = function() {
    return this._subframeHeight;
}
Body.prototype.setSubframeHeight = function(newValue) {
    this._subframeHeight = newValue;
}
Body.prototype.getSubstructureHeight = function() {
    return this._substructureHeight;
}
Body.prototype.setSubstructureHeight = function(newValue) {
    this._substructureHeight = newValue;
}
Body.prototype.getHeadboardHeight = function() {
    return this._headboardHeight;
}
Body.prototype.setHeadboardHeight = function(newValue) {
    this._headboardHeight = newValue;
}
Body.prototype.getTailboardHeight = function() {
    return this._tailboardHeight;
}
Body.prototype.setTailboardHeight = function(newValue) {
    this._tailboardHeight = newValue;
}
Body.prototype.getHeightType = function() {
    return this._heightType;
}
Body.prototype.setHeightType = function(newValue) {
    this._heightType = newValue;
}
Body.prototype.getHeight = function() {
    return this._height;
}
Body.prototype.setHeight = function(newValue) {
    this._height = newValue;
}
Body.prototype.getLengthType = function() {
    return this._lengthType;
}
Body.prototype.setLengthType = function(newValue) {
    this._lengthType = newValue;
}
Body.prototype.getLength = function() {
    return this._length;
}
Body.prototype.setLength = function(newValue) {
    this._length = newValue;
}
Body.prototype.getHingePoint = function() {
    return this._hingePoint;
}
Body.prototype.setHingePoint = function(newValue) {
    this._hingePoint = newValue;
}
Body.prototype.getWidth = function() {
    return this._width;
}
Body.prototype.setWidth = function(newValue) {
    this._width = newValue;
}
Body.prototype.getWidthType = function() {
    return this._widthType;
}
Body.prototype.setWidthType = function(newValue) {
    this._widthType = newValue;
}
Body.prototype.getMass = function() {
    return this._mass;
}
Body.prototype.setMass = function(newValue) {
    this._mass = newValue;
}
Body.prototype.getMassOverride = function () {
    return this._massOverride;
}
Body.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Body.prototype.getCabGapOverride = function () {
    return this._cabGapOverride;
}
Body.prototype.setCabGapOverride = function (newValue) {
    this._cabGapOverride = newValue;
}
Body.prototype.getMassType = function() {
    return this._massType;
}
Body.prototype.setMassType = function(newValue) {
    this._massType = newValue;
}
Body.prototype.getHeadboardThickness = function() {
    return this._headboardThickness;
}
Body.prototype.setHeadboardThickness = function(newValue) {
    this._headboardThickness = newValue;
}
Body.prototype.getTailboardThickness = function() {
    return this._tailboardThickness;
}
Body.prototype.setTailboardThickness = function(newValue) {
    this._tailboardThickness = newValue;
}
Body.prototype.getRoofThickness = function() {
    return this._roofThickness;
}
Body.prototype.setRoofThickness = function(newValue) {
    this._roofThickness = newValue;
}
Body.prototype.getSidewallRight = function() {
    return this._sidewallRight;
}
Body.prototype.setSidewallRight = function(newValue) {
    this._sidewallRight = newValue;
}
Body.prototype.getSidewallLeft = function() {
    return this._sidewallLeft;
}
Body.prototype.setSidewallLeft = function(newValue) {
    this._sidewallLeft = newValue;
}
Body.prototype.getMaterial = function() {
    return this._material;
}
Body.prototype.setMaterial = function(newValue) {
    this._material = newValue;
}
Body.prototype.getFloorType = function() {
    return this._floorType;
}
Body.prototype.setFloorType = function(newValue) {
    this._floorType = newValue;
}
Body.prototype.getCOGType = function() {
    return this._cogType;
}
Body.prototype.setCOGType = function(newValue) {
    this._cogType = newValue;
}
Body.prototype.getHorizontalCOG = function() {
    return this._horizontalCog;
}
Body.prototype.setHorizontalCOG = function(newValue) {
    this._horizontalCog = newValue;
}
Body.prototype.setHorizontalCOGOverride = function(newValue) {
    this._horizontalCogOverride = newValue;
}
Body.prototype.getHorizontalCOGOverride = function() {
    return this._horizontalCogOverride;
}
Body.prototype.getPayloadCOGType = function() {
    return this._payloadCogType;
}
Body.prototype.setPayloadCOGType = function(newValue) {
    this._payloadCogType = newValue;
}
Body.prototype.getPayloadHorizontalCOG = function() {
    return this._payloadHorizontalCog;
}
Body.prototype.setPayloadHorizontalCOG = function(newValue) {
    this._payloadHorizontalCog = newValue;
}
Body.prototype.getTarpCoverMassType = function() {
    return this._tarpCoverMassType;
}
Body.prototype.setTarpCoverMassType = function(newValue) {
    this._tarpCoverMassType = newValue;
}
Body.prototype.getTarpCoverMass = function() {
    return this._tarpCoverMass;
}
Body.prototype.setTarpCoverMass = function(newValue) {
    this._tarpCoverMass = newValue;
}
Body.prototype.getVerticalCOG = function() {
    return this._verticalCOG;
}
Body.prototype.setVerticalCOG = function(newValue) {
    this._verticalCOG = newValue;
}
Body.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Body.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Body.prototype.getPayloadVerticalCOG = function() {
    return this._payloadVerticalCOG;
}
Body.prototype.setPayloadVerticalCOG = function(newValue) {
    this._payloadVerticalCOG = newValue;
}

Body.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Body.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Body.prototype.getRampPlatesVerticalCOGConfirmed = function () {
    return this._rampPlatesVerticalCOGConfirmed;
}
Body.prototype.setRampPlatesVerticalCOGConfirmed = function (newValue) {
    this._rampPlatesVerticalCOGConfirmed = newValue;
}
Body.prototype.getPayloadVerticalCOGConfirmed = function () {
    return this._payloadVerticalCOGConfirmed;
}
Body.prototype.setPayloadVerticalCOGConfirmed = function (newValue) {
    this._payloadVerticalCOGConfirmed = newValue;
}
Body.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Body.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Body.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Body.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Body.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Body.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Body.prototype.getPayloadLateralCOG = function () {
    return this._payloadLateralCOG;
}
Body.prototype.setPayloadLateralCOG = function (newValue) {
    this._payloadLateralCOG = newValue;
}
Body.prototype.getPayloadLateralCOGOverride = function () {
    return this._payloadLateralCOGOverride;
}
Body.prototype.setPayloadLateralCOGOverride = function (newValue) {
    this._payloadLateralCOGOverride = newValue;
}
Body.prototype.getPayloadLateralCOGConfirmed = function () {
    return this._payloadLateralCOGConfirmed;
}
Body.prototype.setPayloadLateralCOGConfirmed = function (newValue) {
    this._payloadLateralCOGConfirmed = newValue;
}
Body.prototype.getRampPlatesLateralCOG = function () {
    return this._rampPlatesLateralCOG;
}
Body.prototype.setRampPlatesLateralCOG = function (newValue) {
    this._rampPlatesLateralCOG = newValue;
}
Body.prototype.getRampPlatesLateralCOGOverride = function () {
    return this._rampPlatesLateralCOGOverride;
}
Body.prototype.setRampPlatesLateralCOGOverride = function (newValue) {
    this._rampPlatesLateralCOGOverride = newValue;
}
Body.prototype.getRampPlatesLateralCOGConfirmed = function () {
    return this._rampPlatesLateralCOGConfirmed;
}
Body.prototype.setRampPlatesLateralCOGConfirmed = function (newValue) {
    this._rampPlatesLateralCOGConfirmed = newValue;
}

//Body.prototype.getGraphic() {
//    return _graphic;
//}
//function setGraphic(newValue) {
//    _graphic = newValue;
//}
Body.prototype.getSideDoors = function() {
    return this._sideDoors;
}
Body.prototype.setSideDoors = function(newValue) {
    this._sideDoors = newValue;

    if (newValue === false) {
        this._sideDoorFrontToDoor = 0;
        this._sideDoorWidthPerDoor = 0;
        this._sideDoorHeight = 0;
    }
}
Body.prototype.getSideDoorType = function() {
    return this._sideDoorType;
}
Body.prototype.setSideDoorType = function(newValue) {
    this._sideDoorType = newValue;
}
Body.prototype.getSideDoorFrontToDoor = function() {
    return this._sideDoorFrontToDoor;
}
Body.prototype.setSideDoorFrontToDoor = function(newValue) {
    this._sideDoorFrontToDoor = newValue;
}
Body.prototype.getSideDoorWidthPerDoor = function() {
    return this._sideDoorWidthPerDoor;
}
Body.prototype.setSideDoorWidthPerDoor = function(newValue) {
    this._sideDoorWidthPerDoor = newValue;
}
Body.prototype.getSideDoorHeight = function() {
    return this._sideDoorHeight;
}
Body.prototype.setSideDoorHeight = function(newValue) {
    this._sideDoorHeight = newValue;
}
Body.prototype.getRampLength = function (newValue) {
    return this._rampLength;
}
Body.prototype.setRampLength = function (newValue) {
    this._rampLength = newValue;
}
Body.prototype.getRampAngle = function (newValue) {
    return this._rampAngle;
}
Body.prototype.setRampAngle = function (newValue) {
    this._rampAngle = newValue;
}
Body.prototype.getRampPlatesProtrusion = function (newValue) {
    return this._rampPlatesProtrusion;
}
Body.prototype.setRampPlatesProtrusion = function (newValue) {
    this._rampPlatesProtrusion = newValue;
}
Body.prototype.getRampPlatesHeight = function (newValue) {
    return this._rampPlatesHeight;
}
Body.prototype.setRampPlatesHeight = function (newValue) {
    this._rampPlatesHeight = newValue;
}
Body.prototype.getRampPlatesMass = function (newValue) {
    return this._rampPlatesMass;
}
Body.prototype.setRampPlatesMass = function (newValue) {
    this._rampPlatesMass = newValue;
}

Body.prototype.getRampPlatesCOGType = function (newValue) {
    return this._rampPlatesCOGType;
}
Body.prototype.setRampPlatesCOGType = function (newValue) {
    this._rampPlatesCOGType = newValue;
}

Body.prototype.getRampPlatesHorizontalCOG = function (newValue) {
    return this._rampPlatesHorizontalCOG;
}
Body.prototype.setRampPlatesHorizontalCOG = function (newValue) {
    this._rampPlatesHorizontalCOG = newValue;
}
Body.prototype.getRampPlatesHorizontalCOGOverride = function () {
    return this._rampPlatesHorizontalCOGOverride;
}
Body.prototype.setRampPlatesHorizontalCOGOverride = function (newValue) {
    this._rampPlatesHorizontalCOGOverride = newValue;
}
Body.prototype.getRampPlatesVerticalCOG = function (newValue) {
    return this._rampPlatesVerticalCOG;
}
Body.prototype.setRampPlatesVerticalCOG = function (newValue) {
    this._rampPlatesVerticalCOG = newValue;
}
Body.prototype.getRampPlatesVerticalCOGOverride = function () {
    return this._rampPlatesVerticalCOGOverride;
}
Body.prototype.setRampPlatesVerticalCOGOverride = function (newValue) {
    this._rampPlatesVerticalCOGOverride = newValue;
}
Body.prototype.getSubframeLength = function () {
    return this._subframeLength;
}
Body.prototype.setSubframeLength = function (newValue) {
    this._subframeLength = newValue;
}
Body.prototype.getPayloadVerticalCOGOverride = function () {
    return this._payloadVerticalCOGOverride;
}
Body.prototype.setPayloadVerticalCOGOverride = function (newValue) {
    this._payloadVerticalCOGOverride = newValue;
}
Body.prototype.getBottomOfBodyToTopOfChassis = function () {
    return this._bottomOfBodyToTopOfChassis;
}
Body.prototype.setBottomOfBodyToTopOfChassis = function (newValue) {
    this._bottomOfBodyToTopOfChassis = newValue;
}
Body.prototype.getLeftCompartmentWidth = function () {
    return this._leftCompartmentWidth;
}
Body.prototype.setLeftCompartmentWidth = function (newValue) {
    this._leftCompartmentWidth = newValue;
}
Body.prototype.getRightCompartmentWidth = function () {
    return this._rightCompartmentWidth;
}
Body.prototype.setRightCompartmentWidth = function (newValue) {
    this._rightCompartmentWidth = newValue;
}
Body.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Body.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Body.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Body.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Body.prototype.getPayloadVerticalCOGType = function () {
    return this._payloadVerticalCOGType;
}
Body.prototype.setPayloadVerticalCOGType = function (newValue) {
    this._payloadVerticalCOGType = newValue;
}
Body.prototype.getPayloadLateralCOGType = function () {
    return this._payloadLateralCOGType;
}
Body.prototype.setPayloadLateralCOGType = function (newValue) {
    this._payloadLateralCOGType = newValue;
}
Body.prototype.getRampPlatesVerticalCOGType = function () {
    return this._rampPlatesVerticalCOGType;
}
Body.prototype.setRampPlatesVerticalCOGType = function (newValue) {
    this._rampPlatesVerticalCOGType = newValue;
}
Body.prototype.getRampPlatesLateralCOGType = function () {
    return this._rampPlatesLateralCOGType;
}
Body.prototype.setRampPlatesLateralCOGType = function (newValue) {
    this._rampPlatesLateralCOGType = newValue;
}
Body.prototype.getAvailableBodyMasses = function () {
    return this._availableBodyMasses;
};
Body.prototype.setAvailableBodyMasses = function (newValue) {
    this._availableBodyMasses = newValue;
};
Body.prototype.getActualLength = function() {

    if (this._lengthType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return this._length;
    } else {
        return this._headboardThickness + this._length + this._tailboardThickness;
    }
}

Body.prototype.getActualWidth = function() {
    //If WidthType = BodyMeasurementTypes.External Then
    //Return Width
    //Else
    //Return Width + SidewallLeft + SidewallRight
    //End If

    if (this._widthType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return this._width;
    } else {
        return this._width + this._sidewallLeft + this._sidewallRight;
    }
}

Body.prototype.getActualInternalWidth = function() {
    if (this._widthType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return this._width - this._sidewallLeft - this._sidewallRight;
    } else {
        return this._width;
    }
}

Body.prototype.getHeightOfRampPlatesProtrudingAboveDeck = function (rampAngle, rampLength, rampPlatesHeight) {
    var rampBelowDeckFloor = getRampBelowDeck(rampAngle, rampLength);
    return rampPlatesHeight - rampBelowDeckFloor;
}

function getRampBelowDeck(rampAngle, rampLength) {
    return ((Math.tan(rampAngle / (180 / Math.PI)) * rampLength));
}

Body.prototype.getActualExternalHeight = function() {
    /*
        If HeightType = BodyMeasurementTypes.External Then

        If BodyType = BodyTemplateTypes.Tipper Or BodyType = BodyTemplateTypes.Dropside Then
            Return SubframeHeight + SubstructureHeight + Math.Max(HeadboardHeight, Height - SubstructureHeight)
        ElseIf BodyType = BodyTemplateTypes.Flatdeck Then
            Return SubframeHeight + SubstructureHeight + Math.Max(HeadboardHeight, TailboardHeight)
        ElseIf BodyType = BodyTemplateTypes.Rollback Then
            Return SubframeHeight + SubstructureHeight + HeadboardHeight
        ElseIf BodyType = BodyTemplateTypes.Timber Then

            Dim maxUprightHeight As Double = 0

            For Each upright As FileLoadingAccessoryBodyUpright In Uprights
                maxUprightHeight = Math.Max(maxUprightHeight, upright.Height)
            Next

            Return SubframeHeight + SubstructureHeight + Math.Max(HeadboardHeight, maxUprightHeight)

        ElseIf BodyType = BodyTemplateTypes.Tanker Then
            Return SubframeHeight + Height
        Else
            'for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            Return SubframeHeight + Height
        End If

    Else

        If BodyType = BodyTemplateTypes.Tipper Or BodyType = BodyTemplateTypes.Dropside Then
            Return SubframeHeight + SubstructureHeight + Math.Max(Height, HeadboardHeight)
        ElseIf BodyType = BodyTemplateTypes.Flatdeck Then
            Return SubframeHeight + SubstructureHeight + Math.Max(HeadboardHeight, TailboardHeight)
        ElseIf BodyType = BodyTemplateTypes.Rollback Then
            Return SubframeHeight + SubstructureHeight + HeadboardHeight
        ElseIf BodyType = BodyTemplateTypes.Timber Then

            Dim maxUprightHeight As Double = 0

            For Each upright As FileLoadingAccessoryBodyUpright In Uprights
                maxUprightHeight = Math.Max(maxUprightHeight, upright.Height)
            Next

            Return SubframeHeight + SubstructureHeight + Math.Max(HeadboardHeight, maxUprightHeight)

        ElseIf BodyType = BodyTemplateTypes.Tanker Then
            Return SubframeHeight + SubstructureHeight + Height
        Else
            'for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            Return SubframeHeight + SubstructureHeight + Height + RoofThickness
        End If

    End If
    */

    if (this._heightType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._subframeHeight + this._substructureHeight + Math.max(this._headboardHeight, this._height - this._substructureHeight);
        } else if (this._type === config.BODY_TYPES.FLATDECK) {
            return this._subframeHeight + this._substructureHeight + Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.ROLLBACK) {
            return this._subframeHeight + this._substructureHeight + this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.TIMBER) {
            return this.getActualExternalHeightForTimber();
        } else if (this._type === config.BODY_TYPES.TANKER) {
            return this._subframeHeight + this._height;
        } else if (this._type === config.BODY_TYPES.BEAVERTAIL) {
            var highestPoint = Math.max(this._headboardHeight, this._height - this._substructureHeight);
            //var highestPoint = Math.max(this._headboardHeight, this._height);
            highestPoint = Math.max(highestPoint, this.getHeightOfRampPlatesProtrudingAboveDeck(this._rampAngle, this._rampLength, this._rampPlatesHeight));

            return this._subframeHeight + this._substructureHeight + highestPoint;
            //return highestPoint;
        } else if (this._type === config.BODY_TYPES.HOOKLIFT) {
            highestPoint = Math.max(this._headboardHeight, this._height - this._substructureHeight);

            return this._subframeHeight + this._substructureHeight + highestPoint;
            //return this._subframeHeight + this._substructureHeight + this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
            return this._height;
        } else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            return this._subframeHeight + this._height;
        }

    } else {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._subframeHeight + this._substructureHeight + Math.max(this._height, this._headboardHeight);
        } else if (this._type === config.BODY_TYPES.FLATDECK) {
            return this._subframeHeight + this._substructureHeight + Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.ROLLBACK) {
            return this._subframeHeight + this._substructureHeight + this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.TIMBER) {

            return this.getActualExternalHeightForTimber();

        } else if (this._type === config.BODY_TYPES.TANKER) {
            return this._subframeHeight + this._substructureHeight + this._height;
        } else if (this._type === config.BODY_TYPES.BEAVERTAIL) {
            highestPoint = Math.max(this._height, this._headboardHeight);
            highestPoint = Math.max(highestPoint, this.getHeightOfRampPlatesProtrudingAboveDeck(this._rampAngle, this._rampLength, this._rampPlatesHeight));

            return this._subframeHeight + this._substructureHeight + highestPoint;
            //return highestPoint + this._subframeHeight + this._substructureHeight + this._bottomOfBodyToTopOfChassis;
        } else if (this._type === config.BODY_TYPES.HOOKLIFT) {
            highestPoint = Math.max(this._headboardHeight, this._height);

            return this._subframeHeight + this._substructureHeight + highestPoint;
            //return this._subframeHeight + this._substructureHeight + this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
            return this._height + this._subframeHeight + this._substructureHeight + this._bottomOfBodyToTopOfChassis;
        } else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            return this._subframeHeight + this._substructureHeight + this._height + this._roofThickness;
        }

    } 
    
    
}



Body.prototype.getRampBelowDeckProtrusion = function () {
    return getRampBelowDeck(this._rampAngle, this._rampLength);
}

Body.prototype.getActualExternalHeightForTimber = function() {
    var maxUprightHeight = 0;

    this._bodyUprightHolder.getBodyUprights().forEach(function (upright) {
        maxUprightHeight = Math.max(maxUprightHeight, upright.getHeight())
    });
    var maxHeight = Math.max(this._headboardHeight, maxUprightHeight);
    maxHeight = Math.max(maxHeight, this._tailboardHeight);
    return this._subframeHeight + this._substructureHeight + maxHeight;
}

Body.prototype.getActualRearDimensionHeight = function() {

    if (this._heightType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._height;
        } else if (this._type === config.BODY_TYPES.FLATDECK || this._type === config.BODY_TYPES.ROLLBACK || this._type === config.BODY_TYPES.TIMBER) {
            return 0;
        } else if (this._type === config.BODY_TYPES.TANKER || this._type === config.BODY_TYPES.SERVICE ) {
            return this._height;
        } else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            return this._height;
        }
    } else {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._height + this._substructureHeight;
        } else if (this._type === config.BODY_TYPES.FLATDECK || this._type === config.BODY_TYPES.ROLLBACK || this._type === config.BODY_TYPES.TIMBER) {
            return 0;
        } else if (this._type === config.BODY_TYPES.TANKER) {
            return this._height + this._substructureHeight;
        } else if (this._type === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.BEAVERTAIL || this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
            return this._height + this._subframeHeight + this._substructureHeight + this._bottomOfBodyToTopOfChassis;
        } else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            return this._substructureHeight + this._height + this._roofThickness;
        }
    }

}

Body.prototype.getActualInternalHeight = function() {

    if (this._heightType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._height - this._substructureHeight;
        } else if (this._type === config.BODY_TYPES.FLATDECK) {
            return Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.ROLLBACK) {
            return this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.TIMBER) {
            return Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.TANKER) {
            return this._height - this._substructureHeight;
        } else if (this._type === config.BODY_TYPES.HOOKLIFT) {
            return Math.max(this._headboardHeight, this._height - this._substructureHeight);
            //return this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.BEAVERTAIL || this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
            return this._height - this._subframeHeight - this._substructureHeight - this._bottomOfBodyToTopOfChassis;
        } /*else if (this._type === config.BODY_TYPES.BEAVERTAIL) {
            var highestPoint = Math.max(this._headboardHeight, this._height - this._substructureHeight);
            //var highestPoint = Math.max(this._headboardHeight, this._height);
            highestPoint = Math.max(highestPoint, this.getHeightOfRampPlatesProtrudingAboveDeck(this._rampAngle, this._rampLength, this._rampPlatesHeight));

            return highestPoint;
            //return highestPoint;
        }*/ else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
            return this._height - this._substructureHeight - this._roofThickness;
        }

    } else {
        if (this._type === config.BODY_TYPES.TIPPER || this._type === config.BODY_TYPES.DROPSIDE) {
            return this._height;
        } else if (this._type === config.BODY_TYPES.FLATDECK) {
            return Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.ROLLBACK) {
            return this._headboardHeight;
        } else if (this._type === config.BODY_TYPES.TIMBER) {
            return Math.max(this._headboardHeight, this._tailboardHeight);
        } else if (this._type === config.BODY_TYPES.TANKER) {
            return this._height;
        } else if (this._type === config.BODY_TYPES.HOOKLIFT) {
            return Math.max(this._headboardHeight, this._height);
            //return this._headboardHeight;
        } /*else if (this._type === config.BODY_TYPES.BEAVERTAIL) {
            var highestPoint = Math.max(this._height, this._headboardHeight);
            highestPoint = Math.max(highestPoint, this.getHeightOfRampPlatesProtrudingAboveDeck(this._rampAngle, this._rampLength, this._rampPlatesHeight));

            return highestPoint;
            //return highestPoint + this._subframeHeight + this._substructureHeight + this._bottomOfBodyToTopOfChassis;
        }*/ else {
            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier, service
            return this._height;
        }
    }

}

Body.prototype.getActualInternalLength = function() {
    //If _LengthType = BodyMeasurementTypes.External Then
    //Return Length - HeadboardThickness - TailboardThickness
    //Else
    //Return Length
    //End If
    if (this._lengthType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return this._length - this._headboardThickness - this._tailboardThickness;//- this._rampLength - this._rampPlatesProtrusion;
    } else {
        return this._length; //- this._rampLength - this._rampPlatesProtrusion;
    }
}
/*
dropside height is only used in the wizard for dropside and beavertail body types. When the wizard is finished dropside height ends up in height
*/
Body.prototype.getDropsideHeight = function () {
    return this._dropsideHeight;
}

Body.prototype.setDropsideHeight = function (newValue) {
    this._dropsideHeight = newValue;
}

Body.prototype.getAboveBelowChassis = function () {
    return this._aboveBelowChassis;
}

Body.prototype.setAboveBelowChassis = function (newValue) {
    this._aboveBelowChassis = newValue;
}

Body.prototype.getAboveBelowChassisOverride = function () {
    return this._aboveBelowChassisOverride;
}

Body.prototype.setAboveBelowChassisOverride = function (newValue) {
    this._aboveBelowChassisOverride = newValue;
}

Body.prototype.getSubType = function () {
    return this._subType;
}

Body.prototype.setSubType = function (newValue) {
    this._subType = newValue;
}

Body.prototype.getFromAxle1Front = function () {
    return this._fromAxle1Front;
}
Body.prototype.setFromAxle1Front = function (newValue) {
    this._fromAxle1Front = newValue;
}
Body.prototype.getFromAxle1FrontOverride = function () {
    return this._fromAxle1FrontOverride;
}
Body.prototype.setFromAxle1FrontOverride = function (newValue) {
    this._fromAxle1FrontOverride = newValue;
}

Body.prototype.getActualMass = function() {
    //If _MassType = BodyMassTypes.Mass Then
    //Return _Mass
    //Else
    //Return _Mass * ActualLength / 1000
    //End If
    if (this._massType === config.MASS_TYPES.MASS) {
        return this._mass + this._rampPlatesMass;
    } else {
        if (this._type === config.BODY_TYPES.HOOKLIFT) {
            return (this.getBodyOnlyMass());
        } else {
            //return (this.getBodyOnlyMass() * this.getActualLength() / 1000) + (this.getBodyOnlyMass() * this._rampLength / 1000) + this._rampPlatesMass;
            return this.getBodyOnlyMass() + this.getRampMass() + this._rampPlatesMass;
        }
    }
}

Body.prototype.getBodyOnlyMass = function () {
    if (this._massType === config.MASS_TYPES.MASSPERDISTANCE) {
        if (this._type === config.BODY_TYPES.HOOKLIFT) {
            return this._subframeLength / 1000 * this._mass;
        } else {
            return (this.getActualLength() / 1000 * this._mass);
        }
        
    } else {
        return 0;
    }
}

Body.prototype.getRampMass = function () {
    if (this._massType === config.MASS_TYPES.MASSPERDISTANCE) {
        return this._mass * this._rampLength / 1000;
    } else {
        return 0;
    }
}

Body.prototype.getActualHorizontalCOG = function() {
    ////If _COGType = ApplicationCOGTypes.Length Then
    ////Return _HorizontalCOG
    ////Else
    ////Return CLng(_HorizontalCOG * ActualLength / 100)
    ////End If

    //if (this._cogType === config.COG_TYPES.LENGTH) {
    //    return this._horizontalCog + this._rampPlatesHorizontalCOG;
    //} else {
    //    return (this._horizontalCog * this.getActualLength() / 100) + (this._rampPlatesHorizontalCOG * (this._rampLength + this._rampPlatesProtrusion) / 100);
    //}
    if (this.getActualMass() > 0) {
        if (this._massType === config.MASS_TYPES.MASS) {
            return (this._mass * this.getBodyPlusRampHorizontalCOG() + this._rampPlatesMass * this.getRampPlatesHorizontalCOGHidden()) / this.getActualMass();
        } else {
            return ((this.getBodyOnlyMass() + this.getRampMass()) * this.getBodyPlusRampHorizontalCOG() + this.getRampPlatesMass() * this.getRampPlatesHorizontalCOGHidden()) / this.getActualMass();// implement kg/m version here
        }
    } else {
        return this.getBodyPlusRampHorizontalCOG();
    }
}

Body.prototype.getBodyPlusRampHorizontalCOG = function () {
    if (this._cogType === config.COG_TYPES.LENGTH) {
        return this._horizontalCog;
    } else {
        if (this._type === config.BODY_TYPES.HOOKLIFT && this.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            return this._horizontalCog * this._subframeLength / 100;
        } else {
            return this._horizontalCog * (this.getActualLength() + this._rampLength) / 100;
        }
    }
    
}

Body.prototype.getRampPlatesHorizontalCOGHidden = function () {
    var retVal;
    var tempActualLength = this.getActualLength();
    if (this._rampPlatesCOGType === config.COG_TYPES.LENGTH) {
        retVal =  tempActualLength + this._rampLength + this._rampPlatesHorizontalCOG;
    } else {
        retVal =  tempActualLength + this._rampLength + (this._rampPlatesHorizontalCOG * this._rampPlatesProtrusion / 100);
    }
    if (retVal === tempActualLength) {
        return 0;
    } else {
        return retVal;
    }
}

Body.prototype.getActualVerticalCOG = function () {
    //If _horizontalCogType = ApplicationCOGTypes.Length Then
    //Return _HorizontalCOG
    //Else
    //Return CLng(_HorizontalCOG * ActualLength / 100)
    //End If

    //if (this._cogType === config.COG_TYPES.LENGTH) {
    //    return this._verticalCOG +this._rampPlatesVerticalCOG;
    //} else {
    //    return (this._verticalCOG * this.getActualExternalHeight() / 100) +(this._rampPlatesVerticalCOG * this.getActualExternalHeight() / 100);
    //}
    if (this.getActualMass() > 0) {
        if (this._massType === config.MASS_TYPES.MASS) {
            return (this._mass * this.getBodyPlusRampVerticalCOG() + this._rampPlatesMass * this.getRampPlatesVerticalCOGHidden()) / this.getActualMass();
        } else {
            return ((this.getBodyOnlyMass() + this.getRampMass()) * this.getBodyPlusRampVerticalCOG() + this.getRampPlatesMass() * this.getRampPlatesVerticalCOGHidden()) / this.getActualMass();// implement kg/m version here
        }
    } else {
        return this.getBodyPlusRampVerticalCOG();
    }
}

Body.prototype.getActualLateralCOG = function () {
    //If _horizontalCogType = ApplicationCOGTypes.Length Then
    //Return _HorizontalCOG
    //Else
    //Return CLng(_HorizontalCOG * ActualLength / 100)
    //End If

    //if (this._cogType === config.COG_TYPES.LENGTH) {
    //    return this._verticalCOG +this._rampPlatesVerticalCOG;
    //} else {
    //    return (this._verticalCOG * this.getActualExternalHeight() / 100) +(this._rampPlatesVerticalCOG * this.getActualExternalHeight() / 100);
    //}
    if (this.getActualMass() > 0) {
        if (this._massType === config.MASS_TYPES.MASS) {
            return (this._mass * this.getBodyPlusRampLateralCOG() + this._rampPlatesMass * this.getRampPlatesLateralCOGHidden()) / this.getActualMass();
        } else {
            return ((this.getBodyOnlyMass() + this.getRampMass()) * this.getBodyPlusRampLateralCOG() + this.getRampPlatesMass() * this.getRampPlatesLateralCOGHidden()) / this.getActualMass();// implement kg/m version here
        }
    } else {
        return this.getBodyPlusRampLateralCOG();
    }
}

//Body.prototype.getActualLateralCOG = function () {
    
//    if (this._cogType === config.COG_TYPES.LENGTH) {
//        return this._lateralCOG;;
//    } else {
//        return (this._lateralCOG * this.getActualWidth() / 100);
//    }
//}


    
Body.prototype.getBodyPlusRampVerticalCOG = function () {
    // Beavertail needs to be treated differently here as it has two COGs requiring different height values to calculate each 
    // but getActualExternalHeight always returns the largest of the three (headbord height, height, portion of ramp plates above deck) 
    // meaning that if the portion of ramp plates height is greater than headboard height or height it will be used for this COG calculation which is incorrect

    
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        if (this.getType() === config.BODY_TYPES.BEAVERTAIL) {
            return this.getActualExternalHeightBodyOnlyBeavertail() * this._verticalCOG / 100;
        } else if (this.getType() === config.BODY_TYPES.HOOKLIFT && this.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            return (this.getHeadboardHeight() + this.getSubframeHeight() + this.getSubstructureHeight()) * this._verticalCOG / 100;
        } else {
            return (this.getActualExternalHeight()) * this._verticalCOG / 100;
        }
    }
}

Body.prototype.getBodyPlusRampLateralCOG = function () {
    // Beavertail needs to be treated differently here as it has two COGs requiring different height values to calculate each 
    // but getActualExternalHeight always returns the largest of the three (headbord height, height, portion of ramp plates above deck) 
    // meaning that if the portion of ramp plates height is greater than headboard height or height it will be used for this COG calculation which is incorrect


    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this.getActualWidth() * this._lateralCOG / 100;
    }
}

Body.prototype.getActualExternalHeightBodyOnlyBeavertail = function() {
    var highestPoint;
    if (this._heightType === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
            highestPoint = Math.max(this._headboardHeight, this._height - this._substructureHeight);
            return this._subframeHeight + this._substructureHeight + highestPoint;
    } else {
            highestPoint = Math.max(this._height, this._headboardHeight);
            return this._subframeHeight + this._substructureHeight + highestPoint;
    }
}

Body.prototype.getRampPlatesVerticalCOGHidden = function () {
    if (this._rampPlatesVerticalCOGType === config.COG_TYPES.LENGTH) {
        return (this._subframeHeight + this._substructureHeight - getRampBelowDeck(this._rampAngle, this._rampLength) - this._rampPlatesProtrusion) + this._rampPlatesVerticalCOG;
    } else {
        return this._subframeHeight + this._substructureHeight - getRampBelowDeck(this._rampAngle, this._rampLength) - this._rampPlatesProtrusion + ((this._rampPlatesHeight * this._rampPlatesVerticalCOG) / 100);
    }
    //if (this._rampPlatesCOGType === config.COG_TYPES.LENGTH) {
    //    return (this._subframeHeight + this._substructureHeight - getRampBelowDeck(this._rampAngle, this._rampLength)) + this._rampPlatesVerticalCOG;
    //} else {
    //    return this._subframeHeight + this._substructureHeight - getRampBelowDeck(this._rampAngle, this._rampLength) + ((this._rampPlatesHeight * this._rampPlatesVerticalCOG) / 100);
    //}
}

Body.prototype.getRampPlatesLateralCOGHidden = function () {
    if (this._rampPlatesLateralCOGType === config.COG_TYPES.LENGTH) {
        return this._rampPlatesLateralCOG;
    } else {
        return this.getActualWidth() * this._rampPlatesLateralCOG / 100;
    }

}

Body.prototype.getActualPayloadHorizontalCOG = function () {
    if (this._payloadCogType === config.COG_TYPES.PERCENTAGE) {
        return this._payloadHorizontalCog * (this.getActualLength() - this.getHeadboardThickness() - this.getTailboardThickness()) / 100;
    } else {
        return this._payloadHorizontalCog;
    }
}

Body.prototype.getActualPayloadVerticalCOG = function () {
    if (this._payloadVerticalCOGType === config.COG_TYPES.PERCENTAGE) {
        if (this._type === config.BODY_TYPES.SERVICE) {
            return this._payloadVerticalCOG * this.getActualExternalHeight() / 100;
        } else {
            return this._payloadVerticalCOG * this.getActualInternalHeight() / 100;
        }
        
    } else {
        return this._payloadVerticalCOG;
    }
}

Body.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this.getActualMass(), distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Body.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() -this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Body.prototype.getTotalLoad = function () {
    return this.getActualMass();
}

    //Body.prototype.getAccessoryType = function() {
    //    return accessoryType;
    //}
    //Body.prototype.getMaxHeightOfPayloadObjects = function() {
    //    var maxHeight = 0;

    //    ko.utils.arrayForEach(this.getPayloadHolder(), function (payloadObject) {
    //        maxHeight = Math.max(maxHeight, payloadObject.getStartZ() + payloadObject.getHeight());
    //    });

    //    return maxHeight;
    //}
Body.prototype.getBodyTypeText = function () {
    return globals.getBodyTypeText(this._type);
}

Body.prototype.getBodyUprightHolder = function () {
    return this._bodyUprightHolder;
}


    //#endregion Getters and setters

Body.prototype.clone = function (keepSameId) {

    var cloneId;
    if (keepSameId && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
}

    var newBody = new Body(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSourceDatabaseId(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._type,
        this._subframeHeight,
        this._substructureHeight,
        this._headboardHeight,
        this._tailboardHeight,
        this._heightType,
        this._height,
        this._lengthType,
        this._length,
        this._hingePoint,
        this._width,
        this._widthType,
        this._mass,
        this._massOverride,
        this._cabGapOverride,
        this._massType,
        this._headboardThickness,
        this._tailboardThickness,
        this._roofThickness,
        this._sidewallRight,
        this._sidewallLeft,
        this._material,
        this._floorType,
        this._cogType,
        this._horizontalCog,
        this._horizontalCogOverride,
        this._payloadCogType,
        this._payloadHorizontalCog,
        this._tarpCoverMassType,
        this._tarpCoverMass,
        this._verticalCOG,
        this._verticalCogOverride,
        this._payloadVerticalCOG,
        this._rampLength,
        this._rampAngle,
        this._rampPlatesProtrusion,
        this._rampPlatesHeight,
        this._rampPlatesMass,
        this._rampPlatesCOGType,
        this._rampPlatesHorizontalCOG,
        this._rampPlatesHorizontalCOGOverride,
        this._rampPlatesVerticalCOG,
        this._rampPlatesVerticalCOGOVerride,
        this._subframeLength,
        this._payloadVerticalCOGOverride,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._payloadLateralCOG,
        this._payloadLateralCOGOverride,
        this._rampPlatesLateralCOG,
        this._rampPlatesLateralCOGOverride,
        this._bottomOfBodyToTopOfChassis,
        this._leftCompartmentWidth,
        this._rightCompartmentWidth,
        this._verticalCOGType,
        this._lateralCOGType,
        this._payloadVerticalCOGType,
        this._payloadLateralCOGType,
        this._rampPlatesVerticalCOGType,
        this._rampPlatesLateralCOGType,
        this._verticalCOGConfirmed,
        this._rampPlatesVerticalCOGConfirmed,
        this._payloadVerticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this._payloadLateralCOGConfirmed,
        this._rampPlatesLateralCOGConfirmed,
        this.getUpdateCounter(),
        this.getCached(),
        this._sideDoors,
        this._sideDoorType,
        this._sideDoorFrontToDoor,
        this._sideDoorWidthPerDoor,
        this._sideDoorHeight,
        this._availableBodyMasses,
        this._costingListPrice,
        this._costingListPriceOverride,
        this._costingDiscount,
        this._costingPeriod,
        this._costingInterestRate,
        this._costingResidualRate,
        this._costingResidualRateType,
        this._aboveBelowChassis,
        this._aboveBelowChassisOverride,
        this._subType,
        this._fromAxle1Front,
        this._fromAxle1FrontOverride
    );

    newBody.setAccessLevel(this.getAccessLevel());
    newBody.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newBody.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    return newBody;
}

Body.prototype.update = function (body) {
    this.updateBase(body);
    this.setType(body.getType());
    this.setSubframeHeight(body.getSubframeHeight());
    this.setSubstructureHeight(body.getSubstructureHeight());
    this.setHeadboardHeight(body.getHeadboardHeight());
    this.setTailboardHeight(body.getTailboardHeight());
    this.setHeightType(body.getHeightType());
    this.setHeight(body.getHeight());
    this.setLengthType(body.getLengthType());
    this.setLength(body.getLength());
    this.setHingePoint(body.getHingePoint());
    this.setWidth(body.getWidth());
    this.setWidthType(body.getWidthType());
    this.setMass(body.getMass());
    this.setMassOverride(body.getMassOverride());
    this.setCabGapOverride(body.getCabGapOverride());
    this.setMassType(body.getMassType());
    this.setHeadboardThickness(body.getHeadboardThickness());
    this.setTailboardThickness(body.getTailboardThickness());
    this.setRoofThickness(body.getRoofThickness());
    this.setSidewallRight(body.getSidewallRight());
    this.setSidewallLeft(body.getSidewallLeft());
    this.setMaterial(body.getMaterial());
    this.setFloorType(body.getFloorType());
    this.setCOGType(body.getCOGType());
    this.setHorizontalCOG(body.getHorizontalCOG());
    this.setHorizontalCOGOverride(body.getHorizontalCOGOverride());
    this.setPayloadCOGType(body.getPayloadCOGType());
    this.setPayloadHorizontalCOG(body.getPayloadHorizontalCOG());
    this.setTarpCoverMassType(body.getTarpCoverMassType());
    this.setTarpCoverMass(body.getTarpCoverMass());
    this.setVerticalCOG(body.getVerticalCOG());
    this.setVerticalCOGOverride(body.getVerticalCOGOverride());
    this.setPayloadVerticalCOG(body.getPayloadVerticalCOG());
    this.setRampLength(body.getRampLength());
    this.setRampAngle(body.getRampAngle());
    this.setRampPlatesProtrusion(body.getRampPlatesProtrusion());
    this.setRampPlatesHeight(body.getRampPlatesHeight());
    this.setRampPlatesMass(body.getRampPlatesMass());
    this.setRampPlatesCOGType(body.getRampPlatesCOGType());
    this.setRampPlatesHorizontalCOG(body.getRampPlatesHorizontalCOG());
    this.setRampPlatesHorizontalCOGOverride(body.getRampPlatesHorizontalCOGOverride());
    this.setRampPlatesVerticalCOG(body.getRampPlatesVerticalCOG());
    this.setRampPlatesVerticalCOGOverride(body.getRampPlatesVerticalCOGOverride());
    this.setSubframeLength(body.getSubframeLength());
    this.setPayloadVerticalCOGOverride(body.getPayloadVerticalCOGOverride());
    this.setLateralCOG(body.getLateralCOG());
    this.setLateralCOGOverride(body.getLateralCOGOverride());
    this.setPayloadLateralCOG(body.getPayloadLateralCOG());
    this.setPayloadLateralCOGOverride(body.getPayloadLateralCOGOverride());
    this.setRampPlatesLateralCOG(body.getRampPlatesLateralCOG());
    this.setRampPlatesLateralCOGOverride(body.getRampPlatesLateralCOGOverride());
    this.setBottomOfBodyToTopOfChassis(body.getBottomOfBodyToTopOfChassis());
    this.setLeftCompartmentWidth(body.getLeftCompartmentWidth());
    this.setRightCompartmentWidth(body.getRightCompartmentWidth());
    this.setVerticalCOGType(body.getVerticalCOGType());
    this.setLateralCOGType(body.getLateralCOGType());
    this.setPayloadVerticalCOGType(body.getPayloadVerticalCOGType());
    this.setPayloadLateralCOGType(body.getPayloadLateralCOGType());
    this.setRampPlatesVerticalCOGType(body.getRampPlatesVerticalCOGType());
    this.setRampPlatesLateralCOGType(body.getRampPlatesLateralCOGType());
    this.setVerticalCOGConfirmed(body.getVerticalCOGConfirmed());
    this.setRampPlatesVerticalCOGConfirmed(body.getRampPlatesVerticalCOGConfirmed());
    this.setPayloadVerticalCOGConfirmed(body.getPayloadVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(body.getLateralCOGConfirmed());
    this.setPayloadLateralCOGConfirmed(body.getPayloadLateralCOGConfirmed());
    this.setRampPlatesLateralCOGConfirmed(body.getRampPlatesLateralCOGConfirmed());
    this.setSideDoors(body.getSideDoors());
    this.setSideDoorType(body.getSideDoorType());
    this.setSideDoorFrontToDoor(body.getSideDoorFrontToDoor());
    this.setSideDoorWidthPerDoor(body.getSideDoorWidthPerDoor());
    this.setSideDoorHeight(body.getSideDoorHeight());
    this.setAboveBelowChassis(body.getAboveBelowChassis());
    this.setAboveBelowChassisOverride(body.getAboveBelowChassisOverride());
    this.setSubType(body.getSubType());
}

/**
 * Get the body JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Remove position values from object.
 * @returns {object}
 */
Body.prototype.getBodyObjectToBeSaved = function (includePositionValues) {
    return {
        //"BodyId": this.getBodyId(),
        //"Name": this.getName(),
        //"Description": this.getDescription(),
        //"Source": this.getSource(),
        "Type": this.getType(),
        "SubframeHeight": this.getSubframeHeight(),
        "SubstructureHeight": this.getSubstructureHeight(),
        "HeadboardHeight": this.getHeadboardHeight(),
        "TailboardHeight": this.getTailboardHeight(),
        "HeightType": this.getHeightType(),
        "Height": this.getHeight(),
        "LengthType": this.getLengthType(),
        "Length": this.getLength(),
        "HingePoint": this.getHingePoint(),
        "WidthType": this.getWidthType(),
        "Width": this.getWidth(),
        "Mass": this.getMass(),
        "MassOverride": this.getMassOverride(),
        "CabGapOverride": this.getCabGapOverride(),
        "MassType": this.getMassType(),
        "HeadboardThickness": this.getHeadboardThickness(),
        "TailboardThickness": this.getTailboardThickness(),
        "RoofThickness": this.getRoofThickness(),
        "SidewallLeft": this.getSidewallLeft(),
        "SidewallRight": this.getSidewallRight(),
        "Material": this.getMaterial(),
        "FloorType": this.getFloorType(),
        "HorizontalCOGType": this.getCOGType(),
        "HorizontalCOG": this.getHorizontalCOG(),
        "HorizontalCOGOverride": this.getHorizontalCOGOverride(),
        "PayloadHorizontalCOGType": this.getPayloadCOGType(),
        "PayloadHorizontalCOG": this.getPayloadHorizontalCOG(),
        "TarpCoverMassType": this.getTarpCoverMassType(),
        "TarpCoverMass": this.getTarpCoverMass(),
        "VerticalCOG": this.getVerticalCOG(),
        "VerticalCOGOverride": this.getVerticalCOGOverride(),
        "PayloadVerticalCOG": this.getPayloadVerticalCOG(),
        //"CabGap": this.getHorizontalGap(),
        //"Graphic": this.getGraphicBlob(),this is in accessory base class
        "SideDoors": this.getSideDoors(),
        "SideDoorType": this.getSideDoorType(),
        "FrontToDoor": this.getSideDoorFrontToDoor(),
        "WidthPerDoor": this.getSideDoorWidthPerDoor(),
        "DoorHeight": this.getSideDoorHeight(),
        "Uprights": this._bodyUprightHolder.getBodyUprightsArrayForSave(),
        "RampLength": this._rampLength,
        "RampAngle": this._rampAngle,
        "RampPlatesProtrusion": this._rampPlatesProtrusion,
        "RampPlatesHeight": this._rampPlatesHeight,
        "RampPlatesMass": this._rampPlatesMass,
        "RampPlatesCOGType": this._rampPlatesCOGType,
        "RampPlatesHorizontalCOG": this._rampPlatesHorizontalCOG,
        "RampPlatesHorizontalCOGOverride": this._rampPlatesHorizontalCOGOverride,
        "RampPlatesVerticalCOG": this._rampPlatesVerticalCOG,
        "RampPlatesVerticalCOGOverride": this._rampPlatesVerticalCOGOverride,
        "SubframeLength": this._subframeLength,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "PayloadLateralCOG": this._payloadLateralCOG,
        "PayloadLateralCOGOverride": this._payloadLateralCOGOverride,
        "RampPlatesLateralCOG": this._rampPlatesLateralCOG,
        "RampPlatesLateralCOGOverride": this._rampPlatesLateralCOGOverride,
        "BottomOfBodyToTopOfChassis": this._bottomOfBodyToTopOfChassis,
        "LeftCompartmentWidth": this._leftCompartmentWidth,
        "RightCompartmentWidth": this._rightCompartmentWidth,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType,
        "PayloadVerticalCOGType": this._payloadVerticalCOGType,
        "PayloadLateralCOGType": this._payloadLateralCOGType,
        "RampPlatesVerticalCOGType": this._rampPlatesVerticalCOGType,
        "RampPlatesLateralCOGType": this._rampPlatesLateralCOGType,
        "AboveBelowChassis": this._aboveBelowChassis,
        "AboveBelowChassisOverride": this._aboveBelowChassisOverride,
        "SubType": this._subType,
        "Axle1FrontToStartOfBody": this._fromAxle1Front,
        "Axle1FrontToStartOfBodyOverride": this._fromAxle1FrontOverride
};
}

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Remove position values from object.
 * @returns {object}
 */
Body.prototype.returnObjectToBeSaved = function (includePositionValues) {
    //return {
    //    "AccessoryType": this.getAccessoryType(),
    //    "AccessoryDetail": this.returnBodyObjectToBeSaved()
    //};
    return this.getSaveObject(this.getBodyObjectToBeSaved(includePositionValues), includePositionValues);
}

//Body.prototype.getIntercomMetadata = function () {
//    var returnObject = {};
//    returnObject.Description = this.getDescription();
//    returnObject.BodyType = this.getType();
//    returnObject.SourceType = this.getSource();
//    return returnObject;
//};

    //function initAccessory() {
    //    self.prototype.constructor(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob);
    //    return self;
    //}
    //#region Initialize


utils.extend(Accessory, Body);

    //utils.inheritsFrom(Body, Accessory);
    //Body.prototype.constructor(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob);

export default Body;

