import config from '../..//services/config';



var Line = function (startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator) {

    var self = this;
    //constructor
    self.startPoint = { x: startX, y: startY };
    self.endPoint = { x: endX, y: endY };
    self.layerNumber = layerNumber;
    self.blockName = blockName;
    self.objNumSeqNum = objNumSeqNum;
    self.closedIndicator = closedIndicator;
    //self.dashEnabled = false
    self.dashEnabled = true;
    self.dashArray = [100, 60];//100, 60
    self.type = 'line';
    if (objNumSeqNum !== undefined) {
        self.objectNumber = parseInt(objNumSeqNum.substring(0, objNumSeqNum.lastIndexOf("_"))),
        self.sequenceNumber = parseInt(objNumSeqNum.substring(objNumSeqNum.lastIndexOf("_") + 1));
    }
    self.drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
        return self;
    }
    self.setDrawingGroupName = setDrawingGroupName;

    if (blockName !== undefined && blockName.indexOf('DASHED') > -1) {
        self.dashEnabled = true;
    } else {
        self.dashEnabled = false;
    }
    
    self.setDashed = function (dashedBool) {
        self.dashEnabled = dashedBool;
    }
    self.setDashLengths = function (dashLength, gapLength) {
        self.dashArray = [dashLength, gapLength];
    }

    self.setLineEndStyle = function (lineEndStyle) {
        self.lineEndStyle = lineEndStyle;
        return self;
    }

    self.setColour = function (colour) {
        self.colour = colour;
    }
    self.bypassHitAreaSort = false;
    self.setBypassHitAreaSort = function (newValue) {
        self.bypassHitAreaSort = newValue;
    }

    self.setDrawingOrder = function (newValue) {
        self.drawingOrder = newValue;
    }

    self.setLineWidth = function (newValue) {
        self.lineWidth = newValue;
    }

    self.setBringToFront = function (newValue) {
        self.bringToFront = newValue;
    }

};

export default Line;


