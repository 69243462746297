<template>
    <MenuTableCellInputSelect v-if="cell.type === INPUT_TYPE.SELECT" :cellObjectFromParent="cell"></MenuTableCellInputSelect>
    <MenuTableCellInputUpDownBox v-if="cell.type === INPUT_TYPE.UPDOWNBOX" :cellObjectFromParent="cell"></MenuTableCellInputUpDownBox>
    <MenuTableCellInputCheckbox v-if="cell.type === INPUT_TYPE.CHECKBOX" :cellObjectFromParent="cell"></MenuTableCellInputCheckbox>
    <MenuTableCellInputTel v-if="cell.type === INPUT_TYPE.TEL" :cellObjectFromParent="cell"></MenuTableCellInputTel>
    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: type === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-settings-select-element' }"></div>
        /ko
        ko if: type === $root.config.INPUT_TYPE.UPDOWNBOX
        <div class="override-updownbox-div" data-bind="template: { name: 'template-settings-upDownBox-element' }, css: customCss"></div>
        /ko
        ko if: type === $root.config.INPUT_TYPE.CHECKBOX
        <div class="settings-checkbox-div" data-bind="template: { name: 'template-settings-checkbox-element' }"></div>
        /ko
        ko if: type === $root.config.INPUT_TYPE.TEL
        <div class="menu-select-div" data-bind="template: { name: 'template-settings-tel-element' }"></div>
        /ko
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';
import MenuTableCellInputSelect from './MenuTableCellInputSelect';
import MenuTableCellInputCheckbox from './MenuTableCellInputCheckbox';
import MenuTableCellInputTel from './MenuTableCellInputTel';
import MenuTableCellInputUpDownBox from './MenuTableCellInputUpDownBox';

export default {
    name: 'MenuTableCellInput',
    props: {
        cellObjectFromParent: Object
    },
    setup (props) {
        const cell = toRef(props, "cellObjectFromParent");

        return {
            cell,
            INPUT_TYPE: config.INPUT_TYPE
        }
    },
    components: { MenuTableCellInputSelect, MenuTableCellInputCheckbox, MenuTableCellInputTel, MenuTableCellInputUpDownBox }
}
</script>

<style lang="scss" scoped>

</style>