<template>
    <th class="tyre-detail-column-axle">
        <span data-bind="text: getTranslationText('2836')">{{getTranslationText('2836')}}</span>
    </th>
    <th class="tyre-detail-column-num-tyres">
        <div>
            <span data-bind="text: getTranslationText('515')">{{getTranslationText('515')}}</span>
        </div>
    </th>
    <th>
        <span data-bind="text: getTranslationText('1171')">{{getTranslationText('1171')}}</span>
    </th>
    <th>
        <span data-bind="text: getTranslationText('1172')">{{getTranslationText('1172')}}</span>
    </th>
    <th class="tyre-detail-column-cost-per-km">
        <span data-bind="text: costPerDistanceText">{{costPerDistanceText}}</span>
    </th>
    <!-- <script type="text/html" id="template-header-tyre-detail">
        <th class="tyre-detail-column-axle">
            <span data-bind="text: getTranslationText('2836')"></span>
        </th>
        <th class="tyre-detail-column-num-tyres">
            <div>
                <span data-bind="text: getTranslationText('515')"></span>
            </div>
        </th>
        <th>
            <span data-bind="text: getTranslationText('1171')"></span>
        </th>
        <th>
            <span data-bind="text: getTranslationText('1172')"></span>
        </th>
        <th class="tyre-detail-column-cost-per-km">
            <span data-bind="text: costPerDistanceText"></span>
        </th>
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

export default {
    name: 'HeaderTyreDetail',
    props: {
        costPerDistanceTextFromParent: String
    },
    setup (props) {
        const costPerDistanceText = toRef(props, 'costPerDistanceTextFromParent');

        return {
            costPerDistanceText,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>