// define(['services/uiInput/ui.input', 'services/utils'], function (Input, utils) {
import Input from "./ui.input";
import utils from "../utils";

import { shallowRef, watchEffect } from 'vue';
//#region RadioGroup
/**
 * Create a group of radio buttons
 * @constructor
 * @param {object} initRadioGroup - Should be created using RadioGroupBuilder
 */
function RadioGroup(initRadioGroup) {
    Input.call(this, initRadioGroup);
    var self = this;

    this._name = initRadioGroup.name;
    this._onChangeCallback = typeof initRadioGroup.onChangeCallback === 'function' ? initRadioGroup.onChangeCallback : null;
    // create options
    this._options = [];
    for (var i = 0; i < initRadioGroup.options.length; i++) {
        this._options.push(new RadioGroupOption(initRadioGroup.options[i]));
    }
    
    if (this.getCheckedOption() !== null) {
        this.checkedOption = shallowRef(this.getCheckedOptionValue());
    } else {
        this.checkedOption = shallowRef();
    }

    // this.checkedOption.subscribe(function (newValue) {
    //     self.setOptionIsChecked(self.checkedOption());
    //     var checkedOption = self.getOptionUsingValue(self.checkedOption());
    //     if (checkedOption !== null) {
    //         checkedOption.updateCustomCssObv();
    //     }
    //     if (self._onChangeCallback !== null) {
    //         self._onChangeCallback(newValue);
    //     }
    // });

    // MIGRATION - Check if this should be a watch
    watchEffect(this.checkedOption, (newValue, oldValue) => {
        self.setOptionIsChecked(self.checkedOption.value);
        var checkedOption = self.getOptionUsingValue(self.checkedOption.value);
        if (checkedOption !== null) {
            checkedOption.updateCustomCssObv();
        }
        if (self._onChangeCallback !== null) {
            self._onChangeCallback(newValue);
        }
    });

    self.updateCheckedOption = function(newValue) {
        self.setOptionIsChecked(self.checkedOption.value);
        var checkedOption = self.getOptionUsingValue(self.checkedOption.value);
        if (checkedOption !== null) {
            checkedOption.updateCustomCssObv();
        }
        if (self._onChangeCallback !== null) {
            self._onChangeCallback(newValue);
        }
    };

    return this;
}

RadioGroup.prototype.getName = function () {
    return this._name;
};

RadioGroup.prototype.getOptions = function () {
    return this._options;
};

RadioGroup.prototype.getCheckedOption = function () {
    return this.getOptions().find(function (option) {
        return option.getIsChecked();
    }) || null;
};

RadioGroup.prototype.getCheckedOptionValue = function () {
    return this.getCheckedOption().getValue();
};

RadioGroup.prototype.cleanUp = function () {
    // clean up options
    this.getOptions().forEach(function (option) {
        option.cleanUp();
    });
    // clean up group
    utils.clearSubscriptions(this.checkedOption);
    this.checkedOption.value = null;
    this.checkedOption = null;
};

RadioGroup.prototype.clearAllOptions = function () {
    this.getOptions().forEach(function (option) {
        option.setIsChecked(false);
        option.updateCustomCssObv();
    });
};

RadioGroup.prototype.getOptionUsingValue = function (value) {
    return this.getOptions().find(function (option) {
        return option.getValue() === value;
    }) || null;
};

RadioGroup.prototype.setOptionIsChecked = function (value, isCheckedValue) {
    var isCheckedValueToUse = typeof isCheckedValue === 'boolean' ? isCheckedValue : true;
    this.clearAllOptions();
    var option = this.getOptionUsingValue(value);
    if (option !== null) {
        option.setIsChecked(isCheckedValueToUse);
    }
};

utils.extend(Input, RadioGroup);
//#endregion RadioGroup

//#region RadioGroupOption
/**
 * Option that can be clicked by the user
 * @constructor
 * @param {object} initRadioGroupOption - Should be created using RadioGroupOptionBuilder
 */
function RadioGroupOption(initRadioGroupOption) {
    Input.call(this, initRadioGroupOption);
    this._value = initRadioGroupOption.value;
    this._isChecked = typeof initRadioGroupOption.isChecked === 'boolean' ? initRadioGroupOption.isChecked : false;
    this._includeIcon = typeof initRadioGroupOption.includeIcon === 'boolean' ? initRadioGroupOption.includeIcon : false;
    this._iconClass = typeof initRadioGroupOption.iconClass === 'string' ? initRadioGroupOption.iconClass : '';

    this.customCssObv = shallowRef(this.getCustomCss());
}

utils.extend(Input, RadioGroupOption);

RadioGroupOption.prototype.getValue = function () {
    return this._value;
};

RadioGroupOption.prototype.getIsChecked = function () {
    return this._isChecked;
};

RadioGroupOption.prototype.setIsChecked = function (newValue) {
    this._isChecked = newValue;
};

RadioGroupOption.prototype.getIncludeIcon = function () {
    return this._includeIcon;
};

RadioGroupOption.prototype.getIconClass = function () {
    return this._iconClass;
};

RadioGroupOption.prototype.getCustomCss = function () {
    if (this._isChecked) {
        return this._customCss + ' active';
    }
    return this._customCss;
};

RadioGroupOption.prototype.updateCustomCssObv = function () {
    this.customCssObv.value = this.getCustomCss();
};

RadioGroupOption.prototype.cleanUp = function () {
    this.customCssObv.value = null;
    this.customCssObv = null;
};
//#endregion RadioGroupOption

export default RadioGroup;
//     return RadioGroup;
// });
