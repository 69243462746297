<template>
    <!-- template-menu -->
    <!-- <div>
        Menu Container
    </div> -->
    <!--
     ko if: getDisplayBreadcrumbButtonsContainer() === true --
    <div class="breadcrumbs-flex-container" data-bind="template: {name: 'template-menu-breadcrumbs', data: $data}"></div>
     /ko --
    <div class="template-menu-content-div" data-bind="template: {name: 'template-menu-content', data: $data}"></div>
     -->
    <MenuBreadcrumbsContainer v-if="menu.getDisplayBreadcrumbButtonsContainer() === true" :menuFromParent="menu"></MenuBreadcrumbsContainer>
    <MenuContent :activeMenuStepFromParent="activeMenuStep"></MenuContent>
</template>

<script>
import { ref, watch, provide, toRef, onUpdated } from 'vue';
import MenuContent from './MenuContent.vue';
import MenuBreadcrumbsContainer from './MenuBreadcrumbsContainer.vue';

export default {
    name: 'MenuContainer',
    props: {
        menuFromParent: Object
    },
    setup(props) {
        // const menu = ref(props.menuFromParent);
        const menu = toRef(props, "menuFromParent");
        const activeMenuStep = ref(null);
        if(menu.value.activeMenuStep.value && menu.value.activeMenuStep.value) {
            activeMenuStep.value = menu.value.activeMenuStep.value;
        }
        provide('fromMenuContainer', menu);

        watch(() => props.menuFromParent,
            (newValue, oldValue) => {
                // menu.value = newValue;
                menu.value = newValue;
                activeMenuStep.value = menu.value.activeMenuStep.value;
            });
        
        onUpdated(() => {
            activeMenuStep.value.updateJsElements();
        });

        return {
            menu,
            activeMenuStep
        }
    },
    components: { MenuContent, MenuBreadcrumbsContainer }
}
</script>

<style lang="scss" scoped>
</style>