<template>
    <div class="accordion" data-bind="template: { name: 'template-user-settings-accordion-tab', foreach: tabs}">
        <template v-for="tab in tabs" :key="tab">
            <h3 :id="tab.id" data-bind="attr: {id: id}">
                <div class="fill-flex-div-row flex-space-between">
                    <div class="fill-flex-div-row waypoint-description">
                        <img class="accordion-logo-image" 
                            :src="tab.logoImageSrc"
                            :alt="tab.alternateText"
                            data-bind="attr: { src: logoImageSrc, alt: alternateText } " />
                        <div v-if="tab.displayText === true" class="waypoint-description-label">
                            <span data-bind="text: label">{{tab.label}}</span>
                        </div>
                    </div>
                    <div class="accordion-buttons">
                        <!-- <div class="breadcrumb-button-i-div">
                            <i :class="{'increment-div-inactive': index === 0}"
                                class="fa fa-chevron-circle-up fa-lg" 
                                @click.stop="moveUpFunction(tab, index)"
                                data-bind="click: $parent.moveUpFunction, touchstart: $parent.moveUpFunction, css: { 'increment-div-inactive': $index() === 0 }"></i>
                        </div>
                        <div class="breadcrumb-button-i-div">
                            <i :class="{ 'increment-div-inactive': index === tabs.length - 1 }"
                                class="fa fa-chevron-circle-down fa-lg" 
                                @click.stop="moveDownFunction(tab, index)"
                                data-bind="click: $parent.moveDownFunction, touchstart: $parent.moveDownFunction, css: { 'increment-div-inactive': $index() === $parent.menuTabs().length - 1 }"></i>
                        </div> -->
                        <div v-if="displayDeleteButton(tab)"
                            class="breadcrumb-button-i-div">
                            <i class="fa fa-trash fa-lg" 
                                @click.stop="deleteFunction(tab)"
                                data-bind="click: $parent.deleteFunction, $parent.deleteFunction"></i>
                        </div>
                    </div>
                </div>
            </h3>
            <div>
                <!-- <table class="menu-tab-group-table" data-bind="template: { name: 'template-user-settings-input-types-tbody', data: items }"></table> -->
                <InputTypesTable :itemsFromParent="tab.items" :classFromParent="classForTable"></InputTypesTable>
            </div>
        </template>
    </div>
    <!-- ko if: displayType === $root.config.TAB_GROUP_DISPLAY_TYPE.ACCORDION
        <div class="accordion" data-bind="template: { name: 'template-user-settings-accordion-tab', foreach: tabs}"></div>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-accordion-tab">
        <h3 data-bind="attr: {id: id}">
            <div class="fill-flex-div-row flex-space-between">
                <div class="fill-flex-div-row waypoint-description">
                    <img class="accordion-logo-image" data-bind="attr: { src: logoImageSrc, alt: alternateText } " />
                    ko if: displayText === true
                    <div class="waypoint-description-label">
                        <span data-bind="text: label"></span>
                    </div>
                    /ko
                </div>
                <div class="accordion-buttons">
                    ko if:  $parent.moveUpFunction !== undefined
                    <div class="breadcrumb-button-i-div">
                        <i class="fa fa-chevron-circle-up fa-lg" data-bind="click: $parent.moveUpFunction, touchstart: $parent.moveUpFunction, css: { 'increment-div-inactive': $index() === 0 }"></i>
                    </div>
                    /ko
                    ko if:  $parent.moveDownFunction !== undefined
                    <div class="breadcrumb-button-i-div">
                        <i class="fa fa-chevron-circle-down fa-lg" data-bind="click: $parent.moveDownFunction, touchstart: $parent.moveDownFunction, css: { 'increment-div-inactive': $index() === $parent.menuTabs().length - 1 }"></i>
                    </div>
                    /ko
                    ko if: typeof $parent.deleteFunction === 'function' && $parent.displayDeleteButton() === true && displayDeleteButton() === true
                    <div class="breadcrumb-button-i-div">
                        <i class="fa fa-trash fa-lg" data-bind="click: $parent.deleteFunction, $parent.deleteFunction, attr: { title: $parent.deleteButtonTooltip }"></i>
                    </div>
                    /ko
                </div>
            </div>
        </h3>
        <div>
            <table class="menu-tab-group-table" data-bind="template: { name: 'template-user-settings-input-types-tbody', data: items }"></table>
        </div>
    </script> -->
</template>

<script>
import { ref, toRef } from 'vue'
// import InputTypesTable from './InputTypesTable.vue';
const TScMessenger = window.TScMessenger;

export default {
    name: "ItemTypeTabGroupAccordion",
    props: {
        tabsFromParent: Array,
        displayDeleteButtonFromParent: Object
    },
    setup(props, context) {
        const tabs = toRef(props, "tabsFromParent");
        const displayDeleteButtonFromParent = toRef(props, "displayDeleteButtonFromParent");
        const classForTable = "menu-tab-group-table";

        // function moveUpFunction(tab, index) {
        //     if(index > 0) {
        //         context.emit('moveUpClick', tab);
        //     }
        // }

        // function moveDownFunction(tab, index) {
        //     if(index !== tabs.value.length - 1) {
        //         context.emit('moveDownClick', tab);
        //     }
        // }

        function deleteFunction(tab) {
            context.emit('deleteClick', tab);
        }

        function displayDeleteButton(tab) {
            // typeof $parent.deleteFunction === 'function' && $parent.displayDeleteButton() === true && displayDeleteButton() === true
            return displayDeleteButtonFromParent.value === true && tab.displayDeleteButton.value === true;
        }

        return {
            tabs,
            classForTable,
            // moveUpFunction,
            // moveDownFunction,
            deleteFunction,
            displayDeleteButton
        };
    },
    // emits: ['moveUpClick', 'moveDownClick', 'deleteClick']
    emits: ['deleteClick']
}
</script>

<style lang="scss" scoped>

</style>