<template>
    <!-- Template = "template-selection-manufacturer-datagrid-row" -->
    <td class="datagrid-icon-td datagrid-icon-expanded-td">
        <div v-if="item.isSavedOffer === true" class="selection-datagrid-type-icon datagrid-icon-saved"></div>
        <div v-else class="selection-datagrid-type-icon">
            <div class="selection-datagrid-fa-icon save-icon">
                <i class="fas fa-lg"
                    :class="[{ 'fa-file-alt': item.Source === VEHICLE_SOURCE_TYPES.STANDARD }, { 'fa-file': item.Source === VEHICLE_SOURCE_TYPES.TEMPLATE }]"></i>
            </div>
        </div>
    </td>
    <td class="datagrid-column datagrid-column-large datagrid-description">
        <span>{{ item.Description }}</span>
    </td>
    <td class="datagrid-column datagrid-wheelbase">
        <span>{{ item.wheelbaseFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-ca">
        <span>{{ item.cabToAxlesFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-gvw">
        <span>{{ item.gvwFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-gcw">
        <span>{{ item.gcwFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-axle-layout">
        <span>{{ item.AxleLayout }}</span>
    </td>
    <td class="datagrid-column datagrid-vehicle-type">
        <span>{{ item.VehicleTypeTranslation }}</span>
    </td>
    <td class="datagrid-column">
        <span>{{ item.SpecDate }}</span>
    </td>
    <td class="datagrid-icon-td" v-if="wizard.activeWizardStep.displayDownloadFileOption">
        <div class="selection-datagrid-type-icon-small datagrid-icon-file-download" @click="wizard.downloadFile"
            :title="wizard.downloadFileTooltipText">
            <i class="fas fa-file-download"></i>
        </div>
    </td>

    <!-- <script type="text/html" id="template-selection-manufacturer-datagrid-row">
        Template = "template-selection-manufacturer-datagrid-row"
        <td class="datagrid-icon-td datagrid-icon-expanded-td">
            ko if: isSavedOffer === false
            <div class="selection-datagrid-type-icon">
                <div class="selection-datagrid-fa-icon save-icon">
                    <i class="fas fa-lg" data-bind="css: { 'fa-file-alt': Source === $root.config.VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': Source === $root.config.VEHICLE_SOURCE_TYPES.TEMPLATE }"></i>
                </div>
            </div>
            /ko
            ko if: isSavedOffer === true
            <div class="selection-datagrid-type-icon datagrid-icon-saved"></div>
            /ko
        </td>
        <td class="datagrid-column datagrid-column-large datagrid-description">
            <span data-bind="text: Description"></span>
        </td>
        <td class="datagrid-column datagrid-wheelbase">
            <span data-bind="text: wheelbaseFormatted"></span>
        </td>
        <td class="datagrid-column datagrid-ca">
            <span data-bind="text: cabToAxlesFormatted"></span>
        </td>
        <td class="datagrid-column datagrid-gvw">
            <span data-bind="text: gvwFormatted"></span>
        </td>
        <td class="datagrid-column datagrid-gcw">
            <span data-bind="text: gcwFormatted"></span>
        </td>
        <td class="datagrid-column datagrid-axle-layout">
            <span data-bind="text: AxleLayout"></span>
        </td>
        <td class="datagrid-column datagrid-vehicle-type">
            <span data-bind="text: VehicleTypeTranslation"></span>
        </td>
        <td class="datagrid-column datagrid-spec-date">
            <span data-bind="text: SpecDate"></span>
        </td>
        ko if: $parent.displayDownloadFileOption
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-type-icon-small datagrid-icon-file-download" data-bind="click: $parents[1].downloadFile, clickBubble: false, attr: { title: $parents[1].downloadFileTooltipText }">
                <i class="fas fa-file-download"></i>
            </div>
        </td>
        /ko
    </script> -->
</template>

<script>
import { ref } from 'vue';
import config from '@/services/config';

export default {
    name: 'SelectionDatagridSelectionManufacturerDatagridRow',
    props: {
        wizardFromParent: Object,
        itemFromParent: Object
    },
    setup(props) {
        const wizard = ref(props.wizardFromParent);
        const item = ref(props.itemFromParent);

        return {
            wizard,
            item,
            VEHICLE_SOURCE_TYPES: config.VEHICLE_SOURCE_TYPES
        }
    }
}
</script>

<style lang="scss" scoped>
</style>