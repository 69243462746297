import config from "@/services/config";
import globals from "@/services/globals";
import { shallowRef } from 'vue';
const $ = window.$;
const TScMessenger = window.TScMessenger;

/**
 * Using documentation for Bootstrap Multiselect (http://davidstutz.github.io/bootstrap-multiselect/)
 */

/**
 *
 * @param {Object} initMsObj - Object generated using MultiSelectBuilder
 */
function MultiSelect(initMsObj) {
  var self = this,
    multiSelectObject = null;

  // #region Public variables
  //self.options = ko.observableArray();
  self.options = shallowRef([]);
  // #endregion Public variables

  // #region Private functions
  /**
   * Initialize variables using values contained in initMsObj
   * @param {Object} initObj - initMsObj passed into function
   */
  function construct(initObj) {
    self.multiselectName = initObj.multiselectName;
    //self.selectId = initObj.selectId.includes('#') ? initObj.selectId : '#' + initObj.selectId;
    self.selectId = initObj.selectId;
    self.onChangeCallback =
      typeof initObj.onChange === "function" ? initObj.onChange : null;
    self.onDropdownShowCallback =
      typeof initObj.onDropdownShowFunction === "function"
        ? initObj.onDropdownShowFunction
        : null;
    self.onDropdownHideCallback =
      typeof initObj.onDropdownHideFunction === "function"
        ? initObj.onDropdownHideFunction
        : null;
    self.onDeselectAllCallback =
      typeof initObj.onDeselectAllCallback === "function"
        ? initObj.onDeselectAllCallback
        : null;
    self.onChangeExtraCallback =
      typeof initObj.onChangeExtraCallback === "function"
        ? initObj.onChangeExtraCallback
        : null;
    self.onDeselectAllExtraCallback =
      typeof initObj.onDeselectAllExtraCallback === "function"
        ? initObj.onDeselectAllExtraCallback
        : null;
    self.descriptionColumn =
      typeof initObj.descriptionColumn === "string"
        ? initObj.descriptionColumn
        : null;
    self.valueColumn =
      typeof initObj.valueColumn === "string" ? initObj.valueColumn : null;
    self.updateOptionsArray(initObj.optionsArray);
    self.selectedOptionType =
      initObj.selectedOptionType !== undefined
        ? initObj.selectedOptionType
        : config.SELECT_OPTION_TYPE.MULTIPLE;

    self.descriptionPropertyName =
      initObj.descriptionPropertyName !== undefined
        ? initObj.descriptionPropertyName
        : "description";
    self.valuePropertyName =
      initObj.valuePropertyName !== undefined
        ? initObj.valuePropertyName
        : "value";
    self.SELECT_OPTION_TYPE = config.SELECT_OPTION_TYPE;
    self.popoverTooltipText = shallowRef(initObj.popoverTooltipText !== undefined ? initObj.popoverTooltipText : null);
    self.useCustomDescriptionForOptionFunction = initObj.useCustomDescriptionForOptionFunction !== undefined ? initObj.useCustomDescriptionForOptionFunction : false;
    self.customDescriptionForOptionFunction = typeof initObj.customDescriptionForOptionFunction === 'function' ? initObj.customDescriptionForOptionFunction : function () {};
    multiSelectObject = createMultiSelectObject(initObj);
  }

  /**
   * Create object to be passed into multiselect when it's being created
   * @param {any} objToUse
   * @returns {Object}
   */
  function createMultiSelectObject(objToUse) {
    var returnObj = {};
    if (objToUse.nonSelectedText !== undefined) {
      returnObj.nonSelectedText = objToUse.nonSelectedText;
    }
    if (objToUse.enableHTML !== undefined) {
      returnObj.enableHTML = objToUse.enableHTML;
    }
    if (objToUse.enableFiltering !== undefined) {
      returnObj.enableFiltering = objToUse.enableFiltering;
      returnObj.filterBehavior = objToUse.filterBehavior;
      returnObj.includeFilterClearBtn = objToUse.includeFilterClearBtn;
      returnObj.enableCaseInsensitiveFiltering =
        objToUse.enableCaseInsensitiveFiltering;
    }
    if (objToUse.includeResetOption !== undefined) {
      returnObj.includeResetOption = objToUse.includeResetOption;
      returnObj.resetText = objToUse.resetText;
      returnObj.includeResetDivider = objToUse.includeResetDivider;
    }
    if (objToUse.columnName !== undefined) {
      returnObj.columnName = objToUse.columnName;
    }
    if (objToUse.filterFunction !== undefined) {
      returnObj.filterFunction = objToUse.filterFunction;
    }
    if (objToUse.buttonWidth !== undefined) {
      returnObj.buttonWidth = objToUse.buttonWidth;
    }
    if (objToUse.maxHeight !== undefined) {
      returnObj.maxHeight = objToUse.maxHeight;
    }
    if (objToUse.disableIfEmpty !== undefined) {
      returnObj.disableIfEmpty = objToUse.disableIfEmpty;
    }
    if (objToUse.disabledText !== undefined) {
      returnObj.disabledText = objToUse.disabledText;
    }
    returnObj.onChange = onChangeFunction;
    returnObj.onDeselectAll = onDeselectAllFunction;
    returnObj.buttonText = objToUse.buttonText;
    returnObj.onDropdownShow = onDropdownShowFunction;
    returnObj.onDropdownHide = onDropdownHideFunction;

    return returnObj;
  }

  /**
   * Gets multiSelectObject created in construct
   * @returns {Object}
   */
  function getMultiSelectObject() {
    return multiSelectObject;
  }

  /**
   *
   * @param {number} newValue - New button width
   */
  function setButtonWidth(newValue) {
    multiSelectObject.buttonWidth = newValue;
  }

  /**
   * Called when user clicks on an option in the multiselect. Uses onChangeCallback passed in using builder
   * @param {Element} option - Element that was clicked. To get value -> option[0].value
   * @param {boolean} checked - Value that the element is being set to
   */
  function onChangeFunction(option, checked) {
    // Use the callback passed in by the
    if (self.onChangeCallback !== null) {
      self.onChangeCallback(option, checked, self);
    }
    if (self.onChangeExtraCallback !== null) {
      self.onChangeExtraCallback();
    }
  }

  /**
   * Called when the dropdown of the multiselect is opened
   * */
  function onDropdownShowFunction() {
    if (self.onDropdownShowCallback !== null) {
      self.onDropdownShowCallback();
    }
  }

  /**
   * Called when the dropdown of the multiselect is closed
   * */
  function onDropdownHideFunction() {
    if (self.onDropdownHideCallback !== null) {
      self.onDropdownHideCallback();
    }
  }

  /**
   * Called when user clicks on Clear button
   */
  function onDeselectAllFunction() {
    if (self.onDeselectAllCallback !== null) {
      self.onDeselectAllCallback(self);
    }
    if (self.onDeselectAllExtraCallback !== null) {
      self.onDeselectAllExtraCallback();
    }
  }

  /**
   *
   */
  function resetButtonClicked() {
    if (
      $("#" + self.selectId + "-multiselect-div .multiselect-search").val() !==
      ""
    ) {
      $("#" + self.selectId + "-multiselect-div .multiselect-search").val("");
      $("#" + self.selectId + "-multiselect-div ul li")
        .show()
        .removeClass("multiselect-filter-hidden");
      $("#" + self.selectId + "-multiselect-div .multiselect-search").focus();
    }
    if (self.onDeselectAllCallback !== null) {
      self.onDeselectAllCallback(self);
    }
    if (self.onDeselectAllExtraCallback !== null) {
      self.onDeselectAllExtraCallback();
    }
  }
  // #endregion

  // #region Public functions
  self.getDescriptionForOption = function (option) {
    // return option[self.descriptionPropertyName];
    if(self.useCustomDescriptionForOptionFunction === true) {
      return self.customDescriptionForOptionFunction(option[self.descriptionPropertyName]);
    } else {
      if(globals.checkIfStringIsUpperCase(option[self.descriptionPropertyName]) || globals.checkIfStringIsLowerCase(option[self.descriptionPropertyName])) {
        // Capitalize first letter of each word in description
        var lowerCase = option[self.descriptionPropertyName].toLowerCase();
        // return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
        return lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      } else {
        return option[self.descriptionPropertyName];
      }
    }
  }

  self.getValueForOption = function (option) {
    return option[self.valuePropertyName];
  };

  /**
   * Initialize multiselect
   */
  self.initializeMultiSelect = function (updateMultiSelectObjectCallback) {
    //var multiSelectObj = getMultiSelectObject();
    try {
      var idToUse = "#" + self.selectId,
        multiselectObject = getMultiSelectObject(),
        newObject =
          typeof updateMultiSelectObjectCallback === "function"
            ? updateMultiSelectObjectCallback()
            : null;

      if (newObject !== null) {
        multiselectObject[newObject.key] = newObject.value;
      }

      if (self.selectedOptionType === config.SELECT_OPTION_TYPE.SINGLE) {
        $(idToUse).val("").multiselect(getMultiSelectObject());
      } else {
        $(idToUse).multiselect(getMultiSelectObject());
      }
      if (self.onDeselectAllCallback !== null) {
        //$('.multiselect-reset .btn').bind('click touchstart', resetButtonClicked);
        $(idToUse + "-multiselect-div .multiselect-reset .btn").bind(
          "click touchstart",
          resetButtonClicked
        );
      }
    } catch (e) {
      TScMessenger.writeDebugMessage("Error when creating multiselect");
    }
  };

  /**
   * Clean up and destroy multiselect when no longer required
   */
  self.cleanUp = function () {
    $("#" + self.selectId).multiselect("destroy");
    $(".multiselect-reset .btn").unbind("click touchstart");

    self.multiselectName = null;
    //self.selectId = initObj.selectId.includes('#') ? initObj.selectId : '#' + initObj.selectId;
    self.selectId = null;
    self.onChangeCallback = null;
    self.onDropdownShowFunction = null;
    self.onDropdownHideFunction = null;
    self.onDeselectAllCallback = null;
    self.onChangeExtraCallback = null;
    self.onDeselectAllExtraCallback = null;
    self.descriptionColumn = null;
    self.valueColumn = null;
    //if (self && self.options && self.options()) {
    //    //while (self.options._subscriptions && self.options._subscriptions.change && self.options._subscriptions.change.length > 0) {
    //    //    self.options._subscriptions.change.forEach(function (subscription) {
    //    //        subscription.dispose();
    //    //    });
    //    //}
    //    utils.clearSubscriptions(self.options);
    //    if (self.options.removeAll) {
    //        try {
    //            self.options.removeAll();
    //        } catch (removeAllEx) {
    //            var breakHere = 0;
    //        }

    //    }
    //    self.options(null);
    //    self.options = null;
    //}
    self.selectedOptionType = null;

    self.descriptionPropertyName = null;
    self.valuePropertyName = null;
    self.SELECT_OPTION_TYPE = null;
    //multiSelectObject = null;
  };

  /**
   * Refresh multiselect. Can be called when selections changed programmtically
   */
  self.refreshMultiSelect = function () {
    $("#" + self.selectId).multiselect("refresh");
  };

  /**
   * Rebuild multiselect. Can be called when options are added/removed from list
   */
  self.rebuildMultiSelect = function () {
    if (self.selectedOptionType === config.SELECT_OPTION_TYPE.SINGLE) {
      $("#" + self.selectId)
        .val("")
        .multiselect("rebuild");
    } else {
      $("#" + self.selectId).multiselect("rebuild");
    }
    if (self.onDeselectAllCallback !== null) {
      //$('.multiselect-reset .btn').bind('click touchstart', resetButtonClicked);
      $("#" + self.selectId + "-multiselect-div .multiselect-reset .btn").bind(
        "click touchstart",
        resetButtonClicked
      );
    }
  };

  /**
   * Updated the values in the options observableArray
   * @param {Object[]} optionsArray
   */
  self.updateOptionsArray = function (optionsArray) {
    var newOptions =
      self.uniqueField !== undefined
        ? globals.getUniqueObjectsFromArrayUsingProperty(
            optionsArray,
            self.uniqueField
          )
        : optionsArray;
    self.options.value = newOptions;
  };

  /**
   * Can add an intermediate callback function when multiselect is inside a selection wizard
   * @param {function} cb
   */
  self.registerOnChangeCallbackFunction = function (cb) {
    self.onChangeCallback = typeof cb === "function" ? cb : null;
  };

  /**
   * Replace onDeselectAllCallback with new function
   * @param {function} cb - New callback function to use
   */
  self.registerOnDeselectAllCallbackFunction = function (cb) {
    self.onDeselectAllCallback = typeof cb === "function" ? cb : null;
  };

  /**
   * Select item(s) in multiselect
   * @param {string|Number|string[]|Number[]} value - Value to be selected in multiselect
   * @param {boolean} triggerOnChange - notify onChange function
   */
  self.selectItemUsingValue = function (value, triggerOnChange) {
    var triggerValue =
      typeof triggerOnChange === "boolean" ? triggerOnChange : false;
    $("#" + self.selectId).multiselect("select", value, triggerValue);
  };

  /**
   * Disable this multiselect
   */
  self.disableMultiSelect = function () {
    $("#" + self.selectId).multiselect("disable");
  };

  /**
   * Enable this multiselect
   */
  self.enableMultiSelect = function () {
    $("#" + self.selectId).multiselect("enable");
  };

  /**
   *
   * @param {boolean} justVisible - set to true if multiselect must be open to deselect the options
   */
  self.deselectAllOptions = function (justVisible) {
    var justVisibleOption = justVisible !== undefined ? justVisible : false;
    $("#" + self.selectId).multiselect("deselectAll", justVisibleOption);
  };

  /**
   * Change the width of the button on the multiselect
   * @param {number} newButtonWidth - New button width
   */
  self.changeButtonWidth = function (newButtonWidth) {
    if (typeof newButtonWidth === "number") {
      var mso = getMultiSelectObject();
      if (parseInt(mso.buttonWidth) !== newButtonWidth) {
        $("#" + self.selectId + "-multiselect-div .btn-group").width(
          newButtonWidth
        );
        setButtonWidth(newButtonWidth);
        newButtonWidth = newButtonWidth + "px";
        $("#" + self.selectId).multiselect("setOptions", {
          buttonWidth: newButtonWidth,
        });
        self.rebuildMultiSelect();
      }
    }
  };
  // #endregion Public functions

  construct(initMsObj);
}

export default MultiSelect;
