import translations from'./translations';


var TScMessenger = window.TScMessenger;


var codeMissingFromTranslationsObjectMessage = '999: Text Not Found',
    _languageCultureCode,
    CULTURE_CODES = {
        ENGLISH: {
            DEFAULT: 'en',
            US: 'en-US',
            CA: 'en-CA',
            GB: 'en-GB',
            AU: 'en-AU',
            NZ: 'en-NZ',
            SA: 'en-ZA'
        },
        // FRENCH: 'fr',
        FRENCH: {
            DEFAULT: 'fr',
            CA: 'fr-CA'
        },
        PORTUGESE: {
            BRAZIL: 'pt'
        },
        VIETNAMESE: 'vi'
    };

/**
 * Returns text from translations object based on the code and culture code provided
 * @param {string} code - Code to map to on translations object
 * @param {string} [cultureCode] - Culture code to look for in translations object. Defaults to en
 * @returns {string} Text from translations object if found. Otherwise it returns codeMissingFromTranslationsObjectMessage value ('999: Text Not Found')
 */
function getTextUsingCode(code, cultureCode) {
    if (typeof code !== 'string') {
        TScMessenger.writeErrorMessage('code parameter is not a string');
        return '';
    }
    var cultureCodeToUse = typeof cultureCode === 'string' ? cultureCode : typeof getLanguageCultureCode() === 'string' ? getLanguageCultureCode() : CULTURE_CODES.ENGLISH.DEFAULT;

    if (translations[cultureCodeToUse] !== undefined && typeof translations[cultureCodeToUse][code] === 'string') {
        // if cultureCode provided in parameter list is available in translations
        return translations[cultureCodeToUse][code];
    } else if (translations[getLanguageCultureCode()] !== undefined && typeof translations[getLanguageCultureCode()][code] === 'string') {
        // if getLanguageCultureCode() code is available in translations
        return translations[getLanguageCultureCode()][code];
    } else if (typeof translations[CULTURE_CODES.ENGLISH.DEFAULT][code] === 'string') {
        // if CULTURE_CODES.ENGLISH.DEFAULT code is available in translations
        return translations[CULTURE_CODES.ENGLISH.DEFAULT][code];
    } else {
        TScMessenger.writeErrorMessage('Could not find text. Code = ' + code + ', cultureCodeToUse = ' + cultureCodeToUse);
        return codeMissingFromTranslationsObjectMessage;
    }
}

/**
 * Returns text from translations object based on the code and culture code provided
 * @param {string|Object} textParam - 
 * @param {string} textParam.Code - Code to map to on translations object
 * @param {string} [textParam.CultureCode] - Culture code to look for in translations object. Defaults to en
 * @returns {string} Text from translations object if found. Otherwise it returns codeMissingFromTranslationsObjectMessage value ('999: Text Not Found')
 */
function getTranslationText(textParam) {
    var codeToUse,
        cultureCodeToUse = typeof textParam.CultureCode === 'string' ? textParam.CultureCode : typeof getLanguageCultureCode() === 'string' ? getLanguageCultureCode() : CULTURE_CODES.ENGLISH.DEFAULT;
    if (typeof textParam === 'object') {
        codeToUse = textParam.Code;
    } else if (typeof textParam === 'string') {
        codeToUse = textParam;
    } else {
        TScMessenger.writeErrorMessage('textParam is not an object or a string in language.getText');
        return codeMissingFromTranslationsObjectMessage;
    }
    cultureCodeToUse = cultureCodeToUse.toLowerCase();
    
    if (translations[cultureCodeToUse] !== undefined && typeof translations[cultureCodeToUse][codeToUse] === 'string') {
        // if cultureCode provided in parameter list is available in translations
        return translations[cultureCodeToUse][codeToUse];
    } else if (translations[getLanguageCultureCode()] !== undefined && typeof translations[getLanguageCultureCode()][codeToUse] === 'string') {
        // if getLanguageCultureCode() code is available in translations
        return translations[getLanguageCultureCode()][codeToUse];
    } else if (typeof translations[CULTURE_CODES.ENGLISH.DEFAULT][codeToUse] === 'string') {
        // if CULTURE_CODES.ENGLISH.DEFAULT code is available in translations
        return translations[CULTURE_CODES.ENGLISH.DEFAULT][codeToUse];
    } else {
        TScMessenger.writeErrorMessage('Could not find text. Code = ' + codeToUse + ', cultureCodeToUse = ' + cultureCodeToUse);
        return codeMissingFromTranslationsObjectMessage;
    }
}

/**
 * 
 * @param {string|Object} textParam - Code for string or 
 * @param {string} textParam.Code - Code to map to on translations object
 * @param {string} [textParam.CultureCode] - Culture code to look for in translations object. Defaults to en
 * @param {string[]} replacementTextArray - Array of strings to be inserted into the string
 * @returns {string} Text from translations object if found. Otherwise it returns codeMissingFromTranslationsObjectMessage value ('999: Text Not Found')
 */
function getTranslationTextWithReplacementText(textParam, replacementTextArray) {
    var replacementNum,
        replacementText,
        returnText = getTranslationText(textParam);

    replacementTextArray.forEach(function (stringToUse, index) {
        var replacementNum = index + 1;
        var replacementText = '$S' + replacementNum;
        returnText = returnText.replace(replacementText, stringToUse);
    });

    //for (var i = 0; i < replacementTextArray.length; i++) {
    //    replacementNum = i + 1;
    //    replacementText = '$S' + replacementNum;
    //    returnText = returnText.replace(replacementText, replacementTextArray[i]);
    //}

    return returnText;
}

function getLanguageCultureCode() {
    return _languageCultureCode.toLowerCase();
}

function setLanguageCultureCode(newValue) {
    _languageCultureCode = newValue;
}

setLanguageCultureCode(CULTURE_CODES.ENGLISH.DEFAULT);

var language =  {
    getLanguageCultureCode: getLanguageCultureCode,
    setLanguageCultureCode: setLanguageCultureCode,
    getTextUsingCode: getTextUsingCode,
    getTranslationText: getTranslationText,
    getTranslationTextWithReplacementText: getTranslationTextWithReplacementText,
    CULTURE_CODES: CULTURE_CODES,
    codeMissingFromTranslationsObjectMessage: codeMissingFromTranslationsObjectMessage
};
    export default language