// define(['services/uiInput/ui.input', 'services/utils'], function (Input, utils) {
    import Input from "./ui.input";
    import utils from "../utils";

    const TScMessenger = window.TScMessenger;
    /**
     * 
     * @param {object} initFileInputForm - Should be created using FileInputFormBuilder
     */
    function FileInputForm(initFileInputForm) {
        Input.call(this, initFileInputForm);

        this._name = typeof initFileInputForm.name === 'string' ? initFileInputForm.name : '';
        this._fileTypes = typeof initFileInputForm.fileTypes === 'string' ? initFileInputForm.fileTypes : '';
        
        this._customCss = typeof initFileInputForm.customCss === 'string' ? initFileInputForm.customCss : '';
        this._customInputContainerCss = typeof initFileInputForm.customInputContainerCss === 'string' ? initFileInputForm.customInputContainerCss : '';
        this._customInputCss = typeof initFileInputForm.customInputCss === 'string' ? initFileInputForm.customInputCss : '';

        this._onChangeCallback = typeof initFileInputForm.onChangeCallback === 'function' ? initFileInputForm.onChangeCallback : null;

        this._displaySubmitButton = typeof initFileInputForm.displaySubmitButton === 'boolean' ? initFileInputForm.displaySubmitButton : false;
        this._submitButtonCallback = typeof initFileInputForm.submitButtonCallback === 'function' ? initFileInputForm.submitButtonCallback : null;
        this._submitButtonText = typeof initFileInputForm.submitButtonText === 'string' ? initFileInputForm.submitButtonText : '';
        this._includeIconInButton = typeof initFileInputForm.includeIconInButton === 'string' ? initFileInputForm.includeIconInButton : '';
        this._submitButtonIcon = typeof initFileInputForm.submitButtonIcon === 'string' ? initFileInputForm.submitButtonIcon : null;

        if (this._displaySubmitButton === false && this._onChangeCallback === null) {
            throw 'A FileInputForm without a submit button requires an onChangeCallback function';
        }

        return this;
    }

    utils.extend(Input, FileInputForm);

    //#region Getters and Setters
    FileInputForm.prototype.getDisplaySubmitButton = function () {
        return this._displaySubmitButton;
    }
    FileInputForm.prototype.getSubmitButtonCallback = function () {
        return this._submitButtonCallback;
    }
    FileInputForm.prototype.getSubmitButtonText = function () {
        return this._submitButtonText;
    }
    FileInputForm.prototype.getCustomCss = function () {
        return this._customCss;
    }
    FileInputForm.prototype.getCustomInputContainerCss = function () {
        return this._customInputContainerCss;
    }
    FileInputForm.prototype.getCustomInputCss = function () {
        return this._customInputCss;
    }
    FileInputForm.prototype.getName = function () {
        return this._name;
    }
    FileInputForm.prototype.getFileTypes = function () {
        return this._fileTypes;
    }
    FileInputForm.prototype.getSubmitButtonIcon = function () {
        return this._submitButtonIcon;
    }
    FileInputForm.prototype.getSubmitButtonText = function () {
        return this._submitButtonText;
    }
    FileInputForm.prototype.getIncludeIconInButton = function () {
        return this._includeIconInButton;
    }
    FileInputForm.prototype.getInputId = function () {
        return this.getId() + '-input';
    }
    
    //#endregion Getters and Setters

    //#region Other Functions
    FileInputForm.prototype.handleFileSubmission = function () {
        if (typeof this._submitButtonCallback === 'function') {
            this._submitButtonCallback();
        } else {
            TScMessenger.writeDebugMessage('_submitButtonCallback is not setup');
        }
    }

    FileInputForm.prototype.onChangeFunction = function () {
        if (typeof this._onChangeCallback === 'function') {
            this._onChangeCallback();
        } else {
            TScMessenger.writeDebugMessage('_submitButtonCallback is not setup');
        }
    }
    //#region Other Functions
    
export default FileInputForm;
//     return FileInputForm;
// });
