import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '../../../services';

var Axle = function (id, parent, number, type, added, steering, lifting, liftingMaxTravel, manufacturerRating, manufacturerRatingOverride, axleWeight, axleWeightOverride,
                    suspensionMassRating, suspensionMassRatingOverride, tyreMassRatingDual, tyreMassRatingDualOverride, tyreMassRatingSingle, tyreMassRatingSingleOverride,
    raised, tyreRadius, tyreWidth, noOfTyresOnAxle, drivenAxle, standardRatio, revsPerKM, tyreWidthOverride, fkDummy, averageCostOfTyre, averageLifeOfTyre, noOfTyresOnAxleForCosting,
    noOfTyresOnAxleForCostingOverride, desiredWeight, desiredWeightOverride, bridgeFormulaPermissible, bridgeFormulaPermissibleOverride, grossAxleWeightRating, grossAxleWeightRatingOverride, percentageOfWeightCarried, percentageOfWeightCarriedOverride, 
    tyreStaticLoadedRadius) {

    var self = this;
    //#region Private members

    var _id,
        _parent,
        _number,
        _type,
        _steering,
        _lifting,
        _liftingMaxTravel,
        _manufacturerRating,
        _manufacturerRatingOverride,
        _axleWeight,
        _axleWeightOverride,
        _suspensionMassRating,
        _suspensionMassRatingOverride,
        _tyreMassRatingDual,
        _tyreMassRatingDualOverride,
        _tyreMassRatingSingle,
        _tyreMassRatingSingleOverride,
        _tyreRadius,
        _tyreStaticLoadedRadius,
        _tyreWidth,
        _noOfTyresOnAxle,
        _raised,
        _vehicleId,
        _added,
        _drivenAxle,
        _standardRatio,
        _revsPerKM,
        _tyreWidthOverride,
        _flatspotOffset,
        _averageCostOfTyre,
        _averageLifeOfTyre,
        _noOfTyresOnAxleForCosting,
        _noOfTyresOnAxleForCostingOverride,
        _desiredWeight,
        _desiredWeightOverride,
        _isAxleGroup = false,
        _axleGroupLocation = null,
        _relatedAxleIds = [],
        _bridgeFormulaPermissible,
        _bridgeFormulaPermissibleOverride,
        _grossAxleWeightRating,
        _grossAxleWeightRatingOverride,
        _axleGroupIncludesAddedAxle = false,
        _percentageOfWeightCarried, 
        _percentageOfWeightCarriedOverride;

    //#endregion Private members

    //#region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getParent() {
        return _parent;
    }
    function setParent(newValue) {
        _parent = newValue;
    }
    function getNumber() {
        return _number;
    }
    function setNumber(newValue) {
        _number = newValue;
    }
    function getType() {
        return _type;
    }
    function setType(newValue) {
        _type = newValue;
    }
    function getSteering() {
        return _steering;
    }
    function setSteering(newValue) {
        _steering = newValue;
    }
    function getLifting() {
        return _lifting;
    }
    function setLifting(newValue) {
        _lifting = newValue;
    }
    function getLiftingMaxTravel() {
        return _liftingMaxTravel;
    }
    function setLiftingMaxTravel(newValue) {
        _liftingMaxTravel = newValue;
    }
    function getManufacturerRating() {
        return _manufacturerRating;
    }
    function setManufacturerRating(newValue) {
        _manufacturerRating = newValue;
    }
    function getManufacturerRatingOverride() {
        return _manufacturerRatingOverride;
    }
    function setManufacturerRatingOverride(newValue) {
        _manufacturerRatingOverride = newValue;
    }
    function getAxleWeight() {
        return _axleWeight;
    }
    function setAxleWeight(newValue) {
        _axleWeight = newValue;
    }
    function getAxleWeightOverride() {
        return _axleWeightOverride;
    }
    function setAxleWeightOverride(newValue) {
        _axleWeightOverride = newValue;
    }
    function getSuspensionMassRating() {
        return _suspensionMassRating;
    }
    function setSuspensionMassRating(newValue) {
        _suspensionMassRating = newValue;
    }
    function getSuspensionMassRatingOverride() {
        return _suspensionMassRatingOverride;
    }
    function setSuspensionMassRatingOverride(newValue) {
        _suspensionMassRatingOverride = newValue;
    }
    function getTyreMassRatingDual() {
        return _tyreMassRatingDual;
    }
    function setTyreMassRatingDual(newValue) {
        _tyreMassRatingDual = newValue;
    }
    function getTyreMassRatingDualOverride() {
        return _tyreMassRatingDualOverride;
    }
    function setTyreMassRatingDualOverride(newValue) {
        _tyreMassRatingDualOverride = newValue;
    }
    function getTyreMassRatingSingle() {
        return _tyreMassRatingSingle;
    }
    function setTyreMassRatingSingle(newValue) {
        _tyreMassRatingSingle = newValue;
    }
    function getTyreMassRatingSingleOverride() {
        return _tyreMassRatingSingleOverride;
    }
    function setTyreMassRatingSingleOverride(newValue) {
        _tyreMassRatingSingleOverride = newValue;
    }
    function getTyreMassRatingOverride() {
        if (_noOfTyresOnAxle === 2) {
            return getTyreMassRatingSingleOverride();
        } else {
            return getTyreMassRatingDualOverride();
        }
    }
    function getTyreMassRating() {
        return getNoOfTyresOnAxle() === 2 ? getTyreMassRatingSingle() : getTyreMassRatingDual();
    }
    function getTyreRadius() {
        return _tyreRadius;
    }
    function setTyreRadius(newValue) {
        _tyreRadius = newValue;
    }
    function getTyreStaticLoadedRadius() {
        return _tyreStaticLoadedRadius;
    }
    function setTyreStaticLoadedRadius(newValue) {
        _tyreStaticLoadedRadius = newValue;
    }
    
    function getTyreWidth() {
        return _tyreWidth;
    }
    function setTyreWidth(newValue) {
        _tyreWidth = newValue;
    }
    function getNoOfTyresOnAxle() {
        return _noOfTyresOnAxle;
    }
    function setNoOfTyresOnAxle(newValue) {
        _noOfTyresOnAxle = newValue;
    }
    function getRaised() {
        return _raised;
    }
    function setRaised(newValue) {
        _raised = newValue;
    }
    function getVehicleId() {
        return _vehicleId;
    }
    function setVehicleId(newValue) {
        _vehicleId = newValue;
    }
    function isAdded() {
        return _added;
    }
    function setAdded(newValue) {
        _added = newValue;
    }
    function getDrivenAxle() {
        return _drivenAxle;
    }
    function setDrivenAxle(newValue) {
        _drivenAxle = newValue;
    }
    function getStandardRatio() {
        return _standardRatio;
    }
    function setStandardRatio(newValue) {
        _standardRatio = newValue;
    }
    function getRevsPerKM() {
        return _revsPerKM;
    }
    function setRevsPerKM(newValue) {
        _revsPerKM = newValue;
    }
    function getTyreWidthOverride() {
        return _tyreWidthOverride;
    }
    function setTyreWidthOverride(newValue) {
        _tyreWidthOverride = newValue;
    }
    // function getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) {
    //     if (getRaised() || (raiseSteerablePusherOrTagForWheelbaseTurning && (getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && getSteering()) || (forceAddedRaised && isAdded())) {
    //         return 0;
    //     } else {
    //         //return getManufacturerRating();
    //         if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
    //             return getSuspensionMassRating();
    //         } else {
    //             if (getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //                 return getDesiredWeight();
    //             } else {
    //                 //return getGrossAxleWeightRating();
    //                 return getSuspensionMassRating();
    //             }
                
    //         }
    //     }
    // }
    function getTypeAsString(optionalTypeNumber) {
        var typeValToUse = optionalTypeNumber || _type;
        switch (typeValToUse) {
            case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                return config.getTranslationText('2856');
            case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                return config.getTranslationText('322');
            case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                return config.getTranslationText('314');
            case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                return config.getTranslationText('467');
            case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                return config.getTranslationText('410');
        }
        return config.getTranslationText('4894');
    }
    function getFlatspotOffset() {
        return _flatspotOffset || 0;
    }
    function setFlatspotOffset(newValue) {
        _flatspotOffset = newValue;
    }
    function getAverageCostOfTyre() {
        return _averageCostOfTyre;
    }
    function setAverageCostOfTyre(newValue) {
        _averageCostOfTyre = newValue;
    }
    function getAverageLifeOfTyre() {
        return _averageLifeOfTyre;
    }
    function setAverageLifeOfTyre(newValue) {
        _averageLifeOfTyre = newValue;
    }
    function getNoOfTyresOnAxleForCosting() {
        return _noOfTyresOnAxleForCosting;
    }
    function setNoOfTyresOnAxleForCosting(newValue) {
        _noOfTyresOnAxleForCosting = newValue;
    }
    function getNoOfTyresOnAxleForCostingOverride() {
        return _noOfTyresOnAxleForCostingOverride;
    }
    function setNoOfTyresOnAxleForCostingOverride(newValue) {
        _noOfTyresOnAxleForCostingOverride = newValue;
    }
    function getCostPerKm() {
        if (getAverageLifeOfTyre() > 0) {
            return (getAverageCostOfTyre() / getAverageLifeOfTyre()) * getNoOfTyresOnAxleForCosting() * 100;
        } else {
            return 0;
        }
    }
    function getCostPerDistance(measurementSystem) {
        if (getAverageLifeOfTyre() > 0) {
            return (getAverageCostOfTyre() / globals.getAverageLifeOfTyresValueForMeasurementSystem(getAverageLifeOfTyre(), measurementSystem)) * getNoOfTyresOnAxleForCosting() * 100;
        } else {
            return 0;
        }
    }
    function getDesiredWeight() {
        return _desiredWeight;
    }
    function setDesiredWeight(newValue) {
        _desiredWeight = newValue;
    }
    function getDesiredWeightOverride() {
        return _desiredWeightOverride;
    }
    function setDesiredWeightOverride(newValue) {
        _desiredWeightOverride = newValue;
    }
    function getIsAxleGroup() {
        return _isAxleGroup;
    }
    function setIsAxleGroup(newValue) {
        _isAxleGroup = newValue;
    }
    function getAxleGroupLocation() {
        return _axleGroupLocation;
    }
    function setAxleGroupLocation(newValue) {
        _axleGroupLocation = newValue;
    }
    function getRelatedAxleIds() {
        return _relatedAxleIds;
    }
    function setRelatedAxleIds(newValue) {
        _relatedAxleIds = newValue;
    }
    function getBridgeFormulaPermissible() {
        return _bridgeFormulaPermissible;
    }
    function setBridgeFormulaPermissible(newValue) {
        _bridgeFormulaPermissible = newValue;
    }
    function getBridgeFormulaPermissibleOverride() {
        return _bridgeFormulaPermissibleOverride;
    }
    function setBridgeFormulaPermissibleOverride(newValue) {
        _bridgeFormulaPermissibleOverride = newValue;
    }
    function getGrossAxleWeightRating() {
        return _grossAxleWeightRating;
    }
    function setGrossAxleWeightRating(newValue) {
        _grossAxleWeightRating = newValue;
    }        
    function getGrossAxleWeightRatingOverride() {
        return _grossAxleWeightRatingOverride;
    }
    function setGrossAxleWeightRatingOverride(newValue) {
        _grossAxleWeightRatingOverride = newValue;
    }
    function getAxleGroupIncludesAddedAxle() {
        return _axleGroupIncludesAddedAxle;
    }
    function setAxleGroupIncludesAddedAxle(newValue) {
        _axleGroupIncludesAddedAxle = newValue;
    }
    function getPercentageOfWeightCarried() {
        return _percentageOfWeightCarried;
    }
    function setPercentageOfWeightCarried(newValue) {
        _percentageOfWeightCarried = newValue;
    }        
    function getPercentageOfWeightCarriedOverride() {
        return _percentageOfWeightCarriedOverride;
    }
    function setPercentageOfWeightCarriedOverride(newValue) {
        _percentageOfWeightCarriedOverride = newValue;
    }

    function getPermissibleDetailed() {
        // Lower of getManufacturerRating, getSuspensionMassRating, getTyreMassRating and getBridgeFormulaPermissible
        var returnValue = getManufacturerRating();
        if (returnValue > getSuspensionMassRating()) {
            returnValue = getSuspensionMassRating();
        }
        if (getNoOfTyresOnAxle() === 2) {
            if (returnValue > getTyreMassRatingSingle()) {
                returnValue = getTyreMassRatingSingle();
            }
        } else {
            if (returnValue > getTyreMassRatingDual()) {
                returnValue = getTyreMassRatingDual();
            }
        }
        
        if (returnValue > getBridgeFormulaPermissible()) {
            returnValue = getBridgeFormulaPermissible();
        }
        return returnValue;
    }
    function getPermissibleSimplified() {
        // Lower of getBridgeFormulaPermissible or GAWR
        var returnValue = getGrossAxleWeightRating();
        if (returnValue > getBridgeFormulaPermissible()) {
            returnValue = getBridgeFormulaPermissible();
        }
        return returnValue;
    }
    
    function canCalculateSteeringAngleForPusher() {
        return getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && getSteering() && !getRaised();
    }

    function canCalculateSteeringAngleForTag() {
        return getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && getSteering() && !getRaised();
    }

    function clone(keepSameId) {
        var cloneId;
        if (keepSameId && keepSameId === true) {
            cloneId = _id;
        } else {
            cloneId = globals.nextId();
        }

        return new Axle(cloneId, 
            _parent, 
            _number, 
            _type,
            _added,
            _steering, 
            _lifting,
            _liftingMaxTravel,
            _manufacturerRating,
            _manufacturerRatingOverride,
            _axleWeight,
            _axleWeightOverride,
            _suspensionMassRating,
            _suspensionMassRatingOverride,
            _tyreMassRatingDual,
            _tyreMassRatingDualOverride,
            _tyreMassRatingSingle,
            _tyreMassRatingSingleOverride,
            _raised,
            _tyreRadius, 
            _tyreWidth, 
            _noOfTyresOnAxle, 
            _drivenAxle, 
            _standardRatio, 
            _revsPerKM, 
            _tyreWidthOverride, 
            _vehicleId,
            _averageCostOfTyre,
            _averageLifeOfTyre,
            _noOfTyresOnAxleForCosting,
            _noOfTyresOnAxleForCostingOverride,
            _desiredWeight,
            _desiredWeightOverride,
            _bridgeFormulaPermissible,
            _bridgeFormulaPermissibleOverride,
            _grossAxleWeightRating,
            _grossAxleWeightRatingOverride,
            _percentageOfWeightCarried,
            _percentageOfWeightCarriedOverride,
            _tyreStaticLoadedRadius
        );
    }

    function returnObjectToBeSaved() {
        return {
            "Type": getType(),
            "Added": isAdded(),
            "AxleWeight": getAxleWeight(),
            "AxleWeightOverride": getAxleWeightOverride(),
            "ManufacturerRating": getManufacturerRating(),
            "ManufacturerRatingOverride": getManufacturerRatingOverride(),
            "SuspensionMassRating": getSuspensionMassRating(),
            "SuspensionMassRatingOverride": getSuspensionMassRatingOverride(),
            "TyreMassRatingDual": getTyreMassRatingDual(),
            "TyreMassRatingDualOverride": getTyreMassRatingDualOverride(),
            "TyreMassRatingSingle": getTyreMassRatingSingle(),
            "TyreMassRatingSingleOverride": getTyreMassRatingSingleOverride(),
            "SteeringAxle": getSteering(),
            "LiftingAxle": getLifting(),
            "MaxLiftingTravel": getLiftingMaxTravel(),
            "AxleRaised": getRaised(),
            "NoOfTyresOnAxle": getNoOfTyresOnAxle(),
            "TyreRadius": getTyreRadius(),
            "TyreWidth": getTyreWidth(),
            "Number": getNumber(),
            "ParentType": getParent(),
            "DrivenAxle": getDrivenAxle(),
            "StandardRatio": getStandardRatio(),
            "RevsPerKM": getRevsPerKM(),
            "TyreWidthOverride": getTyreWidthOverride(),
            "AverageCostOfTyre": getAverageCostOfTyre(),
            "AverageLifeOfTyre": getAverageLifeOfTyre(),
            "NoOfTyresOnAxleForCosting": getNoOfTyresOnAxleForCosting(),
            "NoOfTyresOnAxleForCostingOverride": getNoOfTyresOnAxleForCostingOverride(),
            "DesiredWeight": getDesiredWeight(),
            "DesiredWeightOverride": getDesiredWeightOverride(),
            "BridgeFormulaPermissible": getBridgeFormulaPermissible(),
            "BridgeFormulaPermissibleOverride": getBridgeFormulaPermissibleOverride(),
            "GrossAxleWeightRating": getGrossAxleWeightRating(),
            "GrossAxleWeightRatingOverride": getGrossAxleWeightRatingOverride(),
            "PercentageOfWeightCarried": getPercentageOfWeightCarried(),
            "PercentageOfWeightCarriedOverride": getPercentageOfWeightCarriedOverride(),
            "TyreStaticLoadedRadius": getTyreStaticLoadedRadius()
        };
    }

    function returnAppDataObjectToBeSaved() {
        /*
        .Id = maxAxlePositionId
                    .Driven = incomingPosition.Driven
                    .Fk_Vehicle_Id = vehicleId
                    .Lifting = incomingPosition.Lifting
                    .Position_Number = incomingPosition.Position_Number
                    .Steering = incomingPosition.Steering
                    .Type = incomingPosition.Position_Type
                    .Update_Counter = 0
        */
        return {
            "Driven": getDrivenAxle(),
            "Lifting": getLifting(),
            "Position_Number": getNumber(),
            "Steering": getSteering(),
            "Position_Type": getType()
        };
    }

    function getIntercomMetadata() {
        var returnObject = {};
        returnObject.AxleType = getTypeAsString();
        returnObject.ChassisType = _parent;

        return returnObject;
    }

    /**
     * Returns the lesser of manufacturer rating, suspension mass rating and tyre mass rating
     * @returns {number} - Value to be used to set Gross Axle Weight Rating value
     * */
    function getGrossAxleWeightRatingDefaultValue() {
        return globals.getGrossAxleWeightRatingDefaultValue(getManufacturerRating(), getSuspensionMassRating(), getTyreMassRatingDual(), getTyreMassRatingSingle(), getNoOfTyresOnAxle());
        // var returnValue = getManufacturerRating();

        // if (returnValue > getSuspensionMassRating()) {
        //     returnValue = getSuspensionMassRating();
        // }

        // if (returnValue > getTyreMassRating()) {
        //     returnValue = getTyreMassRating();
        // }
        // return returnValue;
    }

    function doesAxleGroupContainMoreThanOneAxle() {
        if (getIsAxleGroup()) {
            if (getRelatedAxleIds().length > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    //#endregion Getters and setters

    //#region Initialize
    setId(id);
    setParent(parent);
    setNumber(number);
    setType(type);
    setAdded(added);
    setSteering(steering);
    setLifting(lifting);
    setLiftingMaxTravel(liftingMaxTravel);
    setManufacturerRating(manufacturerRating);
    setManufacturerRatingOverride(manufacturerRatingOverride);
    setAxleWeight(axleWeight);
    setAxleWeightOverride(axleWeightOverride);
    setSuspensionMassRating(suspensionMassRating);
    setSuspensionMassRatingOverride(suspensionMassRatingOverride);
    setTyreMassRatingDual(tyreMassRatingDual);
    setTyreMassRatingDualOverride(tyreMassRatingDualOverride);
    setTyreMassRatingSingle(tyreMassRatingSingle);
    setTyreMassRatingSingleOverride(tyreMassRatingSingleOverride);
    setTyreRadius(tyreRadius);
    setTyreStaticLoadedRadius(tyreStaticLoadedRadius);
    setTyreWidth(tyreWidth);
    setNoOfTyresOnAxle(noOfTyresOnAxle);
    setRaised(raised);
    setDrivenAxle(drivenAxle);
    setStandardRatio(standardRatio);
    setRevsPerKM(revsPerKM);
    setVehicleId(fkDummy);
    setTyreWidthOverride(tyreWidthOverride);
    setAverageCostOfTyre(averageCostOfTyre);
    setAverageLifeOfTyre(averageLifeOfTyre);
    setNoOfTyresOnAxleForCosting(noOfTyresOnAxleForCosting || noOfTyresOnAxle);
    setNoOfTyresOnAxleForCostingOverride(noOfTyresOnAxleForCostingOverride || false);
    setDesiredWeight(desiredWeight);
    setDesiredWeightOverride(desiredWeightOverride);
    setBridgeFormulaPermissible(bridgeFormulaPermissible);
    setBridgeFormulaPermissibleOverride(bridgeFormulaPermissibleOverride);
    setGrossAxleWeightRating(typeof grossAxleWeightRating === 'number' ? grossAxleWeightRating : getGrossAxleWeightRatingDefaultValue());
    setGrossAxleWeightRatingOverride(grossAxleWeightRatingOverride || false);
    setPercentageOfWeightCarried(percentageOfWeightCarried);
    setPercentageOfWeightCarriedOverride(percentageOfWeightCarriedOverride);
    //#endregion Initialize

    //#region Public members
    self.getId = getId;
    self.setId = setId;
    self.getParent = getParent;
    self.setParent = setParent;
    self.getNumber = getNumber;
    self.setNumber = setNumber;
    self.getType = getType;
    self.getSteering = getSteering;
    self.setSteering = setSteering;
    self.getLifting = getLifting;
    self.setLifting = setLifting;
    self.getLiftingMaxTravel = getLiftingMaxTravel;
    self.setLiftingMaxTravel = setLiftingMaxTravel;
    self.getManufacturerRating = getManufacturerRating;
    self.setManufacturerRating = setManufacturerRating;
    self.getManufacturerRatingOverride = getManufacturerRatingOverride;
    self.setManufacturerRatingOverride = setManufacturerRatingOverride;
    self.getAxleWeight = getAxleWeight;
    self.setAxleWeight = setAxleWeight;
    self.getAxleWeightOverride = getAxleWeightOverride;
    self.setAxleWeightOverride = setAxleWeightOverride;
    self.getSuspensionMassRating = getSuspensionMassRating;
    self.setSuspensionMassRating = setSuspensionMassRating;
    self.getSuspensionMassRatingOverride = getSuspensionMassRatingOverride;
    self.setSuspensionMassRatingOverride = setSuspensionMassRatingOverride;
    self.getTyreMassRatingDual = getTyreMassRatingDual;
    self.setTyreMassRatingDual = setTyreMassRatingDual;
    self.getTyreMassRatingDualOverride = getTyreMassRatingDualOverride;
    self.setTyreMassRatingDualOverride = setTyreMassRatingDualOverride;
    self.getTyreMassRatingSingle = getTyreMassRatingSingle;
    self.setTyreMassRatingSingle = setTyreMassRatingSingle;
    self.getTyreMassRatingSingleOverride = getTyreMassRatingSingleOverride;
    self.setTyreMassRatingSingleOverride = setTyreMassRatingSingleOverride;
    self.getTyreRadius = getTyreRadius;
    self.setTyreRadius = setTyreRadius;
    self.getTyreStaticLoadedRadius = getTyreStaticLoadedRadius;
    self.setTyreStaticLoadedRadius = setTyreStaticLoadedRadius;
    self.getTyreWidth = getTyreWidth;
    self.setTyreWidth = setTyreWidth;
    self.getNoOfTyresOnAxle = getNoOfTyresOnAxle;
    self.setNoOfTyresOnAxle = setNoOfTyresOnAxle;
    self.getRaised = getRaised;
    self.setRaised = setRaised;
    self.getVehicleId = getVehicleId;
    self.isAdded = isAdded;
    self.setAdded = setAdded;
    self.getDrivenAxle = getDrivenAxle;
    self.setDrivenAxle = setDrivenAxle;
    self.getStandardRatio = getStandardRatio;
    self.setStandardRatio = setStandardRatio;
    self.getRevsPerKM = getRevsPerKM;
    self.setRevsPerKM = setRevsPerKM;
    // self.getManufacturerRatingForWheelbaseCalculation = getManufacturerRatingForWheelbaseCalculation;
    self.getTypeAsString = getTypeAsString;
    self.returnObjectToBeSaved = returnObjectToBeSaved;
    self.getTyreWidthOverride = getTyreWidthOverride;
    self.setTyreWidthOverride = setTyreWidthOverride;
    self.clone = clone;
    self.getFlatspotOffset = getFlatspotOffset;
    self.setFlatspotOffset = setFlatspotOffset;
    self.getIntercomMetadata = getIntercomMetadata;
    self.returnAppDataObjectToBeSaved = returnAppDataObjectToBeSaved;
    self.getAverageCostOfTyre = getAverageCostOfTyre;
    self.setAverageCostOfTyre = setAverageCostOfTyre;
    self.getAverageLifeOfTyre = getAverageLifeOfTyre;
    self.setAverageLifeOfTyre = setAverageLifeOfTyre;
    self.getNoOfTyresOnAxleForCosting = getNoOfTyresOnAxleForCosting;
    self.setNoOfTyresOnAxleForCosting = setNoOfTyresOnAxleForCosting;
    self.getNoOfTyresOnAxleForCostingOverride = getNoOfTyresOnAxleForCostingOverride;
    self.setNoOfTyresOnAxleForCostingOverride = setNoOfTyresOnAxleForCostingOverride;
    self.getCostPerKm = getCostPerKm;
    self.getCostPerDistance = getCostPerDistance;
    self.getDesiredWeight = getDesiredWeight;
    self.setDesiredWeight = setDesiredWeight;
    self.getDesiredWeightOverride = getDesiredWeightOverride;
    self.setDesiredWeightOverride = setDesiredWeightOverride;
    self.getIsAxleGroup = getIsAxleGroup;
    self.setIsAxleGroup = setIsAxleGroup;
    self.getAxleGroupLocation = getAxleGroupLocation;
    self.setAxleGroupLocation = setAxleGroupLocation;
    self.getRelatedAxleIds = getRelatedAxleIds;
    self.setRelatedAxleIds = setRelatedAxleIds;
    self.getBridgeFormulaPermissible = getBridgeFormulaPermissible;
    self.setBridgeFormulaPermissible = setBridgeFormulaPermissible;
    self.getBridgeFormulaPermissibleOverride = getBridgeFormulaPermissibleOverride;
    self.setBridgeFormulaPermissibleOverride = setBridgeFormulaPermissibleOverride;
    self.getPermissibleDetailed = getPermissibleDetailed;
    self.getPermissibleSimplified = getPermissibleSimplified;
    self.getGrossAxleWeightRating = getGrossAxleWeightRating;
    self.setGrossAxleWeightRating = setGrossAxleWeightRating;
    self.getGrossAxleWeightRatingOverride = getGrossAxleWeightRatingOverride;
    self.setGrossAxleWeightRatingOverride = setGrossAxleWeightRatingOverride;
    self.getAxleGroupIncludesAddedAxle = getAxleGroupIncludesAddedAxle;
    self.setAxleGroupIncludesAddedAxle = setAxleGroupIncludesAddedAxle;
    self.doesAxleGroupContainMoreThanOneAxle = doesAxleGroupContainMoreThanOneAxle;
    self.getTyreMassRatingOverride = getTyreMassRatingOverride;
    self.getGrossAxleWeightRatingDefaultValue = getGrossAxleWeightRatingDefaultValue;

    self.getTyreMassRating = getTyreMassRating;
    self.canCalculateSteeringAngleForPusher = canCalculateSteeringAngleForPusher;
    self.canCalculateSteeringAngleForTag = canCalculateSteeringAngleForTag;
    self.getPercentageOfWeightCarried = getPercentageOfWeightCarried;
    self.setPercentageOfWeightCarried = setPercentageOfWeightCarried;
    self.getPercentageOfWeightCarriedOverride = getPercentageOfWeightCarriedOverride;
    self.setPercentageOfWeightCarriedOverride = setPercentageOfWeightCarriedOverride;
    //#endregion Public members
};

export default Axle;

