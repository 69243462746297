<template>
    <div class="menu-select-div">
        <select :id="select.id"
            :title="select.errorMessageObv.value"
            :data-original-title="select.errorMessageObv.value"
            v-model="select.valueObv.value"
            data-toggle="tooltip">
            <option v-for="option in select.displayArray.value" :key="option" :value="option.id">
                {{ option.description }}</option>
        </select>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: inputType === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }"></div>
        /ko
        ko if: inputType === $root.config.INPUT_TYPE.UPDOWNBOX
        <div class="override-updownbox-div" data-bind="template: { name: 'template-menu-upDownBox-element' }, css: customCss"></div>
        /ko
        ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP
        ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU
        <div class="override-updownbox-div override-menuInputGroup-upDownBoxAndSelect-div" data-bind="template: { name: 'template-menu-menuInputGroup-upDownBoxAndSelect-element' }, css: customCss"></div>
        /ko
        /ko
    </script> -->

    <!-- <script type="text/html" id="template-menu-select-element">
        <select data-bind="options: displayArray, optionsText: 'description', optionsValue: 'id', value: valueObv, attr: {id: id, title: errorMessageObv, 'data-original-title': errorMessageObv}" data-toggle="tooltip"></select>
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const select = toRef(props, "valueFromParent");

        return {
            select
        }
    }
}
</script>

<style lang="scss" scoped>

</style>