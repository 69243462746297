

var ComplianceDashboardItem = function(type) {

    this._type = type;
    this._id;
    this._backgroundColour;
    this._interaction;
    

    return this;
}

ComplianceDashboardItem.prototype.getType = function () {
    return this._type;
}

ComplianceDashboardItem.prototype.setId = function (newValue) {
    this._id = newValue;
    return this;
}

ComplianceDashboardItem.prototype.getId = function () {
    return this._id;
}

ComplianceDashboardItem.prototype.setBackgroundColour = function (newValue) {
    this._backgroundColour = newValue;
    return this;
}

ComplianceDashboardItem.prototype.getBackgroundColour = function () {
    return this._backgroundColour;
}

ComplianceDashboardItem.prototype.setInteraction = function (newValue, gmInteractionChainCallback) {
    this._interaction = newValue;
    if (gmInteractionChainCallback) {
        this._interaction.callbacks = gmInteractionChainCallback(this._interaction.callbacks);
    }
    return this;
}

ComplianceDashboardItem.prototype.getInteraction = function () {
    return this._interaction;
}

ComplianceDashboardItem.prototype.getCustomClickableColour = function () {
    return this._customClickableColour;
}

ComplianceDashboardItem.prototype.setCustomClickableColour = function (newValue) {
    this._customClickableColour = newValue;
    return this;
}



export default ComplianceDashboardItem;

      