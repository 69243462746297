import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';


var Payload = function (id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel,
                        status, number, startX, startY, startZ, length, width, height, mass, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, verticalCOGConfirmed, lateralCOGConfirmed,
                        specifyMassOverride, payload, unitOfMeasure, density, payloadType, verticalCOGType, lateralCOGType, weightType, sourceDatabaseId, updateCounter, cached) {
    
    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached);

    //var accessoryType = config.ACCESSORY_TYPES.BODY;
    
    //#region Private members
    this._status = status;
    this._number = number;
    this._startX = startX;
    this._startY = startY;
    this._startZ = startZ;
    this._length = length;
    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._specifyMassOverride = specifyMassOverride || false;
    this._payload = payload;
    this._unitOfMeasure = unitOfMeasure;
    this._density = density;
    this._payloadType = payloadType;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    this._weightType = weightType;
    
    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (source === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(this) && payloadType === config.PAYLOAD_TYPES.DETAILED)) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._lateralCOGOverride = lateralCOGOverride;
        
    }

    //#endregion Private members

    return this;
}
    //#region Getters and setters
    //function getParent() {
    //    return _parent;
    //}
    //function setParent(newValue) {
    //    _parent = newValue;
//}
Payload.prototype.getStatus = function () {
    return this._status;
}
Payload.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Payload.prototype.getNumber = function() {
    return this._number;
}
Payload.prototype.setNumber = function(newValue) {
        //_number = newValue;
    this._number = newValue;
}
    //function getDescription() {
    //    return _description;
    //}
    //function setDescription(newValue) {
    //    _description = newValue;
    //}
Payload.prototype.getStartX = function() {
    return this._startX;
}
Payload.prototype.setStartX = function(newValue) {
    //_startX = newValue;
    this._startX = newValue;
}
Payload.prototype.getStartY = function() {
    return this._startY;
}
Payload.prototype.setStartY = function(newValue) {
    //_startY = newValue;
    this._startY = newValue;
}
Payload.prototype.getStartZ = function() {
    return this._startZ;
}
Payload.prototype.setStartZ = function(newValue) {
    //_startZ = newValue;
    this._startZ = newValue;
}
Payload.prototype.getLength = function() {
    return this._length;
}
Payload.prototype.setLength = function(newValue) {
    //_length = newValue;
    this._length = newValue;
}
Payload.prototype.getWidth = function() {
    return this._width;
}
Payload.prototype.setWidth = function(newValue) {
    //_width = newValue;
    this._width = newValue;
}
Payload.prototype.getHeight = function() {
    return this._height;
}
Payload.prototype.setHeight = function(newValue) {
    //_height = newValue;
    this._height = newValue;
}
Payload.prototype.getMass = function () {
    if (this._payloadType === config.PAYLOAD_TYPES.SIMPLE) {
        return this._mass;
    } else {
        if (this._weightType === config.PAYLOAD_WEIGHT_TYPES.WEIGHT) {
            return this._mass;
        } else {
            return this._payload * this._density;
        }
        
    }
    
}

Payload.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this.getMass(), distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Payload.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Payload.prototype.getTotalLoad = function () {
    return this.getMass();
}

Payload.prototype.setMass = function(newValue) {
    this._mass = newValue;
}
Payload.prototype.getCOGType = function() {
    return this._cOGType;
}
Payload.prototype.setCOGType = function(newValue) {
    this._cOGType = newValue;
}
Payload.prototype.getHorizontalCOG = function() {
    return this._horizontalCOG;
}
Payload.prototype.setHorizontalCOG = function(newValue) {
    this._horizontalCOG = newValue;
}
Payload.prototype.getHorizontalCOGOverride = function() {
    return this._horizontalCOGOverride;
}
Payload.prototype.setHorizontalCOGOverride = function(newValue) {
    this._horizontalCOGOverride = newValue;
}
Payload.prototype.getVerticalCOG = function() {
    return this._verticalCOG;
}
Payload.prototype.setVerticalCOG = function(newValue) {
    this._verticalCOG = newValue;
}
Payload.prototype.getVerticalCOGOverride = function() {
    return this._verticalCOGOverride;
}
Payload.prototype.setVerticalCOGOverride = function(newValue) {
    this._verticalCOGOverride = newValue;
}
Payload.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Payload.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Payload.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Payload.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}

Payload.prototype.getVerticalCOGConfirmed = function() {
    return this._verticalCOGConfirmed;
}
Payload.prototype.setVerticalCOGConfirmed = function(newValue) {
    this._verticalCOGConfirmed = newValue;
}
Payload.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Payload.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Payload.prototype.getActualVerticalCOG = function() {
    //If _COGType = ApplicationCOGTypes.Length Then
    //Return _VerticalCOG
    //Else
    //Return CLng(_VerticalCOG * _Height / 100)
    //End If
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * this._height / 100;
    }
}

Payload.prototype.getActualLateralCOG = function () {
    
    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this._lateralCOG * this._width / 100;
    }
}

Payload.prototype.getActualHorizontalCOG = function() {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    } else {
        return this._horizontalCOG * this._length / 100;
    }
}
    
Payload.prototype.getActualHorizontalCOGFromFrontOfDeck = function() {
    // Return _StartX + ActualHorizontalCOG
    return this._startX + this.getActualHorizontalCOG();
}

Payload.prototype.getActualVerticalCOGFromBottomOfDeck = function() {
    return this._startY + this.getActualVerticalCOG();
}

Payload.prototype.getActualLateralCOGFromSideOfDeck = function () {
    return this._startZ + this.getActualLateralCOG();
}

Payload.prototype.setSpecifyMassOverride = function(newValue) {
    this._specifyMassOverride = newValue;
}
Payload.prototype.getSpecifyMassOverride = function() {
    return this._specifyMassOverride;
}

Payload.prototype.getPayload = function () {
    return this._payload;
}

Payload.prototype.setPayload = function (newValue) {
    this._payload = newValue;
}

Payload.prototype.getUnitOfMeasure = function () {
    return this._unitOfMeasure;
}

Payload.prototype.setUnitOfMeasure = function (newValue) {
    this._unitOfMeasure = newValue;
}

Payload.prototype.getDensity = function () {
    return this._density;
}

Payload.prototype.setDensity = function (newValue) {
    this._density = newValue;
}

Payload.prototype.getPayloadType = function () {
    return this._payloadType;
}

Payload.prototype.setPayloadType = function (newValue) {
    this._payloadType = newValue;
}
Payload.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Payload.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Payload.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Payload.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Payload.prototype.getWeightType = function () {
    return this._weightType;
}
Payload.prototype.setWeightType = function (newValue) {
    this._weightType = newValue;
}
/**
 * Get the payload JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Payload.prototype.getPayloadObjectToBeSaved = function (includePositionValues) {

    return {
        "StartX": includePositionValues === true ? this._startX : 0,
        "StartY": includePositionValues === true ? this._startY : 0,
        "StartZ": includePositionValues === true ? this._startZ : 0,
        "Length": this._length,
        "Width": this._width,
        "Height": this._height,
        "Mass": this._mass,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "SpecifyMassOverride": this._specifyMassOverride,
        "Payload": this._payload,
        "UnitOfMeasure": this._unitOfMeasure,
        "Density": this._density,
        "PayloadType": this._payloadType,
        "Status": this._status,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType,
        "WeightType": this._weightType
    };
}
/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Payload.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getPayloadObjectToBeSaved(includePositionValues), includePositionValues);
}
Payload.prototype.clone = function(keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }

    var newPayload = new Payload(
            cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._status,
        this._number, 
        this._startX, 
        this._startY, 
        this._startZ, 
        this._length, 
        this._width, 
        this._height, 
        this._mass, 
        this._cOGType, 
        this._horizontalCOG, 
        this._horizontalCOGOverride, 
        this._verticalCOG, 
        this._verticalCOGOverride,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this._specifyMassOverride,
        this._payload,
        this._unitOfMeasure,
        this._density,
        this._payloadType,
        this._verticalCOGType,
        this._lateralCOGType,
        this._weightType,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached());

    newPayload.setAccessLevel(this.getAccessLevel());
    newPayload.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newPayload.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());

    return newPayload;
}

Payload.prototype.update = function (payload) {
    this.updateBase(payload);
    this.setStatus(payload.getStatus());
    this.setNumber(payload.getNumber()); 
    this.setStartX(payload.getStartX()); 
    this.setStartY(payload.getStartY()); 
    this.setStartZ(payload.getStartZ()); 
    this.setLength(payload.getLength()); 
    this.setWidth(payload.getWidth()); 
    this.setHeight(payload.getHeight()); 
    this.setMass(payload.getMass()); 
    this.setCOGType(payload.getCOGType()); 
    this.setHorizontalCOG(payload.getHorizontalCOG()); 
    this.setHorizontalCOGOverride(payload.getHorizontalCOGOverride()); 
    this.setVerticalCOG(payload.getVerticalCOG()); 
    this.setVerticalCOGOverride(payload.getVerticalCOGOverride());
    this.setLateralCOG(payload.getLateralCOG());
    this.setLateralCOGOverride(payload.getLateralCOGOverride());
    this.setVerticalCOGConfirmed(payload.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(payload.getLateralCOGConfirmed());
    this.setSpecifyMassOverride(payload.getSpecifyMassOverride());
    this.setPayload(payload.getPayload());
    this.setUnitOfMeasure(payload.getUnitOfMeasure());
    this.setDensity(payload.getDensity());
    this.setPayloadType(payload.getPayloadType());
    this.setVerticalCOGType(payload.getVerticalCOGType());
    this.setLateralCOGType(payload.getLateralCOGType());
    this.setWeightType(payload.getWeightType());
}

    //#endregion Getters and setters

    //#region Initialize
    //setParent(parent);
    //setNumber(number);
    //setDescription(description);
    //setStartX(startX);
    //setStartY(startY);
    //setStartZ(startZ);
    //setLength(length);
    //setWidth(width);
    //setHeight(height);
    //setMass(mass);
    //setCOGType(cOGType);
    //setHorizontalCOG(horizontalCOG);
    //setHorizontalCOGOverride(horizontalCOGOverride);
    //setVerticalCOG(verticalCOG);
    //setVerticalCOGOverride(verticalCOGOverride);
    //setVerticalCOGConfirmed(verticalCOGConfirmed);
    //setSpecifyMassOverride(specifyMassOverride || false);
    ////#endregion Initialize

    ////#region Public members
    
    //self.setNumber = setNumber;
    //self.getNumber = getNumber;
    //self.setDescription = setDescription;
    //self.getDescription = getDescription;
    //self.setStartX = setStartX;
    //self.getStartX = getStartX;
    //self.setStartY = setStartY;
    //self.getStartY = getStartY;
    //self.setStartZ = setStartZ;
    //self.getStartZ = getStartZ;
    //self.setLength = setLength;
    //self.getLength = getLength;
    //self.setWidth = setWidth;
    //self.getWidth = getWidth;
    //self.setHeight = setHeight;
    //self.getHeight = getHeight;
    //self.setMass = setMass;
    //self.getMass = getMass;
    //self.setSpecifyMassOverride = setSpecifyMassOverride;
    //self.getSpecifyMassOverride = getSpecifyMassOverride;
    //self.setCOGType = setCOGType;
    //self.getCOGType = getCOGType;
    //self.setHorizontalCOG = setHorizontalCOG;
    //self.getHorizontalCOG = getHorizontalCOG;
    //self.setHorizontalCOGOverride = setHorizontalCOGOverride;
    //self.getHorizontalCOGOverride = getHorizontalCOGOverride;
    //self.setVerticalCOG = setVerticalCOG;
    //self.getVerticalCOG = getVerticalCOG;
    //self.setVerticalCOGOverride = setVerticalCOGOverride;
    //self.getVerticalCOGOverride = getVerticalCOGOverride;
    //self.getVerticalCOGConfirmed = getVerticalCOGConfirmed;
    //self.getActualVerticalCOG = getActualVerticalCOG;
    //self.getActualHorizontalCOG = getActualHorizontalCOG;
    //self.getActualHorizontalCOGFromFrontOfDeck = getActualHorizontalCOGFromFrontOfDeck;
    //self.getActualVerticalCOGFromBottomOfDeck = getActualVerticalCOGFromBottomOfDeck;
    //self.returnObjectToBeSaved = returnObjectToBeSaved;
    //self.clone = clone;
    ////#endregion Public members
//};

utils.extend(Accessory, Payload);

export default Payload;

