<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox user-settings-modal" :class="{ 'french-text-container': handleFrenchText }">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span>{{getTranslationText('422')}}</span>
                </h4>
            </div>
            <div class="modal-body">
                <ul id="user-settings-ul" class="nav nav-tabs" role="tablist">
                    <li v-if="legislationItems.length > 0"
                        class="nav-item">
                        <a :title="getTranslationText('2587')"
                            class="nav-link"
                            data-toggle="tab"
                            href="#legislation"
                            data-bind="text: getTranslationText('2587'), attr: { title: getTranslationText('2587') }">
                            {{getTranslationText('2587')}}
                        </a>
                    </li>
                    <li v-if="preferenceItems.length > 0"
                        class="nav-item">
                        <a :title="getTranslationText('1418')"
                            class="nav-link"
                            data-toggle="tab"
                            href="#preferences"
                            data-bind="text: getTranslationText('1418'), attr: { title: getTranslationText('1418') }">
                            {{getTranslationText('1418')}}
                        </a>
                    </li>
                    <li v-if="measurementItems.length > 0"
                        class="nav-item">
                        <a :title="getTranslationText('241')"
                            class="nav-link"
                            data-toggle="tab"
                            href="#measurement"
                            data-bind="text: getTranslationText('241'), attr: { title: getTranslationText('241') }">
                            {{getTranslationText('241')}}
                        </a>
                    </li>
                    <li v-if="reportingItems.length > 0"
                        class="nav-item">
                        <a :title="getTranslationText('4220')"
                            class="nav-link"
                            data-toggle="tab"
                            href="#reporting"
                            data-bind="text: getTranslationText('4220'), attr: { title: getTranslationText('4220') }">
                            {{getTranslationText('4220')}}
                        </a>
                    </li>
                    <li v-if="integrationItems.length > 0"
                        class="nav-item">
                        <a :title="getTranslationText('1202')"
                            class="nav-link"
                            data-toggle="tab"
                            href="#integration"
                            data-bind="text: getTranslationText('1202'), attr: { title: getTranslationText('1202') }">
                            {{getTranslationText('1202')}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="legislation" class="tab-pane array-container">
                        <InputTypesTable :itemsFromParent="legislationItems" :classFromParent="classesForInputTypeTables"></InputTypesTable>
                    </div>
                    <div id="preferences" class="tab-pane array-container">
                        <InputTypesTable :itemsFromParent="preferenceItems" :classFromParent="classesForInputTypeTables"></InputTypesTable>
                    </div>
                    <div id="measurement" class="tab-pane array-container">
                        <InputTypesTable :itemsFromParent="measurementItems" :classFromParent="classesForInputTypeTables"></InputTypesTable>
                    </div>
                    <div id="reporting" class="tab-pane array-container">
                        <InputTypesTable :itemsFromParent="reportingItems" :classFromParent="classesForInputTypeTables"></InputTypesTable>
                    </div>
                    <div v-if="integrationItems.length > 0"
                        id="integration"
                        class="tab-pane array-container">
                        <InputTypesTable :itemsFromParent="integrationItems" :classFromParent="classesForInputTypeTables"></InputTypesTable>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="ok"
                    class="btn btn-default btn-truckscience btn-primary" data-bind="click: ok, touchstart: ok">
                    <span>{{getTranslationText('271')}}</span>
                </button>
                <button @click="close"
                    class="btn btn-default btn-truckscience" data-bind="click: close, touchstart: close">
                    <span>{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import offerManager from '@/services/offerManager';
import settings from '@/services/settings/settings';
import logger from '@/services/logger';

import MultiSelect from '@/services/multiselect/multiselect';
import MultiSelectBuilder from '@/services/multiselect/multiselectBuilder';
import { shallowRef, computed, ref, onMounted, onUpdated, toRef, watch, nextTick, watchEffect } from 'vue';
import Globalize from '@/assets/scripts/globalize/globalize';

import PopupModal from '../PopupModal.vue';
// import InputTypesTable from './InputTypesTable.vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import bus from '@/main';


// #region Variables
const $ = window.$;
const TScMessenger = window.TScMessenger;
const WebuiPopovers = window.WebuiPopovers;

settings.setOfferManager(offerManager);

let resizeTimeoutId;
// #endregion Prototype Functions

export default {
    name: 'SettingsModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);

        const preferenceItems = shallowRef([]);
        const measurementItems = shallowRef([]);
        const legislationItems = shallowRef([]);
        const integrationItems = shallowRef([]);
        const reportingItems = shallowRef([]);
        const triggerSubscriptionUpdates = shallowRef();
        const displayReportViewTable = shallowRef(false);

        const DELETE_FUNCTION_PARAM_TYPE = {
            ID: 'id',
            ROW: 'row'
        };

        let activeOffer = offerManager.getActiveOffer();
        let path = config.OBJECT_TYPES.SETTINGS,
            closeCallback,
            targetTab,
            
            licenceCategories,
            activeSettingsObject,
            originalSettingsObject,
            
            
            longSelectMenuWidth = 325,
            multiSelectWidth = 325,
            changedSettingNames,
            _activeMeasurementSystem,
            _activeLegislations,
            _activeLegislation,
            translateValue = false,
            isTargetTabValid = false,
            isCostingAvailable = true,
            resolvePromise,
            rejectPromise;

        closeCallback = opts.value.callback;
        targetTab = opts.value.modalObject.tabToOpen;
        
        licenceCategories = opts.value.modalObject.licenceCategories;

        const classesForInputTypeTables = "specification-modal-table user-settings-table";

        function show() {


            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
        }

        onMounted(() => {
            popup.value.open();
            $.when(activate()).done(function(){
                // nextTick(compositionComplete);
                nextTick(compositionComplete);
            });
        })

        onUpdated(() => {
            TScMessenger.writeDebugMessage('')
        })


        // #endregion Variables

        // #region Getters and Setters
        function getActiveMeasurementSystem() {
            return _activeMeasurementSystem;
        }

        function setActiveMeasurementSystem(value) {
            _activeMeasurementSystem = value;
        }

        

        function getActiveLegislation() {
        return _activeLegislation;
    }

        function getAvailableIntegrationUsingType(typeToGet) {
            var returnObject = settings.getAvailableIntegrations().find(function (element) {
                return element.Type === typeToGet;
            });
            return returnObject !== undefined ? returnObject : null;
        }

        

        /**
         * Get the specify fuel consumption as object from self.preferenceItems array
         * @returns {Object|null} Returns object if found and null if it isn't
         */
        function getFuelConsumptionSelectMenu() {
            return getSettingsObjectUsingId(preferenceItems.value, 'settings-specify-fuel-consumption-as');
        }

        /**
         * Get the settings object from an array using an id identifier
         * @param {Array} array - Array of settings object. Could be self.preferenceItems, self.measurementItems orself.legislationItems
         * @param {string} id - Id used when creating the settings object
         * @returns {Object|null} Returns object if found and null if it isn't
         */
        function getSettingsObjectUsingId(array, id) {
            if (Array.isArray(array)) {
                var settingsObject = array.find(function (item) {
                    return item.id === id;
                });
                if (settingsObject !== undefined) {
                    return settingsObject;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

        // function getLegislationDetails(newLegislation) {
        //     var dfd = $.Deferred();
        //     globals.showSpinner();

        //     dataManager.getLegislationDetails(newLegislation.legislationId, newLegislation.countryId)
        //         .then(function (legislationDetails) {
        //             legislationDetails.roadNames = newLegislation.roadNames;
        //             dfd.resolve(legislationDetails);
        //         })
        //         .fail(function (errorMessage) {
        //             nextTick(function () {
        //                 globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
        //                     .addCallback(function (dialogResult) { });
        //             });

        //             dfd.reject(errorMessage);
        //         });

        //     return dfd.promise();
        // }


        function getInternalStandardsTable() {
            return legislationItems.value.find(function (item) {
                return item.type === config.ITEM_TYPE.MENU_TABLE;
            });
        }

        function getInternalStandardTableRowUsingId(id) {
            return getInternalStandardsTable().rows.value.find(function (row) {
                return row.id === id;
            });
        }

        function getInternalStandardTableRowIndexUsingId(id) {
            var indexToReturn = -1;
            getInternalStandardsTable().rows.value.forEach(function (row, index) {
                if (row.id === id) {
                    indexToReturn = index;
                }
            });

            return indexToReturn;
        }

        function getInternalStandardsForSelect(idsToRemove, idToKeep) {
            var returnArray = [];

            if (typeof idToKeep !== 'number') {
                returnArray.push({
                    id: '',
                    description: ''
                });
            }

            globals.user.getInternalStandards().forEach(function (is) {
                var keepInternalStandardOption = true;

                if (typeof idToKeep === 'number') {
                    if (is.Id !== idToKeep) {
                        idsToRemove.forEach(function (idToRemove) {
                            if (is.Id === idToRemove) {
                                keepInternalStandardOption = false;
                            }
                        });
                    }
                } else {
                    idsToRemove.forEach(function (idToRemove) {
                        if (is.Id === idToRemove) {
                            keepInternalStandardOption = false;
                        }
                    });
                }

                if (keepInternalStandardOption) {
                    let internalStandardOption = {
                        id: is.Id,
                        description: is.Translation
                    };
                    if(internalStandardOption.id === 820 || internalStandardOption.id === 821 || internalStandardOption.id === 822) {
                        let currentCombinationType = globals.getCurrentCombinationType();
                        if(currentCombinationType === config.COMBINATION_TYPES.VEHICLE || currentCombinationType === null) {
                            internalStandardOption.description = internalStandardOption.description + " @ " + config.getTranslationText('5266'); 
                        } else if(currentCombinationType !== config.COMBINATION_TYPES.VEHICLE) {
                            internalStandardOption.description = internalStandardOption.description + " @ " + config.getTranslationText('5267'); 
                        }
                    }
                    returnArray.push(internalStandardOption);
                }
            });

            returnArray.sort(globals.sortDataPointsArray);

            return returnArray;
        }
        // #endregion Getters and Setters

        function selectedApplicationTypeAvailable(availableApplicationTypes, applicationTypeIdToCheck) {
            var selectedApplicationTypeAvailable = false;
            availableApplicationTypes.forEach(function (applicationType) {
                if (applicationType.id === applicationTypeIdToCheck) {
                    selectedApplicationTypeAvailable = true;
                }
            });

            return selectedApplicationTypeAvailable;
        }

        

        

        // #region Durandal Functions
        function activate(data) {
            var dfd = $.Deferred();
            // setUpAvailableIntegrations();
            
            offerManager.setupDefaultValidationConfigs();
            activeOffer = offerManager.getActiveOffer();
            createSettingObjects();
            activeSettingsObject.setUpAvailableLicenceCategories(licenceCategories);
            preferenceItems.value = setupPreferenceItems();
            measurementItems.value = setupMeasurementItems();
            legislationItems.value = setupLegislationItems();
            reportingItems.value = setupReportingItems();

            if (globals.user.displayNteaOptionsToUser()) {
                integrationItems.value = setupIntegrationItems();
            }
            changedSettingNames = '';
            dfd.resolve();

            return dfd.promise();
        }
        function compositionComplete() {
            var isTargetTabValid = false;
            if (typeof targetTab === 'string') {
                isTargetTabValid = Object.keys(config.SETTINGS_MODAL_TABS).some(function (key) {
                    return config.SETTINGS_MODAL_TABS[key] === targetTab;
                });
            }
            $('#user-settings-ul a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                switch (e.target.hash) {
                    case '#preferences':
                    case '#' + config.SETTINGS_MODAL_TABS.PREFERENCES:
                        initializeJsElementsForArray(preferenceItems.value);
                        break;
                    case '#measurement':
                    case '#' + config.SETTINGS_MODAL_TABS.MEASUREMENT:
                        initializeJsElementsForArray(measurementItems.value);
                        break;
                    case '#legislation':
                    case '#' + config.SETTINGS_MODAL_TABS.LEGISLATION:
                        initializeJsElementsForArray(legislationItems.value);
                        break;
                    case '#reporting':
                    case '#' + config.SETTINGS_MODAL_TABS.REPORTING:
                        initializeJsElementsForArray(reportingItems.value);
                        break;
                    case '#integration':
                    case '#' + config.SETTINGS_MODAL_TABS.INTEGRATION:
                        initializeJsElementsForArray(integrationItems.value);
                        break;
                }
            });

            if (isTargetTabValid) {
                $('#user-settings-ul a[href="#' + targetTab + '"]').tab('show');
            } else {
                $('#user-settings-ul a:first').tab('show');
            }

            fixModalPositionToCentre();

            window.addEventListener('resize', onResizeHandler);
            return true;
        }
        // #endregion Durandal Functions

        function fixModalPositionToCentre() {
            // Set the position to absolute to fix the settings modal in the current position when opening
            // CSS positions the modal in the centre of the page automatically
            let settings = $('.messageBox.user-settings-modal');
            $('.messageBox.user-settings-modal').css({
                'position': 'absolute',
                'top': settings.position().top,
                'left': settings.position().left
            });
        }

        function onResizeHandler() {
            $('.messageBox.user-settings-modal').removeAttr('style');
            if (resizeTimeoutId !== undefined) {
                clearTimeout(resizeTimeoutId);
            }
            resizeTimeoutId = setTimeout(fixModalPositionToCentre, 100);
        }

        // #region SettingsObject Functions
        function createSettingObjects() {
            
            setActiveMeasurementSystem(globals.user.getActiveMeasurementSystem());
            if (activeOffer && (activeOffer.getCosting() === undefined || activeOffer.getCosting() === null || !globals.user.hasPermission(config.PERMISSIONS.COSTING.Code))) {
                isCostingAvailable = false;
            } else if (!globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
                isCostingAvailable = false;
            }

            activeSettingsObject = settings.newSettingsObject(offerManager.offerIsOpen.value);

            originalSettingsObject = settings.newSettingsObject(offerManager.offerIsOpen.value);
            
            // if (activeOffer !== '' && activeOffer !== undefined && activeOffer !== null) {
            //     setActiveMeasurementSystem(offerManager.getActiveOffer().getMeasurementSystem());
            //     if (activeOffer.getCosting() === undefined || activeOffer.getCosting() === null || !globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
            //         isCostingAvailable = false;
            //     }

            //     activeSettingsObject = settings.newSettingsObject(globals.user.getActiveMeasurementSystemId(),
            //         activeOffer.getLengthIncrement(),
            //         activeOffer.getMassIncrement(),
            //         activeOffer.getPercentageIncrement(),
            //         globals.user.getActiveLegislationId(),
            //         offerManager.activeApplication.value.id,
            //         globals.user.getColourDrawings(),
            //         globals.user.getShowComplianceScorecard(),
            //         globals.user.getSpecifyWheelbaseAs(),
            //         getAvailableIntegrations(),
            //         globals.user.getSpecifyCabDimensionsAs(),
            //         globals.user.getSpecifyChassisLengthAs(),
            //         globals.user.getSpecifyFuelConsumptionAs(),
            //         globals.user.getSpecifyLicencingRegionAs(),
            //         globals.user.getSpecifyAxleRatingAs(),
            //         globals.user.getDefaultReportPdfPageSize(),
            //         globals.user.getReportLogoForReport(),
            //         globals.user.getDashboardConfiguration().getDashboardDataPointIds(),
            //         globals.user.getDashboardConfiguration().getDashboardLegislationIds(),
            //         globals.user.getDashboardConfiguration().getInternalStandardObjects(),
            //         globals.user.getCompanyAdditionalNote(),
            //         globals.user.getSpecifyBodyPositionAs(),
            //         globals.user.getSpecifyEquipmentPositionAs(),
            //         globals.user.getShowTips(),
            //         globals.user.getReportViewLayout(),
            //         globals.user.getReportViewsObjects(),
            //         globals.user.getNteaUserValidationType());

            //     originalSettingsObject = new SettingsObject(globals.user.getActiveMeasurementSystemId(),
            //         activeOffer.getLengthIncrement(),
            //         activeOffer.getMassIncrement(),
            //         activeOffer.getPercentageIncrement(),
            //         globals.user.getActiveLegislationId(),
            //         offerManager.activeApplication.value.id,
            //         globals.user.getColourDrawings(),
            //         globals.user.getShowComplianceScorecard(),
            //         globals.user.getSpecifyWheelbaseAs(),
            //         JSON.parse(JSON.stringify(getAvailableIntegrations())),
            //         globals.user.getSpecifyCabDimensionsAs(),
            //         globals.user.getSpecifyChassisLengthAs(),
            //         globals.user.getSpecifyFuelConsumptionAs(),
            //         globals.user.getSpecifyLicencingRegionAs(),
            //         globals.user.getSpecifyAxleRatingAs(),
            //         globals.user.getDefaultReportPdfPageSize(),
            //         globals.user.getReportLogoForReport(),
            //         globals.user.getDashboardConfiguration().getDashboardDataPointIds(),
            //         globals.user.getDashboardConfiguration().getDashboardLegislationIds(),
            //         globals.user.getDashboardConfiguration().getInternalStandardObjects(),
            //         globals.user.getCompanyAdditionalNote(),
            //         globals.user.getSpecifyBodyPositionAs(),
            //         globals.user.getSpecifyEquipmentPositionAs(),
            //         globals.user.getShowTips(),
            //         globals.user.getReportViewLayout(),
            //         globals.user.getReportViewsObjects(),
            //         globals.user.getNteaUserValidationType());
            // } else {
            //     setActiveMeasurementSystem(globals.user.getActiveMeasurementSystem());

            //     // Check if active permission is available
            //     if (!globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
            //         isCostingAvailable = false;
            //     }

            //     activeSettingsObject = new SettingsObject(globals.user.getActiveMeasurementSystemId(),
            //         globals.user.getLengthIncrement(),
            //         globals.user.getMassIncrement(),
            //         globals.user.getPercentageIncrement(),
            //         globals.user.getActiveLegislationId(),
            //         globals.user.getDefaultApplication(),
            //         globals.user.getColourDrawings(),
            //         globals.user.getShowComplianceScorecard(),
            //         globals.user.getSpecifyWheelbaseAs(),
            //         getAvailableIntegrations(),
            //         globals.user.getSpecifyCabDimensionsAs(),
            //         globals.user.getSpecifyChassisLengthAs(),
            //         globals.user.getSpecifyFuelConsumptionAs(),
            //         globals.user.getSpecifyLicencingRegionAs(),
            //         globals.user.getSpecifyAxleRatingAs(),
            //         globals.user.getDefaultReportPdfPageSize(),
            //         globals.user.getReportLogoForReport(),
            //         globals.user.getDashboardConfiguration().getDashboardDataPointIds(),
            //         globals.user.getDashboardConfiguration().getDashboardLegislationIds(),
            //         globals.user.getDashboardConfiguration().getInternalStandardObjects(),
            //         globals.user.getCompanyAdditionalNote(),
            //         globals.user.getSpecifyBodyPositionAs(),
            //         globals.user.getSpecifyEquipmentPositionAs(),
            //         globals.user.getShowTips(),
            //         globals.user.getReportViewLayout(),
            //         globals.user.getReportViewsObjects(),
            //         globals.user.getNteaUserValidationType());

            //     originalSettingsObject = new SettingsObject(globals.user.getActiveMeasurementSystemId(),
            //         globals.user.getLengthIncrement(),
            //         globals.user.getMassIncrement(),
            //         globals.user.getPercentageIncrement(),
            //         globals.user.getActiveLegislationId(),
            //         globals.user.getDefaultApplication(),
            //         globals.user.getColourDrawings(),
            //         globals.user.getShowComplianceScorecard(),
            //         globals.user.getSpecifyWheelbaseAs(),
            //         JSON.parse(JSON.stringify(getAvailableIntegrations())),
            //         globals.user.getSpecifyCabDimensionsAs(),
            //         globals.user.getSpecifyChassisLengthAs(),
            //         globals.user.getSpecifyFuelConsumptionAs(),
            //         globals.user.getSpecifyLicencingRegionAs(),
            //         globals.user.getSpecifyAxleRatingAs(),
            //         globals.user.getDefaultReportPdfPageSize(),
            //         globals.user.getReportLogoForReport(),
            //         globals.user.getDashboardConfiguration().getDashboardDataPointIds(),
            //         globals.user.getDashboardConfiguration().getDashboardLegislationIds(),
            //         globals.user.getDashboardConfiguration().getInternalStandardObjects(),
            //         globals.user.getCompanyAdditionalNote(),
            //         globals.user.getSpecifyBodyPositionAs(),
            //         globals.user.getSpecifyEquipmentPositionAs(),
            //         globals.user.getShowTips(),
            //         globals.user.getReportViewLayout(),
            //         globals.user.getReportViewsObjects(),
            //         globals.user.getNteaUserValidationType());
            // }
        }

        // /**
        //  *
        //  * @param {*} measurementSystemId
        //  * @param {*} lengthIncrement
        //  * @param {*} massIncrement
        //  * @param {*} percentageIncrement
        //  * @param {*} legislationId
        //  * @param {*} applicationTypeId
        //  * @param {*} colourDrawings
        //  * @param {*} showComplianceScorecard
        //  * @param {*} specifyWheelbaseAs
        //  * @param {*} integrations
        //  * @param {*} specifyCabDimensionsAs
        //  * @param {*} specifyChassisLengthAs
        //  * @param {*} specifyFuelConsumptionAs
        //  * @param {*} specifyLicencingRegionAs
        //  * @param {*} specifyAxleRatingAs
        //  * @param {*} defaultReportPdfPageSize
        //  * @param {*} reportLogo
        //  * @param {*} dashboardDataPointIds
        //  * @param {*} dashboardLegislationIds
        //  * @param {*} internalStandards
        //  * @param {*} companyAdditionalNote
        //  * @param {*} specifyBodyPositionAs
        //  * @param {*} specifyEquipmentPositionAs
        //  * @param {*} showTips
        //  * @param {string} reportViewLayout - The layout of the report view. Either 'simplified' or 'advanced'
        //  * @param {ReportView[]} reportViews - The report views to be used in the report
        //  * @param {string} nteaUserValidationType - The type of NTEA user validation to use
        //  * @returns {SettingsObject}
        //  */
        // function SettingsObject(measurementSystemId, lengthIncrement, massIncrement, percentageIncrement, legislationId, applicationTypeId, colourDrawings, showComplianceScorecard, specifyWheelbaseAs, integrations, specifyCabDimensionsAs, specifyChassisLengthAs, specifyFuelConsumptionAs, specifyLicencingRegionAs, specifyAxleRatingAs, defaultReportPdfPageSize, reportLogo, dashboardDataPointIds, dashboardLegislationIds, internalStandards, companyAdditionalNote, specifyBodyPositionAs, specifyEquipmentPositionAs, showTips, reportViewLayout, reportViews, nteaUserValidationType) {
        //     //#region internal properties
        //     var _measurementSystem,
        //         _measurementSystemId,
        //         _lengthIncrement,
        //         _massIncrement,
        //         _percentageIncrement,
        //         _legislation,
        //         _legislationId,
        //         _applicationType,
        //         _applicationTypeId,
        //         _colourDrawings,
        //         _showComplianceScorecard,
        //         _specifyWheelbaseAs,
        //         _integrations,
        //         _specifyCabDimensionsAs,
        //         _specifyChassisLengthAs,
        //         _specifyFuelConsumptionAs,
        //         _specifyLicencingRegionAs,
        //         _specifyAxleRatingAs,
        //         _defaultReportPdfPageSize,
        //         _reportLogo,
        //         _dashboardDataPointIds,
        //         _dashboardLegislationIds,
        //         _internalStandards,
        //         _companyAdditionalNote,
        //         _specifyBodyPositionAs,
        //         _specifyEquipmentPositionAs,
        //         _showTips,
        //         _reportViewLayout,
        //         _reportViews,
        //         _nteaUserValidationType;
        //     //#endregion

        //     setMeasurementSystemId(measurementSystemId);
        //     setPercentageIncrement(percentageIncrement);
        //     setLegislationId(legislationId);
        //     setApplicationTypeId(applicationTypeId);
        //     setColourDrawings(colourDrawings);
        //     setShowComplianceScorecard(showComplianceScorecard);
        //     setSpecifyWheelbaseAs(specifyWheelbaseAs);
        //     setIntegrations(integrations);
        //     setSpecifyCabDimensionsAs(specifyCabDimensionsAs);
        //     setSpecifyChassisLengthAs(specifyChassisLengthAs);
        //     setSpecifyFuelConsumptionAs(specifyFuelConsumptionAs);
        //     setSpecifyLicencingRegionAs(specifyLicencingRegionAs);
        //     setSpecifyAxleRatingAs(specifyAxleRatingAs);
        //     setDefaultReportPdfPageSize(defaultReportPdfPageSize);
        //     setReportLogo(reportLogo);
        //     setDashboardDataPointIds(dashboardDataPointIds);
        //     setDashboardLegislationIds(dashboardLegislationIds);
        //     setCompanyAdditionalNote(companyAdditionalNote);
        //     setSpecifyBodyPositionAs(specifyBodyPositionAs);
        //     setSpecifyEquipmentPositionAs(specifyEquipmentPositionAs);
        //     setShowTips(showTips);
        //     setReportViewLayout(reportViewLayout);
        //     setNteatUserValidationType(nteaUserValidationType);

        //     initializeInternalStandards(internalStandards);
        //     initializeReportViews(reportViews);


        //     _lengthIncrement = lengthIncrement;
        //     _massIncrement = massIncrement;

        //     function getMeasurementSystem() {
        //         return _measurementSystem;
        //     }
        //     function setMeasurementSystem(id) {
        //         var newMeasurementSystem = globals.getMeasurementSystemUsingType(id);
        //         _measurementSystem = newMeasurementSystem;
        //     }
        //     function getMeasurementSystemId() {
        //         return _measurementSystemId;
        //     }
        //     function setMeasurementSystemId(value) {
        //         _measurementSystemId = value;
        //         setMeasurementSystem(value);
        //     }
        //     function getLengthIncrement() {
        //         return _lengthIncrement;
        //     }
        //     function setLengthIncrement(value) {
        //         _lengthIncrement = value * getActiveMeasurementSystem().lengthConversion;
        //     }
        //     function getMassIncrement() {
        //         return _massIncrement;
        //     }
        //     function setMassIncrement(value) {
        //         _massIncrement = value / getActiveMeasurementSystem().massConversion;
        //     }
        //     function getPercentageIncrement() {
        //         return _percentageIncrement;
        //     }
        //     function setPercentageIncrement(value) {
        //         _percentageIncrement = value;
        //     }
        //     function getLegislation() {
        //         return _legislation;
        //     }
        //     function setLegislation(value) {
        //         _legislation = value;
        //     }
        //     function getLegislationId() {
        //         return _legislationId;
        //     }
        //     function setLegislationId(value) {
        //         _legislationId = value;
        //     }
        //     function getApplicationType() {
        //         return _applicationType;
        //     }
        //     function setApplicationType(value) {
        //         _applicationType = value;
        //     }
        //     function getApplicationTypeId() {
        //         return _applicationTypeId;
        //     }
        //     function setApplicationTypeId(value) {
        //         _applicationTypeId = value;
        //     }
        //     function getColourDrawings() {
        //         return _colourDrawings;
        //     }
        //     function setColourDrawings(value) {
        //         _colourDrawings = value;
        //     }
        //     function getShowComplianceScorecard() {
        //         return _showComplianceScorecard;
        //     }
        //     function setShowComplianceScorecard(value) {
        //         _showComplianceScorecard = value;
        //     }
        //     function getSpecifyWheelbaseAs() {
        //         return _specifyWheelbaseAs;
        //     }
        //     function setSpecifyWheelbaseAs(value) {
        //         _specifyWheelbaseAs = value;
        //     }
        //     function getIntegrations() {
        //         return integrations;
        //     }
        //     function setIntegrations(value) {
        //         _integrations = Array.isArray(value) ? value : [];
        //     }
        //     function getSpecifyCabDimensionsAs() {
        //         return _specifyCabDimensionsAs;
        //     }
        //     function setSpecifyCabDimensionsAs(value) {
        //         _specifyCabDimensionsAs = value;
        //     }
        //     function getSpecifyChassisLengthAs() {
        //         return _specifyChassisLengthAs;
        //     }
        //     function setSpecifyChassisLengthAs(value) {
        //         _specifyChassisLengthAs = value;
        //     }
        //     function getSpecifyFuelConsumptionAs() {
        //         return _specifyFuelConsumptionAs;
        //     }
        //     function setSpecifyFuelConsumptionAs(value) {
        //         _specifyFuelConsumptionAs = value;
        //     }
        //     function getSpecifyLicencingRegionAs() {
        //         return _specifyLicencingRegionAs;
        //     }
        //     function setSpecifyLicencingRegionAs(newValue) {
        //         _specifyLicencingRegionAs = newValue;
        //     }
        //     function getSpecifyAxleRatingAs() {
        //         return _specifyAxleRatingAs;
        //     }
        //     function setSpecifyAxleRatingAs(value) {
        //         _specifyAxleRatingAs = value;
        //     }
        //     function getDefaultReportPdfPageSize() {
        //         return _defaultReportPdfPageSize;
        //     }
        //     function setDefaultReportPdfPageSize(value) {
        //         _defaultReportPdfPageSize = value;
        //     }
        //     function getReportLogo() {
        //         return _reportLogo;
        //     }
        //     function setReportLogo(newValue) {
        //         _reportLogo = newValue;
        //     }
        //     function getCompanyAdditionalNote() {
        //         return _companyAdditionalNote;
        //     }
        //     function setCompanyAdditionalNote(newValue) {
        //         _companyAdditionalNote = newValue;
        //     }
        //     function getSpecifyBodyPositionAs() {
        //         return _specifyBodyPositionAs;
        //     }
        //     function setSpecifyBodyPositionAs(newValue) {
        //         _specifyBodyPositionAs = newValue;
        //     }
        //     function getSpecifyEquipmentPositionAs() {
        //         return _specifyEquipmentPositionAs;
        //     }
        //     function setSpecifyEquipmentPositionAs(newValue) {
        //         _specifyEquipmentPositionAs = newValue;
        //     }
        //     function getShowTips() {
        //         return _showTips;
        //     }
        //     function setShowTips(value) {
        //         _showTips = value;
        //     }
        //     function getReportViewLayout() {
        //         return _reportViewLayout;
        //     }
        //     function setReportViewLayout(value) {
        //         _reportViewLayout = value;
        //     }
        //     function getNteaUserValidationType() {
        //         return _nteaUserValidationType;
        //     }
        //     function setNteatUserValidationType(value) {
        //         _nteaUserValidationType = value;
        //     }
        //     function getDashboardDataPointIds() {
        //         return _dashboardDataPointIds;
        //     }
        //     function setDashboardDataPointIds(newValue) {
        //         _dashboardDataPointIds = Array.isArray(newValue) ? newValue : [];
        //     }
        //     function getDashboardLegislationIds() {
        //         return _dashboardLegislationIds;
        //     }
        //     function setDashboardLegislationIds(newValue) {
        //         _dashboardLegislationIds = Array.isArray(newValue) ? newValue : [];
        //     }
        //     function getInternalStandards() {
        //         return _internalStandards;
        //     }
        //     function setInternalStandards(newValue) {
        //         _internalStandards = Array.isArray(newValue) ? newValue : [];
        //     }
        //     function getInternalStandardsToBeSaved() {
        //         var returnArray = [];

        //         _internalStandards.forEach(function (internalStandard) {
        //             if (typeof internalStandard.getDataPointId() === 'number') {
        //                 returnArray.push(internalStandard.getObjectToBeSaved());
        //             }
        //         });

        //         return returnArray;
        //     }
        //     function getDashboardLegislationsToBeSaved() {
        //         var returnArray = [];

        //         _dashboardLegislationIds.forEach(function (legId) {
        //             var legislation = getActiveLegislations().find(function (al) {
        //                 return al.id === legId;
        //             });

        //             if (legislation !== undefined) {
        //                 returnArray.push({
        //                     LegislationId: legislation.legislationId,
        //                     CountryId: legislation.countryId
        //                 });
        //             }
        //         });

        //         return returnArray;
        //     }

        //     function getIntegrationUsingType(typeToGet) {
        //         var returnValue = getIntegrations().find(function (element) {
        //             return element.Type = typeToGet;
        //         });
        //         return returnValue !== undefined ? returnValue : null;
        //     }
        //     function setIntegrationValue(value, valueToSet, typeToGet) {
        //         var obj = getIntegrationUsingType(typeToGet);
        //         if (obj !== null) {
        //             obj[valueToSet] = value;
        //         }
        //     }
        //     function setNteaAttribute1Value(value) {
        //         var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        //         if (obj !== null) {
        //             obj.setAttribute1(value);
        //         }
        //     }
        //     function setNteaAttribute2Value(value) {
        //         var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        //         if (obj !== null) {
        //             obj.setAttribute2(value);
        //         }
        //     }
        //     function setNteaPasswordValue(value) {
        //         var obj = getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
        //         if (obj !== null) {
        //             obj.setPassword(value);
        //         }
        //     }

        //     /**
        //      *
        //      * @param {object[]} newInternalStandards - Internal standard objects to add
        //      */
        //     function initializeInternalStandards(newInternalStandards) {
        //         if (Array.isArray(newInternalStandards)) {
        //             var updateArray = [];

        //             newInternalStandards.forEach(function (is, index) {
        //                 if (typeof is.getIsBlankRow === 'function') {
        //                     updateArray.push(is);
        //                 } else {
        //                     is.Id = globals.nextId();
        //                     if (is.RangeType !== config.RANGE_TYPES.BETWEEN) {
        //                         if (is.RangeMin === 0) {
        //                             is.RangeMin = '';
        //                         }
        //                         if (is.RangeMax === 0) {
        //                             is.RangeMax = '';
        //                         }
        //                     }
        //                     //is.IsBlankRow =
        //                     updateArray.push(new SettingsInternalStandard(is));
        //                 }
        //             });
        //             if (updateArray.length < 5) {
        //                 updateArray.push(createEmptyInternalStandard(updateArray));
        //             }

        //             setInternalStandards(updateArray);
        //         }
        //     }

        //     function createEmptyInternalStandard(arr) {
        //         var emptyInternalStandard = {
        //             Id: getIdForInternalStandardBlankRow(arr),
        //             IsBlankRow: true,
        //             DataPointId: '',
        //             RangeMin: '',
        //             RangeMax: '',
        //             RangeType: config.RANGE_TYPES.BETWEEN,
        //             IsActive: false
        //         };

        //         return new SettingsInternalStandard(emptyInternalStandard);
        //     }

        //     function getSettingsInternalStandardUsingId(id) {
        //         return getInternalStandards().find(function (ia) {
        //             return ia.getId() === id;
        //         });
        //     }

        //     function getIdForInternalStandardBlankRow(arr) {
        //         var arrayToCheck = Array.isArray(arr) === true ? arr : getInternalStandards(),
        //             newId = 0;

        //         arrayToCheck.forEach(function (item) {
        //             if (item.getId() > newId) {
        //                 newId = item.getId()
        //             }
        //         });

        //         return newId + 1;
        //     }

        //     /**
        //      *
        //      * @param {any} newInternalStandard
        //      */
        //     function addInternalStandard(newInternalStandard, indexToReplace) {
        //         if (typeof indexToReplace === 'number') {
        //             if (_internalStandards.length === indexToReplace) {
        //                 if (typeof newInternalStandard.getId === 'function') {
        //                     _internalStandards.push(newInternalStandard);
        //                 } else {
        //                     _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //                 }
        //             } else {
        //                 var tempArray = [];

        //                 _internalStandards.forEach(function (is, index) {
        //                     if (index === indexToReplace) {
        //                         if (typeof newInternalStandard.getId === 'function') {
        //                             tempArray.push(newInternalStandard);
        //                         } else {
        //                             tempArray.push(new SettingsInternalStandard(newInternalStandard));
        //                         }
        //                     }
        //                     tempArray.push(is);
        //                 });
        //                 _internalStandards = tempArray;
        //             }
        //         } else {
        //             if (typeof newInternalStandard.getId === 'function') {
        //                 _internalStandards.push(newInternalStandard);
        //             } else {
        //                 _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //             }
        //         }
        //     }

        //     function removeInternalStandard(isToRemove) {
        //         var indexToRemove = _internalStandards.map(function (e) { return e.getId(); }).indexOf(isToRemove.getId());
        //         _internalStandards.splice(indexToRemove, 1);
        //     }

        //     function areThereChangesToDashboardDataPoints(objectToCompare) {
        //         if (getDashboardDataPointIds().length !== objectToCompare.getDashboardDataPointIds().length) {
        //             return true;
        //         }

        //         for (var i = 0; i < getDashboardDataPointIds().length; i++) {
        //             if (getDashboardDataPointIds()[i] !== objectToCompare.getDashboardDataPointIds()[i]) {
        //                 return true;
        //             }
        //         }

        //         return false;
        //     }

        //     function areThereChangesToInternalStandards(objectToCompare) {
        //         if (getInternalStandards().length !== objectToCompare.getInternalStandards().length) {
        //             return true;
        //         }

        //         for (var i = 0; i < getInternalStandards().length; i++) {
        //             var activeInternalStandard = getInternalStandards()[i],
        //                 originalInternalStandard = objectToCompare.getInternalStandards()[i];

        //             if (activeInternalStandard.getIsActive() !== originalInternalStandard.getIsActive()) {
        //                 return true;
        //             }
        //             if (activeInternalStandard.getDataPointId() !== originalInternalStandard.getDataPointId()) {
        //                 return true;
        //             }
        //             if (activeInternalStandard.getRangeMin() !== originalInternalStandard.getRangeMin()) {
        //                 return true;
        //             }
        //             if (activeInternalStandard.getRangeMax() !== originalInternalStandard.getRangeMax()) {
        //                 return true;
        //             }
        //             if (activeInternalStandard.getRangeType() !== originalInternalStandard.getRangeType()) {
        //                 return true;
        //             }
        //         }

        //         return false;
        //     }

        //     function areThereChangesToDashboardLegislations(objectToCompare) {
        //         if (getDashboardLegislationIds().length !== objectToCompare.getDashboardLegislationIds().length) {
        //             return true;
        //         }

        //         for (var i = 0; i < getDashboardLegislationIds().length; i++) {
        //             if (getDashboardLegislationIds()[i] !== objectToCompare.getDashboardLegislationIds()[i]) {
        //                 return true;
        //             }
        //         }

        //         return false;
        //     }

        //     // #region Report Views Section
        //     function getReportViews() {
        //         return _reportViews;
        //     }
        //     function setReportViews(newValue) {
        //         _reportViews = Array.isArray(newValue) ? newValue : [];
        //     }
        //     function getReportViewObjectsToBeSaved() {
        //         var returnArray = [];

        //         _reportViews.forEach(function (rv) {
        //             if(rv.getIsBlankRow() !== true) {
        //                 returnArray.push(rv.getObjectToBeSaved());
        //             }
        //         });

        //         return returnArray;
        //     }
        //     function areSelectedReportViewsValid() {
        //         var returnVal = true;
        //         // If 2 or more rows then there should be at least 1 view selected which is valid
        //         if (getReportViews().length > 1) {
        //             returnVal = true;
        //         } else {
        //             // If row is empty then this is invalid
        //             if(getReportViews().length === 0) {
        //                 returnVal = false;
        //             } else {
        //                 // if there is 1 row and it is blank then it is invalid
        //                 if (getReportViews()[0].getIsBlankRow() === true) {
        //                     returnVal = false;
        //                 } else {
        //                     returnVal = true;
        //                 }
        //             }
        //         }

        //         return returnVal;
        //     }
        //     function getReportViewUsingId(id) {
        //         return getReportViews().find(function (rv) {
        //             // return rv.getOrder() === id;
        //             return rv.getId() === id;
        //         });
        //     }
        //     function getReportViewUsingOrder(order) {
        //         return getReportViews().find(function (rv) {
        //             return rv.getOrder() === order;
        //         });
        //     }
        //     /**
        //      * Checks if any changes have been made to the report views
        //      * @param {object} objectToCompare - The original SettingsObject created when the modal was opened
        //      * @returns {boolean} - True if there are changes to the report views, false if not
        //      */
        //     function areThereChangesToReportViews(objectToCompare) {
        //         if (getReportViews().length !== objectToCompare.getReportViews().length) {
        //             return true;
        //         }

        //         for (var i = 0; i < getReportViews(0).length; i++) {
        //             var activeReportView = getReportViews()[i],
        //                 originalReportView = objectToCompare.getReportViews()[i];

        //             if(activeReportView.getIsBlankRow() === true) {
        //                 return false;
        //             }
        //             if (activeReportView.getIsActive() !== originalReportView.getIsActive()) {
        //                 return true;
        //             }
        //             if (activeReportView.getViewType() !== originalReportView.getViewType()) {
        //                 return true;
        //             }
        //             if (activeReportView.getViewDetail() !== originalReportView.getViewDetail()) {
        //                 return true;
        //             }
        //             if (activeReportView.getElevation() !== originalReportView.getElevation()) {
        //                 return true;
        //             }
        //             if (activeReportView.getDisplay() !== originalReportView.getDisplay()) {
        //                 return true;
        //             }
        //             if (activeReportView.getOrder() !== originalReportView.getOrder()) {
        //                 return true;
        //             }
        //         }

        //         return false;
        //     }
        //     function getSettingsReportViewUsingId(id) {
        //         return getReportViews().find(function (rv) {
        //             return rv.getId() === id;
        //         });
        //     }
        //     // /**
        //     //  * Function to create an empty object to create a row for the report view table
        //     //  * @param {number} id - ID to use for the report view object
        //     //  * @returns {Object} ReportView object
        //     //  */
        //     // function createEmptyReportView(arr) {
        //     //     var emptyReportView = {
        //     //             ViewType: '',
        //     //             ViewDetail: '',
        //     //             Elevation: '',
        //     //             Display: '',
        //     //             Order: getOrderForReportViewBlankRow(arr),
        //     //             IsActive: false,
        //     //             IsBlankRow: true
        //     //         };
        //     //     return new SettingsReportView(emptyReportView);
        //     // }

        //     // function getOrderForReportViewBlankRow(arr) {
        //     //     var arrayToCheck = Array.isArray(arr) === true ? arr : getReportViews(),
        //     //         newId = 0;
        //     //     arrayToCheck.forEach(function (item) {
        //     //         if (item.getOrder() > newId) {
        //     //             newId = item.getOrder()
        //     //         }
        //     //     });
        //     //     return newId + 1;
        //     // }
        //     function removeReportView(rvToRemove) {
        //         var indexToRemove = _reportViews.map(function (e) { return e.getId(); }).indexOf(rvToRemove.getId());
        //         _reportViews.splice(indexToRemove, 1);
        //     }
        //     /**
        //      *
        //      * @param {object} newReportView
        //      */
        //      function addReportView(newReportView) {
        //         if (typeof newReportView.getId === 'function') {
        //             _reportViews.push(newReportView);
        //         } else {
        //             _reportViews.push(new SettingsReportView(newReportView));
        //         }
        //         // if (typeof indexToReplace === 'number') {
        //         //     if (_internalStandards.length === indexToReplace) {
        //         //         if (typeof newInternalStandard.getId === 'function') {
        //         //             _internalStandards.push(newInternalStandard);
        //         //         } else {
        //         //             _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //         //         }
        //         //     } else {
        //         //         var tempArray = [];
        //         //         _internalStandards.forEach(function (is, index) {
        //         //             if (index === indexToReplace) {
        //         //                 if (typeof newInternalStandard.getId === 'function') {
        //         //                     tempArray.push(newInternalStandard);
        //         //                 } else {
        //         //                     tempArray.push(new SettingsInternalStandard(newInternalStandard));
        //         //                 }
        //         //             }
        //         //             tempArray.push(is);
        //         //         });
        //         //         _internalStandards = tempArray;
        //         //     }
        //         // } else {
        //         //     if (typeof newInternalStandard.getId === 'function') {
        //         //         _internalStandards.push(newInternalStandard);
        //         //     } else {
        //         //         _internalStandards.push(new SettingsInternalStandard(newInternalStandard));
        //         //     }
        //         // }
        //     }
        //     /**
        //      * Take in array of objects and creates an array of SettingsReportView objects
        //      * and sets the report views to the array
        //      * @param {object[]} newReportViews - Array of report view objects
        //      */
        //     function initializeReportViews(newReportViews) {
        //         if (Array.isArray(newReportViews)) {
        //             var updateArray = [];

        //             newReportViews.forEach(function (rv, index) {
        //                 updateArray.push(new SettingsReportView(rv));
        //                 // if (typeof is.getIsBlankRow === 'function') {
        //                 //     updateArray.push(is);
        //                 // } else {
        //                 //     is.Id = globals.nextId();
        //                 //     if (is.RangeType !== config.RANGE_TYPES.BETWEEN) {
        //                 //         if (is.RangeMin === 0) {
        //                 //             is.RangeMin = '';
        //                 //         }
        //                 //         if (is.RangeMax === 0) {
        //                 //             is.RangeMax = '';
        //                 //         }
        //                 //     }
        //                 //     //is.IsBlankRow =
        //                 //     updateArray.push(new SettingsInternalStandard(is));
        //                 // }
        //             });

        //             updateArray.push(createEmptyReportView(updateArray));

        //             setReportViews(updateArray);
        //         }
        //     }

        //     function updateReportViewOrderValues() {
        //         var reportViews = getReportViews();
        //         reportViews.forEach(function (rv, index) {
        //             rv.setOrder(index + 1);
        //         });
        //         setReportViews(reportViews);
        //     }
        //     // #endregion Report Views Section

        //     function changesApplied(objectToCompare) {

        //         var changesAppliedDfd = $.Deferred();

        //         var returnValue = false, refreshMenu = false, attrObvGroup, higherMassLimitValue, updateAxleMenuStructures = false;

        //         function addSettingNameToChangedSettingNamesList(settingName) {
        //             if (changedSettingNames === '') {
        //                 changedSettingNames = settingName;
        //             } else {
        //                 changedSettingNames += ',' + settingName;
        //             }
        //         }

        //         async function updateValuesOtherThanLegislation() {
        //             if (getMeasurementSystem().id !== objectToCompare.getMeasurementSystem().id) {
        //                 attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMEASUREMENTSYSTEM);
        //                 await attrObvGroup.extraUpdate(getMeasurementSystemId());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.MEASUREMENT_SYSTEM);
        //                 offerManager.setPreventCostingTotalRevenueUpdate(false);
        //                 returnValue = true;
        //             }
        //             if (getLengthIncrement() !== objectToCompare.getLengthIncrement()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).formatted.value = formatLengthValue(getLengthIncrement());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.LENGTH_INCREMENT);
        //                 returnValue = true;
        //             }
        //             if (getMassIncrement() !== objectToCompare.getMassIncrement()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value = formatMassValue(getMassIncrement());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.MASS_INCREMENT);
        //                 returnValue = true;
        //             }
        //             if (getPercentageIncrement() !== objectToCompare.getPercentageIncrement()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).formatted.value = formatPercentageValue(getPercentageIncrement());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.PERCENTAGE_INCREMENT);
        //                 returnValue = true;
        //             }
        //             if (getApplicationTypeId() !== objectToCompare.getApplicationTypeId()) {
        //                 attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSVEHICLEAPPLICATION);
        //                 attrObvGroup.extraUpdate(getApplicationTypeId());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.VEHICLE_APPLICATION);

        //                 higherMassLimitValue = getApplicationTypeId() === config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT ? true : false;
        //                 attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSAPPLYHIGHERMASSLIMITS);
        //                 attrObvGroup.value.value = higherMassLimitValue;

        //                 returnValue = true;
        //             }
        //             if (getColourDrawings() !== objectToCompare.getColourDrawings()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSCOLOURDRAWINGS).value.value = getColourDrawings();
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.COLOUR_DRAWINGS);
        //                 returnValue = true;
        //             }
        //             if (getShowComplianceScorecard() !== objectToCompare.getShowComplianceScorecard()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSHOWCOMPLIANCESCORECARD).value.value = getShowComplianceScorecard();
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SHOW_COMPLIANCE_SCORECARD);
        //                 returnValue = true;
        //             }
        //             if (getSpecifyWheelbaseAs() !== objectToCompare.getSpecifyWheelbaseAs()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS).optionId.value = getSpecifyWheelbaseAs();
        //                 var specifyWheelbaseAsOption = offerManager.getSpecifyWheelbaseAsOptions()
        //                 .filter(function (option) {
        //                     if (option.id === getSpecifyWheelbaseAs()) {
        //                         return option;
        //                     } else if (globals.user.getSpecifyWheelbaseAs() === '' || globals.user.getSpecifyWheelbaseAs() === undefined) {
        //                         return option;
        //                     }
        //                 })[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS).option.value = specifyWheelbaseAsOption;
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_WHEELBASE_AS);
        //                 returnValue = true;
        //             }
        //             if (getSpecifyCabDimensionsAs() !== objectToCompare.getSpecifyCabDimensionsAs()) {
        //                 var specifyCabDimensionsAsOption = offerManager.getSpecifyCabDimensionsAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyCabDimensionsAs();
        //                     });
        //                 var specifyCabDimensionsAsOptionToUse = specifyCabDimensionsAsOption !== undefined ? specifyCabDimensionsAsOption : offerManager.getSpecifyCabDimensionsAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYCABDIMENSIONSAS).setVal(specifyCabDimensionsAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyCabDimensionsAs: specifyCabDimensionsAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_CAB_DIMENSIONS_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getSpecifyChassisLengthAs() !== objectToCompare.getSpecifyChassisLengthAs()) {
        //                 var specifyChassisLengthAsOption = offerManager.getSpecifyChassisLengthAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyChassisLengthAs();
        //                     });
        //                 var specifyChassisLengthAsOptionToUse = specifyChassisLengthAsOption !== undefined ? specifyChassisLengthAsOption : offerManager.getSpecifyChassisLengthAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYCHASSISLENGTHAS).setVal(specifyChassisLengthAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyChassisLengthAs: specifyChassisLengthAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_CHASSIS_LENGTH_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getSpecifyFuelConsumptionAs() !== objectToCompare.getSpecifyFuelConsumptionAs()) {
        //                 var specifyFuelConsumptionAsOption = offerManager.getSpecifyFuelConsumptionAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyFuelConsumptionAs();
        //                     });
        //                 var specifyFuelConsumptionAsOptionToUse = specifyFuelConsumptionAsOption !== undefined ? specifyFuelConsumptionAsOption : offerManager.getSpecifyFuelConsumptionAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS).setVal(specifyFuelConsumptionAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyFuelConsumptionAs: specifyFuelConsumptionAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_FUEL_CONSUMPTION_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getSpecifyLicencingRegionAs() !== objectToCompare.getSpecifyLicencingRegionAs()) {
        //                 var specifyLicencingRegionAsOption = availableLicenceCategories
        //                     .find(function (option) {
        //                         return option.id === getSpecifyLicencingRegionAs();
        //                     });
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYLICENCINGREGIONAS).setVal(specifyLicencingRegionAsOption);

        //                 globals.user.updateUser({
        //                     specifyLicencingRegionAs: specifyLicencingRegionAsOption.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_LICENCING_REGION_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getSpecifyAxleRatingAs() !== objectToCompare.getSpecifyAxleRatingAs()) {
        //                 var specifyAxleRatingAsOption = offerManager.getSpecifyAxleRatingAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyAxleRatingAs();
        //                     });
        //                 var specifyAxleRatingAsOptionToUse = specifyAxleRatingAsOption !== undefined ? specifyAxleRatingAsOption : offerManager.getSpecifyAxleRatingAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYAXLERATINGAS).setVal(specifyAxleRatingAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyAxleRatingAs: specifyAxleRatingAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_AXLE_RATING_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //                 updateAxleMenuStructures = true;
        //             }
        //             if (getDefaultReportPdfPageSize() !== objectToCompare.getDefaultReportPdfPageSize()) {
        //                 var defaultReportPdfPageSizeOption = offerManager.getDefaultReportPdfPageSizeOptions()
        //                     .find(function (option) {
        //                         return option.id === getDefaultReportPdfPageSize();
        //                     });
        //                 var defaultReportPdfPageSizeOptionToUse = defaultReportPdfPageSizeOption !== undefined ? defaultReportPdfPageSizeOption : offerManager.getDefaultReportPdfPageSizeOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSDEFAULTREPORTPDFPAGESIZE).setVal(defaultReportPdfPageSizeOptionToUse);

        //                 globals.user.updateUser({
        //                     defaultReportPdfPageSize: defaultReportPdfPageSizeOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.DEFAULT_PDF_PAGE_SIZE);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getReportLogo() !== objectToCompare.getReportLogo()) {
        //                 returnValue = true;
        //                 globals.user.setReportLogo(getReportLogo() !== globals.user.getReportGraphics().landscapeReportHeaderLeftURL ? getReportLogo() : '');
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_LOGO);
        //             }
        //             if (getSpecifyBodyPositionAs() !== objectToCompare.getSpecifyBodyPositionAs()) {
        //                 var specifyBodyPositionAsOption = offerManager.getSpecifyBodyPositionAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyBodyPositionAs();
        //                     });
        //                 var specifyBodyPositionAsOptionToUse = specifyBodyPositionAsOption !== undefined ? specifyBodyPositionAsOption : offerManager.getSpecifyBodyPositionAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYBODYPOSITIONAS).setVal(specifyBodyPositionAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyBodyPositionAs: specifyBodyPositionAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_BODY_POSITION_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getSpecifyEquipmentPositionAs() !== objectToCompare.getSpecifyEquipmentPositionAs()) {
        //                 var specifyEquipmentPositionAsOption = offerManager.getSpecifyEquipmentPositionAsOptions()
        //                     .find(function (option) {
        //                         return option.id === getSpecifyEquipmentPositionAs();
        //                     });
        //                 var specifyEquipmentPositionAsOptionToUse = specifyEquipmentPositionAsOption !== undefined ? specifyEquipmentPositionAsOption : offerManager.getSpecifyEquipmentPositionAsOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYEQUIPMENTPOSITIONAS).setVal(specifyEquipmentPositionAsOptionToUse);

        //                 globals.user.updateUser({
        //                     specifyEquipmentPositionAs: specifyEquipmentPositionAsOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SPECIFY_EQUIPMENT_POSITION_AS);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }
        //             if (getShowTips() !== objectToCompare.getShowTips()) {
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSHOWTIPS).value.value = getShowTips();
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.SHOW_TIPS);
        //                 returnValue = true;
        //             }
        //             if (getReportViewLayout() !== objectToCompare.getReportViewLayout()) {
        //                 var reportViewLayoutOption = offerManager.getReportViewLayoutOptions()
        //                     .find(function (option) {
        //                         return option.id === getReportViewLayout();
        //                     });
        //                 var reportViewLayoutOptionToUse = reportViewLayoutOption !== undefined ? reportViewLayoutOption : offerManager.getReportViewLayoutOptions()[0];
        //                 offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSREPORTVIEWLAYOUT).setVal(reportViewLayoutOptionToUse);

        //                 globals.user.updateUser({
        //                     reportViewLayout: reportViewLayoutOptionToUse.id
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_VIEW_LAYOUT);
        //                 returnValue = true;
        //                 refreshMenu = true;
        //             }

        //             if (areThereChangesToDashboardDataPoints(objectToCompare)) {
        //                 returnValue = true;
        //                 globals.user.replaceDashboardDataPointsUsingArrayOfIds(getDashboardDataPointIds());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.DASHBOARD_DATA_POINTS);
        //             }

        //             if (areThereChangesToInternalStandards(objectToCompare)) {
        //                 returnValue = true;
        //                 globals.user.replaceInternalStandardsUsingArray(getInternalStandardsToBeSaved());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.INTERNAL_STANDARDS);
        //             }

        //             if (areThereChangesToReportViews(objectToCompare)) {
        //                 returnValue = true;
        //                 globals.user.replaceReportViewsUsingArray(getReportViewObjectsToBeSaved());
        //                 globals.user.updateUser({
        //                     reportViews: getReportViewObjectsToBeSaved()
        //                 });
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.REPORT_VIEWS);
        //             }

        //             if (getCompanyAdditionalNote() !== objectToCompare.getCompanyAdditionalNote()) {
        //                 returnValue = true;
        //                 globals.user.updateUser({
        //                     companyAdditionalNote: getCompanyAdditionalNote()
        //                 });
        //                 globals.user.setCompanyAdditionalNote(getCompanyAdditionalNote());
        //                 addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.COMPANY_ADDITIONAL_NOTE);
        //             }

        //             if (refreshMenu && offerManager.getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback() !== null) {
        //                 offerManager.getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback()(updateAxleMenuStructures);
        //             }
        //         }

        //         if (areThereChangesToDashboardLegislations(objectToCompare)) {
        //             globals.user.replaceDashboardLegislationsUsingArray(getDashboardLegislationsToBeSaved());
        //             addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.LEGISLATION);
        //             returnValue = true;
        //             offerManager.setLegislations(getActiveLegislations());
        //             offerManager.notifyGraphicsManager()('Legislations_Changed');
        //             updateValuesOtherThanLegislation().then(function (){
        //                 changesAppliedDfd.resolve(returnValue);
        //             });

        //         } else {
        //             updateValuesOtherThanLegislation().then(function (){
        //                 changesAppliedDfd.resolve(returnValue);
        //             });
        //         }

        //         return changesAppliedDfd.promise();
        //     }

        //     function changesAppliedToIntegrations(objectToCompare) {
        //         var returnValue = false;

        //         return returnValue;
        //     }

        //     function changesAppliedToNTEAIntegration(objectToCompare) {
        //         var returnValue = false,
        //             activeNteaObject = getIntegrationUsingType('NTEA'),
        //             originalNteaObject = objectToCompare.getIntegrationUsingType('NTEA'),
        //             isMembershipNumberEmpty = activeNteaObject.getAttribute1() === '' ? true : false,
        //             isUsernameEmpty = activeNteaObject.getAttribute2() === '' ? true : false,
        //             isPasswordEmpty = activeNteaObject.getPassword() === '' ? true : false;
        //         if (activeNteaObject !== null && originalNteaObject !== null) {
        //             Object.keys(activeNteaObject).forEach(function (keyName) {
        //                 if (activeNteaObject[keyName] !== originalNteaObject[keyName]) {
        //                     if (keyName === 'OperationType') {
        //                         if(getNteaUserValidationType() === config.NTEA_USER_VALIDATION_TYPES.NTEA) {
        //                             if (!isUsernameEmpty || !isPasswordEmpty) {
        //                                 returnValue = true;
        //                             }
        //                         } else {
        //                             if (!isUsernameEmpty || !isMembershipNumberEmpty) {
        //                                 returnValue = true;
        //                             }
        //                         }
        //                     } else {
        //                         returnValue = true;
        //                     }
        //                 }
        //             });
        //         }
        //         return returnValue;
        //     }

        //     this.getMeasurementSystem = getMeasurementSystem;
        //     this.setMeasurementSystem = setMeasurementSystem;
        //     this.getMeasurementSystemId = getMeasurementSystemId;
        //     this.setMeasurementSystemId = setMeasurementSystemId;
        //     this.getLengthIncrement = getLengthIncrement;
        //     this.setLengthIncrement = setLengthIncrement;
        //     this.getMassIncrement = getMassIncrement;
        //     this.setMassIncrement = setMassIncrement;
        //     this.getPercentageIncrement = getPercentageIncrement;
        //     this.setPercentageIncrement = setPercentageIncrement;
        //     this.getLegislation = getLegislation;
        //     this.setLegislation = setLegislation;
        //     this.getLegislationId = getLegislationId;
        //     this.setLegislationId = setLegislationId;
        //     this.getApplicationType = getApplicationType;
        //     this.setApplicationType = setApplicationType;
        //     this.getApplicationTypeId = getApplicationTypeId;
        //     this.setApplicationTypeId = setApplicationTypeId;
        //     this.getColourDrawings = getColourDrawings;
        //     this.setColourDrawings = setColourDrawings;
        //     this.getShowComplianceScorecard = getShowComplianceScorecard;
        //     this.setShowComplianceScorecard = setShowComplianceScorecard;
        //     this.getSpecifyWheelbaseAs = getSpecifyWheelbaseAs;
        //     this.setSpecifyWheelbaseAs = setSpecifyWheelbaseAs;
        //     this.changesApplied = changesApplied;
        //     this.getIntegrations = getIntegrations;
        //     this.setIntegrations = setIntegrations;
        //     this.getSpecifyCabDimensionsAs = getSpecifyCabDimensionsAs;
        //     this.setSpecifyCabDimensionsAs = setSpecifyCabDimensionsAs;
        //     this.getSpecifyChassisLengthAs = getSpecifyChassisLengthAs;
        //     this.setSpecifyChassisLengthAs = setSpecifyChassisLengthAs;
        //     this.getIntegrationUsingType = getIntegrationUsingType;
        //     this.changesAppliedToNTEAIntegration = changesAppliedToNTEAIntegration;
        //     this.setIntegrationValue = setIntegrationValue;
        //     this.setNteaAttribute1Value = setNteaAttribute1Value;
        //     this.setNteaAttribute2Value = setNteaAttribute2Value;
        //     this.setNteaPasswordValue = setNteaPasswordValue;
        //     this.getSpecifyFuelConsumptionAs = getSpecifyFuelConsumptionAs;
        //     this.setSpecifyFuelConsumptionAs = setSpecifyFuelConsumptionAs;
        //     this.getSpecifyLicencingRegionAs = getSpecifyLicencingRegionAs;
        //     this.setSpecifyLicencingRegionAs = setSpecifyLicencingRegionAs;
        //     this.getSpecifyAxleRatingAs = getSpecifyAxleRatingAs;
        //     this.setSpecifyAxleRatingAs = setSpecifyAxleRatingAs;
        //     this.getDefaultReportPdfPageSize = getDefaultReportPdfPageSize;
        //     this.setDefaultReportPdfPageSize = setDefaultReportPdfPageSize;
        //     this.getReportLogo = getReportLogo;
        //     this.setReportLogo = setReportLogo;
        //     this.getDashboardDataPointIds = getDashboardDataPointIds;
        //     this.setDashboardDataPointIds = setDashboardDataPointIds;
        //     this.getDashboardLegislationIds = getDashboardLegislationIds;
        //     this.setDashboardLegislationIds = setDashboardLegislationIds;
        //     this.getInternalStandards = getInternalStandards;
        //     this.setInternalStandards = setInternalStandards;
        //     this.addInternalStandard = addInternalStandard;
        //     this.removeInternalStandard = removeInternalStandard;
        //     this.createEmptyInternalStandard = createEmptyInternalStandard;
        //     this.getSettingsInternalStandardUsingId = getSettingsInternalStandardUsingId;
        //     this.getCompanyAdditionalNote = getCompanyAdditionalNote;
        //     this.setCompanyAdditionalNote = setCompanyAdditionalNote;
        //     this.getSpecifyBodyPositionAs = getSpecifyBodyPositionAs;
        //     this.setSpecifyBodyPositionAs = setSpecifyBodyPositionAs;
        //     this.getSpecifyEquipmentPositionAs = getSpecifyEquipmentPositionAs;
        //     this.setSpecifyEquipmentPositionAs = setSpecifyEquipmentPositionAs;
        //     this.getShowTips = getShowTips;
        //     this.setShowTips = setShowTips;
        //     this.getReportViewLayout = getReportViewLayout;
        //     this.setReportViewLayout = setReportViewLayout;
        //     this.getReportViews = getReportViews;
        //     this.setReportViews = setReportViews;
        //     this.getSettingsReportViewUsingId = getSettingsReportViewUsingId;
        //     this.addReportView = addReportView;
        //     this.removeReportView = removeReportView;
        //     this.getReportViewObjectsToBeSaved = getReportViewObjectsToBeSaved;
        //     this.areSelectedReportViewsValid = areSelectedReportViewsValid;
        //     this.getReportViewUsingId = getReportViewUsingId;
        //     this.getReportViewUsingOrder = getReportViewUsingOrder;
        //     this.updateReportViewOrderValues = updateReportViewOrderValues;
        //     this.getNteaUserValidationType = getNteaUserValidationType;
        // }
        // #endregion SettingsObject Functions

        

        // #region Setup Functions
        function setupPreferenceItems() {
            function getMenuBuilderItems() {
                var builtItems = [],
                    fuelConsumptionTooltipTextCode;

                if (activeSettingsObject.getMeasurementSystemId() === config.MEASUREMENT_SYSTEM.IMPERIALUK || activeSettingsObject.getMeasurementSystemId() === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
                    fuelConsumptionTooltipTextCode = '4267';
                } else {
                    fuelConsumptionTooltipTextCode = '4224';
                }

                // Specify Fuel Consumption
                if (globals.user.displayFuelConsumptionAs()) {
                    builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                        .newSelect(activeSettingsObject.getSpecifyFuelConsumptionAs(), offerManager.getSpecifyFuelConsumptionAsOptions(), 'settings-specify-fuel-consumption-as')
                        .label(config.getTranslationText('1482'))
                        .setter(activeSettingsObject.setSpecifyFuelConsumptionAs)
                        .addTooltip(fuelConsumptionTooltipTextCode)
                        .build()));
                }

                // Specify Cab Dimensions As
                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyCabDimensionsAs(), offerManager.getSpecifyCabDimensionsAsOptions(), 'settings-specify-cab-dimensions-as')
                    .label(config.getTranslationText('1414'))
                    .width(longSelectMenuWidth)
                    .setter(activeSettingsObject.setSpecifyCabDimensionsAs)
                    .addTooltip('4225')
                    .build()));

                // Specify Chassis Length As
                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyChassisLengthAs(), offerManager.getSpecifyChassisLengthAsOptions(), 'settings-specify-chassis-length-as')
                    .label(config.getTranslationText('1413'))
                    .width(longSelectMenuWidth)
                    .setter(activeSettingsObject.setSpecifyChassisLengthAs)
                    .addTooltip('4226')
                    .build()));

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyWheelbaseAs(), offerManager.getSpecifyWheelbaseAsOptions(), 'settings-specify-wheelbase-as')
                    .label(config.getTranslationText('1161'))
                    .width(longSelectMenuWidth)
                    .setter(activeSettingsObject.setSpecifyWheelbaseAs)
                    .updateFunction(function (wheelbaseMethodCode) {})
                    .addTooltip('4227')
                    .build()));

                if ((activeSettingsObject.getSpecifyLicencingRegionAs() > 0 || activeSettingsObject.getAvailableLicenceCategories().length > 1) && isCostingAvailable === true) {
                    builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                        .newSelect(activeSettingsObject.getSpecifyLicencingRegionAs(), activeSettingsObject.getAvailableLicenceCategories(), 'settings-specify-licencing-region-as', 'settings-selectmenu-overflow')
                        .label(config.getTranslationText('1492'))
                        .setter(activeSettingsObject.setSpecifyLicencingRegionAs)
                        .addTooltip('4228')
                        .build()));
                }

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyAxleRatingAs(), offerManager.getSpecifyAxleRatingAsOptions(), 'settings-specify-axle-rating-as')
                    .label(config.getTranslationText('2410'))
                    .setter(activeSettingsObject.setSpecifyAxleRatingAs)
                    .addTooltip('4229')
                    .build()));

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyBodyPositionAs(), offerManager.getSpecifyBodyPositionAsOptions(), 'settings-specify-body-position-as')
                    .label(config.getTranslationText('4412'))
                    .setter(activeSettingsObject.setSpecifyBodyPositionAs)
                    .addTooltip('4413')
                    .build()));

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getSpecifyEquipmentPositionAs(), offerManager.getSpecifyEquipmentPositionAsOptions(), 'settings-specify-equipment-position-as', undefined, undefined, undefined, 'last-row-in-tab')
                    .label(config.getTranslationText('4414'))
                    .setter(activeSettingsObject.setSpecifyEquipmentPositionAs)
                    .addTooltip('4415')
                    .build()));

                return builtItems;
            }

            var newItems, items = [];

            newItems = getMenuBuilderItems();

            return newItems;
        }
        function setupMeasurementItems() {
            function getMenuBuilderItems() {
                var builtItems = [];

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getMeasurementSystemId(), globals.user.getAvailableMeasurementSystems(), 'settings-measurement-system')
                    .label(config.getTranslationText('242'))
                    .setter(activeSettingsObject.setMeasurementSystemId)
                    .updateFunction(function (id) {
                        setActiveMeasurementSystem(activeSettingsObject.getMeasurementSystem());
                        triggerSubscriptionUpdates.value = new Date().getTime();
                        refreshSpecifyFuelConsumptionAsOptions(activeSettingsObject.getMeasurementSystem().id);
                        refreshTooltipsOnMeasurementSystemChange(activeSettingsObject.getMeasurementSystem().id);
                    })
                    .addTooltip('4230')
                    .build()));

                builtItems.push(new SettingsTelInput(new SettingsBuilder()
                    .newTel(activeSettingsObject.getLengthIncrement(), 'settings-length-increment')
                    .label(config.getTranslationText('218'))
                    .incrementType(config.INCREMENT_TYPE.LENGTH)
                    .getter(activeSettingsObject.getLengthIncrement)
                    .setter(activeSettingsObject.setLengthIncrement)
                    .formatFunction(formatLengthValue)
                    .addAttrObvGroup(offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT))
                    .addTooltip('4231')
                    .build()));

                builtItems.push(new SettingsTelInput(new SettingsBuilder()
                    .newTel(activeSettingsObject.getMassIncrement(), 'settings-mass-increment')
                    .label(config.getTranslationText('240'))
                    .incrementType(config.INCREMENT_TYPE.MASS)
                    .getter(activeSettingsObject.getMassIncrement)
                    .setter(activeSettingsObject.setMassIncrement)
                    .formatFunction(formatMassValue)
                    .addAttrObvGroup(offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT))
                    .addTooltip('4232')
                    .build()));

                builtItems.push(new SettingsTelInput(new SettingsBuilder()
                    .newTel(activeSettingsObject.getPercentageIncrement(), 'settings-percentage-increment', 'last-row-in-tab')
                    .label(config.getTranslationText('293'))
                    .getter(activeSettingsObject.getPercentageIncrement)
                    .setter(activeSettingsObject.setPercentageIncrement)
                    .formatFunction(formatPercentageValue)
                    .addAttrObvGroup(offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT))
                    .addTooltip('4233')
                    .build()));

                return builtItems;
            }

            var newItems, items = [];

            newItems = getMenuBuilderItems();

            return newItems;
        }
        function setupLegislationItems() {
            function getMenuBuilderItems() {
                var builtItems = [],
                    legislationsSelectedWhenMultiSelectOpened = [];

                function getLegislationsSelectedWhenMultiSelectOpened() {
                    return legislationsSelectedWhenMultiSelectOpened;
                }

                function setLegislationsSelectedWhenMultiSelectOpened(newValue) {
                    legislationsSelectedWhenMultiSelectOpened = newValue;
                }

                var legislationBaseId = 'settings-legislation';
                var legislationsMultiSelect = new MultiSelect(
                    new MultiSelectBuilder(legislationBaseId + '-MULTISELECT')
                        .selectElementId(legislationBaseId + '-multiselect-id')
                        .enableHTML()
                        .objectPropertyToFilterOn('description')
                        .optionDisplayProperties('description', 'id')
                        .userSelectionOptions(globals.user.getSortedAvailableLegislations())
                        .enableSearch()
                        .enableClearButton()
                        .setNonSelectedText(config.getTranslationText('4326'))
                        .setMaxHeight(200)
                        .setButtonWidth(multiSelectWidth)
                        .onChangeFunction(legislationUpdated)
                        .addOnDropdownShowFunction(setUpLegislationsWhenMultiSelectOpened)
                        .addOnDropdownHideFunction(updateApplicationTypeDropdown)
                        .onDeselectAllFunction(clearLegislationIds)
                        .build()
                );

                /**
                 * Function to setup the multiselects when tab is opened
                 * */
                function legislationPostInitializeFunction() {
                    this.multiSelect.selectItemUsingValue(activeSettingsObject.getDashboardLegislationIds(), false);
                }

                /**
                 * Function that is triggered when user clicks on option
                 * @param {object} option - Option that was clicked by the user
                 * @param {boolean} checked - True if user checked option and false if user unchecked option
                 * @param {object} ms - MultiSelect that was used
                 */
                function legislationUpdated(option, checked, ms) {
                    if (globals.user.isUserAllowedFullAccessToSettingsRegulations() === true) {
                        var optionValue = parseInt(option[0].value);
                        // Check that at least 1 row is checked
                        if (checked) {
                            if (activeSettingsObject.getDashboardLegislationIds().length <= 4) {
                                addIdToList(optionValue);
                            } else {
                                $('#' + ms.selectId).multiselect('deselect', [option[0].value]);
                                nextTick(function () {
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('4323', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4323')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                                .addCallback(function (dialogResult) { });
                                });

                            }
                        } else {
                            removeIdFromList(optionValue);
                        }
                    } else {
                        $('#' + ms.selectId).multiselect('deselect', [option[0].value]);
                        nextTick(function () {
                            globals.showDialog(new CustomMessageBox(config.getMessageText('4498', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4498')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) {
                                bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_REGULATIONS, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            });
                        });

                    }


                    function addIdToList(id) {
                        var temp = activeSettingsObject.getDashboardLegislationIds();
                        temp.push(id);
                        activeSettingsObject.setDashboardLegislationIds(temp);
                    }

                    function removeIdFromList(id) {
                        var temp = activeSettingsObject.getDashboardLegislationIds();
                        var indexToRemove = temp.indexOf(id);
                        temp.splice(indexToRemove, 1);
                        activeSettingsObject.setDashboardLegislationIds(temp);
                    }
                }

                /**
                * Function called when multiSelect is opened to setup array to check if user has made changes
                * */
                function setUpLegislationsWhenMultiSelectOpened() {
                    var newArray = [];
                    activeSettingsObject.getDashboardLegislationIds().forEach(function (id) {
                        newArray.push(id);
                    });
                    setLegislationsSelectedWhenMultiSelectOpened(newArray);
                }

                /**
                * Clear the legislation IDs array
                * */
                function clearLegislationIds() {
                    activeSettingsObject.setDashboardLegislationIds([]);
                }

                /**
                * Function triggered when legislation multiSelect is closed
                * */
                function updateApplicationTypeDropdown() {
                    TScMessenger.writeDebugMessage('')
                    if (hasUserChangedLegislations() === true) {
                        updateApplicationTypes();
                    }

                    function hasUserChangedLegislations() {
                        if (getLegislationsSelectedWhenMultiSelectOpened().length !== activeSettingsObject.getDashboardLegislationIds().length) {
                            return true;
                        }

                        var lengthOfArrays = getLegislationsSelectedWhenMultiSelectOpened().length,
                            legislationsSelectedWhenOpened = getLegislationsSelectedWhenMultiSelectOpened(),
                            userDashboardLegislationIds = activeSettingsObject.getDashboardLegislationIds();

                        for (var i = 0; i < lengthOfArrays; i++) {
                            if (legislationsSelectedWhenOpened[i] !== userDashboardLegislationIds[i]) {
                                return true;
                            }
                        }

                        return false;
                    }

                    /**
                    * Get legislation details from cloud services/localStorage and update application types drop down if offer is open
                    * */
                    function updateApplicationTypes() {
                        globals.showSpinner();
                        // var listOfLegislations = [],
                        //     listOfLegislationRequests = [];

                        // activeSettingsObject.getDashboardLegislationIds().forEach(function (id) {
                        //     var newLegislation = globals.user.getAvailableLegislations().find(function (legislation) {
                        //         return legislation.id === id;
                        //     });
                        //     if (newLegislation !== undefined) {
                        //         listOfLegislations.push(newLegislation);
                        //     }
                        // });

                        // listOfLegislations.forEach(function (legislation) {
                        //     listOfLegislationRequests.push(getLegislationDetails(legislation));
                        // });


                        // $.when.apply($, listOfLegislationRequests).then(function () {
                        //     var legislationDetailsArray = [];
                        //     for (var i = 0; i < arguments.length; i++) {
                        //         arguments[i].Description = listOfLegislations[i].description;
                        //         legislationDetailsArray.push(arguments[i]);
                        //     }

                        //     setActiveLegislations(legislationDetailsArray);
                        //     if (offerManager.getActiveOffer() !== undefined && offerManager.getActiveOffer() !== '' && offerManager.getActiveOffer() !== null) {
                        //         var applicationSelectMenu = legislationItems.value.find(function (item) {
                        //             return item.id === 'settings-application';
                        //         });
                        //         if (applicationSelectMenu) {
                        //             var availableApplicationTypes = offerManager.getAvailableVehicleApplicationTypes(getActiveLegislations());

                        //             if (!selectedApplicationTypeAvailable(availableApplicationTypes, activeSettingsObject.getApplicationTypeId())) {
                        //                 activeSettingsObject.setApplicationTypeId(availableApplicationTypes[0].id);
                        //             }
                        //             applicationSelectMenu.optionsArray.value = availableApplicationTypes;
                        //             $('#' + applicationSelectMenu.id).selectmenu('refresh');
                        //         }
                        //     }
                        //     globals.hideSpinner();
                        // },
                        //     function () {
                        //         globals.hideSpinner();
                        //     }
                        // );
                        offerManager.loadLegislationsDetails(activeSettingsObject.getDashboardLegislationIds())
                            .then(function (legislationDetailsArray) {
                                activeSettingsObject.setActiveLegislations(legislationDetailsArray);
                                if (offerManager.getActiveOffer() !== undefined && offerManager.getActiveOffer() !== '' && offerManager.getActiveOffer() !== null) {
                                    var applicationSelectMenu = legislationItems.value.find(function (item) {
                                        return item.id === 'settings-application';
                                    });
                                    if (applicationSelectMenu) {
                                        var availableApplicationTypes = offerManager.getAvailableVehicleApplicationTypes(activeSettingsObject.getActiveLegislations());

                                        if (!selectedApplicationTypeAvailable(availableApplicationTypes, activeSettingsObject.getApplicationTypeId())) {
                                            activeSettingsObject.setApplicationTypeId(availableApplicationTypes[0].id);
                                        }
                                        applicationSelectMenu.optionsArray.value = availableApplicationTypes;
                                        $('#' + applicationSelectMenu.id).selectmenu('refresh');
                                    }
                                }
                                globals.hideSpinner();
                            }).fail(function (error) {
                                globals.hideSpinner();
                                nextTick(function () {
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                        .addCallback(function (dialogResult) { });
                                });
                            });
                    }
                }

                builtItems.push(new SettingsMultiSelect(new SettingsBuilder()
                    .newMultiSelect(activeSettingsObject.getLegislationId(), legislationsMultiSelect, legislationBaseId)
                    .label(config.getTranslationText('2586'))
                    .width(longSelectMenuWidth)
                    .setter(activeSettingsObject.setLegislationId)
                    .addPostInitializeFunction(legislationPostInitializeFunction)
                    .displayUpgradeTooltip(!globals.user.isUserAllowedFullAccessToSettingsRegulations(), 'regulations-upgrade-id', config.getTranslationText('4504'), 'upgrade-link-regulations', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_REGULATIONS_TOOLTIP)
                    .addTooltip('4234')
                    .build()));

                if (offerManager.getActiveOffer() !== undefined && offerManager.getActiveOffer() !== '' && offerManager.getActiveOffer() !== null) {
                    builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                        .newSelect(activeSettingsObject.getApplicationTypeId(), offerManager.getAvailableVehicleApplicationTypes(getActiveLegislation()), 'settings-application')
                        .label(config.getTranslationText('2541'))
                        .width(multiSelectWidth)
                        .setter(activeSettingsObject.setApplicationTypeId)
                        .updateFunction(notifyAuUsers)
                        .addTooltip('4235')
                        .build()));
                }

                var dashboardDataPointBaseId = 'settings-favourite-measurements';
                var dashboardDataPointMultiSelect = new MultiSelect(
                    new MultiSelectBuilder(dashboardDataPointBaseId + '-MULTISELECT')
                        .selectElementId(dashboardDataPointBaseId + '-multiselect-id')
                        .enableHTML()
                        .objectPropertyToFilterOn('description')
                        .optionDisplayProperties('description', 'id')
                        .userSelectionOptions(globals.user.getDashboardDataPoints())
                        .enableSearch()
                        .enableClearButton()
                        .setNonSelectedText(config.getTranslationText('4325'))
                        .setMaxHeight(200)
                        .setButtonWidth(multiSelectWidth)
                        .onChangeFunction(dataPointsUpdated)
                        .onDeselectAllFunction(clearDashboadDataPointIds)
                        .build()
                );

                /**
                * Function to setup multiSelect when tab is opened to select the values in array
                * */
                function dataPointPostInitializeFunction() {
                    this.multiSelect.selectItemUsingValue(activeSettingsObject.getDashboardDataPointIds(), false);
                }

                /**
                * Update data points array based on user selections
                * @param {object} option - Option selected by the user
                * @param {boolean} checked - True if user selected option, False if user deselected option
                * @param {object} ms - MultiSelect that was used
                */
                function dataPointsUpdated(option, checked, ms) {
                    if (globals.user.isUserAllowedFullAccessToSettingsFavourites() === true) {
                        var optionValue = parseInt(option[0].value);
                        // Check that at least 1 row is checked
                        if (checked) {
                            if (activeSettingsObject.getDashboardDataPointIds().length <= 4) {
                                addIdToList(optionValue);
                            } else {
                                $('#' + ms.selectId).multiselect('deselect', [option[0].value]);
                                nextTick(function () {
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('4322', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4322')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) { });
                                });

                            }
                        } else {
                            removeIdFromList(optionValue);
                        }
                    } else {
                        $('#' + ms.selectId).multiselect('deselect', [option[0].value]);
                        nextTick(function () {
                            if(globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('4958', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4958')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            }else {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('4496', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4496')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) {
                                        bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_FAVOURITE_MEASUREMENTS, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                                    });
                            }
                           
                        });

                    }

                    function addIdToList(id) {
                        var temp = activeSettingsObject.getDashboardDataPointIds();
                        temp.push(id);
                        activeSettingsObject.setDashboardDataPointIds(temp);
                    }

                    function removeIdFromList(id) {
                        var temp = activeSettingsObject.getDashboardDataPointIds();
                        var indexToRemove = temp.indexOf(id);
                        temp.splice(indexToRemove, 1);
                        activeSettingsObject.setDashboardDataPointIds(temp);
                    }
                }

                /**
                * Clear the dashboard data point IDs array
                * */
                function clearDashboadDataPointIds() {
                    activeSettingsObject.setDashboardDataPointIds([]);
                }

                builtItems.push(new SettingsMultiSelect(new SettingsBuilder()
                    .newMultiSelect(activeSettingsObject.getLegislationId(), dashboardDataPointMultiSelect, dashboardDataPointBaseId)
                    .label(config.getTranslationText('4273'))
                    .width(280)
                    .setter(activeSettingsObject.setApplicationTypeId)
                    .addPostInitializeFunction(dataPointPostInitializeFunction)
                    .updateFunction(notifyAuUsers)
                    .addTooltip('4274')
                    .displayUpgradeTooltip(globals.showUpgradeToolTipForEssentialsUser(), 'favourite-measurements-upgrade-id', config.getTranslationText('4502'), 'upgrade-link-favourite-measurements', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_FAVOURITE_MEASUREMENTS_TOOLTIP)
                    .build()));

                builtItems.push(createInternalStandardsTable(activeSettingsObject.getInternalStandards()));

                builtItems.push(new SettingsCheckbox(new SettingsBuilder()
                    .newCheckbox(activeSettingsObject.getShowComplianceScorecard(), 'settings-show-compliance-scorecard')
                    .label(config.getTranslationText('960'))
                    .setter(activeSettingsObject.setShowComplianceScorecard)
                    .addTooltip('4236')
                    .build()));

                builtItems.push(new SettingsCheckbox(new SettingsBuilder()
                    .newCheckbox(activeSettingsObject.getShowTips(), 'settings-show-tips', undefined, undefined, 'last-row-in-tab')
                    .label(config.getTranslationText('4629'))
                    .setter(activeSettingsObject.setShowTips)
                    .addTooltip('4630')
                    .build()));

                function notifyAuUsers(newValue) {
                    // This if statement comparison is incorrect but the concept is right. Need to ask Martin about this. Based on legislation?
                    if (globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase()
                        || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase()
                        || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase()
                        || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.CA.cultureCode.toLowerCase()
                        || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase()) {
                        switch (newValue) {
                            case config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT:
                                logger.log(config.getMessageText('1654', globals.getActiveCultureCodeForTranslations()), null, 'settingsModal', true, config.toastrOptionsAuApplicationChange);
                                break;
                            case config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT:
                                if (globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase() || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase()) {
                                    logger.log(config.getMessageText('1655', globals.getActiveCultureCodeForTranslations()), null, 'settingsModal', true, config.toastrOptionsAuApplicationChange);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }


                function createInternalStandardsTable(iaRows) {
                    var tableRows = [],
                        headerRow,
                        idsToRemove = [];

                    iaRows.forEach(function (ia) {
                        idsToRemove.push(ia.getDataPointId());
                    });

                    headerRow = createInternalStandardsHeaderRow();
                    iaRows.forEach(function (rowObject, index) {
                        tableRows.push(createInternalStandardsRow(rowObject, idsToRemove));
                    });

                    return new SettingsTable(new SettingsBuilder()
                        .newTable(tableRows, 'settings-internal-standards-table', headerRow)
                        .label(config.getTranslationText('4275'))
                        .addTooltip('4315')
                        .addDeleteRowFunction(removeInternalStandard)
                        .displayUpgradeTooltip(globals.showUpgradeToolTipForEssentialsUser(), 'internal-standards-upgrade-id', config.getTranslationText('4503'), 'upgrade-link-organizational-standards', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_INTERNAL_STANDARDS_TOOLTIP)
                        .build());
                }

                function removeInternalStandard(row, idType, addBlankRowIfNecessary){
                    var id = idType === DELETE_FUNCTION_PARAM_TYPE.ID ? row : row.id;
                    var isToRemove = activeSettingsObject.getSettingsInternalStandardUsingId(id);
                    if (isToRemove.getIsBlankRow() === false) {
                        // Remove from activeSettingsObject
                        activeSettingsObject.removeInternalStandard(isToRemove);
                        // remove from table
                        var internalStandardsTable = getInternalStandardsTable();
                        var tempRows = internalStandardsTable.rows.value;
                        var indexToRemove = tempRows.map(function (e) { return e.id; }).indexOf(id);
                        var rowToCleanUp = tempRows.splice(indexToRemove, 1);
                        globals.clearShallowRefArrayAndAddItems(internalStandardsTable.rows, tempRows);

                        nextTick(function () {
                            if (addBlankRowIfNecessary === true && internalStandardsTable.rows.value.length < 5 && activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1].getIsBlankRow() === false) {
                                activeSettingsObject.addInternalStandard(activeSettingsObject.createEmptyInternalStandard());
                                var newRow = createInternalStandardsRow(activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1], []);
                                internalStandardsTable.addRow(newRow);

                                // setup all selectmenus
                                internalStandardsTable.rows.value.forEach(function (row) {
                                    row.cells.forEach(function (cell) {
                                        if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                                            cell.cellObject.setUpSelectMenu();
                                        }
                                    });
                                });
                            }
                            // Update all selects to remove
                            updateInternalStandardSelectOptions();
                        });

                        // if (addBlankRowIfNecessary === true && internalStandardsTable.rows.value.length < 5 && activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1].getIsBlankRow() === false) {
                        //     activeSettingsObject.addInternalStandard(activeSettingsObject.createEmptyInternalStandard());
                        //     var newRow = createInternalStandardsRow(activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1], []);
                        //     internalStandardsTable.addRow(newRow);

                        //     // setup all selectmenus
                        //     internalStandardsTable.rows.value.forEach(function (row) {
                        //         row.cells.forEach(function (cell) {
                        //             if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                        //                 cell.cellObject.setUpSelectMenu();
                        //             }
                        //         });
                        //     });
                        // }
                    }
                }

                function createInternalStandardsHeaderRow() {
                    var tableCells = [],
                        emptyColHeader = { value: '' };

                    tableCells.push(createTableTextCell(emptyColHeader));
                    tableCells.push(createTableTextCell(emptyColHeader));
                    tableCells.push(createTableTextCell({
                        value: config.getTranslationText('4276')
                    }, 'settings-table-header'));
                    tableCells.push(createTableTextCell({
                        value: config.getTranslationText('4277')
                    }, 'settings-table-header'));
                    tableCells.push(createTableTextCell(emptyColHeader));

                    return new SettingsTableRow(new SettingsBuilder()
                        .newTableRow(tableCells)
                        .build());
                }

                return builtItems;
            }
            var newItems, items = [];

            newItems = getMenuBuilderItems();

            return newItems;
        }
        function setupReportingItems() {
            function getMenuBuilderItems() {
                var builtItems = [];

                builtItems.push(new SettingsImageUpload(new SettingsBuilder()
                    .newImageUpload('settings-report-logo', activeSettingsObject.getReportLogo(), resetFunction)
                    .label(config.getTranslationText('4265'))
                    .setter(activeSettingsObject.setReportLogo)
                    .isDisabled(!globals.user.allowedToEditCompanyDetails(), reportLogoDisabledMessageCode(), [config.getTranslationText('271'), config.getTranslationText('65')], handleEssentialsMessageResponseForReportLogo)
                    .displayUpgradeTooltip(globals.showUpgradeToolTipForEssentialsUser(), 'report-logo-upgrade-id', config.getTranslationText('4500'), 'upgrade-link-report-logo', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_IMPORT_LOGO_TOOLTIP)
                    .addTooltip('4237')
                    .build()));

                function handleEssentialsMessageResponseForReportLogo(dialogResult) {
                    bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_REPORT_LOGO, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                }

                function isCompanyNotesDisabled() {
                    if (globals.user.isTrialUser() === true) {
                        return true;
                    }
                    if (!globals.user.allowedToEditCompanyDetails()) {
                        return true;
                    }
                    return false;
                }

                function companyNotesDisabledMessageCode() {
                    if (globals.user.isTrialUser() === true) {
                        return '1591';
                    }
                    if(globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
                        return '4957';
                    }
                    return '4499';
                }

                function reportLogoDisabledMessageCode() {
                    // if (globals.user.isTrialUser() === true) {
                    //     return '1591';
                    // }
                    if(globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
                        return '4960';
                    }
                    return '4489';
                }

                function companyNotesDisabledMessageButtons() {
                    if (globals.user.isTrialUser() === true) {
                        return [config.getTranslationText('54'), config.getTranslationText('65')];
                    }
                    return [config.getTranslationText('271'), config.getTranslationText('65')];
                }

                function handleCompanyNotesDisabledMessageButtonClick() {
                    if (globals.user.isTrialUser() === true) {
                        return trialUserBuyNowClicked;
                    }
                    return handleEssentialsMessageResponseForCompanyNotes;
                }

                builtItems.push(new SettingsTextInput(new SettingsBuilder()
                    .newTextInput(activeSettingsObject.getCompanyAdditionalNote(), 'settings-company-additional-note')
                    .label(config.getTranslationText('4299'))
                    .useTextArea(4000)
                    .setter(activeSettingsObject.setCompanyAdditionalNote)
                    .isDisabled(isCompanyNotesDisabled(), companyNotesDisabledMessageCode(), companyNotesDisabledMessageButtons(), handleCompanyNotesDisabledMessageButtonClick())
                    .displayUpgradeTooltip(globals.showUpgradeToolTipForEssentialsUser(), 'report-company-note-upgrade-id', config.getTranslationText('4501'), 'upgrade-link-company-notes', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_COMPANY_NOTES_TOOLTIP)
                    .addTooltip('4300')
                    .build()));

                function trialUserBuyNowClicked(dialogResult) {
                    if (dialogResult === config.getTranslationText('54')) {
                        bus.emit('openBuyNowModal', { modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY});
                    }
                }

                function handleEssentialsMessageResponseForCompanyNotes(dialogResult) {
                    bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_COMPANY_NOTES, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                }

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getDefaultReportPdfPageSize(), offerManager.getDefaultReportPdfPageSizeOptions(), 'settings-default-pdf-page-size')
                    .label(config.getTranslationText('4221'))
                    .setter(activeSettingsObject.setDefaultReportPdfPageSize)
                    .addTooltip('4238')
                    .build()));

                builtItems.push(new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(activeSettingsObject.getReportViewLayout(), offerManager.getReportViewLayoutOptions(), 'settings-report-view-layout')
                    .label(config.getTranslationText('4724'))
                    .setter(updateReportViewLayout)
                    .addTooltip('4725')
                    .displayUpgradeTooltip(!globals.user.isUserAllowedToChangeReportViewLayout(), 'settings-report-view-layout-upgrade-id', config.getTranslationText('4723'), 'upgrade-link-settings-report-view-layout', config.ESSENTIALS_CHECK_LOCATION.SETTINGS_REPORT_VIEW_LAYOUT)
                    .build()));

                updateDisplayReportViewTableValue();

                builtItems.push(createReportViewTable(activeSettingsObject.getReportViews()));

                function updateReportViewLayout(newValue) {
                    if(globals.user.isUserAllowedToChangeReportViewLayout() === true) {
                        activeSettingsObject.setReportViewLayout(newValue);
                        updateDisplayReportViewTableValue();
                    } else {
                        $('#settings-report-view-layout').val(activeSettingsObject.getReportViewLayout());
                        $('#settings-report-view-layout').selectmenu('refresh');
                        nextTick(function () {
                            globals.showDialog(new CustomMessageBox(config.getMessageText('4723', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4723')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) {
                                bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_REPORT_VIEW_LAYOUT, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            });
                        });
                    }
                }

                function updateDisplayReportViewTableValue() {
                    displayReportViewTable.value = activeSettingsObject.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.ADVANCED;
                }

                function resetFunction() {
                    var imageUploadObj = this,
                        newImageSrc;
                    TScMessenger.writeDebugMessage('');
                    
                    if(activeSettingsObject.getReportLogo() !== originalSettingsObject.getReportLogo()) {
                        newImageSrc = originalSettingsObject.getReportLogo();
                        activeSettingsObject.setReportLogo(newImageSrc);
                    } else {
                        if(activeSettingsObject.getReportLogo() !== globals.user.getReportGraphics().landscapeReportHeaderLeftURL) {
                            newImageSrc = globals.user.getReportGraphics().landscapeReportHeaderLeftURL;
                            globals.user.setReportLogo('');
                            activeSettingsObject.setReportLogo('');
                            originalSettingsObject.setReportLogo(globals.user.getReportLogo());
                        }
                    }
                    
                    
                    // Check against globals
                    // if (typeof globals.user.getReportLogo() === 'string' && globals.user.getReportLogo().length > 0) {
                    //     if (imageUploadObj.obv.value.includes(globals.user.getReportLogo())) {
                    //         newImageSrc = globals.user.getReportGraphics().landscapeReportHeaderLeftURL;
                    //     } else {
                    //         if (globals.user.getReportLogo().includes('base64')) {
                    //             newImageSrc = globals.user.getReportLogo();
                    //         } else {
                    //             newImageSrc = 'data:image/png;base64,' + globals.user.getReportLogo();
                    //         }
                    //     }
                    // } else {
                    //     newImageSrc = globals.user.getReportGraphics().landscapeReportHeaderLeftURL;
                    // }

                    // imageUploadObj.setFunction(newImageSrc);
                    if(newImageSrc) {
                        imageUploadObj.obv.value = newImageSrc;
                    }
                }

                function createReportViewTable(rvRows) {
                    var tableRows = [];

                    rvRows.forEach(function (rv) {
                        tableRows.push(createReportViewRow(rv));
                    });

                    return new SettingsTable(new SettingsBuilder()
                        .newTable(tableRows, 'settings-report-view-table')
                        .addDeleteRowFunction(removeReportView)
                        .addChangeSortOrderFunctions(moveReportViewRowUp, moveReportViewRowDown)
                        .isVisible(displayReportViewTable)
                        .build());
                }

                function removeReportView(row) {
                    TScMessenger.writeDebugMessage('Inside the removeReportView function');

                    // Get the report view from the activeSettingsObject
                    var id = row.id,
                        settingsTable = getReportViewTable();
                    var rvToRemove = activeSettingsObject.getReportViewUsingId(id);

                    if(rvToRemove !== undefined && rvToRemove.getIsBlankRow() === false) {
                        // Remove from activeSettingsObject
                        activeSettingsObject.removeReportView(rvToRemove);

                        // remove from table
                        var tempRows = settingsTable.rows.value;
                        var indexToRemove = tempRows.map(function (e) { return e.id; }).indexOf(id);
                        tempRows.splice(indexToRemove, 1);

                        globals.clearShallowRefArrayAndAddItems(settingsTable.rows, tempRows);
                    }
                }

                function moveReportViewRowUp(row) {
                    var id = row.id;
                    var rvToMove = activeSettingsObject.getReportViewUsingId(id);

                    if(rvToMove.getIsBlankRow() === false  && rvToMove.getOrder() > 1) {
                        // Update the settings table
                        // Get the table
                        var reportViewSettingsTable = getReportViewTable();
                        // Get the index of the row that is being moved
                        var index = reportViewSettingsTable.rows.value.indexOf(row);
                        TScMessenger.writeDebugMessage('The index of the row is ' + index);

                        // #region Update the activeSettingsObject table
                        var tempReportViews = activeSettingsObject.getReportViews();
                        // Remove the row from the table
                        tempReportViews.splice(index, 1);
                        // Add the row back in at the new index
                        tempReportViews.splice(index - 1, 0, rvToMove);
                        // Update the table
                        activeSettingsObject.setReportViews(tempReportViews);
                        // Update the order values
                        activeSettingsObject.updateReportViewOrderValues();
                        // #endregion Update the activeSettingsObject table

                        // #region Update the settings table
                        var tempRows = [];
                        activeSettingsObject.getReportViews().forEach(function (rv) {
                            tempRows.push(createReportViewRow(rv));
                        });
                        globals.clearShallowRefArrayAndAddItems(reportViewSettingsTable.rows, tempRows);
                        // #endregion Update the settings table
                    }
                }

                function moveReportViewRowDown(row) {
                    var id = row.id;
                    var rvToMove = activeSettingsObject.getReportViewUsingId(id),
                        nextRow = activeSettingsObject.getReportViewUsingOrder(rvToMove.getOrder() + 1);

                    if(rvToMove.getIsBlankRow() === false && id < activeSettingsObject.getReportViews().length && nextRow.getIsBlankRow() === false) {
                        // Update the settings table
                        // Get the table
                        var reportViewSettingsTable = getReportViewTable();
                        // Get the index of the row that is being moved
                        var index = reportViewSettingsTable.rows.value.indexOf(row);
                        TScMessenger.writeDebugMessage('The index of the row is ' + index);

                        // #region Update the activeSettingsObject table
                        var tempReportViews = activeSettingsObject.getReportViews();
                        // Remove the row from the table
                        tempReportViews.splice(index, 1);
                        // Add the row back in at the new index
                        tempReportViews.splice(index + 1, 0, rvToMove);
                        // Update the table
                        activeSettingsObject.setReportViews(tempReportViews);
                        // Update the order values
                        activeSettingsObject.updateReportViewOrderValues();
                        // #endregion Update the activeSettingsObject table

                        // #region Update the settings table
                        var tempRows = [];
                        activeSettingsObject.getReportViews().forEach(function (rv) {
                            tempRows.push(createReportViewRow(rv));
                        });
                        globals.clearShallowRefArrayAndAddItems(reportViewSettingsTable.rows, tempRows);
                        // #endregion Update the settings table
                    }
                }

                function updateReportViewSelectOptions() {
                    // var idsToRemove = [];

                    // activeSettingsObject.getInternalStandards().forEach(function (ia) {
                    //     idsToRemove.push(ia.getDataPointId());
                    // });

                    getReportViewTable().rows.value.forEach(function (row) {
                        var cellToUpdate = row.cells.find(function (cell) {
                            return cell.cellObject.type === config.INPUT_TYPE.SELECT;
                        });
                        var selectMenuToUpdate = cellToUpdate.cellObject;
                        selectMenuToUpdate.setUpSelectMenu();
                    });
                }

                return builtItems;
            }

            var newItems, items = [];

            newItems = getMenuBuilderItems();

            return newItems;
        }
        function setupIntegrationItems() {
            function createTabGroup() {
                var builtItems = [];

                builtItems.push(new SettingsTabGroup(new SettingsBuilder()
                    .newTabGroup('settings-integrations-ntea-tab-group', createTabs())
                    .displayAsAccordion()
                    .deleteFunction(removeIntegration, globals.user.isUserIntegratedWithNTEA(), config.getTranslationText('1209'))
                    .build()));

                return builtItems;
            }

            function createTabs() {
                var builtItems = [];

                builtItems.push(new SettingsTab(new SettingsBuilder()
                    .newTab('settings-integrations-ntea-tab', 'NTEA', createInputsForNTEAInputGroup(), false)
                    .includeLogoInTab(config.cdnAddress + config.nteaLogoLocation, config.getTranslationText('4896'))
                    .displayDeleteButton(globals.user.isUserIntegratedWithNTEA())
                    .build()));

                return builtItems;
            }

            function createInputsForNTEAInputGroup() {
                var builtItems = [],
                    nteaMembershipObject = activeSettingsObject.getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA),
                    nteaUserValidationType = activeSettingsObject.getNteaUserValidationType(),
                    usernameTooltipTextCode = '4266';
                
                if(nteaUserValidationType === config.NTEA_USER_VALIDATION_TYPES.SALESFORCE) {
                    builtItems.push(new SettingsTextInput(new SettingsBuilder()
                        .newTextInput(nteaMembershipObject.getAttribute1(), nteaMembershipObject.getAttribute1InputId())
                        .label(config.getTranslationText('4862'))
                        .setter(activeSettingsObject.setNteaAttribute1Value)
                        .isDisabled(globals.user.isUserIntegratedWithNTEA())
                        .addTooltip('4863')
                        .build()));

                    usernameTooltipTextCode = '4864';
                }
                
                builtItems.push(new SettingsTextInput(new SettingsBuilder()
                    .newTextInput(nteaMembershipObject.getAttribute2(), nteaMembershipObject.getAttribute2InputId())
                    .label(config.getTranslationText('526'))
                    .setter(activeSettingsObject.setNteaAttribute2Value)
                    .isDisabled(globals.user.isUserIntegratedWithNTEA())
                    .addTooltip(usernameTooltipTextCode)
                    .build()));

                if(nteaUserValidationType === config.NTEA_USER_VALIDATION_TYPES.NTEA) {
                    builtItems.push(new SettingsTextInput(new SettingsBuilder()
                        .newTextInput(nteaMembershipObject.getPassword(), nteaMembershipObject.getPasswordInputId())
                        .label(config.getTranslationText('289'))
                        .isPassword()
                        .setter(activeSettingsObject.setNteaPasswordValue)
                        .isDisabled(globals.user.isUserIntegratedWithNTEA())
                        .build()));
                }
                

                return builtItems;
            }
            var newItems, items = [];

            newItems = createTabGroup();

            return newItems;
        }
        // #endregion Setup Functions

        // #region Format Functions
        function formatLengthValue(value) {
            return Globalize.format((value / getActiveMeasurementSystem().lengthConversion), "n" + getActiveMeasurementSystem().lengthPrecision) + ' ' + getActiveMeasurementSystem().unitLength;
        }

        function formatMassValue(value) {
            return Globalize.format((value * getActiveMeasurementSystem().massConversion), "n" + getActiveMeasurementSystem().massPrecision) + ' ' + getActiveMeasurementSystem().unitMass;
        }

        function formatPercentageValue(value) {
            return offerManager.getUIFormattedPercentageIncrementValue(value, 1);
        }

        function formatAreaValue(value) {
            return Globalize.format((value * getActiveMeasurementSystem().areaConversion), "n" + getActiveMeasurementSystem().areaPrecision) + ' ' + getActiveMeasurementSystem().unitArea;
        }

        function formatDegreesValue(value) {
            return offerManager.getUIFormattedDegreeValue(value);
        }
        // #endregion Format Functions

        // #region Settings Menu Objects
        function SettingsBuilder() {
            var self = this,
                newElement = {
                    displayTooltip: false,
                    tooltipMessage: ''
                };

            /**
            *
            * @param {string} l - label of element
            * @returns {Object} SettingsBuilder object
            */
            function label(l) {
                newElement.label = l;
                return self;
            }

            /**
            *
            * @param {Object} obv
            * @param {Array} oa
            * @param {string} id
            * @param {string} ac
            * @param {function} validationFunc
            * @param {function} failedValidationFunc
            * @param {string} rc
            * @returns {Object} SettingsBuilder object
            */
            function newSelect(value, oa, id, ac, validationFunc, failedValidationFunc, rc) {
                //newElement.obv = obv;
                newElement.value = value;
                newElement.optionsArray = oa;
                newElement.type = config.INPUT_TYPE.SELECT;
                newElement.width = 210;
                newElement.id = id;
                newElement.addClass = typeof ac === 'string' ? ac : null;
                newElement.validationFunc = typeof validationFunc === 'function' ? validationFunc : null;
                newElement.failedValidationFunc = typeof failedValidationFunc === 'function' ? failedValidationFunc : null;
                newElement.rowClass = typeof rc === 'string' ? rc : null;
                return self;
            }

            /**
            * Overrides the default width of selectmenu
            * @param {number} w - length of select in pixels
            * @returns {Object} SettingsBuilder object
            */
            function width(w) {
                newElement.width = w;
                return self;
            }

            /**
            *
            * @param {Number} value
            * @param {string} id
            * @param {string} rc
            * @returns {Object} SettingsBuilder object
            */
            function newTel(value, id, rc) {
                newElement.value = value;
                newElement.type = config.INPUT_TYPE.TEL;
                newElement.id = id;
                newElement.rowClass = typeof rc === 'string' ? rc : '';
                return self;
            }

            /**
            *
            * @param {Number} value
            * @param {string} id
            * @returns {Object} SettingsBuilder object
            */
            function newTextInput(value, id) {
                newElement.value = value;
                newElement.type = config.INPUT_TYPE.TEXT;
                newElement.id = id;
                return self;
            }

            /**
            *
            * @param {boolean} value
            * @param {string} id
            * @param {function} validationFunc
            * @param {function} failedValidationFunc
            * @param {string} rc
            * @returns {Object} SettingsBuilder object
            */
            function newCheckbox(value, id, validationFunc, failedValidationFunc, rc) {
                newElement.value = value;
                newElement.id = id;
                newElement.type = config.INPUT_TYPE.CHECKBOX;
                newElement.validationFunc = typeof validationFunc === 'function' ? validationFunc : null;
                newElement.failedValidationFunc = typeof failedValidationFunc === 'function' ? failedValidationFunc : null;
                newElement.rowClass = typeof rc === 'string' ? rc : null;
                return self;
            }

            /**
            * Add image upload
            * @param {string} id - id for the element
            * @param {string} rl
            * @param {function} rf - function to be called when the reset button is clicked
            * @returns {Object} SettingsBuilder object
            */
            function newImageUpload(id, rl, rf) {
                newElement.type = config.INPUT_TYPE.IMAGE_UPLOAD;
                newElement.id = id;
                newElement.reportLogo = rl;
                newElement.resetFunction = typeof rf === 'function' ? rf : null;
                return self;
            }

            /**
            *
            * @param {string} id
            * @param {object[]} listOfInputs
            * @param {string} buttonText
            * @param {function} passedValidationFunction
            * @returns {object} - object to be built
            */
            function newInputGroup(id, listOfInputs, buttonText, passedValidationFunction) {
                newElement.type = config.INPUT_TYPE.INPUT_GROUP;
                newElement.id = id;
                newElement.listOfInputGroupItems = Array.isArray(listOfInputs) ? listOfInputs : [];
                newElement.buttonText = typeof buttonText === 'string' ? buttonText : config.getTranslationText('4953'); // User Story 17565 Translations 
                newElement.passedValidationFunction = typeof passedValidationFunction === 'function' ? passedValidationFunction : function () {
                    TScMessenger.writeErrorMessage('ERROR - passedValidationFunction not setup correctly');
                };
                return self;
            }

            /**
            * Create new tab group
            * @param {string} id - id to be used in HTML
            * @param {object[]} listOfTabs - list of tabs in the tab group
            * @returns {object} - object to be built
            */
            function newTabGroup(id, listOfTabs) {
                newElement.id = id;
                newElement.displayType = config.TAB_GROUP_DISPLAY_TYPE.TABS;
                newElement.tabs = listOfTabs;
                return self;
            }

            /**
            * Display tab group as an accordion
            * @returns {object} - object to be built
            */
            function displayAsAccordion() {
                newElement.displayType = config.TAB_GROUP_DISPLAY_TYPE.ACCORDION;
                return self;
            }

            /**
            * Creates object for tab to be included in tab group
            * @param {string} id - id for HTML
            * @param {string} label - Label to be displayed in tab/accordion
            * @param {object[]} listOfItems - Items to be included in tab
            * @param {boolean} displayText -
            * @returns {object} - object to be built
            */
            function newTab(id, label, listOfItems, displayText) {
                newElement.id = id;
                newElement.label = label;
                newElement.items = listOfItems;
                newElement.displayText = typeof displayText === 'boolean' ? displayText : true;
                return self;
            }

            /**
            * Allow image to be displayed in the tab/accordion beside the label
            * @param {string} imgSrc - The location of the logo img
            * @param {string} alternateText - Alternate text for image
            * @returns {object} - object to be built
            */
            function includeLogoInTab(imgSrc, alternateText) {
                newElement.includeLogo = true;
                newElement.logoImageSrc = imgSrc;
                newElement.alternateText = typeof alternateText === 'string' ? alternateText : config.getTranslationText('4897');
                return self;
            }

            /**
            * Include function that is run when value has been changed
            * @param {function} uf - callback function to be run when value has been updated
            * @returns {object} - object to be built
            */
            function updateFunction(uf) {
                newElement.updateFunction = uf;
                return self;
            }


            /**
            *
            * @param {function} sf - setter function
            * @returns {Object} SettingsBuilder object
            */
            function setter(sf) {
                newElement.setFunction = sf;
                return self;
            }

            /**
            *
            * @param {function} gf - getter function
            * @returns {Object} SettingsBuilder object
            */
            function getter(gf) {
                newElement.getFunction = gf;
                return self;
            }

            /**
            *
            * @param {function} ff
            * @returns {Object} SettingsBuilder object
            */
            function formatFunction(ff) {
                newElement.formatFunction = ff;
                return self;
            }

            function incrementType(it) {
                newElement.incrementType = it;
                return self;
            }

            function addAttrObvGroup(v) {
                newElement.attrObvGroup = v;
                return self;
            }

            function customValidateFunction(cvf) {
                newElement.customValidateFunction = cvf;
                return self;
            }

            function isPassword() {
                newElement.isPassword = true;
                return self;
            }

            /**
            *
            * @param {function} df
            * @returns {Object} SettingsBuilder object
            */
            function deleteFunction(df, ddb, dbt) {
                newElement.deleteFunction = typeof df === 'function' ? df : null;
                newElement.displayDeleteButton = typeof ddb === 'boolean' ? ddb : newElement.deleteFunction !== null ? true : false;
                newElement.deleteButtonTooltip = typeof dbt === 'string' ? dbt : '';
                return self;
            }

            /**
            *
            * @param {boolean} [ddb=false] - Display delete button to user
            * @returns {Object} SettingsBuilder object
            */
            function displayDeleteButton(ddb) {
                newElement.displayDeleteButton = typeof ddb === 'boolean' ? ddb : false;
                return self;
            }

            /**
            * Disables input box when true
            * @param {boolean} [id=false] - Display delete button to user
            * @param {string|null} [messageCode=null]
            * @param {string[]|null} [buttons=null]
            * @param {function|null} [callback=null]
            * @returns {Object} SettingsBuilder object
            */
            function isDisabled(id, messageCode, buttons, callback) {
                newElement.isDisabled = typeof id === 'boolean' ? id : false;
                newElement.isDisabledMessageCode = typeof messageCode === 'string' ? messageCode : null;
                newElement.isDisabledButtons = Array.isArray(buttons) === true ? buttons : null;
                newElement.isDisabledFunction = typeof callback === 'function' ? callback : null;
                return self;
            }

            /**
            *
            * @param {string} id
            * @param {string} message
            * @returns {Object} SettingsBuilder object
            */
            function displayUpgradeTooltip(displayVal, id, message, linkId, intercomVal) {
                newElement.displayUpgradeTooltip = typeof displayVal === 'boolean' ? displayVal : false;
                newElement.upgradeTooltipMessage = typeof message === 'string' ? message : '';
                newElement.upgradeTooltipMessageId = typeof id === 'string' ? id : '';
                newElement.upgradeTooltipMessageLinkId = typeof linkId === 'string' ? linkId : '';
                newElement.upgradeTooltipIntercomValue = typeof intercomVal === 'string' ? intercomVal : '';

                return self;
            }

            /**
            * Display info tooltip beside
            * @param {string} transalationTextCode
            * @returns {Object} SettingsBuilder object
            */
            function addTooltip(transalationTextCode) {
                newElement.displayTooltip = true;
                newElement.tooltipMessage = config.getTranslationText(transalationTextCode);
                return self;
            }

            /**
            *
            * @param {Object} obv
            * @param {Array} oa
            * @param {string} id
            * @param {string} ac
            * @returns {Object} SettingsBuilder object
            */
            function newMultiSelect(value, ms, id, ac) {
                newElement.value = value;
                newElement.multiSelect = ms;
                newElement.type = config.INPUT_TYPE.MULTISELECT;
                newElement.width = 210;
                newElement.id = id;
                newElement.addClass = typeof ac === 'string' ? ac : null;
                return self;
            }

            /**
            *
            * @param {function} cb - function to be run
            * @returns {Object} SettingsBuilder object
            */
            function addPostInitializeFunction(cb) {
                newElement.postInitializeFunction = typeof cb === 'function' ? cb : function () { };
                return self;
            }

            /**
            *
            * @param {Object[]} rows - The rows to be displayed in the table. Should be generated using newTableRow
            * @param {string} id - Id of the element. Also used for tooltip
            * @param {Object[]} [headerRow] - The row to be displayed in the table header. Should be generated using newTableRow
            * @param {string} [customCss] - String value containing the custom CSS class to be attached to the table
            * @returns {Object} SettingsBuilder object
            */
            function newTable(rows, id, headerRow, customCss) {
                if (!Array.isArray(rows)) {
                    throw 'rows param in newMenuTable must be an array';
                }
                if (typeof id !== 'string') {
                    throw 'newTable id must be a string';
                }
                newElement.id = id;
                newElement.type = config.ITEM_TYPE.MENU_TABLE;
                if (headerRow !== undefined && headerRow !== null) {
                    newElement.includeHeader = true;
                    newElement.headerRow = headerRow;
                }
                newElement.rows = rows;
                newElement.customCss = typeof customCss === 'string' ? customCss : '';
                return self;
            }

            /**
            *
            * @param {Object[]} cells
            * @returns {Object} SettingsBuilder object
            */
            function newTableRow(cells, id) {
                if (!Array.isArray(cells)) {
                    throw 'cells param in newMenuTableRow must be an array';
                }
                newElement.itemType = config.ITEM_TYPE.MENU_TABLE_ROW;
                newElement.cells = cells;
                newElement.id = typeof id === 'number' ? id : null;
                //newElement.rowId = typeof id === 'number' ?  id : null
                return self;
            }

            /**
            *
            * @param {any} cellObject
            * @returns {Object} SettingsBuilder object
            */
            function newTableTextCell(cellObject, customCss) {
                newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
                newElement.cellType = config.MENU_TABLE_CELL_TYPES.TEXT;
                if (cellObject !== undefined) {
                    newElement.cellObject = cellObject;
                } else {
                    newElement.cellObject = {
                        value: 'Cell Item'
                    };
                }
                newElement.customCssForTd = typeof customCss === 'string' ? customCss : '';

                return self;
            }


            function newTableDeleteRowCell(customCss, id) {
                newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
                newElement.cellType = config.MENU_TABLE_CELL_TYPES.DELETE_ROW;
                newElement.cellObject = {
                    id: id,
                    value: 'Cell Item'
                };
                newElement.customCssForTd = typeof customCss === 'string' ? customCss : '';

                return self;
            }

            function newTableAllowChangeSortRowCell(id) {
                newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
                newElement.cellType = config.MENU_TABLE_CELL_TYPES.ALLOW_CHANGE_SORT;
                newElement.cellObject = {
                    id: id,
                    value: 'Cell Item'
                };

                return self;
            }

            /**
            *
            * @param {any} cellInputObject
            * @returns {Object} SettingsBuilder object
            */
            function newTableInputCell(cellInputObject, customCss) {
                newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
                newElement.cellType = config.MENU_TABLE_CELL_TYPES.INPUT;
                newElement.cellObject = cellInputObject;
                newElement.customCssForTd = typeof customCss === 'string' ? customCss : '';
                return self;
            }

            function useTextArea(max) {
                newElement.useTextArea = true;
                newElement.maxLength = typeof max === 'number' ? max : 1000;
                return self;
            }

            /**
             * Adds delete row function to table row
             * @param {function} drf - function to be run when delete button is clicked
             * @returns {Object} SettingsBuilder object
             */
            function addDeleteRowFunction(drf) {
                newElement.deleteRowFunction = typeof drf === 'function' ? drf : null;
                return self;
            }

            /**
             * Adds move up and move down functions to table row
             * @param {function} mruf - function to be run when move up button is clicked
             * @param {function} mrdf - function to be run when move down button is clicked
             * @returns {Object} SettingsBuilder object
             */
            function addChangeSortOrderFunctions(mruf, mrdf) {
                newElement.moveRowUpFunction = typeof mruf === 'function' ? mruf : null;
                newElement.moveRowDownFunction = typeof mrdf === 'function' ? mrdf : null;
                return self;
            }

            /**
             *
             * @param {function} iv - function that determines if the element is visible
             * @returns {Object} SettingsBuilder object
             */
            function isVisible(iv) {
                // newElement.isVisible = typeof iv === 'function' ? iv : false;
                newElement.isVisible = iv;
                return self;
            }

            /**
             * 
             * @param {function} func 
             * @returns {Object} SettingsBuilder object
             */
            function addSetUpSelectMenuFunction(func) {
                newElement.setUpSelectMenuFunction = typeof func === 'function' ? func : function () { };
                return self;
            }

            /**
            * Returns built SettingsBuilder object
            * @returns {Object} Returns built SettingsBuilder object
            */
            function build() {
                return newElement;
            }

            this.newSelect = newSelect;
            this.newTel = newTel;
            this.newTextInput = newTextInput;
            this.newCheckbox = newCheckbox;
            this.newImageUpload = newImageUpload;
            this.newInputGroup = newInputGroup;
            this.newTabGroup = newTabGroup;
            this.displayAsAccordion = displayAsAccordion;
            this.newTab = newTab;
            this.includeLogoInTab = includeLogoInTab;
            this.label = label;
            this.width = width;
            this.getter = getter;
            this.setter = setter;
            this.formatFunction = formatFunction;
            this.updateFunction = updateFunction;
            this.incrementType = incrementType;
            this.addAttrObvGroup = addAttrObvGroup;
            this.customValidateFunction = customValidateFunction;
            this.isPassword = isPassword;
            this.deleteFunction = deleteFunction;
            this.displayDeleteButton = displayDeleteButton;
            this.isDisabled = isDisabled;
            this.displayUpgradeTooltip = displayUpgradeTooltip;
            this.addTooltip = addTooltip;
            this.newMultiSelect = newMultiSelect;
            this.newTable = newTable;
            this.newTableRow = newTableRow;
            this.newTableTextCell = newTableTextCell;
            this.newTableInputCell = newTableInputCell;
            this.newTableDeleteRowCell = newTableDeleteRowCell;
            this.newTableAllowChangeSortRowCell = newTableAllowChangeSortRowCell;
            this.addPostInitializeFunction = addPostInitializeFunction;
            this.useTextArea = useTextArea;
            this.addDeleteRowFunction = addDeleteRowFunction;
            this.addChangeSortOrderFunctions = addChangeSortOrderFunctions;
            this.isVisible = isVisible;
            this.addSetUpSelectMenuFunction = addSetUpSelectMenuFunction;
            this.build = build;
        }

        function SettingsInputBase(initObj) {
            var self = this;
            self.id = initObj.id;
            self.type = initObj.type;
            self.label = initObj.label;
            self.value = initObj.value;
            self.originalValue = initObj.value;
            self.setFunction = initObj.setFunction;
            self.getFunction = initObj.getFunction;
            self.updateFunction = initObj.updateFunction;
            // self.obv = ko.observable(self.value);
            self.obv = shallowRef(self.value);
            self.tooltipId = initObj.id + '-tooltip';
            self.displayTooltip = initObj.displayTooltip;
            self.tooltipMessage = initObj.tooltipMessage;
            self.displayUpgradeTooltip = typeof initObj.displayUpgradeTooltip === 'boolean' ? initObj.displayUpgradeTooltip : false;
            self.upgradeTooltipMessage = typeof initObj.upgradeTooltipMessage === 'string' ? initObj.upgradeTooltipMessage : '';
            self.upgradeTooltipMessageId = typeof initObj.upgradeTooltipMessageId === 'string' ? initObj.upgradeTooltipMessageId : '';
            self.upgradeTooltipMessageLinkId = typeof initObj.upgradeTooltipMessageLinkId === 'string' ? initObj.upgradeTooltipMessageLinkId : '';
            self.upgradeTooltipIntercomValue = typeof initObj.upgradeTooltipIntercomValue === 'string' ? initObj.upgradeTooltipIntercomValue : '';

            self.showPopover = function () {
                globals.displayTooltip('#' + self.tooltipId);
            };

            self.showUpgradePopover = function () {
                globals.displayTooltip('#' + self.upgradeTooltipMessageId, onShowUpgradePopoverFunction, removeClickEvent);
            };

            self.updateTooltipMessage = function (newMessage) {
                var self = this;
                self.tooltipMessage = newMessage;
                $('#' + self.tooltipId).attr('data-content', newMessage);
                $('#' + self.tooltipId).webuiPopover('destroy');
            };

            function onShowUpgradePopoverFunction() {
                $('#' + self.upgradeTooltipMessageLinkId).click(function () {
                    globals.hideTooltip('#' + self.upgradeTooltipMessageId);
                    bus.emit('handleEssentialsMessageResponse', { clickedButtonText: config.getTranslationText('271'), source: self.upgradeTooltipIntercomValue, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                });
            }

            function removeClickEvent() {
                $('#' + self.upgradeTooltipMessageLinkId).off('click');
            }
        }

        function SettingsSelectMenu(initSelectMenuObj) {
            SettingsInputBase.call(this, initSelectMenuObj);
            var self = this;
            self.width = initSelectMenuObj.width;
            // self.optionsArray = ko.observableArray(initSelectMenuObj.optionsArray);
            self.optionsArray = shallowRef(initSelectMenuObj.optionsArray);
            self.isVisible = initSelectMenuObj.isVisible !== undefined ? initSelectMenuObj.isVisible : function () { return true; };
            self.addClass = initSelectMenuObj.addClass;
            self.validationFunc = typeof initSelectMenuObj.validationFunc === 'function' ? initSelectMenuObj.validationFunc : null;
            self.failedValidationFunc = typeof initSelectMenuObj.failedValidationFunc === 'function' ? initSelectMenuObj.failedValidationFunc : null;
            self.isDisabled = initSelectMenuObj.isDisabled !== undefined ? initSelectMenuObj.isDisabled : false;
            self.rowClass = typeof initSelectMenuObj.rowClass === 'string' ? initSelectMenuObj.rowClass : '';
            self.setUpSelectMenuFunction = initSelectMenuObj.setUpSelectMenuFunction !== undefined ? initSelectMenuObj.setUpSelectMenuFunction : function () { };

            self.setIsDisabled = function (newValue) {
                this.isDisabled = typeof newValue === 'boolean' ? newValue : false;
            };

            self.setUpSelectMenu = function () {
                function closeFunction(event, data) {
                    var currentValue = '';
                    if (self.validationFunc !== null) {
                        if (self.validationFunc() === true) {
                            completeSelectMenuClose();
                        } else {
                            if (self.failedValidationFunc !== null) {
                                $('#' + self.id).val(self.obv.value);
                                $('#' + self.id).selectmenu('refresh');
                                self.failedValidationFunc();
                            }
                        }
                    } else {
                        completeSelectMenuClose();
                    }

                    function completeSelectMenuClose() {
                        var valToPass;
                        if (isNaN(parseInt(data.item.value))) {
                            valToPass = data.item.value;
                        } else {
                            valToPass = parseInt(data.item.value);
                        }
                        // set value
                        self.setFunction(valToPass);
                        self.obv.value = valToPass;
                        // update function if available
                        if (self.updateFunction !== undefined) {
                            self.updateFunction(valToPass);
                        }
                    }
                }

                function openFunction(event) {
                    try {
                        var modalZIndex = $('.modalHost').zIndex();
                        var newZindex = modalZIndex + 50;
                        $('#' + event.target.id + '-menu').parent().css('zIndex', newZindex);
                    } catch (e) {

                        TScMessenger.writeDebugMessage('Error raised');
                    }
                }

                if (self.addClass !== null) {
                    $('#' + self.id).selectmenu({
                        width: self.width,
                        change: closeFunction,
                        open: openFunction
                    }).selectmenu("menuWidget")
                        .addClass(self.addClass);
                } else {
                    $('#' + self.id).selectmenu({
                        width: self.width,
                        change: closeFunction,
                        open: openFunction
                    });
                }

                if(self.isDisabled === true) {
                    $('#' + self.id).selectmenu('disable');
                }

                self.setUpSelectMenuFunction();
            };

            self.updateSelectMenuOptions = function (newOptions) {
                if (Array.isArray(newOptions)) {
                    self.optionsArray.value = newOptions;
                    nextTick(function () {
                        if ($('#' + self.id).selectmenu() !== undefined) {
                        $('#' + self.id).selectmenu('refresh');
                    }
                    });

                }
            };
        }

        /**
        * Creates MultiSelect drop down that the user can interact with
        * @param {object} initMultiSelectObj - Initial object to create multiSelect
        */
        function SettingsMultiSelect(initMultiSelectObj) {
            SettingsInputBase.call(this, initMultiSelectObj);
            var self = this;
            self.width = initMultiSelectObj.width;
            self.optionsArray = shallowRef(initMultiSelectObj.optionsArray);
            self.isVisible = initMultiSelectObj.isVisible !== undefined ? initMultiSelectObj.isVisible : function () { return true; };
            self.addClass = initMultiSelectObj.addClass;
            self.multiSelect = initMultiSelectObj.multiSelect;
            self.postInitializeFunction = typeof initMultiSelectObj.postInitializeFunction === 'function' ? initMultiSelectObj.postInitializeFunction : null;

            self.setUpMultiSelect = function () {
                self.multiSelect.initializeMultiSelect();

                if (typeof self.postInitializeFunction === 'function') {
                    self.postInitializeFunction();
                }
            };

            self.updateMultiSelectOptions = function (newOptions) {

            }
        }

        function SettingsItemBase(initBaseItem) {
            var settingsItemBase = this;

            settingsItemBase.id = initBaseItem.id;
            settingsItemBase.type = initBaseItem.type;
            settingsItemBase.customCss = typeof initBaseItem.customCss === 'string' ? initBaseItem.customCss : '';
        }

        /**
        *
        * @param {Object} initSettingsTable - Table to be included as an item in a MenuStep
        */
        function SettingsTable(initSettingsTable) {
            SettingsInputBase.call(this, initSettingsTable);
            SettingsItemBase.call(this, initSettingsTable);
            var settingsTable = this;
            settingsTable.type = config.ITEM_TYPE.MENU_TABLE;

            construct(initSettingsTable);

            //#region Public functions
            settingsTable.createJsElements = function () {
                this.rows.value.forEach(function (row) {
                    row.createJsElements();
                });
            };

            settingsTable.addRow = function (newRow, indexToReplace) {
                var tempRows;
                if (typeof indexToReplace === 'number' && this.rows.value.length !== indexToReplace) {
                    tempRows = [];
                    this.rows.value.forEach(function (row, index) {
                        if (index === indexToReplace) {
                            tempRows.push(newRow);
                        }
                        tempRows.push(row);
                    })
                } else {
                    tempRows = this.rows.value;
                    tempRows.push(newRow);
                }

                // this.rows.value = tempRows;
                globals.clearShallowRefArrayAndAddItems(this.rows, tempRows);
                //this.rows.push(newRow);
            }

            settingsTable.deleteRow = function (row) {
                if(typeof settingsTable.deleteRowFunction === 'function') {
                    settingsTable.deleteRowFunction(row, DELETE_FUNCTION_PARAM_TYPE.ROW, true);
                }
            };

            /**
            * Delete row from table using ID of row
            * @param {object} id - ID of cell to be deleted
            * @param {boolean} [addBlankRowIfNecessary] - Run check to see if blank row should be added and add if required
            */
            settingsTable.deleteRowUsingId = function (id, addBlankRowIfNecessary) {
                if(typeof settingsTable.deleteRowFunction === 'function') {
                    settingsTable.deleteRowFunction(id, DELETE_FUNCTION_PARAM_TYPE.ID, addBlankRowIfNecessary);
                }
                // var isToRemove = activeSettingsObject.getSettingsInternalStandardUsingId(id);
                // if (isToRemove.getIsBlankRow() === false) {
                //     // Remove from activeSettingsObject
                //     activeSettingsObject.removeInternalStandard(isToRemove);
                //     // remove from table
                //     var tempRows = settingsTable.rows.value;
                //     var indexToRemove = tempRows.map(function (e) { return e.id; }).indexOf(id);
                //     var rowToCleanUp = tempRows.splice(indexToRemove, 1);
                //     // settingsTable.rows.value = tempRows;
                //     globals.clearShallowRefArrayAndAddItems(settingsTable.rows, tempRows);

                //     if (addBlankRowIfNecessary === true && settingsTable.rows.value.length < 5 && activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1].getIsBlankRow() === false) {
                //         activeSettingsObject.addInternalStandard(activeSettingsObject.createEmptyInternalStandard());
                //         var newRow = createInternalStandardsRow(activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1], []);
                //         settingsTable.addRow(newRow);

                //         // setup all selectmenus
                //         settingsTable.rows.value.forEach(function (row) {
                //             row.cells.forEach(function (cell) {
                //                 if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                //                     cell.cellObject.setUpSelectMenu();
                //                 }
                //             });
                //         });
                //     }
                // }
            };

            settingsTable.moveRowUp = function (row) {
                if(typeof settingsTable.moveRowUpFunction === 'function') {
                    settingsTable.moveRowUpFunction(row);
                }
            };

            settingsTable.moveRowDown = function (row) {
                if(typeof settingsTable.moveRowDownFunction === 'function') {
                    settingsTable.moveRowDownFunction(row);
                }
            };

            settingsTable.getNumberOfRows = function () {
                return this.rows.value.length;
            };

            settingsTable.getRowUsingId = function (id) {
                return this.rows.value.filter(function (row) {
                    return row.id === id;
                })[0];
            }
            //#endregion Public functions

            //#region Private functions
            function construct(initSettingsTable) {
                if (initSettingsTable.includeHeader === true) {
                    settingsTable.includeHeader = true;
                    settingsTable.header = initSettingsTable.headerRow;
                } else {
                    settingsTable.includeHeader = false;
                    settingsTable.header = null;
                }

                if(initSettingsTable.label !== undefined || initSettingsTable.displayTooltip === true) {
                    settingsTable.displayLabelRow = true;
                } else {
                    settingsTable.displayLabelRow = false;
                }

                settingsTable.rows = shallowRef(initSettingsTable.rows);
                settingsTable.deleteRowFunction = typeof initSettingsTable.deleteRowFunction === 'function' ? initSettingsTable.deleteRowFunction : null;
                settingsTable.moveRowUpFunction = typeof initSettingsTable.moveRowUpFunction === 'function' ? initSettingsTable.moveRowUpFunction : null;
                settingsTable.moveRowDownFunction = typeof initSettingsTable.moveRowDownFunction === 'function' ? initSettingsTable.moveRowDownFunction : null;
                settingsTable.isVisible = initSettingsTable.isVisible !== undefined ? initSettingsTable.isVisible : true;
            }
            //#endregion Private functions
        }

        /**
        *
        * @param {Object} initSettingsTableRow - Row to be included in MenuTable
        */
        function SettingsTableRow(initSettingsTableRow) {
            SettingsItemBase.call(this, initSettingsTableRow);
            var settingsTableRow = this;
            settingsTableRow.type = config.ITEM_TYPE.MENU_TABLE_ROW;

            construct(initSettingsTableRow);

            //#region Public functions
            settingsTableRow.createJsElements = function () {
                this.cells.forEach(function (cell) {
                    cell.createJsElements();
                });
            };

            settingsTableRow.cleanUp = function () {
                this.cells.forEach(function (cell) {
                    cell.cleanUp();
                });
            };
            //#endregion Public functions

            //#region Private functions
            function construct(initSettingsTableRow) {
                settingsTableRow.cells = [];
                initSettingsTableRow.cells.forEach(function (cell, index) {
                    cell.menuPath = settingsTableRow.menuPath + '.TABLECELL-' + index;
                    //settingsTableRow.cells.push(new SettingsTableCell(cell));
                    settingsTableRow.cells.push(cell);
                });
                settingsTableRow.useCellObservableForValue = typeof initSettingsTableRow.useCellObservableForValue === 'boolean' ? initSettingsTableRow.useCellObservablesForValue : false;
                settingsTableRow.id = typeof initSettingsTableRow.id === 'number' ? initSettingsTableRow.id : null;
                settingsTableRow.disableDelete = shallowRef(false);
            }
            //#endregion Private functions
        }

        /**
        *
        * @param {Object} initSettingsTableHeader - Header row to be included in MenuTable
        */
        function SettingsTableHeader(initSettingsTableHeader) {
            SettingsTableRow.call(this, initSettingsTableHeader);
            var menuTableHeader = this;
        }

        /**
        *
        * @param {Object} initSettingsTableCell - Cell to be included in MenuTableRow/MenuTableHeader
        */
        function SettingsTableCell(initSettingsTableCell) {
            SettingsItemBase.call(this, initSettingsTableCell);
            var settingsTableCell = this;
            settingsTableCell.type = config.ITEM_TYPE.MENU_TABLE_CELL;
            settingsTableCell.cellType = initSettingsTableCell.cellType;

            //#region Public functions
            settingsTableCell.createJsElements = function () {
                this.createJsElementsFunction();
            };

            settingsTableCell.cleanUp = function () {
                    this.cleanUpFunction();
            };
            //#endregion Public functions

            //#region Private functions
            function construct(initSettingsTableCell) {
                settingsTableCell.cellType = initSettingsTableCell.cellType;
                settingsTableCell.createJsElementsFunction = function () { };
                settingsTableCell.cleanUpFunction = function () { };
                settingsTableCell.customCssForTd = typeof initSettingsTableCell.customCssForTd === 'string' ? initSettingsTableCell.customCssForTd : '';

                if (settingsTableCell.cellType === config.MENU_TABLE_CELL_TYPES.INPUT) {
                    settingsTableCell.cellInputType = initSettingsTableCell.cellObject.type;
                    initSettingsTableCell.cellObject.menuPath = initSettingsTableCell.menuPath + '.CELL';
                    settingsTableCell.cellObject = initSettingsTableCell.cellObject;

                    switch (settingsTableCell.cellInputType) {
                        case config.INPUT_TYPE.CHECKBOX:
                            //settingsTableCell.cellObject = initSettingsTableCell.cellObject;
                            break;
                        case config.INPUT_TYPE.SELECT:
                            settingsTableCell.createJsElementsFunction = function () {
                                this.cellObject.setUpSelectMenu();
                            };
                            break;
                        case config.INPUT_TYPE.UPDOWNBOX:
                            //settingsTableCell.cellObject = initSettingsTableCell.cellObject;
                            break;
                        case config.INPUT_TYPE.INPUT_GROUP:
                            //settingsTableCell.cellObject = new MenuInputGroup(initSettingsTableCell.cellObject);
                            settingsTableCell.createJsElementsFunction = function () {
                                this.cellObject.select.createSelectMenu();
                            };
                            break;
                        case config.INPUT_TYPE.TEL:
                            settingsTableCell.cleanUpFunction = function () {
                                this.cellObject.cleanUp();
                            };
                            break;
                        default:
                            break;
                    }
                } else {
                    settingsTableCell.cellObject = initSettingsTableCell.cellObject;
                }
            }
            //#endregion Private functions
            construct(initSettingsTableCell);
        }

        function SettingsTelInput(initTelObj) {
            SettingsInputBase.call(this, initTelObj);
            var self = this;

            self.incrementType = initTelObj.incrementType;
            self.obv = shallowRef(self.value);
            self.formatFunction = initTelObj.formatFunction;
            self.getFunction = initTelObj.getFunction;
            self.attrObvGroup = initTelObj.attrObvGroup;
            //self.validator = self.attrObvGroup !== undefined && self.attrObvGroup.getValidator !== undefined ? self.attrObvGroup.getValidator() : function () { return true; };
            self.validator = self.attrObvGroup !== undefined && self.attrObvGroup.getValidator !== undefined ? self.attrObvGroup.getValidator() : null;
            self.obvFormatted = computed(function () {
                // triggerSubscriptionUpdates;
                triggerSubscriptionUpdates.value;
                return setInputValue();
            });
            self.errorMessage = shallowRef(null);
            self.rowClass = typeof initTelObj.rowClass === 'string' ? initTelObj.rowClass : '';

            setInputValue();

            self.blurFunction = function () {
                var tempVal, updateValue,
                    newValue = $('#' + self.id).val();
                if (newValue !== '') {
                    tempVal = convertInputValueToMetric(newValue);
                    if (self.getFunction() !== tempVal) {
                        if (self.validator !== undefined && self.validator !== null) {
                            var deltaVal = tempVal - self.originalValue;
                            if (self.validator.validate(tempVal, self.originalValue, deltaVal)) {
                                updateValue = convertInputValueFromMetric(tempVal);
                                self.obv.value = updateValue;
                                self.setFunction(updateValue);
                            } else {
                                // raise error
                                if (self.attrObvGroup.getErrorsArray().length >= 1) {
                                    self.attrObvGroup.errorMessage.value = self.attrObvGroup.getErrorsArray()[self.attrObvGroup.getErrorsArray().length - 1];
                                } else {
                                    if (self.id.includes('-max-')) {
                                        self.attrObvGroup.errorMessage.value = config.getTranslationText('4881');
                                    } else {
                                        self.attrObvGroup.errorMessage.value = config.getTranslationText('4882');
                                    }
                                }
                                self.errorMessage.value = self.attrObvGroup.errorMessage.value;
                                offerManager.displayError(self.id, self.errorMessage.value);
                                self.attrObvGroup.clearErrors();
                            }
                        } else {
                            updateValue = convertInputValueFromMetric(tempVal);
                            self.obv.value = updateValue;
                            self.setFunction(updateValue);
                        }
                    }
                    triggerSubscriptionUpdates.value = new Date().getTime();
                    setInputValue();
                } else {
                    // raise error
                    self.errorMessage.value = self.attrObvGroup.errorMessage.value;
                    offerManager.displayError(self.id, self.errorMessage.value);
                }
            }

            self.selectAllText = function () {
                globals.selectAllTextById(self.id);
                self.errorMessage.value = null;
                translateValue = true;
            }

            self.cleanUp = function () {
                // self.obv.dispose();
                self.obv.value = null;
                self.obv = null;

                // self.errorMessage.dispose();
                self.errorMessage.value = null;
                self.errorMessage = null;
            };

            function setInputValue() {
                var value;
                if (self.formatFunction !== undefined) {
                    value = self.formatFunction(self.getFunction());
                } else {
                    value = self.obv.value;
                }
                $('#' + self.id).val(value);
                return value;
            }

            function convertInputValueToMetric(value) {
                var newValue = typeof value === 'string' ? globals.removeUnneccessaryCharactersFromGlobalizeNumberString(value) : value,
                    measurementSystemToUse = getActiveMeasurementSystem(),
                    incrementTypeToUse = typeof self.incrementType === 'function' ? self.incrementType() : self.incrementType;

                switch (incrementTypeToUse) {
                    case config.INCREMENT_TYPE.LENGTH:
                        return globals.returnFloat(newValue) * measurementSystemToUse.lengthConversion;
                    case config.INCREMENT_TYPE.MASS:
                        return globals.returnFloat(newValue) / measurementSystemToUse.massConversion;
                    case config.INCREMENT_TYPE.AREA:
                        return globals.returnFloat(newValue) / measurementSystemToUse.areaConversion;
                    default:
                        return globals.returnFloat(newValue);
                }
            }

            function convertInputValueFromMetric(value) {
                var newValue = typeof value === 'string' ? globals.removeUnneccessaryCharactersFromGlobalizeNumberString(value) : value,
                    measurementSystemToUse = getActiveMeasurementSystem(),
                    incrementTypeToUse = typeof self.incrementType === 'function' ? self.incrementType() : self.incrementType;

                switch (incrementTypeToUse) {
                    case config.INCREMENT_TYPE.LENGTH:
                        return globals.returnFloat(newValue) / measurementSystemToUse.lengthConversion;
                    case config.INCREMENT_TYPE.MASS:
                        return globals.returnFloat(newValue) * measurementSystemToUse.massConversion;
                    case config.INCREMENT_TYPE.AREA:
                        return globals.returnFloat(newValue) * measurementSystemToUse.areaConversion;
                    default:
                        return globals.returnFloat(newValue);
                }
            }

            function inputValueAsFloat(value) {
                return globals.returnFloat(globals.removeUnneccessaryCharactersFromGlobalizeNumberString(value));
            }
        }

        function SettingsCheckbox(initCheckboxObj) {
            SettingsInputBase.call(this, initCheckboxObj);
            var self = this;
            self.validationFunc = typeof initCheckboxObj.validationFunc === 'function' ? initCheckboxObj.validationFunc : null;
            self.failedValidationFunc = typeof initCheckboxObj.failedValidationFunc === 'function' ? initCheckboxObj.failedValidationFunc : null;
            self.rowClass = typeof initCheckboxObj.rowClass === 'string' ? initCheckboxObj.rowClass : '';

            self.toggleCheckbox = function () {
                if (self.validationFunc !== null) {
                    if (self.validationFunc() === true) {
                        completeToggleCheckbox();
                    } else {
                        if (self.failedValidationFunc !== null) {
                            self.failedValidationFunc();
                        }
                    }
                } else {
                    completeToggleCheckbox();
                }


                function completeToggleCheckbox() {
                    if (self.obv.value === true) {
                        self.obv.value = false;
                    } else {
                        self.obv.value = true;
                    }
                    self.setFunction(self.obv.value);
                }
            }
        }

        function SettingsImageUpload(initImageUploadMenuObj) {
            SettingsInputBase.call(this, initImageUploadMenuObj);
            var self = this;
            self.fileInputId = self.id + '-file-input';
            self.obv.value = initImageUploadMenuObj.reportLogo;
            self.resetFunction = typeof initImageUploadMenuObj.resetFunction === 'function' ? initImageUploadMenuObj.resetFunction : null;
            self.messageText = config.getTranslationText('4249', [config.maxReportLogoFileSizeInKb]);
            self.isDisabled = typeof initImageUploadMenuObj.isDisabled === 'boolean' ? initImageUploadMenuObj.isDisabled : false;
            self.isDisabledMessageCode = typeof initImageUploadMenuObj.isDisabledMessageCode === 'string' ? initImageUploadMenuObj.isDisabledMessageCode : '4301';
            self.isDisabledButtons = Array.isArray(initImageUploadMenuObj.isDisabledButtons) === true ? initImageUploadMenuObj.isDisabledButtons : [config.getTranslationText('271')];
            self.isDisabledFunction = typeof initImageUploadMenuObj.isDisabledFunction === 'function' ? initImageUploadMenuObj.isDisabledFunction : function () { };

            self.updateClick = function () {
                if (!this.isDisabled) {
                    if (!globals.user.isTrialUser()) {
                        $('#' + this.fileInputId).click();
                    } else {
                        // close();
                        nextTick(function(){
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1591', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('54'), config.getTranslationText('65')], config.getMessageReference('1591')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                .addCallback(function (clickedButtonText) {
                                    if (clickedButtonText === config.getTranslationText('54')) {
                                        nextTick(function(){
                                            bus.emit('openBuyNowModal', { modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY});
                                        });
                                    }
                                });
                        });


                        return false;
                    }
                } else {
                    // close();
                    nextTick(function() {
                        globals.showDialog(new CustomMessageBox(config.getMessageText(self.isDisabledMessageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, self.isDisabledButtons, config.getMessageReference(self.isDisabledMessageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(self.isDisabledFunction);
                    });
                }
            };

            self.resetClick = function () {
                var self = this;
                if (!this.isDisabled) {
                    if (typeof self.resetFunction === 'function') {
                        self.resetFunction();
                    }

                } else {
                    nextTick(function () {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4301', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4301')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) {
                                var breakHere = 0;
                            });
                    });

                }
            };

            self.fileUploaded = function () {
                var self = this,
                    fileIsValid = true,
                    errorObject,
                    fileSelect = document.getElementById(self.fileInputId);

                var files = fileSelect.files;

                if (files.length > 0) {
                    var file = files[0],
                        extension = file.name.slice(-4).toLowerCase();
                    // Check the file type.
                    if (extension !== config.VALID_FILE_INPUT_EXTENSIONS.JPG && extension !== config.VALID_FILE_INPUT_EXTENSIONS.PNG) {
                        fileIsValid = false;
                        errorObject = {
                            messageCode: '4244'
                        };
                    }

                    if (!globals.checkFileSizeDoesNotExceedMaximumKB(file, config.maxReportLogoFileSizeInKb)) {
                        fileIsValid = false;
                        errorObject = {
                            messageCode: '4245'
                        };
                    }

                    if (fileIsValid === true) {
                        // Add the file to the request.
                        var reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onload = function () {
                            self.setFunction(reader.result);
                            self.obv.value = reader.result;
                            //self.updateImgSrc(reader.result);
                        };

                        reader.onerror = function () {
                            nextTick(function () {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('4246', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4246')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) { });
                            });

                        };
                    } else {
                        $('#' + self.fileInputId).val('');
                        if (errorObject !== null && errorObject.variable !== undefined) {
                            nextTick(function () {
                                globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) { });
                            });

                        } else {
                            nextTick(function () {
                                globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) { });
                            });

                        }

                    }
                }
            };
        }

        /**
        * Used to gather a group of objects together with a button where validation will be performed when the button is clicked
        * @param {object} initInputGroupObj - Object to be used to create the input group
        */
        function SettingsInputGroup(initInputGroupObj) {
            var self = this;
            self.type = config.INPUT_TYPE.INPUT_GROUP;
            self.inputGroupItems = initInputGroupObj.listOfInputGroupItems;
            self.buttonText = initInputGroupObj.buttonText;
            self.customValidateFunction = typeof initInputGroupObj.customValidateFunction === 'function' ? initInputGroupObj.customValidateFunction : null;
            self.passedValidationFunction = initInputGroupObj.passedValidationFunction;

            self.clickButtonFunction = function() {
                if (validateInputs()) {
                    // Validation successful
                    // Continue processing
                    self.passedValidationFunction();
                } else {
                    // Error raised
                    // TODOTC: Raise error?
                    TScMessenger.writeDebugMessage('Validation for input group failed');
                }
            }

            function validateInputs() {
                if (self.customValidateFunction !== null) {
                    return self.customValidateFunction();
                } else {
                    return defaultValidation();
                }

                function defaultValidation() {
                    var returnValue = true;

                    self.inputGroupItems.forEach(function (input) {
                        if(input.validateInput() === false) {
                            returnValue = false;
                        }
                    });

                    return returnValue;
                }
            }


            function createInputGroupItems(listOfInputGroupItems) {
                listOfInputGroupItems.forEach(function (item) {
                    self.inputGroupItems.push(item);
                });
            }
        }

        function SettingsTextInput(initTextObj) {
            SettingsInputBase.call(this, initTextObj);
            var self = this,
                errorRaised = false;

            self.obv = shallowRef(self.value);
            watch(self.obv, function (newValue){
                self.setFunction(newValue);
                var integrationObject = getAvailableIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
                if(integrationObject !== null) {
                    if(integrationObject.getOperationType() === config.OPERATION_TYPES.DELETE) {
                        if(newValue !== '') {
                            integrationObject.setOperationType(config.OPERATION_TYPES.UPDATE);
                        }
                    } else {
                        integrationObject.setOperationType(config.OPERATION_TYPES.UPDATE);
                    }
                }
            });
            // self.watch = watchEffect(() => {
            //     self.obv;
            //     self.setFunction(self.obv.value);
            //     var integrationObject = getAvailableIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
            //     if(integrationObject !== null) {
            //         integrationObject.setOperationType(config.OPERATION_TYPES.UPDATE);
            //     }
            // });
            self.errorMessage = shallowRef(null);
            self.disabled = typeof initTextObj.disabled === 'boolean' ? initTextObj.disabled : false;
            self.customValidateFunction = typeof initTextObj.customValidateFunction === 'function' ? initTextObj.customValidateFunction : null;
            self.isPassword = typeof initTextObj.isPassword === 'boolean' ? initTextObj.isPassword : false;
            self.useTextArea = typeof initTextObj.useTextArea === 'boolean' ? initTextObj.useTextArea : false;
            self.maxLength = typeof initTextObj.maxLength === 'number' ? initTextObj.maxLength : 1000;
            self.isDisabled = shallowRef(typeof initTextObj.isDisabled === 'boolean' ? initTextObj.isDisabled : false);
            self.isDisabledMessageCode = typeof initTextObj.isDisabledMessageCode === 'string' ? initTextObj.isDisabledMessageCode : '4301';
            self.isDisabledButtons = Array.isArray(initTextObj.isDisabledButtons) === true ? initTextObj.isDisabledButtons : [config.getTranslationText('271')];
            self.isDisabledFunction = typeof initTextObj.isDisabledFunction === 'function' ? initTextObj.isDisabledFunction : function () { };
            self.raiseError = raiseError;
            self.clearError = clearError;
            self.cleanUp = function () {
                // self.obv.dispose();
                self.obv.value = null;
                self.obv = null;
            };

            self.clickFunction = function () {
                nextTick(function () {
                    globals.showDialog(new CustomMessageBox(config.getMessageText(self.isDisabledMessageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, self.isDisabledButtons, config.getMessageReference(self.isDisabledMessageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(self.isDisabledFunction);
                });

            };

            self.focusFunction = function () {
                self.selectAllText();
            };

            self.selectAllText = function () {
                globals.selectAllTextById(self.id);
            };

            self.blurFunction = function () {
                if ($('#' + self.id).val() !== '') {
                    clearError();
                    setErrorRaised(false);
                } else {
                    if (getErrorRaised() === true) {
                        raiseError();
                    }
                }
            };

            self.validateInput = function () {
                if (self.customValidateFunction !== null) {
                    return self.customValidateFunction();
                } else {
                    return defaultValidation();
                }

                function defaultValidation() {
                    if ($('#' + self.id).val() !== '') {
                        return true;
                    } else {
                        raiseError();
                        return false;
                    }
                }
            }

            /**
            * Display the error and add outline to the input box
            */
            function raiseError(hideTooltip, text) {
                setErrorRaised(true);
                $('#' + self.id).addClass('has-error');
                if (hideTooltip === undefined || hideTooltip === true) {
                    if(text !== undefined && typeof text === 'string' && text !== '') {
                        self.errorMessage.value = text;
                    } else {
                        self.errorMessage.value = config.getTranslationText('4895');
                    }                    
                    $('#' + self.id).tooltip('show');
                }
            }

            /**
            * Check if error exists on input
            * If error state exists remove the error text and remove outline from the input box
            */
            function clearError() {
                self.errorMessage.value = '';
                $('#' + self.id).removeClass('has-error');
                $('#' + self.id).tooltip('hide');
            }

            function getErrorRaised() {
                return errorRaised;
            }

            function setErrorRaised(newValue) {
                errorRaised = newValue;
            }
        }

        /**
        * These items are common to the tabGroup and the tabs
        * @param {object} initObj
        */
        function SettingsTabBase(initObj) {
            var self = this;
            self.id = typeof initObj.id === 'string' ? initObj.id : '';
            self.label = typeof initObj.label === 'string' ? initObj.label : '';
        }

        /**
        * Tab group that will be used for tabs/accordions
        * @param {object} initTabGroupObject - object created using SettingsBuilder
        */
        function SettingsTabGroup(initTabGroupObject) {
            SettingsTabBase.call(this, initTabGroupObject);
            var self = this;
            self.tabs = Array.isArray(initTabGroupObject.tabs) ? initTabGroupObject.tabs : [];
            self.type = config.ITEM_TYPE.TAB_GROUP;
            self.displayType = initTabGroupObject.displayType === config.TAB_GROUP_DISPLAY_TYPE.TABS ? config.TAB_GROUP_DISPLAY_TYPE.TABS : config.TAB_GROUP_DISPLAY_TYPE.ACCORDION;
            self.deleteFunction = typeof initTabGroupObject.deleteFunction === 'function' ? initTabGroupObject.deleteFunction : null;
            self.displayDeleteButton = typeof initTabGroupObject.displayDeleteButton === 'boolean' ? shallowRef(initTabGroupObject.displayDeleteButton) : shallowRef(true);
            self.deleteButtonTooltip = typeof initTabGroupObject.deleteButtonTooltip === 'string' ? initTabGroupObject.deleteButtonTooltip  : '';

            self.setUpTabGroup = function () {
                // setup tabs/accordion
                // call setUpTab when it is opened using JS

                if (self.displayType === config.TAB_GROUP_DISPLAY_TYPE.ACCORDION) {
                    if ($("#" + self.id + " .accordion").accordion("instance") !== undefined) {
                        try {
                            $("#" + self.id + " .accordion").accordion("destroy");
                        } catch (accordionNotCreatedEx) {
                            TScMessenger.writeDebugMessage('Error raised when trying to destroy accordion in settingsModal')
                        }
                    }

                    $("#" + self.id + " .accordion").accordion({
                        heightStyle: "content",
                        icons: {
                            header: "accordion-icon accordion-icon-closed",
                            activeHeader: "accordion-icon accordion-icon-open"
                        },
                        collapsible: true,
                        activate: function (event, ui) {
                            WebuiPopovers.hideAll();
                            if (ui.newPanel.length > 0) {
                                TScMessenger.writeDebugMessage('');
                                var panel = ui.newPanel[0].__vnode;
                                panel.children.forEach(function(child) {
                                    if(child.component !== null) {
                                        initializeJsElementsForArray(child.component.ctx.items);
                                    }
                                });
                            }
                        }
                    });
                } else {
                    $('#' + self.id + ' a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                        TScMessenger.writeDebugMessage('');
                        //e.target // newly activated tab
                        //e.relatedTarget // previous active tab
                    });

                    if (isTargetTabValid) {
                        $('#' + self.id + ' a[href="#' + targetTab + '"]').tab('show');
                    } else {
                        $('#' + self.id + ' a:first').tab('show');
                    }
                }
            };
        }

        /**
        * Used for individual tab within a tab group
        * @param {object} initTabObject - object containing details for the tab
        */
        function SettingsTab(initTabObject) {
            SettingsTabBase.call(this, initTabObject);
            var self = this;
            self.items = initTabObject.items;
            self.includeLogo = typeof initTabObject.includeLogo === 'boolean' ? initTabObject.includeLogo : false;
            self.logoImageSrc = typeof initTabObject.logoImageSrc === 'string' ? initTabObject.logoImageSrc : null;
            self.alternateText = typeof initTabObject.alternateText === 'string' ? initTabObject.alternateText : config.getTranslationText('4898');
            self.displayDeleteButton = typeof initTabObject.displayDeleteButton === 'boolean' ? shallowRef(initTabObject.displayDeleteButton) :shallowRef(false);
            self.displayText = typeof initTabObject.displayText === 'boolean' ? initTabObject.displayText : true;

            self.setUpTab = function () {
                initializeJsElementsForArray(self.items);
            };
        }

        function initializeJsElementsForArray(arrayToUse) {
            WebuiPopovers.hideAll();
            arrayToUse.forEach(function (item) {
                if (item.type === config.INPUT_TYPE.SELECT) {
                    item.setUpSelectMenu();
                }
                if (item.type === config.INPUT_TYPE.MULTISELECT) {
                    item.setUpMultiSelect();
                }
                if (item.type === config.ITEM_TYPE.TAB_GROUP) {
                    item.setUpTabGroup();
                }
                if (item.type === config.ITEM_TYPE.MENU_TABLE) {
                    item.rows.value.forEach(function (row) {
                        row.cells.forEach(function (cell) {
                            if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                                cell.cellObject.setUpSelectMenu();
                            }
                        });
                    });
                }
            });
        }

        function createInternalStandardsRow(iaObj, idsToRemove) {
            var path = config.OBJECT_TYPES.SETTINGS + '.' + config.OBJECT_TYPES.INTERNAL_STANDARD + '.' + iaObj.getId(),
                iaIncrementType = getInternalStandardIncrementTypeUsingDataPointId(iaObj.getDataPointId()),
                iaValueType = getInternalStandardValueTypeUsingIncrementType(iaIncrementType);

            offerManager.createInternalStandardsObservablesForSettingsModal(path, iaObj, iaIncrementType, iaValueType);

            var tableCells = [],
                // Is Active Checkbox
                isActiveCheckbox = new SettingsCheckbox(new SettingsBuilder()
                    .newCheckbox(iaObj.getIsActive(), 'settings-internal-standards-isActive-' + iaObj.getId(), allowUserToToggleCheckbox, notifyUserToUpgrade)
                    .setter(iaObj.setIsActive)
                    .build()),
                // Internal Standard SelectMenu
                iaSelect = new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(iaObj.getDataPointId(), getInternalStandardsForSelect(idsToRemove, iaObj.getDataPointId()), 'settings-internal-standards-select-' + iaObj.getId(), 'settings-selectmenu-overflow', allowUserToChangeSelectMenu, notifyUserToUpgrade)
                    .setter(iaObj.setDataPointId)
                    // .width(264)
                    .width(300)
                    .updateFunction(addInternalStandard)
                    .build()),
                // Min
                min = new SettingsTelInput(new SettingsBuilder()
                    .newTel(iaObj.getRangeMin(), 'settings-internal-standards-range-min-' + iaObj.getId())
                    .getter(iaObj.getRangeMin)
                    .setter(function (newValue) {
                        iaObj.setRangeMin(setValueUsingIncrementType(newValue));
                            if (iaObj.getRangeMax() !== '') {
                            iaObj.setRangeType(config.RANGE_TYPES.BETWEEN);
                        } else {
                            iaObj.setRangeType(config.RANGE_TYPES.GREATER);
                        }
                    })
                    .incrementType(internalStandardIncrementType)
                    .formatFunction(formatInternalStandard)
                    .addAttrObvGroup(offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.INTERNALSTANDARDMIN))
                    .build()),
                // Max
                max = new SettingsTelInput(new SettingsBuilder()
                    .newTel(iaObj.getRangeMax(), 'settings-internal-standards-range-max-' + iaObj.getId())
                    .getter(iaObj.getRangeMax)
                    .setter(function (newValue) {
                        iaObj.setRangeMax(setValueUsingIncrementType(newValue));
                        if (iaObj.getRangeMin() !== '') {
                            iaObj.setRangeType(config.RANGE_TYPES.BETWEEN);
                        } else {
                            iaObj.setRangeType(config.RANGE_TYPES.LESSER);
                        }
                    })
                    .incrementType(internalStandardIncrementType)
                    .formatFunction(formatInternalStandard)
                    .addAttrObvGroup(offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.INTERNALSTANDARDMAX))
                    .build());

            function allowUserToToggleCheckbox() {
                return globals.user.isUserAllowedFullAccessToSettingsInternalStandards() === true;
            }

            function allowUserToChangeSelectMenu() {
                return globals.user.isUserAllowedFullAccessToSettingsInternalStandards() === true;
            }

            function notifyUserToUpgrade() {
                nextTick(function () {
                    if(globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4959', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4959')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    }else {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4497', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4497')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) {
                                bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_INTERNAL_STANDARDS_CHECKBOX, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            });
                    }
                    
                });

            }

            // Delete
            function formatInternalStandard(value) {
                if (value === '') {
                    return value;
                }

                switch (this.incrementType()) {
                    case config.INCREMENT_TYPE.LENGTH:
                        return formatLengthValue(value);
                    case config.INCREMENT_TYPE.MASS:
                        return formatMassValue(value);
                    case config.INCREMENT_TYPE.PERCENTAGE:
                        return formatPercentageValue(value);
                    case config.INCREMENT_TYPE.AREA:
                        return formatAreaValue(value);
                    case config.INCREMENT_TYPE.DEGREES:
                        return formatDegreesValue(value);
                    default:
                        return value;
                }
            }

            function setValueUsingIncrementType(value) {
                if (value === '') {
                    return value;
                }

                switch (iaIncrementType) {
                    case config.INCREMENT_TYPE.LENGTH:
                        return globals.returnFloat(value) * getActiveMeasurementSystem().lengthConversion;
                    case config.INCREMENT_TYPE.MASS:
                        return globals.returnFloat(value) / getActiveMeasurementSystem().massConversion;
                    case config.INCREMENT_TYPE.AREA:
                        return globals.returnFloat(value) / getActiveMeasurementSystem().areaConversion;
                    default:
                        return globals.returnFloat(value);
                }
            }

            function internalStandardIncrementType() {
                var internalStandard,
                    indexForNumber = 'settings-internal-standards-range-min-'.length,
                    internalStandardId = parseInt(this.id.slice(indexForNumber)),
                    siaObj = activeSettingsObject.getSettingsInternalStandardUsingId(internalStandardId);

                if (siaObj !== undefined) {
                    internalStandard = globals.user.getInternalStandards().find(function (is) {
                        return is.Id === siaObj.getDataPointId();
                    });
                }

                return internalStandard !== undefined && typeof internalStandard.UnitFormat === 'string' ? internalStandard.UnitFormat.toLowerCase() : '';
            }

            function getInternalStandardIncrementTypeUsingDataPointId(dataPointId) {
                var internalStandard = globals.user.getInternalStandards().find(function (is) {
                    return is.Id === dataPointId;
                });

                if (internalStandard !== undefined && typeof internalStandard.UnitFormat === 'string') {
                    switch (internalStandard.UnitFormat) {
                        case config.INCREMENT_TYPE.LENGTH.toUpperCase():
                            return config.INCREMENT_TYPE.LENGTH;
                        case config.INCREMENT_TYPE.MASS.toUpperCase():
                            return config.INCREMENT_TYPE.MASS;
                        case config.INCREMENT_TYPE.PERCENTAGE.toUpperCase():
                            return config.INCREMENT_TYPE.PERCENTAGE;
                        case config.INCREMENT_TYPE.AREA.toUpperCase():
                            return config.INCREMENT_TYPE.AREA;
                        case config.INCREMENT_TYPE.DEGREES.toUpperCase():
                            return config.INCREMENT_TYPE.DEGREES;
                        default:
                            return '';
                    }
                } else {
                    return '';
                }
            }

            function getInternalStandardValueTypeMinUsingIncrementType(incrementType) {
                switch (incrementType) {
                    case config.INCREMENT_TYPE.LENGTH:
                    case config.INCREMENT_TYPE.MASS:
                    case config.INCREMENT_TYPE.AREA:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATIONMIN;
                    case config.INCREMENT_TYPE.PERCENTAGE:
                        return config.VALUE_TYPE.DEFAULTPERCENTAGEVALIDATIONMIN;
                    case config.INCREMENT_TYPE.DEGREES:
                        return config.VALUE_TYPE.DEFAULTDEGREESVALIDATIONMIN;
                    default:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATIONMIN;
                }
            }

            function getInternalStandardValueTypeMaxUsingIncrementType(incrementType) {
                switch (incrementType) {
                    case config.INCREMENT_TYPE.LENGTH:
                    case config.INCREMENT_TYPE.MASS:
                    case config.INCREMENT_TYPE.AREA:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATIONMAX;
                    case config.INCREMENT_TYPE.PERCENTAGE:
                        return config.VALUE_TYPE.DEFAULTPERCENTAGEVALIDATIONMAX;
                    case config.INCREMENT_TYPE.DEGREES:
                        return config.VALUE_TYPE.DEFAULTDEGREESVALIDATIONMAX;
                    default:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATIONMAX;
                }
            }

            /**
            * Returns default validation type to be used with internal standard validation
            * @param {string} incrementType - Should be value from INCREMENT_TYPE enum on config
            * @returns {string} - Returns relevant value from VALUE_TYPE enum on config (DEFAULTVALUEVALIDATION / DEFAULTPERCENTAGEVALIDATION / DEFAULTDEGREESVALIDATION)
            */
            function getInternalStandardValueTypeUsingIncrementType(incrementType) {
                switch (incrementType) {
                    case config.INCREMENT_TYPE.LENGTH:
                    case config.INCREMENT_TYPE.MASS:
                    case config.INCREMENT_TYPE.AREA:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATION;
                    case config.INCREMENT_TYPE.PERCENTAGE:
                        return config.VALUE_TYPE.DEFAULTPERCENTAGEVALIDATION;
                    case config.INCREMENT_TYPE.DEGREES:
                        return config.VALUE_TYPE.DEFAULTDEGREESVALIDATION;
                    default:
                        return config.VALUE_TYPE.DEFAULTVALUEVALIDATIONMAX;
                }
            }

            tableCells.push(createTableInputCell(isActiveCheckbox, 'settings-internal-standards-checkbox'));
            tableCells.push(createTableInputCell(iaSelect, 'settings-internal-standards-select'));
            tableCells.push(createTableInputCell(min, 'settings-internal-standards-tel'));
            tableCells.push(createTableInputCell(max, 'settings-internal-standards-tel'));
            tableCells.push(createTableDeleteRowCell('settings-internal-standards-delete', iaObj.getId()));

            return new SettingsTableRow(new SettingsBuilder()
                .newTableRow(tableCells, iaObj.getId())
                .build());
        }

        function addInternalStandard(newValue) {
            var idsToRemove = [],
                newRow,
                indexForNumber = 'settings-internal-standards-select-'.length,
                internalStandardId = parseInt(this.id.slice(indexForNumber)),
                siaObj = activeSettingsObject.getSettingsInternalStandardUsingId(internalStandardId);
            if (globals.user.isUserAllowedFullAccessToSettingsRegulations() === true) {
                if (getInternalStandardsTable().rows.value.length <= 5) {
                    // Check if empty row
                    if (siaObj.getIsBlankRow() === true) {
                        // Mark row as not blank
                        siaObj.setIsBlankRow(false);
                        siaObj.setIsActive(true);
                        updateExistingRow(siaObj.getId());

                        if (getInternalStandardsTable().rows.value.length < 5) {
                            activeSettingsObject.addInternalStandard(activeSettingsObject.createEmptyInternalStandard());
                            newRow = createInternalStandardsRow(activeSettingsObject.getInternalStandards()[activeSettingsObject.getInternalStandards().length - 1], []);
                            getInternalStandardsTable().addRow(newRow);
                        }
                    } else {
                        // Get the index
                        var indexToReplace = getInternalStandardTableRowIndexUsingId(internalStandardId),
                            rowToReplace = getInternalStandardTableRowUsingId(internalStandardId),
                            internalStandardToReplace = activeSettingsObject.getSettingsInternalStandardUsingId(internalStandardId);
                        // Get the row

                        // new SettingsInternalStandard
                        var newInternalStandard = internalStandardToReplace.clone();
                        newInternalStandard.setRangeMax('');
                        newInternalStandard.setRangeMin('');

                        // remove existing newInternalStandard
                        getInternalStandardsTable().deleteRowUsingId(internalStandardToReplace.getId());

                        // remove existing newInternalStandard
                        activeSettingsObject.addInternalStandard(newInternalStandard, indexToReplace);

                        newRow = createInternalStandardsRow(newInternalStandard, []);
                        getInternalStandardsTable().addRow(newRow, indexToReplace);
                    }

                    // setup all selectmenus
                    getInternalStandardsTable().rows.value.forEach(function (row) {
                        row.cells.forEach(function (cell) {
                            if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                                cell.cellObject.setUpSelectMenu();
                            }
                        });
                    });

                    // Update all selects to remove
                    updateInternalStandardSelectOptions();
                } else {
                    $('#settings-internal-standards-select-' + siaObj.getId()).val('');
                    $('#settings-internal-standards-select-' + siaObj.getId()).selectmenu('refresh');
                    nextTick(function () {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4324', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4324')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) { });
                    });

                }
            } else {
                nextTick(function () {
                    globals.showDialog(new CustomMessageBox(config.getMessageText('4497', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4497')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(function (dialogResult) {
                            bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_INTERNAL_STANDARDS_CHECKBOX, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                        });
                });

            }

            function updateExistingRow(internalStandardId) {
                var indexToReplace = getInternalStandardTableRowIndexUsingId(internalStandardId),
                    rowToReplace = getInternalStandardTableRowUsingId(internalStandardId),
                    internalStandardToReplace = activeSettingsObject.getSettingsInternalStandardUsingId(internalStandardId);
                // Get the row

                // new SettingsInternalStandard
                var newInternalStandard = internalStandardToReplace.clone();
                newInternalStandard.setRangeMax('');
                newInternalStandard.setRangeMin('');

                // remove existing newInternalStandard
                getInternalStandardsTable().deleteRowUsingId(internalStandardToReplace.getId());
                //activeSettingsObject.removeInternalStandard(internalStandardToReplace);

                // remove existing newInternalStandard
                activeSettingsObject.addInternalStandard(newInternalStandard, indexToReplace);

                var newRow = createInternalStandardsRow(newInternalStandard, []);

                getInternalStandardsTable().addRow(newRow, indexToReplace);
            }
        }

        function createTableInputCell(cellObject, cssClasses) {
            var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';
            return new SettingsTableCell(new SettingsBuilder()
                .newTableInputCell(cellObject, cssClassesToUse)
                .build());
        }

        function createTableTextCell(cellObject, cssClasses) {
            var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';
            return new SettingsTableCell(new SettingsBuilder()
                .newTableTextCell(cellObject, cssClassesToUse)
                .build());
        }

        function createTableDeleteRowCell(cssClasses, id) {
            var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';
            return new SettingsTableCell(new SettingsBuilder()
                .newTableDeleteRowCell(cssClassesToUse, id)
                .build());
        }

        function createTableAllowChangeSortRowCell() {
            return new SettingsTableCell(new SettingsBuilder()
                .newTableAllowChangeSortRowCell()
                .build());
        }

        function updateInternalStandardSelectOptions() {
            var idsToRemove = [];

            activeSettingsObject.getInternalStandards().forEach(function (ia) {
                idsToRemove.push(ia.getDataPointId());
            });

            getInternalStandardsTable().rows.value.forEach(function (row) {
                var cellToUpdate = row.cells.find(function (cell) {
                    return cell.cellObject.type === config.INPUT_TYPE.SELECT;
                });
                var selectMenuToUpdate = cellToUpdate.cellObject;
                // selectMenuToUpdate.optionsArray.value = getInternalStandardsForSelect(idsToRemove, selectMenuToUpdate.obv.value);
                // $('#' + selectMenuToUpdate.id).selectmenu('refresh');
                globals.clearShallowRefArrayAndAddItems(selectMenuToUpdate.optionsArray, getInternalStandardsForSelect(idsToRemove, selectMenuToUpdate.obv.value));
                nextTick(function () {
                    $('#' + selectMenuToUpdate.id).selectmenu('refresh');
                });
                
            });
        }

        function createReportViewRow(rvObj, idsToRemove) {
            var tableCells = [],
                selectWidth = 122,
                // selectWidth = 100,
                // Is Active Checkbox
                isActiveCheckbox = new SettingsCheckbox(new SettingsBuilder()
                    .newCheckbox(rvObj.getIsActive(), 'settings-report-view-isActive-' + rvObj.getId(), allowUserToToggleCheckbox, notifyUserToUpgrade)
                    .setter(rvObj.setIsActive)
                    .build()),
                // Report View View Type SelectMenu
                rvViewTypeSelect = new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(rvObj.getViewType(), getReportViewViewTypeOptions(), 'settings-report-view-view-type-select-' + rvObj.getId(), 'settings-selectmenu-overflow', allowUserToChangeSelectMenu, notifyUserToUpgrade)
                    // .setter(rvObj.setViewType)
                    .setter(setViewType)
                    // .width(selectWidth)
                    .width(140)
                    .updateFunction(onViewTypeChange)
                    .addSetUpSelectMenuFunction(setUpViewTypeFunction)
                    .build()),
               // Report View View Detail SelectMenu
               rvViewDetailSelect = new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(rvObj.getViewDetail(), getReportViewViewDetailOptions(), 'settings-report-view-view-detail-select-' + rvObj.getId(), 'settings-selectmenu-overflow', allowUserToChangeSelectMenu, notifyUserToUpgrade)
                    .setter(rvObj.setViewDetail)
                    .width(selectWidth)
                    .updateFunction(onViewDetailChange)
                    .isDisabled(rvObj.getIsBlankRow() === true ? true : false)
                    .addSetUpSelectMenuFunction(setUpViewDetailFunction)
                    .build()),
                // Report View Elevation SelectMenu
                rvElevationSelect = new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(rvObj.getElevation(), getReportViewElevationOptions(), 'settings-report-view-elevation-select-' + rvObj.getId(), 'settings-selectmenu-overflow', allowUserToChangeSelectMenu, notifyUserToUpgrade)
                    .setter(rvObj.setElevation)
                    .width(70)
                    .updateFunction(onElevationChange)
                    .isDisabled(rvObj.getIsBlankRow() === true ? true : false)
                    .build()),
                // Report View Display SelectMenu
                rvDisplaySelect = new SettingsSelectMenu(new SettingsBuilder()
                    .newSelect(rvObj.getDisplay(), getReportViewDisplayOptions(), 'settings-report-view-display-select-' + rvObj.getId(), 'settings-selectmenu-overflow', allowUserToChangeSelectMenu, notifyUserToUpgrade)
                    .setter(rvObj.setDisplay)
                    // .width(selectWidth)
                    .width(105)
                    .updateFunction(onDisplayChange)
                    .isDisabled(rvObj.getIsBlankRow() === true ? true : false)
                    .build());

            
            function setUpViewTypeFunction() {
                if(rvObj.getViewType() === config.VIEW_TYPE.OVERVIEW && rvObj.getViewDetail() === config.VIEW_DETAIL.BRIDGE_VIEW) {
                    // Select bridge view
                    $('#settings-report-view-view-type-select-' + rvObj.getId()).val(config.VIEW_DETAIL.BRIDGE_VIEW);
                    $('#settings-report-view-view-type-select-' + rvObj.getId()).selectmenu('refresh');                    
                }
            }

            function setUpViewDetailFunction() {
                if(rvObj.getViewType() === config.VIEW_TYPE.OVERVIEW && rvObj.getViewDetail() === config.VIEW_DETAIL.BRIDGE_VIEW) {
                    // disable view detail select menu
                    $('#settings-report-view-view-detail-select-' + rvObj.getId()).val('');
                    $('#settings-report-view-view-detail-select-' + rvObj.getId()).selectmenu('refresh'); 
                    $('#settings-report-view-view-detail-select-' + rvObj.getId()).selectmenu('disable');
                }
            }

            function setViewType(newValue) {
                if(newValue === config.VIEW_DETAIL.BRIDGE_VIEW) {
                    $('#settings-report-view-view-detail-select-' + rvObj.getId()).val('');
                    $('#settings-report-view-view-detail-select-' + rvObj.getId()).selectmenu('refresh');
                    rvObj.setViewType(config.VIEW_TYPE.OVERVIEW);
                    rvObj.setViewDetail(config.VIEW_DETAIL.BRIDGE_VIEW);
                } else {
                    rvObj.setViewType(newValue);
                }
            }
            /**
             * Returns true if the user is allowed to toggle the checkbox
             * User is not allowed to toggle the checkbox if the row is blank
             * @return {boolean} - Returns true if the user is allowed to toggle the checkbox
             */
            function allowUserToToggleCheckbox() {
                if(rvObj.getIsBlankRow() === true) {
                    return false;
                }
                return globals.user.isUserAllowedFullAccessToSettingsInternalStandards() === true;
            }

            /**
             * Returns true if the user is allowed to change the select menu
             * @returns {boolean} - Returns true if the user is allowed to change the select menu
             */
            function allowUserToChangeSelectMenu() {
                return globals.user.isUserAllowedFullAccessToSettingsInternalStandards() === true;
            }

            /**
             * Notifies the user that they need to upgrade to use this feature
             * Does not notify the user if the row is blank and they have access to the feature
             */
            function notifyUserToUpgrade() {
                if(globals.user.isUserAllowedFullAccessToSettingsInternalStandards() === false) {
                    nextTick(function () {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4497', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4497')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(function (dialogResult) {
                            bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SETTINGS_INTERNAL_STANDARDS_CHECKBOX, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                        });
                    });
                }
            }

            /**
             * Event handler for when the view type select menu is changed
             */
            function onViewTypeChange() {
                // Update the available options in view detail select menu
                // Enable the view detail select menu
                var selectedViewTypeValue = $('#settings-report-view-view-type-select-' + rvObj.getId()).val();
                if(rvObj.getViewType() === config.VIEW_TYPE.OVERVIEW && rvObj.getViewDetail() === config.VIEW_DETAIL.BRIDGE_VIEW && selectedViewTypeValue === config.VIEW_DETAIL.BRIDGE_VIEW) {
                    disableViewDetailSelectMenu();
                    enableElevationSelectMenu();
                } else {
                    enableViewDetailSelectMenu();
                }
            }

            /**
             * Event handler for when the view detail select menu is changed
             */
            function onViewDetailChange() {
                if(rvObj.getViewDetail() === '') {
                    disableElevationSelectMenu();
                } else {
                    enableElevationSelectMenu();
                }
            }

            /**
             * Event handler for when the elevation select menu is changed
             */
            function onElevationChange() {
                if(rvObj.getElevation() === '') {
                    disableDisplaySelectMenu();
                } else {
                    enableDisplaySelectMenu();
                }
            }

            /**
             * Event handler for when the display select menu is changed
             */
            function onDisplayChange() {
                if(rvObj.getDisplay() !== '' && isThereAlreadyABlankRow(rvObj.getOrder()) === false) {
                    // Get the table
                    var reportViewTable = getReportViewTable();
                    // Mark this row as not blank
                    rvObj.setIsBlankRow(false);
                    var rowToUpdate = reportViewTable.getRowUsingId(rvObj.getId());
                    var isActiveCheckboxToUpdate = rowToUpdate.cells.find(function (cell) {
                        return cell.cellObject.type === config.INPUT_TYPE.CHECKBOX;
                    });
                    if(isActiveCheckboxToUpdate.cellObject.obv.value === false) {
                        isActiveCheckboxToUpdate.cellObject.toggleCheckbox();
                    }
                    // Mark the select menu cells as not disabled
                    rowToUpdate.cells.forEach(function (cell) {
                        if(cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                            cell.cellObject.setIsDisabled(false);
                        }
                    });

                    // create a new row
                    var newRow = settings.createEmptyReportView(activeSettingsObject, activeSettingsObject.getReportViews());
                    // Add to the active settings object
                    activeSettingsObject.addReportView(newRow);
                    // Update the order values for the report views
                    activeSettingsObject.updateReportViewOrderValues();                
                    // Add the row to the table0
                    reportViewTable.addRow(createReportViewRow(activeSettingsObject.getReportViews()[activeSettingsObject.getReportViews().length - 1]));
                    nextTick(function(){
                        // setup last row
                        var lastRow = reportViewTable.rows.value[reportViewTable.rows.value.length - 1];
                        lastRow.cells.forEach(function (cell) {
                            if (cell.cellObject.type === config.INPUT_TYPE.SELECT) {
                                cell.cellObject.setUpSelectMenu();
                            }
                        });
                        // scroll to last row
                        var tableBody = $('.template-user-settings-menu-table.no-label-on-table tbody');
                        tableBody.scrollTop(tableBody[0].scrollHeight);
                    });
                } else {
                    // There is already a blank row
                    // Do nothing
                }
            }

            function isThereAlreadyABlankRow(orderNumber) {
                var blankRow = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getIsBlankRow() === true && rv.getOrder() !== orderNumber;
                });
                return blankRow !== undefined ? true : false;
            }

            function enableViewDetailSelectMenu() {
                try {
                    // Add options to view detail select menu
                    rvViewDetailSelect.updateSelectMenuOptions(getReportViewViewDetailOptions());
                    nextTick(function () {
                        rvViewDetailSelect.setUpSelectMenu();
                        // Enable the view detail select menu
                        $('#settings-report-view-view-detail-select-' + rvObj.getId()).selectmenu('enable');
                        disableElevationSelectMenu();
                    });
                } catch (e) {
                    TScMessenger.writeDebugMessage('Error raised when trying to enable view detail select menu in settingsModal')
                }
            }

            function disableViewDetailSelectMenu() {
                $('#settings-report-view-view-detail-select-' + rvObj.getId()).selectmenu('disable');
                disableElevationSelectMenu();
            }

            function disableElevationSelectMenu() {
                $('#settings-report-view-elevation-select-' + rvObj.getId()).selectmenu('disable');
                disableDisplaySelectMenu();
            }

            function enableElevationSelectMenu() {
                if(rvElevationSelect.optionsArray.value.length !== getReportViewElevationOptions()) {
                    rvElevationSelect.updateSelectMenuOptions(getReportViewElevationOptions());
                    nextTick(function () {
                        rvElevationSelect.setUpSelectMenu();
                        // Enable the view detail select menu
                        $('#settings-report-view-elevation-select-' + rvObj.getId()).selectmenu('enable');
                    });
                } else {
                    $('#settings-report-view-elevation-select-' + rvObj.getId()).selectmenu('enable');
                }
            }

            function disableDisplaySelectMenu() {
                $('#settings-report-view-display-select-' + rvObj.getId()).selectmenu('disable');
            }

            function enableDisplaySelectMenu() {
                rvDisplaySelect.updateSelectMenuOptions(getReportViewDisplayOptions());
                nextTick(function () {
                    rvDisplaySelect.setUpSelectMenu();
                    // Enable the view detail select menu
                    $('#settings-report-view-display-select-' + rvObj.getId()).selectmenu('enable');
                });
                // $('#settings-report-view-display-select-' + rvObj.getId()).selectmenu('enable');
            }

            /**
             * Returns array of options for the report view view type select menu
             * @returns {object[]} - Array of options for the view type select menu
             */
            function getReportViewViewTypeOptions() {
                // VIEW_TYPE = {
                //     OVERVIEW: 'overview',
                //     COG: 'cog',
                //     TURNING_CIRCLE: 'turningCircle',
                //     ELEVATION: 'elevation',
                // },
                return [
                    {
                        id: config.VIEW_TYPE.OVERVIEW,
                        description: config.getTranslationText('4726')
                    }, {
                        id: config.VIEW_DETAIL.BRIDGE_VIEW,
                        description: config.getTranslationText('4733')
                    }, {
                        id: config.VIEW_TYPE.COG,
                        description: config.getTranslationText('4727')
                    }, {
                        id: config.VIEW_TYPE.TURNING_CIRCLE,
                        description: config.getTranslationText('4728')
                    }
                ];
            }

            /**
             * Returns array of options for the report view view detail select menu
             * @returns {object[]} - Array of options for the view detail select menu
             */
            function getReportViewViewDetailOptions() {
                // VIEW_DETAIL = {
                //     SUMMARY: 'summary',
                //     EXTRAS: 'extras',
                //     TRAILER1: 'trailer1',
                //     TRAILER2: 'trailer2',
                //     TURNING_CIRCLE_REGULATION: 'regulation',
                //     TURNING_CIRCLE_SMALLEST: 'smallest',
                //     BRIDGE_VIEW: 'bridgeView',
                //     CHASSIS: 'chassis',
                //     VEHICLE: 'vehicle'
                // },
                var returnArray = [
                    {
                        id: '',
                        description: ''
                    },
                ];
                if(rvObj.getViewType() === config.VIEW_TYPE.OVERVIEW) {
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.SUMMARY)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.SUMMARY,
                            description: config.getTranslationText('4730')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.VEHICLE)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.VEHICLE,
                            description: config.getTranslationText('4768')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.CHASSIS)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.CHASSIS,
                            description: config.getTranslationText('4731')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.EXTRAS)) {
                        var extrasText = '4732';
                        if (offerManager.getActiveOffer() !== undefined && offerManager.getActiveOffer() !== '' && offerManager.getActiveOffer() !== null && offerManager.getActiveOffer().getRig().getVehicle().getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                            extrasText = '4789';
                        }
                        returnArray.push({
                            id: config.VIEW_DETAIL.EXTRAS,
                            description: config.getTranslationText(extrasText)
                        });
                    }                    
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.TRAILER1)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TRAILER1,
                            description: config.getTranslationText('4734')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.OVERVIEW, config.VIEW_DETAIL.TRAILER2)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TRAILER2,
                            description: config.getTranslationText('4735')
                        });
                    }
                }
                if(rvObj.getViewType() === config.VIEW_TYPE.COG) {
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.SUMMARY)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.SUMMARY,
                            description: config.getTranslationText('4736')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.VEHICLE)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.VEHICLE,
                            description: config.getTranslationText('4769')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.CHASSIS)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.CHASSIS,
                            description: config.getTranslationText('4737')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.EXTRAS)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.EXTRAS,
                            description: config.getTranslationText('4738')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.TRAILER1)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TRAILER1,
                            description: config.getTranslationText('4739')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.COG, config.VIEW_DETAIL.TRAILER2)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TRAILER2,
                            description: config.getTranslationText('4740')
                        });
                    }
                }
                if(rvObj.getViewType() === config.VIEW_TYPE.TURNING_CIRCLE) {
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.TURNING_CIRCLE, config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION,
                            description: config.getTranslationText('4741')
                        });
                    }
                    if(isAnElevationViewAvailable(config.VIEW_TYPE.TURNING_CIRCLE, config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST)) {
                        returnArray.push({
                            id: config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST,
                            description: config.getTranslationText('4742')
                        });
                    }
                }
                return returnArray;
            }

            /**
             * Returns array of options for the report view elevation select menu
             * @returns {object[]} - Array of options for the elevation select menu
             */
            function getReportViewElevationOptions() {
                // ELEVATION = {
                //     SIDE: 'side',
                //     TOP: 'top'
                // },
                var returnArray = [
                    {
                        id: '',
                        description: ''
                    },
                ];

                if(rvObj.getViewType() === config.VIEW_TYPE.TURNING_CIRCLE) {
                    if(isElevationTopOptionAvailable(rvObj.getViewType(), rvObj.getViewDetail()) || rvObj.getElevation() === config.ELEVATION.TOP) {
                        returnArray.push({
                            id: config.ELEVATION.TOP,
                            description: config.getTranslationText('4743')
                        });
                    }
                } else {
                    if(isElevationSideOptionAvailable(rvObj.getViewType(), rvObj.getViewDetail()) || rvObj.getElevation() === config.ELEVATION.SIDE) {
                        returnArray.push({
                            id: config.ELEVATION.SIDE,
                            description: config.getTranslationText('4744')
                        });
                    }
                    if(isElevationTopOptionAvailable(rvObj.getViewType(), rvObj.getViewDetail()) || rvObj.getElevation() === config.ELEVATION.TOP) {
                        returnArray.push({
                            id: config.ELEVATION.TOP,
                            description: config.getTranslationText('4745')
                        });
                    }
                }
                return returnArray;
            }

            function isAnElevationViewAvailable(viewType, viewDetail) {
                return isElevationSideOptionAvailable(viewType, viewDetail) || isElevationTopOptionAvailable(viewType, viewDetail);
            }

            function isElevationSideOptionAvailable(viewType, viewDetail) {
                var isDetailedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === config.ELEVATION.SIDE && rv.getDisplay() === config.DISPLAY_TYPE.DETAILED;
                });

                var isSimplifiedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === config.ELEVATION.SIDE && rv.getDisplay() === config.DISPLAY_TYPE.SIMPLIFIED;
                });

                return isDetailedAlreadySelected === undefined || isSimplifiedAlreadySelected === undefined? true : false;
            }

            function isElevationTopOptionAvailable(viewType, viewDetail) {
                var isDetailedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === config.ELEVATION.TOP && rv.getDisplay() === config.DISPLAY_TYPE.DETAILED;
                });

                var isSimplifiedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === config.ELEVATION.TOP && rv.getDisplay() === config.DISPLAY_TYPE.SIMPLIFIED;
                });

                return isDetailedAlreadySelected === undefined || isSimplifiedAlreadySelected === undefined? true : false;
            }

            /**
             * Returns array of options for the report view display type select menu
             * @returns {object[]} - Array of options for the display type select menu
             */
            function getReportViewDisplayOptions() {
                // DISPLAY_TYPE = {
                //     DETAILED: 'detailed',
                //     SIMPLIFIED: 'simplified'
                // },

                // Is there a row with the same view type, view detail and elevation
                var returnArray = [
                    {
                        id: '',
                        description: ''
                    },
                ];

                if(isDisplayDetailedOptionAvailable(rvObj.getViewType(), rvObj.getViewDetail(), rvObj.getElevation(), rvObj.getOrder())) {
                    returnArray.push({
                        id: config.DISPLAY_TYPE.DETAILED,
                        description: config.getTranslationText('4746')
                    });
                }

                if(isDisplaySimplifiedOptionAvailable(rvObj.getViewType(), rvObj.getViewDetail(), rvObj.getElevation(), rvObj.getOrder())) {
                    returnArray.push({
                        id: config.DISPLAY_TYPE.SIMPLIFIED,
                        description: config.getTranslationText('4747')
                    });
                }

                return returnArray;
            }

            function isDisplayDetailedOptionAvailable(viewType, viewDetail, elevation, orderNumber) {
                var isDetailedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === elevation && rv.getDisplay() === config.DISPLAY_TYPE.DETAILED && rv.getOrder() !== orderNumber;
                });

                return isDetailedAlreadySelected === undefined ? true : false;
            }

            function isDisplaySimplifiedOptionAvailable(viewType, viewDetail, elevation, orderNumber) {
                var isDetailedAlreadySelected = activeSettingsObject.getReportViews().find(function (rv) {
                    return rv.getViewType() === viewType && rv.getViewDetail() === viewDetail && rv.getElevation() === elevation && rv.getDisplay() === config.DISPLAY_TYPE.SIMPLIFIED && rv.getOrder() !== orderNumber;
                });

                return isDetailedAlreadySelected === undefined ? true : false;
            }

            tableCells.push(createTableInputCell(isActiveCheckbox, 'settings-report-view-checkbox'));
            tableCells.push(createTableInputCell(rvViewTypeSelect, 'settings-report-view-select settings-report-view-view-type-select'));
            tableCells.push(createTableInputCell(rvViewDetailSelect, 'settings-report-view-select settings-report-view-view-detail-select'));
            tableCells.push(createTableInputCell(rvElevationSelect, 'settings-report-view-select settings-report-view-elevation-select'));
            tableCells.push(createTableInputCell(rvDisplaySelect, 'settings-report-view-select settings-report-view-display-select'));
            tableCells.push(createTableDeleteRowCell('settings-report-view-delete', rvObj.getId()));
            tableCells.push(createTableAllowChangeSortRowCell('settings-report-view-delete', rvObj.getId()));

            return new SettingsTableRow(new SettingsBuilder()
                .newTableRow(tableCells, rvObj.getId())
                .build());
        }
        // #endregion Settings Menu Objects

        /**
        * Refresh the specify fuel consumption as options by passing in a measurement system ID
        * @param {number} measurementSystemId - New measurement system ID to use
        */
        function refreshSpecifyFuelConsumptionAsOptions(measurementSystemId) {
            var fuelConsumptionSelectMenu = getFuelConsumptionSelectMenu();
            if (fuelConsumptionSelectMenu !== null) {
                fuelConsumptionSelectMenu.updateSelectMenuOptions(offerManager.getSpecifyFuelConsumptionAsOptions(measurementSystemId));
            }
        }

        /**
        * Refresh the tooltips that have text based on measurement system
        * @param {number} measurementSystemId - New measurement system ID to use
        */
        function refreshTooltipsOnMeasurementSystemChange(measurementSystemId) {
            var fuelConsumptionSelectMenu = getFuelConsumptionSelectMenu();
            if (fuelConsumptionSelectMenu !== null) {
                var tooltipTextCode;
                if (measurementSystemId === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemId === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
                    tooltipTextCode = '4267';
                } else {
                    tooltipTextCode = '4224';
                }
                fuelConsumptionSelectMenu.updateTooltipMessage(config.getTranslationText(tooltipTextCode));
            }
        }

        function removeIntegration(integrationTab, element) {
            // element.stopPropagation();
            var integrationItems = integrationTab.items;
            integrationItems.forEach(function (item) {
                item.obv.value = '';
                item.isDisabled.value = false;
            });
            var integrationObject = getAvailableIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
            integrationObject.setOperationType(config.OPERATION_TYPES.DELETE);
            integrationTab.displayDeleteButton.value = false;
            return false;
        }




        // #region Prototype Functions
        function ok() {
            var self = this,
                returnObj = {},
                listOfIntegrationsToBeSaved = [],
                elementToRaiseError,
                elementToRaiseError2,
                legislationId,
                areInternalStandardsValid = areInternalStandardRowsValid(),
                areReportViewsValid = areReportViewRowsValid();

            changedSettingNames = '';

            if(areReportViewsValid === true && areInternalStandardsValid === true) {
                // Check that at least 1 legislation has been selected
                if (activeSettingsObject.getDashboardLegislationIds().length > 0) {
                    // Check if legislation multiselect is open
                    if ($('#settings-legislation-multiselect-id-multiselect-div .btn-group').hasClass('open') === false) {
                        // Check if there are changes to NTEA User
                        if (globals.user.displayNteaOptionsToUser()) {
                            if (activeSettingsObject.changesAppliedToNTEAIntegration(activeSettingsObject, originalSettingsObject)) {
                                var nteaIntegrationObject = activeSettingsObject.getIntegrationUsingType(config.INTEGRATION_TYPES.NTEA);
                                // Check if integration is to be updated
                                if (nteaIntegrationObject.getOperationType() === config.OPERATION_TYPES.UPDATE) {
                                    // Check that all values have been filled in
                                    // if (nteaIntegrationObject.getAttribute2() !== '' && nteaIntegrationObject.getPassword() !== '') {
                                    if(nteaIntegrationObject.checkAllValuesFilledIn(activeSettingsObject.getNteaUserValidationType())) {
                                        // if changes then check with NTEA
                                        globals.showSpinner();
                                        $.when(dataManager.validateNTEAUser(nteaIntegrationObject.getAttribute1(), nteaIntegrationObject.getAttribute2(), nteaIntegrationObject.getPassword()))
                                            .then(function (data) {
                                                //var listOfIntegrationsToBeSaved = [];
                                                if (data.ReturnCode === 1) {
                                                    var integrationObject = {
                                                        Type: nteaIntegrationObject.getType(),
                                                        Attribute1: nteaIntegrationObject.getAttribute1(),
                                                        Attribute2: nteaIntegrationObject.getAttribute2(),
                                                        Password: nteaIntegrationObject.getPassword()
                                                    };
                                                    listOfIntegrationsToBeSaved.push(createIntegrationObjectToBeSentToCloudServices(integrationObject, config.OPERATION_TYPES.UPDATE));

                                                    globals.hideSpinner();
                                                    applyChangesAndSendToCloudServices(true);
                                                } else {
                                                    globals.hideSpinner();
                                                    var nteaIntegrationItems = self.integrationItems[0].tabs[0].items;
                                                    if (data.ReturnCode === -1 || data.ReturnCode === -2) {
                                                        nextTick(function () {
                                                            var messageTextCode = activeSettingsObject.getNteaUserValidationType() === config.NTEA_USER_VALIDATION_TYPES.SALESFORCE ? '4865' : '1597';
                                                            globals.showDialog(new CustomMessageBox(config.getMessageText(messageTextCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageTextCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                                        });

                                                        if(activeSettingsObject.getNteaUserValidationType() === config.NTEA_USER_VALIDATION_TYPES.SALESFORCE) {
                                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                return element.id === nteaIntegrationObject.getAttribute1InputId();
                                                            });
                                                            elementToRaiseError.raiseError(true, config.getTranslationText('4866'));
                                                            $('#settings-NTEA-attribute1').on('blur', function () {
                                                                elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                    return element.id === nteaIntegrationObject.getAttribute1InputId();
                                                                });
                                                                elementToRaiseError.clearError();
                                                            });
                                                            elementToRaiseError2 = nteaIntegrationItems.find(function (element) {
                                                                return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                            });
                                                            elementToRaiseError2.raiseError(true, config.getTranslationText('4867'));
                                                            $('#settings-NTEA-attribute2').on('blur', function () {
                                                                elementToRaiseError2 = nteaIntegrationItems.find(function (element) {
                                                                    return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                                });
                                                                elementToRaiseError2.clearError();
                                                            });
                                                        } else {
                                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                            });
                                                            elementToRaiseError.raiseError();
                                                            $('#settings-NTEA-attribute2').on('blur', function () {
                                                                elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                    return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                                });
                                                                elementToRaiseError.clearError();
                                                            });
                                                        }
                                                    }
                                                    if (data.ReturnCode === -3) {
                                                        nextTick(function () {
                                                            globals.showDialog(new CustomMessageBox(config.getMessageText('1598', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1598')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                                        });
                                                        
                                                        if(activeSettingsObject.getNteaUserValidationType() === config.NTEA_USER_VALIDATION_TYPES.SALESFORCE) {
                                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                            });
                                                            elementToRaiseError.raiseError(true, config.getTranslationText('4868'));
                                                            $('#settings-NTEA-attribute2').on('blur', function () {
                                                                elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                    return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                                });
                                                                elementToRaiseError.clearError();
                                                            });
                                                        } else {
                                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                            });
                                                            elementToRaiseError.raiseError();
                                                            $('#settings-NTEA-attribute2').on('blur', function () {
                                                                elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                                    return element.id === nteaIntegrationObject.getAttribute2InputId();
                                                                });
                                                                elementToRaiseError.clearError();
                                                            });
                                                        }
                                                    }
                                                    if (data.ReturnCode === -99) {
                                                        nextTick(function () {
                                                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                                        });

                                                    }
                                                }
                                            });
                                    } else {
                                        var nteaIntegrationItems = self.integrationItems[0].tabs[0].items;
                                        if ($('#' + nteaIntegrationObject.getAttribute1InputId()).val() === '') {
                                            // raise error
                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                return element.id === nteaIntegrationObject.getAttribute1InputId();
                                            });
                                            elementToRaiseError.raiseError();
                                        }
                                        if ($('#' + nteaIntegrationObject.getAttribute2InputId()).val() === '') {
                                            // raise error
                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                return element.id === nteaIntegrationObject.getAttribute2InputId();
                                            });
                                            elementToRaiseError.raiseError();
                                        }
                                        if ($('#' + nteaIntegrationObject.getPasswordInputId()).val() === '') {
                                            // raise error
                                            elementToRaiseError = nteaIntegrationItems.find(function (element) {
                                                return element.id === nteaIntegrationObject.getPasswordInputId();
                                            });
                                            elementToRaiseError.raiseError();
                                        }
                                    }
                                } else if (nteaIntegrationObject.getOperationType() === config.OPERATION_TYPES.DELETE) {
                                    // Check if integration is to be deleted
                                    var integrationObject = {
                                        Type: nteaIntegrationObject.getType()
                                    };
                                    listOfIntegrationsToBeSaved.push(createIntegrationObjectToBeSentToCloudServices(integrationObject, config.OPERATION_TYPES.DELETE));
                                    applyChangesAndSendToCloudServices(true);
                                }
                            } else {
                                applyChangesAndSendToCloudServices(false);
                            }
                        } else {
                            applyChangesAndSendToCloudServices(false);
                        }
                    } else {
                        // Close legislation multiselect
                        $('#settings-legislation-multiselect-id-multiselect-div .btn-group button').click();
                    }
                } else {
                    nextTick(function () {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4327', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4327')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    });
                }
            } else {
                var messageText;
                if(areReportViewsValid === false && areInternalStandardsValid === false) {
                    messageText = '4791';
                } else {
                    if(areReportViewsValid === false) {
                        messageText = '4748';
                    } else {
                        messageText = '4790';
                    }
                }
                nextTick(function () {
                    globals.showDialog(new CustomMessageBox(config.getMessageText(messageText, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageText)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                });
            }            

            /**
             * All non-blank rows should have a value for each select
             */
            function areReportViewRowsValid() {
                if(activeSettingsObject.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED) {
                    return true;
                }
                if(activeSettingsObject.areSelectedReportViewsValid() === false) {
                    return false;
                }
                return activeSettingsObject.getReportViews().some(function (rv) {
                    if(rv.getIsBlankRow() === true) {
                        return false;
                    } else {
                        return rv.getViewType() === '' || rv.getViewDetail() === '' || rv.getElevation() === '' || rv.getDisplay() === '';
                    }
                }) === false;
            }

            /**
             * All non-blank rows should have a minimum or a maximum value
             */
            function areInternalStandardRowsValid() {
                if(activeSettingsObject.getInternalStandards().length === 0) {
                    return true;
                }
                return activeSettingsObject.getInternalStandards().some(function (rv) {
                    if(rv.getIsBlankRow() === true) {
                        return false;
                    } else {
                        return rv.getRangeMin() === '' && rv.getRangeMax() === '';
                    }                    
                }) === false;
            }

            

            function applyChangesAndSendToCloudServices(ignoreChangesAppliedFlag) {
                settings.applyAnyChanges(activeSettingsObject, originalSettingsObject)
                    .then(function (changesApplied) {
                        if (changesApplied || ignoreChangesAppliedFlag) {
                            legislationId = activeSettingsObject.getLegislationId();
                            // var reportLogoToSave = activeSettingsObject.getReportLogo() !== globals.user.getReportGraphics().landscapeReportHeaderLeftURL ? activeSettingsObject.getReportLogo() : '';
                            // $.when(dataManager.saveUserSettings(legislationId, activeSettingsObject.getMeasurementSystem().id, activeSettingsObject.getLengthIncrement(), activeSettingsObject.getMassIncrement(), activeSettingsObject.getPercentageIncrement(), activeSettingsObject.getColourDrawings(), activeSettingsObject.getShowComplianceScorecard(), activeSettingsObject.getSpecifyWheelbaseAs(), listOfIntegrationsToBeSaved, activeSettingsObject.getSpecifyCabDimensionsAs(), activeSettingsObject.getSpecifyChassisLengthAs(), activeSettingsObject.getSpecifyFuelConsumptionAs(), activeSettingsObject.getSpecifyLicencingRegionAs(), activeSettingsObject.getSpecifyAxleRatingAs(), activeSettingsObject.getDefaultReportPdfPageSize(), reportLogoToSave, globals.user.getDashboardConfiguration().getObjectToBeSaved(), activeSettingsObject.getCompanyAdditionalNote(), activeSettingsObject.getSpecifyBodyPositionAs(), activeSettingsObject.getSpecifyEquipmentPositionAs(), activeSettingsObject.getShowTips(), activeSettingsObject.getReportViewLayout(), activeSettingsObject.getReportViewObjectsToBeSaved()))
                            //     .then(
                            //     function (success) {
                            //         if (changedSettingNames !== '') {
                            //             dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_SETTINGS, {
                            //                 SettingName: changedSettingNames
                            //             }));
                            //         }
                            //         close(true);
                            //     },
                            //     function (error) {
                            //         nextTick(function () {
                            //             globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            //         .addCallback(function () {
                            //             close();
                            //         });
                            //         });

                            //     }
                            //     );
                            settings.saveSettings(activeSettingsObject, listOfIntegrationsToBeSaved)
                                .then(function(success) { 
                                    close(true);
                                })
                                .catch(function (error) {
                                    // Not sure that this code can ever be hit as user settings uses the sync queue so dialog always closes
                                    // fine and an error toast is shown is the sync fails 
                                    nextTick(function () {
                                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                            .addCallback(function () {
                                                close();
                                            });
                                    });
                                });
                        } else {
                            close();
                        }
                    });
            }

            function createIntegrationObjectToBeSentToCloudServices(attributes, operationType) {
                var returnObject = {},
                    integrationDetails = attributes;

                returnObject.IntegrationDetails = integrationDetails;
                returnObject.Operation = typeof operationType === 'string' ? operationType : config.OPERATION_TYPES.DEFAULT;

                return returnObject;
            }
        }

        function getReportViewTable() {
            return reportingItems.value.find(function (item) {
                return item.type === config.ITEM_TYPE.MENU_TABLE;
            });
        }

        
        

        function close(param) {
            // Wait for the settings modal to close before removing the callback
            setTimeout(function () {
                if(typeof offerManager.getOpenSettingsModalOnClickFunction() === 'function') {
                    offerManager.registerRefreshDataCallback(null);
                    // offerManager.setOpenSettingsModalOnClickFunction(null);
                }
            }, 500);
            
            window.removeEventListener('resize', onResizeHandler);
            WebuiPopovers.hideAll();
            // this.popup.close();

            // closeCallback(param);
            popup.value.close();
            resolvePromise(param);
        }

        function closeButtonClick() {
            close({ allowCallback: false });
        }

        return {
            popup,
            preferenceItems,
            measurementItems,
            legislationItems,
            integrationItems,
            reportingItems,
            classesForInputTypeTables,
            handleFrenchText: globals.handleFrenchText(globals.getActiveCultureCodeForTranslations()),
            // Functions
            show,
            ok,
            close,
            closeButtonClick,
            // Text
            getTranslationText: config.getTranslationText
        }
    },
}
</script>

<style lang="scss" scoped>

</style>