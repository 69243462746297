import config from '../../../services/config';
import utils from '../../../services/utils';
import ComplianceDashboardItem from './complianceDashboardItem';



var ComplianceDashboardRow = function () {

    ComplianceDashboardItem.call(this, config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ROW);

    this._rowItems = [];
    this._textPositionMethod = config.COMPLIANCE_ROW_TEXT_POSITION_METHOD.SUBSEQUENT_FROM_RIGHT;

    return this;
}

ComplianceDashboardRow.prototype.addItem = function(item) {
    this._rowItems.push(item);
}

ComplianceDashboardRow.prototype.getItems = function (item) {
    return this._rowItems;
}

ComplianceDashboardRow.prototype.getItemById = function (id) {
    return this._rowItems.filter(function (item) {
        return item.getId() === id;
    })[0] || null;
}

ComplianceDashboardRow.prototype.getTextPositionMethod = function () {
    return this._textPositionMethod;
}

ComplianceDashboardRow.prototype.setTextPositionMethod = function (newValue) {
    this._textPositionMethod = newValue;
}

utils.extend(ComplianceDashboardItem, ComplianceDashboardRow);


export default ComplianceDashboardRow;


