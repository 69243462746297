<template>
    <div class="image-card-container">
        <!-- Image Card Container
        <div>
            wizard.itemsToDisplayArray = {{ wizard.itemsToDisplayArray.length}}
        </div> -->
        <div class="image-card" v-for="item in wizard.itemsToDisplayArray.value" :key="item"
            @click="wizard.clickOnItem(item)">
            <template v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.AXLE_LAYOUT">
                <div class="axle-layout-container">
                    <div class="axle-layout sprite-icon-div"
                        :class="function () { return 'axle-layout-' + item.AxleLayout; }()"></div>
                </div>
                <span class="axle-layout-img-span">{{
                        item.AxleLayout
                }}</span>
            </template>

            <template v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.VEHICLE_MAKE">
                <img :src="item.MakeLogoImage" />
                <span>{{
                        item.VehicleMake
                }}</span>
            </template>

            <template v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.BODY_TYPE">
                <div class="body-type-image-container">
                    <div class="body-type-image sprite-icon-div"
                        :class="item.getImageClass(wizard.isTrailerSelectionWizard)"></div>
                </div>
                <span>{{
                        item.getBodyTypeText()
                }}</span>
            </template>

            <template
                v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.ACCESSORY_TYPE">
                <div class="equipment-type-image-container">
                    <div class="equipment-type-image sprite-icon-div"
                        :class="item.getImageClass(wizard.isTrailerSelectionWizard)"></div>
                </div>
                <span>{{ item.getAccessoryTypeTranslation() }}</span>
            </template>

            <template v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.TRAILER_TYPE">
                <div class="trailer-type-image-container">
                    <div class="trailer-type-image sprite-icon-div" :class="item.getImageClass()"></div>
                </div>
                <span>{{ item.getTrailerTypeText(wizard.path) }}</span>
            </template>


            <div class="selection-resource-container" :id="'selection-resource-container-id-' + item.Id"
                v-if="wizard.activeWizardStep.value.renderImageCardAs === IMAGE_CARD_RENDERED_AS_OPTIONS.SELECTION_RESOURCE">
                <div class="selection-resource-title-container">
                    <div class="selection-resource-icon">
                        <i class="far fa-newspaper"></i>
                    </div>
                    <div class="selection-resource-title">
                        <span>{{ item.Title }}</span>
                    </div>
                </div>
                <div class="selection-resource-description">
                    <span>{{ item.Description }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        let wizard = toRef(props, "wizardFromParent");

        // watch(() => props.wizardFromParent,
        //     (newValue, oldValue) => {
        //         wizard.value = newValue;
        //     });

        return {
            wizard,
            IMAGE_CARD_RENDERED_AS_OPTIONS: config.IMAGE_CARD_RENDERED_AS_OPTIONS
        }
    }
}
</script>

<style lang="scss" scoped>
</style>