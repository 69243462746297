<template>
    <nav class="layoutHeader layoutNavBar display-overflow">
      <div id="headerContent">
        <div class="navbar-flex-container">
          <div id="leftHeader">
            <img src="@/design/images/logos/truckscience/appheader.png" class="leftHeader-logo" />
            <!-- <div v-if="offerIsOpen !== true && displayAppHeaderTextImage === true" class="get-started-logo"> -->
            <div v-if="offerIsOpen !== true" class="get-started-logo">
                <div class="get-started-logo-text"></div>
            </div>
            <div v-if="offerIsOpen === true"
              class="navbar-offer-details-overall-container offer-nav-bar-menu hide-from-view">
                <div class="navbar-offer-details-container">
                    <div class="navbar-vehicle-model" 
                        data-toggle="tooltip" 
                        data-placement="bottom" 
                        :title="vehicleTitleComputed"
                        data-bind="attr: {title: offerManager.vehicleTitleComputed}">
                        <span class="navabr-vehicle-detail-span" data-bind="text: offerManager.vehicleTitleComputed">{{vehicleTitleComputed}}</span>
                    </div>
                    <div v-if="vehicleDescriptionComputed !== ''">
                        <span class="navabr-vehicle-detail-span" data-bind="text: offerManager.vehicleDescriptionComputed">{{vehicleDescriptionComputed}}</span>
                    </div>
                </div>
            </div>
          </div>

          <!-- #region Start -->
          <div id="navMargin">
            <div id="rightHeader" class="header-menu-right">
              <!--#region New nav bar setup -->
              <div class="nav-buttons-parent-flex-container offer-nav-bar-menu" v-if="$parent.offerIsOpen">
                <!-- <router-link
                  to="/selection"
                  id="nav-button-home"
                  class="nav-buttons knockout-clickable-item"
                  :title="getTranslationText('2397')"
                >
                  <i class="fa fa-home fa-lg"></i>
                </router-link> -->
                <div
                  id="nav-button-home"
                  class="nav-buttons knockout-clickable-item"
                  :title="getTranslationText('2397')"
                  @click="navigateToHome"
                >
                  <i class="fa fa-home fa-lg"></i>
                </div>
              </div>
              <NavBarDivider :displayDivider="true" v-if="$parent.offerIsOpen"/>
              <!-- Routes go here -->
              <div class="nav-buttons-flex-container offer-nav-bar-menu"
                v-if="$parent.offerIsOpen"
              >
                <div class="menu-item-table">
                  <div class="nav-button-flex-container">
                    <template
                      v-for="route in activeRoutes"
                      :key="route.meta.id"
                    >
                    <div class="nav-button-menu"
                      v-if="route.meta.nav"
                      :id="route.meta.id"
                      :title="getTranslationText(route.meta.tooltip)"
                      :class="{ active: route.meta.id === currentRouteId }"
                    >
                      <router-link v-if="typeof route.meta.faClasses === 'undefined'" :to="{ path: route.path }">
                        <div>
                          <div></div>
                        </div>
                      </router-link>
                      <router-link v-if="typeof route.meta.faClasses === 'string'" class="nav-bar-link-with-fa-icon" :to="{ path: route.path }">
                        <div>
                            <i :class="route.meta.faClasses"></i>
                        </div>
                      </router-link>
                    </div>
                    </template>
                  </div>
                </div>
              </div>

              <NavBarDivider :displayDivider="true" v-if="$parent.offerIsOpen" />
              <!-- User menu goes here -->
              <div class="nav-buttons-parent-flex-container user-menu">
                  <UserMenu :displayOfferRelatedParts="$parent.offerIsOpen" />
              </div>
              <!--#endregion New nav bar setup -->
            </div>
          </div>
          <!-- #endregion End -->
        </div>
      </div>
    </nav>
</template>

<script>
import config from "@/services/config";
import globals from "@/services/globals";
import offerManager from "@/services/offerManager";
import UserMenu from "./UserMenu.vue";
import NavBarDivider from "./NavBarDivider";
import { router } from '@/router';
import { computed, watch, ref, nextTick } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import bus from '@/main';

const currentRouteId = ref();
watch(router.currentRoute, (newValue, oldValue) => {
  currentRouteId.value = newValue.meta.id;
});

export default {
    setup(props, context) {

      function navigateToHome() {
        newOffer();
      }

      function newOffer() { // Move to NavBar
        globals.openedSharedOfferToastFlag = false;
        //clear out access code in case configuration was hit through deep link 
        globals.user.updateUser({
            accessCode: ''
        });
        globals.setTrialStatusMessageAlreadyShownForOpenOffer(false);
        if (offerManager.unsavedChangesOnOffer.value) {
          globals.showDialog(new CustomMessageBox(config.getMessageText('2414', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('576'), config.getTranslationText('261'), config.getTranslationText('65')], config.getMessageReference('2414'))).addCallback(function (dialogResult) {
              if (dialogResult === config.getTranslationText('576')) {
                  // saveOffer('selection'); // add bus.on
                  nextTick(function(){
                    bus.emit('saveOfferFromNavigateToHome');
                  });
              } else if (dialogResult === config.getTranslationText('261')) {
                  //navigate to the selection screen (default route)
                  //offerManager.closeOffer();
                  globals.closeOfferVisually();
                  globals.setNavigatingHome(true);
                  bus.emit('routeToSelection');
              } else {
                  //close the dialog   
              }
          });
        } else {
            globals.closeOfferVisually();
            globals.setNavigatingHome(true);
            bus.emit('routeToSelection');
        }
      }

      return {
        activeRoutes: computed(function () { 
          router.currentRoute.value;
          globals.modulesAddedOrDroppedAfterSwap.value;
          return router.getRoutes();
        }),
        console,
        currentRouteId,
        offerIsOpen: offerManager.offerIsOpen,
        vehicleTitleComputed: offerManager.vehicleTitleComputed,
        vehicleDescriptionComputed: offerManager.vehicleDescriptionComputed,
        displayAppHeaderTextImage: config.displayAppHeaderTextImage,
        getTranslationText: config.getTranslationText,
        navigateToHome
      };
    },
    components: { UserMenu, NavBarDivider }
}
</script>

<style lang="scss" scoped>

</style>