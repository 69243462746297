import globals from '../../../services/globals';




var FuelTank = function (id, number, includeInCalculation, offeredAs, horizontalCogOverride, horizontalCog, verticalCogOverride, verticalCog, capacity, volumeIncludedInTare, volumeIncludedInExtras, factoryFitted, lateralCog, lateralCogOverride, verticalCOGConfirmed, lateralCOGConfirmed, contents) {

    var self = this;
    //#region Private members
    var _id,
        _number,
        _includeInCalculation, 
        _offeredAs, 
        _horizontalCogOverride, 
        _horizontalCog, 
        _verticalCogOverride, 
        _verticalCog, 
        _capacity, 
        _volumeIncludedInTare,
        _volumeIncludedInExtras,
        _isFactoryFitted,
        _lateralCog,
        _lateralCogOverride,
        _verticalCOGConfirmed,
        _lateralCOGConfirmed,
        _contents;

        
    //#endregion Private members

    //#region Getters and setters
    function getId() {
        return _id;
    }

    function setId(newValue) {
        _id = newValue;
    }
    function getNumber() {
        return _number;
    }

    function setNumber(newValue) {
        _number = newValue;
    }
    
    function getIncludeInCalculation() {
        return _includeInCalculation;
    }
    function setIncludeInCalculation(newValue) {
        _includeInCalculation = newValue;
    }
    function getOfferedAs() {
        return _offeredAs;
    }
    function setOfferedAs(newValue) {
        _offeredAs = newValue;
    }
    function getHorizontalCogOverride() {
        return _horizontalCogOverride;
    }
    function setHorizontalCogOverride(newValue) {
        _horizontalCogOverride = newValue;
    }
    function getHorizontalCog() {
        return _horizontalCog;
    }
    function setHorizontalCog(newValue) {
        _horizontalCog = newValue;
    }
    function getVerticalCogOverride() {
        return _verticalCogOverride;
    }
    function setVerticalCogOverride(newValue) {
        _verticalCogOverride = newValue;
    }
    function getVerticalCog() {
        return _verticalCog;
    }
    function setVerticalCog(newValue) {
        _verticalCog = newValue;
    }
    function getLateralCogOverride() {
        return _lateralCogOverride;
    }
    function setLateralCogOverride(newValue) {
        _lateralCogOverride = newValue;
    }
    function getLateralCog() {
        return _lateralCog;
    }
    function setLateralCog(newValue) {
        _lateralCog = newValue;
    }
    function getCapacity() {
        return _capacity;
    }
    function setCapacity(newValue) {
        _capacity = newValue;
    }
    function getVolumeIncludedInTare() {
        return _volumeIncludedInTare;
    }
    function setVolumeIncludedInTare(newValue) {
        _volumeIncludedInTare = newValue;
    }
    function getVolumeIncludedInExtras() {
        return _volumeIncludedInExtras;
    }
    function setVolumeIncludedInExtras(newValue) {
        _volumeIncludedInExtras = newValue;
    }
    function isFactoryFitted() {
        return _isFactoryFitted;
    }
    function setFactoryFitted(newValue) {
        _isFactoryFitted = newValue;            
    }
    function getVerticalCOGConfirmed() {
        return _verticalCOGConfirmed;
    }
    function setVerticalCOGConfirmed(newValue) {
        _verticalCOGConfirmed = newValue;
    }
    function getLateralCOGConfirmed() {
        return _lateralCOGConfirmed;
    }
    function setLateralCOGConfirmed(newValue) {
        _lateralCOGConfirmed = newValue;
    }
    function getContents() {
        return _contents;
    }
    function setContents(newValue) {
        _contents = newValue;
    }
    //#endregion Getters and setters

    function returnFuelTankObjectToBeSaved() {
        return {
            "Number": getNumber(),
            "IncludeInCalculation": getIncludeInCalculation(),
            "OfferedAs": getOfferedAs(),
            "COGOverride": getHorizontalCogOverride(),
            "COG": getHorizontalCog(),
            "VerticalCOGOverride": getVerticalCogOverride(),
            "VerticalCOG": getVerticalCog(),
            "Capacity": getCapacity(),
            "VolumeIncludedInTare": getVolumeIncludedInTare(),
            "DoNotDelete": isFactoryFitted(),
            "LateralCOG": getLateralCog(),
            "LateralCOGOverride": getLateralCogOverride(),
            "VolumeIncludedInExtras": getVolumeIncludedInExtras(),
            "Contents": getContents()
        };
    }

    function calculateVolumeIncludedInExtras() {
        return _capacity - _volumeIncludedInTare;
    }

    function calculateVolumeIncludedInTare() {
        return _capacity - _volumeIncludedInExtras;
    }

    

    function clone(keepSameId) {
        var cloneId;
        if (keepSameId && keepSameId === true) {
            cloneId = _id;
        } else {
            cloneId = globals.nextId();
        }

        return new FuelTank(cloneId,
            _number,
            _includeInCalculation,
            _offeredAs,
            _horizontalCogOverride,
            _horizontalCog,
            _verticalCogOverride,
            _verticalCog,
            _capacity,
            _volumeIncludedInTare,
            _volumeIncludedInExtras,
            _isFactoryFitted,
            _lateralCog,
            _lateralCogOverride,
            _verticalCOGConfirmed,
            _lateralCOGConfirmed,
            _contents);
    }

    //#region Initialize
    setId(id);
    setNumber(number);
    setIncludeInCalculation(includeInCalculation);
    setOfferedAs(offeredAs);
    setHorizontalCogOverride(horizontalCogOverride);
    setHorizontalCog(horizontalCog);
    setVerticalCogOverride(verticalCogOverride);
    setVerticalCog(verticalCog);
    setCapacity(capacity);
    setVolumeIncludedInTare(volumeIncludedInTare);
    setVolumeIncludedInExtras(volumeIncludedInExtras);
    setFactoryFitted(factoryFitted);
    setLateralCog(lateralCog);
    setLateralCogOverride(lateralCogOverride);
    setVerticalCOGConfirmed(verticalCOGConfirmed);
    setLateralCOGConfirmed(lateralCOGConfirmed);
    setContents(contents);
    //#endregion Initialize

    //#region Public members
    self.getId = getId;
    self.setId = setId;
    self.getNumber = getNumber;
    self.setNumber = setNumber;
    self.getIncludeInCalculation = getIncludeInCalculation;
    self.setIncludeInCalculation = setIncludeInCalculation;
    self.getOfferedAs = getOfferedAs;
    self.setOfferedAs = setOfferedAs;
    self.getHorizontalCogOverride = getHorizontalCogOverride;
    self.setHorizontalCogOverride = setHorizontalCogOverride;
    self.getHorizontalCog = getHorizontalCog;
    self.setHorizontalCog = setHorizontalCog;
    self.getVerticalCogOverride = getVerticalCogOverride;
    self.setVerticalCogOverride = setVerticalCogOverride;
    self.getVerticalCog = getVerticalCog;
    self.setVerticalCog = setVerticalCog;
    self.getLateralCogOverride = getLateralCogOverride;
    self.setLateralCogOverride = setLateralCogOverride;
    self.getLateralCog = getLateralCog;
    self.setLateralCog = setLateralCog;
    self.getCapacity = getCapacity;
    self.setCapacity = setCapacity;
    self.getVolumeIncludedInTare = getVolumeIncludedInTare;
    self.setVolumeIncludedInTare = setVolumeIncludedInTare;
    self.getVolumeIncludedInExtras = getVolumeIncludedInExtras;
    self.setVolumeIncludedInExtras = setVolumeIncludedInExtras;
    self.isFactoryFitted = isFactoryFitted;
    self.getVerticalCOGConfirmed = getVerticalCOGConfirmed;
    self.getLateralCOGConfirmed = getLateralCOGConfirmed;
    self.returnFuelTankObjectToBeSaved = returnFuelTankObjectToBeSaved;
    self.calculateVolumeIncludedInExtras = calculateVolumeIncludedInExtras;
    self.calculateVolumeIncludedInTare = calculateVolumeIncludedInTare;
    self.getContents = getContents;
    self.setContents = setContents;
    

    self.clone = clone;
    //#endregion Public members
};

export default FuelTank;

    