<template>
    <div class="selection-wizard-render-container">
        <div v-if="renderAs === RENDERED_AS_OPTIONS.SELECTION_WIZARD" class="fill-flex-div">
                <div v-if="wizard.filterOption === WIZARD_FILTER_OPTIONS.DEFAULT" 
                    class="quick-search-container-div"
                    :class="{'space-around': wizard.displayBreadcrumbs.value === true && wizard.displayQuickSearch.value === true, 'quick-search-padding': wizard.displayQuickSearch.value === true }"
                     data-bind="css: {'space-around': displayBreadcrumbs() === true && displayQuickSearch() === true, 'quick-search-padding': displayQuickSearch() === true }">
                    <div v-if="wizard.displayQuickSearch.value === true && wizard.displayClearButton.value === false && wizard.displaySearchBarButton === false" 
                        class="quick-search-div">
                        <input type="text" 
                            v-model="wizard.searchTerms.value"
                            :id="wizard.activeWizardStep.value.getQuickSearchInputId()"
                            :placeholder="wizard.placeholderText"
                            data-bind="textInput: searchTerms, attr: { placeholder: placeholderText, id: activeWizardStep().getQuickSearchInputId() }" />
                        <div class="quick-search-icon">
                            <i class="fa fa-search"></i>
                        </div>
                    </div>
                    <div v-if="wizard.displayQuickSearch.value === true && wizard.displayClearButton.value === true"
                        class="row-container">
                        <div class="quick-search-div">
                            <input type="text" 
                            v-model="wizard.searchTerms.value"
                            :id="wizard.activeWizardStep.value.getQuickSearchInputId()"
                            :placeholder="wizard.placeholderText"
                            data-bind="textInput: searchTerms, attr: { placeholder: placeholderText, id: activeWizardStep().getQuickSearchInputId() }" />
                        </div>
                        <div class="multiselect-div-clear" 
                            @click="wizard.clearAllFilters"
                            :title="wizard.clearButtonTooltip"
                            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
                            <i class="fas fa-times fa-lg"></i> <span>{{getTranslationText('1191')}}</span>
                        </div>
                    </div>
                    <div v-if="wizard.displayQuickSearch.value === true && wizard.displaySearchBarButton === true" 
                        class="row-container-search-bar-button">
                        <div class="quick-search-div">
                            <input type="text" 
                                v-model="wizard.searchTerms.value"
                                :id="wizard.activeWizardStep.value.getQuickSearchInputId()"
                                :placeholder="wizard.placeholderText"
                                data-bind="textInput: searchTerms, attr: { placeholder: placeholderText, id: activeWizardStep().getQuickSearchInputId() }" />
                        </div>
                        <div class="or-separator">
                            <span data-bind="text: getTranslationText('707')"></span>
                        </div>
                        <div class="multiselect-div-clear">
                            <button class="btn btn-default btn-truckscience" 
                                @click="wizard.clickOnSearchBarButton"
                                data-bind="click: clickOnSearchBarButton, touchstart: clickOnSearchBarButton">
                                <i class="fas fa-lg" :class="wizard.searchBarButtonClass"></i> <span>{{wizard.searchBarButtonText}}</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="wizard.displayCustomBreadcrumbs.value === true && wizard.displaySearchResultsBreadcrumb.value === false" class="selection-wizard-breadcrumbs-container" data-bind="foreach: breadcrumbs">
                        <template v-for="(breadcrumb, index) in wizard.customBreadcrumbsObject.breadcrumbs.value" :key="breadcrumb">
                            <div class="selection-wizard-breadcrumb" 
                                @click="breadcrumbClicked(breadcrumb)"
                                :class="{ 'selection-wizard-breadcrumb-disable-click': breadcrumb.allowClick === false }"
                                data-bind="text: text, click: allowClick === true ? $parent.clickFunction: null, css: { 'selection-wizard-breadcrumb-disable-click': allowClick === false }">
                                {{breadcrumb.text}}
                            </div>
                            <div class="selection-wizard-breadcrumb-icon"
                                v-visible="index + 1 !== wizard.customBreadcrumbsObject.breadcrumbs.value"
                                data-bind="visible: $index + 1 !== $parent.breadcrumbs().length" >
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </template>
                    </div>
                    <div v-if="wizard.displayBreadcrumbs.value === true && wizard.displaySearchResultsBreadcrumb.value === false" class="selection-wizard-breadcrumbs-container" data-bind="foreach: steps">
                        <template v-for="step in wizard.steps" :key="step">
                            <div class="selection-wizard-breadcrumb" 
                                v-visible="step.displayInBreadcrumb.value"
                                @click="wizard.activateWizardStepFromBreadcrumb(step)"
                                data-bind="text: displayName, visible: displayInBreadcrumb, click: $parent.activateWizardStepFromBreadcrumb">
                                {{step.displayName}}
                            </div>
                            <div data-bind="visible: $index + 1 !== $parent.steps.length && displayInBreadcrumb" class="selection-wizard-breadcrumb-icon"><i class="fa fa-chevron-right"></i></div>
                        </template>
                    </div>
                    <div v-if="wizard.displaySearchResultsBreadcrumb.value === true" class="selection-wizard-breadcrumbs-container">
                        <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click">{{getTranslationText('2952')}}</div>
                        <div class="selection-wizard-breadcrumb-icon">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                    <div v-if="wizard.displayToolbar.value === true" class="selection-wizard-breadcrumbs-container selection-wizard-toolbar-container" data-bind="foreach: toolbar.buttons">
                        <template v-for="button in wizard.toolbar.buttons" :key="button">
                            <div v-if="button.type === SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON" 
                                class="selection-wizard-toolbar-button" 
                                @click="toobarButtonClickFunction(button)"
                                :class="{ 'tb-button-disabled': button.isEnabled.value === false }"
                                data-bind="click: clickFunction, css: { 'tb-button-disabled': isEnabled() === false }">
                                <i class="fa fa-lg" :class="button.icon"></i><span>{{button.text}}</span>
                            </div>
                            <div v-if="button.type === SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.SEPARATOR" class="selection-wizard-toolbar-separator">
                                <div></div>
                            </div>
                        </template>
                    </div>
                    <div v-if="wizard.displayBackButton === true" class="selection-wizard-breadcrumbs-container display-back-button-div">
                        <div class="selection-wizard-breadcrumb">{{wizard.title.value}}</div>
                        <div v-if="wizard.displayBackButtonOnScreen === true" 
                            class="selection-wizard-breadcrumb-icon"
                            @click="wizard.backButtonClick"
                            data-bind="click: backButtonClick, touchstart: backButtonClick" >
                            <i class="fa fa-chevron-circle-left fa-lg"></i>
                        </div>
                    </div>
                    <div v-if="wizard.displayTabs.value === true" class="selection-wizard-tabs-div" data-bind="foreach: tabs">
                        <div v-for="tab in wizard.tabs.value" :key="tab" 
                            @click="wizard.activateTab(tab)"
                            :class="{'active': tab.isActive.value === true}"
                            :title="tab.tooltipText"
                            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
                            <div class="selection-datagrid-type-icon">
                                <div :class="tab.iconClass"></div>
                            </div>
                            <div class="tab-name">
                                <span class="tab-name-display">{{tab.displayName}}</span>
                                <span class="tab-name-hidden">{{tab.displayName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="wizard.filterOption === WIZARD_FILTER_OPTIONS.MANUFACTURER_VEHICLE_SELECTION" 
                    class="quick-search-container-div manufacturer-filters-container"
                    :class="{ 'quick-search-padding': wizard.displayQuickSearch.value === true }">
                    <div class="row-container">
                        <div class="quick-search-div"
                            :class="[{ 'one-multiselect-sibling': wizard.getMultiSelectByPartialName('STATUS').options.value.length > 1 || (wizard.getMultiSelectByPartialName('COUNTRY') !== null && wizard.getMultiSelectByPartialName('COUNTRY').options.value.length > 1) }, { 'two-multiselect-siblings': wizard.getMultiSelectByPartialName('STATUS').options.value.length > 1 && wizard.getMultiSelectByPartialName('COUNTRY') !== null && wizard.getMultiSelectByPartialName('COUNTRY').options.value.length > 1 }]">
                            <input type="text" v-model="wizard.searchTerms.value" :placeholder="wizard.placeholderText" />
                        </div>
                        <MultiSelect v-if="wizard.getMultiSelectByPartialName('STATUS').options.value.length > 1"
                            :multiSelectFromParent="wizard.getMultiSelectByPartialName('STATUS')"></MultiSelect>
                        <MultiSelect
                            v-if="wizard.getMultiSelectByPartialName('COUNTRY') !== null && wizard.getMultiSelectByPartialName('COUNTRY').options.value.length > 1"
                            :multiSelectFromParent="wizard.getMultiSelectByPartialName('COUNTRY')"></MultiSelect>
                    </div>
                    <div class="row-container">
                        <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('MAKE')"></MultiSelect>
                        <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('RANGE')"></MultiSelect>
                        <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('AXLE-LAYOUT')">
                        </MultiSelect>
                        <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('VEHICLE-TYPE')">
                        </MultiSelect>
                        <div class="multiselect-div-clear" @click="wizard.clearAllFilters"
                            :title="wizard.clearButtonTooltip">
                            <i class="fas fa-times fa-lg"></i> <span
                                data-bind="text: getTranslationText('1191')">{{getTranslationText('1191')}}</span>
                        </div>
                    </div>

                    <div class="selection-wizard-tabs-div" v-if="wizard.displayTabs.value === true">
                        <div v-for="tab in wizard.tabs.value" :key="tab" :class="{ 'active': tab.isActive.value }" :title="tab.tooltipText"
                            @click="wizard.activateTab(tab)"
                            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
                            <div class="selection-datagrid-type-icon">
                                <div :class="tab.iconClass"></div>
                            </div>
                            <div class="tab-name">
                                <span class="tab-name-display">{{ tab.displayName }}</span>
                                <span class="tab-name-hidden">{{ tab.displayName }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="selection-wizard-breadcrumbs-container"
                        v-if="wizard.displayBreadcrumbs.value === true && wizard.displaySearchResultsBreadcrumb.value === false">
                        <template v-for="(breadcrumb, index) in wizard.steps" :key="breadcrumb">
                            <div v-if="breadcrumb.displayInBreadcrumb.value"
                                class="selection-wizard-breadcrumb" 
                                @click="wizard.activateWizardStepFromBreadcrumb(breadcrumb)">
                                {{ breadcrumb.displayName.value }}
                            </div>
                            <div v-if="index + 1 !== wizard.steps.length && breadcrumb.displayInBreadcrumb.value"
                                class="selection-wizard-breadcrumb-icon">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </template>
                        
                    </div>

                    <div class="selection-wizard-breadcrumbs-container"
                        v-if="wizard.displaySearchResultsBreadcrumb.value === true">
                        <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click">{{
                                getTranslationText('2952')
                        }}</div>
                        <div class="selection-wizard-breadcrumb-icon">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div v-if="wizard.filterOption === WIZARD_FILTER_OPTIONS.GENERIC_VEHICLE_SELECTION"
                    class="quick-search-container-div generic-filters-container"
                    :class="{ 'quick-search-padding': wizard.displayQuickSearch.value === true }">
                    <template v-if="wizard.displayQuickSearch.value === true">
                        <div class="row-container">
                            <div class="quick-search-div two-multiselect-siblings">
                                <input type="text"
                                    v-model="wizard.searchTerms.value"
                                    :placeholder="wizard.placeholderText" />
                            </div>
                            <div class="multiselect-div"
                                v-if="wizard.getMultiSelectByPartialName('AXLE-LAYOUT') !== null"
                                :id="wizard.getMultiSelectByPartialName('AXLE-LAYOUT').selectId + '-multiselect-div'">
                                <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('AXLE-LAYOUT')"></MultiSelect>
                            </div>
                            <div class="multiselect-div"
                                v-if="wizard.getMultiSelectByPartialName('VEHICLE-TYPE') !== null"
                                :id="wizard.getMultiSelectByPartialName('VEHICLE-TYPE').selectId + '-multiselect-div'">
                                <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('VEHICLE-TYPE')"></MultiSelect>
                            </div>
                            <div class="multiselect-div"
                                v-if="wizard.getMultiSelectByPartialName('STATUS') !== null"
                                :id="wizard.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div'">
                                <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('STATUS')"></MultiSelect>
                            </div>
                            <div class="multiselect-div"
                                v-if="wizard.getMultiSelectByPartialName('MAKE') !== null"
                                :id="wizard.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div'">
                                <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('MAKE')"></MultiSelect>
                            </div>
                            <div class="multiselect-div-clear"
                                @click="wizard.clearAllFilters"
                                :title="wizard.clearButtonTooltip">
                                <i class="fas fa-times fa-lg"></i> <span
                                    data-bind="text: getTranslationText('1191')"> {{ getTranslationText('1191') }}</span>
                            </div>
                        </div>
                        <div v-if="wizard.displayTabs.value === true" class="selection-wizard-tabs-div">
                            <div v-for="tab in wizard.tabs.value" :key="tab" :class="{ 'active': tab.isActive.value }" :title="tab.tooltipText"
                                @click="wizard.activateTab(tab)"
                                data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
                                <div class="selection-datagrid-type-icon">
                                    <div :class="tab.iconClass"></div>
                                </div>
                                <div class="tab-name">
                                    <span class="tab-name-display">{{ tab.displayName }}</span>
                                    <span class="tab-name-hidden">{{ tab.displayName }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="selection-wizard-breadcrumbs-container"
                            v-if="wizard.displayBreadcrumbs.value === true && wizard.displaySearchResultsBreadcrumb.value === false">
                            <template v-for="(breadcrumb, index) in wizard.steps" :key="breadcrumb">
                                <div class="selection-wizard-breadcrumb" 
                                 @click="wizard.activateWizardStepFromBreadcrumb(breadcrumb)">
                                    <template v-if="breadcrumb.displayInBreadcrumb.value">
                                        {{ breadcrumb.displayName.value }}
                                    </template>
                                </div>
                                <div v-if="index + 1 !== wizard.steps.length && breadcrumb.displayInBreadcrumb.value"
                                    class="selection-wizard-breadcrumb-icon">
                                    <i class="fa fa-chevron-right"></i>
                                </div>
                            </template>
                        </div>
                        <div class="selection-wizard-breadcrumbs-container"
                            v-if="wizard.displaySearchResultsBreadcrumb.value === true">
                            <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click">{{
                                    getTranslationText('2952')
                            }}</div>
                            <div class="selection-wizard-breadcrumb-icon">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="wizard.filterOption === WIZARD_FILTER_OPTIONS.MENU"
                    :class="{ 'quick-search-padding': wizard.displayQuickSearch.value === true }" 
                    class="quick-search-container-div menu-filters-container" >
                    <template v-if="wizard.displayQuickSearch.value === true">
                    <div class="row-container searchbox-row">
                        <div class="quick-search-div">
                            <input type="text" 
                                v-model="wizard.searchTerms.value"
                                :placeholder="wizard.placeholderText" />
                        </div>
                        <div v-if="wizard.displayResetButtonOnSameRowAsSearch === true" 
                            class="multiselect-div-clear" 
                            @click="wizard.clearAllFilters"
                            :attr="wizard.clearButtonTooltip">
                            <i class="fas fa-times fa-lg"></i> <span>{{getTranslationText('1191')}}</span>
                        </div>
                    </div>
                    <div class="row-container">
                        <div v-if="wizard.getMultiSelectByPartialName('STATUS') !== null" 
                            class="multiselect-div" 
                            :id="wizard.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div'"
                            data-bind="attr: { id: $data.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
                            <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('STATUS')"></MultiSelect>
                            </div>
                        <div v-if="wizard.getMultiSelectByPartialName('MAKE') !== null" 
                            class="multiselect-div" 
                            :id="wizard.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div'"
                            data-bind="attr: { id: $data.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
                            <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('MAKE')"></MultiSelect>
                            </div>
                        <div v-if="wizard.getMultiSelectByPartialName('BODY-TYPE') !== null" 
                            class="multiselect-div" 
                            :id="wizard.getMultiSelectByPartialName('BODY-TYPE').selectId + '-multiselect-div'"
                            data-bind="attr: { id: $data.getMultiSelectByPartialName('BODY-TYPE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
                            <MultiSelect :multiSelectFromParent="wizard.getMultiSelectByPartialName('BODY-TYPE')"></MultiSelect>
                            </div>
                        <div v-if="wizard.displayResetButtonOnSameRowAsSearch === false" 
                            class="multiselect-div-clear" 
                            @click="wizard.clearAllFilters"
                            :title="wizard.clearButtonTooltip"
                            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
                            <i class="fas fa-times fa-lg"></i> <span>{{getTranslationText('1191')}}</span>
                        </div>
                    </div>
                    <div v-if="wizard.displayTabs.value === true" class="selection-wizard-tabs-div" data-bind="foreach: tabs">
                        <div v-for="tab in wizard.tabs.value" :key="tab" 
                            @click="wizard.activateTab(tab)"
                            :class="{'active': tab.isActive.value === true}"
                            :title="tab.tooltipText"
                            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
                            <div class="selection-datagrid-type-icon">
                                <div :class="tab.iconClass"></div>
                            </div>
                            <div class="tab-name">
                                <span class="tab-name-display">{{tab.displayName}}</span>
                                <span class="tab-name-hidden">{{tab.displayName}}</span>
                            </div>
                        </div>
                    </div>
                    </template>
                </div>
                <div v-if="wizard.renderSelectionWizardAs.value === RENDERED_AS_OPTIONS.QUICK_SEARCH_DATAGRID"
                    class="datagrid-container-div"
                    :class="wizard.customCss"
                    v-scroll="wizard.handleDataGridScroll">
                    <SelectionDatagrid :wizardFromParent="wizard"></SelectionDatagrid>
                    <!-- <div class="datagrid-container-div" data-bind="visible: renderSelectionWizardAs() === $root.config.RENDERED_AS_OPTIONS.QUICK_SEARCH_DATAGRID, template: {name: 'template-selection-datagrid', data: $data}, css: customCss, event: { scroll: handleDataGridScroll }"></div> -->
                </div>

                <div class="datagrid-container-div"
                    v-if="wizard.renderSelectionWizardAs.value === RENDERED_AS_OPTIONS.DATAGRID" :id="wizard.containerId"
                    :class="wizard.customCss"
                    v-scroll="wizard.handleDataGridScroll">
                    <SelectionDatagrid :wizardFromParent="wizard"></SelectionDatagrid>
                </div>

                <div class="menu-message-div" v-if="wizard.displayRequestItemSection.value === true">
                    <i class="fa fa-info-circle fa-lg"></i>
                    <div class="requestItemObject-div" v-if="wizard.requestItemObject.value !== null">
                        <!-- template-menu-request-item
                        data: requestItemObject, afterRender: afterRenderFunction -->
                        <div v-html="wizard.requestItemObject.value.requestItemText.value"></div>
                    </div>
                </div>

                <div class="datagrid-container-div"
                    v-if="wizard.renderSelectionWizardAs.value === RENDERED_AS_OPTIONS.IMAGE_CARD" :id="wizard.containerId">
                    <SelectionImageCard :wizardFromParent="wizard"></SelectionImageCard>
                </div>

                <div class="datagrid-container-div"
                    v-if="wizard.renderSelectionWizardAs.value === RENDERED_AS_OPTIONS.TEXT_CARD" :id="wizard.containerId">
                    <SelectionTextCard :wizardFromParent="wizard"></SelectionTextCard>
                </div>
        </div>
        <div v-if="renderAs === RENDERED_AS_OPTIONS.REQUEST_A_VEHICLE" class="fill-flex-div">
            <div class="fill-flex-div">
                <form class="request-vehicle-form" role="form" @submit.prevent="wizard.submitForm">
                    <div>
                        <div class="request-vehicle-input-container">
                            <div class="quick-search-div">
                                <input required="" type="text" :value="wizard.make"
                                    :placeholder="wizard.makePlaceholder">
                            </div>
                            <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                        </div>
                        <div class="request-vehicle-input-container">
                            <div class="quick-search-div">
                                <input required="" type="text" :value="wizard.model"
                                    :placeholder="wizard.modelPlaceholder">
                            </div>
                            <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                        </div>
                        <div class="request-vehicle-input-container textarea">
                            <textarea class="request-vehicle-textarea" :value="wizard.additionalInfo"
                                :placeholder="wizard.additionalInfoPlaceholder"></textarea>
                        </div>
                        <div class="request-vehicle-list-container" v-html="wizard.additionalNotesMessage"></div>
                        <div class="request-vehicle-input-container textarea">
                            <div class="request-vehicle-required">
                                <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                                <span> {{ wizard.requiredText }} </span>
                            </div>
                            <div>
                                <button class="btn btn-default btn-truckscience btn-primary" type="submit">
                                    <span>{{ wizard.buttonText }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="renderAs === RENDERED_AS_OPTIONS.MENU" class="fill-flex-div">
            <MenuContainer :menuFromParent="wizard"></MenuContainer>
        </div>
    </div>

    <!-- ko if: displayCustomBreadcrumbs() === true && displaySearchResultsBreadcrumb() === false 
 ko with: customBreadcrumbsObject 
<div class="selection-wizard-breadcrumbs-container" data-bind="foreach: breadcrumbs">
    <div class="selection-wizard-breadcrumb"
        data-bind="text: text, click: allowClick === true ? $parent.clickFunction: null, css: { 'selection-wizard-breadcrumb-disable-click': allowClick === false }">
    </div>
    <div data-bind="visible: $index + 1 !== $parent.breadcrumbs().length"
        class="selection-wizard-breadcrumb-icon">
        <i class="fa fa-chevron-right"></i>
    </div>
</div>
 /ko 
 /ko 
 ko if: displayBreadcrumbs() === true && displaySearchResultsBreadcrumb() === false 
<div class="selection-wizard-breadcrumbs-container" data-bind="foreach: steps">
    <div class="selection-wizard-breadcrumb"
        data-bind="text: displayName, visible: displayInBreadcrumb, click: $parent.activateWizardStepFromBreadcrumb">
    </div>
    <div data-bind="visible: $index + 1 !== $parent.steps.length && displayInBreadcrumb"
        class="selection-wizard-breadcrumb-icon"><i class="fa fa-chevron-right"></i></div>
</div>
 /ko 
 ko if: displaySearchResultsBreadcrumb() === true 
<div class="selection-wizard-breadcrumbs-container">
    <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click"
        data-bind="text: $root.config.getTranslationText('2952')">Search Results</div>
    <div class="selection-wizard-breadcrumb-icon">
        <i class="fa fa-chevron-right"></i>
    </div>
</div>
 /ko 
 ko if: displayToolbar() === true 
<div class="selection-wizard-breadcrumbs-container selection-wizard-toolbar-container"
    data-bind="foreach: toolbar.buttons">
     ko if: type === $root.config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.BUTTON 
    <div class="selection-wizard-toolbar-button"
        data-bind="click: clickFunction, css: { 'tb-button-disabled': isEnabled() === false }">
        <i class="fa fa-lg" data-bind="css: icon"></i><span data-bind="text: text"></span>
    </div>
     /ko 
     ko if: type === $root.config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES.SEPARATOR 
    <div class="selection-wizard-toolbar-separator">
        <div></div>
    </div>
     /ko 
</div>
 /ko 
 ko if: displayBackButton === true 
<div class="selection-wizard-breadcrumbs-container display-back-button-div">
    <div class="selection-wizard-breadcrumb" data-bind="text: title"></div>
     ko if: displayBackButtonOnScreen() === true 
    <div data-bind="click: backButtonClick, touchstart: backButtonClick"
        class="selection-wizard-breadcrumb-icon"><i class="fa fa-chevron-circle-left fa-lg"></i>
    </div>
     /ko 
</div>
 /ko 
 ko if: displayTabs() === true 
<div class="selection-wizard-tabs-div" data-bind="foreach: tabs">
    <div
        data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
        <div class="selection-datagrid-type-icon">
            <div data-bind="css: iconClass"></div>
        </div>
        <div class="tab-name">
            <span class="tab-name-display" data-bind="text: displayName"></span>
            <span class="tab-name-hidden" data-bind="text: displayName"></span>
        </div>
    </div>
</div>
 /ko 

 ko if: filterOption === $root.config.WIZARD_FILTER_OPTIONS.MANUFACTURER_VEHICLE_SELECTION 
<div class="quick-search-container-div manufacturer-filters-container"
    data-bind="css: { 'quick-search-padding': displayQuickSearch() === true }">
    <div class="row-container">
        <div class="quick-search-div"
            data-bind="css: { 'one-multiselect-sibling': $data.getMultiSelectByPartialName('STATUS').options().length > 1 || ($data.getMultiSelectByPartialName('COUNTRY') !== null && $data.getMultiSelectByPartialName('COUNTRY').options().length > 1), 'two-multiselect-siblings': $data.getMultiSelectByPartialName('STATUS').options().length > 1 && $data.getMultiSelectByPartialName('COUNTRY') !== null && $data.getMultiSelectByPartialName('COUNTRY').options().length > 1 }">
            <input type="text"
                data-bind="textInput: searchTerms, attr: { placeholder: placeholderText }" />
        </div>
         ko if: $data.getMultiSelectByPartialName('STATUS').options().length > 1 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('COUNTRY') !== null && $data.getMultiSelectByPartialName('COUNTRY').options().length > 1 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('COUNTRY').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('COUNTRY') } ">
        </div>
         /ko 
    </div>
    <div class="row-container">
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('MAKE') } ">
        </div>
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('RANGE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('RANGE') } ">
        </div>
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('AXLE-LAYOUT').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('AXLE-LAYOUT') } ">
        </div>
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('VEHICLE-TYPE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('VEHICLE-TYPE') } ">
        </div>
        <div class="multiselect-div-clear"
            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
            <i class="fas fa-times fa-lg"></i> <span
                data-bind="text: $root.config.getTranslationText('1191')"></span>
        </div>
    </div>
     ko if: displayTabs() === true 
    <div class="selection-wizard-tabs-div" data-bind="foreach: tabs">
        <div
            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
            <div class="selection-datagrid-type-icon">
                <div data-bind="css: iconClass"></div>
            </div>
            <div class="tab-name">
                <span class="tab-name-display" data-bind="text: displayName"></span>
                <span class="tab-name-hidden" data-bind="text: displayName"></span>
            </div>
        </div>
    </div>
     /ko 
     ko if: displayBreadcrumbs() === true && displaySearchResultsBreadcrumb() === false 
    <div class="selection-wizard-breadcrumbs-container" data-bind="foreach: steps">
        <div class="selection-wizard-breadcrumb"
            data-bind="text: displayName, visible: displayInBreadcrumb, click: $parent.activateWizardStepFromBreadcrumb">
        </div>
        <div data-bind="visible: $index + 1 !== $parent.steps.length && displayInBreadcrumb"
            class="selection-wizard-breadcrumb-icon"><i class="fa fa-chevron-right"></i></div>
    </div>
     /ko 
     ko if: displaySearchResultsBreadcrumb() === true 
    <div class="selection-wizard-breadcrumbs-container">
        <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click"
            data-bind="text: $root.config.getTranslationText('2952')">Search Results</div>
        <div class="selection-wizard-breadcrumb-icon">
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>
     /ko 
</div>
 /ko 
 ko if: filterOption === $root.config.WIZARD_FILTER_OPTIONS.GENERIC_VEHICLE_SELECTION 
<div class="quick-search-container-div generic-filters-container"
    data-bind="css: { 'quick-search-padding': displayQuickSearch() === true }">
     ko if: displayQuickSearch() === true 
    <div class="row-container">
        <div class="quick-search-div two-multiselect-siblings">
            <input type="text"
                data-bind="textInput: searchTerms, attr: { placeholder: placeholderText }" />
        </div>
         ko if: $data.getMultiSelectByPartialName('AXLE-LAYOUT') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('AXLE-LAYOUT').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('AXLE-LAYOUT') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('VEHICLE-TYPE') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('VEHICLE-TYPE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('VEHICLE-TYPE') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('STATUS') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('MAKE') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('MAKE') } ">
        </div>
         /ko 
        <div class="multiselect-div-clear"
            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
            <i class="fas fa-times fa-lg"></i> <span
                data-bind="text: $root.config.getTranslationText('1191')"></span>
        </div>
    </div>
     ko if: displayTabs() === true 
    <div class="selection-wizard-tabs-div" data-bind="foreach: tabs">
        <div
            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
            <div class="selection-datagrid-type-icon">
                <div data-bind="css: iconClass"></div>
            </div>
            <div class="tab-name">
                <span class="tab-name-display" data-bind="text: displayName"></span>
                <span class="tab-name-hidden" data-bind="text: displayName"></span>
            </div>
        </div>
    </div>
     /ko 
     ko if: displayBreadcrumbs() === true && displaySearchResultsBreadcrumb() === false 
    <div class="selection-wizard-breadcrumbs-container" data-bind="foreach: steps">
        <div class="selection-wizard-breadcrumb"
            data-bind="text: displayName, visible: displayInBreadcrumb, click: $parent.activateWizardStepFromBreadcrumb">
        </div>
        <div data-bind="visible: $index + 1 !== $parent.steps.length && displayInBreadcrumb"
            class="selection-wizard-breadcrumb-icon"><i class="fa fa-chevron-right"></i></div>
    </div>
     /ko 
     ko if: displaySearchResultsBreadcrumb() === true 
    <div class="selection-wizard-breadcrumbs-container">
        <div class="selection-wizard-breadcrumb selection-wizard-breadcrumb-disable-click"
            data-bind="text: $root.config.getTranslationText('2952')">Search Results</div>
        <div class="selection-wizard-breadcrumb-icon">
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>
     /ko 
     /ko 
</div>
 /ko 
 ko if: filterOption === $root.config.WIZARD_FILTER_OPTIONS.MENU 
<div class="quick-search-container-div menu-filters-container"
    data-bind="css: { 'quick-search-padding': displayQuickSearch() === true }">
     ko if: displayQuickSearch() === true 
    <div class="row-container searchbox-row">
        <div class="quick-search-div">
            <input type="text"
                data-bind="textInput: searchTerms, attr: { placeholder: placeholderText }" />
        </div>
         ko if: displayResetButtonOnSameRowAsSearch === true 
        <div class="multiselect-div-clear"
            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
            <i class="fas fa-times fa-lg"></i> <span
                data-bind="text: $root.config.getTranslationText('1191')"></span>
        </div>
         /ko 
    </div>
    <div class="row-container">
         ko if: $data.getMultiSelectByPartialName('STATUS') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('STATUS').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('STATUS') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('MAKE') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('MAKE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('MAKE') } ">
        </div>
         /ko 
         ko if: $data.getMultiSelectByPartialName('BODY-TYPE') !== null 
        <div class="multiselect-div"
            data-bind="attr: { id: $data.getMultiSelectByPartialName('BODY-TYPE').selectId + '-multiselect-div' }, template: { name: 'multiselect-template', data: $data.getMultiSelectByPartialName('BODY-TYPE') } ">
        </div>
         /ko 
         ko if: displayResetButtonOnSameRowAsSearch === false 
        <div class="multiselect-div-clear"
            data-bind="click: clearAllFilters, attr: { title: clearButtonTooltip }">
            <i class="fas fa-times fa-lg"></i> <span
                data-bind="text: $root.config.getTranslationText('1191')"></span>
        </div>
         /ko 
    </div>
     ko if: displayTabs() === true 
    <div class="selection-wizard-tabs-div" data-bind="foreach: tabs">
        <div
            data-bind="click: $parent.activateTab, touchstart: $parent.activateTab, css: {'active': isActive() === true}, attr: { title: tooltipText }">
            <div class="selection-datagrid-type-icon">
                <div data-bind="css: iconClass"></div>
            </div>
            <div class="tab-name">
                <span class="tab-name-display" data-bind="text: displayName"></span>
                <span class="tab-name-hidden" data-bind="text: displayName"></span>
            </div>
        </div>
    </div>
     /ko 
     /ko 
</div>
 /ko  -->
</template>

<script>
import { onMounted, onUpdated, toRef } from 'vue';
import config from '@/services/config';
// import MenuContainer from '../menu/MenuContainer.vue';
import SelectionImageCard from './SelectionImageCard.vue';
import SelectionTextCard from './SelectionTextCard.vue';
import SelectionDatagrid from './SelectionDatagrid.vue';
import MultiSelect from '../../components/multiselect/MultiSelect.vue';

export default {
    props: {
        wizardFromParent: Object,
        renderAsFromParent: {
            type: String,
            default: config.RENDERED_AS_OPTIONS.SELECTION_WIZARD
        }
    },
    setup(props) {
        let wizard = toRef(props, "wizardFromParent"),
            renderAs = toRef(props, "renderAsFromParent");

        onMounted(() => {
            triggerAfterRenderFunctionIfRequired();
        })

        onUpdated(() => {
            if(renderAs.value === config.RENDERED_AS_OPTIONS.MENU) {
                wizard.value.activeMenuStep.value.updateJsElements();
            } else {
                wizard.value.initializeMultiSelects();
            }
            triggerAfterRenderFunctionIfRequired();
        });

        function triggerAfterRenderFunctionIfRequired() {
            if(wizard.value.requestItemObject !== undefined && wizard.value.requestItemObject.value !== null) {
                wizard.value.afterRenderFunction();
            }
        }

        function activateTab(tabClicked) {            
            // Get tab clicked details
            let tabDetails = wizard.value.tabs.find(function(tab){
                return tabClicked.currentTarget.innerText === tab.displayName;
            });

            // Call activateTab on wizard
            wizard.value.activateTab(tabDetails)
        }

        function breadcrumbClicked(breadcrumb) {
            if(breadcrumb.allowClick === true) {
                wizard.value.customBreadcrumbsObject.clickFunction(breadcrumb);
            }
        }

        function toobarButtonClickFunction(buttonClicked) {
            if(buttonClicked.isEnabled.value) {
                buttonClicked.clickFunction();
            }
        }
        return {
            wizard,
            renderAs,
            activateTab,
            breadcrumbClicked,
            toobarButtonClickFunction,
            getTranslationText: config.getTranslationText,
            RENDERED_AS_OPTIONS: config.RENDERED_AS_OPTIONS,
            WIZARD_FILTER_OPTIONS: config.WIZARD_FILTER_OPTIONS,
            SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES: config.SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES
        };
    },
    components: { SelectionImageCard, SelectionTextCard, SelectionDatagrid, MultiSelect }
}
</script>

<style lang="scss" scoped>
.selection-wizard-breadcrumbs-container {
    .selection-wizard-breadcrumb {
        display: flex;

        span {
            margin-right: 10px;
        }

        // span + .selection-wizard-breadcrumb-icon {

        // }
    }
}
</style>