

/**
 * Base object to be used for pinned items in dashboard which will be extended for internal standards
 * @param {object} init
 * @returns {object} - Data Point which contains DataPointId getter and setter functions
 */
var DashboardDataPoint = function (init) {
    var dashboardDataPoint = this;
    //Id, Gate, Plaza, Route, Locations, Fees
    var _dataPointId = init.DataPointId;

    // #region Getters and setters
    function getDataPointId() {
        return _dataPointId;
    }
    function setDataPointId(newValue) {
        _dataPointId = newValue;
    }
    // #endregion Getters and setters

    function getObjectToBeSaved() {
        var objectToBeSaved = {};
        objectToBeSaved.DataPointId = dashboardDataPoint.getDataPointId();
        
        return objectToBeSaved;
    }

    dashboardDataPoint.getDataPointId = getDataPointId;
    dashboardDataPoint.setDataPointId = setDataPointId;
    dashboardDataPoint.getObjectToBeSaved = getObjectToBeSaved
}

export default DashboardDataPoint;
