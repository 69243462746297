import config from '../..//services/config';

var Arc = function (startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator) {

    var self = this;
    //properties
    self.centre = { x: startX, y: startY };
    
    self.radius = radius;
    self.startAngle = getRadians(startAngle);
    //if (self.startAngle < 0) {
    //    self.startAngle = Math.PI + self.startAngle;
    //}
    //else {
    //    self.startAngle = startAngle;
    //}
    if (antiClockwise === true) {
        self.endAngle = self.startAngle + getRadians(sweep);
    } else {
        self.endAngle = getRadians(sweep) - self.startAngle;
    }
    self.dashed = false;
    self.startPoint = getArcStartPoint(self.startAngle, self.radius, self.centre);
    self.endPoint = getArcStartPoint(self.endAngle, self.radius, self.centre);
    self.antiClockwise = antiClockwise;
    //self.convertStartAngle = startAngle * Math.PI / 180;
    //self.convertEndAngle = sweep * Math.PI / 180;
    self.layerNumber = layerNumber;
    self.blockName = blockName;
    self.objNumSeqNum = objNumSeqNum;
    self.closedIndicator = closedIndicator;
    self.type = 'arc';
    if (objNumSeqNum !== undefined) {
        self.objectNumber = parseInt(objNumSeqNum.substring(0, objNumSeqNum.lastIndexOf("_"))),
        self.sequenceNumber = parseInt(objNumSeqNum.substring(objNumSeqNum.lastIndexOf("_") + 1));
    }
    
    self.drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
        return self;
    }
    self.setDrawingGroupName = setDrawingGroupName;
    
    function getArcStartPoint(startAngle, radius, centrePoint) {
        var adjustedStartAngle;
        var startX, startY;

        

        if (startAngle >= 0 && startAngle < getRadians(90)) {
            //bottom right
            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(90) && startAngle < getRadians(180)) {
            //bottom left
            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y - radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(180) && startAngle < getRadians(270)) {
            // top left
            startX = centrePoint.x - radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
        } else if (startAngle >= getRadians(270) && startAngle <= getRadians(360)) {
            //top right
            startX = centrePoint.x + radius * Math.abs(Math.cos(startAngle));
            startY = centrePoint.y + radius * Math.abs(Math.sin(startAngle));
        }
        
        return { x: startX, y: startY };
    }

    function getRadians(angleDegrees) {
        var angleRadian =  (Math.PI / 180) * angleDegrees;
        if (angleRadian < 0) angleRadian += 2 * Math.PI;
        return angleRadian;
        //Return Math.PI * angleInDegrees / 180.0
    }

    self.setCentre = function(centre) {
        self.centre = { x: centre.x, y: centre.y };
        self.startPoint = getArcStartPoint(self.startAngle, self.radius, self.centre);

    }

    self.setDashed = function(dashedBool) {
        self.dashed = dashedBool;
    }

    self.setColour = function (colour) {
        self.colour = colour;
    }

    self.setFillColour = function (colour) {
        self.fillColour = colour;

        return self;
    }

    self.refresh = function () {
        
        self.startAngle = getRadians(self.startAngle);
        //if (self.startAngle < 0) {
        //    self.startAngle = Math.PI + self.startAngle;
        //}
        //else {
        //    self.startAngle = startAngle;
        //}
        if (self.antiClockwise === true) {
            self.endAngle = self.startAngle + getRadians(sweep);
        } else {
            self.endAngle = getRadians(sweep) - self.startAngle;
        }
        
        self.startPoint = getArcStartPoint(self.startAngle, self.radius, self.centre);
        self.endPoint = getArcStartPoint(self.endAngle, self.radius, self.centre);
        
    }

    self.bypassHitAreaSort = false;
    self.setBypassHitAreaSort = function (newValue) {
        self.bypassHitAreaSort = newValue;
    }
    
    self.setDrawingOrder = function (newValue) {
        self.drawingOrder = newValue;
    }

    self.setBringToFront = function (newValue) {
        self.bringToFront = newValue;
    }

    self.setLineWidth = function (newValue) {
        self.lineWidth = newValue;
    }
};

export default Arc;

       
