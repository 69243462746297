import config from '../../../services/config';



function Accessory(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached,
    accessorySubType, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType) {

    this._id = id;
    this._parent = parent;
    this._name = name;
    this._description = description;
    this._source = source;
    this._horizontalGap = horizontalGap;
    this._verticalGap = verticalGap;
    this._accessoryType = accessoryType;
    this._graphicBlob = graphicBlob || null;
    this._topViewGraphicBlob = topViewGraphicBlob || null;
    this._decompressedGraphicBlob = null;
    this._decompressedTopViewGraphicBlob = null;
    this._accessLevel = config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL;
    this._updateCounter = updateCounter;
    this._sourceDatabaseId = sourceDatabaseId;
    this._editLevel = editLevel;
    this._cached = cached || 2;
    this._accessorySubType = accessorySubType;
    this._costingListPrice = costingListPrice || 0;
    //this._costingListPrice = costingListPrice || 1000;
    this._costingListPriceOverride = costingListPriceOverride || false;
    this._costingDiscount = costingDiscount || 0;
    this._costingPeriod = costingPeriod || 0;
    this._costingInterestRate = costingInterestRate || 0;
    this._costingResidualRate = costingResidualRate || 0;
    this._costingResidualRateType = costingResidualRateType || config.RESIDUAL_RATE_TYPES.NET_PRICE;
    this._savedFromSourceDatabaseId = savedFromSourceDatabaseId || 0;
    
    if (this._accessorySubType !== '' && this._accessorySubType !== undefined && this._accessorySubType !== null && this._accessoryType === config.ACCESSORY_TYPES.OTHER) {
        this._accessorySubType = this._accessorySubType.toLowerCase();
        this._accessorySubType = this._accessorySubType.charAt(0).toUpperCase() + this._accessorySubType.slice(1);
    }


    return this;
}
    
Accessory.prototype.getId = function () {
    return this._id;
};
Accessory.prototype.setId = function (newValue) {
    this._id = newValue;
};
Accessory.prototype.getParent = function () {
    return this._parent;
};
Accessory.prototype.setParent = function (newValue) {
    this._parent = newValue;
};
Accessory.prototype.getName = function () {
    return this._name;
};
Accessory.prototype.setName = function (newValue) {
    this._name = newValue;
};
Accessory.prototype.getDescription = function () {
    return this._description;
};
Accessory.prototype.setDescription = function (newValue) {
    this._description = newValue;
};
Accessory.prototype.getSource = function () {
    return this._source;
};
Accessory.prototype.setSource = function (newValue) {
    this._source = newValue;
};
Accessory.prototype.getHorizontalGap = function () {
    return this._horizontalGap;
};
Accessory.prototype.setHorizontalGap = function (newValue) {
    this._horizontalGap = newValue;
};
Accessory.prototype.getVerticalGap = function () {
    return this._verticalGap;
};
Accessory.prototype.setVerticalGap = function (newValue) {
    this._verticalGap = newValue;
};
Accessory.prototype.getAccessoryType = function () {
    return this._accessoryType;
};
Accessory.prototype.setAccessoryType = function (newValue) {
    this._accessoryType = newValue;
};
Accessory.prototype.getCostingListPrice = function () {
    return this._costingListPrice;
};
Accessory.prototype.setCostingListPrice = function (newValue) {
    this._costingListPrice = newValue;
};
Accessory.prototype.getCostingListPriceOverride = function () {
    return this._costingListPriceOverride;
};
Accessory.prototype.setCostingListPriceOverride = function (newValue) {
    this._costingListPriceOverride = newValue;
};
Accessory.prototype.getCostingDiscount = function () {
    return this._costingDiscount;
};
Accessory.prototype.setCostingDiscount = function (newValue) {
    this._costingDiscount = newValue;
};
Accessory.prototype.getCostingPeriod = function () {
    return this._costingPeriod;
};
Accessory.prototype.setCostingPeriod = function (newValue) {
    this._costingPeriod = newValue;
};
Accessory.prototype.getCostingInterestRate = function () {
    return this._costingInterestRate;
};
Accessory.prototype.setCostingInterestRate = function (newValue) {
    this._costingInterestRate = newValue;
};
Accessory.prototype.getCostingResidualRate = function () {
    return this._costingResidualRate;
};
Accessory.prototype.setCostingResidualRate = function (newValue) {
    this._costingResidualRate = newValue;
};
Accessory.prototype.getCostingResidualRateType = function () {
    return this._costingResidualRateType;
};
Accessory.prototype.setCostingResidualRateType = function (newValue) {
    this._costingResidualRateType = newValue;
};
//Accessory.prototype.getAccessoryTypeForSelectionWizard = function() {
//    if (this.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
//        return this.getAccessoryType() + '-' + this.getAccessorySubType();
//    } else {
//        return this.getAccessoryType();
//    }
//}
Accessory.prototype.getGraphicBlobByElevation = function (elevation) {
    if (elevation === undefined || elevation === config.ELEVATION.SIDE) {
        return this._graphicBlob;
    } else {
        return this._topViewGraphicBlob;
    }
};
Accessory.prototype.getGraphicBlob = function () {
    return this._graphicBlob;
};
Accessory.prototype.setGraphicBlob = function (newValue) {
    this._graphicBlob = newValue;
};
Accessory.prototype.getTopViewGraphicBlob = function () {
    return this._topViewGraphicBlob;
};
Accessory.prototype.setTopViewGraphicBlob = function (newValue) {
    this._topViewGraphicBlob = newValue;
};
Accessory.prototype.getDecompressedGraphicBlobByElevation = function (elevation) {
    if (elevation === undefined || elevation === config.ELEVATION.SIDE) {
        return this._decompressedGraphicBlob;
    } else {
        return this._decompressedTopViewGraphicBlob;
    }
};
Accessory.prototype.getDecompressedGraphicBlob = function () {
    return this._decompressedGraphicBlob;
};
Accessory.prototype.setDecompressedGraphicBlob = function (newValue) {
    this._decompressedGraphicBlob = newValue;
};
Accessory.prototype.getDecompressedTopViewGraphicBlob = function () {
    return this._decompressedTopViewGraphicBlob;
};
Accessory.prototype.setDecompressedTopViewGraphicBlob = function (newValue) {
    this._decompressedTopViewGraphicBlob = newValue;
};
Accessory.prototype.getUpdateCounter = function () {
    return this._updateCounter;
};
Accessory.prototype.setUpdateCounter = function (newValue) {
    this._updateCounter = newValue;
};
Accessory.prototype.getSourceDatabaseId = function () {
    return this._sourceDatabaseId;
};
Accessory.prototype.setSourceDatabaseId = function (newValue) {
    this._sourceDatabaseId = newValue;
};
Accessory.prototype.getEditLevel = function () {
    return this._editLevel;
}
Accessory.prototype.setEditLevel = function (newValue) {
    this._editLevel = newValue;
}
Accessory.prototype.getCached = function () {
    return this._cached;
};
Accessory.prototype.setCached = function (newValue) {
    this._cached = newValue;
};
Accessory.prototype.getDescriptionForDrawing = function () {
    return this._description.length > 30 ? this._description.slice(0, 30) + '...' : this._description;
};
Accessory.prototype.setAccessLevel = function (newValue) {
    this._accessLevel = newValue;
};
Accessory.prototype.getAccessLevel = function () {
    return this._accessLevel;
};
Accessory.prototype.getAccessorySubType = function () {
    return this._accessorySubType;
};
Accessory.prototype.setAccessorySubType = function (newValue) {
    this._accessorySubType = newValue;
};
Accessory.prototype.getSavedFromSourceDatabaseId = function () {
    return this._savedFromSourceDatabaseId;
}
Accessory.prototype.setSavedFromSourceDatabaseId = function (newValue) {
    this._savedFromSourceDatabaseId = newValue;
}
/**
 * Get JSON object to be saved to database
 * @param {object} details - Object to be updated and saved to database
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Remove position values from object.
 * @returns {object}
 */
Accessory.prototype.getSaveObject = function (details, includePositionValues) {
    details.Name = this._name;
    details.Description = this._description;
    details.Source = this._source;
    details.ParentType = this._parent;
    if (includePositionValues === true) {
        details.CabGap = this._horizontalGap;
        details.VerticalGap = this._verticalGap;
    }
    //details.CabGap = includePositionValues === true ? this._horizontalGap : 0;
    //details.VerticalGap = includePositionValues === true ? this._verticalGap : 0;
    if (this._graphicBlob !== null) {
        if (this._accessoryType === config.ACCESSORY_TYPES.BODY) {
            details.Graphic = this._graphicBlob;
        } else {
            details.GraphicBlob = this._graphicBlob;
        }

    }
    if (this._topViewGraphicBlob !== null) {
        //if (this._accessoryType === config.ACCESSORY_TYPES.BODY) {
        //    details.TopViewGraphic = this._topViewGraphicBlob;
        //} else {
        details.TopViewGraphicBlob = this._topViewGraphicBlob;
        //}

    }

    details.CostingListPrice = this._costingListPrice;
    details.CostingListPriceOverride = this._costingListPriceOverride;
    details.CostingDiscount = this._costingDiscount;
    details.CostingPeriod = this._costingPeriod;
    details.CostingInterestRate = this._costingInterestRate;
    details.CostingResidualRate = this._costingResidualRate;
    details.CostingResidualRateType = this._costingResidualRateType;

    details.UpdateCounter = this._updateCounter;
    details.SourceDatabaseId = (this._sourceDatabaseId + '').indexOf('unknown') !== -1 ? 0 : this._sourceDatabaseId;
    details.AccessoryType = this._accessoryType;
    details.AccessLevel = this._accessLevel;
    return details;
};
Accessory.prototype.getIntercomMetadata = function (chassisType) {
    var returnObject = {};
    returnObject.Description = this._description;
    returnObject.Type = this._accessoryType;
    if (typeof this._accessorySubType === 'string') {
        returnObject.SubType = this._accessorySubType;
    } else {
        returnObject.SubType = this._type;
    }

    returnObject.Source = this._source;
    //returnObject.ChassisType = chassisType !== undefined ? chassisType : 'Chassis Type Not Provided';
    returnObject.ChassisType = this._parent;

    return returnObject;
};

Accessory.prototype.updateBase = function (accessory) {

    this.setParent(accessory.getParent());
    this.setName(accessory.getName());
    this.setDescription(accessory.getDescription());
    this.setSource(accessory.getSource());
    this.setHorizontalGap(accessory.getHorizontalGap());
    this.setVerticalGap(accessory.getVerticalGap());
    this.setAccessoryType(accessory.getAccessoryType());
    this.setGraphicBlob(accessory.getGraphicBlob());
    this.setTopViewGraphicBlob(accessory.getTopViewGraphicBlob());
    this.setAccessLevel(accessory.getAccessLevel());
    this.setUpdateCounter(accessory.getUpdateCounter());
    this.setSourceDatabaseId(accessory.getSourceDatabaseId());
    this.setEditLevel(accessory.getEditLevel());
    this.setCached(accessory.getCached());
    this.setSavedFromSourceDatabaseId(accessory.getSavedFromSourceDatabaseId());
};

Accessory.prototype.getPath = function () {
    var path = null;

    if (this._parent !== undefined) {
        path = this._parent;
        switch (this._accessoryType) {
            case config.ACCESSORY_TYPES.BODY:
            case config.ACCESSORY_TYPES.PAYLOAD:
                path += '.' + this._accessoryType;
                break;
            case config.ACCESSORY_TYPES.TRAILER:
                // do nothing as _parent is the path in this case
                break;
            default:
                path += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + this._accessoryType;
                break;
        }
    }

    return path;
};

export default Accessory;
