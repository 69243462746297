import config from '../../services/config';

/**
 * Allows user to create a view to be added to the report
 * @param {any} init - Initial report view object from cloud services/local storage
 * @returns {object} - Report View object
 */
function ReportView(init) {

    var reportView = this;
    var _id = init.Order,
        _viewType = init.ViewType,
        _viewDetail = init.ViewDetail,
        _elevation = init.Elevation,
        _display = init.Display,
        _order = init.Order,
        _isActive = init.IsActive;

    // #region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getViewType() {
        return _viewType;
    }
    function setViewType(newValue) {
        _viewType = newValue;
    }
    function getViewDetail() {
        return _viewDetail;
    }
    function setViewDetail(newValue) {
        _viewDetail = newValue;
    }
    function getElevation() {
        return _elevation;
    }
    function setElevation(newValue) {
        _elevation = newValue;
    }
    function getDisplay() {
        return _display;
    }
    function setDisplay(newValue) {
        _display = newValue;
    }
    function getOrder() {
        return _order;
    }
    function setOrder(newValue) {
        _order = newValue;
    }
    function getIsActive() {
        return _isActive;
    }
    function setIsActive(newValue) {
        _isActive = newValue;
    }
    // #endregion Getters and setters
    
    function getObjectToBeSaved() {
        var objectToBeSaved = {};
        objectToBeSaved.ViewType = reportView.getViewType();
        objectToBeSaved.ViewDetail = reportView.getViewDetail();
        objectToBeSaved.Elevation = reportView.getElevation();
        objectToBeSaved.Display = reportView.getDisplay();
        objectToBeSaved.Order = reportView.getOrder();
        objectToBeSaved.IsActive = reportView.getIsActive();

        return objectToBeSaved;
    }

    function getTextForSummaryPage(fuelType) {
        if(reportView.getViewDetail() === config.VIEW_DETAIL.BRIDGE_VIEW) {
            return `${getViewDetailText()} - ${getElevationText()} - ${getDisplayText()}`;
        } else {
            return `${getViewTypeText()} - ${getViewDetailText(fuelType)} - ${getElevationText()} - ${getDisplayText()}`;
        }
    }

    function getViewTypeText() {
        switch(reportView.getViewType()) {
            case config.VIEW_TYPE.OVERVIEW:
                return config.getTranslationText('4770');
            case config.VIEW_TYPE.COG:
                return config.getTranslationText('4771');
            case config.VIEW_TYPE.TURNING_CIRCLE:
                return config.getTranslationText('4772');
            default:
                return reportView.getViewType();
        }
    }

    function getViewDetailText(fuelType) {
        switch(reportView.getViewDetail()) {
            case config.VIEW_DETAIL.SUMMARY:
                return config.getTranslationText('4773');
            case config.VIEW_DETAIL.VEHICLE:
                return config.getTranslationText('4774');
            case config.VIEW_DETAIL.CHASSIS:
                return config.getTranslationText('4775');
            case config.VIEW_DETAIL.EXTRAS:
                if(fuelType === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                    return config.getTranslationText('4788');
                } else {
                    return config.getTranslationText('4776');
                }
            case config.VIEW_DETAIL.TRAILER1:
                return config.getTranslationText('4777');
            case config.VIEW_DETAIL.TRAILER2:
                return config.getTranslationText('4778');
            case config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION:
                return config.getTranslationText('4779');
            case config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST:
                return config.getTranslationText('4780');
            case config.VIEW_DETAIL.BRIDGE_VIEW:
                return config.getTranslationText('4785');
            default:
                return reportView.getViewDetail();
        }
    }

    function getElevationText() {
        switch(reportView.getElevation()) {
            case config.ELEVATION.SIDE:
                return config.getTranslationText('4781');
            case config.ELEVATION.TOP:
                return config.getTranslationText('4782');
            default:
                return reportView.getElevation();
        }
    }

    function getDisplayText() {
        switch(reportView.getDisplay()) {
            case config.DISPLAY_TYPE.DETAILED:
                return config.getTranslationText('4783');
                case config.DISPLAY_TYPE.SIMPLIFIED:
                    return config.getTranslationText('4784');
            default:
                return reportView.getDisplay();
        }
    }

    reportView.getId = getId;
    reportView.setId = setId;
    reportView.getViewType = getViewType;
    reportView.setViewType = setViewType;
    reportView.getViewDetail = getViewDetail;
    reportView.setViewDetail = setViewDetail;
    reportView.getElevation = getElevation;
    reportView.setElevation = setElevation;
    reportView.getDisplay = getDisplay;
    reportView.setDisplay = setDisplay;
    reportView.getOrder = getOrder;
    reportView.setOrder = setOrder;
    reportView.getIsActive = getIsActive;
    reportView.setIsActive = setIsActive;
    reportView.getObjectToBeSaved = getObjectToBeSaved;
    reportView.getTextForSummaryPage = getTextForSummaryPage;
    reportView.getViewTypeText = getViewTypeText;
    reportView.getViewDetailText = getViewDetailText;
    reportView.getElevationText = getElevationText;
}

export default ReportView;