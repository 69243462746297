<template>
    <div class="menu-select-div" data-bind="template: { name: 'template-settings-tel-element' }">
        <input :value="tel.obvFormatted.value"
            :id="tel.id"
            :title="tel.errorMessage.value"
            :data-original-title="tel.errorMessage.value"
            @focus="tel.selectAllText"
            @blur="tel.blurFunction"
            @keyup.enter="handleEnterKey"
            class="form-control login-input menu-input menu-input-number menu-input-50 default-width-tel" 
            type="tel" 
            data-bind="value: obvFormatted, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, title: errorMessage, 'data-original-title': errorMessage }"
        />
    </div>

    <!-- <script type="text/html" id="template-settings-tel-element">
        <input class="form-control login-input menu-input menu-input-number menu-input-50 default-width-tel" type="tel" data-bind="value: obvFormatted, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, title: errorMessage, 'data-original-title': errorMessage }" />
    </script> -->
</template>

<script>
import { toRef, nextTick } from 'vue';

const $ = window.$;

export default {
    name: 'MenuTableCellInputTel',
    props: {
        cellObjectFromParent: Object
    },
    setup (props) {
        const tel = toRef(props, "cellObjectFromParent");

        function handleEnterKey(tab) {
            $('#' + tel.value.id).blur();
            // nextTick(function(){
            //  $('#' + tel.value.id).val(tel.value.obvFormatted.value);
            // });
        }

        return {
            tel,
            handleEnterKey
        }
    },
}
</script>

<style lang="scss" scoped>

</style>