<template>
    <tr class="menu-divider">
        <td colspan="2" :class="divider.customCss">
            <div></div>
        </td>
    </tr>
    <!-- <div>
        'template-menu-divider'
    </div> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.DIVIDER 
    <tr class="menu-divider" data-bind="template: {name:'template-menu-divider'}"></tr>
     /ko 
    </script> -->

    <!-- <script type="text/html" id="template-menu-divider">
    <td colspan="2" data-bind="css: customCss">
        <div></div>
    </td>
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const divider = toRef(props, "valueFromParent");

        return {
            divider
        }
    }
}
</script>

<style lang="scss" scoped>
</style>