import utils from '../../../services/utils';
import SummaryReportOfferOption from './model.reportofferoption'


function SummaryReportOption(code, isChecked) {
    SummaryReportOfferOption.call(this, code, isChecked);
    this._reportOfferOptions = [];
    
    return this;
}

SummaryReportOption.prototype.getSummaryReportOfferOptions = function () {
    return this._reportOfferOptions;
};
SummaryReportOption.prototype.setSummaryReportOfferOptions = function (newValue) { this._reportOfferOptions = newValue; };
SummaryReportOption.prototype.getSummaryReportOfferOptionUsingCode = function (code) {
    var codeToUse = typeof code === 'object' ? code.Code : code;
    var summaryReportOfferOptionToReturn = this._reportOfferOptions.find(function (reportOfferOption) {
        if (reportOfferOption.getCode() === codeToUse) {
            return true;
        }
    });
    return summaryReportOfferOptionToReturn !== undefined ? summaryReportOfferOptionToReturn : null;
};

SummaryReportOption.prototype.createSummaryReportOfferOptions = function (newValue) {
    var self = this;
    if (Array.isArray(newValue)) {
        newValue.forEach(function (itemToAdd) {
            self._reportOfferOptions.push(new SummaryReportOfferOption(itemToAdd.Code, typeof itemToAdd.IsChecked === 'boolean' ? itemToAdd.IsChecked : false));
        });
    } else {
        this._reportOfferOptions.push(new SummaryReportOfferOption(newValue.code, newValue.isChecked))
    }
};

SummaryReportOption.prototype.areAllReportOfferOptionsUnchecked = function () {
    var self = this,
        returnValue = true;

    this._reportOfferOptions.forEach(function (reportOfferOption) {
        if (reportOfferOption.getIsChecked() === true) {
            returnValue = false;
        }
    });

    return returnValue;
};

utils.extend(SummaryReportOfferOption, SummaryReportOption);

export default SummaryReportOption;
    