<template>
    <div class="configuration-wizard-input-container flex-item">
        <div v-if="activeWizardStep.stepIncludesMenuSection === true" 
            class="configuration-wizard-input-container-div">
            <!-- <div data-bind="template: {name: 'template-menu', data: menuToIncludeOnStep }"></div> -->
            <MenuContainer :menuFromParent="activeWizardStep.menuToIncludeOnStep"></MenuContainer>
        </div>
    </div>
    
    <!-- <script type="text/html" id="template-itemConfigurationWizardModal-input-container">
        ko if: stepIncludesMenuSection
        <div class="configuration-wizard-input-container-div">
            <div data-bind="template: {name: 'template-menu', data: menuToIncludeOnStep }"></div>
        </div>
        /ko
    </script> -->
</template>

<script>
import { toRef, onUpdated } from 'vue';

export default {
    props: {
        activeWizardStepFromParent: Object
    },
    setup (props) {
        const activeWizardStep = toRef(props, "activeWizardStepFromParent");

        onUpdated(() => {
            if(typeof activeWizardStep.value.activateWizardStepCallback === 'function') {
                activeWizardStep.value.activateWizardStepCallback()
            }
        })

        return {
            activeWizardStep
        }
    }
}
</script>

<style lang="scss" scoped>

</style>