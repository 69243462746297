// import { config } from '../services';



function PermissionObject() {
    var self = this;

    var visiblePermissionFunc;
    var overridePermissionFunc;
    var readOnlyPermissionFunc;
    var visibleDependencyFunc;
    var overrideDependencyFunc;
    var readOnlyDependencyFunc;

    var overridePermissionVal = false;
    var readOnlyPermissionVal = false;
    var visiblePermissionVal = true;


    function permission(path) {
        return {
            override: evaluateOverridePermission(path),
            readOnly: evaluateReadOnlyPermission(path),
            visible: eveluateVisiblePermission(path)
        }
    }

    function evaluateOverridePermission(path) {
        var val = evaluatePermission(overridePermissionFunc, overrideDependencyFunc, overridePermissionVal, path);

        //if (val === false) {
        //    self.override(true);
        //}

        return val;
    }

    function evaluateReadOnlyPermission(path) {
        var val = evaluatePermission(readOnlyPermissionFunc, readOnlyDependencyFunc, readOnlyPermissionVal, path);

        //if (val === true) {
        //    if (self.override) {
        //        self.override(false);
        //    }
        //}

        //return evaluatePermission(readOnlyPermissionFunc, readOnlyDependencyFunc, readOnlyPermissionVal, path);
        return val;
    }

    function eveluateVisiblePermission(path) {
        //var resultFromPermissionFunc, resultFromDependencyFunc, functionResult;

        //resultFromPermissionFunc = visiblePermissionFunc !== undefined ? visiblePermissionFunc() : undefined;
        //resultFromDependencyFunc = visibleDependencyFunc !== undefined ? visibleDependencyFunc() : undefined;

        //if (resultFromDependencyFunc !== undefined && resultFromPermissionFunc !== undefined) {
        //    functionResult = resultFromDependencyFunc && resultFromPermissionFunc;
        //} else if (resultFromDependencyFunc !== undefined) {
        //    functionResult = resultFromDependencyFunc;
        //} else if (resultFromPermissionFunc !== undefined) {
        //    functionResult = resultFromPermissionFunc;
        //}

        //return functionResult !== undefined ? functionResult : visiblePermissionVal;
        return evaluatePermission(visiblePermissionFunc, visibleDependencyFunc, visiblePermissionVal, path);
    }

    function evaluatePermission(permissionFunc, dependencyFunc, permissionVal, path) {
        var resultFromPermissionFunc, resultFromDependencyFunc, functionResult;

        resultFromPermissionFunc = permissionFunc !== undefined ? permissionFunc(path) : undefined;
        resultFromDependencyFunc = dependencyFunc !== undefined ? dependencyFunc(path) : undefined;

        if (resultFromDependencyFunc !== undefined && resultFromPermissionFunc !== undefined) {
            functionResult = resultFromDependencyFunc && resultFromPermissionFunc;
        } else if (resultFromDependencyFunc !== undefined) {
            functionResult = resultFromDependencyFunc;
        } else if (resultFromPermissionFunc !== undefined) {
            functionResult = resultFromPermissionFunc;
        }

        return functionResult !== undefined ? functionResult : permissionVal;
    }

    function readOnly(val) {
        readOnlyPermissionVal = val !== undefined ? val : true;

        return self;
    }

    function readOnlyPermission(func) {
        readOnlyPermissionFunc = func;

        return self;
    }

    // function readOnlyDependency(dependencyPath, comparisonOperator, compareVal) {
    //     readOnlyDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    //     return self;
    // }

    function overridePermission(func) {
        overridePermissionFunc = func;

        return self;
    }

    // function overrideDependency(dependencyPath, comparisonOperator, compareVal) {
    //     overrideDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    //     return self;
    // }

    function visible(val) {
        visiblePermissionVal = val !== undefined ? val : true;

        return self;
    }

    function visiblePermission(func) {
        visiblePermissionFunc = func;

        return self;
    }

    // function visibleDependency(dependencyPath, comparisonOperator, compareVal) {
    //     visibleDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    //     return self;
    // }


    // function getFunction(dependencyPath, comparisonOperator, compareVal) {
    //     var val1;
    //     if (inputType === config.INPUT_TYPE.SELECT) {
    //         val1 = self.offerManager.getAttributeGroup(dependencyPath).optionId;
    //     } else {
    //         val1 = self.offerManager.getAttributeGroup(dependencyPath).value;
    //     }

    //     switch (comparisonOperator) {
    //         case '===':
    //             return function () { return equalTo(val1(), compareVal) };
    //         case '>':
    //             return function () { return greaterThan(val1(), compareVal) };
    //         case '<':
    //             return function () { return lessThan(val1(), compareVal) };
    //         case '>=':
    //             return function () { return greaterThanOrEqualTo(val1(), compareVal) };
    //         case '<=':
    //             return function () { return lessThanOrEqualTo(val1(), compareVal) };
    //     }
    // }

    function equalTo(val1, val2) {
        return val1 === val2;
    }

    function greaterThan(val1, val2) {
        return val1 > val2;
    }

    function lessThan(val1, val2) {
        return val1 < val2;
    }

    function greaterThanOrEqualTo(val1, val2) {
        return val1 >= val2;
    }

    function lessThanOrEqualTo(val1, val2) {
        return val1 <= val2;
    }

    function cleanUp() {
        

        visiblePermissionFunc = null;
        overridePermissionFunc = null;
        readOnlyPermissionFunc = null;
        visibleDependencyFunc = null;
        overrideDependencyFunc = null;
        readOnlyDependencyFunc = null;

        overridePermissionVal = null;
        readOnlyPermissionVal = null;
        visiblePermissionVal = null;

        self = null;
    }

    self.readOnly = readOnly;
    self.readOnlyPermission = readOnlyPermission;
    // self.readOnlyDependency = readOnlyDependency;
    self.overridePermission = overridePermission;
    // self.overrideDependency = overrideDependency;
    self.visible = visible;
    self.visiblePermission = visiblePermission;
    // self.visibleDependency = visibleDependency;
    self.permission = permission;
    self.cleanUp = cleanUp;
}

export default PermissionObject;

