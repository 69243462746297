import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '@/services';
import Globalize from '@/assets/scripts/globalize/globalize';
import { computed } from 'vue';



var SpecificationAttribute = function (headingCode, value) {
    var self = this;

    //#region Private members
    var _headingCode,
        _value;
    //#endregion Private members

    //#region Getters and setters
    function getHeadingCode() {
        return _headingCode;
    }
    function setHeadingCode(newValue) {
        _headingCode = newValue;
    }
    function getValue() {
        return _value;
    }
    function setValue(newValue) {
        _value = newValue;
    }
    function getValueFormatted(unit, measurementSystemType) {
        var measurementSystem;

        if (/[a-z]/i.test(getValue()) || getValue() === '-' || unit === '') {
            switch (getHeadingCode()) {
                case 'NOOFSEATS':
                case 'NOOFAIRBAGS':
                case 'TRANSNOOFGEARSF':
                    if (isNaN(globals.convertToNumber(getValue())) === false) {
                        return Globalize.format(globals.convertToNumber(getValue()), "n0");
                    } else {
                        return getValue();
                    }
                default:
                    return getValue();
            }
            
        }

        switch (measurementSystemType) {
            case config.MEASUREMENT_SYSTEM.METRIC:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC);
                break;
            case config.MEASUREMENT_SYSTEM.IMPERIALUK:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK);
                break;
            case config.MEASUREMENT_SYSTEM.IMPERIALUS:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
                break;                
            default:
                switch (unit) {
                    case '%':
                        return Globalize.format(getValue());
                    default:
                        return getValue();
                }
                //return getValue();
        }
        switch (unit) {
            case 'kg':
            case 'lb':
                // convert to lbs
                //return Globalize.format(globals.convertToMass(getValue(), measurementSystemType), "n0");
                return Globalize.format(globals.convertToMass(getValue(), measurementSystemType), "n" + measurementSystem.massPrecisionForSpecification);
            case 'mm':
            case 'in':
                // convert to in
                //return Globalize.format(globals.convertToLength(getValue(), measurementSystemType), "n0");
                return Globalize.format(globals.convertToLength(getValue(), measurementSystemType), "n" + measurementSystem.lengthPrecisionForSpecification);
            case 'ltrs':
                // convert to gal
                //return Globalize.format(globals.convertToVolume(getValue(), measurementSystemType), "n0");
                return Globalize.format(globals.convertToVolume(getValue(), measurementSystemType), "n" + + measurementSystem.volumePrecisionForSpecification);
            case 'km/h':
                // convert to gal
                //return Globalize.format(globals.convertToSpeed(getValue(), measurementSystemType), "n0");
                return Globalize.format(globals.convertToSpeed(getValue(), measurementSystemType), "n" + measurementSystem.speedPrecisionForSpecification);
            case '%':
                //return Globalize.format(getValue());
                return Globalize.format(globals.convertToNumber(getValue()), "n1");
            case 'cm3':
                return Globalize.format(globals.convertToNumber(getValue()), "n0");
            case 'kW':
            case 'Nm':
                return globals.convertRpmValue(getValue());
            default:
                return getValue();
        }
    }
    //#region Getters and setters

    //#region Initializers
    setHeadingCode(headingCode);
    setValue(value);
    //#endregion Initializers

    //#region Public members
    self.getHeadingCode = getHeadingCode;
    self.setHeadingCode = setHeadingCode;
    self.getValue = getValue;
    self.setValue = setValue;
    self.getValueFormatted = getValueFormatted;
    //#endregion Public members

    //NOTE: This should be replaced by the getters and setters
    //properties
    self.headingCode = headingCode;
    self.value = value;
    self.valueFormatted = computed(function () {
        return Globalize.format(value, "n0");
    });
};

export default SpecificationAttribute;
