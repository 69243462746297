
var Customer = function (id, contactName, company, mobile, directNumber, email, updateCounter, overallUpdateCounter) {
    var self = this;

    //#region Internal properties
    var _id,
        _contactName,
        _company,
        _mobile,
        _directNumber,
        _email,
        _updateCounter,
        _overallUpdateCounter;
    //#endregion Internal properties

    //#region getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getContactName() {
        return _contactName;
    }
    function setContactName(newValue) {
        _contactName = newValue;
    }
    function getCompany() {
        return _company;
    }
    function setCompany(newValue) {
        _company = newValue;
    }
    function getMobile() {
        return _mobile;
    }
    function setMobile(newValue) {
        _mobile = newValue;
    }
    function getDirectNumber() {
        return _directNumber;
    }
    function setDirectNumber(newValue) {
        _directNumber = newValue;
    }
    function getEmail() {
        return _email;
    }
    function setEmail(newValue) {
        _email = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getOverallUpdateCounter() {
        return _overallUpdateCounter;
    }
    function setOverallUpdateCounter(newValue) {
        _overallUpdateCounter = newValue;
    }
    function getCustomerDetails() {
        return {
            Id: _id,
            ContactName: _contactName,
            Company: _company,
            Mobile: _mobile,
            DirectNumber: _directNumber,
            Email: _email,
            UpdateCounter: _updateCounter,
            OverallUpdateCounter: _overallUpdateCounter
        }
    }
    function clear() {
        setId(0);
        setContactName('');
        setCompany('');
        setMobile('');
        setDirectNumber('');
        setEmail('');
        setUpdateCounter(0);
        setOverallUpdateCounter(0);
    }
    //#endregion getters and setters

    //#region Initialize values
    setId(id || 0);
    setContactName(contactName || '');
    setCompany(company || '');
    setMobile(mobile || '');
    setDirectNumber(directNumber || '');
    setEmail(email || '');
    setUpdateCounter(updateCounter || 0);
    setOverallUpdateCounter(overallUpdateCounter || 0);
    //#endregion Initialize values

    //#region Public members
    self.getId = getId;
    self.setId = setId;
    self.getContactName = getContactName;
    self.setContactName = setContactName;
    self.getCompany = getCompany;
    self.setCompany = setCompany;
    self.getMobile = getMobile;
    self.setMobile = setMobile;
    self.getDirectNumber = getDirectNumber;
    self.setDirectNumber = setDirectNumber;
    self.getEmail = getEmail;
    self.setEmail = setEmail;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getOverallUpdateCounter = getOverallUpdateCounter;
    self.setOverallUpdateCounter = setOverallUpdateCounter;
    self.clear = clear;
    self.getCustomerDetails = getCustomerDetails;
    //#endregion Public members
};

export default Customer;
