<template>
    <div class="menu-tab-group-button-container" 
        data-bind="with: $parent.menuTabGroupButton">
        <button class="btn btn-default btn-truckscience"
            @click="button.clickFunction"
            v-visible="button.isVisible()"
            data-bind="click: clickFunction, touchstart: clickFunction, visible: isVisible()">
            <span 
                :title="button.buttonText"
                data-bind="text: buttonText, attr: { title: buttonText }">
                {{button.buttonText}}    
            </span>
        </button>
    </div>
</template>

<script>
import { toRef } from 'vue';

export default {
    props: {
        buttonFromParent: Object
    },
    setup(props) {
        const button = toRef(props, "buttonFromParent");

        return {
            button
        };
    },
}
</script>

<style lang="scss" scoped>

</style>