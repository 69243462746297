import config from '../../services/config';
import globals from '../../services/globals';
import utils from '../../services/utils';
// import { globals, config } from '../../services';
import Configuration from './configuration/model.configuration';
import Costing from './costing/model.costing';
import Performance from './performance/model.performance';
import Specification from './specification/model.specification';
import Rig from './configuration/model.rig';
import SummaryReportOption from './summary/model.reportoption';
// import { Configuration, Costing, Performance, Specification, Rig, SummaryReportOption } from '../../model/offer';
import ShareUser from '../../model/user/model.shareUser';



var Offer = function (offer) {
    var self = this;

    //#region private members
    var _id,
        _updateCounter,
        _configuration,
        _costing,
        _performance,
        _specification,
        _vehicleId,
        _vehicleName,
        _vehicleModel,
        _vehicleModelCode,
        _vehicleMake,
        _vehicleRange,
        _vehicleGVW,
        _vehiclePayload,
        _vehiclePower,
        _vehicleAxleLayout,
        _vehiclePlusBodyImageURL,
        _bodyType,
        _bodyManufacturer,
        _vehicleDistributionGroupId,
        _vehicleDistributionOptionId,
        _customer,
        _selectedCompetitor1Id,
        _selectedCompetitor2Id,
        _summaryNote,
        // _onRoadCostsVersion,
        _additionalNote,
        _measurementSystem,
        _legislation,
        _lengthIncrement,
        _massIncrement,
        _percentageIncrement,
        _application,
        _dataAvailability,
        _description,
        _additionalDescription,
        _preparedForName,
        _preparedForTelephone,
        _applyHigherMassLimits,
        _keepChassisFludhWithBody,
        _specDate,
        _rig,
        _trailer1,
        _trailer2,
        _salesPersonId,
        _summaryReportOptions = [],
        _countryId,
        _rangeId,
        _vehicleTypeName,
        _vehicleTypeNameCode,
        _subCategory1,
        _subCategory1Code,
        _subCategory2,
        _subCategory2Code,
        _status,
        _isSharedOffer,
        _lastModifiedByUserId,
        _lastModifiedByUserFirstName,
        _lastModifiedByUserLastName,
        _lastModifiedByUserEmail,
        _sharerUserInfo,
        _shareeUserInfoArray = [],
        _displayTransSolveWarningsMessage = false,
        _transSolveWarningsArray = [],
        _isFromTSV = false,
        _isSupportOffer = false,
        _supportUserId = 0
    ;
    //#endregion private members

    //#region getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getSalesPersonId() {
        return _salesPersonId;
    }
    function setSalesPersonId(newValue) {
        _salesPersonId = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getConfiguration() {
        return _configuration;
    }
    function setConfiguration(newValue) {
        _configuration = newValue;
    }
    function getCosting() {
        return _costing;
    }
    function setCosting(newValue) {
        _costing = newValue;
    }
    function getPerformance() {
        return _performance;//!== undefined ? _performance : {};
    }
    function setPerformance(newValue) {
        _performance = newValue;
    }
    function getSpecification() {
        return _specification;
    }
    function setSpecification(newValue) {
        _specification = newValue;
    }
    function getVehicleId() {
        return _vehicleId;
    }
    function setVehicleId(newValue) {
        _vehicleId = newValue;
    }
    function getVehicleName() {
        return _vehicleName;
    }
    function setVehicleName(newValue) {
        _vehicleName = newValue;
    }
    function getVehicleModel() {
        return _vehicleModel;
    }
    function setVehicleModel(newValue) {
        _vehicleModel = newValue;
    }
    function getVehicleModelCode() {
        return _vehicleModelCode;
    }
    function setVehicleModelCode(newValue) {
        _vehicleModelCode = newValue;
    }
    function getVehicleMake() {
        return _vehicleMake;
    }
    function setVehicleMake(newValue) {
        _vehicleMake = newValue;
    }
    function getVehicleRange() {
        return _vehicleRange;
    }
    function setVehicleRange(newValue) {
        _vehicleRange = newValue;
    }
    function getVehicleGVW() {
        return _vehicleGVW;
    }
    function setVehicleGVW(newValue) {
        _vehicleGVW = newValue;
    }
    function getVehiclePayload() {
        return _vehiclePayload;
    }
    function setVehiclePayload(newValue) {
        _vehiclePayload = newValue;
    }
    function getVehiclePower() {
        return _vehiclePower;
    }
    function setVehiclePower(newValue) {
        _vehiclePower = newValue;
    }
    function getVehicleAxleLayout() {
        return _vehicleAxleLayout;
    }
    function setVehicleAxleLayout(newValue) {
        _vehicleAxleLayout = newValue;
    }
    function getVehiclePlusBodyImageURL() {
        return _vehiclePlusBodyImageURL;
    }
    function setVehiclePlusBodyImageURL(newValue) {
        _vehiclePlusBodyImageURL = newValue;
    }
    function getBodyType() {
        return _bodyType;
    }
    function setBodyType(newValue) {
        _bodyType = newValue;
    }
    function getBodyManufacturer() {
        return _bodyManufacturer;
    }
    function setBodyManufacturer(newValue) {
        _bodyManufacturer = newValue;
    }
    function getVehicleDistributionGroupId() {
        return _vehicleDistributionGroupId;
    }
    function setVehicleDistributionGroupId(newValue) {
        _vehicleDistributionGroupId = newValue;
    }
    function getVehicleDistributionOptionId() {
        return _vehicleDistributionOptionId;
    }
    function setVehicleDistributionOptionId(newValue) {
        _vehicleDistributionOptionId = newValue;
    }
    function getCountryId() {
        return _countryId;
    }
    function setCountryId(newValue) {
        _countryId = newValue;
    }
    function getRangeId() {
        return _rangeId;
    }
    function setRangeId(newValue) {
        _rangeId = newValue;
    }
    function getCustomer() {
        return _customer;
    }
    function setCustomer(newValue) {
        _customer = newValue;
    }
    function getSelectedCompetitor1Id() {
        return _selectedCompetitor1Id;
    }
    function setSelectedCompetitor1Id(newValue) {
        _selectedCompetitor1Id = newValue;
    }            
    function getSelectedCompetitor2Id() {
        return _selectedCompetitor2Id;
    }
    function setSelectedCompetitor2Id(newValue) {
        _selectedCompetitor2Id = newValue;
    }
    function getSummaryNote() {
        return _summaryNote;
    }
    function setSummaryNote(newValue) {
        _summaryNote = newValue;
    }
    // function getOnRoadCostsVersion() {
    //     return _onRoadCostsVersion;
    // }
    // function setOnRoadCostsVersion(newValue) {
    //     _onRoadCostsVersion = newValue;
    // }
    function getAdditionalNote() {
        return _additionalNote;
    }
    function setAdditionalNote(newValue) {
        _additionalNote = newValue;
    }
    function getAdditionalNoteForDrawing() {
        return getAdditionalNote().replace(/\n/g, ' ').trim();
    }
    function getMeasurementSystem() {
        return _measurementSystem;
    }
    function setMeasurementSystem(newValue) {
        _measurementSystem = newValue;
    }
    function getMeasurementSystemId() {
        return getMeasurementSystem().id;
    }
    function getLegislation() {
        return _legislation;
    }
    function setLegislation(newValue) {
        _legislation = newValue;
    }
    function getLegislationId() {
        return getLegislation().id;
    }
    function getLengthIncrement() {
        return _lengthIncrement;
    }
    function setLengthIncrement(newValue) {
        //_lengthIncrement = Math.round(newValue);
        _lengthIncrement = newValue;
    }
    function getMassIncrement() {
        return _massIncrement;
    }
    function setMassIncrement(newValue) {
        //_massIncrement = Math.round(newValue);
        _massIncrement = newValue;
    }
    function getPercentageIncrement() {
        return _percentageIncrement;
    }
    function setPercentageIncrement(newValue) {
        _percentageIncrement = newValue;
    }
    function getApplication() {
        return _application;
    }
    function setApplication(newValue) {
        _application = newValue;
    }
    function getApplicationId() {
        return getApplication().id;
    }
    function getApplicationTypeCode() {
        var applicationType = getApplication();
        switch (applicationType.id) {
            case config.VEHICLE_APPLICATION_TYPES.ONROAD:
                return 'ONROAD';
            case config.VEHICLE_APPLICATION_TYPES.ONANDOFFROAD:
                return 'ONANDOFFROAD';
            case config.VEHICLE_APPLICATION_TYPES.OFFROADONLY:
                return 'OFFROADONLY';
            case config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT:
                return 'ABNORMALPERMIT';
            case config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT:
                return 'HML';
            case config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT:
                return 'CML';
            default:
                return 'ONROAD';
        }
    }
    function setDataAvailability(newValue) {
        _dataAvailability = newValue;
    }
    function getDataAvailability() {
        return _dataAvailability;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getDescription() {
        return _description;
    }
    function getOverallDescription() {
        if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code) === true) {
            return getDescription();
        } else {
            return getDescription() + ' ' + getAdditionalDescription();
        }
    }
    function setAdditionalDescription(newValue) {
        _additionalDescription = newValue;
    }
    function getAdditionalDescription() {
        return _additionalDescription;
    }
    function getPreparedForName() {
        return _preparedForName;
    }
    function setPreparedForName(newValue) {
        _preparedForName = newValue;
    }
    function getPreparedForTelephone() {
        return _preparedForTelephone;
    }
    function setPreparedForTelephone(newValue) {
        _preparedForTelephone = newValue;
    }
    function getApplyHigherMassLimits() {
        return _applyHigherMassLimits;
    }
    function setApplyHigherMassLimits(newValue) {
        _applyHigherMassLimits = newValue;
    }
    function getApplyConcessionalMassLimits() {
        return getApplication().id === config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT;
    }
    function getKeepChassisFlushWithBody() {
        return _keepChassisFludhWithBody;
    }
    function setKeepChassisFlushWithBody(newValue) {
        _keepChassisFludhWithBody = newValue;
    }
    function getSpecDate() {
        return _specDate;
    }
    function setSpecDate(newValue) {
        _specDate = newValue;
    }
    function setIsSharedOffer(isSharedOffer) {
        _isSharedOffer = isSharedOffer || false;
    }
    function getIsSharedOffer() {
        return _isSharedOffer;
    }
    function setIsFromTSV(isFromTSV) {
        _isFromTSV = isFromTSV;
    }
    function getIsFromTSV() {
        return _isFromTSV;
    }
    function getRig() {
        return _rig;
    }
    function getTrailer1() {
        if (!_trailer1) {
            _trailer1 = _rig.getTrailer1();
        }
        return _trailer1;
    }
    function setTrailer1Null() {
        _trailer1 = null;
    }
    function getTrailer2() {
        if (!_trailer2) {
            _trailer2 = _rig.getTrailer2();
        } 
        return _trailer2;
    }
    function setTrailer2Null() {
        _trailer2 = null;
    }
    function getTrailer(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1();
        } else {
            return getTrailer2();
        }
    }
    function addTrailer(trailer) {
        _rig.addChassisObject(trailer);
    }
    function removeTrailer(trailer) {
        _rig.removeChassisObject(trailer);
        if (trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            _trailer1 = null;
        } else if (trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
            _trailer2 = null;
        }
    }
    function getSummaryReportOptions() {
        return _summaryReportOptions;
    }
    function getVehicleTypeName() {
        return _vehicleTypeName;
    }
    function setVehicleTypeName(newValue) {
        _vehicleTypeName = newValue;
    }
    function getVehicleTypeNameCode() {
        return _vehicleTypeNameCode;
    }
    function setVehicleTypeNameCode(newValue) {
        _vehicleTypeNameCode = newValue;
    }
    function getSubCategory1() {
        return _subCategory1;
    }
    function setSubCategory1(newValue) {
        _subCategory1 = newValue;
    }
    function getSubCategory1Code() {
        return _subCategory1Code;
    }
    function setSubCategory1Code(newValue) {
        _subCategory1Code = newValue;
    }
    function getSubCategory2() {
        return _subCategory2;
    }
    function setSubCategory2(newValue) {
        _subCategory2 = newValue;
    }
    function getSubCategory2Code() {
        return _subCategory2Code;
    }
    function setSubCategory2Code(newValue) {
        _subCategory2Code = newValue;
    }
    function getStatus() {
        return _status;
    }
    function setStatus(newValue) {
        _status = newValue;
    }
    function setLastModifiedByUserId(newValue) {
        _lastModifiedByUserId = newValue;
    }
    function getLastModifiedByUserId() {
        return _lastModifiedByUserId;
    }
    function setLastModifiedByUserFirstName(newValue) {
        _lastModifiedByUserFirstName = newValue;
    }
    function getLastModifiedByUserFirstName() {
        return _lastModifiedByUserFirstName;
    }
    function setLastModifiedByUserLastName(newValue) {
        _lastModifiedByUserLastName = newValue;
    }
    function getLastModifiedByUserLastName() {
        return _lastModifiedByUserLastName;
    }
    function setLastModifiedByUserEmail(newValue) {
        _lastModifiedByUserEmail = newValue;
    }
    function getLastModifiedByUserEmail() {
        return _lastModifiedByUserEmail;
    }
    function getSharerUserInfo() {
        return _sharerUserInfo;
    }
    function setSharerUserInfo(newValue) {
        _sharerUserInfo = newValue;
    }
    function getShareeUserInfoList() {
        return _shareeUserInfoArray;
    }
    function setShareeUserInfoList(newValue) {
        _shareeUserInfoArray = newValue;
    }
    function getDisplayTransSolveWarningsMessage() {
        return _displayTransSolveWarningsMessage;
    }
    function setDisplayTransSolveWarningsMessage(newValue) {
        _displayTransSolveWarningsMessage = newValue;
    }
    function getTransSolveWarningsArray() {
        return _transSolveWarningsArray;
    }
    function setTransSolveWarningsArray(newValue) {
        _transSolveWarningsArray = newValue;
    }
    function getIsSupportOffer() {
        return _isSupportOffer;
    }
    function setIsSupportOffer(newValue) {
        _isSupportOffer = newValue;
    }
    function getSupportUserId() {
        return _supportUserId;
    }
    function setSupportUserId(newValue) {
        _supportUserId = newValue;
    }
    //#endregion getters and setters

    function isModuleAvailableOnOffer(code) {
        if (code === undefined) {
            return false
        }

        return getDataAvailability()[code];
    }

    //#region initialize variables
    _rig = new Rig();


    setId(offer.Id);
    setUpdateCounter(offer.UpdateCounter);
    setDescription(offer.Description || '');
    setAdditionalDescription(offer.AdditionalDescription || '');
    setPreparedForName(offer.PreparedForName || '');
    setPreparedForTelephone(offer.PreparedForTelephone || '');
    setApplyHigherMassLimits(offer.ApplyHigherMassLimits || false);
    setKeepChassisFlushWithBody(offer.KeepChassisFlushWithBody || false);

    setupOfferLevelValues(offer);

    setAdditionalNote('');

    createConfiguration(offer.Configuration);
    if (offer.Costing !== undefined && offer.DataAvailability && offer.DataAvailability.ActiveCosting) {
        createCosting(offer.Costing);
    }
    if (offer.Performance !== undefined && offer.DataAvailability && offer.DataAvailability.ActivePerformance) {
        createPerformance(offer.Performance);
    }
    if (offer.Specification !== undefined && offer.DataAvailability && offer.DataAvailability.ActiveSpecification) {
        createSpecification(offer.Specification);
    }
    if (offer.DataAvailability !== undefined) {
        setDataAvailability(offer.DataAvailability);
        createSummaryReports(offer.DataAvailability);
    }

    

    //#endregion initialize variables

    //#region Internal functions
    function setupOfferLevelValues(offer) {
        
        setVehicleId(offer.VehicleId);
        setVehicleName(offer.VehicleDescription);
        setVehicleModel(offer.VehicleModel);
        setVehicleModelCode(offer.VehicleModelCode);
        setVehicleMake(offer.VehicleMake);
        setVehicleRange(offer.VehicleRange);
        setVehicleGVW(offer.GVW);
        setVehiclePayload(offer.Payload);
        setVehiclePower(offer.Power);
        setVehicleAxleLayout(offer.AxleLayout);
        setVehiclePlusBodyImageURL(offer.VehiclePlusBodyImageURL);
        setBodyType(offer.retrievedBodyType);
        setBodyManufacturer(offer.retrievedBodyManufacturer);
        setVehicleDistributionGroupId(offer.VehicleDistributionGroupId);
        setVehicleDistributionOptionId(offer.VehicleDistributionOptionId);
        setCountryId(offer.CountryId);
        setRangeId(offer.RangeId);
        setIsSharedOffer(offer.IsSharedOffer);

        setVehicleTypeName(offer.VehicleTypeName);
        setVehicleTypeNameCode(offer.VehicleTypeNameCode);
        setSubCategory1(offer.SubCategory1);
        setSubCategory1Code(offer.SubCategory1Code);
        setSubCategory2(offer.SubCategory2);
        setSubCategory2Code(offer.SubCategory2Code);
        setStatus(offer.Status);

    }

    function setupLastModifiedByInfo(lastModifiedByInfo) {
        setLastModifiedByUserId(lastModifiedByInfo.LastModifiedByUserId);
        setLastModifiedByUserFirstName(lastModifiedByInfo.LastModifiedByUserFirstName);
        setLastModifiedByUserLastName(lastModifiedByInfo.LastModifiedByUserLastName);
        setLastModifiedByUserEmail(lastModifiedByInfo.LastModifiedByUserEmail);
    }

    function setupShareInfo(shareInfo) {
        _sharerUserInfo = new ShareUser(shareInfo.Sharer);
        _shareeUserInfoArray = [];
        shareInfo.Sharees.forEach(function (shareeUserInfo) { 
            _shareeUserInfoArray.push(new ShareUser(shareeUserInfo));
        });
    }
    /**
     * Removes any sharee users that are not in the shareInfoArray
     * @param {Array} shareInfoArray 
     */
    function updateShareInfo(shareInfoArray) {
        if(Array.isArray(shareInfoArray)) {
            var indexesToRemove = [];
            _shareeUserInfoArray.forEach(function (shareeUser, index) {
                var test = shareInfoArray.findIndex(function (sharee) {
                    return sharee.shareeEmailAddress.toUpperCase() === shareeUser.getEmail().toUpperCase();
                });
                if(test === -1) {
                    indexesToRemove.push(index);
                }
            });
            if(indexesToRemove.length > 0) {
                if (indexesToRemove.length === _shareeUserInfoArray.length) {
                    _shareeUserInfoArray = [];
                } else {
                    var newArray = [];
                    _shareeUserInfoArray.forEach(function (shareeUser, index) {
                        if (indexesToRemove.indexOf(index) === -1) {
                            newArray.push(shareeUser);
                        }
                    });
                    _shareeUserInfoArray = newArray;
                }
            }
        }
    }
    function displayNotifyOtherUsersOfSaveModal() {
        return _sharerUserInfo !== undefined && Array.isArray(_shareeUserInfoArray) && _shareeUserInfoArray.length > 0;
    }    
    function getSharePermission(emailAddress) {
    //function getSharePermission(userId) {
        var findFunction;
        if (getIsSupportOffer() === true) {
            findFunction = findUsingSupportUserId;
        } else {
            findFunction = findUsingEmailAddress;
        }
        var shareeDetail = _shareeUserInfoArray.find(findFunction);
        if (shareeDetail !== null && shareeDetail !== undefined) {
            return shareeDetail.getSharePermission();
        }
        return config.SHARE_ACCESS_LEVEL_OPTIONS.VIEW_ONLY;

        function findUsingEmailAddress(sharee) {
            return sharee.getEmail() === emailAddress;                    
        }

        function findUsingSupportUserId(sharee) {
            return sharee.getId() === getSupportUserId();
        }
    }
    function setSharePermission(emailAddress, permission) {
        var shareeDetail = _shareeUserInfoArray.find(function (sharee) {
            return sharee.getEmail() === emailAddress;
        });
        if (shareeDetail !== null) {
            return shareeDetail.setSharePermission(permission);
        }
        
    }
    function cleanUp() {
        _rig.cleanUp();
    }
    
    function createConfiguration(configuration) {
        var tempConfiguration = new Configuration(configuration.BodyHeightExternal, configuration.BodyLengthExternalInclRearDoors,
                                                configuration.BumperToBackOfCab, configuration.BumperToStartOfLoadCompartment, configuration.CabGapRecommendedMin,
                                                configuration.CabHeight, configuration.ColourOptions, configuration.CompleteOverallBodyAndEquipmentHeight,
                                                configuration.CompleteOverallLength, configuration.CompleteRearOverhang, configuration.FloorHeight,
                                                configuration.FrontOverhang, configuration.GrossFront, configuration.GrossRear, configuration.GrossTotal,
                                                configuration.LoadCompartmentHeight, configuration.Messages,
                                                configuration.PayloadFront, configuration.PayloadRear, configuration.PayloadTotal, configuration.PermissibleFront,
                                                configuration.PermissibleRear, configuration.PermissibleTotal, configuration.RearOverhang,
                                                configuration.UnladenFront, configuration.UnladenRear, configuration.UnladenTotal,
                                                configuration.VehicleDrawing, configuration.VehicleTopViewDrawing, configuration.WheelbaseTheoretical, configuration.BumperToStartOfBodyInternal,
                                                configuration.BodyLengthInternal, configuration.RearProtrusion, configuration.Axle1FrontToWheelbaseTheoreticalStart,
                                                configuration.FifthWheelOffset, configuration.FifthWheelHieghtFromGround, configuration.Axle1FrontToAxle2Front,
                                                configuration.Axle2FrontToAxle1Rear, configuration.Axle1RearToAxle2Rear, configuration.Axle2RearToAxle3Rear,
                                                configuration.Axle3RearToAxle4Rear, configuration.Control, configuration.BreakpointForWheelbaseFromRearMostAxleFront, configuration.BreakpointForRearOverhangFromRearMostAxle,
                                                configuration.RearMostProtrudingItemBehindCabToRearAxles, configuration.Type, configuration.TareFifthWheelIncluded,
                                                configuration.ChassisHeightFront, configuration.ChassisHeightRear, configuration.Axles, configuration.Source,
                                                configuration.VerticalExhaustHeight, configuration.OverallLength, configuration.GroundClearanceFront, configuration.GroundClearanceRear,
                                                configuration.CrewMassPerPerson, configuration.CrewFirstRowMax, configuration.CrewFirstRowHorizontalCOG, configuration.NoOfCrewInFirstRow, configuration.CrewSecondRowIncluded,
                                                configuration.CrewSecondRowMax, configuration.CrewSecondRowHorizontalCOG, configuration.NoOfCrewInSecondRow, configuration.FuelSpecificGravity, configuration.FuelTank1COG,
                                                configuration.FuelTank1Capacity, configuration.FuelTank2Included, configuration.FuelTank2COG, configuration.FuelTank2Capacity,
                                                configuration.FuelTank3Included, configuration.FuelTank3COG, configuration.FuelTank3Capacity, configuration.FuelTank4Included,
                                                configuration.FuelTank4COG, configuration.FuelTank4Capacity, configuration.TareFront, configuration.TareRear,
                                                configuration.FuelTank1VolumeIncludedInTare, configuration.FuelTank2VolumeIncludedInTare, configuration.FuelTank3VolumeIncludedInTare,
                                                configuration.FuelTank4VolumeIncludedInTare, configuration.WheelbaseTheoreticalManufacturerMin,
                                                configuration.WheelbaseTheoreticalManufacturerMax, configuration.OriginalAUFront, configuration.OriginalAURear,
                                                configuration.GVM, configuration.GCM, configuration.VRating, configuration.VehicleDT, configuration.FrontRoadFriendlySuspension,
                                                configuration.RearRoadFriendlySuspension, configuration.RolloverProtection, configuration.SteeringAngleInner,
                                                configuration.TurningRadiusWallToWall, configuration.TurningRadiusCurbToCurb, configuration.FrontUnderrunProtection,
                                                configuration.WheelbaseTheoreticalMaxCut, configuration.RearOverhangManufacturerMin, configuration.CabTilt, configuration.CabTiltConverter,
                                                configuration.HingePointFromBumper, configuration.HingePointFromGround, configuration.CabTiltRadius, configuration.WheelbaseTheoreticalOverride,
                                                configuration.CabWidth, configuration.RearWidth, configuration.OverallWidth, configuration.TrackWidthFront, configuration.TrackWidthRear,
                                                configuration.ChassisWidth, configuration.Axle1FrontToWheelbaseTheoreticalStartOverride,
                                                configuration.TareFrontOverride, configuration.TareRearOverride, configuration.VehicleVerticalCOG, false,
                                                configuration.TareToolsIncluded, configuration.TareSpareWheelIncluded, configuration.AUFront, configuration.AURear, configuration.ManufacturersFrontAxlesMassOverride,
                                                configuration.ManufacturersRearAxlesMassOverride, configuration.ManufacturersGrossVehicleMassOverride, configuration.PermissibleRearOverride,
                                                configuration.PermissibleFrontOverride, configuration.PermissibleTotalOverride, configuration.CrewFirstRowHorizontalCOGOverride,
                                                configuration.CrewFirstRowVerticalCOG, false, configuration.CrewSecondRow, configuration.CrewSecondRowHorizontalCOGOverride,
                                                configuration.CrewSecondRowVerticalCOG, false, configuration.FuelType, configuration.GAFront, configuration.GARear,
                                                configuration.FuelTank1VerticalCOG, configuration.FuelTank2VerticalCOG, configuration.FuelTank3VerticalCOG, configuration.FuelTank4VerticalCOG,
                                                configuration.FuelTanks, configuration.BodyWidthManufacturerMax, configuration.PayloadCOGType, configuration.PayloadHorizontalCOG,
                                                configuration.PayloadVerticalCOG, false,
                                                false, false, false, false,
                                                configuration.VehicleVerticalCOGOverride, configuration.PayloadVerticalCOGOverride, configuration.FuelTank1VerticalCOGOverride, configuration.FuelTank2VerticalCOGOverride,
                                                configuration.FuelTank3VerticalCOGOverride, configuration.Fueltank4VerticalCOGOverride, configuration.CrewFirstRowVerticalCOGOverride, configuration.CrewSecondRowVerticalCOGOverride,
                                                configuration.TareDriverIncluded, configuration.FifthWheelOffsetMin, configuration.FifthWheelOffsetMax,
                                                getVehicleName(), configuration.VehicleTypeDistributionCode, configuration.FrontAxleAverageTyreLife, configuration.RearAxleAverageTyreLife,
                                                configuration.SecondsteerAverageTyreLife, configuration.PusherAxleAverageTyreLife, configuration.TagAxleAverageTyreLife, configuration.TrailerFrontAxleAverageTyreLife, configuration.TrailerRearAxleAverageTyreLife,
                                                configuration.FuelTank1LateralCOG, false, configuration.FuelTank1LateralCOGOverride,
                                                configuration.FuelTank2LateralCOG, false, configuration.FuelTank2LateralCOGOverride,
                                                configuration.FuelTank3LateralCOG, false, configuration.FuelTank3LateralCOGOverride,
                                                configuration.FuelTank4LateralCOG, false, configuration.FuelTank4LateralCOGOverride,
                                                configuration.CrewFirstRowLateralCOG, false, configuration.CrewFirstRowLateralCOGOverride,
                                                configuration.CrewSecondRowLateralCOG, false, configuration.CrewSecondRowLateralCOGOverride,
                                                configuration.VehicleLateralCOG, false, configuration.VehicleLateralCOGOverride,
                                                configuration.PayloadLateralCOG, false, configuration.PayloadLateralCOGOverride,
                                                configuration.LoadCompartmentWidth, configuration.TyrePriceFirstSet, configuration.TyrePriceRemainingSets,
                                                configuration.CabToAxlesOverride, configuration.VehicleBridgeFormulaPermissible || 0, false, configuration.CombinationBridgeFormulaPermissible || 0, false,
                                                configuration.BridgeFormulaRearPermissible || 0, configuration.BridgeFormulaRearPermissibleOverride || false,
                                                configuration.FuelSpecificGravityDiesel, configuration.FuelSpecificGravityPetrol, configuration.FuelSpecificGravityEthanol,
                                                configuration.FuelTank5Capacity, configuration.FuelTank5VolumeIncludedInTare, configuration.FuelTank5COG, configuration.FuelTank5VerticalCOG, false, configuration.FuelTank5LateralCOG, false, configuration.FuelTank5Included,
                                                configuration.FuelTank6Capacity, configuration.FuelTank6VolumeIncludedInTare, configuration.FuelTank6COG, configuration.FuelTank6VerticalCOG, false, configuration.FuelTank6LateralCOG, false, configuration.FuelTank6Included,
                                                configuration.FuelTank7Capacity, configuration.FuelTank7VolumeIncludedInTare, configuration.FuelTank7COG, configuration.FuelTank7VerticalCOG, false, configuration.FuelTank7LateralCOG, false, configuration.FuelTank7Included,
                                                configuration.FuelTank8Capacity, configuration.FuelTank8VolumeIncludedInTare, configuration.FuelTank8COG, configuration.FuelTank8VerticalCOG, false, configuration.FuelTank8LateralCOG, false, configuration.FuelTank8Included,
                                                configuration.FuelTank5VerticalCOGOverride, configuration.FuelTank6VerticalCOGOverride, configuration.FuelTank7VerticalCOGOverride, configuration.FuelTank8VerticalCOGOverride,
                                                configuration.FuelTank5LateralCOGOverride, configuration.FuelTank6LateralCOGOverride, configuration.FuelTank7LateralCOGOverride, configuration.FuelTank8LateralCOGOverride,
                                                configuration.FuelSpecificGravityBiodiesel, configuration.FuelSpecificGravityLNG, configuration.FuelSpecificGravityCNG, configuration.FuelSpecificGravityHydrogen, configuration.SpecificGravityAdBlue, 
                                                configuration.CNGExpressedAs, configuration.LNGExpressedAs, configuration.HydrogenExpressedAs,
                                                configuration.DevicesForIndirectVision, configuration.CommercialVehicleBrakeSystems, configuration.AdvancedEmergencyBraking, configuration.LaneDepartureWarning, configuration.SideUnderrunProtection, configuration.ConspicuityMarkingsToTheRear, configuration.ConspicuityMarkingsToBothSides, configuration.BlindSpotInformationSystems,
                                                configuration.EngineMaximumTorqueNm, configuration.EngineMaximumTorqueLbFt, configuration.TransmissionFirstGearRatio, configuration.TransmissionReverseGearRatio, configuration.TransmissionTorqueConverterMultiplication, configuration.TransmissionParkPAWL,
                                                configuration.EmissionsStandard, configuration.EmissionsStandardCode,
                                                configuration.UpdateCounter);

        tempConfiguration.setChassisObjectName(config.CHASSIS_OBJECT_TYPES.VEHICLE);
        _rig.addChassisObject(tempConfiguration);
        //#VERY IMPORTANT: the overrides for vehicle, payload fueltanks and crew first and second row vertical COGs above are TIM User overrides meaning they 
        //are confirmed(or not) Vertical COG values and come from AppData side, and have NO relationship to the override values stored in UserData side (which default to false)
        if(configuration.DecompressedVehicleDrawing && utils.isValidDecompressedGraphicFile(configuration.DecompressedVehicleDrawing)) {
            tempConfiguration.setDecompressedVehicleDrawing(configuration.DecompressedVehicleDrawing);
        }
        if(configuration.DecompressedVehicleTopViewDrawing && utils.isValidDecompressedGraphicFile(configuration.DecompressedVehicleTopViewDrawing)) {
            tempConfiguration.setDecompressedVehicleTopViewDrawing(configuration.DecompressedVehicleTopViewDrawing);
        }
        setConfiguration(_rig.getVehicle());
    }
    
    function updateConfiguration(configuration) {
        _rig.getVehicle().updateValues(configuration.BodyHeightExternal, configuration.BodyLengthExternalInclRearDoors,
                                                configuration.BumperToBackOfCab, configuration.BumperToStartOfLoadCompartment, configuration.CabGapRecommendedMin,
                                                configuration.CabHeight, configuration.ColourOptions, configuration.CompleteOverallBodyAndEquipmentHeight,
                                                configuration.CompleteOverallLength, configuration.CompleteRearOverhang, configuration.FloorHeight,
                                                configuration.FrontOverhang, configuration.GrossFront, configuration.GrossRear, configuration.GrossTotal,
                                                configuration.LoadCompartmentHeight, configuration.Messages,
                                                configuration.PayloadFront, configuration.PayloadRear, configuration.PayloadTotal, configuration.PermissibleFront,
                                                configuration.PermissibleRear, configuration.PermissibleTotal, configuration.RearOverhang,
                                                configuration.UnladenFront, configuration.UnladenRear, configuration.UnladenTotal,
                                                configuration.VehicleDrawing, configuration.VehicleTopViewDrawing, configuration.WheelbaseTheoretical, configuration.BumperToStartOfBodyInternal,
                                                configuration.BodyLengthInternal, configuration.RearProtrusion, configuration.Axle1FrontToWheelbaseTheoreticalStart,
                                                configuration.FifthWheelOffset, configuration.FifthWheelHieghtFromGround, configuration.Axle1FrontToAxle2Front,
                                                configuration.Axle2FrontToAxle1Rear, configuration.Axle1RearToAxle2Rear, configuration.Axle2RearToAxle3Rear,
                                                configuration.Axle3RearToAxle4Rear, configuration.Control, configuration.BreakpointForWheelbaseFromRearMostAxleFront, configuration.BreakpointForRearOverhangFromRearMostAxle,
                                                configuration.RearMostProtrudingItemBehindCabToRearAxles, configuration.Type, configuration.TareFifthWheelIncluded,
                                                configuration.ChassisHeightFront, configuration.ChassisHeightRear, configuration.Axles, configuration.Source,
                                                configuration.VerticalExhaustHeight, configuration.OverallLength, configuration.GroundClearanceFront, configuration.GroundClearanceRear,
                                                configuration.CrewMassPerPerson, configuration.CrewFirstRowMax, configuration.CrewFirstRowHorizontalCOG, configuration.NoOfCrewInFirstRow, configuration.CrewSecondRowIncluded,
                                                configuration.CrewSecondRowMax, configuration.CrewSecondRowHorizontalCOG, configuration.NoOfCrewInSecondRow, configuration.FuelSpecificGravity, configuration.FuelTank1COG,
                                                configuration.FuelTank1Capacity, configuration.FuelTank2Included, configuration.FuelTank2COG, configuration.FuelTank2Capacity,
                                                configuration.FuelTank3Included, configuration.FuelTank3COG, configuration.FuelTank3Capacity, configuration.FuelTank4Included,
                                                configuration.FuelTank4COG, configuration.FuelTank4Capacity, configuration.TareFront, configuration.TareRear,
                                                configuration.FuelTank1VolumeIncludedInTare, configuration.FuelTank2VolumeIncludedInTare, configuration.FuelTank3VolumeIncludedInTare,
                                                configuration.FuelTank4VolumeIncludedInTare, configuration.WheelbaseTheoreticalManufacturerMin,
                                                configuration.WheelbaseTheoreticalManufacturerMax, configuration.OriginalAUFront, configuration.OriginalAURear,
                                                configuration.GVM, configuration.GCM, configuration.VRating, configuration.VehicleDT, configuration.FrontRoadFriendlySuspension,
                                                configuration.RearRoadFriendlySuspension, configuration.RolloverProtection, configuration.SteeringAngleInner,
                                                configuration.TurningRadiusWallToWall, configuration.TurningRadiusCurbToCurb, configuration.FrontUnderrunProtection,
                                                configuration.WheelbaseTheoreticalMaxCut, configuration.RearOverhangManufacturerMin, configuration.CabTilt, configuration.CabTiltConverter,
                                                configuration.HingePointFromBumper, configuration.HingePointFromGround, configuration.CabTiltRadius, configuration.WheelbaseTheoreticalOverride,
                                                configuration.CabWidth, configuration.RearWidth, configuration.OverallWidth, configuration.TrackWidthFront, configuration.TrackWidthRear,
                                                configuration.ChassisWidth, configuration.Axle1FrontToWheelbaseTheoreticalStartOverride,
                                                configuration.TareFrontOverride, configuration.TareRearOverride, configuration.VehicleVerticalCOG, false,
                                                configuration.TareToolsIncluded, configuration.TareSpareWheelIncluded, configuration.AUFront, configuration.AURear, configuration.ManufacturersFrontAxlesMassOverride,
                                                configuration.ManufacturersRearAxlesMassOverride, configuration.ManufacturersGrossVehicleMassOverride, configuration.PermissibleRearOverride,
                                                configuration.PermissibleFrontOverride, configuration.PermissibleTotalOverride, configuration.CrewFirstRowHorizontalCOGOverride,
                                                configuration.CrewFirstRowVerticalCOG, false, configuration.CrewSecondRow, configuration.CrewSecondRowHorizontalCOGOverride,
                                                configuration.CrewSecondRowVerticalCOG, false, configuration.FuelType, configuration.GAFront, configuration.GARear,
                                                configuration.FuelTank1VerticalCOG, configuration.FuelTank2VerticalCOG, configuration.FuelTank3VerticalCOG, configuration.FuelTank4VerticalCOG,
                                                configuration.FuelTanks, configuration.BodyWidthManufacturerMax, configuration.PayloadCOGType, configuration.PayloadHorizontalCOG,
                                                configuration.PayloadVerticalCOG, false,
                                                false, false, false, false,
                                                configuration.VehicleVerticalCOGOverride, configuration.PayloadVerticalCOGOverride, configuration.FuelTank1VerticalCOGOverride, configuration.FuelTank2VerticalCOGOverride,
                                                configuration.FuelTank3VerticalCOGOverride, configuration.Fueltank4VerticalCOGOverride, configuration.CrewFirstRowVerticalCOGOverride, configuration.CrewSecondRowVerticalCOGOverride,
                                                configuration.TareDriverIncluded, configuration.FifthWheelOffsetMin, configuration.FifthWheelOffsetMax, getVehicleName(), configuration.VehicleTypeDistributionCode,
                                                configuration.FrontAxleAverageTyreLife, configuration.RearAxleAverageTyreLife, configuration.SecondsteerAverageTyreLife, configuration.PusherAxleAverageTyreLife, 
                                                configuration.TagAxleAverageTyreLife, configuration.TrailerFrontAxleAverageTyreLife, configuration.TrailerRearAxleAverageTyreLife,
                                                configuration.FuelTank1LateralCOG, false, configuration.FuelTank1LateralCOGOverride,
                                                configuration.FuelTank2LateralCOG, false, configuration.FuelTank2LateralCOGOverride,
                                                configuration.FuelTank3LateralCOG, false, configuration.FuelTank3LateralCOGOverride,
                                                configuration.FuelTank4LateralCOG, false, configuration.FuelTank4LateralCOGOverride,
                                                configuration.CrewFirstRowLateralCOG, false, configuration.CrewFirstRowLateralCOGOverride,
                                                configuration.CrewSecondRowLateralCOG, false, configuration.CrewSecondRowLateralCOGOverride,
                                                configuration.VehicleLateralCOG, false, configuration.VehicleLateralCOGOverride,
                                                configuration.PayloadLateralCOG, false, configuration.PayloadLateralCOGOverride,
                                                configuration.LoadCompartmentWidth, configuration.TyrePriceFirstSet, configuration.TyrePriceRemainingSets, 
                                                configuration.CabToAxlesOverride, configuration.VehicleBridgeFormulaPermissible || 0, false, configuration.CombinationBridgeFormulaPermissible || 0, false,
                                                configuration.BridgeFormulaRearPermissible || 0, configuration.BridgeFormulaRearPermissibleOverride || false,
                                                configuration.FuelSpecificGravityDiesel, configuration.FuelSpecificGravityPetrol, configuration.FuelSpecificGravityEthanol,
                                                configuration.FuelTank5Capacity, configuration.FuelTank5VolumeIncludedInTare, configuration.FuelTank5COG, configuration.FuelTank5VerticalCOG, false, configuration.FuelTank5LateralCOG, false, configuration.FuelTank5Included,
                                                configuration.FuelTank6Capacity, configuration.FuelTank6VolumeIncludedInTare, configuration.FuelTank6COG, configuration.FuelTank6VerticalCOG, false, configuration.FuelTank6LateralCOG, false, configuration.FuelTank6Included,
                                                configuration.FuelTank7Capacity, configuration.FuelTank7VolumeIncludedInTare, configuration.FuelTank7COG, configuration.FuelTank7VerticalCOG, false, configuration.FuelTank7LateralCOG, false, configuration.FuelTank7Included,
                                                configuration.FuelTank8Capacity, configuration.FuelTank8VolumeIncludedInTare, configuration.FuelTank8COG, configuration.FuelTank8VerticalCOG, false, configuration.FuelTank8LateralCOG, false, configuration.FuelTank8Included,
                                                configuration.FuelTank5VerticalCOGOverride, configuration.FuelTank6VerticalCOGOverride, configuration.FuelTank7VerticalCOGOverride, configuration.FuelTank8VerticalCOGOverride,
                                                configuration.FuelTank5LateralCOGOverride, configuration.FuelTank6LateralCOGOverride, configuration.FuelTank7LateralCOGOverride, configuration.FuelTank8LateralCOGOverride,
                                                configuration.FuelSpecificGravityBiodiesel, configuration.FuelSpecificGravityLNG, configuration.FuelSpecificGravityCNG, configuration.FuelSpecificGravityHydrogen, configuration.SpecificGravityAdBlue, 
                                                configuration.CNGExpressedAs, configuration.LNGExpressedAs, configuration.HydrogenExpressedAs,
                                                configuration.DevicesForIndirectVision, configuration.CommercialVehicleBrakeSystems, configuration.AdvancedEmergencyBraking, configuration.LaneDepartureWarning, configuration.SideUnderrunProtection, configuration.ConspicuityMarkingsToTheRear, configuration.ConspicuityMarkingsToBothSides, configuration.BlindSpotInformationSystems,
                                                configuration.EngineMaximumTorqueNm, configuration.EngineMaximumTorqueLbFt, configuration.TransmissionFirstGearRatio, configuration.TransmissionReverseGearRatio, configuration.TransmissionTorqueConverterMultiplication, configuration.TransmissionParkPAWL,
                                                configuration.EmissionsStandard, configuration.EmissionsStandardCode,
                                                configuration.UpdateCounter);
        if(configuration.DecompressedVehicleDrawing && utils.isValidDecompressedGraphicFile(configuration.DecompressedVehicleDrawing)) {
            _rig.getVehicle().setDecompressedVehicleDrawing(configuration.DecompressedVehicleDrawing);
        }
        if(configuration.DecompressedVehicleTopViewDrawing && utils.isValidDecompressedGraphicFile(configuration.DecompressedVehicleTopViewDrawing)) {
            _rig.getVehicle().setDecompressedVehicleTopViewDrawing(configuration.DecompressedVehicleTopViewDrawing);
        }
        
    }

    function createCosting(costing) {
        setCosting(new Costing(costing.NoOfUnits, costing.NoOfUnitsMin, costing.NoOfUnitsMax, costing.NoOfUnitsIncrement, costing.PriceIntervals,
                                    costing.DepositRate, costing.DepositRateMin, costing.DepositRateMax, costing.DepositRateIncrement,
                                    costing.FuelConsumption, costing.FuelConsumptionMin, costing.FuelConsumptionMax, costing.FuelConsumptionIncrement,
                                    costing.FuelCost, costing.FuelCostMin, costing.FuelCostMax, costing.FuelCostIncrement,
                                    costing.InterestRate, costing.InterestRateMin, costing.InterestRateMax, costing.InterestRateIncrement,
                                    costing.MaintenanceRate, costing.MaintenanceRateMin, costing.MaintenanceRateMax, costing.MaintenanceRateIncrement,
                                    costing.MonthlyDistance, costing.MonthlyDistanceMin, costing.MonthlyDistanceMax, costing.MonthlyDistanceIncrement,
                                    costing.RepaymentTerm, costing.RepaymentTermMin, costing.RepaymentTermMax, costing.RepaymentTermIncrement,
                                    costing.ResidualRate, costing.ResidualRateMin, costing.ResidualRateMax, costing.ResidualRateIncrement,
                                    costing.OptionPriceMin, costing.OptionPriceMax, costing.OptionPriceIncrement,
                                    costing.BodyAndEquipmentPriceMin, costing.BodyAndEquipmentPriceMax, costing.BodyAndEquipmentPriceIncrement,
                                    costing.VehiclePriceMin, costing.VehiclePriceMax, costing.VehiclePriceIncrement,
                                    costing.OriginalVehiclePrice, costing.OriginalBodyAndEquipmentPrice, costing.OriginalOptionPrice,
                                    costing.OnRoadCostsCosts, costing.OnRoadCostsDiscounts, costing.DecimalsHighPricedItems,
                                    costing.DecimalsMediumPricedItems, costing.DecimalsLowPricedItems, costing.DriverRemuneration, costing.DriverRemunerationMin, costing.DriverRemunerationMax,
                                    costing.DriverRemunerationIncrement, costing.InsuranceRate, costing.InsuranceRateMin, costing.InsuranceRateMax, costing.InsuranceRateIncrement,
                                    costing.OverheadRate, costing.OverheadRateMin, costing.OverheadRateMax, costing.OverheadRateIncrement, costing.TyreRate, costing.TyreRateMin,
                                    costing.TyreRateMax, costing.TyreRateIncrement, costing.LicenceFeesMin, costing.LicenceFeesMax, costing.LicenceFeesIncrement, costing.TollFeesMin,
                                    costing.TollFeesMax, costing.TollFeesIncrement, costing.RevenueMin, costing.RevenueMax, costing.RevenueIncrement, costing.TyrePriceMin, costing.TyrePriceMax,
                                    costing.TyrePriceIncrement, costing.AverageTyreLifeMin, costing.AverageTyreLifeMax, costing.AverageTyreLifeIncrement,
                                    costing.LicenceFeesOverride, costing.VehicleLicenceFees, costing.VehicleLicenceFeesOverride, costing.VehicleLicenceFeesRegionId,
                                    costing.VehiclePriceOverride, costing.VehicleDiscount, costing.VehiclePeriod, costing.VehicleInterestRate,
                                    costing.VehicleResidualRate, costing.VehicleResidualRateType, costing.FinanceTotalPriceOverride, costing.VehicleCommonGroupFinancePeriod, costing.CountryInterestRate, costing.VehiclePriceEffectiveDate,
                                    costing.MonthlyTrips, costing.MonthlyTripsMin, costing.MonthlyTripsMax, costing.MonthlyTripsIncrement, costing.TollFeesOverride, costing.CostingTollFeesPerTrip, costing.CostingTollFeesPerTripOverride,
                                    costing.CostingPermitCostsPerTrip, costing.CostingVehicleClassId, costing.CostingVehicleClassIdOverride,
                                    costing.FuelAndAdblueCostPerMonthMin, costing.FuelAndAdblueCostPerMonthMax, costing.FuelAndAdblueCostPerMonthIncrement,
                                    costing.FuelAndAdblueCostPerMonth, costing.FuelAndAdblueCostPerMonthOverride, costing.VehicleFuelAndAdblueTotalCostPerMonth,
                                    costing.VehicleFuelAndAdblueTotalCostPerMonthOverride, costing.VehicleFuelAndAdblueFuelConsumption, costing.VehicleFuelAndAdblueFuelConsumptionOverride, 
                                    costing.VehicleFuelAndAdblueFuelCostPerVolume, costing.VehicleFuelAndAdblueFuelCostPerVolumeOverride, costing.VehicleFuelAndAdblueFuelCostPerVolumeMin,
                                    costing.VehicleFuelAndAdblueFuelCostPerVolumeMax, costing.VehicleFuelAndAdblueAdblueConsumption,
                                    costing.VehicleFuelAndAdblueAdblueConsumptionOverride, costing.VehicleFuelAndAdblueAdblueConsumptionMin, costing.VehicleFuelAndAdblueAdblueConsumptionMax, 
                                    costing.VehicleFuelAndAdblueAdblueCostPerVolume, costing.VehicleFuelAndAdblueAdblueCostPerVolumeOverride, costing.VehicleFuelAndAdblueAdblueCostPerVolumeMin,
                                    costing.VehicleFuelAndAdblueAdblueCostPerVolumeMax, costing.VehicleFuelAndAdblueEquipmentFuelConsumption, costing.VehicleFuelAndAdblueEquipmentFuelConsumptionOverride, 
                                    costing.VehicleFuelAndAdblueEquipmentFuelCostPerVolume, costing.VehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride, costing.VehicleFuelAndAdblueEquipmentFuelCostPerVolumeMin,
                                    costing.VehicleFuelAndAdblueEquipmentFuelCostPerVolumeMax, costing.VehicleFuelAndAdblueEquipmentUtilisation, costing.TrailerFuelAndAdblueEquipmentFuelCostPerVolume, 
                                    costing.TrailerFuelAndAdblueEquipmentFuelCostPerVolumeMin, costing.TrailerFuelAndAdblueEquipmentFuelCostPerVolumeMax));
                                    // 2020.5 - Add new items for tolls here
                                    //globals.user.getOnRoadCostsCosts(), globals.user.getOnRoadCostsDiscounts()));
                                    
    }
    function updateCosting(costing) {
        getCosting().updateValues(costing.NoOfUnits, costing.NoOfUnitsMin, costing.NoOfUnitsMax, costing.NoOfUnitsIncrement, costing.PriceIntervals,
                                    costing.DepositRate, costing.DepositRateMin, costing.DepositRateMax, costing.DepositRateIncrement,
                                    costing.FuelConsumption, costing.FuelConsumptionMin, costing.FuelConsumptionMax, costing.FuelConsumptionIncrement,
                                    costing.FuelCost, costing.FuelCostMin, costing.FuelCostMax, costing.FuelCostIncrement,
                                    costing.InterestRate, costing.InterestRateMin, costing.InterestRateMax, costing.InterestRateIncrement,
                                    costing.MaintenanceRate, costing.MaintenanceRateMin, costing.MaintenanceRateMax, costing.MaintenanceRateIncrement,
                                    costing.MonthlyDistance, costing.MonthlyDistanceMin, costing.MonthlyDistanceMax, costing.MonthlyDistanceIncrement,
                                    costing.RepaymentTerm, costing.RepaymentTermMin, costing.RepaymentTermMax, costing.RepaymentTermIncrement,
                                    costing.ResidualRate, costing.ResidualRateMin, costing.ResidualRateMax, costing.ResidualRateIncrement,
                                    costing.OptionPriceMin, costing.OptionPriceMax, costing.OptionPriceIncrement,
                                    costing.BodyAndEquipmentPriceMin, costing.BodyAndEquipmentPriceMax, costing.BodyAndEquipmentPriceIncrement,
                                    costing.VehiclePriceMin, costing.VehiclePriceMax, costing.VehiclePriceIncrement,
                                    costing.OriginalVehiclePrice, costing.OriginalBodyAndEquipmentPrice, costing.OriginalOptionPrice,
                                    costing.OnRoadCostsCosts, costing.OnRoadCostsDiscounts, costing.DecimalsHighPricedItems,
                                    costing.DecimalsMediumPricedItems, costing.DecimalsLowPricedItems, costing.DriverRemuneration, costing.DriverRemunerationMin, costing.DriverRemunerationMax,
                                    costing.DriverRemunerationIncrement, costing.InsuranceRate, costing.InsuranceRateMin, costing.InsuranceRateMax, costing.InsuranceRateIncrement,
                                    costing.OverheadRate, costing.OverheadRateMin, costing.OverheadRateMax, costing.OverheadRateIncrement, costing.TyreRate, costing.TyreRateMin,
                                    costing.TyreRateMax, costing.TyreRateIncrement, costing.LicenceFeesMin, costing.LicenceFeesMax, costing.LicenceFeesIncrement, costing.TollFeesMin,
            costing.TollFeesMax, costing.TollFeesIncrement, costing.RevenueMin, costing.RevenueMax, costing.RevenueIncrement, costing.TyrePriceMin, costing.TyrePriceMax,
            costing.TyrePriceIncrement, costing.AverageTyreLifeMin || 1000, costing.AverageTyreLifeMax || 10000, costing.AverageTyreLifeIncrement || 1000,
            costing.LicenceFeesOverride, costing.VehicleLicenceFees, costing.VehicleLicenceFeesOverride, costing.VehicleLicenceFeesRegionId, 
            costing.VehicleListPriceOverride, costing.VehicleDiscount, costing.VehiclePeriod, costing.VehicleInterestRate,
            costing.VehicleResidualRate, costing.VehicleResidualRateType, costing.FinanceTotalPriceOverride, costing.VehicleCommonGroupFinancePeriod, costing.CountryInterestRate, costing.VehiclePriceEffectiveDate);
        //globals.user.getOnRoadCostsCosts(), globals.user.getOnRoadCostsDiscounts()));
    }
    function createPerformance(performance) {
        setPerformance(new Performance(performance.BottomRightLatitude, performance.BottomRightLongitude, performance.TopLeftLatitude, performance.TopLeftLongitude,
                                performance.CoefficientOfDrag, performance.SpeedMaxLoaded, performance.RevsMaxPullingLoaded, performance.TopGearRatio, performance.RevsGoverned, performance.AdblueRate));
    }
    function updatePerformance(performance) {
        getPerformance().updateValues(performance.BottomRightLatitude, performance.BottomRightLongitude, performance.TopLeftLatitude, performance.TopLeftLongitude,
                                performance.CoefficientOfDrag, performance.SpeedMaxLoaded, performance.RevsMaxPullingLoaded, performance.TopGearRatio, performance.RevsGoverned, performance.AdblueRate);
    }
    function createSpecification(specification) {
        setSpecification(new Specification(specification.Headings, specification.BaseVehicle, specification.CompetitorVehicles));
        getSpecification().setVehicleTypeNameCodeFilter(getVehicleTypeNameCode());
        getSpecification().setSubCategory1CodeFilter(getSubCategory1Code());
        getSpecification().setSubCategory2CodeFilter(getSubCategory2Code());
        getSpecification().setStatusCodeFilter(getStatus());
    }
    function updateSpecification(specification) {
        getSpecification.updateValues(specification.Headings, specification.BaseVehicle, specification.CompetitorVehicles);
    }

    /**
        * Create the available summary report options. Reports are defaulted to not be selected.
        * There are functions in offerManager to update the isChecked value when opening an offer
        * @param {Object} dataAvailability - Object containing list of available modules that the user can see
        */
    function createSummaryReports(dataAvailability) {
        _summaryReportOptions = [];
        if (dataAvailability.ActiveConfiguration === true) {
            addSummaryReportOption(new SummaryReportOption(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, false));
        }
        if (dataAvailability.ActiveSpecification === true) {
            addSummaryReportOption(new SummaryReportOption(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION, false));
        }
        if (dataAvailability.ActiveCosting === true) {
            addSummaryReportOption(new SummaryReportOption(config.SUMMARY_REPORT_OPTIONS.COSTING, false));
        }
        if (dataAvailability.ActivePerformance === true) {
            addSummaryReportOption(new SummaryReportOption(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE, false));
        }
    }

    /**
        * Add Report Summary Option to list of available options
        * @param {Object} reportOption - Report Summary Option Object
        */
    function addSummaryReportOption(reportOption) {
        // check if reportObject already exists
        // remove if already there

        // add to _summaryReportOptions
        _summaryReportOptions.push(reportOption);
    }

    /**
        * Update/Create summary report option using code
        * @param {string} code - Code for report option which should come from config.SUMMARY_REPORT_OPTIONS
        * @param {boolean} newValue - New value to the report option
        */
    function updateSummaryReportOptionUsingCode(code, newValue) {
        // Get the summary report option to update from list
        var summaryReportOptionToUpdate = getSummaryReportOptions().filter(function (item) {
            return item.getCode() === code;
        })[0] || null;
        if (summaryReportOptionToUpdate !== null) {
            // Update if it exists
            summaryReportOptionToUpdate.setIsChecked(newValue);
        } else {
            // Create it if it doesn't exist
            addSummaryReportOption(new SummaryReportOption(code, newValue));
        }
    }

    /**
        * Loop through getSummaryReportOptions result and return array where getIsChecked is true
        */
    function getReportOptionsToBeSaved() {
        var returnArray = [];
        getSummaryReportOptions().forEach(function (reportOption) {
            var reportObject = {};
            if (reportOption.getIsChecked() === true) {
                reportObject.Code = reportOption.getCode();
                reportObject.Options = [];

                reportOption.getSummaryReportOfferOptions().forEach(function (reportOfferOption) {
                    var reportOptionObject = {};
                    if (reportOfferOption.getIsChecked() === true) {
                        reportOptionObject.Code = reportOfferOption.getCode();
                        reportObject.Options.push(reportOptionObject);
                    }
                });

                returnArray.push(reportObject);
            }
        });
        return returnArray;
    }

    function getOfferDetailsToBeSaved(rigOps) {
        var waypointsArray = [],
            offerTollFees = [];
        if (getPerformance()) {
            waypointsArray = getPerformance().saveWaypoints();
            offerTollFees = getPerformance().saveOfferTollFees();
        }
        
        var onRoadCostsCostsArray, onRoadCostsDiscountsArray;
        if (getCosting()) {
            onRoadCostsCostsArray = getCosting().saveOnRoadCostsCosts();
            onRoadCostsDiscountsArray = getCosting().saveOnRoadCostsDiscounts();
        } else {
            onRoadCostsCostsArray = [];
            onRoadCostsDiscountsArray = [];
        }

        var fuelTanksArray, axles, accessories, payloads;
        if (getConfiguration()) {
            fuelTanksArray = getConfiguration().saveFuelTanks();
            axles = getConfiguration().getAxlesHolder().getAxleSaveObjects();
            accessories = getConfiguration().getAccessoryHolder().getAccessorySaveObjects(false, true);
            payloads = getConfiguration().getPayloadHolder().getPayloadSaveObjects();
        } else {
            fuelTanksArray = [];
            axles = [];
            accessories = {};
            payloads = [];
        }

        var reports = getReportOptionsToBeSaved();

        var trailers = [];
        if (getRig().getTrailer1() !== null) {
            axles = axles.concat(getRig().getTrailer1().getAxlesHolder().getAxleSaveObjects());
            //accessories = accessories.concat(getRig().getTrailer1().getAccessoryHolder().getAccessorySaveObjects());
            mergeAccessories(accessories, getRig().getTrailer1().getAccessoryHolder().getAccessorySaveObjects(false, true));
            payloads = payloads.concat(getRig().getTrailer1().getPayloadHolder().getPayloadSaveObjects());

            trailers.push(getRig().getTrailer1().returnObjectToBeSaved());

            if (getRig().getTrailer2() !== null) {
                axles = axles.concat(getRig().getTrailer2().getAxlesHolder().getAxleSaveObjects());
                //accessories = accessories.concat(getRig().getTrailer2().getAccessoryHolder().getAccessorySaveObjects());
                mergeAccessories(accessories, getRig().getTrailer2().getAccessoryHolder().getAccessorySaveObjects(false, true));
                payloads = payloads.concat(getRig().getTrailer2().getPayloadHolder().getPayloadSaveObjects());

                trailers.push(getRig().getTrailer2().returnObjectToBeSaved());

            }
        }

        if (getSpecification() !== undefined) {
            if (getSpecification().getSelectedCompetitor1() !== undefined && getSpecification().getSelectedCompetitor1() !== null) {
                if (getSelectedCompetitor1Id() !== getSpecification().getSelectedCompetitor1().vehicleId) {
                    setSelectedCompetitor1Id(getSpecification().getSelectedCompetitor1().vehicleId);
                }
            }
        }

        if (getSpecification() !== undefined) {
            if (getSpecification().getSelectedCompetitor2() !== undefined && getSpecification().getSelectedCompetitor2() !== null) {
                if (getSelectedCompetitor2Id() !== getSpecification().getSelectedCompetitor2().vehicleId) {
                    setSelectedCompetitor2Id(getSpecification().getSelectedCompetitor2().vehicleId);
                }
            }
        }

        //var returnObject = {
        //    "Id": getId(),
        //    "VehicleId": getVehicleId(),
        //    "VehicleColour": getConfiguration().getActiveCabColourToBeSaved(),
        //    "TripDistanceOutbound": getPerformance().getTripDistanceOutbound(),
        //    "TotalFuelOutbound": getPerformance().getTotalFuelOutbound(),
        //    "TripTimeOutbound": getPerformance().getTripTimeOutbound(),
        //    "FuelConsumptionOutbound": getCosting().getFuelConsumptionOutbound(),
        //    "TripDistanceReturn": getPerformance().getTripDistanceReturn(),
        //    "TotalFuelReturn": getPerformance().getTotalFuelReturn(),
        //    "TripTimeReturn": getPerformance().getTripTimeReturn(),
        //    "FuelConsumptionReturn": getCosting().getFuelConsumptionReturn(),
        //    "NumberOfVehicles": getCosting().getNoOfUnits(),
        //    "OriginalVehiclePrice": getCosting().getOriginalVehiclePrice(),
        //    "VehiclePrice": getCosting().getVehiclePrice(),
        //    "OriginalBodyAndEquipmentPrice": getCosting().getOriginalBodyAndEquipmentPrice(),
        //    "BodyAndEquipmentPrice": getCosting().getBodyAndEquipmentPrice(),
        //    "OriginalOptionPrice": getCosting().getOriginalOptionPrice(),
        //    "OptionPrice": getCosting().getOptionPrice(),
        //    "FuelConsumption": getCosting().getFuelConsumption(),
        //    "MonthlyKilometres": getCosting().getMonthlyDistance(),
        //    "FuelCost": getCosting().getFuelCost(),
        //    "MaintenanceCost": getCosting().getMaintenanceRate(),
        //    "RepaymentTerm": getCosting().getRepaymentTerm(),
        //    "InterestRate": getCosting().getInterestRate(),
        //    "Deposit": getCosting().getDepositRate(),
        //    "Residual": getCosting().getResidualRate(),
        //    "RegistrationFee": getCosting().getRegistrationFee(),
        //    "RegistrationFeePercent": getCosting().getRegistrationFeePercent(),
        //    "NumberPlateFee": getCosting().getNumberPlatesCost(),
        //    "InsuranceFee": getCosting().getInsuranceFeeCost(),
        //    "VehicleCertificationFee": getCosting().getVehicleCertificationCost(),
        //    "RegistrationServiceFee": getCosting().getRegistrationServiceCost(),
        //    "RoadTax": getCosting().getRoadTaxCost(),
        //    "Discount": getCosting().getSummaryDiscount(),
        //    "SummaryNote": getSummaryNote(),
        //    "UpdateCounter": getUpdateCounter(),
        //    "Waypoints": waypointsArray,
        //    "BrochureOptions": brochureOptionsArray,
        //    "BodyManufacturer": getBodyManufacturer(),
        //    "BodyType": getBodyType(),
        //    "OnRoadCostsCosts": onRoadCostsCostsArray,
        //    "OnRoadCostsDiscounts": onRoadCostsDiscountsArray,
        //    "OnRoadCostsVersion": getOnRoadCostsVersion(),
        //    "AdditionalNote": getAdditionalNote(),
        //    "MeasurementSystemId": getMeasurementSystemId(),
        //    "LegislationId": getLegislationId(),
        //    "LengthIncrement": getLengthIncrement(),
        //    "MassIncrement": getMassIncrement(),
        //    "PercentageIncrement": getPercentageIncrement(),
        //    "ApplicationId": getApplicationId(),
        //    "Axle2FrontToAxle1Rear": Math.round(getConfiguration().getAxle2FrontToAxle1Rear()),
        //    "RearOverhang": Math.round(getConfiguration().getRearOverhang()),
        //    "VehicleMake": getVehicleMake(),
        //    "VehicleRange": getVehicleRange(),
        //    "StructureVersion": 2,
        //    "FrontOverhang": Math.round(getConfiguration().getFrontOverhang()),
        //    "Axle1FrontToAxle2Front": Math.round(getConfiguration().getAxle1FrontToAxle2Front()),
        //    "Axle1RearToAxle2Rear": Math.round(getConfiguration().getAxle1RearToAxle2Rear()),
        //    "Axle2RearToAxle3Rear": Math.round(getConfiguration().getAxle2RearToAxle3Rear()),
        //    "Axle3RearToAxle4Rear": Math.round(getConfiguration().getAxle3RearToAxle4Rear()),
        //    "RearProtrusion": Math.round(getConfiguration().getRearProtrusion()),
        //    "WheelbaseTheoretical": Math.round(getConfiguration().getWheelbaseTheoretical()),
        //    "WheelbaseTheoreticalOverride": getConfiguration().getWheelbaseTheoreticalOverride(),
        //    "BumperToBackOfCab": Math.round(getConfiguration().getBumperToBackOfCab()),
        //    "RearMostProtrudingItemBehindCabToRearAxles": Math.round(getConfiguration().getRearMostProtrudingItemBehindCabToRearAxles()),
        //    "CabGapRecommendedMin": Math.round(getConfiguration().getCabGapRecommendedMin()),
        //    "CabTilt": getConfiguration().getCabTilt(),
        //    "HingePointFromBumper": Math.round(getConfiguration().getHingePointFromBumper()),
        //    "HingePointFromGround": Math.round(getConfiguration().getHingePointFromGround()),
        //    "CabTiltRadius": getConfiguration().getCabTiltRadius(),
        //    "CabHeight": Math.round(getConfiguration().getCabHeight()),
        //    "ChassisHeightFront": Math.round(getConfiguration().getChassisHeightFront()),
        //    "ChassisHeightRear": Math.round(getConfiguration().getChassisHeightRear()),
        //    "CabWidth": Math.round(getConfiguration().getCabWidth()),
        //    "RearWidth": Math.round(getConfiguration().getRearWidth()),
        //    "OverallWidth": getConfiguration().getOverallWidth(),
        //    "TrackWidthFront": Math.round(getConfiguration().getTrackWidthFront()),
        //    "TrackWidthRear": Math.round(getConfiguration().getTrackWidthRear()),
        //    "ChassisWidth": Math.round(getConfiguration().getChassisWidth()),
        //    "BumperToStartOfBodyInternal": Math.round(getConfiguration().getBumperToStartOfBodyInternal()),
        //    "BodyLengthInternal": Math.round(getConfiguration().getBodyLengthInternal()),
        //    "BodyHeightInternal": Math.round(getConfiguration().getBodyHeightInternal()),
        //    "FloorHeight": Math.round(getConfiguration().getFloorHeight()),
        //    "FifthWheelHeightFromGround": Math.round(getConfiguration().getFifthWheelHeightFromGround()),
        //    "TareFrontOverride": getConfiguration().getTareFrontOverride(),
        //    "TareRearOverride": getConfiguration().getTareRearOverride(),
        //    "VehicleVerticalCOG": Math.round(getConfiguration().getVehicleVerticalCOG()),
        //    "VehicleVerticalCOGOverride": getConfiguration().getVehicleVerticalCOGOverride(),
        //    "TareToolsIncluded": getConfiguration().getTareToolsIncluded(),
        //    "TareSpareWheelIncluded": getConfiguration().getTareSpareWheelIncluded(),
        //    "GAFront": getConfiguration().getGAFront(),
        //    "GARear": getConfiguration().getGARear(),
        //    "AUFront": getConfiguration().getAUFront(),
        //    "AURear": getConfiguration().getAURear(),
        //    "ManufacturersFrontAxlesMassOverride": getConfiguration().getManufacturersFrontAxlesMassOverride(),
        //    "ManufacturersRearAxlesMassOverride": getConfiguration().getManufacturersRearAxlesMassOverride(),
        //    "ManufacturersGrossVehicleMassOverride": getConfiguration().getManufacturersGrossVehicleMassOverride(),
        //    "GVM": getConfiguration().getGVM(),
        //    "GCM": getConfiguration().getGCM(),
        //    "FrontRoadFriendlySuspension": getConfiguration().getFrontRoadFriendlySuspension(),
        //    "RearRoadFriendlySuspension": getConfiguration().getRearRoadFriendlySuspension(),
        //    "RolloverProtection": getConfiguration().getRolloverProtection(),
        //    "FrontUnderrunProtection": getConfiguration().getFrontUnderrunProtection(),
        //    "PermissibleRear": getConfiguration().getPermissibleRear(),
        //    "PermissibleFront": getConfiguration().getPermissibleFront(),
        //    "PermissibleTotal": getConfiguration().getPermissibleTotal(),
        //    "PermissibleRearOverride": getConfiguration().getPermissibleRearOverride(),
        //    "PermissibleFrontOverride": getConfiguration().getPermissibleFrontOverride(),
        //    "PermissibleTotalOverride": getConfiguration().getPermissibleTotalOverride(),
        //    "CrewMassPerPerson": getConfiguration().getCrewMassPerPerson(),
        //    "NoOfCrewInFirstRow": getConfiguration().getNoOfCrewInFirstRow(),
        //    "CrewFirstRowHorizontalCOGOverride": getConfiguration().getCrewFirstRowHorizontalCOGOverride(),
        //    //"CrewFirstRowCOG": getVehicleCrewFirstRowHorizontalCOG(),
        //    "CrewFirstRowHorizontalCOG": rigOps.getVehicleCrewFirstRowHorizontalCOG(),
        //    "CrewFirstRowVerticalCOGOverride": getConfiguration().getCrewFirstRowVerticalCOGOverride(),
        //    "CrewFirstRowVerticalCOG": Math.round(getConfiguration().getCrewFirstRowVerticalCOG()),
        //    "CrewSecondRow": getConfiguration().getCrewSecondRow(),
        //    "NoOfCrewInSecondRow": getConfiguration().getNoOfCrewInSecondRow(),
        //    "CrewSecondRowHorizontalCOGOverride": getConfiguration().getCrewSecondRowHorizontalCOGOverride(),
        //    //"CrewSecondRowCOG": getConfiguration().getCrewSecondRowCOG(),
        //    "CrewSecondRowHorizontalCOG": getConfiguration().getCrewSecondRowCOG(),
        //    "CrewSecondRowVerticalCOGOverride": getConfiguration().getCrewSecondRowVerticalCOGOverride(),
        //    "CrewSecondRowVerticalCOG": Math.round(getConfiguration().getCrewSecondRowVerticalCOG()),
        //    "FuelType": getConfiguration().getFuelType(),
        //    "FuelSpecificGravity": getConfiguration().getFuelSpecificGravity(),
        //    "TareFront": getConfiguration().getTareFront(),
        //    "TareRear": getConfiguration().getTareRear(),
        //    "FuelTanks": fuelTanksArray,
        //    "Accessories": accessories,
        //    "Description": getDescription(),
        //    "AdditionalDescription": getAdditionalDescription(),
        //    "AxleLayout": getVehicleAxleLayout(),
        //    "VehicleType": "",
        //    "SpecDate": getSpecDate() || "",
        //    "Payloads": payloads,
        //    "Reports": reports,
        //    "PreparedForName": getPreparedForName(),
        //    "PreparedForTelephone": getPreparedForTelephone(),
        //    "ApplyHigherMassLimits": getApplyHigherMassLimits(),
        //    "Source": getConfiguration().getSource(),
        //    "Axle1FrontToWheelbaseTheoreticalStart": Math.round(getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart()),
        //    "Axle1FrontToWheelbaseTheoreticalStartOverride": getConfiguration().getAxle1FrontToWheelbaseTheoreticalStartOverride(),
        //    "TareDriverIncluded": getConfiguration().getTareDriverIncluded(),
        //    "Axles": axles,
        //    "ManufacturersGrossCombinationMassOverride": getConfiguration().getManufacturersGrossCombinationMassOverride(),
        //    "FuelConsumptionOverride": getCosting().getFuelConsumptionOverride(),
        //    "DriverRemuneration": getCosting().getDriverRemuneration(),
        //    "InsuranceRate": getCosting().getInsuranceRate(),
        //    "OverheadRate": getCosting().getOverheadRate(),
        //    "TotalRevenue": getCosting().getTotalRevenue(),
        //    "TyreRate": getCosting().getTyreRate(),
        //    "LicenceFees": getCosting().getLicenceFees(),
        //    "TollFees": getCosting().getTollFees(),
        //    "CoefficientOfDrag": getPerformance().getCoefficientOfDrag(),
        //    "CoefficientOfDragOverride": getPerformance().getCoefficientOfDragOverride(),
        //    "SpeedMaxLoaded": getPerformance().getSpeedMaxLoaded(),
        //    "RevsMaxPullingLoaded": getPerformance().getRevsMaxPullingLoaded(),
        //    "TopGearRatio": getPerformance().getTopGearRatio(),
        //    "VehicleTotalUnladenOverride": getPerformance().getVehicleTotalUnladenOverride(),
        //    "VehicleTotalUnladen": getPerformance().getVehicleTotalUnladen(),
        //    "FrontalAreaOverride": getPerformance().getFrontalAreaOverride(),
        //    "FrontalArea": getPerformance().getFrontalArea(),
        //    "DriveAxleRatioOverride": getPerformance().getDriveAxleRatioOverride(),
        //    "DriveAxleRatio": getPerformance().getDriveAxleRatio(),
        //    "TrailerTotalUnladenOverride": getPerformance().getTrailerTotalUnladenOverride(),
        //    "TrailerTotalUnladen": getPerformance().getTrailerTotalUnladen(),
        //    "AltitudeProfile": getPerformance().getAltitudeProfileEntriesBase64(),
        //    "SimResAverageAdblue": getPerformance().getSimResAverageAdblue(),
        //    "SimResAverageCO2Emissions": getPerformance().getSimResAverageCO2Emissions(),
        //    "SimResAverageFuel": getPerformance().getSimResAverageFuel(),
        //    "SimResAverageSpeed": getPerformance().getSimResAverageSpeed(),
        //    "SimResProductivity": getPerformance().getSimResProductivity(),
        //    "SimResTotalAdblue": getPerformance().getSimResTotalAdblue(),
        //    "SimResTotalCO2Emissions": getPerformance().getSimResTotalCO2Emissions(),
        //    "SimResTotalFuel": getPerformance().getSimResTotalFuel(),
        //    "SimResTripDistance": getPerformance().getSimResTripDistance(),
        //    "SimResTripTime": getPerformance().getSimResTripTime(),
        //    "CabWidthOverride": getConfiguration().getCabWidthOverride(),
        //    "RearWidthOverride": getConfiguration().getRearWidthOverride(),
        //    "TrackWidthFrontOverride": getConfiguration().getTrackWidthFrontOverride(),
        //    "TrackWidthRearOverride": getConfiguration().getTrackWidthRearOverride(),
        //    "ChassisWidthOverride": getConfiguration().getChassisWidthOverride(),
        //    "TurningAbilitySpecifiedAs": getConfiguration().getTurningAbilitySpecifiedAs(),
        //    "TurningRadiusCurbToCurbOverride": getConfiguration().getTurningRadiusCurbToCurbOverride(),
        //    "TurningRadiusCurbToCurb": getConfiguration().getTurningRadiusCurbToCurb(),
        //    "TurningRadiusWallToWallOverride": getConfiguration().getTurningRadiusWallToWallOverride(),
        //    "TurningRadiusWallToWall": getConfiguration().getTurningRadiusWallToWall(),
        //    "SteeringAngleInnerOverride": getConfiguration().getSteeringAngleInnerOverride(),
        //    "SteeringAngleInner": getConfiguration().getSteeringAngleInner(),
        //    "Axle1FrontToAxle2FrontOverride": getConfiguration().getAxle1FrontToAxle2FrontOverride(),
        //    "Axle2FrontToAxle1RearOverride": getConfiguration().getAxle2FrontToAxle1RearOverride(),
        //    "Axle1RearToAxle2RearOverride": getConfiguration().getAxle1RearToAxle2RearOverride(),
        //    "Axle2RearToAxle3RearOverride": getConfiguration().getAxle2RearToAxle3RearOverride(),
        //    "Axle3RearToAxle4RearOverride": getConfiguration().getAxle3RearToAxle4RearOverride(),
        //    "PayloadType": getConfiguration().getPayloadHolder().getPayloadType(),
        //    "VehicleModelCode": getVehicleModelCode()
        //};

        var returnObject = {}
        returnObject["Id"] = getId();
        returnObject["VehicleId"] = getVehicleId();
        
        //if (getConfiguration()) {
        addConfigurationAttributesToObject(returnObject, rigOps);
        calculateWheelbaseSpecfiedValuesAndAddToObject(returnObject, rigOps);
        returnObject["Axles"] = axles;
        returnObject["FuelTanks"] = fuelTanksArray;
        returnObject["Accessories"] = accessories;
        returnObject["Payloads"] = payloads;
        //}

        if (getPerformance()) {
            returnObject["TripDistanceOutbound"] = getPerformance().getTripDistanceOutbound() || 0;
            returnObject["TotalFuelOutbound"] = getPerformance().getTotalFuelOutbound() || 0;
            returnObject["TripTimeOutbound"] = getPerformance().getTripTimeOutbound() || 0;
            returnObject["TripDistanceReturn"] = getPerformance().getTripDistanceReturn() || 0;
            returnObject["TotalFuelReturn"] = getPerformance().getTotalFuelReturn() || 0;
            returnObject["TripTimeReturn"] = getPerformance().getTripTimeReturn() || 0;
            returnObject["CoefficientOfDrag"] = getPerformance().getCoefficientOfDrag() || 0;
            returnObject["CoefficientOfDragOverride"] = getPerformance().getCoefficientOfDragOverride() || false;
            returnObject["SpeedMaxLoaded"] = getPerformance().getSpeedMaxLoaded() || 0;
            returnObject["RevsMaxPullingLoaded"] = getPerformance().getRevsMaxPullingLoaded() || 0;
            returnObject["TopGearRatio"] = getPerformance().getTopGearRatio() || 0;
            returnObject["VehicleTotalUnladenOverride"] = getPerformance().getVehicleTotalUnladenOverride() || false;
            returnObject["VehicleTotalUnladen"] = getPerformance().getVehicleTotalUnladen() || 0;
            returnObject["FrontalAreaOverride"] = getPerformance().getFrontalAreaOverride() || false;
            returnObject["FrontalArea"] = getPerformance().getFrontalAreaForSimulation() || 0;
            returnObject["DriveAxleRatioOverride"] = getPerformance().getDriveAxleRatioOverride() || false;
            returnObject["DriveAxleRatio"] = getPerformance().getDriveAxleRatio() || 0;
            returnObject["TrailerTotalUnladenOverride"] = getPerformance().getTrailerTotalUnladenOverride() || false;                    
            returnObject["TrailerTotalUnladen"] = getPerformance().getTrailerTotalUnladen() || 0;
            returnObject["Trailer2TotalUnladenOverride"] = getPerformance().getTrailer2TotalUnladenOverride()|| false;
            returnObject["Trailer2TotalUnladen"] = getPerformance().getTrailer2TotalUnladen() || false;
            returnObject["AltitudeProfile"] = getPerformance().getAltitudeProfileEntriesBase64() || '';
            returnObject["SimResAverageAdblue"] = getPerformance().getSimResAverageAdblue() || 0;
            returnObject["SimResAverageCO2Emissions"] = getPerformance().getSimResAverageCO2Emissions() || 0;
            returnObject["SimResAverageFuel"] = getPerformance().getSimResAverageFuel() || 0;
            returnObject["SimResAverageSpeed"] = getPerformance().getSimResAverageSpeed() || 0;
            returnObject["SimResProductivity"] = getPerformance().getSimResProductivity() || 0;
            returnObject["SimResTotalAdblue"] = getPerformance().getSimResTotalAdblue() || 0;
            returnObject["SimResTotalCO2Emissions"] = getPerformance().getSimResTotalCO2Emissions() || 0;
            returnObject["SimResTotalFuel"] = getPerformance().getSimResTotalFuel() || 0;
            returnObject["SimResTripDistance"] = getPerformance().getSimResTripDistance() || 0;
            returnObject["SimResTripTime"] = getPerformance().getSimResTripTime() || 0;
            returnObject["SimResTotalTimeInTopGear"] = getPerformance().getSimResTotalTimeInTopGear() || 0;
            returnObject["SimResTotalGearChanges"] = getPerformance().getSimResTotalGearChanges() || 0;
        }

        if (getCosting()) {
            returnObject["FuelConsumptionOutbound"] = getCosting().getFuelConsumptionOutbound() || 0;
            returnObject["FuelConsumptionReturn"] = getCosting().getFuelConsumptionReturn() || 0;
            returnObject["NumberOfVehicles"] = getCosting().getNoOfUnits() || 0;
            returnObject["OriginalVehiclePrice"] = getCosting().getOriginalVehiclePrice() || 0;
            returnObject["VehiclePrice"] = getCosting().getVehiclePrice() || 0;
            returnObject["OriginalBodyAndEquipmentPrice"] = getCosting().getOriginalBodyAndEquipmentPrice() || 0;
            returnObject["BodyAndEquipmentPrice"] = getCosting().getBodyAndEquipmentPrice() || 0;
            returnObject["OriginalOptionPrice"] = getCosting().getOriginalOptionPrice() || 0;
            returnObject["OptionPrice"] = getCosting().getOptionPrice() || 0;
            returnObject["FuelConsumption"] = getCosting().getFuelConsumption() || 0;
            returnObject["MonthlyKilometres"] = getCosting().getMonthlyDistance() || 0;
            returnObject["FuelCost"] = getCosting().getFuelCost() || 0;
            returnObject["MaintenanceCost"] = getCosting().getMaintenanceRate() || 0;
            returnObject["RepaymentTerm"] = getCosting().getRepaymentTerm() || 0;
            returnObject["InterestRate"] = getCosting().getInterestRate() || 0;
            returnObject["Deposit"] = getCosting().getDepositRate() || 0;
            returnObject["Residual"] = getCosting().getResidualRate() || 0;
            returnObject["RegistrationFee"] = getCosting().getRegistrationFee() || 0;
            returnObject["RegistrationFeePercent"] = getCosting().getRegistrationFeePercent() || 0;
            returnObject["NumberPlateFee"] = getCosting().getNumberPlatesCost() || 0;
            returnObject["InsuranceFee"] = getCosting().getInsuranceFeeCost() || 0;
            returnObject["VehicleCertificationFee"] = getCosting().getVehicleCertificationCost() || 0;
            returnObject["RegistrationServiceFee"] = getCosting().getRegistrationServiceCost() || 0;
            returnObject["RoadTax"] = getCosting().getRoadTaxCost() || 0;
            returnObject["Discount"] = getCosting().getSummaryDiscount() || 0;
            returnObject["FuelConsumptionOverride"] = getCosting().getFuelConsumptionOverride() || false;
            returnObject["DriverRemuneration"] = getCosting().getDriverRemuneration() || 0;
            returnObject["InsuranceRate"] = getCosting().getInsuranceRate() || 0;
            returnObject["OverheadRate"] = getCosting().getOverheadRate() || 0;
            returnObject["TotalRevenue"] = getCosting().getTotalRevenueAsCurrencyPerKm() || 0;
            // returnObject["TotalRevenue"] = getCosting().getTotalRevenue();
            // returnObject["TyreRate"] = getCosting().getTyreRate();
            returnObject["TyreRate"] = getCosting().getTyreRateAsCentsPerKm() || 0;
            returnObject["TyreRateOverride"] = getCosting().getTyreRateOverride() || false;
            returnObject["LicenceFees"] = getCosting().getLicenceFees() || 0;
            returnObject["LicenceFeesOverride"] = getCosting().getLicenceFeesOverride() || false;
            returnObject["TollFees"] = getCosting().getTollFees() || 0;
            returnObject["VehicleLicenceFees"] = getCosting().getVehicleLicenceFees() || 0;
            returnObject["VehicleLicenceFeesOverride"] = getCosting().getVehicleLicenceFeesOverride() || false;
            returnObject["VehicleLicenceFeesRegionId"] = getCosting().getVehicleLicenceFeesRegionId();

            returnObject["VehiclePrice"] = getCosting().getVehiclePrice() || 0;
            returnObject["VehiclePriceOverride"] = getCosting().getVehiclePriceOverride() || false;
            returnObject["VehicleDiscount"] = getCosting().getVehicleDiscount() || 0;
            returnObject["VehiclePeriod"] = getCosting().getVehiclePeriod() || 0;
            returnObject["VehicleInterestRate"] = getCosting().getVehicleInterestRate() || 0;
            returnObject["VehicleResidualRate"] = getCosting().getVehicleResidualRate() || 0;
            returnObject["VehicleResidualRateType"] = getCosting().getVehicleResidualRateType() || '';
            returnObject["FinanceTotalPrice"] = getCosting().getFinanceTotalPrice() || 0;
            returnObject["FinanceTotalPriceOverride"] = getCosting().getFinanceTotalPriceOverride() || false;

            returnObject["MonthlyTrips"] = getCosting().getMonthlyTrips() || 0;
            returnObject["TollFeesOverride"] = getCosting().getTollFeesOverride() || false;
            returnObject["CostingTollFeesPerTrip"] = getCosting().getTollFeesPerTrip() || 0;
            returnObject["CostingTollFeesPerTripOverride"] = getCosting().getTollFeesPerTripOverride() || false;
            returnObject["CostingPermitCostsPerTrip"] = getCosting().getPermitCostPerTrip() || 0;
            returnObject["CostingVehicleClassId"] = getCosting().getVehicleClassId();
            returnObject["CostingVehicleClassIdOverride"] = getCosting().getVehicleClassIdOverride() || false;
            //returnObject["CostingPermitCostsPerTrip"] = getCosting().getPermitCostsPerTrip();
            //returnObject["CostingVehicleClassId"] = getCosting().getCostingVehicleClassId();
            //returnObject["CostingVehicleClassIdOverride"] = getCosting().getCostingVehicleClassIdOverride();
            returnObject["FuelAndAdblueCostPerMonth"] = getCosting().getFuelAndAdblueCostPerMonth() || 0;
            returnObject["FuelAndAdblueCostPerMonthOverride"] = getCosting().getFuelAndAdblueCostPerMonthOverride() || false;
            returnObject["VehicleFuelAndAdblueTotalCostPerMonth"] = getCosting().getVehicleFuelAndAdblueTotalCostPerMonth() || 0;
            returnObject["VehicleFuelAndAdblueTotalCostPerMonthOverride"] = getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride() || false;
            returnObject["VehicleFuelAndAdblueFuelConsumption"] = getCosting().getVehicleFuelAndAdblueFuelConsumption() || 0;
            returnObject["VehicleFuelAndAdblueFuelConsumptionOverride"] = getCosting().getVehicleFuelAndAdblueFuelConsumptionOverride() || false;
            returnObject["VehicleFuelAndAdblueFuelCostPerVolume"] = getCosting().getVehicleFuelAndAdblueFuelCostPerVolume() || 0;
            returnObject["VehicleFuelAndAdblueFuelCostPerVolumeOverride"] = getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOverride() || false;
            returnObject["VehicleFuelAndAdblueAdblueConsumption"] = getCosting().getVehicleFuelAndAdblueAdblueConsumption() || 0;
            returnObject["VehicleFuelAndAdblueAdblueConsumptionOverride"] = getCosting().getVehicleFuelAndAdblueAdblueConsumptionOverride() || false;
            returnObject["VehicleFuelAndAdblueAdblueCostPerVolume"] = getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume() || 0;
            returnObject["VehicleFuelAndAdblueAdblueCostPerVolumeOverride"] = getCosting().getVehicleFuelAndAdblueAdblueCostPerVolumeOverride() || false;
            returnObject["VehicleFuelAndAdblueEquipmentFuelConsumption"] = getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption() || 0;
            returnObject["VehicleFuelAndAdblueEquipmentFuelConsumptionOverride"] = getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumptionOverride() || false;
            returnObject["VehicleFuelAndAdblueEquipmentFuelCostPerVolume"] = getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume() || 0;
            returnObject["VehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride"] = getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride() || false;
            returnObject["VehicleFuelAndAdblueEquipmentUtilisation"] = getCosting().getVehicleFuelAndAdblueEquipmentUtilisation() || 0;
        }

        returnObject["SummaryNote"] = getSummaryNote();
        returnObject["UpdateCounter"] = getUpdateCounter();
        returnObject["Waypoints"] = waypointsArray;
        returnObject["OfferTollFees"] = offerTollFees;
        returnObject["BodyManufacturer"] = getBodyManufacturer();
        returnObject["BodyType"] = getBodyType();
        returnObject["OnRoadCostsCosts"] = onRoadCostsCostsArray;
        returnObject["OnRoadCostsDiscounts"] = onRoadCostsDiscountsArray;
        // returnObject["OnRoadCostsVersion"] = getOnRoadCostsVersion();
        returnObject["AdditionalNote"] = getAdditionalNote();
        returnObject["MeasurementSystemId"] = getMeasurementSystemId();
        //returnObject["LegislationId"] = getLegislationId();
        returnObject["LengthIncrement"] = getLengthIncrement();
        returnObject["MassIncrement"] = getMassIncrement();
        returnObject["PercentageIncrement"] = getPercentageIncrement();
        returnObject["ApplicationId"] = getApplicationId();
        
        returnObject["VehicleMake"] = getVehicleMake();
        returnObject["VehicleRange"] = getVehicleRange();
        returnObject["StructureVersion"] = globals.getPaddedStructureVersionNumber()//3;
        
        
        
        returnObject["Description"] = getDescription();
        returnObject["AdditionalDescription"] = '';//getAdditionalDescription();
        returnObject["AxleLayout"] = getVehicleAxleLayout();
        returnObject["VehicleType"] = "";
        returnObject["SpecDate"] = getSpecDate() || "";
        
        returnObject["Reports"] = reports;
        returnObject["PreparedForName"] = getPreparedForName();
        returnObject["PreparedForTelephone"] = getPreparedForTelephone();
        returnObject["ApplyHigherMassLimits"] = getApplyHigherMassLimits();
        returnObject["KeepChassisFlushWithBody"] = getKeepChassisFlushWithBody();

        returnObject["VehicleModelCode"] = getVehicleModelCode();
        returnObject["IsSupportOffer"] = getIsSupportOffer();
        returnObject["SupportUserId"] = getSupportUserId();

        if (getSpecification() !== undefined) {
            returnObject["Competitor1Id"] = getSpecification().getSelectedCompetitor1Id();
            returnObject["Competitor2Id"] = getSpecification().getSelectedCompetitor2Id();
        }

        if (trailers.length > 0) {
            returnObject["Trailers"] = trailers;
        }

        returnObject["PropertiesLegislations"] = globals.user.getDashboardConfiguration().getDashboardLegislationsToBeSaved();

        return returnObject;
    }

    function getPendingVehicleDetailsToBeSaved(rigOps) {
        var returnObject = {};
        //returnObject["VehicleColour"] = getConfiguration().getActiveCabColourToBeSaved();
        returnObject["Axle2FrontToAxle1Rear"] = getConfiguration().getAxle2FrontToAxle1Rear();
        returnObject["OverhangRear"] = getConfiguration().getRearOverhang();
        returnObject["OverhangFront"] = getConfiguration().getFrontOverhang();
        returnObject["Axle1FrontToAxle2Front"] = getConfiguration().getAxle1FrontToAxle2Front();
        returnObject["Axle1RearToAxle2Rear"] = getConfiguration().getAxle1RearToAxle2Rear();
        returnObject["Axle2RearToAxle3Rear"] = getConfiguration().getAxle2RearToAxle3Rear();
        returnObject["Axle3RearToAxle4Rear"] = getConfiguration().getAxle3RearToAxle4Rear();
        returnObject["RearProtrusion"] = getConfiguration().getRearProtrusion();
        returnObject["Wheelbase"] = getConfiguration().getWheelbaseTheoretical();
        //returnObject["WheelbaseTheoreticalOverride"] = getConfiguration().getWheelbaseTheoreticalOverride();
        returnObject["BBC"] = getConfiguration().getBumperToBackOfCab();
        returnObject["CabToAxles"] = rigOps.getCabToAxles();
        returnObject["CabToAxlesOverride"] = getConfiguration().getCabToAxlesOverride();
        returnObject["RearMostProtrudingItemBehindCabToRearAxles"] = getConfiguration().getRearMostProtrudingItemBehindCabToRearAxles();
        returnObject["CabGapRecommendedMin"] = getConfiguration().getCabGapRecommendedMin();
        returnObject["TiltCab"] = getConfiguration().getCabTilt();
        returnObject["CabHingePointFromBumper"] = getConfiguration().getHingePointFromBumper();
        returnObject["CabHingePointFromGround"] = getConfiguration().getHingePointFromGround();
        returnObject["CabTiltRadius"] = getConfiguration().getCabTiltRadius();
        returnObject["CabHeight"] = getConfiguration().getCabHeight();
        returnObject["ChassisHeightFront"] = getConfiguration().getChassisHeightFront();
        returnObject["ChassisHeightRear"] = getConfiguration().getChassisHeightRear();
        returnObject["VerticalExhaustHeight"] = getConfiguration().getVerticalExhaustHeight();
        returnObject["GroundClearanceRear"] = getConfiguration().getGroundClearanceRear();
        returnObject["GroundClearanceFront"] = getConfiguration().getGroundClearanceFront();
        returnObject["CabWidth"] = getConfiguration().getCabWidth();
        returnObject["RearWidth"] = getConfiguration().getRearWidth();
        returnObject["OverallWidth"] = getConfiguration().getOverallWidth();
        returnObject["TrackWidthFront"] = getConfiguration().getTrackWidthFront();
        returnObject["TrackWidthRear"] = getConfiguration().getTrackWidthRear();
        returnObject["ChassisWidth"] = getConfiguration().getChassisWidth();
        returnObject["BumperToStartOfBodyInternal"] = getConfiguration().getBumperToStartOfBodyInternal();
        returnObject["BodyLengthInternal"] = getConfiguration().getBodyLengthInternal();
        returnObject["BodyHeightInternal"] = getConfiguration().getBodyHeightInternal();
        returnObject["FloorHeight"] = getConfiguration().getFloorHeight();
        returnObject["FifthWheelHeightFromGround"] = getConfiguration().getFifthWheelHeightFromGround();
        returnObject["TareFrontOverride"] = getConfiguration().getTareFrontOverride();
        returnObject["TareRearOverride"] = getConfiguration().getTareRearOverride();
        returnObject["VehicleVerticalCOG"] = getConfiguration().getVehicleVerticalCOG();
        returnObject["VehicleVerticalCOGOverride"] = getConfiguration().getVehicleVerticalCOGOverride();
        returnObject["VehicleLateralCOG"] = getConfiguration().getVehicleLateralCOG();
        returnObject["VehicleLateralCOGOverride"] = getConfiguration().getVehicleLateralCOGOverride();
        returnObject["TareToolsIncluded"] = getConfiguration().getTareToolsIncluded();
        returnObject["TareSpareWheelIncluded"] = getConfiguration().getTareSpareWheelIncluded();
        returnObject["GAFront"] = getConfiguration().getGAFront();
        returnObject["GARear"] = getConfiguration().getGARear();
        returnObject["AUFront"] = getConfiguration().getAUFront();
        returnObject["AURear"] = getConfiguration().getAURear();
        returnObject["ManufacturersFrontAxlesMassOverride"] = getConfiguration().getManufacturersFrontAxlesMassOverride();
        returnObject["ManufacturersRearAxlesMassOverride"] = getConfiguration().getManufacturersRearAxlesMassOverride();
        returnObject["ManufacturersGrossVehicleMassOverride"] = getConfiguration().getManufacturersGrossVehicleMassOverride();
        returnObject["GVM"] = getConfiguration().getGVM();
        returnObject["GCM"] = getConfiguration().getGCM();
        returnObject["FrontRoadFriendlySuspension"] = getConfiguration().getFrontRoadFriendlySuspension();
        returnObject["RearRoadFriendlySuspension"] = getConfiguration().getRearRoadFriendlySuspension();
        returnObject["RolloverProtection"] = getConfiguration().getRolloverProtection();
        returnObject["FrontUnderrunProtection"] = getConfiguration().getFrontUnderrunProtection();
        //returnObject["PermissibleRear"] = getConfiguration().getPermissibleRear();
        //returnObject["PermissibleFront"] = getConfiguration().getPermissibleFront();
        //returnObject["PermissibleTotal"] = getConfiguration().getPermissibleTotal();
        //returnObject["PermissibleRearOverride"] = getConfiguration().getPermissibleRearOverride();
        //returnObject["PermissibleFrontOverride"] = getConfiguration().getPermissibleFrontOverride();
        //returnObject["PermissibleTotalOverride"] = getConfiguration().getPermissibleTotalOverride();
        returnObject["CrewMassPerPerson"] = getConfiguration().getCrewMassPerPerson();
        returnObject["NoOfCrewInFirstRow"] = getConfiguration().getNoOfCrewInFirstRow();
        //returnObject["CrewFirstRowHorizontalCOGOverride"] = getConfiguration().getCrewFirstRowHorizontalCOGOverride();
        //"CrewFirstRowCOG"] = getVehicleCrewFirstRowHorizontalCOG();
        returnObject["CrewFirstRowHorizontalCOG"] = rigOps.getVehicleCrewFirstRowHorizontalCOG();
        returnObject["CrewFirstRowVerticalCOGOverride"] = getConfiguration().getCrewFirstRowVerticalCOGOverride();
        returnObject["CrewFirstRowVerticalCOG"] = getConfiguration().getCrewFirstRowVerticalCOG();
        returnObject["CrewFirstRowLateralCOGOverride"] = getConfiguration().getCrewFirstRowLateralCOGOverride();
        returnObject["CrewFirstRowLateralCOG"] = getConfiguration().getCrewFirstRowLateralCOG();
        returnObject["CrewSecondRow"] = getConfiguration().getCrewSecondRow();
        returnObject["NoOfCrewInSecondRow"] = getConfiguration().getNoOfCrewInSecondRow();
        //returnObject["CrewSecondRowHorizontalCOGOverride"] = getConfiguration().getCrewSecondRowHorizontalCOGOverride();
        //"CrewSecondRowCOG"] = getConfiguration().getCrewSecondRowCOG();
        returnObject["CrewSecondRowHorizontalCOG"] = getConfiguration().getCrewSecondRowCOG();
        returnObject["CrewSecondRowVerticalCOGOverride"] = getConfiguration().getCrewSecondRowVerticalCOGOverride();
        returnObject["CrewSecondRowVerticalCOG"] = getConfiguration().getCrewSecondRowVerticalCOG();
        returnObject["CrewSecondRowLateralCOGOverride"] = getConfiguration().getCrewSecondRowLateralCOGOverride();
        returnObject["CrewSecondRowLateralCOG"] = getConfiguration().getCrewSecondRowLateralCOG();
        returnObject["FuelType"] = getConfiguration().getFuelType();
        returnObject["FuelSpecificGravity"] = getConfiguration().getFuelSpecificGravity();
        returnObject["TareFrontAxle"] = getConfiguration().getTareFront();
        returnObject["TareRearAxle"] = getConfiguration().getTareRear();
        returnObject["Source"] = getConfiguration().getSource();
        returnObject["Axle1FrontToWheelbaseTheoreticalStart"] = getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart();
        returnObject["Axle1FrontToWheelbaseTheoreticalStartOverride"] = getConfiguration().getAxle1FrontToWheelbaseTheoreticalStartOverride();
        returnObject["TareDriverIncluded"] = getConfiguration().getTareDriverIncluded();
        returnObject["ManufacturersGrossCombinationMassOverride"] = getConfiguration().getManufacturersGrossCombinationMassOverride();
        returnObject["CabWidthOverride"] = getConfiguration().getCabWidthOverride();
        returnObject["RearWidthOverride"] = getConfiguration().getRearWidthOverride();
        returnObject["TrackWidthFrontOverride"] = getConfiguration().getTrackWidthFrontOverride();
        returnObject["TrackWidthRearOverride"] = getConfiguration().getTrackWidthRearOverride();
        returnObject["ChassisWidthOverride"] = getConfiguration().getChassisWidthOverride();
        returnObject["TurningAbilitySpecifiedAs"] = getConfiguration().getTurningAbilitySpecifiedAs();
        //returnObject["TurningRadiusCurbToCurbOverride"] = getConfiguration().getTurningRadiusCurbToCurbOverride();
        returnObject["TurningRadiusCurbToCurb"] = getConfiguration().getTurningRadiusCurbToCurb();
        //returnObject["TurningRadiusWallToWallOverride"] = getConfiguration().getTurningRadiusWallToWallOverride();
        returnObject["TurningRadius"] = getConfiguration().getTurningRadiusWallToWall();
        returnObject["SteeringAngleInnerOverride"] = getConfiguration().getSteeringAngleInnerOverride();
        returnObject["SteeringAngleInner"] = getConfiguration().getSteeringAngleInner();
        returnObject["Axle1FrontToAxle2FrontOverride"] = getConfiguration().getAxle1FrontToAxle2FrontOverride();
        returnObject["Axle2FrontToAxle1RearOverride"] = getConfiguration().getAxle2FrontToAxle1RearOverride();
        returnObject["Axle1RearToAxle2RearOverride"] = getConfiguration().getAxle1RearToAxle2RearOverride();
        returnObject["Axle2RearToAxle3RearOverride"] = getConfiguration().getAxle2RearToAxle3RearOverride();
        returnObject["Axle3RearToAxle4RearOverride"] = getConfiguration().getAxle3RearToAxle4RearOverride();
        returnObject["PayloadType"] = getConfiguration().getPayloadHolder().getPayloadType();

        var simplePayload = getConfiguration().getPayloadHolder().getSimplePayloadObject();
        if (simplePayload !== null) {
            returnObject["PayloadCOGType"] = simplePayload.getCOGType();
            returnObject["PayloadHorizontalCOG"] = simplePayload.getHorizontalCOG();
            returnObject["PayloadVerticalCOG"] = simplePayload.getVerticalCOG();
            returnObject["PayloadVerticalCOGOverride"] = simplePayload.getVerticalCOGOverride();
            returnObject["PayloadLateralCOG"] = simplePayload.getLateralCOG();
            returnObject["PayloadLateralCOGOverride"] = simplePayload.getLateralCOGOverride();
        } else {
            returnObject["PayloadCOGType"] = getConfiguration().getPayloadCOGType();
            returnObject["PayloadHorizontalCOG"] = getConfiguration().getPayloadHorizontalCOG();
            returnObject["PayloadVerticalCOG"] = getConfiguration().getPayloadVerticalCOG();
            returnObject["PayloadVerticalCOGOverride"] = getConfiguration().getPayloadVerticalCOGOverride();
            returnObject["PayloadLateralCOG"] = getConfiguration().getPayloadLateralCOG();
            returnObject["PayloadLateralCOGOverride"] = getConfiguration().getPayloadLateralCOGOverride();
        }


        //returnObject["CoefficientOfDrag"] = getPerformance().getCoefficientOfDrag();


        returnObject["VehicleDescription"] = getConfiguration().getDescription();

        returnObject["CountryID"] = getCountryId();
        returnObject["RangeID"] = getRangeId();
        //returnObject["BodyTypeCode"] = getBodyType();
        addFuelTankAttributesForAppData(returnObject);
        returnObject["AxlePositions"] = getConfiguration().getAxlesHolder().getAxleSaveObjectsForAppData();

        //returnObject["BodyManufacturer"] = getBodyManufacturer();
        //returnObject["BodyType"] = getBodyType();
        //returnObject["VehicleMake"] = getVehicleMake();
        //returnObject["VehicleRange"] = getVehicleRange();
        returnObject["Description"] = getDescription();
        //returnObject["AxleLayout"] = getVehicleAxleLayout();




        returnObject["UpdateCounter"] = getConfiguration().getUpdateCounter();

        return returnObject;
    }

    function addConfigurationAttributesToObject(returnObject, rigOps) {
        returnObject["VehicleColour"] = getConfiguration().getActiveCabColourToBeSaved();
        returnObject["Axle2FrontToAxle1Rear"] = getConfiguration().getAxle2FrontToAxle1Rear();
        returnObject["RearOverhang"] = getConfiguration().getRearOverhang();
        returnObject["FrontOverhang"] = getConfiguration().getFrontOverhang();
        returnObject["Axle1FrontToAxle2Front"] = getConfiguration().getAxle1FrontToAxle2Front();
        returnObject["Axle1RearToAxle2Rear"] = getConfiguration().getAxle1RearToAxle2Rear();
        returnObject["Axle2RearToAxle3Rear"] = getConfiguration().getAxle2RearToAxle3Rear();
        returnObject["Axle3RearToAxle4Rear"] = getConfiguration().getAxle3RearToAxle4Rear();
        returnObject["RearProtrusion"] = getConfiguration().getRearProtrusion();
        returnObject["WheelbaseTheoretical"] = getConfiguration().getWheelbaseTheoretical();
        returnObject["WheelbaseTheoreticalOverride"] = getConfiguration().getWheelbaseTheoreticalOverride();
        returnObject["BumperToBackOfCab"] = getConfiguration().getBumperToBackOfCab();
        //returnObject["Axle1FrontToBackOfCab"] = Math.round(getConfiguration().getAxle1FrontToBackOfCab());
        returnObject["RearMostProtrudingItemBehindCabToRearAxles"] = getConfiguration().getRearMostProtrudingItemBehindCabToRearAxles();
        returnObject["CabGapRecommendedMin"] = getConfiguration().getCabGapRecommendedMin();
        returnObject["CabTilt"] = getConfiguration().getCabTilt();
        returnObject["HingePointFromBumper"] = getConfiguration().getHingePointFromBumper();
        returnObject["HingePointFromGround"] = getConfiguration().getHingePointFromGround();
        returnObject["CabTiltRadius"] = getConfiguration().getCabTiltRadius();
        returnObject["CabHeight"] = getConfiguration().getCabHeight();
        returnObject["ChassisHeightFront"] = getConfiguration().getChassisHeightFront();
        returnObject["ChassisHeightRear"] = getConfiguration().getChassisHeightRear();
        returnObject["CabWidth"] = getConfiguration().getCabWidth();
        returnObject["RearWidth"] = getConfiguration().getRearWidth();
        returnObject["OverallWidth"] = getConfiguration().getOverallWidth();
        returnObject["TrackWidthFront"] = getConfiguration().getTrackWidthFront();
        returnObject["TrackWidthRear"] = getConfiguration().getTrackWidthRear();
        returnObject["ChassisWidth"] = getConfiguration().getChassisWidth();
        returnObject["BumperToStartOfBodyInternal"] = getConfiguration().getBumperToStartOfBodyInternal();
        returnObject["BodyLengthInternal"] = getConfiguration().getBodyLengthInternal();
        returnObject["BodyHeightInternal"] = getConfiguration().getBodyHeightInternal();
        returnObject["FloorHeight"] = getConfiguration().getFloorHeight();
        returnObject["FifthWheelHeightFromGround"] = getConfiguration().getFifthWheelHeightFromGround();
        returnObject["FifthWheelOffset"] = getConfiguration().getFifthWheelOffset();
        returnObject["TareFrontOverride"] = getConfiguration().getTareFrontOverride();
        returnObject["TareRearOverride"] = getConfiguration().getTareRearOverride();
        returnObject["VehicleVerticalCOG"] = Math.round(getConfiguration().getVehicleVerticalCOG());
        returnObject["VehicleVerticalCOGOverride"] = getConfiguration().getVehicleVerticalCOGOverride();
        returnObject["TareToolsIncluded"] = getConfiguration().getTareToolsIncluded();
        returnObject["TareSpareWheelIncluded"] = getConfiguration().getTareSpareWheelIncluded();
        returnObject["GAFront"] = getConfiguration().getGAFront();
        returnObject["GARear"] = getConfiguration().getGARear();
        returnObject["AUFront"] = getConfiguration().getAUFront();
        returnObject["AURear"] = getConfiguration().getAURear();
        returnObject["ManufacturersFrontAxlesMassOverride"] = getConfiguration().getManufacturersFrontAxlesMassOverride();
        returnObject["ManufacturersRearAxlesMassOverride"] = getConfiguration().getManufacturersRearAxlesMassOverride();
        returnObject["ManufacturersGrossVehicleMassOverride"] = getConfiguration().getManufacturersGrossVehicleMassOverride();
        returnObject["GVM"] = getConfiguration().getGVM();
        returnObject["GCM"] = getConfiguration().getGCM();
        returnObject["FrontRoadFriendlySuspension"] = getConfiguration().getFrontRoadFriendlySuspension();
        returnObject["RearRoadFriendlySuspension"] = getConfiguration().getRearRoadFriendlySuspension();
        returnObject["RolloverProtection"] = getConfiguration().getRolloverProtection();
        returnObject["FrontUnderrunProtection"] = getConfiguration().getFrontUnderrunProtection();
        returnObject["PermissibleRear"] = getConfiguration().getPermissibleRear();
        returnObject["PermissibleFront"] = getConfiguration().getPermissibleFront();
        returnObject["PermissibleTotal"] = getConfiguration().getPermissibleTotal();
        returnObject["PermissibleRearOverride"] = getConfiguration().getPermissibleRearOverride();
        returnObject["PermissibleFrontOverride"] = getConfiguration().getPermissibleFrontOverride();
        returnObject["PermissibleTotalOverride"] = getConfiguration().getPermissibleTotalOverride();
        returnObject["CrewMassPerPerson"] = getConfiguration().getCrewMassPerPerson();
        returnObject["NoOfCrewInFirstRow"] = getConfiguration().getNoOfCrewInFirstRow();
        returnObject["CrewFirstRowHorizontalCOGOverride"] = getConfiguration().getCrewFirstRowHorizontalCOGOverride();
        //"CrewFirstRowCOG"] = getVehicleCrewFirstRowHorizontalCOG();
        returnObject["CrewFirstRowHorizontalCOG"] = rigOps.getVehicleCrewFirstRowHorizontalCOG();
        returnObject["CrewFirstRowVerticalCOGOverride"] = getConfiguration().getCrewFirstRowVerticalCOGOverride();
        returnObject["CrewFirstRowVerticalCOG"] = getConfiguration().getCrewFirstRowVerticalCOG();
        returnObject["CrewSecondRow"] = getConfiguration().getCrewSecondRow();
        returnObject["NoOfCrewInSecondRow"] = getConfiguration().getNoOfCrewInSecondRow();
        returnObject["CrewSecondRowHorizontalCOGOverride"] = getConfiguration().getCrewSecondRowHorizontalCOGOverride();
        //"CrewSecondRowCOG"] = getConfiguration().getCrewSecondRowCOG();
        returnObject["CrewSecondRowHorizontalCOG"] = getConfiguration().getCrewSecondRowCOG();
        returnObject["CrewSecondRowVerticalCOGOverride"] = getConfiguration().getCrewSecondRowVerticalCOGOverride();
        returnObject["CrewSecondRowVerticalCOG"] = getConfiguration().getCrewSecondRowVerticalCOG();
        returnObject["FuelType"] = getConfiguration().getFuelType();
        returnObject["FuelSpecificGravity"] = getConfiguration().getFuelSpecificGravity();
        returnObject["FuelSpecificGravityOverride"] = getConfiguration().getFuelSpecificGravityOverride();
        returnObject["TareFront"] = getConfiguration().getTareFront();
        returnObject["TareRear"] = getConfiguration().getTareRearRear();
        returnObject["Source"] = getConfiguration().getSource();
        returnObject["Axle1FrontToWheelbaseTheoreticalStart"] = getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart();
        returnObject["Axle1FrontToWheelbaseTheoreticalStartOverride"] = getConfiguration().getAxle1FrontToWheelbaseTheoreticalStartOverride();
        returnObject["TareDriverIncluded"] = getConfiguration().getTareDriverIncluded();
        returnObject["ManufacturersGrossCombinationMassOverride"] = getConfiguration().getManufacturersGrossCombinationMassOverride();
        returnObject["CabWidthOverride"] = getConfiguration().getCabWidthOverride();
        returnObject["RearWidthOverride"] = getConfiguration().getRearWidthOverride();
        returnObject["TrackWidthFrontOverride"] = getConfiguration().getTrackWidthFrontOverride();
        returnObject["TrackWidthRearOverride"] = getConfiguration().getTrackWidthRearOverride();
        returnObject["ChassisWidthOverride"] = getConfiguration().getChassisWidthOverride();
        returnObject["TurningAbilitySpecifiedAs"] = getConfiguration().getTurningAbilitySpecifiedAs();
        returnObject["TurningRadiusCurbToCurbOverride"] = getConfiguration().getTurningRadiusCurbToCurbOverride();
        returnObject["TurningRadiusCurbToCurb"] = getConfiguration().getTurningRadiusCurbToCurb();
        returnObject["TurningRadiusWallToWallOverride"] = getConfiguration().getTurningRadiusWallToWallOverride();
        returnObject["TurningRadiusWallToWall"] = parseFloat((getConfiguration().getTurningRadiusWallToWall()).toFixed(4));
        returnObject["SteeringAngleInnerOverride"] = getConfiguration().getSteeringAngleInnerOverride();
        returnObject["SteeringAngleInner"] = parseFloat(parseFloat(getConfiguration().getSteeringAngleInner()).toFixed(4));
        returnObject["Axle1FrontToAxle2FrontOverride"] = getConfiguration().getAxle1FrontToAxle2FrontOverride();
        returnObject["Axle2FrontToAxle1RearOverride"] = getConfiguration().getAxle2FrontToAxle1RearOverride();
        returnObject["Axle1RearToAxle2RearOverride"] = getConfiguration().getAxle1RearToAxle2RearOverride();
        returnObject["Axle2RearToAxle3RearOverride"] = getConfiguration().getAxle2RearToAxle3RearOverride();
        returnObject["Axle3RearToAxle4RearOverride"] = getConfiguration().getAxle3RearToAxle4RearOverride();
        returnObject["PayloadType"] = getConfiguration().getPayloadHolder().getPayloadType();

        returnObject["VehicleDescription"] = getConfiguration().getDescription();
        returnObject["CrewFirstRowLateralCOG"] = getConfiguration().getCrewFirstRowLateralCOG();
        returnObject["CrewFirstRowLateralCOGOverride"] = getConfiguration().getCrewFirstRowLateralCOGOverride();
        returnObject["CrewSecondRowLateralCOG"] = getConfiguration().getCrewSecondRowLateralCOG();
        returnObject["CrewSecondRowLateralCOGOverride"] = getConfiguration().getCrewSecondRowLateralCOGOverride();
        returnObject["VehicleLateralCOG"] = getConfiguration().getVehicleLateralCOG();
        returnObject["VehicleLateralCOGOverride"] = getConfiguration().getVehicleLateralCOGOverride();
        returnObject["PayloadLateralCOG"] = getConfiguration().getPayloadLateralCOG();
        returnObject["PayloadLateralCOGOverride"] = getConfiguration().getPayloadLateralCOGOverride();
        returnObject["WheelbaseSpecified"] = getConfiguration().getWheelbaseSpecified();
        returnObject["WheelbaseOverride"] = getConfiguration().getWheelbaseOverride();
        //returnObject["CabToAxles"] = Math.round(rigOps.getCabToAxles());
        returnObject["CabToAxlesOverride"] = getConfiguration().getCabToAxlesOverride();

        returnObject["VehicleBridgeFormulaPermissible"] = getConfiguration().getVehicleBridgeFormulaPermissible();
        returnObject["VehicleBridgeFormulaPermissibleOverride"] = getConfiguration().getVehicleBridgeFormulaPermissibleOverride();
        returnObject["CombinationBridgeFormulaPermissible"] = getConfiguration().getCombinationBridgeFormulaPermissible();
        returnObject["CombinationBridgeFormulaPermissibleOverride"] = getConfiguration().getCombinationBridgeFormulaPermissibleOverride();
        returnObject["BridgeFormulaRearPermissible"] = getConfiguration().getBridgeFormulaRearPermissible();
        returnObject["BridgeFormulaRearPermissibleOverride"] = getConfiguration().getBridgeFormulaRearPermissibleOverride();
        returnObject["EnergySourceExpressedAs"] = getConfiguration().getExpressedAs();
        returnObject["AdBlueSpecificGravity"] = getConfiguration().getSpecificGravityAdBlue();
        returnObject["AdBlueSpecificGravityOverride"] = getConfiguration().getSpecificGravityAdBlueOverride();

        returnObject["DevicesForIndirectVision"] = getConfiguration().getDevicesForIndirectVision();
        returnObject["CommercialVehicleBrakeSystems"] = getConfiguration().getCommercialVehicleBrakeSystems();
        returnObject["AdvancedEmergencyBraking"] = getConfiguration().getAdvancedEmergencyBraking();
        returnObject["LaneDepartureWarning"] = getConfiguration().getLaneDepartureWarning();
        returnObject["SideUnderrunProtection"] = getConfiguration().getSideUnderrunProtection();
        returnObject["ConspicuityMarkingsToTheRear"] = getConfiguration().getConspicuityMarkingsToTheRear();
        returnObject["ConspicuityMarkingsToBothSides"] = getConfiguration().getConspicuityMarkingsToBothSides();
        returnObject["BlindSpotInformationSystems"] = getConfiguration().getBlindSpotInformationSystems();
    }

    function calculateWheelbaseSpecfiedValuesAndAddToObject(returnObject, rigOps) {
        returnObject["WheelbaseCFACRA"] = rigOps.getWheelbaseSpecified(config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES);
        returnObject["WheelbaseFMSACRA"] = rigOps.getWheelbaseSpecified(config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES);
        returnObject["WheelbaseFMSACRDA"] = rigOps.getWheelbaseSpecified(config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES);
        returnObject["WheelbaseFMSAFRDA"] = rigOps.getWheelbaseSpecified(config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE);
    }

    function addFuelTankAttributesForAppData(returnObject) {

        var fuelTank1 = getConfiguration().getFuelTank1();
        returnObject["FuelTank1Capacity"] = fuelTank1.getCapacity();
        returnObject["TareFuelTank1Included"] = fuelTank1.getVolumeIncludedInTare();
        returnObject["FuelTank1VolumeIncludedInTare"] = fuelTank1.getVolumeIncludedInTare();
        returnObject["FuelTank1HorizontalCOG"] = fuelTank1.getHorizontalCog();
        returnObject["FuelTank1VerticalCOG"] = fuelTank1.getVerticalCog();
        returnObject["FuelTank1LateralCOG"] = fuelTank1.getLateralCog();
        
        var verticalCOGConfirmed = fuelTank1.getVerticalCOGConfirmed();
        if (fuelTank1.getVerticalCogOverride() === true) {
            verticalCOGConfirmed = true;
        }
        returnObject["FuelTank1VerticalCOGOverride"] = verticalCOGConfirmed;

        var lateralCOGConfirmed = fuelTank1.getLateralCOGConfirmed();
        if (fuelTank1.getLateralCogOverride() === true) {
            lateralCOGConfirmed = true;
        }
        returnObject["FuelTank1LateralCOGOverride"] = lateralCOGConfirmed;

        var fuelTank2 = getConfiguration().getFuelTank2();
        if(fuelTank2 !== null) {
            if (fuelTank2.getOfferedAs() === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank2.getOfferedAs() === config.OFFERED_AS_OPTIONS.OPTIONAL) {
                returnObject["FuelTank2"] = fuelTank2.getOfferedAs();
                returnObject["FuelTank2Capacity"] = fuelTank2.getCapacity();
                returnObject["TareFuelTank2Included"] = fuelTank2.getVolumeIncludedInTare();
                returnObject["FuelTank2VolumeIncludedInTare"] = fuelTank2.getVolumeIncludedInTare();
                returnObject["FuelTank2HorizontalCOG"] = fuelTank2.getHorizontalCog();
                returnObject["FuelTank2VerticalCOG"] = fuelTank2.getVerticalCog();
                returnObject["FuelTank2LateralCOG"] = fuelTank2.getLateralCog();

                verticalCOGConfirmed = fuelTank2.getVerticalCOGConfirmed();
                if (fuelTank2.getVerticalCogOverride() === true) {
                    verticalCOGConfirmed = true;
                }
                returnObject["FuelTank2VerticalCOGOverride"] = verticalCOGConfirmed;

                lateralCOGConfirmed = fuelTank2.getLateralCOGConfirmed();
                if (fuelTank2.getLateralCogOverride() === true) {
                    lateralCOGConfirmed = true;
                }
                returnObject["FuelTank2LateralCOGOverride"] = lateralCOGConfirmed;
            }
        }

        var fuelTank3 = getConfiguration().getFuelTank3();
        if (fuelTank3 !== null) {
            if (fuelTank3.getOfferedAs() === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank3.getOfferedAs() === config.OFFERED_AS_OPTIONS.OPTIONAL) {
                returnObject["FuelTank3"] = fuelTank3.getOfferedAs();
                returnObject["FuelTank3Capacity"] = fuelTank3.getCapacity();
                returnObject["TareFuelTank3Included"] = fuelTank3.getVolumeIncludedInTare();
                returnObject["FuelTank3VolumeIncludedInTare"] = fuelTank3.getVolumeIncludedInTare();
                returnObject["FuelTank3HorizontalCOG"] = fuelTank3.getHorizontalCog();
                returnObject["FuelTank3VerticalCOG"] = fuelTank3.getVerticalCog();
                returnObject["FuelTank3LateralCOG"] = fuelTank3.getLateralCog();
                verticalCOGConfirmed = fuelTank3.getVerticalCOGConfirmed();
                if (fuelTank3.getVerticalCogOverride() === true) {
                    verticalCOGConfirmed = true;
                }
                returnObject["FuelTank3VerticalCOGOverride"] = verticalCOGConfirmed;

                lateralCOGConfirmed = fuelTank3.getLateralCOGConfirmed();
                if (fuelTank3.getLateralCogOverride() === true) {
                    lateralCOGConfirmed = true;
                }
                returnObject["FuelTank3LateralCOGOverride"] = lateralCOGConfirmed;
            }
        }

        var fuelTank4 = getConfiguration().getFuelTank4();
        if (fuelTank4 !== null) {
            if (fuelTank4.getOfferedAs() === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank4.getOfferedAs() === config.OFFERED_AS_OPTIONS.OPTIONAL) {
                returnObject["FuelTank4"] = fuelTank4.getOfferedAs();
                returnObject["FuelTank4Capacity"] = fuelTank4.getCapacity();
                returnObject["TareFuelTank4Included"] = fuelTank4.getVolumeIncludedInTare();
                returnObject["FuelTank4VolumeIncludedInTare"] = fuelTank4.getVolumeIncludedInTare();
                returnObject["FuelTank4HorizontalCOG"] = fuelTank4.getHorizontalCog();
                returnObject["FuelTank4VerticalCOG"] = fuelTank4.getVerticalCog();
                returnObject["FuelTank4LateralCOG"] = fuelTank4.getLateralCog();
                verticalCOGConfirmed = fuelTank4.getVerticalCOGConfirmed();
                if (fuelTank4.getVerticalCogOverride() === true) {
                    verticalCOGConfirmed = true;
                }
                returnObject["FuelTank4VerticalCOGOverride"] = verticalCOGConfirmed;

                lateralCOGConfirmed = fuelTank4.getLateralCOGConfirmed();
                if (fuelTank4.getLateralCogOverride() === true) {
                    lateralCOGConfirmed = true;
                }
                returnObject["FuelTank4LateralCOGOverride"] = lateralCOGConfirmed;
            }
        }
        
        /*
            .Fuel_Tank_1_Capacity = vehicleDetail.FuelTank1Capacity
                .Fuel_Tank_1_COG = vehicleDetail.FuelTank1HorizontalCOG
                .Fuel_Tank_1_Vertical_COG = vehicleDetail.FuelTank1VerticalCOG
                .Fuel_Tank_1_Vertical_COG_Override = vehicleDetail.FuelTank1VerticalCOGOverride
                .Fuel_Tank_2 = vehicleDetail.FuelTank2
                .Fuel_Tank_2_Capacity = vehicleDetail.FuelTank2Capacity
                .Fuel_Tank_2_COG = vehicleDetail.FuelTank2HorizontalCOG
                .Fuel_Tank_2_Vertical_COG = vehicleDetail.FuelTank2VerticalCOG
                .Fuel_Tank_2_Vertical_COG_Override = vehicleDetail.FuelTank2VerticalCOGOverride
                .Fuel_Tank_3 = vehicleDetail.FuelTank3
                .Fuel_Tank_3_Capacity = vehicleDetail.FuelTank3Capacity
                .Fuel_Tank_3_COG = vehicleDetail.FuelTank3HorizontalCOG
                .Fuel_Tank_3_Vertical_COG = vehicleDetail.FuelTank3VerticalCOG
                .Fuel_Tank_3_Vertical_COG_Override = vehicleDetail.FuelTank3VerticalCOGOverride
                .Fuel_Tank_4 = vehicleDetail.FuelTank4
                .Fuel_Tank_4_Capacity = vehicleDetail.FuelTank4Capacity
                .Fuel_Tank_4_COG = vehicleDetail.FuelTank4HorizontalCOG
                .Fuel_Tank_4_Vertical_COG = vehicleDetail.FuelTank4VerticalCOG
                .Fuel_Tank_4_Vertical_COG_Override = vehicleDetail.FuelTank4VerticalCOGOverride
        */

        return returnObject;
    }

    function mergeAccessories(accessoriesTo, accessoriesFrom) {
        Object.keys(accessoriesTo).forEach(function (key) {
            if (accessoriesFrom[key] !== undefined && accessoriesFrom[key].length > 0) {
                accessoriesTo[key] = accessoriesTo[key].concat(accessoriesFrom[key]);
            }
        });

        Object.keys(accessoriesFrom).forEach(function (key) {
            if (accessoriesTo[key] === undefined && accessoriesFrom[key].length > 0) {
                accessoriesTo[key] = accessoriesFrom[key];
            }
        });

    }

    function getIntercomMetadata(offerType, menuItemIntercomObject) {
        var returnObject = {
            //'description': getDescription(),
            //'axle-layout': getVehicleAxleLayout(),
            //'source-type': getConfiguration().getSource(),
            //'offer-type': offerType, // New/Existing
            //'connectivity-status': globals.isOnline() === true ? config.CONNECTIVITY_STATUS.ONLINE : config.CONNECTIVITY_STATUS.OFFLINE
        };
        returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = getDescription();
        returnObject[config.INTERCOM_METADATA_ITEM.AXLE_LAYOUT] = getVehicleAxleLayout();
        returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getConfiguration().getSource();
        returnObject[config.INTERCOM_METADATA_ITEM.OFFER_TYPE] = offerType;
        //returnObject[config.INTERCOM_METADATA_ITEM.CONNECTIVITY_STATUS] = globals.isOnline() === true ? config.CONNECTIVITY_STATUS.ONLINE : config.CONNECTIVITY_STATUS.OFFLINE;
        returnObject[config.INTERCOM_METADATA_ITEM.VEHICLE_TYPE] = getConfiguration().getType();
        returnObject[config.INTERCOM_METADATA_ITEM.CONNECTIVITY_STATUS] = globals.getConnectivityStatusForIntercom();
        if (typeof menuItemIntercomObject === 'object') {
            for (var key in menuItemIntercomObject) {
                returnObject[key] = menuItemIntercomObject[key];
            }
        }
        if (getSharerUserInfo()) {
            returnObject[config.INTERCOM_METADATA_ITEM.SHARED_BY] = getSharerUserInfo().getEmail();
        }
        return returnObject;
    }

    /**
     * If the vehicle has a route simulated then the getFuelConsumption() function will return the fuel consumption for the route.
     * If the vehicle does not have a route simulated then getVehicleFuelAndAdblueFuelConsumptionOnDatabase() function will return the fuel consumption for the vehicle.
     * @returns {number} - Fuel consumption in litres per 100km
     */
    function getFuelAndAdblueFuelConsumptionForOverride() {
        var returnValue = getCosting().getVehicleFuelAndAdblueFuelConsumptionOnDatabase();
        if(getPerformance()) {
            if(getPerformance().getSimulationCreated()) {
                returnValue = getPerformance().getSimResAverageFuel();
            }
        }
        return returnValue;
    }

    /**
     * If the finance total price is not overridden then the finance total price is calculated from the vehicle price, body price and accessories.
     */
    function updateCostingFinanceTotalPrice() {
        if(getCosting().getFinanceTotalPriceOverride() === false) {
            var newValue = 0;

            newValue += globals.getNetPrice(getCosting().getVehiclePrice(), getCosting().getVehicleDiscount());
            if (getConfiguration().getBody() !== null) {
                newValue += globals.getNetPrice(getConfiguration().getBody().getCostingListPrice(), getConfiguration().getBody().getCostingDiscount());
            }
            if (getConfiguration().getAccessoryHolder().getAccessories().length > 0) {
                getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                    if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                        newValue += globals.getNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                    }
                });
            }
            if (getTrailer1() !== null) {
                newValue += globals.getNetPrice(getTrailer1().getCostingListPrice(), getTrailer1().getCostingDiscount());
                if (getTrailer1().getAccessoryHolder().getBody() !== null) {
                    newValue += globals.getNetPrice(getTrailer1().getAccessoryHolder().getBody().getCostingListPrice(), getTrailer1().getAccessoryHolder().getBody().getCostingDiscount());
                }
                if (getTrailer1().getAccessoryHolder().getAccessories().length > 0) {
                    getTrailer1().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                        if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                                newValue += globals.getNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                            }
                        }
                    });
                }
            }
            if (getTrailer2() !== null) {
                newValue += globals.getNetPrice(getTrailer2().getCostingListPrice(), getTrailer2().getCostingDiscount());
                if (getTrailer2().getAccessoryHolder().getBody() !== null) {
                    newValue += globals.getNetPrice(getTrailer2().getAccessoryHolder().getBody().getCostingListPrice(), getTrailer2().getAccessoryHolder().getBody().getCostingDiscount());
                }
                if (getTrailer2().getAccessoryHolder().getAccessories().length > 0) {
                    getTrailer2().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                        if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                                newValue += globals.getNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                            }
                        }
                    });
                }
            }

            getCosting().setFinanceTotalPrice(newValue);
        }
        
    }
    //#endregion Internal functions

    //#region public members
    self.getConfiguration = getConfiguration;
    self.setConfiguration = setConfiguration;
    self.getCosting = getCosting;
    self.setCosting = setCosting;
    self.getPerformance = getPerformance;
    self.setPerformance = setPerformance;
    self.getSpecification = getSpecification;
    self.setSpecification = setSpecification;

    self.getId = getId;
    self.setId = setId;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getVehicleId = getVehicleId;
    self.setVehicleId = setVehicleId;
    self.getVehicleName = getVehicleName;
    self.setVehicleName = setVehicleName;
    self.getVehicleModel = getVehicleModel;
    self.setVehicleModel = setVehicleModel;
    self.getVehicleModelCode = getVehicleModelCode;
    self.setVehicleModelCode = setVehicleModelCode;
    self.getVehicleMake = getVehicleMake;
    self.setVehicleMake = setVehicleMake;
    self.getVehicleRange = getVehicleRange;
    self.setVehicleRange = setVehicleRange;
    self.getVehicleGVW = getVehicleGVW;
    self.setVehicleGVW = setVehicleGVW;
    self.getVehiclePayload = getVehiclePayload;
    self.setVehiclePayload = setVehiclePayload;
    self.getVehiclePower = getVehiclePower;
    self.setVehiclePower = setVehiclePower;
    self.getVehicleAxleLayout = getVehicleAxleLayout;
    self.setVehicleAxleLayout = setVehicleAxleLayout;
    self.getVehiclePlusBodyImageURL = getVehiclePlusBodyImageURL;
    self.setVehiclePlusBodyImageURL = setVehiclePlusBodyImageURL;
    self.getBodyType = getBodyType;
    self.setBodyType = setBodyType;
    self.getBodyManufacturer = getBodyManufacturer;
    self.setBodyManufacturer = setBodyManufacturer;
    self.getVehicleDistributionGroupId = getVehicleDistributionGroupId;
    self.setVehicleDistributionGroupId = setVehicleDistributionGroupId;
    self.getVehicleDistributionOptionId = getVehicleDistributionOptionId;
    self.setVehicleDistributionOptionId = setVehicleDistributionOptionId;
    self.getCustomer = getCustomer;
    self.setCustomer = setCustomer;

    self.getSelectedCompetitor1Id = getSelectedCompetitor1Id;
    self.setSelectedCompetitor1Id = setSelectedCompetitor1Id;
    self.getSelectedCompetitor2Id = getSelectedCompetitor2Id;
    self.setSelectedCompetitor2Id = setSelectedCompetitor2Id;
    self.getSummaryNote = getSummaryNote;
    self.setSummaryNote = setSummaryNote;
    // self.getOnRoadCostsVersion = getOnRoadCostsVersion;
    // self.setOnRoadCostsVersion = setOnRoadCostsVersion;

    self.createSpecification = createSpecification;
    self.getOfferDetailsToBeSaved = getOfferDetailsToBeSaved;
    
    self.getAdditionalNote = getAdditionalNote;
    self.setAdditionalNote = setAdditionalNote;
    self.getAdditionalNoteForDrawing = getAdditionalNoteForDrawing;
    self.getMeasurementSystem = getMeasurementSystem;
    self.setMeasurementSystem = setMeasurementSystem;
    self.getLegislation = getLegislation;
    self.setLegislation = setLegislation;
    self.getLengthIncrement = getLengthIncrement;
    self.setLengthIncrement = setLengthIncrement;
    self.getMassIncrement = getMassIncrement;
    self.setMassIncrement = setMassIncrement;
    self.getPercentageIncrement = getPercentageIncrement;
    self.setPercentageIncrement = setPercentageIncrement;
    self.getApplication = getApplication;
    self.setApplication = setApplication;
    self.getApplicationTypeCode = getApplicationTypeCode;
    self.getDataAvailability = getDataAvailability;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    //self.getAdditionalDescription = getAdditionalDescription;
    //self.setAdditionalDescription = setAdditionalDescription;
    self.getPreparedForName = getPreparedForName;
    self.setPreparedForName = setPreparedForName;
    self.getPreparedForTelephone = getPreparedForTelephone;
    self.setPreparedForTelephone = setPreparedForTelephone;
    self.getApplyHigherMassLimits = getApplyHigherMassLimits;
    self.setApplyHigherMassLimits = setApplyHigherMassLimits;
    self.getApplyConcessionalMassLimits = getApplyConcessionalMassLimits;
    self.setSpecDate = setSpecDate;
    self.getSpecDate = getSpecDate;
    self.getOverallDescription = getOverallDescription;
    self.getIntercomMetadata = getIntercomMetadata;

    self.getRig = getRig;
    self.getTrailer1 = getTrailer1;
    self.getTrailer2 = getTrailer2;
    self.addTrailer = addTrailer;
    self.removeTrailer = removeTrailer;
    self.getTrailer = getTrailer;
    self.setTrailer1Null = setTrailer1Null;
    self.setTrailer2Null = setTrailer2Null;
    self.setSalesPersonId = setSalesPersonId;
    self.getSalesPersonId = getSalesPersonId;
    self.isModuleAvailableOnOffer = isModuleAvailableOnOffer;
    self.getSummaryReportOptions = getSummaryReportOptions;
    self.updateSummaryReportOptionUsingCode = updateSummaryReportOptionUsingCode;

    self.updateConfiguration = updateConfiguration;
    self.updatePerformance = updatePerformance;
    self.createPerformance = createPerformance;
    self.createConfiguration = createConfiguration;
    self.setDataAvailability = setDataAvailability;
    self.createSummaryReports = createSummaryReports;
    self.updateCosting = updateCosting;
    self.createCosting = createCosting;
    self.updateSpecification = updateSpecification;
    self.setupOfferLevelValues = setupOfferLevelValues;
    self.getKeepChassisFlushWithBody = getKeepChassisFlushWithBody;
    self.setKeepChassisFlushWithBody = setKeepChassisFlushWithBody;
    
    self.getCountryId = getCountryId;
    self.setCountryId = setCountryId;
    self.getPendingVehicleDetailsToBeSaved = getPendingVehicleDetailsToBeSaved;
    self.getRangeId = getRangeId;
    self.setRangeId = setRangeId;
    self.addFuelTankAttributesForAppData = addFuelTankAttributesForAppData;

    self.setIsSharedOffer = setIsSharedOffer;
    self.getIsSharedOffer = getIsSharedOffer;

    self.getVehicleTypeName = getVehicleTypeName;
    self.setVehicleTypeName = setVehicleTypeName;
    self.getVehicleTypeNameCode = getVehicleTypeNameCode;
    self.setVehicleTypeNameCode = setVehicleTypeNameCode;
    self.getSubCategory1 = getSubCategory1;
    self.setSubCategory1 = setSubCategory1;
    self.getSubCategory1Code = getSubCategory1Code;
    self.setSubCategory1Code = setSubCategory1Code;
    self.getSubCategory2 = getSubCategory2;
    self.setSubCategory2 = setSubCategory2;
    self.getSubCategory2Code = getSubCategory2Code;
    self.setSubCategory2Code = setSubCategory2Code;
    self.getStatus = getStatus;
    self.setStatus = setStatus;
    self.cleanUp = cleanUp;

    self.setupLastModifiedByInfo = setupLastModifiedByInfo;
    self.setupShareInfo = setupShareInfo;
    self.updateShareInfo = updateShareInfo;
    self.displayNotifyOtherUsersOfSaveModal = displayNotifyOtherUsersOfSaveModal;
    self.setSharerUserInfo = setSharerUserInfo;
    self.getSharerUserInfo = getSharerUserInfo;
    self.setShareeUserInfoList = setShareeUserInfoList;
    self.getShareeUserInfoList = getShareeUserInfoList;
    self.getSharePermission = getSharePermission;
    self.setSharePermission = setSharePermission;
    self.getDisplayTransSolveWarningsMessage = getDisplayTransSolveWarningsMessage;
    self.setDisplayTransSolveWarningsMessage = setDisplayTransSolveWarningsMessage;
    self.getTransSolveWarningsArray = getTransSolveWarningsArray;
    self.setTransSolveWarningsArray = setTransSolveWarningsArray;

    self.setIsFromTSV = setIsFromTSV;
    self.getIsFromTSV = getIsFromTSV;
    self.getIsSupportOffer = getIsSupportOffer;
    self.setIsSupportOffer = setIsSupportOffer;
    self.getSupportUserId = getSupportUserId;
    self.setSupportUserId = setSupportUserId;
    self.getFuelAndAdblueFuelConsumptionForOverride = getFuelAndAdblueFuelConsumptionForOverride;
    self.updateCostingFinanceTotalPrice = updateCostingFinanceTotalPrice;
    //#endregion public members
};

export default Offer;
