<template>
    <tr class="menu-li menu-li-value menu-tr-tab-group" :class="menuTabGroup.customCss"
        v-visible="isVisible()">
        <td colspan="2">
            <div :id="menuTabGroup.attrTabId">
                <template v-if="menuTabGroup.displayType === 'accordion'">
                    <AccordionTabs :tabsFromParent="Array.isArray(menuTabGroup.menuTabs) === true ? menuTabGroup.menuTabs : menuTabGroup.menuTabs.value"
                                    :menuTabDisplayTypeFromParent="menuTabGroup.menuTabDisplayType"
                                    @moveUpClick="moveUpTriggered"
                                    @moveDownClick="moveDownTriggered"
                                    @deleteClick="deleteTriggered">
                    </AccordionTabs>
                </template>
                <template v-if="menuTabGroup.displayType === 'tabs'">
                    <TabsContainer :tabsFromParent="Array.isArray(menuTabGroup.menuTabs) === true ? menuTabGroup.menuTabs : menuTabGroup.menuTabs.value"
                                    :displayButtonBesideTabsFromParent="menuTabGroup.displayButtonBesideTabs"
                                    :menuTabGroupButtonFromParent="menuTabGroup.menuTabGroupButton">
                    </TabsContainer>
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
import { ref, onUpdated, onMounted, onActivated, toRef, isRef } from 'vue';
import TabsContainer from './menuTabGroup/TabsContainer.vue';
import AccordionTabs from './menuTabGroup/AccordionTabs.vue';

const TScMessenger = window.TScMessenger;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const menuTabGroup = ref(props.valueFromParent);
        const menuTabGroup = toRef(props, "valueFromParent");

        onMounted(() => {
            // Call updateJsElements
            TScMessenger.writeDebugMessage('Tab Group onMounted');
            menuTabGroup.value.initializeMenuTabGroup(menuTabGroup.value.path);
        })
        
        onUpdated(() => {
            TScMessenger.writeDebugMessage('Tab Group onUpdated');
        });

        onActivated(() =>{
            TScMessenger.writeDebugMessage('Tab Group onActivated');
        });

        function moveUpTriggered(tab) {
            menuTabGroup.value.moveUpFunction(tab);
        }

        function moveDownTriggered(tab) {
            menuTabGroup.value.moveDownFunction(tab);
        }

        function deleteTriggered(tab) {
            menuTabGroup.value.deleteFunction(tab);
        }

        function isVisible() {
            if(isRef(menuTabGroup.value.permissionObv)) {
                return menuTabGroup.value.permissionObv.value.visible;
            } else {
                return menuTabGroup.value.permissionObv.visible;
            }
        }

        return {
            menuTabGroup,
            moveUpTriggered,
            moveDownTriggered,
            deleteTriggered,
            isVisible
        };
    },
    components: { TabsContainer, AccordionTabs }
}
</script>

<style lang="scss" scoped>
</style>