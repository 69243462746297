import config from '../../../services/config';



var  ComplianceDashboard = function () {
    //this._activeViewPath = config.COMPLIANCE_DASHBOARD_VIEWS.MAIN;// + '.' + config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE
    this.initialiseActiveViewPath();
    
    this.init();
    //this.getWidthOfText = null;
    

    return this;
}

ComplianceDashboard.prototype.init = function () {
    
    this._rows = [];
    this._width;
    this._defaultTextStyle;
    this._defaultTextColour;
    this._numSmallSpacers = 0;
    this._numLargeSpacers = 0;
    this._numCustomHeightSpacers = 0;
    this._cumulativeCustomSpacerHeight = 0;
    this._numLineSpacers = 0;
    this._yOffset = 0;
    this._iconSize;
    this._smallSpacerHeight;
    this._largeSpacerHeight;
    this._itemRowHeight;
    this._titleRowHeight;
    this._backgroundColour;
    this._backgroundBuffer;
    this._backgroundBorderColour;
    this._dashboardTableGap = 8;//config.minimumComplianceDashboardAndTableBuffer;

    this._navigationBarHeight = config.tableAndDashboardTitleHeight;
    this._navigationBarBackground = config.DRAWING_COLOURS.DASHBOARD_TITLE_BACKGROUND;
}

ComplianceDashboard.prototype.addRow = function (newComplianceDashboardRow) {
    var self = this;
    newComplianceDashboardRow.getItems().forEach(function (complianceDashboardItem) {
        if (complianceDashboardItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {
            if (complianceDashboardItem.getTextStyle() === undefined) {
                complianceDashboardItem.setTextStyle(self._defaultTextStyle);
            }
            if (complianceDashboardItem.getTextColour() === undefined) {
                complianceDashboardItem.setTextColour(self._defaultTextColour);
            }
        }
        if (complianceDashboardItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.SPACER) {
            if (complianceDashboardItem.getSpacerType() === config.SPACER_TYPE.SMALL_VERTICAL) {
                self._numSmallSpacers++;
            }
            if (complianceDashboardItem.getSpacerType() === config.SPACER_TYPE.LARGE_VERTICAL) {
                self._numLargeSpacers++;
            }
            if (complianceDashboardItem.getSpacerType() === config.SPACER_TYPE.CUSTOM_VERTICAL) {
                self._numCustomHeightSpacers++;
                self._cumulativeCustomSpacerHeight += complianceDashboardItem.getHeight();
            }
            if (complianceDashboardItem.getSpacerType() === config.SPACER_TYPE.LINE) {
                self._numLineSpacers++;
            }
        }
        if (complianceDashboardItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON) {
            if (complianceDashboardItem.getIconSize() === undefined) {
                complianceDashboardItem.setIconSize(self._iconSize);
            }
        }
    });
    

    this._rows.push(newComplianceDashboardRow);
}

ComplianceDashboard.prototype.getActiveViewPath = function () {
    return this._activeViewPath;
}

ComplianceDashboard.prototype.setActiveViewPath = function (newValue) {
    this._activeViewPath = newValue;
}
ComplianceDashboard.prototype.updateActiveViewPath = function (newValue) {
    this._activeViewPath += '.' + newValue;
}
ComplianceDashboard.prototype.initialiseActiveViewPath = function () {
    this._activeViewPath = config.COMPLIANCE_DASHBOARD_VIEWS.MAIN;
}
ComplianceDashboard.prototype.moveUpOneLevelOfViewPath = function () {
    var viewPathParts = this._activeViewPath.split('.');
    var i = 0;
    var newActiveViewPath = '';
    while (viewPathParts[i] !== viewPathParts[viewPathParts.length - 1]) {
        newActiveViewPath += viewPathParts[i];
        if (i < viewPathParts.length - 2) {
            newActiveViewPath += '.';
        }
        i++;
    }
    this.setActiveViewPath(newActiveViewPath || viewPathParts[0]);
}
ComplianceDashboard.prototype.clearContents = function () {
    this.init();
    this._rows = [];
}

ComplianceDashboard.prototype.getHeadingItem = function () {
    return this._rows.filter(function (item) { return item.getIsHeading() === true; })[0] || null;
}

ComplianceDashboard.prototype.getSubheadingItem = function () {
    return this._rows.filter(function (item) { return item.getIsSubheading() === true; })[0] || null;
}

ComplianceDashboard.prototype.getRows = function () {
    return this._rows;
}

ComplianceDashboard.prototype.getItemById = function (id) {
    return this._rows.filter(function (item) {
        return item.getId() === id;
    })[0] || null;
}

ComplianceDashboard.prototype.getLargestLabelWidth = function () {
    var self = this;
    var largestWidth = 0;
    this._rows.forEach(function (item) {
        item.getItems().forEach(function (rowItem) {
            if (rowItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {
                if (!rowItem.getIsTextBold()) {
                    largestWidth = Math.max(self.getWidthOfText(rowItem.getText()) + 30, largestWidth);
                } else {
                    largestWidth = Math.max(self.getWidthOfText(rowItem.getText(), true), largestWidth);
                }
            }
        });
        
    });
    return largestWidth;
}

ComplianceDashboard.prototype.getLargestRowWidth = function () {
    var self = this;
    var largestRowWidth = 0;
    this._rows.forEach(function (item) {
        var rowWidth = 0;
        item.getItems().forEach(function (rowItem) {
            if (rowItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {
                if (!rowItem.getIsTextBold()) {
                    rowWidth += self.getWidthOfText(rowItem.getText()) + 30;
                } else {
                    rowWidth += self.getWidthOfText(rowItem.getText(), true) + 5;
                }
            } else if (rowItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON) {
                rowWidth += rowItem.getIconSize()*2;
            }
        });
        largestRowWidth = Math.max(rowWidth, largestRowWidth);
    });
    return largestRowWidth;
}

ComplianceDashboard.prototype.getHeight = function () {
    //return (_itemRowHeight * (_rows.length - _numSmallSpacers - _numLargeSpacers - 3)) + (_titleRowHeight * 2) + (_smallSpacerHeight * _numSmallSpacers) + (_largeSpacerHeight * _numLargeSpacers);
    return (this._itemRowHeight * (this._rows.length - this._numSmallSpacers - this._numLargeSpacers - this._numCustomHeightSpacers - this._numLineSpacers - 1)) + (this._navigationBarHeight) + (this._smallSpacerHeight * this._numSmallSpacers) + (this._largeSpacerHeight * this._numLargeSpacers) + this._cumulativeCustomSpacerHeight;
}

ComplianceDashboard.prototype.setWidth = function (newValue) {
    var self = this;
    this._width = newValue;
    this._rows.forEach(function (item) {
        var tempWidthOfText, widthOfText, numChars, widthPerLetter, numCharsToSubstring;
        if (item.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {
            if (item.getIsHeading()) {
                tempWidthOfText = self.getWidthOfText(item.getText(), true);
                if (tempWidthOfText > self._width) {
                    widthOfText = tempWidthOfText;
                    numChars = item.getText().length;
                    widthPerLetter = widthOfText / numChars;
                    numCharsToSubstring = (self._width / widthPerLetter) - 2;
                    item.setText(item.getText().substring(0, numCharsToSubstring) + '...');
                }
            } else {
                tempWidthOfText = self.getWidthOfText(item.getText());
                if (tempWidthOfText > self._width) {
                    widthOfText = tempWidthOfText;
                    numChars = item.getText().length;
                    widthPerLetter = widthOfText / numChars;
                    numCharsToSubstring = (self._width / widthPerLetter) - 3;
                    item.setText(item.getText().substring(0, numCharsToSubstring) + '...');
                }
            }
        }
    });
}

ComplianceDashboard.prototype.getWidth = function () {
    if (this._width) {
        return this._width;
    }
    return this.getLargestRowWidth() /*+ _iconSize * 2*/;
}

ComplianceDashboard.prototype.getNumSmallSpacers = function () {
    return this._numSmallSpacers;
}

ComplianceDashboard.prototype.getNumLargeSpacers = function () {
    return this._numLargeSpacers;
}

ComplianceDashboard.prototype.setDefaultTextStyle = function (newValue) {
    this._defaultTextStyle = newValue;
}

ComplianceDashboard.prototype.setDefaultTextColour = function (newValue) {
    this._defaultTextColour = newValue;
}

ComplianceDashboard.prototype.setYOffset = function (newValue) {
    this._yOffset = newValue;
}

ComplianceDashboard.prototype.getYOffset = function () {
    return this._yOffset;
}

ComplianceDashboard.prototype.setIconSize = function (newValue) {
    this._iconSize = newValue;
    this._titleRowHeight = this._iconSize / 2;
    //this._itemRowHeight = this._iconSize;
    this._smallSpacerHeight = this._iconSize / 16;
    this._largeSpacerHeight = this._iconSize / 4;
}

ComplianceDashboard.prototype.getIconSize = function () {
    return this._iconSize;
}

ComplianceDashboard.prototype.getItemRowHeight = function () {
    return this._itemRowHeight;
}

ComplianceDashboard.prototype.setItemRowHeight = function (newValue) {
    this._itemRowHeight = newValue;
}

ComplianceDashboard.prototype.getTitleRowHeight = function () {
    return this._titleRowHeight;
}

ComplianceDashboard.prototype.getSmallSpacerHeight = function () {
    return this._smallSpacerHeight;
}

ComplianceDashboard.prototype.getLargeSpacerHeight = function () {
    return this._largeSpacerHeight;
}

ComplianceDashboard.prototype.setBackgroundColour = function (newValue) {
    this._backgroundColour = newValue;
}

ComplianceDashboard.prototype.getBackgroundColour = function () {
    return this._backgroundColour;
}

ComplianceDashboard.prototype.setBackgroundBuffer = function (newValue) {
    this._backgroundBuffer = newValue;
}

ComplianceDashboard.prototype.getBackgroundBuffer = function () {
    return this._backgroundBuffer;
}

ComplianceDashboard.prototype.setBackgroundBorderColour = function(newValue) {
    this._borderBackgroundColour = newValue;
}

ComplianceDashboard.prototype.getBackgroundBorderColour = function () {
    return this._borderBackgroundColour;
}

ComplianceDashboard.prototype.setDashboardTableGap = function (newValue) {
    this._dashboardTableGap = newValue;
}

ComplianceDashboard.prototype.getDashboardTableGap = function () {
    return this._dashboardTableGap;
}

ComplianceDashboard.prototype.getNavigationBarHeight = function () {
    return this._navigationBarHeight;
}

ComplianceDashboard.prototype.getNavigationBarBackgroundColour = function () {
    return this._navigationBarBackground;
}

ComplianceDashboard.prototype.setWidthOfTextCallback = function (withOfTaxtCallbackFunc) {
    this.getWidthOfText = withOfTaxtCallbackFunc;
}


export default ComplianceDashboard;

     
