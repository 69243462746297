<template>
    <tr class="menu-li menu-li-value menu-li-value-checkbox"
        v-visible="isVisible()"
        data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-checkbox' }">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span data-bind="text: checkboxValue.label">{{ checkboxValue.label }}</span>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div">
                    <a v-if="checkboxValue.tooltipMessage !== undefined" data-placement="top"
                        @click="checkboxValue.showPopover"
                        :id="checkboxValue.tooltipId"
                        :data-content="checkboxValue.tooltipMessage"
                        data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                <div class="menu-checkbox-container-div">
                    <div class="increment-div menu-checkbox-div" @click="checkboxValue.toggleCheckbox" :class="{ 'menu-checkbox-div-readonly': isReadOnly() }" >
                        <i class="far fa-lg"
                            :class="{ 'fa-check-square': checkboxValue.value.value === true, 'fa-square': checkboxValue.value.value === false }"></i>
                    </div>
                </div>
                <div class="increment-div decrement-div"></div>
                <div class="increment-div"></div>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef, isRef } from 'vue';

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const checkboxValue = toRef(props, "valueFromParent");

        function isVisible() {
            if(isRef(checkboxValue.value.permissionObv)) {
                return checkboxValue.value.permissionObv.value.visible;
            } else {
                return checkboxValue.value.permissionObv.visible;
            }
        }

        function isReadOnly() {
            if(isRef(checkboxValue.value.permissionObv)) {
                return checkboxValue.value.permissionObv.value.readOnly === true;
            } else {
                return checkboxValue.value.permissionObv.readOnly === true;
            }
        }

        return {
            checkboxValue,
            isVisible,
            isReadOnly
        }
    }
}
</script>

<style lang="scss" scoped>
</style>