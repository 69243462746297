<template>
    <tr class="user-settings-checkbox" :class="checkbox.rowClass">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label">{{checkbox.label}}</span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="checkbox.displayTooltip === true"
                            :id="checkbox.tooltipId"
                            :data-content="checkbox.tooltipMessage"
                            @click="checkbox.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                </div>
                <div @click="checkbox.toggleCheckbox"
                    class="menu-checkbox-div" 
                    data-bind="click: toggleCheckbox, touchstart: toggleCheckbox">
                    <i :class="{ 'fa-check-square': checkbox.obv.value === true, 'fa-square': checkbox.obv.value === false }"
                        class="far fa-lg" 
                        data-bind="css: { 'fa-check-square': obv() === true, 'fa-square': obv() === false }">
                    </i>
                </div>
            </div>
        </td>
    </tr>
    <!-- ko if: type === $root.config.INPUT_TYPE.CHECKBOX
    <tr data-bind="template: { name: 'template-user-settings-checkbox' }"></tr>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-checkbox">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label"></span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                         ko if: displayTooltip === true
                        <a data-placement="top"
                        data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                         /ko
                    </div>
                </div>
                <div class="menu-checkbox-div" data-bind="click: toggleCheckbox, touchstart: toggleCheckbox">
                    <i class="far fa-square fa-lg" data-bind="css: { 'fa-check-square': obv() === true, 'fa-square': obv() === false }"></i>
                </div>
            </div>
        </td>
    </script> -->

</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeCheckbox',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const checkbox = toRef(props, "inputFromParent")

        return {
            checkbox
        }
    }
}
</script>

<style lang="scss" scoped>

</style>