<template>
    <div class="settings-checkbox-div" data-bind="template: { name: 'template-settings-checkbox-element' }">
        <div @click="checkbox.toggleCheckbox"
            class="menu-checkbox-div" 
            data-bind="click: toggleCheckbox, touchstart: toggleCheckbox">
            <i :class="{ 'fa-check-square': checkbox.obv.value === true, 'fa-square': checkbox.obv.value === false }"
                class="far fa-lg" 
                data-bind="css: { 'fa-check-square': obv() === true, 'fa-square': obv() === false }">
            </i>
        </div>
    </div>

    <!-- ko if: type === $root.config.INPUT_TYPE.CHECKBOX
    <div class="settings-checkbox-div" data-bind="template: { name: 'template-settings-checkbox-element' }"></div>
    /ko -->
    <!-- <script type="text/html" id="template-settings-checkbox-element">
        <div class="menu-checkbox-div" data-bind="click: toggleCheckbox, touchstart: toggleCheckbox">
            <i class="far fa-square fa-lg" data-bind="css: { 'fa-check-square': obv() === true, 'fa-square': obv() === false }"></i>
        </div>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'MenuTableCellInputCheckbox',
    props: {
        cellObjectFromParent: Object
    },
    setup (props) {
        const checkbox = toRef(props, "cellObjectFromParent");

        return {
            checkbox
        }
    }
}
</script>

<style lang="scss" scoped>

</style>