<template>
<popup-modal ref="popup">
        <div id="modal" class="messageBox verify-email-modal verify-transfer-licence-modal">
        <div class="modal-header modal-header-text">
            <h4 class="modal-title new-header-text" >{{ getTranslationText('4254') }}</h4>
        </div>
        <form id="verification-modal" @submit.prevent="verify">
            <div class="modal-body modal-body-text">
                <div class="modal-body-text-div" v-html="needToVerifyMessageText"></div>
                <div class="modal-body-text-div email-text">
                    <span >{{ emailAddress }}</span>
                </div>
                <div class="modal-input">
                    <input id="code-element-1" :data-title="getTranslationText('4253')" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" />
                    <input id="code-element-2" :data-title="getTranslationText('4253')" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" />
                    <input id="code-element-3" :data-title="getTranslationText('4253')" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" />
                    <input id="code-element-4" :data-title="getTranslationText('4253')" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" />
                </div>
            </div>
            <div class="modal-footer">
                <div class="button-container">
                    <button id="verify-licence_transfer-button" class="btn btn-default btn-truckscience btn-primary" type="submit"><span >{{ getTranslationText('4251') }}</span></button>
                </div>
                <div class="message-container">                
                    <span v-html="getTranslationText('4252')"></span>
                </div>
                <div class="get-started-background"></div>
            </div>
        </form> 
    </div>
</popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import PopupModal from './PopupModal.vue';
// import { defineComponent } from 'vue/composition-api';
import { ref, toRef, onBeforeMount, onMounted, nextTick } from 'vue';

const $ = window.$;


    // function VerifyTransferLicenceModal(callback, emailAddress) {
    //     var self = this;
    //     self.getTranslationText = config.getTranslationText;
    //     this.needToVerifyMessageText = self.getTranslationText('4241', [emailAddress]);
        
    //     this.verificationCode = ko.observable('');
    //     this.emailAddress = emailAddress;
        
    //     this.compositionComplete = function () {
    //         $('.verify-email-input').on('focus', function () {
    //             if (globals.isiPad()) {
    //                 setTimeout(function () {
    //                     document.getElementById(this.id).selectionStart = 0
    //                     document.getElementById(this.id).selectionEnd = 999
    //                 }, 1);
    //                 //document.getElementById(self.id).selectionStart = 0
    //                 //document.getElementById(self.id).selectionEnd = 999
    //             } else {
    //                 $(this).select();
    //             }
    //         });


    //         $('.verify-email-input').on('keyup', function () {
    //             if ($(this).val()) {
    //                 $(this).removeClass('error');
    //                 $(this).tooltip('destroy');
    //                 $(this).nextAll('.verify-email-input').first().focus();
    //             }
    //             if (this.id === 'code-element-4') {
    //                 $('#verify-licence_transfer-button').focus();
    //             }
    //         });

    //         $('.verify-modal-a').on('click touchstart', function () {
    //             if (this.id === 'resendEmail') {
    //                 self.resendEmail();
    //             } else {
    //                 self.startAgain();
    //             }
    //         });
    //         setTimeout(function () { $('#code-element-1').focus(); }, 100);
    //     };
        
    //     this.callback = callback;
    // }

    // VerifyTransferLicenceModal.show = function (callback, emailAddress) {
    //     globals.user.updateUser({ licenceTransferCodeRequired: true });
    //     return dialog.show(new VerifyTransferLicenceModal(callback, emailAddress));
    // };

    // VerifyTransferLicenceModal.prototype.verify = function () {
    //     var self = this,
    //         verificationCode = "";

    //     if (checkValues()) {
    //         this.callback({ Action: 'verify', Code: verificationCode });
    //         dialog.close(this, false);
    //     }

    //     function checkValues() {
    //         var returnVal = true,
    //             tooltipCreated = false;
    //         $('.verify-email-input').each(function () {
    //             if (!$(this).val()) {
    //                 $(this).addClass('error');
    //                 returnVal = false;
    //                 if (!tooltipCreated) {
    //                     tooltipCreated = true;
    //                     $(this).tooltip({ trigger: 'manual', html: true }).tooltip('show');
    //                 }
    //             } else {
    //                 verificationCode = verificationCode.concat($(this).val());
    //             }
    //         });

    //         return returnVal;
    //     }
        
    // };

    // VerifyTransferLicenceModal.prototype.resendEmail = function () {
    //     this.callback({ Action: 'resendEmail' });
    //     dialog.close(this, false);
    // };

    // VerifyTransferLicenceModal.prototype.startAgain = function () {
    //     this.callback({ Action: 'startAgain' });
    //     dialog.close(this, false);
    // };
        

    // return VerifyTransferLicenceModal;



export default {
    name: 'VerifyTransferLicenceModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props, context) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        let emailAddress = ref(opts.value.emailAddress),
        varificationCode = ref(''),
        getTranslationText = config.getTranslationText,
        needToVerifyMessageText = getTranslationText('4241', [emailAddress]),
        callback = null,
        resolvePromise = undefined,
        rejectPromise = undefined;

        onBeforeMount(() => {
            globals.user.updateUser({ licenceTransferCodeRequired: true });
            callback = opts.value.callbackForVerification;
            emailAddress = opts.value.emailAddress;

        })

        onMounted(() => {
            popup.value.open();
            nextTick(compositionComplete);
            
        })

        function compositionComplete() {
            $('.verify-email-input').on('focus', function () {
                if (globals.isiPad()) {
                    setTimeout(function () {
                        document.getElementById(this.id).selectionStart = 0
                        document.getElementById(this.id).selectionEnd = 999
                    }, 1);
                    //document.getElementById(self.id).selectionStart = 0
                    //document.getElementById(self.id).selectionEnd = 999
                } else {
                    $(this).select();
                }
            });


            $('.verify-email-input').on('keyup', function () {
                if ($(this).val()) {
                    $(this).removeClass('error');
                    $(this).tooltip('destroy');
                    $(this).nextAll('.verify-email-input').first().focus();
                }
                if (this.id === 'code-element-4') {
                    $('#verify-licence_transfer-button').focus();
                }
            });

            $('.verify-modal-a').on('click touchstart', function () {
                if (this.id === 'resendEmail') {
                    resendEmail();
                } else {
                    startAgain();
                }
            });
            setTimeout(function () { $('#code-element-1').focus(); }, 100);
        }

        function show() {
           

            
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
        }

        function verify() {
            var self = this,
                verificationCode = "";

            if (checkValues()) {
                callback({ Action: 'verify', Code: verificationCode });
                popup.value.close();
                resolvePromise();
            }

            function checkValues() {
                var returnVal = true,
                    tooltipCreated = false;
                $('.verify-email-input').each(function () {
                    if (!$(this).val()) {
                        $(this).addClass('error');
                        returnVal = false;
                        if (!tooltipCreated) {
                            tooltipCreated = true;
                            $(this).tooltip({ trigger: 'manual', html: true }).tooltip('show');
                        }
                    } else {
                        verificationCode = verificationCode.concat($(this).val());
                    }
                });

                return returnVal;
            }
            
        }

        function resendEmail() {
            callback({ Action: 'resendEmail' });
            popup.value.close();
            resolvePromise();
        }

        function startAgain() {
            callback({ Action: 'startAgain' });
            popup.value.close();
            resolvePromise();
        }

        return {
            show,
            verify,
            popup,
            resendEmail,
            startAgain,
            emailAddress,
            varificationCode,
            needToVerifyMessageText,
            getTranslationText
        }
    },
}
</script>
