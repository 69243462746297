import config from '../config';
import globals from '../globals';
import logger from '../logger';
// import pdfMake from '../../assets/scripts/pdfmake.min';
// import pdfjsLib from '../../assets/scripts/pdf.min';

const $ = window.$;
const pdfMake = window.pdfMake;
const pdfjsLib = window.pdfjsLib;

var CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;
var TScMessenger = window.TScMessenger;

var pdfCreationPromise;
/**
* Takes in the document definition JSON object and creates PDF using PDFMake
* @method createPdf
* @param {Object} docDef JSON object that contains the structure of the PDF document to be created.
* @returns {Object} returns the PDF document
* 
*/
function createPdf(docDef) {
    pdfCreationPromise = $.Deferred();
    pdfMake.fonts = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Bold.ttf',
            italics: 'Roboto-Regular.ttf',
            bolditalics: 'Roboto-Regular.ttf'
        }
    };
    try {
        pdfMake.createPdf(docDef).getDataUrl(function (outDoc) {
            pdfCreationPromise.resolve(outDoc);
        });
    } catch (e) {
        logger.log('Failed PDF creation', undefined, 'pdfManager.createPdf', true, undefined, config.LOG_MESSAGE_TYPE.ERROR);
        pdfCreationPromise.resolve(undefined);
    }
    
    return pdfCreationPromise.promise();
}

/**
* Takes in the PDF data uri and displays it on the page using  and creates PDF using PDFMake
* @method displayPdf
* @param {String} pdfDataUri The data uri of the pdf to be displayed on the screen
* @param {Number} totalPages The number of pages in the PDF
* @param {String} containerId The id of the element that will display the document
* @param {String} canvasClassName The class name used for displaying the page of the document. Needed in order to display landscape/portrait correctly
* @param {Promise} pdfPromise Creating PDF is a long process so a promise will be used. Displaying is last step so promise will resolve
* 
*/
function displayPdf(pdfDataUri, totalPages, containerId, canvasClassName, pdfPromise) {
    var temp = pdfDataUri.split(",");
    var pdfData = globals.base64ToUint8Array(temp[1]);
    var currentPage = 1;
    //var appVersionNumber = CustomTruckScienceRequirements.getMetaTagContent('app-version-number').replace(/\./g, '');

    function getPdfWorkerFileName() {
        var fileName = '', scriptTags = $('script');

        if (CustomTruckScienceRequirements.getBrowser() !== CustomTruckScienceRequirements.BROWSER_NAME.FIREFOX) {
            for (var i = 0; i < scriptTags.length; i++) {
                if ($(scriptTags[i]).attr('src') !== undefined && $(scriptTags[i]).attr('src').includes('pdf.worker')) {
                    fileName = $(scriptTags[i]).attr('src');
                    break;
                }
            }
        } else {
            fileName = '../../../public/vendor/pdf.worker.min.js';
        }
        return fileName !== '' ? fileName : '../../../public/vendor/pdf.worker.min.js';
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc = getPdfWorkerFileName();
    //PDFJS.workerSrc = 'Scripts/pdf.worker.min.js'
    //PDFJS.workerSrc = CustomTruckScienceRequirements.getMetaTagContent('cdn') + 'Scripts/pdf.worker.min.js';
    //PDFJS.workerSrc = 'Scripts/pdf.worker_' + appVersionNumber + '.min.js';
    //pdfjsLib.getDocument(pdfData).then(function (pdf) {
    pdfjsLib.getDocument({
        data: pdfData,
        verbosity: pdfjsLib.VerbosityLevel.ERROR
    }).then(function (pdf) {
        function createCanvasElement(pageNum) {
            var canv = document.createElement('canvas');
            canv.id = 'pdf-canvas-' + pageNum;
            canv.className = canvasClassName;
            document.getElementById(containerId).appendChild(canv);
        }

        // delete canvas elements if they exist
        // Removing all children from an element
        var element = document.getElementById(containerId);
        if (element !== undefined && element !== null) {
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        }

        // create canvas elements to display PDF pages
        for (var i = 1; i <= pdf.numPages; i++) {
            createCanvasElement(i);
        }

        // Create the PDF
        if (currentPage <= pdf.numPages) getPage();

        function getPage() {
            pdf.getPage(currentPage).then(function (page) {
                var scale = 5;
                var viewport = page.getViewport(scale);
                var canvas = document.getElementById('pdf-canvas-' + currentPage);
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                page.render(renderContext).then(function () {
                    //pages.push(canvas.toDataURL());
                    if (currentPage < pdf.numPages) {
                        currentPage++;
                        getPage();
                    } else {
                        if (pdfPromise !== undefined && totalPages !== undefined) {
                            pdfPromise.resolve({ noOfPages: totalPages });
                        }
                    }
                });
            });
        }
    }).catch(function (error) {
        TScMessenger.writeErrorMessage('Error raised in pdfjsLib.getDocument');
        console.log(error);
    });
}

/**
 * 
 * @param {string} containerId - Id of element to remove all elements
 */
function clearPdfContainerUsingId(containerId) {
    // delete canvas elements if they exist
    // Removing all children from an element
    var element = document.getElementById(containerId);
    if (element !== undefined && element !== null) {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }
}

var pdfManager = {
    createPdf: createPdf,
    displayPdf: displayPdf,
    clearPdfContainerUsingId: clearPdfContainerUsingId
};

  export default pdfManager