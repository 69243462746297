<template>
    <div :class="['buy-now-container', 'intercom-button-displayed', menuStatus === config.PAYMENT_MENU_OPTIONS.UPDATE_PAYMENT_DETAILS ? 'update-payment-details' : '',  menuStatus === $root.config.PAYMENT_MENU_OPTIONS.PAYMENT_DETAILS_EXPIRED ? 'payment-details-expired' : '']" @click="paymentMenuMainButtonClick" @touchstart="paymentMenuMainButtonClick">
        <div class="icon-container">
            <i :class="['fa-lg', menuStatus === $root.config.PAYMENT_MENU_OPTIONS.BUY_NOW ? 'fas fa-shopping-cart' : '', menuStatus === $root.config.PAYMENT_MENU_OPTIONS.UPDATE_PAYMENT_DETAILS || menuStatus === $root.config.PAYMENT_MENU_OPTIONS.PAYMENT_DETAILS_EXPIRED ? 'far fa-credit-card' : '']"></i>
        </div>
        <div class="text-container">
            <span v-text="mainButtonText"></span>
        </div>
    </div>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import bus from '@/main';
import { shallowRef, computed, onBeforeMount, onMounted, toRef } from 'vue';

const $ = window.$;

export default {
    name: 'PaymentButton',
    props: {
        paymentMenuStatus: Object
    },
    setup(props) {
        const menuStatusWrapper = toRef(props, 'paymentMenuStatus')
        function isIntercomButtonVisible() {
            return $('.intercom-launcher-frame').length > 0 && $('.intercom-launcher-frame').is(':visible');
        }

        function paymentMenuMainButtonClick() {
            var headerText = '',
                subheaderText = '';
            switch (menuStatusWrapper.value.paymentMenuStatus.value) {
                case config.PAYMENT_MENU_OPTIONS.BUY_NOW:
                    // Check if NTEA option is available
                    if (globals.user.isTrialUser() && !globals.user.isVipTrialUser() && globals.user.displayNteaOptionsToUser()) {
                        // [NTEA]() member? [Enter membership details]() to qualify for discount
                        //headerText = '<a href=\"https://www.ntea.com/\" target=\"_blank\">NTEA</a> member? [Enter membership details]() to qualify for discount';
                        subheaderText = config.getTranslationText('1206');
                    } else if (globals.user.isTrialUser() && globals.user.isVipTrialUser() && globals.user.displayNteaOptionsToUser()) {
                        // NTEA member discount has been applied
                        if (globals.user.isUserIntegratedWithNTEA()) {
                            subheaderText = config.getTranslationText('1205');
                        } else {
                            subheaderText = config.getTranslationText('1206');
                        }
                    } else {
                        headerText = '';
                    }
                    // if (globals.user.allowGoogleAnalyticsCall()) {
                    //     dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.BUY_NOW_CLICKED);
                    // }
                    //dataManager.registerBuyNowClick();
                    bus.emit('openBuyNowModal', { headerText: headerText, subheaderText: subheaderText });
                    break;
                case config.PAYMENT_MENU_OPTIONS.UPDATE_PAYMENT_DETAILS:
                case config.PAYMENT_MENU_OPTIONS.PAYMENT_DETAILS_EXPIRED:
                    bus.emit('openUpdatePaymentMethodModal');
                    break;
                default:
                    alert('Unhandled PAYMENT_MENU_OPTIONS option');
                    break;
            }

        }

        return {
            mainButtonText: computed(function () {
                switch (menuStatusWrapper.value.paymentMenuStatus.value) {
                    case config.PAYMENT_MENU_OPTIONS.BUY_NOW:
                        return config.getTranslationText('54');
                    case config.PAYMENT_MENU_OPTIONS.UPDATE_PAYMENT_DETAILS:
                    case config.PAYMENT_MENU_OPTIONS.PAYMENT_DETAILS_EXPIRED:
                        return config.getTranslationText('520');
                    default:
                        return '';
                }
            }),
            menuStatus: menuStatusWrapper.value.paymentMenuStatus,
            paymentMenuMainButtonClick: paymentMenuMainButtonClick,
            isIntercomButtonVisible: isIntercomButtonVisible,
            config
        }
    },
}
</script>