
var Instruction = function (instruction) {
    var self = this;
    var tempInstruction = instruction.split(' ');

    self.Instruction = tempInstruction[0];
    if (tempInstruction[1] !== undefined) {
        self.Parameter = tempInstruction[1];
    } else {
        self.Parameter = undefined;
    }
};

export default Instruction;
