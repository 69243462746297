import config from '../../../services/config';
import globals from '../../../services/globals';
import dataManager  from '../../../services/dataManager';
// import { config, globals, dataManager } from '../../../services';
import { shallowRef } from 'vue';

var AccessoryStub = function (id, description, source, accessoryType, accessLevel, fkTrailerId, updateCounter, cachedState, accessorySubType, make, range, status, editLevel) {
    var self = this;
    //#region Private members
    var _id = id,
        _description = description,
        _source = source,
        _accessoryType = accessoryType,
        _accessLevel = accessLevel,
        _updateCounter = updateCounter || 0,
        _cached = cachedState || 0,
        _make = make,
        _range = range,
        _status = status,
        _editLevel = editLevel,
        _accessorySubType = accessorySubType || '';
    //#endregion Private members
    if (_accessorySubType !== '' && _accessoryType === config.ACCESSORY_TYPES.OTHER) {
        _accessorySubType = _accessorySubType.toLowerCase();
        _accessorySubType = _accessorySubType.charAt(0).toUpperCase() + _accessorySubType.slice(1);
    }
    //#region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getSource() {
        return _source;
    }
    function setSource(newValue) {
        _source = newValue;
    }
    function getAccessoryType() {
        return _accessoryType;
    }
    function setAccessoryType(newValue) {
        _accessoryType = newValue;
    }
    function getAccessLevel() {
        return _accessLevel;
    }
    function setAccessLevel(newValue) {
        _accessLevel = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getMake() {
        return _make;
    }
    function setMake(newValue) {
        _make = newValue;
    }
    function getRange() {
        return _range;
    }
    function setRange(newValue) {
        _range = newValue;
    }
    function getStatus() {
        return _status;
    }
    function setStatus(newValue) {
        _status = newValue;
    }
    function getEditLevel() {
        return _editLevel;
    }
    function getCached() {
        return _cached;
    }
    function setCached(newValue) {
        _cached = newValue;
    }
    function getAccessorySubType() {
        return _accessorySubType;
    }
    function getImageClass(returnTrailerClass) {
        var folder = 'EU',
            userCultureCode = globals.getActiveCultureCodeForTranslations();
        if (globals.isAmericanCultureCode(userCultureCode) === true) {
            folder = config.LANGUAGE_PACK.ENGLISH.US.imageFolder;
        }
        if (returnTrailerClass) {
            return 'trailer-accessory-' + getImageFileName().toLowerCase();
        } else {
            return folder.toLowerCase() + '-' + getImageFileName().toLowerCase();
        }
    }
    //#endregion Getter and setters

    function getAccessoryTypeSortValue() {
        switch (_accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                return 1;
            case config.ACCESSORY_TYPES.FRIDGE:
                return 2;
            case config.ACCESSORY_TYPES.TAILLIFT:
                return 3;
            case config.ACCESSORY_TYPES.FAIRING:
                return 4;
            case config.ACCESSORY_TYPES.HEADBOARD:
                return 5;
            case config.ACCESSORY_TYPES.CREW_CAB:
                return 6;
            case config.ACCESSORY_TYPES.BULLBAR:
                return 7;
            case config.ACCESSORY_TYPES.FUEL_TANK:
                return 8;
            case config.ACCESSORY_TYPES.ROLLOVER:
                return 9;
            case config.ACCESSORY_TYPES.OTHER:
                switch (_accessorySubType) {
                    case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                        return 23;
                    case config.ACCESSORY_TYPES.AEROKIT:
                        return 21;
                    case config.ACCESSORY_TYPES.BULLBAR:
                        return 20;
                    case config.ACCESSORY_TYPES.FAIRING:
                        return 24;
                    case config.ACCESSORY_TYPES.OUTRIGGER:
                        return 27;
                    case config.ACCESSORY_TYPES.REAR_BUMPER:
                        return 36;
                    case config.ACCESSORY_TYPES.SNOWPLOW:
                        return 22;
                    case config.ACCESSORY_TYPES.TOOLBOX:
                        return 25;
                    case config.ACCESSORY_TYPES.OTHER:
                        return 99;
                    default:
                        return 99;
                }
                // Other always appears last
                //return 99;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return 11;
            case config.ACCESSORY_TYPES.HITCH:
                return 12;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return 13;
            default:
                // Accessory type is not found
                return 99;
        }
    }

    function getAccessoryTypeTranslation() {
        return globals.getAccessoryTypeTranslation(_accessoryType, _accessorySubType);
        
    }

    function getAccessorySubTypeTranslation() {
        return globals.getAccessorySubTypeTranslation(_accessoryType, _accessorySubType);
    }

    function getAccessoryTypeRemoveTooltip() {
        return globals.getAccessoryTypeRemoveTooltip(_accessoryType, _accessorySubType);
    }

    function getAccessoryTypeSaveTooltip() {
        return globals.getAccessoryTypeSaveTooltip(_accessoryType, _accessorySubType);
    }

    function getImageFileName() {
        switch (_accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                return config.ACCESSORY_TYPES.CRANE.toUpperCase();
            case config.ACCESSORY_TYPES.FRIDGE:
                return config.ACCESSORY_TYPES.FRIDGE.toUpperCase();
            case config.ACCESSORY_TYPES.TAILLIFT:
                return config.ACCESSORY_TYPES.TAILLIFT.toUpperCase();
            case config.ACCESSORY_TYPES.FAIRING:
                return config.ACCESSORY_TYPES.FAIRING.toUpperCase();
            case config.ACCESSORY_TYPES.HEADBOARD:
                return config.ACCESSORY_TYPES.HEADBOARD.toUpperCase();
            case config.ACCESSORY_TYPES.CREW_CAB:
                return config.ACCESSORY_TYPES.CREW_CAB.toUpperCase();
            case config.ACCESSORY_TYPES.BULLBAR:
                return config.ACCESSORY_TYPES.BULLBAR.toUpperCase();
            case config.ACCESSORY_TYPES.FUEL_TANK:
                return config.ACCESSORY_TYPES.FUEL_TANK.toUpperCase();
            case config.ACCESSORY_TYPES.ROLLOVER:
                return config.ACCESSORY_TYPES.ROLLOVER.toUpperCase();
            case config.ACCESSORY_TYPES.OTHER:
                //return config.ACCESSORY_TYPES.OTHER.toUpperCase();
                return getAccessorySubType().toUpperCase();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return config.ACCESSORY_TYPES.FIFTH_WHEEL.toUpperCase();
            case config.ACCESSORY_TYPES.HITCH:
                return config.ACCESSORY_TYPES.HITCH.toUpperCase();
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return config.ACCESSORY_TYPES.HOOKLIFT.toUpperCase();
            default:
                // Accessory type is not found
                // Raise error on Intercom
                var errorMessage = 'Id = ' + _id + ', Description = ' + _description + ', AccessoryType = ' + _accessoryType + ', AccessorySubType = ' + _accessorySubType + ', Source = ' + _source;
                var errorObj = {
                    message: errorMessage
                }
                dataManager.log("getImageFileName, model.accessoryStub.js, fail, 1571, line 209", errorObj, 'model.accessoryStub', config.LOG_MESSAGE_TYPE.ERROR);
                return '';
        }
    }

    /**
     * Check if accessoryType is valid. Used in configuration addFunctionWithPathForAccessoryParentIdentity function. Created in response to Bug 14235
     * @returns {boolean} True if valid, False if not valid
     * */
    function isValidAccessoryTypeToBeAdded() {
        if (_accessoryType === config.ACCESSORY_TYPES.CRANE || _accessoryType === config.ACCESSORY_TYPES.FRIDGE || _accessoryType === config.ACCESSORY_TYPES.TAILLIFT ||
            _accessoryType === config.ACCESSORY_TYPES.FAIRING || _accessoryType === config.ACCESSORY_TYPES.HEADBOARD || _accessoryType === config.ACCESSORY_TYPES.CREW_CAB ||
            _accessoryType === config.ACCESSORY_TYPES.BULLBAR || _accessoryType === config.ACCESSORY_TYPES.FUEL_TANK || _accessoryType === config.ACCESSORY_TYPES.ROLLOVER ||
            _accessoryType === config.ACCESSORY_TYPES.OTHER || _accessoryType === config.ACCESSORY_TYPES.FIFTH_WHEEL || _accessoryType === config.ACCESSORY_TYPES.HITCH ||
            _accessoryType === config.ACCESSORY_TYPES.HOOKLIFT) {
            return true;
        } else {
            return false;
        }
    }

    function getAccessoryTypeForSelectionWizard() {
        if (getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
            return getAccessoryType() + '-' + getAccessorySubType().toUpperCase();
        } else {
            return getAccessoryType();
        }
    }

    function getIntercomMetadata() {
        var returnObject = {};

        returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = getAccessoryType();

        return returnObject;
    }

    function getStatusForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            // return config.getTranslationText('4924');
            return config.ACCESSORY_STATUS_TYPES.CURRENT;
        }
        return _status;
    }
    function getMakeForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            return config.STUB_MULTISELECT_NOT_SPECIFIED;
        }
        return _make;
    }

    self.getId = getId;
    self.setId = setId;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getSource = getSource;
    self.setSource = setSource;
    self.getAccessoryType = getAccessoryType;
    self.setAccessoryType = setAccessoryType;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getCached = getCached;
    self.setCached = setCached;
    self.getAccessoryTypeSortValue = getAccessoryTypeSortValue;
    self.getAccessoryTypeTranslation = getAccessoryTypeTranslation;
    self.getIntercomMetadata = getIntercomMetadata;
    self.imageAvailable = shallowRef(true);
    self.getImageClass = getImageClass;
    self.getAccessoryTypeRemoveTooltip = getAccessoryTypeRemoveTooltip;
    self.getAccessoryTypeSaveTooltip = getAccessoryTypeSaveTooltip;
    self.getAccessLevel = getAccessLevel;
    self.setAccessLevel = setAccessLevel;
    self.getAccessorySubType = getAccessorySubType;
    self.getAccessorySubTypeTranslation = getAccessorySubTypeTranslation;
    self.getAccessoryTypeForSelectionWizard = getAccessoryTypeForSelectionWizard;
    self.getMake = getMake;
    self.getRange = getRange;
    self.getStatus = getStatus;
    self.getStatusForMultiSelect = getStatusForMultiSelect;
    self.getMakeForMultiSelect = getMakeForMultiSelect;
    self.getEditLevel = getEditLevel;
    self.isValidAccessoryTypeToBeAdded = isValidAccessoryTypeToBeAdded;
};

export default AccessoryStub;