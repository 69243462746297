import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';


    
function Fridge(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel, width, height, mass, massOverride, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride,
    status, mountingPosition, protrusion, lateralCOG, lateralCOGOverride, fromLeftOfBodyWidthExternal, verticalCOGType, lateralCOGType,
    verticalCOGConfirmed, lateralCOGConfirmed, sourceDatabaseId, updateCounter, cached, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, fromFrontOfBody, isPinned) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._status = status;
    this._mountingPosition = mountingPosition;
    this._protrusion = protrusion;
    this._fromLeftOfBodyWidthExternal = fromLeftOfBodyWidthExternal || 0;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;
    this._fromFrontOfBody = fromFrontOfBody || 0;
    this._fromCentreOfVehicle = 0;
    //this._massOverride = massOverride;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;
}   

Fridge.prototype.getWidth = function () {
    return this._width;
}
Fridge.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
Fridge.prototype.getHeight = function () {
    return this._height;
}
Fridge.prototype.setHeight = function (newValue) {
    this._height = newValue;
}
Fridge.prototype.getMass = function () {
    return this._mass;
}
Fridge.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
Fridge.prototype.getMassOverride = function () {
    return this._massOverride;
}
Fridge.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Fridge.prototype.getCOGType = function () {
    return this._cOGType;
}
Fridge.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
Fridge.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
Fridge.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
Fridge.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
Fridge.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
Fridge.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Fridge.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Fridge.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Fridge.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Fridge.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Fridge.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Fridge.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Fridge.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Fridge.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Fridge.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Fridge.prototype.getTotalLoad = function () {
    return this._mass;
}
Fridge.prototype.getStatus = function () {
    return this._status;
}
Fridge.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Fridge.prototype.getMountingPosition = function() {
    return this._mountingPosition;
}
Fridge.prototype.setMountingPosition = function (newValue) {
    this._mountingPosition = newValue;
}
Fridge.prototype.getProtrusion = function () {
    return this._protrusion;
}
Fridge.prototype.setProtrusion = function (newValue) {
    this._protrusion = newValue;
}
Fridge.prototype.getFromLeftOfBodyWidthExternal = function () {
    return this._fromLeftOfBodyWidthExternal;
}
Fridge.prototype.setFromLeftOfBodyWidthExternal = function (newValue) {
    //this._protrusion = newValue;
    this._fromLeftOfBodyWidthExternal = newValue;
}
Fridge.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Fridge.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Fridge.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Fridge.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Fridge.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Fridge.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Fridge.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Fridge.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Fridge.prototype.getIsPinned = function () {
    return this._isPinned;
};
Fridge.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
};
Fridge.prototype.getFromFrontOfBody = function () {
    return this._fromFrontOfBody;
};
Fridge.prototype.setFromFrontOfBody = function (newValue) {
    this._fromFrontOfBody = newValue;
};
Fridge.prototype.getFromCentreOfVehicle = function () {
    return this._fromCentreOfVehicle;
}
Fridge.prototype.setFromCentreOfVehicle = function (newValue) {
    this._fromCentreOfVehicle = newValue;
}
Fridge.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    }else {
        return this._horizontalCOG * this._protrusion / 100;
    }
}
Fridge.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * this._height / 100;
    }
}
Fridge.prototype.getActualLateralCOG = function () {
    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this._lateralCOG * this._width / 100;
    }
}
Fridge.prototype.getActualHeight = function () {
    return this._height
}
/**
 * Get the  JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Fridge.prototype.getFridgeObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        "Width": this._width,
        "Height": this._height,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "Status": this._status,
        //"MountingPosition": this._mountingPosition,
        "Protrusion": this._protrusion,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType
        //"FromLeftOfBodyWidthExternal": includePositionValues === true ? this._fromLeftOfBodyWidthExternal : 0
    };

    if (includePositionValues === true) {
        saveObject["MountingPosition"] = this._mountingPosition;
        saveObject["FromLeftOfBodyWidthExternal"] = this._fromLeftOfBodyWidthExternal;
        saveObject["FromFrontOfBody"] = this._fromFrontOfBody;
    }

    return saveObject;
}
/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Fridge.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getFridgeObjectToBeSaved(includePositionValues), includePositionValues);
}

Fridge.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newFridge = new Fridge(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._width,
        this._height,
        this._mass,
        this._massOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._status,
        this._mountingPosition,
        this._protrusion,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfBodyWidthExternal,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType(),
        this.getFromFrontOfBody());

    newFridge.setAccessLevel(this.getAccessLevel());
    newFridge.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newFridge.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    newFridge.setFromCentreOfVehicle(this.getFromCentreOfVehicle());

    return newFridge;
}

Fridge.prototype.update = function (fridge) {
    this.updateBase(fridge);
    this.setWidth(fridge.getWidth());
    this.setHeight(fridge.getHeight());
    this.setMass(fridge.getMass());
    this.setMassOverride(fridge.getMassOverride());
    this.setCOGType(fridge.getCOGType());
    this.setHorizontalCOG(fridge.getHorizontalCOG());
    this.setHorizontalCOGOverride(fridge.getHorizontalCOGOverride());
    this.setVerticalCOG(fridge.getVerticalCOG());
    this.setVerticalCOGOverride(fridge.getVerticalCOGOverride());
    this.setStatus(fridge.getStatus());
    this.setMountingPosition(fridge.getMountingPosition());
    this.setProtrusion(fridge.getProtrusion());
    this.setLateralCOG(fridge.getLateralCOG());
    this.setLateralCOGOverride(fridge.getLateralCOGOverride());
    this.setFromLeftOfBodyWidthExternal(fridge.getFromLeftOfBodyWidthExternal());
    this.setVerticalCOGType(fridge.getVerticalCOGType());
    this.setLateralCOGType(fridge.getLateralCOGType());
    this.setVerticalCOGConfirmed(fridge.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(fridge.getLateralCOGConfirmed());
    this.setFromFrontOfBody(fridge.getFromFrontOfBody());
    this.setFromCentreOfVehicle(fridge.getFromCentreOfVehicle());
}

utils.extend(Accessory, Fridge);

export default Fridge;

