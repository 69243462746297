<template>
    <tr class="datagrid-row" v-for="item in wizard.itemsToDisplayArray" :key="item" @click="wizard.clickOnItem(item)">
        <td class="datagrid-icon-td">
            <template v-if="item.getSource() !== VEHICLE_SOURCE_TYPES.CUSTOM">
                <div class="selection-datagrid-type-icon">
                    <div class="fa"
                        :class="{ 'fa-globe': item.getSource() === VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': item.getSource() === VEHICLE_SOURCE_TYPES.TEMPLATE }"
                        data-bind="css: { 'fa-globe': getSource() === $root.config.VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': getSource() === $root.config.VEHICLE_SOURCE_TYPES.TEMPLATE }">
                    </div>
                </div>
            </template>
            <div v-if="item.getSource() === VEHICLE_SOURCE_TYPES.CUSTOM && item.getAccessLevel() ===
            CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL" class="selection-datagrid-type-icon"><i class="fa fa-user"></i></div>
            <div v-if="item.getSource() === VEHICLE_SOURCE_TYPES.CUSTOM && item.getAccessLevel() ===
            CUSTOM_ITEM_ACCESS_LEVEL.COMPANY" class="selection-datagrid-type-icon"><i class="fa fa-users"></i></div>
        </td>
        <td class="datagrid-column">
            <span>{{ item.getDescription() }}</span>
        </td>
        <template v-if="item.getAccessoryType() !== ACCESSORY_TYPES.PAYLOAD">
            <td class="datagrid-column accessory-sub-type-column">
                <span>{{ item.getAccessorySubTypeTranslation() }}</span>
            </td>
        </template>
        <td class="datagrid-icon-td">
            <div v-if="item.getEditLevel() === ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT"
                class="selection-datagrid-type-icon-small datagrid-icon-no-edit"
                :title="wizard.noEditIconTooltipText"
                data-bind="clickBubble: false, attr: { title: $parents[1].noEditIconTooltipText }">
                <i class="fas fa-lock-alt fa-lg"></i>
            </div>
            <div v-if="item.getSource() === VEHICLE_SOURCE_TYPES.CUSTOM"
                @click.stop="wizard.deleteItem(item)"
                :title="wizard.deleteIconTooltipText"
                class="selection-datagrid-type-icon-small datagrid-icon-delete"
                data-bind="click: $parents[1].deleteItem, clickBubble: false, attr: { title: $parents[1].deleteIconTooltipText }">
                <i class="fa fa-trash-alt fa-lg"></i>
            </div>
        </td>
    </tr>

    <!-- Template = "template-accessorytype-datagrid-row" 
    <td class="datagrid-icon-td">
         ko if: getSource() !== $root.config.VEHICLE_SOURCE_TYPES.CUSTOM 
        <div class="selection-datagrid-type-icon" data-bind="css: { 'fa fa-globe': getSource() === $root.config.VEHICLE_SOURCE_TYPES.STANDARD, 'datagrid-icon-generic': getSource() === $root.config.VEHICLE_SOURCE_TYPES.TEMPLATE }"></div>
        <div class="selection-datagrid-type-icon">
            <div class="fa" data-bind="css: { 'fa-globe': getSource() === $root.config.VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': getSource() === $root.config.VEHICLE_SOURCE_TYPES.TEMPLATE }"></div>
        </div>
         /ko 
         ko if: getSource() === $root.config.VEHICLE_SOURCE_TYPES.CUSTOM && getAccessLevel() === $root.config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL 
        <div class="selection-datagrid-type-icon"><i class="fa fa-user"></i></div>
         /ko 
         ko if: getSource() === $root.config.VEHICLE_SOURCE_TYPES.CUSTOM && getAccessLevel() === $root.config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY 
        <div class="selection-datagrid-type-icon"><i class="fa fa-users"></i></div>
         /ko 
    </td>
    <td class="datagrid-column">
        <span data-bind="text: getDescription()"></span>
    </td>
    <td class="datagrid-icon-td">
         ko if: getEditLevel() === $root.config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT 
        <div class="selection-datagrid-type-icon-small datagrid-icon-no-edit" data-bind="clickBubble: false, attr: { title: $parents[1].noEditIconTooltipText }">
            <i class="fas fa-lock-alt fa-lg"></i>
        </div>
         /ko 
         ko if: getSource() === $root.config.VEHICLE_SOURCE_TYPES.CUSTOM 
        <div class="selection-datagrid-type-icon-small datagrid-icon-delete" data-bind="click: $parents[1].deleteItem, clickBubble: false, attr: { title: $parents[1].deleteIconTooltipText }">
            <i class="fa fa-trash-alt fa-lg"></i>
        </div>
         /ko 
    </td> -->

    <!-- <tr>
        <td>template-accessorytype-row</td>
    </tr> -->

    <!-- <script type="text/html" id="template-accessorytype-row">
        <tr class="datagrid-row" data-bind="template: {name: 'template-accessorytype-datagrid-row'}, click: $parents[1].clickOnItem"></tr>
    </script> -->
</template>

<script>
import { ref, watch } from 'vue';
import config from '../../services/config';

export default {
    name: 'SelectionDatagridBodyTypeRow',
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        return {
            wizard,
            VEHICLE_SOURCE_TYPES: config.VEHICLE_SOURCE_TYPES,
            CUSTOM_ITEM_ACCESS_LEVEL: config.CUSTOM_ITEM_ACCESS_LEVEL,
            ITEM_EDIT_LEVEL_OPTIONS: config.ITEM_EDIT_LEVEL_OPTIONS,
            ACCESSORY_TYPES: config.ACCESSORY_TYPES
        }
    }
}
</script>

<style lang="scss" scoped>
</style>