<template>
    <tr :class="{ 'template-user-settings-textarea': text.useTextArea }"
        data-bind="template: { name: 'template-user-settings-text' }, css: { 'template-user-settings-textarea': $data.useTextArea }">
        <td class="user-settings-modal-td-desc">
            <div>
                <span data-bind="text: label">{{text.label}}</span>
            </div>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="text.displayTooltip === true"
                            :id="text.tooltipId"
                            :data-content="text.tooltipMessage"
                            @click="text.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                    <div v-if="text.displayUpgradeTooltip === true"
                        class="increment-div info-tooltip-div info-upgrade-div">
                        <a :id="text.upgradeTooltipMessageId"
                            :data-content="text.upgradeTooltipMessage"
                            @click="text.showUpgradePopover"
                            data-placement="top"
                            data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                </div>
                
                <div v-if="text.useTextArea === false"
                    class="settings-modal-text-input-container-div">
                    <input :id="text.id"
                        :disabled="text.isDisabled.value"
                        :title="text.errorMessage.value"
                        :data-original-title="text.errorMessage.value"
                        :type="inputType()"
                        :autocomplete="autocompleteType()"
                        @focus="text.selectAllText"
                        @blur="text.blurFunction"
                        v-model="text.obv.value"
                        class="form-control login-input menu-input" 
                        data-placement="right" 
                        data-bind="value: obv, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, disabled: isDisabled() === true, type: isPassword === true ? 'password' : 'text', title: errorMessage, 'data-original-title': errorMessage, autocomplete: isPassword === true ? 'new-password' : '' }" 
                    />
                </div>
                <div v-if="text.useTextArea === true" 
                    @click="clickOnTextArea"
                    class="settings-modal-text-input-container-div">
                    <textarea :id="text.id"
                        :readonly="text.isDisabled.value"
                        :title="text.errorMessage.value"
                        :maxlength="text.maxLength"
                        @focus="text.selectAllText"
                        @blur="text.blurFunction"
                        v-model="text.obv.value"
                        class="form-control login-input menu-input" 
                        data-bind="value: obv, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, disabled: isDisabled() === true, title: errorMessage, maxlength: maxlength }">
                    </textarea>
                </div>
            </div>
        </td>
    </tr>

    <!-- ko if: type === $root.config.INPUT_TYPE.TEXT
    <tr data-bind="template: { name: 'template-user-settings-text' }, css: { 'template-user-settings-textarea': $data.useTextArea }"></tr>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-text">
        <td class="user-settings-modal-td-desc">
            <div>
                <span data-bind="text: label"></span>
            </div>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        ko if: displayTooltip === true
                        <a data-placement="top"
                        data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                        /ko
                    </div>
                    ko if: displayUpgradeTooltip === true
                    <div class="increment-div info-tooltip-div info-upgrade-div">
                        <a data-placement="top"
                        data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                    /ko
                </div>
                
                ko if: useTextArea === false
                <div class="settings-modal-text-input-container-div">
                    <input class="form-control login-input menu-input" data-placement="right" autocomplete="new-password" data-bind="value: obv, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, disabled: isDisabled() === true, type: isPassword === true ? 'password' : 'text', title: errorMessage, 'data-original-title': errorMessage, autocomplete: isPassword === true ? 'new-password' : '' }" />
                </div>
                /ko
                ko if: useTextArea === true
                <div class="settings-modal-text-input-container-div" data-bind="event: { click: isDisabled() === true ? clickFunction : null }">
                    <textarea class="form-control login-input menu-input" data-bind="value: obv, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, disabled: isDisabled() === true, title: errorMessage, maxlength: maxlength }"></textarea>
                </div>
                /ko
            </div>
        </td>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeText',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const text = toRef(props, "inputFromParent");

        function inputType() {
            if(text.value.isPassword === true) {
                return 'password';
            } else {
                return 'text';
            }
        }

        function autocompleteType() {
            if(text.value.isPassword === true) {
                return 'new-password';
            } else {
                return '';
            }
        }

        function clickOnTextArea() {
            if(text.value.isDisabled.value === true) {
                text.value.clickFunction();
            }
        }

        return {
            text,
            inputType,
            autocompleteType,
            clickOnTextArea
        }
    }
}
</script>

<style lang="scss" scoped>

</style>