<template>    
    <div>
        <span data-bind="text: value">{{getCellText()}}</span>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-text">
        <div>
            <span data-bind="text: value"></span>
        </div>
    </script> -->
    
</template>

<script>
import { toRef, isRef } from 'vue';

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const cell = toRef(props, "valueFromParent");

        function getCellText() {
            if(isRef(cell.value.value)) {
                return cell.value.value.value;
            } else {
                return cell.value.value;
            }
        }

        return {
            cell,
            getCellText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>