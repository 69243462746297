// define(['services/config'], function (config) {
// import config from '../config';

/**
 * 
 * @param {string} id - ID of the button
 * @param {string} label - Label of the button - displayed to user
 * @returns {object}
 */
function ButtonBuilder(id, label) {
    var self = this,
        newElement = {
        id: id
    };

    /**
     * Add CSS class names
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomCss(css) {
        newElement.customCss = css;
        return self;
    }

    function build() {
        return newElement;
    }

    this.addCustomCss = addCustomCss;
    this.build = build;
}

/**
 * Use to create object to be passed to RadioGroup
 * @constructor
 * @param {string} id - Id of the radio button group
 * @param {string} label - Descriptive text about the radio group - Not displayed to user
 * @param {string} name - Name to be used to group the options together
 * @param {RadioGroupOptionBuilder[]} options - Options that can be selected
 * @returns {object}
 */
function RadioGroupBuilder(id, label, name, options) {
    if (typeof id !== 'string') {
        throw 'RadioGroupBuilder id param is not a string';
    }
    if (typeof label !== 'string') {
        throw 'RadioGroupBuilder label param is not a string';
    }
    if (typeof name !== 'string') {
        throw 'RadioGroupBuilder name param is not a string';
    }
    if (!Array.isArray(options)) {
        throw 'RadioGroupBuilder options param is not an array';
    }
    var self = this,
        newElement = {
            id: id,
            label: label,
            name: name,
            options: options
    };

    /**
     * 
     * @param {RadioGroupOptionBuilder[]} o
     */
    function addOptions(o) {
        if (!Array.isArray(o)) {
            throw 'RadioGroupBuilder addOptions not created correctly';
        }
        newElement.options = o;
        return self;
    }

    /**
     * Add CSS class names
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomCss(css) {
        newElement.customCss = css;
        return self;
    }

    /**
     * 
     * @param {function} cb - Function to call 
     */
    function onChangeCallback(cb) {
        if (typeof cb !== 'function') {
            throw 'RadioGroupBuilder cb is not a function';
        }
        newElement.onChangeCallback = cb;
        return self;
    }

    function build() {
        if (!Array.isArray(newElement.options)) {
            throw 'RadioGroupBuilder options not created correctly';
        }
        return newElement;
    }
            
    this.addOptions = addOptions;
    this.addCustomCss = addCustomCss;
    this.onChangeCallback = onChangeCallback;
    this.build = build;
}

/**
 * Create object to be passed to RadioGroupOption
 * @constructor
 * @param {string} id - ID of the radio option
 * @param {string} label - The label to be displayed the radio button
 * @param {string} value - The value that will be used to set the 
 */
function RadioGroupOptionBuilder(id, label, value) {
    if (typeof id !== 'string') {
        throw 'RadioGroupOptionBuilder id param is not a string';
    }
    if (typeof label !== 'string') {
        throw 'RadioGroupOptionBuilder label param is not a string';
    }
    if (typeof value !== 'string') {
        throw 'RadioGroupOptionBuilder value param is not a string';
    }
    var self = this,
        newElement = {
            id: id,
            label: label,
            value: value
        };

    /**
     * Add CSS class names
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomCss(css) {
        newElement.customCss = css;
        return self;
    }

    /**
     * Default this option to be checked
     */
    function isChecked() {
        newElement.isChecked = true;
        return self;
    }

    function build() {
        return newElement;
    }

    this.addCustomCss = addCustomCss;
    this.isChecked = isChecked;
    this.build = build;
}

/**
 * Create object to be passed to FileInputForm
 * @constructor
 * @param {string} id - ID of the file Input
 * @param {string} label - The label will be displayed to the user
 * @param {string} name - The name of the HTML input element
 */
function FileInputFormBuilder(id, label, name) {
    if (typeof id !== 'string') {
        throw 'FileInputFormBuilder id param is not a string';
    }
    if (typeof label !== 'string') {
        throw 'FileInputFormBuilder label param is not a string';
    }
    if (typeof name !== 'string') {
        throw 'FileInputFormBuilder name param is not a string';
    }
    var self = this,
        newElement = {
            id: id,
            label: label,
            name: name
        };

    /**
     * Add CSS class names
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomCss(css) {
        newElement.customCss = css;
        return self;
    }

    /**
     * Add CSS class names to be added to the input element
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomInputCss(css) {
        newElement.customInputCss = css;
        return self;
    }

    /**
     * Add CSS class names to be added to the div that surrounds the input and button divs
     * @param {string} css - Name of class. To add more than 1, separate using empty (' ') space eg. 'class1 class2'
     */
    function addCustomInputContainerCss(css) {
        newElement.customInputContainerCss = css;
        return self;
    }

    /**
     * Comma separated list of the file types. See MDN documentation for more details (https: //developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers)
     * @param {string} ft - String of the accepted file types
     */
    function fileTypes(ft) {
        if (typeof ft !== 'string') {
            throw 'FileInputFormBuilder fileTypes ft parameter is not a string';
        }
        newElement.fileTypes = ft;
        return self;
    }

    /**
     * Include section where user can drap and drop file
     */
    function includeDragAndDropSection() {
        newElement.includeDragAndDropSection = true;
        return self;
    }

    /**
     * Include submit in design
     * @param {string} text - Text to be displayed on the button         
     * @param {function} cb - Callback function to be called when user clicks on button
     * @param {string} [icon] - Font Awesome CSS class to be displayed beside the text in the button
     */
    function includeSubmitButton(text, cb, icon) {
        if (typeof text !== 'string') {
            throw 'includeSubmitButton text param is not a string';
        }
        if (typeof cb !== 'function') {
            throw 'includeSubmitButton cb param is not a function';
        }
        newElement.displaySubmitButton = true;
        newElement.submitButtonText = text;
        newElement.submitButtonCallback = cb;
        newElement.includeIconInButton = typeof icon === 'string';
        newElement.submitButtonIcon = typeof icon === 'string' ? icon : '';

        return self;
    }

    /**
     * 
     * @param {any} cb
     */
    function onChangeCallback(cb) {
        if (typeof cb !== 'function') {
            throw 'onChangeCallback cb param is not a function';
        }
        newElement.onChangeCallback = cb;
        return self;
    }

    function build() {
        return newElement;
    }

    this.addCustomCss = addCustomCss;
    this.addCustomInputCss = addCustomInputCss;
    this.addCustomInputContainerCss = addCustomInputContainerCss;
    this.fileTypes = fileTypes;
    this.includeDragAndDropSection = includeDragAndDropSection;
    this.includeSubmitButton = includeSubmitButton;
    this.onChangeCallback = onChangeCallback;
    this.build = build;
}

var UIInputBuilder = {
    ButtonBuilder: ButtonBuilder,
    RadioGroupBuilder: RadioGroupBuilder,
    RadioGroupOptionBuilder: RadioGroupOptionBuilder,
    FileInputFormBuilder: FileInputFormBuilder
};

export default UIInputBuilder;
    // return UIInputBuilder;
// });