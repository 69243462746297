import config from '../../../services/config';
import globals from '../../../services/globals';
// import { globals, config } from '@/services';
import Waypoint from './model.waypoint';
import Toll from './model.toll';
import LZString from '@/assets/scripts/lz-string-1.3.3.min';



var Performance = function (bottomRightLatitude, bottomRightLongitude, topLeftLatitude, topLeftLongitude, coefficientOfDrag, speedMaxLoaded, revsMaxPullingLoaded, topGearRatio, revsGoverned, adblueRate) {

    var self = this;
    //#region Private members
    var _bottomRightLatitude,
        _bottomRightLongitude,
        _topLeftLatitude,
        _topLeftLongitude,
        _waypoints,
        //_simulationResults,
        _tripCosts,
        _tripDistanceOutbound,
        _totalFuelOutbound,
        _tripTimeOutbound,
        _fuelConsumptionOutbound,
        _tripDistanceReturn,
        _totalFuelReturn,
        _tripTimeReturn,
        _fuelConsumptionReturn,
        _coefficientOfDrag,
        _speedMaxLoaded,
        _vehicleTotalUnladenOverride = false,
        _vehicleTotalUnladen,
        _frontalAreaOverride = false,
        _frontalArea,
        _coefficientOfDragOverride = false,
        _driveAxleRatioOverride = false,
        _driveAxleRatio,
        _trailerTotalUnladen,
        _trailerTotalUnladenOverride = false,
        _trailer2TotalUnladen,
        _trailer2TotalUnladenOverride = false,
        _revsMaxPullingLoaded,
        _topGearRatio,
        _revsGoverned,
        _adblueRate,
        _altitudeProfileEntriesArray = [],
        _altitudeProfileEntriesBase64 = null,
        _simResAverageAdblue,
        _simResAverageCO2Emissions,
        _simResAverageFuel,
        _simResAverageSpeed,
        _simResProductivity,
        _simResTotalAdblue,
        _simResTotalCO2Emissions,
        _simResTotalFuel,
        _simResTripDistance,
        _simResTripTime,
        _simulationCreated = false,
        _tolls = [],
        _simResTotalGearChanges,
        _simResTotalTimeInTopGear
    ;
    //#endregion Private members

    //#region Getters and setters
    function getBottomRightLatitude() {
        return _bottomRightLatitude;
    }
    function setBottomRightLatitude(newValue) {
        _bottomRightLatitude = newValue;
    }
    function getBottomRightLongitude() {
        return _bottomRightLongitude;
    }
    function setBottomRightLongitude(newValue) {
        _bottomRightLongitude = newValue;
    }
    function getTopLeftLatitude() {
        return _topLeftLatitude;
    }
    function setTopLeftLatitude(newValue) {
        _topLeftLatitude = newValue;
    }
    function getTopLeftLongitude() {
        return _topLeftLongitude;
    }
    function setTopLeftLongitude(newValue) {
        _topLeftLongitude = newValue;
    }
    function getWaypoints() {
        return _waypoints;
    }
    function setWaypoints(newValue) {
        _waypoints = newValue;
    }
    //function getSimulationResults() {
    //    return _simulationResults;
    //}
    //function setSimulationResults(newValue) {
    //    _simulationResults = newValue;
    //}
    function getTripCosts() {
        return _tripCosts;
    }
    function setTripCosts(newValue) {
        _tripCosts = newValue;
    }        
    function getTripDistanceOutbound() {
        return _tripDistanceOutbound;
    }
    function setTripDistanceOutbound(newValue) {
        _tripDistanceOutbound = newValue;
    }        
    function getTotalFuelOutbound() {
        return _totalFuelOutbound;
    }
    function setTotalFuelOutbound(newValue) {
        _totalFuelOutbound = newValue;
    }        
    function getTripTimeOutbound() {
        return _tripTimeOutbound;
    }
    function setTripTimeOutbound(newValue) {
        _tripTimeOutbound = newValue;
    }        
    function getFuelConsumptionOutbound() {
        return _fuelConsumptionOutbound;
    }
    function setFuelConsumptionOutbound(newValue) {
        _fuelConsumptionOutbound = newValue;
    }
    function getTripDistanceReturn() {
        return _tripDistanceReturn;
    }
    function setTripDistanceReturn(newValue) {
        _tripDistanceReturn = newValue;
    }
    function getTotalFuelReturn() {
        return _totalFuelReturn;
    }
    function setTotalFuelReturn(newValue) {
        _totalFuelReturn = newValue;
    }
    function getTripTimeReturn() {
        return _tripTimeReturn;
    }
    function setTripTimeReturn(newValue) {
        _tripTimeReturn = newValue;
    }
    function getFuelConsumptionReturn() {
        return _fuelConsumptionReturn;
    }
    function setFuelConsumptionReturn(newValue) {
        _fuelConsumptionReturn = newValue;
    }
    function getCoefficientOfDrag() {
        return _coefficientOfDrag;
    }
    function setCoefficientOfDrag(newValue) {
        _coefficientOfDrag = newValue;
    }
    function getSpeedMaxLoaded() {
        return _speedMaxLoaded;
    }
    function setSpeedMaxLoaded(newValue) {
        _speedMaxLoaded = newValue;
    }
    function getVehicleTotalUnladen() {
        return _vehicleTotalUnladen;
    }
    function setVehicleTotalUnladen(newValue) {
        _vehicleTotalUnladen = newValue;
    }
    function getVehicleTotalUnladenOverride() {
        return _vehicleTotalUnladenOverride;
    }
    function setVehicleTotalUnladenOverride(newValue) {
        _vehicleTotalUnladenOverride = newValue;
    }
    function getFrontalAreaOverride() {
        return _frontalAreaOverride;
    }
    function setFrontalAreaOverride(newValue) {
        _frontalAreaOverride = newValue;
    }
    function getFrontalArea() {
        return _frontalArea;
    }
    function setFrontalArea(newValue) {
        _frontalArea = newValue;
    }
    function getCoefficientOfDragOverride() {
        return _coefficientOfDragOverride;
    }
    function setCoefficientOfDragOverride(newValue) {
        _coefficientOfDragOverride = newValue;
    }
    function getDriveAxleRatio() {
        return _driveAxleRatio;
    }
    function setDriveAxleRatio(newValue) {
        _driveAxleRatio = newValue;
    }
    function getDriveAxleRatioOverride() {
        return _driveAxleRatioOverride;
    }
    function setDriveAxleRatioOverride(newValue) {
        _driveAxleRatioOverride = newValue;
    }
    function getTrailerTotalUnladen() {
        return _trailerTotalUnladen;
    }
    function setTrailerTotalUnladen(newValue) {
        _trailerTotalUnladen = newValue;
    }
    function getTrailerTotalUnladenOverride() {
        return _trailerTotalUnladenOverride;
    }
    function setTrailerTotalUnladenOverride(newValue) {
        _trailerTotalUnladenOverride = newValue;
    }
    function getTrailer2TotalUnladen() {
        return _trailer2TotalUnladen;
    }
    function setTrailer2TotalUnladen(newValue) {
        _trailer2TotalUnladen = newValue;
    }
    function getTrailer2TotalUnladenOverride() {
        return _trailer2TotalUnladenOverride;
    }
    function setTrailer2TotalUnladenOverride(newValue) {
        _trailer2TotalUnladenOverride = newValue;
    }
    function getRevsMaxPullingLoaded() {
        return _revsMaxPullingLoaded;
    }
    function setRevsMaxPullingLoaded(newValue) {
        _revsMaxPullingLoaded = newValue;
    }
    function getTopGearRatio() {
        return _topGearRatio;
    }
    function setTopGearRatio(newValue) {
        _topGearRatio = newValue;
    }
    function getRevsGoverned() {
        return _revsGoverned;
    }
    function setRevsGoverned(newValue) {
        _revsGoverned = newValue;
    }
    function getAdblueRate() {
        return _adblueRate;
    }
    function setAdblueRate(newValue) {
        _adblueRate = newValue;
    }
    function getAltitudeProfileEntriesArray() {
        return _altitudeProfileEntriesArray;
    }
    function setAltitudeProfileEntriesArray(newValue) {
        _altitudeProfileEntriesArray = newValue;
    }
    function getAltitudeProfileEntriesBase64() {
        return _altitudeProfileEntriesBase64;
    }
    function setAltitudeProfileEntriesBase64(newValue) {
        if (newValue !== null && newValue !== undefined) {
            var decompressedValue = LZString.decompressFromBase64(newValue);
            if (decompressedValue !== '') {
                var altitudeProfileArray = JSON.parse(LZString.decompressFromBase64(newValue));
                _altitudeProfileEntriesArray = altitudeProfileArray || [];
            } else {
                _altitudeProfileEntriesArray = [];
            }
        }
        _altitudeProfileEntriesBase64 = newValue;
    }
    function getSimResAverageAdblue() {
        return _simResAverageAdblue;
    }
    function setSimResAverageAdblue(newValue) {
        _simResAverageAdblue = newValue;
    }
    function getSimResAverageCO2Emissions() {
        return _simResAverageCO2Emissions;
    }
    function setSimResAverageCO2Emissions(newValue) {
        _simResAverageCO2Emissions = newValue;
    }
    function getSimResAverageFuel() {
        return _simResAverageFuel;
    }
    function setSimResAverageFuel(newValue) {
        _simResAverageFuel = newValue;
    }
    function getSimResAverageSpeed() {
        return _simResAverageSpeed;
    }
    function setSimResAverageSpeed(newValue) {
        _simResAverageSpeed = newValue;
    }
    function getSimResProductivity() {
        return _simResProductivity;
    }
    function setSimResProductivity(newValue) {
        _simResProductivity = newValue;
    }
    function getSimResTotalAdblue() {
        return _simResTotalAdblue;
    }
    function setSimResTotalAdblue(newValue) {
        _simResTotalAdblue = newValue;
    }
    function getSimResTotalCO2Emissions() {
        return _simResTotalCO2Emissions;
    }
    function setSimResTotalCO2Emissions(newValue) {
        _simResTotalCO2Emissions = newValue;
    }
    function getSimResTotalFuel() {
        return _simResTotalFuel;
    }
    function setSimResTotalFuel(newValue) {
        _simResTotalFuel = newValue;
    }
    function getSimResTripDistance() {
        return _simResTripDistance;
    }
    function setSimResTripDistance(newValue) {
        _simResTripDistance = newValue;
    }
    function getSimResTripTime() {
        return _simResTripTime;
    }
    function setSimResTripTime(newValue) {
        _simResTripTime = newValue;
    }
    function getSimulationCreated() {
        return _simulationCreated;
    }
    function setSimulationCreated(newValue) {
        _simulationCreated = newValue;
    }
    function getTolls() {
        return _tolls;
    }
    function setTolls(newValue) {
        _tolls = newValue;
    }
    function getSimResTotalTimeInTopGear() {
        return _simResTotalTimeInTopGear;
    }
    function setSimResTotalTimeInTopGear(newValue) {
        _simResTotalTimeInTopGear = newValue;
    }
    function getSimResTotalGearChanges() {
        return _simResTotalGearChanges;
    }
    function setSimResTotalGearChanges(newValue) {
        _simResTotalGearChanges = newValue;
    }
    function getFrontalAreaForSimulation() {
        var areaPrecision = globals.user.getActiveMeasurementSystem().areaPrecision;
        return globals.roundedToFixed(_frontalArea, areaPrecision);
    }
    //#endregion Getters and setters

    //#region Functions
    function clear() {
        setBottomRightLatitude(0);
        setBottomRightLongitude(0);
        setTopLeftLatitude(0);
        setTopLeftLongitude(0);

        setWaypoints([]);
        //setSimulationResults([]);
        setTripCosts([]);
        setAltitudeProfileEntriesArray([]);
        _altitudeProfileEntriesBase64 = null;
    }

    function clearSimulationResultsOnly() {
        //setSimulationResults([]);
        setTripCosts([]);
        setAltitudeProfileEntriesArray([]);
        _altitudeProfileEntriesBase64 = null;
    }

    function addWaypoints(savedWaypoints) {
        var i = 0,
            tempWaypoints = [],
            previousNetPayload = 0;
            savedWaypoints.forEach(function (waypoint) {
            var icon, obj = {};
            if (i === 0) {
                icon = '<div class="bing-maps-icon bing-maps-icon-start"></div>';
            } else if (i === 1) {
                icon = '<div class="bing-maps-icon bing-maps-icon-end"></div>';
            } else {
                icon = '<div class="bing-maps-icon bing-maps-icon-via"></div>';
            }
            //obj.icon = icon;
            //obj.description = waypoint.Description;
            //obj.latitude = waypoint.Latitude;
            //obj.longitude = waypoint.Longitude;
            //obj.payloadLoaded = waypoint.PayloadLoaded;
            //obj.payloadOffloaded = waypoint.PayloadOffloaded;
            //obj.netPayload = previousNetPayload + waypoint.PayloadLoaded - waypoint.PayloadOffloaded;
            
            var newWayPoint = new Waypoint(globals.nextId(), waypoint.Description, waypoint.Latitude, waypoint.Longitude, waypoint.PayloadLoaded,
                                        waypoint.PayloadOffloaded, previousNetPayload + waypoint.PayloadLoaded - waypoint.PayloadOffloaded, icon);
            
            tempWaypoints.push(newWayPoint);
            i += 1;
            previousNetPayload = newWayPoint.getNetPayload();
        });
        setWaypoints(tempWaypoints);
    }

    function saveWaypoints() {

        var temp = {}, waypointsArray = [], id = 0, order = 1;

        getWaypoints().forEach(function (waypoint) {
            temp = {
                //"Id": id,
                "Description": waypoint.getDescription(),
                "Latitude": waypoint.getLatitude(),
                "Longitude": waypoint.getLongitude(),
                "Order": order,
                "PayloadLoaded": waypoint.getPayloadLoaded(),
                "PayloadOffloaded": waypoint.getPayloadOffloaded(),
            }
            waypointsArray.push(temp);
            id++;
            order++;
        })

        return waypointsArray;
    }

    /**
     * Returns an array of objects to be saved to the database
     * @returns {object[]} - Array of objects
     * */
    function saveOfferTollFees() {

        var offerTollFeesArray = [];

        getTolls().forEach(function (toll) {
            offerTollFeesArray.push(toll.getTollObjectToSaved());
        });

        return offerTollFeesArray;
    }

    function getIntercomMetaData() {
        var returnObj = {};

        returnObj[config.INTERCOM_METADATA_ITEM.VEHICLE_UNLADEN] = getVehicleTotalUnladen();
        returnObj[config.INTERCOM_METADATA_ITEM.TRAILER_UNLADEN] = getTrailerTotalUnladen();
        returnObj[config.INTERCOM_METADATA_ITEM.TRAILER2_UNLADEN] = getTrailer2TotalUnladen();
        returnObj[config.INTERCOM_METADATA_ITEM.FRONTAL_AREA] = getFrontalArea();
        returnObj[config.INTERCOM_METADATA_ITEM.COEFFICIENT_OF_DRAG] = getCoefficientOfDrag();
        returnObj[config.INTERCOM_METADATA_ITEM.DRIVE_AXLE_RATIO] = getDriveAxleRatio();
        returnObj[config.INTERCOM_METADATA_ITEM.MAXIMUM_SPEED] = getSpeedMaxLoaded();
        returnObj[config.INTERCOM_METADATA_ITEM.NO_OF_WAYPOINTS] = _waypoints.length;

        return returnObj;
    }

    /**
     * Add Tolls to performance
     * @param {object[]} newTolls - Array of toll objects that are normally saved on globals.user.getTolls
     */
    function addTolls(newTolls) {
        var tempTolls = [];
        if (Array.isArray(newTolls) === true) {
            newTolls.forEach(function (newToll) {
                newToll = new Toll(newToll);
                newToll.updateActiveTollFee(newToll.getTollFees()[0].getVehicleClassId());
                tempTolls.push(newToll);
            });
        }
        setTolls(tempTolls);
    }

    /**
     * Function that uses the getTolls function on user to populate the tolls array
     * @param {object[]} tollFeesArray - Array returned from cloud services when opening an existing offer
     */
    function addTollsUsingTollFeesArray(tollFeesArray) {
        var tempTolls = [];
        // get the tolls to use from the user
        var userTolls = [];
        tollFeesArray.forEach(function (toll) {
            var tollToUse = globals.user.getTolls().find(function (userToll) { return userToll.Id === toll.TollId; });
            if (tollToUse !== undefined) {
                userTolls.push(tollToUse);
            }
        });

        userTolls.forEach(function (newToll, index) {
            newToll = new Toll(newToll);
            newToll.updateActiveTollFee(newToll.getTollFees()[0].getVehicleClassId());
            // Update Cost
            newToll.setActiveTollFee(tollFeesArray[index].TollFee);
            tempTolls.push(newToll);
        });
        
        setTolls(tempTolls);
    }
    //#endregion Functions

    function updateValues(bottomRightLatitude, bottomRightLongitude, topLeftLatitude, topLeftLongitude, coefficientOfDrag, speedMaxLoaded, revsMaxPullingLoaded, topGearRatio, revsGoverned, adblueRate) {

        setBottomRightLatitude(bottomRightLatitude);
        setBottomRightLongitude(bottomRightLongitude);
        setTopLeftLatitude(topLeftLatitude);
        setTopLeftLongitude(topLeftLongitude);
        setCoefficientOfDrag(coefficientOfDrag);
        setSpeedMaxLoaded(speedMaxLoaded);
        setRevsMaxPullingLoaded(revsMaxPullingLoaded);
        setTopGearRatio(topGearRatio);
        setRevsGoverned(revsGoverned);
        setAdblueRate(adblueRate);
    }

    //#region Initialize
    setBottomRightLatitude(bottomRightLatitude);
    setBottomRightLongitude(bottomRightLongitude);
    setTopLeftLatitude(topLeftLatitude);
    setTopLeftLongitude(topLeftLongitude);
    setCoefficientOfDrag(coefficientOfDrag);
    setSpeedMaxLoaded(speedMaxLoaded);
    setRevsMaxPullingLoaded(revsMaxPullingLoaded);
    setTopGearRatio(topGearRatio);
    setRevsGoverned(revsGoverned);
    setAdblueRate(adblueRate);
    setWaypoints([]);
    //setSimulationResults([]);
    setTripCosts([]);
    //#endregion Initialize

    //#region Publice members
    self.getBottomRightLatitude = getBottomRightLatitude;
    self.setBottomRightLatitude = setBottomRightLatitude;
    self.getBottomRightLongitude = getBottomRightLongitude;
    self.setBottomRightLongitude = setBottomRightLongitude;
    self.getTopLeftLatitude = getTopLeftLatitude;
    self.setTopLeftLatitude = setTopLeftLatitude;
    self.getTopLeftLongitude = getTopLeftLongitude;
    self.setTopLeftLongitude = setTopLeftLongitude;
    self.getWaypoints = getWaypoints;
    self.setWaypoints = setWaypoints;
    //self.getSimulationResults = getSimulationResults;
    //self.setSimulationResults = setSimulationResults;
    self.getTripCosts = getTripCosts;
    self.setTripCosts = setTripCosts;
    self.clear = clear;
    self.getTripDistanceOutbound = getTripDistanceOutbound;
    self.setTripDistanceOutbound = setTripDistanceOutbound;
    self.getTotalFuelOutbound = getTotalFuelOutbound;
    self.setTotalFuelOutbound = setTotalFuelOutbound;
    self.getTripTimeOutbound = getTripTimeOutbound;
    self.setTripTimeOutbound = setTripTimeOutbound
    self.getFuelConsumptionOutbound = getFuelConsumptionOutbound;
    self.setFuelConsumptionOutbound = setFuelConsumptionOutbound;
    self.getTripDistanceReturn = getTripDistanceReturn;
    self.setTripDistanceReturn = setTripDistanceReturn;
    self.getTotalFuelReturn = getTotalFuelReturn;
    self.setTotalFuelReturn = setTotalFuelReturn;
    self.getTripTimeReturn = getTripTimeReturn;
    self.setTripTimeReturn = setTripTimeReturn;
    self.getFuelConsumptionReturn = getFuelConsumptionReturn;
    self.setFuelConsumptionReturn = setFuelConsumptionReturn;
    self.addWaypoints = addWaypoints;
    self.saveWaypoints = saveWaypoints;
    self.saveOfferTollFees = saveOfferTollFees;
    //#endregion Public members

    //NOTE: Moving to getter/setters
    //properties
    self.bottomRightLatitude = bottomRightLatitude;
    self.bottomRightLongitude = bottomRightLongitude;
    self.topLeftLatitude = topLeftLatitude;
    self.topLeftLongitude = topLeftLongitude;

    self.waypoints = [];
    self.simulationResults = [];
    self.tripCosts = [];

    self.tripDistanceOutbound;
    self.totalFuelOutbound;
    self.tripTimeOutbound;
    self.fuelConsumptionOutbound;
    self.tripDistanceReturn;
    self.totalFuelReturn;
    self.tripTimeReturn;
    self.fuelConsumptionReturn;
    self.getCoefficientOfDrag = getCoefficientOfDrag;
    self.setCoefficientOfDrag = setCoefficientOfDrag;
    self.getSpeedMaxLoaded = getSpeedMaxLoaded;
    self.setSpeedMaxLoaded = setSpeedMaxLoaded;
    self.getVehicleTotalUnladen = getVehicleTotalUnladen;
    self.setVehicleTotalUnladen = setVehicleTotalUnladen;
    self.getVehicleTotalUnladenOverride = getVehicleTotalUnladenOverride;
    self.setVehicleTotalUnladenOverride = setVehicleTotalUnladenOverride;
    self.getFrontalArea = getFrontalArea;
    self.setFrontalArea = setFrontalArea;
    self.getFrontalAreaOverride = getFrontalAreaOverride;
    self.setFrontalAreaOverride = setFrontalAreaOverride;
    self.getCoefficientOfDragOverride = getCoefficientOfDragOverride;
    self.setCoefficientOfDragOverride = setCoefficientOfDragOverride;
    self.getDriveAxleRatio = getDriveAxleRatio;
    self.setDriveAxleRatio = setDriveAxleRatio;
    self.getDriveAxleRatioOverride = getDriveAxleRatioOverride;
    self.setDriveAxleRatioOverride = setDriveAxleRatioOverride;
    self.getTrailerTotalUnladen = getTrailerTotalUnladen;
    self.setTrailerTotalUnladen = setTrailerTotalUnladen;
    self.getTrailerTotalUnladenOverride = getTrailerTotalUnladenOverride;
    self.setTrailerTotalUnladenOverride = setTrailerTotalUnladenOverride;
    self.getTrailer2TotalUnladen = getTrailer2TotalUnladen;
    self.setTrailer2TotalUnladen = setTrailer2TotalUnladen;
    self.getTrailer2TotalUnladenOverride = getTrailer2TotalUnladenOverride;
    self.setTrailer2TotalUnladenOverride = setTrailer2TotalUnladenOverride;
    self.getRevsMaxPullingLoaded = getRevsMaxPullingLoaded;
    self.setRevsMaxPullingLoaded = setRevsMaxPullingLoaded;
    self.getTopGearRatio = getTopGearRatio;
    self.setTopGearRatio = setTopGearRatio;
    self.getRevsGoverned = getRevsGoverned;
    self.setRevsGoverned = setRevsGoverned;
    self.getAdblueRate = getAdblueRate;
    self.setAdblueRate = setAdblueRate;
    self.getAltitudeProfileEntriesArray = getAltitudeProfileEntriesArray;
    self.setAltitudeProfileEntriesArray = setAltitudeProfileEntriesArray;
    self.getAltitudeProfileEntriesBase64 = getAltitudeProfileEntriesBase64;
    self.setAltitudeProfileEntriesBase64 = setAltitudeProfileEntriesBase64;
    self.clearSimulationResultsOnly = clearSimulationResultsOnly;
    self.getSimResAverageAdblue = getSimResAverageAdblue;
    self.setSimResAverageAdblue = setSimResAverageAdblue;
    self.getSimResAverageCO2Emissions = getSimResAverageCO2Emissions;
    self.setSimResAverageCO2Emissions = setSimResAverageCO2Emissions;
    self.getSimResAverageFuel = getSimResAverageFuel;
    self.setSimResAverageFuel = setSimResAverageFuel;
    self.getSimResAverageSpeed = getSimResAverageSpeed;
    self.setSimResAverageSpeed = setSimResAverageSpeed;
    self.getSimResProductivity = getSimResProductivity;
    self.setSimResProductivity = setSimResProductivity;
    self.getSimResTotalAdblue = getSimResTotalAdblue;
    self.setSimResTotalAdblue = setSimResTotalAdblue;
    self.getSimResTotalCO2Emissions = getSimResTotalCO2Emissions;
    self.setSimResTotalCO2Emissions = setSimResTotalCO2Emissions;
    self.getSimResTotalFuel = getSimResTotalFuel;
    self.setSimResTotalFuel = setSimResTotalFuel;
    self.getSimResTripDistance = getSimResTripDistance;
    self.setSimResTripDistance = setSimResTripDistance;
    self.getSimResTripTime = getSimResTripTime;
    self.setSimResTripTime = setSimResTripTime;
    self.getIntercomMetaData = getIntercomMetaData;
    self.getSimulationCreated = getSimulationCreated;
    self.setSimulationCreated = setSimulationCreated;
    self.getTolls = getTolls;
    self.setTolls = setTolls;
    self.addTolls = addTolls;
    self.addTollsUsingTollFeesArray = addTollsUsingTollFeesArray;
    self.updateValues = updateValues;
    self.getSimResTotalTimeInTopGear = getSimResTotalTimeInTopGear;
    self.setSimResTotalTimeInTopGear = setSimResTotalTimeInTopGear;
    self.getSimResTotalGearChanges = getSimResTotalGearChanges;
    self.setSimResTotalGearChanges = setSimResTotalGearChanges;
    self.getFrontalAreaForSimulation = getFrontalAreaForSimulation;

    //self.saveWaypoints = function () {

    //    var temp = {}, waypointsArray = [], id = 0, order = 1;

    //    ko.utils.arrayForEach(self.waypoints, function (waypoint) {
    //        temp = {
    //            "Id": id,
    //            "Description": waypoint.description,
    //            "Latitude": waypoint.latitude,
    //            "Longitude": waypoint.longitude,
    //            "Order": order
    //        }
    //        waypointsArray.push(temp);
    //        id++;
    //        order++;
    //    });

    //    return waypointsArray;
    //};
};

export default Performance;

