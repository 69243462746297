<template>    
    <tr class="menu-li menu-li-value menu-tr-table">
        <td class="menu-td-table">
            <table>
                <thead v-if="table.includeHeader === true">
                    <tr>
                        <template v-for="cell in table.header.cells" :key="cell">
                            <th>
                                <MenuContentItemTableCellText :valueFromParent="cell.cellObject"></MenuContentItemTableCellText>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="row in table.rows" :key="row">
                        <tr>
                            <template v-for="cell in row.cells" :key="cell">
                                <td :class="cell.customCssForTd">
                                    <MenuContentItemTableCellInput v-if="isCellInput(cell)" :valueFromParent="cell.cellObject"></MenuContentItemTableCellInput>
                                    <MenuContentItemTableCellText v-else :valueFromParent="cell.cellObject"></MenuContentItemTableCellText>
                                </td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </td>
    </tr>

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.MENU_TABLE 
    <tr class="menu-li menu-li-value menu-tr-table" data-bind="template: { name: 'template-menu-table' }"></tr>
     /ko 
</script> -->

<!-- <script type="text/html" id="template-menu-table">
    <td class="menu-td-table">
        <table>
             ko if: includeHeader === true 
            <thead data-bind="with: header">
                <tr data-bind="foreach: { data: cells, as: 'cell' }">
                    <th data-bind="template: { name: 'template-menu-table-cell-text', data: cellObject }"></th>
                </tr>
            </thead>
             /ko 
            <tbody data-bind="foreach: { data: rows, as: 'row' }">
                <tr data-bind="foreach: { data: cells, as: 'cell' }">
                    <td data-bind="template: { name: cellType === $root.config.MENU_TABLE_CELL_TYPES.INPUT ? 'template-menu-table-cell-input' : 'template-menu-table-cell-text', data: cellObject }, css: customCssForTd"></td>
                </tr>
            </tbody>
        </table>
    </td>
</script> -->
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';

import MenuContentItemTableCellInput from './MenuContentItemTableCellInput.vue';
import MenuContentItemTableCellText from './MenuContentItemTableCellText.vue';

export default {
    props: {
        valueFromParent: Object
    },
    components: { MenuContentItemTableCellInput, MenuContentItemTableCellText },
    setup(props) {
        const table = toRef(props, "valueFromParent");

        function isCellInput(cell) {
            return cell.cellType === config.MENU_TABLE_CELL_TYPES.INPUT
        }

        return {
            table,
            isCellInput,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>