<template>
    <div class="accordion">
        <template v-for="(tab, index) in menuTabs" :key="tab">
            <template v-if="tab.displayMenuTab() === true">
                <template v-if="menuTabDisplayType === MENU_TAB_DISPLAY_TYPES.LABEL_ONLY">
                    <h3 :id="tab.hrefValue"
                        data-bind="text: label, attr: {id: hrefValue}">{{tab.label}}</h3>
                </template>
                <template v-if="menuTabDisplayType === MENU_TAB_DISPLAY_TYPES.LABEL_AND_BUTTONS">
                    <h3 :id="tab.hrefValue"
                        data-bind="attr: {id: hrefValue}">
                        <div class="fill-flex-div-row flex-space-between">
                            <div class="fill-flex-div-row waypoint-description">
                                <div class="waypoint-letter-div" 
                                    :class="getLetterAndClassForWaypoint(index).className"
                                    data-bind="css: $root.getLetterAndClassForWaypoint($index).className">
                                    <span data-bind="text: $root.getLetterAndClassForWaypoint($index).text">{{getLetterAndClassForWaypoint(index).text}}</span>
                                </div>
                                <div class="waypoint-description-label">
                                    <span :title="tab.label"
                                        data-bind="text: label , attr: { title: label }">
                                        {{tab.label}}
                                    </span>
                                </div>
                            </div>
                            <div class="accordion-buttons">
                                <div class="breadcrumb-button-i-div">
                                    <i :class="{'increment-div-inactive': index === 0}"
                                        class="fa fa-chevron-circle-up fa-lg" 
                                        @click.stop="moveUpFunction(tab, index)"
                                        data-bind="click: $parent.moveUpFunction, touchstart: $parent.moveUpFunction, css: { 'increment-div-inactive': $index() === 0 }"></i>
                                </div>
                                <div class="breadcrumb-button-i-div">
                                    <i :class="{ 'increment-div-inactive': index === menuTabs.length - 1 }"
                                        class="fa fa-chevron-circle-down fa-lg" 
                                        @click.stop="moveDownFunction(tab, index)"
                                        data-bind="click: $parent.moveDownFunction, touchstart: $parent.moveDownFunction, css: { 'increment-div-inactive': $index() === $parent.menuTabs().length - 1 }"></i>
                                </div>
                                <div class="breadcrumb-button-i-div">
                                    <i class="fa fa-trash fa-lg" 
                                        @click.stop="deleteFunction(tab)"
                                        data-bind="click: $parent.deleteFunction, $parent.deleteFunction"></i>
                                </div>
                            </div>
                        </div>
                    </h3>
                </template>
                <template v-if="menuTabDisplayType === MENU_TAB_DISPLAY_TYPES.LABEL_AND_INPUT">
                    <h3 class="menuTabInput-h3"
                        :id="tab.hrefValue"
                        data-bind="attr: {id: hrefValue}, css: { 'ui-state-disabled': menuTabInput.override() }">
                        <div class="fill-flex-div-row flex-space-between">
                            <div class="fill-flex-div-row">
                                <div>
                                    <span data-bind="text: label">{{tab.label}}</span>
                                </div>
                            </div>
                            <div class="menuTabInput-div" data-bind="with: menuTabInput">
                                <div class="flex-center menu-flex-end">
                                    <div class="override-updownbox-div"
                                        @click="inputDivClick(tab)"
                                        :class="{'override-false': tab.menuTabInput.override.value === false }">
                                        <div :class="{'override-false': tab.menuTabInput.override.value === false }">
                                            <input class="form-control login-input menu-input menu-input-number default-width-tel" 
                                                type="tel" 
                                                data-toggle="tooltip"
                                                v-model.lazy="tab.menuTabInput.value.value"
                                                :id="tab.menuTabInput.id"
                                                :readonly="tab.menuTabInput.override.value === false"
                                                :title="tab.menuTabInput.override.value === false ? tab.menuTabInput.overrideTooltipMessage : tab.menuTabInput.errorMessageObv.value"
                                                :class="{'override-cursor': addOverrideCursorClass(tab)}"
                                                data-placement="top"
                                                @focus="selectAllText(tab)"
                                                @keyup.enter="handleEnterKey(tab)" />
                                        </div>
                                        <div class="override-updownbox-checkbox"
                                            data-toggle="tooltip"
                                            @click.stop="toggleOverride(tab)"
                                            :class="{'override-false': tab.menuTabInput.override.value === false }">
                                            <i class="far fa-lg"
                                            :class="{ 'fa-check-square': tab.menuTabInput.override.value === true, 'fa-square': tab.menuTabInput.override.value === false }"
                                                data-bind="css: { 'fa-square': override() === false, 'fa-check-square': override() === true }"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </h3>
                </template>
                <template v-if="menuTabDisplayType === MENU_TAB_DISPLAY_TYPES.LABEL_AND_TOTAL">
                    <h3 class="menuTabInput-h3"
                        :id="tab.hrefValue"
                        data-bind="attr: {id: hrefValue}">
                        <div class="fill-flex-div-row flex-space-between">
                            <div class="fill-flex-div-row">
                                <div>
                                    <span data-bind="text: label">{{tab.label}}</span>
                                </div>
                            </div>
                            <div class="menuTabInput-div">
                                <div class="flex-center menu-flex-end">
                                    <span data-bind="text: totalText">{{getTotalText(tab)}}</span>
                                </div>
                            </div>
                        </div>
                    </h3>
                </template>
            </template>
            <div>
                <table class="menu-tab-group-table">                    
                    <MenuContentItem :itemsFromParent="tab.items.value"></MenuContentItem>
                </table>
            </div>
        </template>
    </div>
    
    <!-- 
    ko if: displayMenuTab() === true
    ko if: $parent.menuTabDisplayType === $root.config.MENU_TAB_DISPLAY_TYPES.LABEL_ONLY
    <h3 data-bind="text: label, attr: {id: hrefValue}"></h3>
    /ko
    ko if: $parent.menuTabDisplayType === $root.config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_BUTTONS
    https://codepen.io/tctruckscience/pen/RjMzQq
    <h3 data-bind="attr: {id: hrefValue}">
        <div class="fill-flex-div-row flex-space-between">
            <div class="fill-flex-div-row waypoint-description">
                <div class="waypoint-letter-div" data-bind="css: $root.getLetterAndClassForWaypoint($index).className">
                    <span data-bind="text: $root.getLetterAndClassForWaypoint($index).text"></span>
                </div>
                <div class="waypoint-description-label">
                    <span data-bind="text: label , attr: { title: label }"></span>
                </div>
            </div>
            <div class="accordion-buttons">
                ko if:  $parent.moveUpFunction !== undefined
                <div class="breadcrumb-button-i-div">
                    <i class="fa fa-chevron-circle-up fa-lg" data-bind="click: $parent.moveUpFunction, touchstart: $parent.moveUpFunction, css: { 'increment-div-inactive': $index() === 0 }"></i>
                </div>
                /ko
                ko if:  $parent.moveDownFunction !== undefined
                <div class="breadcrumb-button-i-div">
                    <i class="fa fa-chevron-circle-down fa-lg" data-bind="click: $parent.moveDownFunction, touchstart: $parent.moveDownFunction, css: { 'increment-div-inactive': $index() === $parent.menuTabs().length - 1 }"></i>
                </div>
                /ko
                ko if:  $parent.deleteFunction !== undefined
                <div class="breadcrumb-button-i-div">
                    <i class="fa fa-trash fa-lg" data-bind="click: $parent.deleteFunction, $parent.deleteFunction"></i>
                </div>
                /ko
            </div>
        </div>
    </h3>
    /ko
    ko if: $parent.menuTabDisplayType === $root.config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_INPUT
    https://codepen.io/tctruckscience/pen/RjMzQq
    <h3 class="menuTabInput-h3" data-bind="attr: {id: hrefValue}, css: { 'ui-state-disabled': menuTabInput.override() }">
        <div class="fill-flex-div-row flex-space-between">
            <div class="fill-flex-div-row">
                <div>
                    <span data-bind="text: label"></span>
                </div>
            </div>
            <div class="menuTabInput-div" data-bind="with: menuTabInput">
                <div class="flex-center menu-flex-end">
                    <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel" data-bind="value: value, clickBubble: false, event: { focus: selectAllText }" data-toggle="tooltip" />
                    <div class="override-updownbox-div" data-bind="css: {'override-false': override() === false }">
                        <div data-bind="css: {'override-false': override() === false }">
                            <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel" data-bind="value: formatted, attr: { id: $parent.inputId, 'data-original-title': errorMessage  }, event: { focus: $parent.selectAllText, blur: handleErrorResetIfNecessary }, disable: override() === false || $parent.permissionObv().readOnly" data-toggle="tooltip" />
                            <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel" data-bind="value: value, clickBubble: false, attr: { id: id }, event: { focus: selectAllText }, disable: override() === false" data-toggle="tooltip" />
                        </div>
                        <div class="override-updownbox-checkbox" data-bind="click: $parent.toggleOverride, touchstart: $parent.toggleOverride, clickBubble: false, css: {'override-false': override() === false }" data-toggle="tooltip">
                            <i class="far fa-square fa-lg" data-bind="css: { 'fa-square': override() === false, 'fa-check-square': override() === true }"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </h3>
    /ko
    ko if: $parent.menuTabDisplayType === $root.config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_TOTAL
    https://codepen.io/tctruckscience/pen/RjMzQq
    <h3 class="menuTabInput-h3" data-bind="attr: {id: hrefValue}">
        <div class="fill-flex-div-row flex-space-between">
            <div class="fill-flex-div-row">
                <div>
                    <span data-bind="text: label"></span>
                </div>
            </div>
            <div class="menuTabInput-div">
                <div class="flex-center menu-flex-end">
                    <span data-bind="text: totalText"></span>
                </div>
            </div>
        </div>
    </h3>
    /ko
    <div>
        <table class="menu-tab-group-table">
            <tbody data-bind="template: {name: 'template-menu-item', foreach: items, data: $data }"></tbody>
        </table>
    </div>
    /ko -->
</template>

<script>
import { toRef, onMounted, isRef, computed, nextTick } from 'vue';
import config from '@/services/config';
import globals from '@/services/globals';

const $ = window.$;

export default {
    name: 'AccordionTabs',
    props: {
        tabsFromParent: Array,
        menuTabDisplayTypeFromParent: String,
    },
    setup(props, context) {
        const menuTabs = toRef(props, "tabsFromParent");

        function moveUpFunction(tab, index) {
            if(index > 0) {
                context.emit('moveUpClick', tab);
            }
        }

        function moveDownFunction(tab, index) {
            if(index !== menuTabs.value.length - 1) {
                context.emit('moveDownClick', tab);
            }
        }

        function deleteFunction(tab) {
            context.emit('deleteClick', tab);
        }

        function getLetterAndClassForWaypoint(index) {
            var objToReturn = {};
            objToReturn.text = globals.getLetterFromInteger(index);
            if (index === 0) {
                objToReturn.className = 'start-point';
            } else if (index === menuTabs.value.length - 1) {
                objToReturn.className = 'end-point';
            } else {
                objToReturn.className = 'via-point';
            }

            return objToReturn;
        }

        function selectAllText(tab) {
            tab.menuTabInput.selectAllText();
        }

        function toggleOverride(tab) {
            tab.toggleOverride();
        }

        function getTotalText(tab) {
            if(isRef(tab.totalText)) {
                return tab.totalText.value;
            } else {
                return tab.totalText;
            }
        }

        function handleEnterKey(tab) {
            // nextTick(function(){
            //     nextTick(function(){
            //         $('#' + tab.menuTabInput.id).val(tab.menuTabInput.value.value);
            //     });
            //     // $('#' + tab.menuTabInput.id).val(tab.menuTabInput.value.value);
            //     // tab.menuTabInput.value.value = $('#' + tab.menuTabInput.id).val();
            // });
            nextTick(function(){
                $('#' + tab.menuTabInput.id).val(tab.menuTabInput.value.value);
            });
        }

        function inputDivClick(tab) {
            if (tab.menuTabInput.permissionObv.value.readOnly === false && tab.menuTabInput.permissionObv.value.override === true && tab.menuTabInput.override.value === false) { 
                tab.toggleOverride();
            }
        }

        function addOverrideCursorClass(tab) {
            return tab.menuTabInput.override.value === false;
        }
        
        return {
            menuTabs,
            moveUpFunction,
            moveDownFunction,
            deleteFunction,
            getLetterAndClassForWaypoint,
            selectAllText,
            toggleOverride,
            getTotalText,
            handleEnterKey,
            inputDivClick,
            addOverrideCursorClass,
            menuTabDisplayType: props.menuTabDisplayTypeFromParent,
            MENU_TAB_DISPLAY_TYPES: config.MENU_TAB_DISPLAY_TYPES
        };
    },
    emits: ['moveUpClick', 'moveDownClick', 'deleteClick']
}
</script>

<style lang="scss" scoped>

</style>