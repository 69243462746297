import config from '../..//services/config';



function HitArea(id, points, maxBounds, interactionBehaviour) {
    //var self = this;

    this.id = id;
    this.pointsArray = points;
    this.x = maxBounds.x !== undefined ? maxBounds.x : maxBounds.position ? maxBounds.position.x : 0;
    this.y = maxBounds.y !== undefined ? maxBounds.y : maxBounds.position ? maxBounds.position.y : 0;
    this.width = maxBounds.width;
    this.height = maxBounds.height;
    this.type = 'hitArea';
    this.dontFlipYAxis = false;

    this.interactionBehaviour = interactionBehaviour || config.INTERACTION_BEHAVIOUR.STANDARD;
    
    this.hitMasks = undefined;

    this.interaction = null;
    this.setInteraction = function (interactionObj) {
        this.interaction = interactionObj;
    }
    this.draggable = null;
    this.setDraggable = function(interactionObj) {
        this.draggable = interactionObj;
    }
    this.setDontFlipYAxis = function (flip) {
        this.dontFlipYAxis = flip;
    }
    this.disableDrag = false;
    this.setDisableDrag = function (disable) {
        this.disableDrag = disable;
    }

    this.addHitMask = function (subHitArea) {
        if (this.hitMasks === undefined) {
            this.hitMasks = [];
            //if (this.interaction) {
            //    this.interaction.hitMasks = this.hitMasks;
            //}
        }
        this.hitMasks.push(subHitArea);
        return this;
    }

}

export default HitArea;
