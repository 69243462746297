<template>
    <tr class="menu-li menu-li-value menu-li-value-radio"
        v-visible="isVisible()"
        data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-radio' }">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span v-text="radioValue.label"></span>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div">
                    <a v-if="radioValue.tooltipMessage !== undefined" 
                        data-placement="top"
                        @click="radioValue.showPopover" 
                        :id="radioValue.tooltipId" 
                        :data-content="radioValue.tooltipMessage">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a> 
                </div>
                <div class="menu-checkbox-container-div" :class="radioValue.alignmentClass">
                    <label v-for="option in radioValue.optionsArray" 
                        :key="option"
                        :title="option.tooltipText"
                        :class="['radio-button-label', option.isDisabled ? 'disabled' : '']">
                        <input class="hide" type="radio" v-model="radioValue.value.value" :value="option.value" :disabled="option.isDisabled" :name="radioValue.id" />
                        <i class="fa fa-check-circle-o"></i>
                        <span v-text="option.label"></span>
                        <span class="info-upgrade-icon-span"><i v-if="option.displayUpgradeIcon === true" class="fa fa-chevron-circle-up info-upgrade-icon fa-lg"></i></span>
                    </label>
                </div>
                <div class="increment-div decrement-div"></div>
                <div class="increment-div"></div>
            </div>
        </td>
    </tr>
    

    <!-- <script type="text/html" id="template-menu-item">
     ko if: inputType === $root.config.INPUT_TYPE.RADIO 
    <tr class="menu-li menu-li-value menu-li-value-radio" data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-radio' }"></tr>
     /ko      
</script> -->

<!-- <script type="text/html" id="template-menu-radio">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
        </div>
    </td>
    <td class="menu-value-td">
        <div class="flex-center menu-flex-end">
            <div class="increment-div info-tooltip-div">
                 ko if: tooltipMessage !== undefined 
                <a data-placement="top"
                   data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                    <i class="fa fa-info-circle fa-lg"></i>
                </a>
                 /ko 
            </div>
            <div class="menu-checkbox-container-div" data-bind="foreach: optionsArray, css: alignmentClass">
                <label class="radio-button-label" data-bind="attr: { title: tooltipText }, css: { 'disabled': isDisabled }">
                    <input class="hide" type="radio" data-bind="checked: $parent.value, attr: { value: value, disabled: isDisabled }" />
                    <i class="fa fa-check-circle-o"></i>
                    <span data-bind="text: label"></span>
                     ko if: displayUpgradeIcon === true 
                    <i class="fa fa-chevron-circle-up fa-lg info-upgrade-icon"></i>
                     /ko 
                </label>
            </div>
            <div class="increment-div decrement-div"></div>
            <div class="increment-div"></div>
        </div>
    </td>
</script> -->
</template>

<script>
import { toRef, isRef } from 'vue';

export default {
    props: {
        valueFromParent: Object
    },
    setup(props, context) {
        // const checkboxValue = ref(props.valueFromParent);
        const radioValue = toRef(props, "valueFromParent");

        function isVisible() {
            if(isRef(radioValue.value.permissionObv)) {
                return radioValue.value.permissionObv.value.visible;
            } else {
                return radioValue.value.permissionObv.visible;
            }
        }

        return {
            radioValue,
            isVisible
        }
    }
}
</script>

<style lang="scss" scoped>

</style>