<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox request-item-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title" data-bind="text: title">{{title}}</h4>
            </div>
            <div class="modal-body modal-body-text">
                <div>
                    <div id="countries-div" class="request-vehicle-input-container input-div">
                        <select id="market-input-select"
                            style="display: none;"
                            data-toggle="tooltip">
                            <option v-for="option in countryList" :key="option" :value="option.Abbreviation">
                                {{ option.Name }}</option>
                        </select>
                        <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                    </div>
                    <div id="make-input-div" class="request-vehicle-input-container input-div">
                        <div class="quick-search-div">
                            <input v-model="make"
                                :placeholder="makePlaceholder"
                                required="" 
                                type="text" 
                                data-bind="value: make, attr: { placeholder: makePlaceholder }">
                        </div>
                        <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                    </div>
                    <div id="model-input-div" class="request-vehicle-input-container input-div">
                        <div class="quick-search-div">
                            <input v-model="model"
                                :placeholder="modelPlaceholder"
                                required="" 
                                type="text" 
                                data-bind="value: model, attr: { placeholder: modelPlaceholder }">
                        </div>
                        <div class="request-i-div"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
                    </div>
                    <div class="request-vehicle-input-container textarea">
                        <textarea 
                            v-model="additionalInfo"
                            :placeholder="additionalInfoPlaceholder"
                            class="request-vehicle-textarea" 
                            data-bind="value: additionalInfo, attr: {placeholder: additionalInfoPlaceholder}">
                        </textarea>
                    </div>
                    <div 
                        v-html="additionalNotesMessage"
                        class="request-vehicle-list-container" 
                        data-bind="html: additionalNotesMessage">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="ok"
                    class="btn btn-default btn-truckscience btn-primary" 
                    type="submit" 
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('459')">{{getTranslationText('459')}}</span>
                </button>
                <button @click="close"
                    class="btn btn-default btn-truckscience" 
                    data-bind="click: close, touchstart: close">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import PopupModal from './PopupModal.vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import { ref, onMounted, onUpdated, toRef } from 'vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;

export default {
    name: 'RequestItemModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props) {
        const opts = toRef(props, "propsForModal");
        let resolvePromise,
            requestItemType = '',
            allowErrorRemoval = true,
            callback = null;
        const popup = ref(null);
        const title = ref(opts.value.requestItemModalObject.requestItemTitle);
        const isEnterpriseUser = globals.user.getEdition() === config.APP_EDITION.ENTERPRISE;
        const market = ref('');
        const model = ref('');
        const modelPlaceholder = ref(config.getTranslationText('2593'));
        const make = ref('');
        const makePlaceholder = ref(config.getTranslationText('234'));
        const additionalInfo = ref('');
        const additionalInfoPlaceholder = ref(config.getTranslationText('717'));
        const additionalNotesMessage = isEnterpriseUser === true ? ref(config.getTranslationText('4675')) : ref(config.getTranslationText('1189'));
        const countryList = ref([]);
        

        callback = opts.value.callback;

        var userCountryCode = globals.user.getCountryAbbreviation(),
            coreMarketCountries = globals.getCoreMarketsFromAvailableCountriesList();
        var isCoreMarketCountry = coreMarketCountries.find(function (country) {
            return country.Abbreviation === userCountryCode;
        });

        if(isEnterpriseUser === true) {
            market.value = globals.user.getCountryAbbreviation();
        } else {
            if (isCoreMarketCountry !== undefined) {
                market.value = userCountryCode;
            } else {
                market.value = '';
            }
        }
        countryList.value = getCountryListForDropDown(market.value);

        onMounted(() => {
            TScMessenger.writeDebugMessage('')
        })

        onUpdated(() => {
            TScMessenger.writeDebugMessage('')
        })

        function show(opts = {}) {
            // Once we set our config, we tell the popup modal to open
            this.popup.open()

            setTimeout(function(){
                $('#market-input-select').val(market.value);
                $('#market-input-select').selectmenu({
                    width: 433,
                    open: function () {
                        var container = $('#market-input-select-menu'),
                        scrollTo = $('.ui-menu-item.ui-state-focus');
                        if (container.scrollTop() === 0) {
                            var scrollToPosition = parseInt(scrollTo.offset().top - container.offset().top + scrollTo.scrollTop() - container.height() / 2) + 'px';
                            container.animate({
                                scrollTop: scrollToPosition
                            }, 1);
                        }
                    },
                    change: function (event, data) {
                        market.value = data.item.value;
                        if (market.value === '') {
                            $('#market-input-select-button').addClass('no-market-selected')
                        } else {
                            $('#market-input-select-button').removeClass('no-market-selected')
                        }
                    }
                });
                if (market.value === '') {
                    $('#market-input-select-button').addClass('no-market-selected')
                }
            }, 0)

            $('#market-input-select').val(market.value);
            $('#market-input-select').selectmenu({
                width: 433,
                open: function () {
                    var container = $('#market-input-select-menu'),
                    scrollTo = $('.ui-menu-item.ui-state-focus');
                    if (container.scrollTop() === 0) {
                        var scrollToPosition = parseInt(scrollTo.offset().top - container.offset().top + scrollTo.scrollTop() - container.height() / 2) + 'px';
                        container.animate({
                            scrollTop: scrollToPosition
                        }, 1);
                    }
                },
                change: function (event, data) {
                    market.value = data.item.value;
                    if (market.value === '') {
                        $('#market-input-select-button').addClass('no-market-selected')
                    } else {
                        $('#market-input-select-button').removeClass('no-market-selected')
                    }
                }
            });
            if (market.value === '') {
                $('#market-input-select-button').addClass('no-market-selected')
            }

            setTimeout(function () {
                var newWidth = $('#make-input-div .quick-search-div').width() + 2,
                    containerWidth = newWidth + 17;
                if (newWidth !== 433) {
                    $('.request-item-modal #countries-div').width(containerWidth);
                    $('#market-input-select').selectmenu({
                        width: newWidth
                    });
                }
            }, 10);

            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function getCountryListForDropDown(selectedValue) {
            var returnArray = [];

            if(isEnterpriseUser === true) {
                globals.getAvailableCountriesList().forEach(function (country) {
                    returnArray.push(country);
                });
            } else {
                returnArray.push({
                    Name: config.getTranslationText('4428'),
                    Abbreviation: '',
                    disable: true
                });
                
                globals.getCoreMarketsFromAvailableCountriesList().forEach(function (country) {
                    returnArray.push(country);
                });
            }

            return returnArray;
        }

        function close() {
            this.popup.close();
            resolvePromise();
        }

        function getAllowErrorRemoval() {
            return allowErrorRemoval;
        }

        function ok() {
            var self = this, returnObject = {};

            removeErrorText('countries', getAllowErrorRemoval());
            removeErrorText('make-input', getAllowErrorRemoval());
            removeErrorText('model-input', getAllowErrorRemoval());

            if (market.value === "") {
                $('#countries-div').addClass('error');
            }

            if (model.value === "") {
                $('#model-input-div').addClass('error');
                $('#model-input').tooltip({ trigger: 'manual' }).tooltip('show');
            }

            if (make.value === "") {
                $('#make-input-div').addClass('error');
                $('#make-input').tooltip({ trigger: 'manual' }).tooltip('show');
            }

            if (market.value !== "" && make.value !== "" && model.value !== "") {
                globals.showSpinner();
                $.when(dataManager.postDataRequest(make.value, model.value, additionalInfo.value, requestItemType, market.value, isEnterpriseUser)).done(function (result) {
                    globals.hideSpinner()
                    if (result.ReturnCode > 0) {
                        var metadataObject = {
                            Make: make.value,
                            Model: model.value,
                            Market: market.value
                            //Type: self.requestItemType
                        };
                        if (self.intercomEventType === config.INTERCOM_EVENT.REQUESTED_EQUIPMENT) {
                            metadataObject.Type = self.requestItemType;
                        }
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(self.intercomEventType, metadataObject));
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1581', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1581')));
                        self.close();
                    } else {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                        self.close();
                    }
                })
                .fail(function (result) {
                    globals.hideSpinner();                    
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                    self.close();
                });
            }
        }

        function removeErrorText(id, allowErrorRemoval) {
            if ($('#' + id + '-div').hasClass('error') && allowErrorRemoval) {
                $('#' + id + '-div').removeClass('error');
                $('#' + id).tooltip('destroy').removeClass('error');
            }
        }

        return {
            show,
            popup,
            // Values
            title,
            market,
            model,
            make,
            additionalInfo,
            countryList,
            isEnterpriseUser,
            // Functions
            ok,
            close,
            // Text
            additionalInfoPlaceholder,
            modelPlaceholder,
            makePlaceholder,
            additionalNotesMessage,
            getTranslationText: config.getTranslationText
        }
    },
}
</script>

<style lang="scss" scoped>

</style>