<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox nearly-there-modal-container">
            <div class="get-started-form light" >
                <sign-up-component  :config="config" 
                            v-model:signUpFirstName="signUpFirstName"
                            v-model:signUpLastName="signUpLastName"
                            v-model:signUpEmail="signUpEmail"
                            :showCountriesDropdown="showCountriesDropdown"
                            :countryList="countryList"
                            :signUpButtonText="signUpButtonText"
                            :showSocialSignUpSection="showSocialSignUpSection"
                            :isInternetExplorer="isInternetExplorer()"
                            :microsoftImageUrl="microsoftImageUrl"
                            :showSalesBlurb="showSalesBlurb"
                            :signUpPasswordTooltipMessage="signUpPasswordTooltipMessage"
                            v-model:signUpPassword="signUpPassword"
                            @submit-sign-up="submitSignUp" />
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import logger from '@/services/logger';
import LoginSignUpObject from '@/services/accountAccess/loginSignUpObject';
import CustomMessageBox from '@/services/CustomMessageBox';
import TermsAndConditionsModal from '@/components/modals/TermsAndConditionsModal.vue';
import SignUpComponent from '@/components/shell/SignUpComponent.vue';
import PopupModal from '@/components/modals/PopupModal.vue';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';

import { toRef, ref, nextTick, onBeforeMount, onMounted } from 'vue';

export default {
    
    name: 'NearlyThereModal',
    components: { PopupModal, SignUpComponent },
    props: {
        propsForModal: Object
    },
    setup(props) {

        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);

        let resolvePromise = undefined,
        rejectPromise = undefined;
        
        let loginSignUpObject = new LoginSignUpObject(logger, dataManager, config, globals, opts.value.shell, ForgotPasswordModal, TermsAndConditionsModal, CustomMessageBox, config.GET_STARTED_MODES.SIGN_UP);
        let getTranslationText = config.getTranslationText;
        
        onBeforeMount(function () {
            let params = undefined;
             
            loginSignUpObject.onBeforeMount(params);
        });

        onMounted(function (){
            activate();

            popup.value.open();
            nextTick(compositionComplete);
        });



        function closeDialog() {
            close();
        }

        //#region Durandal Methods
        function activate(data) {
            loginSignUpObject.setActionButtonCallback(closeDialog);
            // return loginSignUpObject.onMounted(data);
        }
        function compositionComplete() {
            loginSignUpObject.setSignUpEmail(globals.user.getEmailAddress());
            loginSignUpObject.setEmailFieldReadOnly(true);

            return loginSignUpObject.onMounted();
        }
        function canActivate(){
            return loginSignUpObject.canActivate();
        }
        function deactivate() {
            return loginSignUpObject.deactivate();
        }

        function close() {
            popup.value.close();
            resolvePromise();
        }

        function show() {

            return new Promise((resolve, reject) => {
                resolvePromise = resolve;
                rejectPromise = reject;
            })
        }

        //#endregion
        return {
            config: config,
            submitLogin: loginSignUpObject.submitLogin,
            submitSignUp: loginSignUpObject.submitSignUp,
            loginEmail: loginSignUpObject.loginEmail,
            loginPassword: loginSignUpObject.loginPassword,
            loginButtonText: loginSignUpObject.loginButtonText,
            signUpFirstName: loginSignUpObject.signUpFirstName,
            signUpLastName: loginSignUpObject.signUpLastName,
            signUpEmail: loginSignUpObject.signUpEmail,
            signUpPassword: loginSignUpObject.signUpPassword,
            signUpCountry: loginSignUpObject.signUpCountry,
            signUpButtonText: loginSignUpObject.signUpButtonText,
            openForgotPasswordModal: loginSignUpObject.openForgotPasswordModal,
            countryList: loginSignUpObject.countryList,
            showTermsAndConditions: loginSignUpObject.showTermsAndConditions,
            showPrivacyPolicy: loginSignUpObject.showPrivacyPolicy,
            currentBrowser: loginSignUpObject.currentBrowser,
            signUpPasswordTooltipMessage: loginSignUpObject.signUpPasswordTooltipMessage,
            getStartedDisplayMode: loginSignUpObject.getStartedDisplayMode,
            toggleGetStartedDisplayMode: loginSignUpObject.toggleGetStartedDisplayMode,
            moduleTitle: config.MODULE_TITLE.GET_STARTED,
            renderButton: loginSignUpObject.renderButton,
            isInternetExplorer: globals.isInternetExplorer,
            microsoftImageUrl: loginSignUpObject.microsoftImageUrl,
            showSalesBlurb: loginSignUpObject.showSalesBlurb,
            showSocialSignUpSection: loginSignUpObject.showSocialSignUpSection,
            showCountriesDropdown: loginSignUpObject.showCountriesDropdown,
            emailFieldReadOnly: loginSignUpObject.emailFieldReadOnly,
            getTranslationText,
            show,
            popup
        }
        

        
    },
}
</script>