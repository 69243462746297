<template>
    <tbody>
        <!-- <template v-for="item in activeMenuStep.menuStepItems" :key="item"> -->
        <template v-for="item in items" :key="item">
            <MenuContentItemDivider v-if="item.type === ITEM_TYPE.DIVIDER" :valueFromParent="item"></MenuContentItemDivider>
            <MenuContentItemTabGroup v-if="item.type === ITEM_TYPE.MENUTABGROUP" :valueFromParent="item"></MenuContentItemTabGroup>
            <MenuContentItemSelectionGroup
                v-if="item.type === ITEM_TYPE.SELECTIONGROUP && (item.selectedMenuItems.value.length > 0 || item.displayButton === true)"
                :valueFromParent="item" :activeMenuCardStepFromParent="item.activeMenuCardStep">
            </MenuContentItemSelectionGroup>
            <MenuContentItemSelectionWizard v-if="item.type === ITEM_TYPE.SELECTIONWIZARD" :valueFromParent="item" :renderAsFromParent="item.renderAs">
            </MenuContentItemSelectionWizard>
            <MenuContentItemTable v-if="item.type === ITEM_TYPE.MENU_TABLE" :valueFromParent="item"></MenuContentItemTable>
            <template v-if="item.type === ITEM_TYPE.VALUE">
                <MenuContentItemValueText v-if="item.inputType === INPUT_TYPE.TEXT" :valueFromParent="item">
                </MenuContentItemValueText>
                <MenuContentItemValueTextWithSave v-if="item.inputType === INPUT_TYPE.TEXT_WITH_SAVE"
                    :valueFromParent="item">
                </MenuContentItemValueTextWithSave>
                <MenuContentItemValueTel v-if="item.inputType === INPUT_TYPE.TEL" :valueFromParent="item">
                </MenuContentItemValueTel>
                <MenuContentItemValueCheckbox v-if="item.inputType === INPUT_TYPE.CHECKBOX" :valueFromParent="item">
                </MenuContentItemValueCheckbox>
                <MenuContentItemValueRadio v-if="item.inputType === INPUT_TYPE.RADIO" :valueFromParent="item">
                </MenuContentItemValueRadio>
                <MenuContentItemValueUpDownBox v-if="item.inputType === INPUT_TYPE.UPDOWNBOX" :valueFromParent="item">
                </MenuContentItemValueUpDownBox>
                <MenuContentItemValueSelect v-if="item.inputType === INPUT_TYPE.SELECT" :valueFromParent="item">
                </MenuContentItemValueSelect>
                <MenuContentItemValueCombobox v-if="item.inputType === INPUT_TYPE.COMBOBOX" :valueFromParent="item">
                </MenuContentItemValueCombobox>
            </template>
            <template v-if="item.type === ITEM_TYPE.INPUT_GROUP">
                <MenuContentItemInputGroupUpDownBoxAndSelectMenu v-if="item.inputGroupType === INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU" :inputGroupFromParent="item"></MenuContentItemInputGroupUpDownBoxAndSelectMenu>
            </template>
        </template>
    </tbody>

    <!-- <div>
        'template-menu-item'
    </div> -->

    <!-- <tbody>
        <template v-for="item in menuStepItems" :key="item">
            <MenuContentItemDivider v-if="item.type === ITEM_TYPE.DIVIDER"></MenuContentItemDivider>
            <MenuContentItemLink v-if="item.type === 'item' && !item.equipmentItem"></MenuContentItemLink>
            <MenuContentItemAccessoryGroup v-if="item.type === ITEM_TYPE.ACCESSORYGROUP">
            </MenuContentItemAccessoryGroup>
            <MenuContentItemSelectionGroup
                v-if="item.type === ITEM_TYPE.SELECTIONGROUP && (item.selectedMenuItems.length > 0 || displayButton === true)">
            </MenuContentItemSelectionGroup>
            <MenuContentItemTabGroup v-if="item.type === ITEM_TYPE.MENUTABGROUP"></MenuContentItemTabGroup>
            <MenuContentItemSelectionWizard v-if="item.type === ITEM_TYPE.SELECTIONWIZARD">
            </MenuContentItemSelectionWizard>
            <MenuContentItemTable v-if="item.type === ITEM_TYPE.MENU_TABLE"></MenuContentItemTable>
            <template v-if="item.type === ITEM_TYPE.VALUE">
                <MenuContentItemValueText v-if="item.inputType === INPUT_TYPE.TEXT"></MenuContentItemValueText>
                <MenuContentItemValueTextWithSave v-if="item.inputType === INPUT_TYPE.TEXT_WITH_SAVE"></MenuContentItemValueTextWithSave>
                <MenuContentItemValueTel v-if="item.inputType === INPUT_TYPE.TEL"></MenuContentItemValueTel>
                <MenuContentItemValueCheckbox v-if="item.inputType === INPUT_TYPE.CHECKBOX"></MenuContentItemValueCheckbox>
                <MenuContentItemValueRadio v-if="item.inputType === INPUT_TYPE.RADIO"></MenuContentItemValueRadio>
                <MenuContentItemValueUpDownBox v-if="item.inputType === INPUT_TYPE.UPDOWNBOX"></MenuContentItemValueUpDownBox>
                <MenuContentItemValueSelect v-if="item.inputType === INPUT_TYPE.SELECT"></MenuContentItemValueSelect>
                <MenuContentItemValueCombobox v-if="item.inputType === INPUT_TYPE.COMBOBOX"></MenuContentItemValueCombobox>
            </template>
            <template v-if="item.type === ITEM_TYPE.INPUT_GROUP">
                <MenuContentItemInputGroupUpDownBoxAndSelectMenu v-if="item.inputType === INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU"></MenuContentItemInputGroupUpDownBoxAndSelectMenu>
            </template>
        </template>
    </tbody> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.DIVIDER 
    <tr class="menu-divider" data-bind="template: {name:'template-menu-divider'}"></tr>
     /ko 
     ko if: type === 'item' && !equipmentItem 
    <tr class="menu-li menu-li-item" data-bind="click: click, touchstart: click, template: {name:'template-menu-item-link'}"></tr>
     /ko 
     ko if: type === $root.config.ITEM_TYPE.ACCESSORYGROUP 
    <tr class="menu-li menu-li-item" data-bind="template: {name:'template-menu-accessory-group'}"></tr>
     /ko 
     ko if: type === $root.config.ITEM_TYPE.SELECTIONGROUP 
     ko if: selectedMenuItems().length > 0 || displayButton === true 
    <tr class="menu-li menu-li-item menu-tr-selection-group" data-bind="visible: permissionObv().visible, template: {name:'template-menu-selection-group' }"></tr>
     /ko 
     /ko 
     ko if: type === $root.config.ITEM_TYPE.MENUTABGROUP 
    <tr class="menu-li menu-li-value menu-tr-tab-group" data-bind="visible: permissionObv().visible, template: { name: 'template-menu-tab-group' }, css: customCss"></tr>
     /ko 
     ko if: type === $root.config.ITEM_TYPE.SELECTIONWIZARD 
    <tr class="menu-li menu-li-value menu-tr-selection-wizard" data-bind="template: { name: 'template-menu-selection-wizard' }"></tr>
    <tr class="menu-li menu-li-value" data-bind="visible: permissionObv().visible, template: { name: 'template-menu-selection-wizard' }"></tr>
     /ko 
     ko if: type === $root.config.ITEM_TYPE.MENU_TABLE 
    <tr class="menu-li menu-li-value menu-tr-table" data-bind="template: { name: 'template-menu-table' }"></tr>
     /ko 
     ko if: type === $root.config.ITEM_TYPE.VALUE 
     ko if: inputType === $root.config.INPUT_TYPE.TEXT 
    <tr class="menu-li menu-li-value" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-text' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.TEXT_WITH_SAVE 
    <tr class="menu-li menu-li-value menu-li-text-with-save" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-text-with-save' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.TEL 
    <tr class="menu-li menu-li-value" data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-tel' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.CHECKBOX 
    <tr class="menu-li menu-li-value menu-li-value-checkbox" data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-checkbox' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.RADIO 
    <tr class="menu-li menu-li-value menu-li-value-radio" data-bind="visible:  permissionObv().visible, template: { name: 'template-menu-radio' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.UPDOWNBOX 
    <tr class="menu-li menu-li-value" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-updownbox' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.SELECT 
    <tr class="menu-li menu-li-value menu-li-value-select" data-bind="visible: permissionObv().visible, css: customCss, template: { name: 'template-menu-select' }"></tr>
     /ko 
     ko if: inputType === $root.config.INPUT_TYPE.COMBOBOX 
    <tr class="menu-li menu-li-value" data-bind="visible: permissionObv().visible, template: { name: 'template-menu-combobox' }"></tr>
     /ko 
     /ko 
     ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP 
     ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU 
    <tr class="menu-li menu-li-value menu-li-menuInputGroup-upDownBoxAndSelect" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menuInputGroup-upDownBoxAndSelect' }"></tr>
     /ko 
     /ko 
</script> -->
</template>

<script>
import { onMounted, onUpdated, toRef } from 'vue';
import config from '../../services/config';
import MenuContentItemDivider from './MenuContentItemDivider.vue';
// import MenuContentItemLink from './MenuContentItemLink.vue';
// import MenuContentItemAccessoryGroup from './MenuContentItemAccessoryGroup.vue';
import MenuContentItemSelectionGroup from './MenuContentItemSelectionGroup.vue';
import MenuContentItemTabGroup from './MenuContentItemTabGroup.vue';
import MenuContentItemSelectionWizard from './MenuContentItemSelectionWizard.vue';
import MenuContentItemTable from './MenuContentItemTable.vue';
import MenuContentItemValueText from './MenuContentItemValueText.vue';
import MenuContentItemValueTextWithSave from './MenuContentItemValueTextWithSave.vue';
import MenuContentItemValueTel from './MenuContentItemValueTel.vue';
import MenuContentItemValueCheckbox from './MenuContentItemValueCheckbox.vue';
import MenuContentItemValueRadio from './MenuContentItemValueRadio.vue';
import MenuContentItemValueUpDownBox from './MenuContentItemValueUpDownBox.vue';
import MenuContentItemValueSelect from './MenuContentItemValueSelect.vue';
import MenuContentItemValueCombobox from './MenuContentItemValueCombobox.vue';
import MenuContentItemInputGroupUpDownBoxAndSelectMenu from './MenuContentItemInputGroupUpDownBoxAndSelectMenu.vue';

const TScMessenger = window.TScMessenger;

export default {
    name: 'MenuContentItem',
    props: {
        // activeMenuStepFromParent: Object
        itemsFromParent: Array
    },
    setup(props) {
        // const items = ref(props.itemsFromParent);
        const items = toRef(props, "itemsFromParent");

        // watch(() => props.itemsFromParent,
        //     (newValue, oldValue) => {
        //         items.value = newValue;
        //     });

        onMounted(() => {
            TScMessenger.writeDebugMessage('in MenuContentItem onMounted')
        })

        onUpdated(() => {
            TScMessenger.writeDebugMessage('in MenuContentItem onUpdated')
        })

        return {
            items,
            ITEM_TYPE: config.ITEM_TYPE,
            INPUT_TYPE: config.INPUT_TYPE,
            INPUT_GROUP_TYPES: config.INPUT_GROUP_TYPES
        }
    },
    components: { 
        MenuContentItemValueText,
        MenuContentItemValueTextWithSave,
        MenuContentItemValueTel,
        MenuContentItemValueCheckbox,
        MenuContentItemValueRadio,
        MenuContentItemValueUpDownBox,
        MenuContentItemValueSelect,
        MenuContentItemValueCombobox,
        MenuContentItemDivider,
        MenuContentItemTabGroup,
        MenuContentItemSelectionWizard,
        MenuContentItemSelectionGroup,
        MenuContentItemInputGroupUpDownBoxAndSelectMenu,
        MenuContentItemTable
    }
}
</script>

<style lang="scss" scoped>
</style>