<template>
    <div class="menu-equipment-text-div">
        <p class="menu-equipment-card-name" data-bind="text: getHeader()">{{menuCard.getHeader.value}}</p>
        <p class="menu-equipment-card-manufacturer" data-bind="text: getDetail()">{{menuCard.getDetail.value}}</p>
    </div>
    <div class="menu-equipment-icons-div">
        <div v-if="menuCard.doNotDelete.value !== true" 
            @click.stop="deleteSelectionGroupItem"
            :title="menuCard.getRemoveTooltip()"
            class="menu-equipment-delete" 
            data-bind="click: $parent.deleteSelectionGroupItem, touchstart: $parent.deleteSelectionGroupItem, clickBubble: false, attr: { title: getRemoveTooltip() }">
            <i class="fa fa-lg fa-cut"></i>
        </div>
        <div v-if="menuCard.allowSave === true && menuCard.displaySaveCardIcon() === true" 
            class="menu-equipment-delete" 
            @click.stop="menuCard.saveCardClick"
            :title="menuCard.getSaveTooltip()"
            data-bind="click: saveCardClick, touchstart: saveCardClick, clickBubble: false, attr: { title: getSaveTooltip() }">
            <i class="fa fa-lg fa-floppy-o"></i>
        </div>
        <div v-if="menuCard.allowCopy === true" 
            class="menu-equipment-delete" 
            @click.stop="copySelectionGroupItem"
            :title="menuCard.getCopyTooltip()"
            data-bind="click: $parent.copySelectionGroupItem, touchstart: $parent.copySelectionGroupItem, clickBubble: false, attr: { title: getCopyTooltip() }">
            <i class="far fa-clone"></i>
        </div>
        <div v-if="menuCard.allowPin === true" 
            class="menu-equipment-delete menu-card-pin-icon" 
            @click.stop="pinSelectionGroupItem"
            :title="menuCard.getPinTooltip()"
            data-bind="click: function() { pinIconClicked($parent); }, touchstart: function (){pinIconClicked($parent); }, clickBubble: false, attr: { title: getPinTooltip() }">
            <i v-if="menuCard.isPinned.value === true" class="fas fa-thumbtack"></i>
            <i v-else class="far fa-thumbtack"></i>
        </div>
    </div>
</template>

<script>
import { toRef } from 'vue';

export default {
    name: 'MenuCard',
    props: {
        menuCardFromParent: Object
    },
    emits:['copySelectionGroupItemClicked', 'deleteSelectionGroupItemClicked', 'pinSelectionGroupItemClicked', 'activateMenuCardOnMount'],
    setup (props, context) {
        const menuCard = toRef(props, "menuCardFromParent");

        function copySelectionGroupItem() {
            context.emit('copySelectionGroupItemClicked', menuCard.value);
        }

        function deleteSelectionGroupItem() {
            context.emit('deleteSelectionGroupItemClicked', menuCard.value);
        }

        function pinSelectionGroupItem() {
            context.emit('pinSelectionGroupItemClicked', menuCard.value);
        }

        return {
            menuCard,
            copySelectionGroupItem,
            deleteSelectionGroupItem,
            pinSelectionGroupItem
        }
    }
}
</script>

<style lang="scss" scoped>

</style>