

var Size = function (length, height, width) {

    var self = this;
    //constructor
    self.length = length || 0;
    self.height = height || 0;
    self.width = width || 0;
    self.lengthOverride = false;
    self.heightOverride = false;
    self.widthOverride = false;
    

    self.getLength = function () {
        return self.length;
    }
    self.setLength = function (newValue) {
        self.length = newValue;
    }
    self.getLengthOverride = function () {
        return self.lengthOverride;
    }
    self.setLengthOverride = function (newValue) {
        self.lengthOverride = newValue;
    }
    self.getHeight = function () {
        return self.height;
    }
    self.setHeight = function (newValue) {
        self.height = newValue;
    }
    self.getHeightOverride = function () {
        return self.heightOverride;
    }
    self.setHeightOverride = function (newValue) {
        self.heightOverride = newValue;
    }
    self.getWidth = function () {
        return self.width;
    }
    self.setWidth = function (newValue) {
        self.width = newValue;
    }
    self.getWidthOverride = function () {
        return self.widthOverride;
    }
    self.setWidthOverride = function (newValue) {
        self.widthOverride = newValue;
    }


};

    export default Size;

