import { watch, shallowRef, triggerRef, computed, ref, isRef, nextTick } from "vue";
import config from "../config";
import globals from "../globals";
import utils from "../utils";
import offerManager from "../offerManager";
import WizardHelper from "../wizard/wizardHelper";
import bus from '@/main';
// import $ from "jquery";

const $ = window.$;
const WebuiPopovers = window.WebuiPopovers;

// define(['services/config', 'services/globals', 'services/offerManager', 'services/wizard/wizardHelper', 'viewmodels/modals/customMessageBox', 'durandal/app', 'services/utils'],
// function (config, globals, offerManager, WizardHelper, CustomMessageBox, app, utils) {
const TScMessenger = window.TScMessenger;
const CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;
var menuIdCounter = 0;

var setupComplete,
  doingDelete = false,
  test = false;

var notifyMenuActivityListenersRef;
var menuActivityListeners = [];

/**
 * Menu object to be used on configuration and performance
 * @param {string} partialMenuId - id of html element that the menu will use
 * @return {Object} Menu object
 */
var Menu = function (partialMenuId) {
  var self = this;

  var breadcrumbButtons = shallowRef([]);
  var menuStructure = shallowRef([]);
  var newMenuStructure;
  var activeMenuStep = shallowRef();
  var offerStructure = shallowRef();
  var displayBreadcrumbButtonsContainer = true;
  var displayBreadcrumbButtons = shallowRef(false);
  var breadcrumbButtonsDisplayType = shallowRef("");
  var partialId = partialMenuId;
  var activateThisStepTest = null;
  var closeMenuCallback,
    menuCloseOptions,
    backButtonCallback,
    popInMenuCallback,
    preventPopMenu = false;
  var displayBackButton = shallowRef(false);
  var menuDocked = shallowRef(true);
  var popMenuTitle = shallowRef(config.getTranslationText("965"));
  var menuTitle = shallowRef();
  let positionMenuCallback, popMenuPositionCallback;

  notifyMenuActivityListenersRef = notifyMenuActivityListeners;
  watch(
    () => activeMenuStep,
    (newValue, oldValue) => {
      if (newValue.displayBreadcrumbsButtonOnStep === true) {
        breadcrumbButtons.value = newValue.breadcrumbsButtonsOnStep;
        breadcrumbButtonsDisplayType.value =
          newValue.breadcrumbButtonsDisplayType;
        displayBreadcrumbButtons.value = true;
      } else {
        breadcrumbButtons.value = [];
        displayBreadcrumbButtons.value = false;
        breadcrumbButtonsDisplayType.value = "";
      }
    }
  );

  var menu = {
    menuStructure: menuStructure,
    activeMenuStep: activeMenuStep,
    createMenu: createMenu,
    offerStructure: offerStructure,
    clearOfferStructure: clearOfferStructure,
    displayBreadcrumbButtons: displayBreadcrumbButtons,
    breadcrumbButtonsDisplayType: breadcrumbButtonsDisplayType,
    breadcrumbButtons: breadcrumbButtons,
    updateJsElements: updateJsElements,
    updateTitle: updateTitle,
    recreateSelectMenuOnResize: recreateSelectMenuOnResize,
    openMenuStepUsingPath: openMenuStepUsingPath,
    openMenuStepUsingPathArray: openMenuStepUsingPathArray,
    cleanUp: cleanUp,
    addSliderToStep: addSliderToStep,
    menuId: partialId,
    registerMenuActivityCallbackListener: registerMenuActivityCallbackListener,
    closeMenu: closeMenu,
    setMenuCloseCallback: setMenuCloseCallback,
    setMenuCloseOptions: setMenuCloseOptions,
    backButtonClick: backButtonClick,
    displayBackButton: displayBackButton,
    setBackButtonCallback: setBackButtonCallback,
    menuDocked: menuDocked,
    popMenuTitle: popMenuTitle,
    popMenu: popMenu,
    setPopMenuPositionCallback: setPopMenuPositionCallback,
    menuTitle: menuTitle,
    activateMenuStepSubscriptionRef: self.activateMenuStepSubscriptionRef,
    refreshMenu: refreshMenu,
    getDisplayBreadcrumbButtonsContainer: getDisplayBreadcrumbButtonsContainer,
    hideBreadcrumbButtonsContainer: hideBreadcrumbButtonsContainer,
    getMenuItemUsingPath: getMenuItemUsingPath,
    setPopInMenuCallback: setPopInMenuCallback,
    getPreventPopMenu: getPreventPopMenu,
    setPreventPopMenu: setPreventPopMenu,
    positionMenuInCentreOfScreen: positionMenuInCentreOfScreen,
    getPartialMenuId: getPartialMenuId,
    updateDisplayBackButton: updateDisplayBackButton
  };

  function clearOfferStructure() {
    offerStructure("");
  }

  function refreshMenu() {
    // offerManager.getUndoHandler().disableUndoOpCreation();
    traverseMenuStepMenuTree(getActiveMenuStepObservable, "REFRESH_PERMISSION");
    // nextTick(function () {
    //   offerManager.getUndoHandler().enableUndoOpCreation();
    // });
  }

  function createMenu(menuStructure) {
    setupComplete = false;
    var homeStep = new MenuStep(
      menuStructure,
      undefined,
      undefined,
      partialMenuId,
      true
    );
    //activeMenuStep.value = homeStep;
    setActiveMenuStep(homeStep);
    activeMenuStep.value.setUpMenuStep();
    updateBreadcrumbButtons();
    setupComplete = true;
    //setTimeout(activeMenuStep().tempAddSliderToStep(), 500);
  }

  function setActiveMenuStep(newValue) {
    activeMenuStep.value = newValue;
  }

  function updateJsElements(usePartialId, pathArray) {
    var id = usePartialId === true ? partialId : undefined;
    updateJsElementsUsingStep(
      activeMenuStep.value,
      partialId,
      pathArray,
      false
    );
  }

  function updateBreadcrumbButtons() {
    if (activeMenuStep.value.displayBreadcrumbsButtonOnStep === true) {
      breadcrumbButtons.value = activeMenuStep.value.breadcrumbsButtonsOnStep;
      breadcrumbButtonsDisplayType.value =
      activeMenuStep.value.breadcrumbButtonsDisplayType;
      displayBreadcrumbButtons.value = true;
    } else {
      breadcrumbButtons.value = [];
      displayBreadcrumbButtons.value = false;
      breadcrumbButtonsDisplayType.value = "";
    }
  }

  function updateTitle(newTitle) {
    //activeMenuStep().stepLabel(newTitle);
    menu.menuTitle.value = newTitle;
  }

  function recreateSelectMenuOnResize() {
    var itemsArray = [];
    activeMenuStep.value.menuStepItems.value.forEach(function (item) {
      if (item.inputType === config.INPUT_TYPE.SELECT) {
        itemsArray.push(item);
      }
      if (item.inputType === config.INPUT_TYPE.COMBOBOX) {
        itemsArray.push(item);
      }
      if (item.type === config.INPUT_TYPE.MENUTABGROUP) {
        $("#" + item.attrTabId + " a:first").tab("show");
        item.menuTabs.value.forEach(function (menuTab) {
          menuTab.items.value.forEach(function (menuTabItem) {
            if (menuTabItem.inputType === config.INPUT_TYPE.SELECT) {
              itemsArray.push(menuTabItem);
            }
            if (menuTabItem.inputType === config.INPUT_TYPE.COMBOBOX) {
              itemsArray.push(menuTabItem);
            }
          });
        });
      }
    });
    if (itemsArray.length > 0) {
      itemsArray.forEach(function (select) {
        if (select.closeAndRecreateMenu !== undefined) {
          select.closeAndRecreateMenu();
        }
        if (select.closeAndRecreateCombobox !== undefined) {
          select.closeAndRecreateCombobox();
        }
      });
    }
  }

  function openMenuStepUsingPath(
    pathToOpen,
    isMenuCard,
    allowIncludesWhenSearchingOnMenuValue
  ) {
    var itemToOpen = null,
      pathToValue,
      pathArray,
      stepsInPath,
      stepToUse = null,
      step;

    if (pathToOpen.split(".").length === 1) {
      stepToUse = this.activeMenuStep.value;
      pathToValue = pathToOpen;
    } else {
      // find relevant value
      activateThisStepTest = null;
      initializeMenuSearch(menu.activeMenuStep.value, pathToOpen);
    }
    // get menu step
    if (stepToUse !== null) {
      //if (stepToUse.menuPath !== activeMenuStep().menuPath) {
      //    stepToUse.click();
      //    stepToUse = stepToUse.nestedStep;
      //}
      pathArray = pathToValue.split(".");
      activateThisStepTest = stepToUse;
      return pathArray;
    } else {
      // could not find step
      activateThisStepTest = null;
    }

    function initializeMenuSearch(stepsArray, path) {
      if (Array.isArray(stepsArray)) {
        for (var i = 0; i < stepsArray.length; i++) {
          if (isValueOnStep(stepsArray[i], path, false)) {
            if (
              stepToUse === null ||
              stepToUse.type === config.ITEM_TYPE.MENU_CARD
            ) {
              stepToUse = stepsArray[i];
            }
          }
        }
      } else {
        if (isValueOnStep(stepsArray, path, false)) {
          if (
            stepToUse === null ||
            stepToUse.type === config.ITEM_TYPE.MENU_CARD
          ) {
            stepToUse = stepsArray;
          }
        }
      }
      return false;
    }
    function isValueOnStep(step, path, allowSearchOnMenuCards) {
      for (var i = 0; i < step.menuStepItems.value.length; i++) {
        var menuStepItem = step.menuStepItems.value[i];
        if (menuStepItem.type === config.ITEM_TYPE.VALUE) {
          if (isValueInItem(menuStepItem, path)) {
            return true;
          }
        }
        if (menuStepItem.type === config.ITEM_TYPE.INPUT_GROUP) {
          if (isValueInItem(menuStepItem.upDownBox, path)) {
            return true;
          }
          if (isValueInItem(menuStepItem.select, path)) {
            return true;
          }
        }
        if (menuStepItem.type === config.ITEM_TYPE.MENUTABGROUP) {
          if (isValueInTabGroup(menuStepItem, path)) {
            return true;
          }
        }
        if (allowSearchOnMenuCards) {
          if (menuStepItem.type === config.ITEM_TYPE.MENUCARD) {
            if (isValueOnStep(menuStepItem.nestedStep, path)) {
              if (stepToUse === null) {
                stepToUse = menuStepItem;
              }
              if (typeof isMenuCard === "function") {
                isMenuCard({
                  parentSelectionGroup: step,
                  path: path,
                });
              }
              return true;
            }
          }
        }
        if (menuStepItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
          for (
            var j = 0;
            j < menuStepItem.selectedMenuItems.value.length;
            j++
          ) {
            var innerMenuStepItem = menuStepItem.selectedMenuItems.value[j];
            if (isValueOnStep(innerMenuStepItem.nestedStep, path, true)) {
              if (typeof isMenuCard === "function") {
                isMenuCard({
                  parentSelectionGroup: menuStepItem,
                  path: path,
                });
              }
              return true;
            }
          }
        }
      }

      return false;
    }
    function isValueInTabGroup(tabGroup, path) {
      for (var i = 0; i < tabGroup.menuTabs.value.length; i++) {
        var tab = tabGroup.menuTabs.value[i];
        if (isValueInTab(tab, path)) {
          return true;
        }
      }
      return false;
    }
    function isValueInTab(tab, path) {
      for (var i = 0; i < tab.items.value.length; i++) {
        var tabItem = tab.items.value[i];
        if (tabItem.type === config.ITEM_TYPE.MENUTABGROUP) {
          if (isValueInTabGroup(tabItem, path)) {
            return true;
          }
        }
        if (tabItem.type === config.ITEM_TYPE.VALUE) {
          if (isValueInItem(tabItem, path)) {
            return true;
          }
        }
        if (tabItem.type === config.ITEM_TYPE.INPUT_GROUP) {
          if (isValueInItem(tabItem.upDownBox, path)) {
            return true;
          }
          if (isValueInItem(tabItem.select, path)) {
            return true;
          }
        }
        if (tabItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
          for (var j = 0; j < tabItem.selectedMenuItems.value.length; j++) {
            var innerMenuStepItem = tabItem.selectedMenuItems.value[j];

            if (isValueOnStep(innerMenuStepItem.nestedStep, path, true)) {
              if (stepToUse === null) {
                //stepToUse = innerMenuStepItem;
                stepToUse = tab.parentMenuStep;
              }
              // add this - menuStepItem
              if (typeof isMenuCard === "function") {
                var pathToCard = null;
                if (
                  globals.doesPathIncludeAxleGroupItemThatExistsOnParentChassis(
                    path
                  ) === true
                ) {
                  pathToCard =
                    innerMenuStepItem.path +
                    "." +
                    path.split(".")[path.split(".").length - 1];
                }
                isMenuCard({
                  parentTab: tab,
                  parentSelectionGroup: tabItem,
                  path: path,
                  pathToCard: pathToCard,
                });
              }
              return true;
            }
          }
        }
      }
      return false;
    }
    function isValueInItem(value, path) {
      if (allowIncludesWhenSearchingOnMenuValue === true) {
        //if (value.path && value.path.includes(path)) {
        if (
          value.path &&
          (value.path === path || value.path.includes(path + "."))
        ) {
          //if (value.path && value.path === path) {
          if (path.substr(path.length - 4) !== config.ACCESSORY_TYPES.BODY) {
            pathToValue = value.menuPath;
            return true;
          }
        }
      } else {
        //if (value.path && value.path.includes(path)) {
        if (value.path && value.path === path) {
          pathToValue = value.menuPath;
          return true;
        }
      }

      return false;
    }
  }

  function openMenuStepUsingPathArray(pathArray, isMenuCard) {
    if (
      activateThisStepTest &&
      activateThisStepTest.menuPath !== activeMenuStep.value.menuPath
    ) {
      if (activateThisStepTest.label === "Home") {
        //activeMenuStep().activateMenuStep(activateThisStepTest, pathArray, undefined, isMenuCard);
        activeMenuStep.value.activateMenuStep(
          activeMenuStep.value,
          pathArray,
          undefined,
          isMenuCard
        );
      } else {
        activateThisStepTest.click();
      }
    } else {
      activeMenuStep.value.activateMenuStep(
        activeMenuStep.value,
        pathArray,
        undefined,
        isMenuCard
      );
    }
  }

  function cleanUp() {
    if (menu.activateMenuStepSubscriptionRef) {
      menu.activateMenuStepSubscriptionRef();
    }
    menu
      .activeMenuStep.value
      .menuStepItems.value
      .forEach(function (menuStep) {
        traverseMenuStepMenuTree(menuStep);
      });
    function traverseMenuStepMenuTree(activeMenuStep) {
      doIt(activeMenuStep);

      function doIt(item) {
        if (item.displaySelectionWizard) {
          try {
            if (item.getMenuGroupSelectionWizard()) {
              if (item.getMenuGroupSelectionWizard().dataToRender.value.cleanUp) {
                item.getMenuGroupSelectionWizard().dataToRender.value.cleanUp();
              }
            }
          } catch (ex) {
            console.log("Crash 10003");
          }
        }
        try {
          if (item.cleanUp) {
            item.cleanUp();
          }
        } catch (itemCleanUpEx) {
          console.log("Crash when cleaning item type: " + item.type);
        }

        if (item.menuStepItems) {
          item.menuStepItems.value.forEach(function (subItem) {
            doIt(subItem);
            utils.clearSubscriptions(subItem);
          });
          utils.clearSubscriptions(item.menuStepItems);
        } else if (item.items) {
          if (Array.isArray(item.items)) {
            item.items.forEach(function (subItem) {
              doIt(subItem);
              utils.clearSubscriptions(subItem);
            });
            utils.clearSubscriptions(item.items);
          } else {
            item.items.value.forEach(function (subItem) {
              doIt(subItem);
              utils.clearSubscriptions(subItem);
            });
            utils.clearSubscriptions(item.items);
          }
        } else if (item.menuTabs) {
          item.menuTabs.value.forEach(function (tab) {
            doIt(tab);
            utils.clearSubscriptions(tab);
          });
          utils.clearSubscriptions(item.menuTabs);
        } else if (item.tabs) {
          item.tabs.forEach(function (tab) {
            doIt(tab);
            utils.clearSubscriptions(tab);
          });
          utils.clearSubscriptions(item.tabs);
        } else if (item.nestedStep) {
          doIt(item.nestedStep);
          if (item.nestedStep.cleanUp) {
            item.nestedStep.cleanUp();
          }
          // } else if (item.menuStepItems) {
          //     doIt(item.menuStepItems());
          //     utils.clearSubscriptions(item.menuStepItems);
        } else if (item.selectedItems) {
          item.selectedItems.value.forEach(function (selectedItem) {
            doIt(selectedItem);
            utils.clearSubscriptions(selectedItem);
          });
          utils.clearSubscriptions(item.selectedItems);
        } else if (item.dataToRender) {
          if (item.dataToRender.value.cleanUp) {
            item.dataToRender.value.cleanUp();
          }
        }
      }
    }

    // breadcrumbButtons.value.removeAll();
    // utils.clearSubscriptions(breadcrumbButtons);
    // menuStructure.value.removeAll();
    // newMenuStructure = null;
    // activeMenuStep.value = null;
    // activeMenuStep = null;
    // offerStructure.value = null;
    // offerStructure = null;
    // displayBreadcrumbButtons.value = null;
    // utils.clearSubscriptions(displayBreadcrumbButtons);
    // displayBreadcrumbButtons = null;
    // breadcrumbButtonsDisplayType.value = null;
    // utils.clearSubscriptions(breadcrumbButtonsDisplayType);
    // breadcrumbButtonsDisplayType = null;
    // self = null;

    // partialId = null;
    // activateThisStepTest = null;
    // closeMenuCallback = null;
    // menuCloseOptions = null;
    // backButtonCallback = null;
    // displayBackButton.value = null;
    // displayBackButton = null;
    // menuDocked.value = null;
    // menuDocked = null;
    // popMenuTitle.value = null;
    // popMenuTitle = null;
    // menuTitle.value = null;
    // menuTitle = null;
    // popInMenuCallback = null;
  }

  function notifyMenuActivityListeners(event, path) {
    if (menuActivityListeners.length > 0) {
      menuActivityListeners.forEach(function (listenerCallback) {
        try {
          listenerCallback(event, path);
        } catch (listenerCallbackEx) {
          console.log("Crash 10000");
        }
      });
    }
  }

  function registerMenuActivityCallbackListener(listener) {
    if (menuActivityListeners.indexOf(listener) === -1) {
      menuActivityListeners.push(listener);
    }
  }

  function getMenuCloseCallback() {
    return closeMenuCallback;
  }

  function setMenuCloseCallback(callback) {
    closeMenuCallback = callback;
  }

  function getPopInMenuCallback() {
    return popInMenuCallback;
  }

  function setPopInMenuCallback(callback) {
    popInMenuCallback = callback;
  }

  function getPreventPopMenu() {
    return preventPopMenu;
  }

  function setPreventPopMenu(newValue) {
    preventPopMenu = typeof newValue === "boolean" ? newValue : false;
  }

  function getMenuCloseOptions() {
    return menuCloseOptions;
  }

  function setMenuCloseOptions(value) {
    menuCloseOptions = value;
  }

  function getPartialMenuId() {
    return partialMenuId;
  }

  function closeMenu() {
    var callback = getMenuCloseCallback(),
      options = getMenuCloseOptions();
    if (callback !== undefined) {
      if (options !== undefined) {
        callback(
          menu.menuId,
          options.menuLocation,
          options.resetObv,
          options.postFix
        );
      } else {
        callback(menu.menuId);
      }
      notifyMenuActivityListenersRef("Menu_Closed", menu.activeMenuStep.value.path);
    } else {
      TScMessenger.writeDebugMessage("Close menu callback is not set");
    }
  }

  function popMenu() {
    var menuContainer = $("#" + partialMenuId + "-container"),
      mainContainer = $("#shell-main-view");
    if (menuDocked.value) {
      // pop out
      menuDocked.value = false;
      popMenuTitle.value = config.getTranslationText("966");
      positionMenuInCentreOfScreen();
    } else {
      // pop in
      if (typeof getPopInMenuCallback() === "function") {
        getPopInMenuCallback()();
      }
      menuDocked.value = true;
      popMenuTitle.value = config.getTranslationText("965");
      positionMenuCallback = getPopMenuPositionCallback();
      if (typeof positionMenuCallback === "function") {
        positionMenuCallback(partialMenuId);
      }
    }
  }

  function positionMenuInCentreOfScreen(useCss) {
    var menuContainer = $("#" + partialMenuId + "-container"),
      changeDisplayAndVisibility = !menuContainer.is(":visible");
    if (changeDisplayAndVisibility === true) {
      menuContainer.css({
        display: "block",
        visibility: "hidden",
      });
    }

    menuContainer.position({
      my: "center",
      at: "center",
      of: "#shell-main-view",
    });

    if (changeDisplayAndVisibility === true) {
      menuContainer.css({
        display: "none",
        visibility: "visible",
      });
    }
  }

  function getPopMenuPositionCallback(callback) {
    return popMenuPositionCallback;
  }

  function setPopMenuPositionCallback(callback) {
    popMenuPositionCallback = callback;
  }

  function getBackButtonCallback() {
    return backButtonCallback;
  }

  function setBackButtonCallback(value) {
    backButtonCallback = value;
  }

  function backButtonClick(menuObj) {
    var callback = getBackButtonCallback();
    if (typeof callback === "function") {
      callback(menuObj);
    } else {
      TScMessenger.writeDebugMessage("back button callback is not set");
    }
  }

  function getActiveMenuStepObservable() {
    return menu.activeMenuStep.value;
  }

  function getMenu() {
    return menu;
  }

  function updateDisplayBackButton(newValue) {
    menu.displayBackButton.value = newValue;
    // if(isRef(menu.displayBackButton)) {
    //   menu.displayBackButton.value = newValue;
    // } else {
    //   menu.displayBackButton = newValue;
    // }
  }

  function getMenuId() {
    return menu.menuId;
  }

  function getMenuDocked() {
    return menu.menuDocked.value;
  }

  // function addSliderToStep(id, slideNumber, tooltipId, disableClick) {
  //   var disableClickValue =
  //     typeof disableClick === "function" ? disableClick() : disableClick;
  //   if (
  //     $("#" + id + "-slider").slick !== undefined &&
  //     $("#" + id + "-slider").hasClass("slick-initialized")
  //   ) {
  //     try {
  //       $("#" + id + "-slider").slick("unslick");
  //     } catch (e) {
  //       TScMessenger.writeDebugMessage("error in creating slider");
  //     }
  //   }
  //   var slickOptions = {
  //     slidesToShow: 2,
  //     slidesToScroll: 1,
  //     infinite: false,
  //     arrows: false,
  //   };
  //   if (slideNumber !== undefined && slideNumber !== null && slideNumber > 1) {
  //     slickOptions.initialSlide = slideNumber;
  //   }
  //   if ($("#" + id + "-slider").is(":visible")) {
  //     try {
  //       var slider = $("#" + id + "-slider")
  //         .not(".slick-initialized")
  //         .slick(slickOptions);
  //     } catch (e) {
  //       TScMessenger.writeDebugMessage("error in creating slider");
  //     }

  //     $("#" + id + "-slider-prev")
  //       .off("click touchstart")
  //       .on("click touchstart", function () {
  //         var currentSlide = $("#" + id + "-slider").slick("slickCurrentSlide");
  //         if (currentSlide > 1) {
  //           $("#" + id + "-slider").slick("slickGoTo", currentSlide - 2);
  //         } else {
  //           $("#" + id + "-slider").slick("slickPrev");
  //         }
  //         return false;
  //       });
  //     $("#" + id + "-slider-next")
  //       .off("click touchstart")
  //       .on("click touchstart", function () {
  //         $("#" + id + "-slider").slick("slickNext");
  //         return false;
  //       });

  //     $(".equipment-slider").show("fade", {}, 200);

  //     if (
  //       tooltipId !== undefined &&
  //       tooltipId !== null &&
  //       disableClickValue === false
  //     ) {
  //       $("#" + tooltipId + " i").addClass("pulse-div");
  //     }
  //   }
  // }

  function updateJsElementsUsingStep(
    step,
    partialId,
    pathArray,
    notifyListeners
  ) {
    if (/*notifyListeners === undefined ||*/ notifyListeners === true) {
      notifyMenuActivityListenersRef("Menu_Opened", step.path);
    }
    if (step.updateJsElements) {
      step.updateJsElements(partialId, pathArray);
    } else {
      TScMessenger.writeDebugMessage("updateJsElements missing from step");
    }
  }

  function updateMenuForStickyHeaders(selectionWizard) {
    // NOTE: Currently hard coding sizes because getting inconsistent height results from JS/jQuery
    // Works for currenty menus but would like to make this more generic by getting the right height results and changing height using these and available space
    var newHeight = 420;
    var firstItem, activeStep;
    if (selectionWizard !== undefined) {
      if (typeof selectionWizard.clickEventCallback === "function") {
        selectionWizard.clickEventCallback();
      }
      if (selectionWizard.partialMenuId !== undefined && selectionWizard.dataToRender.value.getRenderAs() === config.RENDERED_AS_OPTIONS.DATAGRID) {
        if ($("#" + selectionWizard.partialMenuId + "-container").height() > 500) {
          // Check whether slider is displayed
          activeStep = getActiveMenuStepObservable();
          firstItem = activeStep.menuStepItems.value[0];
          // set newHeight 420 false 305 true
          if (firstItem.type === "selectionGroup" &&firstItem.selectedMenuItems.value.length > 0) {
            newHeight = 305;
          }
          // Remove overflow from menu-container class
          $("#" + selectionWizard.partialMenuId + "-container .menu-container").css("overflow", "");
          // Set height to fill 550px (max height of menu)
          $("#" + selectionWizard.partialMenuId + "-container .rows-container").css("height", newHeight);
        }
        if (getMenuDocked()) {
          globals.repositionMenu(selectionWizard.partialMenuId, undefined, true);
        }
      }
    } else {
      if ($("#" + menu.menuId + "-container").height() > 500) {
        // Check whether slider is displayed
        activeStep = getActiveMenuStepObservable();
        firstItem = activeStep.menuStepItems.value[0];
        // set newHeight 420 false 305 true
        if (firstItem.type === "selectionGroup" && firstItem.selectedMenuItems().length > 0) {
          newHeight = 305;
        } else if (firstItem.type === "selectionWizard") {
          newHeight = 350;
        }
        // Remove overflow from menu-container class
        $("#" + menu.menuId + "-container .menu-container").css("overflow", "");
        // Set height to fill 550px (max height of menu)
        $("#" + menu.menuId + "-container .rows-container").css("height", newHeight);
      }
      if (getMenuDocked()) {
        globals.repositionMenu(menu.menuId, undefined, true);
      }
    }
  }

  function activateSelectionWizard(selectionGroup) {
    if (selectionGroup !== undefined) {
      selectionGroup.displaySelectionWizard(true);
    }

    if (menu.activeMenuStep.value !== undefined) {
      if (selectionGroup !== undefined) {
        selectionGroup.menuGroupSelectionWizard.dataToRender.value.resetSelectionWizard();
        updateTitle(selectionGroup.menuGroupSelectionWizard.label);
      }

      if (menu.menuDocked.value) {
        globals.repositionMenu(menu.menuId);
      }
      updateMenuForStickyHeaders();
    }
  }

  function getDisplayBreadcrumbButtonsContainer() {
    return displayBreadcrumbButtonsContainer;
  }

  function setDisplayBreadcrumbButtonsContainer(newValue) {
    displayBreadcrumbButtonsContainer = newValue;
  }

  function hideBreadcrumbButtonsContainer() {
    setDisplayBreadcrumbButtonsContainer(false);
  }

  function getMenuItemUsingPath(pathToOpen) {
    var itemToReturn = null;

    activeMenuStep.value.menuStepItems.value.forEach(checkItems);

    function checkItems(item) {
      if (itemToReturn === null) {
        if (item.type === config.ITEM_TYPE.VALUE && item.path === pathToOpen) {
          itemToReturn = {
            menuValue: item,
          };
        }
        if (item.type === config.ITEM_TYPE.INPUT_GROUP) {
          if (
            (item.inputGroupType =
              config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU)
          ) {
            if (
              item.upDownBox.path === pathToOpen ||
              item.select.path === pathToOpen
            ) {
              itemToReturn = {
                menuValue: item,
              };
            }
          }
        }
        if (item.type === config.ITEM_TYPE.MENUTABGROUP) {
          checkMenuTabGroup(item);
        }
        if (item.type === config.ITEM_TYPE.SELECTIONGROUP) {
          item.selectedMenuItems.value.forEach(checkMenuCards);
        }
      }
    }

    function checkMenuCards(card) {
      if (itemToReturn === null) {
        if (card.path === pathToOpen) {
          itemToReturn = {
            menuValue: card,
            isMenuCard: true,
          };
        } else {
          card.nestedStep.menuStepItems.value.forEach(checkItems);
          if (itemToReturn !== null) {
            itemToReturn.isOnMenuCard = true;
            itemToReturn.label = card.label;
          }
        }
      }
    }

    function checkMenuTabGroup(mtg) {
      TScMessenger.writeDebugMessage("");
      mtg.menuTabs.value.forEach(function (tab) {
        tab.items.value.forEach(checkItems);
      });
    }

    function checkMenuValue(mv) {
      if (mv.menuPath === pathToOpen) {
        itemToReturn = mv;
      }
    }

    return itemToReturn;
  }

  return menu;

  //#region Menu Elements
  /**
   *
   * @param {Object} initStep - object to be used when creating step
   * @param {Object} business
   * @param {boolean} memberOfSelectionGroupItem - notify if the step is nestedStep of a MenuCard
   * @param {string} partialMenuId - id of menu element
   * @param {boolean} setMenuTitleUsingLabel
   */
  function MenuStep(
    initStep,
    business,
    memberOfSelectionGroupItem,
    partialMenuId,
    setMenuTitleUsingLabel
  ) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.menuStepItems = shallowRef([]);
    self.displayItemBreadcrumbs = false;
    self.displayItemInBreadcrumbs = shallowRef(false);
    self.breadcrumbLabel = shallowRef("");
    self.menuStepLevel = initStep.menuStepLevel;
    self.displayBreadcrumbsButtonOnStep =
      initStep.displayBreadcrumbsButtonOnStep;
    self.breadcrumbsButtonsOnStep = [];
    self.menuPath = "STEP-" + initStep.path;
    self.path = initStep.path;
    if (self.displayBreadcrumbsButtonOnStep) {
      self.breadcrumbButtonsDisplayType = initStep.breadcrumbButtonsDisplayType;
      initStep.breadcrumbsButtonsOnStep.forEach(function (button) {
        self.breadcrumbsButtonsOnStep.push(new MenuButton(button));
      });
    }
    self.id = undefined;
    if (initStep.id !== undefined) {
      self.id = initStep.id;
    }
    self.displayTabsOnStep = false;
    if (initStep.displaytAccordionOnStep !== false) {
      self.displayAccordionOnStep = initStep.displayAccordionOnStep;
      self.accordionHeightStyle = initStep.accordionHeightStyle;
    } else {
      self.displayAccordionOnStep = false;
    }

    self.label = initStep.label;
    self.displayLabel = initStep.displayLabel;
    if (initStep.displayAccessories) {
      self.displayAccessories = initStep.displayAccessories;
    } else {
      self.displayAccessories = false;
    }
    self.displaySlider = false;
    self.displaySelectionWizard = false;
    self.sliderDisplayIds = [];
    self.memberOfSelectionGroupItem = memberOfSelectionGroupItem;
    self.partialMenuId = partialMenuId;
    self.customCss = initStep.customCss || "";
    self.containsSelectionGroup = false;
    self.triggerChange = ref(null);

    if (initStep.items !== undefined && initStep.items.length > 0) {
      self.displayItemBreadcrumbs = true;
      initStep.items.forEach(function (item) {
        item.menuPath = self.menuPath;
        switch (item.itemType) {
          case config.ITEM_TYPE.TEXT:
            addMenuItem(
              item,
              self.displayItemInBreadcrumbs,
              self.breadcrumbLabel
            );
            break;
          case config.ITEM_TYPE.VALUE:
            addMenuValue(item);
            break;
          case config.ITEM_TYPE.MENUTABGROUP:
            addMenuTabGroup(
              item,
              self.displayItemInBreadcrumbs,
              self.breadcrumbLabel
            );
            break;
          case config.ITEM_TYPE.DIVIDER:
            addDivider(item.customCss);
            break;
          case config.ITEM_TYPE.SELECTIONGROUP:
            addMenuSelectionGroup(
              item,
              self.displayItemInBreadcrumbs,
              self.breadcrumbLabel
            );
            self.displaySlider = true;
            break;
          case config.ITEM_TYPE.SELECT:
            addMenuSelect(item);
            break;
          case config.ITEM_TYPE.COMBOBOX:
            addMenuCombobox(item);
            break;
          case config.ITEM_TYPE.DATAGRID:
            addMenuDataGrid(item);
            break;
          case config.ITEM_TYPE.ACCESSORYGROUP:
            addMenuAccessoryGroup(
              item,
              self.displayItemInBreadcrumbs,
              self.breadcrumbLabel
            );
            break;
          case config.ITEM_TYPE.SELECTIONWIZARD:
            addMenuSelectionWizard(
              item,
              self.displayItemInBreadcrumbs,
              self.breadcrumbLabel
            );
            self.displaySelectionWizard = true;
            break;
          case config.ITEM_TYPE.INPUT_GROUP:
            addMenuInputGroup(item);
            break;
          case config.ITEM_TYPE.MENU_TABLE:
            addMenuTable(item);
            break;
          case config.ITEM_TYPE.RADIO:
            addMenuRadioInput(item);
            break;
          default:
            break;
        }
      });
    }

    if (initStep.values !== undefined && initStep.values.length > 0) {
      initStep.values.forEach(function (value) {
        addMenuValue(value);
      });
    }

    if (initStep.groups !== undefined) {
      addMenuTabGroup(initStep.groups);
    }

    function getLabel() {
      return self.displayLabel !== undefined ? self.displayLabel : self.label;
    }

    if (setMenuTitleUsingLabel) {
      updateTitle(getLabel());
    }

    var menuStep = {
      menuId: self.menuId,
      label: self.label,
      id: self.id,
      menuStepItems: self.menuStepItems,
      menuStepLevel: self.menuStepLevel,
      displayItemBreadcrumbs: self.displayItemBreadcrumbs,
      displayItemInBreadcrumbs: self.displayItemInBreadcrumbs,
      breadcrumbLabel: self.breadcrumbLabel,
      activateMenuStep: activateMenuStep,
      displayAccessories: self.displayAccessories,
      displayTabsOnStep: self.displayTabsOnStep,
      updateMenuObservable: updateMenuObservable,
      displayAccordionOnStep: self.displayAccordionOnStep,
      memberOfSelectionGroupItem: self.memberOfSelectionGroupItem,
      tempAddSliderToStep: tempAddSliderToStep,
      getLabel: getLabel,
      displayBreadcrumbsButtonOnStep: self.displayBreadcrumbsButtonOnStep,
      breadcrumbsButtonsOnStep: self.breadcrumbsButtonsOnStep,
      breadcrumbButtonsDisplayType: self.breadcrumbButtonsDisplayType,
      path: self.path,
      menuPath: self.menuPath,
      partialMenuId: self.partialMenuId,
      resetMenuStep: resetMenuStep,
      customCss: self.customCss,
      cleanUp: cleanUp,
      setUpMenuStep: setUpMenuStep,
      setUpTitle: setUpTitle,
      updateJsElements: updateJsElements,
      triggerChange: self.triggerChange
    };

    function addMenuItem(item, activateBreadcrumb, breadcrumbLabel) {
      var menuItem = new MenuItem(
        item,
        activateBreadcrumb,
        breadcrumbLabel,
        self
      );
      self.menuStepItems.value.push(menuItem);
    }

    function addMenuValue(value) {
      var menuValue = new MenuValue(value);
      self.menuStepItems.value.push(menuValue);
    }

    function addMenuSelect(value) {
      var menuSelect = new MenuSelect(value);
      self.menuStepItems.value.push(menuSelect);
    }

    function addMenuCombobox(value) {
      var menuCombobox = new MenuCombobox(value);
      self.menuStepItems.value.push(menuCombobox);
    }

    function addMenuDataGrid(value) {
      var menuDataGrid = new MenuDataGrid(value);
      self.menuStepItems.value.push(menuDataGrid);
    }

    function addMenuTabGroup(tabGroup, activateBreadcrumb, breadcrumbLabel) {
      var menuTabGroup = new MenuTabGroup(
        tabGroup,
        activateBreadcrumb,
        breadcrumbLabel,
        self
      );
      self.menuStepItems.value.push(menuTabGroup);

      if (tabGroup.displayType === "accordion") {
        self.displayAccordionOnStep = true;
        self.accordionHeightStyle =
          typeof tabGroup.heightStyle === "string"
            ? tabGroup.heightStyle
            : config.ACCORDION_HEIGHT_STYLE_OPTIONS.CONTENT;
      } else {
        self.displayTabsOnStep = true;
      }
    }

    function addMenuSelectionGroup(
      selectionGroup,
      activateBreadcrumb,
      breadcrumbLabel
    ) {
      var menuSelectionGroup = new MenuSelectionGroup(
        selectionGroup,
        activateBreadcrumb,
        breadcrumbLabel,
        self
      );
      self.sliderDisplayIds.push({
        displayId: menuSelectionGroup.displayId,
        tooltipId: menuSelectionGroup.tooltipId,
        disableClick: menuSelectionGroup.disableClick,
        getAddSliderToStepCallback: menuSelectionGroup.getAddSliderToStepCallback
      });
      self.menuStepItems.value.push(menuSelectionGroup);
      if (
        menuSelectionGroup.canAddItemUsingSelectionWizard === true
        // menuSelectionGroup.displaySelectionWizard.value === true &&
        // menuSelectionGroup.selectedMenuItems.value.length === 0
      ) {
        self.containsSelectionGroup = menuSelectionGroup;
      }
    }

    function addMenuAccessoryGroup(value, activateBreadcrumb, breadcrumbLabel) {
      var menuAccessoryGroup = new MenuAccessoryGroup(
        value,
        activateBreadcrumb,
        breadcrumbLabel
      );
      self.menuStepItems.value.push(menuAccessoryGroup);
    }

    function addMenuSelectionWizard(
      value,
      displayItemInBreadcrumbs,
      breadcrumbLabel
    ) {
      var menuSelectionWizard = new MenuSelectionWizard(
        value,
        self.partialMenuId,
        selectionWizardClick
      );
      self.menuStepItems.value.push(menuSelectionWizard);
    }

    function addDivider(customCss) {
      self.menuStepItems.value.push(getDividerObject(customCss));
    }

    function addMenuInputGroup(value) {
      var menuInputGroup = new MenuInputGroup(value);
      self.menuStepItems.value.push(menuInputGroup);
    }

    function addMenuTable(value) {
      var menuInputGroup = new MenuTable(value);
      self.menuStepItems.value.push(menuInputGroup);
    }

    function addMenuRadioInput(value) {
      var menuRadioInput = new MenuRadioInput(value);
      self.menuStepItems.value.push(menuRadioInput);
    }

    function tempAddSliderToStep(pathArray) {
      displaySlider(pathArray);
    }

    function resetSelectionWizard() {
      if (self.displaySelectionWizard) {
        self.menuStepItems.value.forEach(function (item) {
          if (item.type === config.ITEM_TYPE.SELECTIONWIZARD) {
            // item.dataToRender.value.goBackToStart();
            item.dataToRender.value.goBackToStart();
          }
        });
      }
      hideBackButton();
    }

    function resetSelectionGroupSelectionWizard() {
      self.menuStepItems.value.forEach(function (item) {
        if (
          item.type === config.ITEM_TYPE.SELECTIONGROUP &&
          item.canAddItemUsingSelectionWizard === true
        ) {
          item.getMenuGroupSelectionWizard().dataToRender.value.goBackToStart();
          if (Array.isArray(item.selectedMenuItems) === true) {
            if (item.selectedMenuItems.length > 0) {
              item.displaySelectionWizard.value = false;
            }
          } else {
            if (isRef(item.selectedMenuItems) && Array.isArray(item.selectedMenuItems.value) === true) {
              if (item.selectedMenuItems.value.length > 0) {
                item.displaySelectionWizard.value = false;
              }
            }
          }
          
        }
      });
      hideBackButton();
    }

    function resetSelectionGroup() {
      if (self.displaySlider) {
        self.menuStepItems.value.forEach(function (item) {
          if (item.type === config.ITEM_TYPE.SELECTIONGROUP) {
            item.deactivateAnyActiveMenuCards();
          }

          if (item.type === config.ITEM_TYPE.MENUTABGROUP) {
            item.menuTabs.value.forEach(function (tab) {
              tab.items.value.forEach(function (tabItem) {
                if (tabItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
                  tabItem.deactivateAnyActiveMenuCards();
                }
              });
            });
          }
        });
      }
    }

    function resetMenuStep() {
      tempAddSliderToStep();
      resetSelectionWizard();
      resetSelectionGroupSelectionWizard();
      resetSelectionGroup();
      setUpTitle();
    }

    function activateMenuStep(newStep, pathArray, forceMenuSlide, isMenuCard) {
      if (forceMenuSlide !== true && newStep.menuPath === menuStep.menuPath) {
        if (isMenuCard !== undefined) {
          var menuCardObject =
            typeof isMenuCard === "function" ? isMenuCard() : isMenuCard;
          if (menuCardObject !== false && menuCardObject !== undefined) {
            activateMenuCard(menuCardObject, pathArray);
            pathArray =
              menuCardObject.parentTab !== undefined
                ? menuCardObject.parentTab.menuPath.split(".")
                : pathArray;
          }
        }
        updateJsElementsUsingStep(newStep, undefined, pathArray, false);
      } else {
        $("#menu-content").hide(
          "slide",
          { direction: "left" },
          config.menuSlideDuration,
          function () {
            updateMenuObservable();
            updateJsElementsUsingStep(newStep, undefined, pathArray);
            $("#menu-content").show(
              "slide",
              { direction: "right" },
              config.menuSlideDuration,
              function () {
                globals.repositionMenu(newStep.partialMenuId);
                setTimeout(displaySlider, 500);
              }
            );
          }
        );
      }
    }

    function displaySlider(pathArray) {
      var i = 0,
        menuCardIndex,
        path = "";
      // Add slider if required
      if (self.displaySlider) {
        if (pathArray !== undefined) {
          pathArray.forEach(function (pathItem) {
            i += 1;
            path += pathItem;
            if (i < pathArray.length) {
              path += ".";
            }
          });
          i = 0;
          self.menuStepItems.value.forEach(function (menuStepItem) {
            if (menuStepItem.type === config.ITEM_TYPE.MENUCARD) {
              if (path.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
                if (
                  path.includes(menuStepItem.path) &&
                  menuStepItem.path.includes(config.OBJECT_TYPES.AXLE_GROUPS)
                ) {
                  menuCardIndex = i;
                }
              } else {
                if (path.includes(menuStepItem.path)) {
                  menuCardIndex = i;
                }
              }
              i += 1;
            }
            if (menuStepItem.type === config.ITEM_TYPE.MENUTABGROUP) {
              menuStepItem.menuTabs.value.forEach(function (menuTab) {
                menuTab.items.value.forEach(function (menuTabItem) {
                  if (menuTabItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
                    var innerSgIndex = 0;
                    menuTabItem.selectedMenuItems.value.forEach(function (
                      menuCard
                    ) {
                      if (path.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
                        if (
                          path.includes(menuCard.path) &&
                          menuCard.path.includes(
                            config.OBJECT_TYPES.AXLE_GROUPS
                          )
                        ) {
                          menuCardIndex = innerSgIndex;
                        }
                      } else {
                        if (path.includes(menuCard.path)) {
                          menuCardIndex = innerSgIndex;
                        }
                      }
                      innerSgIndex += 1;
                    });
                  }
                  if (menuTabItem.type === config.ITEM_TYPE.MENUCARD) {
                    if (path.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
                      if (
                        path.includes(menuStepItem.path) &&
                        menuStepItem.path.includes(
                          config.OBJECT_TYPES.AXLE_GROUPS
                        )
                      ) {
                        menuCardIndex = i;
                      }
                    } else {
                      if (path.includes(menuStepItem.path)) {
                        menuCardIndex = i;
                      }
                    }
                    i += 1;
                  }
                });
              });
            }
          });
        }

        self.sliderDisplayIds.forEach(function (id) {
          addSliderToStep(
            id.displayId,
            menuCardIndex,
            id.tooltipId,
            id.disableClick,
            id.getAddSliderToStepCallback()
          );
        });
      }
    }

    function updateMenuObservable() {
      createSliderOnStep(menuStep);
      self.displayItemInBreadcrumbs.value = false;
      deactivateAnyActiveSelectionGroupMenuCards();
    }

    function deactivateAnyActiveSelectionGroupMenuCards() {
      self.menuStepItems.value.forEach(function (menuStepItem) {
        if (menuStepItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
          if (menuStepItem.activeMenuCardStep.value !== null) {
            menuStepItem.activeMenuCardStep.value = null;
          }
        }
        if (menuStepItem.type === config.ITEM_TYPE.MENUCARD) {
          if (menuStepItem.isActive.value) {
            menuStepItem.deactivateMenuCard();
          }
        }
      });
    }

    function activateMenuCard(menuCardObject, pathArray) {
      var menuCardIndex = 0,
        i = 0;
      //ko.utils.arrayForEach(self.menuStepItemsArray, function (menuStepItem) {
      self.menuStepItems.value.forEach(function (menuStepItem) {
        if (menuStepItem.type === config.ITEM_TYPE.SELECTIONGROUP) {
          goThroughtSelectionGroup(menuStepItem, menuCardObject, pathArray);
        }
        if (menuStepItem.type === config.ITEM_TYPE.MENUTABGROUP) {
          goThroughMenuTabGroup(menuStepItem, menuCardObject, pathArray);
        }

        function goThroughMenuTabGroup(
          menuTabGroup,
          menuCardObject,
          pathArray
        ) {
          menuTabGroup.menuTabs.value.forEach(function (tab) {
            tab.items.value.forEach(function (item) {
              if (item.type === config.ITEM_TYPE.SELECTIONGROUP) {
                goThroughtSelectionGroup(item, menuCardObject, pathArray);
              }
              if (item.type === config.ITEM_TYPE.MENUTABGROUP) {
                goThroughMenuTabGroup(item, menuCardObject, pathArray);
              }
            });
          });
        }

        function goThroughtSelectionGroup(
          selectionGroup,
          menuCardObject,
          pathArray
        ) {
          var menuCardIndex = 0;
          selectionGroup.selectedMenuItems.value.forEach(function (menuCard) {
            var pathToUse =
              menuCardObject.pathToCard !== null &&
              menuCardObject.pathToCard !== undefined
                ? menuCardObject.pathToCard
                : menuCardObject.path;
            if (pathToUse.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
              if (
                pathToUse.includes(menuCard.path) &&
                menuCard.path.includes(config.OBJECT_TYPES.AXLE_GROUPS)
              ) {
                $(
                  "#" +
                    menuCardObject.parentSelectionGroup.displayId +
                    "-slider-" +
                    menuCard.id()
                ).click();
                selectionGroup.setActivateMenuCardOnMount(menuCard);
              }
            } else {
              if (pathToUse.includes(menuCard.path)) {
                $(
                  "#" +
                    menuCardObject.parentSelectionGroup.displayId +
                    "-slider-" +
                    menuCard.id()
                ).click();
                selectionGroup.setActivateMenuCardOnMount(menuCard);
                if (pathArray !== undefined && Array.isArray(pathArray)) {
                  menuCard.nestedStep.updateJsElements(null, pathArray);
                }
              }
            }

            menuCardIndex += 1;
          });
        }
      });
    }

    function cleanUp() {
      self.menuId = null;
      self.menuStepItems.value.forEach(function (msi) {
        if (msi.cleanUp) {
          msi.cleanUp();
        }
      });
      self.menuStepItems.value = null;
      utils.clearSubscriptions(self.menuStepItems);
      self.menuStepItems = null;
      self.displayItemBreadcrumbs = null;
      self.displayItemInBreadcrumbs.value = null;
      self.displayItemInBreadcrumbs = null;
      self.breadcrumbLabel.value = null;
      self.menuStepLevel = null;
      self.displayBreadcrumbsButtonOnStep = null;
      self.breadcrumbsButtonsOnStep = null;
      self.menuPath = null;
      self.path = null;
      self.id = null;

      self.displayTabsOnStep = null;

      self.label = null;
      self.displayLabel = null;
      self.displayAccessories = null;
      self.displaySlider = null;
      self.displaySelectionWizard = null;
      self.sliderDisplayIds = null;
      self.memberOfSelectionGroupItem = null;
      self.partialMenuId = null;
      self.customCss = null;
      self.containsSelectionGroup = null;
      if (initStep.cleanUp) {
        initStep.cleanUp();
      }
      self = null;
    }

    function hideBackButton() {
      if (self.containsSelectionGroup) {
        updateDisplayBackButton(false);
      }
    }

    function setUpTitle() {
      var menuItem, label;
      if (self.containsSelectionGroup) {
        menuItem = self.menuStepItems.value.find(function (menuStepItem) {
          return menuStepItem.type === config.ITEM_TYPE.SELECTIONGROUP;
        });
        label =
          menuItem.displaySelectionWizard.value === true
            ? menuItem.getMenuGroupSelectionWizard().label
            : menuItem.label;
        updateTitle(label);
      }

      if (self.displaySelectionWizard) {
        menuItem = self.menuStepItems.value.find(function (menuStepItem) {
          return menuStepItem.type === config.ITEM_TYPE.SELECTIONWIZARD;
        });
        label = menuItem.dataToRender.value.originalTitle;
        updateTitle(label);
      }
    }

    function setUpMenuStep() {
      hideBackButton();
      setUpTitle();
    }

    function selectionWizardClick() {
      var setWizardTitleCallback =
        this.dataToRender.value.getSetWizardTitleFunction();
      if (this.dataToRender.value.activeStepIndex === 0) {
        updateTitle(this.dataToRender.value.originalTitle);
        updateDisplayBackButton(false);
      } else {
        updateTitle(
          setWizardTitleCallback(this.dataToRender.value.itemsToDisplayArray.value)
        );
        updateDisplayBackButton(true);
      }
    }

    function updateJsElements(partialId, pathArray) {
      if (menuStep.displayAccordionOnStep) {
        if ($(".accordion").accordion("instance") !== undefined) {
          try {
            $(".accordion").accordion("destroy");
          } catch (accordionNotCreatedEx) {
            console.log("Crash 10001");
          }
        }
        var menuTabGroup = null,
          accordionHasActiveTab = true,
          accordionActiveOption = 0;

        for (var i = 0; i < menuStep.menuStepItems.value.length; i++) {
          if (menuStep.menuStepItems.value[i].type === "menuTabGroup") {
            menuTabGroup = menuStep.menuStepItems.value[i];
            break;
          }
        }

        if (menuTabGroup !== null) {
          if (
            menuTabGroup.menuTabDisplayType ===
            config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_INPUT
          ) {
            accordionHasActiveTab = false;
            for (var j = 0; j < menuTabGroup.menuTabs.value.length; j++) {
              if (
                menuTabGroup.menuTabs.value[j].menuTabInput.override.value ===
                false
              ) {
                accordionActiveOption = j;
                accordionHasActiveTab = true;
                break;
              }
            }
          }
          if (
            menuTabGroup.menuTabDisplayType ===
            config.MENU_TAB_DISPLAY_TYPES.LABEL_ONLY
          ) {
            if (menuTabGroup.allAccordionsClosedWhenInitialized === true) {
              accordionHasActiveTab = false;
            }
          }
        }

        if (accordionHasActiveTab === false) {
          accordionActiveOption = false;
        }

        $(".accordion").accordion({
          heightStyle: self.accordionHeightStyle,
          icons: {
            header: "accordion-icon accordion-icon-closed",
            activeHeader: "accordion-icon accordion-icon-open",
          },
          collapsible: true,
          active: accordionActiveOption,
          activate: function (event, ui) {
            WebuiPopovers.hideAll();
            if (ui.newPanel.length > 0) {
              // MIGRATION: Replace ko.dataFor - can't fix until performance module is ported as this code is never hit
              // var currentPanel = ko.dataFor(ui.newPanel[0]);
              var currentPanel;
              if (currentPanel !== undefined && currentPanel.items !== undefined) {
                currentPanel.items.value.forEach(function (item) {
                  if (item.type === config.ITEM_TYPE.SELECTIONGROUP) {
                    addSliderToStep(
                      item.displayId,
                      null,
                      item.tooltipId,
                      item.disableClick,
                      item.getAddSliderToStepCallback()
                    );
                  }
                  if (item.inputType === config.ITEM_TYPE.SELECT) {
                    item.createSelectMenu();
                  }
                  if (item.inputType === config.ITEM_TYPE.COMBOBOX) {
                    item.createCombobox();
                  }
                  if (item.inputType === config.ITEM_TYPE.INPUT_GROUP) {
                    item.createJsElements();
                  }
                  if (item.type === config.ITEM_TYPE.MENU_TABLE) {
                    item.createJsElements();
                  }
                });
              }
              changeHeightForAccordionFillIfNecessary(ui, ui.newPanel);
            }
          },
          create: function (event, ui) {
            changeHeightForAccordionFillIfNecessary(ui, ui.panel);
          },
        });
      }

      function changeHeightForAccordionFillIfNecessary(ui, panel) {
        if (
          $(".accordion").accordion("option", "heightStyle") ===
          config.ACCORDION_HEIGHT_STYLE_OPTIONS.FILL
        ) {
          if (panel.get(0).scrollHeight <= panel.height() + 5) {
            panel.css({
              height: "auto",
            });
          }
        }
      }

      // Add select menus if they are required
      var itemsArray = [];
      menuStep.menuStepItems.value.forEach(function (item) {
        if (item.inputType === config.INPUT_TYPE.SELECT) {
          itemsArray.push(item);
        }
        if (item.inputType === config.INPUT_TYPE.COMBOBOX) {
          itemsArray.push(item);
        }
        if (item.type === config.INPUT_TYPE.MENUTABGROUP) {
          item.initializeMenuTabGroup(pathArray);
          goThroughMenuTabGroup(item);
        }
        if (item.type === config.ITEM_TYPE.MENU_TABLE) {
          itemsArray.push(item);
        }
        if (item.inputType === config.ITEM_TYPE.INPUT_GROUP) {
          itemsArray.push(item);
        }
      });
      if (itemsArray.length > 0) {
        itemsArray.forEach(function (select) {
          if (select.createSelectMenu !== undefined) {
            select.createSelectMenu();
          }
          if (select.createCombobox !== undefined) {
            select.createCombobox();
          }
          if (select.createJsElements !== undefined) {
            select.createJsElements();
          }
          if (select.renderAs === config.RENDERED_AS_OPTIONS.SELECTION_WIZARD) {
            select.dataToRender.value.initializeMultiSelects();
          }
        });
      }

      function goThroughMenuTabGroup(menuTabGroup) {
        //menuTabGroup.menuTabs.value.forEach(function (menuTab) {
        // menuTab.items.value.forEach(function (menuTabItem) {
        menuTabGroup.menuTabs.value.forEach(function (menuTab) {
          menuTab.items.value.forEach(function (menuTabItem) {
            if (menuTabItem.type === config.ITEM_TYPE.MENUTABGROUP) {
              goThroughMenuTabGroup(menuTabItem);
            }
            if (menuTabItem.inputType === config.INPUT_TYPE.SELECT) {
              itemsArray.push(menuTabItem);
            }
            if (menuTabItem.inputType === config.INPUT_TYPE.COMBOBOX) {
              itemsArray.push(menuTabItem);
            }
            if (menuTabItem.type === config.ITEM_TYPE.MENU_TABLE) {
              itemsArray.push(menuTabItem);
            }
            if (menuTabItem.inputType === config.INPUT_TYPE.INPUT_GROUP) {
              itemsArray.push(menuTabItem);
            }
            if (menuTabItem.type === config.ITEM_TYPE.SELECTIONWIZARD) {
              itemsArray.push(menuTabItem);
            }
          });
        });
      }
    }

    return menuStep;
  }

  function MenuItem(
    initMenuItem,
    activateBreadcrumb,
    breadcrumbLabel,
    business,
    memberOfSelectionGroup,
    selectionGroupItemsArray,
    addSelectionGroupItemFunction
  ) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initMenuItem.label;
    self.type = config.ITEM_TYPE.ITEM;
    self.activateBreadcrumb = activateBreadcrumb;
    self.breadcrumbLabel = breadcrumbLabel;
    self.itemType = initMenuItem.itemType;
    if (initMenuItem.nestedStep !== undefined) {
      self.nestedStep = addMenuStep(
        initMenuItem.nestedStep,
        business,
        memberOfSelectionGroup
      );
    }

    self.equipmentItem = initMenuItem.equipmentItem;
    self.equipmentItemFunction = initMenuItem.equipmentItemFunction;
    self.equipmentObject = initMenuItem.equipmentObject;
    self.closeMenuFunction = initMenuItem.closeMenuFunction;
    self.graphicsCallbackFunction = initMenuItem.graphicsCallbackFunction;
    self.graphicsCallbackValue = initMenuItem.graphicsCallbackValue;
    self.menuOfSelectionGroup = memberOfSelectionGroup;
    self.goToHomeStepFromBreadcrumb = initMenuItem.goToHomeStepFromBreadcrumb;

    var menuItem = {
      menuId: self.menuId,
      label: self.label,
      type: self.type,
      activateMenuItem: activateMenuItem,
      click: click,
      activateFromBreadcrumb: activateFromBreadcrumb,
      equipmentItem: self.equipmentItem,
      equipmentObject: self.equipmentObject,
      addEquipmentItem: addEquipmentItem,
    };

    return menuItem;

    function addMenuStep(item, business) {
      return new MenuStep(item, business, null, null, null, false);
    }

    function activateMenuItem() {
      // Go to next step in menu
      // Should also work in breadcrumb
    }

    function click() {
      if (self.nestedStep !== undefined) {
        self.nestedStep.activateMenuStep(self.nestedStep, undefined, true);
        self.activateBreadcrumb(true);
        self.breadcrumbLabel(self.label);
      }
    }

    function activateFromBreadcrumb() {
      if (self.goToHomeStepFromBreadcrumb) {
        //self.activateHomeStepFunction();
      } else {
        self.nestedStep.activateMenuStep(self.nestedStep, undefined, true);
        self.activateBreadcrumb(true);
        self.breadcrumbLabel(self.label);
      }
    }

    function addEquipmentItem() {
      self.equipmentItemFunction(self.equipmentObject);
      if (
        self.graphicsCallbackFunction !== undefined &&
        self.graphicsCallbackValue !== undefined
      ) {
        self.graphicsCallbackFunction(self.graphicsCallbackValue);
      }
      self.closeMenuFunction();
    }
  }

  function MenuSelect(initSelect) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.id = initSelect.id;
    self.label = initSelect.label;
    self.value = initSelect.value;
    self.valueType = initSelect.valueType;
    self.inputType = initSelect.inputType;
    self.type = config.ITEM_TYPE.VALUE;
    self.displayArray = shallowRef(initSelect.array);
    self.valueObv = initSelect.valueObv;
    self.updateFunction = initSelect.updateFunction;
    self.permissionFunction = initSelect.permissionFunction;
    self.width = initSelect.width !== undefined ? initSelect.width : 221;
    self.path = initSelect.path;
    self.permissionObv = initSelect.permissionObv;
    self.override = initSelect.override;
    self.overrideTooltipMessage =
      typeof initSelect.overrideTooltipMessage === "string"
        ? initSelect.overrideTooltipMessage
        : config.getTranslationText("80");
    self.errorMessageObv = initSelect.errorMessageObv;
    self.optionsUpdateEventObv = initSelect.optionsUpdateEventObv;
    self.optionsUpdateFunc = initSelect.optionsUpdateFunc;
    self.menuPath = initSelect.menuPath + ".SELECT-" + initSelect.id;

    self.tooltipMessage = initSelect.tooltipMessage;
    self.tooltipMessageBesideLabel = false;
    self.tooltipId = initSelect.id + "-id";
    self.customCss =
      typeof initSelect.customCss === "string" ? initSelect.customCss : "";
    self.addNoContentCss = addNoContentCss;
    self.isOverrideDisabled =
      typeof initSelect.isOverrideDisabled === "boolean"
        ? initSelect.isOverrideDisabled
        : false;
    self.overrideDisabledCallback =
      typeof initSelect.overrideDisabledCallback === "function"
        ? initSelect.overrideDisabledCallback
        : function () {};
    if (self.isOverrideDisabled) {
      self.customCss += " menu-select-override-disabled";
    }

    self.displayUpgradeIcon = typeof initSelect.displayUpgradeIcon === 'boolean' ? initSelect.displayUpgradeIcon : false;
    self.displayUpgradeFunction = typeof initSelect.displayUpgradeFunction === 'function' ? initSelect.displayUpgradeFunction : function () {};
    self.upgradeTooltipMessageId = self.displayUpgradeIcon === true ? self.id + '-upgradeTooltipMessageId' : '';
    self.upgradeTooltipMessage = typeof initSelect.upgradeTooltipMessage === 'string' ? initSelect.upgradeTooltipMessage : '';
    self.upgradeTooltipMessageLinkId = typeof initSelect.upgradeTooltipMessageLinkId === 'string' ? initSelect.upgradeTooltipMessageLinkId : '';
    self.upgradeTooltipIntercomValue = typeof initSelect.upgradeTooltipIntercomValue === 'string' ? initSelect.upgradeTooltipIntercomValue : '';

    if (self.optionsUpdateEventObv !== undefined) {
      watch(self.optionsUpdateEventObv, function(){
        if(self.optionsUpdateFunc) {
          self.displayArray.value = self.optionsUpdateFunc();
        }
      });
    }

    var refreshObvSubscriptionRef;
    if (initSelect.refreshObv) {
      watch(initSelect.refreshObv,
        () => {
          if ($("#" + self.id).selectmenu() !== undefined) {
            $("#" + self.id).selectmenu("refresh");
          }
        }
      );
    }

    var previouslySelectedValue;

    var menuSelect = {
      menuId: self.menuId,
      id: self.id,
      label: self.label,
      value: self.value,
      type: self.type,
      valueType: self.valueType,
      inputType: self.inputType,
      displayArray: self.displayArray,
      valueObv: self.valueObv,
      createSelectMenu: createSelectMenu,
      permissionFunction: self.permissionFunction,
      path: self.path,
      permissionObv: self.permissionObv,
      errorMessageObv: self.errorMessageObv,
      closeAndRecreateMenu: closeAndRecreateMenu,
      cleanUp: cleanUp,
      menuPath: self.menuPath,
      tooltipMessage: self.tooltipMessage,
      tooltipId: self.tooltipId,
      showPopover: showPopover,
      customCss: self.customCss,
      addNoContentCss: self.addNoContentCss,
      override: self.override,
      overrideTooltipMessage: self.overrideTooltipMessage,
      toggleOverride: toggleOverride,
      displayUpgradeIcon: self.displayUpgradeIcon,
      triggerDisplayUpgradeFunction: triggerDisplayUpgradeFunction,
      showUpgradePopover: showUpgradePopover,
      upgradeTooltipMessageId: self.upgradeTooltipMessageId,
      upgradeTooltipMessage: self.upgradeTooltipMessage
    };

    function changeValue() {}

    function closeAndRecreateMenu() {
      if ($("#" + self.id).selectmenu() !== undefined) {
        $("#" + self.id).selectmenu("close");
        $("#" + self.id).selectmenu("destroy");
      }
      createSelectMenu();
    }

    function createSelectMenu() {
      function openFunction(event, ui) {
        var menuId = $(this)[0].id + "-menu";
        var menuTop = $("#" + menuId)
          .parent()
          .position().top;
        var menuHeight = $("#" + menuId).height();
        var contentHeight = $("#content").height();

        if (menuTop + menuHeight > contentHeight) {
          // calculate height
          var newHeight = contentHeight - menuTop + 10;
          if (newHeight > 100) {
            $("#" + menuId).css("height", newHeight);
            $("#" + menuId).css("overflow-y", "auto");
          } else {
            createSelectMenuDropUp();
          }
        }
      }

      function closeFunction(event, data) {
        previouslySelectedValue = $(this).data("lastValue");

        var valToPass;
        if (isNaN(parseInt(data.item.value))) {
          valToPass = data.item.value;
        } else {
          valToPass = parseInt(data.item.value);
        }

        self
          .updateFunction(valToPass, self.id, previouslySelectedValue)
          .then(function () {
            nextTick(function(){
              traverseMenuStepMenuTree(
                getActiveMenuStepObservable,
                "REFRESH_PERMISSION"
              );
              if (getMenuDocked()) {
                nextTick(function(){
                  globals.repositionMenu(getMenuId());
                });
              }
              getActiveMenuStepObservable().tempAddSliderToStep();
            });
          });
        $("#" + self.id).selectmenu("destroy");
        createSelectMenuDropDown();
      }

      function createSelectMenuDropDown() {
        $("#" + self.id).selectmenu({
          //width: 360,
          width: self.width,
          open: openFunction,
          change: closeFunction,
        });
      }

      function createSelectMenuDropUp() {
        $("#" + self.id).selectmenu("destroy");
        $("#" + self.id).selectmenu({
          width: self.width,
          open: openFunction,
          change: closeFunction,
          position: {
            my: "right bottom",
            at: "right top",
          },
        });
        $("#" + self.id).selectmenu("open");
      }

      createSelectMenuDropDown();

      $("#" + self.id).each(function () {
        $(this).data("lastValue", $(this).val());
      });

      if (self.permissionObv.value !== undefined) {
        if (
          self.permissionObv.value.readOnly ||
          self.override.value === false
        ) {
          $("#" + self.id).selectmenu("disable");
        }
      } else {
        if (self.permissionObv.readOnly || (self.override && self.override.value && self.override.value === false)) {
          $("#" + self.id).selectmenu("disable");
        }
      }
    }
    function createInfoTooltip() {
      if (self.tooltipMessage !== undefined) {
        var options = {
          trigger: "manual",
        };
        //$('#' + self.tooltipId).popover(options);
        $("#" + self.tooltipId).webuiPopover({
          trigger: "manual",
          closeable: true,
          animation: "pop",
        });
      }
    }

    function showPopover() {
      globals.displayTooltip("#" + self.tooltipId);
    }

    createInfoTooltip();

    function cleanUp() {
      // if (optionsUpdateEventObvSubcriptionRef !== undefined) {
      //   optionsUpdateEventObvSubcriptionRef.dispose();
      //   optionsUpdateEventObvSubcriptionRef = null;
      // }
      if (refreshObvSubscriptionRef) {
        refreshObvSubscriptionRef.dispose();
        refreshObvSubscriptionRef = null;
      }

      self.menuId = null;
      self.id = null;
      self.label = null;
      self.value = null;
      self.valueType = null;
      self.inputType = null;
      self.type = null;
      if (self.displayArray) {
        //utils.clearSubscriptions(self.displayArray);
        //self.displayArray.removeAll();
        self.displayArray = null;
      }

      if (self.valueObv) {
        //utils.clearSubscriptions(self.valueObv);
        self.valueObv = null;
      }
      self.updateFunction = null;
      self.permissionFunction = null;
      self.width = null;
      self.path = null;
      if (self.permissionObv) {
        //utils.clearSubscriptions(self.permissionObv, true);
        //self.permissionObv(null);
        self.permissionObv = null;
      }
      if (self.errorMessageObv) {
        //utils.clearSubscriptions(self.errorMessageObv);
        self.errorMessageObv = null;
      }
      //if (self.optionsUpdateEventObv) {
      //    utils.clearSubscriptions(self.optionsUpdateEventObv);
      //    self.optionsUpdateEventObv = null;
      //}
      self.optionsUpdateFunc = null;
      self.menuPath = null;

      self.tooltipMessage = null;
      self.tooltipMessageBesideLabel = null;
      self.tooltipId = null;
      self.customCss = null;

      if (initSelect.refreshObv) {
        //utils.clearSubscriptions(initSelect.refreshObv);
      }
      if (initSelect.cleanUp) {
        initSelect.cleanUp();
      }
    }

    function toggleOverride() {
      if(self.displayUpgradeIcon === false) {
        if (self.isOverrideDisabled === false) {
          if (self.override.value === true) {
            self.override.value = false;
            $("#" + self.id).selectmenu("refresh");
            $("#" + self.id).selectmenu("disable");
          } else {
            self.override.value = true;
            $("#" + self.id).selectmenu("enable");
          }
        } else {
          self.overrideDisabledCallback();
        }
      } else {
        triggerDisplayUpgradeFunction();
      }
    }

    function triggerDisplayUpgradeFunction() {
      self.displayUpgradeFunction();
    }

    function showUpgradePopover() {
        globals.displayTooltip('#' + self.upgradeTooltipMessageId, onShowUpgradePopoverFunction, removeClickEvent);
    }

    function updateTooltipMessage(newMessage) {
        var self = this;
        self.tooltipMessage = newMessage;
        $('#' + self.tooltipId).attr('data-content', newMessage);
        $('#' + self.tooltipId).webuiPopover('destroy');
    }

    function onShowUpgradePopoverFunction() {
        $('#' + self.upgradeTooltipMessageLinkId).click(function () {
            globals.hideTooltip('#' + self.upgradeTooltipMessageId);
            // triggerDisplayUpgradeFunction();
            bus.emit('handleEssentialsMessageResponse', { clickedButtonText: config.getTranslationText('271'), source: self.upgradeTooltipIntercomValue, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
        });
    }

    function removeClickEvent() {
        $('#' + self.upgradeTooltipMessageLinkId).off('click');
    }

    return menuSelect;
  }

  function MenuTooltip(id) {
    var menuTooltip = this;
    menuTooltip.id = id;
    menuTooltip.createInfoTooltip = function () {
      if (self.tooltipMessage !== undefined) {
        var options = {
          trigger: "manual",
        };
        $("#" + self.tooltipId).webuiPopover({
          trigger: "manual",
          closeable: true,
          animation: "pop",
        });
      }
    };

    return menuTooltip;
  }

  function MenuInfoTooltip() {
    MenuTooltip.call(this);

    var menuInfoTooltip = this;
    menuInfoTooltip.id = this.id + "-info-tooltip";

    return menuInfoTooltip;
  }

  function MenuInputTooltip() {
    MenuTooltip.call(this);

    var menuInputTooltip = this;
    menuInputTooltip.id = this.id + "-input-tooltip";

    return menuInputTooltip;
  }

  function MenuCombobox(initCombobox) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.id = initCombobox.id;
    self.label = initCombobox.label;
    self.value = initCombobox.value;
    self.valueType = initCombobox.valueType;
    self.inputType = initCombobox.inputType;
    self.type = config.ITEM_TYPE.VALUE;
    self.displayArray = shallowRef(initCombobox.array);
    self.valueObv = initCombobox.valueObv;
    self.updateFunction = initCombobox.updateFunction;
    self.permissionFunction = initCombobox.permissionFunction;
    //self.width = initCombobox.width !== undefined ? initCombobox.width : 215;
    self.width = initCombobox.width !== undefined ? initCombobox.width : 209;
    self.path = initCombobox.path;
    self.permissionObv = initCombobox.permissionObv;
    self.errorMessageObv = initCombobox.errorMessageObv;
    self.optionsUpdateEventObv = initCombobox.optionsUpdateEventObv;
    self.optionsUpdateFunc = initCombobox.optionsUpdateFunc;
    self.menuPath = initCombobox.menuPath + ".COMBOBOX-" + initCombobox.id;

    var optionsUpdateEventObvSubcriptionRef;
    if (self.optionsUpdateEventObv !== undefined) {
      // optionsUpdateEventObvSubcriptionRef =
        // self.optionsUpdateEventObv.subscribe(function () {
        //   self.displayArray(self.optionsUpdateFunc());
        // });
      watch(self.optionsUpdateEventObv, function(){
        self.displayArray.value = self.optionsUpdateFunc();
      });
    }

    var previouslySelectedValue;

    var menuCombobox = {
      menuId: self.menuId,
      id: self.id,
      label: self.label,
      value: self.value,
      type: self.type,
      valueType: self.valueType,
      inputType: self.inputType,
      displayArray: self.displayArray,
      valueObv: self.valueObv,
      createCombobox: createCombobox,
      permissionFunction: self.permissionFunction,
      path: self.path,
      permissionObv: self.permissionObv,
      errorMessageObv: self.errorMessageObv,
      closeAndRecreateCombobox: closeAndRecreateCombobox,
      cleanUp: cleanUp,
      menuPath: self.menuPath,
    };

    function closeAndRecreateCombobox() {
      if ($("#" + self.id).combobox() !== undefined) {
        $("#" + self.id).combobox("close");
        $("#" + self.id).combobox("destroy");
      }
      createCombobox();
    }

    function createCombobox() {
      function openFunction(event, ui) {
        var menuId = $(this)[0].id + "-menu";
        var menuTop = $("#" + menuId)
          .parent()
          .position().top;
        var menuHeight = $("#" + menuId).height();
        var contentHeight = $("#content").height();

        if (menuTop + menuHeight > contentHeight) {
          // calculate height
          var newHeight = contentHeight - menuTop + 10;
          if (newHeight > 100) {
            $("#" + menuId).css("height", newHeight);
            $("#" + menuId).css("overflow-y", "auto");
          } else {
            createComboboxDropUp();
          }
        }
      }

      function closeFunction(event, data) {
        previouslySelectedValue = $(this).data("lastValue");
        var isText = false;
        var valToPass;
        if (data.text === undefined) {
          if (isNaN(parseInt(data.item.value))) {
            valToPass = data.item.value;
          } else {
            valToPass = parseInt(data.item.value);
          }
        } else {
          valToPass = data.text;
          isText = true;
        }

        self
          .updateFunction(
            valToPass,
            isText /*self.id, previouslySelectedValue*/
          )
          .then(function () {
            //traverseMenuStepMenuTree(activeMenuStepObv, 'REFRESH_PERMISSION');
            traverseMenuStepMenuTree(
              getActiveMenuStepObservable,
              "REFRESH_PERMISSION"
            );
          });
        //$('#' + self.id).combobox('destroy');
        //createComboboxDropDown();
      }

      function createComboboxDropDown() {
        globals.addComboboxCodeIfNotAvailable();
        $("#" + self.id).combobox({
          //width: 360,
          width: self.width,
          open: openFunction,
          select: closeFunction,
          change: closeFunction,
        });
      }

      function createComboboxDropUp() {
        $("#" + self.id).combobox("destroy");
        $("#" + self.id).combobox({
          width: self.width,
          open: openFunction,
          select: closeFunction,
          change: closeFunction,
          position: {
            my: "right bottom",
            at: "right top",
          },
        });
        $("#" + self.id).combobox("open");
      }

      createComboboxDropDown();

      $("#" + self.id).each(function () {
        $(this).data("lastValue", $(this).val());
      });

      if (self.permissionObv.value.readOnly) {
        $("#" + self.id).combobox("disable");
      }
    }

    function cleanUp() {
      if (initCombobox.cleanUp) {
        initCombobox.cleanUp();
      }
    }

    return menuCombobox;
  }

  function MenuValue(initValue) {
    var self = this;

    self.incrementButtonTimeout;
    self.decrementButtonTimeout;
    self.menuId = menuIdCounter++;
    self.id = initValue.id;
    self.label = initValue.label;
    self.value = initValue.value;
    self.valueType = initValue.valueType;
    self.inputType = initValue.inputType;
    self.type = config.ITEM_TYPE.VALUE;
    self.maxLength = initValue.maxLength;
    self.errorMessage = initValue.errorMessage;
    self.errorMessageObv = initValue.errorMessageObv;
    self.permissionFunction = initValue.permissionFunction;
    self.override = typeof initValue.override === 'boolean' ? shallowRef(initValue.override) : initValue.override;
    self.overrideTooltipMessage =
      typeof initValue.overrideTooltipMessage === "string"
        ? initValue.overrideTooltipMessage
        : config.getTranslationText("80");
    self.tooltipMessage = initValue.tooltipMessage;
    self.tooltipMessageBesideLabel =
      typeof initValue.tooltipMessageBesideLabel === "boolean"
        ? initValue.tooltipMessageBesideLabel
        : false;
    var optionsUpdateEventObvSubcriptionRef;

    if(isRef(self.tooltipMessage)) {
      watch(
        () => self.tooltipMessage,
        (newValue, oldValue) => {
          refreshInfoTooltip();
        }
      );
    }

    self.tooltipId = initValue.id + "-id";
    self.displayInDataGrid = initValue.displayInDataGrid;
    self.resetValueFunction = initValue.resetValueFunction;
    self.resetValue = initValue.resetValue;
    self.valueTypeName = initValue.valueTypeName;
    self.menuPath = initValue.menuPath + ".VALUE-" + initValue.id;
    self.path = initValue.path;
    self.isReadOnly = initValue.isReadOnly;
    self.permissionObv = initValue.permissionObv;
    self.attributeGroup = initValue.attributeGroup;
    self.overrideDefaultWidth = initValue.overrideDefaultWidth || false;
    self.customCss =
      initValue.customCss !== undefined ? initValue.customCss : "";
    self.isMac = CustomTruckScienceRequirements.isMac();

    self.displaySaveSection =
      typeof initValue.displaySaveSection === "boolean"
        ? initValue.displaySaveSection
        : false;
    self.editFunctionCallback = initValue.editFunctionCallback;
    self.displayEditFunctionCallback = initValue.displayEditFunctionCallback;
    self.editButtonTooltipText =
      typeof initValue.editButtonTooltipText === "string"
        ? initValue.editButtonTooltipText
        : "";
    self.saveFunctionCallback = initValue.saveFunctionCallback;
    self.displaySaveFunctionCallback = initValue.displaySaveFunctionCallback;
    self.saveButtonTooltipText =
      typeof initValue.saveButtonTooltipText === "string"
        ? initValue.saveButtonTooltipText
        : "";
    self.allowSave =
      typeof initValue.allowSave === "boolean" ? initValue.allowSave : false;
    self.allowEdit =
      typeof initValue.allowEdit === "boolean" ? initValue.allowEdit : false;
    self.showSaveDisabled = initValue.showSaveDisabled || false;
    self.focusCallback = initValue.focusCallback
      ? function () {
          initValue.focusCallback(self.path);
        }
      : null;
    self.displayIncrementAndDecrementButtons =
      typeof initValue.displayIncrementAndDecrementButtons === "boolean"
        ? initValue.displayIncrementAndDecrementButtons
        : true;

    if (
      typeof initValue.displayInputTooltip === "function" &&
      initValue.displayInputTooltip() === true
    ) {
      self.displayInputTooltip = initValue.displayInputTooltip;
      self.inputTooltip = new MenuTooltipInput(initValue.inputTooltipObject);
    } else {
      self.displayInputTooltip = function () {
        return false;
      };
      self.inputTooltip = null;
    }

    if (initValue.displayInfoTooltip === true) {
      self.displayInfoTooltip = true;
      self.infoTooltip = new MenuTooltipInfo(initValue.infoTooltipObject);
    } else {
      self.displayInfoTooltip = false;
      self.infoTooltip = null;
    }

    var menuValue = {
      menuId: self.menuId,
      id: self.id,
      label: self.label,
      value: self.value,
      type: self.type,
      valueType: self.valueType,
      inputType: self.inputType,
      maxLength: self.maxLength,
      incrementValue: incrementValue,
      decrementValue: decrementValue,
      translateErrorMessage: translateErrorMessage,
      errorMessageObv: self.errorMessageObv,
      showTooltip: showTooltip,
      selectAllText: selectAllText,
      permissionFunction: self.permissionFunction,
      override: self.override,
      tooltipMessage: self.tooltipMessage,
      tooltipMessageBesideLabel: self.tooltipMessageBesideLabel,
      tooltipId: self.tooltipId,
      displayInDataGrid: self.displayInDataGrid,
      showPopover: showPopover,
      toggleOverride: toggleOverride,
      toggleCheckbox: toggleCheckbox,
      revertErrorValueOnBlur: revertErrorValueOnBlur,
      path: self.path,
      menuPath: self.menuPath,
      permissionObv: self.permissionObv,
      overrideDefaultWidth: self.overrideDefaultWidth,
      customCss: self.customCss,
      isMac: self.isMac,
      editButtonClick: editButtonClick,
      saveButtonClick: saveButtonClick,
      allowSave: self.allowSave,
      allowEdit: self.allowEdit,
      displayEditButton: displayEditButton,
      displaySaveButton: displaySaveButton,
      displaySaveSection: self.displaySaveSection,
      editButtonTooltipText: self.editButtonTooltipText,
      saveButtonTooltipText: self.saveButtonTooltipText,
      showSaveDisabled: self.showSaveDisabled,
      displayInputTooltip: self.displayInputTooltip,
      inputTooltip: self.inputTooltip,
      displayInfoTooltip: self.displayInfoTooltip,
      infoTooltip: self.infoTooltip,
      displayIncrementAndDecrementButtons:
        self.displayIncrementAndDecrementButtons,
      overrideTooltipMessage: self.overrideTooltipMessage,
      cleanUp: cleanUp,
      addNoContentCss: addNoContentCss,
    };

    function incrementValue(divInfo) {
      if (
        self.attributeGroup.override === undefined ||
        self.attributeGroup.override.value
      ) {
        TScMessenger.writeDebugMessage("inside incrementValue");
        if (!$(divInfo.target).hasClass("hide-button")) {
          $(divInfo.target).removeClass("show-button").addClass("hide-button");
        }
        clearTimeout(self.incrementButtonTimeout);
        self.incrementButtonTimeout = setTimeout(function () {
          TScMessenger.writeDebugMessage("before incrementValue clickHandler");
          clickHandler(divInfo);
          TScMessenger.writeDebugMessage("after incrementValueclickHandler");
        }, 100);
        self.attributeGroup.incrementObservable();
      }
    }

    function decrementValue(divInfo) {
      if (
        self.attributeGroup.override === undefined ||
        self.attributeGroup.override.value
      ) {
        TScMessenger.writeDebugMessage("inside decrementValue");
        if (!$(divInfo.target).hasClass("hide-button")) {
          $(divInfo.target).removeClass("show-button").addClass("hide-button");
        }
        clearTimeout(self.decrementButtonTimeout);
        self.decrementButtonTimeout = setTimeout(function () {
          TScMessenger.writeDebugMessage("before decrementValue clickHandler");
          clickHandler(divInfo);
          TScMessenger.writeDebugMessage("after decrementValue clickHandler");
        }, 100);
        self.attributeGroup.decrementObservable();
      }
    }

    function clickHandler(divInfo) {
      if ($(divInfo.target).hasClass("fa")) {
        if ($(divInfo.target).parent().hasClass("increment-div")) {
          $(divInfo.target).parent().removeClass("hide-button");
        }
        $(divInfo.target).removeClass("hide-button").addClass("show-button");
      }
      if ($(divInfo.target).hasClass("increment-div")) {
        if ($(divInfo.target).find("i").hasClass("fa")) {
          $(divInfo.target).find("i").removeClass("hide-button");
        }
        $(divInfo.target).removeClass("hide-button").addClass("show-button");
      }
    }

    function translateErrorMessage() {
      return config.getMessageText(self.errorMessage);
    }

    function createTooltip() {
      var options = {
        placement: "top",
        title: translateErrorMessage(),
      };
      $("#" + self.id).tooltip();

      if (self.displayInputTooltip()) {
        self.inputTooltip.createTooltip();
      }
    }

    function createInfoTooltip() {
      if (self.tooltipMessage !== undefined) {
        var options = {
          trigger: "manual",
        };
        $("#" + self.tooltipId).webuiPopover({
          trigger: "manual",
          closeable: true,
          animation: "pop",
        });
      }
    }

    function refreshInfoTooltip() {
      $("#" + self.tooltipId).webuiPopover("destroy");
      createInfoTooltip();
    }

    function showTooltip() {
      $("#" + self.id).tooltip("toggle");
    }

    function showPopover() {
      globals.displayTooltip("#" + self.tooltipId);
    }

    function hideTooltip() {
      $("#" + self.id).tooltip("hide");
    }

    function toggleTooltip() {
      $("#" + self.id).tooltip("toggle");
    }

    createInfoTooltip();

    function selectAllText(item, event) {
      if (event !== undefined) {
        event.stopPropagation();
      }
      if (self.focusCallback) {
        self.focusCallback();
      }

      if (globals.isiPad()) {
        setTimeout(function () {
          document.getElementById(self.id).selectionStart = 0;
          document.getElementById(self.id).selectionEnd = 999;
        }, 1);
      } else if (
        CustomTruckScienceRequirements.getBrowser() === config.BROWSER_NAME.EDGE
      ) {
        setTimeout(function () {
          $("#" + self.id).select();
        }, 1);
      } else {
        TScMessenger.writeDebugMessage("selectAllText 1");
        $("#" + self.id).select();
        TScMessenger.writeDebugMessage("selectAllText 2");
      }

      if (
        CustomTruckScienceRequirements.getBrowser() === config.BROWSER_NAME.EDGE
      ) {
        $("#" + self.id).bind("keypress", function (e) {
          if (e.keyCode == 13) {
            revertErrorValueOnBlur(true);
          }
        });
      }
    }

    function toggleOverride() {
      if (self.override.value === true) {
        self.override.value = false;
      } else {
        self.override.value = true;
        nextTick(function () {
          selectAllText();
        });
      }
    }

    function toggleCheckbox() {
      if (self.permissionObv.value.override === true) {
        if (self.override.value === true) {
          self.override.value = false;
        } else {
          self.override.value = true;
        }
      } else {
        if (!self.permissionObv.value.readOnly) {
          if (self.value.value === true) {
            self.value.value = false;
          } else {
            self.value.value = true;
          }
        }

        // traverseMenuStepMenuTree(
        //   getActiveMenuStepObservable,
        //   "REFRESH_PERMISSION"
        // );
        // offerManager.getUndoHandler().disableUndoOpCreation();
        nextTick(function(){
          traverseMenuStepMenuTree(getActiveMenuStepObservable, "REFRESH_PERMISSION");
          nextTick(function(){
            globals.repositionMenu(getMenu().menuId);
          });
        });
        // nextTick(function () {
        //   offerManager.getUndoHandler().enableUndoOpCreation();
        // });
      }
    }

    function revertErrorValueOnBlur(doNotRemoveBindingForKeyPress) {
      TScMessenger.writeDebugMessage("revertErrorValueOnBlur 1");
      if (self.resetValue !== undefined && self.resetValue.value) {
        self.resetValueFunction(self.id, true);
      }
      TScMessenger.writeDebugMessage("revertErrorValueOnBlur 2");
      if (
        doNotRemoveBindingForKeyPress !== true &&
        CustomTruckScienceRequirements.getBrowser() === config.BROWSER_NAME.EDGE
      ) {
        $("#" + self.id).unbind("keypress");
      }
    }

    function editButtonClick() {
      if (typeof self.editFunctionCallback === "function") {
        self.editFunctionCallback(getStepPath());
      }
    }

    function saveButtonClick() {
      if (typeof self.saveFunctionCallback === "function") {
        self.saveFunctionCallback(getStepPath());
      }
    }

    function displayEditButton() {
      if (typeof self.displayEditFunctionCallback === "function") {
        return self.displayEditFunctionCallback(getStepPath());
      }
      return false;
    }

    function displaySaveButton() {
      if (typeof self.displaySaveFunctionCallback === "function") {
        return self.displaySaveFunctionCallback(getStepPath());
      }
      return true;
    }

    function getStepPath() {
      var valueTypeNameLength = self.valueTypeName.length,
        indexToUse = (valueTypeNameLength + 1) * -1;
      return self.path.slice(0, indexToUse);
    }

    function cleanUp() {
      if (optionsUpdateEventObvSubcriptionRef !== undefined) {
        optionsUpdateEventObvSubcriptionRef.dispose();
      }
      
      self.incrementButtonTimeout = null;
      self.decrementButtonTimeout = null;
      self.menuId = null;
      self.id = null;
      // if (ko.isObservable(self.label)) {
      //   utils.clearSubscriptions(self.label);
      // }
      self.label = null;
      if (self.value) {
        //utils.clearSubscriptions(self.value);
        self.value = null;
      }
      self.valueType = null;
      self.inputType = null;
      self.type = null;
      self.maxLength = null;
      self.errorMessage = null;
      if (self.errorMessageObv) {
        //utils.clearSubscriptions(self.errorMessageObv);
        self.errorMessageObv = null;
      }
      self.permissionFunction = null;
      self.override = null;
      self.overrideTooltipMessage = null;
      self.tooltipMessage = null;
      self.tooltipMessageBesideLabel = null;
      /**************/
      self.tooltipId = null;
      self.displayInDataGrid = null;
      self.resetValueFunction = null;
      self.resetValue = null;
      self.valueTypeName = null;
      //self.path = initValue.path + ".VALUE-" + initValue.label;
      self.menuPath = null;
      self.path = null;
      self.isReadOnly = null;
      if (self.permissionObv) {
        //utils.clearSubscriptions(self.permissionObv);
        self.permissionObv = null;
      }

      self.attributeGroup = null;
      self.overrideDefaultWidth = null;
      self.customCss = null;
      self.isMac = null;

      self.displaySaveSection = null;
      self.editFunctionCallback = null;
      self.displayEditFunctionCallback = null;
      self.editButtonTooltipText = null;
      self.saveFunctionCallback = null;
      self.displaySaveFunctionCallback = null;
      self.saveButtonTooltipText = null;
      self.allowSave = null;
      self.allowEdit = null;
      self.showSaveDisabled = null;
      self.focusCallback = null;
      self.displayIncrementAndDecrementButtons = null;
      self.displayInputTooltip = false;
      self.inputTooltip = null;

      if (initValue.cleanUp) {
        initValue.cleanUp();
      }
    }

    return menuValue;
  }

  /**
   * Base function to be used for inheritance
   * @param {Object} initBaseItem - Base item to be used to create item
   * @param {string} initBaseItem.menuPath - The menuPath for the item
   * @param {string} initBaseItem.id - The id for the item
   * @param {string} initBaseItem.type - Type of menu item. Should exist on the ITEM_TYPE enum in config.js
   * @param {string} initBaseItem.customCss -
   */
  function MenuItemBase(initBaseItem) {
    var menuItemBase = this;

    menuItemBase.menuId = menuIdCounter++;
    menuItemBase.menuPath = initBaseItem.menuPath;
    menuItemBase.id = initBaseItem.id;
    menuItemBase.type = initBaseItem.type;
    menuItemBase.customCss =
      typeof initBaseItem.customCss === "string" ? initBaseItem.customCss : "";
  }

  function MenuItemInput(initInputItem) {
    MenuItemBase.call(this, initInputItem);
    var menuItemInput = this;
    menuItemInput.label = initInputItem.label;
    menuItemInput.inputType = initInputItem.inputType;
  }

  /**
   *
   * @param {Object} initMenuInputGroup - Initial object for menu input group
   * @returns {Object} Returns MenuInputGroup item that can be plugged into the menu in configuration/performance
   */
  function MenuInputGroup(initMenuInputGroup) {
    initMenuInputGroup.type = config.ITEM_TYPE.INPUT_GROUP;

    //MenuItemBase.call(this, initMenuInputGroup);
    MenuItemInput.call(this, initMenuInputGroup);
    var menuInputGroup = this;
    menuInputGroup.itemType = initMenuInputGroup.itemType;
    menuInputGroup.inputGroupType = initMenuInputGroup.inputGroupType;
    menuInputGroup.menuPath = initMenuInputGroup.menuPath + ".INPUTGROUP";
    menuInputGroup.createJsElements = function () {};
    menuInputGroup.refreshPermissions = function (path) {
      if (this.displayInputTooltip()) {
        this.inputTooltip.permissionObv.value =
          this.inputTooltip.permissionFunction(path || "");
      }
    };
    if (initMenuInputGroup.displayInfoTooltip === true) {
      menuInputGroup.displayInfoTooltip = true;
      menuInputGroup.infoTooltip = new MenuTooltipInfo(
        initMenuInputGroup.infoTooltip
      );
    } else {
      menuInputGroup.displayInfoTooltip = false;
      menuInputGroup.infoTooltip = null;
    }

    if (
      typeof initMenuInputGroup.displayInputTooltip === "function" &&
      initMenuInputGroup.displayInputTooltip() === true
    ) {
      menuInputGroup.displayInputTooltip =
        initMenuInputGroup.displayInputTooltip;
      menuInputGroup.inputTooltip = new MenuTooltipInput(
        initMenuInputGroup.inputTooltipObject
      );
    } else {
      menuInputGroup.displayInputTooltip = function () {
        return false;
      };
      menuInputGroup.inputTooltip = null;
    }

    switch (initMenuInputGroup.inputGroupType) {
      case config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU:
        createUpDownBoxAndSelectInputGroup(initMenuInputGroup);
        break;
      default:
        throw "inputGroupType not handled in MenuInputGroup switch statement";
    }

    function createUpDownBoxAndSelectInputGroup(
      initUpDownBoxAndSelectInputGroup
    ) {
      initUpDownBoxAndSelectInputGroup.upDownBox.menuPath =
        menuInputGroup.menuPath;
      initUpDownBoxAndSelectInputGroup.select.menuPath =
        menuInputGroup.menuPath;
      menuInputGroup.upDownBox = new MenuValue(
        initUpDownBoxAndSelectInputGroup.upDownBox
      );
      // if upDownBox has a tooltip then add it here
      if (menuInputGroup.upDownBox.tooltipMessage !== undefined) {
        var infoTooltip = {
          id: menuInputGroup.upDownBox.tooltipId,
          tooltipMessage: menuInputGroup.upDownBox.tooltipMessage,
        };
        menuInputGroup.displayInfoTooltip = true;
        menuInputGroup.infoTooltip = new MenuTooltipInfo(infoTooltip);
        menuInputGroup.tooltipMessageBesideLabel =
          typeof menuInputGroup.upDownBox.tooltipMessageBesideLabel ===
          "boolean"
            ? menuInputGroup.upDownBox.tooltipMessageBesideLabel
            : false;
        menuInputGroup.addNoContentCss = addNoContentCss;
      }

      menuInputGroup.permissionObv = menuInputGroup.upDownBox.permissionObv;
      menuInputGroup.select = new MenuSelect(
        initUpDownBoxAndSelectInputGroup.select
      );
      menuInputGroup.createJsElements = function () {
        this.select.createSelectMenu();
      };
    }
    function cleanUp() {
      menuInputGroup.itemType = null;
      menuInputGroup.inputGroupType = null;
      menuInputGroup.menuPath = null;
      menuInputGroup.createJsElements = null;
      menuInputGroup.refreshPermissions = null;
      menuInputGroup.displayInfoTooltip = null;
      if (menuInputGroup.infoTooltip) {
        if (menuInputGroup.infoTooltip.cleanUp) {
          menuInputGroup.infoTooltip.cleanUp();
        }
        menuInputGroup.infoTooltip = null;
      }
      menuInputGroup.displayInputTooltip = null;
      menuInputGroup.inputTooltip = null;
      menuInputGroup.inputType = null;
      menuInputGroup.label = null;
      menuInputGroup.menuId = null;
      menuInputGroup.type = null;
      if (menuInputGroup.select) {
        if (menuInputGroup.select.cleanUp) {
          menuInputGroup.select.cleanUp();
        }
        utils.deleteObjectMembers(menuInputGroup.select);
        menuInputGroup.select = null;
      }
      if (menuInputGroup.upDownBox) {
        if (menuInputGroup.upDownBox.cleanUp) {
          menuInputGroup.upDownBox.cleanUp();
        }
        utils.deleteObjectMembers(menuInputGroup.upDownBox);
        menuInputGroup.upDownBox = null;
      }
      if (menuInputGroup.permissionObv) {
        //utils.clearSubscriptions(menuInputGroup.permissionObv);
        //menuInputGroup.permissionObv(null);
        menuInputGroup.permissionObv = null;
      }
      if (initMenuInputGroup.cleanUp) {
        initMenuInputGroup.cleanUp();
      }
    }
    menuInputGroup.cleanUp = cleanUp;
    return menuInputGroup;
  }

  function MenuTooltipBase(initMenuTooltipBase) {
    var menuTooltipBase = this;
    menuTooltipBase.id = initMenuTooltipBase.id;
    menuTooltipBase.options =
      typeof initMenuTooltipBase.options === "object"
        ? initMenuTooltipBase.options
        : null;
    menuTooltipBase.isDisabled = shallowRef(
      typeof initMenuTooltipBase.isDisabled === "boolean"
        ? initMenuTooltipBase.isDisabled
        : false
    );

    menuTooltipBase.createTooltip = function () {
      var options = {
        trigger: "manual",
        closeable: true,
        animation: "pop",
      };
      var optionsToUse =
        initMenuTooltipBase.options === null
          ? initMenuTooltipBase.options
          : options;
      $("#" + this.id).webuiPopover(optionsToUse);
    };

    menuTooltipBase.showPopover = function () {
      if (!this.isDisabled.value) {
        var options = {
          trigger: "manual",
          closeable: true,
          animation: "pop",
        };
        var optionsToUse = this.options !== null ? this.options : options;
        $("#" + this.id).webuiPopover(optionsToUse);
        $("#" + this.id).webuiPopover("show");
      }
    };

    menuTooltipBase.cleanUp = function () {
      menuTooltipBase.id = null;
      menuTooltipBase.options = null;
      if (menuTooltipBase.isDisabled) {
        utils.clearSubscriptions(menuTooltipBase.isDisabled);
        menuTooltipBase.isDisabled = null;
      }
      utils.deleteObjectMembers(initMenuTooltipBase);
    };
  }

  function MenuTooltipInfo(initMenuTooltipInfo) {
    MenuTooltipBase.call(this, initMenuTooltipInfo);
    var menuTooltipInfo = this;
    menuTooltipInfo.tooltipMessage = initMenuTooltipInfo.tooltipMessage;
    menuTooltipInfo.permissionObv = initMenuTooltipInfo.permissionObv;
    menuTooltipInfo.permissionFunction = initMenuTooltipInfo.permissionFunction;
  }

  function MenuTooltipInput(initMenuTooltipInput) {
    MenuTooltipBase.call(this, initMenuTooltipInput);
    var menuTooltipInput = this;
    menuTooltipInput.permissionObv = initMenuTooltipInput.permissionObv;
    menuTooltipInput.permissionFunction =
      initMenuTooltipInput.permissionFunction;

    menuTooltipInput.showPopover = function () {
      if (!this.permissionObv.value.readOnly) {
        var options = {
          trigger: "manual",
          closeable: true,
          animation: "pop",
        };
        var optionsToUse = this.options !== null ? this.options : options;
        $("#" + this.id).webuiPopover(optionsToUse);
        $("#" + this.id).webuiPopover("show");
      }
    };
  }

  /**
   *
   * @param {Object} initMenuTable - Table to be included as an item in a MenuStep
   */
  function MenuTable(initMenuTable) {
    MenuItemBase.call(this, initMenuTable);
    var menuTable = this;
    menuTable.type = config.ITEM_TYPE.MENU_TABLE;
    menuTable.permissionFunction = initMenuTable.permissionFunction;
    menuTable.permissionObv = initMenuTable.permissionObv;
    menuTable.menuPath = initMenuTable.menuPath + ".TABLE";

    construct(initMenuTable);

    //#region Public functions
    menuTable.createJsElements = function () {
      this.rows.forEach(function (row) {
        row.createJsElements();
      });
    };
    //#endregion Public functions

    //#region Private functions
    function construct(initMenuTable) {
      if (initMenuTable.includeHeader === true) {
        menuTable.includeHeader = true;
        menuTable.header = new MenuTableHeader(initMenuTable.headerRow);
      } else {
        menuTable.includeHeader = false;
        menuTable.header = null;
      }

      menuTable.rows = [];
      initMenuTable.rows.forEach(function (row, index) {
        row.menuPath = menuTable.menuPath + ".TABLEROW-" + index;
        menuTable.rows.push(new MenuTableRow(row));
      });
    }
    //#endregion Private functions
  }

  /**
   *
   * @param {Object} initMenuTableRow - Row to be included in MenuTable
   */
  function MenuTableRow(initMenuTableRow) {
    MenuItemBase.call(this, initMenuTableRow);
    var menuTableRow = this;
    menuTableRow.type = config.ITEM_TYPE.MENU_TABLE_ROW;

    construct(initMenuTableRow);

    //#region Public functions
    menuTableRow.createJsElements = function () {
      this.cells.forEach(function (cell) {
        cell.createJsElements();
      });
    };
    //#endregion Public functions

    //#region Private functions
    function construct(initMenuTableRow) {
      menuTableRow.cells = [];
      initMenuTableRow.cells.forEach(function (cell, index) {
        cell.menuPath = menuTableRow.menuPath + ".TABLECELL-" + index;
        menuTableRow.cells.push(new MenuTableCell(cell));
      });
      menuTableRow.useCellObservableForValue =
        typeof initMenuTableRow.useCellObservableForValue === "boolean"
          ? initMenuTableRow.useCellObservablesForValue
          : false;
    }
    //#endregion Private functions
  }

  /**
   *
   * @param {Object} initMenuTableHeader - Header row to be included in MenuTable
   */
  function MenuTableHeader(initMenuTableHeader) {
    MenuTableRow.call(this, initMenuTableHeader);
    var menuTableHeader = this;
  }

  /**
   *
   * @param {Object} initMenuTableCell - Cell to be included in MenuTableRow/MenuTableHeader
   */
  function MenuTableCell(initMenuTableCell) {
    MenuItemBase.call(this, initMenuTableCell);
    var menuTableCell = this;
    menuTableCell.type = config.ITEM_TYPE.MENU_TABLE_CELL;
    menuTableCell.cellType = initMenuTableCell.cellType;

    //#region Public functions
    menuTableCell.createJsElements = function () {
      this.createJsElementsFunction();
    };
    //#endregion Public functions

    //#region Private functions
    function construct(initMenuTableCell) {
      menuTableCell.cellType = initMenuTableCell.cellType;
      menuTableCell.createJsElementsFunction = function () {};
      menuTableCell.customCssForTd =
        typeof initMenuTableCell.customCssForTd === "string"
          ? initMenuTableCell.customCssForTd
          : "";

      if (menuTableCell.cellType === config.MENU_TABLE_CELL_TYPES.INPUT) {
        menuTableCell.cellInputType = initMenuTableCell.cellObject.inputType;
        initMenuTableCell.cellObject.menuPath =
          initMenuTableCell.menuPath + ".CELL";

        switch (menuTableCell.cellInputType) {
          case config.INPUT_TYPE.SELECT:
            menuTableCell.cellObject = new MenuSelect(
              initMenuTableCell.cellObject
            );
            menuTableCell.createJsElementsFunction = function () {
              this.cellObject.createSelectMenu();
            };
            break;
          case config.INPUT_TYPE.UPDOWNBOX:
            menuTableCell.cellObject = new MenuValue(
              initMenuTableCell.cellObject
            );
            break;
          case config.INPUT_TYPE.INPUT_GROUP:
            menuTableCell.cellObject = new MenuInputGroup(
              initMenuTableCell.cellObject
            );
            menuTableCell.createJsElementsFunction = function () {
              this.cellObject.select.createSelectMenu();
            };
            break;
          default:
            break;
        }
      } else {
        menuTableCell.cellObject = initMenuTableCell.cellObject;
      }
    }
    //#endregion Private functions
    construct(initMenuTableCell);
  }

  /**
   *
   * @param {object} initMenuRadioInput -
   */
  function MenuRadioInput(initMenuRadioInput) {
    MenuItemBase.call(this, initMenuRadioInput);
    var menuRadioInput = this;
    menuRadioInput.type = config.ITEM_TYPE.VALUE;
    menuRadioInput.inputType = config.INPUT_TYPE.RADIO;

    //#region Public functions
    menuRadioInput.showPopover = function () {
      if (!this.permissionObv.value.readOnly) {
        var options = {
          trigger: "manual",
          closeable: true,
          animation: "pop",
        };
        var optionsToUse = this.options !== null ? this.options : options;
        $("#" + this.tooltipId).webuiPopover(optionsToUse);
        $("#" + this.tooltipId).webuiPopover("show");
      }
    };
    //#endregion Public functions

    //#region Private functions
    function construct(init) {
      try {
        menuRadioInput.label =
          typeof init.label === "string" ? init.label : "Label Not Set";
        menuRadioInput.value = init.value;
        menuRadioInput.optionsArray = [];
        if (Array.isArray(init.array)) {
          init.array.forEach(function (option) {
            menuRadioInput.optionsArray.push(new MenuRadioInputOption(option));
          });
        }
        menuRadioInput.permissionFunction = init.permissionFunction;
        menuRadioInput.permissionObv = init.permissionObv;
        menuRadioInput.tooltipMessage = init.tooltipMessage;
        menuRadioInput.tooltipId = init.id + "-tooltipId";
        menuRadioInput.options =
          init.options !== null && init.options !== undefined
            ? init.options
            : null;
        menuRadioInput.alignmentClass =
          init.alignment === config.RADIO_INPUT_ALIGNMENT_OPTIONS.HORIZONTAL
            ? "radio-buttons-horizontal"
            : "radio-buttons-vertical";
      } catch (e) {
        throw "Error raised in MenuRadioInput construct function";
      }
    }

    /**
     *
     * @param {object} initMenuRadioInputOption - Test
     * @returns {object} - Returns option object to be used in MenuRadioInput
     */
    function MenuRadioInputOption(initMenuRadioInputOption) {
      var menuRadioInputOption = this;

      function construct(init) {
        try {
          menuRadioInputOption.label = init.label;
          menuRadioInputOption.value = init.value;
          menuRadioInputOption.customCss =
            typeof init.customCss === "string" ? init.customCss : "";
          menuRadioInputOption.isDisabled =
            typeof init.isDisabled === "boolean" ? init.isDisabled : false;
          menuRadioInputOption.tooltipText =
            typeof init.tooltipText === "string" ? init.tooltipText : "";
          menuRadioInputOption.displayUpgradeIcon =
            typeof init.displayUpgradeIcon === "boolean"
              ? init.displayUpgradeIcon
              : false;
        } catch (e) {
          throw "Error raised in MenuRadioInputOption construct function";
        }
      }

      construct(initMenuRadioInputOption);
    }
    //#endregion Private functions
    construct(initMenuRadioInput);
  }

  function traverseMenuStepMenuTree(activeMenuStep, operationFlag) {
    doIt(activeMenuStep());

    function doIt(item) {
      if (item.menuStepItems) {
        item.menuStepItems.value.forEach(function (subItem) {
          if (subItem.permissionFunction) {
            //doOperation(subItem);
          }
          doIt(subItem);
        });
      } else if (item.items) {
        let arrayToUse = Array.isArray(item.items)
          ? item.items
          : item.items.value;
        arrayToUse.forEach(function (subItem) {
          if (subItem.permissionFunction) {
            //doOperation(subItem);
          }
          doIt(subItem);
        });
      } else if (item.menuTabs) {
        item.menuTabs.value.forEach(function (tab) {
          if (tab.permissionFunction) {
            //doOperation(tab);
          }
          doIt(tab);
        });
      } else if (item.tabs) {
        item.tabs.forEach(function (tab) {
          if (tab.permissionFunction) {
            //doOperation(tab);
          }
          doIt(tab);
        });
      } else if (item.nestedStep) {
        //ko.utils.arrayForEach(item.menuTabs(), function (tab) {
        //    if (tab.permissionFunction) {
        //        //doOperation(tab);
        //    }
        if (item.permissionFunction) {
          doOperation(item);
        }
        doIt(item.nestedStep);
        //});
      } else if (item.selectedItems) {
        item.selectedItems.value.forEach(function (subItem) {
          if (subItem.permissionFunction) {
            //doOperation(subItem);
          }
          doIt(subItem);
        });
      } else {
        doOperation(item);
      }
      function doOperation(item) {
        switch (operationFlag) {
          case "REFRESH_PERMISSION":
            refreshPermissionObv(item);
            break;
        }
      }
    }
  }

  function refreshPermissionObv(item) {
    // offerManager.getUndoHandler().disableUndoOpCreation();
    
    if (item.permissionFunction) {
      item.permissionObv.value = item.permissionFunction(item.path || "");
    }
    if (item.type === config.ITEM_TYPE.INPUT_GROUP) {
      if (item.refreshPermissions && typeof item.refreshPermissions === "function") {
        item.refreshPermissions(item.path || "");
      }
    }
    
    // nextTick(function () {
    //   offerManager.getUndoHandler().enableUndoOpCreation();
    // });
  }

  function MenuAccessoryGroup(
    initAccessoryGroup,
    activateBreadcrumb,
    breadcrumbLabel,
    parentMenuStep
  ) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initAccessoryGroup.label;
    self.type = config.ITEM_TYPE.ACCESSORYGROUP;
    self.items = [];

    var accessoryGroup = {
      menuId: self.menuId,
      label: self.label,
      type: self.type,
      items: self.items,
    };

    setUpItems(activateBreadcrumb, breadcrumbLabel, parentMenuStep);

    function setUpItems(activateBreadcrumb, breadcrumbLabel, parentMenuStep) {
      initAccessoryGroup.items.forEach(function (accessoryItem) {
        accessoryItem.getHeader = function () {
          return accessoryItem.equipmentObject.getDescription();
        };
        accessoryItem.getDetail = function () {
          return accessoryItem.equipmentObject.getSource();
        };
        accessoryItem.getSaveTooltip = function () {
          return accessoryItem.equipmentObject.getAccessoryTypeSaveTooltip();
        };
        accessoryItem.getRemoveTooltip = function () {
          return accessoryItem.equipmentObject.getAccessoryTypeRemoveTooltip();
        };
        accessoryItem.doNotDelete = true;
        accessoryItem.allowCopy = false;
        accessoryItem.type = config.ITEM_TYPE.MENUCARD;
        self.items.push(
          new MenuCard(
            accessoryItem,
            activateBreadcrumb,
            breadcrumbLabel,
            parentMenuStep
          )
        );
      });
    }

    return accessoryGroup;
  }

  function MenuAccessoryItem(initAccessoryItem) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initAccessoryItem.label;
    self.type = config.ITEM_TYPE.ACCESSORYITEM;
    self.path = initAccessoryItem.path;

    self.equipmentItem = initAccessoryItem.equipmentItem;
    self.equipmentItemFunction = initAccessoryItem.equipmentItemFunction;
    self.equipmentObject = initAccessoryItem.equipmentObject;
    self.closeMenuFunction = initAccessoryItem.closeMenuFunction;
    self.graphicsCallbackFunction = initAccessoryItem.graphicsCallbackFunction;
    self.graphicsCallbackValue = initAccessoryItem.graphicsCallbackValue;

    var accessoryItem = {
      menuId: self.menuId,
      label: self.label,
      type: self.type,
      equipmentItem: self.equipmentItem,
      equipmentObject: self.equipmentObject,
      addEquipmentItem: addEquipmentItem,
    };

    return accessoryItem;

    function addEquipmentItem() {
      self.equipmentItemFunction(self.equipmentObject, self.path);
      if (
        self.graphicsCallbackFunction !== undefined &&
        self.graphicsCallbackValue !== undefined
      ) {
        self.graphicsCallbackFunction(self.graphicsCallbackValue);
      }
      self.closeMenuFunction();
    }
  }

  function MenuDataGrid(initMenuDataGrid) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initMenuDataGrid.label;

    self.columns = shallowRef([]);
    self.values = shallowRef([]);
    self.rows = shallowRef([]);
    self.buttons = shallowRef([]);
    self.type = config.INPUT_TYPE.DATAGRID;
    self.inputType = config.INPUT_TYPE.DATAGRID;
    self.items = initMenuDataGrid.items;
    self.activeDataGridValue = shallowRef(undefined);

    setUpColumns(initMenuDataGrid.columns);
    setUpRows(initMenuDataGrid.items);
    setUpButtons(initMenuDataGrid.buttons);

    self.permissionFunction = initMenuDataGrid.permissionFunction;

    var menuDataGrid = {
      menuId: self.menuId,
      label: self.label,
      columns: self.columns,
      rows: self.rows,
      type: self.type,
      inputType: self.inputType,
      activeDataGridValue: self.activeDataGridValue,
      activateRow: activateRow,
      permissionFunction: self.permissionFunction,
      buttons: self.buttons,
    };

    function setUpColumns(columns) {
      columns.forEach(function (column) {
        self.columns.push(column);
      });
    }

    function setUpRows(values) {
      var temp = [];
      var id = 0;
      values.forEach(function (value) {
        temp.push(createDataGridValue(value, id));
        id++;
      });

      self.values.value = temp;

      // now setup rows
      var rows = [],
        rowValues = [],
        row = {};
      self.values.value.forEach(function (menuDataGridValue) {
        rowValues = [];
        //need to add id for row to filter on to set the active row
        row = {
          isActive: shallowRef(false),
          menuDataGridValue: menuDataGridValue,
        };
        menuDataGridValue.menuTabGroups.forEach(function (menuTabGroup) {
          menuTabGroup.menuTabs.value.forEach(function (menuTab) {
            menuTab.items.value.forEach(function (item) {
              if (item.displayInDataGrid === true) {
                rowValues.push(item);
              }
            });
          });
        });
        row.rowValues = rowValues;
        //row.activateRow = activateRow;
        rows.push(row);
      });

      self.rows(rows);
    }

    function setUpButtons(values) {
      var temp = [];

      if (values !== undefined) {
        values.forEach(function (value) {
          if (value.type === "add") {
            value.bindFunction = addDataGridValue;
          } else {
            value.bindFunction = deleteDataGridValue;
          }
          temp.push(value);
        });
      }
      self.buttons(temp);
    }

    function createDataGridValue(value, id) {
      var temp;
      temp = new MenuDataGridValue(value, id);
      return temp;
    }

    function markAllRowsAsInactive() {
      self.rows.value.forEach(function (menuDataGridValue) {
        menuDataGridValue.isActive(false);
      });
      self.activeDataGridValue(undefined);
    }

    function activateRow(row) {
      markAllRowsAsInactive();
      // Update CSS on row in table
      if (row.isActive()) {
        deactivateRow(row);
      } else {
        row.isActive(true);
        self.activeDataGridValue(row.menuDataGridValue);
        $(".accordion").accordion({
          heightStyle: "content",
          icons: {
            header: "accordion-icon accordion-icon-closed",
            activeHeader: "accordion-icon accordion-icon-open",
          },
          collapsible: true,
        });

        var itemsArray = [];

        self.activeDataGridValue.value.menuTabGroups.value(function (item) {
          if (item.inputType === config.INPUT_TYPE.MENUTABGROUP) {
            item.menuTabs.value.forEach(function (menuTab) {
              menuTab.items.value.forEach(function (menuTabItem) {
                if (menuTabItem.inputType === config.INPUT_TYPE.SELECT) {
                  itemsArray.push(menuTabItem);
                }
                if (menuTabItem.inputType === config.INPUT_TYPE.COMBOBOX) {
                  itemsArray.push(menuTabItem);
                }
                if (menuTabItem.inputType === config.ITEM_TYPE.INPUT_GROUP) {
                  itemsArray.push(menuTabItem);
                }
              });
            });
          }
        });
        if (itemsArray.length > 0) {
          itemsArray.forEach(function (select) {
            if (typeof select.createSelectMenu === "function") {
              select.createSelectMenu();
            }
            if (select.inputType === config.ITEM_TYPE.INPUT_GROUP) {
              select.createJsElements();
            }
          });
        }
      }
      // Set activeMenuValue

      // If it has change css and set activeMenuValue to undefined
    }

    function deactivateRow(row) {
      row.isActive(false);
      self.activeDataGridValue(undefined);
    }

    function addDataGridValue(buttonDetails) {
      // Update offer using offerManager
      var newDataGridValueObject = buttonDetails.offerFunction();
      if (newDataGridValueObject !== null) {
        self.items.push(newDataGridValueObject);
        // Add new datagridvalue from offerManager using function
        var newDataGridValue = createDataGridValue(newDataGridValueObject);
        setUpRows(self.items);
        // Activate row
        var newActiveRow = self.rows()[self.rows().length - 1];
        activateRow(newActiveRow);
      }
    }

    function deleteDataGridValue(buttonDetails) {
      // only enable button when activeDataGridValue is not undefined
      // possibly check that
      if (self.activeDataGridValue() !== undefined) {
        // Check that item can be deleted
        // Delete item from rows array
        removeDataGridValueFromRows(self.activeDataGridValue());
        // remove from offer/offerManager
        self.items = buttonDetails.offerFunction(self.activeDataGridValue().id);
        self.activeDataGridValue(undefined);
        setUpRows(self.items);
      }
    }

    function removeDataGridValueFromRows(dataGridValue) {
      // remove rows
      var newRowsArray = self.rows.value.filter(function (row) {
        return row.menuDataGridValue.id !== dataGridValue.id;
      });
      self.rows(newRowsArray);
      // remove from items too
      var newItemsArray = self.items.filter(function (item, index) {
        if (index === dataGridValue.id) {
          return false;
        } else {
          return true;
        }
      });
      self.items = newItemsArray;
    }

    return menuDataGrid;
  }

  function MenuDataGridValue(initMenuDataGridValue, id) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.id = id;
    self.columns = [];
    self.menuTabGroups = [];
    self.menuTabGroup = shallowRef();

    var menuDataGridValue = {
      menuId: self.menuId,
      columns: self.columns,
      id: self.id,
      menuTabGroups: self.menuTabGroups,
      menuTabGroup: self.menuTabGroup,
    };

    createObject(initMenuDataGridValue);

    function createObject(item) {
      switch (item.itemType) {
        case config.ITEM_TYPE.MENUTABGROUP:
          addMenuTabGroup(item);
          break;
        default:
          break;
      }
    }

    function addMenuTabGroup(tabGroup) {
      var menuTabGroup = new MenuTabGroup(tabGroup);
      self.menuTabGroups.push(menuTabGroup);
      self.menuTabGroup(menuTabGroup);

      if (tabGroup.displayType === "accordion") {
        self.displayAccordionOnStep = true;
        self.accordionHeightStyle =
          typeof tabGroup.heightStyle === "string"
            ? tabGroup.heightStyle
            : config.ACCORDION_HEIGHT_STYLE_OPTIONS.CONTENT;
      } else {
        self.displayTabsOnStep = true;
      }
    }

    return menuDataGridValue;
  }

  function MenuSelectionGroup(
    initSelectionGroup,
    activateBreadcrumb,
    breadcrumbLabel,
    parentMenuStep
  ) {
    var self = this;
    
    self.menuId = menuIdCounter++;
    self.buttonItem = {};
    self.listItems = shallowRef([]);
    self.type = config.ITEM_TYPE.SELECTIONGROUP;
    self.displayId = initSelectionGroup.displayId;
    self.displayButton = initSelectionGroup.displayButton;
    self.displayDropDownButton = initSelectionGroup.displayDropDownButton;
    self.dropDownOptions = initSelectionGroup.dropDownOptions;
    self.dropDownHasOptions = shallowRef(initSelectionGroup.dropDownHasOptions || false);
    self.removeItemCallback = typeof initSelectionGroup.removeItemCallback === 'function' ? initSelectionGroup.removeItemCallback : null;
    self.partialMenuId = parentMenuStep.partialMenuId;

    self.label = initSelectionGroup.label || "";
    self.displayLabel = initSelectionGroup.displayLabel || true;

    self.activateBreadcrumb = activateBreadcrumb;
    self.breadcrumbLabel = breadcrumbLabel;

    self.selectedItems = initSelectionGroup.selectedItems;
    self.selectedMenuItems = shallowRef([]);
    self.parentMenuStep = parentMenuStep;
    self.click = initSelectionGroup.click;
    self.path = initSelectionGroup.path;
    self.permissionObv = initSelectionGroup.permissionObv;
    self.permissionFunction = initSelectionGroup.permissionFunction;
    self.preventSelectionGroupItemActivationCallback =
      initSelectionGroup.preventSelectionGroupItemActivationCallback;
    self.customCss = initSelectionGroup.customCss || "";
    self.tabMenuPath = initSelectionGroup.tabMenuPath;
    let activateMenuCardOnMount = null,
      activateMenuTabFunction = null,
      addSliderToStepCallback = null;

    function getActivateMenuCardOnMount() {
      return activateMenuCardOnMount;
    }

    function setActivateMenuCardOnMount(newValue) {
      activateMenuCardOnMount = newValue;
    }

    function getActivateMenuTabFunction() {
      return activateMenuTabFunction;
    }

    function setActivateMenuTabFunction(newValue) {
      activateMenuTabFunction = newValue;
    }

    function registerAddSliderToStepCallback(cb) {
      addSliderToStepCallback = cb;
    }

    function getAddSliderToStepCallback() {
      return addSliderToStepCallback;
    }
    
    self.tooltipMessage = function () {
      if (
        initSelectionGroup.tooltipMessage === undefined ||
        typeof initSelectionGroup.tooltipMessage === "string"
      ) {
        return initSelectionGroup.tooltipMessage;
      }
      else if(isRef(initSelectionGroup.tooltipMessage)) {
        return initSelectionGroup.tooltipMessage.value;
      } else {
        return initSelectionGroup.tooltipMessage();
      }
    };
    self.tooltipId = self.menuId + "-id";
    self.showTooltipCallback = initSelectionGroup.showTooltipCallback;

    // Temporary - prevent user from adding more than 1 body
    if (initSelectionGroup.disableClick !== undefined) {
      self.disableClick = initSelectionGroup.disableClick;
    } else {
      self.disableClick = false;
    }
    // End Temporary - prevent user from adding more than 1 body

    self.canAddItemUsingSelectionWizard =
      initSelectionGroup.selectionWizardObject !== undefined ? true : false;
    self.displaySelectionWizard = shallowRef(false);

    setUpSelectionGroup();
    setUpSelectionGroupItems(
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    );
    setUpSelectionWizard(initSelectionGroup);
    createInfoTooltip();

    // watch(() => self.selectedMenuItems, (item) => {
    //   TScMessenger.writeDebugMessage("change");
    // }, { deep: true });
    // self.selectedItemSubscriptionRef = watch(() => self.selectedItems, function (newValue, oldValue) {
    const selectedItemsWatch = watch(self.selectedItems, (newValue, oldValue) => {      
      TScMessenger.writeDebugMessage('In self.selectedItemSubscriptionRef');   
      if(self !== null) {
        if(newValue !== undefined && Array.isArray(newValue)) {
          var temp = [];
          self.selectedMenuItems.value.forEach(function (item) {
            temp.push(item);
          });
          newValue.forEach(function(item){
            var check = temp.findIndex(function(mi){
              return mi.id.value === item.id.value && mi.path === item.path;
            });
            if(check < 0) {
              // Check if card also exists on temp
              var newSelectionGroupItem = addCard(item);
              temp.push(newSelectionGroupItem);
              var test = temp.filter(function (item) {
                return item.activateSelectionGroupItem !== undefined;
              });
              // self.selectedMenuItems.value = test;
              // triggerRef(self.selectedMenuItems);
              globals.clearShallowRefArrayAndAddItems(self.selectedMenuItems, test);
              if (setupComplete) {
                if (
                  self.preventSelectionGroupItemActivationCallback ===
                    undefined ||
                  !self.preventSelectionGroupItemActivationCallback()
                ) {
                  //newSelectionGroupItem.activateSelectionGroupItem();
                }
              }
            }
          });
          if(newValue.length < oldValue.length) {
            nextTick(function(){
              if(typeof self.removeItemCallback === 'function') {
                // if(self.dropDownHasOptions.value === false) {
                //   self.dropDownHasOptions.value = true;
                // }
                var cbResult = self.removeItemCallback(self.path);
                if(self.dropDownHasOptions.value !== cbResult.dropDownHasOptions) {
                  self.dropDownHasOptions.value = cbResult.dropDownHasOptions;
                  
                  // globals.clearShallowRefArrayAndAddItems(self.dropDownOptions, cbResult.dropDownOptions);
                }
                if(typeof self.disableClick === "function") {
                  self.disableClick();
                }
              }
            });
          }
        }
      }
        
        // changes.forEach(function (change) {
        //   if (change.status === "added" && doingDelete === false) {
        //     var temp = [];
        //     self.selectedMenuItems.value.forEach(function (item) {
        //       temp.push(item);
        //     });
        //     var newSelectionGroupItem = addCard(change.value);
        //     temp.push(newSelectionGroupItem);
        //     var test = temp.filter(function (item) {
        //       return item.activateSelectionGroupItem !== undefined;
        //     });
        //     self.selectedMenuItems.value = [];
        //     self.selectedMenuItems.value = test;
        //     if (setupComplete) {
        //       if (
        //         self.preventSelectionGroupItemActivationCallback ===
        //           undefined ||
        //         !self.preventSelectionGroupItemActivationCallback()
        //       ) {
        //         //newSelectionGroupItem.activateSelectionGroupItem();
        //       }
        //     }
        //   }
        // });
      // }, { deep: true }
      }
    );

    self.activeMenuCardStep = ref(null);

    // if (self.displayDropDownButton === true) {
    //   if (ko.isObservable(self.dropDownOptions)) {
    //     self.dropDownOptions().forEach(function (option) {
    //       if (option.displayIconBesideText !== true) {
    //         option.displayIconBesideText = false;
    //         option.iconToDisplayClass = "";
    //       }
    //     });
    //   } else {
    //     if (Array.isArray(self.dropDownOptions)) {
    //       self.dropDownOptions.forEach(function (option) {
    //         if (option.displayIconBesideText !== true) {
    //           option.displayIconBesideText = false;
    //           option.iconToDisplayClass = "";
    //         }
    //       });
    //     }
    //   }
    // }

    var selectionGroup = {
      menuId: self.menuId,
      displayId: self.displayId,
      label: self.label,
      displayLabel: self.displayLabel,
      type: self.type,
      buttonItem: self.buttonItem,
      listItems: self.listItems,
      selectedItems: self.selectedItems,
      selectedMenuItems: self.selectedMenuItems,
      click: click,
      activateFromBreadcrumb: activateFromBreadcrumb,
      deleteSelectionGroupItem: deleteSelectionGroupItem,
      copySelectionGroupItem: copySelectionGroupItem,
      saveSelectionGroupItem: saveSelectionGroupItem,
      addCard: addCard,
      permissionFunction: self.permissionFunction, //function () { return config.defaultMenuPermissionObject; },
      setUpSelectionGroupItems: setUpSelectionGroupItems,
      //areFactoryFittedFuelTanks: self.areFactoryFittedFuelTanks,
      path: self.path,
      permissionObv: self.permissionObv,
      tooltipMessage: self.tooltipMessage,
      tooltipId: self.tooltipId,
      displayButton: self.displayButton,
      displayDropDownButton: self.displayDropDownButton,
      dropDownOptions: self.dropDownOptions,
      cleanUp: cleanUp,
      setActiveMenuCard: setActiveMenuCard,
      activeMenuCardStep: self.activeMenuCardStep,
      displaySelectionWizard: self.displaySelectionWizard,
      canAddItemUsingSelectionWizard: self.canAddItemUsingSelectionWizard,
      getMenuGroupSelectionWizard: getMenuGroupSelectionWizard,
      resetSelectionWizard: resetSelectionWizard,
      customCss: self.customCss,
      disableClick: self.disableClick,
      deactivateAnyActiveMenuCards: deactivateAnyActiveMenuCards,
      dropDownHasOptions: self.dropDownHasOptions,
      triggerChangeToSelectedItems: triggerChangeToSelectedItems,
      updateSlider: updateSlider,
      destroySlider: destroySlider,
      getActivateMenuCardOnMount: getActivateMenuCardOnMount,
      setActivateMenuCardOnMount: setActivateMenuCardOnMount,
      getActivateMenuTabFunction: getActivateMenuTabFunction,
      setActivateMenuTabFunction: setActivateMenuTabFunction,
      registerAddSliderToStepCallback: registerAddSliderToStepCallback,
      getAddSliderToStepCallback: getAddSliderToStepCallback
    };

    function setUpSelectionGroup() {
      initSelectionGroup.items.forEach(function (item) {
        switch (item.itemType) {
          case config.ITEM_TYPE.TEXT:
            setUpButtonItem(item);
            break;
          case config.ITEM_TYPE.SELECTIONGROUPITEM:
            setUpSelectionGroupItems(
              item,
              activateBreadcrumb,
              breadcrumbLabel,
              parentMenuStep
            );
            break;
          default:
            break;
        }
      });
    }

    function setUpSelectionWizard(initSelectionGroup) {
      if (initSelectionGroup.selectionWizardObject !== undefined) {
        self.canAddItemUsingSelectionWizard = true;
        self.menuGroupSelectionWizard = new MenuSelectionWizard(
          initSelectionGroup.selectionWizardObject,
          self.partialMenuId,
          selectionWizardClick
        );

        if (self.selectedMenuItems.value.length === 0) {
          activateSelectionWizard();
        }
      }
    }

    function getMenuGroupSelectionWizard() {
      if (self) {
        return self.menuGroupSelectionWizard;
      } else {
        return undefined;
      }
    }

    function setUpSelectionGroupItems(
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      var temp = [],
        tempObj;
      initSelectionGroup.selectedItems.value.forEach(function (item) {
        tempObj =
          item.type === config.ITEM_TYPE.MENUCARD
            ? item
            : createSelectionGroupItem(
                item,
                activateBreadcrumb,
                breadcrumbLabel,
                parentMenuStep
              );
        temp.push(tempObj);
        //parentMenuStep.menuStepItemsArray.push(tempObj);
        //parentMenuStep.menuStepItems().push(tempObj);
        // Temporary - prevent user from adding more than 1 body
        if (item.path === "VEHICLE.Body") {
          self.disableClick = true;
        }
        // if (
        //   item.path.includes("VEHICLE.FUEL_TANKS") &&
        //   !offerManager.isNotStandardVehicle()
        // ) {
        //   self.disableClick = true;
        // }
      });
      self.selectedMenuItems.value = temp;
      triggerRef(self.selectedMenuItems);
    }

    function setUpButtonItem(buttonItem) {
      self.buttonItem = new MenuItem(
        buttonItem,
        self.activateBreadcrumb,
        self.breadcrumbLabel,
        undefined,
        true
      );
    }

    function createSelectionGroupItem(
      item,
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      item.type = config.ITEM_TYPE.SELECTIONGROUPITEM;
      return new MenuCard(
        item,
        activateBreadcrumb,
        breadcrumbLabel,
        parentMenuStep,
        getSelectionGroup
      );
    }

    function click(optionalSource) {
      // Temporary - prevent user from adding more than 1 body
      var disableClick =
        typeof self.disableClick === "function"
          ? self.disableClick()
          : self.disableClick;

      if (disableClick === false) {
        if (self.click !== undefined) {
          if (self.canAddItemUsingSelectionWizard) {
            activateSelectionWizardWithTransition();
          } else {
            if (optionalSource) {
              offerManager.checkObjectRules(self.path, optionalSource, doClick);
            } else {
              doClick();
            }
          }
        } else {
          self.buttonItem.click();
        }
      } else {
        if (self.tooltipMessage !== undefined) {
          showPopover();
        }
      }

      function doClick() {
        self.selectedMenuItems.value = [];
        self.click(self.path, optionalSource);
        addSliderToStep(
          self.displayId,
          null,
          self.tooltipId,
          self.disableClick,
          getAddSliderToStepCallback()
        );
      }
    }

    function toggleSelectionWizard() {
      if (self.displaySelectionWizard()) {
        deactivateSelectionWizard();
      } else {
        activateSelectionWizard();
      }
    }

    function activateSelectionWizardWithTransition() {
      // deactivate menu card
      self.selectedMenuItems.value.forEach(function (menuCard) {
        if (menuCard.isActive.value) {
          menuCard.deactivateMenuCard();
          self.activeMenuCardStep.value = null;
        }
      });
      // Change visibility on element

      // Change the observable
      activateSelectionWizard();

      if (self.selectedMenuItems.value.length > 0) {
        updateDisplayBackButton(true);
      }

      // Change visibility on element
    }

    function activateSelectionWizard() {
      self.displaySelectionWizard.value = true;

      if (getActiveMenuStepObservable() !== undefined) {
        self.menuGroupSelectionWizard.dataToRender.value.resetSelectionWizard();
        updateTitle(self.menuGroupSelectionWizard.label);
        if (getMenu().menuDocked.value) {
          nextTick(function(){
            globals.repositionMenu(getMenu().menuId);
          });
        }
      }
    }

    function deactivateSelectionWizard() {
      self.displaySelectionWizard.value = false;
    }

    function resetSelectionWizard() {
      self.menuGroupSelectionWizard.dataToRender.value.resetSelectionWizard();
      deactivateSelectionWizard();
    }

    function activateFromBreadcrumb() {
      if (self.buttonItem.activateFromBreadcrumb !== undefined) {
        self.buttonItem.activateFromBreadcrumb();
      } else {
        //self.parentMenuStep.activeMenuStepObv();
      }
    }

    function deleteSelectionGroupItem(selectionGroupItem, skipRuleCheck) {
      var deleteSelecetionGroupItemDfd = $.Deferred();

      if (skipRuleCheck === undefined || typeof skipRuleCheck !== "boolean") {
        offerManager.checkDeleteRules(
          selectionGroupItem.path,
          function () {
            completeSelectionGroupItemDelete(
              selectionGroupItem,
              deleteSelecetionGroupItemDfd
            );
          },
          deleteSelecetionGroupItemDfd
        );
      } else {
        completeSelectionGroupItemDelete(
          selectionGroupItem,
          deleteSelecetionGroupItemDfd
        );
      }
      return deleteSelecetionGroupItemDfd.promise();
    }

    function completeSelectionGroupItemDelete(
      selectionGroupItem,
      deleteSelecetionGroupItemDfd
    ) {
      var doNotDeleteCardFromSelectionGroup = false;
      if (
        selectionGroupItem.path.indexOf(config.OBJECT_TYPES.ACCESSORY) !== -1 ||
        selectionGroupItem.path.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !==
          -1 ||
        selectionGroupItem.path === config.CHASSIS_OBJECT_TYPES.TRAILER1 ||
        selectionGroupItem.path === config.CHASSIS_OBJECT_TYPES.TRAILER2
      ) {
        var accessory = offerManager
          .getRigOps()
          .getAccessoryFromPath(selectionGroupItem.path);
        var undoPath = selectionGroupItem.path;
        if (
          selectionGroupItem.path.indexOf(config.ACCESSORY_TYPES.OTHER) !==
            -1 ||
          selectionGroupItem.path.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1
        ) {
          var pathParts = selectionGroupItem.path.split(".");
          var idPart = pathParts[pathParts.length - 1];
          undoPath = undoPath.replace("." + idPart, "");
        }
        offerManager.getUndoHandler().newRemoveRigObjectOp(undoPath, accessory);
      } else if (
        selectionGroupItem.path.indexOf(config.OBJECT_TYPES.AXLES) !== -1
      ) {
        var axle = offerManager
          .getRigOps()
          .getAxleFromPath(selectionGroupItem.path);

        pathParts = selectionGroupItem.path.split(".");
        idPart = pathParts[pathParts.length - 1];
        undoPath = selectionGroupItem.path.replace("." + idPart, "");

        offerManager.getUndoHandler().newRemoveAxleOp(undoPath, axle);
      } else if (
        selectionGroupItem.path.indexOf(config.OBJECT_TYPES.AXLE_GROUPS) !== -1
      ) {
        var chassisObjectToUse = offerManager
            .getRigOps()
            .getChassisObjectFromPath(selectionGroupItem.path),
          axleGroup = offerManager
            .getRigOps()
            .getAxleGroupFromPath(selectionGroupItem.path),
          axleGroupAxle;

        axleGroup.getRelatedAxleIds().forEach(function (axleId) {
          var tempAxle = chassisObjectToUse
            .getAxlesHolder()
            .getAxleById(axleId);
          if (tempAxle.isAdded()) {
            axleGroupAxle = tempAxle;
          }
        });
        pathParts = selectionGroupItem.path.split(".");
        idPart = pathParts[pathParts.length - 1];
        undoPath = selectionGroupItem.path
          .replace("." + idPart, "")
          .replace(config.OBJECT_TYPES.AXLE_GROUPS, config.OBJECT_TYPES.AXLES);
        var axleGroupAxlePathToDelete = undoPath + "." + axleGroupAxle.getId(),
          axleGroupAxleIdPathToDelete = axleGroupAxle.getId();
        offerManager.getUndoHandler().newRemoveAxleOp(undoPath, axleGroupAxle);
        doNotDeleteCardFromSelectionGroup = true;
      }

      // offerManager.showShellSpinner()(true);
      globals.showSpinner();
      //setTimeout(function () {
      doingDelete = true;
      var idOfObjectToDelete = selectionGroupItem.id(),
        menuPathToUse =
          self.tabMenuPath !== undefined
            ? self.tabMenuPath.split(".")
            : undefined;

      if (doNotDeleteCardFromSelectionGroup === false) {
        // destroySlider();
        // $('#' + self.displayId + '-slider div.menu-div-item.menu-equipment-card').remove();
        let copyOfSelectedMenuItems = self.selectedMenuItems.value.map((x) => x);
        let copyOfSelectedItems = self.selectedItems.value.map((x) => x);
        
        // var indexOfItemToDelete = self
        //   .selectedMenuItems.value
        //   .findIndex(function (smi) {
        //     return smi.id() === idOfObjectToDelete;
        //   });
        // var filteredArray = self.selectedMenuItems.value.filter(function (
        //   selectedMenuItem
        // ) {
        //   return selectionGroupItem.id() !== selectedMenuItem.id();
        // });
        // var filteredSelectedItemsArray = self.selectedItems.value.filter(
        //   function (selectedItem) {
        //     return selectionGroupItem.id() !== selectedItem.id();
        //   }
        // );
        var indexOfItemToDelete = copyOfSelectedMenuItems
          .findIndex(function (smi) {
            return smi.id() === idOfObjectToDelete;
          });
        var filteredArray = copyOfSelectedMenuItems.filter(function (
          selectedMenuItem
        ) {
          return selectionGroupItem.id() !== selectedMenuItem.id();
        });
        var filteredSelectedItemsArray = copyOfSelectedItems.filter(
          function (selectedItem) {
            return selectionGroupItem.id() !== selectedItem.id();
          }
        );

        if (self.activeMenuCardStep.value !== null) {
          self.activeMenuCardStep.value = null;
        }

        // removeMenuCardFromSlider(indexOfItemToDelete);
        self.selectedMenuItems.value = [];
        self.selectedItems.value = [];
        // triggerRef(self.selectedMenuItems);
        
        // $('#' + self.displayId + '-slider div').remove();
        // Delete internal slider HTML
        // $('#' + self.displayId + '-slider .slick-list.draggable').remove();
        // self.selectedMenuItems.value = [];
        // self.selectedItems.value = [];
        globals.addItemToShallowRefArray(self.selectedMenuItems, filteredArray);
        globals.addItemToShallowRefArray(self.selectedItems, filteredSelectedItemsArray);
        triggerRef(self.selectedMenuItems);
        
        var activeMenuStepObv = getActiveMenuStepObservable();
        if (activeMenuStepObv.menuStepItems) {
          var filteredMenuStepItems =
            activeMenuStepObv.menuStepItems.value.filter(function (
              menuStepItem
            ) {
              return selectionGroupItem.menuId !== menuStepItem.menuId;
            });
          activeMenuStepObv.menuStepItems.value = filteredMenuStepItems;
        }
        offerManager.deleteObject(selectionGroupItem.path, idOfObjectToDelete);
        traverseMenuStepMenuTree(
          getActiveMenuStepObservable,
          "REFRESH_PERMISSION"
        );
      } else {
        if (
          selectionGroupItem.path.indexOf(config.OBJECT_TYPES.AXLE_GROUPS) !==
          -1
        ) {
          offerManager.deleteObject(
            axleGroupAxlePathToDelete,
            axleGroupAxleIdPathToDelete
          );
          selectionGroupItem.doNotDelete(true);
        } else {
          offerManager.deleteObject(
            selectionGroupItem.path,
            idOfObjectToDelete
          );
        }
      }

      doingDelete = false;

      if (self.selectedMenuItems.value.length > 0) {
        triggerChangeToSelectedItems()
        if (indexOfItemToDelete > self.selectedMenuItems.value.length - 1) {
          indexOfItemToDelete = self.selectedMenuItems.value.length - 1;
        }
        // addSliderToStep(self.displayId, indexOfItemToDelete);
      } else {
        activateSelectionWizard();
      }
      deleteSelecetionGroupItemDfd.resolve();
    }

    function recreateSelectMenuOnResize() {
      var itemsArray = [];
      var activeMenuStepObv = getActiveMenuStepObservable;
      activeMenuStepObv.menuStepItems.value.forEach(function (item) {
        if (item.inputType === config.INPUT_TYPE.SELECT) {
          itemsArray.push(item);
        }
        if (item.inputType === config.INPUT_TYPE.COMBOBOX) {
          itemsArray.push(item);
        }
        if (item.type === config.INPUT_TYPE.MENUTABGROUP) {
          $("#" + item.attrTabId + " a:first").tab("show");
          item.menuTabs.value.forEach(function (menuTab) {
            menuTab.items.value.forEach(function (menuTabItem) {
              if (menuTabItem.inputType === config.INPUT_TYPE.SELECT) {
                itemsArray.push(menuTabItem);
              }
              if (menuTabItem.inputType === config.INPUT_TYPE.COMBOBOX) {
                itemsArray.push(menuTabItem);
              }
            });
          });
        }
      });
      if (itemsArray.length > 0) {
        itemsArray.forEach(function (select) {
          if (select.closeAndRecreateMenu !== undefined) {
            select.closeAndRecreateMenu();
          }
          if (select.closeAndRecreateCombobox !== undefined) {
            select.closeAndRecreateCombobox();
          }
        });
      }
    }

    function copySelectionGroupItem(selectionGroupItem) {
      offerManager.copyObject(self.path, selectionGroupItem.id());
    }

    function saveSelectionGroupItem(selectionGroupItem) {
      alert("saveItem");
    }

    function addCard(selectionGroupItem) {
      return new MenuCard(
        selectionGroupItem,
        self.activateBreadcrumb,
        self.breadcrumbLabel,
        self.parentMenuStep,
        getSelectionGroup
      );
    }

    function createInfoTooltip() {
      if (self.tooltipMessage !== undefined) {
        var options = {
          trigger: "manual",
          closeable: true,
          animation: "pop",
        };
        if (self.showTooltipCallback !== undefined) {
          options.onShow = self.showTooltipCallback;
        }
        $("#" + self.tooltipId).webuiPopover(options);
      }
    }

    function showTooltip() {
      $("#" + self.id).tooltip("toggle");
    }

    function showPopover() {
      var options = {
        trigger: "manual",
        closeable: true,
        animation: "pop",
      };
      if (self.showTooltipCallback !== undefined) {
        options.onShow = self.showTooltipCallback;
      }
      $("#" + self.tooltipId).webuiPopover(options);
      $("#" + self.tooltipId).webuiPopover("show");
    }

    function hideTooltip() {
      $("#" + self.id).tooltip("hide");
    }

    function toggleTooltip() {
      $("#" + self.id).tooltip("toggle");
    }

    function cleanUp() {
      if (self !== null) {
        if (self.selectedMeniItemsSubscriptionRef) {
          self.selectedMeniItemsSubscriptionRef.dispose();
        }
        if (self.selectedItemSubscriptionRef) {
          self.selectedItemSubscriptionRef();
        }
        selectedItemsWatch();

        self.buttonItem = null;
        // self.listItems.removeAll();
        globals.clearShallowRefArrayAndAddItems(self.listItems, []);
        self.type = config.ITEM_TYPE.SELECTIONGROUP;
        self.displayId = null;

        self.displayButton = null;

        self.label = null;
        
        self.activateBreadcrumb.value = null;
        self.activateBreadcrumb = null;

        self.parentMenuStep = null;

        // self.selectedItems.removeAll();
        globals.clearShallowRefArrayAndAddItems(self.selectedItems, []);
        //    utils.clearSubscriptions(self.selectedItems);
        self.selectedItems = null;

        self.selectedMenuItems.value.forEach(function (selectedMenuItem) {
          if (selectedMenuItem.cleanUp) {
            selectedMenuItem.cleanUp();
          }
        });

        // self.selectedMenuItems.removeAll();
        globals.clearShallowRefArrayAndAddItems(self.selectedMenuItems, []);
        //    utils.clearSubscriptions(self.selectedMenuItems);
        self.selectedMenuItems = null;

        self.click = null;
        self.path = null;
        setActivateMenuTabFunction(null);
        try {
          self.permissionObv = null;
        } catch (subscriptionDisposeEx) {
          console.log("Crash 10002");
        }

        if (initSelectionGroup.cleanUp) {
          initSelectionGroup.cleanUp();
        }
        self = null;
      }
    }

    function setActiveMenuCard(menuCard) {
      deactivateAnyActiveMenuCards(menuCard);
      self.activeMenuCardStep.value = null;
      self.activeMenuCardStep.value = menuCard;
    }

    function deactivateAnyActiveMenuCards(menuCard) {
      // loop through cards and deactivate if any are active
      if (menuCard !== null) {
        self.selectedMenuItems.value.forEach(function (mc) {
          if (mc.isActive) {
            if (menuCard === undefined || mc.path !== menuCard.path) {
              mc.deactivateMenuCard();
            }
          }
        });
      }
      deactivateMenuCard();
    }

    function deactivateMenuCard() {
      self.activeMenuCardStep.value = null;
    }

    function selectionWizardClick() {
      var setWizardTitleCallback =
        this.dataToRender.value.getSetWizardTitleFunction();
      if (
        this.dataToRender.value.activeStepIndex === 0 &&
        self.selectedMenuItems.value.length === 0
      ) {
        updateTitle(this.label);
        updateDisplayBackButton(false);
      } else {
        if (setWizardTitleCallback !== undefined) {
          var itemsArray = this.dataToRender.value !== undefined ? this.dataToRender.value.itemsToDisplayArray.value : this.dataToRender.itemsToDisplayArray;
          updateTitle(
            setWizardTitleCallback(itemsArray)
          );
        } else {
          updateTitle(this.label);
        }
        updateDisplayBackButton(true);
      }
    }

    function triggerChangeToSelectedItems() {
      var temp = [];
      self.selectedMenuItems.value.forEach(function (item) {
        temp.push(item);
      });
      // Add a card
      if(self.selectedItems.value.length > self.selectedMenuItems.value.length) {
        self.selectedItems.value.forEach(function(item){
          var check = temp.findIndex(function(mi){
            return mi.id() === item.id() && mi.path === item.path;
          });
          if(check < 0) {
            // Check if card also exists on temp
            var newSelectionGroupItem = addCard(item);
            temp.push(newSelectionGroupItem);
            var test = temp.filter(function (item) {
              return item.activateSelectionGroupItem !== undefined;
            });
            self.selectedMenuItems.value = [];
            self.selectedMenuItems.value = test;
            if (setupComplete) {
              if (
                self.preventSelectionGroupItemActivationCallback ===
                  undefined ||
                !self.preventSelectionGroupItemActivationCallback()
              ) {
                //newSelectionGroupItem.activateSelectionGroupItem();
              }
            }
          }
        })
      } else {
        // Remove a card
        var indexOfCardsToRemove = [];
        self.selectedMenuItems.value.forEach(function(item, index){
          var check = self.selectedItems.value.findIndex(function(mi){
            return mi.id() === item.id() && mi.path === item.path;
          });
          if(check < 0) {
            indexOfCardsToRemove.push(index);
          }
        });
        
        indexOfCardsToRemove.forEach(function(index){
          self.selectedMenuItems.value.splice(index, 1);
        });
      }
      
    }

    function updateSlider() {
      if(self !== null) {
        addSliderToStep(
          self.displayId,
          null,
          self.tooltipId,
          self.disableClick,
          getAddSliderToStepCallback()
        );
      }
    }

    function destroySlider() {
      $("#" + self.displayId + "-slider").slick("unslick");
    }

    function removeMenuCardFromSlider(indexToRemove) {
      $("#" + self.displayId + "-slider").slick("slickRemove", indexToRemove);
    }

    function getSelectionGroup() {
      return selectionGroup;
    }
    
    return selectionGroup;
  }

  function MenuCard(
    initMenuCard,
    activateBreadcrumb,
    breadcrumbLabel,
    parentMenuStep,
    getParentSelectionGroupFunction
  ) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initMenuCard.label;
    self.labelObv = shallowRef(initMenuCard.label);
    self.id = initMenuCard.id;
    self.type = config.ITEM_TYPE.MENU_CARD;
    self.getHeader = initMenuCard.getHeader;
    self.getDetail = initMenuCard.getDetail;
    self.getCopyTooltip =
      initMenuCard.getCopyTooltip !== undefined
        ? initMenuCard.getCopyTooltip
        : function () {
            return config.getTranslationText("1003");
          };
    self.getSaveTooltip =
      initMenuCard.getSaveTooltip !== undefined
        ? initMenuCard.getSaveTooltip
        : function () {
            return config.getTranslationText("744");
          };
    self.getRemoveTooltip =
      initMenuCard.getRemoveTooltip !== undefined
        ? initMenuCard.getRemoveTooltip
        : function () {
            return config.getTranslationText("968");
          };
    self.getPinTooltip =
      initMenuCard.getPinTooltip !== undefined
        ? initMenuCard.getPinTooltip
        : function () {
            return config.getTranslationText("4387");
          };
    self.displayItemBreadcrumbs = true;
    self.displayItemInBreadcrumbs = shallowRef(true);
    self.activateBreadcrumb = activateBreadcrumb;
    self.breadcrumbLabel = breadcrumbLabel;
    self.menuStepLevel = initMenuCard.menuStepLevel;
    self.parentMenuStep = parentMenuStep;
    self.activeMenuStep = shallowRef();

    function setMenuCardLabel(newValue) {
      self.label = newValue;
    }

    if (initMenuCard.nestedStep !== undefined) {
      initMenuCard.nestedStep.id = self.id;
      self.nestedStep = addMenuStep(initMenuCard.nestedStep, parentMenuStep);
    }

    if (initMenuCard.click !== undefined) {
      self.click = initMenuCard.click;
    } else {
      self.click = undefined;
    }

    self.doNotDelete = isRef(initMenuCard.doNotDelete) === true ? initMenuCard.doNotDelete : shallowRef(initMenuCard.doNotDelete);
    self.allowCopy = initMenuCard.allowCopy || false;
    self.allowSave = initMenuCard.allowSave || false;
    self.allowPin = initMenuCard.allowPin || false;
    self.saveFunctionCallback = initMenuCard.saveFunctionCallback;
    self.displaySaveFunctionCallback = initMenuCard.displaySaveFunctionCallback;
    self.path = initMenuCard.path;
    self.equipmentObject = initMenuCard.equipmentObject;
    self.graphicsCallbackFunction = initMenuCard.graphicsCallbackFunction;
    self.graphicsCallbackValue = initMenuCard.graphicsCallbackValue;
    self.isActive = shallowRef(false);
    self.displaySaveButtonOnCard =
      typeof initMenuCard.displaySaveButtonOnCard === "boolean"
        ? initMenuCard.displaySaveButtonOnCard
        : true;
    self.permissionObv = initMenuCard.permissionObv;
    self.permissionFunction = initMenuCard.permissionFunction;
    self.isPinned = self.allowPin === true ? shallowRef(initMenuCard.isPinned) : null;
    self.pinClickCallback = initMenuCard.pinClickFunction;
    self.getParentSelectionGroup = typeof getParentSelectionGroupFunction === 'function' ? getParentSelectionGroupFunction : undefined;

    var menuSelectionGroupItem = {
      menuId: self.menuId,
      label: self.label,
      id: self.id,
      type: self.type,
      getHeader: self.getHeader,
      getDetail: self.getDetail,
      activateSelectionGroupItem: activateSelectionGroupItem,
      click: activateSelectionGroupItem,
      activateFromBreadcrumb: activateSelectionGroupItem,
      activateBreadcrumb: self.activateBreadcrumb,
      breadcrumbLabel: self.breadcrumbLabel,
      displayItemInBreadcrumbs: self.displayItemInBreadcrumbs,
      displayItemBreadcrumbs: self.displayItemBreadcrumbs,
      doNotDelete: self.doNotDelete,
      allowCopy: self.allowCopy,
      allowSave: self.allowSave,
      allowPin: self.allowPin,
      path: self.path,
      nestedStep: self.nestedStep,
      updateNestedStep: updateNestedStep,
      setMenuCardLabel: setMenuCardLabel,
      labelObv: self.labelObv,
      cleanUp: cleanUp,
      isActive: self.isActive,
      activeMenuStep: self.activeMenuStep,
      activateMenuCard: activateMenuCard,
      deactivateMenuCard: deactivateMenuCard,
      saveCardClick: saveCardClick,
      displaySaveCardIcon: displaySaveCardIcon,
      getSaveTooltip: self.getSaveTooltip,
      getRemoveTooltip: self.getRemoveTooltip,
      getCopyTooltip: self.getCopyTooltip,
      getPinTooltip: self.getPinTooltip,
      permissionObv: self.permissionObv,
      isPinned: self.isPinned,
      pinIconClicked: pinIconClicked,
      getParentSelectionGroup: self.getParentSelectionGroup,
    };

    function addMenuStep(item, business) {
      // Add next step in menu
      return new MenuStep(item, business, true, business.partialMenuId, false);
    }

    function activateSelectionGroupItem(parentSelectionGroup, index) {
      var indexToActivate = null;
      if (self.click === undefined) {
        // Setup function to deactivate all other cards in selection group
        // Update to instead set the menu card to active
        // Create observable on selection group to be populated with nested step
        if (this.isActive.value) {
          // already active so deactivate
          deactivateMenuCard(parentSelectionGroup);
        } else {
          // activate
          if (index !== undefined) {
            indexToActivate = typeof index === "function" ? index() : index;
          }
          activateMenuCard(parentSelectionGroup, null, indexToActivate);
        }
        //if (self.activeMenuStepObv().getMenuCallback().menuDocked()) {
        //    globals.repositionMenu(self.activeMenuStepObv().getMenuCallback().menuId);
        //}
        if (getMenuDocked()) {
          globals.repositionMenu(getMenuId());
        }
      } else {
        // update offer
        self.click(self.equipmentObject, self.path);
        // update drawing
        if (
          self.graphicsCallbackFunction !== undefined &&
          self.graphicsCallbackValue !== undefined
        ) {
          self.graphicsCallbackFunction(self.graphicsCallbackValue);
        }
      }
    }

    function deactivateMenuCard(parentSelectionGroup) {
      WebuiPopovers.hideAll();
      self.activeMenuStep.value = null;
      self.isActive.value = false;
      if (parentSelectionGroup !== undefined) {
        parentSelectionGroup.setActiveMenuCard(null);
      }
    }

    function activateMenuCard(
      parentSelectionGroup,
      pathArray,
      indexToActivate
    ) {
      self.activeMenuStep.value = self.nestedStep;
      self.isActive.value = true;
      if (parentSelectionGroup !== undefined) {
        parentSelectionGroup.setActiveMenuCard(self);
      }
      nextTick(function(){
        updateJsElementsUsingStep(self.nestedStep, undefined, pathArray, true);
        addSliderToStep(parentSelectionGroup.displayId, indexToActivate, undefined, undefined, parentSelectionGroup.getAddSliderToStepCallback());
      });
      // updateJsElementsUsingStep(self.nestedStep, undefined, pathArray, true);
      // addSliderToStep(parentSelectionGroup.displayId, indexToActivate, undefined, undefined, parentSelectionGroup.getAddSliderToStepCallback());
    }

    function updateNestedStep(newLabel) {
      self.nestedStep.breadcrumbLabel.value = newLabel;
      self.nestedStep.label = newLabel;
    }

    function pinIconClicked(selectionGroupItem) {
      selectionGroupItem.selectedMenuItems.value.forEach(function (menuCardItem) {
        if (menuCardItem.allowPin && menuCardItem.allowPin === true) {
          menuCardItem.isPinned.value = false;
        }
      });
      selectionGroupItem.selectedItems.value.forEach(function (menuCardItem) {
        if (menuCardItem.allowPin && menuCardItem.allowPin === true) {
          menuCardItem.isPinned = false;
        }
      });
      var newIsPinnedValue = self.pinClickCallback();
      if (typeof newIsPinnedValue === "boolean") {
        self.isPinned.value = newIsPinnedValue;
        var index = selectionGroupItem.selectedItems.value.findIndex(function(mc){
          return self.path === mc.path;
        });
        if(index >= 0) {
          selectionGroupItem.selectedItems.value[index].isPinned = newIsPinnedValue;
        }
      } else {
        TScMessenger.writeErrorMessage(
          "In pinIconClicked function, pinClickCallback function does not return a boolean value"
        );
      }
    }

    function cleanUp() {
      // // If we encounter issues with cleanup due to the no longer applicable code commented out below, a shallowRef/watchEffect combo may provide a more disposable solution, see commented code in header func in menuPartBuilder for example
      if (self !== null && self.getHeader) {
        // self.getHeader.dispose();
        // utils.clearSubscriptions(self.getHeader);
        self.getHeader = null;
      }
      if (self.isPinned !== null) {
        // self.isPinned.dispose();
        // utils.clearSubscriptions(self.isPinned);
        self.isPinned = null;
      }
      if (initMenuCard.getHeader) {
        // initMenuCard.getHeader.dispose();
        // utils.clearSubscriptions(initMenuCard.getHeader);
        initMenuCard.getHeader = null;
      }
      if (self !== null && self.getDetail) {
        // self.getDetail.dispose();
        // utils.clearSubscriptions(self.getDetail);
        self.getDetail = null;
      }
      if (initMenuCard.getDetail) {
        // initMenuCard.getDetail.dispose();
        // utils.clearSubscriptions(initMenuCard.getDetail);
        initMenuCard.getDetail = null;
      }
      if (initMenuCard.cleanUp) {
        initMenuCard.cleanUp();
      }
      if (self.nestedStep) {
        self.nestedStep.cleanUp();
      }
      self = null;
    }

    function saveCardClick() {
      if (typeof self.saveFunctionCallback === "function") {
        self.saveFunctionCallback(self.nestedStep.path);
      }
    }

    function displaySaveCardIcon() {
      if (typeof self.displaySaveFunctionCallback === "function") {
        return (
          self.displaySaveFunctionCallback(self.path) &&
          self.displaySaveButtonOnCard
        );
      }
      return self.displaySaveButtonOnCard;
    }

    return menuSelectionGroupItem;
  }

  function MenuTab(
    initTab,
    activateBreadcrumb,
    breadcrumbLabel,
    parentMenuStep
  ) {
    var self = this,
      isActive = false;

    self.menuId = menuIdCounter++;
    self.label = initTab.label;
    self.hrefValue = initTab.hrefValue;
    self.content = initTab.label;
    self.items = shallowRef([]);
    self.menuPath =
      initTab.menuPath + ".TAB-" + initTab.tabIndex + "-" + initTab.hrefValue;
    self.path = initTab.path;
    self.containerPartialMenuId = menu.menuId;
    self.parentMenuStep = parentMenuStep !== undefined ? parentMenuStep : null;
    self.customCss = initTab.customCss || "";

    if (initTab.permissionFunction !== undefined) {
      self.permissionFunction = initTab.permissionFunction;
    } else {
      self.permissionFunction = function () {
        return true;
      };
    }

    if (initTab.displayInputOnTab === true) {
      self.displayInputOnTab = true;
      self.menuTabInput = new MenuValue(initTab.menuTabInput);
      self.toggleOverride = function () {
        if (self.menuTabInput.override.value === true) {
          self.menuTabInput.toggleOverride();          
          if ($("#" + self.hrefValue).hasClass("ui-state-disabled") === true) {
            $("#" + self.hrefValue).removeClass("ui-state-disabled")
          }
          $("#" + self.hrefValue).click();
        } else {
          if ($("#" + self.hrefValue).hasClass("ui-state-active") === true) {
            $("#" + self.hrefValue).click();
          }
          if ($("#" + self.hrefValue).hasClass("ui-state-disabled") === false) {
            $("#" + self.hrefValue).addClass("ui-state-disabled")
          }
          self.menuTabInput.toggleOverride();
        }
      };
    } else {
      self.displayInputOnTab = false;
      self.menuTabInput = null;
      self.toggleOverride = null;
    }
    self.totalText = initTab.totalText !== undefined ? initTab.totalText : null;

    if (initTab.items !== undefined && initTab.items.length > 0) {
      setUpItems();
    }

    var menuTab = {
      menuId: self.menuId,
      label: self.label,
      type: config.ITEM_TYPE.MENUTAB,
      hrefValue: self.hrefValue,
      content: self.content,
      items: self.items,
      displayMenuTab: displayMenuTab,
      path: self.path,
      menuPath: self.menuPath,
      containerPartialMenuId: self.containerPartialMenuId,
      customCss: self.customCss,
      parentMenuStep: self.parentMenuStep,
      initializeMenuTab: initializeMenuTab,
      displayInputOnTab: self.displayInputOnTab,
      menuTabInput: self.menuTabInput,
      totalText: self.totalText,
      toggleOverride: self.toggleOverride,
      cleanUp: cleanUp,
      getIsActive: getIsActive,
      setIsActive: setIsActive,
    };

    function setUpItems() {
      var tempItems = [];
      var tabItem;
      initTab.items.forEach(function (item) {
        item.menuPath = self.menuPath;
        switch (item.itemType) {
          case config.ITEM_TYPE.VALUE:
            tabItem = new MenuValue(item);
            tempItems.push(tabItem);
            break;
          case config.ITEM_TYPE.DATAGRID:
            tempItems.push(new MenuDataGrid(item));
            break;
          case config.ITEM_TYPE.SELECT:
            tabItem = new MenuSelect(item);
            tempItems.push(tabItem);
            break;
          case config.ITEM_TYPE.COMBOBOX:
            tabItem = new MenuCombobox(item);
            tempItems.push(tabItem);
            break;
          case config.ITEM_TYPE.SELECTIONGROUP:
            tempItems.push(
              addMenuSelectionGroup(
                item,
                activateBreadcrumb,
                breadcrumbLabel,
                parentMenuStep
              )
            );
            parentMenuStep.displaySlider = true;
            break;
          case config.ITEM_TYPE.MENUTABGROUP:
            tempItems.push(
              addMenuTabGroup(
                item,
                activateBreadcrumb,
                breadcrumbLabel,
                parentMenuStep
              )
            );
            break;
          case config.ITEM_TYPE.DIVIDER:
            tempItems.push(getDividerObject(item.customCss));
            break;
          case config.ITEM_TYPE.INPUT_GROUP:
            tabItem = new MenuInputGroup(item);
            tempItems.push(tabItem);
            break;
          case config.ITEM_TYPE.MENU_TABLE:
            tabItem = new MenuTable(item);
            tempItems.push(tabItem);
            break;
          case config.ITEM_TYPE.SELECTIONWIZARD:
            tempItems.push(addMenuSelectionWizard(item));
            break;
          case config.ITEM_TYPE.RADIO:
            tabItem = new MenuRadioInput(item);
            tempItems.push(tabItem);
            break;
          default:
            break;
        }
      });

      self.items.value = tempItems;
    }

    function addMenuTabGroup(
      tabGroup,
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      var menuTabGroup = new MenuTabGroup(
        tabGroup,
        activateBreadcrumb,
        breadcrumbLabel,
        parentMenuStep
      );

      if (tabGroup.displayType === "accordion") {
        self.displayAccordionOnStep = true;
        self.accordionHeightStyle =
          typeof tabGroup.heightStyle === "string"
            ? tabGroup.heightStyle
            : config.ACCORDION_HEIGHT_STYLE_OPTIONS.CONTENT;
      } else {
        self.displayTabsOnStep = true;
      }

      return menuTabGroup;
    }

    function addMenuSelectionGroup(
      selectionGroup,
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      selectionGroup.tabMenuPath = self.menuPath;
      var temp = new MenuSelectionGroup(
        selectionGroup,
        activateBreadcrumb,
        breadcrumbLabel,
        parentMenuStep
      );
      parentMenuStep.sliderDisplayIds.push({
        displayId: temp.displayId,
        tooltipId: temp.tooltipId,
        disableClick: temp.disableClick,
        getAddSliderToStepCallback: temp.getAddSliderToStepCallback
      });
      return temp;
    }

    function addMenuSelectionWizard(value) {
      var menuSelectionWizard = new MenuSelectionWizard(value);
      return menuSelectionWizard;
    }

    function displayMenuTab() {
      if (!self.permissionFunction()) {
        return false;
      }
      if (self.items.value.length === 0) {
        return false;
      }
      var temp = self.items.value.filter(function (item) {
        if (item.type === config.ITEM_TYPE.MENUTABGROUP) {
          return item.displayMenuTabGroup();
        } else {
          if (
            // typeof item.permissionObv === "function" &&
            // item.permissionObv()
            item.permissionObv !== undefined && 
            item.permissionObv.value !== undefined
          ) {
            return item.permissionObv.value.visible;
          } else {
            return true;
          }
        }
      });

      if (temp.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    function initializeMenuTab(pathArray, index) {
      self.items.value.forEach(function (menuTabItem) {
        if (menuTabItem.inputType === config.INPUT_TYPE.SELECT) {
          //itemsArray.push(menuTabItem);
        }
        if (menuTabItem.inputType === config.INPUT_TYPE.COMBOBOX) {
          //itemsArray.push(menuTabItem);
        }
        if (menuTabItem.type === config.ITEM_TYPE.MENUTABGROUP) {
          menuTabItem.initializeMenuTabGroup(pathArray, index);
        }
        if (menuTabItem.inputType === config.INPUT_TYPE.INPUT_GROUP) {
          //itemsArray.push(menuTabItem);
        }
      });
    }

    function cleanUp() {
      self.items.value.forEach(function (item) {
        if (item.cleanUp) {
          item.cleanUp();
        }
      });
    }

    function getIsActive() {
      return isActive;
    }

    function setIsActive(newValue) {
      isActive = newValue;
    }

    return menuTab;
  }

  function MenuTabGroup(
    initMenuTabGroup,
    activateBreadcrumb,
    breadcrumbLabel,
    parentMenuStep
  ) {
    var self = this;

    self.menuId = menuIdCounter++;
    self.label = initMenuTabGroup.label;
    self.hrefValue = initMenuTabGroup.hrefValue;
    self.type = config.ITEM_TYPE.MENUTABGROUP;
    self.inputType = config.ITEM_TYPE.MENUTABGROUP;
    self.displayType = initMenuTabGroup.displayType;
    self.menuPath =
      initMenuTabGroup.menuPath + "." + initMenuTabGroup.displayType;
    self.path = initMenuTabGroup.path;
    self.permissionObv = initMenuTabGroup.permissionObv;
    self.attrTabId = initMenuTabGroup.attrTabId;
    self.displayMenuTabsWithButtons =
      initMenuTabGroup.displayMenuTabsWithButtons !== undefined
        ? initMenuTabGroup.displayMenuTabsWithButtons
        : false;
    self.menuTabDisplayType =
      initMenuTabGroup.menuTabDisplayType !== undefined
        ? initMenuTabGroup.menuTabDisplayType
        : config.MENU_TAB_DISPLAY_TYPES.LABEL_ONLY;
    if (self.displayMenuTabsWithButtons === true) {
      self.menuTabDisplayType = config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_BUTTONS;
    }
    self.displayButtonBesideTabs =
      typeof initMenuTabGroup.displayButtonBesideTabs === "boolean"
        ? initMenuTabGroup.displayButtonBesideTabs
        : false;
    if (self.displayButtonBesideTabs === true) {
      self.menuTabGroupButton = initMenuTabGroup.button;
    }
    self.deleteFunction = initMenuTabGroup.deleteFunction;
    self.moveUpFunction = initMenuTabGroup.moveUpFunction;
    self.moveDownFunction = initMenuTabGroup.moveDownFunction;
    self.heightStyle =
      typeof initMenuTabGroup.heightStyle === "string"
        ? initMenuTabGroup.heightStyle
        : null;
    self.customCss =
      typeof initMenuTabGroup.customCss === "string"
        ? initMenuTabGroup.customCss
        : "";
    self.allAccordionsClosedWhenInitialized =
      typeof initMenuTabGroup.allAccordionsClosedWhenInitialized === "boolean"
        ? initMenuTabGroup.allAccordionsClosedWhenInitialized
        : false;

    self.menuTabs = shallowRef([]);

    if (initMenuTabGroup.permissionFunction !== undefined) {
      self.permissionFunction = initMenuTabGroup.permissionFunction;
    } else {
      self.permissionFunction = function () {
        return JSON.parse(JSON.stringify(config.defaultMenuPermissionObject));
      };
    }

    if (
      initMenuTabGroup.tabs !== undefined &&
      initMenuTabGroup.tabs.length > 0
    ) {
      setUpMenuTabs(initMenuTabGroup.tabs);
    }

    var menuTabGroup = {
      menuId: self.menuId,
      label: self.label,
      hrefValue: self.hrefValue,
      type: self.type,
      menuTabs: self.menuTabs,
      inputType: self.inputType,
      displayType: self.displayType,
      permissionFunction: self.permissionFunction,
      path: self.path,
      menuPath: self.menuPath,
      permissionObv: self.permissionObv,
      attrTabId: self.attrTabId,
      displayMenuTabGroup: displayMenuTabGroup,
      addMenuTab: addMenuTab,
      deleteMenuTab: deleteMenuTab,
      displayMenuTabsWithButtons: self.displayMenuTabsWithButtons,
      deleteFunction: self.deleteFunction,
      moveUpFunction: self.moveUpFunction,
      moveDownFunction: self.moveDownFunction,
      initializeMenuTabGroup: initializeMenuTabGroup,
      menuTabDisplayType: self.menuTabDisplayType,
      heightStyle: self.heightStyle,
      cleanUp: cleanUp,
      displayButtonBesideTabs: self.displayButtonBesideTabs,
      menuTabGroupButton: self.menuTabGroupButton,
      getActiveMenuTab: getActiveMenuTab,
      getActiveMenuTabIndex: getActiveMenuTabIndex,
      allAccordionsClosedWhenInitialized:
        self.allAccordionsClosedWhenInitialized,
      customCss: self.customCss,
    };

    function setUpMenuTabs(tabs) {
      var tempTabs = [],
        tabIndex = 0;

      tabs.forEach(function (tab) {
        tab.tabIndex =
          tabIndex > 9 ? tabIndex.toString() : "0" + tabIndex.toString();
        tab.menuPath = self.menuPath;
        tempTabs.push(
          createMenuTab(
            tab,
            activateBreadcrumb,
            breadcrumbLabel,
            parentMenuStep
          )
        );
        tabIndex++;
      });

      self.menuTabs.value = tempTabs;
    }

    function displayMenuTabGroup() {
      var visibleTabs = self.menuTabs.value.filter(function (tab) {
        if (tab.displayMenuTab !== undefined) {
          return tab.displayMenuTab();
        } else {
          return tab.permissionObv.value.visible;
        }
      });
      if (visibleTabs.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    function addMenuTab(
      tab,
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      tab.tabIndex =
        self.menuTabs.value.length > 9
          ? self.menuTabs.value.length.toString()
          : "0" + self.menuTabs.value.length.toString();
      tab.menuPath = self.menuPath;
      // self.menuTabs.value.push(
      //   createMenuTab(tab, activateBreadcrumb, breadcrumbLabel, parentMenuStep)
      // );
      globals.addItemToShallowRefArray(self.menuTabs, createMenuTab(tab, activateBreadcrumb, breadcrumbLabel, parentMenuStep));
    }

    function createMenuTab(
      tab,
      activateBreadcrumb,
      breadcrumbLabel,
      parentMenuStep
    ) {
      return new MenuTab(
        tab,
        activateBreadcrumb,
        breadcrumbLabel,
        parentMenuStep
      );
    }

    function deleteMenuTab(index) {
      self.menuTabs.value.splice(index, 1);
    }

    function initializeMenuTabGroup(pathArray, indexAlreadyFound) {
      var fromIndex =
          typeof indexAlreadyFound === "number" ? indexAlreadyFound : 0,
        indexToSend = 0;
      $('#' + self.attrTabId + ' a[data-toggle="tab"]').unbind("shown.bs.tab");
      $('#' + self.attrTabId + ' a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        var indexActivated = self.menuTabs.value.findIndex(function (tab) {
          return tab.hrefValue + "-id" === e.currentTarget.id;
        });
        if (indexActivated > -1) {
          var tabDetails = self.menuTabs.value[indexActivated];
          tabDetails.items.value.forEach(function (item) {
            if (item.type === config.ITEM_TYPE.SELECTIONGROUP) {
              var activeCardIndex = item
                .selectedMenuItems.value
                .findIndex(function (card) {
                  return card.isActive.value === true;
                });
              var cardIndexToUse =
                activeCardIndex > -1 ? activeCardIndex : null;
              addSliderToStep(item.displayId, cardIndexToUse, item.tooltipId, item.disableClick, item.getAddSliderToStepCallback());
              var func = item.getActivateMenuTabFunction();
              if(func !== null) {
                func();
              }
            }
            if (item.inputType === config.ITEM_TYPE.SELECT) {
              item.createSelectMenu();
            }
            if (item.inputType === config.ITEM_TYPE.COMBOBOX) {
              item.createCombobox();
            }
            if (item.inputType === config.ITEM_TYPE.INPUT_GROUP) {
              item.createJsElements();
            }
            if (item.type === config.ITEM_TYPE.MENU_TABLE) {
              item.createJsElements();
            }
            if (item.type === config.ITEM_TYPE.SELECTIONWIZARD) {
              // item.dataToRender.itemsToDisplayArray.forEach(function (swi) {
              item.dataToRender.value.itemsToDisplayArray.value.forEach(function (swi) {
                if (typeof swi.activateMenuTabCallback === "function") {
                  swi.activateMenuTabCallback();
                }
              });
            }
          });
          if (getMenuDocked()) {
            globals.repositionMenu(tabDetails.containerPartialMenuId);
          }
          self.menuTabs.value.forEach(function (tab) {
            tab.setIsActive(false);
          });
          tabDetails.setIsActive(true);
        }
      });

      if (pathArray !== undefined && Array.isArray(pathArray)) {
        var indexOfTabs = pathArray.indexOf("tabs", fromIndex),
          containerId;
        if (indexOfTabs >= 0) {
          indexToSend = indexOfTabs + 1;
          $('[href="#' + pathArray[indexOfTabs + 1].substring(7) + '"]').tab("show");
          var indexOfAccordion = pathArray.indexOf("accordion");
          if (indexOfAccordion >= 0) {
            var accordionString = ".accordion h3",
              fullString = "",
              lastTabIndex = pathArray.lastIndexOf("tabs");

            if (lastTabIndex > 0) {
              containerId = pathArray[lastTabIndex + 1].substring(7);
              fullString = "#" + containerId + " " + accordionString;
            } else {
              fullString = accordionString;
            }
            var getTabIndexToOpen = $(fullString).index(
              $("#" + pathArray[indexOfAccordion + 1].substring(7))
            );
            $(".accordion").accordion("option", "active", getTabIndexToOpen);
          } else {
            if (
              indexOfTabs < 0 &&
              self.allAccordionsClosedWhenInitialized === false
            ) {
              $("#" + self.attrTabId + " a:first").tab("show");
            }
          }
        } else {
          if (
            indexOfTabs < 0 &&
            self.allAccordionsClosedWhenInitialized === false
          ) {
            $("#" + self.attrTabId + " a:first").tab("show");
          }
        }
      } else {
        openFirstAvailableTab();
      }

      function openFirstAvailableTab(indexOfTabs) {
        var tabActivated = false;
        if (indexOfTabs < 0 || indexOfTabs === undefined) {
          if (
            self.menuTabDisplayType ===
            config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_INPUT
          ) {
            if (self.menuTabs.value[0].menuTabInput.override.value === false) {
              $("#" + self.attrTabId + " a:first").tab("show");
            } else {
              for (var i = 1; i < self.menuTabs.value.length; i++) {
                if (
                  self.menuTabs.value[i].menuTabInput.override.value === false
                ) {
                  $(".accordion").accordion("option", "active", i);
                  tabActivated = true;
                  break;
                }
              }
              if (!tabActivated) {
                $(".accordion").accordion("option", "active", false);
              }
            }
          } else {
            if (self.allAccordionsClosedWhenInitialized === false) {
              $("#" + self.attrTabId + " a:first").tab("show");
            }
          }
        }
      }

      self.menuTabs.value.forEach(function (menuTab) {
        menuTab.initializeMenuTab(pathArray, indexToSend);
      });
    }

    function cleanUp() {
      self.menuTabs.value.forEach(function (tab) {
        if (tab.cleanUp) {
          tab.cleanUp();
          //utils.clearSubscriptions(tab);
        }
      });

      utils.clearSubscriptions(self.menuTabs);
    }

    function getActiveMenuTab() {
      return self.menuTabs.value.find(function (tab) {
        return tab.getIsActive();
      });
    }

    function getActiveMenuTabIndex() {
      return self.menuTabs.value.findIndex(function (tab) {
        return tab.getIsActive();
      });
    }

    return menuTabGroup;
  }

  function MenuSelectionWizard(
    initSelectionWizard,
    partialMenuId,
    clickEventCallback
  ) {
    var self = this;
    self.label = initSelectionWizard.label;
    self.displayLabel = initSelectionWizard.displayLabel || true;
    self.type = config.ITEM_TYPE.SELECTIONWIZARD;
    self.renderAs = config.RENDERED_AS_OPTIONS.SELECTION_WIZARD;
    var tempSelectionWizard = new WizardHelper.createSelectionWizard(
      initSelectionWizard.items[0],
      partialMenuId,
      initSelectionWizard.doNotIncludeCallback === true
        ? null
        : updateMenuForStickyHeaders
    );
    tempSelectionWizard.activateWizardStep(tempSelectionWizard.steps[0]);
    tempSelectionWizard.activateMenuItem();
    self.dataToRender = shallowRef(tempSelectionWizard);
    self.partialMenuId = partialMenuId;
    self.clickEventCallback = clickEventCallback;

    var selectionWizard = {
      label: self.label,
      displayLabel: self.displayLabel,
      type: self.type,
      renderAs: self.renderAs,
      dataToRender: self.dataToRender,
    };

    function updateMenuForStickyHeaders() {
      // NOTE: Currently hard coding sizes because getting inconsistent height results from JS/jQuery
      // Works for currenty menus but would like to make this more generic by getting the right height results and changing height using these and available space
      var newHeight = 420;

      if (typeof self.clickEventCallback === "function") {
        self.clickEventCallback();
      }
      if (self.partialMenuId !== undefined && self.dataToRender.value.getRenderAs() === config.RENDERED_AS_OPTIONS.DATAGRID ) {
        if ($("#" + self.partialMenuId + "-container").height() > 500) {
          var activeStep = getActiveMenuStepObservable();
          var firstItem = activeStep.menuStepItems.value[0];
          // set newHeight 420 false 305 true
          if (firstItem.type === "selectionGroup" && firstItem.selectedMenuItems.value.length > 0) {
            newHeight = 305;
          }
          // Remove overflow from menu-container class
          $("#" + self.partialMenuId + "-container .menu-container").css("overflow", "");
          // Set height to fill 550px (max height of menu)
          $("#" + self.partialMenuId + "-container .rows-container").css("height", newHeight);
        }
        // Repostion the menu if required
        if (getMenuDocked()) {
          globals.repositionMenu(self.partialMenuId, undefined, true);
        }
      }
    }

    function cleanUp() {
      if (self.dataToRender.value.cleanUp) {
        self.dataToRender.value.cleanUp();
      }
    }

    return selectionWizard;
  }

  function MenuButton(initButton) {
    var self = this;

    self.id = initButton.id;
    self.label = initButton.label;
    self.iconClass = initButton.iconClass;
    self.useSprite = initButton.useSprite;
    self.clickCallback = initButton.clickFunction;
    self.displayFunction = initButton.displayFunction;
    self.selectionWizardAttachedToButton =
      typeof initButton.selectionWizardAttachedToButton === "boolean"
        ? initButton.selectionWizardAttachedToButton
        : false;

    var menuButton = {
      id: self.id,
      label: self.label,
      iconClass: self.iconClass,
      useSprite: self.useSprite,
      clickFunction: clickFunction,
      displayFunction: self.displayFunction,
    };

    function clickFunction(path) {
      self.clickCallback(path);
      if (self.selectionWizardAttachedToButton === true) {
        activateSelectionWizard();
      }
    }

    return menuButton;
  }

  //#endregion Menu Elements
};

function displaySliderOnStep(step) {
  // // Add slider if required
  // if (step.displaySlider) {
  //   step.sliderDisplayIds.forEach(function (id) {
  //     addSliderToStep(id.displayId, null, id.tooltipId, id.disableClick);
  //   });
  // }
}

function createSliderOnStep(step) {
  var selectionGroups = step.menuStepItems.value.filter(function (
    selectionGroup
  ) {
    return (
      selectionGroup.type === config.ITEM_TYPE.SELECTIONGROUP &&
      selectionGroup.selectedMenuItems !== undefined &&
      selectionGroup.selectedMenuItems().length > 0
    );
  });
  selectionGroups.forEach(function (selectionGroup) {
    addSliderToStep(
      selectionGroup.displayId,
      null,
      selectionGroup.tooltipId,
      selectionGroup.disableClick,
      selectionGroup.getAddSliderToStepCallback()
    );
  });
}

function getDividerObject(customCss) {
  var returnObj = {};
  returnObj.type = config.ITEM_TYPE.DIVIDER;
  // returnObj.permissionObv = function () {
  //   return {
  //     visible: true,
  //   };
  // };
  returnObj.permissionObv = shallowRef({
    visible: true
  });
  if (customCss !== undefined) {
    returnObj.customCss = customCss;
  } else {
    returnObj.customCss = "";
  }
  return returnObj;
}

function addNoContentCss() {
  if (this.tooltipMessage === undefined) {
    return true;
  }
  if (typeof this.tooltipMessage === "string") {
    return false;
  }
  if (
    typeof this.tooltipMessage === "function" &&
    typeof this.tooltipMessage() === "string"
  ) {
    return false;
  }
  return false;
}

/**
 * 
 * @param {string} id 
 * @param {number} slideNumber 
 * @param {string} tooltipId 
 * @param {function|boolean} disableClick 
 * @param {function} [callback] - Extra function to be called if necessary. Typically return value from MenuSelectionGroup.getAddSliderToStepCallback()
 */
function addSliderToStep(id, slideNumber, tooltipId, disableClick, callback) {
  var disableClickValue = typeof disableClick === "function" ? disableClick() : disableClick;
  // if ($("#" + id + "-slider").slick !== undefined && $("#" + id + "-slider").hasClass("slick-initialized")) {
  //   try {
  //     $("#" + id + "-slider").slick("unslick");
  //   } catch (e) {
  //     TScMessenger.writeDebugMessage("error in creating slider");
  //   }
  // }
  // var slickOptions = {
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   infinite: false,
  //   arrows: false,
  // };
  // if (slideNumber !== undefined && slideNumber !== null && slideNumber > 1) {
  //   slickOptions.initialSlide = slideNumber;
  // }
  // if ($("#" + id + "-slider").is(":visible")) {
  //   try {
  //     var slider = $("#" + id + "-slider")
  //       .not(".slick-initialized")
  //       .slick(slickOptions);
  //   } catch (e) {
  //     TScMessenger.writeDebugMessage("error in creating slider");
  //   }

  //   $("#" + id + "-slider-prev")
  //     .off("click touchstart")
  //     .on("click touchstart", function () {
  //       var currentSlide = $("#" + id + "-slider").slick("slickCurrentSlide");
  //       if (currentSlide > 1) {
  //         $("#" + id + "-slider").slick("slickGoTo", currentSlide - 2);
  //       } else {
  //         $("#" + id + "-slider").slick("slickPrev");
  //       }
  //       return false;
  //     });
  //   $("#" + id + "-slider-next")
  //     .off("click touchstart")
  //     .on("click touchstart", function () {
  //       $("#" + id + "-slider").slick("slickNext");
  //       return false;
  //     });

  //   $(".equipment-slider").show("fade", {}, 200);

  if (tooltipId !== undefined && tooltipId !== null && disableClickValue === false) {
    $("#" + tooltipId + " i").addClass("pulse-div");
  }

  if(typeof callback === 'function') {
    callback();
  }
  // }
}

export default Menu;
//});
