import config from '../../../services/config';
import utils from '../../../services/utils';
import ComplianceDashboardItem from './complianceDashboardItem';



var ComplianceDashboardText = function() {

    ComplianceDashboardItem.call(this, config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT);

    this._text;
    this._textColour;
    this._textStyle;
    this._textIsBold = false;
    this._icon;
    this._iconColour;
    this._iconSize;
    this._heading;
    this._subheading;
    this._spacerType;
    this._interaction;
    this._backgroundColour;

    return this;
}


ComplianceDashboardText.prototype.setText = function (newValue) {
    this._text = newValue;
    return this;
}

ComplianceDashboardText.prototype.getText = function () {
    return this._text;
}

ComplianceDashboardText.prototype.setTextColour = function (newValue) {
    this._textColour = newValue;
    return this;
}

ComplianceDashboardText.prototype.getTextColour = function () {
    return this._textColour;
}

ComplianceDashboardText.prototype.setTextStyle = function (newValue) {
    this._textStyle = newValue;
    if (this._textStyle.indexOf('bold') !== -1) {
        this._textIsBold = true;
    }
    return this;
}

ComplianceDashboardText.prototype.getIsTextBold = function () {
    return this._textIsBold;
}

ComplianceDashboardText.prototype.getTextStyle = function () {
    return this._textStyle;
}

ComplianceDashboardText.prototype.setIsHeading = function () {
    this._heading = true;
    return this;
}

ComplianceDashboardText.prototype.getIsHeading = function () {
    return this._heading;
}

ComplianceDashboardText.prototype.setIsSubheading = function () {
    this._subheading = true;
    return this;
}

ComplianceDashboardText.prototype.getIsSubheading = function() {
    return this._subheading;
}

//function setSpacerType(newValue) {
//    _spacerType = newValue;
//    return self;
//}

//function getSpacerType() {
//    return _spacerType;
//}

//function setInteraction(newValue) {
//    _interaction = newValue;
//    _interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(_interaction.callbacks);
//    return self;
//}

//function getInteraction() {
//    return _interaction;
//}

//function setBackgroundColour(newValue) {
//    _backgroundColour = newValue;
//    return self;
//}

//function getBackgroundColour() {
//    return _backgroundColour;
//}

utils.extend(ComplianceDashboardItem, ComplianceDashboardText);


export default ComplianceDashboardText;

       
