import { LogLevel } from '@azure/msal-browser'
const CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: CustomTruckScienceRequirements.getMetaTagContent('microsoft-signin-client-id'),
        redirectUri: location.origin + '/mslogin.html',
        postLogoutRedirectUri: location.origin + '/get_started',
        // authority:
        //     "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        // storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // system: {
    //     loggerOptions: {
    //         logLevel: LogLevel.Trace,
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //                 default:
    //                     console.log(message);
    //                     return;
    //             }
    //         },
    //     },
    // },
    // telemetry: {
    //     application: {
    //         appName: "MSAL Browser V2 Default Sample",
    //         appVersion: "1.0.0",
    //     },
    // },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};

// // Add here the endpoints for MS Graph API services you would like to use.
// const graphConfig = {
//     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
//     graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
// };

// // Add here scopes for access token to be used at MS Graph API endpoints.
// const tokenRequest = {
//     scopes: ["Mail.Read"],
//     forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
// };

export const silentRequest = {
    // scopes: ["openid", "profile", "User.Read", "Mail.Read"],
    scopes: ["User.Read"],
    loginHint: localStorage.getItem('loginHint')
};

const logoutRequest = {};