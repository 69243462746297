import config from '../../../services/config';
// import globals from '@/services/globals';
// import utils from '@/services/utils';
// import { config, utils } from '../../../services';

var Rig = function () {

    var self = this;

    var _chassisObjects = [];

    function addChassisObject(chassisObj) {
        _chassisObjects.push(chassisObj);
    }

    function removeChassisObject(chassisObjToRemove) {
        var indexOfElementToRemove = _chassisObjects.map(function (chassisObj) { return chassisObj.getType(); }).indexOf(chassisObjToRemove.getType());
        _chassisObjects.splice(indexOfElementToRemove, 1);
    }

    function getChassisObjects() {
        return _chassisObjects;
    }

    function getVehicle() {
        return getChassisObjectByName(config.CHASSIS_OBJECT_TYPES.VEHICLE);
    }

    function getTrailer1() {
        return getChassisObjectByName(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2() {
        return getChassisObjectByName(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getChassisObjectByName(chassisObjectName) {
        return _chassisObjects.filter(function (chassisObj) {
            return chassisObj.getChassisObjectName() === chassisObjectName;

        })[0] || null;
    }

    function getTrailerBySourceDatabaseId(sourceDatabaseId) {
        return _chassisObjects.filter(function (chassisObj) {
            return (chassisObj.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObj.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObj.getSourceDatabaseId() === sourceDatabaseId;

        })[0] || null;
    }

    function cleanUp() {
        _chassisObjects.forEach(function (chassisObject) {
            if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                chassisObject.setVehicleDrawing(null);
                chassisObject.setVehicleTopViewDrawing(null);
                chassisObject.getAccessoryHolder().cleanUp();
                chassisObject.setFuelTanks([]);
                //chassisObject.getPayloadHolder().cleanUp();
                //chassisObject.getAxlesHolder().cleanUp;
            } 
        });
    }

    self.addChassisObject = addChassisObject;
    self.removeChassisObject = removeChassisObject;
    self.getVehicle = getVehicle;
    self.getTrailer1 = getTrailer1;
    self.getTrailer2 = getTrailer2;
    self.getChassisObjectByName = getChassisObjectByName;
    self.getTrailerBySourceDatabaseId = getTrailerBySourceDatabaseId;
    self.getChassisObjects = getChassisObjects;
    self.cleanUp = cleanUp;
    
}

export default Rig;
