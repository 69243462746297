<template>
    <tr class="menu-li menu-li-value menu-tr-selection-wizard">
        <td class="menu-td-selection-wizard">
            <div class="selection-wizard-template" data-bind="template: { name: 'template-selection-wizard-container'}">
                <SelectionWizard :wizardFromParent="item.dataToRender" :renderAsFromParent="item.renderAs"></SelectionWizard>
            </div>
        </td>
    </tr>
    <!-- <div>
        'template-menu-selection-wizard'
    </div> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.SELECTIONWIZARD 
    <tr class="menu-li menu-li-value menu-tr-selection-wizard" data-bind="template: { name: 'template-menu-selection-wizard' }"></tr>
     /ko     
</script> -->

    <!-- <script type="text/html" id="template-menu-selection-wizard">
    <td class="menu-td-selection-wizard">
        <div class="selection-wizard-template" data-bind="template: { name: 'template-selection-wizard-container'}"></div>
    </td>
</script> -->
</template>

<script>
import { ref, toRef } from 'vue';
import SelectionWizard from "../selectionWizard/SelectionWizard.vue"

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const item = ref(props.valueFromParent);
        const item = toRef(props, "valueFromParent");

        return {
            item
        };
    },
    components: { SelectionWizard }
}
</script>

<style lang="scss" scoped>
</style>