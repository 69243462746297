<template>
    <transition name="fade">
        <div class="popup-modal" v-if="isVisible">            
            <div class="window draggable">                
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import { nextTick } from 'vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;

export default {
    name: 'PopupModal',

    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false
        },
    },

    mounted() {
        nextTick(function(){
            if($('.draggable .messageBox .modal-drag-div').length > 0) {
                createDraggableMenu();
                $('.modal-drag-div').on('mouseenter click touchstart', function () {
                    createDraggableMenu();
                }).mouseleave(removeDraggableMenu);
            }
        });
    }
}

function createDraggableMenu() {
    if($('.draggable .messageBox .modal-drag-div').length > 0) {
        removeDraggableMenu();
        $('.draggable .messageBox').draggable({
            containment: '#applicationBody',
            handle: '.modal-drag-div',
            drag: function () {},
            stop: function () {
                $(this).css({
                    'height': 'auto'
                });
            }
        });
        $( ".modal-drag-div" ).disableSelection();
    }
}

function removeDraggableMenu() {
    try {
        if($('.draggable .messageBox').draggable('instance') !== undefined) {
            $('.draggable .messageBox').draggable('destroy');
        }
    } catch (e) {
        TScMessenger.writeErrorMessage('Error raised in removeDraggableMenu in PopupModal.vue');
    }
}
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.popup-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 1051;
}

.window {
    /* background: #fff; */
    background: transparent;
    /* border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2); */
    /* max-width: 480px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
</style>