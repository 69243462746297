import config from '@/services/config';

class CustomMessageBox {

    _message;
    _title;
    _buttons;
    _messageRef;
    _callback = null;
    _modalDisplayMode = config.MODAL_DISPLAY_MODE.QUEUE;
    _additionalClasses = '';
    

    constructor(message, title, buttons, messageRef, additionalClasses) {
        this._message = message;
        this._title = title;
        this._buttons = buttons;
        this._messageRef = messageRef;
        this._additionalClasses = typeof additionalClasses === 'string' ? additionalClasses : '';
    }

    getTitle() {
        return this._title;
    }

    getMessage() {
        return this._message;
    }

    getButtons() {
        return this._buttons;
    }

    getMessageRef() {
        return this._messageRef;
    }

    addCallback(callback) {
        this._callback = callback;
    }

    getCallback() {
        return this._callback;
    }

    setModalDisplayMode(displayMode) {
        this._modalDisplayMode = displayMode;
        return this;
    }

    getModalDisplayMode() {
        return this._modalDisplayMode;
    }

    getAdditionalClasses() {
        return this._additionalClasses;
    }

    getData() {
        return {
            title: this._title,
            message: this._message,
            messageRef: this._messageRef,
            buttons: this._buttons,
            additionalClasses: this._additionalClasses
        };
    }
}

export default CustomMessageBox;