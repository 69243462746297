<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox specification-width">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('2580')">{{getTranslationText('2580')}}</span>
                     - 
                    <span data-bind="text: appName">{{appName}}</span></h4>
            </div>
            <div class="modal-body help-modal-body">
                <div>
                    <p data-bind="text: getTranslationText('183')">{{getTranslationText('183')}}</p>
                    <table class="help-modal-table">
                        <tr>
                            <td>
                                <span data-bind="text: getTranslationText('4513')">{{getTranslationText('4513')}}</span>
                            </td>
                            <td>
                                <a @click.stop="openIntercom"
                                    href="#" 
                                    data-bind="click: openIntercom">
                                    <span data-bind="text: getTranslationText('4514')">{{getTranslationText('4514')}}</span>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span data-bind="text: getTranslationText('123')">{{getTranslationText('123')}}</span>
                            </td>
                            <td>
                                <a :href="'mailto:' + supportEmailAddress"
                                    data-bind="attr: {href: 'mailto:' + supportEmailAddress}">
                                    <span data-bind="text: supportEmailAddress">{{supportEmailAddress}}</span>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span data-bind="text: getTranslationText('565')">{{getTranslationText('565')}}</span>
                            </td>
                            <td>
                                <a :href="supportWebsite"
                                    data-bind="attr: {href: supportWebsite}" target="_blank">
                                    <span data-bind="text: supportWebsite">{{supportWebsite}}</span>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>{{getTranslationText('5239')}}</span>
                            </td>
                            <td>
                                <a :href="'tel:' + supportTelephoneNumberForLink"
                                    data-bind="attr: {href: 'tel:' + supportTelephoneNumberForLink}">
                                    <span data-bind="text: supportTelephoneNumber">{{supportTelephoneNumber}}</span>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="help-body-copyright">
                    <span data-bind="text: getTranslationText('182')">{{getTranslationText('182')}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <div class="flex-space-between">
                    <a class="help-terms-and-conditions-a">
                        <span @click.stop="openTermsAndConditionsModal"
                            data-bind="text: getTranslationText('478'), click: openTermsAndConditionsModal, touchstart: openTermsAndConditionsModal">
                            {{getTranslationText('478')}}
                        </span>
                    </a>
                    <button @click="ok"
                        class="btn btn-default btn-truckscience btn-primary" data-bind="click: ok, touchstart: ok">
                        <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                    </button>
                </div>        
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import { toRef, ref, nextTick } from 'vue';
import PopupModal from './PopupModal.vue';
import bus from '@/main';

export default {
    name: 'HelpModal',
    props: {
        propsForModal: Object
    },
    components: { PopupModal },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const appName = ref(opts.value.modalObject.appName),
            supportEmailAddress = ref(globals.user.getSupportEmailAddress()),
            supportTelephoneNumber = ref(globals.user.getSupportTelephoneNumber()),
            supportTelephoneNumberForLink = ref(globals.user.getSupportTelephoneNumberForLink()),
            supportWebsite = ref(globals.user.getSupportWebsite());

        let resolvePromise;

        function show(opts = {}) {
            // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function ok() {
            this.popup.close();
            resolvePromise();
        }

        function close() {
            popup.value.close();
            resolvePromise();
        }

        function openIntercom() {
            // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomshow
            window.Intercom('show');
        }

        function openTermsAndConditionsModal() {
            // close();
            nextTick(function () {
                bus.emit('openTermsAndConditionsModalOnClick', false);
            });
            
        }

        return {            
            // Functions
            show,
            popup,
            ok,
            close,
            openIntercom,
            openTermsAndConditionsModal,
            // Text
            appName,
            supportEmailAddress,
            supportTelephoneNumber,
            supportTelephoneNumberForLink,
            supportWebsite,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>