
var PriceInterval = function (noOfUnits, bodyAndEquipmentPriceMin, bodyAndEquipmentPriceMax, vehiclePriceMin, vehiclePriceMax) {

    var self = this;
    //#region Private members
    var _noOfUnits,
        _bodyAndEquipmentPriceMin,
        _bodyAndEquipmentPriceMax,
        _vehiclePriceMin,
        _vehiclePriceMax;
    //#endregion Private members

    //#region Getters and setters
    function getNoOfUnits() {
        return _noOfUnits;
    }
    function setNoOfUnits(newValue) {
        _noOfUnits = newValue;
    }
    function getBodyAndEquipmentPriceMin() {
        return _bodyAndEquipmentPriceMin;
    }
    function setBodyAndEquipmentPriceMin(newValue) {
        _bodyAndEquipmentPriceMin = newValue;
    }
    function getBodyAndEquipmentPriceMax() {
        return _bodyAndEquipmentPriceMax;
    }
    function setBodyAndEquipmentPriceMax(newValue) {
        _bodyAndEquipmentPriceMax = newValue;
    }
    function getVehiclePriceMin() {
        return _vehiclePriceMin;
    }
    function setVehiclePriceMin(newValue) {
        _vehiclePriceMin = newValue;
    }
    function getVehiclePriceMax() {
        return _vehiclePriceMax;
    }
    function setVehiclePriceMax(newValue) {
        _vehiclePriceMax = newValue;
    }
    //#endregion Getters and setters

    //#region Initialize
    setNoOfUnits(noOfUnits);
    setBodyAndEquipmentPriceMin(bodyAndEquipmentPriceMin);
    setBodyAndEquipmentPriceMax(bodyAndEquipmentPriceMax);
    setVehiclePriceMin(vehiclePriceMin);
    setVehiclePriceMax(vehiclePriceMax);
    //#endregion Initialize

    //#region Public members
    self.getNoOfUnits = getNoOfUnits;
    self.setNoOfUnits = setNoOfUnits;
    self.getBodyAndEquipmentPriceMin = getBodyAndEquipmentPriceMin;
    self.setBodyAndEquipmentPriceMin = setBodyAndEquipmentPriceMin;
    self.getBodyAndEquipmentPriceMax = getBodyAndEquipmentPriceMax;
    self.setBodyAndEquipmentPriceMax = setBodyAndEquipmentPriceMax;
    self.getVehiclePriceMin = getVehiclePriceMin;
    self.setVehiclePriceMin = setVehiclePriceMin;
    self.getVehiclePriceMax = getVehiclePriceMax;
    self.setVehiclePriceMax = setVehiclePriceMax;
    //#endregion Public members

    //NOTE: This will be removed in the future. Moving to use getters/setters
    //properties
    self.noOfUnits = noOfUnits;
    self.bodyAndEquipmentPriceMin = bodyAndEquipmentPriceMin;
    self.bodyAndEquipmentPriceMax = bodyAndEquipmentPriceMax;
    self.vehiclePriceMin = vehiclePriceMin;
    self.vehiclePriceMax = vehiclePriceMax;
};

export default PriceInterval;

