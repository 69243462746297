var Position = function (x, y, z) {

    var self = this;
    //constructor
    self.x = x || 0;
    self.y = y || 0;
    self.z = z || 0;
    
    self.getX = function () {
        return self.x;
    }
    self.setX = function (newValue) {
        self.x = newValue;
    }
    self.getY = function () {
        return self.y;
    }
    self.setY = function (newValue) {
        self.y = newValue;
    }
    self.getZ = function () {
        return self.z;
    }
    self.setZ = function (newValue) {
        self.z = newValue;
    }


};

export default Position;

