import { shallowRef, computed, watchEffect } from "vue";
import config from "../config";
import offerManager from "../offerManager";
import PermissionObject from "../permissionObject";

function MenuPartBuilder(path) {
  var self = this;
  var newElement = {
    path: path,
    cleanUp: cleanUp,
  };

  var disableClickParamVal = false,
    disableClickFunc;

  //var permissionObject = JSON.parse(JSON.stringify(config.defaultMenuPermissionObject));
  //var permissionObject = new PermissionObject();

  function addDefaultPermissionAttributes() {
    newElement.permissionObject = new PermissionObject();
    //newElement.permissionFunction = permission;//function () { return permissionObject; };
    newElement.permissionFunction = newElement.permissionObject.permission; //function () { return permissionObject; };
    newElement.permissionObv = shallowRef(
      newElement.permissionFunction(newElement.path)
    );
    newElement.disableClick = evaluateDisableClickPermission;

    //permissionObject = new PermissionObject();
    //newElement.permissionFunction = permissionObject.permission;
    //newElement.permissionObv(newElement.permissionFunction());
    //return self;
  }

  function evaluateDisableClickPermission() {
    var resultFromDisablePermissionFunc;

    resultFromDisablePermissionFunc =
      disableClickFunc !== undefined ? disableClickFunc() : undefined;

    return resultFromDisablePermissionFunc !== undefined
      ? resultFromDisablePermissionFunc
      : disableClickParamVal;
  }

  function newMenuItem(menuItemType) {
    addDefaultPermissionAttributes();
    newElement.itemType = menuItemType;
    return self;
  }

  function newMenuStep(label, level, displayLabel) {
    addDefaultPermissionAttributes();
    newElement.label = label;
    newElement.menuStepLevel = level;
    newElement.displayLabel = displayLabel;
    return self;
  }

  function menuStepLevel(level) {
    newElement.menuStepLevel = level;
    return self;
  }

  function newTab(label) {
    addDefaultPermissionAttributes();
    newElement.label = label;

    //var hrefVal = label.split(' ')[0] + '-hrefValue-' + new Date().getTime();
    //var hrefVal = label.replace(/([/ ])/g, '') + '-hrefValue-' + new Date().getTime();
    var hrefVal = label.replace(/[^\w\s]/gi, "");
    hrefVal =
      newElement.path.replace(/[.]/g, "-") +
      "-" +
      hrefVal.replace(/([/ ])/g, "") +
      "-hrefValue";

    newElement.hrefValue = hrefVal;
    return self;
  }

  function newSelectionGroupItem(item) {
    addDefaultPermissionAttributes();
    newElement.itemType = config.ITEM_TYPE.SELECTIONGROUPITEM;

    newElement.id =
      item.getId !== undefined
        ? function () {
            return item.getId();
          }
        : function () {
            return typeof item.getNumber === "function" ? item.getNumber() : "";
            //return item.getNumber();
          };
    //newElement.label = item.getDescription();

    return self;
  }

  function displayId(id) {
    newElement.displayId = id;

    return self;
  }

  function header(headerFunc) {
    newElement.getHeader = computed(function () {
        offerManager.refreshUILiveLabels().value;
        return headerFunc();
    });
    // If we encounter issues with cleanup the below shallowRef/watchEffect combo may provide a more disposable solution
    // newElement.header = shallowRef(headerFunc()); 
    // newElement.cleanUpHeaderWatcher = watchEffect(function () {
    //     offerManager.refreshUILiveLabels().value;
    //     newElement.header.value = headerFunc();
    // });
    return self;
  }

  function detail(detailFunc) {
    newElement.getDetail = computed(function () {
        offerManager.refreshUILiveLabels().value;
        return detailFunc();
    });
    
    return self;
  }

  function label(lbl) {
    newElement.label = lbl;
    newElement.displayLabel = true;
    return self;
  }

  function saveTooltip(st) {
    newElement.getSaveTooltip = st;
    return self;
  }

  function removeTooltip(rt) {
    newElement.getRemoveTooltip = rt;
    return self;
  }

  function copyTooltip(ct) {
    newElement.getCopyTooltip = ct;
    return self;
  }

  //function permission(permissionFunction) {
  //    //newElement.permissionFunction = permissionFunction;
  //    newElement.permissionObv().visible = permissionFunction();

  //    return self;
  //}
  function permission(path) {
    var evaluatedPermissionObject =
      newElement.permissionObject.permission(path);

    return evaluatedPermissionObject;
  }

  function visiblePermission(permissionFunction) {
    newElement.permissionObject.visiblePermission(permissionFunction);
    //TScMessenger.writeDebugMessage('permissionObject visible = ' + permissionObject.visible);
    //permissionObject.visible = function(){return permissionFunction(newElement.path);}();
    ////permissionObject.visible = permissionFunction();
    ////permissionObject.visible = function(){return permissionFunction();};
    //TScMessenger.writeDebugMessage('permissionObject visible = ' + permissionObject.visible);
    //newElement.permissionFunction = function(){return permissionObject;};
    //newElement.permissionObv(newElement.permissionFunction());

    return self;
  }

  function readOnlyPermission(permissionFunction) {
    newElement.permissionObject.readOnlyPermission(permissionFunction);

    return self;
  }

  function readOnly(isReadOnly) {
    newElement.isReadOnly = isReadOnly;
    return self;
  }

  function disableClick(optionalBooleanParam) {
    //newElement.disableClick = optionalBooleanParam || true;
    disableClickParamVal =
      optionalBooleanParam !== undefined ? optionalBooleanParam : true;
    return self;
  }

  function disableClickPermission(disableFunc) {
    disableClickFunc = disableFunc;
    return self;
  }

  function allowCopy(optionalParam) {
    newElement.allowCopy = optionalParam;

    return self;
  }

  /**
   *
   * @param {function} toggleFunc - Function called when user clicks on the pin on the menu card. This function should return boolean value which will be used to show icon on card
   * @param {boolean} isPinned - Boolean value that determines whether the pin on the card is active
   * @param {boolean} allowPin - Boolean value that determines whether the pin is displayed on the card
   * @returns {Object} Returns MenuPartBuilder object
   */
  function allowPin(toggleFunc, isPinned, allowPin) {
    newElement.pinClickFunction = toggleFunc;
    newElement.isPinned = typeof isPinned === "boolean" ? isPinned : false;
    newElement.allowPin = typeof allowPin === "boolean" ? allowPin : false;
    //newElement.allowPin = typeof (optionalParam) === 'boolean' ? optionalParam : false;
    return self;
  }

  function tooltip(tt) {
    newElement.tooltipMessage = tt;

    return self;
  }

  function items(itemsArray) {
    newElement.items = itemsArray;
    return self;
  }

  function tabs(tabsArray) {
    newElement.tabs = tabsArray;
    return self;
  }

  function selectedItems(selectedItemsObservableArray) {
    newElement.selectedItems = selectedItemsObservableArray;
    return self;
  }

  function nestedStep(step) {
    newElement.nestedStep = step;
    return self;
  }

  function asAccordion(ati, hs) {
    var value = ati !== undefined && ati !== null ? ati : "accordion";
    newElement.displayType = "accordion";
    //newElement.attrTabId = value + '_' + new Date().getTime();
    //newElement.attrTabId = ati + '_' + new Date().getTime();
    newElement.attrTabId = newElement.path.replace(/[.]/g, "-") + "-" + value;
    newElement.heightStyle =
      typeof hs === "string"
        ? hs
        : config.ACCORDION_HEIGHT_STYLE_OPTIONS.CONTENT;
    return self;
  }

  function asTabs(ati, button) {
    var value = ati !== undefined ? ati : "tabs";
    newElement.displayType = "tabs";
    //newElement.attrTabId = value + '_' + new Date().getTime();
    //newElement.attrTabId = ati + '_' + new Date().getTime();
    newElement.attrTabId = newElement.path.replace(/[.]/g, "-") + "-" + value;
    if (button !== undefined) {
      newElement.displayButtonBesideTabs = true;
      newElement.button = button;
    } else {
      newElement.displayButtonBesideTabs = false;
    }
    return self;
  }

  function asButton() {
    newElement.type = "button";
    return self;
  }

  function hrefValue(hrefVal) {
    newElement.hrefValue = hrefVal;
    return self;
  }

  function goHomeFromCrumb() {
    newElement.goToHomeStepFromBreadcrumb = true;
    return self;
  }

  function doNotDelete(dontDelete) {
    newElement.doNotDelete = dontDelete;
    return self;
  }

  function clickListener(clickCallbackFunc) {
    newElement.click = clickCallbackFunc;
    return self;
  }

  function displayAccordionOnStep(ahs) {
    newElement.displayAccordionOnStep = true;
    newElement.accordionHeightStyle =
      typeof ahs === "string"
        ? ahs
        : config.ACCORDION_HEIGHT_STYLE_OPTIONS.CONTENT;
    return self;
  }

  function displayAccessories() {
    newElement.displayAccessories = true;

    return self;
  }

  function addToBreadCrumbs() {
    newElement.addToBreadcrumbs = true;

    return self;
  }

  function displayBreadcrumbsButtonOnStep(buttons, type) {
    newElement.displayBreadcrumbsButtonOnStep = true;
    newElement.breadcrumbsButtonsOnStep = buttons;
    newElement.breadcrumbButtonsDisplayType = type;
    return self;
  }

  function displayButton() {
    newElement.displayButton = true;
    return self;
  }

  function displayDropDownButton(dropDownOptions, dropDownHasOptions, removeItemCallback) {
    newElement.displayDropDownButton = true;
    newElement.dropDownOptions = dropDownOptions;
    newElement.dropDownHasOptions = typeof dropDownHasOptions === 'boolean' ? dropDownHasOptions : false;
    newElement.removeItemCallback = typeof removeItemCallback === 'function' ? removeItemCallback : null;
    return self;
  }

  function customCssClasses(customCssClasses) {
    newElement.customCss = customCssClasses;
    return self;
  }

  function preventSelectionGroupItemActivationCallback(callback) {
    newElement.preventSelectionGroupItemActivationCallback = callback;
    return self;
  }

  function showTooltipCallback(callback) {
    newElement.showTooltipCallback = callback;
    return self;
  }

  function displayMenuTabsWithButtons(muf, mdf, df) {
    //newElement.displayMenuTabsWithButtons = true;
    newElement.menuTabDisplayType =
      config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_BUTTONS;
    newElement.moveUpFunction = muf;
    newElement.moveDownFunction = mdf;
    newElement.deleteFunction = df;
    return self;
  }

  function displayMenuTabsWithInput() {
    newElement.menuTabDisplayType =
      config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_INPUT;
    return self;
  }

  function displayMenuTabsWithTotal() {
    newElement.menuTabDisplayType =
      config.MENU_TAB_DISPLAY_TYPES.LABEL_AND_TOTAL;
    return self;
  }

  function displayMenuTabWithInput(i) {
    newElement.displayInputOnTab = true;
    newElement.menuTabInput = i;
    return self;
  }

  function displayMenuTabWithTotal(tt) {
    newElement.totalText = tt;
    return self;
  }

  /**
   * Add an item to a selection group using a selection wizard
   * @param {Object} sw - Selection Wizard Object
   * @returns {Object} Returns MenuPartBuilder object
   */
  function addItemUsingSelectionWizard(sw) {
    newElement.selectionWizardObject = sw;
    return self;
  }

  /**
   * Set displayLabel = false so that it won't appear for selection groups and selection wizards
   * @returns {Object} Returns MenuPartBuilder object
   */
  function hideLabel() {
    newElement.displayLabel = false;
    return self;
  }

  /**
   * Display save icon on selection group menu card
   * @param {function} sf - Callback for saving item to database
   * @param {function} df - Callback for displaying the save icon on the card. Defaults to anonyymous function that returns true.
   * @param {boolean} dsb - Determines whether the save button is displayed on the menu card
   * @returns {Object} Returns MenuPartBuilder object
   */
  function allowSave(sf, df, dsb) {
    if (sf !== undefined) {
      newElement.allowSave = true;
      newElement.saveFunctionCallback = sf;
      newElement.displaySaveFunctionCallback =
        df !== undefined
          ? df
          : function () {
              return true;
            };
      newElement.displaySaveButtonOnCard =
        typeof dsb === "boolean" ? dsb : true;
    } else {
      newElement.allowSave = false;
    }

    return self;
  }

  /**
   *
   * @param {function} ef - Callback when edit button is clicked
   * @param {function} df - Callback for displaying the edit icon. Defaults to anonyymous function that returns true.
   * @returns {Object} Returns MenuPartBuilder object
   */
  function allowEdit(ef, df) {
    if (typeof ef === "function") {
      newElement.allowEdit = true;
      newElement.editFunctionCallback = ef;
      newElement.displayEditFunctionCallback =
        df !== undefined
          ? df
          : function () {
              return true;
            };
    } else {
      newElement.allowEdit = false;
    }

    return self;
  }

  /**
   * https://github.com/sandywalker/webui-popover#default-options
   * @param {string} id - id to be used in the HTML of input tooltip element
   * @param {function|string} html - Function that creates the message to be displayed to the user. Should return valid HTML
   * @param {function} [onShow] - Function to be called when the tooltip is displayed. Add click functions go here. Parameter is element
   * @param {function} [onHide] - Function to be called when the tooltip is closed. Parameter is element
   * @param {string} [classes] - Adds a classname to the container for styling, prefixed by `webui-popover-`. Default '' (light theme), 'inverse' for dark theme
   * @returns {Object} MenuItemBuilder object
   */
  function newMenuInputTooltip(id, html, onShow, onHide, classes) {
    if (typeof id !== "string") {
      throw "id parameter is not a string in newMenuInputTooltip";
    }
    if (html === undefined) {
      throw "html parameter not defined in newMenuInputTooltip";
    }
    addDefaultPermissionAttributes();

    var options = {
      trigger: "manual",
      closeable: true,
      animation: "pop",
      content: html,
      cache: false,
    };

    if (typeof onShow === "function") {
      options.onShow = onShow;
    }

    if (typeof onHide === "function") {
      options.onHide = onHide;
    }

    if (typeof classes === "string") {
      options.style = classes;
    }

    //newElement.inputTooltipObject = {
    //    id: id,
    //    options: options
    //};
    newElement.id = id;
    newElement.options = options;

    return self;
  }

  /**
   * https://github.com/sandywalker/webui-popover#default-options
   * @param {string} id - id to be used in the HTML of input tooltip element
   * @param {function|string} html - Function that creates the message to be displayed to the user. Should return valid HTML
   * @param {function} [onShow] - Function to be called when the tooltip is displayed. Add click functions go here. Parameter is element
   * @param {function} [onHide] - Function to be called when the tooltip is closed. Parameter is element
   * @param {string} [classes] - Adds a classname to the container for styling, prefixed by `webui-popover-`. Default '' (light theme), 'inverse' for dark theme
   * @returns {Object} MenuItemBuilder object
   */
  function newMenuInfoTooltip(id, html, onShow, onHide, classes) {
    if (typeof id !== "string") {
      throw "id parameter is not a string in newMenuInfoTooltip";
    }
    if (html === undefined) {
      throw "html parameter not defined in newMenuInfoTooltip";
    }
    addDefaultPermissionAttributes();

    var options = {
      trigger: "manual",
      closeable: true,
      animation: "pop",
      content: html,
      cache: false,
    };

    if (typeof onShow === "function") {
      options.onShow = onShow;
    }

    if (typeof onHide === "function") {
      options.onHide = onHide;
    }

    if (typeof classes === "string") {
      options.style = classes;
    }

    newElement.id = id;
    newElement.options = options;

    return self;
  }

  /**
   *
   * @param {Object[]} rows - The rows to be displayed in the table. Should be generated using newMenuTableRow
   * @param {Object[]} [headerRow] - The row to be displayed in the table header. Should be generated using newMenuTableRow
   * @param {string} [customCss] - String value containing the custom CSS class to be attached to the table
   * @returns {Object} MenuPartBuilder object
   */
  function newMenuTable(rows, headerRow, customCss) {
    if (!Array.isArray(rows)) {
      throw "rows param in newMenuTable must be an array";
    }
    //if (headerRow !== undefined && headerRow !== null && !Array.isArray(headerRow)) {
    //    throw 'headerRow param in newMenuTable must be an array';
    //}
    //if (Array.isArray(headerRow) && Array.isArray(headerRow[0])) {
    //    throw 'headerRow param in newMenuTable must be a 1 dimensional array';
    //}
    addDefaultPermissionAttributes();
    newElement.itemType = config.ITEM_TYPE.MENU_TABLE;
    if (headerRow !== undefined && headerRow !== null) {
      newElement.includeHeader = true;
      newElement.headerRow = headerRow;
    }
    newElement.rows = rows;
    newElement.customCss = typeof customCss === "string" ? customCss : "";
    return self;
  }

  function newMenuTableRow(cells) {
    if (!Array.isArray(cells)) {
      throw "cells param in newMenuTableRow must be an array";
    }
    newElement.itemType = config.ITEM_TYPE.MENU_TABLE_ROW;
    newElement.cells = cells;
    return self;
  }

  function newMenuTableTextCell(cellObject) {
    newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
    newElement.cellType = config.MENU_TABLE_CELL_TYPES.TEXT;
    if (cellObject !== undefined) {
      newElement.cellObject = cellObject;
    } else {
      newElement.cellObject = {
        value: "Cell Item",
      };
    }

    return self;
  }

  function newMenuTableInputCell(cellInputObject) {
    newElement.itemType = config.ITEM_TYPE.MENU_TABLE_CELL;
    newElement.cellType = config.MENU_TABLE_CELL_TYPES.INPUT;
    newElement.cellObject = cellInputObject;
    return self;
  }

  function addCustomCssClassesForTdElement(ccft) {
    newElement.customCssForTd = typeof ccft === "string" ? ccft : "";
    return self;
  }

  function doNotIncludeCallback() {
    newElement.doNotIncludeCallback = true;
    return self;
  }

  function keepAllAccordionsClosedWhenInitialized() {
    newElement.allAccordionsClosedWhenInitialized = true;
    return self;
  }

  function build() {
    if (newElement.permissionFunction) {
      newElement.permissionObv.value = newElement.permissionFunction(newElement.path);
    }
    return newElement;
  }

  function cleanUp() {
    for (var member in newElement) {
      var breakHere = 0;
      delete newElement[member];
    }
  }

  this.label = label;
  this.permission = permission;
  this.visiblePermission = visiblePermission;
  this.readOnlyPermission = readOnlyPermission;
  this.readOnly = readOnly;
  this.items = items;
  this.tabs = tabs;
  this.nestedStep = nestedStep;
  this.menuStepLevel = menuStepLevel;
  this.asAccordion = asAccordion;
  this.asTabs = asTabs;
  this.selectedItems = selectedItems;
  this.newSelectionGroupItem = newSelectionGroupItem;
  this.newTab = newTab;
  this.asButton = asButton;
  this.goHomeFromCrumb = goHomeFromCrumb;
  this.hrefValue = hrefValue;
  this.newMenuStep = newMenuStep;
  this.newMenuItem = newMenuItem;
  this.displayAccordionOnStep = displayAccordionOnStep;
  this.header = header;
  this.detail = detail;
  this.saveTooltip = saveTooltip;
  this.removeTooltip = removeTooltip;
  this.copyTooltip = copyTooltip;
  this.disableClick = disableClick;
  this.disableClickPermission = disableClickPermission;
  this.displayId = displayId;
  this.doNotDelete = doNotDelete;
  this.clickListener = clickListener;
  this.displayAccessories = displayAccessories;
  this.addToBreadCrumbs = addToBreadCrumbs;
  this.tooltip = tooltip;
  this.allowCopy = allowCopy;
  this.allowPin = allowPin;
  this.displayBreadcrumbsButtonOnStep = displayBreadcrumbsButtonOnStep;
  this.displayButton = displayButton;
  this.displayDropDownButton = displayDropDownButton;
  this.customCssClasses = customCssClasses;
  this.preventSelectionGroupItemActivationCallback =
    preventSelectionGroupItemActivationCallback;
  this.showTooltipCallback = showTooltipCallback;
  this.displayMenuTabsWithButtons = displayMenuTabsWithButtons;
  this.displayMenuTabsWithInput = displayMenuTabsWithInput;
  this.displayMenuTabsWithTotal = displayMenuTabsWithTotal;
  this.displayMenuTabWithInput = displayMenuTabWithInput;
  this.displayMenuTabWithTotal = displayMenuTabWithTotal;
  this.addItemUsingSelectionWizard = addItemUsingSelectionWizard;
  this.hideLabel = hideLabel;
  this.allowSave = allowSave;
  this.allowEdit = allowEdit;
  this.newMenuInputTooltip = newMenuInputTooltip;
  this.newMenuInfoTooltip = newMenuInfoTooltip;
  this.newMenuTable = newMenuTable;
  this.newMenuTableRow = newMenuTableRow;
  this.newMenuTableTextCell = newMenuTableTextCell;
  this.newMenuTableInputCell = newMenuTableInputCell;
  this.addCustomCssClassesForTdElement = addCustomCssClassesForTdElement;
  this.doNotIncludeCallback = doNotIncludeCallback;
  this.cleanUp = cleanUp;
  this.keepAllAccordionsClosedWhenInitialized =
    keepAllAccordionsClosedWhenInitialized;
  this.build = build;
}

export default MenuPartBuilder;
