import config from '../config';

    /**
     * 
     */
    function DocumentDefinitionBuilder() {
        var self = this;
        var newElement = {};

        function base() {
            newElement.pageOrientation = 'portrait';
            //newElement.pageMargins = [0, 0, 0, 0];
        }

        /**
         * Create PdfMake document definition
         */
        function newDocumentDefinition() {
            base();
            return self;
        }

        /**
         * 
         * @param {Object[]} nc - List of PdfMake elements to be displayed in PDF
         */
        function setContent(nc) {
            newElement.content = nc;
            return self;
        }

        /**
         * 
         * @param {function} f - callback function to be used in footer
         */
        function setFooter(f) {
            newElement.footer = f;
            return self;
        }

        /**
         * 
         * @param {function|Object} h - header object/function
         */
        function setHeader(h) {
            newElement.header = h;
            return self;
        }

        /**
         * 
         * @param {Object} s - Object with name of style and details
         */
        function addStyle(s) {
            if(newElement.styles === undefined) {
                newElement.styles = {};    
            }
            newElement.styles[s.styleName] = s;
            return self;
        }

        /**
         * 
         * @param {Object} ds - style object for 
         */
        function setDefaultStyle(ds) {
            newElement.defaultStyle = ds;
            return self;
        }

        /**
         * * Overwrite default margin of element
         * margin: [left, top, right, bottom]
         * { text: 'sample', margin: [ 5, 2, 10, 20 ] },
         * margin: [horizontal, vertical]
         * { text: 'another text', margin: [5, 2] },
         * margin: equalLeftTopRightBottom
         * { text: 'last one', margin: 5 }
         * @param {number[]} pm - values for document margins
         */
        function setPageMargins(pm) {
            newElement.pageMargins = pm;
            return self;
        }

        /**
         * Set document orientation to landscape. Document defaults to portrait.
         */
        function setPageOrientationToLandscape() {
            newElement.pageOrientation = 'landscape';
            return self;
        }

        /**
         * Set the size of the page for the document. Defaults to config.PDFMAKE_PAGE_SIZE_OPTIONS.A4.
         * @param {string} ps - Page size to set the document. Should be available on PDFMAKE_PAGE_SIZE_OPTIONS on config
         */
        function setPageSize(ps) {
            newElement.pageSize = typeof ps === 'string' ? ps : config.PDFMAKE_PAGE_SIZE_OPTIONS.A4;
            return self;
        }

        /**
         * Returns the built PdfMake element
         * @return {Object} - built object
         */
        function build() {
            return newElement;
        }

        this.newDocumentDefinition = newDocumentDefinition;
        this.setContent = setContent;
        this.setFooter = setFooter;
        this.setHeader = setHeader;
        this.addStyle = addStyle;
        this.setDefaultStyle = setDefaultStyle;
        this.setPageMargins = setPageMargins;
        this.setPageOrientationToLandscape = setPageOrientationToLandscape;
        this.setPageSize = setPageSize;
        this.build = build;
    }

    /**
     * 
     */
    function PdfMakeElementBuilder() {
        var self = this;
        var newElement = {};

        /**
         * base items for all PDFMake elements
         */
        function base() {
            newElement.margin = 0;
        }

        // #region New PdfMake Element functions

        /**
         * 
         * @param {string} s - name of style
         */
        function newStyleElement(s) {
            newElement.styleName = s;
            return self;
        }

        /**
         * Add text to PDF
         * @param {string} te - Text to be written to PDF
         */
        function newTextElement(te) {
            base();
            newElement.text = te;
            return self;
        }

        /**
         * Add a list of items using bullet points to PDF
         * @param  {Object[]} bl - Array that contains content to be added to bullet point list
         */
        function newBulletedListElement(bl) {
            base();
            newElement.ul = bl;
            return self;
        }

        /**
         * Add a list of items using numbered points to PDF
         * @param {Object[]} nl - Array that contains content to be added to numbered list
         */
        function newNumberedListElement(nl) {
            base();
            newElement.ol = nl;
            return self;
        }

        /**
         * Create new table to be 
         * @param {Object[]} b - body
         */
        function newTableElement(b) {
            var tableElement = {};
            base();
            tableElement.body = b;

            //try {
            //    // Go through body and make sure that columns all match to prevent error when creating PDF
            //    b.forEach(function (tableRow) {
            //        if (tableRow.length === 3) {
            //            tableElement.body.push(tableRow);
            //        } else {
            //            // check if rowspan is present
            //            // otherwise add blank column ('')
            //            throw new Error("The number of columns in tableRow do not match table columns");
            //        }
            //    })
            //    tableElement.body = b;
            //} catch (e) {
            //    e.message;
            //}
            newElement.table = tableElement;
            return self;
        }

        /**
         * Create an image element
         * @param {string} i - image name or dataUrl 
         */
        function newImageElement(i) {
            base();

            newElement.image = i;
            return self;
        }

        /**
         * Create columns element
         */
        function newColumns() {
            newElement.columns = [];
            return self;
        }

        /**
         * 
         * @param {Object} col - Object with width and element type
         * @returns {Object} builder
         */
        function addColumn(col) {
            if(col.width === undefined) {
                col.width = '*';
            }
            newElement.columns.push(col);
            return self;
        }

        /**
         * 
         * @param {Object[]} s - Stack of PdfMake elements
         * @returns {Object} self
         */
        function newStackElement(s) {
            newElement.stack = s;
            return self;
        }
        // #endregion New PdfMake Element functions

        // #region Change defaults

        /**
         * Overwrite default margin of element
         * margin: [left, top, right, bottom]
         * { text: 'sample', margin: [ 5, 2, 10, 20 ] },
         * margin: [horizontal, vertical]
         * { text: 'another text', margin: [5, 2] },
         * margin: equalLeftTopRightBottom
         * { text: 'last one', margin: 5 }
         * @param {number|number[]} am - Can be Number or Array of numbers
         */
        function setMargin(am) {
            newElement.margin = am;
            return self;
        }

        /**
         * Add named style to element
         * @param {string|string[]} as - Name of style to be added that must exist in document definition. Can also be an array of string names. Last name values takes precedence except for margin values
         */
        function setStyle(as) {
            newElement.style = as;
            return self;
        }

        /**
         * Font for element will be bold
         */
        function addBold() {
            newElement.bold = true;
            return self;
        }

        /**
         * Font for element will be italics
         */
        function addItalics() {
            newElement.italics = true;
            return self;
        }

        /**
         * Change the PdfMake element colour 
         * @param {string} c - colour for element
         */
        function setColour(c) {
            newElement.color = c;
            return self;
        }

        /**
         * Change the PdfMake element font size
         * @param {number} fs - font size for element
         */
        function setFontSize(fs) {
            newElement.fontSize = fs;
            return self;
        }

        /**
         * Make sure that text won't wrap
         */
        function addNoWrap() {
            newElement.noWrap = true;
            return self;
        }

        /**
         * Add page break before element
         */
        function addPageBreakBeforeElement() {
            newElement.pageBreak = 'before';
            return self;
        }

        /**
         * Add page break after element
         */
        function addPageBreakAfterElement() {
            newElement.pageBreak = 'after';
            return self;
        }

        /**
         * 
         * @param {number} w - Width of element
         */
        function setWidth(w) {
            newElement.width = w;
            return self;
        }

        /**
         * 
         * @param {number} h - Height value
         */
        function setHeight(h) {
            newElement.height = h;
            return self;
        }

        /**
         * Related to image elements
         * @param {number[]} f - Fit value [width, height]
         */
        function setFit(f) {
            newElement.fit = f;
            return self;
        }

        /**
         * Add noBorders layout for table
         */
        function addNoBordersLayout() {
            newElement.layout = 'noBorders';
            return self;
        }


        function sethLineWidth(hlw) {
            if(newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.hLineWidth = hlw;
            return self;
        }

        function setvLineWidth(vlw) {
            if(newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.vLineWidth = vlw;
            return self;
        }

        function sethLineColor(hlc) {
            if(newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.hLineColor = hlc;
            return self;
        }

        function setvLineColor(vlc) {
            if(newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.vLineColor = vlc;
            return self;
        }

        /**
         * 
         * @param {number} hr - number of header rows in table
         */
        function addHeaderRows(hr) {
            newElement.table.headerRows = hr !== undefined ? hr : 1;
            return self;
        }

        /**
         * 
         * @param {number[]|string[]} aw - Array of column widths for table. Can be number, 'auto, or '*'
         */
        function setColumnWidthsForTable(aw) {
            newElement.table.widths = aw;
            return self;
        }

        /**
         * 
         * @param {string} a - Alignment of text (left, right, justify)
         */
        function setAlignment(a) {
            newElement.alignment = a;
            return self;
        }

        /**
         * 
         * @param {string} f - Change font used in style/element
         */
        function setFont(f) {
            newElement.font = f;
            return self;
        }

        /**
         * Set the fill colour of a cell in a table
         * @param {string} fc - Can be colour name or hex code (eg. 'red' or '#ff0000' gives the same result)
         */
        function setFillColour(fc) {
            newElement.fillColor = fc;
            return self;
        }

        /**
         * 
         * @param {number} cs - number of columns that are spanned
         */
        function setColSpan(cs) {
            newElement.colSpan = cs;
            return self;
        }

        /**
         * 
         * @param {number} rs - number of rows that are spanned
         */
        function setRowSpan(cs) {
            newElement.rowSpan = cs;
            return self;
        }

        /**
         * 
         * @param {number} apx - Number with value of absolute position on X axis
         */
        function setAbsolutePositionX(apx) {
            if (newElement.absolutePosition === undefined) {
                newElement.absolutePosition = {};
            }
            newElement.absolutePosition.x = apx;
            return self;
        }

        /**
         * 
         * @param {number} apy - Number with value of absolute position on y axis
         */
        function setAbsolutePositionY(apy) {
            if (newElement.absolutePosition === undefined) {
                newElement.absolutePosition = {};
            }
            newElement.absolutePosition.y = apy;
            return self;
        }

        /**
         * 
         * @param {string|function} flc - colour of the background of the table row - can be hexadecimal
         */
        function setFillColourForTable(flc) {
            if (newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.fillColor = flc;
            return self;
        }

        /**
         * 
         * @param {boolean[]} b - Array of 4 booleans to add border to cell. [left, top, right, bottom]
         */
        function addBorderToCell(b) {
            newElement.border = b;
            return self;
        }

        /**
         * Remove default border from table element
         */
        function removeDefaultBorder() {
            if (newElement.layout === undefined) {
                newElement.layout = {};
            }
            newElement.layout.defaultBorder = false;
            return self;
        }

        /**
         * 
         * @param {function|number} h - number/function that sets the height of the cells iun a table. Function can use (row) argument.
         */
        function addCellHeightsToTable(h) {
            newElement.table.heights = h;
            return self;
        }
        // #endregion Change defaults

        /**
         * Change the page that an element appears on to be shown in landscape mode
         * Should be paired with pageBreak
         */
        function setPageOrientationToLandscape() {
            newElement.pageOrientation = 'landscape';
            return self;
        }

        /**
         * Change the page that an element appears on to be shown in portrait mode
         * Should be paired with pageBreak
         */
        function setPageOrientationToPortrait() {
            newElement.pageOrientation = 'portrait';
            return self;
        }

        /**
         * Used to reduce line height in block of text to move them closer together or further apart
         * @param {number} lh - New line height value
         */
        function setLineHeight(lh) {
            newElement.lineHeight = lh;
            return self;
        }

        function preserveLeadingSpaces() {
            newElement.preserveLeadingSpaces = true;
            return self;
        }

        /**
         * Returns the built PdfMake element
         * @return {Object} - built object
         */
        function build() {
            return newElement;
        }

        this.newTextElement = newTextElement;
        this.newStyleElement = newStyleElement;
        this.newBulletedListElement = newBulletedListElement;
        this.newNumberedListElement = newNumberedListElement;
        this.newTableElement = newTableElement;
        this.newImageElement = newImageElement;
        this.newColumns = newColumns;
        this.addColumn = addColumn;
        this.newStackElement = newStackElement;
        this.setStyle = setStyle;
        this.setMargin = setMargin;
        this.addBold = addBold;
        this.addItalics = addItalics;
        this.setColour = setColour;
        this.setFontSize = setFontSize;
        this.addNoWrap = addNoWrap;
        this.addPageBreakBeforeElement = addPageBreakBeforeElement;
        this.addPageBreakAfterElement = addPageBreakAfterElement;
        this.setWidth = setWidth;
        this.setColumnWidthsForTable = setColumnWidthsForTable;
        this.setHeight = setHeight;
        this.setFit = setFit;
        this.addNoBordersLayout = addNoBordersLayout;
        this.sethLineWidth = sethLineWidth;
        this.setvLineWidth = setvLineWidth;
        this.sethLineColor = sethLineColor;
        this.setvLineColor = setvLineColor;
        this.addHeaderRows = addHeaderRows;
        this.setAlignment = setAlignment;
        this.setFont = setFont;
        this.setFillColour = setFillColour;
        this.setColSpan = setColSpan;
        this.setRowSpan = setRowSpan;
        this.setAbsolutePositionX = setAbsolutePositionX;
        this.setAbsolutePositionY = setAbsolutePositionY;
        this.setFillColourForTable = setFillColourForTable;
        this.addBorderToCell = addBorderToCell;
        this.removeDefaultBorder = removeDefaultBorder;
        this.addCellHeightsToTable = addCellHeightsToTable;
        this.setPageOrientationToLandscape = setPageOrientationToLandscape;
        this.setPageOrientationToPortrait = setPageOrientationToPortrait;
        this.setLineHeight = setLineHeight;
        this.preserveLeadingSpaces = preserveLeadingSpaces;
        this.build = build;
    }

    var pdfBuilders =  {
        DocumentDefinitionBuilder: DocumentDefinitionBuilder,
        PdfMakeElementBuilder: PdfMakeElementBuilder
    };

    export default pdfBuilders;