import config from '../../../services/config';
import globals from '../../../services/globals';
// import utils from '@/services/utils';
// import { config, utils, globals } from '../../../services';
import { shallowRef } from 'vue';

var TrailerStub = function (id, description, source, type, status, editLevel, accessoryType, accessLevel, updateCounter, make, range, bodyType, cachedState) {
    var self = this;
    //#region Private members


    /*
    Public Property Id As Integer
    Public Property Description As String
    Public Property Source As String
    Public Property Type As String
    Public Property BodyType As String
    Public Property NumberOfAxlesFront As Integer
    Public Property NumberOfAxlesRear As Integer
    Public Property Status As String
    Public Property AccessoryType As String
    Public Property UpdateCounter As Integer
    */
    var _id = id,
        _description = description,
        _source = source,
        _type = type,
        _status = status,
        _editLevel = editLevel,
        _accessoryType = accessoryType,
        _accessLevel = accessLevel,
        _updateCounter = updateCounter || 0,
        _make = make,
        _range = range,
        _bodyType = bodyType,
        _cached = cachedState || 0;

    if(_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM && (_bodyType === undefined || _bodyType === null || _bodyType === '')) {
        // Discussed with Martin and decided to set the body type to none for custom trailers if no value is provided
        _bodyType = config.TRAILER_BODY_TYPES.NONE;
    }
    //#endregion Private members

    //#region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getSource() {
        return _source;
    }
    function setSource(newValue) {
        _source = newValue;
    }
    function getType() {
        return _type;
    }
    function setType(newValue) {
        _type = newValue;
    }
    function getStatus() {
        return _status;
    }
    function setStatus(newValue) {
        _status = newValue;
    }
    function getEditLevel() {
        return _editLevel;
    }
    function getAccessoryType() {
        return _accessoryType;
    }
    function setAccessoryType(newValue) {
        _accessoryType = newValue;
    }
    function getAccessLevel() {
        return _accessLevel;
    }
    function setAccessLevel(newValue) {
        _accessLevel = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getMake() {
        return _make;
    }
    function setMake(newValue) {
        _make = newValue;
    }
    function getRange() {
        return _range;
    }
    function setRange(newValue) {
        _range = newValue;
    }
    function getBodyType() {
        return _bodyType;
    }
    function setBodyType(newValue) {
        _bodyType = newValue;
    }
    function getTrailerTypeText(path) {
        return globals.getTrailerTypeText(_type, path);
    }

    function getTrailerTypeSortValue() {
        switch (_type) {
            case config.TRAILER_TYPES.DRAWBAR:
                return 1;
            case config.TRAILER_TYPES.PUP:
                return 2;
            case config.TRAILER_TYPES.INTERLINK:
                return 3;
            case config.TRAILER_TYPES.SEMI:
                return 4;
        }
    }

    function getCached() {
        return _cached;
    }
    function setCached(newValue) {
        _cached = newValue;
    }
    //function getImage() {
    //    var folder = 'EU',
    //        userCultureCode = globals.user.getCultureCode();
    //    if (userCultureCode.toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase()) {
    //        folder = config.LANGUAGE_PACK.ENGLISH.US.imageFolder;
    //    }
    //    return folder + '/' + 'VAN';
    //}
    function getImageClass() {
        var folder = 'EU',
            userCultureCode = globals.getActiveCultureCodeForTranslations();
        if (globals.isAmericanCultureCode(userCultureCode) === true) {
            folder = config.LANGUAGE_PACK.ENGLISH.US.imageFolder;
        }
        return folder.toLowerCase() + '-' + _type.toLowerCase();
    }

    function getIntercomMetadata(details) {
        var returnObject = {};
        returnObject.Description = this.getDescription();
        //returnObject.Type = this._accessoryType;
        returnObject.SubType = this.getType();
        //returnObject.Source = this._source;

        return returnObject;
    }

    function getStatusForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            // return config.getTranslationText('4928');
            return config.ACCESSORY_STATUS_TYPES.CURRENT;
        }
        return _status;
    }
    function getMakeForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            return config.STUB_MULTISELECT_NOT_SPECIFIED;
            // return config.getTranslationText('4929');
        }
        return _make;
    }
    function getBodyTypeText() {
        return globals.getBodyTypeText(_bodyType);
    }
    //#endregion Getter and setters

    self.getId = getId;
    self.setId = setId;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getType = getType;
    self.setType = setType;
    self.getSource = getSource;
    self.setSource = setSource;
    self.getStatus = getStatus;
    self.setStatus = setStatus;
    self.getEditLevel = getEditLevel;
    self.getAccessoryType = getAccessoryType;
    self.setAccessoryType = setAccessoryType;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getTrailerTypeText = getTrailerTypeText;
    self.getCached = getCached;
    self.setCached = setCached;
    self.getTrailerTypeSortValue = getTrailerTypeSortValue;
    self.getImageClass = getImageClass;
    self.getIntercomMetadata = getIntercomMetadata;
    self.imageAvailable = shallowRef(true);
    self.setAccessLevel = setAccessLevel;
    self.getAccessLevel = getAccessLevel;
    self.getMake = getMake;
    self.getRange = getRange;
    self.getBodyType = getBodyType;
    self.getStatusForMultiSelect = getStatusForMultiSelect;
    self.getMakeForMultiSelect = getMakeForMultiSelect;
    self.getBodyTypeText = getBodyTypeText;
};
    
export default TrailerStub;
