import SpecificationHeading from './model.specificationHeading';
import SpecificationVehicle from './model.specificationVehicle';
import SpecificationAdvantage from './model.specificationAdvantage';



var Specification = function (headings, baseVehicle, competitorVehicles) {

    var self = this;
    //properties
    var _headings,
        _baseVehicle,
        _competitorVehicles,
        _selectedCompetitor1,
        _selectedCompetitor2,
        _vehicleTypeNameCodeFilter,
        _subCategory1CodeFilter,
        _subCategory2CodeFilter,
        _statusCodeFilter;
    
    //#region Getters and setters
    function getHeadings() {
        return _headings;
    }
    function setHeadings(newValue) {
        _headings = newValue;
    }
    function getBaseVehicle() {
        return _baseVehicle;
    }
    function setBaseVehicle(newValue) {
        _baseVehicle = newValue;
    }
    function getCompetitorVehicles() {
        return _competitorVehicles;
    }
    function setCompetitorVehicles(newValue) {
        _competitorVehicles = newValue;
    }
    function getSelectedCompetitor1() {
        return _selectedCompetitor1;
    }
    function setSelectedCompetitor1(newValue) {
        _selectedCompetitor1 = newValue;
    }
    function getSelectedCompetitor2() {
        return _selectedCompetitor2;
    }
    function setSelectedCompetitor2(newValue) {
        _selectedCompetitor2 = newValue;
    }
    function getSelectedCompetitor1Id() {
        if (getSelectedCompetitor1() !== null) {
            return getSelectedCompetitor1().getVehicleId();
        }
        return 0;
    }
    function getSelectedCompetitor2Id() {
        if (getSelectedCompetitor2() !== null) {
            return getSelectedCompetitor2().getVehicleId();
        }
        return 0;
    }
    function getBaseVehicleAttributeUsingCode(code, unit, measurementSystemId) {
        return getBaseVehicle().getAttributeUsingCode(code, unit, measurementSystemId);
    }
    function getCompetitor1AttributeUsingCode(code, unit, measurementSystemId) {
        return getSelectedCompetitor1().getAttributeUsingCode(code, unit, measurementSystemId);
    }
    function getCompetitor2AttributeUsingCode(code, unit, measurementSystemId) {
        return getSelectedCompetitor2().getAttributeUsingCode(code, unit, measurementSystemId);
    }
    //#endregion Getters and setters

    //#region Other Functions
    function addAdvantages(advantages) {
        var temp = [];
        for (var i = 0; i < getCompetitorVehicles().length; i++) {
            for (var j = 0; j < advantages.Competitors.length; j++) {
                if (getCompetitorVehicles()[i].vehicleId === advantages.Competitors[j].VehicleId) {
                    for (var k = 0; k < advantages.Competitors[j].Advantages.length; k++) {
                        temp.push(new SpecificationAdvantage(advantages.Competitors[j].Advantages[k].Description));
                    }
                    getCompetitorVehicles()[i].setAdvantages(temp);
                    temp = [];
                    break;
                }
            }
        }
        //self.advantagesPopulated(true);
    }
    function getVehicleTypeNameCodeFilter() {
        return _vehicleTypeNameCodeFilter;
    }
    function setVehicleTypeNameCodeFilter(newValue) {
        var valToUse = Array.isArray(newValue) ? newValue : [newValue];
        _vehicleTypeNameCodeFilter = valToUse;
    }
    function getSubCategory1CodeFilter() {
        return _subCategory1CodeFilter;
    }
    function setSubCategory1CodeFilter(newValue) {
        var valToUse = Array.isArray(newValue) ? newValue : [newValue];
        _subCategory1CodeFilter = valToUse;
    }
    function getSubCategory2CodeFilter() {
        return _subCategory2CodeFilter;
    }
    function setSubCategory2CodeFilter(newValue) {
        var valToUse = Array.isArray(newValue) ? newValue : [newValue];
        _subCategory2CodeFilter = valToUse;
    }
    function getStatusCodeFilter() {
        return _statusCodeFilter;
    }
    function setStatusCodeFilter(newValue) {
        var valToUse = Array.isArray(newValue) ? newValue : [newValue];
        _statusCodeFilter = valToUse;
    }
    //#endregion Other Functions

    //#region Initialize
    var tempHeadings = [];
    for (var i = 0; i < headings.length; i++) {
        tempHeadings.push(new SpecificationHeading(headings[i].Description, headings[i].HeadingCode, headings[i].HeadingIndicator, headings[i].Unit));
    }
    setHeadings(tempHeadings);
    setBaseVehicle(new SpecificationVehicle(baseVehicle.ManufacturerLogo, baseVehicle.VehicleChassisImage, baseVehicle.ReportManufacturerLogo,
        baseVehicle.ReportVehicleChassisImage, baseVehicle.VehicleModel, baseVehicle.VehicleId, baseVehicle.Attributes,
        baseVehicle.PdfName1, baseVehicle.PdfUrl1, baseVehicle.PdfName2, baseVehicle.PdfUrl2, baseVehicle.PdfName3, baseVehicle.PdfUrl3,
        baseVehicle.PdfName4, baseVehicle.PdfUrl4, baseVehicle.PdfName5, baseVehicle.PdfUrl5));

    var tempCompetitorVehicles = [];
    for (i = 0; i < competitorVehicles.length; i++) {
        tempCompetitorVehicles.push(new SpecificationVehicle(competitorVehicles[i].ManufacturerLogo, competitorVehicles[i].VehicleChassisImage,
                                                                competitorVehicles[i].ReportManufacturerLogo, competitorVehicles[i].ReportVehicleChassisImage,
                                                                competitorVehicles[i].VehicleModel, competitorVehicles[i].VehicleId, competitorVehicles[i].Attributes,
                                                                competitorVehicles[i].PdfName1, competitorVehicles[i].PdfUrl1, competitorVehicles[i].PdfName2,
                                                                competitorVehicles[i].PdfUrl2, competitorVehicles[i].PdfName3, competitorVehicles[i].PdfUrl3,
                                                                competitorVehicles[i].PdfName4, competitorVehicles[i].PdfUrl4, competitorVehicles[i].PdfName5,
                                                                competitorVehicles[i].PdfUrl5));
    }
    setCompetitorVehicles(tempCompetitorVehicles);

    //if (getCompetitorVehicles().length > 0) {
    //    setSelectedCompetitor1(getCompetitorVehicles()[0]);
    //    if (getCompetitorVehicles().length > 1) {
    //        setSelectedCompetitor2(getCompetitorVehicles()[1]);
    //    }
    //} else {
    //    clearSelectedCompetitor1();
    //    clearSelectedCompetitor2();
    //}
    clearSelectedCompetitor1();
    clearSelectedCompetitor2();

    //#endregion Initialize

    /**
     * Create selected competitor 1 specification vehicle object
     * @param {Object} vehicleObject - Object returned from cloud services
     */
    function createSelectedCompetitor1(vehicleObject) {
        setSelectedCompetitor1(new SpecificationVehicle(vehicleObject.ManufacturerLogo, vehicleObject.VehicleChassisImage,
            vehicleObject.ReportManufacturerLogo, vehicleObject.ReportVehicleChassisImage,
            vehicleObject.VehicleModel, vehicleObject.VehicleId, vehicleObject.Attributes,
            vehicleObject.PdfName1, vehicleObject.PdfUrl1, vehicleObject.PdfName2, vehicleObject.PdfUrl2, vehicleObject.PdfName3,
            vehicleObject.PdfUrl3, vehicleObject.PdfName4, vehicleObject.PdfUrl4, vehicleObject.PdfName5, vehicleObject.PdfUrl5));
    }

    /**
     * Create selected competitor 2 specification vehicle object
     * @param {Object} vehicleObject - Object returned from cloud services
     */
    function createSelectedCompetitor2(vehicleObject) {
        setSelectedCompetitor2(new SpecificationVehicle(vehicleObject.ManufacturerLogo, vehicleObject.VehicleChassisImage,
            vehicleObject.ReportManufacturerLogo, vehicleObject.ReportVehicleChassisImage,
            vehicleObject.VehicleModel, vehicleObject.VehicleId, vehicleObject.Attributes,
            vehicleObject.PdfName1, vehicleObject.PdfUrl1, vehicleObject.PdfName2, vehicleObject.PdfUrl2, vehicleObject.PdfName3,
            vehicleObject.PdfUrl3, vehicleObject.PdfName4, vehicleObject.PdfUrl4, vehicleObject.PdfName5, vehicleObject.PdfUrl5));
    }

    /**
     * Set to selected competitor 1 to null
     */
    function clearSelectedCompetitor1() {
        setSelectedCompetitor1(null);
    }

    /**
     * Set to selected competitor 2 to null
     */
    function clearSelectedCompetitor2() {
        setSelectedCompetitor2(null);
    }

    //#region Public members
    self.getHeadings = getHeadings;
    self.setHeadings = setHeadings;
    self.getBaseVehicle = getBaseVehicle;
    self.setBaseVehicle = setBaseVehicle;
    self.getCompetitorVehicles = getCompetitorVehicles;
    self.setCompetitorVehicles = setCompetitorVehicles;
    self.getSelectedCompetitor1 = getSelectedCompetitor1;
    self.setSelectedCompetitor1 = setSelectedCompetitor1;
    self.getSelectedCompetitor2 = getSelectedCompetitor2;
    self.setSelectedCompetitor2 = setSelectedCompetitor2;
    self.getSelectedCompetitor1Id = getSelectedCompetitor1Id;
    self.getSelectedCompetitor2Id = getSelectedCompetitor2Id;
    self.addAdvantages = addAdvantages;
    self.getBaseVehicleAttributeUsingCode = getBaseVehicleAttributeUsingCode;
    self.getCompetitor1AttributeUsingCode = getCompetitor1AttributeUsingCode;
    self.getCompetitor2AttributeUsingCode = getCompetitor2AttributeUsingCode;
    self.createSelectedCompetitor1 = createSelectedCompetitor1;
    self.createSelectedCompetitor2 = createSelectedCompetitor2;
    self.clearSelectedCompetitor1 = clearSelectedCompetitor1;
    self.clearSelectedCompetitor2 = clearSelectedCompetitor2;
    self.getVehicleTypeNameCodeFilter = getVehicleTypeNameCodeFilter;
    self.setVehicleTypeNameCodeFilter = setVehicleTypeNameCodeFilter;
    self.getSubCategory1CodeFilter = getSubCategory1CodeFilter;
    self.setSubCategory1CodeFilter = setSubCategory1CodeFilter;
    self.getSubCategory2CodeFilter = getSubCategory2CodeFilter;
    self.setSubCategory2CodeFilter = setSubCategory2CodeFilter;
    self.getStatusCodeFilter = getStatusCodeFilter;
    self.setStatusCodeFilter = setStatusCodeFilter;
    //#endregion Public members
};

export default Specification;

