<template>
    <td class="datagrid-icon-td">
        <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" 
            @click.stop="toggleCheckbox"
            data-bind="click: $parent.toggleCheckbox, clickBubble: false">
            <i class="far fa-lg" 
                :class="{ 'fa-square': item.isSelected === false, 'fa-check-square': item.isSelected === true }" 
                data-bind="css: { 'fa-square': isSelected() === false, 'fa-check-square': isSelected() === true }"></i>
        </div>
    </td>
    <td class="datagrid-icon-td folder-row-td">
        <div class="selection-datagrid-fa-icon save-icon">
            <template v-if="item.IsSupportOffer === true">
                <i class="far fa-user-headset fa-lg"></i>
            </template>
            <template v-else>
                <i class="fas fa-share-alt-square fa-lg"></i>
            </template>
        </div>
    </td>
    <td>
        <div class="datagrid-description-div">
            <span data-bind="text: descriptionDisplay">{{ item.descriptionDisplay }}</span>
        </div>
        <div v-if="wizard.searchTerms.value !== ''"
            class="datagrid-path-div">
            <a href="#" 
                @click.stop="navigateToFolder()"
                data-bind="click: function (item, event) { navigateToFolder(item, event) }">
                <span data-bind="text: folderPath">{{ item.folderPath }}</span>
            </a>
        </div>
    </td>
    <td>
        <span>{{ item.PreparedForName }}</span>
    </td>
    <td>
        <span>{{ item.ModifiedByName }}</span>
    </td>
    <td>
        <span>{{ item.getFormattedOfferDate() }}</span>
    </td>
    
    <!-- <script type="text/html" id="template-selection-datagrid-shared-row">
        Template = "template-selection-datagrid-shared-row"
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" data-bind="click: $parent.toggleCheckbox, clickBubble: false">
                <i class="far fa-square fa-lg" data-bind="css: { 'fa-square': isSelected() === false, 'fa-check-square': isSelected() === true }"></i>
            </div>
        </td>
        <td class="datagrid-icon-td folder-row-td">
            <div class="selection-datagrid-fa-icon save-icon">            
                ko if: IsSupportOffer !== true
                <i class="fas fa-share-alt-square fa-lg"></i>
                /ko
                ko if: IsSupportOffer === true
                <i class="far fa-user-headset fa-lg"></i>
                /ko
            </div>
        </td>
        <td>
            <div class="datagrid-description-div">
                <span data-bind="text: descriptionDisplay"></span>
            </div>
            ko if: $parents[1].searchTerms() !== ''
            <div class="datagrid-path-div">
                <a href="#" data-bind="click: function (item, event) { navigateToFolder(item, event) }">
                    <span data-bind="text: folderPath"></span>
                </a>
            </div>
            /ko
        </td>
        <td>
            <span data-bind="text: PreparedForName"></span>
        </td>
        <td>
            <span data-bind="text: ModifiedByName"></span>
        </td>
        <td>
            <span data-bind="text: getFormattedOfferDate()"></span>
        </td>
    </script> -->
</template>

<script>
import { ref, watch } from 'vue';

export default {
    props: {
        wizardFromParent: Object,
        itemFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);
        let item = ref(props.itemFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        function toggleCheckbox() {
            wizard.value.activeWizardStep.toggleCheckbox(item.value);
        }

        function navigateToFolder() {
            item.value.navigateToFolder(item.value);
        }
        return {
            wizard,
            item,
            toggleCheckbox,
            navigateToFolder
        }
    }
}
</script>

<style lang="scss" scoped>
</style>