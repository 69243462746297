<template>
    <tr class="menu-li menu-li-value menu-li-value-select" :class="selectValue.customCss"
        v-visible="displayTableRow()">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{ getLabel() }}</span>
            </div>
        </td>
        <td class="menu-value-td menu-select-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div">
                    <a data-placement="top" v-if="selectValue.tooltipMessage !== undefined"
                        :id="selectValue.tooltipId"
                        :data-content="selectValue.tooltipMessage"
                        @click="selectValue.showPopover"
                        data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}"><i
                            class="fa fa-info-circle fa-lg"></i></a>
                </div>
                <div v-if="selectValue.displayUpgradeIcon === true"
                    class="increment-div info-tooltip-div">
                    <a :id="selectValue.upgradeTooltipMessageId"
                        :data-content="selectValue.upgradeTooltipMessage"
                        @click="selectValue.showUpgradePopover"
                        data-placement="top"
                        data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                        <i class="fa fa-chevron-circle-up fa-lg info-upgrade-icon"></i>
                    </a>
                </div>
                <div class="override-updownbox-checkbox"
                    v-if="displayOverride()"
                    @click="selectValue.toggleOverride"
                    :class="{ 'override-false': selectValue.override.value === false }"
                    :title="overrideTooltipText()"
                    data-toggle="tooltip">
                    <i class="far fa-lg"
                        :class="{ 'fa-check-square': selectValue.override.value === true, 'fa-square': selectValue.override.value === false }"></i>
                </div>
                <div class="menu-select-div">
                    <select :id="selectValue.id" :title="selectValue.errorMessageObv"
                        :data-original-title="selectValue.errorMessageObv"
                        v-model="selectValue.valueObv.value"
                        data-toggle="tooltip">
                        <option v-for="option in selectValue.displayArray.value" :key="option" :value="option.id">
                            {{ option.description }}</option>
                    </select>
                </div>
            </div>
        </td>
    </tr>
    <!-- <div>
        'template-menu-select'
    </div> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: inputType === $root.config.INPUT_TYPE.SELECT 
    <tr class="menu-li menu-li-value menu-li-value-select" data-bind="visible: permissionObv().visible, css: customCss, template: { name: 'template-menu-select' }"></tr>
     /ko      
</script> -->

    <!-- <script type="text/html" id="template-menu-select">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
        </div>
    </td>
    <td class="menu-value-td menu-select-td">
        <div class="flex-center menu-flex-end">
            <div class="increment-div info-tooltip-div">
                 ko if: tooltipMessage !== undefined 
                <a data-placement="top" data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}"><i class="fa fa-info-circle fa-lg"></i></a>
                 /ko 
            </div>
             ko if: permissionObv().readOnly === false && permissionObv().override === true 
            <div class="override-updownbox-checkbox" data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: { title: override() === false ? overrideTooltipMessage: '' }" data-toggle="tooltip">
                <i class="far fa-square fa-lg" data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
            </div>
             /ko 
            <div class="menu-select-div">
                <select data-bind="options: displayArray, optionsText: 'description', optionsValue: 'id', value: valueObv, attr: {id: id, title: errorMessageObv, 'data-original-title': errorMessageObv}" data-toggle="tooltip"></select>
            </div>
            <div class="increment-div"></div>
        </div>
    </td>
</script> -->
</template>

<script>
import { isRef, toRef } from 'vue';

export default {
    name: 'MenuContentItemValueSelect',
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const selectValue = ref(props.valueFromParent);
        const selectValue = toRef(props, "valueFromParent");

        function overrideTooltipText() {
            if(selectValue.value.override.value === false) {
                return selectValue.value.overrideTooltipMessage;
            } else {
                return '';
            }
        }

        function getLabel() {
            if(isRef(selectValue.value.label)) {
                return selectValue.value.label.value;
            } else {
                return selectValue.value.label;
            }
        }

        function displayOverride() {
            if(isRef(selectValue.value.permissionObv) && selectValue.value.permissionObv.value) {
                return selectValue.value.permissionObv.value.readOnly === false && selectValue.value.permissionObv.value.override === true
            } else {
                return selectValue.value.permissionObv.readOnly === false && selectValue.value.permissionObv.override === true
            }
        }

        function displayTableRow() {
            if(isRef(selectValue.value.permissionObv) && selectValue.value.permissionObv.value) {
                return selectValue.value.permissionObv.value.visible;
            } else {
                return selectValue.value.permissionObv.visible;
            }
        }

        return {
            selectValue,
            overrideTooltipText,
            getLabel,
            displayOverride,
            displayTableRow
        }
    }
}
</script>

<style lang="scss" scoped>
</style>