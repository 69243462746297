import config from '../..//services/config';


//var Dimension = function (type, layerNumber, name, start, length, displayLength, style, innerDimensionOffset) {
var Dimension = function (type, layerNumber, dimensionConfig, style, innerDimensionOffset) {
//var Dimension = function (dimensionConfig) {
    var self = this;

    self.textBuffer = 4;
    self.type = type;
    self.layerNumber = layerNumber;
    self.style = style || config.DIMENSION_STYLE.STANDARD;
    
    self.name = getName(dimensionConfig);
    //self.name = dimensionConfig.getId();
    function getName(dC) {
        if (dC.getContext() !== undefined) {
            return dC.getContext() + '.' + dC.getId();
        } else {
            return dC.getId();
        }
    }
    self.innerDimensionOffset = innerDimensionOffset || 0;

    if (self.type === config.DIMENSION_TYPE.VERTICAL_LEFT || self.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || self.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
        self.startPoint = { x: 0 + self.innerDimensionOffset, y: dimensionConfig.getStart() };
    } else if (self.type === config.DIMENSION_TYPE.FREE_ANGLE || self.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
        self.startPoint = dimensionConfig.getStartPoint();
        self.endPoint = dimensionConfig.getEndPoint();
        self.lineEndStyle = dimensionConfig.getLineEndStyle();
    }else{
        self.startPoint = { x: dimensionConfig.getStart(), y: 0 + self.innerDimensionOffset };
        
    }
    
    self.displayLength = dimensionConfig.getDisplayText();
    self.length = dimensionConfig.getLength();
    self.hide = self.length === 0 ? true : false;
    self.lineEnd = {};
    self.drawBG = dimensionConfig.getDrawBG();
    //arrow props
    self.ah1 = {
        tip: {},
        sideAEnd : {},
        sideBEnd : {}
    };
    
    self.ah2 = {
        tip: {},
        sideAEnd : {},
        sideBEnd : {}
    };
    
    function addInteraction(interactionObj) {
        self.interaction = interactionObj;
        return self;
    }

    self.drawingGroupName = 'NOT_SET';
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
        return self;
    }

    self.refreshDimension = function (newStart, newLength, offset) {
        self.displayLength = dimensionConfig.getDisplayText(newLength);
        self.length = newLength;
        self.innerDimensionOffset = offset;
        if (self.type === config.DIMENSION_TYPE.VERTICAL_LEFT || self.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || self.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
            self.startPoint = { x: 0 + self.innerDimensionOffset, y: newStart };
        } else {
            self.startPoint = { x: newStart, y: 0 + self.innerDimensionOffset };
        }
    }

    self.setShowAsWarning = function (showAsWarning) {
        self.showAsWarning = showAsWarning;
    }

    self.setShowAsPinned = function (showAsPinned) {
        self.showAsPinned = showAsPinned;
    }

    self.setShowAsHyperLink = function (showAsHyperLink) {
        self.showAsHyperlink = showAsHyperLink;
    }

    self.setPulse = function (newValue) {
        self.pulse = newValue;
    }

    self.getHideDimension = function () {
        return self.hide;
    }

    self.getId = function () {
        return dimensionConfig.getId();
    }

    self.getContextId = function () {
        if(dimensionConfig.getContext() && dimensionConfig.getContext() !== '') {
            return dimensionConfig.getContext() + '.' + dimensionConfig.getId();
        }
        return dimensionConfig.getId();
    }

    //line stops a.k.a delimiters
    self.lineStop1Start = {};
    self.lineStop1End = {};
    self.lineStop2Start = {};
    self.lineStop2End = {};

    //dimension text/value props
    self.textCentre = {};
    self.textBackgroundWidth = 0;
    self.textBackgroundHeight = 0;
    self.rotateText = false;
    self.font = '';
    self.yDimensionOffset = 0;
    self.addInteraction = addInteraction;
    self.setDrawingGroupName = setDrawingGroupName;
};

export default Dimension;


