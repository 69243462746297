import pixiAdapter from './pixiAdapter';
import config from './config';
import globals from './globals';
import utils from './utils';
import Arc from '../model/drawing/arc';
import Line from '../model/drawing/line';
import $ from '../assets/scripts/jquery-3.6.0.min';
import * as PIXI from '../assets/scripts/pixi.min';


var TScMessenger = window.TScMessenger;


var mainX, mainY, leftX, leftY, rightX, rightY, topX, topY, bottomX, bottomY, notesX, notesY, warningsX, warningsY, tablesX, tablesY,
    emptyVerticalTopX, emptyVerticalTopY, emptyVerticalBottomX, emptyVerticalBottomY/*, emptyHorizontalLeftX, emptyHorizontalLeftY, emptyHorizontalRightX,
    emptyHorizontalRightY*/, topBufferX, topBufferY, bottomBufferX, bottomBufferY, axleLettersX, axleLettersY, axleLetterTableConnectorLayerX, axleLetterTableConnectorLayerY,
    belowTableBufferLayerX, belowTableBufferLayerY, sideWidth, sideHeight, topWidth, topHeight,
    bottomWidth, bottomHeight, notesWidth, notesHeight, warningsWidth, warningsHeight, tablesWidth, tablesHeight, emptyVerticalSpace, emptyHorizontalSpace,
    topBufferHeight, bottomBufferHeight, axleLettersHeight, axleLetterTableConnectorLayerHeight, belowTableBufferLayerHeight;

var inDrawingDimensionLayerOffsetX, inDrawingDimensionLayerOffsetY, topInDrawingDimensionLayerThickness;

var cogDimensionFontTextHeight;

var graphicsAdapter = pixiAdapter;
var drawingArea = null;

var renderer;
var doRender = false;
var scalingFactor, itemScalingFactor, scalingItemDrawingGroupNames, previousScalingFactor = null;
var scalingItemOriginTranslation;
var drawWatermarkOverlay = false, needToDoRerender = false;
var sprite = undefined;

var overallWidth, overallHeight, mainWidth = null, mainHeight = null;
var ratio;

var tableFontSize, dimensionFontSize, labelFontSize, axleLetterFontSize, notesAndWarningsFontSize, layerIncrement, dashboardAndTableTitleFontSize;

var curFontName;

var dimensionLineWidth = 0.8;

var id = 0;

var frontTable, totalTable, rearTable;
var tableCols = {};

//var selectedDrawingGroupId = null, mousedOverDrawingGroupId = null;
var interactionState;
var cogs;

var displayObjects = [], dimensions = [], notes = [], warnings = [], originalAxleLetters = [];


var mainDrawingGraphicsObjects = {};
var dimensionLayersGraphicsObjects = {};
var mainGraphicsObjectUsers = {};
var hitAreaObjects = {};
var hitMaskGraphicObjects = {};
var tableCellTextObjectsForInteractionHighlights = {};
var tableCellsForInteractionHighlights = {};
var allTableCellTextObjects = {};
var allTableCells = {};
var allComplianceDashboardItems = {};
var complianceDashboardItemsForInteractionHighlights = {};
var axleLetterTopViewGraphicsObjects = {};

var pulseController = null;
var dimensionPulseControllers = {};
var dimensionsToPulse = [];

var dragging = false;
var canDrag = true, dragDelayPeriod = 500;
var currentDragItemElementId = null;
var lastPosition = null;
var firstPosition = null;
var refLinesContainer = null;
var curDraggableInteractionObject = null;
var dragMoveCounter = 0;
var dragStartDelayTimerRef = null;

var originalDimensions;
var tableDefinitionData;

var complianceDashboardDefinition;
var complianceContainer = null;


var animationFrameRef = null;
var viewContainer = null;

var addedDimensions = {};
var scaleHorizontalDimensions = true, scaleVerticalDimensions = true;

var dimensionLayerChanged = [];

var underlineHeight = 1;

var pinnedInternalDimensionsJumpThreshold = 55;

var CONTROL_TYPES = {
    FORWARD: 'FORWARD',
    BUS: 'BUS',
    SEMI_FORWARD: 'SEMI_FORWARD',
    NORMAL: 'NORMAL',
    COACH: 'COACH'
};

var VEHICLE_TYPES = {
    COMPLETE_VECHICLE: 'COMPLETEVEHICLE',
    RIGID: 'RIGID',
    TRUCK_TRACTOR: 'TRUCKTRACTOR'
};

var VEHICLE_INCLUCSION_TYPES = {
    YES: 'YES',
    NO: 'NO',
    NOT_SPECIFIED: 'NOTSPECIFIED'
};

function scalePoint(point) {
    point.x = point.x * scalingFactor;
    point.y = point.y * scalingFactor;
}

function itemScalePoint(point) {
    point.x = point.x * itemScalingFactor;
    point.y = point.y * itemScalingFactor;
}

function scalePointAsCopy(point) {
    var copyOfPoint = JSON.parse(JSON.stringify(point));
    scalePoint(copyOfPoint);
    return copyOfPoint;
}

function itemScalePointAsCopy(point) {
    var copyOfPoint = JSON.parse(JSON.stringify(point));
    itemScalePoint(copyOfPoint);
    return copyOfPoint;
}

function flipMainDrawingAreaAxisPoint(point) {
    //point.y = (mainHeight + topInDrawingDimensionLayerThickness) - point.y;
    point.y = (mainHeight) - point.y;
    return point;
}

function scaleValue(value) {
    return value * scalingFactor;
}

function itemScaleValue(value) {
    return value * itemScalingFactor;
}

function itemScaleLine(objectData) {
    itemScalePoint(objectData.startPoint);
    itemScalePoint(objectData.endPoint);
}

function itemScaleArc(objectData) {
    itemScalePoint(objectData.centre);
    itemScalePoint(objectData.startPoint);
    itemScalePoint(objectData.endPoint);
    objectData.radius = itemScaleValue(objectData.radius);
}

function translateLineBackToOrigin(scalingItemOriginTranslation, objectData) {
    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.endPoint);
    //} else {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.endPoint);
    //}
    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.endPoint);
    } /*else {
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.endPoint);
    } /*else {
        translatePoint(0, scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.endPoint);
    }*/
}

function translateLineBackToOffset(scalingItemOriginTranslation, objectData) {

    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.endPoint);
    //} else {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.endPoint);
    //}

    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.endPoint);
    } /*else {
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.endPoint);
    } /*else {
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.endPoint);
    }*/
}

function translateArcBackToOrigin(scalingItemOriginTranslation, objectData) {
    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.centre);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.endPoint);
    //} else {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.centre);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.endPoint);
    //}
    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.centre);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }/* else {
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.centre);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.centre);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.endPoint);
    }/* else {
        translatePoint(0, scalingItemOriginTranslation.y, objectData.centre);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.endPoint);
    }*/
}

function translateArcBackToOffset(scalingItemOriginTranslation, objectData) {
    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.centre);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, objectData.endPoint);
    //} else {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.centre);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.startPoint);
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, objectData.endPoint);
    //}
    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.centre);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }/* else {
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.centre);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.startPoint);
        translatePoint(-scalingItemOriginTranslation.x, 0, objectData.endPoint);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, scalingItemOriginTranslation.y, objectData.centre);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, scalingItemOriginTranslation.y, objectData.endPoint);
    } /*else {
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.centre);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.startPoint);
        translatePoint(0, -scalingItemOriginTranslation.y, objectData.endPoint);
    }*/
}

function translatePoint(changeX, changeY, point) {
    point.x = point.x - changeX * scalingFactor;
    point.y = point.y - changeY * scalingFactor;
}

function translatePointBackToOrigin(scalingItemOriginTranslation, point) {
    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, point);
    //} else {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, point);
    //}
    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(-scalingItemOriginTranslation.x, 0, point);
    } /*else {
        translatePoint(scalingItemOriginTranslation.x, 0, point);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, -scalingItemOriginTranslation.y, point);
    }/* else {
        translatePoint(0, scalingItemOriginTranslation.y, point);
    }*/
}

function translatePointBackToOffset(scalingItemOriginTranslation, point) {
    //if (scalingItemOriginTranslation.x < 0 && scalingItemOriginTranslation.y < 0) {
    //    translatePoint(scalingItemOriginTranslation.x, scalingItemOriginTranslation.y, point);
    //} else {
    //    translatePoint(-scalingItemOriginTranslation.x, -scalingItemOriginTranslation.y, point);
    //}
    if (scalingItemOriginTranslation.x < 0) {
        translatePoint(scalingItemOriginTranslation.x, 0, point);
    } /*else {
        translatePoint(-scalingItemOriginTranslation.x, 0, point);
    }*/
    if (scalingItemOriginTranslation.y < 0) {
        translatePoint(0, scalingItemOriginTranslation.y, point);
    }/* else {
        translatePoint(0, -scalingItemOriginTranslation.y, point);
    }*/
}

function flipYAxisVal(value) {
    return mainHeight - value;
}

function drawText(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour) {
    //any time add to graphics is true it means that the object is to be added to the main drawing area graphics object and so any points need to be scaled and flipped in the Y axis
    if (addToGraphics) {
        scalePoint(position);
        flipMainDrawingAreaAxisPoint(position);
    }

    return graphicsAdapter.drawText(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour);

}

//function drawLine(objectData, addToGraphics) {
//    //if (objectData.dashEnabled == true) {
//    //    return graphicsAdapter.drawDashedLine(objectData.startPoint, objectData.endPoint, addToGraphics, objectData.dashArray, 2);
//    //} else {
//    //    return graphicsAdapter.drawLine(objectData.startPoint, objectData.endPoint, addToGraphics);
//    //}
//    if (objectData.dashEnabled == true) {
//        scalePoint(objectData.startPoint);
//        scalePoint(objectData.endPoint);
//        flipMainDrawingAreaAxisPoint(objectData.startPoint);
//        flipMainDrawingAreaAxisPoint(objectData.endPoint);

//        //need to scale the dashed section values
//        objectData.dashArray = [scaleValue(objectData.dashArray[0]), scaleValue(objectData.dashArray[1])];

//        return graphicsAdapter.drawDashedLine(objectData.startPoint, objectData.endPoint, objectData.dashArray, 2);
//    } else {
//        if (addToGraphics && addToGraphics === true) {
//            scalePoint(objectData.startPoint);
//            scalePoint(objectData.endPoint);
//            flipMainDrawingAreaAxisPoint(objectData.startPoint);
//            flipMainDrawingAreaAxisPoint(objectData.endPoint);
//            graphicsAdapter.drawLine(objectData.startPoint, objectData.endPoint, addToGraphics);
//        } else {
//            return graphicsAdapter.drawLine(objectData.startPoint, objectData.endPoint, addToGraphics);
//        }

//    }

//}

function drawDashedLine(graphicsObjectToUse, objectData, addToGraphics, optionalColour) {

    //var colourToUse;
    //var lineWidth;
    //if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
    //    colourToUse = getPulseColourToUse(objectData);
    //    lineWidth = getPulseLineWidth();
    //} else {
    //    colourToUse = getColourToUse(objectData);
    //}

    //need to scale the dashed section values
    objectData.dashArray = [scaleValue(objectData.dashArray[0]), scaleValue(objectData.dashArray[1])];
    //startPoint, endPoint, dashArray, width, colour, alpha
    return graphicsAdapter.drawDashedLine(graphicsObjectToUse, objectData.startPoint, objectData.endPoint, objectData.dashArray, 1, optionalColour);
}

function tempDrawLine(objectData) {
    drawLine(graphicsAdapter.getGraphicsObject(), objectData, true);
}

function drawLine(graphicsObjectToUse, objectData, addToGraphics) {
    //if (objectData.dashEnabled == true) {
    //    return graphicsAdapter.drawDashedLine(objectData.startPoint, objectData.endPoint, addToGraphics, objectData.dashArray, 2);
    //} else {
    //    return graphicsAdapter.drawLine(objectData.startPoint, objectData.endPoint, addToGraphics);
    //}

    var colourToUse;
    var lineWidth;
    if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth();
    } else if (dimensionPulseControllers[objectData.drawingGroupName]) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth(objectData.drawingGroupName);
    } else {
        colourToUse = getColourToUse(objectData, objectData.colour);
    }

    if (objectData.lineEndStyle) {
        drawLineEndStyle(graphicsObjectToUse, objectData, addToGraphics, lineWidth || objectData.lineWidth, colourToUse);
    }

    if (objectData.dashEnabled == true) {
        scalePoint(objectData.startPoint);
        scalePoint(objectData.endPoint);
        if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
            if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                translateLineBackToOrigin(scalingItemOriginTranslation, objectData);
                itemScaleLine(objectData);
                translateLineBackToOffset(scalingItemOriginTranslation, objectData);
            } else {
                itemScaleLine(objectData);
            }
        }
        flipMainDrawingAreaAxisPoint(objectData.startPoint);
        flipMainDrawingAreaAxisPoint(objectData.endPoint);

        //need to scale the dashed section values
        objectData.dashArray = [scaleValue(objectData.dashArray[0]), scaleValue(objectData.dashArray[1])];
        //startPoint, endPoint, dashArray, width, colour, alpha
        return graphicsAdapter.drawDashedLine(graphicsObjectToUse, objectData.startPoint, objectData.endPoint, objectData.dashArray, lineWidth || objectData.lineWidth, colourToUse);
    } else {


        if (addToGraphics && addToGraphics === true) {
            //if (scalingItemDrawingGroupName !== undefined && scalingItemDrawingGroupName !== null && scalingItemDrawingGroupName === objectData.drawingGroupName) {
            //    itemScalePoint(objectData.startPoint);
            //    itemScalePoint(objectData.endPoint);
            //} else {
            //    scalePoint(objectData.startPoint);
            //    scalePoint(objectData.endPoint);
            //}
            scalePoint(objectData.startPoint);
            scalePoint(objectData.endPoint);
            if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
                if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                    translateLineBackToOrigin(scalingItemOriginTranslation, objectData);
                    itemScaleLine(objectData);
                    translateLineBackToOffset(scalingItemOriginTranslation, objectData);
                } else {
                    itemScaleLine(objectData);
                }
            }
            flipMainDrawingAreaAxisPoint(objectData.startPoint);
            flipMainDrawingAreaAxisPoint(objectData.endPoint);
            graphicsAdapter.drawLine(graphicsObjectToUse, objectData.startPoint, objectData.endPoint, addToGraphics, lineWidth || objectData.lineWidth, colourToUse);
        } else {
            return graphicsAdapter.drawLine(graphicsObjectToUse, objectData.startPoint, objectData.endPoint, addToGraphics, undefined, colourToUse);
        }

    }


}

function drawLineEndStyle(graphicsObjectToUse, objectData, addToGraphics, lineWidth, colourToUse) {

    var angleOfLine = calculateAngleOfLine(objectData);

    if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_ONLY_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_ONLY_WITHOUT_DELIMITER) {
        drawArrow(objectData.startPoint, objectData.endPoint, angleOfLine);
        if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_ONLY_WITH_DELIMITER) {
            drawDelimiter(objectData.startPoint, angleOfLine);
        }
    } else if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_END_ONLY_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER) {
        //if (objectData.endPoint.x > objectData.startPoint.x) {
        //    drawArrow(objectData.startPoint, objectData.endPoint, angleOfLine);
        //    if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_END_ONLY_WITH_DELIMITER) {
        //        drawDelimiter(objectData.startPoint, angleOfLine);
        //    }
        //} else {
        drawArrow(objectData.endPoint, objectData.startPoint, angleOfLine);
        if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_END_ONLY_WITH_DELIMITER) {
            drawDelimiter(objectData.endPoint, angleOfLine);
        }
        //}

    } else if ((objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT) && Math.abs(objectData.endPoint.x - objectData.startPoint.x) <= pinnedInternalDimensionsJumpThreshold) {
        //drawArrow(objectData.startPoint, objectData.endPoint, angleOfLine);
        if (objectData.endPoint.x > objectData.startPoint.x) {
            drawArrow({ x: objectData.startPoint.x + (objectData.length * scalingFactor), y: objectData.startPoint.y }, { x: objectData.endPoint.x + (objectData.length * scalingFactor), y: objectData.endPoint.y }, calculateAngleOfLine(objectData, true));
            drawArrow({ x: objectData.endPoint.x - (objectData.length * scalingFactor), y: objectData.endPoint.y }, { x: objectData.startPoint.x - (objectData.length * scalingFactor), y: objectData.startPoint.y }, angleOfLine);
            drawDelimiter(objectData.startPoint, angleOfLine);
            drawDelimiter(objectData.endPoint, angleOfLine);
        } else {
            drawArrow({ x: objectData.endPoint.x + (objectData.length * scalingFactor), y: objectData.endPoint.y }, { x: objectData.startPoint.x + (objectData.length * scalingFactor), y: objectData.startPoint.y }, angleOfLine);
            drawArrow({ x: objectData.startPoint.x - (objectData.length * scalingFactor), y: objectData.startPoint.y }, { x: objectData.endPoint.x - (objectData.length * scalingFactor), y: objectData.endPoint.y }, calculateAngleOfLine(objectData, true));
            drawDelimiter(objectData.startPoint, angleOfLine);
            drawDelimiter(objectData.endPoint, angleOfLine);
        }
        
    } else if ((objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN) && Math.abs(objectData.startPoint.y - objectData.endPoint.y) <= pinnedInternalDimensionsJumpThreshold) {
        //drawArrow(objectData.startPoint, objectData.endPoint, angleOfLine);

        if (objectData.startPoint.y > objectData.endPoint.y) {
            drawArrow({ x: objectData.startPoint.x, y: objectData.startPoint.y - (objectData.length * scalingFactor) }, { x: objectData.endPoint.x, y: objectData.endPoint.y - (objectData.length * scalingFactor) }, calculateAngleOfLine(objectData, true));
            drawArrow({ x: objectData.endPoint.x, y: objectData.endPoint.y + (objectData.length * scalingFactor) }, { x: objectData.startPoint.x, y: objectData.startPoint.y + (objectData.length * scalingFactor) }, angleOfLine);
            drawDelimiter(objectData.startPoint, angleOfLine);
            drawDelimiter(objectData.endPoint, angleOfLine);
        } else {
            drawArrow({ x: objectData.endPoint.x, y: objectData.endPoint.y }, { x: objectData.endPoint.x, y: objectData.startPoint.y }, calculateAngleOfLine(objectData, true));
            drawArrow({ x: objectData.startPoint.x, y: objectData.startPoint.y }, { x: objectData.endPoint.x, y: objectData.startPoint.y }, angleOfLine);
            drawDelimiter(objectData.startPoint, angleOfLine);
            drawDelimiter(objectData.endPoint, angleOfLine);
        }


    } else {
        if (objectData.startPoint.x !== objectData.endPoint.x) {
            if (objectData.startPoint.x > objectData.endPoint.x) {
                drawArrow(objectData.endPoint, objectData.startPoint, angleOfLine);
                drawArrow(objectData.startPoint, objectData.endPoint, angleOfLine);
                if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT ||
                    objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP) {
                    drawDelimiter(objectData.startPoint, angleOfLine);
                    drawDelimiter(objectData.endPoint, angleOfLine);
                }
            } else {
                drawArrow(objectData.startPoint, objectData.endPoint, calculateAngleOfLine(objectData, true));
                drawArrow(objectData.endPoint, objectData.startPoint, angleOfLine);
                if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT ||
                    objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP) {
                    drawDelimiter(objectData.startPoint, angleOfLine);
                    drawDelimiter(objectData.endPoint, angleOfLine);
                }
            }
            
        } else {
            if (objectData.startPoint.y > objectData.endPoint.y) {
                drawArrow(objectData.startPoint, objectData.endPoint, calculateAngleOfLine(objectData, true));
                drawArrow(objectData.endPoint, objectData.startPoint, angleOfLine);
                if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT ||
                    objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP) {
                    drawDelimiter(objectData.startPoint, angleOfLine);
                    drawDelimiter(objectData.endPoint, angleOfLine);
                }
            } else {
                drawArrow(objectData.startPoint, objectData.endPoint, calculateAngleOfLine(objectData, true));
                drawArrow(objectData.endPoint, objectData.startPoint, angleOfLine);
                
                if (objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT ||
                    objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN || objectData.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP) {
                    drawDelimiter(objectData.startPoint, angleOfLine);
                    drawDelimiter(objectData.endPoint, angleOfLine);
                }
            }
            
        }
        
    }

    function drawArrow(arrowPoint, otherPoint, angleOfLine) {
        var ahWidth = 1.5;
        var ahLength = 5.5;
        var stopLength = 4;

        if (angleOfLine === 180) {
            if (arrowPoint.x > otherPoint.x) {
                angleOfLine = 0;
            }
        }

        var firstPoint = { x: 0, y: 0 }, secondPoint = { x: 0, y: 0 }, arrowHeadEnd = { x: 0, y: 0 };

        arrowHeadEnd.x = arrowPoint.x + Math.cos((180 + angleOfLine) * Math.PI / 180) * ahLength;
        arrowHeadEnd.y = arrowPoint.y + Math.sin((180 + angleOfLine) * Math.PI / 180) * ahLength;

        //calculate the first arrow point
        firstPoint.x = arrowHeadEnd.x + Math.cos((150 + angleOfLine) * Math.PI / 180) * ahLength;
        firstPoint.y = arrowHeadEnd.y + Math.sin((150 + angleOfLine) * Math.PI / 180) * ahLength;
        //calculate the second arrow point
        secondPoint.x = arrowHeadEnd.x + Math.cos((210 + angleOfLine) * Math.PI / 180) * ahLength;
        secondPoint.y = arrowHeadEnd.y + Math.sin((210 + angleOfLine) * Math.PI / 180) * ahLength;

        graphicsAdapter.drawLine(graphicsObjectToUse, arrowPoint, firstPoint, addToGraphics, lineWidth, colourToUse);
        graphicsAdapter.drawLine(graphicsObjectToUse, arrowPoint, secondPoint, addToGraphics, lineWidth, colourToUse);

        /*
            Dim firstPoint, secondPoint, arrowHeadEnd As Point
        arrowHeadEnd.X = arrowPoint.X + Math.Cos((180 + angleOfDimensionLine) * Math.PI / 180) * DimensionLength
        arrowHeadEnd.Y = arrowPoint.Y + Math.Sin((180 + angleOfDimensionLine) * Math.PI / 180) * DimensionLength

        'calculate the first arrow point
        firstPoint.X = arrowHeadEnd.X + Math.Cos((90 + angleOfDimensionLine) * Math.PI / 180) * DimensionHeight
        firstPoint.Y = arrowHeadEnd.Y + Math.Sin((90 + angleOfDimensionLine) * Math.PI / 180) * DimensionHeight
        'calculate the second arrow point
        secondPoint.X = arrowHeadEnd.X + Math.Cos((270 + angleOfDimensionLine) * Math.PI / 180) * DimensionHeight
        secondPoint.Y = arrowHeadEnd.Y + Math.Sin((270 + angleOfDimensionLine) * Math.PI / 180) * DimensionHeight

        If ApplicationGlobals.CurrentDrawingSurface.SurfaceType = UtilDrawingSurface.SurfaceTypes.PDFDocument Then

            ApplicationGlobals.CurrentDrawingSurface.DrawPDFLine(arrowPoint.X, _
                                                                    arrowPoint.Y, _
                                                                    firstPoint.X, _
                                                                    firstPoint.Y, _
                                                                    Colors.Black, _
                                                                    False, _
                                                                    False)

            ApplicationGlobals.CurrentDrawingSurface.DrawPDFLine(arrowPoint.X, _
                                                                    arrowPoint.Y, _
                                                                    secondPoint.X, _
                                                                    secondPoint.Y, _
                                                                    Colors.Black, _
                                                                    False, _
                                                                    False)

        Else

            Dim newLine As Line = Nothing

            ApplicationGlobals.CurrentDrawingSurface.DrawCanvasLine(newLine, _
                                                                    False, _
                                                                    Colors.Black, _
                                                                    0, _
                                                                    False, _
                                                                    False)

            With newLine
                .X1 = arrowPoint.X
                .X2 = firstPoint.X
                .Y1 = arrowPoint.Y
                .Y2 = firstPoint.Y
            End With

            MyBase.UIElements.Add(newLine)

            ApplicationGlobals.CurrentDrawingSurface.DrawCanvasLine(newLine, _
                                                                    False, _
                                                                    Colors.Black, _
                                                                    0, _
                                                                    False, _
                                                                    False)

            With newLine
                .X1 = arrowPoint.X
                .X2 = secondPoint.X
                .Y1 = arrowPoint.Y
                .Y2 = secondPoint.Y
            End With

            MyBase.UIElements.Add(newLine)

        End If
        */
    }
    function drawDelimiter(point, angleOfLine) {

        var delimiterLength = 8;

        var firstPoint = { x: 0, y: 0 }, secondPoint = { x: 0, y: 0 };

        firstPoint.x = point.x + Math.cos((angleOfLine + 90) * Math.PI / 180) * delimiterLength / 2;
        firstPoint.y = point.y + Math.sin((angleOfLine + 90) * Math.PI / 180) * delimiterLength / 2;
        secondPoint.x = point.x + Math.cos((angleOfLine - 90) * Math.PI / 180) * delimiterLength / 2;
        secondPoint.y = point.y + Math.sin((angleOfLine - 90) * Math.PI / 180) * delimiterLength / 2;

        graphicsAdapter.drawLine(graphicsObjectToUse, firstPoint, secondPoint, addToGraphics, lineWidth, colourToUse);

        /*
        Dim firstPoint, secondPoint As Point

    firstPoint.X = endPoint.X + Math.Cos((angleOfDimensionLine + 90) * Math.PI / 180) * DelimiterHeight / 2
    firstPoint.Y = endPoint.Y + Math.Sin((angleOfDimensionLine + 90) * Math.PI / 180) * DelimiterHeight / 2
    secondPoint.X = endPoint.X + Math.Cos((angleOfDimensionLine - 90) * Math.PI / 180) * DelimiterHeight / 2
    secondPoint.Y = endPoint.Y + Math.Sin((angleOfDimensionLine - 90) * Math.PI / 180) * DelimiterHeight / 2

    If ApplicationGlobals.CurrentDrawingSurface.SurfaceType = UtilDrawingSurface.SurfaceTypes.PDFDocument Then

        ApplicationGlobals.CurrentDrawingSurface.DrawPDFLine(firstPoint.X, _
                                                                firstPoint.Y, _
                                                                secondPoint.X, _
                                                                secondPoint.Y, _
                                                                Colors.Black, _
                                                                False, _
                                                                False)

    Else

        Dim newLine As Line = Nothing

        ApplicationGlobals.CurrentDrawingSurface.DrawCanvasLine(newLine, _
                                                                False, _
                                                                Colors.Black, _
                                                                0, _
                                                                False, _
                                                                False)

        With newLine
            .X1 = firstPoint.X
            .X2 = secondPoint.X
            .Y1 = firstPoint.Y
            .Y2 = secondPoint.Y
        End With

        MyBase.UIElements.Add(newLine)

    End If
        */
    }
}

function calculateAngleOfLine(line, optionalSwitch) {

    var startPoint, endPoint;
    if (optionalSwitch && optionalSwitch === true) {
        startPoint = line.endPoint;
        endPoint = line.startPoint;
    } else if (line.endPoint.x > line.startPoint.x) {
        startPoint = line.endPoint;
        endPoint = line.startPoint;
    } else {
        startPoint = line.startPoint;
        endPoint = line.endPoint;
    }


    var angleOfLine = Math.atan(Math.abs(endPoint.y - startPoint.y) / Math.abs(endPoint.x - startPoint.x)) * 180 / Math.PI;
    
    if (startPoint.x <= endPoint.x && startPoint.y <= endPoint.y) {
        if (isNaN(angleOfLine)) {
            return optionalSwitch ? 0 : 180;
        }
        return angleOfLine;
    } else if (startPoint.x <= endPoint.x && startPoint.y >= endPoint.y) {
        if (isNaN(angleOfLine)) {
            return 270;
        }
        return 360 - angleOfLine;
    } else if (startPoint.x >= endPoint.x && startPoint.y >= endPoint.y) {
        if (isNaN(angleOfLine)) {
            return 180;
        }
        return 180 + angleOfLine;
    } else {
        if (isNaN(angleOfLine)) {
            return 90;
        }
        return 180 - angleOfLine;
    }

    //return angleOfLine;
}

function drawArcOnGivenGraphicsObject(graphicsObjectToUse, objectData, scalePoints, optionalFillColour, optionalColour) {

}

function getColourToUse(objectData, defaultColourOverride) {
    var colourToUse = defaultColourOverride || config.DRAWING_COLOURS.BLACK;
    if (objectData.showAsWarning === true) {
        colourToUse = config.DRAWING_COLOURS.RED;
    } else if (objectData.showAsPinned === true) {
        colourToUse = config.DRAWING_COLOURS.ORANGE;
    }
    //if (curDraggableInteractionObject !== null && objectData.drawingGroupName === curDraggableInteractionObject.elementId) {
    //    colourToUse = config.DRAWING_COLOURS.ORANGE;
    //} else {
    var drawingGroupNameToUse = objectData.linkedDrawingGroupName === undefined ? objectData.drawingGroupName : objectData.linkedDrawingGroupName;
    if (interactionState[drawingGroupNameToUse + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[drawingGroupNameToUse + '_' + 'ACTIVE'] === undefined) {
        colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
    } else if (interactionState[drawingGroupNameToUse + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
        colourToUse = config.DRAWING_COLOURS.CLICKED;
    }
    //}


    return colourToUse;
}

function getPulseColourToUse(defaultColourOverride) {
    var colourToUse = defaultColourOverride || config.DRAWING_COLOURS.BLACK;
    if (pulseController !== null) {
        if (pulseController.pulseState === 'ON') {
            colourToUse = config.DRAWING_COLOURS.ORANGE;
        } else if (pulseController.pulseState === 'OFF') {
            colourToUse = defaultColourOverride || config.DRAWING_COLOURS.BLACK;
        }
    }

    return colourToUse;
}

//function getDimensionPulseColourToUse(objectData) {
//    var colourToUse = getColourToUse(objectData);
//    if (pulseController !== null) {
//        if (pulseController.pulseState === 'ON') {
//            colourToUse = config.DRAWING_COLOURS.ORANGE;
//        } else if (pulseController.pulseState === 'OFF') {
//            colourToUse = config.DRAWING_COLOURS.BLACK;
//        }
//    }

//    return colourToUse;
//}

function getPulseLineWidth(drawingGroupName) {
    if (drawingGroupName === undefined) {
        return pulseController.getCurrentLineWidth();
    } else {
        return dimensionPulseControllers[drawingGroupName].getCurrentLineWidth();
    }

}

function getTextColourToUse(objectData, defaultColourToUse) {
    var colourToUse = defaultColourToUse || config.DRAWING_COLOURS.BLACK;
    var drawingGroupNameToUse = objectData.linkedDrawingGroupName === undefined ? objectData.drawingGroupName : objectData.linkedDrawingGroupName;
    if (mainDrawingGraphicsObjects[drawingGroupNameToUse] !== undefined &&
        ((mainDrawingGraphicsObjects[drawingGroupNameToUse].containerObject && mainDrawingGraphicsObjects[drawingGroupNameToUse].containerObject.hitArea !== null) ||
            (mainDrawingGraphicsObjects[drawingGroupNameToUse].graphicsObject && mainDrawingGraphicsObjects[drawingGroupNameToUse].graphicsObject.interactive === true && ((objectData.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP && objectData.showAsHyperlink) || (objectData.type !== config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP))))) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE;
        if (interactionState[drawingGroupNameToUse + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[drawingGroupNameToUse + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
        } else if (interactionState[drawingGroupNameToUse + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
            colourToUse = config.DRAWING_COLOURS.CLICKED;
        }
    }

    return colourToUse;
}

function getCellTextColourToUse(cellData, defaultColourToUse) {
    var colourToUse = defaultColourToUse || config.DRAWING_COLOURS.BLACK;

    if (cellData.interaction !== undefined || cellData.missingValMeta !== undefined) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE;
        if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[cellData.interaction.elementId + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
        } else if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
            colourToUse = config.DRAWING_COLOURS.CLICKED;
        }
    }

    return colourToUse;
}

function getCellAltTextColourToUse(cellData, defaultColourToUse) {
    var colourToUse = defaultColourToUse || config.DRAWING_COLOURS.BLACK;

    if (cellData.interaction !== undefined || cellData.missingValMeta !== undefined) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE_ALT;
        if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[cellData.interaction.elementId + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER_ALT;
        } else if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
            colourToUse = config.DRAWING_COLOURS.CLICKED_ALT;
        }
    }

    return colourToUse;
}

function getDimensionTextColourToUse(objectData, defaultColourToUse) {
    var colourToUse = defaultColourToUse || config.DRAWING_COLOURS.BLACK;

    if (objectData.showAsWarning === true) {
        colourToUse = config.DRAWING_COLOURS.RED;
    }

    if (dimensionLayersGraphicsObjects[objectData.drawingGroupName] !== undefined && dimensionLayersGraphicsObjects[objectData.drawingGroupName].graphicsObject.hitArea !== null) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE;
        if (interactionState[objectData.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[objectData.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
        } else if (interactionState[objectData.drawingGroupName + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
            colourToUse = config.DRAWING_COLOURS.CLICKED;
        }
    }

    return colourToUse;
}

function drawArc(graphicsObjectToUse, objectData, scalePoints, optionalFillColour, optionalColour) {


    if (scalePoints === true) {
        scalePoint(objectData.centre);
        scalePoint(objectData.startPoint);
        scalePoint(objectData.endPoint);
        objectData.radius = scaleValue(objectData.radius);
        if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
            if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                translateArcBackToOrigin(scalingItemOriginTranslation, objectData);
                itemScaleArc(objectData);
                translateArcBackToOffset(scalingItemOriginTranslation, objectData);
            } else {
                itemScaleArc(objectData);
            }
        }


    }
    flipMainDrawingAreaAxisPoint(objectData.centre);
    flipMainDrawingAreaAxisPoint(objectData.startPoint);
    flipMainDrawingAreaAxisPoint(objectData.endPoint);

    //objectData.radius = scaleValue(objectData.radius);

    //var colourToUse = getColourToUse(objectData);
    var colourToUse;
    var lineWidth;
    if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth();
    } else {
        colourToUse = getColourToUse(objectData, objectData.colour);
    }


    if (optionalFillColour !== undefined) {

        graphicsAdapter.fillArc(optionalFillColour, objectData.centre, objectData.startPoint, objectData.endPoint, objectData.radius, objectData.startAngle, objectData.endAngle, graphicsObjectToUse, lineWidth, optionalColour || colourToUse);
    } else if (objectData.fillColour !== undefined) {
        graphicsAdapter.fillArc(objectData.fillColour, objectData.centre, objectData.startPoint, objectData.endPoint, objectData.radius, objectData.startAngle, objectData.endAngle, graphicsObjectToUse, lineWidth, optionalColour || colourToUse);
    } else {
        if (objectData.dashed === true) {
            //need to flip this back as the cab tilt arc dashed sections need to be calculated on the original data
            //and then flipping is applied to each arc sections points internally
            flipMainDrawingAreaAxisPoint(objectData.centre);

            //need to add topLayer here as dashed arcs are special case where each segment is flipped individually
            objectData.centre.y = objectData.centre.y + (topInDrawingDimensionLayerThickness);
            objectData.startPoint.y = objectData.startPoint.y + (topInDrawingDimensionLayerThickness);
            objectData.endPoint.y = objectData.endPoint.y + (topInDrawingDimensionLayerThickness);

            graphicsAdapter.drawDashedArc(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, graphicsObjectToUse, lineWidth, colourToUse);
        } else {
            graphicsAdapter.drawArc(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, graphicsObjectToUse, lineWidth, optionalColour || colourToUse);
        }

    }
}

//function drawArcNoScaling(objectData, optionalFillColour, optionalColour) {
//    if (optionalFillColour !== undefined) {
//        graphicsAdapter.fillArcNoScaling(optionalFillColour, objectData.centre, objectData.startPoint, objectData.endPoint, objectData.radius, objectData.startAngle, objectData.endAngle, optionalColour || 0x000000);
//    } else {
//        if (objectData.dashed === true) {
//            graphicsAdapter.drawDashedArcNoScaling(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, 0x000000);
//        } else {
//            graphicsAdapter.drawArcNoScaling(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, 0x000000);
//        }

//    }
//}

function drawPolygon(graphicsObjectToUse, objectData) {
    objectData.pointsArray.forEach(function (point) {

        //if (scalingItemDrawingGroupName !== undefined && scalingItemDrawingGroupName !== null && scalingItemDrawingGroupName === objectData.drawingGroupName) {
        //    itemScalePoint(point);
        //} else {
        //    scalePoint(point);
        //}
        scalePoint(point);
        if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
            if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                translatePointBackToOrigin(scalingItemOriginTranslation, point);
                itemScalePoint(point);
                translatePointBackToOffset(scalingItemOriginTranslation, point);
            } else {
                itemScalePoint(point);
            }

        }
        flipMainDrawingAreaAxisPoint(point);

    });

    //var colourToUse = getColourToUse(objectData);
    var colourToUse;
    var lineWidth;
    if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth();
    } else {
        colourToUse = getColourToUse(objectData, objectData.colour);
    }
    var fillColour;
    if (objectData.drawingGroupName.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1 && objectData.fillPolygon === true) {
        if (objectData.fillColour === config.DRAWING_COLOURS.WHITE) {
            fillColour = objectData.fillColour;
        } /*else if (objectData.drawingGroupName.indexOf('configurationModal') !== -1) {
            fillColour = objectData.fillColour;
        } */else {
            //fillColour = getColourToUse(objectData, objectData.fillColour);
            if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
                fillColour = getPulseColourToUse(objectData.colour);
            } else {
                fillColour = getColourToUse(objectData, objectData.colour);
            }
        }

    } else {
        fillColour = objectData.fillColour;
    }
    var thePolygon = graphicsAdapter.drawPolygon(graphicsObjectToUse, objectData.pointsArray, colourToUse, fillColour, lineWidth, objectData.alpha);

    return thePolygon;
}

function drawCircle(graphicsObjectToUse, objectData, scalePoints) {

    var circleCentre, circleRadius, groundLine, axleLettersHeightLocal;
    var groundLineY;
    if (scalePoints === true) {
        //scalePoint(objectData.centre);


        var scaledCopy = scalePointAsCopy(objectData.centre);
        circleRadius = scaleValue(objectData.radius);

        if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
            if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                translatePointBackToOrigin(scalingItemOriginTranslation, scaledCopy);
                itemScalePoint(scaledCopy);
                translatePointBackToOffset(scalingItemOriginTranslation, scaledCopy);
            } else {
                itemScalePoint(scaledCopy);

            }
            //itemScalePoint(scaledCopy);
            circleRadius = itemScaleValue(circleRadius);
        }

        flipMainDrawingAreaAxisPoint(scaledCopy);
        circleCentre = scaledCopy;
        groundLineY = scaleValue(objectData.groundLineY);
        axleLettersHeightLocal = scaleValue(axleLettersHeight);
    } else {
        circleCentre = objectData.centre;
        circleRadius = objectData.radius;
        groundLineY = objectData.groundLineY;
        axleLettersHeightLocal = axleLettersHeight;
    }

    //var colourToUse = getColourToUse(objectData);
    var colourToUse;
    var lineWidth;
    if ((pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName)) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth();
    } else if (dimensionPulseControllers[objectData.drawingGroupName]) {
        colourToUse = getPulseColourToUse(objectData.pulseColour);
        lineWidth = getPulseLineWidth(objectData.drawingGroupName);
    } else {
        colourToUse = getColourToUse(objectData, objectData.colour);
    }
    var theCircle;
    if (objectData.dashEnabled == true) {
        theCircle = graphicsAdapter.drawDashedCircle(graphicsObjectToUse, circleCentre, circleRadius, scaleValue(objectData.dash), lineWidth, colourToUse);
    } else {
        theCircle = graphicsAdapter.drawCircle(graphicsObjectToUse, circleCentre, circleRadius, colourToUse, objectData.fillColour, lineWidth);
    }
    if (objectData.circleType !== undefined && (objectData.circleType === "1" || objectData.circleType === 1) && objectData.drawGroundline === true) {
        var flatSpotWhiteOutWidth, flatSpotWhiteOutHeight;

        var flatSpotWhiteOutRectPos;
        if (groundLineY < 0) {
            flatSpotWhiteOutRectPos = { x: circleCentre.x - circleRadius, y: groundLineY };
            drawRect(graphicsObjectToUse, flatSpotWhiteOutRectPos, circleRadius * 2, groundLineY - 10, true, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
        } else if (groundLineY > 0) {
            flatSpotWhiteOutRectPos = { x: circleCentre.x - circleRadius, y: mainHeight - groundLineY };
            drawRect(graphicsObjectToUse, flatSpotWhiteOutRectPos, circleRadius * 2, groundLineY - graphicsObjectToUse.parent.y + 10, false, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
        }

        var groundLineStartPoint, groundLineEndPoint;


        groundLineStartPoint = { x: circleCentre.x - circleRadius, y: groundLineY };
        groundLineEndPoint = { x: circleCentre.x + circleRadius, y: groundLineY };


        groundLineStartPoint.y = (mainHeight - groundLineStartPoint.y);
        groundLineEndPoint.y = (mainHeight - groundLineEndPoint.y);

        graphicsAdapter.drawLine(graphicsObjectToUse, groundLineStartPoint, groundLineEndPoint, true);


    }

    return theCircle;
}

function drawEllipse(graphicsObjectToUse, objectData, scalePoints) {

    var ellipseCentre, ellipseHalfWidth, ellipseHalfHeight;

    if (scalePoints === true) {
        //scalePoint(objectData.centre);


        var scaledCopy = scalePointAsCopy(objectData.centre);
        ellipseHalfWidth = scaleValue(objectData.halfWidth);
        ellipseHalfHeight = scaleValue(objectData.halfHeight);

        if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[objectData.drawingGroupName] !== undefined) {
            if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
                translatePointBackToOrigin(scalingItemOriginTranslation, scaledCopy);
                itemScalePoint(scaledCopy);
                translatePointBackToOffset(scalingItemOriginTranslation, scaledCopy);
            } else {
                itemScalePoint(scaledCopy);

            }
            //itemScalePoint(scaledCopy);
            ellipseHalfWidth = itemScaleValue(ellipseHalfWidth);
            ellipseHalfHeight = itemScaleValue(ellipseHalfHeight);
        }

        flipMainDrawingAreaAxisPoint(scaledCopy);
        ellipseCentre = scaledCopy;
    } else {
        ellipseCentre = objectData.centre;
        ellipseHalfWidth = objectData.halfWidth;
        ellipseHalfHeight = objectData.halfHeight;

    }

    //var colourToUse = getColourToUse(objectData);
    var colourToUse;
    var lineWidth;
    if ((pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName)) {
        colourToUse = getPulseColourToUse(objectData.colour);
        lineWidth = getPulseLineWidth();
    } else if (dimensionPulseControllers[objectData.drawingGroupName]) {
        colourToUse = getPulseColourToUse(objectData.pulseColour);
        lineWidth = getPulseLineWidth(objectData.drawingGroupName);
    } else {
        colourToUse = getColourToUse(objectData, objectData.colour);
    }
    var theEllipse;
    if (objectData.dashEnabled == true) {
        theEllipse = graphicsAdapter.drawDashedEllipse(graphicsObjectToUse, ellipseCentre, ellipseHalfWidth, ellipseHalfHeight, scaleValue(objectData.dash), lineWidth, colourToUse);
    } else {
        theEllipse = graphicsAdapter.drawEllipse(graphicsObjectToUse, ellipseCentre, ellipseHalfWidth, ellipseHalfHeight, colourToUse, objectData.fillColour, lineWidth);
    }


    return theEllipse;
}


function drawRect(graphicsObjectToUse, position, width, height, addToGraphics, optionalColour, optionalFillColour, optionalAlpha) {

    var rectPoints = new RectHelper(position, width, height);

    if (addToGraphics === true) {
        flipMainDrawingAreaAxisPoint(rectPoints.topLeft);
        flipMainDrawingAreaAxisPoint(rectPoints.topRight);
        flipMainDrawingAreaAxisPoint(rectPoints.bottomRight);
        flipMainDrawingAreaAxisPoint(rectPoints.bottomLeft);
    }

    return graphicsAdapter.drawRect(graphicsObjectToUse, rectPoints.topLeft, rectPoints.topRight, rectPoints.bottomRight, rectPoints.bottomLeft, addToGraphics, optionalColour, optionalFillColour, optionalAlpha);
}

function drawRectFromRectObject(graphicsObjectToUse, objectData) {
    scalePoint(objectData.position);

    objectData.width = scaleValue(objectData.width);
    objectData.height = scaleValue(objectData.height);
    var rectPoints = new RectHelper(objectData.position, objectData.width, objectData.height);

    flipMainDrawingAreaAxisPoint(rectPoints.topLeft);
    flipMainDrawingAreaAxisPoint(rectPoints.topRight);
    flipMainDrawingAreaAxisPoint(rectPoints.bottomRight);
    flipMainDrawingAreaAxisPoint(rectPoints.bottomLeft);

    graphicsAdapter.drawRect(graphicsObjectToUse, rectPoints.topLeft, rectPoints.topRight, rectPoints.bottomRight, rectPoints.bottomLeft, true, objectData.lineColour, objectData.fillColour, objectData.alpha);
}

function drawComplianceDashboard(complianceDashboard) {

    var largestComplianceItemLabel = complianceDashboard.getLargestLabelWidth();

    var startPoint = LayoutManager.getComplianceDashboardStartPoint();
    var iconSize = complianceDashboard.getIconSize();
    var rowHeight = complianceDashboard.getItemRowHeight(), titleRowHeight = complianceDashboard.getTitleRowHeight();
    var largeSpacerHeight = complianceDashboard.getLargeSpacerHeight(), smallSpacerHeight = complianceDashboard.getSmallSpacerHeight();
    var width = complianceDashboard.getWidth();
    var rowCount = 0, titleRowCount = 0, smallSpacerCount = 0, largeSpacerCount = 0, numRowIcons = 0, numRowTexts = 0, cumulativeRowTextWidth = 0;
    var complianceOverallHeight = complianceDashboard.getHeight();
    var lineRowCount = 0;
    var cumulativeCustomSpacerHeight = 0;

    var complianceNavigationBarContainer = null;
    var complianceContentAreaContainer = null;
    var contentAreaStartPoint = { x: startPoint.x, y: complianceDashboard.getNavigationBarHeight() };

    var useExisting = false;
    if (complianceContainer === null) {
        //complianceContainer = graphicsAdapter.getDrawingContainer(startPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight, complianceDashboard.getBackgroundColour()/*, complianceDashboard.getBackgroundBorderColour()*/);
        complianceContainer = drawRect(graphicsAdapter.getNewGraphicsObject(), startPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight, false, complianceDashboard.getBackgroundColour(), complianceDashboard.getBackgroundColour());
        //complianceNavigationBarContainer = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, width + complianceDashboard.getBackgroundBuffer() * 2, complianceDashboard.getNavigationBarHeight(), complianceDashboard.getNavigationBarBackgroundColour(), complianceDashboard.getNavigationBarBackgroundColour());
        complianceNavigationBarContainer = drawRect(graphicsAdapter.getNewGraphicsObject(), startPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceDashboard.getNavigationBarHeight(), false, complianceDashboard.getNavigationBarBackgroundColour(), complianceDashboard.getNavigationBarBackgroundColour());

        complianceContentAreaContainer = drawRect(graphicsAdapter.getNewGraphicsObject(), contentAreaStartPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight - complianceDashboard.getNavigationBarHeight(), false, complianceDashboard.getBackgroundColour()/*, complianceDashboard.getBackgroundBorderColour()*/);
        //complianceContentAreaContainer = graphicsAdapter.getDrawingContainer(contentAreaStartPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight - complianceDashboard.getNavigationBarHeight(), complianceDashboard.getBackgroundColour()/*, complianceDashboard.getBackgroundBorderColour()*/);
        complianceContainer.addChild(complianceNavigationBarContainer);
        complianceContainer.addChild(complianceContentAreaContainer);
    } else {
        useExisting = true;

        var bgGraphicsObject = complianceContainer;

        complianceNavigationBarContainer = bgGraphicsObject.getChildAt(0);
        complianceContentAreaContainer = bgGraphicsObject.getChildAt(1);

        complianceContainer.clear();
        drawRect(complianceContainer, startPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight, false, complianceDashboard.getBackgroundColour(), complianceDashboard.getBackgroundColour());
        //complianceContainer.x = startPoint.x;
        //complianceContainer.y = startPoint.y;
        //complianceContainer.width = width + complianceDashboard.getBackgroundBuffer() * 2;
        //complianceContainer.height = complianceOverallHeight;
        complianceContainer.dirty++;
        complianceContainer.clearDirty++;
        complianceContainer.removeChildren();



        //complianceContentAreaContainer.x = contentAreaStartPoint.x;
        //complianceContentAreaContainer.y = contentAreaStartPoint.y;

        //bgGraphicsObject.clear();
        //bgGraphicsObject.width = width + complianceDashboard.getBackgroundBuffer() * 2;
        //bgGraphicsObject.height = complianceOverallHeight;
        //bgGraphicsObject.dirty++;
        //bgGraphicsObject.clearDirty++;
        //bgGraphicsObject.removeChildren();
        //complianceContainer.dirty++;
        //complianceContainer.clearDirty++;
        //complianceContainer.removeChildren(1);

        var navRow = complianceNavigationBarContainer.getChildAt(0);
        navRow.dirty++;
        //navRow.clearDirty++;
        navRow.removeChildren();



        complianceNavigationBarContainer.clear();
        drawRect(complianceNavigationBarContainer, startPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceDashboard.getNavigationBarHeight(), false, complianceDashboard.getNavigationBarBackgroundColour(), complianceDashboard.getNavigationBarBackgroundColour());
        complianceNavigationBarContainer.dirty++;
        complianceNavigationBarContainer.clearDirty++;
        complianceNavigationBarContainer.removeChildren(0);

        complianceContentAreaContainer.clear();
        drawRect(complianceContentAreaContainer, contentAreaStartPoint, width + complianceDashboard.getBackgroundBuffer() * 2, complianceOverallHeight - complianceDashboard.getNavigationBarHeight(), false, complianceDashboard.getBackgroundColour()/*, complianceDashboard.getBackgroundBorderColour()*/);
        complianceContentAreaContainer.dirty++;
        complianceContentAreaContainer.clearDirty++;
        var contentRows = complianceContentAreaContainer.removeChildren(0);
        contentRows.forEach(function (childRow) {
            childRow.dirty++;
            childRow.clearDirty++;
            childRow.removeChildren();
        });

        bgGraphicsObject.addChild(complianceNavigationBarContainer);
        bgGraphicsObject.addChild(complianceContentAreaContainer);
        //complianceContainer.addChild(bgGraphicsObject);

    }
    var complianceItemCounter = 0;
    complianceDashboard.getRows().forEach(function (complianceRow) {

        var complianceItemRow;

        var bgColourToUse = complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION ? complianceDashboard.getNavigationBarBackgroundColour() : complianceDashboard.getBackgroundColour();

        var rowY, rowX;
        rowX = complianceDashboard.getBackgroundBuffer() + contentAreaStartPoint.x;
        if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
            rowY = smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount + cumulativeCustomSpacerHeight;
        } else {
            rowY = rowHeight * rowCount + smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount + cumulativeCustomSpacerHeight;
            rowY += contentAreaStartPoint.y;
        }

        if (allComplianceDashboardItems[complianceRow.getId()] && allComplianceDashboardItems[complianceRow.getId()].row) {
            complianceItemRow = allComplianceDashboardItems[complianceRow.getId()].row;
            complianceItemRow.clear();
            //drawRect(complianceItemRow, { x: complianceDashboard.getBackgroundBuffer(), y: rowHeight * rowCount + smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount }, width, rowHeight, false, bgColourToUse/*, complianceItem.getBackgroundColour() || complianceDashboard.getBackgroundColour()*/);
            if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
                drawRect(complianceItemRow, { x: rowX, y: rowY }, width, complianceDashboard.getNavigationBarHeight(), false, bgColourToUse);
            } else {
                drawRect(complianceItemRow, { x: rowX, y: rowY }, width, rowHeight, false, bgColourToUse/*, complianceItem.getBackgroundColour() || complianceDashboard.getBackgroundColour()*/);
            }
            complianceItemRow.dirty++;
            complianceItemRow.clearDirty++;
        } else {
            if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
                complianceItemRow = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: rowX, y: rowY }, width, complianceDashboard.getNavigationBarHeight(), false, bgColourToUse);
            } else {
                complianceItemRow = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: rowX, y: rowY }, width, rowHeight, false, bgColourToUse/*, complianceItem.getBackgroundColour() || complianceDashboard.getBackgroundColour()*/);
            }

            allComplianceDashboardItems[complianceRow.getId()] = {};
            allComplianceDashboardItems[complianceRow.getId()].row = complianceItemRow;
        }


        numRowIcons = 0;
        numRowTexts = 0;
        cumulativeRowTextWidth = 0;
        complianceRow.getItems().forEach(function (complianceItem) {

            var curComplianceGraphicElement;

            var rowContentY;
            if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
                rowContentY = titleRowCount > 0 ? ((titleRowHeight * titleRowCount) + titleRowHeight / 2) + smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount + complianceDashboardDefinition.getBackgroundBuffer() : complianceDashboard.getNavigationBarHeight() / 2;
            } else {
                rowContentY = rowCount > 0 ? ((rowHeight * rowCount) + rowHeight / 2) + smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount + cumulativeCustomSpacerHeight : (rowHeight / 2) + (smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount) + cumulativeCustomSpacerHeight;
                rowContentY += contentAreaStartPoint.y;
            }

            if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {





                var complianceItemText;


                var textItemXValToUse;
                if (numRowTexts === 0) {
                    textItemXValToUse = rowX;

                } else {
                    if (complianceRow.getTextPositionMethod() === config.COMPLIANCE_ROW_TEXT_POSITION_METHOD.SEQUENTIAL_FROM_LEFT) {
                        textItemXValToUse = rowX + (complianceDashboard.getBackgroundBuffer() * numRowTexts) + cumulativeRowTextWidth;
                    } else {
                        textItemXValToUse = rowX + width - complianceDashboard.getWidthOfText(complianceItem.getText()) - complianceDashboard.getWidthOfText(complianceItem.getText()) * numRowIcons
                    }

                }
                if (allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()]) {

                    complianceItemText = allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()];
                    complianceItemText.text = complianceItem.getText();
                    // var textItemXValToUse;
                    //if (numRowTexts === 0) {
                    //    textItemXValToUse = complianceDashboard.getBackgroundBuffer();
                    //} else {
                    //    textItemXValToUse = complianceDashboard.getBackgroundBuffer() + width - complianceDashboard.getWidthOfText(complianceItem.getText()) - complianceDashboard.getWidthOfText(complianceItem.getText()) * numRowIcons
                    //}
                    complianceItemText.x = textItemXValToUse;
                    complianceItemText.y = rowContentY;
                    //complianceItemText.graphicsData[0].fillColor = config.DRAWING_COLOURS.TABLE_CELL;
                    complianceItemText.style.fill = getComplianceItemTextColour(complianceItem);

                } else {

                    //var textItemXValToUse;
                    //if (numRowTexts === 0) {
                    //    textItemXValToUse = complianceDashboard.getBackgroundBuffer();
                    //} else {
                    //    textItemXValToUse = complianceDashboard.getBackgroundBuffer() + width - complianceDashboard.getWidthOfText(complianceItem.getText()) - complianceDashboard.getWidthOfText(complianceItem.getText()) * numRowIcons
                    //}
                    complianceItemText = drawText(complianceItem.getText(), { x: textItemXValToUse, y: rowContentY }, false, { x: 0, y: 0.5 }, undefined, complianceItem.getTextStyle(), getComplianceItemTextColour(complianceItem));
                    allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()] = complianceItemText;



                }
                complianceItemRow.addChild(complianceItemText);
                curComplianceGraphicElement = complianceItemText;






                cumulativeRowTextWidth += complianceDashboard.getWidthOfText(complianceItem.getText());
                numRowTexts++;



            } else if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON) {
                var complianceItemIcon;
                var iconSizeToUse = complianceItem.getIconSize() || iconSize;

                //var yValToUse;
                //if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
                //    yValToUse = rowY;
                //} else {
                //    yValToUse = rowY;
                //}

                if (allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()]) {
                    complianceItemIcon = allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()];
                    complianceItemIcon.text = complianceItem.getIcon();
                    complianceItemIcon.x = rowX + width - iconSizeToUse / 2 - iconSizeToUse * numRowIcons;
                    complianceItemIcon.y = rowContentY;
                    complianceItemIcon.style.fill = complianceItem.getIconColour();
                    complianceItemIcon.style.fontSize = iconSizeToUse + 'px';
                    complianceItemIcon.style.fontFamily = 'FontAwesome5ProTruckScience';
                } else {

                    complianceItemIcon = drawText(complianceItem.getIcon(), { x: rowX + width - iconSizeToUse / 2 - iconSizeToUse * numRowIcons, y: rowContentY }, false, { x: 0.5, y: 0.5 }, undefined, iconSizeToUse + 'px FontAwesome5ProTruckScience', complianceItem.getIconColour());
                    allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()] = complianceItemIcon;

                }
                complianceItemRow.addChild(complianceItemIcon);
                numRowIcons++;
                curComplianceGraphicElement = complianceItemIcon;
            } else if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.SPACER) {
                if (complianceItem.getSpacerType() === config.SPACER_TYPE.LINE) {
                    //if (titleRowCount === 2 && rowCount === 0) {
                    //var lineY = rowHeight * rowCount + smallSpacerHeight * smallSpacerCount + largeSpacerHeight * largeSpacerCount + complianceDashboardDefinition.getBackgroundBuffer();
                    complianceItemRow.addChild(drawLine(graphicsAdapter.getNewGraphicsObject(), new Line(rowX, rowContentY - rowHeight / 2, rowX + width, rowContentY - rowHeight / 2), false));
                    lineRowCount++;
                    //}
                } else if (complianceItem.getSpacerType() === config.SPACER_TYPE.SMALL_VERTICAL) {
                    smallSpacerCount++;
                } else if (complianceItem.getSpacerType() === config.SPACER_TYPE.LARGE_VERTICAL) {
                    largeSpacerCount++;
                } else if (complianceItem.getSpacerType() === config.SPACER_TYPE.CUSTOM_VERTICAL) {
                    cumulativeCustomSpacerHeight += complianceItem.getHeight();
                }
                rowCount--;
            }

            if (complianceItem.getInteraction() !== undefined) {
                var interaction = complianceItem.getInteraction();
                interaction.callbacks = getCallbacks(interaction, interaction.callbacks);
                if (!complianceDashboardItemsForInteractionHighlights[complianceRow.getId()]) {
                    complianceDashboardItemsForInteractionHighlights[complianceRow.getId()] = {};
                }
                if (!complianceDashboardItemsForInteractionHighlights[complianceRow.getId()][complianceItem.getId()]) {
                    complianceDashboardItemsForInteractionHighlights[complianceRow.getId()][complianceItem.getId()] = curComplianceGraphicElement;
                    if (interaction.callbacks !== undefined) {
                        curComplianceGraphicElement.interactive = true;
                        curComplianceGraphicElement.buttonMode = true;
                        if (interaction.callbacks.mouseover !== undefined && typeof interaction.callbacks.mouseover === 'function') {
                            curComplianceGraphicElement.mouseover = interaction.callbacks.mouseover;
                        }
                        if (interaction.callbacks.click !== undefined && typeof interaction.callbacks.click === 'function') {
                            curComplianceGraphicElement.click = interaction.callbacks.click;
                            curComplianceGraphicElement.tap = interaction.callbacks.click;
                        }
                        if (interaction.callbacks.mouseout !== undefined && typeof interaction.callbacks.mouseout === 'function') {
                            curComplianceGraphicElement.mouseout = interaction.callbacks.mouseout;
                        }
                        if (interaction.callbacks.mousemove !== undefined && typeof interaction.callbacks.mousemove === 'function') {
                            curComplianceGraphicElement.mousemove = interaction.callbacks.mousemove;
                        }
                    }
                }
                //addInteractionToText(dimension, textObj);
            } else if (complianceDashboardItemsForInteractionHighlights[complianceRow.getId()]) {
                if (complianceDashboardItemsForInteractionHighlights[complianceRow.getId()][complianceItem.getId()]) {
                    complianceDashboardItemsForInteractionHighlights[complianceRow.getId()][complianceItem.getId()] = undefined;
                }
                //complianceDashboardItemsForInteractionHighlights[complianceRow.getId()] = undefined;
            }

            //if (!useExisting) {

            //if (complianceItem.getInteraction() !== undefined) {
            //    var interaction = complianceItem.getInteraction();
            //    interaction.callbacks = getCallbacks(interaction, interaction.callbacks);
            //    if (!complianceDashboardItemsForInteractionHighlights[complianceRow.getId()]) {
            //        complianceDashboardItemsForInteractionHighlights[complianceRow.getId()] = {};
            //    }
            //    complianceDashboardItemsForInteractionHighlights[complianceRow.getId()][complianceItem.getId()] = curComplianceGraphicElement;
            //    if (interaction.callbacks !== undefined) {
            //        curComplianceGraphicElement.interactive = true;
            //        curComplianceGraphicElement.buttonMode = true;
            //        if (interaction.callbacks.mouseover !== undefined && typeof interaction.callbacks.mouseover === 'function') {
            //            curComplianceGraphicElement.mouseover = interaction.callbacks.mouseover;
            //        }
            //        if (interaction.callbacks.click !== undefined && typeof interaction.callbacks.click === 'function') {
            //            curComplianceGraphicElement.click = interaction.callbacks.click;
            //            curComplianceGraphicElement.tap = interaction.callbacks.click;
            //        }
            //        if (interaction.callbacks.mouseout !== undefined && typeof interaction.callbacks.mouseout === 'function') {
            //            curComplianceGraphicElement.mouseout = interaction.callbacks.mouseout;
            //        }
            //        if (interaction.callbacks.mousemove !== undefined && typeof interaction.callbacks.mousemove === 'function') {
            //            curComplianceGraphicElement.mousemove = interaction.callbacks.mousemove;
            //        }
            //    }
            //    //addInteractionToText(dimension, textObj);
            //}
            //} else {


            //}

        });



        if (complianceRow.getId() === config.COMPLIANCE_IDS.NAVIGATION) {
            titleRowCount++;
            complianceNavigationBarContainer.addChild(complianceItemRow);
        } else {
            rowCount++;
            complianceContentAreaContainer.addChild(complianceItemRow);
        }
    });
    //if (!useExisting) {
    drawingArea.tablesDrawingArea.addChild(complianceContainer);
    //}

}

function getComplianceItemTextColour(complianceItem) {

    var colourToUse;

    if (complianceItem.getInteraction() !== undefined) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE;
        if (interactionState[complianceItem.getInteraction().elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[complianceItem.getInteraction().elementId + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
        } else if (complianceItem.getCustomClickableColour()) {
            colourToUse = complianceItem.getCustomClickableColour();
        }
    } else {
        colourToUse = complianceItem.getTextColour();
    }
    return colourToUse;
    /*
    if (cellData.interaction !== undefined || cellData.missingValMeta !== undefined) {
        colourToUse = config.DRAWING_COLOURS.CLICKABLE;
        if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[cellData.interaction.elementId + '_' + 'ACTIVE'] === undefined) {
            colourToUse = config.DRAWING_COLOURS.MOUSE_OVER;
        } else if (interactionState[cellData.interaction.elementId + '_' + config.INTERACTION_EVENT.CLICK] !== undefined) {
            colourToUse = config.DRAWING_COLOURS.CLICKED;
        }
    }
    */
}

function getComplianceDashboardPosition() {
    var complianceDashboardStartPoint = LayoutManager.getComplianceDashboardStartPoint();
    return { x: Math.round(complianceDashboardStartPoint.x + complianceDashboardDefinition.getBackgroundBuffer() + complianceDashboardDefinition.getWidth() / 2), y: Math.round(tablesY + complianceDashboardStartPoint.y) };
}

function LayoutManager() { }
LayoutManager.getComplianceDashboardStartPoint = function () {

    var largestLabelWidth = getLargestLabelWidth(tableDefinitionData);
    var totalComplianceWidth = complianceDashboardDefinition.getWidth() + complianceDashboardDefinition.getBackgroundBuffer() * 2;

    
    var totalColumnWidth = (tableDefinitionData.cellWidth * (getTotalColumnCount(tableDefinitionData) - 1)) + Math.max(largestLabelWidth + tableDefinitionData.labelBuffer * 2, tableDefinitionData.cellWidth);

    //var separatorThicknessAndBuffer = complianceDashboardDefinition.getIconSize() * 2 + config.configurationTableAreaVerticalSeparatorThickness;

    var tableAndLabelTotalWidth = totalColumnWidth;

    var complianceStartX = (overallWidth / 2) - ((tableAndLabelTotalWidth + totalComplianceWidth + complianceDashboardDefinition.getDashboardTableGap() /*+ separatorThicknessAndBuffer*/) / 2);

    //var yOffset = tableDefinitionData.tableHeight > complianceDashboardDefinition.getHeight() + complianceDashboardDefinition.getBackgroundBuffer() * 2 ? tableDefinitionData.tableHeight/2 - (complianceDashboardDefinition.getHeight() + complianceDashboardDefinition.getBackgroundBuffer() * 2)/2 : complianceDashboardDefinition.getYOffset();
    var yOffset = complianceDashboardDefinition.getYOffset();

    return { x: complianceStartX, y: yOffset };
}
LayoutManager.getTableStartX = function () {
    if (complianceDashboardDefinition) {
        var complianceDashboardStartPoint = LayoutManager.getComplianceDashboardStartPoint();

        return complianceDashboardStartPoint.x + complianceDashboardDefinition.getBackgroundBuffer() * 2 + complianceDashboardDefinition.getWidth() + complianceDashboardDefinition.getDashboardTableGap();// + complianceDashboardDefinition.getDashboardTableGap();// + complianceDashboardDefinition.getIconSize() * 4 + config.configurationTableAreaVerticalSeparatorThickness;
    } else {
        var largestLabelWidth = getLargestLabelWidth(tableDefinitionData);
        var totalColumnWidth = (tableDefinitionData.cellWidth * (getTotalColumnCount(tableDefinitionData) - 1)) + Math.max(largestLabelWidth + tableDefinitionData.labelBuffer * 2, tableDefinitionData.cellWidth);

        //var tableAndLabelTotalWidth = (largestLabelWidth) + totalColumnWidth + (tableDefinitionData.labelBuffer);

        return (overallWidth / 2) - ((totalColumnWidth + 20) / 2);
    }

}

function drawVerticalSeparatorBetweenDashboardAndTable() {
    var startPoint = LayoutManager.getComplianceDashboardStartPoint();

    var width = complianceDashboardDefinition.getWidth() + complianceDashboardDefinition.getBackgroundBuffer() * 2;
    var separatorHeight = Math.max(complianceDashboardDefinition.getHeight() + complianceDashboardDefinition.getBackgroundBuffer() * 2, getTableHeight(tableDefinitionData));

    startPoint.x = startPoint.x + width + complianceDashboardDefinition.getDashboardTableGap() / 2;
    var endPoint = JSON.parse(JSON.stringify(startPoint));
    endPoint.y = endPoint.y + separatorHeight;

    drawingArea.tablesDrawingArea.addChild(graphicsAdapter.drawLine(graphicsAdapter.getNewGraphicsObject(), startPoint, endPoint, false, complianceDashboardDefinition.getDashboardTableGap(), config.DRAWING_COLOURS.WHITE));

}

function convertDecimalToHex(decimalVal) {
    return decimalVal.toString(16);
}

function drawTable(tableData) {
    //var totalPosition = { x: frontPosition.x + ((rearPosition.x - frontPosition.x) / 2), y: frontPosition.y };
    //var columnWidth = Math.round(100 * scalingFactor), columnHeight = Math.round(120 * scalingFactor);

    //tableData.front.position.x = inDrawingDimensionLayerOffsetX + (Math.round(tableData.front.position.x * scalingFactor) - (tableData.cellWidth / 2));
    //tableData.total.position.x = inDrawingDimensionLayerOffsetX + (Math.round(tableData.total.position.x * scalingFactor) - (tableData.cellWidth / 2));
    //tableData.rear.position.x = inDrawingDimensionLayerOffsetX + (Math.round(tableData.rear.position.x * scalingFactor) - (tableData.cellWidth / 2));
    var largestLabelWidth = getLargestLabelWidth(tableData);
    var totalColumnWidth = tableData.cellWidth * getTotalColumnCount(tableData);

    var tableAndLabelTotalWidth = (largestLabelWidth) + totalColumnWidth + (tableData.labelBuffer);
    var tableAndLabelSectionX = LayoutManager.getTableStartX();//(overallWidth / 2) - (tableAndLabelTotalWidth / 2);

    if (drawingArea.tablesDrawingArea.children.length > 1) {
        drawingArea.tablesDrawingArea.removeChildren(2);
    }

    var startX = tableAndLabelSectionX /*+ largestLabelWidth + tableData.labelBuffer*2*/;
    var colXOffsetMultiplier = 0;
    var interColGap = config.configurationTableInterColGap;
    //tableCols = {};
    var leftOfTableLabelAdded = false;
    var cumulativeColWidth = 0;
    Object.keys(tableData.cols).forEach(function (key) {

        var cellWidthToUse;
        if (key === 'Labels') {
            cellWidthToUse = Math.max(largestLabelWidth + tableData.labelBuffer * 2, tableData.cellWidth);
        } else {
            cellWidthToUse = tableData.cellWidth;
        }

        //if (key !== 'cellWidth' && key !== 'cellHeight' && key !== 'labelBuffer') {

        tableData.cols[key].position.x = startX + cumulativeColWidth + (colXOffsetMultiplier * interColGap);
        cumulativeColWidth += cellWidthToUse;
        colXOffsetMultiplier++;
        var labelPosition = 'none';
        if (key === 'front' || key === 'Front') {
            labelPosition = 'left';
            leftOfTableLabelAdded = true;
        } else if (key === 'trailerFront' && leftOfTableLabelAdded === false) {
            labelPosition = 'left';
        } /*else if (key === 'total') {
            labelPosition = 'right';
        }*/



        tableCols[key] = drawColumn(tableCols[key], tableData.cols[key], tableData.cols[key].title, tableData.cols[key].position, cellWidthToUse, tableData.cellHeight, tableData.labelBuffer, labelPosition, tableData.cols[key].colour, tableData.cols[key].textColour, tableData.titleRowBackgroundColour, tableData.titleCellBorderColour);
        drawingArea.tablesDrawingArea.addChild(tableCols[key].table);
        //}
    });



    //tableData.front.position.x = tableAndLabelSectionX + largestLabelWidth + tableData.labelBuffer;
    //tableData.rear.position.x = tableData.front.position.x + tableData.cellWidth;
    //tableData.total.position.x = tableData.rear.position.x + tableData.cellWidth;

    //frontTable = drawColumn(tableData.front.cellData, tableData.front.title, tableData.front.position, tableData.cellWidth, tableData.cellHeight, tableData.labelBuffer, 'left');
    //totalTable = drawColumn(tableData.total.cellData, tableData.total.title, tableData.total.position, tableData.cellWidth, tableData.cellHeight, tableData.labelBuffer, 'right');
    //rearTable = drawColumn(tableData.rear.cellData, tableData.rear.title, tableData.rear.position, tableData.cellWidth, tableData.cellHeight, tableData.labelBuffer, 'none');

    //drawingArea.tablesDrawingArea.addChild(frontTable.table);
    //drawingArea.tablesDrawingArea.addChild(totalTable.table);
    //drawingArea.tablesDrawingArea.addChild(rearTable.table);

    //for (var i = 0; i < Object.keys(frontTable.cellPositions).length; i++) {
    //    drawingArea.tablesDrawingArea.addChild(graphicsAdapter.drawLine({ x: frontTable.table.position.x, y: frontTable.table.position.y + frontTable.cellPositions["Cell_" + i].y }, { x: rearTable.table.position.x, y: rearTable.table.position.y + rearTable.cellPositions["Cell_" + i].y }, false, undefined, undefined, 0.05));
    //}
}

function getLargestLabelWidth(tableData) {
    var largestWidth = 0;

    var labelsCol = tableData.cols[Object.keys(tableData.cols)[0]];

    Object.keys(labelsCol.cellData).forEach(function (key) {
        largestWidth = Math.max(getWidthOfText(labelsCol.cellData[key].label || labelsCol.cellData[key].value, curFontName, tableFontSize), largestWidth);
    });

    largestWidth = Math.max(getWidthOfText(labelsCol.title, curFontName, labelsCol.titleFontSize, labelsCol.titleFontStyleAddition), largestWidth)
    return largestWidth;
}

function getTotalColumnCount(tableData) {
    //var totalColumnCount = 0;
    //Object.keys(tableData.cols).forEach(function (key) {
    //    if (key !== 'cellWidth' && key !== 'cellHeight' && key !== 'labelBuffer') {
    //        totalColumnCount++;
    //    }
    //})
    //return totalColumnCount;
    return Object.keys(tableData.cols).length;
}

// function getHasLabelsCol(tableData) {
//     Object.keys(tableData.cols).forEach(function (key) {
//         if (key !== 'cellWidth' && key !== 'cellHeight' && key !== 'labelBuffer') {
//             totalColumnCount++;
//         }
//     })
// }

function drawColumn(existingColumn, colData, title, position, width, height, labelBuffer, showLabels, colColour, colTextColour, titleBGColour, titleBorderColour) {

    var data = colData.cellData;

    //var graphicsObjectToUse = graphicsAdapter.getNewGraphicsObject();
    var optionalColour = undefined;//config.DRAWING_COLOURS.TABLE_CELL;//this is not currently used but leaving in in case needed in future
    var tableStandardBGColour = config.DRAWING_COLOURS.TABLE_CELL;
    var tableAltBGColour = colColour;

    var largestLabelWidth = getLargestLabelWidth(tableDefinitionData);
    var cellX = 0;
    var cellTextX = getCellTextX(colData, cellX, width, labelBuffer);

    //draw cell rectangles and add into container
    var numUnderlineOrSpacerRows = 0;
    var numDataRows = 0;
    var numRows = 0;

    var cumulativeSpacerHeight = 0;
    Object.keys(data).forEach(function (key) {
        if (key.indexOf('spacer') === -1) {
            if (key.indexOf('underline') !== -1) {
                numUnderlineOrSpacerRows++;
            } else {
                numDataRows++;
            }

        }
        numRows++;
    });
    var container;
    var useExisting = false;
    if (existingColumn) {
        container = existingColumn.table;
        container.x = position.x;
        container.y = position.y;
        container.getChildAt(0).width = width;
        container.getChildAt(0).height = height * numRows;
        useExisting = true;
        container.removeChildren(1);
    } else {
        container = graphicsAdapter.getDrawingContainer(position, width, (height * (numDataRows - 1)) + (underlineHeight * numUnderlineOrSpacerRows) + config.tableAndDashboardTitleHeight);
    }


    var fontSize = tableFontSize;

    var tableFont = fontSize + "px " + curFontName;
    var labelFont = labelFontSize + "px " + curFontName;
    var titleFont = dashboardAndTableTitleFontSize + 'px ' + curFontName;
    var cellPositions = {};

    var titleRow;
    var titleText;
    //add/update col titles
    var titleFontStyleAddition = '';
    var titleTextStartX, titleTextXPos;
    if (colData.contentsAlign === 'left') {
        titleTextXPos = cellTextX;
        titleTextStartX = 0;
    } else if (colData.contentsAlign === 'right') {
        titleTextXPos = width - ((width - cellTextX) / 2);
        titleTextStartX = 1;
    } else if (colData.contentsAlign === undefined) {
        //centre
        titleTextXPos = width / 2
        titleTextStartX = 0.5;
    }
    if (allTableCellTextObjects[title + '.text']) {
        titleRow = allTableCellTextObjects[title + '.row'];//container.getChildAt(1);
        titleRow.removeChildren();

        titleText = allTableCellTextObjects[title + '.text'];//titleRow.getChildAt(0);
        //titleText.text = title;
        titleText.x = titleTextXPos;
        titleText.y = position.y + (config.tableAndDashboardTitleHeight / 2);
        //titleText.style.fill = config.DRAWING_COLOURS.BLACK;
        titleRow.clear();
        drawRect(titleRow, { x: 0, y: position.y }, width, config.tableAndDashboardTitleHeight, false, titleBorderColour || titleBGColour, titleBGColour);
        //titleRow.graphicsData[0].fillColor = config.DRAWING_COLOURS.TABLE_CELL;

        titleRow.dirty++;
        titleRow.clearDirty++;
    } else {

        if (colData.titleFontStyleAddition) {
            titleFontStyleAddition = colData.titleFontStyleAddition + ' ';
        }

        titleRow = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: 0, y: position.y }, width, config.tableAndDashboardTitleHeight, false, titleBorderColour || titleBGColour, titleBGColour);
        titleText = drawText(title, { x: titleTextXPos, y: position.y + (config.tableAndDashboardTitleHeight / 2) }, false, { x: titleTextStartX, y: 0.5 }, undefined, titleFontStyleAddition + titleFont, config.DRAWING_COLOURS.COLUMN_TITLE);

        allTableCellTextObjects[title + '.text'] = titleText;
        allTableCellTextObjects[title + '.row'] = titleRow;

    }

    titleRow.addChild(titleText);
    container.addChild(titleRow);




    var numUnderlineRows = 0;
    var numSpacersSoFar = 0;

    var cellIdCounter = 0;
    for (var i = 0; i < numRows; i++) {

        var callbacks;
        var cellY = getCellYOffset(position.y, height, underlineHeight, i, numUnderlineRows, cumulativeSpacerHeight, numSpacersSoFar);//position.y + (height * (i + 1));
        var cellMeta = data[Object.keys(data)[i]];
        if (!Object.keys(data)[i].includes('underline')) {

            var cell, warningIcon;
            var id = cellMeta.id;

            var heightToUse = height;
            if (cellMeta.id && cellMeta.id.indexOf && cellMeta.id.indexOf('spacer') !== -1) {
                cumulativeSpacerHeight += cellMeta.height;
                heightToUse = cellMeta.height;
                numSpacersSoFar++;
            }

            if (allTableCells[cellMeta.id]) {

                cell = allTableCells[cellMeta.id].cell;//container.getChildAt(i + 1);
                if (!cell.text) {
                    //cell.x = cellX;
                    //cell.y = cellY;
                    //cell.width = width;
                    //cell.height = height;
                    //cell.graphicsData[0].fillColor = cellMeta.colour || tableStandardBGColour;
                    cell.clear();
                    drawRect(cell, { x: cellX, y: cellY }, width, heightToUse, false, cellMeta.colour || tableAltBGColour || tableStandardBGColour, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
                    if (cell.hitArea) {
                        cell.hitArea.x = cellX;
                        cell.hitArea.y = cellY;
                        cell.hitArea.width = width;
                        cell.hitArea.height = heightToUse;
                    }
                    cell.dirty++;
                    cell.clearDirty++;
                }
            } else if ((cellMeta.missingValMeta !== undefined && cellMeta.missingValMeta.showAsLink === true) || (cellMeta.interaction !== undefined && cellMeta.interaction.showAsLink === true)) {

                callbacks = getCallbacks(cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta : cellMeta.interaction, cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta.callbacks : cellMeta.interaction.callbacks);


                //makeRectInteractive
                cell = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: cellX, y: cellY }, width, heightToUse, false, cellMeta.colour || tableAltBGColour || tableStandardBGColour, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
                graphicsAdapter.makeRectInteractive(cell, cellX, cellY, callbacks);
                if (cellMeta.missingValMeta !== undefined) {
                    //if (showLabels && showLabels === 'right') {
                    //warningIcon = graphicsAdapter.drawInteractiveTextIcon(callbacks, height, /*'\uf057'*/'\uf071', { x: cellX + width - 5, y: cellY }, false, { x: 1, y: 0 }, undefined, '12px fontawesome', 0xEF8033);
                    warningIcon = graphicsAdapter.drawInteractiveTextIcon(callbacks, heightToUse, config.COMPLIANCE_STATUS_ICON.WARNING, { x: cellX + width - 5, y: cellY }, false, { x: 1, y: 0 }, undefined, '12px FontAwesome5ProTruckScience', 0xEF8033);
                    //} else {
                    //    warningIcon = graphicsAdapter.drawInteractiveTextIcon(callbacks, height, '\uf071', { x: cellX + width + 5, y: cellY }, false, undefined, undefined, '14px fontawesome', 0xEF8033);
                    //}
                }


            } else {

                cell = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: cellX, y: cellY }, width, heightToUse, false, cellMeta.colour || tableAltBGColour || tableStandardBGColour, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
            }

            //var customFontColour = undefined;
            ////if (cellMeta.colour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
            //if (cellMeta.colour !== undefined && cellMeta.colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE && cellMeta.interaction === undefined) {
            //    customFontColour = config.DRAWING_COLOURS.WHITE;    
            //} else if (cellMeta.interaction !== undefined ) {
            //    if (cellMeta.interaction.id === config.INTERACTION_ID.TABLE_CELL) {
            //        if (cellMeta.colour !== undefined && cellMeta.colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE) {
            //            customFontColour = getCellAltTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
            //        } else {
            //            customFontColour = getCellTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
            //        }
            //    } else if (cellMeta.interaction.id === config.INTERACTION_ID.XXED_OUT_VALUE) {
            //        if (cellMeta.colour !== undefined) {
            //            customFontColour = config.DRAWING_COLOURS.WHITE;
            //        } else {
            //            customFontColour = config.DRAWING_COLOURS.BLUE;
            //        }

            //    }

            //}
            if (!(cellMeta.id && cellMeta.id.indexOf && cellMeta.id.indexOf('spacer') === 1)) {


                var customFontColour = getTableFontColour(cellMeta);

                var contents;
                var customTableFont;
                if (cellMeta.fontStyle !== undefined) {
                    customTableFont = cellMeta.fontStyle + ' ' + tableFont;
                } else {
                    customTableFont = undefined;
                }
                //if (cellMeta.bold) {
                //    if (customTableFont) {
                //        customTableFont += 'bold ';
                //    } else {
                //        tableFont += 'bold';
                //    }

                //    customBoldColour = config.DRAWING_COLOURS.COLUMN_TITLE;
                //}

                var textStartX;
                if (colData.contentsAlign === 'left') {
                    textStartX = 0;
                } else if (colData.contentsAlign === 'right' || colData.contentsAlign === undefined) {
                    textStartX = 1;
                } else {
                    //centre
                    textStartX = 0.5;
                }

                if (allTableCells[cellMeta.id]) {
                    if (!cell.text/* && !cell.graphicsData*/) {
                        cell.removeChildren();
                        contents = allTableCellTextObjects[cellMeta.id];//cell.getChildAt(0);
                        if (cellMeta.missingValMeta !== undefined) {
                            //contents = drawText(cellMeta.missingValMeta.tableLinkChar, { x: ((cellX + width) / 2), y: cellY + (height / 2) }, false, { x: 0, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                            contents.text = cellMeta.missingValMeta.tableLinkChar;
                            contents.anchor.x = 0;
                            contents.x = ((cellX + width) / 2);
                            contents.y = cellY + (height / 2);
                        } else if (cellMeta.interaction !== undefined) {
                            //contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);
                            if (cellMeta.interaction.id === config.INTERACTION_ID.TABLE_CELL) {
                                //contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                                //tableCellTextObjectsForInteractionHighlights[cellMeta.interaction.elementId] = contents;
                                //tableCellsForInteractionHighlights[cellMeta.interaction.elementId] = cellMeta;
                                contents.text = cellMeta.value;
                                contents.anchor.x = 1;
                                contents.x = cellTextX;
                                contents.y = cellY + (height / 2);
                                contents.style.fill = '0x' + convertDecimalToHex(customFontColour);
                                //contents.destroy({ children: true });

                                //contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                                //if (tableCellTextObjectsForInteractionHighlights[cellMeta.interaction.elementId] === undefined) {
                                //    cell.mouseover = null;
                                //    cell.mouseout = null;
                                //    cell.click = null;
                                //    var callbacks = getCallbacks(cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta : cellMeta.interaction, cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta.callbacks : cellMeta.interaction.callbacks);
                                //    graphicsAdapter.makeRectInteractive(cell, cellX, cellY, callbacks);
                                //    tableCellTextObjectsForInteractionHighlights[cellMeta.interaction.elementId] = contents;
                                //    tableCellsForInteractionHighlights[cellMeta.interaction.elementId] = cellMeta;
                                //}
                                if (allTableCells[cellMeta.id].linkedHighlightElementId && tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId]) {
                                    tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                                    delete tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                                    tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                                    delete tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                                    allTableCells[cellMeta.id].linkedHighlightElementId = undefined;
                                    delete allTableCells[cellMeta.id].linkedHighlightElementId;
                                }
                                if (cellMeta.interaction.elementId !== allTableCells[cellMeta.id].linkedHighlightElementId) {
                                    cell.mouseover = null;
                                    cell.mouseout = null;
                                    cell.click = null;
                                    callbacks = getCallbacks(cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta : cellMeta.interaction, cellMeta.missingValMeta !== undefined ? cellMeta.missingValMeta.callbacks : cellMeta.interaction.callbacks);
                                    graphicsAdapter.makeRectInteractive(cell, cellX, cellY, callbacks);
                                    tableCellTextObjectsForInteractionHighlights[cellMeta.interaction.elementId] = contents;
                                    tableCellsForInteractionHighlights[cellMeta.interaction.elementId] = cellMeta;
                                    allTableCells[cellMeta.id].linkedHighlightElementId = cellMeta.interaction.elementId;
                                }

                                //if (renderer !== undefined && renderer !== null) {
                                //    renderer.render(drawingArea.drawingArea);
                                //}
                            } else if (cellMeta.interaction.id === config.INTERACTION_ID.XXED_OUT_VALUE) {
                                //contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                                contents.text = cellMeta.interaction.tableLinkChar;
                                contents.anchor.x = 1;
                                contents.x = cellTextX;
                                contents.y = cellY + (height / 2);
                            }

                        } else if (cellMeta.value === '-' && contents.text === '-') {
                            ////contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);
                            //contents = drawText(cellMeta.value, { x: ((cellX + width) / 2), y: cellY + (height / 2) }, false, { x: 0, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                            //contents.text = cellMeta.missingValMeta.tableLinkChar;
                            contents.anchor.x = 0;
                            contents.x = ((cellX + width) / 2);
                            contents.y = cellY + (height / 2);
                        } /*else if (cellMeta.interaction === undefined) {
                        if (allTableCells[cellMeta.id].linkedHighlightElementId && tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId]) {
                            tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                            delete tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                            tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                            delete tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                        }
                        contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                        allTableCellTextObjects[cellMeta.id] = contents;
                        allTableCells[cellMeta.id].linkedHighlightElementId = undefined;
                        delete allTableCells[cellMeta.id].linkedHighlightElementId;
                    }*/ else {
                            ////contents = drawText(cellMeta.value, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);
                            //contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                            contents.text = cellMeta.value;
                            if (cellMeta.value === '-') {
                                contents.anchor.x = 0;
                                contents.x = ((cellX + width) / 2);
                                contents.y = cellY + (height / 2);
                            } else {
                                contents.anchor.x = textStartX;
                                contents.x = cellTextX;
                                contents.y = cellY + (height / 2);
                            }


                            if (customFontColour !== undefined && customFontColour !== 0) {
                                contents.style.fill = '0x' + convertDecimalToHex(customFontColour);
                            } else if (colTextColour !== undefined) {
                                contents.style.fill = '0x' + convertDecimalToHex(colTextColour);
                            } else {
                                contents.style.fill = '0x000000';
                            }
                            if (allTableCells[cellMeta.id].linkedHighlightElementId && tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId]) {
                                tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                                delete tableCellTextObjectsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                                tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId] = undefined;
                                delete tableCellsForInteractionHighlights[allTableCells[cellMeta.id].linkedHighlightElementId];
                                allTableCells[cellMeta.id].linkedHighlightElementId = undefined;
                                delete allTableCells[cellMeta.id].linkedHighlightElementId;
                            }
                        }

                        //allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].text = tableData.cols[key].cellData[innerKey].value;

                        //if (tableData.cols[key].cellData[innerKey].colour !== undefined) {
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].graphicsData[0].fillColor = tableData.cols[key].cellData[innerKey].colour;
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].dirty = true;
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].clearDirty++;
                        //    allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].style.fill = getTableFontColour(tableData.cols[key].cellData[innerKey]);
                        //} else {
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].graphicsData[0].fillColor = config.DRAWING_COLOURS.TABLE_CELL;
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].dirty = true;
                        //    allTableCells[tableData.cols[key].cellData[innerKey].id].clearDirty++;
                        //    allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].style.fill = config.DRAWING_COLOURS.BLACK;
                        //    //allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].dirty = true;
                        //}
                        cell.addChild(contents);
                    }
                } else {
                    if (cellMeta.missingValMeta !== undefined) {
                        contents = drawText(cellMeta.missingValMeta.tableLinkChar, { x: ((cellX + width) / 2), y: cellY + (height / 2) }, false, { x: 0, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                    } else if (cellMeta.interaction !== undefined) {
                        //contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);
                        if (cellMeta.interaction.id === config.INTERACTION_ID.TABLE_CELL) {
                            contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                            tableCellTextObjectsForInteractionHighlights[cellMeta.interaction.elementId] = contents;
                            tableCellsForInteractionHighlights[cellMeta.interaction.elementId] = cellMeta;
                        } else if (cellMeta.interaction.id === config.INTERACTION_ID.XXED_OUT_VALUE) {
                            contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellTextX, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour);
                        }

                    } else if (cellMeta.value === '-') {
                        //contents = drawText(cellMeta.interaction.tableLinkChar, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);
                        contents = drawText(cellMeta.value, { x: ((cellX + width) / 2), y: cellY + (height / 2) }, false, { x: 0, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour || colTextColour);
                    } else {
                        //contents = drawText(cellMeta.value, { x: cellX + width - (config.generalDrawingMargin*2), y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, undefined, tableFont, customFontColour);

                        contents = drawText(cellMeta.value, { x: cellTextX, y: cellY + (height / 2) }, false, { x: textStartX, y: 0.5 }, undefined, customTableFont || tableFont, customFontColour || colTextColour);
                    }
                    allTableCellTextObjects[cellMeta.id] = contents;
                    allTableCells[cellMeta.id] = {};
                    allTableCells[cellMeta.id].cell = cell;
                    if (cellMeta.interaction && cellMeta.interaction.id === config.INTERACTION_ID.TABLE_CELL) {
                        allTableCells[cellMeta.id].linkedHighlightElementId = cellMeta.interaction.elementId;
                    }
                    cell.addChild(contents);
                    if (warningIcon !== undefined) {
                        cell.addChild(warningIcon);
                    }

                }
            }
            container.addChild(cell);

            var label;
            //if (showLabels && showLabels !== 'none') {
            //    var text = cellMeta.label;

            //    var labelFontToUse = '', customBoldColour;
            //    if (cellMeta.bold) {
            //        labelFontToUse += 'bold ';
            //        customBoldColour = config.DRAWING_COLOURS.COLUMN_TITLE;
            //    }
            //    labelFontToUse += labelFont;

            //    var textWidth = getWidthOfText(text, curFontName, fontSize);
            //    var prefixForPermissible;
            //    if (Object.keys(data)[i] === 'Permissible') {
            //        if (showLabels && showLabels === 'left') {
            //            if (allTableCellTextObjects[cellMeta.id + '.less']) {
            //                var prefixForPermissible = allTableCellTextObjects[cellMeta.id + '.less'];
            //                prefixForPermissible.x = cellX - labelBuffer - textWidth - 4;
            //                prefixForPermissible.y = cellY + (height / 2);
            //                prefixForPermissible.anchor.set(1, 0.5);
            //                prefixForPermissible.style.align = 'right';
            //            } else {
            //                prefixForPermissible = drawText('less', { x: cellX - labelBuffer - textWidth - 4, y: cellY + (height / 2) }, false, { x: 1, y: 0.5 }, 'right', 'oblique ' + labelFont, customBoldColour);
            //            }


            //        } else if (showLabels && showLabels === 'right') {
            //            if (allTableCellTextObjects[cellMeta.id + '.less']) {
            //                var prefixForPermissible = allTableCellTextObjects[cellMeta.id + '.less'];
            //                prefixForPermissible.x = cellX + width + labelBuffer + textWidth + 4;
            //                prefixForPermissible.y = cellY + (height / 2);
            //                prefixForPermissible.anchor.set(0, 0.5);
            //                prefixForPermissible.style.align = 'left';
            //            } else {
            //                prefixForPermissible = drawText('less', { x: cellX + width + labelBuffer + textWidth + 4, y: cellY + (height / 2) }, false, { x: 0, y: 0.5 }, 'left', 'oblique ' + labelFont, customBoldColour);
            //            }


            //        }
            //        allTableCellTextObjects[cellMeta.id + '.less'] = prefixForPermissible;
            //    }

            //    var labelCell;
            //    if (showLabels && showLabels === 'left') {
            //        if (allTableCellTextObjects[cellMeta.id + '.label']) {
            //            label = allTableCellTextObjects[cellMeta.id + '.label'];
            //            label.x = cellX - labelBuffer;
            //            label.y = cellY + (height / 2);
            //            label.anchor.set(1, 0.5);
            //            label.style.align = 'left';
            //        } else {
            //            var labelX = cellX - (labelBuffer*2 + largestLabelWidth), labelY = cellY;
            //            labelCell = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: labelX, y: labelY }, largestLabelWidth + labelBuffer * 2, height, false, optionalColour || config.DRAWING_COLOURS.WHITE, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
            //            label = drawText(text, { x: cellX - labelBuffer - largestLabelWidth, y: cellY + height / 2 }, false, { x: 0, y: 0.5 }, 'left', labelFontToUse, customBoldColour);
            //        }

            //        //container.addChild(label);
            //    } else if (showLabels && showLabels === 'right') {
            //        if (allTableCellTextObjects[cellMeta.id + '.label']) {
            //            label = allTableCellTextObjects[cellMeta.id + '.label'];
            //            label.x = cellX + width + labelBuffer;
            //            label.y = cellY + (height / 2);
            //            label.anchor.set(0, 0.5);
            //            label.style.align = 'left';
            //        } else {
            //            var labelX = cellX + width, labelY = cellY;
            //            labelCell = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: labelX, y: labelY }, largestLabelWidth + labelBuffer * 2, height, false, optionalColour || config.DRAWING_COLOURS.WHITE, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
            //            label = drawText(text, { x: labelX + labelBuffer, y: cellY + height / 2 }, false, { x: 0, y: 0.5 }, 'left', labelFontToUse, customBoldColour);
            //        }

            //    }

            //    labelCell.addChild(label)
            //    if (prefixForPermissible) {
            //        container.addChild(prefixForPermissible);
            //    }
            //    container.addChild(labelCell);
            //}
            //if (cellMeta.missingValMeta !== undefined && cellMeta.missingValMeta.showAsLink === true) {

            //}


            cellPositions["Cell_" + cellIdCounter++] = { id: id, x: cellX, y: cellY };
        } else {
            //draw line for cell
            //cell = drawRect(graphicsObjectToUse, { x: cellX, y: cellY }, width, underlineHeight, false, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
            //cell.addChild(graphicsAdapter.drawLine(graphicsObjectToUse, { x: cellX, y: cellY }, { x: cellX + width, y: cellY }, false, 1, callMeta.underlineColour));
            container.addChild(graphicsAdapter.drawLine(graphicsAdapter.getNewGraphicsObject(), { x: cellX /*- (labelBuffer * 2 + largestLabelWidth)*/, y: cellY }, { x: cellX + width, y: cellY }, false, 1, cellMeta.underlineColour));
            numUnderlineRows++;
        }





    }
    //var border;
    //if (allTableCellTextObjects[cellMeta.id + '.border']) {
    //    border = allTableCellTextObjects[cellMeta.id + '.border'];
    //    border.clear();
    //    drawRect(border, { x: 0, y: position.y }, width, height * (numRows + 1), false, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
    //    border.dirty++;
    //    border.clearDirty++;
    //} else {
    //    border = drawRect(graphicsAdapter.getNewGraphicsObject(), { x: 0, y: position.y }, width, height * (numRows + 1), false, cellMeta.colour || tableAltBGColour || tableStandardBGColour);
    //    allTableCellTextObjects[cellMeta.id + '.border'] = border;
    //}

    //container.addChild(border);

    return { table: container, cellPositions: cellPositions, cellWidth: width, cellHeight: height };
}

function getCellYOffset(yOffset, cellHeight, underlineHeight, rowNumber, numUnderlineRows, cumulativeSpacerHeight, numSpacersSoFar) {
    var standardRowCombinedHeight = (cellHeight * ((rowNumber - numSpacersSoFar - numUnderlineRows)));
    var underlineRowCombinedHeight = underlineHeight * numUnderlineRows;

    return yOffset + standardRowCombinedHeight + cumulativeSpacerHeight + underlineRowCombinedHeight + config.tableAndDashboardTitleHeight;
}

function getCellTextX(data, cellX, cellWidth, labelBuffer) {
    var widestCellValueText = 0;
    Object.keys(data.cellData).forEach(function (key) {
        widestCellValueText = Math.max(getWidthOfText(data.cellData[key].value, curFontName, tableFontSize), widestCellValueText);
    });
    widestCellValueText = Math.max(getWidthOfText(data.title, curFontName, data.titleFontSize, data.titleFontStyleAddition), widestCellValueText);
    if (data.contentsAlign === 'left') {
        //return cellX + (cellWidth - widestCellValueText) / 2;
        return cellX + labelBuffer;
    } else if (data.contentsAlign === 'right' || data.contentsAlign === undefined) {
        //return cellX + cellWidth - ((cellWidth - widestCellValueText) / 2);
        return cellX + cellWidth - labelBuffer;
    } else {
        //centre
        return cellX + cellWidth / 2 - widestCellValueText / 2;
    }

    //return cellX + cellWidth - ((cellWidth - widestCellValueText) / 2);

}

function getTableHeight(tableData) {

    var numUnderlineOrSpacerRows = 0;
    var numDataRows = 0;
    var numRows = 0;
    var cumulativeSpacerHeight = 0;

    var frontCol = tableData.cols['front'];
    if (frontCol === undefined) {
        frontCol = tableData.cols['Front'];
    }
    if (frontCol === undefined) {
        frontCol = tableData.cols['trailerFront'];
    }
    if (frontCol === undefined) {
        frontCol = tableData.cols['Labels'];
    }

    Object.keys(frontCol.cellData).forEach(function (key) {
        if (key.indexOf('spacer') === -1) {
            if (key.indexOf('underline') !== -1) {
                numUnderlineOrSpacerRows++;
            } else {
                numDataRows++;
            }
        } else {
            cumulativeSpacerHeight += frontCol.cellData[key].height;
        }
        numRows++;
    });

    return tableData.cellHeight * (numDataRows) + underlineHeight * numUnderlineOrSpacerRows + config.tableAndDashboardTitleHeight + cumulativeSpacerHeight;

}

function getTableFontColour(cellMeta) {
    var fontColour = config.DRAWING_COLOURS.BLACK;
    //if (cellMeta.colour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
    if (cellMeta.colour !== undefined && cellMeta.colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE && cellMeta.interaction === undefined) {
        fontColour = config.DRAWING_COLOURS.WHITE;
    } else if (cellMeta.interaction !== undefined) {
        if (cellMeta.interaction.id === config.INTERACTION_ID.TABLE_CELL) {
            if (cellMeta.colour !== undefined && cellMeta.colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE) {
                fontColour = getCellAltTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
            } else {
                fontColour = getCellTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
            }
        } else if (cellMeta.interaction.id === config.INTERACTION_ID.XXED_OUT_VALUE) {
            if (cellMeta.colour !== undefined) {
                fontColour = config.DRAWING_COLOURS.WHITE;
            } else {
                fontColour = config.DRAWING_COLOURS.BLUE;
            }

        }

    }
    return fontColour;
}

function getCellPosition(cellId, returnTableTopYPosition) {

    var cellPos = null;

    Object.keys(tableCols).forEach(function (key) {
        for (var i = 0; i < Object.keys(tableCols[key].cellPositions).length; i++) {
            if (tableCols[key].cellPositions['Cell_' + i].id === cellId) {
                cellPos = tableCols[key].cellPositions['Cell_' + i];
                cellPos.x = cellPos.x + tablesX + tableCols[key].table.position.x;//getTableCellXOffset('FRONT');
                if (returnTableTopYPosition === true) {
                    cellPos.y = tablesY + tableCols[key].table.position.y;//getTableCellYOffset();
                } else {
                    cellPos.y = cellPos.y + tablesY + tableCols[key].table.position.y;//getTableCellYOffset();
                }
                return cellPos;
            }
        }
        //Object.keys(tableCols[key].cellPositions).forEach(function (innerKey) {
        //    if (tableCols[key].cellPositions[innerKey].id === cellId) {
        //        cellPos = tableCols[key].cellPositions[innerKey];
        //        cellPos.x = cellPos.x + tablesX + tableCols[key].table.position.x;//getTableCellXOffset('FRONT');
        //        cellPos.y = cellPos.y + tablesY + tableCols[key].table.position.y;//getTableCellYOffset();
        //        return cellPos;
        //    }
        //});
    });
    return cellPos;
    //var frontCellPos = null;
    //for (var i = 0; i < Object.keys(frontTable.cellPositions).length; i++) {
    //    if (frontTable.cellPositions['Cell_' + i].id === cellId) {
    //        frontCellPos = frontTable.cellPositions['Cell_' + i];
    //        frontCellPos.x = frontCellPos.x + getTableCellXOffset('FRONT');
    //        frontCellPos.y = frontCellPos.y + getTableCellYOffset();
    //        return frontCellPos;
    //    }
    //}
    //var rearCellPos = null;
    //for (var i = 0; i < Object.keys(rearTable.cellPositions).length; i++) {
    //    if (rearTable.cellPositions['Cell_' + i].id === cellId) {
    //        rearCellPos = rearTable.cellPositions['Cell_' + i];
    //        rearCellPos.x = rearCellPos.x + getTableCellXOffset('REAR');
    //        rearCellPos.y = rearCellPos.y + getTableCellYOffset();
    //        return rearCellPos;
    //    }
    //}
    //var totalCellPos = null;
    //for (var i = 0; i < Object.keys(totalTable.cellPositions).length; i++) {
    //    if (totalTable.cellPositions['Cell_' + i].id === cellId) {
    //        totalCellPos = totalTable.cellPositions['Cell_' + i];
    //        totalCellPos.x = totalCellPos.x + getTableCellXOffset('TOTAL');
    //        totalCellPos.y = totalCellPos.y + getTableCellYOffset();
    //        return totalCellPos;
    //    }
    //}


}

function getTableCellXOffset(tableColumn) {
    if (tableColumn === 'FRONT') {
        return tablesX + frontTable.table.position.x;
    } else if (tableColumn === 'REAR') {
        return tablesX + rearTable.table.position.x;
    } else if (tableColumn === 'TOTAL') {
        return tablesX + totalTable.table.position.x;
    }
}


function getTableCellYOffset() {
    return tablesY + frontTable.table.position.y;
}

function getCallbacks(interaction, callbacks) {
    return {
        click: callbacks.click !== undefined ? function (data) {
            if (dragging === false) {
                //alert('click!');
                globals.eventFiredFromDrawingElement = true;

                //data.stopPropagation();
                //data.data.originalEvent.stopImmediatePropagation();

                if (interaction.hitMasks !== undefined && interaction.hitMasks.length > 0) {
                    var hitFound = false;
                    for (var i = 0; i < interaction.hitMasks.length; i++) {
                        var hitMask = interaction.hitMasks[i];
                        var hitRect = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                        //alert('hitMask.x ' + hitMask.x + ', hitMask.y ' + hitMask.y + ', hitMask.width ' + hitMask.width + ', hitMask.height ' + hitMask.height + ', mainX ' + mainX + ', mainY ' + mainY + ', mouse.global.x ' + renderer.plugins.interaction.mouse.global.x + ', mouse.global.y ' + renderer.plugins.interaction.mouse.global.y);
                        if (hitRect.contains(renderer.plugins.interaction.mouse.global.x - mainX, renderer.plugins.interaction.mouse.global.y - mainY)) {

                            hitFound = true;
                            //interaction.hitMaskId = hitMask.id;
                            interaction.hitMaskId = interaction.elementId;
                            //interaction.elementId = hitMask.id;
                            //alert('hitMask id: ' + hitMask.id);
                            interaction.interactionBehaviour = hitMask.interactionBehaviour
                            break;
                        }
                    }
                    //interaction.hitMasks.forEach(function (hitMask) {

                    //});
                    //if (!hitFound) {
                    //    doStandardClickHandling();
                    //}
                }
                //else {
                //    doStandardClickHandling();
                //}
                doStandardClickHandling(interaction, callbacks);

                

            }
            function doStandardClickHandling(interaction, callbacks) {
                if (interactionState[interaction.elementId + '_' + config.INTERACTION_EVENT.CLICK] === undefined || interaction.hitMaskId !== undefined) {
                    //data.stopPropagation();
                    //data.data.originalEvent.stopImmediatePropagation();
            
                    try {
                        callbacks.click(interaction, renderer.plugins.interaction.mouse.global);
                        handleInteractionEvent(interaction, config.INTERACTION_EVENT.CLICK);
            
                    } catch (eventWireEx) {
                        TScMessenger.writeDebugMessage('Error handling click event');
                    }
                }
            }
        } : undefined,
        mouseover: callbacks.mouseover !== undefined ? function (data) {
            //data.stopPropagation();
            if (dragging === false) {

                if (interaction.hitMasks !== undefined && interaction.hitMasks.length > 0) {
                    var hitFound = false;
                    for (var i = 0; i < interaction.hitMasks.length; i++) {
                        var hitMask = interaction.hitMasks[i];
                        var hitRect = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                        if (hitRect.contains(renderer.plugins.interaction.mouse.global.x - mainX, renderer.plugins.interaction.mouse.global.y - mainY)) {
                            hitFound = true;
                            //console.log('************* hit mask id: ' + hitMask.id);
                            //data.stopPropagation();
                            //data.data.originalEvent.stopImmediatePropagation();
                            break;
                        }
                    }
                    //interaction.hitMasks.forEach(function (hitMask) {

                    //});
                    if (hitFound) {
                        doStandardMouseOverHandling();
                    } else {
                        if (interactionState[interaction.elementId + '_' + 'PINNED'] !== undefined) {
                            doStandardMouseOverHandling();
                        }
                    }
                } else {
                    doStandardMouseOverHandling();
                }



            }
            function doStandardMouseOverHandling() {
                try {

                    callbacks.mouseover(interaction, renderer.plugins.interaction.mouse.global);
                    handleInteractionEvent(interaction, config.INTERACTION_EVENT.MOUSE_OVER);

                } catch (eventWireEx) {
                    TScMessenger.writeDebugMessage('Error handling mouseover event');
                }
            }
        } : undefined,
        mouseout: callbacks.mouseout !== undefined ? function (data) {
            //data.stopPropagation();
            if (dragging === false) {
                try {

                    callbacks.mouseout(interaction, renderer.plugins.interaction.mouse.global);
                    handleInteractionEvent(interaction, config.INTERACTION_EVENT.MOUSE_OUT);

                } catch (eventWireEx) {
                    TScMessenger.writeDebugMessage('Error handling mouseout event');
                }
            }
        } : undefined,
        mousemove: callbacks.mousemove !== undefined ? function (data) {
            //data.stopPropagation();
            if (dragging === false) {

                if (interaction.hitMasks !== undefined && interaction.hitMasks.length > 0) {
                    var hitFound = false;
                    for (var i = 0; i < interaction.hitMasks.length; i++) {
                        var hitMask = interaction.hitMasks[i];
                        var hitRect = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                        if (hitRect.contains(renderer.plugins.interaction.mouse.global.x - mainX, renderer.plugins.interaction.mouse.global.y - mainY)) {
                            hitFound = true;

                            break;
                        }
                    }
                    //interaction.hitMasks.forEach(function (hitMask) {

                    //});
                    if (hitFound) {
                        if (interactionState[interaction.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] === undefined) {
                            doStandardMouseMoveHandling();
                        }
                    } else {
                        if (interactionState[interaction.elementId + '_' + 'PINNED'] !== undefined && interactionState[interaction.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] === undefined) {
                            doStandardMouseMoveHandling();
                        }
                    }

                } else {
                    doStandardMouseMoveHandling();
                }

                try {


                    handleInteractionEvent(interaction, config.INTERACTION_EVENT.MOUSE_MOVE);

                } catch (eventWireEx) {
                    TScMessenger.writeDebugMessage('Error handling mousemove event');
                }

            }
            function doStandardMouseMoveHandling() {
                try {

                    callbacks.mousemove(interaction, renderer.plugins.interaction.mouse.global);
                    //handleInteractionEvent(interaction, config.INTERACTION_EVENT.MOUSE_MOVE);

                } catch (eventWireEx) {
                    TScMessenger.writeDebugMessage('Error handling mousemove event');
                }
            }
        } : undefined,
    }
}



function getDraggableCallbacks(interaction, container, callbacks) {
    return {
        dragStart: callbacks.dragStart !== undefined ? (function onDragStart(event) {
            // store a reference to the data
            // the reason for this is because of multitouch
            // we want to track the movement of this particular touch
            //this.data = event.data;
            //this.alpha = 0.5;
            //this.dragging = true;
            //dragging = true;
            //doRender = true;
            //oldPosition = null;
            callbacks.dragStart(interaction);
        }).bind(container) : undefined,

        dragMove: callbacks.dragMove !== undefined ? (function onDragMove() {
            //if (this.dragging) {
            //    var newPosition = this.data.getLocalPosition(this.parent);
            //    if (oldPosition !== null) {
            //        this.x += newPosition.x - oldPosition.x;
            //        this.y += newPosition.y - oldPosition.y;
            //    } else {
            //        oldPosition = new PIXI.Point(0, 0);
            //    }
            //    oldPosition.x = newPosition.x;
            //    oldPosition.y = newPosition.y;
            //    //callbacks.dragMove(interaction);
            //}
            if (lastPosition !== null) {

                var increment = interaction.dragInfoObjects[0].movementIncrement;
                var scaledIncrement = increment * scalingFactor;

                var doCallback = true;

                interaction.dragInfoObjects.forEach(function (dragObjInfo) {
                    if (dragObjInfo.directionOfMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL) {
                        if (dragObjInfo.lockPlane && dragObjInfo.switchThreshold !== undefined) {
                            if (!dragObjInfo.isLockedOut) {
                                if (removeSign(lastPosition.x - firstPosition.x) / scaledIncrement > dragObjInfo.switchThreshold && (dragObjInfo.switchOn === undefined || dragObjInfo.switchOn === false)) {
                                    dragObjInfo.switchOn = true;
                                } else if (lastPosition.x > firstPosition.x + scaledIncrement && dragObjInfo.switchOn) {
                                    dragObjInfo.switchOn = false;
                                } else {
                                    doCallback = false;
                                }
                            } /*else {
                                doCallback = false;
                            }*/
                        } else {
                            if (dragObjInfo.offsetAction === 'plus') {
                                dragObjInfo.dragValue = dragObjInfo.value + ((lastPosition.x - firstPosition.x) / scalingFactor);
                            } else {
                                dragObjInfo.dragValue = dragObjInfo.value - ((lastPosition.x - firstPosition.x) / scalingFactor);
                            }
                            dragObjInfo.dragHorizontalDelta = lastPosition.x - firstPosition.x;
                        }

                    } else {
                        if (dragObjInfo.lockPlane && dragObjInfo.switchThreshold !== undefined) {
                            if (!dragObjInfo.isLockedOut) {
                                if (removeSign(lastPosition.y - firstPosition.y) / scaledIncrement > dragObjInfo.switchThreshold && (dragObjInfo.switchOn === undefined || dragObjInfo.switchOn === false)) {
                                    dragObjInfo.switchOn = true;
                                    //} else if (removeSign(lastPosition.y - firstPosition.y) < scaledIncrement && dragObjInfo.switchOn && dragObjInfo.switchOn) {
                                } else if (lastPosition.y > firstPosition.y + scaledIncrement && dragObjInfo.switchOn) {
                                    dragObjInfo.switchOn = false;
                                } else {
                                    doCallback = false;
                                }
                            } /*else {
                                doCallback = false;
                            }*/
                        }/* else if (dragObjInfo.isLockedOut === true) {
                            doCallback = false;
                        }*/ else {
                            if (dragObjInfo.offsetAction === 'plus') {
                                dragObjInfo.dragValue = dragObjInfo.value + ((((mainHeight - lastPosition.y) - (mainHeight - firstPosition.y))) / scalingFactor);
                            } else {
                                dragObjInfo.dragValue = dragObjInfo.value - ((((mainHeight - lastPosition.y) - (mainHeight - firstPosition.y))) / scalingFactor);
                            }
                            dragObjInfo.dragVerticalDelta = lastPosition.y - firstPosition.y;
                        }


                    }
                });


                //callbacks.dragEnd(interaction);
            }
            //if (dragMoveCounter % 12 === 0) {
            //    callbacks.dragMove(interaction);
            //} else {
            //    if (dragMoveCounter > 2000000) {
            //        dragMoveCounter = 1;
            //    } else {
            //        dragMoveCounter++;
            //    }
            //}
            if (doCallback === true) {
                callbacks.dragMove(interaction);
            }

        }).bind(container) : undefined,
        dragEnd: callbacks.dragEnd !== undefined ? (function onDragEnd(dragAborted) {
            //this.alpha = 1;
            //this.dragging = false;
            //dragging = false;
            //// set the interaction data to null
            //this.data = null;
            //doRender = false;
            if (lastPosition !== null) {
                interaction.dragInfoObjects.forEach(function (dragObjInfo) {
                    if (dragObjInfo.directionOfMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL) {
                        if (dragObjInfo.offsetAction === 'plus') {
                            dragObjInfo.value = (dragObjInfo.value + ((lastPosition.x - firstPosition.x) / scalingFactor)) / dragObjInfo.unitConversion;
                        } else {
                            dragObjInfo.value = (dragObjInfo.value - ((lastPosition.x - firstPosition.x) / scalingFactor)) / dragObjInfo.unitConversion;
                        }
                    } else {
                        if (dragObjInfo.offsetAction === 'plus') {
                            dragObjInfo.value = (dragObjInfo.value + ((((mainHeight - lastPosition.y) - (mainHeight - firstPosition.y))) / scalingFactor)) / dragObjInfo.unitConversion;
                        } else {
                            dragObjInfo.value = (dragObjInfo.value - ((((mainHeight - lastPosition.y) - (mainHeight - firstPosition.y))) / scalingFactor)) / dragObjInfo.unitConversion;
                        }

                    }
                });

                //doRender = true;
                //renderer.render(drawingArea.drawingArea);
                //doRender = false;
                //if (dragAborted) {
                //    callbacks.dragEnd();
                //} else {
                callbacks.dragEnd(interaction);
                //}

            }

        }).bind(container) : undefined
    }
}

function handleInteractionEvent(interactionObj, eventType) {


    if (pulseController !== null && pulseController.drawingGroupName === interactionObj.elementId) {
        pulseController.destroy();
    }
    if (dimensionPulseControllers.length > 0) {
        dimensionPulseControllers.forEach(function (pulseController) {
            if (pulseController !== null && pulseController.drawingGroupName === interactionObj.elementId) {
                pulseController.destroy();
            }
        });
    }

    switch (interactionObj.id) {
        case config.INTERACTION_ID.DRAWING_GROUP:
        case config.INTERACTION_ID.COG_DIMENSION:
            return handleDrawingGroupInteraction(interactionObj, eventType);
    }

    return false;
}

function handleDrawingGroupInteraction(interactionObj, eventType) {
    switch (eventType) {
        case config.INTERACTION_EVENT.CLICK:
            //refreshDrawingGroup(interactionObj.elementId);
            renderer.render(drawingArea.drawingArea);
            return false;
        case config.INTERACTION_EVENT.MOUSE_OVER:
            //refreshDrawingGroup(interactionObj.elementId);
            renderer.render(drawingArea.drawingArea);
            //show hover colour
            return true;
        case config.INTERACTION_EVENT.MOUSE_OUT:
            //refreshDrawingGroup(interactionObj.elementId);
            renderer.render(drawingArea.drawingArea);
            // reset to default
            return true;
        case config.INTERACTION_EVENT.MOUSE_MOVE:
            //refreshDrawingGroup(interactionObj.elementId);
            renderer.render(drawingArea.drawingArea);
            // reset to default
            return true;
    }
}

function processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement) {

    var linewidth = 1;
    var fontSize = dimensionFontSize;
    var fontName = curFontName;


    var textHeight;



    if (dimension.type.indexOf('cog') !== -1) {
        textHeight = cogDimensionFontTextHeight;
        processCOGViewDimension();
    } else {
        textHeight = 10;
        processOverviewViewDimension();
    }

    if (dimension.interaction !== undefined) {
        dimension.interaction.callbacks = getCallbacks(dimension.interaction, dimension.interaction.callbacks);
    }

    dimension.font = fontSize + 'px ' + fontName;

    if (dimension.style !== config.DIMENSION_STYLE.COG && dimension.interaction === undefined) {
        dimension.fontColour = config.DRAWING_COLOURS.BLACK;
    } else {
        dimension.fontColour = config.DRAWING_COLOURS.BLUE;
    }
    return dimension;


    function processOverviewViewDimension() {

        var plane, fixedLength;

        var textWidth = getWidthOfText("" + dimension.displayLength, fontName, fontSize);

        if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT || dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
            plane = 'vertical';
        } else if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
            plane = 'both';
        } else {
            plane = 'horizontal';
        }
        var arrowStyle;
        if (plane === 'horizontal') {

            dimension.startPoint.x = dimension.startPoint.x + inDrawingDimensionLayerOffsetX;

            if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
                dimension.startPoint.y = topHeight - (layerIncrement * dimension.layerNumber);
            } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
                if (dimension.layerNumber === 1) {
                    dimension.startPoint.y = layerIncrement;
                    
                } else {
                    //if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
                    //    dimension.startPoint.y = (layerIncrement * (dimension.layerNumber)) + layerIncrement;
                    //} else {
                        dimension.startPoint.y = (layerIncrement * (dimension.layerNumber - 1)) + layerIncrement;
                    //}
                    
                }

            } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER) {
                //dimension.startPoint.y = mainHeight - dimension.startPoint.y
                //var breakHere = 0;
            } else {
                dimension.startPoint.y = layerIncrement * dimension.layerNumber;
            }
            
            if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT && textWidth > dimension.length - 40) {
                dimension.jumpingRight = true;
            } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT && textWidth > dimension.length - 40) {
                dimension.jumpingLeft = true;
            } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER && textWidth > dimension.length - 40) {
                dimension.poppingLayer = true;
            }

            arrowStyle = config.ARROW_STYLE.LEFT;
            if (dimension.jumpingRight !== undefined || dimension.jumpingLeft !== undefined || dimension.poppingLayer !== undefined) {
                arrowStyle = config.ARROW_STYLE.RIGHT;
            }

            addArrow(dimension.startPoint, arrowStyle, dimension.ah1);
            dimension.lineStop1Start = { x: dimension.ah1.tip.x, y: dimension.ah1.tip.y - stopLength };
            dimension.lineStop1End = { x: dimension.ah1.tip.x, y: dimension.ah1.tip.y + stopLength };

            if (dimension.style !== config.DIMENSION_STYLE.JUMP_LEFT && dimension.style !== config.DIMENSION_STYLE.JUMP_RIGHT && dimension.style !== config.DIMENSION_STYLE.POP_LAYER) {
                doStandardLineSetup();
            } else {
                fixedLength = 20;
                if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
                    if (dimension.jumpingLeft !== undefined && dimension.jumpingLeft === true) {
                        doJumpLeftOrRightLineSetup();
                    } else {
                        doStandardLineSetup();
                    }
                } else if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {//JUMP_RIGHT
                    if (dimension.jumpingRight !== undefined && dimension.jumpingRight === true) {
                        doJumpLeftOrRightLineSetup();
                    } else {
                        doStandardLineSetup();
                    }
                } else {
                    if (dimension.poppingLayer !== undefined && dimension.poppingLayer === true) {
                        doPoppingLayerLineSetup();
                        //doStandardLineSetup();
                    } else {
                        doStandardLineSetup();
                    }
                }
            }
            

            arrowStyle = config.ARROW_STYLE.RIGHT;
            if (dimension.jumpingRight !== undefined || dimension.jumpingLeft !== undefined || dimension.poppingLayer !== undefined) {
                arrowStyle = config.ARROW_STYLE.LEFT;
            }
            addArrow({ x: dimension.lineStop1Start.x + dimension.length, y: dimension.ah1.tip.y }, arrowStyle, dimension.ah2);
            dimension.lineStop2Start = { x: dimension.ah2.tip.x, y: dimension.ah2.tip.y - stopLength  };
            dimension.lineStop2End = { x: dimension.ah2.tip.x, y: dimension.ah2.tip.y + stopLength };

            if (dimension.jumpingRight === true) {
                dimension.textCentre = { x: dimension.startPoint.x + dimension.length + fixedLength + 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
            } else if (dimension.jumpingLeft === true) {
                dimension.textCentre = { x: dimension.lineStart.x - (textWidth + 4), y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
            } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
                //if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
                    dimension.textCentre = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) - layerIncrement };
                //} else {
                //    dimension.textCentre = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) + layerIncrement };
                //}
            } else {
                dimension.textCentre = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
            }


            dimension.textBackgroundWidth = textWidth + 8;
            dimension.textBackgroundHeight = textHeight + 2;


        } else if (plane === 'vertical') {


            if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                dimension.startPoint.x = sideWidth - (layerIncrement * dimension.layerNumber);
            } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
                //dimension.startPoint.x = (layerIncrement * dimension.layerNumber);
            } else {
                dimension.startPoint.x = (layerIncrement * dimension.layerNumber);
            }

            dimension.length = -dimension.length;

            if (dimension.type !== config.DIMENSION_TYPE.VERTICAL_INNER) {
                if (dimension.style === config.DIMENSION_STYLE.JUMP_UP && textWidth > Math.abs(dimension.length) - 40) {
                    dimension.jumpingUp = true;
                } else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN && textWidth > Math.abs(dimension.length) - 30) {
                    dimension.jumpingDown = true;
                } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER ) {
                    dimension.poppingLayer = true;
                }
                dimension.startPoint.y = ((mainHeight + topHeight + emptyVerticalSpace / 2 + topBufferHeight) - (dimension.startPoint.y + dimension.yDimensionOffset));
            } else {
                dimension.startPoint.y = mainHeight - (dimension.startPoint.y + dimension.yDimensionOffset);
            }

            ahLength = -ahLength;
            arrowStyle = config.ARROW_STYLE.UP;
            if (dimension.jumpingUp !== undefined || dimension.jumpingDown !== undefined || dimension.poppingLayer !== undefined) {
                arrowStyle = config.ARROW_STYLE.DOWN;
                if (dimension.jumpingUp !== undefined) {
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 }, arrowStyle, dimension.ah1);
                    dimension.lineStop1Start = { x: dimension.ah1.tip.x - stopLength, y: dimension.ah1.tip.y };
                    dimension.lineStop1End = { x: dimension.ah1.tip.x + stopLength, y: dimension.ah1.tip.y };
                } else if (dimension.jumpingDown !== undefined) {
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 }, arrowStyle, dimension.ah1);
                    dimension.lineStop1Start = { x: dimension.ah1.tip.x - stopLength, y: dimension.ah1.tip.y };
                    dimension.lineStop1End = { x: dimension.ah1.tip.x + stopLength, y: dimension.ah1.tip.y };
                } else if (dimension.poppingLayer !== undefined) {
                    //arrowStyle = config.ARROW_STYLE.UP;
                    //addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 }, arrowStyle, dimension.ah1);
                    //dimension.lineStop1Start = { x: dimension.ah1.tip.x - stopLength, y: dimension.ah1.tip.y };
                    //dimension.lineStop1End = { x: dimension.ah1.tip.x + stopLength, y: dimension.ah1.tip.y };
                    //arrowStyle = config.ARROW_STYLE.UP;
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 }, arrowStyle, dimension.ah1);
                    dimension.lineStop1Start = { x: dimension.ah1.tip.x - stopLength, y: dimension.ah1.tip.y };
                    dimension.lineStop1End = { x: dimension.ah1.tip.x + stopLength, y: dimension.ah1.tip.y };
                }
            } else {
                addArrow(dimension.startPoint, arrowStyle, dimension.ah1);
                dimension.lineStop1Start = { x: dimension.ah1.tip.x - stopLength, y: dimension.ah1.tip.y + 1 };
                dimension.lineStop1End = { x: dimension.ah1.tip.x + stopLength, y: dimension.ah1.tip.y + 1 };
            }





            if (dimension.style !== config.DIMENSION_STYLE.JUMP_UP && dimension.style !== config.DIMENSION_STYLE.JUMP_DOWN && dimension.style !== config.DIMENSION_STYLE.POP_LAYER) {
                doStandardVerticalLineSetup();
            } else {
                fixedLength = 20;
                if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
                    if (dimension.jumpingUp !== undefined && dimension.jumpingUp === true) {
                        doJumpUpOrDownLineSetup();
                    } else {
                        doStandardVerticalLineSetup();
                    }
                } else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {//JUMP_DOWN
                    if (dimension.jumpingDown !== undefined && dimension.jumpingDown === true) {
                        doJumpUpOrDownLineSetup();
                    } else {
                        doStandardVerticalLineSetup();
                    }

                } else if(dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
                    if (dimension.poppingLayer !== undefined && dimension.poppingLayer === true) {
                        doVerticalPoppingLineSetup();
                    } else {
                        doStandardVerticalLineSetup();
                    }
                }
            }

            

            arrowStyle = config.ARROW_STYLE.DOWN;
            if (dimension.jumpingUp !== undefined || dimension.jumpingDown !== undefined || dimension.poppingLayer !== undefined) {
                arrowStyle = config.ARROW_STYLE.UP;
                if (dimension.jumpingDown !== undefined) {
                    //{ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 }
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 + dimension.length }, arrowStyle, dimension.ah2);
                    dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
                    dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };
                } else if (dimension.jumpingUp !== undefined) {
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 + dimension.length }, arrowStyle, dimension.ah2);
                    dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
                    dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };
                } else if (dimension.poppingLayer !== undefined) {
                    addArrow({ x: dimension.startPoint.x, y: dimension.startPoint.y + 1 + dimension.length }, arrowStyle, dimension.ah2);
                    dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
                    dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };
                }
            } else {
                addArrow({ x: dimension.ah1.tip.x, y: dimension.ah1.tip.y + dimension.length }, arrowStyle, dimension.ah2);
                dimension.ah2.tip.y += 1;
                dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
                dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };
            }

            //dimension.ah2.tip.y += 2;
            //dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
            //dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };

            if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
                dimension.textCentre = getStandardTextCentre();
            } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
                if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
                    dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
                } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
                    dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
                }
            } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
                if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
                    dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + layerIncrement, y: ((dimension.lineStart.y - fixedLength + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
                } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                    dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - layerIncrement, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
                }
            } else {
                if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
                    if (dimension.jumpingDown && dimension.jumpingDown === true) {
                        dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y + 20 + Math.abs(dimension.length)) - (textWidth / 2)) - 4) };
                    } else {
                        dimension.textCentre = getStandardTextCentre();
                    }
                } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP && dimension.jumpingUp === true) {
                    dimension.textCentre = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y - 30 - (Math.abs(dimension.length))) - (textWidth / 2)) - 4) };
                } else {
                    dimension.textCentre = getStandardTextCentre();
                }
            }

            

            dimension.textBackgroundWidth = textHeight + 4;
            dimension.textBackgroundHeight = textWidth + 8;
            if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                dimension.rotateText = 4.71239;
            } else {
                dimension.rotateText = 1.5708;
            }




        } else if (plane === 'both') {
            //if (dimension.endPoint.x > dimension.startPoint.x) {
            //    var tempStartPoint = dimension.startPoint; 
            //    dimension.startPoint = dimension.endPoint;
            //    dimension.endPoint = tempStartPoint;
            //} 

            dimension.startPoint.x = dimension.startPoint.x + inDrawingDimensionLayerOffsetX;
            dimension.endPoint.x = dimension.endPoint.x + inDrawingDimensionLayerOffsetX;

            //dimension.startPoint.y = ((mainHeight + topHeight + emptyVerticalSpace / 2 + topBufferHeight) - dimension.startPoint.y);
            //dimension.endPoint.y = ((mainHeight + topHeight + emptyVerticalSpace / 2 + topBufferHeight) - dimension.endPoint.y);

            dimension.startPoint.y = ((mainHeight) - dimension.startPoint.y);
            dimension.endPoint.y = ((mainHeight) - dimension.endPoint.y);

            var lengthOfDimension = Math.sqrt(Math.pow(Math.abs(dimension.startPoint.x - dimension.endPoint.x), 2) + Math.pow(Math.abs(dimension.startPoint.y - dimension.endPoint.y), 2));
            //lengthOfDimensionSection = (lengthOfDimension - (dimensionTextSize.Width + 2 * _DimensionTextSpacer)) / 2

            dimension.textBackgroundWidth = textWidth + 8;
            dimension.textBackgroundHeight = textHeight + 2;

            dimension.bgTopLeft = { x: 0, y: 0 };
            dimension.textCentre = { x: 0, y: 0 };
            var jumpingTailToTextGap = 35;
            var angleOfLine = calculateAngleOfLine(dimension);
            if (dimension.layerNumber) {//if it's an equipment pinned dimensions as opposed to TC dimension
                if (dimension.endPoint.y === dimension.startPoint.y) {
                    if (dimension.style === config.DIMENSION_STYLE.STANDARD || (dimension.style !== config.DIMENSION_STYLE.STANDARD && (dimension.endPoint.x - dimension.startPoint.x) > pinnedInternalDimensionsJumpThreshold || (dimension.startPoint.x - dimension.endPoint.x) > pinnedInternalDimensionsJumpThreshold)) {
                        if (dimension.endPoint.x - dimension.startPoint.x < 0) {
                            dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                            dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                            dimension.bgTopLeft.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 2;
                            dimension.bgTopLeft.y = dimension.endPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                        } else {
                            dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                            dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                            dimension.bgTopLeft.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 2;
                            dimension.bgTopLeft.y = dimension.startPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                        }

                        dimension.bgWidth = lengthOfDimension + 4;
                        dimension.bgHeight = 16;
                    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
                        if (dimension.startPoint.x > dimension.endPoint.x) {
                            dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * jumpingTailToTextGap;
                            dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * (jumpingTailToTextGap + textWidth);
                            dimension.bgTopLeft.y = dimension.endPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = jumpingTailToTextGap + textWidth;
                            dimension.bgHeight = 16;
                        } else {
                            dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * jumpingTailToTextGap;
                            dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * (jumpingTailToTextGap + textWidth);
                            dimension.bgTopLeft.y = dimension.startPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = jumpingTailToTextGap + textWidth;
                            dimension.bgHeight = 16;
                        }

                    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
                        if (dimension.startPoint.x < dimension.endPoint.x) {
                            dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * (-jumpingTailToTextGap);
                            dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.y = dimension.endPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = jumpingTailToTextGap + textWidth;
                            dimension.bgHeight = 16;
                        } else {
                            dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * (-jumpingTailToTextGap);
                            dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.y = dimension.startPoint.y - 8 + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = jumpingTailToTextGap + textWidth;
                            dimension.bgHeight = 16;
                        }

                    }

                } else {
                    if (dimension.style === config.DIMENSION_STYLE.STANDARD || (dimension.style !== config.DIMENSION_STYLE.STANDARD && (dimension.startPoint.y - dimension.endPoint.y) > pinnedInternalDimensionsJumpThreshold || (dimension.endPoint.y - dimension.startPoint.y) > pinnedInternalDimensionsJumpThreshold)) {
                        dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                        dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                        dimension.bgTopLeft.x = dimension.endPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                        dimension.bgTopLeft.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 2;
                        dimension.bgWidth = 16;
                        dimension.bgHeight = lengthOfDimension + 4;
                    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
                        dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                        dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * jumpingTailToTextGap;
                        dimension.bgTopLeft.x = dimension.endPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                        dimension.bgTopLeft.y = dimension.endPoint.y - (jumpingTailToTextGap + textWidth) + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                        dimension.bgWidth = 16;
                        dimension.bgHeight = jumpingTailToTextGap + textWidth;
                    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
                        if (dimension.startPoint.y > dimension.endPoint.y) {
                            dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * (-jumpingTailToTextGap);
                            dimension.bgTopLeft.x = dimension.startPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = 16;
                            dimension.bgHeight = jumpingTailToTextGap + textWidth;
                        } else {
                            dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * jumpingTailToTextGap;
                            //dimension.bgTopLeft.x = dimension.endPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            //dimension.bgTopLeft.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 50;
                            //dimension.bgWidth = 16;
                            //dimension.bgHeight = 50 + 4;
                            dimension.bgTopLeft.x = dimension.endPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgTopLeft.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 1;
                            dimension.bgWidth = 16;
                            dimension.bgHeight = jumpingTailToTextGap + textWidth;
                        }


                    }
                }
            } else {
                if (dimension.endPoint.x > dimension.startPoint.x) {
                    dimension.textCentre.x = dimension.endPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                    dimension.textCentre.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                } else {
                    dimension.textCentre.x = dimension.startPoint.x + Math.cos((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                    dimension.textCentre.y = dimension.startPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * lengthOfDimension / 2;
                }
                dimension.textBackgroundWidth = textWidth + 8;
                dimension.textBackgroundHeight = textHeight + 2;
            }


            if (angleOfLine < 270) {
                dimension.rotateText = (180 + angleOfLine) * Math.PI / 180;
            } else {
                dimension.rotateText = (angleOfLine) * Math.PI / 180;
            }



            dimension.processed = true;
        }

        function doStandardLineSetup() {
            dimension.lineStart = { x: dimension.lineStop1Start.x, y: dimension.startPoint.y };
            dimension.lineEnd = { x: dimension.startPoint.x + (dimension.length / 2) - linewidth, y: dimension.startPoint.y };
            dimension.lineStart2 = { x: dimension.lineEnd.x, y: dimension.lineEnd.y };
            dimension.lineEnd2 = { x: dimension.lineStart2.x + (dimension.length / 2), y: dimension.lineStart2.y };
        }
        function doJumpLeftOrRightLineSetup() {
            dimension.lineStart = { x: (dimension.startPoint.x) - fixedLength, y: dimension.startPoint.y };
            dimension.lineEnd = { x: dimension.lineStart.x + fixedLength, y: dimension.startPoint.y };
            dimension.lineStart2 = { x: dimension.startPoint.x + dimension.length, y: dimension.lineEnd.y };
            dimension.lineEnd2 = { x: dimension.lineStart2.x + fixedLength, y: dimension.lineStart2.y };
        }
        function doPoppingLayerLineSetup() {
            dimension.lineStart = { x: (dimension.startPoint.x) - fixedLength, y: dimension.startPoint.y };
            dimension.lineEnd = { x: dimension.lineStart.x + fixedLength, y: dimension.startPoint.y };
            dimension.lineStart2 = { x: dimension.startPoint.x + dimension.length, y: dimension.lineEnd.y };
            dimension.lineEnd2 = { x: dimension.lineStart2.x + fixedLength, y: dimension.lineStart2.y };
        }

        function doStandardVerticalLineSetup() {
            dimension.lineStart = dimension.startPoint;
            dimension.lineEnd = { x: dimension.startPoint.x, y: dimension.startPoint.y + (dimension.length / 2) };

            dimension.lineStart2 = { x: dimension.lineEnd.x, y: dimension.lineEnd.y + 2 };
            dimension.lineEnd2 = { x: dimension.lineStart2.x, y: dimension.lineStart2.y + (dimension.length / 2) + 2 };
        }

        function doJumpUpOrDownLineSetup() {
            dimension.lineStart = { x: (dimension.startPoint.x), y: dimension.startPoint.y + fixedLength };
            dimension.lineEnd = { x: dimension.lineStart.x, y: dimension.lineStart.y - fixedLength };
            dimension.lineStart2 = { x: dimension.startPoint.x, y: dimension.lineEnd.y - Math.abs(dimension.length) };
            dimension.lineEnd2 = { x: dimension.lineStart2.x, y: dimension.lineStart2.y - fixedLength };
        }

        function doVerticalPoppingLineSetup() {
            dimension.lineStart = { x: (dimension.startPoint.x), y: dimension.startPoint.y + fixedLength };
            dimension.lineEnd = { x: dimension.lineStart.x, y: dimension.lineStart.y - fixedLength };
            dimension.lineStart2 = { x: dimension.startPoint.x, y: dimension.lineEnd.y - Math.abs(dimension.length) };
            dimension.lineEnd2 = { x: dimension.lineStart2.x, y: dimension.lineStart2.y - fixedLength };
        }

        function getStandardTextCentre() {
            return { x: (dimension.lineStart.x - (textHeight / 2)) - 2, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
        }
    }

    function processCOGViewDimension() {

        var verticalTextWidth = getWidthOfText("" + dimension.displayLengthVertical, fontName, fontSize);
        var horizontalTextWidth = getWidthOfText("" + dimension.displayLengthHorizontal, fontName, fontSize);

        dimension.setTotalFrontLayerThickness(inDrawingDimensionLayerOffsetX);
        dimension.setTotalBottomLayerThickness(inDrawingDimensionLayerOffsetY);
        dimension.setTotalTopLayerThickness(topInDrawingDimensionLayerThickness);
        dimension.setMainHeight(mainHeight);
        dimension.setupPoints();
        setupTextMeta();
        addArrowsAndLineStops();

        //drawCOGMarker(dimension.cogPoint, 10, false);

        function setupTextMeta() {

            dimension.verticalTextCentre = {
                x: (dimension.verticalStartPoint.x - (textHeight / 2)) - 2,
                y: getVerticalTextYVal()
            };
            dimension.horizontalTextCentre = {
                x: getHorizontalTextXVal(),
                y: (dimension.horizontalStartPoint.y + (textHeight / 2)) + 2
            };

            dimension.verticalTextBackgroundWidth = textHeight + 4;
            dimension.verticalTextBackgroundHeight = verticalTextWidth + 8;

            dimension.horizontalTextBackgroundWidth = horizontalTextWidth + 8;
            dimension.horizontalTextBackgroundHeight = textHeight + 4;

            if (dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimension.type === config.DIMENSION_TYPE.COG_TOP_LEFT) {
                dimension.rotateText = 4.71239;
            } else {
                dimension.rotateText = 1.5708;
            }

            function getVerticalTextYVal() {
                if (verticalTextWidth + 20 > Math.abs(dimension.lengthVertical)) {
                    if (dimension.lengthVertical < 0) {
                        return inDrawingDimensionLayerOffsetY + dimension.verticalAxisOrigin + 12 + verticalTextWidth;
                    } else {
                        return inDrawingDimensionLayerOffsetY + dimension.verticalAxisOrigin - (6);
                    }
                } else {
                    return ((dimension.verticalStartPoint.y + ((dimension.lengthVertical - 2) / 2)) + (verticalTextWidth / 2)) + 4;
                }

            }

            function getHorizontalTextXVal() {

                if (horizontalTextWidth + 20 > dimension.lengthHorizontal) {
                    if (dimension.horizontalStartPoint.x < inDrawingDimensionLayerOffsetX + dimension.horizontalReferenceX) {
                        return dimension.horizontalStartPoint.x + dimension.lengthHorizontal + 10;
                    } else {
                        return dimension.horizontalStartPoint.x - (10 + horizontalTextWidth);
                    }

                } else {
                    return (dimension.horizontalStartPoint.x + ((dimension.lengthHorizontal - 2) / 2) - (horizontalTextWidth / 2)) - 4;
                }


            }
        }

        function addArrowsAndLineStops() {
            if (dimension.horizontalStartPoint.x < inDrawingDimensionLayerOffsetX + dimension.horizontalReferenceX) {
                addArrow(dimension.horizontalEndPoint, config.ARROW_STYLE.RIGHT, dimension.ah1);
            } else {
                if (dimension.horizontalStartPoint.x === inDrawingDimensionLayerOffsetX + dimension.horizontalReferenceX && dimension.lengthHorizontal === 0) {
                    addArrow(dimension.horizontalStartPoint, config.ARROW_STYLE.LEFT, dimension.ah1, true);
                } else {
                    addArrow(dimension.horizontalStartPoint, config.ARROW_STYLE.LEFT, dimension.ah1);
                }

            }
            dimension.lineStop1Start = { x: dimension.ah1.tip.x, y: dimension.ah1.tip.y - stopLength };
            dimension.lineStop1End = { x: dimension.ah1.tip.x, y: dimension.ah1.tip.y + stopLength };
            if (dimension.verticalStartPoint.y < inDrawingDimensionLayerOffsetY + dimension.verticalAxisOrigin) {
                addArrow(dimension.verticalStartPoint, config.ARROW_STYLE.DOWN, dimension.ah2);
            } else {
                if (dimension.verticalStartPoint.y === inDrawingDimensionLayerOffsetY + dimension.verticalAxisOrigin && dimension.lengthVertical === 0) {
                    addArrow(dimension.verticalStartPoint, config.ARROW_STYLE.UP, dimension.ah2, true);
                } else if (dimension.lengthVertical < 0) {
                    addArrow(dimension.verticalStartPoint, config.ARROW_STYLE.DOWN, dimension.ah2);
                } else {
                    addArrow(dimension.verticalStartPoint, config.ARROW_STYLE.UP, dimension.ah2);
                }
            }

            dimension.lineStop2Start = { x: dimension.ah2.tip.x - stopLength, y: dimension.ah2.tip.y };
            dimension.lineStop2End = { x: dimension.ah2.tip.x + stopLength, y: dimension.ah2.tip.y };
        }

    }





    function addArrow(position, direction, arrowHead, addTail) {

        arrowHead.tip = JSON.parse(JSON.stringify(position));

        switch (direction) {
            case config.ARROW_STYLE.LEFT:
                arrowHead.sideAEnd = { x: arrowHead.tip.x + ahLength, y: arrowHead.tip.y - (ahWidth + linewidth) };
                arrowHead.sideBEnd = { x: arrowHead.tip.x + ahLength, y: arrowHead.tip.y + ahWidth + linewidth };
                if (addTail === true) {
                    arrowHead.tailPoint = { x: arrowHead.tip.x + (ahLength * 2), y: arrowHead.tip.y };
                }
                break;
            case config.ARROW_STYLE.RIGHT:
                arrowHead.sideAEnd = { x: arrowHead.tip.x - ahLength, y: arrowHead.tip.y - (ahWidth + linewidth) };
                arrowHead.sideBEnd = { x: arrowHead.tip.x - ahLength, y: arrowHead.tip.y + ahWidth + linewidth };
                if (addTail === true) {
                    arrowHead.tailPoint = { x: arrowHead.tip.x - (ahLength * 2), y: arrowHead.tip.y };
                }
                break;
            case config.ARROW_STYLE.UP:
                arrowHead.sideAEnd = { x: arrowHead.tip.x - (ahWidth + 1), y: arrowHead.tip.y + ahLength };
                arrowHead.sideBEnd = { x: arrowHead.tip.x + ahWidth + 1, y: arrowHead.tip.y + ahLength };
                if (addTail === true) {
                    arrowHead.tailPoint = { x: arrowHead.tip.x, y: arrowHead.tip.y + (ahLength * 2) };
                }
                break;
            case config.ARROW_STYLE.DOWN:
                arrowHead.sideAEnd = { x: arrowHead.tip.x - (ahWidth + 1), y: arrowHead.tip.y - ahLength };
                arrowHead.sideBEnd = { x: arrowHead.tip.x + ahWidth + 1, y: arrowHead.tip.y - ahLength };
                if (addTail === true) {
                    arrowHead.tailPoint = { x: arrowHead.tip.x, y: arrowHead.tip.y - (ahLength * 2) };
                }
                break;
        }
    }
}
var counter = 0;
function drawDimension(dimension, graphicsObj, addToGraphicsObject) {

    //var graphicsObj;

    //if (addToGraphicsObject !== undefined) {
    //    graphicsObj = graphicsAdapter.getGraphicsObject();
    //} else {
    //    graphicsObj = getDimensionLayersGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'DIMENSION');
    //    graphicsAdapter.setAlternateGraphicsObject(graphicsObj);

    //}
    graphicsAdapter.setAlternateGraphicsObject(graphicsObj);

    var colourToUse = getColourToUse(dimension);
    //var colourToUse;
    var lineWidth;
    if (dimensionPulseControllers[dimension.drawingGroupName]) {
        //colourToUse = getPulseColourToUse(dimension);
        lineWidth = getPulseLineWidth(dimension.drawingGroupName);
    } else {
        //colourToUse = getColourToUse(dimension);
    }
    if (dimension.type.indexOf('cog') !== -1) {

        var flipYAxisVal = mainHeight;// + dimension.totalTopLayerThickness;

        //var colourToUse = getColourToUse(dimension);

        var drawCompleteCOGDimension = false;
        if ((dimension.drawingGroupName !== 'NOT_SET' && (interactionState[dimension.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined || interactionState[dimension.drawingGroupName + '_' + 'PINNED'] !== undefined)) || dimension.drawingGroupName === 'NOT_SET') {
            drawCompleteCOGDimension = true;
        }

        if (drawCompleteCOGDimension === true) {
            drawArrowHead(dimension.ah1, true, lineWidth, colourToUse);
            drawArrowHead(dimension.ah2, true, lineWidth, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, flipMainDrawingAreaAxisPoint(dimension.lineStop1Start), flipMainDrawingAreaAxisPoint(dimension.lineStop1End), true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, flipMainDrawingAreaAxisPoint(dimension.lineStop2Start), flipMainDrawingAreaAxisPoint(dimension.lineStop2End), true, undefined, colourToUse);
        }

        drawNewCOGElement(getMainDrawingAreaGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'COG_DIMENSION'), dimension, dimension.markerRadius, dimension.labelText, dimension.labelStyle, false);

        var horizontalStartPoint = flipMainDrawingAreaAxisPoint(dimension.horizontalStartPoint);
        var horizontalEndPoint = flipMainDrawingAreaAxisPoint(dimension.horizontalEndPoint);
        var verticalStartPoint = flipMainDrawingAreaAxisPoint(dimension.verticalStartPoint);
        var verticalEndPoint = flipMainDrawingAreaAxisPoint(dimension.verticalEndPoint);
        var cogPoint = flipMainDrawingAreaAxisPoint(dimension.cogPoint);

        if (drawCompleteCOGDimension === true) {
            //draw horizontal dimension portion
            graphicsAdapter.drawLine(graphicsObj, horizontalStartPoint, horizontalEndPoint, true, undefined, colourToUse);

            //draw vertical dimension portion
            graphicsAdapter.drawLine(graphicsObj, verticalStartPoint, verticalEndPoint, true, undefined, colourToUse);
            //draw vertical portion to COG marker connecting line
            graphicsAdapter.drawLine(graphicsObj, verticalEndPoint, cogPoint, true, undefined, colourToUse);
            //draw cog marker to horizontal portion connecting line
            if (cogPoint.x === horizontalEndPoint.x) {
                graphicsAdapter.drawLine(graphicsObj, cogPoint, horizontalEndPoint, true, undefined, colourToUse);
            } else {
                graphicsAdapter.drawLine(graphicsObj, cogPoint, horizontalStartPoint, true, undefined, colourToUse);
            }


            drawDimensionText(dimension.displayLengthVertical, dimension.font, colourToUse, dimension.verticalTextCentre, dimension.verticalTextBackgroundWidth, dimension.verticalTextBackgroundHeight, dimension.rotateText);
            drawDimensionText(dimension.displayLengthHorizontal, dimension.font, colourToUse, dimension.horizontalTextCentre, dimension.horizontalTextBackgroundWidth, dimension.horizontalTextBackgroundHeight);
        }

    } else if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE) {
        var jumpingTailLength = 20;
        if (dimension.drawBG) {
            drawRect(graphicsObj, dimension.bgTopLeft, dimension.bgWidth, dimension.bgHeight, false, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE, 0.75);
        }
        if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT && (dimension.endPoint.x - dimension.startPoint.x) <= pinnedInternalDimensionsJumpThreshold && (dimension.endPoint.x - dimension.startPoint.x) > 0) {
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.startPoint.x - jumpingTailLength, y: dimension.startPoint.y }, dimension.startPoint, true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.endPoint.x + jumpingTailLength, y: dimension.endPoint.y }, dimension.endPoint, true, undefined, colourToUse);
        } else if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT && (dimension.startPoint.x - dimension.endPoint.x) <= pinnedInternalDimensionsJumpThreshold && (dimension.endPoint.x - dimension.startPoint.x) < 0) {
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.endPoint.x - jumpingTailLength, y: dimension.endPoint.y }, dimension.endPoint, true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, { x: dimension.startPoint.x + jumpingTailLength, y: dimension.startPoint.y }, true, undefined, colourToUse);
        } else if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT && Math.abs(dimension.endPoint.x - dimension.startPoint.x) <= pinnedInternalDimensionsJumpThreshold) {
            if (dimension.endPoint.x > dimension.startPoint.x) {
                graphicsAdapter.drawLine(graphicsObj, dimension.endPoint, { x: dimension.endPoint.x + jumpingTailLength, y: dimension.endPoint.y }, true, undefined, colourToUse);
                graphicsAdapter.drawLine(graphicsObj, { x: dimension.startPoint.x - jumpingTailLength, y: dimension.startPoint.y }, dimension.startPoint, true, undefined, colourToUse);
            } else {
                graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, { x: dimension.startPoint.x + jumpingTailLength, y: dimension.startPoint.y }, true, undefined, colourToUse);
                graphicsAdapter.drawLine(graphicsObj, { x: dimension.endPoint.x - jumpingTailLength, y: dimension.endPoint.y }, dimension.endPoint, true, undefined, colourToUse);
            }
            
        } else if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP && (dimension.startPoint.y - dimension.endPoint.y) <= pinnedInternalDimensionsJumpThreshold) {
            graphicsAdapter.drawLine(graphicsObj, dimension.endPoint, { x: dimension.endPoint.x, y: dimension.endPoint.y - jumpingTailLength }, true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.startPoint.x, y: dimension.startPoint.y + jumpingTailLength }, dimension.startPoint, true, undefined, colourToUse);
        } else if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN && (dimension.startPoint.y - dimension.endPoint.y) <= pinnedInternalDimensionsJumpThreshold && dimension.startPoint.y > dimension.endPoint.y) {
            graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, { x: dimension.startPoint.x, y: dimension.startPoint.y + jumpingTailLength }, true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.endPoint.x, y: dimension.endPoint.y - jumpingTailLength }, dimension.endPoint, true, undefined, colourToUse);
        } else if (dimension.lineEndStyle === config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN && Math.abs(dimension.startPoint.y - dimension.endPoint.y) <= pinnedInternalDimensionsJumpThreshold  && dimension.startPoint.y <= dimension.endPoint.y) {
            graphicsAdapter.drawLine(graphicsObj, { x: dimension.endPoint.x, y: dimension.endPoint.y + jumpingTailLength }, dimension.endPoint, true, undefined, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, { x: dimension.startPoint.x, y: dimension.startPoint.y - jumpingTailLength }, true, undefined, colourToUse);
        } else {
            //drawRect(graphicsObj, { x: dimension.startPoint.x - 10, y: dimension.startPoint.y - 10 }, (dimension.length * scalingFactor) + 20, 20, false, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE, 0.5);

            graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, dimension.endPoint, true, undefined, colourToUse);
        }
        
        drawLineEndStyle(graphicsObj, dimension, addToGraphicsObject, undefined, colourToUse);
        drawDimensionText(dimension.displayLength, dimension.font, getDimensionTextColourToUse(dimension), dimension.textCentre, dimension.textBackgroundWidth, dimension.textBackgroundHeight, dimension.rotateText);
    } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
        var drawingGroupNameToUse = dimension.linkedDrawingGroupName === undefined ? dimension.drawingGroupName : dimension.linkedDrawingGroupName;
        if ((drawingGroupNameToUse !== 'NOT_SET' && (interactionState[drawingGroupNameToUse + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined || interactionState[drawingGroupNameToUse + '_' + 'PINNED'] !== undefined)) || drawingGroupNameToUse === 'NOT_SET') {
            graphicsAdapter.drawLine(graphicsObj, dimension.startPoint, dimension.endPoint, true, undefined, colourToUse);
            drawLineEndStyle(graphicsObj, dimension, addToGraphicsObject, undefined, colourToUse);
            drawDimensionText(dimension.displayLength, dimension.font, getTextColourToUse(dimension), dimension.textCentre, dimension.textBackgroundWidth, dimension.textBackgroundHeight, dimension.rotateText);
        }

    } else {
        //var addToGraphics = false;
        //if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER) {
        //    addToGraphics = true;
        //}



        if (dimension.style !== config.DIMENSION_STYLE.COG) {
            graphicsObj.lineStyle(lineWidth || dimensionLineWidth, colourToUse, 1);
        } else {
            graphicsObj.lineStyle(lineWidth || dimensionLineWidth, config.DRAWING_COLOURS.BLUE, 1);
        }

        //if (dimension.style !== config.DIMENSION_STYLE.POP_LAYER) {
            //if (dimension.jumpingLeft === undefined && dimension.jumpingDown === undefined) {
            if (dimension.style !== config.DIMENSION_STYLE.COG) {
                //arrow head 1
                drawArrowHead(dimension.ah1, addToGraphicsObject, lineWidth, colourToUse);
            }
            // line stop 1
            graphicsAdapter.drawLine(graphicsObj, dimension.lineStop1Start, dimension.lineStop1End, addToGraphicsObject, lineWidth, colourToUse);

            //main line 1
            graphicsAdapter.drawLine(graphicsObj, dimension.lineStart, dimension.lineEnd, addToGraphicsObject, lineWidth, colourToUse);

            if (dimension.style !== config.DIMENSION_STYLE.COG) {
                //main line 2
                graphicsAdapter.drawLine(graphicsObj, dimension.lineStart2, dimension.lineEnd2, addToGraphicsObject, lineWidth, colourToUse);

                // arrow head 2
                drawArrowHead(dimension.ah2, addToGraphicsObject, lineWidth, colourToUse);
                //line stop 2
                graphicsAdapter.drawLine(graphicsObj, dimension.lineStop2Start, dimension.lineStop2End, addToGraphicsObject, lineWidth, colourToUse);
            }
        //}
        var fontWeight;
        if (dimension.style === config.DIMENSION_STYLE.COG) {
            fontWeight = 'bold';
        }

        var customFont;
        if (dimensionPulseControllers[dimension.drawingGroupName] && dimensionPulseControllers[dimension.drawingGroupName].getPulseState() === 'ON') {
            customFont = 'bold ' + dimension.font;
        }

        drawDimensionText(dimension.displayLength, customFont || dimension.font, getDimensionTextColourToUse(dimension), dimension.textCentre, dimension.textBackgroundWidth, dimension.textBackgroundHeight, dimension.rotateText);
        if (dimension.pulse === true) {
            dimensionsToPulse.push(dimension);
            ////pulseController = new PulseController(dimension.drawingGroupName);
            ////dimension.setPulse(false);
            ////pulseController.start();
            //dimensionPulseControllers[dimension.drawingGroupName] = new PulseController(dimension.drawingGroupName);
            //dimension.setPulse(false);
            //dimensionPulseControllers[dimension.drawingGroupName].start();
            //var breakHere = 0;
        }
    }


    //if (addToGraphicsObject === undefined) {
    graphicsAdapter.setAlternateGraphicsObject(null);
    return graphicsObj;
    //}
    function drawArrowHead(ah, addToGraphics, lineWidth, colourToUse) {
        if (addToGraphics === true) {
            //#unfinished, this codes needs to be tidied up, currectly clause below needed due to double flip as processOverviewDimension code not ported to better style of new cogDimension
            if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER) {

                graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.sideAEnd, true, lineWidth, colourToUse);
                graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.sideBEnd, true, lineWidth, colourToUse);
                if (ah.tailPoint !== undefined) {
                    graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.tailPoint, true, lineWidth, colourToUse);
                }
            } else {
                flipMainDrawingAreaAxisPoint(ah.tip);
                graphicsAdapter.drawLine(graphicsObj, ah.tip, flipMainDrawingAreaAxisPoint(ah.sideAEnd), true, lineWidth, colourToUse);
                graphicsAdapter.drawLine(graphicsObj, ah.tip, flipMainDrawingAreaAxisPoint(ah.sideBEnd), true, lineWidth, colourToUse);
                if (ah.tailPoint !== undefined) {
                    graphicsAdapter.drawLine(graphicsObj, ah.tip, flipMainDrawingAreaAxisPoint(ah.tailPoint), true, lineWidth, colourToUse);
                }
            }

        } else {
            graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.sideAEnd, false, lineWidth, colourToUse);
            graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.sideBEnd, false, lineWidth, colourToUse);
            if (ah.tailPoint !== undefined) {
                graphicsAdapter.drawLine(graphicsObj, ah.tip, ah.tailPoint, false, lineWidth, colourToUse);
            }
        }


    }


    function drawDimensionText(displayText, font, fontColour, textCentre, bgWidth, bgHeight, optionalRotationAmount) {
        //var textObj = new PIXI.Text("" + displayText, { font: font, fill: fontColour, align: 'center', fontWeight: fontWeight });
        if (addToGraphicsObject !== undefined && addToGraphicsObject === true) {
            //unfinished, check for inner dimesion below only required because overview dimension code not ported and therefore double axis flip is happening ... code needs to be refactored to newer cogDimension style 
            if (dimension.type !== config.DIMENSION_TYPE.VERTICAL_INNER && dimension.type !== config.DIMENSION_TYPE.HORIZONTAL_INNER && dimension.type !== config.DIMENSION_TYPE.FREE_ANGLE && dimension.type !== config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
                flipMainDrawingAreaAxisPoint(textCentre);
            }

        }
        var anchorToUse = { x: 0.5, y: 0.5 };
        if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
            anchorToUse = { x: 0, y: 0 };
        }
        var textObj = drawText("" + displayText, { x: bgWidth / 2, y: bgHeight / 2 }, false, anchorToUse, 'center', font, fontColour);
        if (dimension.interaction !== undefined) {
            if (dimension.interaction.callbacks !== undefined) {
                textObj.interactive = true;
                textObj.buttonMode = true;
                if (dimension.interaction.callbacks.mouseover !== undefined && typeof dimension.interaction.callbacks.mouseover === 'function') {
                    textObj.mouseover = dimension.interaction.callbacks.mouseover;
                }
                if (dimension.interaction.callbacks.click !== undefined && typeof dimension.interaction.callbacks.click === 'function') {
                    textObj.click = dimension.interaction.callbacks.click;
                    textObj.tap = dimension.interaction.callbacks.click;
                }
                if (dimension.interaction.callbacks.mouseout !== undefined && typeof dimension.interaction.callbacks.mouseout === 'function') {
                    textObj.mouseout = dimension.interaction.callbacks.mouseout;
                }
                if (dimension.interaction.callbacks.mousemove !== undefined && typeof dimension.interaction.callbacks.mousemove === 'function') {
                    textObj.mousemove = dimension.interaction.callbacks.mousemove;
                }
            }
            //addInteractionToText(dimension, textObj);
        }


        //textObj.anchor = new PIXI.Point(0.5, 0.5);

        var textBackground = graphicsAdapter.getNewGraphicsObject();

        textBackground.x = textCentre.x;
        textBackground.y = textCentre.y;
        //textObj.x = bgWidth / 2;
        //textObj.y = bgHeight / 2;

        if (optionalRotationAmount !== undefined) {
            if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
                textBackground.rotation = optionalRotationAmount;
                textObj.x = textObj.x - bgWidth + 4;
                textObj.y = textObj.y - bgHeight - 2;
            } else {
                textObj.rotation = optionalRotationAmount;
            }


        }

        textBackground.beginFill(config.DRAWING_COLOURS.WHITE, 1);
        //textBackground.lineStyle(1, 0x000000);
        if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
            textBackground.drawRect(-bgWidth / 2, -bgHeight / 2, bgWidth, bgHeight);
        } else {
            textBackground.drawRect(0, 0, bgWidth, bgHeight);
        }

        textBackground.endFill();

        textBackground.addChild(textObj);
        graphicsObj.addChild(textBackground);
    }
}

function drawTrialWatermarkOverlay() {
    var dfd = $.Deferred();
    sprite = graphicsAdapter.getTrialWatermarkSprite();
    if (sprite !== null) {
        //sprite.interactive = true;
        //sprite.mouseover = function (mouseData) {
        //    var breakHere = 0;
        //}

        // set observable
        globals.trialStampWatermarkIsAvailable.value = false;

        sprite.position.x = drawingArea.mainDrawingArea.width / 2 - sprite.width / 2;
        sprite.position.y = ((overallHeight / 2) - mainY) - (sprite.height / 2);
        drawingArea.trialWatermarkDrawingArea.addChild(sprite);
        if (needToDoRerender === true) {
            needToDoRerender = false;
            renderer.render(drawingArea.drawingArea);
        }

        globals.trialStampWatermarkIsAvailable.value = true;

        dfd.resolve();
    } else {
        dfd.reject();
    }
    return dfd.promise();
}

//function drawCOGElement(cogElementMeta) {
//    var connectingLineLength = 15 / scalingFactor;
//    var radius  = cogElementMeta.height;

//    drawConnectingLine(cogElementMeta.position, radius, connectingLineLength, cogElementMeta.style);

//    var textContainerPosition;
//    //var textPos;
//    if (cogElementMeta.style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
//        textContainerPosition = { x: cogElementMeta.position.x - radius - connectingLineLength - cogElementMeta.width, y: cogElementMeta.position.y - cogElementMeta.height/2 };

//    } else {
//        textContainerPosition = { x: cogElementMeta.position.x + radius + connectingLineLength, y: cogElementMeta.position.y - cogElementMeta.height/2 };
//        //graphicsAdapter.drawRect({ x: textContainerPosition.x, y: textContainerPosition.y  }, cogElementMeta.width + 4, cogElementMeta.height, true, undefined, 0xFFFFFF);
//    }
//    //textPos = { x: textContainerPosition.x + (cogElementMeta.textBuffer * 4), y: textContainerPosition.y + cogElementMeta.textBuffer };

//    //drawRoundedRect(textContainerPosition, cogElementMeta.width, cogElementMeta.height);
//    //graphicsAdapter.drawRect(textContainerPosition, cogElementMeta.width, cogElementMeta.height, true, undefined, 0xFFFFFF);
//    graphicsAdapter.drawRectWithFixedBuffer(textContainerPosition, cogElementMeta.width - (cogElementMeta.textBuffer * 2), cogElementMeta.height - (cogElementMeta.textBuffer * 2), cogElementMeta.textBuffer, undefined, config.DRAWING_COLOURS.WHITE);

//    //var scaledCentre = { x: (textContainerPosition.x * scalingFactor) + cogElementMeta.textBuffer, y: mainHeight - ((textContainerPosition.y * scalingFactor) + ((cogElementMeta.height * scalingFactor) - cogElementMeta.textBuffer)) };
//    //var scaledCentre = { x: (textContainerPosition.x * scalingFactor) + cogElementMeta.textBuffer, y: mainHeight - ((textContainerPosition.y + cogElementMeta.height / 2) + (cogElementMeta.textBuffer * 2)) * scalingFactor };
//    //var scaledTextPos = { x: ((textContainerPosition.x) * scalingFactor)+3, y: (mainHeight) - ((((textContainerPosition.y + (cogElementMeta.height / 2))) * scalingFactor)) };

//    //var scaledTextPos = { x: Math.floor(((textContainerPosition.x + cogElementMeta.textBuffer) * scalingFactor)), y: (mainHeight) - (cogElementMeta.position.y * scalingFactor) };
//    var textPos = { x: textContainerPosition.x + cogElementMeta.textBuffer, y: cogElementMeta.position.y };
//    var customFontColour;

//    var text = drawText(cogElementMeta.displayText, textPos, true, { x: 0, y: 0.5 }, undefined, dimensionFontSize + "px " + curFontName, cogElementMeta.customFontColour);

//    //if (cogElementMeta.interaction !== undefined) {
//    //    addInteractionToText(cogElementMeta, text);
//    //}
//    if (cogElementMeta.interaction !== undefined) {

//        cogElementMeta.interaction.callbacks = getCallbacks(cogElementMeta.interaction, cogElementMeta.interaction.callbacks);

//        if (cogElementMeta.interaction.callbacks !== undefined) {
//            text.interactive = true;
//            text.buttonMode = true;
//            if (cogElementMeta.interaction.callbacks.mouseover !== undefined && typeof cogElementMeta.interaction.callbacks.mouseover === 'function') {
//                text.mouseover = cogElementMeta.interaction.callbacks.mouseover;
//            }
//            if (cogElementMeta.interaction.callbacks.click !== undefined && typeof cogElementMeta.interaction.callbacks.click === 'function') {
//                text.click = cogElementMeta.interaction.callbacks.click;
//                text.touchstart = cogElementMeta.interaction.callbacks.click;
//            }
//            if (cogElementMeta.interaction.callbacks.mouseout !== undefined && typeof cogElementMeta.interaction.callbacks.mouseout === 'function') {
//                text.mouseout = cogElementMeta.interaction.callbacks.mouseout;
//            }
//        }

//    }


//    //drawingArea.mainDrawingArea.addChild(text);
//    drawCOGMarker(graphicsAdapter.getGraphicsObject(), cogElementMeta.position, radius, true);

//}

//#note, this method is not currently used alhtough should be in future and all instances were interaction added to text object should use it, one thing to wathc for is the setting up of the callbacks using getCallbacks
//as this step should be skipped if already done as is the case with dimensions but not with cog element however the rest of the code will be identical
function addInteractionToText(interactiveElement, textObject) {
    if (interactiveElement.interaction !== undefined) {

        interactiveElement.interaction.callbacks = getCallbacks(interactiveElement.interaction, interactiveElement.interaction.callbacks);

        if (interactiveElement.interaction.callbacks !== undefined) {
            textObject.interactive = true;
            textObject.buttonMode = true;
            if (interactiveElement.interaction.callbacks.mouseover !== undefined && typeof interactiveElement.interaction.callbacks.mouseover === 'function') {
                textObject.mouseover = interactiveElement.interaction.callbacks.mouseover;
            }
            if (interactiveElement.interaction.callbacks.click !== undefined && typeof interactiveElement.interaction.callbacks.click === 'function') {
                textObject.click = interactiveElement.interaction.callbacks.click;
                textObject.tap = interactiveElement.interaction.callbacks.click;
            }
            if (interactiveElement.interaction.callbacks.mouseout !== undefined && typeof interactiveElement.interaction.callbacks.mouseout === 'function') {
                textObject.mouseout = interactiveElement.interaction.callbacks.mouseout;
            }
            if (interactiveElement.interaction.callbacks.mousemove !== undefined && typeof interactiveElement.interaction.callbacks.mousemove === 'function') {
                textObject.mousemove = interactiveElement.interaction.callbacks.mousemove;
            }
        }

    }
}

function drawCOGLabel(text, cogPoint, cogStyle) {

}

function isCOGDimension(type) {
    if (type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT || type === config.DIMENSION_TYPE.COG_TOP_LEFT || type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
        return true;
    }
    return false;
}

function drawNewCOGElement(cogGraphicObject, element, radius, text, style, scalePoints) {

    var drawCompleteCOGElement = false;
    if (!element.drawMarkerOnly) {
        if ((isCOGDimension(element.type) && element.drawingGroupName !== 'NOT_SET' && (interactionState[element.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined || interactionState[element.drawingGroupName + '_' + 'PINNED'] !== undefined)) || (!isCOGDimension(element.type) || element.drawingGroupName === 'NOT_SET')) {
            //alert('drawCompleteCOGElement = true');
            drawCompleteCOGElement = true;
        }
    }
    var defaultColourOverrideColour;
    if (isCOGDimension(element.type) && hitAreaObjects[element.drawingGroupName] && hitAreaObjects[element.drawingGroupName].hitMasks) {
        defaultColourOverrideColour = config.DRAWING_COLOURS.CLICKABLE;
    } else {
        defaultColourOverrideColour = element.colour;
    }

    var colourForMarkerToUse = getColourToUse(element, defaultColourOverrideColour);
    var colourToUse = getColourToUse(element);
    //var cogGraphicObject = graphicsAdapter.getNewGraphicsObject();
    var cogPoint = element.cogPoint || element.position;
    graphicsAdapter.setAlternateGraphicsObject(cogGraphicObject);

    drawCOGMarker(cogGraphicObject, element.drawingGroupName, colourForMarkerToUse, cogPoint, radius, scalePoints);
    if (text !== null) {
        if (drawCompleteCOGElement) {
            drawNewConnectingLine();
            drawNewCOGTextElement();
        }

    }


    graphicsAdapter.getGraphicsObject().addChild(cogGraphicObject);
    graphicsAdapter.setAlternateGraphicsObject(null);

    return cogGraphicObject;

    function drawNewConnectingLine() {
        var lineStartPoint = {}, lineEndPoint = {}, verticalLineStartPoint, verticalLineEndPoint;
        if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
            lineStartPoint.x = cogPoint.x - radius - 15;
            lineEndPoint.x = cogPoint.x - radius;
            lineStartPoint.y = cogPoint.y;
            lineEndPoint.y = cogPoint.y;
        } else if (style === config.COG_ELEMENT_STYLE.DRAW_RIGHT) {
            lineStartPoint.x = cogPoint.x + radius;
            lineEndPoint.x = cogPoint.x + radius + 15;
            lineStartPoint.y = cogPoint.y;
            lineEndPoint.y = cogPoint.y;
        } else if (style === config.COG_ELEMENT_STYLE.DRAW_TOP) {
            lineStartPoint.x = cogPoint.x - radius - 15;
            lineEndPoint.x = cogPoint.x;
            lineStartPoint.y = cogPoint.y + radius + 15;
            lineEndPoint.y = cogPoint.y + radius + 15;

            verticalLineStartPoint = {};
            verticalLineEndPoint = {};

            verticalLineStartPoint.x = cogPoint.x;
            verticalLineEndPoint.x = cogPoint.x;
            verticalLineStartPoint.y = cogPoint.y + radius;
            verticalLineEndPoint.y = cogPoint.y + radius + 15;

        } else if (style === config.COG_ELEMENT_STYLE.DRAW_BOTTOM) {
            lineStartPoint.x = cogPoint.x - radius - 15;
            lineEndPoint.x = cogPoint.x;
            lineStartPoint.y = cogPoint.y - (radius + 15);
            lineEndPoint.y = cogPoint.y - (radius + 15);

            verticalLineStartPoint = {};
            verticalLineEndPoint = {};

            verticalLineStartPoint.x = cogPoint.x;
            verticalLineEndPoint.x = cogPoint.x;
            verticalLineStartPoint.y = cogPoint.y - radius;
            verticalLineEndPoint.y = cogPoint.y - (radius + 15);

        }

        //lineStartPoint.y = cogPoint.y;
        //lineEndPoint.y = cogPoint.y;
        //lineStartPoint.y = cogPosition.y;
        //lineEndPoint.y = cogPosition.y;
        //scalePoint(lineStartPoint);
        //scalePoint(lineEndPoint);
        //flipMainDrawingAreaAxisPoint(lineStartPoint);
        //flipMainDrawingAreaAxisPoint(lineEndPoint);
        var addToGraphics = false;
        graphicsAdapter.drawLine(cogGraphicObject, flipMainDrawingAreaAxisPoint(lineStartPoint), flipMainDrawingAreaAxisPoint(lineEndPoint), addToGraphics, undefined, colourToUse);
        if (verticalLineStartPoint) {
            graphicsAdapter.drawLine(cogGraphicObject, flipMainDrawingAreaAxisPoint(verticalLineStartPoint), flipMainDrawingAreaAxisPoint(verticalLineEndPoint), addToGraphics, undefined, colourToUse);
        }
    }
    function drawNewCOGTextElement() {
        var textWidth, textHeight;
        if (element.width) {
            textWidth = element.width;//getWidthOfText("" + text, curFontName, dimensionFontSize);
        } else {
            textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
        }
        if (element.height) {
            textHeight = element.height;//getHeightOfText("" + text, curFontName, dimensionFontSize, textWidth);//10;
        } else {
            textHeight = cogDimensionFontTextHeight;
        }
        var textBuffer = 5;

        var cogPointCopy = JSON.parse(JSON.stringify(cogPoint));
        flipMainDrawingAreaAxisPoint(cogPointCopy);

        var bgElementTopLeftCorner = {}, textElementPoint = {};
        //if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
        //    bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
        //    //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
        //    textElementPoint.x = textBuffer;
        //} else {
        //    bgElementTopLeftCorner.x = cogPointCopy.x + radius + 15;
        //    //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
        //    textElementPoint.x = textBuffer;
        //}
        if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
            bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
            //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
            textElementPoint.x = textBuffer;

            bgElementTopLeftCorner.y = cogPointCopy.y - textBuffer - (textHeight / 2);
            //textElementPoint.y = cogPointCopy.y;
            textElementPoint.y = textBuffer + (textHeight / 2);
        } else if (style === config.COG_ELEMENT_STYLE.DRAW_RIGHT) {
            bgElementTopLeftCorner.x = cogPointCopy.x + radius + 15;
            //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
            textElementPoint.x = textBuffer;

            bgElementTopLeftCorner.y = cogPointCopy.y - textBuffer - (textHeight / 2);
            //textElementPoint.y = cogPointCopy.y;
            textElementPoint.y = textBuffer + (textHeight / 2);
        } else if (style === config.COG_ELEMENT_STYLE.DRAW_TOP) {
            bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
            //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
            textElementPoint.x = textBuffer;

            bgElementTopLeftCorner.y = cogPointCopy.y - radius - 15 - textBuffer - (textHeight / 2);
            //textElementPoint.y = cogPointCopy.y;
            textElementPoint.y = textBuffer + (textHeight / 2);
        } else if (style === config.COG_ELEMENT_STYLE.DRAW_BOTTOM) {
            bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
            //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
            textElementPoint.x = textBuffer;

            bgElementTopLeftCorner.y = cogPointCopy.y + radius + 15 - textBuffer - (textHeight / 2);
            //textElementPoint.y = cogPointCopy.y;
            textElementPoint.y = textBuffer + (textHeight / 2);
        }
        //bgElementTopLeftCorner.y = cogPointCopy.y - textBuffer - (textHeight / 2);
        ////textElementPoint.y = cogPointCopy.y;
        //textElementPoint.y = textBuffer + (textHeight / 2);

        var bgElement = graphicsAdapter.getDrawingContainer(bgElementTopLeftCorner, textWidth + (textBuffer * 2), textHeight + (textBuffer * 2), config.DRAWING_COLOURS.WHITE, colourToUse);

        var textColourToUse = getTextColourToUse(element);
        var textElement = drawText(text, textElementPoint, false, { x: 0, y: 0.5 }, undefined, dimensionFontSize + "px " + curFontName, element.customFontColour || textColourToUse);

        //if (element.interaction !== undefined) {
        //    addInteractionToText(element, textElement);
        //}
        if (element.interaction !== undefined) {
            if (element.interaction.callbacks !== undefined) {
                textElement.interactive = true;
                textElement.buttonMode = true;
                if (element.interaction.callbacks.mouseover !== undefined && typeof element.interaction.callbacks.mouseover === 'function') {
                    textElement.mouseover = element.interaction.callbacks.mouseover;
                }
                if (element.interaction.callbacks.click !== undefined && typeof element.interaction.callbacks.click === 'function') {
                    textElement.click = element.interaction.callbacks.click;
                    textElement.tap = element.interaction.callbacks.click;
                }
                if (element.interaction.callbacks.mouseout !== undefined && typeof element.interaction.callbacks.mouseout === 'function') {
                    textElement.mouseout = element.interaction.callbacks.mouseout;
                }
                if (element.interaction.callbacks.mousemove !== undefined && typeof element.interaction.callbacks.mousemove === 'function') {
                    textElement.mousemove = element.interaction.callbacks.mousemove;
                }
            }
            //addInteractionToText(dimension, textObj);
        }

        bgElement.addChild(textElement);
        cogGraphicObject.addChild(bgElement);
    }
}

function drawCOGMarker(graphicsObjectToUse, drawingGroupName, colourToUse, cogPosition, radius, scalePoints, optionalLabelMeta) {

    var topLeftArcStartAngle, topLeftArcEndAngle, topRightArcStartAngle, topRightArcEndAngle, bottomLeftArcStartAngle, bottomLeftArcEndAngle, bottomRightArcStartAngle, bottomRightArcEndAngle;

    var cogMarkerRadius = radius;

    topLeftArcStartAngle = 90;
    topLeftArcEndAngle = 90;

    topRightArcStartAngle = 0;
    topRightArcEndAngle = 90;

    bottomLeftArcStartAngle = 180;
    bottomLeftArcEndAngle = 90;

    bottomRightArcStartAngle = 270;
    bottomRightArcEndAngle = 90;

    if (scalePoints) {
        var scaledCopy = scalePointAsCopy(cogPosition);
        flipMainDrawingAreaAxisPoint(scaledCopy);
        graphicsAdapter.drawCircle(graphicsObjectToUse, scaledCopy, scaleValue(cogMarkerRadius), config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
    } else {
        var flippedCoGPositionCopy = JSON.parse(JSON.stringify(cogPosition));
        flipMainDrawingAreaAxisPoint(flippedCoGPositionCopy);
        graphicsAdapter.drawCircle(graphicsObjectToUse, flippedCoGPositionCopy, cogMarkerRadius, config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
    }


    var arc1 = new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, topLeftArcStartAngle, topLeftArcEndAngle, true);
    arc1.setDrawingGroupName(drawingGroupName);

    var arc2 = new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, topRightArcStartAngle, topRightArcEndAngle);
    arc2.setDrawingGroupName(drawingGroupName);

    var arc3 = new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, bottomLeftArcStartAngle, bottomLeftArcEndAngle);
    arc3.setDrawingGroupName(drawingGroupName);

    var arc4 = new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, bottomRightArcStartAngle, bottomRightArcEndAngle, true);
    arc4.setDrawingGroupName(drawingGroupName);

    drawArc(graphicsObjectToUse, arc1, scalePoints, colourToUse, colourToUse);
    drawArc(graphicsObjectToUse, arc2, scalePoints, undefined, colourToUse !== config.DRAWING_COLOURS.BLACK ? colourToUse : undefined);
    drawArc(graphicsObjectToUse, arc3, scalePoints, undefined, colourToUse !== config.DRAWING_COLOURS.BLACK ? colourToUse : undefined);
    drawArc(graphicsObjectToUse, arc4, scalePoints, colourToUse, colourToUse);

}

//function drawCOGMarkerNoScaling(cogPosition, radius) {

//    var topLeftArcStartAngle, topLeftArcEndAngle, topRightArcStartAngle, topRightArcEndAngle, bottomLeftArcStartAngle, bottomLeftArcEndAngle, bottomRightArcStartAngle, bottomRightArcEndAngle;

//    var cogMarkerRadius = radius;

//    topLeftArcStartAngle = 90;
//    topLeftArcEndAngle = 90;

//    topRightArcStartAngle = 0;
//    topRightArcEndAngle = 90;

//    bottomLeftArcStartAngle = 180;
//    bottomLeftArcEndAngle = 90;

//    bottomRightArcStartAngle = 270;
//    bottomRightArcEndAngle = 90;

//    graphicsAdapter.drawCircleNoScaling(cogPosition, cogMarkerRadius, 0xFFFFFF, 0xFFFFFF);

//    drawArc(new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, topLeftArcStartAngle, topLeftArcEndAngle, true), false, 0x000000);
//    drawArc(new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, topRightArcStartAngle, topRightArcEndAngle), false);
//    drawArc(new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, bottomLeftArcStartAngle, bottomLeftArcEndAngle), false);
//    drawArc(new Arc(cogPosition.x, cogPosition.y, cogMarkerRadius, bottomRightArcStartAngle, bottomRightArcEndAngle, true), false, 0x000000);

//}

function drawConnectingLine(cogPosition, radius, connectingLineLength, style) {

    var lineStartPoint = {}, lineEndPoint = {};
    if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
        lineStartPoint.x = cogPosition.x - radius - connectingLineLength;
        lineEndPoint.x = cogPosition.x - radius;
    } else {
        lineStartPoint.x = cogPosition.x + radius;
        lineEndPoint.x = cogPosition.x + radius + connectingLineLength;
    }

    lineStartPoint.y = cogPosition.y;
    lineEndPoint.y = cogPosition.y;
    //lineStartPoint.y = cogPosition.y;
    //lineEndPoint.y = cogPosition.y;
    scalePoint(lineStartPoint);
    scalePoint(lineEndPoint);
    flipMainDrawingAreaAxisPoint(lineStartPoint);
    flipMainDrawingAreaAxisPoint(lineEndPoint);
    var addToGraphics = true;
    graphicsAdapter.drawLine(graphicsAdapter.getGraphicsObject(), lineStartPoint, lineEndPoint, addToGraphics);
}

//function drawRoundedRect(position, width, height, optionalFillColour) {
//    var addToGraphics = true;
//    var topLineStartPoint = {}, topLineEndPoint = {}, leftLineStartPoint = {}, leftLineEndPoint = {}, bottomLineStartPoint = {}, bottomLineEndPoint = {}, rightLineStartPoint = {}, rightLineEndPoint = {};
//    var topLeftArcCentrePoint = {}, topRightArcCentrePoint = {}, bottomLeftArcCentrePoint = {}, bottomRightArcCentrePoint = {};
//    var topLeftArcStartAngle, topLeftArcEndAngle, topRightArcStartAngle, topRightArcEndAngle, bottomLeftArcStartAngle, bottomLeftArcEndAngle, bottomRightArcStartAngle, bottomRightArcEndAngle;

//    var radius = height / 6;



//    topLineStartPoint.x = position.x + radius;
//    topLineStartPoint.y = position.y;
//    topLineEndPoint.x = position.x + width - radius;
//    topLineEndPoint.y = position.y;

//    bottomLineStartPoint.x = position.x + radius;
//    bottomLineStartPoint.y = position.y + height;
//    bottomLineEndPoint.x = position.x + width - radius;
//    bottomLineEndPoint.y = position.y + height;

//    leftLineStartPoint.x = position.x;
//    leftLineStartPoint.y = position.y + radius;
//    leftLineEndPoint.x = position.x;
//    leftLineEndPoint.y = leftLineStartPoint.y + height - (radius * 2);

//    rightLineStartPoint.x = position.x + width;
//    rightLineStartPoint.y = position.y + radius;
//    rightLineEndPoint.x = position.x + width;
//    rightLineEndPoint.y = rightLineStartPoint.y + height - (radius * 2);
//    //graphicsAdapter.getGraphicsObject().beginFill(0xFFFFFF, 1);
//    drawRect(position, width, height, addToGraphics, 0xFFFFFF, 0xFFFFFF);
//    graphicsAdapter.drawLine(topLineStartPoint, topLineEndPoint, addToGraphics);
//    graphicsAdapter.drawLine(bottomLineStartPoint, bottomLineEndPoint, addToGraphics);
//    graphicsAdapter.drawLine(leftLineStartPoint, leftLineEndPoint, addToGraphics);
//    graphicsAdapter.drawLine(rightLineStartPoint, rightLineEndPoint, addToGraphics);
//    //graphicsAdapter.getGraphicsObject().endFill();
//    topLeftArcCentrePoint.x = position.x + radius;
//    topLeftArcCentrePoint.y = position.y + radius;

//    topRightArcCentrePoint.x = position.x + width - radius;
//    topRightArcCentrePoint.y = position.y + radius;

//    bottomLeftArcCentrePoint.x = position.x + radius;
//    bottomLeftArcCentrePoint.y = position.y + height - radius;

//    bottomRightArcCentrePoint.x = position.x + width - radius;
//    bottomRightArcCentrePoint.y = position.y + height - radius;

//    topLeftArcStartAngle = 90;
//    topLeftArcEndAngle = 90;

//    topRightArcStartAngle = 0;
//    topRightArcEndAngle = 90;

//    bottomLeftArcStartAngle = 180;
//    bottomLeftArcEndAngle = 90;

//    bottomRightArcStartAngle = 270;
//    bottomRightArcEndAngle = 90;
//    //drawArc(new Arc(topLeftArcCentrePoint.x, topLeftArcCentrePoint.y, radius, topLeftArcStartAngle, topLeftArcEndAngle, true), 0xFFFFFF);
//    //drawArc(new Arc(topRightArcCentrePoint.x, topRightArcCentrePoint.y, radius, topRightArcStartAngle, topRightArcEndAngle), 0xFFFFFF);
//    //drawArc(new Arc(bottomLeftArcCentrePoint.x, bottomLeftArcCentrePoint.y, radius, bottomLeftArcStartAngle, bottomLeftArcEndAngle), 0xFFFFFF);
//    //drawArc(new Arc(bottomRightArcCentrePoint.x, bottomRightArcCentrePoint.y, radius, bottomRightArcStartAngle, bottomRightArcEndAngle, true), 0xFFFFFF);

//    drawArc(new Arc(topLeftArcCentrePoint.x, topLeftArcCentrePoint.y, radius, topLeftArcStartAngle, topLeftArcEndAngle, true), true);
//    drawArc(new Arc(topRightArcCentrePoint.x, topRightArcCentrePoint.y, radius, topRightArcStartAngle, topRightArcEndAngle), true);
//    drawArc(new Arc(bottomLeftArcCentrePoint.x, bottomLeftArcCentrePoint.y, radius, bottomLeftArcStartAngle, bottomLeftArcEndAngle), true);
//    drawArc(new Arc(bottomRightArcCentrePoint.x, bottomRightArcCentrePoint.y, radius, bottomRightArcStartAngle, bottomRightArcEndAngle, true), true);

//    //graphicsAdapter.drawArc(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, 0x000000);
//    //graphicsAdapter.drawLine(objectData.startPoint, objectData.endPoint, addToGraphics);
//    //graphicsAdapter.drawArc(objectData.centre, objectData.startPoint, objectData.radius, objectData.startAngle, objectData.endAngle, 0x000000);
//    //new arc(startX, startY, radius, startAngle, sweep)
//}

function drawRoundedTriangle(centrePoint, radius) {

}

function drawTextObject(graphicsObjjectToUse, objectData) {
    var fontSize = dimensionFontSize;
    var fontName = curFontName;
    var displayText = objectData.getDisplayText();
    var fontStyle = fontSize + 'px ' + fontName;
    var textWidth = getWidthOfText(displayText, fontName, fontSize), textHeight = 10;
    var bgWidth = objectData.backgroundWidth || (textWidth + 4);
    var bgHeight = objectData.backgroundHeight || (textHeight + 2);
    //(text, position, addToGraphics, anchorPoint, textAligment, font, optionalFontColour
    var textObj = drawText(displayText, { x: bgWidth / 2, y: bgHeight / 2 }, false, { x: 0.5, y: 0.5 }, undefined, objectData.style || fontStyle, objectData.colour);

    var textBackground = graphicsAdapter.getNewGraphicsObject();

    //need to scal and flip as false was passed into drawText for addToGraphics param
    scalePoint(objectData.position);
    flipMainDrawingAreaAxisPoint(objectData.position);

    textBackground.x = objectData.position.x - bgWidth / 2;
    textBackground.y = objectData.position.y - bgHeight / 2;

    var backgroundColour;
    if (objectData.backgroundColour !== undefined) {
        if (pulseController !== null && pulseController.drawingGroupName === objectData.drawingGroupName) {
            backgroundColour = getPulseColourToUse(objectData.backgroundColour);
        } else {
            backgroundColour = getColourToUse(objectData, objectData.backgroundColour);
        }
    } else {
        backgroundColour = config.DRAWING_COLOURS.WHITE;
    }


    textBackground.beginFill(backgroundColour, 1);
    textBackground.drawRect(0, 0, bgWidth, bgHeight);

    textBackground.endFill();

    textBackground.addChild(textObj);
    if (objectData.rotationAngle !== undefined) {
        textBackground.pivot = new PIXI.Point(bgWidth / 2, -bgHeight / 4);
        textBackground.rotation = objectData.rotationAngle * Math.PI / 180;
    }
    graphicsObjjectToUse.addChild(textBackground);
}

function drawHitchLoadObject(graphicsObjectToUse, hitchLoadObj) {
    //graphicsObjectToUse.beginFill(0x000000, 1);
    //graphicsObjectToUse.drawRect(hitchLoadObj.centre.x * scalingFactor, mainHeight-( hitchLoadObj.centre.y * scalingFactor), 1000, 1000);
    //graphicsObjectToUse.endFill();
    var originX = hitchLoadObj.centre.x * scalingFactor, originY = mainHeight - (hitchLoadObj.centre.y * scalingFactor);

    //drawLine(graphicsObjectToUse, new Line(originX, originY, originX - 20, originY + 20), false);
    //drawLine(graphicsObjectToUse, new Line(originX - 20, originY + 20, originX - 10, originY + 20), false);
    //drawLine(graphicsObjectToUse, new Line(originX - 10, originY + 20, originX - 10, originY + 30), false);
    //drawLine(graphicsObjectToUse, new Line(originX - 10, originY + 30, originX - 30, originY + 30), false);
    //drawLine(graphicsObjectToUse, new Line(originX - 30, originY + 30, originX - 30, originY + 50), false);


    //drawLine(graphicsObjectToUse, new Line(originX, originY, originX + 20, originY + 20), false);
    //drawLine(graphicsObjectToUse, new Line(originX + 20, originY + 20, originX + 10, originY + 20), false);
    //drawLine(graphicsObjectToUse, new Line(originX + 10, originY + 20, originX + 10, originY + 30), false);
    //drawLine(graphicsObjectToUse, new Line(originX + 10, originY + 30, originX + 30, originY + 30), false);
    //drawLine(graphicsObjectToUse, new Line(originX + 30, originY + 30, originX + 30, originY + 50), false);

    //drawLine(graphicsObjectToUse, new Line(originX - 30, originY + 50, originX + 30, originY + 50), false);

    var hitchLoadArrowLine = new Line(originX, originY - 80, originX, originY - 30);
    hitchLoadArrowLine.setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);
    drawLine(graphicsObjectToUse, hitchLoadArrowLine, false);

    drawHitchLoadLabel();

    mainDrawingGraphicsObjects[hitchLoadObj.drawingGroupName].bringToFront = true;


    function drawHitchLoadLabel() {
        var textWidth = getWidthOfText("" + hitchLoadObj.text, curFontName, dimensionFontSize);
        var textHeight = 10;
        var textBuffer = 5;

        var hitchPointCopy = scalePointAsCopy(hitchLoadObj.centre);
        flipMainDrawingAreaAxisPoint(hitchPointCopy);

        var bgElementTopLeftCorner = {}, textElementPoint = {};

        bgElementTopLeftCorner.x = hitchPointCopy.x - 5;
        textElementPoint.x = textBuffer;


        bgElementTopLeftCorner.y = hitchPointCopy.y - 80 - textHeight - textBuffer * 2;
        textElementPoint.y = textBuffer + textHeight / 2;

        var bgElement = graphicsAdapter.getDrawingContainer(bgElementTopLeftCorner, textWidth + (textBuffer * 2), textHeight + (textBuffer * 2), config.DRAWING_COLOURS.WHITE, 0x000000);

        //var textColourToUse = getTextColourToUse(hitchLoadObj);
        var textElement = drawText(hitchLoadObj.text, textElementPoint, false, { x: 0, y: 0.5 }, undefined, dimensionFontSize + "px " + curFontName, 0x000000);

        bgElement.addChild(textElement);
        graphicsObjectToUse.addChild(bgElement);
    }
}

function getWidthOfText(txt, fontname, fontsize, fontAddition) {
    // Create a dummy canvas (render invisible with css)
    var c = document.createElement('canvas');
    // Get the context of the dummy canvas
    var ctx = c.getContext('2d');
    // Set the context.font to the font that you are using
    ctx.font = fontsize + 'px ' + fontname;
    if (fontAddition) {
        ctx.font = fontAddition + ' ' + ctx.font;
    }
    // Measure the string 
    // !!! <CRUCIAL>  !!!
    var length = ctx.measureText(txt).width;
    // !!! </CRUCIAL> !!!
    // Return width
    return length;
}

function getHeightOfText(txt, fontname, fontsize, width) {
    //// Create a dummy canvas (render invisible with css)
    //var span = document.createElement("span");
    //span.innerHTML = txt; //or whatever string you want.
    //span.style.fontFamily = fontname;
    //span.style.fontSize = fontsize;

    //return span.offsetHeight;

    var body = document.getElementsByTagName('body')[0];
    var dummy = document.createElement('div');
    //dummy.setAttribute("style","width:" + width + "px");
    var dummyText = document.createTextNode(txt);
    dummy.appendChild(dummyText);
    var fontStyle = "font-size:" + fontsize + "px;font-family:" + fontname;
    dummy.setAttribute('style', fontStyle + ';display:block; width:' + width + "px;");
    body.appendChild(dummy);
    var height = dummy.offsetHeight;
    body.removeChild(dummy);
    return height;
}

function getMaxBoundsForGroup(groupName, displayObjs) {

    var x = 99999, y = 99999, width = 0, height = 0;
    var groupPresent = false;

    displayObjs.forEach(function (displayObject) {
        if (displayObject.drawingGroupName === groupName) {
            groupPresent = true;
            var scaledCopy;
            if (displayObject.type === 'poly') {
                displayObject.pointsArray.forEach(function (point) {
                    updateMaxBounds(point);
                });
            } else if (displayObject.type === 'circle') {
                var circleRadius = scaleValue(displayObject.radius);

                scaledCopy = scalePointAsCopy(displayObject.centre);
                flipMainDrawingAreaAxisPoint(scaledCopy);
                updateMaxBounds(scaledCopy);
                updateMaxBounds({ x: scaledCopy.x + circleRadius, y: scaledCopy.y + circleRadius });
                updateMaxBounds({ x: scaledCopy.x - circleRadius, y: scaledCopy.y - circleRadius });
            } else if (displayObject.type === 'ellipse') {
                var ellipseHalfWidth = scaleValue(displayObject.halfWidth);
                var ellipseHalfHeight = scaleValue(displayObject.halfHeight);

                scaledCopy = scalePointAsCopy(displayObject.centre);
                flipMainDrawingAreaAxisPoint(scaledCopy);
                updateMaxBounds(scaledCopy);
                updateMaxBounds({ x: scaledCopy.x + ellipseHalfWidth, y: scaledCopy.y + ellipseHalfHeight });
                updateMaxBounds({ x: scaledCopy.x - ellipseHalfWidth, y: scaledCopy.y - ellipseHalfHeight });
            } else if (displayObject.type === 'arc') {
                updateMaxBounds(displayObject.centre);
            } else if (displayObject.type === 'line') {
                updateMaxBounds(displayObject.startPoint);
                updateMaxBounds(displayObject.endPoint);
            } else if (displayObject.type === 'cog') {
                //#unfinished, need to implement for cog elements

            } else if (displayObject.type === 'rect') {
                updateMaxBounds(displayObject.position);
                updateMaxBounds({ x: displayObject.position.x + displayObject.width, y: displayObject.position.y + displayObject.height });
            }
        }

    });

    if (groupPresent === true) {
        return { x: x, y: y, width: width, height: height };
    } else {
        return null;
    }


    function updateMaxBounds(point) {
        if (point.x < x) {
            x = point.x;
        }
        if (point.y < y) {
            y = point.y;
        }
        if (point.x > width) {
            width = point.x;
        }
        if (point.y > height) {
            height = point.y;
        }
    }
}



function getMainDrawingAreaGraphicsObject(groupName, groupType) {
    if (mainDrawingGraphicsObjects[groupName] === undefined) {
        //var graphicsObject = graphicsAdapter.getNewGraphicsObject();
        var containerObject, graphicsObject;
        if (groupType !== undefined && (groupType === 'COG_DIMENSION' || groupType === 'COG_GROUP_ELEMENT')) {
            graphicsObject = graphicsAdapter.getNewGraphicsObject();
        } else {
            containerObject = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, 5, 5);
            graphicsObject = containerObject.getChildAt(0);
        }


        mainDrawingGraphicsObjects[groupName] = { graphicsObject: graphicsObject, containerObject: containerObject, groupType: groupType || 'STANDARD', drawingGroupName: groupName, keep: true };
        return graphicsObject;
    } else {
        return mainDrawingGraphicsObjects[groupName].graphicsObject;
    }
}

function getDimensionLayersGraphicsObject(groupName, groupType) {
    if (dimensionLayersGraphicsObjects[groupName] === undefined) {
        var graphicsObject = graphicsAdapter.getNewGraphicsObject();

        dimensionLayersGraphicsObjects[groupName] = { graphicsObject: graphicsObject, groupType: groupType || 'STANDARD', drawingGroupName: groupName, keep: true };
        return graphicsObject;
    } else {
        return dimensionLayersGraphicsObjects[groupName].graphicsObject;
    }
}

function removeGraphicsObject(drawingGroupName) {
    var objectToRemove;
    if (mainDrawingGraphicsObjects[drawingGroupName]) {
        objectToRemove = mainDrawingGraphicsObjects[drawingGroupName];
        if (addedDimensions[drawingGroupName]) {
            delete addedDimensions[drawingGroupName];
        }
        delete mainDrawingGraphicsObjects[drawingGroupName];
        if (objectToRemove.graphicsObject) {
            objectToRemove.graphicsObject.clear();
            objectToRemove.graphicsObject.destroy(true);
            delete objectToRemove.graphicsObject;
        }
        if (objectToRemove.containerObject) {
            objectToRemove.containerObject.destroy(true);
            objectToRemove.containerObject.removeChildren();
            delete objectToRemove.containerObject;
        }
        utils.deleteObjectMembers(objectToRemove);
    } else if (dimensionLayersGraphicsObjects[drawingGroupName]) {
        objectToRemove = dimensionLayersGraphicsObjects[drawingGroupName];
        if (addedDimensions[drawingGroupName]) {
            delete addedDimensions[drawingGroupName];
        }
        delete dimensionLayersGraphicsObjects[drawingGroupName];
        if (objectToRemove.graphicsObject) {
            objectToRemove.graphicsObject.clear();
            objectToRemove.graphicsObject.destroy(true);
            delete objectToRemove.graphicsObject;
        }
        if (objectToRemove.containerObject) {
            objectToRemove.containerObject.destroy(true);
            objectToRemove.containerObject.removeChildren();
            delete objectToRemove.containerObject;
        }
        utils.deleteObjectMembers(objectToRemove);
    }
    if (hitAreaObjects[drawingGroupName]) {
        utils.deleteObjectMembers(hitAreaObjects[drawingGroupName]);
        delete hitAreaObjects[drawingGroupName];
    }
    //addHitAreas(displayObjects);
    //clearUnusedHitAreas();
}

function setupGraphicsObjects(displayObjectsMeta, dimensionsMeta) {

    displayObjectsMeta.forEach(function (displayObject) {
        if (displayObject.drawingGroupName !== undefined && displayObject.drawingGroupName !== 'NOT_SET') {
            if (mainDrawingGraphicsObjects[displayObject.drawingGroupName] === undefined) {
                if (displayObject.type === 'cog') {
                    getMainDrawingAreaGraphicsObject(displayObject.drawingGroupName, 'COG_ELEMENT')
                } else if (displayObject.type === 'cogGroup') {
                    var drawingGroupNameToUse = displayObject.linkedDrawingGroupName === undefined ? displayObject.drawingGroupName : displayObject.linkedDrawingGroupName;
                    getMainDrawingAreaGraphicsObject(drawingGroupNameToUse, 'COG_GROUP_ELEMENT')
                } else {
                    getMainDrawingAreaGraphicsObject(displayObject.drawingGroupName);
                }
            } else if (mainDrawingGraphicsObjects[displayObject.drawingGroupName] !== undefined) {
                if (mainDrawingGraphicsObjects[displayObject.drawingGroupName].groupType === 'COG_ELEMENT' && displayObject.type === 'cog') {
                    mainDrawingGraphicsObjects[displayObject.drawingGroupName].keep = true;
                } else if (mainDrawingGraphicsObjects[displayObject.drawingGroupName].groupType === 'COG_GROUP_ELEMENT' && displayObject.type === 'cogGroup') {
                    mainDrawingGraphicsObjects[displayObject.drawingGroupName].keep = true;
                } else if (mainDrawingGraphicsObjects[displayObject.drawingGroupName].groupType !== 'COG_GROUP_ELEMENT' && mainDrawingGraphicsObjects[displayObject.drawingGroupName].groupType !== 'COG_ELEMENT' && mainDrawingGraphicsObjects[displayObject.drawingGroupName].groupType !== 'COG_DIMENSION') {
                    mainDrawingGraphicsObjects[displayObject.drawingGroupName].keep = true;
                }
            } else if (mainDrawingGraphicsObjects[displayObject.name] !== undefined) {
                mainDrawingGraphicsObjects[displayObject.name].keep = true;
            }
        }
    });

    dimensionsMeta.forEach(function (dimensionObject) {
        if (dimensionObject.drawingGroupName === undefined || (dimensionObject.drawingGroupName.indexOf(config.OBJECT_TYPES.TURNING_CIRCLE) === -1)) {
            if ((dimensionObject.drawingGroupName !== undefined && dimensionObject.drawingGroupName !== 'NOT_SET')) {
                if ((mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] === undefined && dimensionLayersGraphicsObjects[dimensionObject.drawingGroupName] === undefined) ||
                    (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_GROUP_ELEMENT') ||
                    (mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType === 'COG_GROUP_ELEMENT')) {

                    if (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_GROUP_ELEMENT' && dimensionObject.type !== config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
                        removeGraphicsObject(dimensionObject.drawingGroupName);
                    } else if (mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType === 'COG_GROUP_ELEMENT' && dimensionObject.type !== config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
                        removeGraphicsObject(dimensionObject.linkedDrawingGroupName);
                    } else if (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_DEIMSNION' && (dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_LEFT &&
                        dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_RIGHT && dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_LEFT && dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_RIGHT)) {
                        removeGraphicsObject(dimensionObject.drawingGroupName);
                    } else if (mainDrawingGraphicsObjects[dimensionObject.name] !== undefined && mainDrawingGraphicsObjects[dimensionObject.name].groupType === 'COG_DEIMSNION' && (dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_LEFT &&
                        dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_RIGHT && dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_LEFT && dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_RIGHT)) {
                        removeGraphicsObject(dimensionObject.name);
                    } else if (dimensionObject.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName] !== undefined && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType !== 'COG_GROUP_ELEMENT') {
                        removeGraphicsObject(dimensionObject.linkedDrawingGroupName);
                    }

                    if (dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
                        dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
                        getMainDrawingAreaGraphicsObject(dimensionObject.drawingGroupName !== undefined && dimensionObject.drawingGroupName !== 'NOT_SET' ? dimensionObject.drawingGroupName : dimensionObject.name, 'COG_DIMENSION');
                    } else if (dimensionObject.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {

                        var drawingGroupNameToUse = dimensionObject.linkedDrawingGroupName === undefined ? dimensionObject.drawingGroupName : dimensionObject.linkedDrawingGroupName;
                        if (mainDrawingGraphicsObjects[drawingGroupNameToUse] === undefined) {
                            getMainDrawingAreaGraphicsObject(drawingGroupNameToUse, 'COG_GROUP_ELEMENT');
                        }
                    } else if (dimensionObject.type === config.DIMENSION_TYPE.FREE_ANGLE) {
                        getDimensionLayersGraphicsObject(dimensionObject.drawingGroupName, 'FREE_ANGLE_DIMENSION');
                    }else {
                        getDimensionLayersGraphicsObject(dimensionObject.drawingGroupName !== undefined && dimensionObject.drawingGroupName !== 'NOT_SET' ? dimensionObject.drawingGroupName : dimensionObject.name, 'DIMENSION');
                    }
                } else if (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] !== undefined) {
                    if ((dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
                        dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_DIMENSION') {
                        mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                    } else if (dimensionObject.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_GROUP_ELEMENT') {
                        mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                    } /*else if (dimensionLayersGraphicsObjects[dimensionObject.name] !== undefined) {
                        dimensionLayersGraphicsObjects[dimensionObject.name].keep = true;
                    }*/ else if (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_DIMENSION' && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType !== 'COG_GROUP_ELEMENT') {
                        dimensionLayersGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                    }
                    //mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                } else if (mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName] !== undefined) {
                    if ((dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
                        dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) && mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].groupType === 'COG_DIMENSION') {
                        mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].keep = true;
                    } else if (dimensionObject.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType === 'COG_GROUP_ELEMENT') {
                        mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].keep = true;
                    } /*else if (dimensionLayersGraphicsObjects[dimensionObject.name] !== undefined) {
                        dimensionLayersGraphicsObjects[dimensionObject.name].keep = true;
                    }*/ else if (mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType === 'COG_DIMENSION' && mainDrawingGraphicsObjects[dimensionObject.linkedDrawingGroupName].groupType !== 'COG_GROUP_ELEMENT') {
                        dimensionLayersGraphicsObjects[dimensionObject.linkedDrawingGroupName].keep = true;
                    }
                    //mainDrawingGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                } else if (dimensionLayersGraphicsObjects[dimensionObject.drawingGroupName] !== undefined) {
                    dimensionLayersGraphicsObjects[dimensionObject.drawingGroupName].keep = true;
                }
            } else if ((dimensionObject.name !== undefined || dimensionObject.name !== 'NOT_SET') /* && dimensionLayersGraphicsObjects[dimensionObject.name] !== undefined*/) {
                if (dimensionLayersGraphicsObjects[dimensionObject.name] === undefined && (dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_LEFT && dimensionObject.type !== config.DIMENSION_TYPE.COG_BOTTOM_RIGHT &&
                    dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_LEFT && dimensionObject.type !== config.DIMENSION_TYPE.COG_TOP_RIGHT && dimensionObject.type !== config.DIMENSION_TYPE.FREE_ANGLE)) {
                    getDimensionLayersGraphicsObject(dimensionObject.name, 'DIMENSION');
                } else if (dimensionLayersGraphicsObjects[dimensionObject.name] === undefined && dimensionObject.type === config.DIMENSION_TYPE.FREE_ANGLE) {
                    getDimensionLayersGraphicsObject(dimensionObject.name, 'FREE_ANGLE_DIMENSION');
                }else if (mainDrawingGraphicsObjects[dimensionObject.name] === undefined && (dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
                    dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_RIGHT)) {
                    getMainDrawingAreaGraphicsObject(dimensionObject.drawingGroupName !== undefined && dimensionObject.drawingGroupName !== 'NOT_SET' ? dimensionObject.drawingGroupName : dimensionObject.name, 'COG_DIMENSION');
                } else if (dimensionLayersGraphicsObjects[dimensionObject.name] !== undefined) {
                    dimensionLayersGraphicsObjects[dimensionObject.name].keep = true;
                } else if (mainDrawingGraphicsObjects[dimensionObject.name] !== undefined) {
                    mainDrawingGraphicsObjects[dimensionObject.name].keep = true;
                }
            }
        }
    });



}

function clearUnusedGraphicsObjectsAndHitAreas() {
    var graphicsObjectsToRemove = [];
    Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {

        if (mainDrawingGraphicsObjects[key].keep) {
            delete mainDrawingGraphicsObjects[key].keep;
        } else {
            graphicsObjectsToRemove.push(key);
        }
    });


    Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {

        if (dimensionLayersGraphicsObjects[key].keep) {
            delete dimensionLayersGraphicsObjects[key].keep;
        } else {
            graphicsObjectsToRemove.push(key);
        }

    });
    graphicsObjectsToRemove.forEach(function (key) {
        removeGraphicsObject(key);
    });
    utils.deleteObjectMembers(graphicsObjectsToRemove);
    graphicsObjectsToRemove = null;

    //var hitAreaObjectsToRemove = [];
    //Object.keys(hitAreaObjects).forEach(function (key) {

    //    if (hitAreaObjects[key].keep) {
    //        delete hitAreaObjects[key].keep;
    //    } else {
    //        hitAreaObjectsToRemove.push(key);
    //    }
    //});


    //hitAreaObjectsToRemove.forEach(function (key) {
    //    utils.deleteObjectMembers(hitAreaObjects[key]);
    //});
    //utils.deleteObjectMembers(hitAreaObjectsToRemove);
    //hitAreaObjectsToRemove = null;

    //clearUnusedHitAreas();
}

function clearUnusedHitAreas() {

    var hitAreaObjectsToRemove = [];
    Object.keys(hitAreaObjects).forEach(function (key) {

        if (hitAreaObjects[key].keep) {
            delete hitAreaObjects[key].keep;
        } else {
            hitAreaObjectsToRemove.push(key);
        }
    });


    hitAreaObjectsToRemove.forEach(function (key) {
        utils.deleteObjectMembers(hitAreaObjects[key]);
        hitAreaObjects[key] = undefined;
    });
    utils.deleteObjectMembers(hitAreaObjectsToRemove);
    hitAreaObjectsToRemove = null;
}

function clearGraphics() {
    //Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
    //    //mainDrawingGraphicsObjects[key].graphicsObject.children.forEach(function (child) {
    //    //    if (child.children && child.children.length > 0) {
    //    //        callDestroyOnChildren(child);
    //    //    }
    //    //});
    //    //mainDrawingGraphicsObjects[key].graphicsObject.removeChildren();
    //    mainDrawingGraphicsObjects[key].graphicsObject.clear();
    //});
    //Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {
    //    //mainDrawingGraphicsObjects[key].graphicsObject.children.forEach(function (child) {
    //    //    if (child.children && child.children.length > 0) {
    //    //        callDestroyOnChildren(child);
    //    //    }
    //    //});
    //    //mainDrawingGraphicsObjects[key].graphicsObject.removeChildren();
    //    dimensionLayersGraphicsObjects[key].graphicsObject.clear();
    //});
    graphicsAdapter.getGraphicsObject().clear();
    //callDestroyOnChildren(graphicsAdapter.getGraphicsObject());
    graphicsAdapter.getGraphicsObject().removeChildren();
    //drawingArea.tablesDrawingArea
}

//function setupDimensionLayersGraphicsObjects(dimensionsMeta) {

//    dimensionsMeta.forEach(function (dimensionObject) {
//        if (dimensionObject.drawingGroupName !== undefined && dimensionObject.drawingGroupName !== 'NOT_SET') {
//            if (mainDrawingGraphicsObjects[dimensionObject.drawingGroupName] === undefined) {
//                if (dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
//                dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimensionObject.type === config.DIMENSION_TYPE.COG_TOP_RIGHT) {
//                    getMainDrawingAreaGraphicsObject(dimensionObject.drawingGroupName, 'COG_DIMENSION')
//                } else {
//                    getDimensionLayersGraphicsObject(dimensionObject.drawingGroupName, 'DIMENSION')
//                }
//            }
//        }
//    });
//}

function addHitAreas(displayObjectsMeta) {

    var hitAreas = [];

    var i;
    for (i = 0; i < displayObjectsMeta.length; i++) {

        var tempMeta = displayObjectsMeta[i];

        //if (optionalDrawingGroup === undefined || optionalDrawingGroup !== undefined && tempMeta.drawingGroupName === optionalDrawingGroup) {

        if (tempMeta.type === 'hitArea' && hitAreaObjects[tempMeta.id] === undefined) {


            //hitAreas.push(tempMeta);
            addHitArea(tempMeta);
        } else if (tempMeta.type === 'hitArea' && hitAreaObjects[tempMeta.id] !== undefined) {
            updateHitArea(tempMeta);
            hitAreaObjects[tempMeta.id].keep = true;
        }
    }


    //if (hitAreas.length > 0) {
    //    for (i = 0; i < hitAreas.length; i++) {
    //        addHitArea(hitAreas[i]);
    //    }

    //}
}
function populateDisplayObjects(displayObjectsMeta) {
    displayObjectsMeta.forEach(function (displayObject) {
        var displayObjectCopy = JSON.parse(JSON.stringify(displayObject));
        if (displayObject.type === 'text') {
            displayObjectCopy.getDisplayText = displayObject.getDisplayText;
            displayObjectCopy.formattingFunc = displayObject.formattingFunc;
        }
        displayObjects.push(displayObjectCopy);

    });
}
function addDrawingElements(displayObjectsMeta) {
    //if (optionalDrawingGroup === undefined) {

    //} else {
    //    mainDrawingGraphicsObjects[optionalDrawingGroup].clear();
    //}

    //displayObjects = [];
    //displayObjects = JSON.parse(JSON.stringify(displayObjectsMeta));

    displayObjects = [];//JSON.parse(JSON.stringify(displayObjects));
    populateDisplayObjects(displayObjectsMeta);

    cogs = []; 
    // hitAreas = []; this may need to be commented back in? Was picked up by eslint and doesn't appear important

    var i;
    for (i = 0; i < displayObjectsMeta.length; i++) {

        var tempMeta = displayObjectsMeta[i];

        //if (optionalDrawingGroup === undefined || optionalDrawingGroup !== undefined && tempMeta.drawingGroupName === optionalDrawingGroup) {

        if (tempMeta.type === 'hitArea') {

            ////processHitArea(tempMeta);
            //processCOGDimensionHitArea(tempMeta);
            ////if (tempMeta.interaction.hitMasks !== undefined && tempMeta.interaction.hitMasks.length > 0) {
            ////    tempMeta.interaction.hitMasks.forEach(function (hitMask) {
            ////        processHitArea(hitMask);
            ////    });
            ////}



            //hitAreas.push(tempMeta);
        } else if (tempMeta.type === 'cog' || tempMeta.type === 'cogGroup') {
            applyOffsetsToCOG(tempMeta);
            cogs.push(tempMeta);

        } else {
            drawObject(tempMeta, getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName));
        }
        //if (optionalDrawingGroup === undefined || optionalDrawingGroup !== undefined && tempMeta.drawingGroupName === optionalDrawingGroup) {

        //    if (tempMeta.type === 'poly') {
        //        //scalePoly(tempMeta);
        //        //displayObjects.push(drawPolygon(tempMeta));
        //        applyOffsetsToPolygon(tempMeta);
        //        //drawPolygon(tempMeta);
        //        drawPolygon(getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName), tempMeta);
        //    } else if (tempMeta.type === 'circle') {
        //        //axles.push(tempMeta.Centre);
        //        //displayObjects.push(drawCircle(tempMeta));
        //        applyOffsetsToCircle(tempMeta);
        //        //drawCircle(graphicsAdapter.getGraphicsObject(), tempMeta, true);
        //        drawCircle(getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName), tempMeta, true);
        //    } else if (tempMeta.type === 'arc') {
        //        // need to avoid applying offsets to cab tilt arc as dashed arcs are a special case and handled differently
        //        if (tempMeta.cnoceptualGroup !== 'cabtilt') {
        //            applyOffsetsToArc(tempMeta);
        //        }
        //        //drawArc(graphicsAdapter.getGraphicsObject(), tempMeta, true);
        //        drawArc(getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName), tempMeta, true);
        //    } else if (tempMeta.type === 'line') {
        //        applyOffsetsToLine(tempMeta);
        //        //drawLine(tempMeta, true);
        //        drawLine(getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName), tempMeta, true);
        //    } else if (tempMeta.type === 'cog') {
        //        applyOffsetsToCOG(tempMeta);
        //        cogs.push(tempMeta);

        //    } else if (tempMeta.type === 'rect') {
        //        applyOffsetsToRect(tempMeta);
        //        drawRectFromRectObject(getMainDrawingAreaGraphicsObject(tempMeta.drawingGroupName), tempMeta);
        //    } else if (tempMeta.type === 'hitArea' && optionalDrawingGroup === undefined) {
        //        applyOffsetsToPolygon(tempMeta);
        //        tempMeta.pixiPointsArray = [];
        //        tempMeta.pointsArray.forEach(function (point) {
        //            scalePoint(point);
        //            flipMainDrawingAreaAxisPoint(point);
        //            tempMeta.pixiPointsArray.push(new PIXI.Point(point.x, point.y));
        //        });
        //        tempMeta.x = scaleValue(tempMeta.x);
        //        tempMeta.y = scaleValue(tempMeta.y);

        //        tempMeta.width = scaleValue(tempMeta.width);
        //        tempMeta.height = scaleValue(tempMeta.height);
        //        tempMeta.y = mainHeight - (tempMeta.y + tempMeta.height);

        //        hitAreas.push(tempMeta);
        //    }
        //}
    }

    //if (optionalDrawingGroup === undefined) {
    if (cogs.length > 0) {
        for (i = 0; i < cogs.length; i++) {
            //drawCOGElement(cogs[i]);

            //cogs[i].width = scaleValue(cogs[i].width);
            //cogs[i].height = scaleValue(cogs[i].height);

            //scalePoint(cogs[i].position)

            //drawNewCOGElement(getMainDrawingAreaGraphicsObject(cogs[i].drawingGroupName, 'COG_ELEMENT'), cogs[i], cogs[i].height, cogs[i].displayText, cogs[i].style, false);
            handleCOGElement(cogs[i]);

        }

    }
    //if (hitAreas.length > 0) {
    //    for (i = 0; i < hitAreas.length; i++) {
    //        addHitArea(hitAreas[i]);
    //    }

    //}

    addMainDrawingAreaGraphicsObjects();



    if (drawWatermarkOverlay === true) {
        globals.trialStampWatermarkIsAvailable.value = false;
        drawTrialWatermarkOverlay().then(function () { needToDoRerender = false; }).fail(function () {
            needToDoRerender = true;
            callUntilSuccessful();
        });
        
    }
    //}
    //for (var i = 0; i < displayObjects.length; i++) {
    //    drawingArea.mainDrawingArea.addChild(displayObjects[i].getDisplayObject());
    //}


    function callUntilSuccessful() {
        drawTrialWatermarkOverlay().fail(function () {
            setTimeout(function () { callUntilSuccessful(); }, 500);

        });
    }


}

function addMainDrawingAreaGraphicsObjects() {
    //drawingArea.mainDrawingArea.removeChildren();
    var childrenLength = drawingArea.mainDrawingArea.children.length;

    for (var i = childrenLength - 1; i >= 1; i--) {
        drawingArea.mainDrawingArea.removeChildAt(i);
    }
    var mainDrawingGraphicsObjectsArr = [];
    Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
        if (mainDrawingGraphicsObjects[key].groupType !== 'COG_DIMENSION' && mainDrawingGraphicsObjects[key].groupType !== 'COG_GROUP_ELEMENT') {
            mainDrawingGraphicsObjectsArr.push(mainDrawingGraphicsObjects[key]);
        }

    });

    mainDrawingGraphicsObjectsArr.sort(sortByLargestHitAreaAscendingOrSpecfiedLayer);
    mainDrawingGraphicsObjectsArr.sort(sortForeGroundItemsByLargestHitAreaAscending);

    var layerCounter = 1;
    //Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
    //    drawingArea.mainDrawingArea.addChildAt(mainDrawingGraphicsObjects[key], layerCounter++);
    //});
    mainDrawingGraphicsObjectsArr.forEach(function (mainDrawingAreaGraphic) {
        drawingArea.mainDrawingArea.addChildAt(mainDrawingAreaGraphic.containerObject, layerCounter++);
    });
    drawingArea.mainDrawingArea.addChildAt(graphicsAdapter.getGraphicsObject(), layerCounter++);



    var freeAngleGraphicsObjectsArr = [];
    Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {
        if (dimensionLayersGraphicsObjects[key].groupType === 'FREE_ANGLE_DIMENSION') {
            freeAngleGraphicsObjectsArr.push(dimensionLayersGraphicsObjects[key]);
        }

    });

    freeAngleGraphicsObjectsArr.forEach(function (freeAngleDimensionGraphicsObject) {
        drawingArea.mainDrawingArea.addChild(freeAngleDimensionGraphicsObject.graphicsObject);
    });
    
}

function sortForeGroundItemsByLargestHitAreaAscending(a, b) {
    if ((b.bringToFront || b.groupType === 'COG_ELEMENT') && (a.bringToFront || a.groupType === 'COG_ELEMENT')) {
        if ((b.containerObject.hitArea) && (a.containerObject.hitArea)) {
            return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
        } /*else if ((b.containerObject.hitArea) && (a.containerObject.hitArea)) {
                    return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
                } else if ((b.containerObject.hitArea && b.bringToFront) && (a.containerObject.hitArea && !a.bringToFront)) {
                    return -1;
                } else if ((b.containerObject.hitArea && !b.bringToFront) && (a.containerObject.hitArea && a.bringToFront)) {
                    return 1;
                }*/
    } else if ((b.bringToFront || b.groupType === 'COG_GROUP_ELEMENT') && (a.bringToFront || a.groupType === 'COG_GROUP_ELEMENT')) {
        if ((b.hitArea) && (a.hitArea)) {
            return (b.hitArea.width * b.hitArea.height) - (a.hitArea.width * a.hitArea.height);
        } /*else if ((b.containerObject.hitArea) && (a.containerObject.hitArea)) {
                    return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
                } else if ((b.containerObject.hitArea && b.bringToFront) && (a.containerObject.hitArea && !a.bringToFront)) {
                    return -1;
                } else if ((b.containerObject.hitArea && !b.bringToFront) && (a.containerObject.hitArea && a.bringToFront)) {
                    return 1;
                }*/
    }
    return 0;
}

function sortByLargestHitAreaAscendingOrSpecfiedLayer(a, b) {

    /*if (interactionState[a.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[a.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
        return -1;
    } else if (interactionState[b.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[b.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
        return -1;
    } else*/ if (a.drawingOrder || b.drawingOrder) {
        if (a.drawingOrder && !b.drawingOrder) {
            return -1;
        } else if (!a.drawingOrder && b.drawingOrder) {
            return 1;
        } else if (a.drawingOrder && b.drawingOrder) {
            return b.drawingOrder - a.drawingOrder;
        }

    } else {


        /*if (b.bypassHitAreaSort && a.bypassHitAreaSort) {
            return 0;
        } else if (b.bypassHitAreaSort && !a.bypassHitAreaSort) {
            return 1;
        } else if (!b.bypassHitAreaSort && a.bypassHitAreaSort) {
            return -1;
        } else*/
        if ((b.bringToFront || b.groupType === 'COG_ELEMENT') && (a.bringToFront || a.groupType === 'COG_ELEMENT')) {
            return 0;
        } else if ((b.bringToFront || b.groupType === 'COG_ELEMENT') && !(a.bringToFront || a.groupType === 'COG_ELEMENT')) {
            return -1;
        } else if (!(b.bringToFront || b.groupType === 'COG_ELEMENT') && (a.bringToFront || a.groupType === 'COG_ELEMENT')) {
            return 1;
        } else if ((b.bringToFront || b.groupType === 'COG_GROUP_ELEMENT') && (a.bringToFront || a.groupType === 'COG_GROUP_ELEMENT')) {
            return 0;
        } else if ((b.bringToFront || b.groupType === 'COG_GROUP_ELEMENT') && !(a.bringToFront || a.groupType === 'COG_GROUP_ELEMENT')) {
            return -1;
        } else if (!(b.bringToFront || b.groupType === 'COG_GROUP_ELEMENT') && (a.bringToFront || a.groupType === 'COG_GROUP_ELEMENT')) {
            return 1;
        } else if ((b.containerObject.hitArea) && (a.containerObject.hitArea)) {
            return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
        }/* else if ((b.containerObject.hitArea && !b.bringToFront) && (a.containerObject.hitArea && !a.bringToFront)) {
                    return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
                } else if ((b.containerObject.hitArea && b.bringToFront) && (a.containerObject.hitArea && a.bringToFront)) {
                    return (b.containerObject.hitArea.width * b.containerObject.hitArea.height) - (a.containerObject.hitArea.width * a.containerObject.hitArea.height);
                } else if ((b.containerObject.hitArea && b.bringToFront) && (a.containerObject.hitArea && !a.bringToFront)) {
                    return -1;
                } else if ((b.containerObject.hitArea && !b.bringToFront) && (a.containerObject.hitArea && a.bringToFront)) {
                    return 1;
                }*/ /*else if (b.bringToFront && a.bringToFront) {
                    return 0;
                } else if (b.bringToFront && !a.bringToFront) {
                    return -1;
                } else if (!b.bringToFront && a.bringToFront) {
                    return 1;
                }*/
    }
    return 0;
}

function sortByLargestHitAreaAscending(a, b) {
    if ((b.hitArea) && (a.hitArea)) {
        return (b.hitArea.width * b.hitArea.height) - (a.hitArea.width * a.hitArea.height);
    }
    return 0;
}

//function processHitArea(tempMeta) {
//    applyOffsetsToPolygon(tempMeta);
//    tempMeta.x = tempMeta.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
//    tempMeta.y = tempMeta.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
//    tempMeta.pixiPointsArray = [];
//    tempMeta.pointsArray.forEach(function (point) {
//        scalePoint(point);
//        flipMainDrawingAreaAxisPoint(point);
//        tempMeta.pixiPointsArray.push(new PIXI.Point(point.x, point.y));
//    });
//    tempMeta.x = scaleValue(tempMeta.x);
//    tempMeta.y = scaleValue(tempMeta.y);

//    tempMeta.width = scaleValue(tempMeta.width);
//    tempMeta.height = scaleValue(tempMeta.height);
//    tempMeta.y = mainHeight - (tempMeta.y + tempMeta.height);
//}
function processHitArea(tempMeta) {

    applyOffsetsToPolygon(tempMeta);
    tempMeta.x = tempMeta.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    tempMeta.y = tempMeta.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
    tempMeta.pixiPointsArray = [];
    tempMeta.pointsArray.forEach(function (point) {
        scalePoint(point);
        if (tempMeta.dontFlipYAxis === false) {
            flipMainDrawingAreaAxisPoint(point);
        }
        tempMeta.pixiPointsArray.push(new PIXI.Point(point.x, point.y));
    });
    tempMeta.x = scaleValue(tempMeta.x);
    tempMeta.y = scaleValue(tempMeta.y);

    tempMeta.width = scaleValue(tempMeta.width);
    tempMeta.height = scaleValue(tempMeta.height);
    if (tempMeta.dontFlipYAxis === false) {
        tempMeta.y = mainHeight - (tempMeta.y + tempMeta.height);
    }
}
function applyOffsetsToLine(lineMeta) {
    lineMeta.startPoint.x = lineMeta.startPoint.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    lineMeta.startPoint.y = lineMeta.startPoint.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
    lineMeta.endPoint.x = lineMeta.endPoint.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    lineMeta.endPoint.y = lineMeta.endPoint.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
    //also flip y axis and apply any offsets
}

function adjustOriginForLine(lineMeta, containerObject) {
    lineMeta.startPoint.x = lineMeta.startPoint.x - (containerObject.x / scalingFactor);
    lineMeta.startPoint.y = lineMeta.startPoint.y + (containerObject.y / scalingFactor);
    lineMeta.endPoint.x = lineMeta.endPoint.x - (containerObject.x / scalingFactor);
    lineMeta.endPoint.y = lineMeta.endPoint.y + (containerObject.y / scalingFactor);
}

function applyOffsetsToRect(metaRect) {
    metaRect.position.x = metaRect.position.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaRect.position.y = metaRect.position.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

function adjustOriginForRect(rectMeta, containerObject) {
    rectMeta.position.x = rectMeta.position.x - (containerObject.x / scalingFactor);
    rectMeta.position.y = rectMeta.position.y + (containerObject.y / scalingFactor);
}

function applyOffsetsToPolygon(metaPolygon) {
    metaPolygon.pointsArray.forEach(function (metaPoint) {
        metaPoint.x = metaPoint.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
        metaPoint.y = metaPoint.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
    });
}

function adjustOriginForPolygon(metaPolygon, containerObject) {
    metaPolygon.pointsArray.forEach(function (metaPoint) {
        metaPoint.x = metaPoint.x - (containerObject.x / scalingFactor);
        metaPoint.y = metaPoint.y + (containerObject.y / scalingFactor);
    });
}

function applyOffsetsToCircle(metaCircle) {
    metaCircle.centre.x = metaCircle.centre.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaCircle.centre.y = metaCircle.centre.y + (inDrawingDimensionLayerOffsetY / scalingFactor);

    if (metaCircle.circleType !== undefined && (metaCircle.circleType === "1" || metaCircle.circleType === 1)) {
        metaCircle.groundLineY = metaCircle.groundLineY + (inDrawingDimensionLayerOffsetY / scalingFactor);
    }

    //metaCircle.axlePosition.x = metaCircle.axlePosition.x + inDrawingDimensionLayerOffsetX;
    //metaCircle.axlePosition.y = metaCircle.axlePosition.y + inDrawingDimensionLayerOffsetY;
}

function adjustOriginForCircle(metaCircle, containerObject) {
    metaCircle.centre.x = metaCircle.centre.x - (containerObject.x / scalingFactor);
    metaCircle.centre.y = metaCircle.centre.y + (containerObject.y / scalingFactor);

    if (metaCircle.circleType !== undefined && (metaCircle.circleType === "1" || metaCircle.circleType === 1)) {
        metaCircle.groundLineY = metaCircle.groundLineY + (containerObject.y / scalingFactor);
    }
}

function applyOffsetsToEllipse(metaEllipse) {
    metaEllipse.centre.x = metaEllipse.centre.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaEllipse.centre.y = metaEllipse.centre.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

function adjustOriginForEllipse(metaEllipse, containerObject) {
    metaEllipse.centre.x = metaEllipse.centre.x - (containerObject.x / scalingFactor);
    metaEllipse.centre.y = metaEllipse.centre.y + (containerObject.y / scalingFactor);
}

function applyOffsetsToHitchLoad(metaHitchLoad) {
    metaHitchLoad.centre.x = metaHitchLoad.centre.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaHitchLoad.centre.y = metaHitchLoad.centre.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

function adjustOriginForHitch(metaHitchLoad, containerObject) {
    metaHitchLoad.centre.x = metaHitchLoad.centre.x - (containerObject.x / scalingFactor);
    metaHitchLoad.centre.y = metaHitchLoad.centre.y + (containerObject.y / scalingFactor);
}

function applyOffsetsToCOG(metaCOG) {
    metaCOG.position.x = metaCOG.position.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaCOG.position.y = metaCOG.position.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

//function applyOffsetsToRect(metaRect) {
//    metaRect.position.x = metaRect.position.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
//    metaRect.position.y = metaRect.position.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
//}

function applyOffsetsToArc(metaArc) {
    metaArc.centre.x = metaArc.centre.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaArc.centre.y = metaArc.centre.y + (inDrawingDimensionLayerOffsetY / scalingFactor);

    metaArc.startPoint.x = metaArc.startPoint.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaArc.startPoint.y = metaArc.startPoint.y + (inDrawingDimensionLayerOffsetY / scalingFactor);

    metaArc.endPoint.x = metaArc.endPoint.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaArc.endPoint.y = metaArc.endPoint.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

function applyOffsetsToText(metaText) {
    metaText.position.x = metaText.position.x + (inDrawingDimensionLayerOffsetX / scalingFactor);
    metaText.position.y = metaText.position.y + (inDrawingDimensionLayerOffsetY / scalingFactor);
}

function adjustOriginForText(metaText, containerObject) {
    metaText.position.x = metaText.position.x - (containerObject.x / scalingFactor);
    metaText.position.y = metaText.position.y + (containerObject.y / scalingFactor);


}

function adjustOriginForArc(metaArc, containerObject) {
    metaArc.centre.x = metaArc.centre.x - (containerObject.x / scalingFactor);
    metaArc.centre.y = metaArc.centre.y + (containerObject.y / scalingFactor);

    metaArc.startPoint.x = metaArc.startPoint.x - (containerObject.x / scalingFactor);
    metaArc.startPoint.y = metaArc.startPoint.y + (containerObject.y / scalingFactor);

    metaArc.endPoint.x = metaArc.endPoint.x - (containerObject.x / scalingFactor);
    metaArc.endPoint.y = metaArc.endPoint.y + (containerObject.y / scalingFactor);
}

function drawObject(tempMeta, graphicsObjectToUse) {




    if (tempMeta.type === 'poly') {
        //scalePoly(tempMeta);
        //displayObjects.push(drawPolygon(tempMeta));
        applyOffsetsToPolygon(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForPolygon(tempMeta, graphicsObjectToUse.parent);
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        //drawPolygon(tempMeta);
        drawPolygon(graphicsObjectToUse, tempMeta);
    } else if (tempMeta.type === 'circle') {
        //axles.push(tempMeta.Centre);
        //displayObjects.push(drawCircle(tempMeta));
        if (tempMeta.groundLineY) {
            tempMeta.originalGroundLineY = tempMeta.groundLineY;
        }

        applyOffsetsToCircle(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForCircle(tempMeta, graphicsObjectToUse.parent);
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        if (tempMeta.pulse) {
            dimensionsToPulse.push(tempMeta);
        }
        //drawCircle(graphicsAdapter.getGraphicsObject(), tempMeta, true);
        drawCircle(graphicsObjectToUse, tempMeta, true);
    } else if (tempMeta.type === 'ellipse') {

        applyOffsetsToEllipse(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForEllipse(tempMeta, graphicsObjectToUse.parent);
        }

        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        if (tempMeta.pulse) {
            dimensionsToPulse.push(tempMeta);
        }

        drawEllipse(graphicsObjectToUse, tempMeta, true);
    } else if (tempMeta.type === 'arc') {
        // need to avoid applying offsets to cab tilt arc as dashed arcs are a special case and handled differently
        //if (tempMeta.drawingGroupName !== 'cabtilt') {
        applyOffsetsToArc(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForArc(tempMeta, graphicsObjectToUse.parent);
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        //}
        //drawArc(graphicsAdapter.getGraphicsObject(), tempMeta, true);
        drawArc(graphicsObjectToUse, tempMeta, true);
    } else if (tempMeta.type === 'line') {
        var drawTheLine = true;
        applyOffsetsToLine(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined && graphicsObjectToUse.parent) {
            adjustOriginForLine(tempMeta, graphicsObjectToUse.parent);
        }
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined && mainDrawingGraphicsObjects[tempMeta.drawingGroupName].groupType === 'COG_GROUP_ELEMENT') {
            drawTheLine = false;
            if ((tempMeta.drawingGroupName !== 'NOT_SET' && (interactionState[tempMeta.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined || interactionState[tempMeta.drawingGroupName + '_' + 'PINNED'] !== undefined)) || tempMeta.drawingGroupName === 'NOT_SET') {
                drawTheLine = true;
            }

            graphicsObjectToUse = mainDrawingGraphicsObjects[tempMeta.drawingGroupName].graphicsObject;
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        //drawLine(tempMeta, true);
        if (drawTheLine) {
            drawLine(graphicsObjectToUse, tempMeta, true);
        }
    } else if (tempMeta.type === 'rect') {
        applyOffsetsToRect(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForRect(tempMeta, graphicsObjectToUse.parent);
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        drawRectFromRectObject(graphicsObjectToUse, tempMeta);
    } else if (tempMeta.type === 'text') {
        applyOffsetsToText(tempMeta);
        if (tempMeta.drawingGroupName.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1) {
            adjustOriginForText(tempMeta, graphicsObjectToUse.parent)
        }
        //if (tempMeta.bypassHitAreaSort) {
        //    mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bypassHitAreaSort = true;
        //}
        if (tempMeta.drawingOrder) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].drawingOrder = tempMeta.drawingOrder;
        }
        if (tempMeta.bringToFront) {
            mainDrawingGraphicsObjects[tempMeta.drawingGroupName].bringToFront = tempMeta.bringToFront;
        }
        drawTextObject(graphicsObjectToUse, tempMeta);
    } else if (tempMeta.type === 'hitchLoad') {
        applyOffsetsToHitchLoad(tempMeta);
        if (mainDrawingGraphicsObjects[tempMeta.drawingGroupName] !== undefined) {
            adjustOriginForHitch(tempMeta, graphicsObjectToUse.parent)
        }
        drawHitchLoadObject(graphicsObjectToUse, tempMeta);
    }
}
function adjustOrigin(tempMeta, graphicsObjectToUse) {

    if (tempMeta.type === 'poly') {
        adjustOriginForPolygon(tempMeta, graphicsObjectToUse.parent);
    } else if (tempMeta.type === 'circle') {
        adjustOriginForCircle(tempMeta, graphicsObjectToUse.parent);

    } else if (tempMeta.type === 'ellipse') {
        adjustOriginForEllipse(tempMeta, graphicsObjectToUse.parent);
    } else if (tempMeta.type === 'arc') {
        // need to avoid adjusting Origin for cab tilt arc as dashed arcs are a special case and handled differently
        if (tempMeta.drawingGroupName !== 'cabtilt') {
            adjustOriginForArc(tempMeta, graphicsObjectToUse.parent);
        }
    } else if (tempMeta.type === 'line') {
        adjustOriginForLine(tempMeta, graphicsObjectToUse.parent);
    } else if (tempMeta.type === 'rect') {
        adjustOriginForRect(tempMeta, graphicsObjectToUse.parent);
    }
}

function handleCOGElement(cogElement) {


    if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[cogElement.drawingGroupName] !== undefined) {

        //cogElement.width = itemScaleValue(cogElement.width);
        //cogElement.height = itemScaleValue(cogElement.height);
        //cogElement.width = scaleValue(cogElement.width);
        //cogElement.height = scaleValue(cogElement.height);

        //if (scalingItemOriginTranslation && (scalingItemOriginTranslation.x !== 0 || scalingItemOriginTranslation.y !== 0) && scalingItemDrawingGroupNames[objectData.drawingGroupName] === true) {
        //    translatePointBackToOrigin(scalingItemOriginTranslation, cogElement.position);
        //    itemScalePoint(cogElement.position);
        //    translatePointBackToOffset(scalingItemOriginTranslation, cogElement.position);
        //} else {
        //    itemScalePoint(cogElement.position);
        //}
    } else {
        cogElement.width = scaleValue(cogElement.width);
        cogElement.height = scaleValue(cogElement.height);


    }
    scalePoint(cogElement.position);
    var drawingGroupType;
    if (cogElement.type === 'cog') {
        drawingGroupType = 'COG_ELEMENT';
    } else {
        drawingGroupType = 'COG_GROUP_ELEMENT'
    }
    drawNewCOGElement(getMainDrawingAreaGraphicsObject(cogElement.drawingGroupName, drawingGroupType), cogElement, cogElement.height, cogElement.displayText, cogElement.style, false);
}

function addHitArea(hitAreaObj) {

    if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[hitAreaObj.id] !== undefined) {
        hitAreaObj.x = itemScaleValue(hitAreaObj.x);
        hitAreaObj.y = itemScaleValue(hitAreaObj.y);
        hitAreaObj.width = itemScaleValue(hitAreaObj.width);
        hitAreaObj.height = itemScaleValue(hitAreaObj.height);
    }

    hitAreaObjects[hitAreaObj.id] = hitAreaObj;

    processHitArea(hitAreaObj);
    hitAreaObj.keep = true;
    if (hitAreaObj.hitMasks !== undefined && hitAreaObj.hitMasks.length > 0) {
        hitAreaObj.hitMasks.forEach(function (hitMask) {
            processHitArea(hitMask);
        });
    }

    var hitAreaGraphicsObject;

    hitAreaObj.interaction.callbacks = getCallbacks(hitAreaObj.interaction, hitAreaObj.interaction.callbacks);

    if (mainDrawingGraphicsObjects[hitAreaObj.id] !== undefined) {
        if (mainDrawingGraphicsObjects[hitAreaObj.id].containerObject) {
            hitAreaGraphicsObject = mainDrawingGraphicsObjects[hitAreaObj.id].containerObject;
            hitAreaGraphicsObject.x = hitAreaObj.x;
            hitAreaGraphicsObject.y = hitAreaObj.y;
            hitAreaGraphicsObject.hitArea = graphicsAdapter.getRect(0, 0, hitAreaObj.width, hitAreaObj.height);
            //hitAreaGraphicsObject.getChildAt(0).clear().lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(0, 0, hitAreaObj.width, hitAreaObj.height).endFill();
        } else {
            hitAreaGraphicsObject = mainDrawingGraphicsObjects[hitAreaObj.id].graphicsObject;
            //hitAreaGraphicsObject.x = hitAreaObj.x;
            //hitAreaGraphicsObject.y = hitAreaObj.y;
            if (hitAreaObj.hitMasks === undefined) {
                hitAreaGraphicsObject.hitArea = graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
            }
            //hitAreaGraphicsObject.lineStyle(1, config.DRAWING_COLOURS.BLUE).beginFill(config.DRAWING_COLOURS.BLUE).drawRect(hitAreaObj.x, hitAreaObj.y - 5, hitAreaObj.width, hitAreaObj.height + 10).endFill();
            //var counter = 0;
            //var blueShown = false;
            //var colourCounter = 1;
            if (hitAreaObj.hitMasks) {
                var childGraphicsObjects = [];
                hitAreaObj.hitMasks.forEach(function (hitMask) {
                    //processCOGDimensionHitArea(hitMask);
                    var childGraphicsObj = graphicsAdapter.getNewGraphicsObject();
                    hitMaskGraphicObjects[hitMask.id] = childGraphicsObj;
                    childGraphicsObj.interactive = true;
                    childGraphicsObj.buttonMode = true;
                    childGraphicsObj.hitArea = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                    if (hitAreaObj.interaction.callbacks.mouseover !== undefined && typeof hitAreaObj.interaction.callbacks.mouseover === 'function') {
                        childGraphicsObj.mouseover = hitAreaObj.interaction.callbacks.mouseover;
                        //childGraphicsObj.mouseover = function (data) {
                        //    var breakHere = 0;
                        //    data.stopPropagation();
                        //    data.data.originalEvent.stopImmediatePropagation();
                        //};
                    }
                    if (hitAreaObj.interaction.callbacks.click !== undefined && typeof hitAreaObj.interaction.callbacks.click === 'function') {
                        childGraphicsObj.click = hitAreaObj.interaction.callbacks.click;
                    }
                    if (hitAreaObj.interaction.callbacks.mousemove !== undefined && typeof hitAreaObj.interaction.callbacks.mousemove === 'function') {
                        childGraphicsObj.mousemove = hitAreaObj.interaction.callbacks.mousemove;
                    }
                    if (hitAreaObj.interaction.callbacks.mouseout !== undefined && typeof hitAreaObj.interaction.callbacks.mouseout === 'function') {
                        childGraphicsObj.mouseout = hitAreaObj.interaction.callbacks.mouseout;
                    }

                    childGraphicsObjects.push(childGraphicsObj);
                    //hitAreaGraphicsObject.addChild(childGraphicsObj);
                    //if (colourCounter === 1) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.BLUE).beginFill(config.DRAWING_COLOURS.BLUE).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //} else if (colourCounter === 2) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //} else if (colourCounter === 3) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.GREEN).beginFill(config.DRAWING_COLOURS.GREEN).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //}
                    //colourCounter++;
                });
                childGraphicsObjects.sort(sortByLargestHitAreaAscending);

                childGraphicsObjects.forEach(function (childGraphicsObj) {
                    hitAreaGraphicsObject.addChild(childGraphicsObj);



                });
            }
        }

        //if (hitAreaObj.hitMasks === undefined) {
        hitAreaGraphicsObject.interactive = true;
        if (hitAreaObj.draggable) {
            addDragSupport(hitAreaObj, hitAreaGraphicsObject);
            hitAreaGraphicsObject.cursor = "move";
        } else {
            hitAreaGraphicsObject.buttonMode = true;
        }
        //}



        //.;
    } else {
        hitAreaGraphicsObject = dimensionLayersGraphicsObjects[hitAreaObj.id].graphicsObject;
        hitAreaGraphicsObject.tempFlag = true;
        hitAreaGraphicsObject.hitArea = graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
        //hitAreaGraphicsObject.lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height).endFill();
        hitAreaGraphicsObject.interactive = true;
        hitAreaGraphicsObject.buttonMode = true;
    }

    //////hitAreaGraphicsObject.beginFill(0x0000FF, 0.2);
    //mainDrawingGraphicsObjects[hitAreaObj.id].graphicsObject.drawPolygon(hitAreaObj.pixiPointsArray);
    //////hitAreaGraphicsObject.drawRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
    //new PIXI.PoldrawRect(maxBoundsCrane.x, maxBoundsCrane.y, maxBoundsCrane.width - maxBoundsCrane.x, maxBoundsCrane.height - maxBoundsCrane.y);
    //mainDrawingGraphicsObjects[hitAreaObj.id].hitArea = graphicsAdapter.getPolygon(hitAreaObj.pixiPointsArray);
    //mainDrawingGraphicsObjects[hitAreaObj.id].hitArea = graphicsAdapter.drawPolygon(hitAreaObj.pixiPointsArray);
    //////hitAreaGraphicsObject.endFill();



    if (hitAreaObj.interaction.callbacks !== undefined) {

        if (hitAreaObj.interaction.callbacks.mouseover !== undefined && typeof hitAreaObj.interaction.callbacks.mouseover === 'function') {
            hitAreaGraphicsObject.mouseover = hitAreaObj.interaction.callbacks.mouseover;
        }
        if (hitAreaObj.interaction.callbacks.click !== undefined && typeof hitAreaObj.interaction.callbacks.click === 'function') {
            hitAreaGraphicsObject.click = hitAreaObj.interaction.callbacks.click;
            hitAreaGraphicsObject.tap = hitAreaObj.interaction.callbacks.click;
        }
        if (hitAreaObj.interaction.callbacks.mouseout !== undefined && typeof hitAreaObj.interaction.callbacks.mouseout === 'function') {
            hitAreaGraphicsObject.mouseout = hitAreaObj.interaction.callbacks.mouseout;
        }
        if (hitAreaObj.interaction.callbacks.mousemove !== undefined && typeof hitAreaObj.interaction.callbacks.mousemove === 'function') {
            hitAreaGraphicsObject.mousemove = hitAreaObj.interaction.callbacks.mousemove;
        }
    }

    //mainDrawingGraphicsObjects[hitAreaObj.id].mouseover = function () {
    //    mainDrawingGraphicsObjects[hitAreaObj.id].beginFill(0x00FF00, 0.2);
    //    //mainDrawingGraphicsObjects[hitAreaObj.id].drawPolygon(hitAreaObj.pixiPointsArray);
    //    mainDrawingGraphicsObjects[hitAreaObj.id].drawRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
    //    //mainDrawingGraphicsObjects[hitAreaObj.id].hitArea = graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
    //    mainDrawingGraphicsObjects[hitAreaObj.id].endFill();
    //    renderer.render(drawingArea.drawingArea)
    //};

    //mainDrawingGraphicsObjects[hitAreaObj.id].mouseout = function () {
    //    mainDrawingGraphicsObjects[hitAreaObj.id].beginFill(0x0000FF, 0.2);
    //    //mainDrawingGraphicsObjects[hitAreaObj.id].drawPolygon(hitAreaObj.pixiPointsArray);
    //    mainDrawingGraphicsObjects[hitAreaObj.id].drawRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
    //    //mainDrawingGraphicsObjects[hitAreaObj.id].hitArea = graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
    //    mainDrawingGraphicsObjects[hitAreaObj.id].endFill();
    //    renderer.render(drawingArea.drawingArea)
    //};

    //var maxBoundsCrane = getMaxBoundsForGroup(config.ACCESSORY_TYPES.CRANE, displayObjectsMeta);
    //if (maxBoundsCrane !== null) {
    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].beginFill(0x0000FF, 0.2);
    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].drawRect(maxBoundsCrane.x, maxBoundsCrane.y, maxBoundsCrane.width - maxBoundsCrane.x, maxBoundsCrane.height - maxBoundsCrane.y);
    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].endFill();
    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].interactive = true;
    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].mouseover = function () {
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].beginFill(0x00FF00, 0.2);
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].drawRect(maxBoundsCrane.x, maxBoundsCrane.y, maxBoundsCrane.width - maxBoundsCrane.x, maxBoundsCrane.height - maxBoundsCrane.y);
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].endFill();
    //        renderer.render(drawingArea.drawingArea)
    //    };

    //    mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].mouseout = function () {
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].beginFill(0x0000FF, 0.2);
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].drawRect(maxBoundsCrane.x, maxBoundsCrane.y, maxBoundsCrane.width - maxBoundsCrane.x, maxBoundsCrane.height - maxBoundsCrane.y);
    //        mainDrawingGraphicsObjects[config.ACCESSORY_TYPES.CRANE].endFill();
    //        renderer.render(drawingArea.drawingArea)
    //    };
    //}
}

function updateHitArea(hitAreaObj) {

    if (scalingItemDrawingGroupNames !== undefined && scalingItemDrawingGroupNames !== null && scalingItemDrawingGroupNames[hitAreaObj.id] !== undefined) {
        hitAreaObj.x = itemScaleValue(hitAreaObj.x);
        hitAreaObj.y = itemScaleValue(hitAreaObj.y);
        hitAreaObj.width = itemScaleValue(hitAreaObj.width);
        hitAreaObj.height = itemScaleValue(hitAreaObj.height);
    }
    processHitArea(hitAreaObj);

    updateHitAreaValues(hitAreaObjects[hitAreaObj.id], hitAreaObj);


    if (hitAreaObj.hitMasks !== undefined && hitAreaObj.hitMasks.length > 0) {
        hitAreaObj.hitMasks.forEach(function (hitMask, index) {
            processHitArea(hitMask);
            if (hitAreaObjects[hitAreaObj.id].hitMasks[index]) {
                updateHitAreaValues(hitAreaObjects[hitAreaObj.id].hitMasks[index], hitMask);
            } else {
                var childGraphicsObj = graphicsAdapter.getNewGraphicsObject();
                hitMaskGraphicObjects[hitMask.id] = childGraphicsObj;
                childGraphicsObj.interactive = true;
                childGraphicsObj.buttonMode = true;
                childGraphicsObj.hitArea = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                if (hitAreaObj.interaction.callbacks.mouseover !== undefined && typeof hitAreaObj.interaction.callbacks.mouseover === 'function') {
                    childGraphicsObj.mouseover = hitAreaObj.interaction.callbacks.mouseover;
                    //childGraphicsObj.mouseover = function (data) {
                    //    var breakHere = 0;
                    //    data.stopPropagation();
                    //    data.data.originalEvent.stopImmediatePropagation();
                    //};
                }
                if (hitAreaObj.interaction.callbacks.click !== undefined && typeof hitAreaObj.interaction.callbacks.click === 'function') {
                    childGraphicsObj.click = hitAreaObj.interaction.callbacks.click;
                }
                if (hitAreaObj.interaction.callbacks.mousemove !== undefined && typeof hitAreaObj.interaction.callbacks.mousemove === 'function') {
                    childGraphicsObj.mousemove = hitAreaObj.interaction.callbacks.mousemove;
                }
                if (hitAreaObj.interaction.callbacks.mouseout !== undefined && typeof hitAreaObj.interaction.callbacks.mouseout === 'function') {
                    childGraphicsObj.mouseout = hitAreaObj.interaction.callbacks.mouseout;
                }
                mainDrawingGraphicsObjects[hitAreaObj.id].graphicsObject.children[0].addChild(childGraphicsObj);
                //childGraphicsObjects.push(childGraphicsObj);
                //hitAreaGraphicsObject.addChild(childGraphicsObj);
                //if (colourCounter === 1) {
                //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.BLUE).beginFill(config.DRAWING_COLOURS.BLUE).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                //} else if (colourCounter === 2) {
                //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                //} else if (colourCounter === 3) {
                //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.GREEN).beginFill(config.DRAWING_COLOURS.GREEN).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                //}
                //colourCounter++;

                hitAreaObjects[hitAreaObj.id].hitMasks.push(hitMask);
            }

        });
    }
    hitAreaObj = hitAreaObjects[hitAreaObj.id];
    hitAreaObj.keep = true;
    var hitAreaGraphicsObject;

    //hitAreaObj.interaction.callbacks = getCallbacks(hitAreaObj.interaction, hitAreaObj.interaction.callbacks);

    if (mainDrawingGraphicsObjects[hitAreaObj.id] !== undefined) {
        if (mainDrawingGraphicsObjects[hitAreaObj.id].containerObject) {
            hitAreaGraphicsObject = mainDrawingGraphicsObjects[hitAreaObj.id].containerObject;
            hitAreaGraphicsObject.x = hitAreaObj.x;
            hitAreaGraphicsObject.y = hitAreaObj.y;
            hitAreaGraphicsObject.hitArea.width = hitAreaObj.width; //graphicsAdapter.getRect(0, 0, hitAreaObj.width, hitAreaObj.height);
            hitAreaGraphicsObject.hitArea.height = hitAreaObj.height;

        } else {
            hitAreaGraphicsObject = mainDrawingGraphicsObjects[hitAreaObj.id].graphicsObject;

            if (hitAreaObj.hitMasks === undefined) {

                hitAreaGraphicsObject.hitArea.x = hitAreaObj.x; //graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
                hitAreaGraphicsObject.hitArea.y = hitAreaObj.y;
                hitAreaGraphicsObject.hitArea.width = hitAreaObj.width;
                hitAreaGraphicsObject.hitArea.height = hitAreaObj.height;
            }

            if (hitAreaObj.hitMasks) {
                var childGraphicsObjects = [];
                hitAreaGraphicsObject.removeChildren();
                hitAreaObj.hitMasks.forEach(function (hitMask) {
                    var childGraphicsObj = hitMaskGraphicObjects[hitMask.id];
                    childGraphicsObj.hitArea.x = hitMask.x;
                    childGraphicsObj.hitArea.y = hitMask.y;
                    childGraphicsObj.hitArea.width = hitMask.width;
                    childGraphicsObj.hitArea.height = hitMask.height;
                    //if (hitMaskGraphicObjects[hitMask.id]) {

                    //} else {
                    //    childGraphicsObj = graphicsAdapter.getNewGraphicsObject();
                    //    childGraphicsObj.interactive = true;
                    //    childGraphicsObj.buttonMode = true;
                    //    childGraphicsObj.hitArea = graphicsAdapter.getRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height);
                    //}


                    //if (hitAreaObj.interaction.callbacks.mouseover !== undefined && typeof hitAreaObj.interaction.callbacks.mouseover === 'function') {
                    //    childGraphicsObj.mouseover = hitAreaObj.interaction.callbacks.mouseover;
                    //    //childGraphicsObj.mouseover = function (data) {
                    //    //    var breakHere = 0;
                    //    //    data.stopPropagation();
                    //    //    data.data.originalEvent.stopImmediatePropagation();
                    //    //};
                    //}
                    //if (hitAreaObj.interaction.callbacks.click !== undefined && typeof hitAreaObj.interaction.callbacks.click === 'function') {
                    //    childGraphicsObj.click = hitAreaObj.interaction.callbacks.click;
                    //}
                    //if (hitAreaObj.interaction.callbacks.mousemove !== undefined && typeof hitAreaObj.interaction.callbacks.mousemove === 'function') {
                    //    childGraphicsObj.mousemove = hitAreaObj.interaction.callbacks.mousemove;
                    //}
                    //if (hitAreaObj.interaction.callbacks.mouseout !== undefined && typeof hitAreaObj.interaction.callbacks.mouseout === 'function') {
                    //    childGraphicsObj.mouseout = hitAreaObj.interaction.callbacks.mouseout;
                    //}

                    childGraphicsObjects.push(childGraphicsObj);
                    //hitAreaGraphicsObject.addChild(childGraphicsObj);
                    //if (colourCounter === 1) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.BLUE).beginFill(config.DRAWING_COLOURS.BLUE).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //} else if (colourCounter === 2) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //} else if (colourCounter === 3) {
                    //    childGraphicsObj.lineStyle(1, config.DRAWING_COLOURS.GREEN).beginFill(config.DRAWING_COLOURS.GREEN).drawRect(hitMask.x, hitMask.y, hitMask.width, hitMask.height).endFill();
                    //}
                    //colourCounter++;
                });
                childGraphicsObjects.sort(sortByLargestHitAreaAscending);

                childGraphicsObjects.forEach(function (childGraphicsObj) {
                    hitAreaGraphicsObject.addChild(childGraphicsObj);



                });
            }
        }


        //.;
    } else {
        hitAreaGraphicsObject = dimensionLayersGraphicsObjects[hitAreaObj.id].graphicsObject;
        hitAreaGraphicsObject.hitArea.x = hitAreaObj.x; //= graphicsAdapter.getRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height);
        hitAreaGraphicsObject.hitArea.y = hitAreaObj.y;
        //hitAreaGraphicsObject.lineStyle(1, config.DRAWING_COLOURS.RED).beginFill(config.DRAWING_COLOURS.RED).drawRect(hitAreaObj.x, hitAreaObj.y, hitAreaObj.width, hitAreaObj.height).endFill();
        hitAreaGraphicsObject.hitArea.width = hitAreaObj.width;
        hitAreaGraphicsObject.hitArea.height = hitAreaObj.height;
    }

    //if (hitAreaObj.interaction.callbacks !== undefined) {

    //    if (hitAreaObj.interaction.callbacks.mouseover !== undefined && typeof hitAreaObj.interaction.callbacks.mouseover === 'function') {
    //        hitAreaGraphicsObject.mouseover = hitAreaObj.interaction.callbacks.mouseover;
    //    }
    //    if (hitAreaObj.interaction.callbacks.click !== undefined && typeof hitAreaObj.interaction.callbacks.click === 'function') {
    //        hitAreaGraphicsObject.click = hitAreaObj.interaction.callbacks.click;
    //        hitAreaGraphicsObject.tap = hitAreaObj.interaction.callbacks.click;
    //    }
    //    if (hitAreaObj.interaction.callbacks.mouseout !== undefined && typeof hitAreaObj.interaction.callbacks.mouseout === 'function') {
    //        hitAreaGraphicsObject.mouseout = hitAreaObj.interaction.callbacks.mouseout;
    //    }
    //    if (hitAreaObj.interaction.callbacks.mousemove !== undefined && typeof hitAreaObj.interaction.callbacks.mousemove === 'function') {
    //        hitAreaGraphicsObject.mousemove = hitAreaObj.interaction.callbacks.mousemove;
    //    }
    //}
    function updateHitAreaValues(curHitArea, newHitArea) {
        curHitArea.height = newHitArea.height;
        curHitArea.width = newHitArea.width;
        curHitArea.x = newHitArea.x;
        curHitArea.y = newHitArea.y;
        curHitArea.pointsArray = newHitArea.pointsArray;
        curHitArea.pixiPointsArray = newHitArea.pixiPointsArray;
        if (newHitArea.draggable && curHitArea.draggable) {
            Object.keys(curHitArea.draggable.dragInfoObjects).forEach(function (curKey) {
                Object.keys(newHitArea.draggable.dragInfoObjects).forEach(function (newKey) {
                    if (curHitArea.draggable.dragInfoObjects[curKey].path === newHitArea.draggable.dragInfoObjects[newKey].path && !curHitArea.draggable.dragInfoObjects[curKey].matchFound) {
                        curHitArea.draggable.dragInfoObjects[curKey].drawingOffset = newHitArea.draggable.dragInfoObjects[newKey].drawingOffset;
                        curHitArea.draggable.dragInfoObjects[curKey].refPoint = newHitArea.draggable.dragInfoObjects[newKey].refPoint;
                        curHitArea.draggable.dragInfoObjects[curKey].portionOverhangingFront = newHitArea.draggable.dragInfoObjects[newKey].portionOverhangingFront;
                        curHitArea.draggable.dragInfoObjects[curKey].portionOverhangingLeft = newHitArea.draggable.dragInfoObjects[newKey].portionOverhangingLeft;
                        curHitArea.draggable.dragInfoObjects[curKey].value = newHitArea.draggable.dragInfoObjects[newKey].value;
                        curHitArea.draggable.dragInfoObjects[curKey].movementIncrement = newHitArea.draggable.dragInfoObjects[newKey].movementIncrement;
                        curHitArea.draggable.dragInfoObjects[curKey].unitConversion = newHitArea.draggable.dragInfoObjects[newKey].unitConversion;
                        newHitArea.draggable.dragInfoObjects[newKey].processedInUpdate = true;
                        curHitArea.draggable.dragInfoObjects[curKey].matchFound = true;
                    } /*else {
                        curHitArea.draggable.dragInfoObjects[key] = null;
                        delete curHitArea.draggable.dragInfoObjects[key];
                    }*/
                    curHitArea.draggable.dragInfoObjects[curKey].dragValue = undefined;
                });
            });
            var indecesToRemove = [];
            Object.keys(curHitArea.draggable.dragInfoObjects).forEach(function (key) {
                if (curHitArea.draggable.dragInfoObjects[key].matchFound) {
                    delete curHitArea.draggable.dragInfoObjects[key].matchFound;
                } else {
                    indecesToRemove.push(key);
                    //curHitArea.draggable.dragInfoObjects[key] = null;
                    //curHitArea.draggable.dragInfoObjects.splice(index, 1);
                }
            });
            if (indecesToRemove.length > 0) {
                for (var i = indecesToRemove.length - 1; i >= 0; i--) {
                    curHitArea.draggable.dragInfoObjects[indecesToRemove[i]] = null;
                    curHitArea.draggable.dragInfoObjects.splice(indecesToRemove[i], 1);
                }
            }
            Object.keys(newHitArea.draggable.dragInfoObjects).forEach(function (key) {
                if (!newHitArea.draggable.dragInfoObjects[key].processedInUpdate) {
                    curHitArea.draggable.dragInfoObjects.push(newHitArea.draggable.dragInfoObjects[key]);
                    newHitArea.draggable.dragInfoObjects[key].processedInUpdate = true;
                }
            });
        }
    }
}

function addDragSupport(hitAreaObj, container) {


    hitAreaObj.draggable.callbacks = getDraggableCallbacks(hitAreaObj.draggable, container, hitAreaObj.draggable.callbacks);

    //if (hitAreaObj.draggable.callbacks !== undefined) {

    //    if (hitAreaObj.draggable.callbacks.dragStart !== undefined && typeof hitAreaObj.draggable.callbacks.dragStart === 'function') {
    //        //container.pointerdown = hitAreaObj.draggable.callbacks.dragStart;
    //        container.on('pointerdown', hitAreaObj.draggable.callbacks.dragStart);
    //    }
    //    if (hitAreaObj.draggable.callbacks.dragEnd !== undefined && typeof hitAreaObj.draggable.callbacks.dragEnd === 'function') {
    //        //container.pointerup = hitAreaObj.interaction.callbacks.dragEnd;
    //        container.on('pointerup', hitAreaObj.interaction.callbacks.dragEnd);
    //        //container.pointerupoutside = hitAreaObj.interaction.callbacks.dragEnd;
    //        container.on('pointerupoutside', hitAreaObj.interaction.callbacks.dragEnd);
    //    }
    //    if (hitAreaObj.draggable.callbacks.dragMove !== undefined && typeof hitAreaObj.draggable.callbacks.dragMove === 'function') {
    //        //container.pointermove = hitAreaObj.interaction.callbacks.dragMove;
    //        container.on('pointermove', hitAreaObj.interaction.callbacks.dragMove);
    //    }
    //}

    hitAreaObj.pointerRefs = {};
    //hitAreaObj.pointerRefs['pointerdown'] = onDragStart.bind(container, event, hitAreaObj);
    //hitAreaObj.pointerRefs['pointerup'] = onDragEnd.bind(container, event, hitAreaObj);
    ////hitAreaObj.pointerRefs['pointerupoutside'] = hitAreaObj.pointerRefs['pointerup'];
    //hitAreaObj.pointerRefs['pointermove'] = onDragMove.call(container, event, hitAreaObj);

    hitAreaObj.pointerRefs['pointerdown'] = function (event) { onDragStart.call(container, event, hitAreaObj); };
    hitAreaObj.pointerRefs['pointerup'] = function (event) { onDragEnd.call(container, event, hitAreaObj); };
    //hitAreaObj.pointerRefs['pointerupoutside'] = hitAreaObj.pointerRefs['pointerup'];
    hitAreaObj.pointerRefs['pointermove'] = function (event) { onDragMove.call(container, event, hitAreaObj); };

    container
        .on('pointerdown', hitAreaObj.pointerRefs['pointerdown'])
        .on('pointerup', hitAreaObj.pointerRefs['pointerup'])
        .on('pointerupoutside', hitAreaObj.pointerRefs['pointerup'])
        .on('pointermove', hitAreaObj.pointerRefs['pointermove']);




}
function onDragStart(event, hitAreaObj) {
    if (canDrag) {
        this.data = event.data;
        //this.alpha = 0.5;

        //this.children[0].lineColour = config.DRAWING_COLOURS.ORANGE;

        lastPosition = null;
        firstPosition = this.data.getLocalPosition(this.parent);
        lastPosition = new PIXI.Point();
        lastPosition.copy(firstPosition);
        dragStartDelayTimerRef = setTimeout(function () {
            currentDragItemElementId = hitAreaObj.draggable.elementId;
            this.dragging = true;
            dragging = true;
            doRender = true;

            if (hitAreaObj.draggable.dragInfoObjects[0].showDraggingReferenceLines) {
                makeOtherObjectsSemiTransparent(hitAreaObj.draggable.elementId);
            }
            hitAreaObj.draggable.callbacks.dragStart();
            curDraggableInteractionObject = hitAreaObj.draggable;
            //makeOtherObjectsSemiTransparent(hitAreaObj.draggable.elementId);

            if (curDraggableInteractionObject.dragInfoObjects[0].showDraggingReferenceLines) {
                drawDraggingReferenceLines();
            }


        }.bind(this), dragDelayPeriod);
    }
}

function onDragEnd(event, hitAreaObj) {
    if (this.dragging === true) {
        currentDragItemElementId = null;
        var eventData = event.data.originalEvent;
        event.stopPropagation();
        event.data.originalEvent.stopImmediatePropagation();


        this.alpha = 1;
        setTimeout(function () {
            dragging = false;
            if (needToDoRerender) {
                needToDoRerender = false;
                //if (renderer !== undefined && renderer !== null) {
                //    renderer.render(drawingArea.drawingArea);
                //}
                refreshDrawingGroup();
            }
        }.bind(this), 500);
        this.dragging = false;

        // set the interaction data to null

        doRender = false;
        if (hitAreaObj.draggable.dragInfoObjects[0].showDraggingReferenceLines) {
            makeOtherObjectsFullyOpaque(hitAreaObj.draggable.elementId);
        }
        //if (this.data && !(this.data.getLocalPosition(this.parent).x - firstPosition.x < 1 && this.data.getLocalPosition(this.parent).x - firstPosition.x > -1) && !(this.data.getLocalPosition(this.parent).y - firstPosition.y < 1 && this.data.getLocalPosition(this.parent).y - firstPosition.y > -1)) {

        hitAreaObj.draggable.callbacks.dragEnd(hitAreaObj);


        //} else {
        //    event.stopPropagation();
        //    if (curDraggableInteractionObject !== null) {
        //        var parentGraphicsObjectToUse = getMainDrawingAreaGraphicsObject(curDraggableInteractionObject.elementId);
        //        parentGraphicsObjectToUse.removeChild(refLinesContainer);
        //        //hitAreaObj.draggable.callbacks.dragEnd(true);
        //        renderer.render(drawingArea.drawingArea);
        //    }
        //}
        if (curDraggableInteractionObject !== null) {
            var parentGraphicsObjectToUse = getMainDrawingAreaGraphicsObject(curDraggableInteractionObject.elementId);
            parentGraphicsObjectToUse.removeChild(refLinesContainer);
            if (refLinesContainer && refLinesContainer.children && refLinesContainer.children.length > 0) {
                refLinesContainer.getChildAt(0).children.forEach(function (child) {
                    if (child.children && child.children.length > 0) {
                        callDestroyOnChildren(child);
                    }
                });
                refLinesContainer.getChildAt(0).removeChildren();
                refLinesContainer.getChildAt(0).clear();
            }
            refLinesContainer = null;
            //hitAreaObj.draggable.callbacks.dragEnd(true);
            renderer.render(drawingArea.drawingArea);
        }
        this.data = null;
    } else {
        if (dragStartDelayTimerRef) {
            clearTimeout(dragStartDelayTimerRef);
            dragStartDelayTimerRef = null;
        }
    }

}

function cleanUpDrag() {
    this.alpha = 1;
    this.dragging = false;
    dragging = false;
    // set the interaction data to null
    this.data = null;
    doRender = false;
}

function onDragMove(event, hitAreaObj) {
    if (this.dragging) {

        var increment = hitAreaObj.draggable.dragInfoObjects[0].movementIncrement;
        var scaledIncrement = increment * scalingFactor;

        var newPosition = this.data.getLocalPosition(this.parent);
        if (lastPosition !== null) {

            switch (getAllowedDirectionOfMovementForDragabble(hitAreaObj.draggable)) {
                case config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL:
                    updateXAxisPosition(this);
                    refresh();
                    break;
                case config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL:
                    updateYAxisPosition(this);
                    refresh();
                    break;
                case config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL_AND_VERTICAL:

                    var lockedPlane = getLockedPlane();

                    if (lockedPlane === 'horizontal') {
                        newPosition.y = firstPosition.y;
                        lastPosition.y = firstPosition.y;
                    } else if (lockedPlane === 'vertical') {
                        newPosition.x = firstPosition.x;
                        lastPosition.x = firstPosition.x;
                    }

                    if (lockedPlane === 'horizontal' || lockedPlane === 'none') {
                        updateXAxisPosition(this);
                    }
                    if (lockedPlane === 'vertical' || lockedPlane === 'none') {
                        updateYAxisPosition(this);
                    }

                    refresh(lockedPlane);
                    break;
            }

        } else {
            lastPosition = new PIXI.Point(0, 0);
            refresh();
        }
    }
    function getLockedPlane() {

        if (hitAreaObj.draggable.dragInfoObjects[0].lockPlane === false && hitAreaObj.draggable.dragInfoObjects[1].lockPlane === false) {
            return 'none';
        }

        if (hitAreaObj.draggable.dragInfoObjects[0].isLocked) {
            return 'horizontal';
        }
        if (hitAreaObj.draggable.dragInfoObjects[1].isLocked) {
            return 'vertical';
        }

        var diffHorizontal = removeSign((newPosition.x - lastPosition.x));
        var diffVertical = removeSign((newPosition.y - lastPosition.y));

        //if (diffHorizontal >= diffVertical) {
        //    hitAreaObj.draggable.dragInfoObjects[0].isLocked = true;
        //    hitAreaObj.draggable.dragInfoObjects[1].isLockedOut = true;
        //    return 'horizontal';
        //} else {
        //    hitAreaObj.draggable.dragInfoObjects[1].isLocked = true;
        //    hitAreaObj.draggable.dragInfoObjects[0].isLockedOut = true;
        //    return 'vertical';
        //}
        if (diffHorizontal >= diffVertical) {
            var horizontalDiffMag = diffHorizontal - diffVertical;
            if (horizontalDiffMag > (hitAreaObj.draggable.dragInfoObjects[0].movementIncrement * scalingFactor)) {
                hitAreaObj.draggable.dragInfoObjects[0].isLocked = true;
                hitAreaObj.draggable.dragInfoObjects[1].isLockedOut = true;
                return 'horizontal';
            } else {
                return 'none';
            }

        } else {
            var verticalDiffMag = diffVertical - diffHorizontal;
            if (verticalDiffMag > (hitAreaObj.draggable.dragInfoObjects[1].movementIncrement * scalingFactor)) {
                hitAreaObj.draggable.dragInfoObjects[1].isLocked = true;
                hitAreaObj.draggable.dragInfoObjects[0].isLockedOut = true;
                return 'vertical';
            } else {
                return 'none';
            }
        }

    }
    function updateXAxisPosition(container) {



        if (newPosition.x < lastPosition.x) {
            container.x -= removeSign((newPosition.x - lastPosition.x) - ((newPosition.x - lastPosition.x) % scaledIncrement));
        } else {
            container.x += (newPosition.x - lastPosition.x) - ((newPosition.x - lastPosition.x) % scaledIncrement);
        }
    }
    function updateYAxisPosition(container) {
        if (newPosition.y < lastPosition.y) {
            container.y -= removeSign((newPosition.y - lastPosition.y) - ((newPosition.y - lastPosition.y) % scaledIncrement));
        } else {
            container.y += (newPosition.y - lastPosition.y) - ((newPosition.y - lastPosition.y) % scaledIncrement);
        }
    }

    function refresh() {



        if (newPosition.x < lastPosition.x) {
            lastPosition.x -= removeSign((newPosition.x - lastPosition.x) - ((newPosition.x - lastPosition.x) % scaledIncrement));
        } else {
            lastPosition.x += (newPosition.x - lastPosition.x) - ((newPosition.x - lastPosition.x) % scaledIncrement);
        }

        if (newPosition.y < lastPosition.y) {
            lastPosition.y -= removeSign((newPosition.y - lastPosition.y) - ((newPosition.y - lastPosition.y) % scaledIncrement));
        } else {
            lastPosition.y += (newPosition.y - lastPosition.y) - ((newPosition.y - lastPosition.y) % scaledIncrement);
        }

        if (hitAreaObj.draggable.dragInfoObjects[0].showDraggingReferenceLines) {
            drawDraggingReferenceLines();
        }

        hitAreaObj.draggable.callbacks.dragMove();
    }
}
function setCanDrag(newValue) {
    canDrag = newValue;
}
function setDragDelayPeriod(newValue) {
    dragDelayPeriod = newValue;
}
function removeSign(num) {
    if (num < 0) {
        return -num;
    }
    return num;
}

function makeOtherObjectsSemiTransparent(curObjectBeingDraggedId) {
    Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
        if (mainDrawingGraphicsObjects[key].containerObject !== undefined && key !== curObjectBeingDraggedId) {
            mainDrawingGraphicsObjects[key].containerObject.alpha = 0.15;
        }

    });
    Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {
        if (dimensionLayersGraphicsObjects[key].graphicsObject !== undefined && key !== curObjectBeingDraggedId) {
            dimensionLayersGraphicsObjects[key].graphicsObject.alpha = 0.15;
        }

    });
    graphicsAdapter.getGraphicsObject().alpha = 0.15;
}

function makeOtherObjectsFullyOpaque(curObjectBeingDraggedId) {
    Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
        if (mainDrawingGraphicsObjects[key].containerObject !== undefined && key !== curObjectBeingDraggedId) {
            mainDrawingGraphicsObjects[key].containerObject.alpha = 1;
        }

    });
    Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {
        if (dimensionLayersGraphicsObjects[key].graphicsObject !== undefined && key !== curObjectBeingDraggedId) {
            dimensionLayersGraphicsObjects[key].graphicsObject.alpha = 1;
        }

    });
    graphicsAdapter.getGraphicsObject().alpha = 1;
}
function getAllowedDirectionOfMovementForDragabble(draggable) {
    var horizontalAllowed = false, verticalAllowed = false;
    draggable.dragInfoObjects.forEach(function (directionObj) {
        if (directionObj.directionOfMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL) {
            horizontalAllowed = true;
        } else {
            verticalAllowed = true;
        }
    });

    if (horizontalAllowed && verticalAllowed) {
        return config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL_AND_VERTICAL;
    } else if (horizontalAllowed && !verticalAllowed) {
        return config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL;
    } else {
        return config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL;
    }
}
//function getMovementIncrementInMM(draggable) {
//    return parseFloat((draggable.dragInfoObjects[0].movementIncrement * draggable.dragInfoObjects[0].unitConversion).toFixed(4));
//}

function drawDraggingReferenceLines() {
    var positionToUse;
    if (lastPosition) {
        positionToUse = lastPosition;
    } else if (firstPosition) {
        positionToUse = firstPosition;
    }


    if (positionToUse) {
        var parentGraphicsObjectToUse = getMainDrawingAreaGraphicsObject(curDraggableInteractionObject.elementId);
        if (refLinesContainer === null || refLinesContainer._destroyed === true) {
            refLinesContainer = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, mainWidth, mainHeight);
            //drawingArea.drawingArea.addChild(refLinesContainer);
            parentGraphicsObjectToUse.addChild(refLinesContainer);
        } else {
            if (refLinesContainer.children && refLinesContainer.children.length > 0) {
                refLinesContainer.getChildAt(0).children.forEach(function (child) {
                    if (child.children && child.children.length > 0) {
                        callDestroyOnChildren(child);
                    }
                });
                refLinesContainer.getChildAt(0).removeChildren();
                refLinesContainer.getChildAt(0).clear();
            }
        }

        curDraggableInteractionObject.dragInfoObjects.forEach(function (dragObjInfo) {
            var refLine, objectLine;
            if (dragObjInfo.directionOfMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL && dragObjInfo.isLockedOut !== true) {

                if (dragObjInfo.offsetAction === 'plus') {
                    refLine = new Line(-((dragObjInfo.value * scalingFactor) + ((positionToUse.x - firstPosition.x))) + (dragObjInfo.drawingOffset * scalingFactor),
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y),
                        -((dragObjInfo.value * scalingFactor) + ((positionToUse.x - firstPosition.x))) + (dragObjInfo.drawingOffset * scalingFactor),
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + mainHeight);
                } else {
                    refLine = new Line((dragObjInfo.refPoint * scalingFactor) + (dragObjInfo.portionOverhangingFront * scalingFactor) - mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + inDrawingDimensionLayerOffsetX,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + (dragObjInfo.portionOverhangingLeft * scalingFactor)),
                        (dragObjInfo.refPoint * scalingFactor) + (dragObjInfo.portionOverhangingFront * scalingFactor) - mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + inDrawingDimensionLayerOffsetX,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + (dragObjInfo.portionOverhangingLeft * scalingFactor) + inDrawingDimensionLayerOffsetY) + mainHeight);
                }

                if (dragObjInfo.offsetAction === 'plus') {
                    objectLine = new Line(dragObjInfo.drawingOffset * scalingFactor,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y),
                        dragObjInfo.drawingOffset * scalingFactor,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + mainHeight);
                } else {
                    objectLine = new Line(-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (dragObjInfo.refPoint * scalingFactor) + (dragObjInfo.portionOverhangingFront * scalingFactor) - ((dragObjInfo.value * scalingFactor) - (positionToUse.x - firstPosition.x)) + inDrawingDimensionLayerOffsetX,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + (dragObjInfo.portionOverhangingLeft * scalingFactor)),
                        -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (dragObjInfo.refPoint * scalingFactor) + (dragObjInfo.portionOverhangingFront * scalingFactor) - ((dragObjInfo.value * scalingFactor) - (positionToUse.x - firstPosition.x)) + inDrawingDimensionLayerOffsetX,
                        -(mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY + (dragObjInfo.portionOverhangingLeft * scalingFactor)) + mainHeight);
                }

                refLine.setDashed(true);
                objectLine.setDashed(true);

                drawDashedLine(refLinesContainer.getChildAt(0), refLine, false);
                drawDashedLine(refLinesContainer.getChildAt(0), objectLine, false, config.DRAWING_COLOURS.ORANGE);

                drawDraggingOffsetDimension(dragObjInfo);
                /*
                length = vals.value - deltaX;
                if (-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (vals.refPoint * scalingFactor) - ((vals.value * scalingFactor) - (positionToUse.x - firstPosition.x)) < -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (vals.refPoint * scalingFactor)) {
                    //if (length < 0) {
                    //    length = -length;
                    //}
                    start = start - length;
                } else {
                    if (length < 0) {
                        length = -length;
                    }
                    
                }
                */
            } else if (dragObjInfo.isLockedOut !== true) {

                refLine = new Line(-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x,
                    (mainHeight - ((dragObjInfo.refPoint) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY),
                    -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + mainWidth,
                    (mainHeight - ((dragObjInfo.refPoint) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY));

                if (dragObjInfo.offsetAction === 'plus') {
                    objectLine = new Line(-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x,
                        (mainHeight - ((dragObjInfo.refPoint + dragObjInfo.value) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + (positionToUse.y - firstPosition.y),
                        -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + mainWidth,
                        (mainHeight - ((dragObjInfo.refPoint + dragObjInfo.value) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + (positionToUse.y - firstPosition.y));
                } else {
                    objectLine = new Line(-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x,
                        (mainHeight - ((dragObjInfo.refPoint - dragObjInfo.value) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + (positionToUse.y - firstPosition.y),
                        -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + mainWidth,
                        (mainHeight - ((dragObjInfo.refPoint - dragObjInfo.value) * scalingFactor)) - (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY) + (positionToUse.y - firstPosition.y));
                }

                refLine.setDashed(true);
                objectLine.setDashed(true);

                drawDashedLine(refLinesContainer.getChildAt(0), refLine, false);
                drawDashedLine(refLinesContainer.getChildAt(0), objectLine, false, config.DRAWING_COLOURS.ORANGE);

                drawDraggingOffsetDimension(dragObjInfo);
            }
        });
    }

    function drawDraggingOffsetDimension(vals) {
        var dimension = vals.dimensionObj;
        var start = vals.refPoint;

        var ahWidth = 1.5;
        var ahLength = 10;
        var stopLength = 4;
        //var layerIncrement = config.configurationDimensionLayerIncrement;


        var length, offset;
        if (vals.directionOfMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL) {

            var horizontalStartFlipped = false;

            var deltaX = (positionToUse.x / scalingFactor - firstPosition.x / scalingFactor);
            var endX = vals.refPoint + vals.value + deltaX;
            length = start - endX;
            offset = (-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y / scalingFactor) + 25;


            if (vals.offsetAction === 'plus') {

                endX = vals.refPoint + vals.value + deltaX;
                length = start - endX;

                if (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x - inDrawingDimensionLayerOffsetX < (vals.refPoint + vals.portionOverhangingFront - vals.drawingOffset) * scalingFactor) {
                    start = start - length;
                    horizontalStartFlipped = true;
                } else {
                    if (length < 0) {
                        length = -length;
                    }
                }
            } else {
                //endX = vals.refPoint - (vals.value + deltaX);
                //length = start - endX;
                length = vals.value - deltaX;
                if (-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (vals.refPoint * scalingFactor) - ((vals.value * scalingFactor) - (positionToUse.x - firstPosition.x)) < -mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x + (vals.refPoint * scalingFactor)) {
                    //if (length < 0) {
                    //    length = -length;
                    //}
                    start = start - length;
                } else {
                    if (length < 0) {
                        length = -length;
                    }

                }

            }

            dimension.refreshDimension(start, length, offset);
            //flipMainDrawingAreaAxisPoint(dimension.startPoint);
            //dimension.startPoint.y = 25/scalingFactor;
            scaleDimension(dimension);

            if (horizontalStartFlipped === true) {
                //dimension.startPoint.x = (dimension.startPoint.x - mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x) + (vals.portionOverhangingFront * scalingFactor);
                dimension.startPoint.x = vals.drawingOffset * scalingFactor - inDrawingDimensionLayerOffsetX;
            } else {
                //dimension.startPoint.x = (dimension.startPoint.x - mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x);
                dimension.startPoint.x = (dimension.startPoint.x - mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x) + (vals.portionOverhangingFront * scalingFactor);
            }



        } else {

            var deltaY = (positionToUse.y / scalingFactor - firstPosition.y / scalingFactor);
            var endY;
            
            offset = (-mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.x / scalingFactor) + (mainWidth / scalingFactor) - 25;
            //if (mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y > (mainHeight - ((vals.refPoint + vals.value + deltaY) * scalingFactor/*vals.refPoint * scalingFactor*/))) {
            if (vals.offsetAction === 'plus') {
                endY = vals.refPoint + vals.value - deltaY;
                length = start - endY;
                if (deltaY - vals.value > 0) {
                    if (length < 0) {
                        length = -length;
                    }
                    start = start - length;

                } else {
                    if (length < 0) {
                        length = -length;
                    }
                }
            } else {
                endY = vals.refPoint - (vals.value + deltaY);
                length = start - endY;
                if (deltaY + vals.value > 0) {
                    if (length < 0) {
                        length = -length;
                    }
                    start = start - length;

                } else {
                    if (length < 0) {
                        length = -length;
                    }
                }
            }


            //if (length < 0) {
            //    length = -length;
            //}
            dimension.refreshDimension(start, length, offset);

            scaleDimension(dimension);
            dimension.startPoint.y = dimension.startPoint.y + mainDrawingGraphicsObjects[curDraggableInteractionObject.elementId].containerObject.y + inDrawingDimensionLayerOffsetY;


        }
        processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);
        drawDimension(dimension, refLinesContainer.getChildAt(0));

    }
}

function cleanUpDraggingVariableState() {
    if (currentDragItemElementId !== null) {

        setTimeout(function () {
            dragging = false;
        }, 500);

        doRender = false;
        makeOtherObjectsFullyOpaque(currentDragItemElementId);
    } else if (dragStartDelayTimerRef) {
        clearTimeout(dragStartDelayTimerRef);
        dragStartDelayTimerRef = null;
    }
}

function addDimensions(dimensionsMeta) {
    var ahWidth = 1.5;
    var ahLength = 10;
    var stopLength = 4;
    //var layerIncrement = config.configurationDimensionLayerIncrement;
    dimensions = [];

    var checkVerticalLeft = false, checkVerticalRight = false, checkHorizontalTop = false, checkHorizontalBottom = false;
    if (dimensionLayerChanged.length > 0) {
        dimensionLayerChanged.forEach(function (dimensionLayerChange) {
            if (dimensionLayerChange === config.DIMENSION_LAYER_CHANGE.TOP_TO_BOTTOM) {
                checkHorizontalTop = true;
            } else if (dimensionLayerChange === config.DIMENSION_LAYER_CHANGE.BOTTOM_TO_TOP) {
                checkHorizontalBottom = true;
            } else if (dimensionLayerChange === config.DIMENSION_LAYER_CHANGE.LEFT_TO_RIGHT) {
                checkVerticalLeft = true;
            } else if (dimensionLayerChange === config.DIMENSION_LAYER_CHANGE.RIGHT_TO_LEFT) {
                checkVerticalRight = true;
            }
        });
    }

    //assignLayerNumbers(dimensionsMeta);
    for (var i = 0; i < dimensionsMeta.length; i++) {
        var addedDimensionKey = dimensionsMeta[i].drawingGroupName !== undefined && dimensionsMeta[i].drawingGroupName !== 'NOT_SET' ? dimensionsMeta[i].drawingGroupName : dimensionsMeta[i].name;
        //if (addedDimensions[addedDimensionKey] === undefined) {
        //    addedDimensions[addedDimensionKey] = true;
        scaleDimension(dimensionsMeta[i]);
        var dimension = processDimension(dimensionsMeta[i], ahWidth, ahLength, stopLength, layerIncrement);
        if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER || dimension.type.indexOf('cog') !== -1 || dimension.type === config.DIMENSION_TYPE.FREE_ANGLE || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
            applyOffsetsToInDrawingDimension(dimension);
            var graphicsObjectToUse, addToGraphic;
            if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
                graphicsObjectToUse = getMainDrawingAreaGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'HORIZONTAL_COG_GROUP_DIMENSION');
                addToGraphic = false;
                dimensions.push(drawDimension(dimension, graphicsObjectToUse, addToGraphic));
            }else if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE && dimension.layerNumber) {
                graphicsObjectToUse = getDimensionLayersGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'FREE_ANGLE_DIMENSION');
                addToGraphic = false;
                dimensions.push(drawDimension(dimension, graphicsObjectToUse, addToGraphic));
            } else {
                mainGraphicsObjectUsers[addedDimensionKey] = {};
                graphicsObjectToUse = graphicsAdapter.getGraphicsObject();
                addToGraphic = true;
                drawDimension(dimension, graphicsObjectToUse, addToGraphic);
            }
            //issue is to do with what graphic object the dimension is being drawn on and whether thats ebing added and at what position, not sure if the code changes above are accurate
        } else {
            var dimensionGraphicsObj = drawDimension(dimension, getDimensionLayersGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'DIMENSION'));
            dimensions.push(dimensionGraphicsObj);
            if (dimensionsMeta[i].type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
                drawingArea.topDrawingArea.addChild(dimensionGraphicsObj);
            } else if (dimensionsMeta[i].type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
                drawingArea.bottomDrawingArea.addChild(dimensionGraphicsObj);
            } else if (dimensionsMeta[i].type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                drawingArea.leftDrawingArea.addChild(dimensionGraphicsObj);
            } else if (dimensionsMeta[i].type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
                drawingArea.rightDrawingArea.addChild(dimensionGraphicsObj);
            }
        }
            //if (dimension !== undefined) {

            //}
        //} else {
        //    if (dimensionsMeta[i].type.indexOf('cog') !== -1) {
        //        scaleDimension(dimensionsMeta[i]);
        //    } else if (checkHorizontalTop || checkHorizontalBottom || checkVerticalRight || checkVerticalLeft) {

        //        if (checkHorizontalBottom && dimensionsMeta[i].type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
        //            var dimensionGraphicsObj = getDimensionLayersGraphicsObject(dimensionsMeta[i].drawingGroupName !== undefined && dimensionsMeta[i].drawingGroupName !== 'NOT_SET' ? dimensionsMeta[i].drawingGroupName : dimensionsMeta[i].name, 'DIMENSION');
        //            try {
        //                //var index = drawingArea.bottomDrawingArea.getChildIndex(dimensionGraphicsObj);
        //                var foundObject = drawingArea.bottomDrawingArea.children.filter(function (displayObject) {
        //                    return displayObject === dimensionGraphicsObj;
        //                });
        //                if (foundObject[0]) {
        //                    drawingArea.bottomDrawingArea.removeChild(foundObject[0]);
        //                    drawingArea.topDrawingArea.addChild(dimensionGraphicsObj);
        //                }
        //            }catch(childNotFoundEx) {

        //            }
                
        //        } else if (checkHorizontalTop && dimensionsMeta[i].type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
        //            //drawingArea.bottomDrawingArea.addChild(dimensionGraphicsObj);
        //            //drawingArea.topDrawingArea.addChild(dimensionGraphicsObj);
        //            var dimensionGraphicsObj = getDimensionLayersGraphicsObject(dimensionsMeta[i].drawingGroupName !== undefined && dimensionsMeta[i].drawingGroupName !== 'NOT_SET' ? dimensionsMeta[i].drawingGroupName : dimensionsMeta[i].name, 'DIMENSION');
        //            try {
        //                //var index = drawingArea.topDrawingArea.getChildIndex(dimensionGraphicsObj);
        //                var foundObject = drawingArea.topDrawingArea.children.filter(function (displayObject) {
        //                    return displayObject === dimensionGraphicsObj;
        //                });
        //                if (foundObject[0]) {
        //                    drawingArea.topDrawingArea.removeChild(foundObject[0]);
        //                    drawingArea.bottomDrawingArea.addChild(dimensionGraphicsObj);
        //                }
        //            }catch(childNotFoundEx) {

        //            }
        //        } else if (checkVerticalRight && dimensionsMeta[i].type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
        //            var dimensionGraphicsObj = getDimensionLayersGraphicsObject(dimensionsMeta[i].drawingGroupName !== undefined && dimensionsMeta[i].drawingGroupName !== 'NOT_SET' ? dimensionsMeta[i].drawingGroupName : dimensionsMeta[i].name, 'DIMENSION');
        //            try {
        //                //var index = drawingArea.rightDrawingArea.getChildIndex(dimensionGraphicsObj);
        //                var foundObject = drawingArea.rightDrawingArea.children.filter(function (displayObject) {
        //                    return displayObject === dimensionGraphicsObj;
        //                });
        //                if (foundObject[0]) {
        //                    drawingArea.rightDrawingArea.removeChild(foundObject[0]);
        //                    drawingArea.leftDrawingArea.addChild(dimensionGraphicsObj);
        //                }
        //            } catch (childNotFoundEx) {

        //            }
        //        } else if (checkVerticalLeft && dimensionsMeta[i].type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
        //            var dimensionGraphicsObj = getDimensionLayersGraphicsObject(dimensionsMeta[i].drawingGroupName !== undefined && dimensionsMeta[i].drawingGroupName !== 'NOT_SET' ? dimensionsMeta[i].drawingGroupName : dimensionsMeta[i].name, 'DIMENSION');
        //            try {
        //                //var index = drawingArea.leftDrawingArea.getChildIndex(dimensionGraphicsObj);
        //                var foundObject = drawingArea.leftDrawingArea.children.filter(function (displayObject) {
        //                    return displayObject === dimensionGraphicsObj;
        //                });
        //                if (foundObject[0]) {
        //                    drawingArea.leftDrawingArea.removeChild(foundObject[0]);
        //                    drawingArea.rightDrawingArea.addChild(dimensionGraphicsObj);
        //                }
        //            } catch (childNotFoundEx) {

        //            }
        //        }
        //    }
        //    //if (scaleHorizontalDimensions === true && dimensionsMeta[i].type.indexOf('horizontal') !== -1) {
        //    //    processDimension(dimensionsMeta[i], ahWidth, ahLength, stopLength, layerIncrement);
        //    //    //scaleDimension(dimensionsMeta[i]);
        //    //} else if (scaleVerticalDimensions === true && dimensionsMeta[i].type.indexOf('vertical') !== -1) {
        //    //    processDimension(dimensionsMeta[i], ahWidth, ahLength, stopLength, layerIncrement);
        //    //    //scaleDimension(dimensionsMeta[i]);
        //    //}
        //}
    }
    utils.deleteObjectMembers(dimensionLayerChanged, true);
    
}

function applyOffsetsToInDrawingDimension(dimension) {
    if (dimension.type.indexOf('cog') !== -1) {
        //dimension.verticalStartPoint.x = dimension.verticalStartPoint.x + inDrawingDimensionLayerOffsetX;
        //dimension.verticalStartPoint.y = dimension.verticalStartPoint.y + inDrawingDimensionLayerOffsetY;

        //dimension.verticalEndPoint.x = dimension.verticalEndPoint.x + inDrawingDimensionLayerOffsetX;
        //dimension.verticalEndPoint.y = dimension.verticalEndPoint.y + inDrawingDimensionLayerOffsetY;

        //dimension.horizontalStartPoint.x = dimension.horizontalStartPoint.x + inDrawingDimensionLayerOffsetX;
        //dimension.horizontalStartPoint.y = dimension.horizontalStartPoint.y + inDrawingDimensionLayerOffsetY;

        //dimension.horizontalEndPoint.x = dimension.horizontalEndPoint.x + inDrawingDimensionLayerOffsetX;
        //dimension.horizontalEndPoint.y = dimension.horizontalEndPoint.y + inDrawingDimensionLayerOffsetY;

        //dimension.cogPoint.x = dimension.cogPoint.x + inDrawingDimensionLayerOffsetX;
        //dimension.cogPoint.y = dimension.cogPoint.y + inDrawingDimensionLayerOffsetY;
        //dimension.verticalTextCentre.x = dimension.verticalTextCentre.x + inDrawingDimensionLayerOffsetX;
        //dimension.verticalTextCentre.y = dimension.verticalTextCentre.y + inDrawingDimensionLayerOffsetY;

        //dimension.horizontalTextCentre.x = dimension.horizontalTextCentre.x + inDrawingDimensionLayerOffsetX;
        //dimension.horizontalTextCentre.y = dimension.horizontalTextCentre.y + inDrawingDimensionLayerOffsetY;
    } else {
        dimension.startPoint.x = dimension.startPoint.x + inDrawingDimensionLayerOffsetX;
        dimension.startPoint.y = dimension.startPoint.y + inDrawingDimensionLayerOffsetY;
    }
}

function addAxleLetters(axleLetters) {
    axleLetters.forEach(function (axleLetter) {
        if (axleLettersHeight > 0) {
            axleLetter.centre = { 
                x: inDrawingDimensionLayerOffsetX + ((axleLetter.centre.x * scalingFactor) ),
                //y: mainHeight - ((axleLetter.centre.y * scalingFactor) - mainDrawingGraphicsObjects[axleLetter.drawingGroupName].containerObject.y + inDrawingDimensionLayerOffsetY)/*axleLettersHeight / 2*/
                y: 0 + axleLettersHeight / 2
            };
        } else {
            axleLetter.centre = {
                x: inDrawingDimensionLayerOffsetX + ((axleLetter.centre.x * scalingFactor) - mainDrawingGraphicsObjects[axleLetter.drawingGroupName].containerObject.x),
                //y: mainHeight - ((axleLetter.centre.y * scalingFactor) - mainDrawingGraphicsObjects[axleLetter.drawingGroupName].containerObject.y + inDrawingDimensionLayerOffsetY)/*axleLettersHeight / 2*/
                y: (mainHeight - ((axleLetter.centre.y * scalingFactor) + inDrawingDimensionLayerOffsetY)) - mainDrawingGraphicsObjects[axleLetter.drawingGroupName].containerObject.y /*axleLettersHeight / 2*/
            };
        }
        //scaledCentre.x = scaledCentre.x + inDrawingDimensionLayerOffsetX;
        //scaledCentre.y = scaledCentre.y + inDrawingDimensionLayerOffsetY;
        var markerRadius = getWidthOfWidestAxleLetter(axleLetters);
        
        if (axleLettersHeight > 0) {
            drawingArea.axleLettersDrawingArea.addChild(drawAxleLetterElement(axleLetter, markerRadius));
        } else {
            drawAxleLetterElement(axleLetter, markerRadius);
        }
    });
}

function getWidthOfWidestAxleLetter(axleLetters) {
    var widestLetter = 0;
    axleLetters.forEach(function (axleLetter) {
        widestLetter = Math.max(getWidthOfText(axleLetter.letter, curFontName, axleLetterFontSize), widestLetter);
    });
    return widestLetter + (config.generalDrawingBufferSmall);
}

function drawAxleLetterElement(axleLetter, markerRadius) {
    var axleLetterGraphicObject;
    if (axleLettersHeight > 0) {
        if (axleLetterTopViewGraphicsObjects[axleLetter.letter]) {
            axleLetterGraphicObject = axleLetterTopViewGraphicsObjects[axleLetter.letter];
            
        } else {
            axleLetterGraphicObject = graphicsAdapter.getNewGraphicsObject();
            axleLetterTopViewGraphicsObjects[axleLetter.letter] = axleLetterGraphicObject;
        }
        axleLetterGraphicObject.clear();
    } else {
        axleLetterGraphicObject = getMainDrawingAreaGraphicsObject(axleLetter.drawingGroupName);
    }
    axleLetterGraphicObject.dirty = true;
    axleLetterGraphicObject.clearDirty++;
    

    var centre = axleLetter.centre;
    graphicsAdapter.setAlternateGraphicsObject(axleLetterGraphicObject);

    //var bgColour = config.DRAWING_COLOURS.BLUE;
    var bgColour = config.TABLE_HIGHLIGHT_COLOURS.OK;
    if (axleLetter.colour) {
        bgColour = axleLetter.colour;
    }
    
    drawAxleLetterMarker(centre, markerRadius, bgColour);
    drawAxleLetterTextElement();

    graphicsAdapter.setAlternateGraphicsObject(null);
    return axleLetterGraphicObject;

    function drawAxleLetterMarker(centre, markerRadius, bgColour) {
        graphicsAdapter.drawCircle(axleLetterGraphicObject, centre, markerRadius, bgColour, bgColour);
        graphicsAdapter.drawCircle(axleLetterGraphicObject, centre, markerRadius - (config.generalDrawingBufferSmall / 2), config.DRAWING_COLOURS.WHITE, bgColour);
    }

    

    function drawAxleLetterTextElement() {

        var axleLetterText;
        if (axleLetterGraphicObject.children.length > 0) {
            axleLetterText = axleLetterGraphicObject.getChildAt(0);
            axleLetterText.text = axleLetter.letter;
            axleLetterText.x = axleLetter.centre.x;
            axleLetterText.y = axleLetter.centre.y;
            axleLetterText.style.fontSize = axleLetterFontSize + "px";
        } else {
            axleLetterText = drawText(axleLetter.letter, axleLetter.centre, false, { x: 0.5, y: 0.5 }, undefined, "bold " + axleLetterFontSize + "px " + curFontName, config.DRAWING_COLOURS.WHITE);
            axleLetterGraphicObject.addChild(axleLetterText);
        }
        

        //if (element.interaction !== undefined) {
        //    addInteractionToText(element, textElement);
        //}
        if (axleLetter.interaction !== undefined) {
            if (axleLetter.interaction.callbacks !== undefined) {
                axleLetterText.interactive = true;
                axleLetterText.buttonMode = true;
                if (axleLetter.interaction.callbacks.mouseover !== undefined && typeof axleLetter.interaction.callbacks.mouseover === 'function') {
                    axleLetterText.mouseover = axleLetter.interaction.callbacks.mouseover;
                }
                if (axleLetter.interaction.callbacks.click !== undefined && typeof axleLetter.interaction.callbacks.click === 'function') {
                    axleLetterText.click = axleLetter.interaction.callbacks.click;
                    axleLetterText.tap = axleLetter.interaction.callbacks.click;
                }
                if (axleLetter.interaction.callbacks.mouseout !== undefined && typeof axleLetter.interaction.callbacks.mouseout === 'function') {
                    axleLetterText.mouseout = axleLetter.interaction.callbacks.mouseout;
                }
                if (axleLetter.interaction.callbacks.mousemove !== undefined && typeof axleLetter.interaction.callbacks.mousemove === 'function') {
                    axleLetterText.mousemove = axleLetter.interaction.callbacks.mousemove;
                }
            }
            //addInteractionToText(dimension, textObj);
        }

        
        
    }
}

function initialise(renderMode, displayObjectsMeta, dimensionsMeta, tableData, complianceDashboard, axleLetters, isPdfDrawing, drawWatermark, interactionStateObj, fontSizes, optionalDrawingGroupNameToPulse) {
    //destroy();
    dashboardAndTableTitleFontSize = fontSizes.dashboardAndTableTitleFontSize;
    tableFontSize = fontSizes.tableFontSize;
    dimensionFontSize = fontSizes.dimensionFontSize;
    labelFontSize = fontSizes.labelFontSize;
    axleLetterFontSize = fontSizes.axleLetterFontSize;
    notesAndWarningsFontSize = fontSizes.notesAndWarningsFontSize;
    
    if (isPdfDrawing === true) {
    //    dashbaordAndTabletitleFontSize = config.summaryDashbaordAndTableTitleFontSize;
    //    tableFontSize = config.summaryTableFontSize;
    //    dimensionFontSize = config.summaryDimensionFontSize;
    //    labelFontSize = config.summaryLabelFontSize;
    //    axleLetterFontSize = config.summaryAxleLetterFontSize;
    //    notesAndWarningsFontSize = config.summaryNotesAndWarningsFontSize;
        layerIncrement = config.summaryDimensionLayerIncrement;
    } else {
    //    dashbaordAndTabletitleFontSize = config.configurationDashbaordAndTableTitleFontSize;
    //    tableFontSize = config.configurationTableFontSize;
    //    dimensionFontSize = config.configurationDimensionFontSize;
    //    labelFontSize = config.configurationLabelFontSize;
    //    axleLetterFontSize = config.configurationAxleLetterFontSize;
    //    notesAndWarningsFontSize = config.configurationNotesAndWarningsFontSize;
        layerIncrement = config.configurationDimensionLayerIncrement;
    }
    if (pulseController !== null) {
        pulseController.destroy();
    }
    if (Object.keys(dimensionPulseControllers).length > 0) {
        Object.keys(dimensionPulseControllers).forEach(function (key) {
            dimensionPulseControllers[key].destroy();
        });
        dimensionPulseControllers = {};
    }
    dimensionsToPulse = [];
    graphicsAdapter.loadImageResources();
    drawWatermarkOverlay = drawWatermark;
    
    drawingArea = getDrawingArea(renderMode, overallWidth, overallHeight, mainWidth, mainHeight);
    
    interactionState = interactionStateObj;
    mainDrawingGraphicsObjects = {};
    hitAreaObjects = {};
    dimensionLayersGraphicsObjects = {};
    mainGraphicsObjectUsers = {};
    tableCellTextObjectsForInteractionHighlights = {};
    complianceDashboardItemsForInteractionHighlights = {};
    tableCellsForInteractionHighlights = {};
    allTableCellTextObjects = {};
    allTableCells = {};
    allComplianceDashboardItems = {};
    axleLetterTopViewGraphicsObjects = {};
    originalDimensions = dimensionsMeta;
    setupGraphicsObjects(displayObjectsMeta, dimensionsMeta);
    addHitAreas(displayObjectsMeta);
    addDimensions(dimensionsMeta);
    addDrawingElements(displayObjectsMeta);
    complianceDashboardDefinition = complianceDashboard;

    
    
    if (tableData !== null) {
        tableDefinitionData = tableData;
        drawTable(tableData);
    }
    if (complianceDashboard) {
        drawComplianceDashboard(complianceDashboard);
        drawVerticalSeparatorBetweenDashboardAndTable();
    }
    //var whiteOutForTable = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, LayoutManager.getTableStartX() + tableData.tableWidth, Math.max(complianceDashboard.getHeight() + complianceDashboard.getBackgroundBuffer() * 2, tableData.tableHeight), config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
    //drawingArea.tablesDrawingArea.addChildAt(0, whiteOutForTable);
    //drawVerticalSeperator();
    
    if (axleLetters) {
        originalAxleLetters = axleLetters;
        addAxleLetters(JSON.parse(JSON.stringify(axleLetters)));
    } else {
        originalAxleLetters = null;
    }
    doRender = true;

    //if (optionalDrawingGroupNameToPulse !== undefined) {
    //    pulseController = new PulseController(optionalDrawingGroupNameToPulse);
    //    pulseController.start();
    //}

    //if (dimensionsToPulse.length > 0) {
    //    dimensionsToPulse.forEach(function (dimension) {
    //        dimensionPulseControllers[dimension.drawingGroupName] = new PulseController(dimension.drawingGroupName);
    //        dimension.setPulse(false);
    //        dimensionPulseControllers[dimension.drawingGroupName].start();
    //    });
    //}
    
}

function update(renderMode, displayObjectsMeta, dimensionsMeta, tableData, complianceDashboard, axleLetters, isPdfDrawing, drawWatermark, interactionStateObj, fontSizes, needToRedrawInfoArea, optionalDrawingGroupNameToPulse) {
    //destroy();

    //if (isPdfDrawing === true) {
    dashboardAndTableTitleFontSize = fontSizes.dashboardAndTableTitleFontSize;
    tableFontSize = fontSizes.tableFontSize;
    dimensionFontSize = fontSizes.dimensionFontSize;
    labelFontSize = fontSizes.labelFontSize;
    axleLetterFontSize = fontSizes.axleLetterFontSize;
    notesAndWarningsFontSize = fontSizes.notesAndWarningsFontSize;
    
    if (isPdfDrawing === true) {
        //    dashbaordAndTabletitleFontSize = config.summaryDashbaordAndTableTitleFontSize;
        //    tableFontSize = config.summaryTableFontSize;
        //    dimensionFontSize = config.summaryDimensionFontSize;
        //    labelFontSize = config.summaryLabelFontSize;
        //    axleLetterFontSize = config.summaryAxleLetterFontSize;
        //    notesAndWarningsFontSize = config.summaryNotesAndWarningsFontSize;
        layerIncrement = config.summaryDimensionLayerIncrement;
    } else {
        //    dashbaordAndTabletitleFontSize = config.configurationDashbaordAndTableTitleFontSize;
        //    tableFontSize = config.configurationTableFontSize;
        //    dimensionFontSize = config.configurationDimensionFontSize;
        //    labelFontSize = config.configurationLabelFontSize;
        //    axleLetterFontSize = config.configurationAxleLetterFontSize;
        //    notesAndWarningsFontSize = config.configurationNotesAndWarningsFontSize;
        layerIncrement = config.configurationDimensionLayerIncrement;
    }
    if (pulseController !== null) {
        pulseController.destroy();
    }
    if (Object.keys(dimensionPulseControllers).length > 0) {
        Object.keys(dimensionPulseControllers).forEach(function (key) {
            dimensionPulseControllers[key].destroy();
        });
        dimensionPulseControllers = {};
    }
    dimensionsToPulse = [];
    graphicsAdapter.loadImageResources();
    drawWatermarkOverlay = drawWatermark;
    
    getDrawingArea(renderMode, overallWidth, overallHeight, mainWidth, mainHeight);

    interactionState = interactionStateObj;
    //mainDrawingGraphicsObjects = {};
    //hitAreaObjects = {};
    //dimensionLayersGraphicsObjects = {};
    //tableCellTextObjectsForInteractionHighlights = {};
    //complianceDashboardItemsForInteractionHighlights = {};
    //tableCellsForInteractionHighlights = {};
    //allTableCellTextObjects = {};
    //allTableCells = {};
    //complianceDashboardIcons = {};
    if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        clearGraphics();
    }
    originalDimensions = dimensionsMeta;
    setupGraphicsObjects(displayObjectsMeta, dimensionsMeta);
    
    addHitAreas(displayObjectsMeta);
    addDimensions(dimensionsMeta);
    clearUnusedGraphicsObjectsAndHitAreas();
    
    //addDrawingElements(displayObjectsMeta);
    complianceDashboardDefinition = complianceDashboard;
    

    if (tableData !== null) {
        tableDefinitionData = tableData;
        if (needToRedrawInfoArea) {
            drawTable(tableData);
        }
    }
    if (complianceDashboard) {
        if (needToRedrawInfoArea) {
            drawComplianceDashboard(complianceDashboard);
            drawVerticalSeparatorBetweenDashboardAndTable();
        }
    }
    //var whiteOutForTable = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, LayoutManager.getTableStartX() + tableData.tableWidth, Math.max(complianceDashboard.getHeight() + complianceDashboard.getBackgroundBuffer() * 2, tableData.tableHeight), config.DRAWING_COLOURS.WHITE, config.DRAWING_COLOURS.WHITE);
    //drawingArea.tablesDrawingArea.addChildAt(0, whiteOutForTable);
    //drawVerticalSeperator();

    if (axleLetters) {
        originalAxleLetters = axleLetters;
        addAxleLetters(JSON.parse(JSON.stringify(axleLetters)));
    } else {
        originalAxleLetters = null;
    }
    doRender = true;

    if (optionalDrawingGroupNameToPulse !== undefined && pulseController === null) {
        pulseController = new PulseController(optionalDrawingGroupNameToPulse);
        pulseController.start();
    }

    if (dimensionsToPulse.length > 0) {
        dimensionsToPulse.forEach(function (dimension) {
            if (dimensionPulseControllers[dimension.drawingGroupName] === undefined) {
                dimensionPulseControllers[dimension.drawingGroupName] = new PulseController(dimension.drawingGroupName);
                dimensionPulseControllers[dimension.drawingGroupName].start();
            }
            dimension.setPulse(false);
        });
    }
    displayObjects = [];
    populateDisplayObjects(displayObjectsMeta);
    refreshDrawingGroup();
    //if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        addMainDrawingAreaGraphicsObjects();
    //}
    
}

function PulseController(drawingGroupName) {
    var self = this;
    this.pulseState = 'ON';
    this.drawingGroupName = drawingGroupName;
    this.timerRef = null;
    this.lineWidth = 0.8;
    this.lineExpanding = true;
    this.start = function () {
        
        function changeLineWidth() {
            if (self.pulseState === 'ON') {
                if (self.lineWidth >= 0.8 && self.lineWidth < 2 && self.lineExpanding === true) {
                    self.lineWidth += 0.1;
                    refreshIfGraphicsObjectStillExistsElseDestroy();
                    setTimeout(function () {
                        changeLineWidth();
                    }, 50);
                } else if (self.lineWidth >= 0.8) {
                    self.lineExpanding = false;
                    self.lineWidth -= 0.075;
                    refreshIfGraphicsObjectStillExistsElseDestroy();
                    setTimeout(function () {
                        changeLineWidth();
                    }, 50);
                }
            }
            
        }
        changeLineWidth();
        self.timerRef = setTimeout(function () {
            refreshIfGraphicsObjectStillExistsElseDestroy();
            //self.pulseState = 'OFF';
            self.timerRef = setTimeout(function () {
                refreshIfGraphicsObjectStillExistsElseDestroy();
                self.pulseState = 'OFF';
                self.timerRef = setTimeout(function () {
                    refreshIfGraphicsObjectStillExistsElseDestroy();
                    self.destroy();
                }, 500);
                //refreshDrawingGroup(drawingGroupName);
                //self.pulseState = 'ON';
                //setTimeout(function () {
                //    refreshDrawingGroup(drawingGroupName);
                //    self.pulseState = 'OFF';
                //    setTimeout(function () {
                //        refreshDrawingGroup(drawingGroupName);
                //        self.pulseState = '';
                //        setTimeout(function () {
                //            refreshDrawingGroup(drawingGroupName);
                //            self.destroy();
                //        }, 500);
                //    }, 500);
                //}, 500);
            }, 5000);
        }, 300);

        function refreshIfGraphicsObjectStillExistsElseDestroy() {
            if (mainDrawingGraphicsObjects[drawingGroupName] === undefined && dimensionLayersGraphicsObjects[drawingGroupName] === undefined) {
                self.destroy();
            } else {
                refreshDrawingGroup(drawingGroupName);
            }
        }
    }

    this.getPulseState = function () {
        return self.pulseState;
    }

    this.getCurrentLineWidth = function () {
        return self.lineWidth;
    }

    this.destroy = function () {
        if (dimensionPulseControllers[self.drawingGroupName]) {
            dimensionPulseControllers[self.drawingGroupName] = null;
            delete dimensionPulseControllers[self.drawingGroupName];
        } else {
            pulseController = null;
        }
        
        try {
            if (self.timerRef !== undefined && self.timerRef !== null) {
                clearTimeout(self.timerRef);
            }
        }catch(timerCancelEx) {
            TScMessenger.writeDebugMessage('Error cancelling pulse timer');
        }
        
        //renderer.render(drawingArea.drawingArea);
    }
}

function refreshDrawingGroup(drawingGroupName) {
    if (dragging === false) {
        if (drawingGroupName !== undefined) {
            redrawDrawingGroupElements(drawingGroupName);
        } else {
            Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
                redrawDrawingGroupElements(key);

            });
            Object.keys(dimensionLayersGraphicsObjects).forEach(function (key) {
                redrawDrawingGroupElements(key);

            });
            Object.keys(mainGraphicsObjectUsers).forEach(function (key) {
                redrawDrawingGroupElements(key);
            });
        }

        refreshTableInteractionHighlights();
        refreshComplianceDashboardInteractionHighlights();
        if ((drawingGroupName === undefined || drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1 || drawingGroupName.indexOf(config.OBJECT_TYPES.AXLE_GROUPS) !== -1)  && originalAxleLetters) {
            addAxleLetters(JSON.parse(JSON.stringify(originalAxleLetters)));
        }
        if (renderer !== undefined && renderer !== null) {
            renderer.render(drawingArea.drawingArea);
        }
    } else {
        needToDoRerender = true;
    }
}

function redrawDrawingGroupElements(drawingGroupName) {
    var graphicsDetails;
    var graphicsObjectToUse;
    if (mainDrawingGraphicsObjects[drawingGroupName] !== undefined) {
        graphicsDetails = mainDrawingGraphicsObjects[drawingGroupName];
        graphicsObjectToUse = graphicsDetails.graphicsObject;
    } else if (dimensionLayersGraphicsObjects[drawingGroupName] !== undefined) {
        graphicsDetails = dimensionLayersGraphicsObjects[drawingGroupName];
        graphicsObjectToUse = graphicsDetails.graphicsObject;
    } else {
        graphicsDetails = {
            groupType: 'DIMENSION',
            preventDestroy: true
        };
        graphicsObjectToUse = graphicsAdapter.getGraphicsObject();
    }
        
    handleRedraw();
    
    //if (graphicsDetails.groupType === 'COG_GROUP_ELEMENT') {
    //    graphicsDetails = mainDrawingGraphicsObjects[drawingGroupName];
    //    graphicsObjectToUse = graphicsDetails.graphicsObject;
    //    handleRedraw()
    //}
    
    function handleRedraw() {
        var displayObjectsCopy, ahWidth, ahLength, stopLength;
        if (graphicsDetails.groupType === 'STANDARD' || graphicsDetails.groupType === 'COG_ELEMENT') {
            callDestroyOnChildren(graphicsObjectToUse);
            graphicsObjectToUse.removeChildren();
            graphicsObjectToUse.clear();

            displayObjectsCopy = [];//JSON.parse(JSON.stringify(displayObjects));
            displayObjects.forEach(function (displayObject) {
                var displayObjectCopy = JSON.parse(JSON.stringify(displayObject));
                if (displayObject.type === 'text') {
                    displayObjectCopy.getDisplayText = displayObject.getDisplayText;
                    displayObjectCopy.formattingFunc = displayObject.formattingFunc;
                }
                displayObjectsCopy.push(displayObjectCopy);

            });

            displayObjectsCopy.forEach(function (displayObject) {
                if (displayObject.drawingGroupName === drawingGroupName) {
                    if (graphicsDetails.groupType === 'STANDARD') {
                        //if (displayObject.type !== 'text') {
                        drawObject(displayObject, graphicsObjectToUse);
                        //}
                    } else if (graphicsDetails.groupType === 'COG_ELEMENT' || graphicsDetails.groupType === 'COG_GROUP_ELEMENT') {
                        handleCOGElement(displayObject);
                    }
                }

            });
            

        } else if (graphicsDetails.groupType === 'COG_GROUP_ELEMENT') {
            //callDestroyOnChildren(graphicsObjectToUse);
            graphicsObjectToUse.removeChildren();
            graphicsObjectToUse.clear();

            //Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
            //    if (mainDrawingGraphicsObjects[key].groupType === 'COG_GROUP_ELEMENT') {
            //        callDestroyOnChildren(mainDrawingGraphicsObjects[key].graphicsObject);
            //        mainDrawingGraphicsObjects[key].graphicsObject.clear();
            //    }
            //});

            //graphicsAdapter.getGraphicsObject().children.forEach(function (child) {
            //    if (child.children && child.children.length > 0) {
            //        callDestroyOnChildren(child);
            //    }
            //});
            //graphicsAdapter.getGraphicsObject().removeChildren();
            //graphicsAdapter.getGraphicsObject().clear();

            displayObjectsCopy = [];//JSON.parse(JSON.stringify(displayObjects));
            displayObjects.forEach(function (displayObject) {
                var displayObjectCopy = JSON.parse(JSON.stringify(displayObject));
                if (displayObject.type === 'text') {
                    displayObjectCopy.getDisplayText = displayObject.getDisplayText;
                    displayObjectCopy.formattingFunc = displayObject.formattingFunc;
                }
                displayObjectsCopy.push(displayObjectCopy);

            });
            var cogElementDisplayObject;
            displayObjectsCopy.forEach(function (displayObject) {
                if (displayObject.drawingGroupName === drawingGroupName) {
                    if (graphicsDetails.groupType === 'STANDARD' || (displayObject.type && displayObject.type === 'line')) {
                        //if (displayObject.type !== 'text') {
                        drawObject(displayObject, graphicsObjectToUse);
                        //}
                    } else if (graphicsDetails.groupType === 'COG_ELEMENT' || graphicsDetails.groupType === 'COG_GROUP_ELEMENT') {
                        cogElementDisplayObject = displayObject;
                        handleCOGElement(displayObject);
                    }
                }

            });
            //displayObjectsCopy.forEach(function (displayObject) {
            //    if (displayObject.drawingGroupName === drawingGroupName) {
            //        handleCOGElement(displayObject);
            //    }

            //});

            //var keysToRemove = [];
            //Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
            //    if (mainDrawingGraphicsObjects[key].groupType === 'COG_GROUP_ELEMENT') {
            //        callDestroyOnChildren(mainDrawingGraphicsObjects[key].graphicsObject);
            //        mainDrawingGraphicsObjects[key].graphicsObject.clear();
            //    }
            //});

            //graphicsAdapter.getGraphicsObject().children.forEach(function (child) {
            //    if (child.children && child.children.length > 0) {
            //        callDestroyOnChildren(child);
            //    }
            //});
            //graphicsAdapter.getGraphicsObject().removeChildren();
            //graphicsAdapter.getGraphicsObject().clear();

            //var dimensionsCopy = JSON.parse(JSON.stringify(originalDimensions));
            ahWidth = 1.5;
            ahLength = 10;
            stopLength = 4;
            //var layerIncrement = config.configurationDimensionLayerIncrement;

            originalDimensions.sort(function (a, b) {
                if (interactionState[a.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[a.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
                    return -1;
                } else if (interactionState[b.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[b.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
                    return -1;
                }
                return 0;
            });

            originalDimensions.forEach(function (dimension) {
                if (dimension.name !== config.VALUE_TYPE.WHEELBASETHEORETICAL && dimension.name.indexOf(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL) === -1) {
                    if (dimension.drawingGroupName === drawingGroupName || dimension.linkedDrawingGroupName === drawingGroupName) {
                    //flipMainDrawingAreaAxisPoint(dimension.cogPoint);
                    
                    //var processedDimension = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);

                    
                        drawDimension(dimension, graphicsObjectToUse, true);

                    

                    }
                }

            });
        }else if (graphicsDetails.groupType === 'COG_DIMENSION') {
            var keysToRemove = [];
            Object.keys(mainDrawingGraphicsObjects).forEach(function (key) {
                if (mainDrawingGraphicsObjects[key].groupType === 'COG_DIMENSION') {
                    //callDestroyOnChildren(mainDrawingGraphicsObjects[key].graphicsObject);
                    mainDrawingGraphicsObjects[key].graphicsObject.removeChildren();
                    mainDrawingGraphicsObjects[key].graphicsObject.clear();
                }
            });

            graphicsAdapter.getGraphicsObject().children.forEach(function (child) {
                if (child.children && child.children.length > 0) {
                    callDestroyOnChildren(child);
                }
            });
            graphicsAdapter.getGraphicsObject().removeChildren();
            graphicsAdapter.getGraphicsObject().clear();

            //var dimensionsCopy = JSON.parse(JSON.stringify(originalDimensions));
            ahWidth = 1.5;
            ahLength = 10;
            stopLength = 4;
            //var layerIncrement = config.configurationDimensionLayerIncrement;

            originalDimensions.sort(function (a, b) {
                if (interactionState[a.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[a.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
                    return -1;
                } else if (interactionState[b.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[b.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
                    return -1;
                }
                return 0;
            });

            originalDimensions.forEach(function (dimension) {
                if (dimension.name !== config.VALUE_TYPE.WHEELBASETHEORETICAL && dimension.name.indexOf(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL) === -1) {
                    //if (dimension.drawingGroupName === drawingGroupName) {
                    flipMainDrawingAreaAxisPoint(dimension.cogPoint);
                    //drawNewCOGElement(getMainDrawingAreaGraphicsObject(dimension.drawingGroupName, 'COG_DIMENSION'), dimension, dimension.markerRadius, dimension.labelText, dimension.labelStyle, false);
                    //scaleDimension(dimension);
                    var processedDimension = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);

                    //applyOffsetsToInDrawingDimension(processesDimension);
                    drawDimension(processedDimension, graphicsAdapter.getGraphicsObject(), true);

                    //if (graphicsDetails.groupType === 'STANDARD') {
                    //    drawObject(displayObject, graphicsObjectToUse);
                    //} else if (graphicsDetails.groupType === 'COG_ELEMENT') {
                    //    handleCOGElement(displayObject);
                    //} else if (graphicsDetails.groupType === 'COG_DIMENSION') {

                    //}
                }

            });
        } else if (graphicsDetails.groupType === 'DIMENSION' || graphicsDetails.groupType === 'FREE_ANGLE_DIMENSION') {
            ahWidth = 1.5;
            ahLength = 10;
            stopLength = 4;
            //var layerIncrement = config.configurationDimensionLayerIncrement;
            originalDimensions.forEach(function (dimension) {
                if (dimension.drawingGroupName === drawingGroupName || dimension.name === drawingGroupName) {

                    var dimensionToUse = dimension;

                    var addToGraphic = false;
                    if (!graphicsDetails.preventDestroy) {
                        callDestroyOnChildren(graphicsObjectToUse);
                        graphicsObjectToUse.removeChildren();
                        graphicsObjectToUse.clear();

                        if (!dimension.lineStart && graphicsDetails.groupType === 'DIMENSION') {
                            scaleDimension(dimension);
                            dimensionToUse = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);
                        } else if (graphicsDetails.groupType === 'FREE_ANGLE_DIMENSION') {
                            var breakHere = 0;
                            dimensionToUse = dimension;
                        }/* else if (scaleHorizontalDimensions) {
                            scaleDimension(dimension);
                            dimensionToUse = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);
                        }*/
                    } else {
                        addToGraphic = true;
                        if (!dimension.processed) {
                            scaleDimension(dimension);
                            dimensionToUse = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);
                        }
                    }
                    //flipMainDrawingAreaAxisPoint(dimension.startPoint);

                    //var processedDimension = processDimension(dimension, ahWidth, ahLength, stopLength, layerIncrement);
                    
                    
                    drawDimension(dimensionToUse, graphicsObjectToUse, addToGraphic/* getDimensionLayersGraphicsObject(dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' ? dimension.drawingGroupName : dimension.name, 'DIMENSION')*/);

                }

            });

            //need to call functions to redraw the dimension just cleared
            //plus need to refactor drawing function to use correct colour for dimension
        }

    }
    
}

function callDestroyOnChildren(objectWithChildren, objectToExclude) {
    if (objectWithChildren.children && objectWithChildren.children.length > 0) {
        objectWithChildren.children.forEach(function (child) {
            
            if (objectToExclude === undefined || objectToExclude !== child) {
                if (child.clear) {
                    child.clear();
                }
                
                if (child._destroyed === false) {
                    child.destroy(true);
                }
                
            } else {
                var breakHere = 0;
            }
            
        });
    }
    
}
/*

    

*/
//function refreshTableData(tableData) {
    
//    drawingArea.tablesDrawingArea.removeChildren();
//    drawTable(tableData);

//    renderer.render(drawingArea.drawingArea);
//}

function refreshTableInteractionHighlights() {
    Object.keys(tableCellTextObjectsForInteractionHighlights).forEach(function (key) {
        if (tableCellsForInteractionHighlights[key].colour !== undefined && tableCellsForInteractionHighlights[key].colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE) {
            //customFontColour = getCellAltTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);

            tableCellTextObjectsForInteractionHighlights[key].style.fill = getCellAltTextColourToUse({ interaction: { elementId: key } }, config.DRAWING_COLOURS.WHITE);
        } else {
            //customFontColour = getCellTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
            tableCellTextObjectsForInteractionHighlights[key].style.fill = getCellTextColourToUse({ interaction: { elementId: key } }, config.DRAWING_COLOURS.WHITE);
        }
    });
    
    if (renderer !== undefined && renderer !== null) {
        renderer.render(drawingArea.drawingArea);
    }
    
}

function refreshComplianceDashboardInteractionHighlights() {
    if (complianceDashboardDefinition) {
        Object.keys(complianceDashboardItemsForInteractionHighlights).forEach(function (key) {
            var complianceRow = complianceDashboardDefinition.getItemById(key);
            if (complianceRow !== null) {
                Object.keys(complianceDashboardItemsForInteractionHighlights[key]).forEach(function (complianceItemKey) {
                    var complianceItem = complianceRow.getItemById(complianceItemKey);
                    if (complianceItem !== null) {
                        if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT || complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON) {
                            if (complianceDashboardItemsForInteractionHighlights[key][complianceItemKey]) {
                                complianceDashboardItemsForInteractionHighlights[key][complianceItemKey].style.fill = getComplianceItemTextColour(complianceItem);
                            }
                        }
                    }


                });

            }
        });
    }


    if (renderer !== undefined && renderer !== null) {
        renderer.render(drawingArea.drawingArea);
    }
}

function refreshInfoAreaData(tableData, complianceDashboardData) {

    Object.keys(tableData.cols).forEach(function (key) {
        
        Object.keys(tableData.cols[key].cellData).forEach(function (innerKey) {
            if (tableData.cols[key].cellData[innerKey].id && allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id]) {
                allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].text = tableData.cols[key].cellData[innerKey].value;
                
                if (tableData.cols[key].cellData[innerKey].colour !== undefined) {
                    //console.log('key : Inner key : colour : ' + key + ' : ' + innerKey + ' : ' + tableData.cols[key].cellData[innerKey].colour);
                    allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].fillColor = tableData.cols[key].cellData[innerKey].colour;
                    allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].lineColor = tableData.cols[key].cellData[innerKey].colour;
                    allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].style.fill = getTableFontColour(tableData.cols[key].cellData[innerKey]);
                } else {
                    if (allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].fillColor === config.TABLE_HIGHLIGHT_COLOURS.FAIL || allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].fillColor === config.TABLE_HIGHLIGHT_COLOURS.OK) {
                        var breakHere = 0;
                    }
                    allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].fillColor = config.DRAWING_COLOURS.TABLE_CELL;
                    allTableCells[tableData.cols[key].cellData[innerKey].id].cell.graphicsData[0].lineColor = config.DRAWING_COLOURS.TABLE_CELL;
                    allTableCellTextObjects[tableData.cols[key].cellData[innerKey].id].style.fill = config.DRAWING_COLOURS.BLACK;
                    
                }
                allTableCells[tableData.cols[key].cellData[innerKey].id].cell.dirty++;
                allTableCells[tableData.cols[key].cellData[innerKey].id].cell.clearDirty++;
            }
            
        });

    });

    if (complianceDashboardData !== null) {
        complianceDashboardData.getRows().forEach(function (complianceRow) {
            complianceRow.getItems().forEach(function (complianceItem) {
                if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON) {
                    allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()].text = complianceItem.getIcon();
                    allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()].style.fill = complianceItem.getIconColour();
                } else if (complianceItem.getType() === config.COMPLIANCE_DASHBOARD_ITEM_TYPES.TEXT) {
                    allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()].text = complianceItem.getText();
                    //allComplianceDashboardItems[complianceRow.getId()][complianceItem.getId()].style.fill = complianceItem.getIconColour();
                }
            });
            
        });
    }
    

    //Object.keys(allTableCells).forEach(function(key) {
    //    if (tableCells[key].colour !== undefined && tableCells[key].colour !== config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE) {
    //        //customFontColour = getCellAltTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);

    //        tableCellTextObjects[key].style.fill = getCellAltTextColourToUse({ interaction: { elementId: key } }, config.DRAWING_COLOURS.WHITE);
    //    } else {
    //        //customFontColour = getCellTextColourToUse(cellMeta, config.DRAWING_COLOURS.WHITE);
    //        tableCellTextObjects[key].style.fill = getCellTextColourToUse({ interaction: { elementId: key } }, config.DRAWING_COLOURS.WHITE);
    //    }
    //});


    renderer.render(drawingArea.drawingArea);
}


function scaleDimension(dimensionMeta) {
    if (dimensionMeta.type.indexOf('cog') !== -1) {
        scaleCOGViewDimension();
    } else if (dimensionMeta.type === config.DIMENSION_TYPE.FREE_ANGLE || dimensionMeta.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) {
        scaleFreeAngleDimension();
    } else {
        scaleOverviewViewDimension();
    }

    function scaleOverviewViewDimension() {
        dimensionMeta.startPoint.x = Math.round(dimensionMeta.startPoint.x * scalingFactor);
        dimensionMeta.startPoint.y = Math.round(dimensionMeta.startPoint.y * scalingFactor);
        dimensionMeta.yDimensionOffset = Math.round(dimensionMeta.yDimensionOffset * scalingFactor);
        //dimensionMeta.displayLength = dimensionMeta.length;
        dimensionMeta.length = Math.round(dimensionMeta.length * scalingFactor);
    }

    function scaleCOGViewDimension() {
        dimensionMeta.startHorizontal = Math.round(dimensionMeta.startHorizontal * scalingFactor);
        dimensionMeta.lengthHorizontal = Math.round(dimensionMeta.lengthHorizontal * scalingFactor);
        dimensionMeta.startVertical = Math.round(dimensionMeta.startVertical * scalingFactor);
        dimensionMeta.lengthVertical = Math.round(dimensionMeta.lengthVertical * scalingFactor);
        dimensionMeta.horizontalReferenceX = Math.round(dimensionMeta.horizontalReferenceX * scalingFactor);
        dimensionMeta.vehicleLengthInc = Math.round(dimensionMeta.vehicleLengthInc * scalingFactor);
        dimensionMeta.vehicleHeightInc = Math.round(dimensionMeta.vehicleHeightInc * scalingFactor);
        dimensionMeta.verticalAxisOrigin = Math.round(dimensionMeta.verticalAxisOrigin * scalingFactor);
    }

    function scaleFreeAngleDimension() {
        dimensionMeta.startPoint.x = Math.round(dimensionMeta.startPoint.x * scalingFactor);
        dimensionMeta.startPoint.y = Math.round(dimensionMeta.startPoint.y * scalingFactor);
        dimensionMeta.endPoint.x = Math.round(dimensionMeta.endPoint.x * scalingFactor);
        dimensionMeta.endPoint.y = Math.round(dimensionMeta.endPoint.y * scalingFactor);
    }
    
}

function RectHelper(position, width, height) {

    
    this.topLeft = { x: position.x, y: position.y };
    
    
    this.topRight = { x: position.x + width, y: position.y };
    
    
    this.bottomRight = { x: position.x + width, y: position.y + height };
    
    
    this.bottomLeft = { x: position.x, y: position.y + height };
    
    //rectGraphicsObject.lineTo(position.x + width, position.y);
    //rectGraphicsObject.lineTo(position.x + width, position.y + height);
    //rectGraphicsObject.lineTo(position.x, position.y + height);
    //rectGraphicsObject.lineTo(position.x, position.y);
}

function clearDrawingArea() {
    Object.keys(drawingArea).forEach(function (key) {
        if (drawingArea[key] !== undefined) {
            drawingArea[key].removeChildren();
        }
        
        //drawingArea[key] = null;
    });
    if (sprite !== undefined) {
        try {
            drawingArea.mainDrawingArea.removeChild(sprite);
        } catch (ex) {
            TScMessenger.writeDebugMessage('Error removing children from drawing area')
        }
    }
    //drawingArea.drawingArea.removeChildren();
}

function getRenderer(rWidth, rHeight) {
    return graphicsAdapter.getRenderer(rWidth, rHeight);
}

function resizeRenderer(width, height) {
    renderer.resize(width, height);
}

function beginRendering(container, rWidth, rHeight, forceFreshBool) {
    viewContainer = container;
    //var pixelRatio = getPixelRatio();
    // PIXI.INTERACTION_FREQUENCY = 60; //this is not allowed and was picked up by eslint >> attempting to write a read only property
    renderer = getRenderer(rWidth, rHeight);//optional  arguments e.g.: { antialias: true } , { autoResize: true }, {resolution: ratio}, { resolution: 1, antialias: false }
    renderer.plugins.interaction.cursorStyles.move = 'move';//"url('../Content/images/drag_cursor.png'), auto";

    //app.renderer.plugins.interaction.cursorStyles.text = 'text';

    //renderer.plugins.interaction.destroy();
    //renderer.plugins.interaction = null;
    //renderer.smoothProperty = 5;
    //renderer.preserveDrawingBuffer = false;
    //renderer.context.setTransform(1, 0, 0, 1, 0, 0);
    //renderer.context.clearRect(0, 0, rWidth, rHeight);
    //renderer.context.beginPath();
    renderer.roundPixels = true;
    //renderer.context.translate(0.5, 0.5);
    renderer.backgroundColor = config.DRAWING_COLOURS.WHITE;
    while (container.hasChildNodes()) {
        container.removeChild(container.lastChild);
    }
    renderer.view.id = 'drawing-area-canvas';
    // renderer.view.style.position = 'absolute';


    
    
    
    container.appendChild(renderer.view);
    

    

    //ratio = overallWidth / overallHeight;
    //window.onresize = function (event) {
    //    resize();
    //}
    doRender = true;
    function animate() {
        if (drawingArea !== null && doRender === true) {
            
            if (dragging !== true) {
                doRender = false;
                if (refLinesContainer && refLinesContainer.children && refLinesContainer.children.length > 0) {
                    refLinesContainer.getChildAt(0).children.forEach(function (child) {
                        if (child.children && child.children.length > 0) {
                            callDestroyOnChildren(child);
                        }
                    });
                    refLinesContainer.getChildAt(0).removeChildren();
                    refLinesContainer.getChildAt(0).clear();
                }
                refLinesContainer = null;
                curDraggableInteractionObject = null;
            } /*else {
                drawDraggingReferenceLines();
            }*/
            
            
            
            TScMessenger.writeDebugMessage('drawing');
            
            renderer.render(drawingArea.drawingArea);
            
        } 
        animationFrameRef = requestAnimationFrame(animate);
        
    }
    animate();
    
}

function getPixelRatio() {
    var ctx = document.createElement("canvas").getContext("2d"),
    dpr = window.devicePixelRatio || 1,
    bsr = ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio || 1;

    return dpr / bsr;
}

function freeCanvasAndCancelAnimationLoop() {
    if (animationFrameRef !== null) {
        cancelAnimationFrame(animationFrameRef);
        animationFrameRef = null;
    }
    if (viewContainer !== null && viewContainer !== undefined) {
        viewContainer.removeChild(renderer.view);
        viewContainer = null;
    }
    
}

function destroy(stopRenderer) {
    graphicsAdapter.getGraphicsObject().clear();
    destroyChildren(graphicsAdapter.getGraphicsObject().removeChildren());
    graphicsAdapter.getGraphicsObject().destroy(true);
    graphicsAdapter.destroyGraphicsObject();
    if (drawingArea !== null) {
        cleanUpDrawingArea(destroyChildren);
    }
    if (stopRenderer && stopRenderer === true) {
        doRender = false;
        //if (removeCanvas && removeCanvas === true) {
        //    renderer.destroy(true);
        //    renderer = null;
        //}
    }
    graphicsAdapter.setWaterMarkSpriteToNUll();
    utils.deleteObjectMembers(displayObjects, true);
    displayObjects = [];
    utils.deleteObjectMembers(dimensions, true);
    dimensions = [];
    notes = [];
    warnings = [];
    ////if (removeCanvas) {
        utils.deleteObjectMembers(mainDrawingGraphicsObjects, true);
        mainDrawingGraphicsObjects = {};

        utils.deleteObjectMembers(hitAreaObjects, true);
        hitAreaObjects = {};
        utils.deleteObjectMembers(tableCols, true);
        tableCols = {};
        utils.deleteObjectMembers(hitMaskGraphicObjects, true);
        hitMaskGraphicObjects = {};
        utils.deleteObjectMembers(addedDimensions, true);
        addedDimensions = {};
        utils.deleteObjectMembers(dimensionLayersGraphicsObjects, true);
        dimensionLayersGraphicsObjects = {};
        utils.deleteObjectMembers(mainGraphicsObjectUsers, true);
        mainGraphicsObjectUsers = {};
        utils.deleteObjectMembers(tableCellTextObjectsForInteractionHighlights, true);
        tableCellTextObjectsForInteractionHighlights = {};
        utils.deleteObjectMembers(tableCellsForInteractionHighlights, true);
        tableCellsForInteractionHighlights = {};
        utils.deleteObjectMembers(allTableCellTextObjects, true);
        allTableCellTextObjects = {};
        utils.deleteObjectMembers(allTableCells, true);
        allTableCells = {};
        utils.deleteObjectMembers(allComplianceDashboardItems, true);
        allComplianceDashboardItems = {};
        utils.deleteObjectMembers(complianceContainer, true);
        complianceContainer = null;
        utils.deleteObjectMembers(complianceDashboardItemsForInteractionHighlights, true);
        complianceDashboardItemsForInteractionHighlights = {};
        utils.deleteObjectMembers(axleLetterTopViewGraphicsObjects, true);
        axleLetterTopViewGraphicsObjects = {};

        pulseController = null;
        utils.deleteObjectMembers(dimensionPulseControllers, true);
        dimensionPulseControllers = {};
        utils.deleteObjectMembers(dimensionsToPulse, true);
        dimensionsToPulse = [];

        dragging = false;
        canDrag = true;
        dragDelayPeriod = 500;
        lastPosition = null;
        firstPosition = null;
        refLinesContainer = null;
        curDraggableInteractionObject = null;
        dragMoveCounter = 0;
        dragStartDelayTimerRef = null;

        originalDimensions = null;
        tableDefinitionData = null;

        complianceDashboardDefinition = null;

        mainWidth = null;
        mainHeight = null;
        previousScalingFactor === null;
    //}
    //if (destroyAll && destroyAll === true) {
        
    //    Object.keys(drawingArea).forEach(function (key) {
    //        drawingArea[key].destroy(true);
    //        //drawingArea[key] = null;
    //    });
    //    drawingArea.drawingArea.destroy(true);
    //}
    
    
    //renderer.view = null;
    //renderer.destroy();
    
    //drawingArea.drawingArea.destroy(true);
    //Object.keys(drawingArea).forEach(function (key) {
    //    //drawingArea[key].destroy(true);
    //    drawingArea[key] = null;
    //});
    //drawingArea = null;
}

function destroyChildren(childrenArray) {
    childrenArray.forEach(function (child) {
        destroyChildren(child.removeChildren());
        if (child.name !== null && child.name === 'watermark') {
            var breakHere = 0;
        } else {
            if (child.clear) {
                child.clear();
            }
            child.destroy(true);
            //utils.deleteObjectMembers(child, true);
        }

    });
}

function cleanUpDrawingArea(destroyChildren) {
    Object.keys(drawingArea).forEach(function (key) {
        if (drawingArea[key]) {
            destroyChildren(drawingArea[key].removeChildren());
            drawingArea[key].destroy(true);
            //utils.deleteObjectMembers(drawingArea[key], true);
        }
        //drawingArea[key] = null;
    });
    drawingArea.drawingArea.destroy(true);
    
    drawingArea = null;
}

//function resize() {
//    if (window.innerWidth / window.innerHeight >= ratio) {
//        var w = Math.floor(window.innerHeight * ratio);
//        var h = window.innerHeight;
//    } else {
//        var w = window.innerWidth;
//        var h = Math.floor(window.innerWidth / ratio);
//    }
//    //ratio = Math.min(window.innerWidth / overallWidth,
//    //           window.innerHeight / overallHeight);
//    //drawingArea.drawingArea.scale.x = drawingArea.drawingArea.scale.y = ratio;
//    if (w > 1024 && h > 768) {
//        renderer.view.style.width = w + 'px';
//        renderer.view.style.height = h + 'px';
//    }
    
//    //renderer.view.style.width = w + 'px';
//    //renderer.view.style.height = h + 'px';
//    //renderer.resize(Math.ceil(overallWidth * ratio),
//    //          Math.ceil(overallHeight * ratio));
//}

function setRendererWidthandHeight(w, h) {
    renderer.view.style.width = w + 'px';
    renderer.view.style.height = h + 'px';
}

function setScalingFactor(factor) {
    previousScalingFactor = scalingFactor;
    scalingFactor = factor;
    graphicsAdapter.setScalingFactor(factor);
}

function setItemScalingFactor(scalingFactor, originTranslation, drawingGroupNames) {
    itemScalingFactor = scalingFactor;
    scalingItemDrawingGroupNames = drawingGroupNames;
    scalingItemOriginTranslation = originTranslation;
    //graphicsAdapter.setItemScalingFactor(itemScalingFactor, itemDrawingGroupName);
}

function setBounds(w, h, mW, mH, tLH, bLH, tableLH, nH, wH, eVS, eHS, www, tBH, bBH, aLH, dimensionLayerOffsetX, dimensionLayerOffsetY, topDimensionLayerThickness, axleLetterTableConnectorLayerH, belowTableBufferLayerH, cogDFTH) {
    overallWidth = w;
    overallHeight = h;
    if ((previousScalingFactor !== null && (mainWidth / previousScalingFactor) !== (mW / scalingFactor)) || previousScalingFactor === null) {
        scaleHorizontalDimensions = true;
    } else {
        scaleHorizontalDimensions = false;
    }
    if ((previousScalingFactor !== null && (mainHeight / previousScalingFactor) !== (mH/ scalingFactor)) || previousScalingFactor === null) {
        scaleVerticalDimensions = true;
    } else {
        scaleVerticalDimensions = false;
    }
    mainWidth = mW;
    mainHeight = mH;
    topHeight = tLH;//Math.round(tLH*scalingFactor);
    bottomHeight = bLH;//Math.round(bLH*scalingFactor);
    tablesHeight = tableLH;
    notesHeight = nH;
    warningsHeight = wH;
    graphicsAdapter.setMainHeight(mainHeight + topDimensionLayerThickness);
    //graphicsAdapter.setTopOffsetRequiredForAxisFlip(topDimensionLayerThickness);
    emptyVerticalSpace = eVS;
    emptyHorizontalSpace = eHS;
    graphicsAdapter.setWordWrapWidth(www);
    topBufferHeight = tBH;
    bottomBufferHeight = bBH;
    axleLettersHeight = aLH;
    inDrawingDimensionLayerOffsetX = dimensionLayerOffsetX;
    inDrawingDimensionLayerOffsetY = dimensionLayerOffsetY;
    topInDrawingDimensionLayerThickness = topDimensionLayerThickness;
    axleLetterTableConnectorLayerHeight = axleLetterTableConnectorLayerH;
    belowTableBufferLayerHeight = belowTableBufferLayerH;
    cogDimensionFontTextHeight = cogDFTH;
}

function displayText(text, textType, textHeight, wrapWidth, xPos) {
    switch (textType) {
        case 'WARNING':
            graphicsAdapter.setWordWrapWidth(wrapWidth);
            var warningTitle = drawText(config.getTranslationText('2795') + ': ', { x: 5, y: 0 }, false, undefined, 'left', 'bold ' + notesAndWarningsFontSize + 'px ' + curFontName);
            var warningText = drawText(text, { x: 5 + warningTitle.width, y: 0 }, false, undefined, 'left', notesAndWarningsFontSize + 'px ' + curFontName);
            if (drawingArea.warningsDrawingArea) {
                drawingArea.warningsDrawingArea.addChild(warningTitle);
                drawingArea.warningsDrawingArea.addChild(warningText);
            }
            break;
        case 'NOTE':
            graphicsAdapter.setWordWrapWidth(wrapWidth);
            var notesTitle = drawText(config.getTranslationText('2796') + ': ', { x: 5, y: 0 }, false, undefined, 'left', 'bold ' + notesAndWarningsFontSize + 'px ' + curFontName);
            var noteText = drawText(text, { x: 5 + notesTitle.width, y: 0 }, false, undefined, 'left', notesAndWarningsFontSize + 'px ' + curFontName);
            if (drawingArea.notesDrawingArea) {
                drawingArea.notesDrawingArea.addChild(notesTitle);
                drawingArea.notesDrawingArea.addChild(noteText);
            }
            break;
        case 'NOTESTEXT':
            // Add italics
            // Move down slightly
            graphicsAdapter.setWordWrapWidth(wrapWidth);
            //var notesTitle = drawText(text, { x: xPos, y: 2 }, false, undefined, 'left', 'bold italic ' + notesAndWarningsFontSize + 'px ' + curFontName);
            //var notesText = drawText(text, { x: xPos, y: 20 }, false, undefined, 'left', 'italic ' + notesAndWarningsFontSize + 'px ' + curFontName);
            var notesText = drawText(text, { x: xPos, y: 2 }, false, undefined, 'left', 'oblique ' + notesAndWarningsFontSize + 'px ' + curFontName);
            if (drawingArea.notesDrawingArea) {
                //drawingArea.notesDrawingArea.addChild(notesTitle);
                drawingArea.notesDrawingArea.addChild(notesText);
            }
            break;
    }
}



function getDrawingArea(renderMode, width, height, mainWidth, mainHeight) {

    //var fullScreenDrawingArea = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, overallWidth, overallHeight, config.DRAWING_COLOURS.WHITE);
    var fullScreenDrawingArea;

    if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        if (drawingArea && !drawingArea.topDrawingArea) {
            cleanUpDrawingArea(destroyChildren);
            graphicsAdapter.destroyGraphicsObject();
        }
    } else if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        if (drawingArea && drawingArea.topDrawingArea) {
            cleanUpDrawingArea(destroyChildren);
            graphicsAdapter.destroyGraphicsObject();
        }
    }

    if (drawingArea === null) {
        fullScreenDrawingArea = graphicsAdapter.getDrawingContainer({ x: 0, y: 0 }, overallWidth, overallHeight, config.DRAWING_COLOURS.WHITE);
    } else {
        fullScreenDrawingArea = drawingArea.drawingArea;
        //fullScreenDrawingArea.x = 0;
        //fullScreenDrawingArea.y = 0;
        //fullScreenDrawingArea.width = width;
        //fullScreenDrawingArea.height = height;
    }
    var mainDrawingArea, mainDrawingAreaBG, trialWatermarkDrawingArea, trialWatermarkDrawingAreaBG;
    if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        sideWidth = (width - mainWidth) / 2;
        //sideHeight = height;
        sideHeight = height - (notesHeight + warningsHeight + tablesHeight + (emptyVerticalSpace / 2));
        topWidth = mainWidth;

        bottomWidth = topWidth;
        //notesWidth = bottomWidth;
        notesWidth = width;
        warningsWidth = notesWidth;
        tablesWidth = width;


        leftX = 0;
        leftY = 0;

        emptyVerticalTopX = leftX + sideWidth;
        emptyVerticalTopY = 0;
        emptyVerticalBottomX = emptyVerticalTopX;
        emptyVerticalBottomY = height - (emptyVerticalSpace / 2);
        topX = emptyVerticalTopX;
        topY = emptyVerticalTopY + emptyVerticalSpace / 2 + 1;
        rightX = topX + topWidth;
        rightY = 0;

        topBufferX = topX;
        topBufferY = topY + topHeight;
        mainX = topX;
        mainY = topBufferY + topBufferHeight;
        bottomBufferX = topX;
        bottomBufferY = mainY + mainHeight + 1;
        bottomX = topX;
        bottomY = bottomBufferY + bottomBufferHeight;
        axleLettersX = topX;
        axleLettersY = bottomY + bottomHeight;
        axleLetterTableConnectorLayerX = topX;
        axleLetterTableConnectorLayerY = axleLettersY + axleLettersHeight;
        //bottomBufferX = topX;
        //bottomBufferY =  mainY + mainHeight + 1;
        //axleLettersX = topX;
        //axleLettersY = bottomBufferY + bottomBufferHeight;


        //bottomX = topX;
        //bottomY = axleLettersY + axleLettersHeight;

        //axleLetterTableConnectorLayerX = topX;
        //axleLetterTableConnectorLayerY = bottomY + axleLettersHeight;
        tablesX = leftX;
        tablesY = axleLetterTableConnectorLayerY + axleLetterTableConnectorLayerHeight;
        //notesX = topX;
        belowTableBufferLayerX = topX;
        belowTableBufferLayerY = tablesY + tablesHeight;
        notesX = leftX;
        notesY = belowTableBufferLayerY + belowTableBufferLayerHeight;
        warningsX = notesX;
        warningsY = notesY + notesHeight;

        var leftDrawingArea;
        if (drawingArea === null) {
            leftDrawingArea = graphicsAdapter.getDrawingContainer({ x: leftX, y: leftY }, sideWidth, sideHeight);
        } else {
            leftDrawingArea = drawingArea.leftDrawingArea;
            var leftDrawingAreaBG = leftDrawingArea.getChildAt(0);
            if (leftDrawingArea.children && leftDrawingArea.children.length > 1) {
                leftDrawingArea.removeChildren(1);
            }
            leftDrawingArea.x = leftX;
            leftDrawingArea.y = leftY;
            leftDrawingAreaBG.clear();
            leftDrawingAreaBG.drawRect(0, 0, sideWidth, sideHeight);
            
        }
        
        var topDrawingArea;
        if (drawingArea === null) {
            topDrawingArea = graphicsAdapter.getDrawingContainer({ x: topX, y: topY }, topWidth, topHeight);
        } else {
            topDrawingArea = drawingArea.topDrawingArea;
            var topDrawingAreaBG = topDrawingArea.getChildAt(0);
            if (topDrawingArea.children && topDrawingArea.children.length > 1) {
                topDrawingArea.removeChildren(1);
            }
            topDrawingArea.x = topX;
            topDrawingArea.y = topY;
            topDrawingAreaBG.clear();
            topDrawingAreaBG.drawRect(0, 0, topWidth, topHeight);
            
        }
        
        var rightDrawingArea;
        if (drawingArea === null) {
            rightDrawingArea = graphicsAdapter.getDrawingContainer({ x: rightX, y: rightY }, sideWidth, sideHeight);
        } else {
            rightDrawingArea = drawingArea.rightDrawingArea;
            var rightDrawingAreaBG = rightDrawingArea.getChildAt(0);
            if (rightDrawingArea.children && rightDrawingArea.children.length > 1) {
                rightDrawingArea.removeChildren(1);
            }
            rightDrawingArea.x = rightX;
            rightDrawingArea.y = rightY;
            rightDrawingAreaBG.clear();
            rightDrawingAreaBG.drawRect(0, 0, sideWidth, sideHeight)
        }
        
        var bottomDrawingArea;
        if (drawingArea === null) {
            bottomDrawingArea = graphicsAdapter.getDrawingContainer({ x: bottomX, y: bottomY }, bottomWidth, bottomHeight);
        } else {
            bottomDrawingArea = drawingArea.bottomDrawingArea;
            var bottomDrawingAreaBG = bottomDrawingArea.getChildAt(0);
            if (bottomDrawingArea.children && bottomDrawingArea.children.length > 1) {
                bottomDrawingArea.removeChildren(1);
            }
            bottomDrawingArea.x = bottomX;
            bottomDrawingArea.y = bottomY;
            bottomDrawingAreaBG.clear();
            bottomDrawingAreaBG.drawRect(0, 0, bottomWidth, bottomHeight);
        }
        
        var tablesDrawingArea;
        if (drawingArea === null) {
            tablesDrawingArea = graphicsAdapter.getDrawingContainer({ x: tablesX, y: tablesY}, tablesWidth, tablesHeight, config.DRAWING_COLOURS.WHITE);//needs to be white bg
        } else {
            tablesDrawingArea = drawingArea.tablesDrawingArea;
            var tablesDrawingAreaBG = tablesDrawingArea.getChildAt(0);
            tablesDrawingArea.x = tablesX;
            tablesDrawingArea.y = tablesY;
            tablesDrawingAreaBG.clear();
            tablesDrawingAreaBG.drawRect(0, 0, tablesWidth, tablesHeight);
        }
        
        var notesDrawingArea;
        if (drawingArea === null) {
            notesDrawingArea = graphicsAdapter.getDrawingContainer({ x: notesX, y: notesY }, notesWidth, notesHeight, config.DRAWING_COLOURS.WHITE);//needs to be white bg
        } else {
            notesDrawingArea = drawingArea.notesDrawingArea;
            var notesDrawingAreaBG = notesDrawingArea.getChildAt(0);
            notesDrawingArea.x = notesX;
            notesDrawingArea.y = notesY;
            notesDrawingAreaBG.clear();
            notesDrawingAreaBG.drawRect(0, 0, notesWidth, notesHeight);
        }
        
        

        var warningsDrawingArea;
        if (warningsHeight > 0) {
            if (drawingArea === null) {
                warningsDrawingArea = graphicsAdapter.getDrawingContainer({ x: warningsX, y: warningsY }, warningsWidth, warningsHeight, config.DRAWING_COLOURS.WHITE);//needs to be white bg
            } else {
                warningsDrawingArea = drawingArea.warningsDrawingArea;
                var warningsDrawingAreaBG = warningsDrawingArea.getChildAt(0);
                warningsDrawingArea.x = warningsX;
                warningsDrawingArea.y = warningsY;
                warningsDrawingAreaBG.clear();
                warningsDrawingAreaBG.drawRect(0, 0, warningsWidth, warningsHeight);
            }
            
        }

        
        if (drawingArea === null) {
            mainDrawingArea = graphicsAdapter.getDrawingContainer({ x: mainX, y: mainY }, mainWidth, mainHeight);
        } else {
            mainDrawingArea = drawingArea.mainDrawingArea;
            mainDrawingAreaBG = mainDrawingArea.getChildAt(0);
            mainDrawingArea.x = mainX;
            mainDrawingArea.y = mainY;
            mainDrawingAreaBG.clear();
            mainDrawingAreaBG.drawRect(0, 0, mainWidth, mainHeight);
        }



        
        if (drawWatermarkOverlay === true) {
            if (drawingArea === null) {
                trialWatermarkDrawingArea = graphicsAdapter.getDrawingContainer({ x: mainX, y: mainY }, mainWidth, mainHeight);
            } else {
                trialWatermarkDrawingArea = drawingArea.trialWatermarkDrawingArea;
                trialWatermarkDrawingAreaBG = trialWatermarkDrawingArea.getChildAt(0);
                trialWatermarkDrawingArea.x = mainX;
                trialWatermarkDrawingArea.y = mainY;
                trialWatermarkDrawingAreaBG.clear();
                trialWatermarkDrawingAreaBG.drawRect(0, 0, mainWidth, mainHeight);
            }
        }
        
        var axleLettersDrawingArea;
        if (drawingArea === null) {
            axleLettersDrawingArea = graphicsAdapter.getDrawingContainer({ x: axleLettersX, y: axleLettersY }, mainWidth, axleLettersHeight);
        } else {
            axleLettersDrawingArea = drawingArea.axleLettersDrawingArea;
            if (axleLettersDrawingArea.children.length > 1) {
                axleLettersDrawingArea.removeChildren(1);
            }
            var axleLettersDrawingAreaBG = axleLettersDrawingArea.getChildAt(0);
            axleLettersDrawingArea.x = axleLettersX;
            axleLettersDrawingArea.y = axleLettersY;
            axleLettersDrawingAreaBG.clear();
            axleLettersDrawingAreaBG.drawRect(0, 0, mainWidth, axleLettersHeight);
        }

        var topEmptyArea;
        if (drawingArea === null) {
            topEmptyArea = graphicsAdapter.getDrawingContainer({ x: emptyVerticalTopX, y: emptyVerticalTopY }, mainWidth, emptyVerticalSpace / 2);
        } else {
            topEmptyArea = drawingArea.topEmptyArea;
            var topEmptyAreaBG = topEmptyArea.getChildAt(0);
            topEmptyArea.x = emptyVerticalTopX;
            topEmptyArea.y = emptyVerticalTopY;
            topEmptyAreaBG.clear();
            topEmptyAreaBG.drawRect(0, 0, mainWidth, emptyVerticalSpace / 2);
        }


        var bottomEmptyArea;
        if (drawingArea === null) {
            bottomEmptyArea = graphicsAdapter.getDrawingContainer({ x: emptyVerticalBottomX, y: emptyVerticalBottomY }, mainWidth, emptyVerticalSpace / 2);
        } else {
            bottomEmptyArea = drawingArea.bottomEmptyArea;
            var bottomEmptyAreaBG = bottomEmptyArea.getChildAt(0);
            bottomEmptyArea.x = emptyVerticalBottomX;
            bottomEmptyArea.y = emptyVerticalBottomY;
            bottomEmptyAreaBG.clear();
            bottomEmptyAreaBG.drawRect(0, 0, mainWidth, emptyVerticalSpace / 2);
        }

        var topBufferArea
        if (drawingArea === null) {
            topBufferArea = graphicsAdapter.getDrawingContainer({ x: topBufferX, y: topBufferY }, mainWidth, topBufferHeight);
        } else {
            topBufferArea = drawingArea.topBufferArea;
            var topBufferAreaBG = topBufferArea.getChildAt(0);
            topBufferArea.x = topBufferX;
            topBufferArea.y = topBufferY;
            topBufferAreaBG.clear();
            topBufferAreaBG.drawRect(0, 0, mainWidth, topBufferHeight);
        }

        var bottomBufferArea;
        if (drawingArea === null) {
            bottomBufferArea = graphicsAdapter.getDrawingContainer({ x: bottomBufferX, y: bottomBufferY }, mainWidth, bottomBufferHeight);
        } else {
            bottomBufferArea = drawingArea.bottomBufferArea;
            var bottomBufferAreaBG = bottomBufferArea.getChildAt(0);
            bottomBufferArea.x = bottomBufferX;
            bottomBufferArea.y = bottomBufferY;
            bottomBufferAreaBG.clear();
            bottomBufferAreaBG.drawRect(0, 0, mainWidth, bottomBufferHeight);
        }

        

        if (drawingArea === null) {
            fullScreenDrawingArea.addChild(topBufferArea);
            fullScreenDrawingArea.addChild(bottomBufferArea);
            fullScreenDrawingArea.addChild(leftDrawingArea);
            if (topDrawingArea !== undefined) {
                fullScreenDrawingArea.addChild(topDrawingArea);
            }
            fullScreenDrawingArea.addChild(rightDrawingArea);


            fullScreenDrawingArea.addChild(notesDrawingArea);
            if (warningsDrawingArea !== undefined) {
                fullScreenDrawingArea.addChild(warningsDrawingArea);
            }

            fullScreenDrawingArea.addChild(topEmptyArea);
            fullScreenDrawingArea.addChild(bottomEmptyArea);

            /*fullScreenDrawingArea.addChild(mainDrawingArea);*/
            fullScreenDrawingArea.addChild(tablesDrawingArea);
            fullScreenDrawingArea.addChild(bottomDrawingArea);
            fullScreenDrawingArea.addChild(axleLettersDrawingArea);
            fullScreenDrawingArea.addChild(mainDrawingArea);
            if (trialWatermarkDrawingArea !== undefined) {
                fullScreenDrawingArea.addChild(trialWatermarkDrawingArea);
            }
            //addBackground(drawingArea, 0xFFFFFF);
        }

        return {
            drawingArea: fullScreenDrawingArea,
            leftDrawingArea: leftDrawingArea,
            rightDrawingArea: rightDrawingArea,
            topDrawingArea: topDrawingArea,
            bottomDrawingArea: bottomDrawingArea,
            tablesDrawingArea: tablesDrawingArea,
            notesDrawingArea: notesDrawingArea,
            warningsDrawingArea: warningsDrawingArea,
            mainDrawingArea: mainDrawingArea,
            axleLettersDrawingArea: axleLettersDrawingArea,
            trialWatermarkDrawingArea: trialWatermarkDrawingArea,
            topEmptyArea: topEmptyArea,
            bottomEmptyArea: bottomEmptyArea,
            topBufferArea: topBufferArea,
            bottomBufferArea: bottomBufferArea
        };
    } else if (renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {

        mainX = topBufferHeight;
        mainY = topBufferHeight;

        
        if (drawingArea === null) {
            mainDrawingArea = graphicsAdapter.getDrawingContainer({ x: mainX, y: mainY }, mainWidth, mainHeight);
        } else {
            mainDrawingArea = drawingArea.mainDrawingArea;
            mainDrawingAreaBG = mainDrawingArea.getChildAt(0);
            mainDrawingArea.x = mainX;
            mainDrawingArea.y = mainY;
            mainDrawingAreaBG.clear();
            mainDrawingAreaBG.drawRect(0, 0, mainWidth, mainHeight);
        }

        

        
        if (drawWatermarkOverlay === true) {
            
            if (drawingArea === null) {
                trialWatermarkDrawingArea = graphicsAdapter.getDrawingContainer({ x: mainX, y: mainY }, mainWidth, mainHeight);
            } else {
                trialWatermarkDrawingArea = drawingArea.trialWatermarkDrawingArea;
                trialWatermarkDrawingAreaBG = trialWatermarkDrawingArea.getChildAt(0);
                trialWatermarkDrawingArea.x = mainX;
                trialWatermarkDrawingArea.y = mainY;
                trialWatermarkDrawingAreaBG.clear();
                trialWatermarkDrawingAreaBG.drawRect(0, 0, mainWidth, mainHeight);
            }
        }

        if (drawingArea === null) {
            fullScreenDrawingArea.addChild(mainDrawingArea);
            if (trialWatermarkDrawingArea !== undefined) {
                fullScreenDrawingArea.addChild(trialWatermarkDrawingArea);
            }
        }
        return {
            drawingArea: fullScreenDrawingArea,
            mainDrawingArea: mainDrawingArea,
            trialWatermarkDrawingArea: trialWatermarkDrawingArea
        };
    }
    //return { drawingArea: drawingArea, mainDrawingArea: mainDrawingArea };

}

function getDrawingAsDataURL() {
    return renderer.view.toDataURL('image/jpg');
}

function setFontName(fontName) {
    curFontName = fontName;
}

function refreshDrawing() {
    renderer.render(drawingArea.drawingArea);
}

function getMainX() {
    return mainX;
}

function getMainY() {
    return mainY;
}

function getMainTopLeftCornerPos() {
    return { x: getMainX(), y: getMainY() };
}

function getTopElevationAxleLetterRowTopLeftPos() {
    return { x: axleLettersX, y: axleLettersY };
}

function notifyDimensionLayerChange(dimensionLayerChange) {
    dimensionLayerChanged.push(dimensionLayerChange);
}


var graphicsLayer = {
    initialise: initialise,
    beginRendering: beginRendering,
    setScalingFactor: setScalingFactor,
    setBounds: setBounds,
    displayText: displayText,
    destroy: destroy,
    getDrawingAsDataURL: getDrawingAsDataURL,
    setFontName: setFontName,
    clearDrawingArea: clearDrawingArea,
    setRendererWidthandHeight: setRendererWidthandHeight,
    drawTrialWatermarkOverlay: drawTrialWatermarkOverlay,
    getRenderer: getRenderer,
    resizeRenderer: resizeRenderer,
    getCellPosition: getCellPosition,
    refreshDrawingGroup: refreshDrawingGroup,
    refreshTableInteractionHighlights: refreshTableInteractionHighlights,
    refreshInfoAreaData: refreshInfoAreaData,
    refreshComplianceDashboardInteractionHighlights: refreshComplianceDashboardInteractionHighlights,
    getComplianceDashboardPosition: getComplianceDashboardPosition,
    refreshDrawing: refreshDrawing,
    tempDrawLine: tempDrawLine,
    freeCanvasAndCancelAnimationLoop: freeCanvasAndCancelAnimationLoop,
    getMainTopLeftCornerPos: getMainTopLeftCornerPos,
    getTopElevationAxleLetterRowTopLeftPos: getTopElevationAxleLetterRowTopLeftPos,
    setItemScalingFactor: setItemScalingFactor,
    setCanDrag: setCanDrag,
    setDragDelayPeriod: setDragDelayPeriod,
    cleanUpDraggingVariableState: cleanUpDraggingVariableState,
    update: update,
    removeGraphicsObject: removeGraphicsObject,
    notifyDimensionLayerChange: notifyDimensionLayerChange,
    
}
export default graphicsLayer;
    

