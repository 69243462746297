<template>
    <tr data-bind="template: { name: 'template-user-settings-tab-group' }">
        <td colspan="2">
            <div :id="tabGroup.id" data-bind="attr: {id: id}">
                <!-- ko if: displayType === $root.config.TAB_GROUP_DISPLAY_TYPE.ACCORDION
                <div class="accordion" data-bind="template: { name: 'template-user-settings-accordion-tab', foreach: tabs}"></div>
                /ko -->
                <template v-if="tabGroup.displayType === 'accordion'">
                    <!-- <h1>Accordion</h1> -->
                    <!-- <div class="accordion" data-bind="template: { name: 'template-menu-tab-group-accordion', foreach: tabs}"></div> -->
                    <AccordionTabs :tabsFromParent="Array.isArray(tabGroup.tabs) === true ? tabGroup.tabs : tabGroup.tabs.value"
                                    :displayDeleteButtonFromParent="tabGroup.displayDeleteButton"
                                    :menuTabDisplayTypeFromParent="tabGroup.menuTabDisplayType"
                                    @deleteClick="deleteTriggered">
                    </AccordionTabs>
                </template>
                <template v-if="tabGroup.displayType === 'tabs'">
                    <h1>Tabs</h1>
                    <!-- <TabsContainer :tabsFromParent="Array.isArray(tabGroup.tabs) === true ? tabGroup.tabs : tabGroup.tabs.value"
                                    :displayButtonBesideTabsFromParent="tabGroup.displayButtonBesideTabs"
                                    :tabGroupButtonFromParent="tabGroup.tabGroupButton"
                    >
                    </TabsContainer> -->
                </template>
                <!-- ko if: displayType === $root.config.TAB_GROUP_DISPLAY_TYPE.TABS
                <ul class="nav nav-pills" data-bind="template: { name: 'template-menu-tab-group-tabs-li', foreach: tabs }"></ul>
                <div class="tab-content" data-bind="template: { name: 'template-menu-tab-group-tabs', foreach: tabs }"></div>
                /ko -->
            </div>
        </td>
    </tr>
    <!-- ko if: type === $root.config.ITEM_TYPE.TAB_GROUP
    <tr data-bind="template: { name: 'template-user-settings-tab-group' }"></tr>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-tab-group">
        <td colspan="2">
            <div data-bind="attr: {id: id}">
                ko if: displayType === $root.config.TAB_GROUP_DISPLAY_TYPE.ACCORDION
                <div class="accordion" data-bind="template: { name: 'template-user-settings-accordion-tab', foreach: tabs}"></div>
                /ko
                ko if: displayType === $root.config.TAB_GROUP_DISPLAY_TYPE.TABS
                <ul class="nav nav-pills" data-bind="template: { name: 'template-menu-tab-group-tabs-li', foreach: tabs }"></ul>
                <div class="tab-content" data-bind="template: { name: 'template-menu-tab-group-tabs', foreach: tabs }"></div>
                /ko
            </div>
        </td>
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import AccordionTabs from './ItemTypeTabGroupAccordion.vue';

export default {
    name: 'ItemTypeTabGroup',
    props: {
        itemFromParent: Object
    },
    components: { AccordionTabs },
    setup (props) {
        const tabGroup = toRef(props, "itemFromParent");

        function deleteTriggered(tab) {
            tabGroup.value.deleteFunction(tab);
        }

        return {
            tabGroup,
            deleteTriggered
        }
    }
}
</script>

<style lang="scss" scoped>

</style>