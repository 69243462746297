<template>
    <div>
        <span data-bind="text: value">{{cell.value}}</span>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-text">
        <div>
            <span data-bind="text: value"></span>
        </div>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'MenuTableCellText',
    props: {
        cellObjectFromParent: Object
    },
    setup (props) {
        const cell = toRef(props, "cellObjectFromParent");

        return {
            cell
        }
    }
}
</script>

<style lang="scss" scoped>

</style>