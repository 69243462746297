<template>
    <div class="divider offer-nav-bar-menu hide-from-view" v-visible="displayDivider"></div>
</template>

<script>

export default {
    name: 'NavBarDivider',
    setup () {

        return {
            
        }
    },
    props: {
        displayDivider: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>

</style>