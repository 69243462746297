import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '../../../services';
import {ref } from 'vue';



var BodyStub = function (id, description, source, accessoryType, accessLevel, type, fkTrailerId, updateCounter, make, range, status, editLevel, cachedState, subType) {
    var self = this;
    //#region Private members
    var _id = id,
        _description = description,
        _type = type,
        _source = source,
        _accessoryType = accessoryType,
        _accessLevel = accessLevel,
        _updateCounter = updateCounter || 0,
        _make = make,
        _range = range,
        _status = status,
        _editLevel = editLevel,
        _cached = cachedState || 0,
        _subType = subType || '';
    //#endregion Private members

    //#region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getType() {
        return _type;
    }
    function setType(newValue) {
        _type = newValue;
    }
    function getSource() {
        return _source;
    }
    function setSource(newValue) {
        _source = newValue;
    }
    function getAccessoryType() {
        return _accessoryType;
    }
    function setAccessoryType(newValue) {
        _accessoryType = newValue;
    }
    function getAccessLevel() {
        return _accessLevel;
    }
    function setAccessLevel(newValue) {
        _accessLevel = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getMake() {
        return _make;
    }
    function setMake(newValue) {
        _make = newValue;
    }
    function getRange() {
        return _range;
    }
    function setRange(newValue) {
        _range = newValue;
    }
    function getStatus() {
        return _status;
    }
    function setStatus(newValue) {
        _status = newValue;
    }
    function getEditLevel() {
        return _editLevel;
    }
    function getSubType() {
        return _subType;
    }
    function getBodyTypeText() {
        return globals.getBodyStubTypeText(_type);
    }

    function getImageFileName() {
        switch (_type) {
            case config.BODY_TYPES.VAN:
                return config.BODY_TYPES.VAN;
            case config.BODY_TYPES.TIPPER:
                return config.BODY_TYPES.TIPPER;
            case config.BODY_TYPES.DROPSIDE:
                return config.BODY_TYPES.DROPSIDE;
            case config.BODY_TYPES.TAUTLINER:
                return config.BODY_TYPES.TAUTLINER;
            case config.BODY_TYPES.FLATDECK:
                return config.BODY_TYPES.FLATDECK;
            case config.BODY_TYPES.TANKER:
                return config.BODY_TYPES.TANKER;
            case config.BODY_TYPES.PIGCARRIER:
            case config.BODY_TYPES.SHEEPCARRIER:
            case config.BODY_TYPES.CATTLECARRIER:
                return config.BODY_TYPES.LIVESTOCK;
            case config.BODY_TYPES.COMPACTOR:
                return config.BODY_TYPES.COMPACTOR;
            case config.BODY_TYPES.ROLLBACK:
                return config.BODY_TYPES.ROLLBACK;
            case config.BODY_TYPES.TIMBER:
                return config.BODY_TYPES.TIMBER;
            case config.BODY_TYPES.BEAVERTAIL:
                return config.BODY_TYPES.BEAVERTAIL;
            case config.BODY_TYPES.HOOKLIFT:
                return config.BODY_TYPES.HOOKLIFT;
            case config.BODY_TYPES.OTHER:
                return config.BODY_TYPES.OTHER;
            case config.BODY_TYPES.SERVICE:
                return config.BODY_TYPES.SERVICE;
        }
    }

    var sortingOrder = {
        "en": function () {
            switch (_type) {
                case config.BODY_TYPES.VAN:
                    return 1;
                case config.BODY_TYPES.TAUTLINER:
                    return 2;
                case config.BODY_TYPES.DROPSIDE:
                    return 3;
                case config.BODY_TYPES.FLATDECK:
                    return 4;
                case config.BODY_TYPES.TIMBER:
                    return 5;
                case config.BODY_TYPES.PIGCARRIER:
                case config.BODY_TYPES.SHEEPCARRIER:
                case config.BODY_TYPES.CATTLECARRIER:
                    return 6;
                case config.BODY_TYPES.TIPPER:
                    return 7;
                case config.BODY_TYPES.ROLLBACK:
                    return 8;
                case config.BODY_TYPES.BEAVERTAIL:
                    return 9;
                case config.BODY_TYPES.TANKER:
                    return 10;
                case config.BODY_TYPES.COMPACTOR:
                    return 11;
                case config.BODY_TYPES.HOOKLIFT:
                    return 12;
                case config.BODY_TYPES.SERVICE:
                    return 13;
                case config.BODY_TYPES.OTHER:
                    return 99;
            }
        },
        "en-us": function () {
            switch (_type) {
                case config.BODY_TYPES.SERVICE:
                    return 1;
                case config.BODY_TYPES.VAN:
                    return 2;
                case config.BODY_TYPES.TAUTLINER:
                    return 3;
                case config.BODY_TYPES.DROPSIDE:
                    return 4;
                case config.BODY_TYPES.FLATDECK:
                    return 5;
                case config.BODY_TYPES.TIMBER:
                    return 6;
                case config.BODY_TYPES.PIGCARRIER:
                case config.BODY_TYPES.SHEEPCARRIER:
                case config.BODY_TYPES.CATTLECARRIER:
                    return 7;
                case config.BODY_TYPES.TIPPER:
                    return 8;
                case config.BODY_TYPES.ROLLBACK:
                    return 9;
                case config.BODY_TYPES.BEAVERTAIL:
                    return 10;
                case config.BODY_TYPES.TANKER:
                    return 11;
                case config.BODY_TYPES.COMPACTOR:
                    return 12;
                case config.BODY_TYPES.HOOKLIFT:
                    return 13;

                case config.BODY_TYPES.OTHER:
                    return 99;
            }
        },
        "en-ca": function () {
            switch (_type) {
                case config.BODY_TYPES.SERVICE:
                    return 1;
                case config.BODY_TYPES.VAN:
                    return 2;
                case config.BODY_TYPES.TAUTLINER:
                    return 3;
                case config.BODY_TYPES.DROPSIDE:
                    return 4;
                case config.BODY_TYPES.FLATDECK:
                    return 5;
                case config.BODY_TYPES.TIMBER:
                    return 6;
                case config.BODY_TYPES.PIGCARRIER:
                case config.BODY_TYPES.SHEEPCARRIER:
                case config.BODY_TYPES.CATTLECARRIER:
                    return 7;
                case config.BODY_TYPES.TIPPER:
                    return 8;
                case config.BODY_TYPES.ROLLBACK:
                    return 9;
                case config.BODY_TYPES.BEAVERTAIL:
                    return 10;
                case config.BODY_TYPES.TANKER:
                    return 11;
                case config.BODY_TYPES.COMPACTOR:
                    return 12;
                case config.BODY_TYPES.HOOKLIFT:
                    return 13;

                case config.BODY_TYPES.OTHER:
                    return 99;
            }
        }
    }

    function getBodyTypeSortValue(cultureCode) {

        if (cultureCode === undefined || sortingOrder[cultureCode.toLowerCase()] === undefined) {
            cultureCode = "en";
        }

        return sortingOrder[cultureCode.toLowerCase()]();



    }

    function getCached() {
        return _cached;
    }

    function setCached(newValue) {
        _cached = newValue;
    }

    function getImageClass(returnTrailerClass) {
        var folder = 'EU',
            userCultureCode = globals.getActiveCultureCodeForTranslations();
        if (globals.isAmericanCultureCode(userCultureCode) === true) {
            folder = config.LANGUAGE_PACK.ENGLISH.US.imageFolder;
        }
        if (returnTrailerClass) {
            return 'trailer-body-' + getImageFileName().toLowerCase();
        } else {
            return folder.toLowerCase() + '-' + getImageFileName().toLowerCase();
        }
    }

    function getIntercomMetadata(details) {
        var returnObject = {};
        returnObject.Description = this.getDescription();
        returnObject.SubType = this.getType();

        return returnObject;
    }

    function getStatusForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            // return config.getTranslationText('4926');
            return config.ACCESSORY_STATUS_TYPES.CURRENT;
        }
        return _status;
    }
    function getMakeForMultiSelect() {
        if (_source === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            // return config.getTranslationText('4927');
            return config.STUB_MULTISELECT_NOT_SPECIFIED;
        }
        
        return _make;
    }
    //#endregion Getter and setters

    self.getId = getId;
    self.setId = setId;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getType = getType;
    self.setType = setType;
    self.getSource = getSource;
    self.setSource = setSource;
    self.getAccessoryType = getAccessoryType;
    self.setAccessoryType = setAccessoryType;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getBodyTypeText = getBodyTypeText;
    self.getCached = getCached;
    self.setCached = setCached;
    self.getBodyTypeSortValue = getBodyTypeSortValue;
    self.getIntercomMetadata = getIntercomMetadata;
    self.imageAvailable = ref(true);
    self.getImageClass = getImageClass;
    self.getAccessLevel = getAccessLevel;
    self.setAccessLevel = setAccessLevel;
    self.getMake = getMake;
    self.getRange = getRange;
    self.getStatus = getStatus;
    self.getStatusForMultiSelect = getStatusForMultiSelect;
    self.getMakeForMultiSelect = getMakeForMultiSelect;
    self.editLevel = editLevel;
    self.getEditLevel = getEditLevel;
    self.getSubType = getSubType;
};
    
export default BodyStub;
