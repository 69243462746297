<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox verify-email-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title new-header-text" v-text="getTranslationText('950')"></h4>
            </div>
            <form id="verification-modal" @submit.prevent="verify">
                <div class="modal-body modal-body-text">
                    <div class="modal-body-text-div" v-html="getTranslationText('951')"></div>
                    <div class="modal-body-text-div email-text">
                        <span>{{ emailAddress }}</span>
                    </div>
                    <div class="modal-input">
                        <input id="code-element-1" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" :data-title="getTranslationText('954')" />
                        <input id="code-element-2" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" :data-title="getTranslationText('954')" />
                        <input id="code-element-3" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" :data-title="getTranslationText('954')" />
                        <input id="code-element-4" class="verify-email-input" maxlength="1" type="tel" data-toggle="tooltip" data-placement="top" :data-title="getTranslationText('954')" />
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="button-container">
                        <button id="verify-email-button" class="btn btn-default btn-truckscience btn-primary" type="submit"><span v-text="getTranslationText('952')"></span></button>
                    </div>
                    <div class="message-container">                
                        <span v-html="getTranslationText('953')"></span>
                    </div>
                    <div class="get-started-background"></div>
                </div>
            </form> 
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import { ref, toRef, onBeforeMount, onMounted, nextTick } from 'vue';
import PopupModal from './PopupModal.vue';

const $ = window.$;

export default {
  
    name: 'VerifyEmailModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props, context) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);

        let emailAddress = ref(opts.value.emailAddress),
        intiValForCode = opts.value.optionalCode === undefined ? '' : typeof opts.value.optionalCode === 'string' ? opts.value.optionalCode : '',
        varificationCode = ref(intiValForCode),
        getTranslationText = config.getTranslationText,
        needToVerifyMessageText = getTranslationText('547', [emailAddress]),
        resolvePromise = undefined,
        rejectPromise = undefined;

        
        
        onMounted(function () {
            popup.value.open();
            nextTick(compositionComplete);
            
        });
        
        

        function compositionComplete() {
            $('.verify-email-input').on('focus', function () {
                if (globals.isiPad()) {
                    setTimeout(function () {
                        document.getElementById(this.id).selectionStart = 0
                        document.getElementById(this.id).selectionEnd = 999
                    }, 1);
                    //document.getElementById(self.id).selectionStart = 0
                    //document.getElementById(self.id).selectionEnd = 999
                } else {
                    $(this).select();
                }
            });


            $('.verify-email-input').on('keyup', function () {
                if ($(this).val()) {
                    $(this).removeClass('error');
                    $(this).tooltip('destroy');
                    $(this).nextAll('.verify-email-input').first().focus();
                }
                if (this.id === 'code-element-4') {
                    $('#verify-email-button').focus();
                }
            });

            $('.verify-modal-a').on('click touchstart', function () {
                if (this.id === 'resendEmail') {
                    resendEmail();
                } else {
                    startAgain();
                }
            });
            setTimeout(function () { $('#code-element-1').focus(); }, 100);
        }

        function show() {
           

            // Once we set our config, we tell the popup modal to open
            popup.value.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve;
                rejectPromise = reject;
            })
        }

        function verify() {
            var self = this,
                verificationCode = "";

            if (checkValues()) {
                popup.value.close();
                resolvePromise({ Action: 'verify', Code: verificationCode });
            }

            function checkValues() {
                var returnVal = true,
                    tooltipCreated = false;
                $('.verify-email-input').each(function () {
                    if (!$(this).val()) {
                        $(this).addClass('error');
                        returnVal = false;
                        if (!tooltipCreated) {
                            tooltipCreated = true;
                            $(this).tooltip({ trigger: 'manual', html: true }).tooltip('show');
                        }
                    } else {
                        verificationCode = verificationCode.concat($(this).val());
                    }
                });

                return returnVal;
            }
            
        }

        function resendEmail() {
            popup.value.close();
            resolvePromise({ Action: 'resendEmail' });
        }

        function startAgain() {
            popup.value.close();
            resolvePromise({ Action: 'startAgain' });
            
        }

        function later() {
            popup.value.close();
            resolvePromise();
        }

        return {
            show,
            verify,
            popup,
            emailAddress,
            varificationCode,
            needToVerifyMessageText,
            getTranslationText,
            resendEmail,
            startAgain,
            later
        }
    },
}
</script>