

    var Text = function (text, positionX, positionY, optionalFormattingFunc, optionalColour, optionalStyle) {

        var self = this;
        
        self.text = text;
        self.position = { x: positionX, y: positionY };
        self.formattingFunc = optionalFormattingFunc;
        self.colour = optionalColour;
        self.type = 'text';
        self.style = optionalStyle;
        self.getDisplayText = function () {
            if (self.formattingFunc) {
                return self.formattingFunc(self.text);
            } else {
                return self.text;
            }
        }
        self.setDrawingGroupName = function(group) {
            self.drawingGroupName = group;
            return self;
        }
        self.setBackgroundColour = function (bgColour) {
            self.backgroundColour = bgColour;
            return self;
        }
        self.setBackgroundWidth = function (width) {
            self.backgroundWidth = width;
        }
        self.setBackgroundHeight = function (height) {
            self.backgroundHeight = height;
        }
        self.setRotationAngle = function (angle) {
            self.rotationAngle = angle;
        }
        self.setAnchorPoint = function (point) {
            self.anchorPoint = point;
        }
    };



    export default Text;

