import config from "@/services/config";

/*
 * Using documentation for Bootstrap Multiselect (http://davidstutz.github.io/bootstrap-multiselect/)
 */

/**
 *
 * @param {string} multiselectName - string that can be used to identify the multiselect
 */
function MultiSelectBuilder(multiselectName) {
  var self = this;
  var newElement = {
    multiselectName: multiselectName || "Name not provided",
    selectedOptionType: config.SELECT_OPTION_TYPE.MULTIPLE,
    descriptionPropertyName: "description",
    valuePropertyName: "value",
    buttonText: function (options, select) {
      if (options.length === 0) {
        return this.nonSelectedText;
      } else if (options.length === 1) {
        return options[0].text;
      } else {
        var label,
          count = 0;
        options.each(function (index, el) {
          if (index === 0) {
            label = el.text;
          } else {
            count++;
          }
        });
        return label + " (+" + count + ")";
      }
    },
  };

  /**
   * This value is displayed in button when no values have been selected by the user
   * Defaults to "None selected" when not set here
   * @param {string} t - text to be displayed
   * @returns {object} self
   */
  function setNonSelectedText(t) {
    newElement.nonSelectedText = t;
    return self;
  }

  /**
   * Allow HTML within options and for clear button
   * @returns {object} self
   */
  function enableHTML() {
    newElement.enableHTML = true;
    return self;
  }

  /**
   * Include the search within the multiselect
   * @param {string} filterBehaviour - Identifies what attribute to filter on. Use MULTISELECT_FILTER_BEHAVIOUR_OPTIONS on config. Defaults to TEXT
   * @param {boolean} includeFilterClearBtn - Display button to clear input box
   * @param {boolean} caseSensitiveFiltering - Set to true if you want the search box to be case sensitive
   * @returns {object} self
   */
  function enableSearch(
    filterBehaviour,
    includeFilterClearBtn,
    caseSensitiveFiltering
  ) {
    newElement.enableFiltering = true;
    newElement.filterBehavior =
      typeof filterBehaviour === "string"
        ? filterBehaviour
        : config.MULTISELECT_FILTER_BEHAVIOUR_OPTIONS.TEXT;
    newElement.includeFilterClearBtn =
      typeof includeFilterClearBtn === "boolean"
        ? includeFilterClearBtn
        : false;
    newElement.enableCaseInsensitiveFiltering =
      typeof caseSensitiveFiltering === "boolean"
        ? caseSensitiveFiltering
        : true;
    return self;
  }

  /**
   * Display clear button to clear all selection in multiselect
   * @param {string} text - Text to be displayed in button. Defaults to ltlClear
   * @param {boolean} includeDivider - Include divider below the button to separate it from values. Defaults to false
   * @returns {object} self
   */
  function enableClearButton(text, includeDivider) {
    newElement.includeResetOption = true;
    newElement.resetText =
      typeof text === "string" ? text : config.getTranslationText("79");
    newElement.includeResetDivider =
      typeof includeDivider === "boolean" ? includeDivider : false;
    return self;
  }

  /**
   * Function that is called when the user makes a selection in the drop down
   * @param {function} cb - Function to be fired when value is selected/deselected in multiselect
   * @returns {object} self
   */
  function onChangeFunction(cb) {
    newElement.onChange = cb;
    return self;
  }

  /**
   * The id of the HTML select element
   * @param {string} id - id of the select element to apply the multi select
   * @returns {object} self
   */
  function selectElementId(id) {
    newElement.selectId = id;
    return self;
  }

  /**
   * The options that are presented to the user
   * @param {Object[]} arr - Array of options that the user can select from
   * @returns {object} self
   */
  function userSelectionOptions(arr) {
    newElement.optionsArray = arr;
    return self;
  }

  /**
   * Used in selection.js, applying fil;ter to array using value selected using this column
   * @param {string} cn - Column name that selection will be applied to
   * @returns {object} self
   */
  function columnNameToApplyFilterTo(cn) {
    newElement.columnName = cn;
    return self;
  }

  /**
   * Function used to limit the results. Used to update optionsArray. Function should return an array of objects
   * @param {function} ff - Function to get results
   * @returns {Object} self
   */
  function filterFunction(ff) {
    newElement.filterFunction = ff;
    return self;
  }

  /**
   *
   * @param {string} propName
   * @param {string} valuePropName
   * @returns {object} self
   */
  function objectPropertyToFilterOn(propName, valuePropName) {
    newElement.descriptionColumn = propName;
    newElement.valueColumn =
      typeof valuePropName === "string" ? valuePropName : propName;
    return self;
  }

  /**
   *
   * @param {string|Number} width - Sets the width of the button
   * @returns {object} self
   */
  function setButtonWidth(width) {
    if (typeof width === "number") {
      width = width + "px";
    }
    newElement.buttonWidth = typeof width === "string" ? width : null;
    return self;
  }

  /**
   *
   * @param {Number} h - sets the maxHeight that the multiselect has before it starts scrolling
   * @returns {object} self
   */
  function setMaxHeight(h) {
    newElement.maxHeight = typeof h === "number" ? h : null;
    return self;
  }

  /**
   * Function that is called when the user clicks on the Clear button
   * @param {function} cb - Function to be fired when Clear button is clicked
   * @returns {object} self
   */
  function onDeselectAllFunction(cb) {
    newElement.onDeselectAllCallback = cb;
    return self;
  }

  /**
   * Multiselect will be disabled if no rows available
   * @param {string} text - Text to be displayed on the multiselect when disabled. 'None selected' is the default
   * @returns {object} self
   */
  function disableIfEmpty(text) {
    if (typeof text === "string") {
      newElement.disabledText = text;
    }
    newElement.disableIfEmpty = true;
    return self;
  }

  /**
   * Add extra callback function to be called after first callback function has completed when user has changed value in multiselect
   * @param {function} ocec - Callback function
   * @returns {object} self
   */
  function addOnChangeExtraCallback(ocec) {
    newElement.onChangeExtraCallback = typeof ocec === "function" ? ocec : null;
    return self;
  }

  /**
   * Add extra callback function to be called after first callback function has completed when user has clicked Clear in multiselect
   * @param {function} odaec - Callback function
   * @returns {object} self
   */
  function addOnDeselectAllExtraCallback(odaec) {
    newElement.onDeselectAllExtraCallback =
      typeof odaec === "function" ? odaec : null;
    return self;
  }

  /**
   * Only allow the user to select 1 option in the list
   * @returns {object} self
   */
  function selectSingleItemOnly() {
    newElement.selectedOptionType = config.SELECT_OPTION_TYPE.SINGLE;
    return self;
  }

  /**
   * Update the property names used from description/value to other properties on the object that can be selected by the user
   * @param {string} descriptionPropertyName - The text that will be displayed to the user in the multiselect
   * @param {string} valuePropertyName - The value attached to the text
   * @returns {object} self
   */
  function optionDisplayProperties(descriptionPropertyName, valuePropertyName) {
    newElement.descriptionPropertyName = descriptionPropertyName;
    newElement.valuePropertyName =
      valuePropertyName !== undefined
        ? valuePropertyName
        : descriptionPropertyName;
    return self;
  }

  /**
   * Add function that will be called when the dropdown is opened
   * @param {function} f
   * @returns {object} self
   */
  function addOnDropdownShowFunction(f) {
    newElement.onDropdownShowFunction =
      typeof f === "function" ? f : function () {};
    return self;
  }

  /**
   * Add function that will be called when the dropdown is closed
   * @param {function} f
   * @returns {object} self
   */
  function addOnDropdownHideFunction(f) {
    newElement.onDropdownHideFunction =
      typeof f === "function" ? f : function () {};
    return self;
  }

  /**
   * 
   * @param {string} tooltipText 
   * @returns {object} self
   */
  function addPopoverTooltip(tooltipText) {
    newElement.popoverTooltipText = tooltipText;
    return self;
  }

  /**
   * 
   * @param {function} cb - Custom function that takes the text to be displayed to user as a parameter and returns text to be displayed
   * @returns {object} self
   */
  function addCustomDescriptionForOptionFunction(cb) {
    if(typeof cb === 'function') {
      newElement.useCustomDescriptionForOptionFunction = true;
      newElement.customDescriptionForOptionFunction = cb;
    } 
    return self;
  }

  /**
   * Returns the built multiselect object
   * @return {Object} - built object
   */
  function build() {
    return newElement;
  }

  // #region Public Methods
  this.setNonSelectedText = setNonSelectedText;
  this.enableHTML = enableHTML;
  this.enableSearch = enableSearch;
  this.enableClearButton = enableClearButton;
  this.onChangeFunction = onChangeFunction;
  this.selectElementId = selectElementId;
  this.userSelectionOptions = userSelectionOptions;
  this.columnNameToApplyFilterTo = columnNameToApplyFilterTo;
  this.filterFunction = filterFunction;
  this.objectPropertyToFilterOn = objectPropertyToFilterOn;
  this.setButtonWidth = setButtonWidth;
  this.setMaxHeight = setMaxHeight;
  this.onDeselectAllFunction = onDeselectAllFunction;
  this.disableIfEmpty = disableIfEmpty;
  this.addOnChangeExtraCallback = addOnChangeExtraCallback;
  this.addOnDeselectAllExtraCallback = addOnDeselectAllExtraCallback;
  this.selectSingleItemOnly = selectSingleItemOnly;
  this.optionDisplayProperties = optionDisplayProperties;
  this.addOnDropdownShowFunction = addOnDropdownShowFunction;
  this.addOnDropdownHideFunction = addOnDropdownHideFunction;
  this.addPopoverTooltip = addPopoverTooltip;
  this.addCustomDescriptionForOptionFunction = addCustomDescriptionForOptionFunction;
  this.build = build;
  // #endregion Public Methods
}

export default MultiSelectBuilder;