<template>
    <tr class="user-settings-tel-tr" :class="tel.rowClass">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label">{{tel.label}}</span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="tel.displayTooltip === true"
                            :id="tel.tooltipId"
                            :data-content="tel.tooltipMessage"
                            @click="tel.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                </div>
                <input 
                    :id="tel.id"
                    :title="tel.errorMessage.value"
                    :data-original-title="tel.errorMessage.value"
                    @focus="tel.selectAllText"
                    @blur="tel.blurFunction"
                    v-model="tel.obvFormatted.value"
                    class="form-control login-input menu-input menu-input-number menu-input-50 default-width-tel" 
                    type="tel" 
                    data-bind="value: obvFormatted, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, title: errorMessage, 'data-original-title': errorMessage }" />
            </div>
        </td>
    </tr>

    <!-- ko if: type === $root.config.INPUT_TYPE.TEL
    <tr data-bind="template: { name: 'template-user-settings-tel' }"></tr>
    /ko -->
    <!-- <script type="text/html" id="template-user-settings-tel">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label"></span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        ko if: displayTooltip === true
                        <a data-placement="top"
                        data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                        /ko
                    </div>
                </div>
                <input class="form-control login-input menu-input menu-input-number menu-input-50 default-width-tel" type="tel" data-bind="value: obvFormatted, event: { focus: selectAllText, blur: blurFunction }, attr: { id: id, title: errorMessage, 'data-original-title': errorMessage }" />
            </div>
        </td>
    </script> -->

</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeTel',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const tel = toRef(props, "inputFromParent")

        return {
            tel
        }
    }
}
</script>

<style lang="scss" scoped>

</style>