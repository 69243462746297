<template>
    <div class="sign-up-form-container">
                <div class="form-container">
                    <div class="title">
                        <h2 v-text="config.getTranslationText('2364')"></h2>
                    </div>
                    <div class="login-input-container">                        
                        <form id="sign-up-form" @submit.prevent="$emit('submitSignUp')">
                            <div class="title existing-account flex-start">
                                <span v-text="config.getTranslationText('2365')"></span>
                            </div>
                            <div class="form-elements-container">
                                <div id="sign-up-name" class="input-div input-x2">
                                    <input id="sign-up-first-name" 
                                            type="text" 
                                            class="form-control login-input first"
                                            :value="signUpFirstName"
                                            @input="$emit('update:signUpFirstName', $event.target.value)"
                                            :placeholder="config.getTranslationText('2370')" />
                                    <input id="sign-up-last-name" type="text" class="form-control login-input" :value="signUpLastName" @input="$emit('update:signUpLastName', $event.target.value)" :placeholder="config.getTranslationText('2371')" />
                                </div>
                                <div id="sign-up-email" class="input-div">
                                    <input id="sign-up-email-input"
                                        type="email"
                                        class="form-control login-input"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        :value="signUpEmail"
                                        @input="$emit('update:signUpEmail', $event.target.value)"
                                        :placeholder="config.getTranslationText('2372')"
                                        :data-title="config.getTranslationText('2876')"
                                        :readonly="emailFieldReadOnly" />
                                </div>
                                <div id="sign-up-password" class="input-div sign-up-password-tooltip">
                                    <input id="sign-up-password-input"
                                        type="password"
                                        class="form-control login-input"
                                        maxlength="100"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        :value="signUpPassword"
                                        @input="$emit('update:signUpPassword', $event.target.value)"
                                        :placeholder="config.getTranslationText('2373')"
                                        :data-title="signUpPasswordTooltipMessage" />
                                </div>
                                <div v-visible="showCountriesDropdown">
                                    <div id="countries-div" class="input-div">
                                        <select id="get-started-country-select" style="display: none;">
                                            <option v-for="country in countryList" :value="country.Abbreviation" :key="country">
                                                {{ country.Name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div id="div-to-hide" class="input-div input-x2 get-started-countries-div">
                                        <input class="form-control login-input" />
                                        <div id="spinner-div">
                                            <i id="countries-spinner" class="fa fa-circle-o-notch fa-spin fa-lg"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div>
                                        <span v-html="config.getTranslationText('2374')"></span>
                                    </div>
                                    <div class="signInWrapper">
                                        <button class="btn btn-success btn-block btn-sign-up" type="submit"><span v-text="signUpButtonText"></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div v-visible="showSocialSignUpSection">
                            <div class="separator-or-div">
                                <span v-text="config.getTranslationText('2376')"></span>
                            </div>
                            <div class="form-elements-container">
                                <div class="title existing-account flex-start">
                                    <span v-text="config.getTranslationText('2377')"></span>
                                </div>
                                <div class="signInWrapper">
                                    <div class="googleSignInWrapper">
                                        <div id="customBtn"></div>
                                    </div>
                                    <div class="msSignInWrapper" v-if="isInternetExplorer === false">
                                        <button id="msRegisterBtn" class="btn btn-success btn-block btn-sign-up btn-branded btn-microsoft">
                                            <div class="btn-text">
                                                <img :src="microsoftImageUrl" />
                                                <span v-text="config.getTranslationText('2379')"></span>
                                            </div>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-container">
                        <div>
                            <span v-html="config.getTranslationText('2380')"></span>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>

import { toRef } from 'vue';

export default {
    name: 'SignUpComponent',
    props : {
        config: Object,
        signUpFirstName: String,
        signUpLastName: String,
        signUpEmail: String,
        signUpPassword: String,
        showCountriesDropdown: Boolean,
        countryList: Array,
        signUpButtonText: String,
        showSocialSignUpSection: Boolean,
        isInternetExplorer: Boolean,
        microsoftImageUrl: String,
        showSalesBlurb: Boolean,
        emailFieldReadOnly: Boolean,
        signUpPasswordTooltipMessage: String
    },
    emits: ['update:signUpFirstName','update:signUpLastName', 'update:signUpEmail', 'update:signUpPassword', 'submitSignUp'],
    setup(props) {
        // const config = toRef(props, 'config');
        
        
        
        // const showCountriesDropdown = toRef(props, 'showCountriesDropdown');
        // const countryList = toRef(props, 'countryList');
        // const signUpButtonText = toRef(props, 'signUpButtonText');
        // const showSocialSignUpSection = toRef(props, 'showSocialSignUpSection');
        // const isInternetExplorer = toRef(props, 'isInternetExplorer');
        // const microsoftImageUrl = toRef(props, 'microsoftImageUrl');
        // const showSalesBlurb = toRef(props, 'showSalesBlurb');





        return {
            // config,
            // signUpFirstNameWrapper,
            // signUpLastNameWrapper,
            // signUpEmailWrapper,
            // signUpPasswordWrapper,
            // showCountriesDropdown,
            // countryList,
            // signUpButtonText,
            // showSocialSignUpSection,
            // isInternetExplorer,
            // microsoftImageUrl,
            // showSalesBlurb
        }
    },
}
</script>