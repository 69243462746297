

var BodyUpright = function (id, number, insideFrontToUpright, width, height) {
    var self = this;

    var _id,
        _number,
        _insideFrontToUpright,
        _width,
        _height;

    function getId() {
        return _id;
    }

    function setId(newValue) {
        _id = newValue;
    }

    function getNumber() {
        return _number;
    }

    function setNumber(newValue) {
        _number = newValue;
    }

    function getInsideFrontToUpright() {
        return _insideFrontToUpright;
    }

    function setInsideFrontToUpright(newValue) {
        _insideFrontToUpright = newValue;
    }

    function getWidth() {
        return _width;
    }

    function setWidth(newValue) {
        _width = newValue;
    }

    function getHeight() {
        return _height;
    }

    function setHeight(newValue) {
        _height = newValue;
    }

    function clone() {
        return new BodyUpright(_id,
            undefined,
            _insideFrontToUpright,
            _width,
            _height);
    }

    setId(id);
    setNumber(number);
    setInsideFrontToUpright(insideFrontToUpright);
    setWidth(width);
    setHeight(height);

    self.getId = getId;
    self.setId = setId;
    self.getNumber = getNumber;
    self.setNumber = setNumber;
    self.getInsideFrontToUpright = getInsideFrontToUpright;
    self.setInsideFrontToUpright = setInsideFrontToUpright;
    self.getWidth = getWidth;
    self.setWidth = setWidth;
    self.getHeight = getHeight;
    self.setHeight = setHeight;
    self.clone = clone;

};

export default BodyUpright;
