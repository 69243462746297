import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';


    
function FifthWheel(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel,
                offset, frontOverhang, height, length, subframeHeight, width, mass, massOverride, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, status, lateralCOG, lateralCOGOverride, fromLeftOfVehicleRearWidth,
    verticalCOGType, lateralCOGType, verticalCOGConfirmed, lateralCOGConfirmed, sourceDatabaseId, updateCounter, cached, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType, isPinned) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    this._offset = offset;
    this._frontOverhang = frontOverhang;
    this._height = height;
    this._length = length;
    this._subframeHeight = subframeHeight;
    this._width = width;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    
    this._status = status;
    this._fromLeftOfVehicleRearWidth = fromLeftOfVehicleRearWidth || 0;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;
    //this._massOverride = massOverride;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;
}
    

FifthWheel.prototype.getOffset = function () {
    return this._offset;
}
FifthWheel.prototype.setOffset = function (newValue) {
    this._offset = newValue;
}
FifthWheel.prototype.getFrontOverhang = function () {
    return this._frontOverhang;
}
FifthWheel.prototype.setFrontOverhang = function (newValue) {
    this._frontOverhang = newValue;
}
FifthWheel.prototype.getHeight = function () {
    return this._height;
}
FifthWheel.prototype.setHeight = function (newValue) {
    this._height = newValue;
}
FifthWheel.prototype.getLength = function () {
    return this._length;
}
FifthWheel.prototype.setLength = function (newValue) {
    this._length = newValue;
}
FifthWheel.prototype.getSubframeHeight = function () {
    return this._subframeHeight;
}
FifthWheel.prototype.setSubframeHeight = function (newValue) {
    this._subframeHeight = newValue;
}
FifthWheel.prototype.getWidth = function () {
    return this._width;
}
FifthWheel.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
FifthWheel.prototype.getMass = function () {
    return this._mass;
}
FifthWheel.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
FifthWheel.prototype.getMassOverride = function () {
    return this._massOverride;
}
FifthWheel.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
FifthWheel.prototype.getCOGType = function () {
    return this._cOGType;
}
FifthWheel.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
FifthWheel.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
FifthWheel.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
FifthWheel.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
FifthWheel.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
FifthWheel.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
FifthWheel.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
FifthWheel.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
FifthWheel.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
FifthWheel.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
FifthWheel.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
FifthWheel.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
FifthWheel.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
FifthWheel.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
FifthWheel.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
FifthWheel.prototype.getTotalLoad = function () {
    return this._mass;
}
FifthWheel.prototype.getStatus = function () {
    return this._status;
}
FifthWheel.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
FifthWheel.prototype.getFromLeftOfVehicleRearWidth = function () {
    return this._fromLeftOfVehicleRearWidth;
}
FifthWheel.prototype.setFromLeftOfVehicleRearWidth = function (newValue) {
    this._fromLeftOfVehicleRearWidth = newValue;
}
FifthWheel.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
FifthWheel.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
FifthWheel.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
FifthWheel.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
FifthWheel.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
FifthWheel.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
FifthWheel.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
FifthWheel.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
FifthWheel.prototype.getIsPinned = function () {
    return this._isPinned;
};
FifthWheel.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
};
FifthWheel.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    }else {
        return this._horizontalCOG * this._length / 100;
    }
}
FifthWheel.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * (this._subframeHeight + this._height) / 100;
    }
}
FifthWheel.prototype.getActualLateralCOG = function () {

    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return (this._lateralCOG * this._width / 100);
    }
}
FifthWheel.prototype.getActualHeight = function () {
    return this._height
}

/**
 * Get the fifth wheel JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
FifthWheel.prototype.getFifthWheelObjectToBeSaved = function (includePositionValues) {
    var saveObject = {
        //"Offset": includePositionValues === true ? this._offset : 0,
        "FrontOverhang": this._frontOverhang,
        "Height": this._height,
        "Length": this._length,
        "SubframeHeight": this._subframeHeight,
        "Width": this._width,
        "Mass": this._mass,
        "MassOverride": this._massOverride,
        "COGType": this._cOGType,
        "HorizontalCOG": this._horizontalCOG,
        "HorizontalCOGOverride": this._horizontalCOGOverride,
        "VerticalCOG": this._verticalCOG,
        "VerticalCOGOverride": this._verticalCOGOverride,
        "Status": this._status,
        "LateralCOG": this._lateralCOG,
        "LateralCOGOverride": this._lateralCOGOverride,
        "FromLeftOfVehicleRearWidth": this._fromLeftOfVehicleRearWidth,
        "VerticalCOGType": this._verticalCOGType,
        "LateralCOGType": this._lateralCOGType
    };

    if (includePositionValues === true) {
        saveObject["Offset"] = this._offset;
    }

    return saveObject;
}

/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
 */
FifthWheel.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getFifthWheelObjectToBeSaved(includePositionValues), includePositionValues);
}

FifthWheel.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newFifthWheel = new FifthWheel(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._offset,
        this._frontOverhang,
        this._height,
        this._length,
        this._subframeHeight,
        this._width,
        this._mass,
        this._massOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._status,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfVehicleRearWidth,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType());

    newFifthWheel.setAccessLevel(this.getAccessLevel());
    newFifthWheel.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newFifthWheel.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());

    return newFifthWheel;
}

FifthWheel.prototype.update = function (fifthWheel) {
    this.updateBase(fifthWheel);
    this.setOffset(fifthWheel.getOffset());
    this.setFrontOverhang(fifthWheel.getFrontOverhang());
    this.setHeight(fifthWheel.getHeight());
    this.setLength(fifthWheel.getLength());
    this.setSubframeHeight(fifthWheel.getSubframeHeight());
    this.setWidth(fifthWheel.getWidth());
    this.setMass(fifthWheel.getMass());
    this.setMassOverride(fifthWheel.getMassOverride());
    this.setCOGType(fifthWheel.getCOGType());
    this.setHorizontalCOG(fifthWheel.getHorizontalCOG());
    this.setHorizontalCOGOverride(fifthWheel.getHorizontalCOGOverride());
    this.setVerticalCOG(fifthWheel.getVerticalCOG());
    this.setVerticalCOGOverride(fifthWheel.getVerticalCOGOverride());
    this.setStatus(fifthWheel.getStatus());
    this.setLateralCOG(fifthWheel.getLateralCOG());
    this.setLateralCOGOverride(fifthWheel.getLateralCOGOverride());
    this.setFromLeftOfVehicleRearWidth(fifthWheel.getFromLeftOfVehicleRearWidth());
    this.setVerticalCOGType(fifthWheel.getVerticalCOGType());
    this.setLateralCOGType(fifthWheel.getLateralCOGType());
    this.setVerticalCOGConfirmed(fifthWheel.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(fifthWheel.getLateralCOGConfirmed());
}

utils.extend(Accessory, FifthWheel);

export default FifthWheel;

