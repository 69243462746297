import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, utils, globals } from '../../../services';
import Accessory from '../../offer/configuration/model.accessory';

function Taillift(id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, savedFromSourceDatabaseId, editLevel, width, height,
    mass, massOverride, cOGType, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, fromLeftOfBodyWidthExternal, status,
    type, rearClearance, internalMountingLength, rearProtrusion, aboveOrBelowFloor, mechanismHeight, verticalCOGType, lateralCOGType, verticalCOGConfirmed, lateralCOGConfirmed,
    sourceDatabaseId, updateCounter, cached, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate,
    costingResidualRateType, isPinned) {

    Accessory.call(this, id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, topViewGraphicBlob, updateCounter || 0, sourceDatabaseId, savedFromSourceDatabaseId, editLevel, cached, null, costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType);

    
    this._width = width;
    this._height = height;
    this._mass = mass;
    this._cOGType = cOGType;
    this._horizontalCOG = horizontalCOG;
    //this._horizontalCOGOverride = horizontalCOGOverride;
    this._verticalCOG = verticalCOG;
    this._lateralCOG = lateralCOG;
    this._fromLeftOfBodyWidthExternal = fromLeftOfBodyWidthExternal || 0;
    //this._verticalCOGOverride = verticalCOGOverride;
    this._status = status;
    this._type = type;
    this._rearClearance = rearClearance;
    this._internalMountingLength = internalMountingLength;
    this._rearProtrusion = rearProtrusion;
    this._aboveOrBelowFloor = aboveOrBelowFloor || 0;
    this._mechanismHeight = mechanismHeight;
    this._verticalCOGType = verticalCOGType;
    this._lateralCOGType = lateralCOGType;
    this._verticalCOGConfirmed = verticalCOGConfirmed;
    this._lateralCOGConfirmed = lateralCOGConfirmed;
    this._isPinned = isPinned;
    this._fromCentreOfVehicle = 0;
    //this._massOverride = massOverride;

    if (source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        this._horizontalCOGOverride = true;
        this._verticalCOGOverride = true;
        this._massOverride = true;
        this._lateralCOGOverride = true;
    } else {
        this._horizontalCOGOverride = horizontalCOGOverride;
        this._verticalCOGOverride = verticalCOGOverride;
        this._massOverride = massOverride;
        this._lateralCOGOverride = lateralCOGOverride;
    }

    return this;
}
    

Taillift.prototype.getWidth = function () {
    return this._width;
}
Taillift.prototype.setWidth = function (newValue) {
    this._width = newValue;
}
Taillift.prototype.getPlatformHeight = function () {
    return this._height;
}
Taillift.prototype.setPlatformHeight = function (newValue) {
    this._height = newValue;
}
Taillift.prototype.getHeight = function (newValue) {
    return this._height + this._mechanismHeight;
}
Taillift.prototype.getMass = function () {
    return this._mass;
}
Taillift.prototype.setMass = function (newValue) {
    this._mass = newValue;
}
Taillift.prototype.getMassOverride = function () {
    return this._massOverride;
}
Taillift.prototype.setMassOverride = function (newValue) {
    this._massOverride = newValue;
}
Taillift.prototype.getCOGType = function () {
    return this._cOGType;
}
Taillift.prototype.setCOGType = function (newValue) {
    this._cOGType = newValue;
}
Taillift.prototype.getHorizontalCOG = function () {
    return this._horizontalCOG;
}
Taillift.prototype.setHorizontalCOG = function (newValue) {
    this._horizontalCOG = newValue;
}
Taillift.prototype.getHorizontalCOGOverride = function () {
    return this._horizontalCOGOverride;
}
Taillift.prototype.setHorizontalCOGOverride = function (newValue) {
    this._horizontalCOGOverride = newValue;
}
Taillift.prototype.getVerticalCOG = function () {
    return this._verticalCOG;
}
Taillift.prototype.setVerticalCOG = function (newValue) {
    this._verticalCOG = newValue;
}
Taillift.prototype.getVerticalCOGOverride = function () {
    return this._verticalCOGOverride;
}
Taillift.prototype.setVerticalCOGOverride = function (newValue) {
    this._verticalCOGOverride = newValue;
}
Taillift.prototype.getLateralCOG = function () {
    return this._lateralCOG;
}
Taillift.prototype.setLateralCOG = function (newValue) {
    this._lateralCOG = newValue;
}
Taillift.prototype.getLateralCOGOverride = function () {
    return this._lateralCOGOverride;
}
Taillift.prototype.setLateralCOGOverride = function (newValue) {
    this._lateralCOGOverride = newValue;
}
Taillift.prototype.getFromLeftOfBodyWidthExternal = function () {
    return this._fromLeftOfBodyWidthExternal;
}
Taillift.prototype.setFromLeftOfBodyWidthExternal = function (newValue) {
    this._fromLeftOfBodyWidthExternal = newValue;
}
Taillift.prototype.getFrontAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return utils.getTurningMomentsFront(this._mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Taillift.prototype.getRearAxleLoad = function (distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    return this.getTotalLoad() - this.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
}
Taillift.prototype.getTotalLoad = function () {
    return this._mass;
}
Taillift.prototype.getStatus = function () {
    return this._status;
}
Taillift.prototype.setStatus = function (newValue) {
    this._status = newValue;
}
Taillift.prototype.getType = function () {
    return this._type;
}
Taillift.prototype.setType = function (newValue) {
    this._type = newValue;
}
Taillift.prototype.getFromBackOfBody = function () {
    return this._rearClearance;
}
Taillift.prototype.setFromBackOfBody = function (newValue) {
    this._rearClearance = newValue;
}
Taillift.prototype.getMechanismLength = function () {
    return this._internalMountingLength;
}
Taillift.prototype.setMechanismLength = function (newValue) {
    this._internalMountingLength = newValue;
}
Taillift.prototype.getPlatformProtrusion = function () {
    return this._rearProtrusion;
}
Taillift.prototype.setPlatformProtrusion = function (newValue) {
    this._rearProtrusion = newValue;
}
Taillift.prototype.getAboveOrBelowFloor = function () {
    return this._aboveOrBelowFloor;
}
Taillift.prototype.setAboveOrBelowFloor = function (newValue) {
    this._aboveOrBelowFloor = newValue;
}
Taillift.prototype.getMechanismHeight = function () {
    return this._mechanismHeight;
}
Taillift.prototype.setMechanismHeight = function (newValue) {
    this._mechanismHeight = newValue;
}
Taillift.prototype.getVerticalCOGType = function () {
    return this._verticalCOGType;
}
Taillift.prototype.setVerticalCOGType = function (newValue) {
    this._verticalCOGType = newValue;
}
Taillift.prototype.getLateralCOGType = function () {
    return this._lateralCOGType;
}
Taillift.prototype.setLateralCOGType = function (newValue) {
    this._lateralCOGType = newValue;
}
Taillift.prototype.getVerticalCOGConfirmed = function () {
    return this._verticalCOGConfirmed;
}
Taillift.prototype.setVerticalCOGConfirmed = function (newValue) {
    this._verticalCOGConfirmed = newValue;
}
Taillift.prototype.getLateralCOGConfirmed = function () {
    return this._lateralCOGConfirmed;
}
Taillift.prototype.setLateralCOGConfirmed = function (newValue) {
    this._lateralCOGConfirmed = newValue;
}
Taillift.prototype.getIsPinned = function () {
    return this._isPinned;
}
Taillift.prototype.setIsPinned = function (newValue) {
    this._isPinned = newValue;
}
Taillift.prototype.getFromCentreOfVehicle = function () {
    return this._fromCentreOfVehicle;
}
Taillift.prototype.setFromCentreOfVehicle = function (newValue) {
    this._fromCentreOfVehicle = newValue;
}
Taillift.prototype.getActualHorizontalCOG = function () {
    if (this._cOGType === config.COG_TYPES.LENGTH) {
        return this._horizontalCOG;
    }else {
        return this._horizontalCOG * (this._internalMountingLength + this._rearProtrusion) / 100;
    }
}
Taillift.prototype.getActualVerticalCOG = function () {
    if (this._verticalCOGType === config.COG_TYPES.LENGTH) {
        return this._verticalCOG;
    } else {
        return this._verticalCOG * this.getHeight() / 100;
    }
}
Taillift.prototype.getActualLateralCOG = function () {
    if (this._lateralCOGType === config.COG_TYPES.LENGTH) {
        return this._lateralCOG;
    } else {
        return this._lateralCOG * this._width / 100;
    }
}
Taillift.prototype.getActualHeight = function () {
    return this._height + this._mechanismHeight;
}

/**
 * Get the hitch JSON object to be saved to database
 * @param {boolean} [isCustomFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0 (If key is not included in object then cloud services defaults it to 0).
 * @returns {object}
 */
Taillift.prototype.getTailliftObjectToBeSaved = function (includePositionValues) {
    var saveObj = {};
    
    saveObj["Width"]= this._width;
    saveObj["Height"] = this._height;
    saveObj["Mass"] = this._mass;
    saveObj["MassOverride"] = this._massOverride;
    saveObj["COGType"] = this._cOGType;
    saveObj["HorizontalCOG"] = this._horizontalCOG;
    saveObj["HorizontalCOGOverride"] = this._horizontalCOGOverride;
    saveObj["VerticalCOG"] = this._verticalCOG;
    saveObj["VerticalCOGOverride"] = this._verticalCOGOverride;
    saveObj["Status"] = this._status;
    saveObj["Type"] = this._type;
    
    saveObj["InternalMountingLength"] = this._internalMountingLength;
    saveObj["RearProtrusion"] = this._rearProtrusion;        
    saveObj["MechanismHeight"] = this._mechanismHeight;
    saveObj["LateralCOG"] = this._lateralCOG;
    saveObj["LateralCOGOverride"] = this._lateralCOGOverride;
    saveObj["VerticalCOGType"] = this._verticalCOGType;
    saveObj["LateralCOGType"] = this._lateralCOGType;
        
    if (includePositionValues === true) {
        // These two attributes are the same attribute named differnetly in OFFER_TAILLIFT (AboveOrBelowFloor) and CUSTOM_TAILLIFT (AboveOrBelowSubframe). In any given situation (calculation save or custom trailer save) the controller will only use 
        // the appropriate one so it's ok to include both
        saveObj["AboveOrBelowSubframe"] = this._aboveOrBelowFloor;
        saveObj["AboveOrBelowFloor"] = this._aboveOrBelowFloor;
        saveObj["RearClearance"] = this._rearClearance;
        saveObj["FromLeftOfBodyWidthExternal"] = this._fromLeftOfBodyWidthExternal;
    } 

    return saveObj;

    //return {
    //    "Width": this._width,
    //    "Height": this._height,
    //    "Mass": this._mass,
    //    "MassOverride": this._massOverride,
    //    "COGType": this._cOGType,
    //    "HorizontalCOG": this._horizontalCOG,
    //    "HorizontalCOGOverride": this._horizontalCOGOverride,
    //    "VerticalCOG": this._verticalCOG,
    //    "VerticalCOGOverride": this._verticalCOGOverride,
    //    "Status": this._status,
    //    "Type": this._type,
    //    "RearClearance": this._rearClearance,
    //    "InternalMountingLength": this._internalMountingLength,
    //    "RearProtrusion": this._rearProtrusion,
    //    "AboveOrBelowFloor": this._aboveOrBelowFloor,
    //    "MechanismHeight": this._mechanismHeight
    //};
}
/**
 * Get JSON object to be saved to database
 * @param {boolean} [isCustomSaveFlag]
 * @param {boolean} [includePositionValues] - True = include position values in object. False = Set position values to 0.
 * @returns {object}
*/
Taillift.prototype.returnObjectToBeSaved = function (includePositionValues) {
    return this.getSaveObject(this.getTailliftObjectToBeSaved(includePositionValues), includePositionValues);
}

Taillift.prototype.clone = function (keepSameId) {
    var cloneId;
    if (keepSameId !== undefined && keepSameId === true) {
        cloneId = this.getId();
    } else {
        cloneId = globals.nextId();
    }
    var newTaillift = new Taillift(
        cloneId,
        this.getParent(),
        this.getName(),
        this.getDescription(),
        this.getSource(),
        this.getHorizontalGap(),
        this.getVerticalGap(),
        this.getAccessoryType(),
        this.getGraphicBlob(),
        this.getTopViewGraphicBlob(),
        this.getSavedFromSourceDatabaseId(),
        this.getEditLevel(),
        this._width,
        this._height,
        this._mass,
        this._massOverride,
        this._cOGType,
        this._horizontalCOG,
        this._horizontalCOGOverride,
        this._verticalCOG,
        this._verticalCOGOverride,
        this._lateralCOG,
        this._lateralCOGOverride,
        this._fromLeftOfBodyWidthExternal,
        this._status,
        this._type,
        this._rearClearance,
        this._internalMountingLength,
        this._rearProtrusion,
        this._aboveOrBelowFloor,
        this._mechanismHeight,
        this._verticalCOGType,
        this._lateralCOGType,
        this._verticalCOGConfirmed,
        this._lateralCOGConfirmed,
        this.getSourceDatabaseId(),
        this.getUpdateCounter(),
        this.getCached(),
        this.getCostingListPrice(),
        this.getCostingListPriceOverride(),
        this.getCostingDiscount(),
        this.getCostingPeriod(),
        this.getCostingInterestRate(),
        this.getCostingResidualRate(),
        this.getCostingResidualRateType());

    newTaillift.setAccessLevel(this.getAccessLevel());
    newTaillift.setDecompressedGraphicBlob(this.getDecompressedGraphicBlob());
    newTaillift.setDecompressedTopViewGraphicBlob(this.getDecompressedTopViewGraphicBlob());
    newTaillift.setFromCentreOfVehicle(this.getFromCentreOfVehicle());

    return newTaillift;
}

Taillift.prototype.update = function (taillift) {
    this.updateBase(taillift);
    this.setWidth(taillift.getWidth());
    this.setPlatformHeight(taillift.getPlatformHeight());
    this.setMass(taillift.getMass());
    this.setMassOverride(taillift.getMassOverride());
    this.setCOGType(taillift.getCOGType());
    this.setHorizontalCOG(taillift.getHorizontalCOG());
    this.setHorizontalCOGOverride(taillift.getHorizontalCOGOverride());
    this.setVerticalCOG(taillift.getVerticalCOG());
    this.setVerticalCOGOverride(taillift.getVerticalCOGOverride());
    this.setLateralCOG(taillift.getLateralCOG());
    this.setLateralCOGOverride(taillift.getLateralCOGOverride());
    this.setFromLeftOfBodyWidthExternal(taillift.getFromLeftOfBodyWidthExternal());
    this.setStatus(taillift.getStatus());
    this.setType(taillift.getType());
    this.setFromBackOfBody(taillift.getFromBackOfBody());
    this.setMechanismLength(taillift.getMechanismLength());
    this.setPlatformProtrusion(taillift.getPlatformProtrusion());
    this.setAboveOrBelowFloor(taillift.getAboveOrBelowFloor());
    this.setMechanismHeight(taillift.getMechanismHeight());
    this.setVerticalCOGType(taillift.getVerticalCOGType());
    this.setLateralCOGType(taillift.getLateralCOGType());
    this.setVerticalCOGConfirmed(taillift.getVerticalCOGConfirmed());
    this.setLateralCOGConfirmed(taillift.getLateralCOGConfirmed());
    this.setFromCentreOfVehicle(taillift.getFromCentreOfVehicle());
}

utils.extend(Accessory, Taillift);

export default Taillift;

