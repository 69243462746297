import config from '../..//services/config';

var Circle = function (centreX, centreY, radius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator) {

    var self = this;
    //constructor
    self.centre = { x: centreX, y: centreY };
    
    self.radius = radius;
    self.fillColour = config.DRAWING_COLOURS.WHITE;
    self.fill = fill;
    self.blockName = blockName;
    self.layerNumber = layerNumber;
    self.circleType = circleType;
    self.objNumSeqNum = objNumSeqNum;
    self.closedIndicator = closedIndicator;
    self.type = 'circle';
    self.dashEnabled = true;
    self.dash = 1;
    if (objNumSeqNum !== undefined && objNumSeqNum.indexOf('_') > -1) {
        self.objectNumber = parseInt(objNumSeqNum.substring(0, objNumSeqNum.lastIndexOf("_")));
        self.sequenceNumber = parseInt(objNumSeqNum.substring(objNumSeqNum.lastIndexOf("_") + 1));
    } 
    self.axlePosition = -1;
    self.groundLineY = -1;


    if (blockName !== undefined && blockName.indexOf('DASHED') > -1) {
        self.dashEnabled = true;
    } else {
        self.dashEnabled = false;
    }
    self.setDashed = function (dashedBool) {
        self.dashEnabled = dashedBool;
    }
    self.setDashLength = function (dashLength) {
        self.dash = dashLength;
    }

    self.setShowAsWarning = function (showAsWarning) {
        self.showAsWarning = showAsWarning;
    }

    self.drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
        return self;
    }
    self.setDrawingGroupName = setDrawingGroupName;
    //self.drawAxleLetter
    self.setColour = function (colour) {
        self.colour = colour;
    }

    self.drawGroundline = false;
    self.setDrawGroundline = function (newValue) {
        self.drawGroundline = newValue;
    }

    self.bypassHitAreaSort = false;
    self.setBypassHitAreaSort = function (newValue) {
        self.bypassHitAreaSort = newValue;
    }

    self.setDrawingOrder = function (newValue) {
        self.drawingOrder = newValue;
    }

    self.setPulse = function (newValue) {
        self.pulse = newValue;
    }

    self.setPulseColour = function (colour) {
        self.pulseColour = colour;
    }

    self.setBringToFront = function (newValue) {
        self.bringToFront = newValue;
    }

    self.setLineWidth = function (newValue) {
        self.lineWidth = newValue;
    }
};


//var Circle = function (meta) {

//    var self = this;

//    self.centre = { x: meta.centreX, y: meta.centreY };
//    self.radius = meta.radius;

//    if (meta.dataType === "FULL") {
//        self.fill = meta.fill;
//        self.blockName = meta.blockName;
//        self.layerNumber = meta.layerNumber;
//        self.circleType = meta.circleType;
//        self.objNumSeqNum = meta.objNumSeqNum;
//        self.closedIndicator = meta.closedIndicator;
        
//    } else {
//        self.circleType = meta.circleType;
//    }

//    self.type = 'circle';
//};

export default Circle;

    