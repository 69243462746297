

var SpecificationAdvantage = function (description) {
    //new SpecificationAttribute(attributes[i].HeadingCode, attributes[i].Value)
    var self = this;
    //properties
    var _description;

    function getDescription() {
        return _description;
    }

    function setDescription(newValue) {
        _description = newValue;
    }

    setDescription(description);

    self.description = description;
    self.getDescription = getDescription;

};

export default SpecificationAdvantage;

