<template>
    <template v-if="item.isSavedOffer !== undefined && item.isSavedOffer === true">
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" 
                @click.stop="toggleCheckbox"
                data-bind="click: $parent.toggleCheckbox, clickBubble: false">
                <i class="far fa-lg"
                    :class="{ 'fa-square': item.isSelected === false, 'fa-check-square': item.isSelected === true }" 
                    data-bind="css: { 'fa-square': isSelected() === false, 'fa-check-square': isSelected() === true }"></i>
            </div>
        </td>
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-fa-icon save-icon">
                <i class="fas fa-save fa-lg"></i>
            </div>
        </td>
    </template>
    <td v-if="item.isSharedOffer !== undefined && item.isSharedOffer === true">
        <div class="selection-datagrid-type-icon"></div>
    </td>
    <td>
        <div class="datagrid-description-div">
            <span data-bind="text: descriptionDisplay">{{ item.descriptionDisplay }}</span>
        </div>
        <div v-if="wizard.displaySearchResultsBreadcrumb === true"
            class="datagrid-path-div">
            <a href="#" 
                @click.stop="navigateToFolder()"
                data-bind="click: function (item, event) { navigateToFolder(item, event) }">
                <span data-bind="text: folderPath">{{ item.folderPath }}</span>
            </a>
        </div>
    </td>
    <td>
        <span>{{ item.PreparedForName }}</span>
    </td>
    <td>
        <span>{{ item.ModifiedByName }}</span>
    </td>
    <td>
        <span>{{ item.getFormattedOfferDate() }}</span>
    </td>

    <!-- <td>
        'template-selection-datagrid-saved-row'
    </td> -->
    
    <!-- <td v-if="item.isSavedOffer !== undefined && item.isSavedOffer === true" class="datagrid-icon-td">
        <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" @click="toggleCheckbox(item)">
            <i class="far fa-square fa-lg"
                :class="[{ 'fa-square': item.isSelected === false }, { 'fa-check-square': item.isSelected === true }]"></i>
        </div>
    </td>
    <td v-if="item.isSavedOffer !== undefined && item.isSavedOffer === true" class="datagrid-icon-td">
        <div class="selection-datagrid-fa-icon save-icon">
            <i class="fas fa-save fa-lg"></i>
        </div>
    </td>
    <td v-if="item.isSavedOffer !== undefined && item.isSavedOffer === true">
        <div class="selection-datagrid-type-icon"></div>
    </td>
    <td>
        <div class="datagrid-description-div">
            <span>{{ item.descriptionDisplay }}</span>
        </div>
        <div v-if="wizard.displaySearchResultsBreadcrumb === true" class="datagrid-path-div">
            <a href="#" @click.prevent="navigateToFolder(item)">
                <span>{{item.folderPath}}</span>
            </a>
        </div>
    </td>
    <td>
        <span>{{ item.PreparedForName }}</span>
    </td>
    <td>
        <span>{{ item.ModifiedByName }}</span>
    </td>
    <td>
        <span>{{ item.getFormattedOfferDate }}</span>
    </td> -->
</template>

<script>
import { ref, watch } from 'vue';

const TScMessenger = window.TScMessenger;

export default {
    props: {
        wizardFromParent: Object,
        itemFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);
        let item = ref(props.itemFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        function toggleCheckbox(itemCicked) {
            wizard.value.activeWizardStep.toggleCheckbox(item.value);
        }

        function navigateToFolder() {
            item.value.navigateToFolder(item.value);
        }
        return {
            wizard,
            item,
            toggleCheckbox,
            navigateToFolder
        }
    }
}
</script>

<style lang="scss" scoped>
</style>