<template>
    <div class="table-cell-sort-icon-container">
        <div @click="moveUpClicked"
            class="menu-checkbox-div move-row-up-icon-div">
            <i class="fa fa-chevron-circle-up fa-lg"></i>
        </div>
        <div @click="moveDownClicked"
            class="menu-checkbox-div move-row-down-icon-div">
            <i class="fa fa-chevron-circle-down fa-lg"></i>
        </div>
    </div>
</template>

<script>
import { toRef } from 'vue';

export default {
    name: 'MenuTableCellAllowChangeSortRow',
    props: {
        cellObjectFromParent: Object
    },
    emits: ['moveUpClicked', 'moveDownClicked'],
    setup (props, context) {
        const cell = toRef(props, "cellObjectFromParent");

        function moveUpClicked() {
            context.emit("moveUpClicked");
        }

        function moveDownClicked() {
            context.emit("moveDownClicked");
        }

        return {
            cell,
            moveUpClicked,
            moveDownClicked
        }
    }
}
</script>

<style lang="scss" scoped>
.table-cell-sort-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding: 0 5px;
    // height: 100%;
    // width: 100%;
    width: 50px;
    // padding-top: 5px
    padding-top: 4px
}

.menu-checkbox-div {
    cursor: pointer;
    height: 16px;
    width: 14px;
    font-size: 13px;
    margin-right: 15px;
}
</style>