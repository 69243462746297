<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox specification-width terms-and-conditions-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title"><span>{{ title }}</span></h4>
            </div>
            <div class="modal-body help-modal-body">
                <div v-html="text"
                    data-bind="html: text">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" @click="ok" @touchstart="ok"><span>{{ getTranslationText('271') }}</span></button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import PopupModal from './PopupModal.vue';
import { ref, toRef } from 'vue';

export default {
    name: 'TermsAndConditionsModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup: (props, context) => {
        // Private variables
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const title = ref('');
        const text = ref('');
        
        let resolvePromise,
            displayPrivacyPolicy = opts.value.modalObject.displayPrivacyPolicy;

        if(displayPrivacyPolicy) {
            title.value = config.getTranslationText('310');
            text.value = config.getTranslationText('2400');
        } else {
            title.value = config.getTranslationText('478');
            text.value = config.getTranslationText('2401');
        }
        
        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function ok(clickedButton) {
            this.popup.close();
            resolvePromise(clickedButton.currentTarget.innerText);
        }

        return {
            popup,
            title,
            text,
            show,
            ok,
            getTranslationText: config.getTranslationText
        };

        
    }
   
}
</script>
