import config from '../../../services/config';
import utils from '../../../services/utils';
import ComplianceDashboardItem from './complianceDashboardItem';


var ComplianceDashboardIcon = function () {

    ComplianceDashboardItem.call(this, config.COMPLIANCE_DASHBOARD_ITEM_TYPES.ICON);

    this._id;
    this._icon;
    this._iconColour;
    this._iconSize;
    this._interaction;
    this._backgroundColour;


    return this;
}


ComplianceDashboardIcon.prototype.setIcon = function(newValue) {
    this._icon = newValue;
    return this;
}

ComplianceDashboardIcon.prototype.getIcon = function() {
    return this._icon;
}

ComplianceDashboardIcon.prototype.setIconColour = function(newValue) {
    this._iconColour = newValue;
    return this;
}

ComplianceDashboardIcon.prototype.getIconColour = function() {
    return this._iconColour;
}

ComplianceDashboardIcon.prototype.setIconSize = function(newValue) {
    this._iconSize = newValue;
    return this;
}

ComplianceDashboardIcon.prototype.getIconSize = function() {
    return this._iconSize;
}

//ComplianceDashboardIcon.prototype.setInteraction = function(newValue) {
//     _interaction = newValue;
//     _interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(_interaction.callbacks);
//     return self;
// }

//ComplianceDashboardIcon.prototype.getInteraction = function() {
//     return _interaction;
// }

//ComplianceDashboardIcon.prototype.setBackgroundColour = function(newValue) {
//     _backgroundColour = newValue;
//     return self;
// }

//ComplianceDashboardIcon.prototype.getBackgroundColour = function() {
//     return _backgroundColour;
// }

    



utils.extend(ComplianceDashboardItem, ComplianceDashboardIcon);


export default ComplianceDashboardIcon;


