<template>
    <div 
        class="override-updownbox-div" 
        data-bind="template: { name: 'template-settings-upDownBox-element' }, css: customCss">
        <span>Text box goes here</span>
    </div>
    <!-- ko if: type === $root.config.INPUT_TYPE.UPDOWNBOX
    <div class="override-updownbox-div" data-bind="template: { name: 'template-settings-upDownBox-element' }, css: customCss"></div>
    /ko -->
    <!-- <script type="text/html" id="template-settings-upDownBox-element">
        <span>Text box goes here</span>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: "MenuTableCellInputUpDownBox",
    props: {
        cellObjectFromParent: Object
    },
    setup(props) {
        const cell = toRef(props, "cellObjectFromParent");
        return {
            cell
        };
    },
}
</script>

<style lang="scss" scoped>

</style>