<template>
    <!-- <thead> -->
        <th v-for="column in wizard.activeWizardStep.columns" :key="column" :class="column.headerThClass">
            <div class="select-all-checkbox-div" v-if="column.includeSelectAllCheckbox === true"
                @click="wizard.activeWizardStep.toggleSelectAllCheckbox" :class="column.headerClass">
                <i class="far fa-lg"
                    :class="[{ 'fa-square': column.isChecked.value === SELECT_ALL_CHECKBOX_OPTIONS.NOT_CHECKED }, { 'fa-check-square': column.isChecked.value === SELECT_ALL_CHECKBOX_OPTIONS.CHECKED }, { 'fa-minus-square': column.isChecked.value === SELECT_ALL_CHECKBOX_OPTIONS.MINUS }]"></i>
            </div>
            <div v-else @click="wizard.updateSort(column)"
                :class="[
                    column.headerClass,
                    { 'active-sort-column': column.activeSort.value === true }
                ]">
                <span v-if="column.nameDisplayed">{{ column.name }}</span>
                <template v-if="column.sortable">
                    <i class="fa" aria-hidden="true" 
                        :class="[
                            { 'fa-chevron-down': column.sortType.value === SORT_OPTIONS.DESC && column.activeSort.value !== true }, 
                            { 'fa-chevron-up': column.sortType.value === SORT_OPTIONS.ASC && column.activeSort.value !== true }, 
                            { 'fas fa-chevron-circle-down': column.sortType.value === SORT_OPTIONS.DESC && column.activeSort.value === true }, 
                            { 'fas fa-chevron-circle-up': column.sortType.value === SORT_OPTIONS.ASC && column.activeSort.value === true }, 
                            { 'datagrid-active-sort': column.activeSort === true }]"></i>
                </template>
                <!-- <i class="fa" aria-hidden="true" v-if="column.sortable"
                    :class="[{ 'fa-chevron-down': column.sortType.value === SORT_OPTIONS.DESC }, { 'fa-chevron-up': column.sortType.value === SORT_OPTIONS.ASC }, { 'datagrid-active-sort': column.activeSort === true }]"></i> -->
            </div>
        </th>
    <!-- </thead> -->
</template>

<script>
import config from '@/services/config';
import { ref, watch } from 'vue';

export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        return {
            wizard,
            SORT_OPTIONS: config.SORT_OPTIONS,
            SELECT_ALL_CHECKBOX_OPTIONS: config.SELECT_ALL_CHECKBOX_OPTIONS
        }
    }
}
</script>

<style lang="scss" scoped>
</style>