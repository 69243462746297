import config from '../..//services/config';


var COG = function (xOffset, yOffset, textWidth, textHeight, displayText, style) {
    var self = this;

    self.textBuffer = 4;
    self.type = 'cog';
    self.position = { x: xOffset, y: yOffset };
    self.displayText = displayText;
    self.width = textWidth + self.textBuffer * 2;
    self.height = textHeight + self.textBuffer * 2;
    self.style = style || config.COG_ELEMENT_STYLE.DRAW_LEFT;
    self.markerRadius = self.height;

    function addInteraction(interactionObj) {
        self.interaction = interactionObj;
        self.customFontColour = config.DRAWING_COLOURS.BLUE;
        return self;
    }

    self.drawingGroupName = 'NOT_SET';
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
    }
    function setDrawMarkerOnly(markerOnlyBool) {
        self.drawMarkerOnly = markerOnlyBool;
    }
    function setColour(colour) {
        self.colour = colour;
    }
    function changeTypeToCOGGroup() {
        self.type = 'cogGroup';
    }

    self.setDrawingGroupName = setDrawingGroupName;
    self.addInteraction = addInteraction;
    self.setDrawMarkerOnly = setDrawMarkerOnly;
    self.setColour = setColour;
    self.changeTypeToCOGGroup = changeTypeToCOGGroup;
    
};

export default COG;

