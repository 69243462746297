<template>    
    <div>
        'template-menu-combobox'
    </div>

    <!-- <script type="text/html" id="template-menu-item">
     ko if: inputType === $root.config.INPUT_TYPE.COMBOBOX 
    <tr class="menu-li menu-li-value" data-bind="visible: permissionObv().visible, template: { name: 'template-menu-combobox' }"></tr>
     /ko      
</script> -->

<!-- <script type="text/html" id="template-menu-combobox">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
        </div>
    </td>
    <td class="menu-value-td menu-select-td">
        <div class="flex-center menu-flex-end">
            <div class="menu-tooltip-div"></div>
            <div class="menu-combobox-div">
                <select data-bind="options: displayArray, optionsText: 'description', optionsValue: 'id', value: valueObv, attr: {id: id, title: errorMessageObv, 'data-original-title': errorMessageObv}" data-toggle="tooltip"></select>
            </div>
        </div>
    </td>
</script> -->
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>