

import Globalize from '@/assets/scripts/globalize/globalize';

function TollFee(initTollFeeObject) {
    var self = this;

    var _vehicleClassId = initTollFeeObject.TollClassId,
        _vehicleClassDescription = initTollFeeObject.TollClassDescription,
        _vehicleClassName = initTollFeeObject.TollClassName,
        _fee = initTollFeeObject.Fee;

    //#region Getters and Setters
    function getVehicleClassId() {
        return _vehicleClassId;
    }
    function setVehicleClassId(newValue) {
        _vehicleClassId = newValue;
    }
    function getVehicleClassDescription() {
        return _vehicleClassDescription;
    }
    function setVehicleClassDescription(newValue) {
        _vehicleClassDescription = newValue;
    }
    function getVehicleClassName() {
        return _vehicleClassName;
    }
    function setVehicleClassName(newValue) {
        _vehicleClassName = newValue;
    }
    function getFee() {
        return _fee;
    }
    function setFee(newValue) {
        _fee = newValue;
    }
    function getFeeFormatted(currencySymbol) {
        return currencySymbol + ' ' + Globalize.format(getFee(), "n2")
    }
    //#endregion Getters and Setters

    self.getVehicleClassId = getVehicleClassId;
    self.setVehicleClassId = setVehicleClassId;
    self.getVehicleClassDescription = getVehicleClassDescription;
    self.setVehicleClassDescription = setVehicleClassDescription;
    self.getVehicleClassName = getVehicleClassName;
    self.setVehicleClassName = setVehicleClassName;
    self.getFee = getFee;
    self.setFee = setFee;
    self.getFeeFormatted = getFeeFormatted;
}

export default TollFee;