<template>
    <div class="override-updownbox-div" 
        :class="upDownBox.customCss">
        <div
            @click="inputDivClick">
            <input class="form-control login-input menu-input menu-input-number default-width-tel"
                type="tel" v-model.lazy="upDownBox.value.value"
                @click="inputClickFunction"
                @focus="inputFocusFunction"
                @blur="upDownBox.revertErrorValueOnBlur"
                @keyup.enter="handleEnterKey"
                :readonly="isDisabled()"
                :id="upDownBox.id"
                :title="upDownBox.override.value === false ? upDownBox.overrideTooltipMessage : upDownBox.errorMessageObv.value"
                :class="{ 'override-false': addOverrideFalseClass(), 'override-cursor': addOverrideCursorClass() }"
                data-toggle="tooltip" />
        </div>
        <div v-if="displayOverride()"
            class="override-updownbox-checkbox" @click="upDownBox.toggleOverride"
            :class="{ 'override-false': upDownBox.override.value === false }" :id="upDownBox.id"
            :title="upDownBox.override.value === false ? upDownBox.overrideTooltipMessage : ''"
            data-toggle="tooltip">
            <i class="far fa-lg"
                :class="{ 'fa-check-square': upDownBox.override.value === true, 'fa-square': upDownBox.override.value === false }"
                data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
        </div>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: inputType === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }"></div>
        /ko
        ko if: inputType === $root.config.INPUT_TYPE.UPDOWNBOX
        <div class="override-updownbox-div" data-bind="template: { name: 'template-menu-upDownBox-element' }, css: customCss"></div>
        /ko
        ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP
        ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU
        <div class="override-updownbox-div override-menuInputGroup-upDownBoxAndSelect-div" data-bind="template: { name: 'template-menu-menuInputGroup-upDownBoxAndSelect-element' }, css: customCss"></div>
        /ko
        /ko
    </script> -->

    <!-- <script type="text/html" id="template-menu-upDownBox-element">
        <div data-bind="click: function() { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false ) { toggleOverride()}  }, touchstart: function () { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false) {  toggleOverride() } }">
            <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel" data-bind="value: value, click: isMac === true ? selectAllText : null,disable: override() === false || permissionObv().readOnly === true, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, title: override() === false ? overrideTooltipMessage : errorMessageObv, 'data-original-title': errorMessageObv}, css: {'override-false': override() === false || permissionObv().readOnly === true, 'override-cursor' : override() === false && permissionObv().readOnly === false}" data-toggle="tooltip" />
        </div>
        ko if: permissionObv().readOnly === false && permissionObv().override === true
        <div class="override-updownbox-checkbox" data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: {id: id, title: override() === false ? overrideTooltipMessage: '' }" data-toggle="tooltip">
            <i class="far fa-square fa-lg" data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
        </div>
        /ko
    </script> -->
</template>

<script>
import { toRef, nextTick, isRef } from 'vue';

const $ = window.$;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        const upDownBox = toRef(props, "valueFromParent");

        function inputDivClick() {
            if (upDownBox.value.permissionObv.value.readOnly === false && upDownBox.value.permissionObv.value.override === true && upDownBox.value.override.value === false) { 
                upDownBox.value.toggleOverride();
            }
        }

        function inputClickFunction() {
            if(upDownBox.value.isMac === true) {
                upDownBox.value.selectAllText();
            }
        }

        function inputFocusFunction() {
            if(upDownBox.value.isMac === false) {
                upDownBox.value.selectAllText();
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + upDownBox.value.id).val(upDownBox.value.value.value);
            });
        }

        function displayOverride() {
            if(isRef(upDownBox.value.permissionObv)) {
                return upDownBox.value.permissionObv.value.readOnly === false && upDownBox.value.permissionObv.value.override === true;
            } else {
                return upDownBox.value.permissionObv.readOnly === false && upDownBox.value.permissionObv.override === true;
            }
        }

        function addOverrideFalseClass() {
            if(isRef(upDownBox.value.permissionObv)) {
                return upDownBox.value.override.value === false || upDownBox.value.permissionObv.value.readOnly === true;
            } else {
                return upDownBox.value.override.value === false || upDownBox.value.permissionObv.readOnly === true;
            }
        }

        function addOverrideCursorClass() {
            if(isRef(upDownBox.value.permissionObv)) {
                return upDownBox.value.override.value === false && upDownBox.value.permissionObv.value.readOnly === false;
            } else {
                return upDownBox.value.override.value === false && upDownBox.value.permissionObv.readOnly === false;
            }
        }

        function isDisabled() {
            if(isRef(upDownBox.value.permissionObv)) {
                return upDownBox.value.override.value === false || upDownBox.value.permissionObv.value.readOnly === true;
            } else {
                return upDownBox.value.override.value === false || upDownBox.value.permissionObv.readOnly === true;
            }
        }

        return {
            upDownBox,
            inputDivClick,
            inputClickFunction,
            inputFocusFunction,
            handleEnterKey,
            displayOverride,
            addOverrideFalseClass,
            addOverrideCursorClass,
            isDisabled
        }
    }
}
</script>

<style lang="scss" scoped>

</style>