<template>
    <div class="modal-drag-div">
        <i class="fa fa-arrows"></i>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped>

</style>