<template>
    <tr class="settings-modal-select-row" :class="select.rowClass">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label">{{select.label}}</span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="select.displayTooltip === true"
                            :id="select.tooltipId"
                            :data-content="select.tooltipMessage"
                            @click="select.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                    <div v-if="select.displayUpgradeTooltip === true"
                        class="increment-div info-tooltip-div info-upgrade-div">
                        <a :id="select.upgradeTooltipMessageId"
                            :data-content="select.upgradeTooltipMessage"
                            @click="select.showUpgradePopover"
                            data-placement="top"
                            data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                </div>
                <select :id="select.id"
                        v-model="select.obv.value"
                        data-toggle="tooltip">
                        <option v-for="option in select.optionsArray.value" :key="option" :value="option.id">
                            {{ option.description }}
                        </option>
                </select>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'InputTypeSelect',
    props: {
        inputFromParent: Object
    },
    setup (props) {
        const select = toRef(props, "inputFromParent")

        return {
            select
        }
    }
}
</script>

<style lang="scss" scoped>

</style>