<template>
    <tr class="menu-li menu-li-value" 
        :class="textValue.customCss"
        v-visible="isVisible()">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{ textValue.label }}</span>
            </div>
        </td>
        <td class="menu-value-td" v-if="textValue.allowEdit === false && textValue.allowSave === false">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div">
                    <a data-placement="top" v-if="textValue.tooltipMessage !== undefined" @click="textValue.showPopover"
                        :id="textValue.tooltipId" :data-content="tooltipMessageText()">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                <input class="form-control login-input menu-input menu-input-number" type="text"
                    v-model.lazy="textValue.value.value" 
                    @click="focusFunction"
                    @focus="focusFunction"
                    @keyup.enter="handleEnterKey"
                    @blur="textValue.revertErrorValueOnBlur" :id="textValue.id" :maxlength="textValue.maxLength"
                    :title="textValue.errorMessageObv.value" :data-original-title="textValue.errorMessageObv.value"
                    :class="{ 'override-false': isReadOnly() }"
                    :readonly="returnReadOnly()" />
                <div class="increment-div decrement-div"></div>
                <div class="increment-div"></div>
            </div>
        </td>
        <td class="menu-value-td allow-edit-save" v-if="textValue.allowEdit === true || textValue.allowSave === true">
            <div class="flex-center menu-flex-end">
                <input class="form-control login-input menu-input menu-input-number" type="text"
                    v-model.lazy="textValue.value.value"
                    @click="focusFunction"
                    @focus="focusFunction"
                    @blur="textValue.revertErrorValueOnBlur"
                    @keyup.enter="handleEnterKey"
                    :id="textValue.id"
                    :maxlength="textValue.maxLength"
                    :title="textValue.errorMessageObv.value" :data-original-title="textValue.errorMessageObv.value"
                    :readonly="isReadOnly()" :class="{'override-false': isReadOnly()}" />
                <div class="menu-equipment-delete" v-if="textValue.displayEditButton() === true"
                    @click="textValue.editButtonClick" :title="textValue.editButtonTooltipText">
                    <i class="fa fa-lg fa-drafting-compass"></i>
                </div>
                <div class="menu-equipment-delete" v-if="textValue.displaySaveButton() === true"
                    @click="checkBindSaveButton"
                    :title="textValue.saveButtonTooltipText"
                    :class="{ 'save-button-disabled': textValue.showSaveDisabled }">
                    <i class="fas fa-lg fa-save"></i>
                </div>
            </div>
        </td>
    </tr>

    <!-- <div>
        'template-menu-text'
    </div> -->

    <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.VALUE 
     ko if: inputType === $root.config.INPUT_TYPE.TEXT 
    <tr class="menu-li menu-li-value" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menu-text' }"></tr>
     /ko 
     /ko 
</script> -->

    <!-- <script type="text/html" id="template-menu-text">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
        </div>
    </td>
     ko if: allowEdit === false && allowSave === false 
    <td class="menu-value-td">
        <div class="flex-center menu-flex-end">
            <div class="increment-div info-tooltip-div">
                 ko if: tooltipMessage !== undefined 
                <a data-placement="top"
                   data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                    <i class="fa fa-info-circle fa-lg"></i>
                </a>
                 /ko 
            </div>
            <input class="form-control login-input menu-input menu-input-number" type="text" data-bind="value: value, click: isMac === true ? selectAllText : null, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, maxlength: maxLength, title: errorMessageObv, 'data-original-title': errorMessageObv}, disable: permissionObv().readOnly, css: {'override-false': permissionObv().readOnly === true}" />
            <div class="increment-div decrement-div"></div>
            <div class="increment-div"></div>
        </div>
    </td>
     /ko 
     ko if: allowEdit === true || allowSave === true 
    <td class="menu-value-td allow-edit-save">
        <div class="flex-center menu-flex-end">
            <input class="form-control login-input menu-input menu-input-number" type="text" data-bind="value: value, click: isMac === true ? selectAllText : null, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, maxlength: maxLength, title: errorMessageObv, 'data-original-title': errorMessageObv}, disable: permissionObv().readOnly, css: {'override-false': permissionObv().readOnly === true}" />
             ko if: displayEditButton() === true 
            <div class="menu-equipment-delete" data-bind="click: editButtonClick, touchstart: editButtonClick, attr: { title: editButtonTooltipText }">
                <i class="fa fa-lg fa-drafting-compass"></i>
            </div>
             /ko 
             ko if: displaySaveButton() === true 
            <div class="menu-equipment-delete" data-bind="click: showSaveDisabled === true ? null : saveButtonClick, touchstart: showSaveDisabled === true ? null : saveButtonClick, attr: { title: saveButtonTooltipText }, css: {'save-button-disabled': showSaveDisabled}">
                <i class="fa fa-lg fa-save"></i>
            </div>
             /ko 
        </div>
    </td>
     /ko 
</script> -->
</template>

<script>
import { toRef, isRef, nextTick } from 'vue';

const $ = window.$;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const textValue = ref(props.valueFromParent);
        const textValue = toRef(props, "valueFromParent");

        function checkBindSaveButton() {
            return textValue.value.showSaveDisabled === true ? null : textValue.value.saveButtonClick();
        }

        function focusFunction() {
            if(textValue.value.isMac === false) {
                textValue.value.selectAllText();
            }
        }

        function tooltipMessageText() {
            $('a').webuiPopover('destroy');
            if(isRef(textValue.value.tooltipMessage)) {
                return textValue.value.tooltipMessage.value;
            } else {
                return textValue.value.tooltipMessage;
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + textValue.value.id).val(textValue.value.value.value);
            });
        }

        function isVisible() {
            if(isRef(textValue.value.permissionObv) && textValue.value.permissionObv.value) {
                return textValue.value.permissionObv.value.visible;
            } else {
                return textValue.value.permissionObv.visible;
            }
        }

        function isReadOnly() {
            if(isRef(textValue.value.permissionObv) && textValue.value.permissionObv.value) {
                return textValue.value.permissionObv.value.readOnly === true;
            } else {
                return textValue.value.permissionObv.readOnly === true;
            }
        }

        function returnReadOnly() {
            if(isRef(textValue.value.permissionObv) && textValue.value.permissionObv.value) {
                return textValue.value.permissionObv.value.readOnly;
            } else {
                return textValue.value.permissionObv.readOnly;
            }
        }

        return {
            textValue,
            focusFunction,
            checkBindSaveButton,
            tooltipMessageText,
            handleEnterKey,
            isVisible,
            isReadOnly,
            returnReadOnly
        }
    }
}
</script>

<style lang="scss" scoped>
</style>