<template>
    <template v-for="item in wizard.itemsToDisplayArray.value" :key="item">
    
    <tr v-if="item.isSavedOffer === true"
        class="datagrid-row vehicletype-datagrid-row" 
        @click="wizard.clickOnItem(item)"
        data-bind="template: {name: 'template-vehicletype-datagrid-saved-row'}, click: $parents[1].clickOnItem">
        <!-- Template = "template-vehicletype-datagrid-saved-row" -->
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-type-icon datagrid-icon-saved"></div>
        </td>
        <td class="datagrid-column datagrid-column-large datagrid-description">
            <span data-bind="text: descriptionDisplay">{{item.descriptionDisplay}}</span>
        </td>
        <td class="datagrid-icon-td">
            <div 
                class="selection-datagrid-type-icon-small datagrid-icon-delete" 
                @click.stop="wizard.deleteItem(item)"
                :title="wizard.deleteIconTooltipText"
                data-bind="click: $parents[1].deleteItem, clickBubble: false, attr: { title: $parents[1].deleteIconTooltipText }">
                <i class="fa fa-trash-alt fa-lg"></i>
            </div>
        </td>
        <td class="datagrid-icon-td">
            <template v-if="checkOfferCachedIsRef(item)">
                <div v-if="item.OfferCached.value === 0" class="selection-datagrid-type-icon-small datagrid-icon-cloud"></div>
                <div v-if="item.OfferCached.value === 2" class="selection-datagrid-type-icon-small datagrid-icon-cached"></div>
            </template>
            <template v-else>
                <div v-if="item.OfferCached === 0" class="selection-datagrid-type-icon-small datagrid-icon-cloud"></div>
                <div v-if="item.OfferCached === 2" class="selection-datagrid-type-icon-small datagrid-icon-cached"></div>
            </template>
        </td>
    </tr>
    <tr v-else 
        class="datagrid-row vehicletype-datagrid-row" 
        @click="wizard.clickOnItem(item)"
        data-bind="template: {name: 'template-vehicletype-datagrid-row'}, click: $parents[1].clickOnItem">
        <!-- Template = "template-vehicletype-datagrid-row" -->
        <td class="datagrid-icon-td">
            <div v-if="item.isSavedOffer === true" 
                class="selection-datagrid-type-icon datagrid-icon-saved">
            </div>
            <div v-else class="selection-datagrid-type-icon">
                <div class="selection-datagrid-fa-icon save-icon">
                    <i class="fas fa-lg" 
                        :class="{ 'fa-file-alt': item.Source === VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': item.Source === VEHICLE_SOURCE_TYPES.TEMPLATE }"
                        data-bind="css: { 'fa-file-alt': Source === $root.config.VEHICLE_SOURCE_TYPES.STANDARD, 'fa-file': Source === $root.config.VEHICLE_SOURCE_TYPES.TEMPLATE }"></i>
                </div>
            </div>
        </td>
        <td class="datagrid-column datagrid-column-large datagrid-description">
            <span>{{item.Description}}</span>
        </td>
        <td class="datagrid-column">
            <span v-if="userSpecifyChassisLength === SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE">{{item.wheelbaseFormatted.value}}</span>
            <span v-if="userSpecifyChassisLength === SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA">{{item.cabToAxlesFormatted.value}}</span>
        </td>
        <td class="datagrid-column datagrid-gvw">
            <span>{{ item.gvwFormatted.value }}</span>
        </td>
        <td class="datagrid-column datagrid-gcw">
            <span>{{ item.gcwFormatted.value }}</span>
        </td>
        <td class="datagrid-column">
            <span data-bind="text: SpecDate">{{item.SpecDate}}</span>        
        </td>
    </tr>
    </template>

    <!-- <tr>
        <td>template-vehicletype-row</td>
    </tr> -->

    <!-- <script type="text/html" id="template-vehicletype-row">
        !-- ko if: isSavedOffer === false --
        <tr class="datagrid-row" data-bind="template: {name: 'template-vehicletype-datagrid-row'}, click: $parents[1].clickOnItem"></tr>
        !-- /ko --
        !-- ko if: isSavedOffer === true --
        <tr class="datagrid-row" data-bind="template: {name: 'template-vehicletype-datagrid-saved-row'}, click: $parents[1].clickOnItem"></tr>
        !-- /ko --
    </script> -->
</template>

<script>
import config from '../../services/config';
import globals from '../../services/globals';
import { onMounted, onUpdated, toRef, isRef } from 'vue';

export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        const wizard = toRef(props, "wizardFromParent");
        let userSpecifyChassisLength = globals.user.getSpecifyChassisLengthAs();
        // let wizard = ref(props.wizardFromParent);

        // watch(() => props.wizardFromParent,
        //     (newValue, oldValue) => {
        //         wizard.value = newValue;
        //     });
        
        onMounted(() => {
            wizard.value.initializeMultiSelects();
        });

        onUpdated(() => {
            wizard.value.initializeMultiSelects();
        });

        function checkOfferCachedIsRef(item) {
            return isRef(item.OfferCached);
        }

        return {
            wizard,
            checkOfferCachedIsRef,
            userSpecifyChassisLength,
            VEHICLE_SOURCE_TYPES: config.VEHICLE_SOURCE_TYPES,
            SPECIFY_CHASSIS_LENGTH_AS_OPTIONS: config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS
        }
    }
}
</script>

<style lang="scss" scoped>

</style>