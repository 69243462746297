import Instruction from './model.instruction';

var Attribute = function (attribute) {
    var self = this;
    var tempInstructions = attribute.Instructions.split('\r\n');

    self.name = attribute.Name;
    self.instructions = [];
    
    tempInstructions.forEach(function (instruction) {
        self.instructions.push(new Instruction(instruction));
    });
};

export default Attribute;
