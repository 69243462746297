
var HitchLoad = function (xOffset, yOffset, text) {

    var self = this;
    //constructor
    self.centre = { x: xOffset, y: yOffset };
    self.text = text;
    
    self.type = 'hitchLoad';

    self.drawingGroupName = 'NOT_SET';
    self.setDrawingGroupName = function (newValue) {
        self.drawingGroupName = newValue;
    }
};



export default HitchLoad;

       