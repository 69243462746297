<template>
    <template v-if="isVisible()">
        <tr v-if="menuTable.displayLabelRow === true"
            class="template-user-settings-label-and-tooltip-only">
            <td class="user-settings-modal-td-desc">
                <div>
                    <span>{{menuTable.label}}</span>
                </div>
            </td>
            <td class="summary-note-modal-td-desc-input">
                <div class="flex-start">
                    <div class="info-tooltips-container flex-end">
                        <div class="increment-div info-tooltip-div">
                            <a v-if="menuTable.displayTooltip === true"
                                :id="menuTable.tooltipId"
                                :data-content="menuTable.tooltipMessage"
                                @click="menuTable.showPopover"
                                data-placement="top">
                                <i class="fa fa-info-circle fa-lg"></i>
                            </a>
                        </div>
                        <div v-if="menuTable.displayUpgradeTooltip === true"
                            class="increment-div info-tooltip-div info-upgrade-div">
                            <a :id="menuTable.upgradeTooltipMessageId"
                                :data-content="menuTable.upgradeTooltipMessage"
                                @click="menuTable.showUpgradePopover"
                                data-placement="top">
                                <i class="fa fa-chevron-circle-up fa-lg"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr class="menu-li menu-li-value menu-tr-table template-user-settings-menu-table"
            :class="{'no-label-on-table': menuTable.displayLabelRow === false}"
            :id="menuTable.id">
            <td class="menu-td-table" colspan="2">
                <table>
                    <thead v-if="menuTable.includeHeader === true">
                        <tr>
                            <th v-for="cell in menuTable.header.cells" :key="cell">
                                <MenuTableCellText :cellObjectFromParent="cell.cellObject"></MenuTableCellText>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in menuTable.rows.value" :key="row">
                            <td v-for="cell in row.cells" :key="cell" :class="cell.customCssForTd">
                                <MenuTableCellInput v-if="cell.cellType === MENU_TABLE_CELL_TYPES.INPUT" :cellObjectFromParent="cell.cellObject"></MenuTableCellInput>
                                <MenuTableCellAllowChangeSortRow v-else-if="cell.cellType === MENU_TABLE_CELL_TYPES.ALLOW_CHANGE_SORT" 
                                    :cellObjectFromParent="cell.cellObject"
                                    v-on:moveUpClicked="moveUp(row)"
                                    v-on:moveDownClicked="moveDown(row)"
                                >
                                </MenuTableCellAllowChangeSortRow>
                                <MenuTableCellDeleteRow v-else-if="cell.cellType === MENU_TABLE_CELL_TYPES.DELETE_ROW" 
                                    :cellObjectFromParent="cell.cellObject"
                                    v-on:deleteRowClicked="deleteRow(row)"
                                >
                                </MenuTableCellDeleteRow>                            
                                <MenuTableCellText v-else :cellObjectFromParent="cell.cellObject"></MenuTableCellText>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </template>
    
</template>

<script>
import { toRef, onUpdated, isRef } from 'vue';
import MenuTableCellText from './MenuTableCellText.vue';
import MenuTableCellInput from './MenuTableCellInput.vue';
import MenuTableCellDeleteRow from './MenuTableCellDeleteRow.vue';
import MenuTableCellAllowChangeSortRow from './MenuTableCellAllowChangeSortRow.vue';
import config from '@/services/config';

export default {
    name: "ItemTypeMenuTable",
    props: {
        itemFromParent: Object
    },
    setup(props) {
        const menuTable = toRef(props, "itemFromParent");
        
        function deleteRow(rowToDelete) {
            menuTable.value.deleteRow(rowToDelete);
        }

        function moveUp(row) {
            menuTable.value.moveRowUp(row);
        }

        function moveDown(row) {
            menuTable.value.moveRowDown(row);
        }

        function isVisible() {
            if(isRef(menuTable.value.isVisible)) {
                return menuTable.value.isVisible.value;
            } else {
                return menuTable.value.isVisible;
            }
        }

        onUpdated(() => {
            menuTable.value.rows.value.forEach(row => {
                row.createJsElements();
            });
        })

        return {
            menuTable,
            deleteRow,
            moveUp,
            moveDown,
            isVisible,
            MENU_TABLE_CELL_TYPES: config.MENU_TABLE_CELL_TYPES
        };
    },
    components: { MenuTableCellText, MenuTableCellInput, MenuTableCellDeleteRow, MenuTableCellAllowChangeSortRow }
}
</script>

<style lang="scss" scoped>

</style>