

function SummaryReportOfferOption(code, isChecked) {
    this._code = code;
    this._isChecked = isChecked;

    return this;
}

SummaryReportOfferOption.prototype.getCode = function () {
    return this._code;
}
SummaryReportOfferOption.prototype.setCode = function (newValue) {
    this._code = newValue;
}
SummaryReportOfferOption.prototype.getIsChecked = function () {
    return this._isChecked;
}
SummaryReportOfferOption.prototype.setIsChecked = function (newValue) {
    this._isChecked = newValue;
}

export default SummaryReportOfferOption;
