<template>
    <div class="datagrid-wizard-container">
        <table>
            <thead>
                <DatagridHeaderColumns :wizardFromParent="wizard"></DatagridHeaderColumns>
            </thead>
            <tbody>
                <SelectionDatagridSelectionRow v-if="wizard.rowType === WIZARD_ROW_TYPE.SELECTION"
                    :wizardFromParent="wizard"></SelectionDatagridSelectionRow>

                <SelectionDatagridBodyTypeRow v-if="wizard.rowType === WIZARD_ROW_TYPE.BODYTYPE"
                    :wizardFromParent="wizard">
                </SelectionDatagridBodyTypeRow>

                <SelectionDatagridAccessoryTypeRow v-if="wizard.rowType === WIZARD_ROW_TYPE.ACCESSORYTYPE"
                    :wizardFromParent="wizard"></SelectionDatagridAccessoryTypeRow>

                <SelectionDatagridTrailerTypeRow v-if="wizard.rowType === WIZARD_ROW_TYPE.TRAILERTYPE"
                    :wizardFromParent="wizard"></SelectionDatagridTrailerTypeRow>

                <SelectionDatagridVehicleTypeRow v-if="wizard.rowType === WIZARD_ROW_TYPE.VEHICLETYPE"
                    :wizardFromParent="wizard"></SelectionDatagridVehicleTypeRow>
            </tbody>
        </table>
    </div>
</template>

<script>
import { toRef } from 'vue';
import config from '@/services/config';
import DatagridHeaderColumns from '@/components/selectionWizard/DatagridHeaderColumns.vue';
import SelectionDatagridSelectionRow from '@/components/selectionWizard/SelectionDatagridSelectionRow.vue';
import SelectionDatagridBodyTypeRow from '@/components/selectionWizard/SelectionDatagridBodyTypeRow.vue';
import SelectionDatagridAccessoryTypeRow from '@/components/selectionWizard/SelectionDatagridAccessoryTypeRow.vue';
import SelectionDatagridTrailerTypeRow from '@/components/selectionWizard/SelectionDatagridTrailerTypeRow.vue';
import SelectionDatagridVehicleTypeRow from '@/components/selectionWizard/SelectionDatagridVehicleTypeRow.vue';


export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        const wizard = toRef(props, "wizardFromParent");

        return {
            wizard,
            WIZARD_ROW_TYPE: config.WIZARD_ROW_TYPE
        }
    },
    components: { DatagridHeaderColumns, SelectionDatagridSelectionRow, SelectionDatagridBodyTypeRow, SelectionDatagridAccessoryTypeRow, SelectionDatagridTrailerTypeRow, SelectionDatagridVehicleTypeRow }
}
</script>

<style lang="scss" scoped>
</style>