<template>
    <table :class="classesForTable">
        <tbody data-bind="foreach: $data">
            <template v-for="item in items" :key="item">
                <InputTypeSelectVue v-if="item.type === INPUT_TYPE.SELECT" :inputFromParent="item"></InputTypeSelectVue>
                <InputTypeMultiSelectVue v-if="item.type === INPUT_TYPE.MULTISELECT" :inputFromParent="item"></InputTypeMultiSelectVue>
                <InputTypeTelVue v-if="item.type === INPUT_TYPE.TEL" :inputFromParent="item"></InputTypeTelVue>
                <InputTypeCheckboxVue v-if="item.type === INPUT_TYPE.CHECKBOX" :inputFromParent="item"></InputTypeCheckboxVue>
                <InputTypeTextVue v-if="item.type === INPUT_TYPE.TEXT" :inputFromParent="item"></InputTypeTextVue>
                <InputTypeInputGroupVue v-if="item.type === INPUT_TYPE.INPUT_GROUP" :inputFromParent="item"></InputTypeInputGroupVue>
                <InputTypeImageUploadVue v-if="item.type === INPUT_TYPE.IMAGE_UPLOAD" :inputFromParent="item"></InputTypeImageUploadVue>
                <ItemTypeTabGroupVue v-if="item.type === ITEM_TYPE.TAB_GROUP" :itemFromParent="item"></ItemTypeTabGroupVue>
                <ItemTypeMenuTableVue v-if="item.type === ITEM_TYPE.MENU_TABLE" :itemFromParent="item"></ItemTypeMenuTableVue>
            </template>
        </tbody>
    </table>

    <!-- <table class="specification-modal-table user-settings-table" data-bind="template: { name: 'template-user-settings-input-types-tbody', data: legislationItems }"></table> -->
    <!-- <script type="text/html" id="template-user-settings-input-types-tbody">
        <tbody data-bind="foreach: $data">
            ko if: type === $root.config.INPUT_TYPE.SELECT
            <tr class="settings-modal-select-row" data-bind="template: { name: 'template-user-settings-select' }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.MULTISELECT
            <tr class="settings-modal-multiselect-row" data-bind="template: { name: 'template-user-settings-multiselect' }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.TEL
            <tr data-bind="template: { name: 'template-user-settings-tel' }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.CHECKBOX
            <tr data-bind="template: { name: 'template-user-settings-checkbox' }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.TEXT
            <tr data-bind="template: { name: 'template-user-settings-text' }, css: { 'template-user-settings-textarea': $data.useTextArea }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.INPUT_GROUP
            <tr data-bind="template: { name: 'template-user-settings-input-group' }"></tr>
            /ko
            ko if: type === $root.config.ITEM_TYPE.TAB_GROUP
            <tr data-bind="template: { name: 'template-user-settings-tab-group' }"></tr>
            /ko
            ko if: type === $root.config.INPUT_TYPE.IMAGE_UPLOAD
            <tr class="template-user-settings-image-upload" data-bind="template: { name: 'template-user-settings-image-upload' }"></tr>
            /ko
            ko if: type === $root.config.ITEM_TYPE.MENU_TABLE
            <tr class="template-user-settings-label-and-tooltip-only" data-bind="template: { name: 'template-user-settings-label-and-tooltip-only' }"></tr>
            <tr class="menu-li menu-li-value menu-tr-table template-user-settings-menu-table" data-bind="template: { name: 'template-user-settings-menu-table' }"></tr>
            /ko
        </tbody>
    </script> -->
</template>

<script>
import config from '@/services/config';
import { toRef } from 'vue';
import InputTypeSelectVue from './InputTypeSelect.vue';
import InputTypeMultiSelectVue from './InputTypeMultiSelect.vue';
import InputTypeTelVue from './InputTypeTel.vue';
import InputTypeCheckboxVue from './InputTypeCheckbox.vue';
import InputTypeTextVue from './InputTypeText.vue';
import InputTypeInputGroupVue from './InputTypeInputGroup.vue';
import InputTypeImageUploadVue from './InputTypeImageUpload.vue';
import ItemTypeTabGroupVue from './ItemTypeTabGroup.vue';
import ItemTypeMenuTableVue from './ItemTypeMenuTable.vue';

export default {
    name: 'InputTypesTable',
    props: {
        itemsFromParent: Array,
        classFromParent: String
    },
    setup (props) {
        const items = toRef(props, "itemsFromParent");
        const classesForTable = toRef(props, "classFromParent");

        return {
            items,
            classesForTable,
            INPUT_TYPE: config.INPUT_TYPE,
            ITEM_TYPE: config.ITEM_TYPE
        }
    },
    components: { InputTypeSelectVue, InputTypeMultiSelectVue, InputTypeTelVue, InputTypeCheckboxVue, InputTypeTextVue, InputTypeInputGroupVue, ItemTypeTabGroupVue, InputTypeImageUploadVue, ItemTypeMenuTableVue }
}
</script>

<style lang="scss" scoped>

</style>