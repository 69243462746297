<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title"><span v-text="modalTitle"></span></h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span v-text="getTranslationText('2968')"></span>
                    </div>
                    <div>
                        <input id="offerDescription" class="form-control summary-modal-input" maxlength="100" required v-model="description" />
                    </div>
                </div>
                
                <div class="modal-label-input-container folder-container" >
                    <div class="modal-label">
                        <span v-text="getTranslationText('2969')"></span>
                    </div>
                    <div class="folder-container-div">
                        <div id="jstree_demo" class="folder-selection-container"></div>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" @click="ok" @touchstart="ok"><span v-text="getTranslationText('271')"></span></button>
                <button class="btn btn-default btn-truckscience" @click="cancel" @touchstart="cancel"><span v-text="getTranslationText('65')"></span></button>
            </div>
        </div>
    </popup-modal>
</template>

<script>

import config from '@/services/config';
import globals from '@/services/globals';
import utils from '@/services/utils';
import dataManager from '@/services/dataManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import folderManager from '@/services/folderManager/folderManager';
import PopupModal from './PopupModal.vue';
import { ref, shallowRef, toRef, onBeforeMount, onMounted } from 'vue';



const $ = window.$;
    

export default {
    name: 'BodyBuilderOfferSaveModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        
        let resolvePromise = undefined,
        rejectPromise = undefined;

        let isAdminUser = globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code),
        getTranslationText = config.getTranslationText,
        modalTitle = shallowRef(''),
        remainingCharacters = 100,

        selectedFolderDatabaseId = null,
        existingFolders = null,
        jsTreeObject = null,
        setJsTreeObject = function (newValue) { jsTreeObject = newValue; },


        description = shallowRef(''),
        callback = null;

        onBeforeMount(()=>{
            description.value = opts.value.offerDescription;
            callback = opts.value.callbackForResult;
            modalTitle.value = opts.value.saveType === config.SAVE_TYPE.SAVE_AS ? config.getTranslationText('752') : config.getTranslationText('751');
            
            remainingCharacters = 100 - description.value.length;

           
        });
        

        function doOKCallBackAndClose(offerToOverwrite) {
            var pathToFolder = folderManager.convertSelectedNodePathForUseInCloudServices(jsTreeObject.jstree(true));
            cleanUp();
            callback({ Description: description.value, OfferToOverwrite: offerToOverwrite, PathToFolder: pathToFolder });
            popup.value.close();
            resolvePromise()
        }

        function doCancelCallbackAndClose () {
            cleanUp();
            callback();
            popup.value.close();
            resolvePromise();
        }

        function cleanUp() {
            if (jsTreeObject !== null) {
                folderManager.cleanUpInstance(jsTreeObject, setJsTreeObject);
            }
        }

        // onBeforeMount( () => {
        //     var breakHere = 0;
        //     // var pms = $.Deferred();

        //     // dataManager.getFolderTreeStructure().done(function (result) {
        //     //     existingFolders = folderManager.convertFolderDataForUseInFolderManager(result.Folders, 0);
        //     //     pms.resolve({});
        //     // });

        //     // return pms.promise();
        // })

        // onMounted( () => {
        //     $(document).ready(function () {
                
                
        //     });
            
           
        // })

        function show() {
           
            if (opts.value.autoSelectOK !== undefined) {
                ok(opts.value.autoSelectOK);
            }else {
                globals.showSpinner();
                dataManager.getFolderTreeStructure().done(function (result) {
                    globals.hideSpinner();
                    if(result.Result.ReturnCode === 1) {
                        existingFolders = folderManager.convertFolderDataForUseInFolderManager(result.Folders, 0);
                    

                        var typesObj = {
                        default: {
                            icon: 'fas fa-folder',
                            valid_children: ['default', 'new_folder', 'create_new_folder']
                        },
                        new_folder: {
                            icon: 'far fa-folder',
                            valid_children: ['default', 'new_folder', 'create_new_folder']
                        },
                        create_new_folder: {
                            icon: 'fas fa-asterisk'
                        }
                        };
                        // $('#jstree_demo').on("ready.jstree", function (e, data) { 
                        //         //self.setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', self.existingFolders, typesObj, [config.JSTREE_PLUGINS.TYPES, config.JSTREE_PLUGINS.CONTEXTMENU]));
                        //         setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', existingFolders, typesObj));
                                
                        //         folderManager.addEvent(jsTreeObject, config.JSTREE_EVENTS.CORE.CHANGED, folderManager.handleCreateFolderOnSelection);
                        //         popup.value.open(); 
                        //     });
                        // self.setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', self.existingFolders, typesObj, [config.JSTREE_PLUGINS.TYPES, config.JSTREE_PLUGINS.CONTEXTMENU]));
                        // setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', existingFolders, typesObj));
                        
                        // folderManager.addEvent(jsTreeObject, config.JSTREE_EVENTS.CORE.CHANGED, folderManager.handleCreateFolderOnSelection);
                        popup.value.open(); 
                        setTimeout(()=>{
                            setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', existingFolders, typesObj));
                            folderManager.addEvent(jsTreeObject, config.JSTREE_EVENTS.CORE.CHANGED, folderManager.handleCreateFolderOnSelection);
                        }, 0);
                    }else {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        doCancelCallbackAndClose();
                    }
                    
                })
                .fail(function (error) {
                    globals.hideSpinner();
                    dataManager.log("BodyBuilderOfferSaveModal, dataManager.getFolderTreeStructure, fail, 1571", error, 'BodyBuilderOfferSaveModal', config.LOG_MESSAGE_TYPE.ERROR);
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    doCancelCallbackAndClose();
                });
            }
            
            

            // Once we set our config, we tell the popup modal to open
            
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
            
        }

        function ok(autoSelectOK) {
            
            var existingOffers = [];

            if (existingOffers.length > 0 || (autoSelectOK !== undefined && autoSelectOK === true)) {
                var messageText = utils.getOverwriteMessageText(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                globals.showDialog(new CustomMessageBox(messageText, config.applicationTitle, [config.getTranslationText('576'), config.getTranslationText('261')], config.getMessageReference('1611'))).addCallback(function (dialogResult) {
                    if (dialogResult === config.getTranslationText('576')) {
                        doOKCallBackAndClose(existingOffers[0]);
                    }
                });
            } else {
                doOKCallBackAndClose();
            }
            
        }

        function cancel () {
            doCancelCallbackAndClose();
        }

        return {
            show, 
            popup,
            ok,
            cancel,
            getTranslationText,
            modalTitle,
            description
        }
    },
}
</script>