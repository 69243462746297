import config from './config';
import globals from './globals';
// import { config, globals } from '@/services';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import Zlib from '../assets/scripts/zip.min';
// import Zlib from 'zlib';
import JSZip from '../assets/scripts/jszip.min';
import { router, ROUTES } from '@/router';
import * as CryptoJS from 'crypto-js';

const $ = window.$;
var TScMessenger = window.TScMessenger;
// const ROUTES = {};
// methods
var formatNumberRgx = function (num) {

    var parts = num.toString().split(".");

    if (parts.length > 2) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

function setOptions(url, operationType, dataType, beforeSendFunction, apiVersion, asyncTransaction, timeout) {

    if (asyncTransaction === undefined) {
        asyncTransaction = true;
    }

    if (timeout === undefined) {
        timeout = (config.getRequestTimeout*1000);
    }

    var options = {
        url: config.getCloudServicesUrl() + apiVersion + '/' + url,
        type: operationType,
        crossDomain: true,
        dataType: dataType,
        beforeSend: beforeSendFunction,
        async: asyncTransaction,
        timeout: timeout,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    //     xhrFields: {
    //         withCredentials: true
    //    }
    };

    return options;

}

function setPostOptions(url, operationType, dataType, dataObj, beforeSendFunction, apiVersion, asyncTransaction, timeout, server) {

    let fullURL;
    
    if (asyncTransaction === undefined) {
        asyncTransaction = true;
    }

    if (timeout === undefined) {
        timeout = (config.postRequestTimeout*1000);
    }

    if(server === undefined) {
        server = config.SERVER.CLOUD_SERVICES;
    }

    if(server.Type === config.SERVER_TYPE.CLOUD_SERVICES) {
        fullURL = server.BaseURLFunc() + apiVersion + '/' + url;
    }else if(server.Type === config.SERVER_TYPE.AZURE_FUNCTIONS) {
        fullURL = server.BaseURLFunc() + '/api/' + url;
    }

    var options = {
        url: fullURL,
        type: operationType,
        crossDomain: true,
        dataType: dataType,
        data: dataObj,
        beforeSend: beforeSendFunction,
        async: asyncTransaction,
        timeout: timeout
    };

    return options;

}

function setOptionsCDNBaseURL(url, operationType, dataType, beforeSendFunction, asyncTransaction, timeout) {

    if (asyncTransaction === undefined) {
        asyncTransaction = true;
    }

    if (timeout === undefined) {
        timeout = (config.getRequestTimeout * 1000);
    }

    var options = {
        url: config.cdnAddress + url,
        type: operationType,
        crossDomain: true,
        dataType: dataType,
        beforeSend: beforeSendFunction,
        async: asyncTransaction,
        timeout: timeout,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8'
    };

    return options;

}


function generateRoutesBasedOnPermissions(routes, permissions) {
    var returnArray = routes;

    returnArray.push(ROUTES.NOT_FOUND);
    returnArray.push(ROUTES.GET_STARTED);
    returnArray.push(ROUTES.SELECTION);
    
    returnArray.push(ROUTES.EXTERNAL_ACCESS);

    for (var prop in config.PERMISSIONS) {
        var permission = config.PERMISSIONS[prop];
        if ($.inArray(permission.Code, permissions) !== -1) {
            if (permission.Route) {
                returnArray.push(ROUTES[permission.Route]);
            }
        }
    }
    if ($.inArray(config.PERMISSIONS.SPECIFICATION.Code, permissions) === -1 && globals.user.allowSpecificationModule()) {
        returnArray.push(ROUTES[config.PERMISSIONS.SPECIFICATION.Route]);
    }
    returnArray.push(ROUTES.SUMMARY);

    return returnArray;
}

function generateRoutesBasedOnPermissionsAndDataAvailability(routes, permissions, dataAvailability) {
    var returnArray = routes;

    returnArray.push(ROUTES.NOT_FOUND);
    returnArray.push(ROUTES.GET_STARTED);
    returnArray.push(ROUTES.SELECTION);
    
    returnArray.push(ROUTES.EXTERNAL_ACCESS);

    for (var prop in config.PERMISSIONS) {
        var permission = config.PERMISSIONS[prop];
        if ($.inArray(permission.Code, permissions) !== -1) {
            if (permission.Route && (ROUTES[permission.Route].meta.dataAvailabilityCode === undefined || dataAvailability[ROUTES[permission.Route].meta.dataAvailabilityCode] === true)) {
                returnArray.push(ROUTES[permission.Route]);
            }
        }
    }
    if ($.inArray(config.PERMISSIONS.SPECIFICATION.Code, permissions) === -1 && globals.user.allowSpecificationModule() && dataAvailability.ActiveSpecification === true) {
        returnArray.push(ROUTES[config.PERMISSIONS.SPECIFICATION.Route]);
    }
    returnArray.push(ROUTES.SUMMARY);

    return returnArray;
}

function removeAllRoutes() {
    Object.keys(ROUTES).forEach(function (key) {
        if(router.hasRoute(ROUTES[key].name)) {
            router.removeRoute(ROUTES[key].name);
        }
    });
}

function addRoutes(routes) {
    routes.forEach(function (route) {
        router.addRoute(route);
    });
}

function populateUIObservablesFromCustomer(customerObservable, customer) {
    customerObservable.Id.value = customer.getId();
    customerObservable.ContactName.value = customer.getContactName();
    customerObservable.Company.value = customer.getCompany();
    customerObservable.Mobile.value = customer.getMobile();
    customerObservable.DirectNumber.value = customer.getDirectNumber();
    customerObservable.Email.value = customer.getEmail();
    customerObservable.UpdateCounter.value = customer.getUpdateCounter();
    customerObservable.OverallUpdateCounter.value = customer.getOverallUpdateCounter();
}

function getTurningMomentsFront(mass, distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical) {
    if (wheelbaseTheoretical !== 0) {
        return (mass * distanceFromWheelbaseTheoreticalEnd) / wheelbaseTheoretical;
    } else {
        return 0;
    }
}

function inheritsFrom(childObject, parentObject) {
    childObject = Object.create(parentObject.prototype);
}

function extend(base, sub) {
    // Avoid instantiating the base class just to setup inheritance
    // Also, do a recursive merge of two prototypes, so we don't overwrite 
    // the existing prototype, but still maintain the inheritance chain
    // Thanks to @ccnokes
    var origProto = sub.prototype;
    
    sub.prototype = Object.create(base.prototype);
    for (var key in origProto) {
        sub.prototype[key] = origProto[key];
    }
    // The constructor property was set wrong, let's fix it
    Object.defineProperty(sub.prototype, 'constructor', {
        enumerable: false,
        value: sub
    });

    //var origProto = sub.prototype;
    //sub.prototype = Object.create(base.prototype);
    //sub.prototype = jQuery.extend(sub.prototype, origProto);

    //// Remember the constructor property was set wrong, let's fix it
    //sub.prototype.constructor = sub;
    //// In ECMAScript5+ (all modern browsers), you can make the constructor property
    //// non-enumerable if you define it like this instead
    //Object.defineProperty(sub.prototype, 'constructor', {
    //    enumerable: false,
    //    value: sub
    //});
}
function convertRadiansToDegrees(radians) {
    return (radians * 180) / Math.PI;
}

function getAlias() {

    //determine the domain
    var domain = window.location.hostname.split('.');

    if (domain.length === 1 || domain.length === 3) {
        return domain[0];
    } else {

        var alias = '';

        for (var k = 0; k < domain.length - 2; k++) {
            if (k !== 0) {
                alias = alias + '.';
            }
            alias = alias + domain[k];
        }

        return alias;
    }
}

function isLegacyDomainAlias() {
    var alias = getAlias();
    var openingCharacters = alias.substring(0, 4).toLowerCase();
    if (openingCharacters === ("fuso")) {
        return true;
    }
    switch (alias) {
        case "app":
        case "appdev":
        case "apptest":
            return false;
        case "fuso-vietnam":
        case "fuso.vietnam":
        case "fuso.vietnam.dev":
        case "fuso.vietnam.test":
        case "fusovietnam":
        case "fusovietnamdev":
        case "fusovietnamtest":
        case "fusovn":
        case "fusovndev":
        case "fusovntest":
        case "fuso-vn":
        case "fuso-vndev":
        case "fuso-vntest":
        case "fuso-demo":
        case "fuso.demo":
        case "fuso.demo.test":
        case "fuso.demo.dev":
        case "fusodemo":
        case "fusodemotest":
        case "fusodemodev":
        case "volvo.southafrica":
        case "volvo.southafrica.test":
        case "volvo.southafrica.dev":
        case "volvosouthafrica":
        case "volvosouthafricatest":
        case "volvosouthafricadev":
        case "man.southafrica":
        case "man.southafrica.test":
        case "man.southafrica.dev":
        case "mansouthafrica":
        case "mansouthafricatest":
        case "mansouthafricadev":
        case "daf.southafrica":
        case "daf.southafrica.test":
        case "daf.southafrica.dev":
        case "dafsouthafrica":
        case "dafsouthafricatest":
        case "dafsouthafricadev":
        case "scania.southafrica":
        case "scania.southafrica.test":
        case "scania.southafrica.dev":
        case "scaniasouthafrica":
        case "scaniasouthafricatest":
        case "scaniasouthafricadev":
        case "isuzu.southafrica":
        case "isuzu.southafrica.test":
        case "isuzu.southafrica.dev":
        case "isuzusouthafrica":
        case "isuzusouthafricatest":
        case "isuzusouthafricadev":
        case "ud.southafrica":
        case "ud.southafrica.test":
        case "ud.southafrica.dev":
        case "udsouthafrica":
        case "udsouthafricatest":
        case "udsouthafricadev":
        case "hino.southafrica":
        case "hino.southafrica.test":
        case "hino.southafrica.dev":
        case "hinosouthafrica":
        case "hinosouthafricatest":
        case "hinosouthafricadev":
        case "mercedes-benz.southafrica":
        case "mercedes-benz.southafrica.test":
        case "mercedes-benz.southafrica.dev":
        case "mercedes-benzsouthafrica":
        case "mercedes-benzsouthafricatest":
        case "mercedes-benzsouthafricadev":
            return true;
        default:
            return false;
    }
}

function getOverwriteMessageText(objectType, optionalAccessoryType) {
    var messageText = config.getMessageText('1611', globals.getActiveCultureCodeForTranslations());
    var replacementText = '';
    if (objectType === config.OBJECT_TYPES.ACCESSORY) {
        replacementText = globals.getAccessoryTypeTranslation(optionalAccessoryType)
    } else if (objectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        replacementText = config.getTranslationText('4214');
    }
    messageText = messageText.replace('$S1', replacementText); // User Story 17565
    return messageText;
}

function returnValueOrZeroIfNanOrInfinity(valueToCheck) {

    if (!isNaN(parseFloat(valueToCheck)) && isFinite(valueToCheck)) {
        return valueToCheck;
    } else {
        return 0;
    }
}

function returnValueOrDashIfNanOrZero(valueToCheck) {

    if (!isNaN(parseFloat(valueToCheck)) && isFinite(valueToCheck) && valueToCheck !== 0) {
        return valueToCheck;
    } else {
        return config.defaultTableBlankRowChar;
    }
}

function getColour(blockName, cabColour) {
    if (blockName === 'CAB' && cabColour.colour !== undefined) {
        return parseInt(cabColour.colour.replace('#', '0x'), 16);
    } else {
        return parseInt(config.fillColour(blockName).replace('#','0x'),16);
    }
}



async function translateGraphicToXmlDoc(graphic) {
    if(graphic === null) {
        return null;
    }
    try {
        var xmlVar = "";

        // decode graphic from base64 encoding
        var decodedGraphic = window.atob(graphic);

        // convert string to UInt8Array
        var buf = new ArrayBuffer(decodedGraphic.length);
        var bufView = new Uint8Array(buf);
        for (var i = 0; i < decodedGraphic.length; i++) {
            bufView[i] = decodedGraphic.charCodeAt(i);
        }
        var bb = new Blob([buf]);
        bb.lastModifiedDate = new Date();
        bb.name = "archive.zip";
        // bb.type = "zip";

        // unzip the graphic file
        // var unzip = new Zlib.Unzip(bufView);
        // var filenames = unzip.getFilenames();
        // var plain = unzip.decompress(filenames[0]);

        var unzip = new JSZip();

        return await unzip.loadAsync(bb, {base64: false}).then(function(zip) {
            return zip.file("Graphic.xml").async("string").then(function (content) {
               // content is the file as a string
               if (window.ActiveXObject) {
                    var microsoftXML = new window.ActiveXObject("Microsoft.XMLDOM");
                    microsoftXML.loadXML(content);
                    TScMessenger.writeDebugMessage(microsoftXML);
                    return microsoftXML;
                } else {
                    var otherXML = (new DOMParser()).parseFromString(content, "text/xml");
                    return otherXML;
                }
            });
        }).catch((e) => {
            var breakHere = 0;
        });

        // var img = zip.folder("images");
        // img.file("smile.gif", imgData, {base64: true});

        // unzip.files['tempFile'].async('blob').then(function(plain) {
        //     // see FileSaver.js
        //     // saveAs(content, "example.zip");
        //     for (var j = 0; j < plain.byteLength; j++) {
        //         xmlVar += String.fromCharCode(plain[j]);
        //     }
    
        //     if (window.ActiveXObject) {
        //         var microsoftXML = new window.ActiveXObject("Microsoft.XMLDOM");
        //         microsoftXML.loadXML(xmlVar);
        //         TScMessenger.writeDebugMessage(microsoftXML);
        //         return microsoftXML;
        //     } else {
        //         var otherXML = (new DOMParser()).parseFromString(xmlVar, "text/xml");
        //         return otherXML;
        //     }
        // }).catch(function (error){
        //     var breakHere = 0;

        // });
        
        
        
        // convert the Uint8Array back to string which contains graphics XML
        
    } catch (xmlUnzipEx) {
        return null;
    }

}

// function translateGraphicToXmlDoc(graphic) {
//     try {
//         var xmlVar = "";

//         // decode graphic from base64 encoding
//         var decodedGraphic = window.atob(graphic);

//         // convert string to UInt8Array
//         var buf = new ArrayBuffer(decodedGraphic.length);
//         var bufView = new Uint8Array(buf);
//         for (var i = 0; i < decodedGraphic.length; i++) {
//             bufView[i] = decodedGraphic.charCodeAt(i);
//         }

//         // unzip the graphic file
//         var unzip = new Zlib.Unzip(bufView);
//         var filenames = unzip.getFilenames();
//         var plain = unzip.decompress(filenames[0]);

//         // convert the Uint8Array back to string which contains graphics XML
//         for (var j = 0; j < plain.byteLength; j++) {
//             xmlVar += String.fromCharCode(plain[j]);
//         }

//         if (window.ActiveXObject) {
//             var microsoftXML = new window.ActiveXObject("Microsoft.XMLDOM");
//             microsoftXML.loadXML(xmlVar);
//             TScMessenger.writeDebugMessage(microsoftXML);
//             return microsoftXML;
//         } else {
//             var otherXML = (new DOMParser()).parseFromString(xmlVar, "text/xml");
//             return otherXML;
//         }
//     } catch (xmlUnzipEx) {
//         return null;
//     }

// }


async function translateXmlDocToGraphic(xmlDoc) {

    var xmlAsString = (new XMLSerializer()).serializeToString(xmlDoc);
    xmlAsString = xmlAsString.replace(/(\n)/gm, "\r\n");
    var buf = new ArrayBuffer(xmlAsString.length);
    var bufView = new Uint8Array(buf);
    for (var i = 0; i < xmlAsString.length; i++) {
        bufView[i] = xmlAsString.charCodeAt(i);
    }

    //var deflate = new Zlib.Deflate(bufView);
    //var compressed = deflate.compress();

    var zip = new JSZip();
    
    zip.file('Graphic.xml', bufView);
    return await zip.generateAsync({type : "uint8array"}).then(function (compressed){
        var graphicString = "";
        for (var j = 0; j < compressed.byteLength; j++) {
            graphicString += String.fromCharCode(compressed[j]);
        }
        var base64GraphicBlob = window.btoa(graphicString);
        return base64GraphicBlob;

    });

    
    
}


// function translateXmlDocToGraphic(xmlDoc) {

//     var xmlAsString = (new XMLSerializer()).serializeToString(xmlDoc);
//     xmlAsString = xmlAsString.replace(/(\n)/gm, "\r\n");
//     var buf = new ArrayBuffer(xmlAsString.length);
//     var bufView = new Uint8Array(buf);
//     for (var i = 0; i < xmlAsString.length; i++) {
//         bufView[i] = xmlAsString.charCodeAt(i);
//     }

//     //var deflate = new Zlib.Deflate(bufView);
//     //var compressed = deflate.compress();

//     var zip = new Zlib.Zip();
    
//     zip.addFile(bufView, {
//         filename: stringToByteArray('Graphic.xml')
//     });
//     var compressed = zip.compress();

//     var graphicString = "";
//     for (var j = 0; j < compressed.byteLength; j++) {
//         graphicString += String.fromCharCode(compressed[j]);
//     }
//     var base64GraphicBlob = window.btoa(graphicString);
//     return base64GraphicBlob;

//     function stringToByteArray(str) {
//         var array = new (window.Uint8Array !== void 0 ? Uint8Array : Array)(str.length);
//         var i;
//         var il;

//         for (i = 0, il = str.length; i < il; ++i) {
//             array[i] = str.charCodeAt(i) & 0xff;
//         }

//         return array;
//     }
// }

function clearSubscriptions(itemWithSubscriptions, dontClearChangeSubscriptions) {
    if (itemWithSubscriptions !== undefined && itemWithSubscriptions !== null) {
        if (!dontClearChangeSubscriptions) {
            try {
                while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.change && itemWithSubscriptions._subscriptions.change.length > 0) {
                    itemWithSubscriptions._subscriptions.change.forEach(function (subscription) {
                        subscription.dispose();
                    });
                }
            } catch (changeSubscriptionsEx) {
                TScMessenger.writeDebugMessage("Error clearing subscriptions");
            }
        }
        try {
            while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.beforeChange && itemWithSubscriptions._subscriptions.beforeChange.length > 0) {
                itemWithSubscriptions._subscriptions.beforeChange.forEach(function (subscription) {
                    subscription.dispose();
                });
            }
        } catch (beforeChangeSubscriptionsEx) {
            TScMessenger.writeDebugMessage("Error clearing beforeChange subscriptions");
        }
        try {
            while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.arrayChange && itemWithSubscriptions._subscriptions.arrayChange.length > 0) {
                itemWithSubscriptions._subscriptions.arrayChange.forEach(function (subscription) {
                    subscription.dispose();
                });
            }
        } catch (arrayChangeSubscriptionsEx) {
            TScMessenger.writeDebugMessage("Error clearing arrayChange subscriptions");
        }
    }
}

function clearSubscriptionsWithoutDispose(itemWithSubscriptions, dontClearChangeSubscriptions) {
    if (itemWithSubscriptions !== undefined && itemWithSubscriptions !== null) {
        if (!dontClearChangeSubscriptions) {
            try {
                while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.change && itemWithSubscriptions._subscriptions.change.length > 0) {
                    itemWithSubscriptions._subscriptions.change = [];
                }
            } catch (changeSubscriptionsEx) {
                TScMessenger.writeDebugMessage("Error clearing change subscriptions");
            }
        }
        try {
            while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.beforeChange && itemWithSubscriptions._subscriptions.beforeChange.length > 0) {
                itemWithSubscriptions._subscriptions.beforeChange = [];
            }
        } catch (beforeChangeSubscriptionsEx) {
            TScMessenger.writeDebugMessage("Error clearing beforeChange subscriptions");
        }
        try {
            while (itemWithSubscriptions._subscriptions && itemWithSubscriptions._subscriptions.arrayChange && itemWithSubscriptions._subscriptions.arrayChange.length > 0) {
                itemWithSubscriptions._subscriptions.arrayChange = [];
            }
        } catch (arrayChangeSubscriptionsEx) {
            TScMessenger.writeDebugMessage("Error clearing arrayChange subscriptions");
        }
    }
}

function itemHasGraphic(item) {

    var graphicBlob, topViewGraphicBlob;

    if (item.getDecompressedGraphicBlob) {
        graphicBlob = item.getDecompressedGraphicBlob();
        topViewGraphicBlob = item.getDecompressedTopViewGraphicBlob();
    } else if (item.DecompressedGraphicBlob) {
        graphicBlob = item.DecompressedGraphicBlob;
        topViewGraphicBlob = item.DecompressedTopViewGraphicBlob;
    } else if (item.DecompressedGraphic) {
        graphicBlob = item.DecompressedGraphic;
        topViewGraphicBlob = item.DecompressedTopViewGraphicBlob;
    }


    if ((graphicBlob === null || graphicBlob === undefined || graphicBlob === '') && (topViewGraphicBlob === null || topViewGraphicBlob === undefined || topViewGraphicBlob === '')) {
        return false;
    } else {
        var xmlDoc = graphicBlob;
        if (xmlDoc === null || (xmlDoc.documentElement && xmlDoc.documentElement.childElementCount <= 1)) {
            xmlDoc = topViewGraphicBlob;
            if (xmlDoc === null || (xmlDoc.documentElement && xmlDoc.documentElement.childElementCount <= 1)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

}


function itemHasSideViewGraphic(item) {
    //this method should only be used on the data retrieval side of things to check if there is a file/blob to decompress
    var sideViewGraphicBlob;

    if (item.getGraphicBlob) {
        sideViewGraphicBlob = item.getGraphicBlob();
    } else if(item.getVehicleDrawing) {
        sideViewGraphicBlob = item.getVehicleDrawing();
    } else if (item.GraphicBlob) {
        sideViewGraphicBlob = item.GraphicBlob;
    } else if (item.Graphic) {
        sideViewGraphicBlob = item.Graphic;
    }else if (item.VehicleDrawing) {
        sideViewGraphicBlob = item.VehicleDrawing;
    }


    if ((sideViewGraphicBlob === null || sideViewGraphicBlob === undefined || sideViewGraphicBlob === '')) {
        return false;
    } else {
        // var xmlDoc = translateGraphicToXmlDoc(sideViewGraphicBlob);
        // if (xmlDoc === null || (xmlDoc.documentElement && xmlDoc.documentElement.childElementCount <= 1)) {
        //     return false;
        // } else {
        //     return true;
        // }
        return true;
    }

}

function itemHasValidSideViewGraphic(item) {
    //this method is to help deal with the situation wherby the decompressed blob is not a valid xml graphic file, should be used everywhere except during data/item retrieval
    var sideViewGraphicBlob;

    if (item.getDecompressedGraphicBlob) {
        sideViewGraphicBlob = item.getDecompressedGraphicBlob();
    } else if(item.getDecompressedVehicleDrawing) {
        sideViewGraphicBlob = item.getDecompressedVehicleDrawing();
    } else if (item.DecompressedGraphicBlob) {
        sideViewGraphicBlob = item.DecompressedGraphicBlob;
    } else if (item.DecompressedGraphic) {
        sideViewGraphicBlob = item.DecompressedGraphic;
    }else if (item.DecompressedVehicleDrawing) {
        sideViewGraphicBlob = item.DecompressedVehicleDrawing;
    }


    if ((sideViewGraphicBlob === null || sideViewGraphicBlob === undefined || sideViewGraphicBlob === '')) {
        return false;
    } else {
        
        if (isValidDecompressedGraphicFile(sideViewGraphicBlob)) {
            return true;
        } else {
            return false;
        }
    }

}

function itemHasTopViewGraphic(item) {

    var topViewGraphicBlob;
    
    if (item.getTopViewGraphicBlob) {
        topViewGraphicBlob = item.getTopViewGraphicBlob();
    } else if (item.getVehicleTopViewDrawing) {
        topViewGraphicBlob = item.getVehicleTopViewDrawing();
    } else if (item.TopViewGraphicBlob) {
        topViewGraphicBlob = item.TopViewGraphicBlob;
    } else if (item.VehicleTopViewDrawing) {
        topViewGraphicBlob = item.VehicleTopViewDrawing;
    } 


    if (topViewGraphicBlob === null || topViewGraphicBlob === undefined || topViewGraphicBlob === '') {
        return false;
    } else {
        // var xmlDoc = topViewGraphicBlob;
        // if (xmlDoc === null || (xmlDoc.documentElement && xmlDoc.documentElement.childElementCount <= 1)) {
        //     return false;
        // } else {
        //     return true;
        // }
        return true;
    }

}

function itemHasValidTopViewGraphic(item) {

    var topViewGraphicBlob;
    
    if (item.getDecompressedTopViewGraphicBlob) {
        topViewGraphicBlob = item.getDecompressedTopViewGraphicBlob();
    } else if (item.getDecompressedVehicleTopViewDrawing) {
        topViewGraphicBlob = item.getDecompressedVehicleTopViewDrawing();
    } else if (item.DecompressedTopViewGraphicBlob) {
        topViewGraphicBlob = item.DecompressedTopViewGraphicBlob;
    } else if (item.DecompressedVehicleTopViewDrawing) {
        topViewGraphicBlob = item.DecompressedVehicleTopViewDrawing;
    } 


    if (topViewGraphicBlob === null || topViewGraphicBlob === undefined || topViewGraphicBlob === '') {
        return false;
    } else {
        if (isValidDecompressedGraphicFile(topViewGraphicBlob)) {
            return true;
        } else {
            return false;
        }
    }

}

function isValidDecompressedGraphicFile(xmlDoc) {
    return xmlDoc.documentElement && xmlDoc.documentElement.childElementCount > 1;
}

function getElevationFromItemConfigurationWizardStep(itemConfiguratioWizardStepType) {
    if (itemConfiguratioWizardStepType.indexOf(config.ELEVATION.TOP) !== -1) {
        return config.ELEVATION.TOP;
    } else if(itemConfiguratioWizardStepType.indexOf(config.ELEVATION.SIDE) !== -1) {
        return config.ELEVATION.SIDE;
    }
    return null;
}

function getModuleHashFromTitle(moduleTitle) {
    var foundHash = null;
    Object.keys(ROUTES).forEach(function (key) {
        if (ROUTES[key].meta.title === moduleTitle) {
            foundHash = ROUTES[key].meta.hash;
        }
    });

    return foundHash;
}
function deleteObjectMembers(obj, shallowOnly) {
    for (var member in obj) {
        if (obj[member] && obj[member].cleanUp) {
            obj[member].cleanUp();
        }
        if (typeof obj[member] === 'object' && obj[member] !== null && shallowOnly !== true) {
            deleteObjectMembers(obj[member]);
        }
        delete obj[member];
    }
}

function encrypt(txt, optionalSecret) {
    return CryptoJS.AES.encrypt(txt, optionalSecret || globals.getStorageKey()).toString();
}

function decrypt(txtToDecrypt, optionalSecret) {
    return CryptoJS.AES.decrypt(txtToDecrypt, optionalSecret || globals.getStorageKey()).toString(CryptoJS.enc.Utf8);
}


var utils = {
    formatNumberRgx: formatNumberRgx,
    setOptions: setOptions,
    setPostOptions: setPostOptions,
    generateRoutesBasedOnPermissions: generateRoutesBasedOnPermissions,
    generateRoutesBasedOnPermissionsAndDataAvailability: generateRoutesBasedOnPermissionsAndDataAvailability,
    populateUIObservablesFromCustomer: populateUIObservablesFromCustomer,
    getTurningMomentsFront: getTurningMomentsFront,
    inheritsFrom: inheritsFrom,
    extend: extend,
    convertRadiansToDegrees: convertRadiansToDegrees,
    isLegacyDomainAlias: isLegacyDomainAlias,
    getOverwriteMessageText: getOverwriteMessageText,
    returnValueOrZeroIfNanOrInfinity: returnValueOrZeroIfNanOrInfinity,
    setOptionsCDNBaseURL: setOptionsCDNBaseURL,
    getColour: getColour,
    translateGraphicToXmlDoc: translateGraphicToXmlDoc,
    clearSubscriptions: clearSubscriptions,
    clearSubscriptionsWithoutDispose: clearSubscriptionsWithoutDispose,
    itemHasGraphic: itemHasGraphic,
    getElevationFromItemConfigurationWizardStep: getElevationFromItemConfigurationWizardStep,
    translateXmlDocToGraphic: translateXmlDocToGraphic,
    itemHasSideViewGraphic: itemHasSideViewGraphic,
    itemHasTopViewGraphic: itemHasTopViewGraphic,
    getModuleHashFromTitle: getModuleHashFromTitle,
    deleteObjectMembers: deleteObjectMembers,
    removeAllRoutes: removeAllRoutes,
    addRoutes: addRoutes,
    isValidDecompressedGraphicFile: isValidDecompressedGraphicFile,
    itemHasValidSideViewGraphic: itemHasValidSideViewGraphic,
    itemHasValidTopViewGraphic: itemHasValidTopViewGraphic,
    returnValueOrDashIfNanOrZero: returnValueOrDashIfNanOrZero,
    encrypt: encrypt,
    decrypt: decrypt
};

export default utils;



