<template>
    <div class="configuration-wizard-results-table-container flex-item">
        <div v-if="activeWizardStep.stepIncludesResultsTableSection === true" 
            :class="activeWizardStep.resultsTableToIncludeOnStep.customCss"
            class="configuration-wizard-results-table-container-div" 
            data-bind="with: resultsTableToIncludeOnStep, css: resultsTableToIncludeOnStep.customCss">
            <table>
                <tbody data-bind="foreach: rows">
                    <tr v-for="row in activeWizardStep.rows" :key="row">
                        <td>
                            <span data-bind="text: key">{{row.key}}</span>
                        </td>
                        <td>
                            <span data-bind="text: value">{{row.value}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
        
    <!-- <script type="text/html" id="template-itemConfigurationWizardModal-results-table-container">
        ko if: stepIncludesResultsTableSection
        <div class="configuration-wizard-results-table-container-div" data-bind="with: resultsTableToIncludeOnStep, css: resultsTableToIncludeOnStep.customCss">
            <table>
                <tbody data-bind="foreach: rows">
                    <tr>
                        <td>
                            <span data-bind="text: key"></span>
                        </td>
                        <td>
                            <span data-bind="text: value"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        /ko
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    props: {
        activeWizardStepFromParent: Object
    },
    name: "ResultsTableContainer",
    setup (props) {
        const activeWizardStep = toRef(props, "activeWizardStepFromParent");

        return {
            activeWizardStep
        }
    }
}
</script>

<style lang="scss" scoped>

</style>