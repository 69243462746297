<template>
    <td class="datagrid-icon-td">
        <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" 
            @click.stop="toggleCheckbox"
            data-bind="click: $parent.toggleCheckbox, clickBubble: false">
            <i class="far fa-lg" 
                :class="{ 'fa-square': item.isSelected === false, 'fa-check-square': item.isSelected === true }" 
                data-bind="css: { 'fa-square': isSelected() === false, 'fa-check-square': isSelected() === true }"></i>
        </div>
    </td>
    <td class="datagrid-icon-td folder-row-td">
        <div class="selection-datagrid-fa-icon save-icon">
            <template v-if="item.rowType === ROW_TYPE.FOLDER">
                <i class="fas fa-folder fa-lg"></i>
            </template>
            <template v-if="item.rowType === ROW_TYPE.VIRTUAL_FOLDER">
                <template v-if="item.IsSupportOffer === true">
                    <i class="fas fa-user-headset fa-lg"></i>    
                </template>
                <template v-else>
                    <i class="fas fa-folder fa-lg"></i>        
                </template>
            </template>
        </div>
    </td>
    <td>
        <div class="datagrid-description-div folder-row-td">
            <span data-bind="text: descriptionDisplay">{{item.descriptionDisplay}}</span>
        </div>
        <!-- ko if: $parents[1].searchTerms() !== ''
        <div class="datagrid-path-div">
        <a href="#" data-bind="click: function (item, event) { navigateToFolder(item, event) }">
            <span data-bind="text: folderPath"></span>
        </a>
        </div>
        /ko -->
    </td>
    <td></td>
    <td></td>
    <td></td>    
    
    <!-- <script type="text/html" id="template-selection-datagrid-folder-row">
        Template = "template-selection-datagrid-folder-row"
        <td class="datagrid-icon-td">
            <div class="selection-datagrid-fa-icon selection-datagrid-type-checkbox" data-bind="click: $parent.toggleCheckbox, clickBubble: false">
                <i class="far fa-square fa-lg" data-bind="css: { 'fa-square': isSelected() === false, 'fa-check-square': isSelected() === true }"></i>
            </div>
        </td>
        <td class="datagrid-icon-td folder-row-td">
            <div class="selection-datagrid-fa-icon save-icon">
                ko if: rowType === $root.config.ROW_TYPE.FOLDER
                <i class="fas fa-folder fa-lg"></i>
                /ko
                ko if: rowType === $root.config.ROW_TYPE.VIRTUAL_FOLDER
                ko if: IsSupportOffer !== true
                <i class="fas fa-folder fa-lg"></i>
                /ko
                ko if: IsSupportOffer === true
                <i class="fas fa-user-headset fa-lg"></i>
                /ko
                /ko
            </div>
        </td>
        <td>
            <div class="datagrid-description-div folder-row-td">
                <span data-bind="text: descriptionDisplay"></span>
            </div>
            ko if: $parents[1].searchTerms() !== ''
            <div class="datagrid-path-div">
            <a href="#" data-bind="click: function (item, event) { navigateToFolder(item, event) }">
                <span data-bind="text: folderPath"></span>
            </a>
            </div>
            /ko
        </td>
        <td></td>
        <td></td>
        <td></td>
    </script> -->
</template>

<script>
import { ref, watch } from 'vue';
import config from '@/services/config';

const TScMessenger = window.TScMessenger;

export default {
    props: {
        wizardFromParent: Object,
        itemFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);
        let item = ref(props.itemFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        function toggleCheckbox() {
            // Emit event
            // alert('Emit toggleCheckbox event to parent');
            TScMessenger.writeDebugMessage('Emit toggleCheckbox event to parent');
            wizard.value.activeWizardStep.toggleCheckbox(item.value);
        }

        function navigateToFolder(item) {
            alert('Emit navigateToFolder event to parent');
        }
        return {
            wizard,
            item,
            toggleCheckbox,
            navigateToFolder,
            ROW_TYPE: config.ROW_TYPE
        }
    }
}
</script>

<style lang="scss" scoped>
</style>