<template>
    <div class="configuration-wizard-radio-group flex-item">
        <div v-if="activeWizardStep.radioGroupInput !== null" 
            class="radio-button-group-container" 
            data-bind="with: radioGroupInput">
            <div :class="activeWizardStep.radioGroupInput.getCustomCss()"
                data-bind="foreach: getOptions()">
                <template v-for="option in activeWizardStep.radioGroupInput.getOptions()" :key="option">
                    <label :class="option.customCssObv.value">
                        <input type="radio" 
                            @click="clickFunction(option)"
                            :name="activeWizardStep.radioGroupInput.getName()"
                            :value="option.getValue()"
                            data-bind="checked: $parent.checkedOption, attr: { 'name': $parent.getName(), value: getValue() }" />
                        <span :for="option.getValue()"
                            data-bind="text: getLabel(), attr: { for: getValue() }">{{option.getLabel()}}</span>
                    </label>
                </template>
                
            </div>
        </div>
    </div>

    <!-- <script type="text/html" id="template-itemConfigurationWizardModal-radio-group">
        ko if: radioGroupInput !== null
        <div class="radio-button-group-container" data-bind="with: radioGroupInput">
            <div data-bind="foreach: getOptions(), css: getCustomCss()">
                <label data-bind="css: customCssObv">
                    <input type="radio" data-bind="checked: $parent.checkedOption, attr: { 'name': $parent.getName(), value: getValue() }" />
                    <span data-bind="text: getLabel(), attr: { for: getValue() }"></span>
                </label>
            </div>
        </div>
        /ko 
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    name: "RadioGroup",
    props: {
        activeWizardStepFromParent: Object
    },
    setup(props) {
        const activeWizardStep = toRef(props, "activeWizardStepFromParent");

        function clickFunction(optionClicked) {
            activeWizardStep.value.radioGroupInput.checkedOption.value = optionClicked.getValue();
            activeWizardStep.value.radioGroupInput.updateCheckedOption(optionClicked.getValue());
        }

        return {
            activeWizardStep,
            clickFunction
        }
    }
}
</script>

<style lang="scss" scoped>

</style>