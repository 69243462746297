import Attribute from './model.attribute';

var Legislation = function(legislationObj) {
    var self = this;

    self.id = legislationObj.Id;
    self.name = legislationObj.CountryName;
    self.countryId = legislationObj.CountryId;
    self.legislationId = legislationObj.LegislationId;
    self.description = legislationObj.Description;
    self.attributes = [];
    self.roadNames = legislationObj.roadNames;

    legislationObj.Attributes.forEach(function (attribute) {
        self.attributes.push(new Attribute(attribute));
    });

    function evaluate(attributeType) {
        var returnVal;
        var tempVal = self.attributes.filter(function (attribute) {
            return attributeType === attribute.name.toUpperCase();
        });
        returnVal = tempVal[0];

        return returnVal;
    }

    self.evaluate = evaluate;
}

export default Legislation;
