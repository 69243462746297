<template>
    <tr class="datagrid-row" v-for="item in wizard.itemsToDisplayArray" :key="item" @click="wizard.clickOnItem(item)">
        <!-- template-selection-datagrid-row -->
        <SelectionDatagridSelectionDatagridRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-datagrid-row'" :wizardFromParent="wizard" :itemFromParent="item">
        </SelectionDatagridSelectionDatagridRow>
        <SelectionDatagridSelectionManufacturerDatagridRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-manufacturer-datagrid-row'" :wizardFromParent="wizard" :itemFromParent="item">
        </SelectionDatagridSelectionManufacturerDatagridRow>
        <SelectionDatagridSelectionTemplateDatagridRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-template-datagrid-row'" :wizardFromParent="wizard" :itemFromParent="item"
        ></SelectionDatagridSelectionTemplateDatagridRow>        
        <SelectionDatagridSelectionSavedRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-datagrid-saved-row'" :wizardFromParent="wizard" :itemFromParent="item"
        ></SelectionDatagridSelectionSavedRow>
        <SelectionDatagridSelectionSharedRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-datagrid-shared-row'" :wizardFromParent="wizard" :itemFromParent="item"
        ></SelectionDatagridSelectionSharedRow>
        <SelectionDatagridSelectionFolderRow
            v-if="wizard.activeWizardStep.rowTemplateName(item) === 'template-selection-datagrid-folder-row'" :wizardFromParent="wizard" :itemFromParent="item"
        ></SelectionDatagridSelectionFolderRow>
    </tr>
</template>

<script>
import { ref, watch, onMounted, onUpdated } from 'vue';
import SelectionDatagridSelectionDatagridRow from '@/components/selectionWizard/SelectionDatagridSelectionDatagridRow.vue';
import SelectionDatagridSelectionManufacturerDatagridRow from '@/components/selectionWizard/SelectionDatagridSelectionManufacturerDatagridRow.vue';
import SelectionDatagridSelectionTemplateDatagridRow from '@/components/selectionWizard/SelectionDatagridSelectionTemplateDatagridRow.vue';
import SelectionDatagridSelectionSavedRow from '@/components/selectionWizard/SelectionDatagridSelectionSavedRow.vue';
import SelectionDatagridSelectionSharedRow from '@/components/selectionWizard/SelectionDatagridSelectionSharedRow.vue';
import SelectionDatagridSelectionFolderRow from '@/components/selectionWizard/SelectionDatagridSelectionFolderRow.vue';

export default {
    props: {
        wizardFromParent: Object
    },
    setup(props) {
        let wizard = ref(props.wizardFromParent);

        watch(() => props.wizardFromParent,
            (newValue, oldValue) => {
                wizard.value = newValue;
            });

        onMounted(() => {
            wizard.value.initializeMultiSelects();
        });

        onUpdated(() => {
            wizard.value.initializeMultiSelects();
        });

        return {
            wizard
        }
    },
    components: { SelectionDatagridSelectionDatagridRow, SelectionDatagridSelectionManufacturerDatagridRow, SelectionDatagridSelectionTemplateDatagridRow, SelectionDatagridSelectionSavedRow, SelectionDatagridSelectionSharedRow, SelectionDatagridSelectionFolderRow }
}
</script>

<style lang="scss" scoped>
</style>