<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox send-email-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title"><span data-bind="text: getTranslationText('416')">{{getTranslationText('416')}}</span></h4>
            </div>
            <div class="modal-body modal-body-text">
                <table class="specification-modal-table summary-modal-table" style="text-align: left;">
                    <tbody style="vertical-align: top;">
                        <tr class="summary-modal-tr">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('488')">{{getTranslationText('488')}}</span>
                            </td>
                            <td>
                                <input id="email-input-to" 
                                    class="form-control login-input summary-modal-input email-validation" 
                                    data-toggle="tooltip" 
                                    data-placement="top" 
                                    type="email" 
                                    maxlength="80"
                                    v-model="sendTo"
                                    :title="getTranslationText('418')"
                                    :placeholder="getTranslationText('417')"
                                    data-bind="value: sendTo, attr: { title: getTranslationText('418'), placeholder: getTranslationText('417') }" />
                            </td>
                        </tr>
                        <tr class="summary-modal-tr">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('70')">{{getTranslationText('70')}}</span>
                            </td>
                            <td>
                                <input id="email-input-cc" 
                                    class="form-control login-input summary-modal-input email-validation" 
                                    data-toggle="tooltip" 
                                    data-placement="top" 
                                    type="email" 
                                    maxlength="80" 
                                    v-model="cc"
                                    :title="getTranslationText('418')"
                                    :placeholder="getTranslationText('417')"
                                    data-bind="value: cc, attr: { title: getTranslationText('418'), placeholder: getTranslationText('417') }" />
                            </td>
                        </tr>
                        <tr class="summary-modal-tr">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('36')">{{getTranslationText('36')}}</span>
                            </td>
                            <td>
                                <input id="email-input-bcc" 
                                    class="form-control login-input summary-modal-input email-validation" 
                                    data-toggle="tooltip" 
                                    data-placement="top" 
                                    type="email" 
                                    v-model="bcc"
                                    :title="getTranslationText('418')"
                                    :placeholder="getTranslationText('417')"
                                    data-bind="value: bcc, attr: { title: getTranslationText('418'), placeholder: getTranslationText('417') }" />
                            </td>
                        </tr>
                        <tr class="summary-modal-tr">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('456')">{{getTranslationText('456')}}</span>
                            </td>
                            <td>
                                <input class="form-control login-input summary-modal-input" 
                                    type="text" 
                                    v-model="subject"
                                    data-bind="value: subject" />
                            </td>
                        </tr>
                        <tr class="summary-modal-tr tr-align-top">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('42')">{{getTranslationText('42')}}</span>
                            </td>
                            <td>
                                <textarea class="modal-textarea" 
                                    v-model="body"
                                    data-bind="value: body"></textarea>
                            </td>
                        </tr>
                        <tr class="summary-modal-tr">
                            <td class="summary-modal-td-desc">
                                <span data-bind="text: getTranslationText('22')">{{ getTranslationText('22')}}</span>
                            </td>
                            <td>
                                <i class="far fa-file-pdf fa-lg"></i>
                                <span data-bind="text: attachmentName">{{attachmentName}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button @click="ok"
                    class="btn btn-default btn-truckscience btn-primary" 
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button @click="closeClick"
                    class="btn btn-default btn-truckscience" 
                    data-bind="click: cancel, touchstart: cancel">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import { ref, toRef, onMounted, nextTick } from 'vue';

import config from '@/services/config';
import dataManager from '@/services/dataManager';
import globals from '@/services/globals';
import CustomMessageBox from '@/services/CustomMessageBox';
import PopupModal from '../PopupModal.vue';

var $ = window.$;

export default {
    name: 'SendEmailModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);

        const sendTo = ref();
        const cc = ref();
        const bcc = ref(globals.user.getEmailAddress());
        const subject = ref(opts.value.modalObject.fileName);
        const body = ref();
        const attachmentName = globals.returnValidFileName(opts.value.modalObject.fileName) + '.pdf';
        const pdfUri = opts.value.modalObject.pdfUri.split(',')[1];

        let resolvePromise;

        onMounted(() => {
            popup.value.open();
            nextTick(()=> {
                compositionComplete();
            });
            
        });

        function compositionComplete() {
            $('.email-validation').on('blur', function () {
                var inputValue = $(this).val(),
                    id = this.id;
                $(this).tooltip('destroy').removeClass('error');
                isEmailValid(id, inputValue);
            });
        }

        function areAllEmailAddressesInListValid(emailList) {
            var emailArray = emailList.split(',');
            for (var i = 0; i < emailArray.length; i++) {
                if (!globals.isValidEmailAddress(emailArray[i].trim())) {
                    return false;
                }
            }
            return true;
        }

        function isEmailValid(id, inputValue) {
            if (inputValue !== "") {
                if (inputValue.includes(',')) {
                    if (areAllEmailAddressesInListValid(inputValue)) {
                        return true;
                    } else {
                        $('#' + id).tooltip({ trigger: 'manual' }).tooltip('show').addClass('error').focus();
                        return false;
                    }
                } else {
                    if (globals.isValidEmailAddress(inputValue)) {
                        return true;
                    } else {
                        $('#' + id).tooltip({ trigger: 'manual' }).tooltip('show').addClass('error').focus();
                        return false;
                    }
                }
            }
            return true;
        }

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }
        
        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        function ok() {
            var sendEmail = true;
            $('.error').tooltip('destroy').removeClass('error');
            // Check sendTo email address is valid and populated
            if ($('#email-input-to').val() === "" || !isEmailValid("email-input-to", $('#email-input-to').val())) {
                sendEmail = false;
                $('#email-input-to').tooltip({ trigger: 'manual' }).tooltip('show').addClass('error').focus();
            }
            if (sendEmail === true) {
                if (!isEmailValid("email-input-cc", $('#email-input-cc').val())) {
                    sendEmail = false;
                    $('#email-input-cc').tooltip({ trigger: 'manual' }).tooltip('show').addClass('error').focus();
                }
            }
            if (sendEmail === true) {
                if (!isEmailValid("email-input-bcc", $('#email-input-bcc').val())) {
                    sendEmail = false;
                    $('#email-input-bcc').tooltip({ trigger: 'manual' }).tooltip('show').addClass('error').focus();
                }
            }
            
            // Check email addresses in cc and bcc are valid
            if (sendEmail === true) {
                globals.showSpinner();
                $.when(dataManager.postEmailOffer(createObjectToSend())).done(function (returnMessage) {
                    globals.hideSpinner();
                    if (returnMessage.ReturnCode < 0) {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1583', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1583')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function () {
                                close();
                            });
                    } else {
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.EMAILED_OFFER));
                        // dialog.close(self);
                        // self.callback();
                        close();
                    }
                })
                .fail(function () {
                    globals.hideSpinner();
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1583', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1583')));
                    close();
                });
            }
        }

        function createObjectToSend() {
            return {
                "SendTo": sendTo.value !== undefined ? sendTo.value : '',
                "Cc": cc.value !== undefined ? cc.value : '',
                "Bcc": bcc.value !== undefined ? bcc.value : '',
                "Subject": subject.value !== undefined ? subject.value : '',
                "Body": globals.replaceNewLineWithBreaks(body.value),
                "AttachmentName": attachmentName,
                "AttachmentContent": pdfUri
            };
        }

        function closeClick() {
            close();
        }

        return {
            popup,
            show,
            // Inputs
            sendTo,
            cc,
            bcc,
            subject,
            body,
            attachmentName,
            pdfUri,
            // Buttons
            ok,
            closeClick,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>