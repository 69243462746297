import config from './config';
import globals from './globals';
import utils from './utils';
import logger from './logger';
import CustomMessageBox from './CustomMessageBox';
import security from './security';
// import $ from '../assets/scripts/jquery-3.6.0.min';


const $ = window.$;
var TScMessenger = window.TScMessenger;
var OPERATION = config.OPERATION;

//constants
var
    operationTypeGet = 'GET',
    operationTypeHead = 'HEAD',
    operationTypePost = 'POST',
    operationTypeDelete = 'DELETE',
    dataTypeJson = 'json',
    dataTypeString = 'text',
    dataTypePdf = 'pdf';

//variables
var
    userNameProvided,
    passwordProvided,
    securityTokenProvided,
    accessTokenProvided,
    cultureCodeProvided;

var opTimeouts = {};

var remoteDatalayer = {
    checkIsOnline: checkIsOnline,
    handleRequest: handleRequest,
    handleSync: handleSync,
    checkIsOnlineHeadVersion: checkIsOnlineHeadVersion
};

export default remoteDatalayer;

/**
 * Method for checking if the app is online, or more specifically if the server is available. Determines this by trying to reach a resource on the server.
 * @method checkIsOnline
 * @param {Boolean} async Flag to indicate whether the ajax call should be carried out synchronously or asynchronously.
 * @param {Number} randomPart Optional parameter aimed at ensuring the call is always made instead of going to the cache.
 * @return Resolves true if online or rejects false if offline.
 */
function checkIsOnline(operation, security, async, optionalPayload) {
    var dfd = $.Deferred();

    var url;
    var opType;
    var doAsync = async !== undefined ? async : true;

    securityTokenProvided = security.getSecurityToken();

    switch (operation.Code) {
        case OPERATION.CheckIsOnline.Code:
            url = operation.BaseUrl + '/?rand=' + Date.now();
            opType = operationTypeGet;

            $.ajax(utils.setOptions(url, opType, dataTypeJson, setQueryHeader, globals.cloudServicesVersion1, doAsync, (config.isOnlineTimeout * 1000)))
                .then(requestSucceeded)
                .fail(requestFailed);
            break;
        case OPERATION.PostDebugInfo.Code:
        case OPERATION.PostUsageInfo.Code:
            url = operation.BaseUrl;
            opType = operationTypePost;

            $.ajax(utils.setPostOptions(url, opType, dataTypeJson, optionalPayload, setQueryHeader, globals.cloudServicesVersion1, doAsync, (config.postRequestTimeout * 1000)))
                .then(requestSucceeded)
                .fail(requestFailed);

            break;
    }




    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {
        if (jqXHR.status !== 204) {
            updateSecurity(security, jqXHR);
        }
        dfd.resolve(data);
    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject()
    }

    return dfd.promise();
}

function checkIsOnlineHeadVersion() {
    var dfd = $.Deferred();

    var url;
    var opType;
    var doAsync = true;

    url = 'favicon.ico?rand=' + Date.now();
    opType = operationTypeHead;
    dfd.resolve();
    // $.ajax(utils.setOptionsCDNBaseURL(url, opType, dataTypeJson, undefined, doAsync, (config.isOnlineTimeout * 1000)))
    //     .then(headIsOnlineRequestSucceeded)
    //     .fail(headIsOnlineRequestFailed);

    // function headIsOnlineRequestSucceeded(data, textStatus, jqXHR) {
    //     dfd.resolve();
    // }

    // function headIsOnlineRequestFailed(xhr, ajaxOptions, thrownError) {
    //     dfd.reject()
    // }

    return dfd.promise();
}

//#region Internal methods
function setQueryHeader(xhr) {
    // xhr.withCredentials = true;
    xhr.setRequestHeader('SecurityToken', securityTokenProvided);
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
    xhr.setRequestHeader('MachineCode', security.getMachineCode());
}

function setValidateAccessTokenQueryHeader(xhr) {
    xhr.setRequestHeader('AccessToken', accessTokenProvided);
}

function setQueryHeaderDebug(xhr) {
    xhr.setRequestHeader('SecurityToken', securityTokenProvided);
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
    xhr.setRequestHeader('OperationFlag', "debug");
}

function setQueryHeaderUsage(xhr) {
    xhr.setRequestHeader('SecurityToken', securityTokenProvided);
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
    xhr.setRequestHeader('OperationFlag', "usage");
}

function setQueryHeaderIsOnline(xhr) {
    xhr.setRequestHeader('SecurityToken', securityTokenProvided);
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
    xhr.setRequestHeader('OperationFlag', "isOnline");
}

function setUnAuthenticatedQueryHeader(xhr) {
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
}

function queryFailed(xhr, ajaxOptions, thrownError) {
    //alert('Query failed. ' + xhr.responseText);
    //app.showMessage('Query failed. ' + xhr.responseText, config.applicationTitle, [ltlOK()]).then(function (dialogResult) {
    //    //do nothing
    //});
    globals.showDialog(new CustomMessageBox('Query failed. ' + xhr.responseText, config.applicationTitle, [config.getTranslationText('271')]));
    var msg = 'Error getting data. ';
    logger.log(msg, xhr, 'remoteDatalayer', config.showDebugToast);
}
//#endregion

/**
 * Method for handling a sync item operation. Sets up the request and options and then passes responsibility off to 'doAjax' method.
 * @method handleSync
 * @param {Object} security Security reference used to get the security token and then passed on to 'doAjax'.
 * @param {Object} syncItem The sync item containing the operation, parameter and payload information necessary to build the request and options for the ajax call.
 */
function handleSync(security, syncItem) {
    var dfd = $.Deferred();

    switch (syncItem.Operation.Code) {
        case config.OPERATION.SendIntercomEventData.Code:
            intercomEventOperation(syncItem.Details, dfd);
            break;
        case config.OPERATION.SendIntercomUserData.Code:
            intercomUserOperation(syncItem.Details, dfd);
            break;
        default:
            securityTokenProvided = security.getSecurityToken();
            var request = getRequest(syncItem.Operation, syncItem.Params);
            var options = getOptions(syncItem.Operation.Code, request, syncItem.Details, syncItem.timeout);
            doAjax(security, options, dfd, request, syncItem.Operation);
    }

    return dfd.promise();
}

/**
 * Method for handling online data requests. Sets up the options and then passes responsibility off to 'doAjax' method.
 * @method handleRequest
 * @param {Object} security Security reference used to get the security token and then passed on to 'doAjax'.
 * @param {String} request The request url for the operation.
 * @param {String} operation The operation to be carried out.
 * @param {Object} optionalData Optional payload to be used for POST requests.
 */
function handleRequest(security, request, operation, optionalData) {
    var dfd = $.Deferred();
    var options;
    switch (operation) {
        case config.OPERATION.SendIntercomEventData.Code:
            intercomEventOperation(optionalData, dfd);
            break;
        case config.OPERATION.SendIntercomUserData.Code:
            intercomUserOperation(optionalData, dfd);
            dfd.resolve();
            break;
        case config.OPERATION.ValidateAccessToken:
            accessTokenProvided = globals.getAccessToken();
            globals.setAccessToken(null);
            options = getOptions(operation, request, optionalData);
            TScMessenger.writeDebugMessage("Making Request : " + request);
            doAjax(security, options, dfd, request, operation);
            break;
        default:
            securityTokenProvided = security.getSecurityToken();
            options = getOptions(operation, request, optionalData);
            TScMessenger.writeDebugMessage("Making Request : " + request);
            doAjax(security, options, dfd, request, operation);
    }

    return dfd.promise();
}

/**
 * Method for constructing options for ajax call. Wraps utils.setOptions but preconfigures some variants based on operation being performed.
 * @method getOptions
 * @param {String} op The operation being carried out.
 * @param {String} req The request url for the operation.
 * @param {Object} optionalData The optional payload for a POST operation.
 * @param {Number} optionalTimeout Optional timeout value in case the caller wants to override the standard 20 second hardcoded timeout.
 * @return Returns the options object.
 */
function getOptions(op, req, optionalData, optionalTimeout) {
    var opType, apiVers, mimeType, dataTypeToUse = dataTypeJson, server = config.SERVER.CLOUD_SERVICES, customHeaderFunc;
    let withCredentials = false;
    switch (op) {
        case OPERATION.GetSelectionList:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.GetNewOfferDetails:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion2;
            req = req + '&offlineUses=' + globals.user.getOfflineUsage();
            break;
        case OPERATION.GetExistingOfferDetails:
        case OPERATION.GetSharedOfferDetails.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion3;
            break;
        case OPERATION.GetNewOfferSpecificationDetails:
        case OPERATION.GetExistingOfferSpecificationDetails:
            opType = operationTypeGet;
            //apiVers = globals.cloudServicesVersion1;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.Sync:
        case OPERATION.GetSalesPersonInfo:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.SaveOffer.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion3;
            if (typeof optionalData.Offer.Id === 'string') {
                if (optionalData.Offer.Id.indexOf("_") !== -1) {
                    optionalData.Offer.Id = 0;
                }
            }
            if (typeof optionalData.Customer.Id === 'string') {
                if (optionalData.Customer.Id.indexOf("_") !== -1) {
                    optionalData.Customer.Id = 0;
                }
            }
            break;
        case OPERATION.SaveCustomer.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            if (typeof optionalData.Id === 'string') {
                if (optionalData.Id.indexOf("_") !== 0) {
                    optionalData.Id = 0;
                }
            }
            break;
        case OPERATION.UpdateOfferStatus.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.DeleteOffer.Code:
            opType = operationTypeDelete;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetTranslations:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetCommonPackDetails:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetLegislationList:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetLegislationDetails:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetOnRoadCosts:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetCustomers:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetUserSettings:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SaveUserSettings.Code:
            opType = operationTypePost;
            //apiVers = globals.cloudServicesVersion1;
            apiVers = globals.cloudServicesVersion3;
            //if (typeof optionalData.Id === 'string') {
            //    if (optionalData.Id.indexOf("_") !== 0) {
            //        optionalData.Id = 0;
            //    }
            //}
            break;
        case OPERATION.GetAppConfig.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetBodyStubs:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetBody:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetTrailerStubs:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetTrailer:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetPayloadStubs:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetPayload:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.PostDataRequest:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.GetAccessoryStubs:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetAccessory:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SaveCustomAccessory.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.DeleteCustomAccessory.Code:
            opType = operationTypeDelete;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.CheckEmailVerificationStatus:
        case OPERATION.VerifyCode:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SignUp:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ResendVerificationEmail:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SendFeedback:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SendReferral:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.SendReferralPromptNotification:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.RequestTrialExtension:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.RequestSalesTool:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.RequestUpgrade:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.RegisterBuyNowClick:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetPortalURL:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetUpdatePaymentMethodURL:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.PostEmailOffer:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.PostLoginDebug:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.PostAppOptionItem.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ChangePassword.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            withCredentials = true;
            break;
        case OPERATION.PostScreenSizeDebug:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.PostCSVData.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            mimeType = 'multipart/form-data';
            break;
        case OPERATION.PostTransSolveData.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            mimeType = 'multipart/form-data';
            break;
        case OPERATION.PostDXFData.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            mimeType = 'multipart/form-data';
            server = config.SERVER.AZURE_FUNCTIONS;
            break;
        case OPERATION.UpdatePendingVehicle.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ShareOffer.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.GetShareeUserOfferDetails.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion2;
            break;
        case OPERATION.GetUserAssociations:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.DeleteUserOfferShare.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetSharedOfferStubs.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetSavedOfferStubs.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetOfferShareStatus.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break; 
        case OPERATION.DownloadVehicleCsv:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            dataTypeToUse = dataTypeString;
            break;
        case OPERATION.UpdateShareeUserDetails.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.UpdateOfferShareStatus.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ValidateNTEAUser:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetTeamSyncItems.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetBodyMasses.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break
        case OPERATION.GetLicenceCategories.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetFolderContents.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetSearchResults.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetFolderTreeStructure.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.CreateFolder.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.MoveTo.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetSharers.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.Rename.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.Remove.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.GetResources.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.VerifyTransferLicenceCode.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ResendTransferLicenceVerificationEmail.Code:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.UpdateUserAfterCheckout.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
        case OPERATION.ValidateAccessToken:
            opType = operationTypeGet;
            apiVers = globals.cloudServicesVersion1;
            customHeaderFunc = setValidateAccessTokenQueryHeader;
            break;
        case OPERATION.SendEmail.Code:
            opType = operationTypePost;
            apiVers = globals.cloudServicesVersion1;
            break;
    }
    var options;
    if (optionalData !== undefined) {

        options = utils.setPostOptions(req, opType, dataTypeJson, optionalData, setQueryHeader, apiVers, true, optionalTimeout, server);
        if (mimeType) {
            options['mimeType'] = mimeType;
            options['processData'] = false;
            options['contentType'] = false;
        }
    } else if(customHeaderFunc) {
        options = utils.setOptions(req, opType, dataTypeToUse, customHeaderFunc, apiVers, true, optionalTimeout);
    } else {
        options = utils.setOptions(req, opType, dataTypeToUse, setQueryHeader, apiVers, true, optionalTimeout);
    }
    options.cache = false;
    if(withCredentials) {
        options.xhrFields = {
            withCredentials: true
       }
    }
    return options;
}



/**
 * Method for constructing request for ajax call. Pulls base request url out of op and then appends any params.
 * @method getRequest
 * @param {Object} op The operation being carried out.
 * @param {Object} params The params required for the call(if any).
 * @return Returns the full request url.
 */
function getRequest(op, params) {

    var request = op.BaseUrl;
    var counter = 0;

    for (var key in params) {
        if (counter === 0) {
            request = request + "?";
        } else {
            request = request + "&";
        }
        counter++;
        var paramVal;
        if (key === "id" || key === "customerId" || key === "offerId") {
            if (typeof params[key] === 'string' && params[key].indexOf("_") !== -1) {
                paramVal = 0;
            } else {
                paramVal = params[key];
            }
        } else {
            paramVal = params[key];
        }
        request = request + key + "=" + paramVal;
    }

    return request;
}

/**
 * Method for carrying out ajax call and then passing result back to caller. If the call fails an online check is immediately carried out to see if it failed because the app is offline or not.
 * @method doAjax
 * @param {Object} security Used for setting the updated security token on in the event of a successful call.
 * @param {Object} options The options that describe the call to be made.
 * @param {Object} dfd The Deferred used to pass result or error back to caller.
 * @return Resolves the result of the successful request or rejects with error message.
 */
function doAjax(security, options, dfd, request, operation) {

    var _security = security,
        _options = options,
        _dfd = dfd,
        _request = request,
        _operation = operation;


    if (typeof _operation === 'string') {
        opTimeouts[_operation] = _options.timeout;
    } else {
        opTimeouts[_operation.Code] = _options.timeout;
    }

    $.ajax(_options)
        .then(function (data, textStatus, jqXHR) {
            updateSecurity(_security, jqXHR);
            //clear the offline uses as the transaciton was successful
            globals.user.updateUser({
                offlineUsage: 0
            });
            TScMessenger.writeDebugMessage("Successful Request : " + _request);
            _dfd.resolve(data);
        })
        .fail(function (xhr, ajaxOptions, thrownError) {
            TScMessenger.writeErrorMessage("Failed Request : " + _request);
            checkIsOnline(OPERATION.CheckIsOnline, _security)
                .then(function () {
                    var curOp = typeof _operation === 'string' ? _operation : _operation.Code;
                    _dfd.reject({
                        errorMessage: xhr.statusText,
                        failedOp: curOp,
                        opTimeout: opTimeouts[curOp]
                    });
                })
                .fail(function () {
                    var curOp = typeof _operation === 'string' ? _operation : _operation.Code;
                    _dfd.reject({
                        errorMessage: 'Offline',
                        failedOp: curOp,
                        opTimeout: opTimeouts[curOp]
                    });
                });
        });
}

function updateSecurity(security, jqXHR) {
    var securityToken, validUntil;
    securityToken = jqXHR.getResponseHeader('SecurityToken');
    if (securityToken !== undefined && securityToken !== null && securityToken !== '') {
        validUntil = jqXHR.getResponseHeader('ValidUntil');
        security.setSecurityToken(securityToken, validUntil);
    }
}

/**
 * Provides call to Intercom to send event data
 * @param {Object} data Event data object to be sent to Intercom
 * @param {Promise} dfd Promise to be resolved
 */
function intercomEventOperation(data, dfd) {
    try {
        if (data.metadata === undefined) {
            window.Intercom('trackEvent', data.eventName);
        } else {
            window.Intercom('trackEvent', data.eventName, data.metadata);
        }
        setTimeout(function () {
            window.Intercom("update", {
                last_request_at: parseInt((new Date()).getTime() / 1000)
            })
            dfd.resolve();
        }, 500);
        //dfd.resolve();
    } catch (e) {
        //TScMessenger.writeDebugMessage("Error when trying to send Intercom data for event: " + data.eventName);
        //dataManager.log("Error when trying to send Intercom data for event: " + data.eventName, error, "remoteDatalayer.intercomEventOperation", config.LOG_MESSAGE_TYPE.ERROR);
        dfd.reject({
            errorMessage: "Error when trying to send Intercom data for event: " + data.eventName,
            errorDetails: e,
            errorType: 'INTERCOM'
        });
    }
}

/**
 * Provides call to Intercom to update user
 * @param {Object} data New user data object to be updated on Intercom
 * @param {Promise} dfd Promise to be resolved
 */
function intercomUserOperation(data, dfd) {
    try {
        window.Intercom("update", data);
        dfd.resolve();
    } catch (e) {
        TScMessenger.writeErrorMessage("Error when trying to send Intercom data for user update");
        //dataManager.log("Error when trying to send Intercom data for user update", error, "remoteDatalayer.intercomUserOperation", config.LOG_MESSAGE_TYPE.ERROR);
        dfd.reject({
            errorMessage: "Error when trying to send Intercom data for user update",
            errorDetails: e,
            errorType: 'INTERCOM'
        });
    }
}

    