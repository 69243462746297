<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container resourceModal-container">
            <div class="modal-header modal-header-text">
                <div class="resourceModal-header-container">
                    <h4 class="modal-title">
                        <span data-bind="text: modalTitle">{{modalTitle}}</span>
                    </h4>
                    <p class="resourceModal-description" data-bind="text: modalDescription">{{modalDescription}}</p>
                </div>
                <div @click="cancel" class="resourceModal-close-container" data-bind="click: cancel, touchstart: cancel">
                    <i class="fas fa-2x fa-times"></i>
                </div>    
            </div>
            <div v-html="modalBody"
                class="modal-body modal-body-text"
                data-bind="html: modalBody"></div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import PopupModal from './PopupModal.vue';
import { ref, onMounted, toRef } from 'vue';

const $ = window.$;

export default {
    name: 'ResourceModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    }, 
    setup(props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const modalTitle = ref(opts.value.resourceObject.Title);
        const modalDescription = ref(opts.value.resourceObject.Description);
        const modalBody = ref(opts.value.resourceObject.Body);
        const callback = opts.value.callback;
        
        let resolvePromise, 
            getTranslationText = config.getTranslationText;

        onMounted(() => {
            
        })

        function show(opts = {}) {
            // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function cancel() {
            this.popup.close();
            resolvePromise();
        }

        return {
            show,
            popup,
            modalTitle,
            modalDescription,
            modalBody,
            cancel,
            getTranslationText
        }
    },
}
</script>
