<template>
  <!-- <div
    class="spinner-page-splash"
    data-bind="visible: router.isNavigating() === true || displayBlackout() === true"
  ></div>
  <div
    class="spinner-page-splash-foreground"
    data-bind="visible: router.isNavigating() === true || displayBlackout() === true"
  >
    <div
      class="spinner-message"
      data-bind="visible: (router.isNavigating() === true || displaySpinner() === true) && displayProgressBar() === false"
    >
      <div>
        <i class="fa fa-circle-o-notch fa-spin fa-4x"></i>
      </div>
      <div class="spinner-text" data-bind="text: spinnerText"></div>
    </div>
    <div class="splash progress-bar-update" data-bind="visible: displayProgressBar() === true">
      <div class="progress progress-striped active page-progress-bar">
        <div class="progress-bar" data-bind="style: { width: progressBarPercentageFormatted }"></div>
      </div>
    </div>
  </div>-->

<!--
  <div class="spinner-page-splash" v-if="displayBlackout"></div>
  <div class="spinner-page-splash-foreground" v-if="displayBlackout">
    <div class="spinner-message" v-if="displaySpinner">
-->

  <div class="spinner-page-splash"></div>
  <div class="spinner-page-splash-foreground">
    <div class="spinner-message">
      <div>
        <i class="fa fa-circle-o-notch fa-spin fa-4x"></i>
      </div>
      <div class="spinner-text" v-text="globals.spinnerText.value"></div>
    </div>
  </div>

  <!-- <nav>
    <router-link to="/">Get Started</router-link>|
    <router-link to="/selection">Selection</router-link>|
    <router-link to="/configuration">Configuration</router-link>
  </nav>-->
  <header>
     <NavBar /> 
  </header>
  <section id="content" class="main container-fluid layoutBody layoutRow">
    <div class="layoutLeftPad layoutCol"></div>
    <div id="shell-main-view" class="layoutMiddle layoutCol ipad-page-container">
      <div class="durandal-wrapper">
        <router-view v-if="canShowRouterView" />
      </div>
    </div>
    <div class="layoutRightPad layoutCol"></div>
  </section>
  <footer class="layoutFooter">
    <FooterBar />
  </footer>
  
  <div v-if="globals.paymentMenuStatus.value !== config.PAYMENT_MENU_OPTIONS.DO_NOT_DISPLAY && globals.paymentMenuStatus.value !== ''">
    <PaymentButton :paymentMenuStatus="{ paymentMenuStatus: globals.paymentMenuStatus }"></PaymentButton>
  </div>
  <div id="intercom-hopscotch-placeholder"></div>
  
  <!-- <router-view /> -->
  <Teleport to="body">
        <component ref="curModal" :is="curModalType" :propsForModal="curModalProps"></component>
        <component ref="curModal2" :is="curModal2Type" :propsForModal="curModal2Props"></component>
        <component ref="curModal3" :is="curModal3Type" :propsForModal="curModal3Props"></component>
        <component ref="curModal4" :is="curModal4Type" :propsForModal="curModal4Props"></component>
    </Teleport>
</template>


<script>
import config from './services/config';
import User from './model/user/model.user';
import dataManager from './services/dataManager';
import globals from './services/globals';
import utils from '@/services/utils';
import logger from '@/services/logger';
import { ref, inject, onMounted, onBeforeMount, watch, nextTick, watchEffect, shallowRef, computed, triggerRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import FooterBar from '@/components/shell/FooterBar.vue';
import NavBar from './components/shell/NavBar.vue';
import PaymentButton from '@/components/PaymentButton.vue';
import CustomMessageBox from '@/services/CustomMessageBox';
// import shell from './views/shell';
import * as packageJson from '../package.json';
import * as msalAuth from './services/msal/auth';


import offerManager from './services/offerManager';

const customMessageDialogue = ref(null);
// const messageChannel = new MessageChannel();



// const displayBlackout = ref(false);
// const displaySpinner = ref(false);
const $ = window.$;
var TScMessenger = window.TScMessenger;
const CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;

//config.cloudServicesAddress = "https://localhost:44300/api/";
config.cloudServicesAddress = process.env.VUE_APP_CloudServicesUrl;

config.cdnAddress = process.env.VUE_APP_CDNUrl;
config.appVersionNumber = packageJson.version;//process.env.VUE_APP_VERSION;//process.env.npm_package_version;
// config.identity.password = metas[i].getAttribute("content");
// config.identity.username = metas[i].getAttribute("content");
// config.identity.redirectFlag = metas[i].getAttribute("content");
config.maxTrialChangesBeforeForcedEmailVerification = process.env.VUE_APP_MaxTrialChangesBeforeForcedEmailVerification;
config.externalAPIKey = process.env.VUE_APP_ExternalAPIKey;
// globals.updateStatus = metas[i].getAttribute("content");
config.intercomAppId = process.env.VUE_APP_IntercomAppId;
config.azureFunctionsServer = process.env.VUE_APP_AzureFunctionsServer;
config.chargebeeSiteName = process.env.VUE_APP_ChargebeeSiteName;
config.chargebeeProductVersion = process.env.VUE_APP_ChargebeeProductVersion;
config.ipapiKey = process.env.VUE_APP_IpapiKey;
config.appEnvironment = process.env.VUE_APP_Environment;


globals.user = new User;
// globals.user.setLocalStorageEncryptionStateFlag();
// globals.user.getMostRecentUser();

let authenticationMethod = localStorage.getItem('authMethod');
globals.user.setAuthenticationMethod(authenticationMethod);

let activeCultureCode = localStorage.getItem('activeCultureCode');
globals.setActiveCultureCode(activeCultureCode);

TScMessenger.writeDebugMessage('In App.vue...');


export default {
  name: 'App',
  setup() {

    
    const router = useRouter();
    const route = useRoute();
    const bus = inject('bus');
    const ROUTES = inject('ROUTES');
    
    const curModalType = ref(null);
    const curModal2Type = ref(null);
    const curModal3Type = ref(null);
    const curModal4Type = ref(null);
    
    const curModal = ref(null);
    const curModal2 = ref(null);
    const curModal3 = ref(null);
    const curModal4 = ref(null);
    
    const curModalProps = ref(null);
    const curModal2Props = ref(null);
    const curModal3Props = ref(null);
    const curModal4Props = ref(null);
    
    
    // const curModal = shallowRef(null);
    let curData = null;
    let curData2 = null;
    let curData3 = null;
    let curData4 = null;
    let modalQueue = [];
    
    let portraitMessageDisplayed;
    

    onBeforeMount(async () => {
        globals.indexedDbReady.value = false;
        if(performance !== undefined && performance.navigation !== undefined && performance.navigation.type !== undefined && performance.navigation.type === 1) {
            TScMessenger.writeDebugMessage('Setting globals.dataModelReady.value to false in App.vue onBeforeMount...');
            globals.dataModelReady.value = false;
        }
        if(route.name !== 'externalAccess') {

            let externalAccessInfoForAPIUser = localStorage.getItem('externalAccessAPIUser');
            if(externalAccessInfoForAPIUser) {
                // localStorage.removeItem('externalAccessAPIUser');
                // globals.spinnerText.value = 'Re-attempting opening of vehicle...';
                TScMessenger.writeDebugMessage('App.vue, Re-attempting opening of vehicle after update...');
                router.push('/externalAccess' + externalAccessInfoForAPIUser);
            }
        }
        
    });

    onMounted(function () {
        
        TScMessenger.writeDebugMessage('In App.vue onMounted...');
        
        dataManager.setupIndexedDB()
        .then(function (setupMessage) {
            TScMessenger.writeDebugMessage('In App.vue, onMounted, after setupIndexedDB...');
            globals.indexedDbReady.value = true;
            // if(!globals.user.getAppRefreshUnderway()) {
            //     globals.dataModelReady.value = true;
            // }
            TScMessenger.writeTimerMessage('In main, after indexeddb setup...');
            config.setLanguageCultureCode(globals.getActiveCultureCodeForTranslations());
            globals.user.setAvailableMeasurementSystemsDefault();
            TScMessenger.writeTimerMessage('In main, before checkIsOnline');
            
            dataManager.checkIsOnlineHeadVersion()
                .then(function () {
                    var isOnline = true;
                    TScMessenger.writeTimerMessage('In main, after checkIsOnline success');
                    globals.isOnline.value = isOnline;

                    if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE) {
                        // globals.user.updateUser({ securityToken: '' });
                        activate();
                        compositionComplete();
                    } else if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.MICROSOFT) {
                        TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=true, authentication method=MICROSOFT');
                        // https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa
                        // var silentRequest = {
                        //     scopes: ["user.read"],
                        //     forceRefresh: true
                        // };
                        
                        msalAuth.setupAndInitialiseMsalPublicClientApplication()
                            .then(data => {
                                globals.user.setMicrosoftLoggedInUserAccessTokenAsPassword(data.accessToken);
                                globals.user.setEmail(data.email);
                                activate();
                                compositionComplete();
                            })
                            .fail(error => {
                                var breakHere = 0;
                            });
                        
                        // globals.getAuthContext().acquireTokenSilent(["user.read"], null).then(

                            
                    } else {
                        TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=true, authentication method=TRUCKSCIENCE');
                        // globals.user.updateUser({ securityToken: '' });
                        activate();
                        compositionComplete();
                    }
                    
                    
                    

                    //     if (isOnline === true) {
                    //         if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE) {
                    //             TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=true, authentication method=GOOGLE');
                    //             if (auth2 !== undefined) {
                    //                 auth2.currentUser.get().reloadAuthResponse().then(function (authResponse) {
                    //                     globals.user.updateUser({ securityToken: '', password: authResponse.id_token });
                    //                     app.setRoot('viewmodels/shell');
                    //                 }, function (data) {
                    //                     globals.user.updateUser({ securityToken: '' });
                    //                     app.setRoot('viewmodels/shell');
                    //                 });
                    //             } else {
                    //                 globals.user.updateUser({ securityToken: '' });
                    //                 app.setRoot('viewmodels/shell');
                    //             }
                    //         } else if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.MICROSOFT) {
                    //             TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=true, authentication method=MICROSOFT');
                    //             // https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa
                    //             globals.getAuthContext().acquireTokenSilent(["user.read"], null).then(
                    //                 function (accessToken) {
                    //                     globals.user.updateUser({ securityToken: '', password: accessToken });
                    //                     app.setRoot('viewmodels/shell');
                    //                 },
                    //                 function (data) {
                    //                     globals.user.updateUser({ securityToken: '' });
                    //                     app.setRoot('viewmodels/shell');
                    //                 }
                    //             );
                    //         } else {
                    //             TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=true, authentication method=TRUCKSCIENCE');
                    //             globals.user.updateUser({ securityToken: '' });
                    //             app.setRoot('viewmodels/shell');
                    //         }

                    //     } else {
                    //         TScMessenger.writeTimerMessage('In main, after checkIsOnline success, online=false');
                    //         app.setRoot('viewmodels/shell');
                    //     }
                    // })
                    // .fail(function () {
                    //     TScMessenger.writeTimerMessage('In main, after checkIsOnline failure');
                    //     globals.isOnline(false);

                    //     app.setRoot('viewmodels/shell');
                    // });
                    
                })
                .fail(function (error) {
                    //alert('main: ' + error)
                    //var breakHere = 0;
                    try {
                    TScMessenger.writeTimerMessage('Error in main when setting up indexed db');
                    TScMessenger.writeErrorMessage('Error in main when setting up indexed db');
                    TScMessenger.writeErrorMessage('error: ' + error);
                    Object.keys(error).forEach(function (exKey) {
                        TScMessenger.writeErrorMessage(exKey);
                    });
                    } catch (errroExtractionEx) {
                    TScMessenger.writeErrorMessage('error setting up indexed db');
                    }
                    
                });
            });
        bus.on('displayMessage', (data) => {
            if(curModalType.value !== null && curModalType.value !== '' && data.displayMode !== config.MODAL_DISPLAY_MODE.OVERLAY) {
                modalQueue.push(data);
            }else {
                let modalSlot = 1;
                if(data.displayMode === config.MODAL_DISPLAY_MODE.OVERLAY) {
                    if(curModalType.value !== null && curModalType.value !== '') {
                        modalSlot++;
                        if(curModal2Type.value !== null && curModal2Type.value !== '') {
                            modalSlot++;
                            if(curModal3Type.value !== null && curModal3Type.value !== '') {
                                modalSlot++;
                            }
                        }
                    }
                }
                data.modalSlot = modalSlot;
                displayModal(data);
            }
        });

        function displayModal(data) {
            let curDataToUse, curModalToUse, curModalPropsToUse, curModalTypeToUse;
            switch(data.modalSlot) {
                case 1:
                    curData = data;
                    curModalProps.value = curData.data;
                    curModalType.value = data.type;

                    curDataToUse = curData;
                    curModalToUse = curModal;
                    curModalPropsToUse = curModalProps;
                    curModalTypeToUse = curModalType;
                    break;
                case 2:
                    curData2 = data;
                    curModal2Props.value = curData2.data;
                    curModal2Type.value = data.type;

                    curDataToUse = curData2;
                    curModalToUse = curModal2;
                    curModalPropsToUse = curModal2Props;
                    curModalTypeToUse = curModal2Type;
                    break;
                case 3:
                    curData3 = data;
                    curModal3Props.value = curData3.data;
                    curModal3Type.value = data.type;

                    curDataToUse = curData3;
                    curModalToUse = curModal3;
                    curModalPropsToUse = curModal3Props;
                    curModalTypeToUse = curModal3Type;
                    break;
                case 4:
                    curData4 = data;
                    curModal4Props.value = curData4.data;
                    curModal4Type.value = data.type;

                    curDataToUse = curData4;
                    curModalToUse = curModal4;
                    curModalPropsToUse = curModal4Props;
                    curModalTypeToUse = curModal4Type;
                    break;
            }

            
            setTimeout(function(){
                curModalToUse.value.show().then(function (result){
                
                    if(curDataToUse.callback()) {
                        curDataToUse.callback()(result);
                    }
                    curModalTypeToUse.value = null;
                    checkQueue();
                });
            }, 100);
        }

        function checkQueue() {
            if(modalQueue.length > 0) {
                displayModal(modalQueue.shift());
            }
        }
    });

    // onUpdated(() => {
    //     TScMessenger.writeDebugMessage('In onUpdated lifecycle function App.vue...');
    // });

   

    function refreshDfdCallback() {
        // TScMessenger.writeDebugMessage('App.vue path inside then... globals.dataRefreshConcurrentPath1Complete: ' + globals.dataRefreshConcurrentPath1Complete + ', globals.dataRefreshConcurrentPath2Complete: ' + globals.dataRefreshConcurrentPath2Complete);
        // globals.dataRefreshConcurrentPath1Complete = true;
        // if(globals.dataRefreshConcurrentPath1Complete && globals.dataRefreshConcurrentPath2Complete) {
            TScMessenger.writeDebugMessage('App.vue path inside double flag check...');
            globals.hideSpinner();
            globals.spinnerText.value = '';
            // globals.dataRefreshConcurrentPath1Complete = false;
            // globals.dataRefreshConcurrentPath2Complete = false;
            
            bus.emit('updateSelectionData');
            globals.refreshDfd = null;
            globals.refreshDfdCallback = null;
            // doStandardNavigation();
        // }
        TScMessenger.writeDebugMessage('globals.dataModelReady.value = true in refreshDfdCallback...');
        globals.dataModelReady.value = true;
        
    }

    function refreshDfdFailCallback(error) {
        globals.refreshDfd = null;
        globals.hideSpinner();
        globals.spinnerText.value = '';
        try {
            dataManager.sendLoginDebugInfo('shell', "shell.activate, refresh process, failure, 4801, Refresh Scenario: " + globals.user.getLastUrlHash(), "", error, globals.user.getEmailAddress());
            var metaDataObject = {};
            metaDataObject[config.INTERCOM_METADATA_ITEM.DATA_REFRESH_SCENARIO] = globals.user.getLastUrlHash();
            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.DATA_REFRESH_FAILED, metaDataObject));
        } catch (sendDebugEx) {
            var failedToSendEmail = 0;
        }
        globals.showDialog(new CustomMessageBox(config.getMessageText('4801', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4801')))
            .addCallback(function () {
                globals.indexedDbReady.value = false;
                globals.dataModelReady.value = false;
                bus.emit('checkDataIntegrity');
                // bus.emit('routeToLogout');
            });
        

    }

    function activate() {

        TScMessenger.writeTimerMessage('In shell, activate start');
        
        // bus.emit('setupIsOnlineCheckSyncSubscription');
        globals.paymentMenuStatus.value = config.PAYMENT_MENU_OPTIONS.DO_NOT_DISPLAY;
        
        
       if (globals.user.getAppRefreshUnderway()) {
            if (globals.refreshDfd !== null) {
                TScMessenger.writeDebugMessage('App.vue, top of activate, globals.refreshDfd !== null, path1 ...');
                globals.showSpinner();
                TScMessenger.writeDebugMessage('App.vue, top of activate, path before then...');
                globals.refreshDfd.promise()
                    .then(refreshDfdCallback)
                    .fail(refreshDfdFailCallback);
            } else {
                globals.refreshDfdCallback = refreshDfdCallback;
                globals.refreshDfdFailCallback = refreshDfdFailCallback;
            }


        }

        return dataManager.loadLocallyOrRetrieveAndStoreAppLogos()
            .then(function () {
                TScMessenger.writeTimerMessage('In shell, activate dataManager.loadLocallyOrRetrieveAndStoreAppLogos success');
                if (globals.user.getAppUpdateAvailable() === true) {
                    bus.emit('handleAppUpdate');
                } else if(localStorage.getItem('appUpdateDetected') === 'YES') {
                    localStorage.removeItem('appUpdateDetected');
                    TScMessenger.writeDebugMessage('appUpdateDetected from index.html, forcing data refresh');
                    bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                }else {
                    if (navigator.serviceWorker) {
                        let versionNumber = process.env.VUE_APP_VERSION;
                        // setupServiceWorkerUpdateHandler();
                        TScMessenger.writeDebugMessage("adding listener for sw registered: " + versionNumber);
                        // messageChannel.port1.onmessage = (event) => {
                        //     if (event.data && event.data.type === 'PROMPT_USER_TO_UPDATE') {
                        //         // if(registration.waiting && registration.active) {
                        //         //     console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting...');
                        //         //     registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //         // }else {
                        //         //     console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...');
                        //         //     registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //         // }
                        //         console.log('Recieved PROMPT_USER_TO_UPDATE App.vue...');
                        //         bus.emit('setupToastToInformUserOfUpdate');
                        //     } else if (event.data && event.data.type === 'AUTO_UPDATE') {
                        //         console.log('Recieved AUTO_UPDATE App.vue...');
                        //         bus.emit('handleAppUpdate');
                                
                        //     }
                        // };
                        document.addEventListener(
                            'swRegistered', function (e) {
                                let registeredServiceWorkerContainer = e.detail;
                                globals.setRegisteredServiceWorkerContainer(e.detail);
                                // if (registeredServiceWorkerContainer.waiting && registeredServiceWorkerContainer.active) {
                                //     setTimeout(function () {
                                //         bus.emit('setupToastToInformUserOfUpdate');
                                //     }, 10000);
                                // }
                            }
                        );
                        document.addEventListener(
                            'swUpdated', function (e) {
                                let registration = e.detail;
                                TScMessenger.writeDebugMessage('waiting App.vue swUpdated:' + versionNumber + ', ' + registration.waiting );
                                TScMessenger.writeDebugMessage('active App.vue swUpdated:' + versionNumber + ', ' + registration.active );
                                TScMessenger.writeDebugMessage('installing App.vue swUpdated:' + versionNumber + ',' + registration.installing );

                                // if(route.name !== 'externalAccess') {
            
                                    let externalAccessInfoForAPIUser = localStorage.getItem('externalAccessAPIUser');
                                    if(externalAccessInfoForAPIUser) {
                                       
                                        // localStorage.removeItem('externalAccessAPIUser');
                                        if (registration.active) {
                                            if(registration.waiting) {
                                                TScMessenger.writeDebugMessage('swUpdated, /externalAccess, update installed event fired, emitting handleAppUpdate... ');
                                                registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                                // globals.spinnerText.value = '/externalAccess, Applying update...';
                                                navigator.serviceWorker.addEventListener('controllerchange', function (event) {
                                                    //console.log('controlling event detected');
                                                    TScMessenger.writeDebugMessage('swUpdated, /externalAccess, Calling forceDataRefresh, controllerchange');
                                                    bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                                                    // forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
                                                });
                                                //console.log('sending skip waiting message...');
                                                TScMessenger.writeDebugMessage('swUpdated, /externalAccess, Calling skipwaiting...');
                                                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                            }
                                            
                                            //doUpdateCode();
                                        } else {
                                            //do nothing - first time install
                                        }
                                        
                                    }
                                // }
                                // if (registration.active) {
                                //     // console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active... App.vue swUpdated...');
                                //     // registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                    
                                    
                                //     if(registration.waiting) {

                                //         if(globals.checkingForUpdates) {
                                //             console.log('swUpdated, checkingForUpdates=true, emitting setupToastToInformUserOfUpdate... ' + versionNumber);
                                //             globals.checkingForUpdates = false;
                                //             bus.emit('setupToastToInformUserOfUpdate');
                                //         } else {
                                //             console.log('swUpdated, checkingForUpdates=false, emitting handleAppUpdate... ' + versionNumber);
                                //             bus.emit('handleAppUpdate');
                                //         }
                                //         // console.log('Posting CHECK_UPDATE_FLAG to active... App.vue swUpdated...');
                                //         // navigator.serviceWorker.controller.postMessage({ type: 'CHECK_UPDATE_FLAG'}, [messageChannel.port2]);
                                //         // console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting... App.vue swUpdated, update already moved to waiting...');
                                //         // registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                //         // console.log('Setting up update toast to inform user of update 2...');
                                //         // setTimeout(function () {
                                //         //     if(registration.waiting) {
                                //         //         bus.emit('setupToastToInformUserOfUpdate');
                                //         //     }
                                //         // }, 10000);

                                //     }
                                    
                                //     //doUpdateCode();
                                // } else {
                                //     //do nothing - first time install
                                // }
                            }
                        );
                        document.addEventListener(
                            'swUpdateFound', function (e) {
                                let registration = e.detail;
                                TScMessenger.writeDebugMessage('adding state change listener, swUpdateFound... ' + versionNumber);
                                // navigator.serviceWorker.getRegistration('/').then((registration) => {
                                    TScMessenger.writeDebugMessage('waiting App.vue, swUpdateFound:' + versionNumber + ', ' + registration.waiting );
                                    TScMessenger.writeDebugMessage('active App.vue, swUpdateFound:' + versionNumber + ',' + registration.active );
                                    TScMessenger.writeDebugMessage('installing App.vue, swUpdateFound:' + versionNumber + ',' + registration.installing );
                                    if (registration && registration.installing) {
                                        registration.installing.addEventListener('statechange', function (event) {
                                            TScMessenger.writeDebugMessage('service worker state change fired, swUpdateFound:' + versionNumber + ', ' + event.target.state);
                                            if (event.target.state === 'installed') {
                                                if (registration.active) {
                                                    if(registration.waiting) {
                                                        // console.log('Posting CHECK_UPDATE_FLAG to active, swUpdateFound... App.vue, installed state change detected... ' + navigator.serviceWorker.controller);
                                                        // navigator.serviceWorker.controller.postMessage({ type: 'CHECK_UPDATE_FLAG'}, [messageChannel.port2]);
                                                        TScMessenger.writeDebugMessage('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting, swUpdateFound, installed... App.vue... ' + versionNumber);
                                                        registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                                        let externalAccessInfoForAPIUser = localStorage.getItem('externalAccessAPIUser');
                                                        if(externalAccessInfoForAPIUser) {
                                                            navigator.serviceWorker.addEventListener('controllerchange', function (event) {
                                                                //console.log('controlling event detected');
                                                                TScMessenger.writeDebugMessage('updateFound, installing, /externalAccess, Calling forceDataRefresh, controllerchange');
                                                                bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                                                                // forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
                                                            });
                                                            //console.log('sending skip waiting message...');
                                                            TScMessenger.writeDebugMessage('updateFound, installing, /externalAccess, Calling skipwaiting...');
                                                            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                                        }else {
                                                            if(globals.checkingForUpdates) {
                                                                TScMessenger.writeDebugMessage('swUpdateFound, checkingForUpdates=true, emitting setupToastToInformUserOfUpdate... ' + versionNumber);
                                                                globals.checkingForUpdates = false;
                                                                bus.emit('setupToastToInformUserOfUpdate');
                                                            } else {
                                                                TScMessenger.writeDebugMessage('swUpdateFound, checkingForUpdates=false, emitting handleAppUpdate... ' + versionNumber);
                                                                bus.emit('handleAppUpdate');
                                                            }
                                                        }
                                                        
                                                        // console.log('Setting up update toast to inform user of update...');
                                                        // setTimeout(function () {
                                                        //     if(registration.waiting) {
                                                        //         bus.emit('setupToastToInformUserOfUpdate');
                                                        //     }
                                                        // }, 10000);
                                                    }
                                                    
                                                    //doUpdateCode();
                                                } else {
                                                    //do nothing - first time install
                                                }
                                            }
                                        });
                                    }else if(registration && registration.waiting) {
                                        if (registration.active) {
                                            if(registration.waiting) {
                                                // console.log('Posting CHECK_UPDATE_FLAG to active, swUpdateFound... App.vue, update already moved to waiting...');
                                                // navigator.serviceWorker.controller.postMessage({ type: 'CHECK_UPDATE_FLAG'}, [messageChannel.port2]);
                                                TScMessenger.writeDebugMessage('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting, swUpdateFound... App.vue, update already moved to waiting...');
                                                registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                                let externalAccessInfoForAPIUser = localStorage.getItem('externalAccessAPIUser');
                                                if(externalAccessInfoForAPIUser) {
                                                    navigator.serviceWorker.addEventListener('controllerchange', function (event) {
                                                        //console.log('controlling event detected');
                                                        TScMessenger.writeDebugMessage('updateFound, waiting, /externalAccess, Calling forceDataRefresh, controllerchange');
                                                        bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                                                        // forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
                                                    });
                                                    //console.log('sending skip waiting message...');
                                                    TScMessenger.writeDebugMessage('updateFound, waiting, /externalAccess, Calling skipwaiting...');
                                                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                                }else {
                                                    if(globals.checkingForUpdates) {
                                                        TScMessenger.writeDebugMessage('swUpdateFound, checkingForUpdates=true, emitting setupToastToInformUserOfUpdate... ' + versionNumber);
                                                        globals.checkingForUpdates = false;
                                                        bus.emit('setupToastToInformUserOfUpdate');
                                                    } else {
                                                        TScMessenger.writeDebugMessage('swUpdateFound, checkingForUpdates=false, emitting handleAppUpdate... ' + versionNumber);
                                                        bus.emit('handleAppUpdate');
                                                    }
                                                }
                                                
                                                // console.log('Setting up update toast to inform user of update 3...');
                                                // setTimeout(function () {
                                                //     if(registration.waiting) {
                                                //         bus.emit('setupToastToInformUserOfUpdate');
                                                //     }
                                                // }, 10000);
                                            }
                                            
                                            //doUpdateCode();
                                        } else {
                                            //do nothing - first time install
                                        }
                                    }
                                // });
                                    // let registeredServiceWorkerContainer = globals.getRegisteredServiceWorkerContainer();
                                    // if(registeredServiceWorkerContainer) {
                                    //     registeredServiceWorkerContainer.installing.addEventListener('statechange', function (event) {
                                    //         console.log('service worker state change fired: ' + event.target.state);
                                    //         if (event.target.state === 'installed') {
                                    //             if (registeredServiceWorkerContainer.active) {
                                    //                 console.log('Setting up update toast to inform user of update...');
                                    //                 setTimeout(function () {
                                    //                     bus.emit('setupToastToInformUserOfUpdate');
                                    //                 }, 5000);
                                    //                 //doUpdateCode();
                                    //             } else {
                                    //                 //do nothing - first time install
                                    //             }
                                    //         }
                                    //     });
                                    // }
                            }
                        );
                        // if (navigator.serviceWorker) {  
                        //     navigator.serviceWorker.addEventListener(
                        //     'controllerchange', () => {
                        //         if (this.refreshing) return;
                        //         this.refreshing = true;
                        //         window.location.reload();
                        //     }
                        //     );
                        // }
                        
                        // navigator.serviceWorker.getRegistration('/').then((registration) => {
                        //     console.log('Pre NEW_APP_ACKNOWLEDGE_RESPONSE message...');
                        //      if(registration.installing) {
                        //         console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to installing... App.vue');
                        //         registration.installing.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     }
                        //     if(registration.waiting) {
                        //         console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting... App.vue');
                        //         registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     }/*else {
                        //         console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...' + versionNumber);
                        //         registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     }*/
                        //     if(registration.active) {
                        //         console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active... App.vue');
                        //         registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     }
                        //     // if(registration.waiting && registration.active) {
                        //     //     console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting...');
                        //     //     registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     // }else {
                        //     //     console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...');
                        //     //     registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     // }
                        //     // navigator.serviceWorker.onmessage = (event) => {
                        //     //     if (event.data && event.data.type === 'NEW_APP_ACKNOWLEDGE') {
                        //     //         if(registration.waiting && registration.active) {
                        //     //             console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to waiting...');
                        //     //             registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     //         }else {
                        //     //             console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...');
                        //     //             registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                        //     //         }
                                    
                        //     //     }
                        //     // };
                        // });
                        
                    }else if (window.applicationCache !== undefined && window.applicationCache.status === window.applicationCache.UPDATEREADY) {
                        globals.updateStatus = 'UPDATEREADY_FIRED_SHELL';
                    } else if (window.applicationCache !== undefined && window.applicationCache.status === window.applicationCache.DOWNLOADING) {
                        globals.updateStatus = 'DOWNLOADING';
                    }
                    
                    logger.log('TruckScience SPA Loaded!', null, config.showDebugToast);
                    TScMessenger.writeTimerMessage('In shell, TruckScience SPA Loaded!');
                    globals.salesModeActive.value = true;

                    config.setLanguageCultureCode(globals.getActiveCultureCodeForTranslations());
                    TScMessenger.writeDebugMessage('User Story 17565 - App.vue, activate, after setLanguageCultureCode..., cultureCode = ' + globals.getActiveCultureCodeForTranslations());
                    globals.updateNavBarTextObservable();
                    
                    if(route.name !== 'externalAccess') {
                        let authenticationState = dataManager.securityTokenIsValidAsTextFlag(true, licenceTransferCallback);
                        if (authenticationState === config.AUTHENTICATION_STATE.AUTHENTICATED) { 
                            globals.user.setMenuItemsHistoryOnSelectionScreen([]);
                            // setupLoginStepCallbackMechanism(globals.spinnerText);
                            if (config.identity.username !== '') {
                                globals.user.updateUser(config.identity);
                            }
                            bus.emit('performStartupTasks', { callback: function () {
                                    TScMessenger.writeDebugMessage('In shell, performStartupTasks then');
                                    dataManager.log("TruckScience SPA Loaded!", null, 'shell', config.LOG_MESSAGE_TYPE.INFO);
                                    
                                    dataManager.getUserSettings()
                                        .then(function (userSettings) {
                                            globals.userSettingsDfd = null;
                                            continueWithAppSetup();
                                        });
                                } });

                            //set up the SignalR connection if it does not yet exist
                            bus.emit('initialiseSignalR');

                        } else {

                            if(authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_USERNAME || 
                                authenticationState === config.AUTHENTICATION_STATE.UNEXPECTED_SERVER_ERROR ||
                                authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_AUTHENTICATION_METHOD || 
                                authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_PASSWORD ||
                                authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_SECURITY_TOKEN_API_USER || 
                                authenticationState === config.AUTHENTICATION_STATE.AUTHENTICATION_FAILED_API_USER_INVALID_SECURITY_TOKEN ||
                                authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_INVALID_APPLICATION_TYPE || 
                                authenticationState === config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_MACHINE_CODE || 
                                authenticationState === config.AUTHENTICATION_STATE.AUTHENTICATION_FAILED ||
                                globals.user.getAppRefreshUnderway() || 
                                (globals.user.getEmailAddress() !== '' && globals.user.getEmailAddress() !== null && globals.user.getEmailAddress() !== undefined)) {
                                // if we are here it means that something has gone wrong with authentication (possibly a timeout and during an update or data refresh), so the safest thing to do is log the user out
                                
                                bus.emit('routeToLogout');
                            }else {
                                var loginNavRoute;
                                let routesArray = [];
                                routesArray.push(ROUTES.GET_STARTED);
                                // routesArray.push(ROUTES.SIGN_UP);
                                routesArray.push(ROUTES.SELECTION);
                                routesArray.push(ROUTES.NOT_FOUND);
                                // loginNavRoute = window.location.pathname === "" ? ROUTES.GET_STARTED.path : window.location.pathname;
                                
                                loginNavRoute = ROUTES.GET_STARTED.path;
                                TScMessenger.writeDebugMessage('Calling from App.vue activate');

                                
                                routesArray.push(ROUTES.EXTERNAL_ACCESS);
                                utils.removeAllRoutes();
                                utils.addRoutes(routesArray);
                                
                                if(localStorage.getItem('mode')) {
                                    localStorage.removeItem('mode');
                                } else if(localStorage.getItem('accessCode')) {
                                    localStorage.removeItem('accessCode');
                                } else if(localStorage.getItem('accessFlag')) {
                                    localStorage.removeItem('accessFlag');
                                }else {
                                    // return router.push(loginNavRoute);    
                                }
                                
                            }

                            //##########################################################################
                            //generate only login route, other routes will be generated on successful login
                            //##########################################################################

                            
                        }
                    }
                    
                }


            }).fail(function (error) {
                //var ifThisIsHitThenIndexedDBFailed = 0;
                try {
                    TScMessenger.writeErrorMessage('Error in shell activate when setting up indexed db');
                    TScMessenger.writeErrorMessage('error: ' + error);
                    Object.keys(error).forEach(function (exKey) {
                        TScMessenger.writeErrorMessage(exKey);
                    });
                } catch (errorExtractionEx) {
                    //do nothing
                }

            });

    }
    function licenceTransferCallback() {
        TScMessenger.writeTimerMessage('In App.vue, hiding spinner after security check passed...');
        globals.hideSpinner();
        bus.emit('showTransferLicenceVerificationModal', { callback: function () {
            globals.hideSpinner();
        }})
    }
function continueWithAppSetup() {
    //##########################################################################
    //generate routes based on the permissions in local storage
    //##########################################################################
    // routesArray = utils.generateRoutesBasedOnPermissions(routesArray, globals.user.getPermissions());
    // this.$router.activeInstruction(null);
    // router.makeRelative("")
    //     .map(routesArray)
    //     .buildNavigationModel()
    //     .activate();

    // router.mapUnknownRoutes(config.ROUTES.GET_STARTED.moduleId, config.ROUTES.GET_STARTED.hash);
    let routesArray = [];
    routesArray = utils.generateRoutesBasedOnPermissions(routesArray, globals.user.getPermissions());
    
    
    routesArray.push(ROUTES.EXTERNAL_ACCESS);
    utils.removeAllRoutes();
    utils.addRoutes(routesArray);
    
    config.setLanguageCultureCode(globals.getActiveCultureCodeForTranslations());
    TScMessenger.writeDebugMessage('In shell, continueWithAppSetup, after generateRoutesBasedOnPermissions...');
    TScMessenger.writeDebugMessage('User Story 17565 - App.vue, continueWithAppSetup, after setLanguageCultureCode..., cultureCode = ' + globals.getActiveCultureCodeForTranslations());
    globals.updateNavBarTextObservable();
    globals.spinnerText.value = '';

    if (globals.isAmericanCultureCode(globals.getActiveCultureCodeForTranslations()) === true) {
        globals.addAmericanPageContainer();
        if (globals.isFrenchCultureCode(globals.getActiveCultureCodeForTranslations()) === true) {
            globals.addFrenchPageContainer();
        }
    } else {
        globals.removeAmericanPageContainer();
    }
    showBuyNowIfNecessary();
    bus.emit('setupIntercomUserSettings');

    if (globals.user.displayRecommendedBrowserMessage() && !globals.isRecommendedBrowser()) {
        bus.emit('setupRecommendedBrowserToast');
    }


    // if (performance !== undefined && performance.navigation !== undefined && performance.navigation.type !== undefined) {
        /*if (performance.navigation.type !== 1) {
            bus.emit('checkIfSyncNeeded', { passingThroughActivate: true });
            doStandardNavigation();
        } else*/ if (/*globals.navigationIsFromDataRefreshScenario()*/ globals.user.getAppRefreshUnderway()) {
            let curRefreshScenario = globals.user.getAppRefreshUnderway();
            globals.user.removeAppRefreshUnderway();
            if (curRefreshScenario === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED) {
                //set flag so that you are already subscribed message shows when app is up
                globals.showYourAreAlreadySubscribedMessageFlag = true;
            } /*else if (curRefreshScenario === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE) {
                //set flag so that you you have successfully subscribed message shows when app is up
                globals.showYouHaveSuccessfullySubscribedMessageFlag = true;
            }*/ else if (curRefreshScenario === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_MISSING_DATA) {
                globals.spinnerText.value = config.getTranslationText('640');
            } else if (curRefreshScenario === config.APP_DATA_REFRESH_SCENARIO.USER_REFRESH) {
                globals.spinnerText.value = config.getTranslationText('641');
            }
            if (globals.refreshDfd === null) {
                TScMessenger.writeDebugMessage('App.vue, globals.refreshDfd !== null, path1 ...');
                doStandardNavigation();
            } 


        } /*else if (localStorage.getItem('forcedRefreshFromBuild51') !== null && localStorage.getItem('forcedRefreshFromBuild51') !== undefined) {
            localStorage.removeItem('forcedRefreshFromBuild51');
            doStandardNavigation();
        }*/ else {

            if (globals.user.getFirstName() === config.shareeTempName) {
                return doShareNavigation();
            } else {
                globals.openedSharedOfferToastFlag = false;
                bus.emit('setupTimers');
                bus.emit('setupOnlineOfflineEventListeners');
                doStandardNavigation();
            }
        }
    // } else {
    //     if (globals.refreshDfd !== null) {
    //         TScMessenger.writeDebugMessage('App.vue, globals.refreshDfd !== null, path2 ...');
    //     }
    //     bus.emit('checkIfSyncNeeded', { passingThroughActivate: true });
    //     doStandardNavigation();
    // }

    function doShareNavigation() {
        if (globals.user.getAccessCode() !== '' || (router.activeInstruction().queryString !== "" && router.activeInstruction().queryString.indexOf('accessCode') !== -1)) {
            var queryString = '';
            if (globals.user.getAccessCode() !== '') {
                //queryString = 'accessCode=' + globals.user.getAccessCode();
                offerManager.handleDeepLinkAccessCode(globals.user.getAccessCode());
            } else {
                queryString = router.activeInstruction().queryString;
                return router.push('/externalAccess?' + queryString);
            }

        } else {
            bus.emit('logout');
        }
    }

    function doStandardNavigation() {
        // if (globals.user.getFirstName() === config.shareeTempName || globals.user.getAccessCode() !== '' || (router.activeInstruction() && router.activeInstruction().queryString !== null && router.activeInstruction().queryString !== "" && router.activeInstruction().queryString.indexOf('accessCode') !== -1)) {
        if (globals.user.getFirstName() === config.shareeTempName || globals.user.getAccessCode() !== '' || (router.currentRoute.value.query !== undefined && router.currentRoute.value.query.accessCode)) {
            return doShareNavigation();
        } else {
            // return router.push(ROUTES.SELECTION.name);
        }

    }
}
        function compositionComplete() {
        $('meta[name=apple-mobile-web-app-title]').attr('content', config.getAppShortcutName());

        $(document).ready(function () {

            //$('.dropdown-toggle').click(function () {
            //    $(this).next('.dropdown-menu').slideToggle(config.menuSlideDuration);
            //});
            //$('.dropdown-toggle').blur(function () {
            //    $(this).next('.dropdown-menu').slideUp(config.menuSlideDuration);
            //});
            // ADD SLIDEDOWN ANIMATION TO DROPDOWN //
            $('.dropdown').on('show.bs.dropdown', function (e) {
                $(this).find('.dropdown-menu').first().stop(true, true).slideDown(config.menuSlideDuration);
            });
            // ADD SLIDEUP ANIMATION TO DROPDOWN //
            $('.dropdown').on('hide.bs.dropdown', function (e) {
                $(this).find('.dropdown-menu').first().stop(true, true).slideUp(config.menuSlideDuration);
            });


            globals.passedThroughShell = true;
            if (globals.getAndroidVersion() !== false) {
                try {
                    document.addEventListener("visibilitychange", function () {
                        if (document.hidden === true) {
                            globals.screenCounter = 0;
                            globals.passedThroughShell = false;
                        } else {
                            checkIfUpdate();
                        }
                    }, false);
                } catch (visibilityChangedEx) {
                    dataManager.log("compositionComplete, attempting to add visibilityChange event listener", visibilityChangedEx, 'shell', config.LOG_MESSAGE_TYPE.ERROR);
                }
            }



            if (CustomTruckScienceRequirements.meetsMinimumRequirements()) {
                if (globals.isOpenInTabletBrowser()) {
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1577', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1577')));
                }
                //checkBrowserDimensions();
                isPortrait(true);
                portraitMessageDisplayed = false;

                if (window.onorientationchange !== undefined) {
                    window.addEventListener('orientationchange', function () {
                        isPortrait(false);
                    }, false);
                }

                if (globals.isiPad() === true) {
                    if (window.navigator.standalone === true) {
                        if (globals.isiOS8() === true || globals.isiOS92() === true || globals.isiOS93() === true || globals.isiOS10() === true) {
                            // remove classes
                            $('#ipad-info-bar-id').removeClass();
                            $('#shell-main-view').removeClass('normal-page-container').addClass('ipad-page-container');
                            $('#content').addClass('ipad-section');
                        } else {
                            if (globals.isiOS9() === true) {
                                $('#ipad-info-bar-id').removeClass();
                                $('#shell-main-view').removeClass('normal-page-container ipad-page-container').addClass('ipad-page-container-ios9');
                                $('#content').addClass('ipad-section');
                            }
                        }
                    } else {
                        $('#shell-main-view').removeClass('normal-page-container').addClass('ipad-page-container');
                        //message goes here 
                    }
                } else {
                    if (globals.getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER) {
                        $('#shell-main-view').removeClass('ipad-page-container').addClass('normal-page-container ie-page-container');
                        $('#headerContent').removeClass('ie-page-container').addClass('ie-page-container');
                    } else if (globals.getBrowser() === config.BROWSER_NAME.FIREFOX) {
                        $('#shell-main-view').removeClass('ipad-page-container').addClass('normal-page-container firefox-page-container');
                        $('html').addClass('firefox-page-container');
                    } else if (globals.getBrowser() === config.BROWSER_NAME.EDGE) {
                        $('#shell-main-view').removeClass('ipad-page-container').addClass('normal-page-container edge-page-container');
                    } else if (globals.getBrowser() === config.BROWSER_NAME.SAFARI && CustomTruckScienceRequirements.getOperatingSystem() === CustomTruckScienceRequirements.OS_NAME.Mac) {
                        $('#shell-main-view').removeClass('ipad-page-container').addClass('normal-page-container mac-safari-page-container');
                    } else {
                        $('#shell-main-view').removeClass('ipad-page-container').addClass('normal-page-container');
                    }
                }

                bus.emit('updateColourScheme');


            } else {
                // open modal
                globals.showDialog(new CustomMessageBox(config.getMessageText('1576', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1576'))).addCallback(function (dialogResult) {
                    // hide application on OK
                    $('#applicationHost').hide();
                    window.location.href = "https://www.truckscience.com";
                });
            }
        });
    }

    function checkIfUpdate() {
        globals.screenCounter++;
        if (globals.getAndroidVersion() !== false) {
            if (globals.passedThroughShell === false && globals.screenCounter === 1) {
                if (globals.getAndroidVersion() !== false) {
                    window.applicationCache.update();
                }
            } else {
                globals.passedThroughShell = false;
            }
        }

    }

    function isPortrait(startup) {
        if (globals.isiPad()) {
            if (window.orientation !== 90 && window.orientation !== -90) {
                if (window.innerHeight > window.innerWidth) {
                    $("#applicationHost").hide();
                    if ($("#modal") !== null) {
                        $("#modal").hide();
                    }
                    if (startup) {
                        //the app.showMessage is not available at this stage so we have to show an alert instead
                        alert(config.getMessageText('1572', globals.getActiveCultureCodeForTranslations()));
                    } else {
                        //once the app has started up completely, we can show the dialog
                        if (!portraitMessageDisplayed) {
                            portraitMessageDisplayed = true;
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1572', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1572'))).addCallback(function (dialogResult) {
                                portraitMessageDisplayed = false;
                            });
                        }
                    }
                } else {
                    $("#applicationHost").show();
                    if ($("#modal") !== null) {
                        $("#modal").show();
                    }
                }
            } else {
                $("#applicationHost").show();
                if ($("#modal") !== null) {
                    $("#modal").show();
                }
            }
        } else {
            if (window.innerWidth < window.innerHeight) {
                $("#applicationHost").hide();
                if ($("#modal") !== null) {
                    $("#modal").hide();
                }
                if (startup) {
                    //the app.showMessage is not available at this stage so we have to show an alert instead
                    alert(config.getMessageText('1572', globals.getActiveCultureCodeForTranslations()));
                } else {
                    //once the app has started up completely, we can show the dialog
                    if (!portraitMessageDisplayed) {
                        portraitMessageDisplayed = true;
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1572', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1572'))).addCallback(function (dialogResult) {
                            portraitMessageDisplayed = false;
                        });
                    }
                }
            } else {
                $("#applicationHost").show();
                if ($("#modal") !== null) {
                    $("#modal").show();
                }
            }
        }
    }


    

    
    

 

    // function handleAppUpdate() {
    //     if (globals.offerSyncing.value === false) {
    //         //if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE) {
    //         //    auth2.currentUser.get().reloadAuthResponse().then(function (authResponse) {
    //         //        globals.user.updateUser({password: authResponse.id_token });
    //         //        forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
    //         //    });
    //         //} else {
    //         //    forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
    //         //}
    //         if (navigator.serviceWorker && globals.getBrowser() !== config.BROWSER_NAME.INTERNETEXPLORER) {
                
                
    //             //wBox.messageSW({ type: 'SKIP_WAITING' });
    //             if (globals.getRegisteredServiceWorkerContainer() && globals.getRegisteredServiceWorkerContainer().waiting) {
    //                 navigator.serviceWorker.addEventListener('controllerchange', function (event) {
    //                     //console.log('controlling event detected');
    //                     bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
    //                 });
    //                 //console.log('sending skip waiting message...');
    //                 globals.getRegisteredServiceWorkerContainer().waiting.postMessage({ type:'SKIP_WAITING' });
    //             } else {

    //                 bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
    //             }
    //         } else {
    //             bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
    //         }
    //     } else {
    //         var offerSyncingSubcriptionRef = watch(globals.offerSyncing, function (newValue) {
    //             if (newValue === false) {
    //                 offerSyncingSubcriptionRef();
    //                 //if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE) {
    //                 //    auth2.currentUser.get().reloadAuthResponse().then(function (authResponse) {
    //                 //        globals.user.updateUser({password: authResponse.id_token });
    //                 //        forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
    //                 //    });
    //                 //} else {
    //                 //    forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
    //                 //}
    //                 if (navigator.serviceWorker && globals.getBrowser() !== config.BROWSER_NAME.INTERNETEXPLORER) {
    //                     navigator.serviceWorker.addEventListener('controllerchange', function (event) {
    //                         //console.log('controlling event detected');
    //                         bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
    //                     });
    //                     //console.log('sending skip waiting message...');
    //                     //wBox.messageSW({ type: 'SKIP_WAITING' });
    //                     globals.getRegisteredServiceWorkerContainer().waiting.postMessage({ type:'SKIP_WAITING' });
    //                 } else {
    //                     bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
    //                 }
    //             }
    //         });
    //     }
    // }

    // function setupServiceWorkerUpdateHandler() {
    //     if ('serviceWorker' in navigator) {
    //         //console.log('setting up service worker update mechanism....');
    //         //var wb = new workbox.Workbox('/service-worker.js')
    //         //wBox.addEventListener('waiting', function (event) {
    //         //    console.log('waiting detected...');
    //         //    setupToastToInformUserOfUpdate();
    //         //});
    //         //registeredServiceWorkerContainer.addEventListener('waiting', function (event) {
    //         //    console.log('waiting detected...');
    //         //    setupToastToInformUserOfUpdate();
    //         //});

    //         if (globals.updateStatus === 'UPDATEREADY_FIRED_INDEX') {
    //             //console.log('setting up update toast due to update detected from index.cshtml listener...')
    //             setTimeout(function () {
    //                 bus.emit('setupToastToInformUserOfUpdate');
    //             }, 5000);
                
    //         }

    //         if (globals.getRegisteredServiceWorkerContainer()) {
    //             setupServiceWorkerUpdateListener();
    //         } else {
    //             globals.registerServiceWorker().then(function () {
    //                 setupServiceWorkerUpdateListener();
    //             });
    //         }
            
    //     }

        
    // }

    

    // function setupServiceWorkerUpdateListener(e) {
    //         // let registeredServiceWorkerContainer = globals.getRegisteredServiceWorkerContainer();
    //         let registeredServiceWorkerContainer = e.detail;
    //         globals.setRegisteredServiceWorkerContainer(e.detail);
    //         if (registeredServiceWorkerContainer.waiting && registeredServiceWorkerContainer.active) {
    //             setTimeout(function () {
    //                 bus.emit('setupToastToInformUserOfUpdate');
    //             }, 5000);
    //         }

    //         registeredServiceWorkerContainer.onupdatefound = function (event) {
    //             //console.log('Setting up update toast due to Service Worker update detected from shell listener...');
    //             //setTimeout(function () {
    //             //    setupToastToInformUserOfUpdate();
    //             //}, 5000);
    //             registeredServiceWorkerContainer.installing.addEventListener('statechange', function (event) {
    //                 if (event.target.state === 'installed') {
    //                     if (registeredServiceWorkerContainer.active) {
    //                         console.log('Setting up update toast to inform user of update...');
    //                         setTimeout(function () {
    //                             bus.emit('setupToastToInformUserOfUpdate');
    //                         }, 5000);
    //                         //doUpdateCode();
    //                     } else {
    //                         //do nothing - first time install
    //                     }
    //                 }
    //             });
    //         };
    //     }

    // function setupLoginStepCallbackMechanism(uiObservableToUpdate) {
    //     loginReAuthStepDisplayObservableRef = uiObservableToUpdate;
    //     dataManager.registerDataOperationListener(loginStepListenerCallbackFunction);
    // }

    

    // function performStartupTasks() {
    //     var startUpTaskDfd = $.Deferred();

    //     var startUpTasks = [];
    //     addTask(dataManager.loadProperties);
    //     addTask(dataManager.doDebugLogMaintenanceIfRequired);
    //     //addTask(dataManager.getUserSettings);

    //     doTask(startUpTasks);

    //     function doTask(taskArr) {
    //         if (taskArr.length > 0) {
    //             var curTask = taskArr.shift();
    //             curTask().then(function () {
    //                 doTask(taskArr);
    //             });
    //         } else {
    //             startUpTaskDfd.resolve();
    //         }

    //     }

    //     function addTask(task) {
    //         startUpTasks.push(function () {
    //             return task();
    //         });
    //     }

    //     return startUpTaskDfd.promise();
    // }

    function showBuyNowIfNecessary() {
        switch (globals.user.getUserType()) {
            case config.USER_TYPES.TRIAL_USER_OK:
            case config.USER_TYPES.TRIAL_USER_FORMER:
                //paymentMenuStatus(config.PAYMENT_MENU_OPTIONS.BUY_NOW);
                bus.emit('updatePaymentMenuStatus', { PAYMENT_MENU_OPTION: config.PAYMENT_MENU_OPTIONS.BUY_NOW });
                break;
            default:
                globals.paymentMenuStatus.value = config.PAYMENT_MENU_OPTIONS.DO_NOT_DISPLAY;
                break;
        }
    }

    

    return {
      customMessageDialogue,
      curModal,
      curModalType,
      curModalProps,
      curModal2,
      curModal2Type,
      curModal2Props,
      curModal3,
      curModal3Type,
      curModal3Props,
      curModal4,
      curModal4Type,
      curModal4Props,
      globals,
      offerIsOpen: offerManager.offerIsOpen,
      config,
      canShowRouterView: computed(function () { 
          TScMessenger.writeDebugMessage('canShowRouterView: ' + (globals.indexedDbReady.value && globals.dataModelReady.value));
          TScMessenger.writeDebugMessage('globals.indexedDbReady.value: ' + globals.indexedDbReady.value);
          TScMessenger.writeDebugMessage('globals.dataModelReady.value: ' + globals.dataModelReady.value);
          if(globals.indexedDbReady.value && globals.dataModelReady.value) {
              let breakHere = 0;
          }
          return globals.indexedDbReady.value && globals.dataModelReady.value;
        }),
      // openShareDialogOnClick: () => {
      //   bus.emit('openShareDialogOnClick', {});
      // },
        // displayBlackout,
        // displaySpinner,
      };

    },
    components: { FooterBar, NavBar, PaymentButton }
}
</script>

<style lang="scss">
@import "@/design/index.scss";
</style>

