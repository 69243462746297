<template>
    <tr class="settings-modal-multiselect-row" data-bind="template: { name: 'template-user-settings-multiselect' }">
        <td class="user-settings-modal-td-desc">
            <span data-bind="text: label">{{multiSelect.label}}</span>
        </td>
        <td class="summary-note-modal-td-desc-input">
            <div class="flex-start">
                <div class="info-tooltips-container flex-end">
                    <div class="increment-div info-tooltip-div">
                        <a v-if="multiSelect.displayTooltip === true"
                            :id="multiSelect.tooltipId"
                            :data-content="multiSelect.tooltipMessage"
                            @click="multiSelect.showPopover"
                            data-placement="top"
                            data-bind="click: showPopover, attr: {id: tooltipId, 'data-content': tooltipMessage}">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </a>
                    </div>
                    <div v-if="multiSelect.displayUpgradeTooltip === true"
                        class="increment-div info-tooltip-div info-upgrade-div">
                        <a :id="multiSelect.upgradeTooltipMessageId"
                            :data-content="multiSelect.upgradeTooltipMessage"
                            @click="multiSelect.showUpgradePopover"
                            data-placement="top"
                            data-bind="click: showUpgradePopover, attr: {id: upgradeTooltipMessageId, 'data-content': upgradeTooltipMessage}">
                            <i class="fa fa-chevron-circle-up fa-lg"></i>
                        </a>
                    </div>
                </div>
                <div class="multiselect-container-div" data-bind="with: multiSelect">
                    <MultiSelect :multiSelectFromParent="multiSelect.multiSelect"></MultiSelect>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import { toRef } from 'vue'
import MultiSelect from '@/components/multiselect/MultiSelect.vue'

export default {
    name: "InputTypeMultiSelect",
    props: {
        inputFromParent: Object
    },
    setup(props) {
        const multiSelect = toRef(props, "inputFromParent");
        return {
            multiSelect
        };
    },
    components: { MultiSelect }
}
</script>

<style lang="scss" scoped>

</style>