<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container notifyOtherUsersOfSaveModal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span v-text="getTranslationText('2959')"></span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div>
                    <span v-text="messageText"></span>
                </div>
                <div class="modal-label-input-container">
                    <div class="tsc-checkbox-with-message" @click="toggleCheckbox" @touchstart="toggleCheckbox">
                        <div class="tsc-icon-container">
                            <i :class="['far', 'fa-lg', isChecked ? 'fa-check-square' : 'fa-square']"></i>
                        </div>
                        <span v-text="getTranslationText('2961')"></span>
                    </div>
                </div>
                <div class="modal-label-input-container textarea">
                    <textarea class="form-control" v-model="comments" :placeholder="getTranslationText('2962')"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" @click="ok" @touchstart="ok"><span v-text="getTranslationText('271')"></span></button>
                <button class="btn btn-default btn-truckscience" @click="cancel" @touchstart="cancel"><span v-text="getTranslationText('65')"></span></button>
            </div>
        </div>
    </popup-modal>
</template>

<script>

import config from '@/services/config';
import globals from '@/services/globals';
import CustomMessageBox from '@/services/CustomMessageBox';
import PopupModal from '@/components/modals/PopupModal.vue';
import { ref, shallowRef, toRef } from 'vue';
    

const $ = window.$;   
    

export default {
    name: 'NotifyOtherUsersOfSaveModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        
        let resolvePromise = undefined,
        rejectPromise = undefined;

        let getTranslationText = config.getTranslationText,
            isChecked = shallowRef(false),
            comments = shallowRef(''),
            messageText = createMessageText(opts.value.shareeUserNames);

        function createMessageText(listOfNames) {
            if (listOfNames.length === 1) {
                return config.getTranslationText('2960', [generateUserNameFromObject(listOfNames[0])]);
            }

            if (listOfNames.length === 2) {
                return config.getTranslationText('2963', [generateUserNameFromObject(listOfNames[0]), generateUserNameFromObject(listOfNames[1])]);
            }

            if (listOfNames.length === 3) {
                return config.getTranslationText('2964', [generateUserNameFromObject(listOfNames[0]), generateUserNameFromObject(listOfNames[1]), generateUserNameFromObject(listOfNames[2])]);
            }

            if (listOfNames.length === 4) {
                return config.getTranslationText('2965', [generateUserNameFromObject(listOfNames[0]), generateUserNameFromObject(listOfNames[1]), generateUserNameFromObject(listOfNames[2]), generateUserNameFromObject(listOfNames[3])]);
            }

            if (listOfNames.length === 5) {
                return config.getTranslationText('2966', [generateUserNameFromObject(listOfNames[0]), generateUserNameFromObject(listOfNames[1]), generateUserNameFromObject(listOfNames[2]), generateUserNameFromObject(listOfNames[3])]);
            }

            if (listOfNames.length > 5) {
                var diff = listOfNames.length - 4;
                return config.getTranslationText('2967', [generateUserNameFromObject(listOfNames[0]), generateUserNameFromObject(listOfNames[1]), generateUserNameFromObject(listOfNames[2]), generateUserNameFromObject(listOfNames[3]), diff]);
            }
        }

        function generateUserNameFromObject(nameObj) {
            if(nameObj.FirstName === config.shareeTempName) {
                return nameObj.Email;
            } else {
                return nameObj.FirstName + ' ' + nameObj.LastName;
            }
            
        }

        function toggleCheckbox() {
            if (isChecked.value) {
                isChecked.value = false;
            } else {
                isChecked.value = true;
            }
        }

        function cleanUp() {
            isChecked.value = null;
            isChecked = null;

            comments.value = null;
            comments = null;
        }

        function okCheckFunction() {
            var check = {
                passed: true,
                messageCode: null
            };

            return check;
        }
        

        

        function ok() {
            

            // $.when(okCheckFunction()).done(function (checkObject) {
            //     if (checkObject.passed) {
                    var returnObject = {
                        isChecked: isChecked.value,
                        comments: comments.value
                    };
                    cleanUp();
                    popup.value.close();
                    resolvePromise(returnObject);        
            //     } else {
            //         globals.showDialog(new CustomMessageBox(config.getMessageText(checkObject.messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference(checkObject.messageCode)));
            //     }
            // });

        }

        function cancel() {
            
            popup.value.close();
            resolvePromise();
        }

        function show() {
            popup.value.open(); 
            

            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
            
        }

        return {
            ok,
            cancel,
            getTranslationText,
            isChecked,
            comments,
            messageText,
            toggleCheckbox,
            show,
            popup
        }
    },
}
</script>