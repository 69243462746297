import config from '../..//services/config';



var Polygon = function () {

    var self = this;
    
    self.pointsArray;
    self.fillColour;
    self.closedPath;
    self.name;
    self.type = 'poly';

    self.drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    function setDrawingGroupName(group) {
        self.drawingGroupName = group;
    }
    self.setDrawingGroupName = setDrawingGroupName;
    self.setColour = function (colour) {
        self.colour = colour;
    }

    self.bypassHitAreaSort = false;
    self.setBypassHitAreaSort = function (newValue) {
        self.bypassHitAreaSort = newValue;
    }

    self.setDrawingOrder = function (newValue) {
        self.drawingOrder = newValue;
    }

    self.setBringToFront = function (newValue) {
        self.bringToFront = newValue;
    }

    self.setLineWidth = function (newValue) {
        self.lineWidth = newValue;
    }

    self.setAlpha = function (newValue) {
        self.alpha = newValue;
    }

    self.setFillPolygon = function (newValue) {
        self.fillPolygon = newValue;
    }
};

export default Polygon;

