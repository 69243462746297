import config from './config';
//import toastr from '../assets/scripts/toastr.min';

const toastr = window.toastr;

//region Methods
function showToastInfo(message, options) {
    setToastrOptions(options);
    toastr.info(message);
}

function showToastError(message, options) {
    setToastrOptions(options);
    toastr.error(message);
}

function setToastrOptions(options) {
    if (options !== undefined) {
        toastr.options = options;
    } else {
        toastr.options = config.toastrOptionsDefault;
    }
}
//endregion Methods

var toastrManager = {
    showToastInfo: showToastInfo,
    showToastError: showToastError,
    setToastrOptions: setToastrOptions
};

export default toastrManager;
