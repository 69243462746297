<template>
    <div @click="clickFunction"
        class="menu-checkbox-div" 
        data-bind="click: $parents[2].deleteRow, touchstart: $parents[2].deleteRow">
        <i class="fa fa-trash fa-lg"></i>
    </div>

    <!-- <div>
        <span data-bind="text: value">{{cell.value}}</span>
    </div> -->

    <!-- <script type="text/html" id="template-settings-delete-row-element">
        <div class="menu-checkbox-div" data-bind="click: $parents[2].deleteRow, touchstart: $parents[2].deleteRow">
            <i class="fa fa-trash fa-lg"></i>
        </div>
    </script> -->
</template>

<script>
import { toRef } from 'vue';

export default {
    name: 'MenuTableCellDeleteRow',
    props: {
        cellObjectFromParent: Object
    },
    emits: ['deleteRowClicked'],
    setup (props, context) {
        const cell = toRef(props, "cellObjectFromParent");

        function clickFunction() {
            context.emit("deleteRowClicked");
        }

        return {
            cell,
            clickFunction
        }
    }
}
</script>

<style lang="scss" scoped>

</style>