

function Waypoint(internalId, description, latitude, longitude, payloadLoaded, payloadOffloaded, netPayload, icon) {
    var self = this;

    var _internalId = internalId,
        _description = description,
        _latitude = latitude,
        _longitude = longitude,
        _payloadLoaded = payloadLoaded,
        _payloadOffloaded = payloadOffloaded,
        _netPayoad = netPayload,
        _icon = icon;

    function getInternalId() {
        return _internalId;
    }
    function setInternalId(newValue) {
        _internalId = newValue;
    }
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getLatitude() {
        return _latitude;
    }
    function setLatitude(newValue) {
        _latitude = newValue;
    }
    function getLongitude() {
        return _longitude;
    }
    function setLongitude(newValue) {
        _longitude = newValue;
    }
    function getPayloadLoaded() {
        return _payloadLoaded;
    }
    function setPayloadLoaded(newValue) {
        _payloadLoaded = newValue;
    }
    function getPayloadOffloaded() {
        return _payloadOffloaded;
    }
    function setPayloadOffloaded(newValue) {
        _payloadOffloaded = newValue;
    }
    function getNetPayload() {
        return _netPayoad;
    }
    function setNetPayload(newValue) {
        _netPayoad = newValue;
    }
    function getIcon() {
        return _icon;
    }
    function setIcon(newValue) {
        _icon = newValue;
    }


    self.getInternalId = getInternalId;
    self.setInternalId = setInternalId;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getLatitude = getLatitude;
    self.setLatitude = setLatitude;
    self.getLongitude = getLongitude;
    self.setLongitude = setLongitude;
    self.getPayloadLoaded = getPayloadLoaded;
    self.setPayloadLoaded = setPayloadLoaded;
    self.getPayloadOffloaded = getPayloadOffloaded;
    self.setPayloadOffloaded = setPayloadOffloaded;
    self.getNetPayload = getNetPayload;
    self.setNetPayload = setNetPayload;
    self.getIcon = getIcon;
    self.setIcon = setIcon;

}

export default Waypoint;
