
var ChassisObjectMixin = function () {
    this._chassisObjectName;

    this.getChassisObjectName = function() {
        return this._chassisObjectName;
    }
    this.setChassisObjectName = function(newValue) {
        this._chassisObjectName = newValue;
    }
    
    
}

export default ChassisObjectMixin;
