import globals from '../../../services/globals'


/**
 * Legislation to be used in offer
 * @param {object} init - Initial dashboard legislation object from cloud services/local storage
 * @returns {object} - Dashboard Legislation object
 */
var DashboardLegislation = function(init) {
    var dashboardLegislation = this;
    var _legislationId = init.LegislationId,
        _countryId = init.CountryId;

    // #region Getters and setters
    function getLegislationId() {
        return _legislationId;
    }
    function setLegislationId(newValue) {
        _legislationId = newValue;
    }
    function getCountryId() {
        return _countryId;
    }
    function setCountryId(newValue) {
        _countryId = newValue;
    }
    function getIdForMultiSelect() {
        return globals.encodeUniqueLegislationId(_countryId, _legislationId);
    }
    // #endregion Getters and setters

    function getObjectToBeSaved() {
        var objectToBeSaved = {};
        objectToBeSaved.LegislationId = dashboardLegislation.getLegislationId();
        objectToBeSaved.CountryId = dashboardLegislation.getCountryId();

        return objectToBeSaved;
    }

    function clone() {
        var legislationObj = {
            LegislationId: this.getLegislationId(),
            CountryId: this.getCountryId()
        };
        return new DashboardLegislation(legislationObj);
    }

    dashboardLegislation.getLegislationId = getLegislationId;
    dashboardLegislation.setLegislationId = setLegislationId;
    dashboardLegislation.getCountryId = getCountryId;
    dashboardLegislation.setCountryId = setCountryId;
    dashboardLegislation.getIdForMultiSelect = getIdForMultiSelect;
    dashboardLegislation.getObjectToBeSaved = getObjectToBeSaved;
    dashboardLegislation.clone = clone;
}

export default DashboardLegislation;
