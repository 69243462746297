<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox request-trial-extension">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title new-header-text" v-text="headerText"></h4>
            </div>
            <form id="request-trial-extension-modal-form" @submit.prevent="ok">
                <div class="modal-body modal-body-text">
                    <div class="message-text"  v-html="getTranslationText('133')"></div>
                    <div class="table-container">
                        <table class="specification-modal-table offer-properties-table" style="overflow-y: auto; text-align: left;">
                            <tbody>
                                <tr>
                                    <td class="summary-note-modal-td-desc-input" colspan="2">
                                        <input id="company-name-input" 
                                            class="form-control summary-modal-input"
                                            type="text"
                                            maxlength="80"
                                            v-model="company"
                                            :placeholder="getTranslationText('955')"
                                            :data-title="getTranslationText('715')" />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="summary-note-modal-td-desc-input" colspan="2">
                                        <input id="phone-number-input"
                                            class="form-control summary-modal-input"
                                            type="tel"
                                            maxlength="20"
                                            v-model="phoneNumber"
                                            :placeholder="getTranslationText('956')"
                                            :data-title="getTranslationText('715')" />
                                    </td>
                                </tr>                        
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="button-container">
                        <button class="btn btn-default btn-truckscience btn-primary" type="submit"><span>{{ getTranslationText('2617') }}</span></button>
                        <button class="btn btn-default btn-truckscience" @click="cancel" @touchstart="cancel"><span >{{ getTranslationText('65') }}</span></button>
                    </div>
                </div>
            </form>
        </div>
    </popup-modal>

</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';

import PopupModal from './PopupModal.vue';
import { ref, shallowRef, toRef, onBeforeMount, onMounted } from 'vue';

const $ = window.$;

export default {
    name: 'RequestTrialExtensionModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup: (props, context) => {
        // Private variables
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        let resolvePromise = undefined,
        rejectPromise = undefined,
        headerTextCode = '132',
        headerText = ref(''),
        company = ref(''),
        phoneNumber = ref(''),
        source = '',
        getTranslationText = config.getTranslationText,
        modalDisplayMode = '';

        onBeforeMount(()=>{
            if (opts.value.modalType === config.REQUEST_MODAL_TYPE.SALES_TOOL) {
                headerTextCode = '4484';
            } else if (opts.value.modalType === config.REQUEST_MODAL_TYPE.REQUEST_UPGRADE) {
                headerTextCode = '4490';
            }
            headerText.value = config.getTranslationText(headerTextCode);
            source = opts.value.source;
            modalDisplayMode = opts.value.displayMode;
        });
        

        function show() {
            
            // Once we set our config, we tell the popup modal to open
            popup.value.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve;
                rejectPromise = reject;
            })
        }

        function checkFields() {
            var returnVal = true;

            if (company.value === '') {
                $('#company-name-input').addClass('error');
                returnVal = false;
            }
            if (phoneNumber.value === '') {
                $('#phone-number-input').addClass('error');
                returnVal = false;
            }

            return returnVal;
        }
        
        function ok(clickedButton) {
            if (checkFields()) {
                popup.value.close();
                resolvePromise({ Company: company.value, PhoneNumber: phoneNumber.value, source: source, displayMode: modalDisplayMode  });
            }
        }
        function cancel(clickedButton) {
            popup.value.close();
            resolvePromise();
        }
       
        return {
            show,
            popup,
            company,
            phoneNumber,
            // Buttons
            ok,
            cancel,
            // Text
            getTranslationText,
            headerText
        }

        
    },
}
</script>
