<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: modalTitle">{{modalTitle}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('2955')">{{getTranslationText('2955')}}</span>
                    </div>
                    <div>
                        <input :value="currentName"
                            class="form-control login-input summary-modal-input" 
                            maxlength="100" 
                            required 
                            disabled 
                            data-bind="value: currentName" />
                    </div>
                </div>
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('2956')">{{getTranslationText('2956')}}</span>
                    </div>
                    <div>
                        <input v-model="newName"
                            id="newName" 
                            class="form-control summary-modal-input" 
                            maxlength="100" 
                            required 
                            data-bind="value: newName" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="ok"
                    class="btn btn-default btn-truckscience btn-primary" 
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button @click="closeClick"
                    class="btn btn-default btn-truckscience" 
                    data-bind="click: cancel, touchstart: cancel">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import dataManager from '@/services/dataManager';
import globals from '@/services/globals';
import PopupModal from './PopupModal.vue';
import { ref, toRef } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';

const $ = window.$;

export default {
    name: 'RenameModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const newName = ref(opts.value.modalObject.currentName);
        const currentName = ref(opts.value.modalObject.currentName);
        const modalTitle = ref(opts.value.modalObject.modalType === config.ROW_TYPE.FOLDER ? config.getTranslationText('2954') : config.getTranslationText('2953'));

        let id = opts.value.modalObject.id,
            rows = opts.value.modalObject.rows,
            rowType = opts.value.modalObject.modalType;
        
        let resolvePromise;
        
        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function okCheckFunction() {
            var check = {
                passed: true,
                messageCode: null
            };

            if (newName.value === '') {
                check.passed = false;
                check.messageCode = '2957';

                return check;
            }

            if (newName.value.toUpperCase() === currentName.value.toUpperCase()) {
                check.passed = false;
                check.messageCode = '2958';

                return check;
            }

            var existingOfferOrFolders = [];
            rows.forEach(function (offerOrFolder) {
                if (offerOrFolder.rowType === rowType && offerOrFolder.descriptionDisplay === newName.value) {
                    existingOfferOrFolders.push(offerOrFolder);
                }
            });
            if (existingOfferOrFolders.length > 0) {
                check.passed = false;
                if (rowType === config.ROW_TYPE.OFFER) {
                    check.messageCode = '3079';
                } else {
                    check.messageCode = '3080';
                }
                

                return check;
            }

            return check;
        }

        function ok() {
            globals.showSpinner();

            $.when(okCheckFunction()).done(function (checkObject) {
                if (checkObject.passed) {
                    dataManager.rename(id, newName.value, rowType)
                        .then(function (data) {
                            globals.hideSpinner();
                            if (data.ReturnCode === 1) {
                                var metaDataObj = {};
                                metaDataObj[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = currentName.value;
                                metaDataObj[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = newName.value;
                                metaDataObj[config.INTERCOM_METADATA_ITEM.TYPE] = rowType;
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.RENAMED_ITEM, metaDataObj));
                                // dialog.close(self, true);
                                close(true);
                            } else if (data.ReturnCode === -2) {
                                var messageCode;
                                if (rowType === config.ROW_TYPE.OFFER) {
                                    messageCode = '3079';
                                } else {
                                    messageCode = '3080';
                                }

                                globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('3081')], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            } else {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            }
                            
                        })
                        .fail(function (error) {
                            globals.hideSpinner();
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        })
                } else {
                    globals.hideSpinner();
                    globals.showDialog(new CustomMessageBox(config.getMessageText(checkObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference(checkObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                }
            });
        }

        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        function closeClick() {
            close(false);
        }

        return {
            popup,
            show,
            newName,
            currentName,
            modalTitle,
            ok,
            closeClick,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>