<template>
    <div class="breadcrumbs-flex-container">
        <div class="menu-container breadcrumbs-container">
            <div>
                <span>{{menu.menuTitle.value}}</span>
            </div>
        </div>
        
        <div class="breadcrumbs-button-container-div">
            <div class="breadcrumbs-button-container">
                <template v-if="menu.displayBreadcrumbButtons.value === true">
                    <template v-if="menu.breadcrumbButtonsDisplayType.value === BREADCRUMB_BUTTON_TYPE.BODY">
                        <div v-for="breadcrumb in menu.breadcrumbButtons.value" :key="breadcrumb" 
                            class="breadcrumb-button-i-div" 
                            @click="breadcrumb.clickFunction(menu.activeMenuStep.value.path)"
                            :title="breadcrumb.label"
                            :class="{ 'reduce-lg-icon' : breadcrumb.iconClass === 'fa-trash'  }"
                            data-bind="click: function() { clickFunction($parent.activeMenuStep().path); }, touchstart: function() { clickFunction($parent.activeMenuStep().path); }, attr: { title: label }, css: { 'reduce-lg-icon' : iconClass === 'fa-trash'  } ">
                                <template v-if="breadcrumb.displayFunction() === true">
                                    <div v-if="breadcrumb.useSprite === true" :class="breadcrumb.iconClass" class="icn-hi-res breadcrumb-button-container" data-bind="css: breadcrumb.iconClass"></div>                
                                    <i v-else class="fa fa-lg" :class="breadcrumb.iconClass" data-bind="css: breadcrumb.iconClass"></i>
                                </template>
                        </div>
                    </template>
                    <template v-if="menu.breadcrumbButtonsDisplayType.value === BREADCRUMB_BUTTON_TYPE.ACCESSORY">
                        <div v-for="breadcrumb in menu.breadcrumbButtons.value" :key="breadcrumb" 
                            class="breadcrumb-button-i-div" 
                            @click="breadcrumb.clickFunction(menu.activeMenuStep.value.path, menu.activeMenuStep.id)"
                            :title="breadcrumb.label"
                            data-bind="click: function() { clickFunction($parent.activeMenuStep().path, $parent.activeMenuStep().id()); }, touchstart: function() { clickFunction($parent.activeMenuStep().path, $parent.activeMenuStep().id()); }, attr: { title: label }">
                            <template v-if="breadcrumb.displayFunction() === true">
                                <div v-if="breadcrumb.useSprite === true" :class="breadcrumb.iconClass" class="icn-hi-res breadcrumb-button-container" data-bind="css: iconClass"></div>
                                <i v-else :class="breadcrumb.iconClass" class="fa fa-lg" data-bind="css: iconClass"></i>
                            </template>
                        </div>
                    </template>
                    <template v-if="menu.breadcrumbButtonsDisplayType.value === BREADCRUMB_BUTTON_TYPE.TRAILER">
                        <div v-for="breadcrumb in menu.breadcrumbButtons.value" :key="breadcrumb" 
                            class="breadcrumb-button-i-div" 
                            @click="breadcrumb.clickFunction(menu.activeMenuStep.value.path)"
                            :title="breadcrumb.label"
                            data-bind="click: function() { clickFunction($parent.activeMenuStep().path); }, touchstart: function() { clickFunction($parent.activeMenuStep().path); }, attr: { title: label }">
                            <template v-if="breadcrumb.displayFunction() === true">
                                <div v-if="breadcrumb.useSprite === true" :class="breadcrumb.iconClass" class="icn-hi-res breadcrumb-button-container" data-bind="css: iconClass"></div>
                                <i v-else :class="breadcrumb.iconClass" class="fa fa-lg" data-bind="css: iconClass"></i>
                            </template>
                        </div>
                    </template>
                    <template v-if="menu.breadcrumbButtonsDisplayType.value === BREADCRUMB_BUTTON_TYPE.VEHICLE">
                        <template v-for="breadcrumb in menu.breadcrumbButtons.value" :key="breadcrumb">
                            <div v-if="breadcrumb.displayFunction() === true" 
                                    class="breadcrumb-button-i-div" 
                                    :title="breadcrumb.label" 
                                    @click="breadcrumb.clickFunction(menu.activeMenuStep.value.path)"
                                    data-bind="click: function() { clickFunction($parent.activeMenuStep().path); }, touchstart: function() { clickFunction($parent.activeMenuStep().path); }, attr: { title: label }">
                                <i class="fa fa-lg" v-if="breadcrumb.useSprite === false" :class="breadcrumb.iconClass"></i>
                                <div v-if="breadcrumb.useSprite === true"
                                    class="icn-hi-res breadcrumb-button-container" 
                                    :class="breadcrumb.iconClass"></div>
                            </div>
                        </template>
                    </template>
                    
                    <div class="breadcrumbs-button-container breadcrumbs-button-separator">
                        <div></div>
                    </div>
                </template>
            </div>
            
            <div class="breadcrumbs-button-container breadcrumbs-button-close-container">
                <div class="breadcrumb-button-i-div reduce-lg-icon" v-if="menu.displayBackButton.value === true"                
                    @click="menu.backButtonClick(menu)"
                    :title="getTranslationText('1004')"
                    data-bind="click: function() { $parent.backButtonClick($parent); }, touchstart: function() { $parent.backButtonClick($parent); }, attr: { title: $root.getTranslationText('1004') }">
                    <i class="fa fa-chevron-circle-left fa-lg"></i>
                </div>
                <div class="breadcrumb-button-i-div reduce-lg-icon" :title="menu.popMenuTitle.value" @click="menu.popMenu">
                    <i class="fa fa-lg"
                        :class="{ 'fa-expand-alt': menu.menuDocked.value === true, 'fa-compress-alt': menu.menuDocked.value === false }"></i>
                </div>
                <div class="breadcrumb-button-i-div"
                    @click="menu.closeMenu"
                    :title="getTranslationText('967')"
                    data-bind="click: $parent.closeMenu, touchstart: $parent.closeMenu, attr: { title: $root.getTranslationText('967') }">
                    <i class="fa fa-times fa-lg"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="breadcrumbs-flex-container" data-bind="template: {name: 'template-menu-breadcrumbs', data: $data}"></div> -->
</template>

<script>
import { ref, watch, inject } from 'vue';
import config from '../../services/config';

export default {
    props: {
        menuFromParent: Object
    },
    setup(props) {
        //const menu = ref(props.menuFromParent);
        const menu = inject('fromMenuContainer');
        const displayBackButton = ref(null);

        // watch(() => props.menuFromParent, (newValue, oldValue) => {
        //     menu.value = newValue;
        //     displayBackButton.value = menu.value.displayBackButton;
        // });
        return {
            menu,
            displayBackButton,
            BREADCRUMB_BUTTON_TYPE: config.BREADCRUMB_BUTTON_TYPE,
            getTranslationText: config.getTranslationText
        };
    },
    // components: { MenuBreadcrumbsButtonContainer }
}
</script>

<style lang="scss" scoped>
</style>