<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('2946')">{{getTranslationText('2946')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div>
                    <span data-bind="text: getTranslationText('2947')">{{getTranslationText('2947')}}</span>
                </div>
                <div id="jstree_demo" class="folder-selection-container"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" 
                    @click="ok"
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience" 
                    @click="cancel"
                    data-bind="click: cancel, touchstart: cancel">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import folderManager from '@/services/folderManager/folderManager';
import PopupModal from './PopupModal.vue';
import { ref, toRef, onMounted } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import bus from '@/main';

export default {
    name: 'MoveToModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const selectedItems = opts.value.modalObject.selectedItems;

        const $ = window.$;

        let jsTreeObject = null,
            existingFolders;

        function getJsTreeObject () { return jsTreeObject; }
        function setJsTreeObject(newValue) { jsTreeObject = newValue; }
        function cleanUp() {
            if (jsTreeObject !== null) {
                folderManager.cleanUpInstance(jsTreeObject, setJsTreeObject);
            }
        }

        function activate() {
            var pms = $.Deferred();

            dataManager.getFolderTreeStructure().done(function (result) {
                existingFolders = folderManager.convertFolderDataForUseInFolderManager(result.Folders, 0);
                pms.resolve({});
            });

            return pms.promise();
        }

        function compositionComplete() {
            var typesObj = {
                default: {
                    icon: 'fas fa-folder',
                    valid_children: ['default', 'new_folder', 'create_new_folder']
                },
                new_folder: {
                    icon: 'far fa-folder',
                    valid_children: ['default', 'new_folder', 'create_new_folder']
                },
                create_new_folder: {
                    icon: 'fas fa-asterisk'
                }
            };
            
            setJsTreeObject(folderManager.createFolderStructure('#jstree_demo', existingFolders, typesObj));
            folderManager.addEvent(jsTreeObject, config.JSTREE_EVENTS.CORE.CHANGED, folderManager.handleCreateFolderOnSelection);
        }
        
        let resolvePromise;

        onMounted(async () => {
            await activate();
            compositionComplete();
        });
        
        function show() {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        function ok() {
            // Add check for folder name
            var isFolderStructureValidResult = isFolderStructureValid();
            
            if (isFolderStructureValidResult === true) {
                // if check passes continue                
                var pathToFolder = folderManager.convertSelectedNodePathForUseInCloudServices(jsTreeObject.jstree(true));
                var checkThatFolderIdIsNotPotentialParentFolderIdResult = checkThatFolderIdIsNotPotentialParentFolderId(selectedItems, pathToFolder);
                if(checkThatFolderIdIsNotPotentialParentFolderIdResult.passed === true) {
                    globals.showSpinner();

                    dataManager.moveTo(selectedItems, pathToFolder)
                        .then(function (data) {
                            if (data.Result.ReturnCode === 1) {
                                var metaDataObj = {};
                                metaDataObj[config.INTERCOM_METADATA_ITEM.TYPE] = selectedItems.length > 1 ? 'MULTI' : 'SINGLE';
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.MOVED_ITEMS, metaDataObj));
                                bus.emit('displayToast', {
                                    method: 'showToast',
                                    params: [config.getMessageText('2949', globals.getActiveCultureCodeForTranslations()), config.TOASTR_TYPES.SUCCESS] 
                                });
                                cleanUp();
                                close(data);
                            } else if (data.Result.ReturnCode === -2) {
                                globals.hideSpinner();
                                var messageObject = getDuplicateFolderNameMessageObject(data);
                                globals.showDialog(new CustomMessageBox(config.getMessageText(messageObject.messageCode, globals.getActiveCultureCodeForTranslations(), messageObject.textToUse), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference(messageObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            } else if (data.Result.ReturnCode === -3) {
                                globals.hideSpinner();
                                globals.showDialog(new CustomMessageBox(config.getMessageText('2951', globals.getActiveCultureCodeForTranslations(), [data.MatchedSubFolder.Name, data.MatchedSelectedFolder.Name]), config.applicationTitle, [config.getTranslationText('2943')], config.getMessageReference('2951')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            } else {
                                globals.hideSpinner();
                                dataManager.log("moveToModal.ok, dataManager.moveTo, ReturnCode=" + data.Result.ReturnCode + ",  1571", data.Result.Message, 'MoveToModal', config.LOG_MESSAGE_TYPE.ERROR);
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            }
                        })
                        .fail(function (error) {
                            dataManager.log("moveToModal.ok, dataManager.moveTo, fail", error, 'MoveToModal', config.LOG_MESSAGE_TYPE.ERROR);
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        });
                } else {
                    var foldersListHTML = '<ul>';
                    checkThatFolderIdIsNotPotentialParentFolderIdResult.foldersToRemove.forEach(function(folder){
                        foldersListHTML += '<li>' + folder.NameOrDescription + '</li>';
                    });
                    foldersListHTML += '</ul>';
                    globals.showDialog(new CustomMessageBox(config.getMessageText('4616', globals.getActiveCultureCodeForTranslations(), foldersListHTML), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4616')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                }
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('4617', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4617')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                    .addCallback(function(){
                        // else select problem node and allow edit
                        jsTreeObject.jstree(true).select_node(isFolderStructureValidResult.nodeIdToUse);
                        var node = jsTreeObject.jstree(true).get_selected();
                        jsTreeObject.jstree(true).edit(node);
                    });
            }
            
            /**
             * Function to get the correct message code and text to use for the duplicate folder name message
             * @param data  - Data containing duplicate folder names
             * @returns {object} - Object containing message code and text to use
             */
            function getDuplicateFolderNameMessageObject(data) {
                if(data.DuplicateFolderNames.length === 1) {
                    // return data.DuplicateFolderNames[0];
                    return {
                        "messageCode": "2950",
                        "textToUse": [data.DuplicateFolderNames[0]]
                    }
                } else if (data.DuplicateFolderNames.length === 2) {
                    // return data.DuplicateFolderNames[0] + ' and ' + data.DuplicateFolderNames[1];
                    return {
                        "messageCode": "2950",
                        "textToUse": [data.DuplicateFolderNames[0], data.DuplicateFolderNames[1]]
                    }
                } else if (data.DuplicateFolderNames.length === 3) {
                    // return data.DuplicateFolderNames[0] + ', ' + data.DuplicateFolderNames[1] + ' and ' + data.DuplicateFolderNames[2];
                    return {
                        "messageCode": "2950",
                        "textToUse": [data.DuplicateFolderNames[0], data.DuplicateFolderNames[1], data.DuplicateFolderNames[2]]
                    }
                } else if (data.DuplicateFolderNames.length === 4) {
                    // return data.DuplicateFolderNames[0] + ', ' + data.DuplicateFolderNames[1] + ', ' + data.DuplicateFolderNames[2] + ' and ' + (data.DuplicateFolderNames.length - 3) + ' other';
                    return {
                        "messageCode": "2950",
                        "textToUse": [data.DuplicateFolderNames[0], data.DuplicateFolderNames[1], data.DuplicateFolderNames[2], (data.DuplicateFolderNames.length - 3)]
                    }
                } else {
                    // return data.DuplicateFolderNames[0] + ', ' + data.DuplicateFolderNames[1] + ', ' + data.DuplicateFolderNames[2] + ' and ' + (data.DuplicateFolderNames.length - 3) + ' others';
                    return {
                        "messageCode": "2950",
                        "textToUse": [data.DuplicateFolderNames[0], data.DuplicateFolderNames[1], data.DuplicateFolderNames[2], (data.DuplicateFolderNames.length - 3)]
                    }
                }
            }

            function isFolderStructureValid() {
                var internalJstreeObject = jsTreeObject.jstree(true),
                    returnValue = true;
                var nodeString = internalJstreeObject.get_selected();
                var selectedNode = internalJstreeObject.get_node(nodeString);
                var pathToNode = internalJstreeObject.get_path(selectedNode, null, true);
                var topLevelNewFolderToCheck = pathToNode.find(function (nodeId) {
                    var nodeToCheck = internalJstreeObject.get_node(nodeId);
                    return nodeToCheck.type === 'new_folder';
                });
                if (topLevelNewFolderToCheck === undefined) {
                    returnValue = true;
                } else {
                    var topLevelNewFolderNode = internalJstreeObject.get_node(topLevelNewFolderToCheck),
                        parentNode = internalJstreeObject.get_node(internalJstreeObject.get_parent(topLevelNewFolderToCheck));

                    parentNode.children.forEach(function (childNodeId) {
                        var childNode = internalJstreeObject.get_node(childNodeId);
                        if (childNode.type === 'default' && topLevelNewFolderNode.text.toUpperCase() === childNode.text.toUpperCase()) {
                            returnValue = {
                                passed: false,
                                nodeIdToUse: topLevelNewFolderNode.id
                            };
                        }
                    });
                }
                
                return returnValue;
            }

            function checkThatFolderIdIsNotPotentialParentFolderId(selectedItemsArray, pathToFolderArray) {
                var returnValue = {
                    passed: true,
                    foldersToRemove: []
                };
                var folders = selectedItemsArray.filter(function (item) {
                    return item.Type === config.ROW_TYPE.FOLDER;
                });
                // var folders = [];
                // selectedItemsArray.forEach(function (item) {
                //     if(item.Type === config.ROW_TYPE.FOLDER) {
                //         folders.push(item);
                //     }
                // });
                folders.forEach(function(folder) {
                    pathToFolderArray.forEach(function (pathItem) {
                        if (pathItem.Id === folder.Id) {
                            returnValue.passed = false;
                            returnValue.foldersToRemove.push(folder);
                        }
                    });
                });

                return returnValue;
            }
        }

        function cancel() {
            cleanUp();
            close(false);
        }

        return {
            show,
            popup,
            // Functions
            cancel,
            ok,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>