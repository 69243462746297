<template>
    <ul class="nav nav-pills" data-bind="template: { name: 'template-menu-tab-group-tabs-li', foreach: menuTabs }">
        <template v-for="(tab, index) in menuTabs" :key="tab">
            <li v-if="tab.displayMenuTab() === true">
                <a data-toggle="tab" :href="'#' + tab.hrefValue" :title="tab.label" :id="tab.hrefValue + '-id'">{{ tab.label }}</a>
            </li>
            <MenuTabGroupButtonVue 
                v-if="menuTabs.length === index + 1 && displayButtonBesideTabs === true"
                :buttonFromParent="menuTabGroupButton">
            </MenuTabGroupButtonVue>
        </template>
    </ul>
    <div class="tab-content">
        <div class="tab-pane fade" v-for="tab in menuTabs" :key="tab" :id="tab.hrefValue" :class="tab.customCss">
            <table>
                <MenuContentItem :itemsFromParent="tab.items.value"></MenuContentItem>
            </table>
        </div>
    </div>

    <!-- <script type="text/html" id="template-menu-tab-group-tabs-li">
         ko if: displayMenuTab() === true 
        <li><a data-toggle="tab"
                data-bind="text: label, attr: { href: '#' + hrefValue, title: label, id: hrefValue + '-id' }"></a></li>
         /ko 
         ko if: $parent.menuTabs().length === $index() + 1 && $parent.displayButtonBesideTabs === true 
        <div class="menu-tab-group-button-container" data-bind="with: $parent.menuTabGroupButton">
            <button class="btn btn-default btn-truckscience"
                data-bind="click: clickFunction, touchstart: clickFunction, visible: isVisible()"><span
                    data-bind="text: buttonText, attr: { title: buttonText }"></span></button>
        </div>
         /ko 
    </script> -->

    <!-- <script type="text/html" id="template-menu-tab-group-tabs">
        ko if: displayMenuTab() === true
        <div class="tab-pane fade" data-bind="attr: { id: hrefValue }, css: customCss">
            <table>
                <tbody data-bind="template: {name: 'template-menu-item', foreach: items }"></tbody>
            </table>
        </div>
        /ko
    </script> -->
</template>

<script>
import { toRef } from 'vue';
import MenuTabGroupButtonVue from './MenuTabGroupButton.vue';

export default {
    props: {
        tabsFromParent: Array,
        displayButtonBesideTabsFromParent: Boolean,
        menuTabGroupButtonFromParent: Object
    },
    setup(props) {
        // const menuTabs = ref(props.tabsFromParent);
        const menuTabs = toRef(props, "tabsFromParent");
        const menuTabGroupButton = toRef(props, "menuTabGroupButtonFromParent");

        return {
            menuTabs,
            menuTabGroupButton,
            displayButtonBesideTabs: props.displayButtonBesideTabsFromParent
        };
    },
    components: {MenuTabGroupButtonVue}
}
</script>

<style lang="scss" scoped>
</style>