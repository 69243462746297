<template>
    <popup-modal ref="popup">
        <div class="modal-wrapper">
                <div class="modal-container messageBox custom-message-box" :class="additionalClasses">
                    <div class="modal-header">
                        <h3>{{title}}</h3>
                        <!-- <slot name="header">default header</slot> -->
                    </div>

                    <div class="modal-body">
                        <p class="message" v-html="message"></p>
                        <!-- <slot name="message" v-html="message"></slot> -->
                        <!-- <slot name="body">default body</slot> -->
                    </div>

                    <div class="modal-footer rows">
                        <div class="error-text">   
                            <span v-if="messageRef !== ''">{{messageRef}}</span>
                        </div>
                        <div>
                            <!-- !-- ko foreach: options --
                            !-- <button data-bind="click: function () { $parent.selectOption($parent.getButtonValue($data)); }, text: $parent.getButtonText($data), css: $parent.getButtonClass($index)"></button> --
                            !-- /ko -- -->

                            <!-- <button v-for="button in buttons" :key="button" 
                                class="btn btn-default btn-truckscience btn-primary autofocus" @click="$emit('close')">
                                OK
                            </button> -->

                            <button v-for="(button, index) in buttons" :key="button" 
                                @click="buttonClick"
                                :class="{ 'btn-primary': index === 0 }"
                                class="btn btn-default btn-truckscience autofocus">
                                {{button}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue';
import { ref, shallowRef, onBeforeMount, onMounted, toRef, triggerRef } from 'vue';

export default {
    name: 'CustomMessageDialogue',

    components: { PopupModal },
    props: {
        propsForModal: Object
    },

    setup: (props, context) => {
// Private variables
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        let resolvePromise = undefined,
        rejectPromise = undefined,
        title = shallowRef(''),
        message = shallowRef(''), // Main text content
        messageRef = shallowRef(''),
        buttons = shallowRef([]),
        additionalClasses = shallowRef(''),
        valuesSet = false;

        onBeforeMount(()=>{
            if(opts.value) {
                title.value = opts.value.title;
                message.value = opts.value.message;
                messageRef.value = opts.value.messageRef;
                buttons.value = opts.value.buttons;
                additionalClasses.value = opts.value.additionalClasses;
                triggerRef(buttons);
                valuesSet = true;
                // popup.value.open();
            }
        });

        onMounted(()=>{
            if(valuesSet) {
                popup.value.open();
            }
        });

        function show() {
            // this.title = opts.title;
            // this.message = opts.message;
            // this.messageRef = opts.messageRef;
            // this.buttons = opts.buttons;
            
            // Once we set our config, we tell the popup modal to open
            
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
        }

        function buttonClick(clickedButton) {
            valuesSet = false;
            popup.value.close();
            resolvePromise(clickedButton.currentTarget.innerHTML);
            // context.emit('modalInteractionEventFired', clickedButton.currentTarget.innerText);
        }

        return {
            title,
            message,
            messageRef,
            buttons,
            additionalClasses,
            show,
            buttonClick,
            popup
        };

        
    },
    // data: () => ({
    //     // Parameters that change depending on the type of dialogue
    //     title: undefined,
    //     message: undefined, // Main text content
    //     messageRef: undefined,
    //     buttons: undefined,
        
    //     // Private variables
    //     resolvePromise: undefined,
    //     rejectPromise: undefined,
    // }),

    // methods: {
    //     show(opts = {}) {
    //         this.title = opts.title;
    //         this.message = opts.message;
    //         this.messageRef = opts.messageRef;
    //         this.buttons = opts.buttons;
           
    //         // Once we set our config, we tell the popup modal to open
    //         this.$refs.popup.open()
    //         // Return promise so the caller can get results
    //         return new Promise((resolve, reject) => {
    //             this.resolvePromise = resolve
    //             this.rejectPromise = reject
    //         })
    //     },

    //     buttonClick(clickedButton) {
    //         this.$refs.popup.close();
    //         this.resolvePromise(clickedButton.currentTarget.innerText);
    //         // context.emit('modalInteractionEventFired', clickedButton.currentTarget.innerText);
    //     },

        
    // },
}
</script>

<style lang="scss" scoped>
.modal-body {
    margin: 0;
}
</style>