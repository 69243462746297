import TollFee from './model.tollFee';
import Globalize from '@/assets/scripts/globalize/globalize';



    function Toll(initTollObject) {
        var self = this;
        //Id, Gate, Plaza, Route, Locations, Fees
        var _id = initTollObject.Id,
            _gate = initTollObject.Gate,
            _plaza = initTollObject.Plaza,
            _route = initTollObject.Route,
            _activeTollFee,
            _tollFees = [];

        addTollFees(initTollObject.Fees);

        //#region Getters and Setters
        function getId() {
            return _id;
        }
        function setId(newValue) {
            _id = newValue;
        }
        function getGate() {
            return _gate;
        }
        function setGate(newValue) {
            _gate = newValue;
        }
        function getPlaza() {
            return _plaza;
        }
        function setPlaza(newValue) {
            _plaza = newValue;
        }
        function getRoute() {
            return _route;
        }
        function setRoute(newValue) {
            _route = newValue;
        }
        function getTollFees() {
            return _tollFees;
        }
        function setTollFees(newValue) {
            _tollFees = newValue;
        }
        function getActiveTollFee() {
            return _activeTollFee;
        }
        function setActiveTollFee(newValue) {
            _activeTollFee = newValue;
        }
        function getActiveTollFeeFormatted(currencySymbol) {
            return currencySymbol + ' ' + Globalize.format(getActiveTollFee(), "n2")
        }
        //#endregion Getters and Setters

        function addTollFees(tollFees) {
            var tempTollFees = [];

            if (Array.isArray(tollFees)) {
                tollFees.forEach(function (tollFeeToAdd) {
                    var newTollFee = new TollFee(tollFeeToAdd);
                    tempTollFees.push(newTollFee);
                });
                
            }

            setTollFees(tempTollFees);
        }

        /**
         * Get object to be stored in OFFER_TOLL_FEE table on database
         * */
        function getTollObjectToSaved() {
            return {
                "TollId": getId(),
                "TollFee": getActiveTollFee()
            };
        }

        /**
         * Update the active toll fee for toll by passing in a vehicle class ID
         * @param {number} vehicleClassId - Vehicle class ID
         */
        function updateActiveTollFee(vehicleClassId) {
            var tollFeeToUse = getTollFees().find(function (tollFee) {
                return tollFee.getVehicleClassId() === vehicleClassId;
            });

            if (tollFeeToUse !== undefined) {
                setActiveTollFee(tollFeeToUse.getFee());
            }
        }

        /**
         * Gets toll class from toll fees array based on vehicle class ID
         * @param {number} id
         * @returns {object|null}
         */
        function getTollClassUsingTollClassId(tollClassId) {
            return getTollFees().find(function (tollFee) { return tollFee.getVehicleClassId() === tollClassId; }) || null;
        }

        self.getId = getId;
        self.setId = setId;
        self.getGate = getGate;
        self.setGate = setGate;
        self.getPlaza = getPlaza;
        self.setPlaza = setPlaza;
        self.getRoute = getRoute;
        self.setRoute = setRoute;
        self.getTollFees = getTollFees;
        self.setTollFees = setTollFees;
        self.getActiveTollFee = getActiveTollFee;
        self.setActiveTollFee = setActiveTollFee;
        self.getTollObjectToSaved = getTollObjectToSaved;
        self.updateActiveTollFee = updateActiveTollFee;
        self.getActiveTollFeeFormatted = getActiveTollFeeFormatted;
        self.getTollClassUsingTollClassId = getTollClassUsingTollClassId;
    }

    export default Toll;
