import DashboardDataPoint from './dashboardDataPoint';


/**
 * Allows user to create their own internal standards
 * @param {any} init - Initial internal standard object from cloud services/local storage
 * @returns {object} - Internal Standard object
 */
function InternalStandard(init) {
    DashboardDataPoint.call(this, init);

    var internalStandard = this;
    var _rangeMin = init.RangeMin,
        _rangeMax = init.RangeMax,
        _rangeType = init.RangeType,
        _isActive = init.IsActive;

    // #region Getters and setters
    function getRangeMin() {
        return _rangeMin;
    }
    function setRangeMin(newValue) {
        _rangeMin = newValue;
    }
    function getRangeMax() {
        return _rangeMax;
    }
    function setRangeMax(newValue) {
        _rangeMax = newValue;
    }
    function getRangeType() {
        return _rangeType;
    }
    function setRangeType(newValue) {
        _rangeType = newValue;
    }
    function getIsActive() {
        return _isActive;
    }
    function setIsActive(newValue) {
        _isActive = newValue;
    }
    // #endregion Getters and setters
    
    function getObjectToBeSaved() {
        var objectToBeSaved = {};
        objectToBeSaved.DataPointId = internalStandard.getDataPointId();
        objectToBeSaved.RangeMin = internalStandard.getRangeMin();
        objectToBeSaved.RangeMax = internalStandard.getRangeMax();
        objectToBeSaved.RangeType = internalStandard.getRangeType();
        objectToBeSaved.IsActive = internalStandard.getIsActive();

        return objectToBeSaved;
    }

    internalStandard.getRangeMin = getRangeMin;
    internalStandard.setRangeMin = setRangeMin;
    internalStandard.getRangeMax = getRangeMax;
    internalStandard.setRangeMax = setRangeMax;
    internalStandard.getRangeType = getRangeType;
    internalStandard.setRangeType = setRangeType;
    internalStandard.getIsActive = getIsActive;
    internalStandard.setIsActive = setIsActive;
    internalStandard.getObjectToBeSaved = getObjectToBeSaved;
}

export default InternalStandard;
