import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '@/services';


var SpecificationHeading = function (description, headingCode, headingIndicator, unit) {

    var self = this;
    //properties
    var _description,
        _headingCode,
        _headingIndicator,
        _unit;

    //#region getters and setters
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getHeadingCode() {
        return _headingCode;
    }
    function setHeadingCode(newValue) {
        _headingCode = newValue;
    }
    function getHeadingIndicator() {
        return _headingIndicator;
    }
    function setHeadingIndicator(newValue) {
        _headingIndicator = newValue;
    }
    function getUnit() {
        return _unit;
    }
    function setUnit(newValue) {
        _unit = newValue;
    }
    //#endregion getters and setters

    function getUnitTranslation(measurementSystemType) {
        var measurementSystem;
        switch (measurementSystemType) {
            case config.MEASUREMENT_SYSTEM.IMPERIALUK:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK);
                break;
            case config.MEASUREMENT_SYSTEM.IMPERIALUS:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
                break;
            default:
                measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC);
                switch (getUnit()) {
                    case 'lb':
                        return measurementSystem.unitMass;
                    case 'in':
                        return measurementSystem.unitLength;
                    default:
                        return getUnit();
                }
        }

        switch (getUnit()) {
            case 'kg':
                return measurementSystem.unitMass;
            case 'mm':
                return measurementSystem.unitLength;
            case 'ltrs':
                return measurementSystem.unitVolume;
            case 'km/h':
                return measurementSystem.unitSpeed;
            default:
                return getUnit();
        }
    }

    //#region Initialize
    setDescription(description);
    setHeadingCode(headingCode);
    setHeadingIndicator(headingIndicator);
    setUnit(unit);
    //#endregion Initialize

    //#region Public memebers
    self.description = description;
    self.headingCode = headingCode;
    self.headingIndicator = headingIndicator;
    self.unit = unit;

    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getHeadingCode = getHeadingCode;
    self.setHeadingCode = setHeadingCode;
    self.getHeadingIndicator = getHeadingIndicator;
    self.setHeadingIndicator = setHeadingIndicator;
    self.getUnit = getUnit;
    self.setUnit = setUnit;
    self.getUnitTranslation = getUnitTranslation;
    //#region Public memebers
};

export default SpecificationHeading;

