<template>
    <!-- Template = "template-selection-datagrid-row" -->
    <td class="datagrid-icon-td datagrid-icon-expanded-td">
        <div v-if="item.isSavedOffer === true" class="selection-datagrid-type-icon datagrid-icon-saved"></div>
        <div v-else class="selection-datagrid-type-icon">
            <div class="selection-datagrid-fa-icon save-icon">
                <i class="fas fa-lg"
                    :class="[{ 'fa-file-alt': item.Source === VEHICLE_SOURCE_TYPES.STANDARD }, { 'fa-file': item.Source === VEHICLE_SOURCE_TYPES.TEMPLATE }]"></i>
            </div>
        </div>
    </td>
    <td class="datagrid-column datagrid-column-large datagrid-description">
        <span>{{ item.Description }}</span>
    </td>
    <td class="datagrid-column datagrid-model-code">
        <span>{{ item.ModelCode }}</span>
    </td>
    <td class="datagrid-column datagrid-wheelbase">
        <span>{{ item.wheelbaseFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-axle-layout">
        <span>{{ item.AxleLayout }}</span>
    </td>
    <td class="datagrid-column datagrid-vehicle-type">
        <span>{{ item.VehicleTypeTranslation }}</span>
    </td>
    <td class="datagrid-column datagrid-gvw">
        <span>{{ item.gvwFormatted }}</span>
    </td>
    <td class="datagrid-column datagrid-gcw">
        <span>{{ item.gcwFormatted }}</span>
    </td>
    <td class="datagrid-column">
        <span>{{ item.SpecDate }}</span>
    </td>
    <td class="datagrid-icon-td" v-if="wizard.activeWizardStep.displayDownloadFileOption">
        <div class="selection-datagrid-type-icon-small datagrid-icon-file-download" @click="wizard.downloadFile"
            :title="wizard.downloadFileTooltipText">
            <i class="fas fa-file-download"></i>
        </div>
    </td>
</template>

<script>
import { ref } from 'vue';
import config from '@/services/config';

export default {
    props: {
        wizardFromParent: Object,
        itemFromParent: Object
    },
    setup(props) {
        const wizard = ref(props.wizardFromParent);
        const item = ref(props.itemFromParent);

        return {
            wizard,
            item,
            VEHICLE_SOURCE_TYPES: config.VEHICLE_SOURCE_TYPES
        }
    }
}
</script>

<style lang="scss" scoped>
</style>