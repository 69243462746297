<template>
    <div class="configuration-wizard-dxf-input-container flex-item">
        <form v-if="fileInputForm !== null"
            :id="fileInputForm.getId()"
            :class="fileInputForm.getCustomCss()"
            enctype="multipart/form-data" 
            action="" 
            method="post" 
            role="form" 
            data-bind="submit: handleFileSubmission, attr: { id: getId() }, css: getCustomCss()">
            <div :class="fileInputForm.getCustomInputContainerCss()"
                class="fill-display-flex" 
                data-bind="css: getCustomInputContainerCss()">
                <div :class="fileInputForm.getCustomInputCss()"
                    class="file-input-div"
                    data-bind="css: getCustomInputCss()">
                    <input :id="fileInputForm.getInputId()"
                        :name="fileInputForm.getName()"
                        :accept="fileInputForm.getFileTypes()"
                        @change="onChangeFunction"
                        type="file" 
                        data-bind="attr: { id: getInputId(), name: getName(), accept: getFileTypes() }, event: { change: getDisplaySubmitButton() === false ? onChangeFunction : null }" />
                </div>
                <div v-if="fileInputForm.getDisplaySubmitButton() === true"
                    class="multiselect-div-clear" 
                    data-bind="click: function() { $('#' + getId()).submit(); }, touchstart: function() { $('#' + getId()).submit(); }">
                    <i v-if="fileInputForm.getIncludeIconInButton() === true"
                        :class="fileInputForm.getSubmitButtonIcon()"
                        class="fas fa-lg" data-bind="css: getSubmitButtonIcon()">
                    </i>
                    <span data-bind="text: getSubmitButtonText()">{{fileInputForm.getSubmitButtonText()}}</span>
                </div>
            </div>
        </form>
    </div>
    

    <!-- <script type="text/html" id="template-itemConfigurationWizardModal-dxf-input-container">
        ko if: $data !== null
        <form enctype="multipart/form-data" action="" method="post" role="form" data-bind="submit: handleFileSubmission, attr: { id: getId() }, css: getCustomCss()">
            <div class="fill-display-flex" data-bind="css: getCustomInputContainerCss()">
                <div class="file-input-div" data-bind="css: getCustomInputCss()">
                    <input type="file" data-bind="attr: { id: getInputId(), name: getName(), accept: getFileTypes() }, event: { change: getDisplaySubmitButton() === false ? onChangeFunction : null }" />
                </div>
                ko if: getDisplaySubmitButton() === true
                <div class="multiselect-div-clear" data-bind="click: function() { $('#' + getId()).submit(); }, touchstart: function() { $('#' + getId()).submit(); }">
                    ko if: getIncludeIconInButton()
                    <i class="fas fa-lg" data-bind="css: getSubmitButtonIcon()"></i>
                    /ko
                    <span data-bind="text: getSubmitButtonText()"></span>
                </div>
                /ko
            </div>
        </form>
        /ko
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: "DxfInputContainer",
    props: {
        fileInputFormFromParent: Object
    },
    setup (props) {
        const fileInputForm = toRef(props, "fileInputFormFromParent")

        function onChangeFunction() {
            if(fileInputForm.value.getDisplaySubmitButton() === false) {
                fileInputForm.value.onChangeFunction();
            }
        }

        return {
            fileInputForm,
            onChangeFunction
        }
    }
}
</script>

<style lang="scss" scoped>

</style>