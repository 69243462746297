import config from '../../../services/config';
import utils from '../../../services/utils';
import ComplianceDashboardItem from './complianceDashboardItem';



var ComplianceDashboardSpacer = function () {

    ComplianceDashboardItem.call(this, config.COMPLIANCE_DASHBOARD_ITEM_TYPES.SPACER);

    this._spacerType;
    this._height;

    return this;
}

ComplianceDashboardSpacer.prototype.setSpacerType = function (newValue) {
    this._spacerType = newValue;
    return this;
}

ComplianceDashboardSpacer.prototype.getSpacerType = function () {
    return this._spacerType;
}

ComplianceDashboardSpacer.prototype.setHeight = function (newValue) {
    this._height = newValue;
    return this;
}

ComplianceDashboardSpacer.prototype.getHeight = function () {
    return this._height;
}

utils.extend(ComplianceDashboardItem, ComplianceDashboardSpacer);


export default ComplianceDashboardSpacer;

       
