<template>
    <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }">
        <select :id="select.id"
                v-model="select.obv.value"
                data-toggle="tooltip">
                <option v-for="option in select.optionsArray.value" :key="option" :value="option.id">
                    {{ option.description }}
                </option>
        </select>
    </div>

    <!-- <script type="text/html" id="template-menu-table-cell-input">
        ko if: inputType === $root.config.INPUT_TYPE.SELECT
        <div class="menu-select-div" data-bind="template: { name: 'template-menu-select-element' }"></div>
        /ko
    </script> -->
    <!-- <script type="text/html" id="template-settings-select-element">
        <select data-bind="options: optionsArray, optionsText: 'description', optionsValue: 'id', value: obv, attr: { id: id }" data-toggle="tooltip"></select>
    </script> -->
</template>

<script>
import { toRef } from 'vue'

export default {
    name: 'MenuTableCellInputSelect',
    props: {
        cellObjectFromParent: Object
    },
    setup (props) {
        const select = toRef(props, "cellObjectFromParent");

        return {
            select
        }
    }
}
</script>

<style lang="scss" scoped>

</style>